import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'

const buildDefaultValues = coach => ({
  coachId: coach.id || '',
  name: coach?.name || '',
  email: coach?.email || '',
  phone: coach?.phone || '',
})

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .label('Display Name')
    .required(),
  email: yup
    .string()
    .email()
    .label('Email')
    .required(),
  phone: yup
    .string()
    .label('Phone')
    .test(
      'is-valid',
      'Phone is a required field',
      value => !value.includes('_'),
    )
    .required(),
})

export const useAccountForm = coach => {
  const form = useForm({
    defaultValues: buildDefaultValues(coach),
    resolver: yupResolver(validationSchema),
  })
  const hasErrors = Object.keys(form.errors).length > 0

  return { form, hasErrors }
}
