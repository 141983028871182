import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TipWell from 'components/tip-well'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import StripeAccountSetupRedirect from './stripe-account-setup-redirect'

const AddPaymentAccountForm = () => {
  const [formSubmission, setFormSubmission] = useState(null)
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async formValues => {
    setFormSubmission(formValues)
  }

  if (formSubmission) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Box mb={2}>
          <CircularHighlightedIcon icon={CreditCardOutlinedIcon} />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Sending you to Stripe...</Typography>
        </Box>
        <Box textAlign="center">
          <Typography variant="body1" color="textSecondary">
            We’re sending you to Stripe, where you can setup an online payment
            account.
          </Typography>
        </Box>
        <StripeAccountSetupRedirect
          accountName={formSubmission.paymentAccountName}
        />
      </Box>
    )
  }

  return (
    <>
      <Box mb={3}>
        <TipWell caption="Use a name that will help you identify the connected bank account at a glance." />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <TextField
            name="paymentAccountName"
            label="Online payment account name"
            inputRef={register({ required: true, minLength: 1 })}
            variant="outlined"
            error={!!errors.paymentAccountName}
            helperText={errors?.paymentAccountName && 'This field is required'}
          />
        </Box>
        <Box textAlign="right">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Next
          </Button>
        </Box>
      </form>
    </>
  )
}

export default AddPaymentAccountForm
