import React from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import ShareIcon from '@material-ui/icons/Share'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  menuRow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px',
    margin: '10px',
  },
  menuFont: {
    fontSize: '14px',
    fontWeight: '500',
  },
})

const EventsActionsMenu = ({
  event,
  isOpen,
  handleClose,
  anchorEl,
  setIsShareOpen,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const menuItems = [
    {
      Icon: ShareIcon,
      text: 'Share',
      onClick: () => setIsShareOpen(true),
    },
    {
      Icon: MessageOutlinedIcon,
      text: 'Message event host',
      onClick: () =>
        history.push(`/things-to-do/events/${event.slug}/messages`),
    },
  ]

  return (
    <Menu open={isOpen} onClose={handleClose} anchorEl={anchorEl}>
      {menuItems.map(({ Icon, text, onClick }) => (
        <MenuItem
          key={text}
          className={classes.menuRow}
          onClick={() => {
            handleClose()
            onClick()
          }}
        >
          <Icon style={{ marginRight: '5px' }} />
          <Typography className={classes.menuFont}>{text}</Typography>
        </MenuItem>
      ))}
    </Menu>
  )
}

export default EventsActionsMenu
