import ActivityPlans from 'components/activity-plans'
import StandaloneHeader from 'components/page-header/standalone-header'
import { gtmEvent } from 'lib/gtm'
import { useHideIntercom } from 'lib/support'
import { useEffect } from 'react'

const PublicActivityPlansPage = () => {
  useEffect(() => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'stages-loaded',
      activitiesLabel: 'stages-loaded',
    })
  }, [])
  useHideIntercom()

  return (
    <>
      <StandaloneHeader title="Activity Plans" />
      <ActivityPlans />
    </>
  )
}

export default PublicActivityPlansPage
