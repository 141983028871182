import React from 'react'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles, styled } from '@material-ui/core/styles'
import { useIsMobile } from 'lib/hooks'
import Dialog from 'components/dialog'
import AvailabilityExampleImage from 'images/availability-example.png'

const useStyles = makeStyles(theme => ({
  content: {
    padding: '24px 48px 32px 48px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 24px 16px 24px',
    },
  },
  paper: {
    maxWidth: '760px',
  },
}))

const ImageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#2196F31A',
  paddingLeft: theme.spacing(6),
  paddingTop: theme.spacing(4),
  marginRight: theme.spacing(7),
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    marginRight: 0,
  },
  '& img': {
    width: '100%',
    maxHeight: '360px',
  },
}))

const StyledAnnotation = styled(Typography)(({ theme }) => ({
  letterSpacing: '0.15px',
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}))

const ColoredBlock = styled(Box)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(2),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}))

const StyledDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  letterSpacing: '0.15px',
  fontFamily: 'Roboto',
  lineHeight: '22px',
}))

const HowAvailabilityWorksDialog = () => {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <Box>
      <Dialog
        showCloseButton
        trigger={
          <Button variant={isMobile ? 'text' : 'outlined'} color="primary">
            How It Works
          </Button>
        }
        fullScreen={isMobile}
        classes={{ paper: classes.paper }}
      >
        {({ closeDialog }) => {
          return (
            <>
              <DialogTitle>How Availability Works</DialogTitle>
              <DialogContent className={classes.content}>
                <ImageWrapper>
                  <img
                    src={AvailabilityExampleImage}
                    alt="availability-example"
                  />
                </ImageWrapper>
                <Box display="flex" alignItems="center" my={6}>
                  <Box display="flex" alignItems="center">
                    <ColoredBlock
                      bgcolor="#4CAF501A"
                      border="dashed 2px #4CAF50"
                    />
                    <StyledAnnotation variant="body1">
                      Available
                    </StyledAnnotation>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ColoredBlock bgcolor="#F88078" />
                    <StyledAnnotation variant="body1">
                      Blocked Times
                    </StyledAnnotation>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ColoredBlock bgcolor="#AAD1FF" />
                    <StyledAnnotation variant="body1">
                      Booked Offering
                    </StyledAnnotation>
                  </Box>
                </Box>
                <Typography variant="subtitle1" gutterBottom>
                  Blocked Times
                </Typography>
                <StyledDetails variant="body2" style={{ marginBottom: '24px' }}>
                  Reserve specific hours within your availability to mark when
                  you're not available, like for lunch breaks or personal
                  matters. These times are blocked out on your calendar,
                  indicating to clients that you're not available for coaching
                  sessions during these periods.
                </StyledDetails>
                <Typography variant="subtitle1" gutterBottom>
                  Offerings
                </Typography>
                <StyledDetails variant="body2">
                  After setting your availability and blocked times, create
                  offerings to detail the specific types of coaching sessions
                  clients can book with you. Offerings can range from private
                  lessons to group clinics, allowing clients to choose the
                  service that best fits their needs during your available
                  times.
                </StyledDetails>
              </DialogContent>
            </>
          )
        }}
      </Dialog>
    </Box>
  )
}

export default HowAvailabilityWorksDialog
