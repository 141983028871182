import React from 'react'
import { useParams } from 'react-router-dom'
import PageHeader from '../header-components/page-header'
import { formatNameForDisplay } from 'lib/text-display'
import StudentActionButtons from '../header-components/action-buttons'
import LoadingView from '../../loading-view'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { gql, useQuery } from '@apollo/client'
import EmergencyInformation from './emergency-info'
import DeleteStudent from '../details-components/delete-student'
import AdditionalInformation from './additional-information'
import GolfInformation from './golf-information'
import ServiceBackground from './service-background'
import { Container } from '@material-ui/core'
import ParticipantInfo from './participant-info'

const PGAHopeStudent = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  const { studentId } = useParams()

  const GET_HOPE_STUDENT = gql`
    query Student($id: ID!) {
      student(id: $id) {
        id
        firstName
        lastName
        dateOfBirth
        hasAccount
        contact {
          email
          phoneNumber
        }
        pgaHopeEmergencyContact {
          firstName
          lastName
          phone
          relationship
        }
        demographicProfile {
          gender
        }
        pgaHopeProfile {
          accommodations
          additionalInfo
          golfCareerInterest
          golfExperience
          referralSource
          handedness
          hasOwnClubs
          militaryStatus
          referralSource
          serviceBranch
          serviceYearStart
          serviceYearEnd
          shirtSize
        }
      }
    }
  `

  const { loading, data: { student } = {} } = useQuery(GET_HOPE_STUDENT, {
    variables: { id: studentId },
  })

  if (loading) {
    return <LoadingView />
  } else {
    const studentName = `${student.firstName} ${student.lastName}`

    return (
      <>
        <PageHeader
          title={formatNameForDisplay(studentName)}
          actions={<StudentActionButtons student={student} />}
          isMobile={isMobile}
          activeTab="Details"
          style={{ color: 'black !important' }}
        />
        <Container>
          <ParticipantInfo student={student} />
          <EmergencyInformation student={student} />
          <AdditionalInformation student={student} />
          <ServiceBackground student={student} />
          <GolfInformation student={student} />
          <DeleteStudent studentId={student.id} />
        </Container>
      </>
    )
  }
}

export default PGAHopeStudent
