import { gql } from '@apollo/client'

const GET_GROUP_EVENT_BY_SLUG = gql`
  query GroupEventBySlug($slug: String) {
    currentUser {
      groupEvents(slug: $slug) {
        __typename
        id
        startDateTime
        endDateTime
        registrationCloseDateTime
        registrationCount
        externalRegistrationUrl
        paymentRecurrenceSchedule
        numberOfPayments
        lessonCount
        acceptsOnlinePayments
        coach {
          id
        }
        tags
        timezone
        isCanceled
        unlisted
        title
        maxAttendance
        accessCode
        paymentRecurrenceSchedule
        numberOfPayments
        priceInCents
        totalPriceInCents
        featuredImageUrl
        slug
        hasWaitlist
        coachFacility {
          id
          name
          timezone
        }
        academy {
          id
          facilities {
            id
            name
            timezone
          }
          coaches {
            id
            name
          }
        }
        assistantCoaches {
          id
          name
        }
        description
        paymentAccount {
          id
        }
        sessions {
          id
          startDateTime
          endDateTime
        }
      }
      attributes {
        ... on Coach {
          coachFacilities {
            id
            name
            timezone
          }
          academies {
            id
            name
            facilities {
              id
              name
              timezone
            }
          }
          paymentAccounts {
            id
            name
            last4
          }
        }
      }
    }
  }
`

export default GET_GROUP_EVENT_BY_SLUG
