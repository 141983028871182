import React, { useState, useEffect } from 'react'
import SearchBar from './search-bar'
import { makeStyles } from '@material-ui/styles'
import EnhancedTable from 'components/tables/enhanced-table'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import EmptyStudents from 'components/empty-page-state'
import { TableContainer } from '@material-ui/core'
import { LeadTableItem } from './lead-table-item'
import { useIsTablet } from 'lib/hooks'
import withSearch from './with-search'

export const useStyles = makeStyles(theme => ({
  leadNameCell: {
    paddingLeft: '1.5rem',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  containerStyles: {
    boxSizing: 'border-box',
    padding: '0 36px 1rem',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
      padding: '0 8px 1rem',
    },
  },
}))

const LeadsTable = ({
  filteredItems,
  setUnviewedLeadCount,
  refetch,
  studentRefetch,
}) => {
  const isTablet = useIsTablet()
  const classes = useStyles()
  const [filteredLeads, setFilteredLeads] = useState(filteredItems)
  const [searchTerm, setSearchTerm] = useState('')

  // true if search term is empty - '' - or contained by name; false otherwise
  const nameMatchesSearchToken = ({ firstName, lastName }, token) => {
    const fullName = `${firstName} ${lastName}`.toLowerCase().trim()
    const term = token.toLowerCase().trim()

    return fullName.includes(term)
  }

  // inside the call to searchableItems.filter, return cond && otherCond && ... for filtering purposes
  //
  // In doing this we can compose filters.
  //
  // Make sure to update the effect dependencies list.
  useEffect(() => {
    const filtered = filteredItems.filter(item =>
      nameMatchesSearchToken(item, searchTerm),
    )
    setFilteredLeads(filtered)
  }, [searchTerm, filteredItems])

  const sortByCreatedOn = (a, b, dir) => {
    a = a.props.lead.createdAt
    b = b.props.lead.createdAt

    if (a < b) return dir === 'desc' ? 1 : -1
    if (a === b) return 0
    if (a > b) return dir === 'desc' ? -1 : 1
  }

  const sortByFullName = (a, b, dir) => {
    a = (
      a.props.lead.firstName.toLowerCase() + a.props.lead.lastName.toLowerCase()
    ).replace(/\s/g, '')
    b = (
      b.props.lead.firstName.toLowerCase() + b.props.lead.lastName.toLowerCase()
    ).replace(/\s/g, '')

    if (a > b) return dir === 'desc' ? 1 : -1
    if (a < b) return dir === 'desc' ? -1 : 1
    return 0
  }

  const columns = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      width: isTablet ? '80%' : '30%',
      mobile: true,
      sortable: true,
      sortComparator: sortByFullName,
    },
    {
      id: 'interest',
      label: 'Interest',
      align: 'left',
      width: '30%',
      mobile: false,
      sortable: false,
    },
    {
      id: 'created-on',
      label: 'Created On',
      align: 'left',
      width: '20%',
      mobile: false,
      sortable: true,
      sortComparator: sortByCreatedOn,
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      width: '20%',
      mobile: true,
      sortable: false,
    },
  ]

  const rows = filteredLeads?.map(lead => (
    <LeadTableItem
      lead={lead}
      key={`${lead.id}`}
      setUnviewedLeadCount={setUnviewedLeadCount}
      refetch={refetch}
      studentRefetch={studentRefetch}
    />
  ))

  return (
    <TableContainer className={classes.containerStyles}>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder="Search Leads"
        data-testid="leads-list-search"
      />
      <EnhancedTable
        rows={rows}
        columns={columns}
        label="Leads"
        numberOfRows={25}
        emptyState={() => (
          <EmptyStudents
            title="Leads"
            caption="No matching leads."
            IconComp={() => (
              <PermContactCalendarOutlinedIcon fontSize="large" />
            )}
            removeButton
          />
        )}
      />
    </TableContainer>
  )
}

export default withSearch(LeadsTable)
