import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  eventWrapper: {
    backgroundColor: 'white',
    padding: '60px 60px 20px 60px',
    [theme.breakpoints.only('xs')]: {
      padding: '16px 0px 10px 0px',
    },
  },
  baseWrapper: {
    backgroundColor: 'white',
    padding: '0px 60px 30px 60px',
    [theme.breakpoints.only('xs')]: {
      padding: '0px 0px 16px 0px',
    },
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  formStateContainer: ({ completedRegistration }) => ({
    backgroundColor: 'white',
    minHeight: 'inherit',
    marginTop: theme.spacing(6),
    borderRadius: '12px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '1px',
      minHeight: completedRegistration ? '0px' : 'calc(100vh - 57px)',
      borderRadius: '0px',
      width: 'calc(100% + 32px)',
      marginLeft: '-16px',
    },
  }),
  formSectionHeader: {
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  formSectionSubheader: {
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: 700,
    },
  },
  stepActions: {
    background: '#fff',
  },
  divider: {
    margin: '16px 0px',
  },
  actionButton: {
    borderRadius: '50px',
    background: theme.palette.blue.main,
    color: '#fff',
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    width: '186px',
    fontWeight: 700,
    '&:hover': {
      background: theme.palette.blue.main,
      opacity: 0.9,
    },
  },
  next: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  previous: {
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  detailsButton: {
    border: '1px solid #3268A7',
    borderRadius: '50px',
    background: '#fff',
    color: '#3268A7',
    textTransform: 'none',
    width: '150px',
    marginRight: theme.spacing(2),
    fontWeight: 700,
  },
  body: {
    marginBottom: theme.spacing(3),
  },
  statusesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    fontFamily: 'Source Serif Pro',
    marginTop: '12px',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'capitalize',
    letterSpacing: '0',
  },
  bottomDivider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  link: {
    color: '#3268A7',
    textDecoration: 'underline',
  },
  proceedingText: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  error: {
    border: '1px solid red',
    borderRadius: '4px',
    padding: '0.5rem',
  },
}))

export default useStyles
