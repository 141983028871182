import React from 'react'
import { styled } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { formatPhoneNumber } from 'utils/phoneNumber'
import { useFormContext } from 'react-hook-form'

const formatErrorMessage = (name, message) => {
  if (!message) return null
  const error = message
    .split(' ')
    .slice(1)
    .join(' ')
  return `${name} ${error}`
}

export const StudentNameFields = () => {
  const { register, errors } = useFormContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          name="firstName"
          margin="normal"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="First name *"
          errors={!!errors.firstName}
          helperText={formatErrorMessage(
            'First name',
            errors.firstName?.message,
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="lastName"
          margin="normal"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="Last name *"
          errors={!!errors.lastName}
          helperText={formatErrorMessage('Last name', errors.lastName?.message)}
        />
      </Grid>
    </Grid>
  )
}

export const ContactInfoFields = () => {
  const { register, errors } = useFormContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          name="email"
          inputRef={register}
          margin="normal"
          fullWidth
          variant="outlined"
          label="Email"
          errors={!!errors.email}
          helperText={formatErrorMessage('Email', errors.email?.message)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="phone"
          inputRef={register}
          margin="normal"
          fullWidth
          variant="outlined"
          label="Phone number"
          onBlur={e => (e.target.value = formatPhoneNumber(e.target.value))}
          errors={!!errors.phone}
          helperText={formatErrorMessage('Phone', errors.phone?.message)}
        />
      </Grid>
    </Grid>
  )
}

export const GuardianNameFields = () => {
  const { register, errors } = useFormContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          name="guardianFirstName"
          margin="normal"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="First name *"
          errors={!!errors.guardianFirstName}
          helperText={formatErrorMessage(
            'First name',
            errors.guardianFirstName?.message,
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="guardianLastName"
          margin="normal"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="Last name *"
          errors={!!errors.guardianLastName}
          helperText={formatErrorMessage(
            'Last name',
            errors.guardianLastName?.message,
          )}
        />
      </Grid>
    </Grid>
  )
}

const TileButton = styled('div')(({ theme, selected }) => ({
  width: '155px',
  height: '70px',
  border: `1px solid ${theme.palette.primary.active}`,
  marginRight: '10px',
  borderRadius: '4px',
  position: 'relative',
  cursor: 'not-allowed',
  backgroundColor: selected ? theme.palette.primary.selected : 'white',
  [theme.breakpoints.down('xs')]: {
    width: '45%',
  },
}))

const TileButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '20px',
  [theme.breakpoints.down('xs')]: {
    justifyContent: 'center',
  },
}))

const ButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.active,
  position: 'absolute',
  height: 'auto',
  top: 'calc(50% - 20%)',
  left: 'calc(50% - 20%)',
}))

const CheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.active,
  position: 'absolute',
  bottom: '5px',
  left: '5px',
}))

export const AgeTiles = ({ isMinor, setIsMinor }) => (
  <div style={{ marginBottom: '10px 0' }}>
    <Typography style={{ marginBottom: '16px' }}>
      Is this student an adult or junior (18 years old or younger)?
    </Typography>
    <TileButtonWrapper>
      <TileButton selected={!isMinor} onClick={() => setIsMinor(true)}>
        <ButtonText color="primary">ADULT</ButtonText>
        {!isMinor && <CheckIcon color="primary" data-testid="adult-selected" />}
      </TileButton>
      <TileButton selected={isMinor} onClick={() => setIsMinor(false)}>
        <ButtonText color="primary">JUNIOR</ButtonText>
        {isMinor && <CheckIcon color="primary" data-testid="junior-selected" />}
      </TileButton>
    </TileButtonWrapper>
  </div>
)
