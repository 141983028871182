import { PGA_COM_HOST } from 'env'
import React, { useState } from 'react'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import EmptyPageState from 'components/empty-page-state'
import GET_GROUP_EVENTS from './query'
import EventsDisplay from './events-display'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { parseEvents } from 'utils/eventUtils'
import PartyPopperIcon from 'components/icons/party-popper-icon'

const EventsPageData = ({ selectedTab }) => {
  const { data, loading, error } = useQuery(GET_GROUP_EVENTS, {
    variables: {
      orderBy: { field: 'START_DATE_TIME', direction: 'ASC' },
    },
  })

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error />

  const events = get(data, 'currentUser.groupEvents', [])
  const groupEvents = parseEvents(events)

  const upcomingEvents = groupEvents.filter(
    b =>
      DateTime.fromISO(b.seriesEndDateTime) >= DateTime.local().startOf('day'),
  )
  const pastEvents = groupEvents.filter(
    b =>
      DateTime.fromISO(b.seriesEndDateTime) < DateTime.local().startOf('day'),
  )

  const currentlyViewingEvents =
    selectedTab === 'upcoming' ? upcomingEvents : pastEvents

  return (
    <>
      {currentlyViewingEvents.length ? (
        <EventsDisplay events={currentlyViewingEvents} />
      ) : (
        <EmptyPageState
          IconComp={() => <PartyPopperIcon />}
          title={`No ${
            selectedTab === 'upcoming' ? 'Upcoming' : 'Past'
          } Events`}
          caption="Events you register for will show up here."
          buttonText="Find an Event"
          actionFn={() => window.open(`${PGA_COM_HOST}/things-to-do`)}
        />
      )}
    </>
  )
}

const EventsPage = () => {
  const [selectedTab, setSelectedTab] = useState('upcoming')
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'My Coach',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]
  return (
    <>
      <PageHeader
        title="Events"
        crumbs={crumbs}
        tabs={
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
          >
            <Tab label="Upcoming" value="upcoming" />
            <Tab label="Past" value="past" />
          </Tabs>
        }
      />
      <EventsPageData selectedTab={selectedTab} />
    </>
  )
}

export default EventsPage
