import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import BlockTimeDestroyDialog from './block-time-destroy-dialog'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import useStyles from './block-time-manager.styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const BlockTimeItem = ({ time, onDestroySuccess }) => {
  const classes = useStyles()
  const googleBlockTimesId = '0'
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  return (
    <Box mb={1.5}>
      <Card variant="outlined" style={{ position: 'relative' }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography style={{ fontWeight: 'bold' }}>Start</Typography>
              <Box>
                <Typography>
                  {time.startDate}&nbsp;
                  {time.startTime === 'All day'
                    ? '(All day)'
                    : `at ${time.startTime}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box mt={isMobile ? 2 : 0}>
                <Typography style={{ fontWeight: 'bold' }}>End</Typography>
              </Box>
              <Box>
                <Typography>
                  {time.endDate}&nbsp;
                  {time.endTime === 'All day'
                    ? '(All day)'
                    : `at ${time.endTime}`}
                </Typography>
              </Box>
            </Grid>
            {time.blockNote && (
              <Grid item xs={12} md={4}>
                <Box mt={isMobile ? 2 : 0}>
                  <Typography style={{ fontWeight: 'bold' }}>Note</Typography>
                </Box>
                <Box>
                  <Typography>{time.blockNote}</Typography>
                </Box>
              </Grid>
            )}
            <Box style={{ position: 'absolute', top: '16px', right: '16px' }}>
              {time.id !== googleBlockTimesId && (
                <BlockTimeDestroyDialog
                  blockTime={time}
                  onSuccess={onDestroySuccess}
                  trigger={
                    <Box>
                      <IconButton aria-label="delete" style={{ padding: 0 }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                />
              )}
              {time.id === googleBlockTimesId && (
                <Tooltip
                  title="This blocked time is being synced from your connected calendar."
                  placement="right"
                  className={classes.action}
                >
                  <SyncAltIcon fontSize="small" />
                </Tooltip>
              )}
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default BlockTimeItem
