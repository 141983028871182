import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useFormContext, useFieldArray } from 'react-hook-form'
import EditFacilities from '../form-components/cp-edit-facilities'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const MAX_FACILITY_COUNT = 3

const FacilitiesStep = () => {
  const { register, errors, control, setValue, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'facilities',
  })

  const deleteFacility = indexToRemove => {
    remove(indexToRemove)
  }

  const handleChange = ({ target }) => {
    setValue(target.name, target.value, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const setFacilityFieldValue = (field, value) => {
    setValue(field, value, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const addNewFacility = () => {
    append({ customData: { facilityRank: fields.length + 1 } })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box textAlign="center" maxWidth="735px">
        <Typography variant="h4" gutterBottom>
          Add a Facility
        </Typography>
        <Typography variant="body1" gutterBottom>
          For your coach profile to be promoted on pga.com, you must add a
          facility or create a custom one. The first facility entered will be
          your primary facility used for location-based searches.
        </Typography>
      </Box>
      {fields.map((facility, i) => (
        <EditFacilities
          key={facility.id}
          setFieldValue={setFacilityFieldValue}
          errors={errors?.facilities && errors.facilities[i]}
          register={register}
          control={control}
          watch={watch}
          formPrefix={`facilities[${i}]`}
          isPrimary={i === 0}
          deleteMe={() => deleteFacility(i)}
          handleChange={handleChange}
          facilityIndex={i}
          addItems={setValue}
          facility={facility}
        />
      ))}
      <Button
        style={{ marginBottom: '1rem', marginTop: '1rem' }}
        variant="outlined"
        color="primary"
        size="large"
        type="button"
        onClick={addNewFacility}
        disabled={fields.length >= MAX_FACILITY_COUNT}
        startIcon={<AddIcon />}
      >
        Add Another Facility
      </Button>
    </Box>
  )
}

export default FacilitiesStep
