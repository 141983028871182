import ActivityPlans from 'components/activity-plans'
import { PageHeader } from 'components/page-header'
import { gtmEvent } from 'lib/gtm'
import React, { useEffect } from 'react'

const ActivityPlansPage = () => {
  useEffect(() => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'stages-loaded',
      activitiesLabel: 'stages-loaded',
    })
  }, [])

  return (
    <>
      <PageHeader title="Activity Plans" />
      <ActivityPlans />
    </>
  )
}

export default ActivityPlansPage
