// Schema fragment
export const baseProfileSchemaFragment = `
  firstName
  lastName
  displayName
  title
  gender
  profilePhoto
  memberId
  universalId
  customSlug
  email
  phoneNumber
  phoneExt
  mobileNumber
  city
  state
  zipCode
  bio
  introductionVideoUrl
  numberOfStudents
  numberOfFollowers
  socialJsonData {
    type
    reference
    preferred
  }
  testimonialsJsonData {
    testimonial
    author
    rank
  }
  photosJsonData {
    reference
    rank
  }
  toolJsonData
  prices {
    priceId
  }
`
export const facilitiesSchemaFragment = `
  facilities {
    id
    externalFacilityId
    name
    address1
    address2
    city
    state
    zip
    phone
    url
    isPrivate
    latitude
    longitude
    coachProfilesFacilities {
      facilityRank
      visible
      offersCoachingAtPrivateClub
    }
  }
`
export const mutateProfileSchemaFragment = `
  ${baseProfileSchemaFragment}
  ${facilitiesSchemaFragment}
  experiences {
    experienceId
  }
  services {
    serviceId
  }
  skills {
    skillId
  }
`
// Schema used for retrieving a coach
export const getProfileSchema = `
  coachProfileId
  ${baseProfileSchemaFragment}
  status
  certificationLevel
  memberType
  coach {
    ${facilitiesSchemaFragment}
  }
  experienceTypes {
    experienceTypeId
    typeName
    description
    experiences {
      experienceId
      experienceName
      experienceType
    }
  }
  serviceTypes {
    serviceTypeId
    typeName
    description
    services {
      serviceId
      serviceName
      serviceType
    }
  }
  skills {
    skillId
    skillName
    description
  }
  createdAt
  updatedAt
`
// Schema for creating a new profile
export const createProfileSchema = `
  internalUserId
  ${mutateProfileSchemaFragment}
`
// Schema for updating an existing profile
export const updateProfileSchema = `
  ${mutateProfileSchemaFragment}
  status
`
