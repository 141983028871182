import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'env'

const stripePromise = loadStripe(STRIPE_KEY)

const defaultOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
}

const withStripeElements = (Component, options = defaultOptions) => props => {
  return (
    <Elements stripe={stripePromise} options={options}>
      <Component {...props} />
    </Elements>
  )
}

export default withStripeElements
