import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Error from 'components/error'
import { useAuth } from 'lib/auth'
import { LessonReviewQuery } from './query'
import ReviewBookingSkeleton from './review-booking-skeleton'
import BuyLessonPackLayout from './BuyLessonPackLayout'
import SummaryLayout from './summary-layout'

export default ({
  idOrSlug,
  previousUrl,
  lessonTypeId,
  locationId,
  lessonPackId,
}) => {
  const { isLoggedIn, user } = useAuth()
  const [isComplete, setIsComplete] = useState(false)
  const [cardBrand, setCardBrand] = useState('')
  const [lastDigits, setLastDigits] = useState(null)
  const [selectedLessonPackId, setSelectedLessonPackId] = useState(
    lessonPackId || null,
  )

  const { data, loading, error } = useQuery(LessonReviewQuery, {
    variables: {
      id: lessonTypeId,
    },
  })

  if (loading) return <ReviewBookingSkeleton />
  if (error) return <Error error={error} />

  const lessonInfo = data?.lessonTypes[0]
  if (isComplete) {
    return (
      <SummaryLayout
        locationId={locationId}
        lessonType={lessonInfo}
        lessonTypeId={lessonTypeId}
        idOrSlug={idOrSlug}
        selectedLessonPackId={selectedLessonPackId}
        cardBrand={cardBrand}
        lastDigits={lastDigits}
        isComplete={isComplete}
      />
    )
  }
  if (isLoggedIn) {
    return (
      <BuyLessonPackLayout
        previousUrl={previousUrl}
        lessonInfo={lessonInfo}
        user={user}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        setCardBrand={setCardBrand}
        setLastDigits={setLastDigits}
        selectedLessonPackId={selectedLessonPackId}
        setSelectedLessonPackId={setSelectedLessonPackId}
      />
    )
  }
  return (
    <BuyLessonPackLayout previousUrl={previousUrl} lessonInfo={lessonInfo} />
  )
}
