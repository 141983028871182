import React, { useState, Fragment } from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { styled, makeStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CloseIcon from '@material-ui/icons/Close'

const StyledCard = styled(Card)(() => ({
  padding: 0,
  overflow: 'visible',
}))

const FormCardContent = styled(CardContent)(() => ({
  padding: 0,
  '&:last-child': { padding: 0 },
}))

const CardHeadingBox = styled(Box)(({ theme }) => ({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.grey[300],
  padding: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
}))

const CardContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}))

const HelpButton = styled(Button)(() => ({
  padding: 0,
  minWidth: 0,
}))
const helpStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.info.light,
    marginLeft: theme.spacing(1),
  },
}))

function Help({ text, title }) {
  const [open, setOpen] = useState(false)

  const styles = helpStyles()

  return (
    <Fragment>
      <span className={styles.icon}>
        <HelpButton onClick={() => setOpen(true)} color="primary">
          <HelpOutlineIcon />
        </HelpButton>
      </span>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            {title}
            <Button onClick={() => setOpen(false)}>
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box pb={4}>
            <Typography variant="body1">{text}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default function FormCard({
  title,
  helpText,
  children,
  actions,
  id,
  ...props
}) {
  return (
    <Grid item {...props}>
      <StyledCard variant="outlined">
        <FormCardContent>
          <CardHeadingBox>
            <Typography variant="h6" id={id}>
              {title}
            </Typography>
            {helpText && <Help text={helpText} title={title} />}
            {actions && (
              <Box display="flex" flexDirection="row-reverse" flexGrow={1}>
                {actions}
              </Box>
            )}
          </CardHeadingBox>

          <CardContentBox>
            <Grid container spacing={4} justify="space-between">
              {children}
            </Grid>
          </CardContentBox>
        </FormCardContent>
      </StyledCard>
    </Grid>
  )
}
