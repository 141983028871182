import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  CardRoot,
  CheckboxItemWrapper,
  StyledH5,
  HelperMsg,
  InputsTwoColumns,
  ErrorMsg,
} from 'components/coaches-profile/profile-stepper.styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { findIndex, some } from 'lodash'

const ExperiencesQuery = gql`
  query Experiences {
    Experiences {
      message
      success
      experienceTypes {
        experienceTypeId
        typeName
        description
        experiences {
          experienceId
          experienceName
          experienceType
        }
      }
    }
  }
`

export const ExperienceStep = ({ experienceTypes }) => {
  const { register, errors, control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: 'experiences',
    control,
  })

  const isChecked = (experienceId, checkedExperiences) => {
    return some(checkedExperiences, { experienceId })
  }

  const toggleExperienceChecked = (experienceId, checkedExperiences) => {
    isChecked(experienceId, checkedExperiences)
      ? remove(findIndex(checkedExperiences, { experienceId }))
      : append({ experienceId })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4" gutterBottom>
        Experience
      </Typography>
      {errors.experiences && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>
          {errors.experiences?.message}
        </ErrorMsg>
      )}
      {experienceTypes.map(experienceType => {
        return (
          <CardRoot key={experienceType.typeName} className="auto-width">
            <StyledH5>{experienceType.typeName}</StyledH5>
            <HelperMsg>{experienceType.description}</HelperMsg>
            <InputsTwoColumns>
              {experienceType.experiences.map((experience, i) => {
                return (
                  <CheckboxItemWrapper key={experience.experienceId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            toggleExperienceChecked(
                              experience.experienceId,
                              fields,
                            )
                          }
                          color="secondary"
                          checked={isChecked(experience.experienceId, fields)}
                        />
                      }
                      value={experience.experienceId}
                      name={`experiences[${i}].experienceId`}
                      label={experience.experienceName}
                      color="secondary"
                    />
                  </CheckboxItemWrapper>
                )
              })}
            </InputsTwoColumns>
          </CardRoot>
        )
      })}
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <input
              type="hidden"
              ref={register()}
              name={`experiences[${index}].experienceId`}
              value={item.experienceId}
            />
          </div>
        )
      })}
    </Box>
  )
}

export default function Experience() {
  const { data, loading, error } = useQuery(ExperiencesQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />
  return <ExperienceStep experienceTypes={data?.Experiences?.experienceTypes} />
}
