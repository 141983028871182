import React from 'react'
import { useFormContext } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  accessCodeRoot: {
    marginTop: '42px',
  },
  inputStyled: {
    maxWidth: '450px',
    marginTop: '16px',
  },
}))

const AccessCode = () => {
  const { register, errors, setValue, watch } = useFormContext()

  const classes = useStyles()
  const accessCode = watch('accessCode')
  const clearAccessCode = () => setValue('accessCode', null)

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      className={classes.accessCodeRoot}
    >
      <Typography variant="subtitle1" gutterBottom>
        Access Code
      </Typography>
      <Typography variant="body2" gutterBottom>
        Requiring an access code is a way to password protect your offering.
        Create your own code and share with contacts to allow them to register.
      </Typography>
      <TextField
        name="accessCode"
        type="text"
        inputRef={register}
        label="Access Code"
        error={!!errors.accessCode}
        helperText={errors?.accessCode?.message}
        className={classes.inputStyled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!accessCode} onClick={clearAccessCode}>
                <ClearOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default AccessCode
