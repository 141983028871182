import React from 'react'
import Dialog from 'components/dialog'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Disclaimer from 'components/disclaimer'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useGroupEventTemplateOptions } from 'components/group-events/form-option-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { startSurvey } from 'lib/support'
import GroupEventTemplateCard from './group-event-template-card'
import PGAIcon from 'images/pga-seal-icon.svg'
import { useIsMobile } from 'lib/hooks'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  base: {
    background: '#e9f5fe',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: '0 8px',
    background: 'e9f5fe',
    color: 'e9f5fe',
  },
  logo: {
    width: '60px',
    minWidth: '60px',
    alignSelf: 'center',
    margin: '8px 8px',
  },
  body: {
    padding: '16px',
  },
  button: {
    width: '200px',
    minWidth: '200px',
  },
}))

const GroupEventTemplatesList = ({ onCreateClick }) => {
  const groupEventTemplates = useGroupEventTemplateOptions()
  return (
    <>
      {groupEventTemplates.map(template => (
        <Grid key={template.title} item lg={4} md={6} xs={12}>
          <GroupEventTemplateCard
            event={template}
            callToAction="Use template"
            onCreateClick={onCreateClick}
          />
        </Grid>
      ))}
    </>
  )
}

const CreateFromTemplateDialog = ({ trigger }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <Dialog trigger={trigger} fullScreen showCloseButton>
      {({ closeDialog }) => {
        return (
          <>
            <DialogTitle>
              <Container maxWidth="lg">
                Create From Template
                <Typography variant="body1">
                  Select a template to get started. As you progress through the
                  event creation wizard, feel free to edit the event information
                  as you see fit.
                </Typography>
              </Container>
            </DialogTitle>
            <DialogContent dividers>
              <Container maxWidth="lg">
                <Grid container spacing={4}>
                  <GroupEventTemplatesList onCreateClick={closeDialog} />
                </Grid>
              </Container>
              <Container maxWidth="md" className={classes.container}>
                <Box mt={4}>
                  <Disclaimer
                    logo={PGAIcon}
                    description={
                      <>
                        <strong>{"Don't see what you're looking for? "}</strong>
                        <br />
                        {
                          'Make a suggestion on a popular event that you would like to see featured. Check back often as we continue to add new event templates.'
                        }
                      </>
                    }
                    action={
                      <Button
                        variant={isMobile ? 'text' : 'outlined'}
                        color="primary"
                        onClick={() => {
                          startSurvey(27771605)
                          closeDialog && closeDialog()
                        }}
                      >
                        Make a suggestion
                      </Button>
                    }
                  />
                </Box>
              </Container>
            </DialogContent>
          </>
        )
      }}
    </Dialog>
  )
}

export default CreateFromTemplateDialog
