import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7),
    [theme.breakpoints.only('xs')]: {
      margin: '16px 0px',
    },
  },
  action: {
    marginLeft: 'auto',
  },
}))

export const SettingsBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  padding: '24px 8px 24px 24px',
  marginBottom: theme.spacing(2),
  color: 'rgba(0, 0, 0, 0.87)',
  minHeight: theme.spacing(9),
}))

export default useStyles
