import { Typography, Button, Container } from '@material-ui/core'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { styled } from '@material-ui/styles'
import { useStyles } from './intake-form.styles'
import { StepLabels } from './steps'
import { PGA_COM_HOST } from 'env'
import DownloadMyPGAsection from 'components/download-mypga-section/download-mypga-section'
import RouterLinkButton from 'components/router-link-button'
import StepperComponent from './stepper'

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontFamily: 'Roboto',
  fontSize: '34px',
  lineHeight: '42px',
  color: theme.palette.text.primary,
  marginBottom: '2rem',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '28px',
    lineHeight: '36px',
  },
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: '80%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto 5rem',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0 auto 2rem',
  },
}))

const SuccessIcon = styled(AssignmentTurnedInOutlinedIcon)(({ theme }) => ({
  borderRadius: '50%',
  background: 'rgba(0,25,75,.08)',
  padding: '1rem',
  color: theme.palette.primary.main,
  width: '83px',
  height: '83px',
  marginBottom: '.5rem',
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: '400',
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '22px',
  letterSpacing: '0.15px',
  textAlign: 'center',
}))

const StyledContent = styled(Typography)(({ theme }) => ({
  marginBottom: '2rem',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '1rem 1.5rem',
  background: '#043362',
  color: '#fff',
}))

const StyledDivider = styled('hr')(({ theme }) => ({
  width: '100%',
  margin: '2rem 0 0',
}))
const Success = () => {
  const uniqueSteps = [...new Set(Object.values(StepLabels))]
  const labelStep = 2
  const classes = useStyles()

  return (
    <StyledContainer>
      <StepperComponent labelStep={labelStep} uniqueSteps={uniqueSteps} />
      <SuccessIcon fontSize="large" />
      <StyledHeader variant="h4">
        Great job on updating your profile!
      </StyledHeader>
      <StyledContent>
        <StyledTypography variant="body2">
          Your coach is now all set to give you an amazing lesson!
        </StyledTypography>
        <StyledTypography variant="body2" className={classes.successSeparation}>
          Ready to discover exciting golf events near you?
        </StyledTypography>
      </StyledContent>
      <StyledButton href={`${PGA_COM_HOST}/things-to-do`} variant="contained">
        FIND EVENTS NEAR ME
      </StyledButton>
      <RouterLinkButton
        variant="text"
        color="primary"
        to="/club"
        style={{ padding: '1rem 1.5rem' }}
      >
        HOME
      </RouterLinkButton>
      <StyledDivider />
      <DownloadMyPGAsection subtitle="Connect with your coach and stay up to date on your coaching center programs!" />
    </StyledContainer>
  )
}
export default Success
