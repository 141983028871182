import { useRef } from 'react'
import { useQuery } from '@apollo/client'
import { LessonAvailabilityQuery } from 'pages/schedule/queries'
const { DateTime } = require('luxon')

export const aggregateByDate = (timeSlots, tz) => {
  return timeSlots.reduce((acc, ts) => {
    const date = DateTime.fromISO(ts, { zone: tz }).toISODate()
    if (!(date in acc)) {
      acc[date] = []
    }
    acc[date].push(ts)
    return acc
  }, {})
}

export const aggregateByPeriod = (timeSlots, tz) => {
  return timeSlots.reduce(
    (acc, ts) => {
      const period = DateTime.fromISO(ts, { zone: tz }).toFormat('a')
      acc[period].push(ts)
      return acc
    },
    { AM: [], PM: [] },
  )
}

export const dateRangeForMonth = (month, currentTime) => {
  const startDateTime = DateTime.max(currentTime, month)
  const endDateTime = startDateTime.endOf('month')

  return { startDateTime, endDateTime }
}

export const useMonthlyLessonAvailability = (locationId, lessonTypeId) => {
  const currentTime = useRef(DateTime.local())
  const { startDateTime, endDateTime } = dateRangeForMonth(
    DateTime.local().startOf('month'),
    currentTime.current,
  )
  return useQuery(LessonAvailabilityQuery, {
    variables: {
      locationId,
      id: lessonTypeId,
      startDateTime,
      endDateTime,
    },
  })
}
