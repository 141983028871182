import React from 'react'
import {
  Route,
  Switch,
  useLocation,
  Redirect,
  useRouteMatch,
} from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import AcademyScheduleStepController from 'pages/academy/schedule'
import SpecialtyPrograms from '../pages/academy/specialty-programs'
import ProgramInterest from '../pages/academy/specialty-programs/program-interest'
import Reservations from '../pages/academy/reservations'
import Error from 'components/error'
import ReservationRules from '../pages/academy/reservations/rules'

const GolfAcademiesHelmet = () => (
  <Helmet>
    <title>MyPGA | Golf Academies</title>
    <meta name="description" content="MyPGA | Golf Academies" />
    <link rel="icon" href="/pga-coach-favicon.ico" />
  </Helmet>
)

const AcademyRoutes = () => {
  const location = useLocation()
  const { pathname, search } = location
  const { path } = useRouteMatch()
  const queryParams = new URLSearchParams(search)
  const category = queryParams.get('category')

  const isSpecialtyProgramsPath = pathname.includes('specialty-programs')
  const isReservationsPath = pathname.includes('reservations')
  const isSpecialtyProgramsOrReservationsPath =
    isSpecialtyProgramsPath || isReservationsPath
  // If the category is missing from the URL And the URL is neither the specialty-programs nor the reservations page
  // we should redirect the user to the coaching-center page with the category query param set to PRIVATE
  if (!category) {
    if (!isSpecialtyProgramsOrReservationsPath) {
      return <Redirect to={`${path}?category=PRIVATE`} />
    }
  }
  /*
  This is used as work-around related to Intercom Product Tours
  More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
  */
  const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)
  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.offeringsRefresh,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <GolfAcademiesHelmet />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Switch>
          <Route
            exact
            path={`${path}?/:slug/schedule`}
            component={AcademyScheduleStepController}
          />
          <Route exact path={`${path}/reservations`} component={Reservations} />
          <Route
            exact
            path={`${path}/reservations/rules`}
            component={ReservationRules}
          />
          <Route
            exact
            path={`${path}/specialty-programs`}
            component={SpecialtyPrograms}
          />
          <Route
            exact
            path={`${path}/specialty-programs/interest`}
            component={ProgramInterest}
          />
          <Route exact path={path} component={AcademyScheduleStepController} />
          <Route path="*" render={() => <Error statusCode={404} />} />
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default AcademyRoutes
