import React from 'react'
import getDeviceType from 'lib/utils/getDeviceType'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useAndroidStyles, useIosStyles } from './styles'

const SmartBannerAd = ({
  appIcon,
  appStoreUrl,
  titleText,
  subtitleText,
  ctaText,
  handleCtaClick,
  handleDismiss,
  top,
}) => {
  const androidStyles = useAndroidStyles({ top })
  const iosStyles = useIosStyles({ top })
  const deviceType = getDeviceType()
  const classes = deviceType === 'android' ? androidStyles : iosStyles

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  if (deviceType === 'unknown' || !isMobile) return null

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}
      >
        <Box display="flex" alignItems="center">
          <CloseIcon onClick={handleDismiss} className={classes.dismissIcon} />
          <img src={appIcon} alt="app-icon" className={classes.appIcon} />
          <Box display="flex" flexDirection="column">
            <Typography className={classes.titleText}>{titleText}</Typography>
            <Typography className={classes.subtitleText}>
              {subtitleText}
            </Typography>
          </Box>
        </Box>
        <Button onClick={handleCtaClick} className={classes.ctaButton}>
          {ctaText}
        </Button>
      </Box>
    </>
  )
}

export default SmartBannerAd
