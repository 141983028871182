import React from 'react'
import { Box, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
  },
  terms: {
    marginLeft: '8px',
  },
  termsList: {
    padding: '0 1.5rem',
    marginTop: '-2.25rem',
  },
  agreement: {
    '& span': {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
}))

const TermsAndConditions = ({ isChecked, setIsChecked, register }) => {
  const classes = useStyles()

  return (
    <>
      <Box mb={4}>
        <Typography variant="body1" className={classes.attributes} gutterBottom>
          <Box mb={4}>Terms and Conditions for Holiday Packs</Box>
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.attributes} ${classes.terms}`}
          gutterBottom
        >
          <ul className={classes.termsList}>
            <li>
              Lesson Packs must be used for private Lessons with the assigned
              Coach.
            </li>
            <li>
              If the assigned Coach is no longer available, the remaining
              Lessons may be taken with a Coach at the same level or price.
            </li>
            <li>
              Lesson Packs may not be transferred by the intended recipient.
            </li>
            <li>
              Each lesson in the Lesson Pack must be scheduled within one year
              of the date of purchase. Lessons may be used more than one year
              after purchase so long as they are scheduled within one year of
              the date of purchase.
            </li>
            <li>Lesson Packs may not be redeemed for cash.</li>
          </ul>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              color="primary"
              onChange={() => setIsChecked(!isChecked)}
              name="termsAndConditions"
              ref={register}
            />
          }
          label="By checking this box, you are agreeing to PGA of
          America's Terms and Conditions for Holiday Packs"
          className={classes.agreement}
        />
      </Box>
    </>
  )
}

export default TermsAndConditions
