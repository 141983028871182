import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/client'
import { useAuth } from 'lib/auth'
import { useSnackbarSuccess, useSnackbarError } from 'lib/snackbar'
import { PageHeader } from 'components/page-header'
import Error from 'components/error'
import { usePortal } from 'lib/portal'
import useStyles, { SettingsBlock } from './styles'
import { FormActionBar } from 'components/form-util'
import { toBoolean } from 'lib/utils/boolean'
import { GET_BOOKING_LIMIT_OPTIONS, SET_BOOKING_LIMIT } from './query'
import { flags, useFeatureFlags } from 'lib/feature-flags'

const BookingLimitsPage = () => {
  const { path } = useRouteMatch()
  const { user } = useAuth()
  const userId = user?.coach?.id
  const [target] = usePortal()
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const snackbarError = useSnackbarError(enqueueSnackbar)
  const [bookingLimitValue, setBookingLimitValue] = useState(null)
  const [bookingCutoffValue, setBookingCutoffValue] = useState(null)
  const [saveVisible, setSaveVisible] = useState(false)
  const [checked, setChecked] = useState(true)

  const [isStudentCancellationEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_STUDENT_CANCELLATION,
  ])

  const [updateCoachPreferences] = useMutation(SET_BOOKING_LIMIT, {
    onCompleted: data => {
      const limitChanged = data?.updateCoachPreferences?.success
      if (!limitChanged) {
        return snackbarError(data?.updateCoachPreferences?.message)
      }
      setSaveVisible(false)
      snackbarSuccess('Successfully set booking limits')
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['set-booking-limit'],
        })
      snackbarError('Booking limits were not set.')
    },
  })

  const handleSave = e => {
    updateCoachPreferences({
      variables: {
        input: {
          bookingLimit: bookingLimitValue,
          bookingCutoff: bookingCutoffValue,
          canStudentCancel: checked,
        },
      },
    })
  }

  const crumbs = [
    {
      label: 'Settings',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]

  const { data, loading, error } = useQuery(GET_BOOKING_LIMIT_OPTIONS, {
    variables: { id: userId },
    onCompleted: data => {
      setBookingLimitValue(data?.coach?.bookingLimit)
      setBookingCutoffValue(data?.coach?.bookingCutoff)
      setChecked(toBoolean(data?.coach?.canStudentCancel))
    },
  })

  const handleLimitChange = e => {
    setBookingLimitValue(e.target.value)
    if (bookingCutoffValue) {
      setSaveVisible(true)
    } else {
      setSaveVisible(false)
    }
  }

  const handleCutoffChange = e => {
    setBookingCutoffValue(e.target.value)
    if (bookingLimitValue) {
      setSaveVisible(true)
    } else {
      setSaveVisible(false)
    }
  }

  const handleRadioChange = value => e => {
    e.preventDefault()
    setChecked(value)
    setSaveVisible(true)
  }

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />

  return (
    <>
      <PageHeader
        title="Booking & Cancellation Rules"
        crumbs={crumbs}
        actions={target}
      />
      {saveVisible && (
        <FormActionBar includeCancel>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSave}
          >
            Save
          </Button>
        </FormActionBar>
      )}
      <Container className={styles.root}>
        <Typography variant="h6" gutterBottom>
          Booking Window
        </Typography>
        <Grid container item md={8} xs={12}>
          <Box mb={4}>
            <Typography variant="body1" color="textSecondary">
              Determine how far in advance you want to your students to be able
              book with you. This is applied to all individual offerings. For
              example, if you set your booking window to “30 days,” a student is
              able to book 30 days in advance.
            </Typography>
          </Box>
        </Grid>
        <Grid container item md={4} xs={12}>
          <Box mb={2} width="100%">
            <FormControl variant="outlined">
              <TextField
                select
                defaultValue={data?.coach?.bookingLimit}
                value={bookingLimitValue}
                variant="outlined"
                onChange={handleLimitChange}
              >
                {data?.bookingLimitOptions.map(blo => (
                  <MenuItem key={blo.value} value={blo.value}>
                    {blo.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </Grid>
      </Container>
      <Container className={styles.root}>
        <Typography variant="h6" gutterBottom>
          Booking Cutoff
        </Typography>
        <Grid container item md={8} xs={12}>
          <Box mb={4}>
            <Typography variant="body1" color="textSecondary">
              Determine the minimum lead time you wish to allow students to
              schedule sessions with you. This is applied to all individual
              offerings. For example, if you set your booking cutoff to “4
              hours”, a student can book with you up until 4 hours before your
              next available session.
            </Typography>
          </Box>
        </Grid>
        <Grid container item md={4} xs={12}>
          <Box mb={2} width="100%">
            <FormControl variant="outlined">
              <TextField
                select
                defaultValue={data?.coach?.bookingCutoff}
                value={bookingCutoffValue?.value}
                variant="outlined"
                onChange={handleCutoffChange}
              >
                {data?.bookingCutoffOptions.map(blo => (
                  <MenuItem key={blo.value} value={blo.value}>
                    {blo.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </Grid>
      </Container>
      {isStudentCancellationEnabled && (
        <Container className={styles.root}>
          <Typography variant="h6" gutterBottom>
            Student Cancellations
          </Typography>
          <Grid container item md={8} xs={12}>
            <Box mb={4}>
              <Typography variant="body1" color="textSecondary">
                Set your preferences for cancellations to determine if
                individual bookings can be cancelled by your students.
              </Typography>
            </Box>
          </Grid>
          <Box mb={2} width="100%">
            <FormControl>
              <Grid item lg={7} md={8} xs={12}>
                <SettingsBlock className={styles.settingsDescription}>
                  <FormControlLabel
                    checked={checked}
                    control={<Radio color="primary" />}
                    onChange={handleRadioChange(true)}
                    label={
                      <Typography
                        variant="subtitle1"
                        className={styles.settingsCaption}
                      >
                        Allow Cancellations (Default)
                      </Typography>
                    }
                  />
                  <Typography
                    variant="body2"
                    display="block"
                    className={styles.settingsText}
                  >
                    Allow students to cancel their individual bookings with you.
                  </Typography>
                </SettingsBlock>
              </Grid>
              <Grid item lg={7} md={8} xs={12}>
                <SettingsBlock className={styles.settingsDescription}>
                  <FormControlLabel
                    checked={!checked}
                    control={<Radio color="primary" />}
                    onChange={handleRadioChange(false)}
                    label={
                      <Typography
                        variant="subtitle1"
                        className={styles.settingsCaption}
                      >
                        Prevent Cancellations
                      </Typography>
                    }
                  />
                  <Typography variant="body2" className={styles.settingsText}>
                    Prevents students from cancelling their individual bookings
                    with you.
                  </Typography>
                </SettingsBlock>
              </Grid>
            </FormControl>
          </Box>
        </Container>
      )}
    </>
  )
}

export default BookingLimitsPage
