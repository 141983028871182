import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined'
import { ReactComponent as CalendarAddIcon } from 'images/icons/calendar-add.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import AddToCalendarButton from 'components/add-to-calendar-button'
import React from 'react'
import { toLuxonFromIso } from 'utils/dateUtils'
import { PGA_COM_HOST } from 'env'
import RouterLinkButton from 'components/router-link-button'

const makeCalendarEvent = ({
  id,
  slug,
  startDateTime,
  endDateTime,
  title,
  description,
  coachFacility,
}) => ({
  uid: `coach-tools.lesson.${id}.pga.com`,
  startDateTime: toLuxonFromIso(startDateTime),
  endDateTime: toLuxonFromIso(endDateTime),
  title,
  description,
  location: coachFacility.name,
  url: `${PGA_COM_HOST}/things-to-do/events/${slug}`,
})

const Actions = ({ groupEvent }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'))

  return (
    <Box mt={5} width="100%">
      <List>
        <ListItem
          disableGutters={isMobile}
          secondaryAction={
            <AddToCalendarButton
              event={makeCalendarEvent(groupEvent)}
              style={{ backgroundColor: '#EBF0F2' }}
              size="small"
              variant="contained"
            >
              Add
            </AddToCalendarButton>
          }
        >
          <ListItemIcon>
            <CalendarAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add to Calendar" />
        </ListItem>
        <ListItem
          disableGutters={isMobile}
          secondaryAction={
            <RouterLinkButton
              variant="contained"
              size="small"
              style={{ backgroundColor: '#EBF0F2' }}
              to={`/club/messages/${groupEvent.coach.externalId}/new-conversation`}
            >
              Message
            </RouterLinkButton>
          }
        >
          <ListItemIcon>
            <MessageOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Message the Coach" />
        </ListItem>
      </List>
    </Box>
  )
}

export default Actions
