import React, { useState } from 'react'
import { shuffle } from 'lodash'
import { useFormContext, Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ImageSelectionInput from 'components/image-selection-input'
import { CloudinaryImageUpload, largeUploadPreset } from 'lib/cloudinary'

const featuredImageUrls = shuffle([
  'https://images.unsplash.com/photo-1584304474743-a04b149f4210?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjc4MTc&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1560674498-ec05053cbf14?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyMzQ&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1465195079111-667efe5ba139?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyNTE&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1602646395218-8739d82c619a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyNzI&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1602991174407-a015b35a7b00?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyODc&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1526166997988-ce48e2f004af?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyOTg&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1591491653056-4313c0e2f379?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzMTA&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1584837140804-599306fb37f9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzMjE&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1520389013787-910f801d75f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzMzE&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1562204320-c7f5f2a04156?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzNDI&ixlib=rb-1.2.1&q=80&w=400',
  'https://images.unsplash.com/photo-1595827432953-7161e19e303e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzNTM&ixlib=rb-1.2.1&q=80&w=400',
])

const useStyles = makeStyles(theme => ({
  image: {
    margin: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    width: '255px',
    height: '200px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
}))

const FeaturedImage = () => {
  const [editingImage, setEditingImage] = useState(false)
  const { errors, control, watch } = useFormContext()

  const featuredImg = watch('featuredImageUrl')

  const classes = useStyles()
  return (
    <Controller
      name="featuredImageUrl"
      control={control}
      defaultValue={null}
      render={({ onChange, value }, _) => (
        <>
          {editingImage ? (
            <>
              <ImageSelectionInput
                imageUrls={
                  value && !featuredImageUrls.includes(value)
                    ? [value, ...featuredImageUrls]
                    : featuredImageUrls
                }
                value={value}
                onChange={imageUrl => {
                  setEditingImage(!editingImage)
                  onChange(imageUrl)
                }}
              />
              {errors.featuredImageUrl ? (
                <Typography variant="caption" color="error">
                  Please select an image to continue
                </Typography>
              ) : null}
              <Box
                m={5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <CloudinaryImageUpload
                  render={({ openUploadDialog, uploadError }) => (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        type="button"
                        onClick={() => openUploadDialog()}
                      >
                        Upload Image
                      </Button>
                      {uploadError != null ? (
                        <Box my={1}>
                          <Typography
                            variant="body1"
                            color="error"
                            align="center"
                          >
                            {uploadError}
                          </Typography>
                        </Box>
                      ) : null}
                    </>
                  )}
                  onUpload={uploadedImageUrl => {
                    featuredImageUrls.unshift(uploadedImageUrl)
                    onChange(uploadedImageUrl)
                  }}
                  options={{
                    upload_preset: largeUploadPreset,
                    cropping_aspect_ratio: 4 / 3,
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box
                className={classes.image}
                style={{ backgroundImage: `url(${featuredImg})` }}
              />
              <Button onClick={() => setEditingImage(!editingImage)}>
                Change image
              </Button>
            </>
          )}
        </>
      )}
    />
  )
}

export default FeaturedImage
