import React, { useState, useEffect, useMemo } from 'react'
import CheckIcon from '@material-ui/icons/Check'
import SearchBar from 'pages/pga-coach/students/search-bar'
import { makeStyles } from '@material-ui/styles'
import { useIsTablet } from 'lib/hooks'
import StudentItemView from './student-item-view'
import {
  sortByFullName,
  sortByUpcoming,
  sortByCoach,
} from 'lib/student-list/utils'

import { parseStudents } from '../utils'
import EnhancedTable from 'components/tables/enhanced-table'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import EmptyStudents from 'components/empty-page-state'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TableContainer,
  Popper,
  Grow,
  ClickAwayListener,
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

export const useStyles = makeStyles(theme => ({
  containerStyles: {
    boxSizing: 'border-box',
    padding: '0 36px 1rem',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
      padding: '0 8px 1rem',
    },
  },
  filterTitle: {
    backgroundColor: 'rgba(0, 35, 75, 0.04)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    marginBottom: '1em',
    width: '100%',
  },
  filterButton: {
    textTransform: 'none',
    fontWeight: 400,
    height: '56px',
    width: '115px',
    padding: 0,
    justifyContent: 'space-around',
  },
}))

const StudentListView = ({ students, refetchStudents, ...props }) => {
  const classes = useStyles()
  const isTablet = useIsTablet()

  const coaches =
    (students.length > 0 &&
      students
        ?.map(student => student.coaches.filter(coach => coach !== null))
        .flat()) ||
    []

  const uniqueCoaches = coaches.filter(
    (coach, index, self) =>
      index === self.findIndex(c => c.id === coach.id && c.name === coach.name),
  )

  const uniqueFacilities =
    (students.length > 0 &&
      students
        ?.map(student => student.facilities)
        .flat()
        .filter(
          (facility, index, self) =>
            index ===
            self.findIndex(
              f => f.id === facility.id && f.name === facility.name,
            ),
        )) ||
    []

  const searchableItems = useMemo(() => parseStudents(students), [students])
  const [anchorEl, setAnchorEl] = useState(null)

  const [filteredStudents, setFilteredStudents] = useState(searchableItems)
  const [searchTerm, setSearchTerm] = useState('')

  const [filteredLength, setFilteredLength] = useState(searchableItems.length)
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const [selectedCoach, setSelectedCoach] = useState('all')
  const [selectedLocation, setSelectedLocation] = useState('all')
  const [ageGroup, setAgeGroup] = useState('all')

  // returns true/false for if student is in selected age group
  const isInAgeGroup = ({ isMinor }, desiredGroup) => {
    if (desiredGroup === 'all') {
      return true
    } else if (isMinor && desiredGroup === 'junior') {
      return true
    } else if (!isMinor && desiredGroup === 'adult') {
      return true
    } else {
      return false
    }
  }
  // returns true/false for if student has this coach in their Coach array
  const hasCoach = ({ coaches }, desiredCoach) => {
    if (desiredCoach === 'all') {
      return true
    } else if (coaches.some(coach => coach.id === desiredCoach)) {
      return true
    } else {
      return false
    }
  }
  // returns true/false for if student has this location in their record
  const hasLocation = ({ facilities }, desiredLocation) => {
    if (desiredLocation === 'all') {
      return true
      // if the student has a facility that matches the selected location, return true
    } else if (facilities.some(facility => facility.id === desiredLocation)) {
      return true
    } else {
      return false
    }
  }

  // true if search term is empty - '' - or contained by name; false otherwise
  const nameMatchesSearchToken = ({ firstName, lastName }, token) => {
    const fullName = `${firstName} ${lastName}`.toLowerCase().trim()
    const term = token.toLowerCase().trim()

    return fullName.includes(term)
  }

  // inside the call to searchableItems.filter, return cond && otherCond && ... for filtering purposes
  //
  // In doing this we can compose filters.
  //
  // Make sure to update the effect dependencies list.
  useEffect(() => {
    const filtered =
      searchableItems.length > 0 &&
      searchableItems.filter(item => {
        return (
          nameMatchesSearchToken(item, searchTerm) &&
          isInAgeGroup(item, ageGroup) &&
          hasCoach(item, selectedCoach) &&
          hasLocation(item, selectedLocation)
        )
      })
    setFilteredStudents(filtered)
  }, [searchTerm, searchableItems, ageGroup, selectedCoach, selectedLocation])

  const handleClose = event => {
    setAnchorEl(null)
    setFilterMenuOpen(false)
  }

  const filterBoxes = (
    <Grid
      container
      direction={isTablet ? 'column' : 'row'}
      justifyContent={isTablet ? 'center' : 'flex-start'}
      alignItems={isTablet ? 'center' : 'flex-start'}
    >
      {isTablet && (
        <Typography
          component="h3"
          variant="h6"
          align="center"
          className={classes.filterTitle}
        >
          {filteredLength} Selected Contacts
        </Typography>
      )}
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Age Group</FormLabel>
          <Divider style={{ margin: '8px 8px 0 0' }} />
          <RadioGroup
            aria-label="age-group"
            name="age-group"
            value={ageGroup}
            onChange={event => {
              setAgeGroup(event.target.value)
              setFilteredLength(
                searchableItems.filter(item => {
                  return (
                    nameMatchesSearchToken(item, searchTerm) &&
                    isInAgeGroup(item, event.target.value) &&
                    hasCoach(item, selectedCoach) &&
                    hasLocation(item, selectedLocation)
                  )
                }).length,
              )
            }}
          >
            <FormControlLabel value="adult" control={<Radio />} label="Adult" />
            <FormControlLabel
              value="junior"
              control={<Radio />}
              label="Junior"
            />
            <FormControlLabel
              checked={ageGroup === 'all'}
              value="all"
              control={<Radio />}
              label="All Ages"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Divider orientation="vertical" flexItem style={{ margin: '.5rem' }} />
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Coach</FormLabel>
          <Divider style={{ margin: '8px 8px 0 0' }} />
          <RadioGroup
            aria-label="coach-filter"
            name="coach-filter"
            value={selectedCoach}
            onChange={event => {
              setSelectedCoach(event.target.value)
              setFilteredLength(
                searchableItems.filter(item => {
                  return (
                    nameMatchesSearchToken(item, searchTerm) &&
                    isInAgeGroup(item, event.target.value) &&
                    hasCoach(item, selectedCoach) &&
                    hasLocation(item, selectedLocation)
                  )
                }).length,
              )
            }}
          >
            {uniqueCoaches?.map(coach => (
              <FormControlLabel
                key={coach.id}
                value={coach.id}
                control={<Radio />}
                label={coach.name}
              />
            ))}
            <FormControlLabel
              checked={selectedCoach === 'all'}
              value="all"
              control={<Radio />}
              label="All Coaches"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Divider orientation="vertical" flexItem variant="middle" />

      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">Location</FormLabel>
          <Divider style={{ margin: '.5rem' }} />
          <RadioGroup
            aria-label="coach-filter"
            name="coach-filter"
            value={selectedLocation}
            onChange={event => {
              setSelectedLocation(event.target.value)
              setFilteredLength(
                searchableItems.filter(item => {
                  return (
                    nameMatchesSearchToken(item, searchTerm) &&
                    isInAgeGroup(item, event.target.value) &&
                    hasCoach(item, selectedCoach) &&
                    hasLocation(item, selectedLocation)
                  )
                }).length,
              )
            }}
          >
            {uniqueFacilities?.map(facility => (
              <FormControlLabel
                key={facility.id}
                value={facility.id}
                control={<Radio />}
                label={facility.name}
              />
            ))}
            <FormControlLabel
              checked={selectedLocation === 'all'}
              value="all"
              control={<Radio />}
              label="All Locations"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )

  const filterButtons = isTablet ? (
    <Box>
      <Button
        color="primary"
        onClick={() => setFilterMenuOpen(!filterMenuOpen)}
      >
        Filters
      </Button>
      <Drawer
        anchor="bottom"
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
      >
        <Grid
          justifyContent="space-between"
          direction="row"
          container
          alignItems="center"
          style={{ padding: '8px 8px' }}
        >
          <Grid item>
            <Button color="primary" onClick={() => setFilterMenuOpen(false)}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Typography component="h3" variant="h6">
              Filters
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => {
                setAgeGroup(ageGroup)
                setFilterMenuOpen(false)
              }}
              endIcon={<CheckIcon />}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
        <Container style={{ padding: 0 }}>
          <Box>{filterBoxes}</Box>
        </Container>
      </Drawer>
    </Box>
  ) : (
    <Box style={{ position: 'relative', display: 'inline-block' }}>
      <Button
        id="student-list-filters"
        aria-controls={filterMenuOpen ? 'student-filters-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={filterMenuOpen ? 'true' : undefined}
        variant="outlined"
        color="default"
        size="medium"
        disableElevation
        onClick={e => {
          setFilterMenuOpen(!filterMenuOpen)
          setAnchorEl(e.currentTarget)
        }}
        endIcon={<KeyboardArrowDown />}
        disableRipple
        className={classes.filterButton}
      >
        Filters
      </Button>
      <Popper
        id="student-filters-menu"
        open={filterMenuOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 50000 }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Card
                variant="outlined"
                aria-labelledby="demo-customized-button"
                style={{
                  padding: '8px',
                  minWidth: '200px',
                  opacity: '100%',
                }}
                open={filterMenuOpen}
              >
                <CardContent style={{ padding: '0 !important' }}>
                  {filterBoxes}
                </CardContent>
                <CardActions
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAgeGroup(ageGroup || 'all')
                      setFilterMenuOpen(false)
                    }}
                  >
                    Apply
                  </Button>
                </CardActions>
              </Card>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </Box>
  )

  const columns = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      width: isTablet ? '60%' : '30%',
      mobile: true,
      sortable: true,
      sortComparator: sortByFullName,
    },
    {
      id: 'upcoming',
      label: 'Next Upcoming Session',
      align: 'left',
      width: '30%',
      mobile: false,
      sortable: true,
      sortComparator: sortByUpcoming,
    },
    {
      id: 'coach',
      label: 'Coach',
      align: 'left',
      width: '30%',
      mobile: false,
      sortable: true,
      sortComparator: sortByCoach,
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      width: '10%',
      mobile: true,
      sortable: false,
    },
  ]

  const rows = filteredStudents?.map(student => (
    <StudentItemView
      student={student}
      refetchStudents={refetchStudents}
      key={student.id}
    />
  ))

  return (
    <TableContainer className={classes.containerStyles}>
      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <Grid item xs={9}>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder="Search Students"
            style={{ width: '100%', margin: '20px' }}
            data-testid="student-list-search"
          />
        </Grid>
        <Grid item xs={3}>
          {filterButtons}
        </Grid>
      </Grid>
      <EnhancedTable
        rows={rows}
        columns={columns}
        label="Students"
        numberOfRows={25}
        emptyState={() => (
          <EmptyStudents
            title="Students"
            caption="No matching students."
            IconComp={() => (
              <PermContactCalendarOutlinedIcon fontSize="large" />
            )}
            removeButton
          />
        )}
      />
    </TableContainer>
  )
}
export default StudentListView
