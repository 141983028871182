import { CoachingCenterWaiverContent } from 'components/waivers/cc-waiver'
import { WaiverWrapper } from '../styles'
import React from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_PRACTICE_SESSION_FOR_STUDENT } from '../queries'
import BookingConfirmationSkeleton from 'components/skeletons/booking-confirmation-skeleton'
import { useSnackbar } from 'notistack'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import { gtmEvent } from 'lib/gtm'
import CoachingCenterAgreeSection from 'components/waivers/cc-agree-section'

const ReservationsWaiver = ({
  form,
  stripeToken,
  academy,
  setIsComplete,
  setCardBrand,
  setLastDigits,
  setSpaceName,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const [createReservation, { loading }] = useMutation(
    CREATE_PRACTICE_SESSION_FOR_STUDENT,
  )
  const practiceSessionTypeId = academy?.practiceSessionTypes?.[0]?.id
  const { startDateTime, studentId, memberNumber } = qs.parse(location.search)

  const onSubmit = async formValues => {
    return createReservation({
      variables: {
        input: {
          practiceSessionTypeId,
          startDateTime,
          stripeToken: stripeToken?.id,
          studentId: studentId,
          studentMemberNumber: memberNumber,
        },
      },
    })
      .then(({ data }) => {
        const {
          success,
          message,
          practiceSession,
        } = data.createPracticeSessionForStudent

        if (success) {
          const { spaceName } = practiceSession.reservation
          enqueueSnackbar('Bay has been reserved.', {
            variant: 'success',
          })
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'create-bay-reservation',
            formAction: 'clicked-create-bay-reservation-button',
          })
          setLastDigits(stripeToken?.card.last4)
          setCardBrand(stripeToken?.card.brand)
          setSpaceName(spaceName)
          setIsComplete(true)
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['bay-reservation', 'form-submit'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
  }

  const handleClick = async () => {
    await form.handleSubmit(onSubmit)()
  }

  if (loading) {
    return <BookingConfirmationSkeleton />
  }

  return (
    <WaiverWrapper>
      <CoachingCenterWaiverContent showHeader={false} />
      <CoachingCenterAgreeSection form={form} handleClick={handleClick} />
    </WaiverWrapper>
  )
}

export default ReservationsWaiver
