import { omit, isEmpty, get } from 'lodash'
import omitDeep from 'omit-deep'
import { gtmEvent } from 'lib/gtm'

const filterNullSocialRefs = social =>
  social?.length && social.filter(el => el && el.reference)
const formatPhoneNumber = num => {
  if (num) {
    const cleanedNum = num.replace(/\D/g, '')
    return `+1${cleanedNum}`
  }
  return null
}

const TYPENAME = '__typename'

const logError = (error, newProfile) => {
  // Send Error to Raygun
  window.rg4js &&
    window.rg4js('send', {
      error: error,
      tags: ['coach-profile'],
    })

  // GTM Tracking Code
  gtmEvent({
    event: 'formSubmit',
    formCategory: 'profile',
    formAction: newProfile ? 'create-profile' : 'update-profile',
    formStatus: 'error',
    errorDescription: error.message,
  })
}

export const submissionHandler = (
  values,
  newProfile,
  updateOrCreateProfile,
  coachProfileId,
  setApiResponseObject,
  setOpenApiResponseDialog,
) => {
  const valuesToSubmit = {
    ...values,
    phoneExt: values.phoneExt + '',
    phoneNumber: formatPhoneNumber(values.phoneNumber),
    mobileNumber: formatPhoneNumber(values.mobileNumber),
    socialJsonData: filterNullSocialRefs(values?.socialJsonData) || [],
    numberOfStudents: values.numberOfStudents
      ? parseInt(values.numberOfStudents, 10)
      : null,
    numberOfFollowers: values.numberOfFollowers
      ? parseInt(values.numberOfFollowers, 10)
      : null,
    toolJsonData: values?.toolJsonData?.filter(tool => tool !== ''),
    facilities:
      values?.facilities === undefined || values?.facilities === null
        ? null
        : values.facilities.map(facility => ({
          ...facility,
          phoneNumber: facility.phoneNumber,
          customData: {
            ...facility.customData,
            offerings: get(facility, ['customData', 'offerings'], []).map(
              offering => omit(offering, ['id']),
            ),
          },
        })),
    introductionVideoUrl: values.introductionVideoUrl || null,
  }
  let apiResponsePromise
  if (newProfile) {
    // create profile
    delete valuesToSubmit.status
    delete valuesToSubmit.coachProfileId
    delete valuesToSubmit.internalUserId // think this is only for admin so dont need for now

    // return promise
    apiResponsePromise = updateOrCreateProfile({
      variables: { params: { ...valuesToSubmit } },
    })
  } else {
    const withoutTypenames = {
      ...omitDeep(valuesToSubmit, [TYPENAME]),
      facilities: valuesToSubmit.facilities.map(facility => ({
        ...facility,
        website: isEmpty(valuesToSubmit.website)
          ? null
          : valuesToSubmit.website,
        customData: {
          ...facility.customData,
          offerings: get(facility, ['customData', 'offerings'], []).map(
            offering => omit(offering, ['id']),
          ),
        },
      })),
    }
    delete withoutTypenames.customSlug
    delete withoutTypenames.memberId
    delete withoutTypenames.coachProfileId
    delete withoutTypenames.internalUserId
    delete withoutTypenames.memberType

    // return promise
    apiResponsePromise = updateOrCreateProfile({
      variables: {
        coachProfileId,
        params: { ...withoutTypenames },
      },
    })
  }
  // when API response resolves, set the response object and open the dialog box
  return apiResponsePromise.then(
    res => {
      // Successful create
      setApiResponseObject(
        res?.data.CoachProfileCreate || res?.data.CoachProfileUpdate,
      )
      // GTM Tracking Code
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'profile',
        formAction: newProfile ? 'create-profile' : 'update-profile',
        formStatus: 'success',
      })
      setOpenApiResponseDialog(true)
    },
    error => {
      logError(error, newProfile)
      // Error during create
      setApiResponseObject(error)
      setOpenApiResponseDialog(true)
    },
  )
}
