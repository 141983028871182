import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircle from '@material-ui/icons/CheckCircle'

const defaultStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer',
  },
  imageContainerSelected: {
    borderColor: theme.palette.primary.main,
  },
  image: {
    boxSizing: 'border-box',
    width: '270px',
    height: '212px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    marginBottom: theme.spacing(1),
  },
  imageIcon: {
    color: theme.palette.grey[300],
  },
  imageIconSelected: {
    color: theme.palette.primary.main,
  },
}))

const ImageSelectionInput = ({ imageUrls, value, onChange, classes = {} }) => {
  const defaultClasses = defaultStyles()
  return (
    <Box className={`${defaultClasses.root} ${classes.root ?? ''}`}>
      {imageUrls.map((imageUrl, index) => {
        const isSelected = value && imageUrl.includes(value)
        return (
          <Box
            key={imageUrl}
            onClick={() => onChange(imageUrl)}
            className={[
              defaultClasses.imageContainer,
              classes.imageContainer ?? '',
              isSelected ? defaultClasses.imageContainerSelected : '',
            ].join(' ')}
            data-cy={`image-selection-input-${index}`}
          >
            <Box
              className={`
                ${defaultClasses.image} ${classes.image ?? ''}
                ${
                  value === imageUrl
                    ? `${
                      defaultClasses.selectedImage
                    } ${classes.selectedImage ?? ''}`
                    : ''
                }
              `}
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              {/* The purpose of this tag is to pre-request the image file being used as a background image above. */}
              <img src={imageUrl} style={{ display: 'none' }} alt="" />
            </Box>
            <CheckCircle
              className={
                isSelected
                  ? defaultClasses.imageIconSelected
                  : defaultClasses.imageIcon
              }
              fontSize="small"
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default ImageSelectionInput
