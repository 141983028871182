import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const LoadingView = () => (
  <Card variant="outlined">
    <CardContent>
      <LinearProgress color="primary" />
    </CardContent>
  </Card>
)

export default LoadingView
