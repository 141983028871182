import { gql } from '@apollo/client'

import GolferProfileFragment from 'fragments/student/golferProfile'

export const GET_STUDENT_QUERY = gql`
  ${GolferProfileFragment}
  query Student($id: ID!) {
    student(id: $id) {
      id
      isMinor
      golferProfile {
        ...GolferProfileAttributes
      }
    }
  }
`

export const UPSERT_GOLFER_PROFILE = gql`
  ${GolferProfileFragment}
  mutation upsertGolferProfile($studentId: ID!, $input: GolferProfileInput!) {
    upsertGolferProfile(studentId: $studentId, input: $input) {
      golferProfile {
        ...GolferProfileAttributes
      }
    }
  }
`
