import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialDialog from '@material-ui/core/Dialog'
import MaterialDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '0px',

    [theme.breakpoints.down('xs')]: {
      top: '-4px',
      right: '-4px',
    },

    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',

      [theme.breakpoints.down('xs')]: {
        width: '20px',
        height: '20px',
      },
    },
  },
}))

const Dialog = props => {
  const {
    children,
    trigger,
    isOpen,
    showCloseButton,
    fullWidth,
    fullScreen,
    maxWidth,
    onClose,
    beforeOpen,
    hideBackdrop,
    classes,
  } = props

  const styles = useStyles()

  const [_isOpen, _setIsOpen] = useState(false)
  const openDialog = async () => {
    if (beforeOpen) {
      const proceed = await beforeOpen()
      if (!proceed) return
    }
    _setIsOpen(true)
  }
  const closeDialog = () => {
    _setIsOpen(false)
    if (onClose != null) {
      onClose()
    }
  }

  const DialogCloseButton = () => (
    <IconButton
      aria-label="close"
      className={styles.closeButton}
      onClick={closeDialog}
    >
      <CloseIcon />
    </IconButton>
  )

  // control open state either from external prop or internal state
  // (prefer external prop, if provided)
  const open = isOpen != null ? isOpen : _isOpen

  return (
    <>
      {trigger && <trigger.type {...trigger.props} onClick={openDialog} />}
      <MaterialDialog
        open={open}
        onClose={closeDialog}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        hideBackdrop={hideBackdrop}
        classes={classes}
      >
        <>
          {showCloseButton && <DialogCloseButton onClick={closeDialog} />}
          {children({ closeDialog })}
        </>
      </MaterialDialog>
    </>
  )
}

Dialog.propTypes = {
  children: PropTypes.func.isRequired,
  trigger: PropTypes.element,
  isOpen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
  beforeOpen: PropTypes.func,
  hideBackdrop: PropTypes.bool,
  classes: PropTypes.object,
}

Dialog.defaultProps = {
  fullWidth: false,
  showCloseButton: false,
  maxWidth: 'sm',
}

export default Dialog

const useActionStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'flex-start',
  },
}))

export function DialogActions(props) {
  const classes = useActionStyles()

  return <MaterialDialogActions {...props} className={classes.actions} />
}
