import React from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  EmailIcon,
} from 'react-share'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CopyTextButton from 'components/copy-text-button'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import XShareButton from 'components/x-share-button'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '14px',
  },
  menuRow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#E0E0E0',
      width: '100%',
    },
  },
  menuFont: {
    fontSize: '14px',
    fontWeight: '500',
  },
  shareRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px',
  },
  shareMenuFont: {
    fontSize: '14px',
    fontWeight: 500,
    flexGrow: 1,
  },
  iconWrapper: {
    marginRight: '10px',
  },
})

const ShareEventPopper = ({ isOpen, handleClose, anchorEl, url, title }) => {
  const classes = useStyles()
  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      transition
      style={{ zIndex: 1500 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classes.root}>
              <FacebookShareButton
                url={url}
                quote={title}
                className={classes.shareRow}
              >
                <FacebookIcon size={32} round className={classes.iconWrapper} />
                <Typography variant="body1" className={classes.shareMenuFont}>
                  Share on Facebook
                </Typography>
              </FacebookShareButton>
              <XShareButton
                url={url}
                title={title}
                wrapperClassname={classes.shareRow}
                buttonClassname={classes.iconWrapper}
                size={32}
              >
                <Typography variant="body1" className={classes.shareMenuFont}>
                  Share on X
                </Typography>
              </XShareButton>
              <Button
                className={classes.shareRow}
                classes={{
                  label: classes.copyButton,
                }}
              >
                <FileCopyOutlinedIcon className={classes.iconWrapper} />
                <CopyTextButton
                  value={url}
                  component={Typography}
                  className={classes.shareMenuFont}
                  style={{ textTransform: 'none' }}
                >
                  Copy Link
                </CopyTextButton>
              </Button>
              <EmailShareButton
                url={url}
                subject={title}
                className={classes.shareRow}
              >
                <EmailIcon size={32} round className={classes.iconWrapper} />
                <Typography variant="body1" className={classes.shareMenuFont}>
                  Email
                </Typography>
              </EmailShareButton>
            </Paper>
          </ClickAwayListener>
        </Fade>
      )}
    </Popper>
  )
}

export default ShareEventPopper
