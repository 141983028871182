import { makeStyles } from '@mui/styles'
import { useAuth } from 'lib/auth'
import consumerQRCode from 'images/onelinkto_mypga.png'
import coachQRCode from 'images/onelinkto_pga-coach.png'
import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '4rem',
    borderRadius: '4px',
    background:
      'radial-gradient(97.57% 53.87% at 70.36% 50.12%, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(33, 150, 243, 0.15)',
  },
  text: {
    textAlign: 'start',
    color: 'var(--coach-primary-main, #00234B)',
  },
}))

function SwingsVideoPlaceholder() {
  const { isCoach } = useAuth()
  const classes = useStyles()

  return (
    <Box py={2} px={4} className={classes.container}>
      <Grid
        container
        spacing="2"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={6}>
          <Typography variant="h5" className={classes.text}>
            <strong>New Shared Video!</strong> View it in the{' '}
            {isCoach ? 'PGA Coach' : 'MyPGA'} app
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {isCoach ? (
            <img src={coachQRCode} alt="QR Code for PGA Coach App" />
          ) : (
            <img src={consumerQRCode} alt="QR Code for MyPGA App" />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(SwingsVideoPlaceholder)
