import { Box, Container } from '@material-ui/core'
import React from 'react'
import { get } from 'lodash'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useQuery } from '@apollo/client'
import AccountDropdown from 'components/account-dropdown'
import Error from 'components/error'
import PGALogo from 'images/pga-logo-navy.png'
import { withProtectedRoute, useAuth, roles } from 'lib/auth'
import PgaCoach from './tiles/pga-coach'
import JrLeague from './tiles/jr-league'
import FamilyCup from './tiles/family-cup'
import PGACoachBusinessInsights from './tiles/pga-coach-business-insights'
import CoachTraining from './tiles/coach-training'
import secureLink from 'utils/secureLink'
import useStyles from './landing.styles'
import { ProfilesQuery } from './query'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export function LandingPage() {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const { user } = useAuth()
  const [firstName, lastName] = user.name.split(' ')
  const { data, loading, error } = useQuery(ProfilesQuery)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />

  const coachProfile = get(data, 'CoachProfiles.profiles[0]', {})
  const { profilePhoto, displayName } = coachProfile

  return (
    <Box display="flex">
      <AppBar position="fixed" className={classes.appBar}>
        <Container>
          <Toolbar>
            <img src={PGALogo} alt="PGA logo" className={classes.logo} />
            <div className={classes.filler} />
            <AccountDropdown />
          </Toolbar>
        </Container>
      </AppBar>
      <Container className={classes.landingBox}>
        <Box m={4}>
          <Avatar
            aria-label="coach"
            src={secureLink(profilePhoto)}
            className={classes.avatarStyles}
          >
            {!profilePhoto &&
              `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
          </Avatar>
        </Box>
        <Typography variant="h4" className={classes.coachName}>
          Welcome {displayName || user.name}
        </Typography>
        <Typography variant="h6" className={classes.ctaText}>
          Choose an app below to get started
        </Typography>
        <Grid container justifyContent="center">
          <Grid
            container
            item
            md={8}
            direction="row"
            spacing={isMobile ? 2 : 3}
          >
            <Grid item md={6} xs={12}>
              <PgaCoach />
            </Grid>
            <Grid item md={6} xs={12}>
              <JrLeague />
            </Grid>
            <Grid item md={6} xs={12}>
              <FamilyCup />
            </Grid>
            <Grid item md={6} xs={12}>
              <CoachTraining />
            </Grid>
            <Grid item md={6} xs={12}>
              <PGACoachBusinessInsights />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default withProtectedRoute(LandingPage, roles.COACH)
