import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  starButton: {
    padding: '5px 5px 0px 5px',
  },
  contactName: {
    marginLeft: '8px',
    marginTop: '12px',
  },
}))

export default useStyles
