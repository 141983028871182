import React, { useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import useStyles from './group-event-registration-form.styles'
import { branchOfServiceOptions, militaryStatusOptions } from './constants'
import { FormRadioGroup } from 'components/form-util'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import { range } from 'lodash'

const MilitaryService = () => {
  const { control, errors, watch } = useFormContext()
  const classes = useStyles()
  const serviceYearStartWatch = watch('pgaHopeProfile.serviceYearStart', false)
  const [serviceYearEndRangeStart, setServiceYearEndRangeStart] = useState(1900)

  useEffect(() => {
    if (serviceYearStartWatch) {
      setServiceYearEndRangeStart(serviceYearStartWatch)
    }
  }, [serviceYearStartWatch])

  const militaryStatusError = errors?.pgaHopeProfile?.militaryStatus

  return (
    <>
      <Typography variant="h4" component="h1" style={{ marginBottom: '32px' }}>
        Additional Information
      </Typography>
      <Typography variant="h5" className={classes.formSectionHeader}>
        Service Background
      </Typography>
      <Box>
        <Typography
          variant="body1"
          style={{
            marginBottom: '16px',
            color: militaryStatusError ? 'red' : 'inherit',
          }}
        >
          What is your current status? *
        </Typography>
        <Box
          my={2}
          style={{ paddingLeft: !militaryStatusError ? '1rem' : '0px' }}
        >
          <Controller
            as={
              <FormRadioGroup error={!!militaryStatusError}>
                <Grid
                  container
                  className={militaryStatusError ? classes.error : ''}
                >
                  <Grid xs={6} sm={12} item className={classes.statusesWrapper}>
                    {militaryStatusOptions.slice(0, 2).map(option => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                      />
                    ))}
                  </Grid>
                  <Grid xs={6} sm={12} item className={classes.statusesWrapper}>
                    {militaryStatusOptions.slice(2, 4).map(option => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                      />
                    ))}
                  </Grid>
                </Grid>
              </FormRadioGroup>
            }
            control={control}
            name="pgaHopeProfile.militaryStatus"
          />
        </Box>
        <FormControl
          error={!!errors?.pgaHopeProfile?.serviceBranch}
          variant="outlined"
          className={classes.input}
          required
        >
          <InputLabel id="pga-hope-branch-of-service-label">
            Branch of service
          </InputLabel>
          <Controller
            as={
              <Select
                labelId="pga-hope-branch-of-service-label"
                value=""
                label="Branch of service"
              >
                {branchOfServiceOptions.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name="pgaHopeProfile.serviceBranch"
            control={control}
          />
        </FormControl>
        <Typography gutterBottom>What years did you serve?</Typography>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <FormControl
              error={!!errors?.pgaHopeProfile?.serviceYearStart}
              variant="outlined"
              className={classes.input}
              required
            >
              <InputLabel id="pga-hope-service-date-from-label">
                From
              </InputLabel>
              <Controller
                as={
                  <Select
                    labelId="pga-hope-service-date-from-label"
                    label="From"
                    value=""
                  >
                    {range(1950, new Date().getFullYear() + 1).map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="pgaHopeProfile.serviceYearStart"
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid xs={6} item>
            <FormControl
              error={!!errors?.pgaHopeProfile?.serviceYearEnd}
              variant="outlined"
              className={classes.input}
            >
              <InputLabel id="pga-hope-service-date-to-label">To</InputLabel>
              <Controller
                as={
                  <Select
                    labelId="pga-hope-service-date-to-label"
                    label="To"
                    value=""
                  >
                    {range(
                      serviceYearEndRangeStart,
                      new Date().getFullYear() + 1,
                    ).map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                    <MenuItem value="Present">Present</MenuItem>
                  </Select>
                }
                name="pgaHopeProfile.serviceYearEnd"
                control={control}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default MilitaryService
