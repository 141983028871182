import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import PartyPopperIcon from 'components/icons/party-popper-icon'
import Shell from 'components/shell/shell'
import PGAIcon from 'images/pga-logo-navy.png'
import { withProtectedRoute, roles } from 'lib/auth'
import themes, { extendTheme } from 'themes'
import ConsumerMessages from 'pages/club/messages'
import MyCoach from 'pages/club/my-coach'
import MyEvents from 'pages/club/my-events'
import MyLessonPacks from 'pages/club/my-lesson-packs'
import MyPgaBannerAd from 'components/smart-banner-ads/my-pga-banner-ad'

const ClubRoutes = () => {
  const { path } = useRouteMatch()

  const menuItems = [
    {
      icon: <PersonOutlineIcon />,
      label: 'My Coach',
      path: `${path}`,
      exact: true,
    },
    {
      icon: <ConfirmationNumberOutlinedIcon size={1} />,
      label: 'Lesson Packs',
      path: `${path}/lesson-packs`,
    },
    {
      icon: <PartyPopperIcon color="black" size={1} />,
      label: 'My Events',
      path: `${path}/events`,
    },
    {
      icon: <MessageOutlinedIcon />,
      label: 'Messaging',
      path: `${path}/messages`,
    },
    {
      icon: <SchoolOutlinedIcon />,
      label: 'Golf Tips',
      path: 'https://www.pga.com/search?searchText=golf%20tips&offset=0',
      external: true,
      annotation: <OpenInNewIcon style={{ fontSize: '16px' }} />,
    },
  ]

  return (
    <ThemeProvider theme={extendTheme(themes.pgaCoach)}>
      <Helmet>
        <title>MyPGA Clubhouse</title>
        <meta name="description" content="MyPGA Clubhouse" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MyPgaBannerAd />
        <Switch>
          <Shell
            logo={{
              src: PGAIcon,
              alt: 'PGA logo',
              width: '48px',
              mobileWidth: '48px',
            }}
            menuItems={menuItems}
            showBrandSwitcher={false}
          >
            <Route exact path={`${path}`} component={MyCoach} />
            <Route
              exact
              path={`${path}/lesson-packs`}
              component={MyLessonPacks}
            />
            <Route
              exact
              path={`${path}/events/:eventSlug?`}
              component={MyEvents}
            />
            <Route
              exact
              path={`${path}/messages/:externalId/new-conversation`}
              component={ConsumerMessages}
            />
            <Route
              exact
              path={`${path}/messages/:channelId?`}
              component={ConsumerMessages}
            />
          </Shell>
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(ClubRoutes, roles.CONSUMER)
