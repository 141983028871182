import { gql, useQuery } from '@apollo/client'

const GET_GROUP_EVENT_TEMPLATES = gql`
  query GetGroupEventTemplates {
    groupEventTemplates {
      academyRequired
      title
      caption
      tags
      description
      featuredImageUrl
      isNew
    }
  }
`

const useGroupEventTemplateOptions = () => {
  const { data = {} } = useQuery(GET_GROUP_EVENT_TEMPLATES)
  const { groupEventTemplates = [] } = data
  return groupEventTemplates
}

const GET_GROUP_EVENT_TAGS = gql`
  query GetGroupEventTags {
    groupEventTags
  }
`

const useGroupEventTagOptions = () => {
  const { data = {} } = useQuery(GET_GROUP_EVENT_TAGS)
  const { groupEventTags = [] } = data
  return groupEventTags
}

export { useGroupEventTemplateOptions, useGroupEventTagOptions }
