import { NODE_ENV } from 'env'
import { gtmEvent } from 'lib/gtm'
import Cookie from 'js-cookie'
import getDeviceType from 'lib/utils/getDeviceType'

export const setBannerCookie = () => {
  Cookie.set('appInstalledOrDismissed', 'nodisplay', {
    expires: 15,
    secure: NODE_ENV !== 'development',
    sameSite: 'Lax',
    path: '/',
  })
}

export const handleCtaClick = (setIsDisplaying, appStoreUrl, app) => () => {
  const device = getDeviceType()
  setBannerCookie()
  gtmEvent({
    event: 'click',
    clickCategory: 'app-banner',
    clickAction: 'install',
    metaData: {
      device,
      app,
    },
  })
  setIsDisplaying(false)
  window.location = appStoreUrl
}

export const handleDismiss = (setIsDisplaying, app) => () => {
  const device = getDeviceType()
  setBannerCookie()
  gtmEvent({
    event: 'click',
    clickCategory: 'app-banner',
    clickAction: 'close',
    metaData: {
      device,
      app,
    },
  })
  setIsDisplaying(false)
}
