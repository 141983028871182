import React from 'react'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'
import { Button } from '@material-ui/core/index'
import useHover from 'lib/hooks/use-hover'
import CtaArrow from './cta-arrow'

// eslint-disable-next-line object-curly-newline
const ButtonCta = React.forwardRef(
  (
    {
      className,
      href,
      children,
      dataAttrs,
      reference,
      externalLink,
      color,
      size,
      variant,
      arrow,
    },
    ref,
  ) => {
    let linkProps = {}
    const [hoverRef, isHovered] = useHover()

    if (!href) {
      linkProps = externalLink && { href: externalLink }
    }

    return externalLink ? (
      <Button
        color={color}
        size={size}
        variant={variant}
        {...linkProps}
        {...dataAttrs}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        ref={hoverRef}
      >
        {!!arrow && (
          <>
            {children}
            <CtaArrow hovered={isHovered} />
          </>
        )}
        {!arrow && <>{children}</>}
      </Button>
    ) : (
      <Link href={href || linkProps?.href || ''} ref={ref}>
        <Button
          color={color}
          size={size}
          variant={variant}
          {...dataAttrs}
          className={className}
          ref={hoverRef}
        >
          {!!arrow && (
            <>
              {children}
              <CtaArrow hovered={isHovered} />
            </>
          )}
          {!arrow && <>{children}</>}
        </Button>
      </Link>
    )
  },
)

ButtonCta.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  prefetch: PropTypes.bool,
  dataAttrs: PropTypes.object,
  externalLink: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['default', 'small']),
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  arrow: PropTypes.bool,
  reference: PropTypes.shape({
    __typename: PropTypes.string,
    slug: PropTypes.string,
  }),
}

ButtonCta.defaultProps = {
  arrow: false,
}

export default ButtonCta
