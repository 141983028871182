import React from 'react'

const fieldName = (prefix, name) => (prefix ? `${prefix}.${name}` : name)

export function RegisteredHiddenInputs({ register, prefix, fields = [] }) {
  return (
    <>
      {fields.map((field, index) => (
        <input
          key={`${prefix}-${index}`}
          type="hidden"
          name={fieldName(prefix, field)}
          ref={register()}
        />
      ))}
    </>
  )
}
