export const ResizePlugin = disableControls => slider => {
  const observer = new ResizeObserver(() => {
    if (slider.track.details.slidesLength <= 1) {
      disableControls(true)
    } else {
      disableControls(false)
    }
  })

  slider.on('created', () => {
    observer.observe(slider.container)
  })
  slider.on('destroyed', () => {
    observer.unobserve(slider.container)
  })
}
