import React from 'react'
import PropTypes from 'prop-types'
import EventsDisplay from 'pages/pga-coach/bookings/events/event-components/events-display'
import EmptyPageState from 'components/empty-page-state'
import PartyPopperIcon from 'components/icons/party-popper-icon'

const EventsDisplaySelection = ({
  events,
  history,
  refetch,
  selectedTab,
  advancePagination,
  retreatPagination,
  currentPage,
  lastPage,
}) => {
  return events.length ? (
    <EventsDisplay
      events={events}
      refetch={refetch}
      advancePagination={advancePagination}
      retreatPagination={retreatPagination}
      currentPage={currentPage}
      lastPage={lastPage}
    />
  ) : (
    <EmptyPageState
      actionFn={() => history.push('/pga-coach/bookings/events/new')}
      IconComp={() => <PartyPopperIcon />}
      title={`No ${selectedTab === 'upcoming' ? 'Upcoming' : 'Past'} Events`}
      caption="Events represent your group coach offerings. Examples include Whiskey and Wedges, Wine and Nine, Night Glow Golf, etc."
      buttonText="Create Event"
    />
  )
}
export default EventsDisplaySelection

EventsDisplaySelection.propTypes = {
  events: PropTypes.array,
  history: PropTypes.object,
  refetch: PropTypes.func,
  selectedTab: PropTypes.string,
  advancePagination: PropTypes.func,
  retreatPagination: PropTypes.func,
  currentPage: PropTypes.number,
  lastPage: PropTypes.bool,
}
