import React, { useState } from 'react'
import addYears from 'date-fns/addYears'
import isAfter from 'date-fns/isAfter'
import subMonths from 'date-fns/subMonths'
import {
  BACKGROUND_CHECK_EXPIRATION_IN_YEARS,
  BACKGROUND_CHECK_START_RENEWAL_IN_MONTHS,
} from 'env'
import * as maybe from 'lib/maybe'
import IconCard from 'components/icon-card'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import StatusChip, { statuses as requirementStatuses } from './status-chip'
import RequestBackgroundCheck from './request-background-check'

export const statuses = {
  NOT_STARTED: 'NOT_STARTED',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  CANCELLED: 'CANCELLED',
  FLAGGED: 'FLAGGED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  CLOSED: 'CLOSED',
  DUPLICATE: 'DUPLICATE',
  INCOMPLETE: 'INCOMPLETE',
  CHECKING: 'CHECKING',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  NEAR_EXPIRY: 'NEAR_EXPIRY',
}

const toChipStatus = bgStatus =>
  ({
    [statuses.NOT_STARTED]: requirementStatuses.NOT_STARTED,
    [statuses.SUBMITTED]: requirementStatuses.SUBMITTED,
    [statuses.PENDING]: requirementStatuses.PENDING,
    [statuses.PENDING_VERIFICATION]: requirementStatuses.PENDING_VERIFICATION,
    [statuses.CANCELLED]: requirementStatuses.CANCELLED,
    [statuses.FLAGGED]: requirementStatuses.FLAGGED,
    [statuses.PASSED]: requirementStatuses.PASSED,
    [statuses.FAILED]: requirementStatuses.FAILED,
    [statuses.CLOSED]: requirementStatuses.CLOSED,
    [statuses.DUPLICATE]: requirementStatuses.DUPLICATE,
    [statuses.INCOMPLETE]: requirementStatuses.INCOMPLETE,
    [statuses.CHECKING]: requirementStatuses.CHECKING,
    [statuses.ERROR]: requirementStatuses.ERROR,
    [statuses.EXPIRED]: requirementStatuses.EXPIRED,
    [statuses.NEAR_EXPIRY]: requirementStatuses.NEAR_EXPIRY,
  }[bgStatus])

const promptToManageBackgroundCheck = ({
  status,
  passedAt,
  now,
  expirationPeriodInYears,
  renewalPeriodInMonths,
}) => {
  const withinRenewalPeriod = passedAt_ => {
    const expirationDate = addYears(passedAt_, expirationPeriodInYears)
    const renewalStart = subMonths(expirationDate, renewalPeriodInMonths)

    return isAfter(now, renewalStart)
  }

  return (
    status !== requirementStatuses.SUBMITTED &&
    status !== requirementStatuses.PENDING &&
    status !== requirementStatuses.CHECKING &&
    status !== requirementStatuses.ERROR &&
    (status !== requirementStatuses.PASSED ||
      Boolean(maybe.map(passedAt, withinRenewalPeriod)))
  )
}

export default function BackgroundCheck({ status, passedAt, refetchStatus }) {
  return (
    <PureBackgroundCheck
      status={toChipStatus(status)}
      passedAt={passedAt}
      refetchStatus={refetchStatus}
      now={new Date()}
      expirationPeriodInYears={BACKGROUND_CHECK_EXPIRATION_IN_YEARS}
      renewalPeriodInMonths={BACKGROUND_CHECK_START_RENEWAL_IN_MONTHS}
    />
  )
}

export function PureBackgroundCheck({
  status,
  passedAt,
  refetchStatus,
  now,
  expirationPeriodInYears,
  renewalPeriodInMonths,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const openRequestForm = () => {
    setIsDialogOpen(prev => !prev)
    refetchStatus()
  }
  const actions = promptToManageBackgroundCheck({
    status,
    passedAt,
    now,
    expirationPeriodInYears,
    renewalPeriodInMonths,
  })
    ? [
      {
        onClick: () => openRequestForm(),
        url: null,
        text: 'REQUEST SCREENING',
        arrowIcon: true,
      },
    ]
    : []

  return (
    <>
      <IconCard
        statusChip={<StatusChip status={status} />}
        title="Background Screening"
        actions={actions}
        icon={AssignmentIndOutlinedIcon}
        testid="background-card"
      >
        As a Coach or Manager you are required to go through a Background
        Screening before participating. Please click on the below link to
        start/update the Background screening process. Background screenings are
        required every two years.
      </IconCard>
      <RequestBackgroundCheck isOpen={isDialogOpen} onClose={openRequestForm} />
    </>
  )
}
