import { withStyles } from '@material-ui/core/styles'
import { StepLabel } from '@material-ui/core'

const StyledStepLabel = withStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
  },
  active: {
    '&$alternativeLabel$label': {
      color: theme.palette.primary.main,
      marginTop: 2,
    },
  },
  label: {},
  alternativeLabel: {
    '&$label': {
      marginTop: 2,
    },
  },
}))(StepLabel)

export default StyledStepLabel
