import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const errorConfig = {
  title: 'Error',
  text: 'Sorry! An error has occurred. Please contact our support team.',
}

const successConfig = {
  title: 'Success!',
  text: 'Your review request was successfully sent.',
}

const SendSuccessModal = ({ open, setOpen, setStep, error }) => {
  const { title, text } = error ? errorConfig : successConfig
  const styles = useStyles()
  return (
    <>
      <DialogTitle>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body" style={{ fontSize: '16px' }}>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions className={styles.actionsWrapper}>
        <Button
          onClick={() => {
            setStep(0)
            setOpen(!open)
          }}
          color="secondary"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </>
  )
}

SendSuccessModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setStep: PropTypes.func,
  error: PropTypes.bool,
}

export default SendSuccessModal
