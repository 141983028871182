import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { gendersList } from 'components/coaches-profile/constants'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { InputsTwoColumns } from 'components/coaches-profile/profile.styles'
import FormControl from '@material-ui/core/FormControl'

const idSectionConfig = [
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
  },
  {
    name: 'displayName',
    label: 'Display Name',
  },
  {
    name: 'gender',
    label: 'Gender',
    menuList: gendersList,
  },
  {
    name: 'title',
    label: 'Title',
  },
]

export const AboutYou = ({ memberProfile }) => {
  const { register, errors, control } = useFormContext()

  return (
    <InputsTwoColumns>
      {idSectionConfig.map(({ name, label, menuList, required }) =>
        menuList ? (
          <FormControl
            key={name}
            error={!!errors[name]}
            className={`text-input ${errors[name] && ' error'}`}
          >
            <Controller
              name={name}
              control={control}
              render={({ value, onChange }) => (
                <TextField
                  select
                  variant="standard"
                  name={name}
                  label={label}
                  value={value || ''}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                  required={required}
                  onChange={onChange}
                >
                  {menuList.map(opt => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
        ) : (
          <TextField
            key={name}
            name={name}
            label={label}
            type="text"
            inputRef={register()}
            defaultValue={memberProfile[name] || ''}
            variant="standard"
            className={`text-input ${errors[name] && ' error'}`}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            required={required}
          />
        ),
      )}
    </InputsTwoColumns>
  )
}

export default AboutYou
