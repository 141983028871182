import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  actionTileWrapper: {
    position: 'relative',
    border: '1px solid #E0E0E0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: theme.spacing(3),
    minHeight: '265px',
    maxWidth: '375px',
    margin: '10px',
    overflow: 'hidden',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      minHeight: 'unset',
      justifyContent: 'space-between',
    },
  },
  captionText: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1.5)}px 0`,
    },
  },
  chip: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),

    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      marginBottom: theme.spacing(2),
      top: 0,
      right: 0,
    },
  },
}))
