import { ContentSection, ListText } from './content-box'
import { formatNameForDisplay } from 'lib/text-display'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
} from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined'
import { useStyles } from '../styles'
import { useIsTablet } from 'lib/hooks'

export default function EmergencyInformation({
  emergencyContact,
  medicalProfile,
}) {
  const classes = useStyles()
  const isTablet = useIsTablet()

  if (!emergencyContact && !medicalProfile) return null

  const emergencyContactName = emergencyContact
    ? formatNameForDisplay(
      `${emergencyContact.firstName} ${emergencyContact.lastName}`,
    )
    : 'N/A'
  const doctorName = medicalProfile
    ? `Dr. ${formatNameForDisplay(medicalProfile.primaryDoctor)}`
    : 'N/A'

  return (
    <ContentSection title="Emergency Information">
      <List className={classes.informationContainer}>
        <Box className={classes.informationColumn}>
          {!isTablet && (
            <ListItemText
              style={{ marginLeft: '16px' }}
              secondary="Emergency Contact"
            />
          )}
          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <PersonOutlineIcon className={classes.icon} />
            </ListItemAvatar>
            <ListText
              data-testid="emergency-name"
              text={emergencyContactName}
            />
          </ListItem>
          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <LocalPhoneOutlinedIcon className={classes.icon} />
            </ListItemAvatar>
            {emergencyContact?.phone ? (
              <>
                <a
                  href={`tel:${emergencyContact.phone}`}
                  target="_top"
                  className={classes.linkText}
                >
                  <ListText
                    data-testid="emergency-phone"
                    text={emergencyContact.phone}
                  />
                </a>
              </>
            ) : (
              <ListText data-testid="emergency-phone" text="N/A" />
            )}
          </ListItem>
        </Box>

        <Box className={classes.informationColumn}>
          {!isTablet && <ListItemText secondary="Doctor Information" />}
          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <PersonOutlineIcon className={classes.icon} />
            </ListItemAvatar>
            <ListText data-testid="doctor-name" text={doctorName} />
          </ListItem>
          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <LocalPhoneOutlinedIcon className={classes.icon} />
            </ListItemAvatar>
            {medicalProfile?.primaryDoctorPhone ? (
              <>
                <a
                  href={`tel:${medicalProfile.primaryDoctorPhone}`}
                  target="_top"
                  className={classes.linkText}
                >
                  <ListText
                    data-testid="doctor-phone"
                    text={medicalProfile.primaryDoctorPhone}
                  />
                </a>
              </>
            ) : (
              <ListText data-testid="doctor-phone" text="N/A" />
            )}
          </ListItem>
        </Box>
      </List>
    </ContentSection>
  )
}
