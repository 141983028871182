import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import BookingTypeCard from '../../pages/club/my-coach/lesson-packs-card'

import { useStyles } from './styles'

const CoachCardWithLessonPacks = ({ coach, actions }) => {
  const classes = useStyles()
  const { id, name, facilityName, coachAvatar } = coach

  return (
    <Card key={id} className={classes.root} variant="outlined">
      <CardContent className={classes.coachInfo}>
        <Avatar className={classes.coachAvatar} alt={name} src={coachAvatar}>
          {name.substring(0, 1)}
        </Avatar>
        <div>
          <Typography variant="body1">{name}</Typography>
          {facilityName ? (
            <Typography variant="caption">{facilityName}</Typography>
          ) : null}{' '}
        </div>
      </CardContent>
      {actions ? (
        <CardActions disableSpacing className={classes.actionBar}>
          {actions}
        </CardActions>
      ) : null}
      <Box m={2}>
        {coach.lessonCreditBalances?.map(lc => (
          <BookingTypeCard
            key={lc.id}
            lessonType={lc.lessonType}
            remainingCredits={lc.remainingCredits}
            expiresAt={lc.expiresAt}
            bookingUrl={lc.lessonType.coach.bookingUrl}
            coachName={coach.name}
          />
        ))}
      </Box>
    </Card>
  )
}

export default CoachCardWithLessonPacks
