const STRIPE_FEE_FIXED = 0.3
const STRIPE_FEE_PERCENT = 0.029

const calculateStripeFee = amountInCents => {
  // Stripe rounds the fee to the nearest cent
  const feeInCents = Math.round(
    amountInCents * STRIPE_FEE_PERCENT + STRIPE_FEE_FIXED * 100,
  )
  const netAmountInCents = amountInCents - feeInCents
  return [netAmountInCents, feeInCents]
}

export default calculateStripeFee
