import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
  CardRoot,
  StyledH5,
} from 'components/coaches-profile/profile-stepper.styles'
import AboutYou from '../form-components/about-you'
import ProfilePhoto from '../form-components/profile-photo'
import Contact from '../form-components/contact'
import Location from '../form-components/location'
import Price from '../form-components/price'
import Personal from '../form-components/personal'
import Social from '../form-components/social'
import Testimonials from '../form-components/testimonials'
import Photos from '../form-components/photos'

export const ProfileStep = ({ memberProfile }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>

      <CardRoot>
        <StyledH5>About You</StyledH5>
        <AboutYou memberProfile={memberProfile} />
        <ProfilePhoto memberProfile={memberProfile} />
      </CardRoot>

      <CardRoot>
        <StyledH5>Contact</StyledH5>
        <Contact />
      </CardRoot>

      <CardRoot style={{ paddingBottom: '24px' }}>
        <StyledH5>Service Area</StyledH5>
        <Typography variant="body2" color="textSecondary">
          This describes where you offer services on PGA.com and helps people
          find you on Google.
        </Typography>
        <Location />
        <Typography style={{ marginTop: '16px' }}>
          &#42;All fields are required
        </Typography>
      </CardRoot>

      <CardRoot>
        <StyledH5>Price</StyledH5>
        <Price />
      </CardRoot>

      <CardRoot>
        <StyledH5>Personal</StyledH5>
        <Personal />
        <StyledH5>Social</StyledH5>
        <Social />
      </CardRoot>

      <CardRoot>
        <StyledH5>Testimonials</StyledH5>
        <Testimonials />
      </CardRoot>

      <CardRoot>
        <StyledH5>Photos</StyledH5>
        <Photos />
      </CardRoot>
    </Box>
  )
}

export default ProfileStep
