import { createStatusChip } from 'components/status-chip'

export const statuses = {
  NOT_STARTED: 'NOT_STARTED',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  CANCELLED: 'CANCELLED',
  FLAGGED: 'FLAGGED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  CHECKING: 'CHECKING',
  ERROR: 'ERROR',
  NOT_SETUP: 'NOT_SETUP',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  DUPLICATE: 'DUPLICATE',
  INCOMPLETE: 'INCOMPLETE',
  EXPIRED: 'EXPIRED',
  NEAR_EXPIRY: 'NEAR_EXPIRY',
}

const statusMap = {
  [statuses.NOT_STARTED]: { label: 'Not Started', color: 'gray' },
  [statuses.SUBMITTED]: { label: 'Request Sent', color: 'info' },
  [statuses.PENDING]: { label: 'Pending', color: 'gray' },
  [statuses.PENDING_VERIFICATION]: {
    label: 'Pending Verification',
    color: 'gray',
  },
  [statuses.CANCELLED]: { label: 'Cancelled', color: 'gray' },
  [statuses.FLAGGED]: { label: 'Flagged', color: 'gray' },
  [statuses.PASSED]: { label: 'Passed', color: 'success' },
  [statuses.FAILED]: { label: 'Failed', color: 'gray' },
  [statuses.CHECKING]: { label: 'Checking...', color: 'gray' },
  [statuses.ERROR]: { label: 'ERROR', color: 'gray' },
  [statuses.NOT_SETUP]: { label: 'Not Setup', color: 'gray' },
  [statuses.ACTIVE]: { label: 'Active', color: 'success' },
  [statuses.CLOSED]: { label: 'Closed', color: 'gray' },
  [statuses.DUPLICATE]: { label: 'Duplicate', color: 'gray' },
  [statuses.INCOMPLETE]: { label: 'Incomplete', color: 'gray' },
  [statuses.EXPIRED]: { label: 'Needs Attention', color: 'danger' },
  [statuses.NEAR_EXPIRY]: { label: 'Needs Attention', color: 'warning' },
}

export default createStatusChip(statusMap)
