import React, { useState } from 'react'
import { DateTime, Interval, Duration } from 'luxon'
import { useQuery, gql } from '@apollo/client'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { currency } from 'lib/utils/string'
import { toFullDateAndTime } from 'utils/dateUtils'
import Error from 'components/error'
import RouterLinkButton from 'components/router-link-button'
import {
  getProductName,
  getTransactionType,
} from 'components/transaction-summary/transaction-utils'
import PgaMobileDatePicker from 'components/pga-time-picker/pga-mobile-date-picker'

const GET_REGISTRATION_PAYMENTS = gql`
  query GetRegistrationPayments($createdAt: DateTimeRangeInput) {
    registrationPayments(createdAt: $createdAt) {
      id
      createdAt
      totalInCents
      payor {
        ... on Contact {
          firstName
          lastName
        }
        ... on Coach {
          name
        }
      }
      products {
        ... on Enrollment {
          lesson {
            title
          }
        }
        ... on LessonPack {
          lessonType {
            title
          }
          lessonPackOffering {
            quantity
          }
        }
        ... on FamilyCupProgram {
          name
        }
        ... on JRLChampionshipSeasonProgram {
          name
        }
        ... on JRLLocalLeagueProgram {
          name
        }
        ... on JRLProgramEnrollment {
          player {
            fullName
          }
          program {
            name
          }
        }
        ... on JRLKitOrder {
          category
          program {
            name
          }
        }
        ... on FamilyCupProgramEnrollment {
          program {
            name
          }
          captain {
            firstName
            lastName
          }
        }
      }
    }
  }
`

const useTransactionHistoryQuery = (startDate, endDate) => {
  const createdAtInterval =
    startDate && endDate && Interval.fromDateTimes(startDate, endDate)
  const isInvalidInterval = !createdAtInterval?.isValid
  const isIntervalOver30Days =
    createdAtInterval?.isValid &&
    createdAtInterval.toDuration('days') >= Duration.fromObject({ days: 30 })

  const warnings = [
    ...(isInvalidInterval ? ['Start date must be before end date'] : []),
    ...(isIntervalOver30Days ? ['Date range must be 30 days or less'] : []),
  ]

  const query = useQuery(GET_REGISTRATION_PAYMENTS, {
    skip: warnings.length > 0,
    variables: {
      createdAt: {
        from: createdAtInterval?.start,
        to: createdAtInterval?.end,
      },
    },
  })

  return { ...query, warnings }
}

const TransactionHistoryRow = ({ registrationPayment }) => {
  const { id, payor, totalInCents, createdAt, products } = registrationPayment
  const payorName = payor.name || `${payor.firstName} ${payor.lastName}`
  const initials = payorName
    .split(' ')
    .map(s => s[0])
    .join('')
  const amount = currency(totalInCents / 100)
  const product = getProductName(products[0])
  const transactionType = getTransactionType(products[0])

  return (
    <Box display="flex" justifyContent="space-between" py={2}>
      <Box display="inline-flex" mx={2}>
        <Avatar>{initials}</Avatar>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body1">{payorName}</Typography>
        <Typography variant="body2" color="textSecondary">
          {amount} on {toFullDateAndTime(createdAt)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {transactionType}
        </Typography>
        <Typography variant="body1">{product}</Typography>
      </Box>
      <Box display="inline-flex">
        <RouterLinkButton
          to={`/account/payments/transactions/${id}`}
          color="primary"
        >
          View Details
        </RouterLinkButton>
      </Box>
    </Box>
  )
}

const TransactionHistory = () => {
  const [startDate, setStartDate] = useState(
    DateTime.now()
      .startOf('day')
      .minus({ days: 29 }),
  )
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day'))

  const {
    data,
    previousData,
    loading,
    error,
    warnings,
  } = useTransactionHistoryQuery(startDate, endDate)

  const registrationPayments =
    data?.registrationPayments || previousData?.registrationPayments || []

  const TransactionHistoryTable = () => {
    if (loading) return <LinearProgress color="primary" />
    if (error) return <Error error={error} />
    if (registrationPayments.length === 0) {
      return (
        <Box mb={2}>
          <Divider />
          <Box py={4} px={2}>
            <Typography variant="body1">
              No results for the selected date range. Transactions that occur
              within the selected dates will appear here.
            </Typography>
          </Box>
          <Divider />
        </Box>
      )
    }

    return (
      <Box mb={2}>
        <Divider />
        {registrationPayments.map(rp => (
          <Box key={rp.id}>
            <TransactionHistoryRow registrationPayment={rp} />
            <Divider />
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Container>
      <Box my={2}>
        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Box>
              <LocalizationProvider dateAdapter={CustomLuxonAdapter}>
                <Box display="inline-flex" mr={2} mb={1}>
                  <PgaMobileDatePicker
                    label="Start date"
                    value={startDate}
                    format="MM/dd/yyyy"
                    onAccept={date => setStartDate(date)}
                  />
                </Box>
                <Box display="inline-flex">
                  <PgaMobileDatePicker
                    label="End date"
                    value={endDate}
                    format="MM/dd/yyyy"
                    onAccept={date => setEndDate(date?.endOf('day') || null)}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            {warnings.map(warning => (
              <FormHelperText key={warning} error>
                {warning}
              </FormHelperText>
            ))}
          </Box>
        </Box>
      </Box>
      <TransactionHistoryTable />
    </Container>
  )
}

export default TransactionHistory
