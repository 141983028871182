import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import MultiSessionInput from 'components/multi-session-input'
import { useFormContext } from 'react-hook-form'

const MultiSessionEventDayTime = ({ facilities }) => {
  const { getValues } = useFormContext()
  const selectedFacilityId = getValues('locationId')
  const selectedFacility = facilities?.find(
    facility => facility.id === selectedFacilityId,
  )
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Typography variant="h4" align="center">
          What day(s) do you want to host this offering?
        </Typography>
      </Box>
      <Container maxWidth="md" xs={12}>
        <MultiSessionInput
          name="sessions"
          timezone={selectedFacility?.timezone}
        />
      </Container>
    </Box>
  )
}

export default MultiSessionEventDayTime
