import React from 'react'
import { useMutation } from '@apollo/client'
import { Button, IconButton } from '@material-ui/core'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { get } from 'lodash'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import EventNoteOutlined from '@material-ui/icons/EventNoteOutlined'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import { useSnackbar, useSnackbarSuccess, useSnackbarError } from 'lib/snackbar'
import { makeStyles, styled } from '@material-ui/core/styles'
import { inviteToPlatformMutation } from '../../mutations'
import { useAuth } from 'lib/auth'
import { gtmEvent } from 'lib/gtm'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import { isPgaLink } from 'lib/utils'
import EditOutlined from '@material-ui/icons/EditOutlined'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  button: {
    borderColor: theme.palette.primary.border,
    fontColor: theme.palette.primary.light,
    minWidth: '110px',
    boxSizing: 'border-box !important',
    [theme.breakpoints.down('sm')]: {
      borderColor: theme.palette.primary.border,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      minWidth: '75px',
      maxWidth: '75px',
      height: '72px',
      fontSize: '0.75rem',
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('333')]: {
      '&:last-of-type': {
        marginTop: '12px',
      },
    },
  },
  icon: {
    minWidth: '35px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '20px',
    },
    [theme.breakpoints.down(375)]: {
      minWidth: 'unset',
      width: '25%',
    },
  },
  iconButton: {
    padding: 0,
  },
}))

/// Constrained width and conditional display depending on if button is disabled or not.
const EditButtonToolTip = styled(({ className, disabled, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ disabled }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 155,
    display: disabled && 'none',
  },
}))

export default function StudentActionButtons({
  student,
  useCornerActions = false,
}) {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useAuth()
  const { contact } = student

  // ---> INVITATION HANDLERS.
  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const [inviteToPlatform] = useMutation(inviteToPlatformMutation)

  // Only show the Book button for internal booking. Otherwise the button goes nowhere.
  const showBookButton =
    user.coach.bookingUrl !== null ? isPgaLink(user.coach.bookingUrl) : false

  const hasAccount = student?.hasAccount

  const handleInvitation = async () => {
    try {
      const { data } = await inviteToPlatform({
        variables: { studentIds: [student.id] },
      })
      const success = get(data, ['SendConnectionInvitation', 'success'])
      if (!success) return snackbarError()
      snackbarSuccess(`${student.firstName} invited to platform`)
    } catch (e) {
      snackbarError()
    }
  }

  // ----> BOOKING HANDLER.
  const handleBookSessionClick = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-session-button',
    })
    const defaultPath = new URL(user.coach.bookingUrl).pathname
    window.open(
      `${defaultPath}?student=${student.id}`,
      '_blank',
      'noopener,noreferrer',
    )
  }

  const handleEditClick = () => {
    history.push(`/pga-coach/student/${student.id}/edit`)
  }

  if (useCornerActions) {
    return (
      <>
        <SensitiveInfoWrapper>
          <StreamSendMessageButton
            userIds={[contact.externalId]}
            color="primary"
            component={() => (
              <IconButton className={classes.iconButton} color="primary">
                <MessageOutlinedIcon className={classes.icon} />
              </IconButton>
            )}
          />
        </SensitiveInfoWrapper>
        {showBookButton ? (
          <IconButton
            className={classes.iconButton}
            color="primary"
            onClick={handleBookSessionClick}
          >
            <EventNoteOutlined className={classes.icon} />
          </IconButton>
        ) : null}
        {!hasAccount && (
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={handleInvitation}
          >
            <SyncAltIcon className={classes.icon} />
          </IconButton>
        )}
        <EditButtonToolTip
          title="This student is associated with a MyPGA account. Updating account information needs to be done by the student."
          leaveDelay={500}
          leaveTouchDelay={500}
          disabled={!hasAccount}
        >
          <div>
            <IconButton
              color="primary"
              disabled={hasAccount}
              className={classes.iconButton}
              onClick={handleEditClick}
            >
              <EditOutlined className={classes.icon} />
            </IconButton>
          </div>
        </EditButtonToolTip>
      </>
    )
  }

  return (
    <>
      <SensitiveInfoWrapper>
        <StreamSendMessageButton
          userIds={[contact.externalId]}
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<MessageOutlinedIcon className={classes.icon} />}
        >
          Message
        </StreamSendMessageButton>
      </SensitiveInfoWrapper>
      {showBookButton ? (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<EventNoteOutlined className={classes.icon} />}
          onClick={handleBookSessionClick}
        >
          Book
        </Button>
      ) : null}
      {!hasAccount && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<SyncAltIcon className={classes.icon} />}
          onClick={handleInvitation}
        >
          Invite
        </Button>
      )}
      <EditButtonToolTip
        title="This student is associated with a MyPGA account. Updating account information needs to be done by the student."
        leaveDelay={300}
        leaveTouchDelay={500}
        disabled={!hasAccount}
      >
        <div>
          <Button
            variant="outlined"
            color="primary"
            disabled={hasAccount}
            className={classes.button}
            startIcon={<EditOutlined className={classes.icon} />}
            onClick={handleEditClick}
          >
            Edit
          </Button>
        </div>
      </EditButtonToolTip>
    </>
  )
}
