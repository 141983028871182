import React from 'react'
import { useFormContext } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  attendanceRoot: {
    marginTop: '42px',
  },
  maxFieldStyled: {
    maxWidth: '450px',
    marginTop: '16px',
  },
}))

const MaxAttendance = () => {
  const { register, errors, setValue, watch } = useFormContext()

  const classes = useStyles()
  const maxAttendance = watch('maxAttendance')
  const clearMaxAttendance = () => setValue('maxAttendance', null)

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      className={classes.attendanceRoot}
    >
      <Typography variant="subtitle1" gutterBottom>
        Max attendance
      </Typography>
      <Typography variant="body2" gutterBottom>
        Enter the max number of participants that can attend this event.
      </Typography>
      <TextField
        name="maxAttendance"
        type="number"
        inputRef={register}
        label="Maximum number of attendees"
        error={!!errors.maxAttendance}
        helperText={errors?.maxAttendance?.message}
        className={classes.maxFieldStyled}
        InputProps={{
          inputProps: { min: 0 },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={maxAttendance === null || maxAttendance === ''}
                onClick={clearMaxAttendance}
              >
                <ClearOutlinedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default MaxAttendance
