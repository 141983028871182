import React from 'react'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'

const copy =
  navigator.clipboard != null
    ? value => navigator.clipboard.writeText(value)
    : value => {
      const temp = document.createElement('input')
      temp.value = value
      document.body.appendChild(temp)
      temp.select()
      document.execCommand('copy')
      document.body.removeChild(temp)

      return Promise.resolve()
    }

const CopyTextButton = ({
  value,
  children,
  notificationText = 'Copied text to clipboard',
  component = Button,
  ...componentProps
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const onClick = () => {
    componentProps?.onClick && componentProps.onClick()
    copy(value).then(() => {
      enqueueSnackbar(notificationText, { variant: 'info' })
    })
  }

  return React.createElement(component, {
    ...componentProps,
    children,
    onClick,
  })
}

export default CopyTextButton
