import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CoachEventCard from './coach-event-card'
import { GROUP_EVENT_PAGE_LIMIT } from 'lib/pga-dot-coach'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '45px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
      marginBottom: '70px',
    },
  },
}))

const EventsDisplay = ({
  events,
  refetch,
  advancePagination,
  retreatPagination,
  currentPage,
  lastPage,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        {events.map(event => (
          <Grid
            key={event.id}
            item
            lg={4}
            md={6}
            sm={12}
            style={{ width: '100%' }}
          >
            <CoachEventCard event={event} onPayment={refetch} />
          </Grid>
        ))}
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        mt={2}
        data-testid="events-display"
        align="space-evenly"
      >
        {(currentPage > 1 || lastPage) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={retreatPagination}
            style={{ marginRight: '.5rem' }}
          >
            <ChevronLeftIcon />
          </Button>
        )}
        {!lastPage && events.length === GROUP_EVENT_PAGE_LIMIT && (
          <Button
            variant="outlined"
            color="primary"
            onClick={advancePagination}
            style={{ marginLeft: '.5rem' }}
          >
            <ChevronRightIcon />
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default EventsDisplay

EventsDisplay.propTypes = {
  events: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  advancePagination: PropTypes.func.isRequired,
  retreatPagination: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.bool.isRequired,
}
