import { gql, useQuery } from '@apollo/client'

export const GET_REGISTRATION_PAYMENT = gql`
  query GetRegistrationPayment($registrationPaymentId: ID!) {
    registrationPayment(registrationPaymentId: $registrationPaymentId) {
      id
      cardBrand
      createdAt
      last4
      updatedAt
      leftToRefundInCents
      registrationPaymentCharge {
        id
        chargeType
        chargeNumber
        checkNumber
      }
      payor {
        ... on Contact {
          id
          email
          firstName
          lastName
        }
        ... on Coach {
          id
          email
          name
        }
      }
      products {
        ... on Enrollment {
          lesson {
            title
          }
        }
        ... on LessonPack {
          lessonType {
            title
          }
          lessonPackOffering {
            quantity
          }
        }
        ... on FamilyCupProgram {
          name
        }
        ... on JRLChampionshipSeasonProgram {
          name
        }
        ... on JRLLocalLeagueProgram {
          name
        }
        ... on JRLProgramEnrollment {
          id
          active
          player {
            fullName
          }
          program {
            name
          }
        }
        ... on JRLKitOrder {
          category
          program {
            name
          }
        }
        ... on FamilyCupProgramEnrollment {
          program {
            name
          }
          captain {
            firstName
            lastName
          }
        }
      }
      transactions {
        amountInCents
        createdAt
        refund
        refundReason
        stripeReceiptUrl
        sourceType
        lineItems {
          amountInCents
          discountCode
          lineType
        }
      }
    }
    paymentSourceType: __type(name: "PaymentSourceType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`

const useGetRegistrationPayment = registrationPaymentId =>
  useQuery(GET_REGISTRATION_PAYMENT, {
    variables: {
      registrationPaymentId,
    },
  })

export default useGetRegistrationPayment
