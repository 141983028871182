import React, { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'
import { show } from 'lib/support'

const ContactButton = () => {
  useEffect(() => {
    const launcher = document.querySelector(
      '.intercom-lightweight-app-launcher',
    )

    if (launcher) {
      launcher.style.display = 'none'
    }

    return () => {
      const launcher = document.querySelector(
        '.intercom-lightweight-app-launcher',
      )

      if (launcher) {
        launcher.style.display = 'block'
      }
    }
  }, [])

  return (
    <IconButton color="primary" onClick={show}>
      <LiveHelpOutlinedIcon />
    </IconButton>
  )
}

export default ContactButton
