import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import MenuList from '@material-ui/core/MenuList'
import Popover from '@material-ui/core/Popover'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

export default function ActionsMenu({
  ariaId,
  children,
  buttonContent = <MoreHorizIcon />,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  ...buttonProps
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const toggleMenu = event => {
    event.stopPropagation()
    setAnchorEl(el => (el ? null : event.currentTarget))
  }

  const closeMenu = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls={`actions-menu-${ariaId}`}
        aria-haspopup="true"
        color="primary"
        {...buttonProps}
        onClick={toggleMenu}
      >
        {buttonContent}
      </Button>

      <Popover
        keepMounted
        id={`actions-menu-${ariaId}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={closeMenu}
      >
        <MenuList autoFocusItem onBlur={closeMenu} onClick={closeMenu}>
          {children}
        </MenuList>
      </Popover>
    </div>
  )
}
