import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  background: {
    border: '1px solid #D8D8D8',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  header: {
    borderLeft: '0.5px solid #D8D8D8',
    paddingLeft: theme.spacing(2),
  },
  standardHeader: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '600',
    marginBottom: theme.spacing(1),
  },
  body: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    lineHeight: '20px',
    fontWeight: '600',
  },
  optInText: {
    fontSize: '10px',
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  iconWithDivider: {
    marginRight: theme.spacing(2),
  },
  formControlLabel: {
    alignItems: 'flex-start',
  },
  checkbox: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
  },
}))

export default useStyles
