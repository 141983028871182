import { makeStyles } from '@material-ui/styles'
import { styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    background: theme.palette.primary.selected,
    height: '135px',
  },
  categoryIcon: {
    position: 'absolute',
    top: '110px',
    height: '80px',
    width: '80px',
    background: theme.palette.primary.light,
    '& svg': {
      width: '2.5rem !important',
      height: '2.5rem !important',
      '& path': {
        fill: '#FFF !important',
      },
    },
  },
  cancelPaymentRequestButtons: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
}))

const ActionsWrapper = styled(Box)(({ theme }) => ({
  minWidth: '100px',
  '& svg': {
    color: theme.palette.primary.light,
  },
}))

export { useStyles, ActionsWrapper }
