import { makeStyles } from '@material-ui/core/styles'
import themes from 'themes'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '3rem auto',
  },
  rowWrapped: {
    display: 'flex',
    backgroundColor: '#EEE',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    alignItems: 'center',
    marginBottom: '1.5rem',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  fontBolded: {
    fontWeight: 600,
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttonOverrideBook: {
    backgroundColor: themes.offeringsRefresh.palette.primary.lightest,
    color: themes.offeringsRefresh.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
    },
  },
  buttonScale: {
    maxWidth: '213px',
    maxHeight: '40px',
    width: '100%',
    height: '100%',
    borderRadius: '50px',
    textTransform: 'none',
    padding: '0.5rem 1rem',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '22px',
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
      color: themes.offeringsRefresh.palette.primary.contrastText,
    },
  },
  gridItem: {
    marginBottom: '3rem',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '16px',
  },
  studentCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    border: '1px solid #EEE',
    height: '80px',
    padding: '16px',
  },
  bookingButton: {
    backgroundColor: themes.offeringsRefresh.palette.primary.lightest,
    color: themes.offeringsRefresh.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
    },
    padding: '0.5rem 1rem',
  },
  loginPromptText: { marginLeft: '1rem' },
  icon: {
    marginRight: '0.5rem',
  },
  divider: {
    margin: '1.5rem 0px 2.5rem',
  },
  loadingStyles: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
