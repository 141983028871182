import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  helperText: {
    // transform: 'translate(0px, 0)',
    margin: 0,
    position: 'absolute',
    right: 0,
    top: '-16px',
  },
}))

export default useStyles
