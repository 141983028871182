import React from 'react'
import {
  Card,
  CardContent,
  Container,
  Typography,
  Divider,
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, styled } from '@material-ui/styles'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import PGAIcon from 'images/pga-logo-navy.png'
import { useFindTemplate } from 'components/lightning-fast-onboarding/use-find-template'
import RouterLinkButton from 'components/router-link-button'
import { useTrackAffiliate, useTrackTemplate } from 'lib/gtm'
import { ArrowRightAlt } from '@material-ui/icons'

const appBarHeight = '61px'
const footerHeight = '70px'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '34px',
    fontWeight: '500',
    color: '#00234B',
    paddingTop: '2rem',
    paddingBottom: '1.25rem',
  },
  card: {
    margin: '0 0 2rem 0',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardMedia: {
    height: '80px',
  },
  button: {
    fontSize: '15px',
    textTransform: 'uppercase',
    color: theme.palette.primary.light,
    padding: 0,
    marginTop: '11px',
    marginBottom: '-10px',
  },
  actionBtnContainer: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    backgroundColor: '#ffffff',
  },
  wrapper: ({ viewportHeight }) => ({
    height: 'fit-content',
    overflowY: 'auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      // (full viewport - appbar - controls)
      height: `calc(${viewportHeight}px - ${appBarHeight} - ${footerHeight})`,
      paddingBottom: '20px',
    },
  }),
}))

export const CardIcon = styled(Box)(({ theme, iconSource }) => ({
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundImage: `url(${iconSource})`,
  backgroundColor: theme.palette.common.white,
  backgroundPosition: 'center',
  backgroundSize: '60%',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  top: '16px',
  left: '16px',
}))

const ActionButtons = () => {
  const { pathname, search } = useLocation()

  return (
    <>
      <Box display="flex" justifyContent="end" flexGrow="1">
        <Box display="flex">
          <RouterLinkButton
            variant="contained"
            color="primary"
            to={`${pathname}/new${search}`}
            data-testid="lfo-get-started"
          >
            Getting Started
          </RouterLinkButton>
        </Box>
      </Box>
    </>
  )
}

function truncate(string, length) {
  return string.length > length ? string.substr(0, length) + '...' : string
}

const LightningFastOnboarding = () => {
  useTrackAffiliate()
  useTrackTemplate()

  const templateValues = useFindTemplate()
  const { pathname, search } = useLocation()
  const history = useHistory()
  const viewportHeight = window.innerHeight
  const classes = useStyles({ viewportHeight })
  return (
    <>
      <Box className={classes.wrapper}>
        <Container>
          <Typography className={classes.header} variant="h4">
            Power your business from your pocket with PGA Coach
          </Typography>
          <Typography variant="body1">
            Let's get started! In the next 2 minutes, you'll create your coach
            profile — if you don't have one already — and your first group
            event:
          </Typography>
          <Box mb={1} mt={2.5} display="flex" justifyContent="center">
            <Typography variant="caption" color="textSecondary">
              PREVIEW
            </Typography>
          </Box>
          <Card
            className={classes.card}
            variant="outlined"
            onClick={() => history.push(`${pathname}/new${search}`)}
          >
            <CardMedia
              image={templateValues.featuredImageUrl}
              title=""
              className={classes.cardMedia}
            />
            <CardIcon iconSource={PGAIcon} />
            <CardContent>
              <Typography gutterBottom variant="h6">
                {templateValues.title || 'Your First Group Event'}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {truncate(templateValues.description, 100) ||
                  'Leverage the power of PGA Coach and pga.com to manage and promote your group events.'}
              </Typography>
              <RouterLinkButton
                variant="text"
                color="primary"
                endIcon={<ArrowRightAlt />}
                disableRipple
                to={`${pathname}/new${search}`}
                className={classes.button}
              >
                Getting Started
              </RouterLinkButton>
            </CardContent>
          </Card>
          <Typography variant="body1">
            We've made it easy for you by pre-filling what we know. Edit as you
            go, and get ready to leverage the power of PGA Coach.
          </Typography>
        </Container>
      </Box>
      <Box className={classes.actionBtnContainer}>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <ActionButtons />
        </Box>
      </Box>
    </>
  )
}

export default LightningFastOnboarding
