import React, { Fragment } from 'react'
import { Hidden, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import fcLogo from 'images/family-cup-logo.svg'

const useStyles = makeStyles(theme => ({
  logo: {
    height: 70,
    position: 'absolute',
    left: theme.spacing(4),
    top: theme.spacing(2),
  },
  logoSmallScreen: {
    height: 140,
    marginTop: theme.spacing(2),
  },
  stepHeading: {
    fontSize: 30,
    fontWeight: 'bold',
  },
}))

export function FCSignupLogo() {
  const classes = useStyles()

  return (
    <Fragment>
      <Hidden smDown>
        <img src={fcLogo} alt="Family Cup Logo" className={classes.logo} />
      </Hidden>
      <Hidden mdUp>
        <Box display="flex" justifyContent="center">
          <img
            src={fcLogo}
            alt="Family Cup Logo"
            className={classes.logoSmallScreen}
          />
        </Box>
      </Hidden>
    </Fragment>
  )
}
