import React from 'react'
import Grid from '@material-ui/core/Grid'
import StateSelector from 'components/util/state-selector'
import {
  CtrlTextField,
  TextFieldWithError,
  ZipField,
} from 'components/form-util'
import { Controller } from 'react-hook-form'

const fieldName = (prefix, name) => (prefix ? `${prefix}.${name}` : name)

export default function CtrlAddress({
  form,
  nameField,
  disabled,
  prefix = 'address',
}) {
  const { control, errors } = form
  return (
    <Grid container item spacing={2} md={10}>
      {nameField && (
        <Grid item xs={12}>
          <CtrlTextField
            required
            label="Name"
            name={fieldName(prefix, nameField)}
            variant="outlined"
            control={control}
            errors={errors}
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CtrlTextField
          required
          label="Address1"
          name={fieldName(prefix, 'address1')}
          variant="outlined"
          control={control}
          errors={errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CtrlTextField
          label="Address2"
          name={fieldName(prefix, 'address2')}
          variant="outlined"
          control={control}
          errors={errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CtrlTextField
          required
          label="City"
          name={fieldName(prefix, 'city')}
          variant="outlined"
          control={control}
          errors={errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Controller
          as={
            <TextFieldWithError
              component={StateSelector}
              required
              label="State"
              errors={errors}
              disabled={disabled}
            />
          }
          control={control}
          name={fieldName(prefix, 'state')}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={4}>
        <CtrlTextField
          required
          label="Zip"
          name={fieldName(prefix, 'zip')}
          variant="outlined"
          component={ZipField}
          control={control}
          errors={errors}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
