import React from 'react'
import { useLocation } from 'react-router-dom'
import withSearch from 'pages/pga-coach/students/with-search'
import Grid from '@material-ui/core/Grid'
import SearchBar from '../../pga-coach/students/search-bar'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { gtmEvent } from 'lib/gtm'

export const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '82px',
    width: '300px',
    borderRadius: '8px',
    padding: '20px',
    cursor: 'pointer',
    transition: 'all 0.1s ease',
    boxShadow: '0px 4px 36px rgb(0 10 22 / 13%)',
    '&:hover': {
      border: '1px solid black',
      boxShadow: '0px 4px 36px 8px rgb(0 10 22 / 32%)',
    },
  },
}))

const StudentGridItem = ({ student, history, currentUrl, eventObject }) => {
  const name = `${student.firstName} ${student.lastName}`
  const classes = useStyles()
  const location = useLocation()
  const studentPreSelect = new URLSearchParams(location.search).get('student')

  if (studentPreSelect) {
    history.push(`${currentUrl}&studentId=${studentPreSelect}`)
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'pre-selected-student',
    })
  }

  const handleSelectStudentClick = () => {
    history.push(`${currentUrl}&studentId=${student.id}`)
    gtmEvent({
      ...eventObject,
      event: 'select-student-to-schedule',
    })
    // Remove the below event in the future.
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-add-student-button',
    })
  }

  return (
    <Grid item key={student.id} onClick={handleSelectStudentClick}>
      <Card
        variant="outlined"
        className={classes.cardRoot}
        data-testid="student-card"
      >
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1">{name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary">
                {student.isMinor ? 'Junior' : 'Adult'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

const StudentGrid = ({
  filteredItems,
  searchTerm,
  setSearchTerm,
  history,
  currentUrl,
  openAddStudent,
  setOpenAddStudent,
  eventObject = {},
}) => {
  const handleAddNewStudentClick = () => {
    setOpenAddStudent(!openAddStudent)
    // Remove the below event in the future.
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-add-student-button',
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={4}
        xs={12}
        style={{ paddingLeft: '0px', paddingBottom: '0px' }}
      >
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          style={{ margin: '20px', marginLeft: '0px' }}
          placeholder="Search Students"
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '30px' }}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '40px' }}
            onClick={handleAddNewStudentClick}
          >
            Add a New Student
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
        {filteredItems.map(student => (
          <StudentGridItem
            student={student}
            key={student.id}
            history={history}
            currentUrl={currentUrl}
            eventObject={eventObject}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default withSearch(StudentGrid)
