import React from 'react'
import { useQuery, gql } from '@apollo/client'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import Unauthorized from 'components/unauthorized'
import Error from 'components/error'
import { PageHeader } from 'components/page-header'
import ExternalLinkButton from 'components/external-link-button'
import { PGA_COM_HOST } from 'env'
import { useAuth, ImpersonateUserDialog } from 'lib/auth'
import * as maybe from 'lib/maybe'
import { academyLink } from './utils'

const ACADEMY_COACHES_QUERY = gql`
  query GetAcademyCoaches {
    currentUser {
      attributes {
        ... on Coach {
          employments {
            role
            academy {
              name
              slug
              employments {
                id
                role
                coach {
                  id
                  name
                  email
                  coachProfile {
                    profilePhoto
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const AcademyEmploymentCard = ({ employment }) => {
  const { role, coach } = employment

  const avatarContent = maybe
    .of(coach)
    .map(({ name, email }) => name || email)
    .map(name => name.charAt(0).toUpperCase())
    .withDefault('U')

  const toTitleCase = str =>
    str.replace(
      /(\w)(\w*)/g,
      (_, firstChar, rest) => firstChar + rest.toLowerCase(),
    )

  return (
    <Box mb={2}>
      <Paper variant="outlined">
        <Box px={2} py={1.5}>
          <Grid container display="flex" alignItems="center">
            <Grid item xs={6} md={4}>
              <Box display="flex" alignItems="center">
                <Avatar src={coach.coachProfile?.profilePhoto}>
                  {avatarContent}
                </Avatar>
                <Box ml={2}>
                  <Typography variant="subtitle1">
                    <strong>{coach.name}</strong>
                  </Typography>
                  <Typography variant="body1">{toTitleCase(role)}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="body1" align="center">
                {coach.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex" justifyContent="flex-end">
                <ImpersonateUserDialog
                  trigger={<Button variant="outlined">Impersonate User</Button>}
                  user={{ userType: 'Coach', id: coach.id, name: coach.name }}
                  hideSensitiveInformation
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

const AcademyCoachesListItem = ({ academy }) => {
  const path = academyLink(academy)
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={1.5}
      >
        <Typography variant="h6">{academy.name}</Typography>
        <ExternalLinkButton color="primary" href={`${PGA_COM_HOST}/${path}`}>
          View Academy Page&nbsp;
          <OpenInNewOutlinedIcon fontSize="small" color="primary" />
        </ExternalLinkButton>
      </Box>
      {academy.employments.map(e => (
        <AcademyEmploymentCard key={e.id} employment={e} />
      ))}
    </>
  )
}

const AcademyCoachesList = () => {
  const { data, loading, error } = useQuery(ACADEMY_COACHES_QUERY)

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  const ownedAcademies = data.currentUser.attributes.employments
    .filter(e => e.role === 'OWNER')
    .map(e => e.academy)

  return (
    <Container>
      <Grid container direction="column" spacing={6}>
        {ownedAcademies.map(academy => (
          <Grid key={academy.id} item xs={12}>
            <AcademyCoachesListItem academy={academy} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

const AccountAcademyCoachesPage = () => {
  const { user, isCoach } = useAuth()
  if (!isCoach || !user.isAcademyOwner || user.isAssumed) {
    return <Unauthorized />
  }
  return (
    <>
      <PageHeader title="Academy Coaches" />
      <Box mt={4}>
        <AcademyCoachesList />
      </Box>
    </>
  )
}

export default AccountAcademyCoachesPage
