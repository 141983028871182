import { get } from 'lodash'

export const hasAtLeastOneCoach = students => {
  let hasCoach = false
  students.forEach(({ coaches }) => {
    if (coaches && coaches.length) {
      hasCoach = true
      return hasCoach
    }
  })
  return hasCoach
}

const flattenCoaches = coaches =>
  coaches.reduce((acc, cv) => {
    acc.push({
      id: cv.id,
      externalId: cv.externalId,
      name: cv.name,
      coachAvatar: get(cv, 'coachProfile.profilePhoto', null),
      coachSlug: get(cv, ['coachProfile', 'slug']),
      facilityName: get(
        cv,
        ['coachProfile', 'facilities', '0', 'facilityName'],
        null,
      ),
    })
    return acc
  }, [])

export const parseData = representatives =>
  representatives.reduce((acc, cv) => {
    const { id, firstName, lastName, coaches } = cv.student
    acc.push({
      id: id,
      relationshipType: cv.relationshipType,
      firstName,
      lastName,
      coaches: flattenCoaches(coaches),
    })
    return acc
  }, [])
