import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { gql, useQuery } from '@apollo/client'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
  CardRoot,
  InputsTwoColumns,
  CheckboxItemWrapper,
  ErrorMsg,
} from '../profile.styles'
import { formFields } from '../constants'
import { findIndex, some } from 'lodash'

const MAX_SKILLS = 8

const SkillsQuery = gql`
  query Skills {
    Skills {
      message
      success
      skills {
        skillId
        skillName
        description
      }
    }
  }
`

const isChecked = (skillId, skills) => some(skills, { skillId })

const toggleSkillChecked = (skillId, skills, push, remove) => {
  isChecked(skillId, skills)
    ? remove(findIndex(skills, { skillId }))
    : push({ skillId })
}

const Skills = props => {
  const { values, errors } = props
  const checkedSkills = values.skills || []

  const { data, loading, error } = useQuery(SkillsQuery)
  if (error) {
    return <Error error={error} displayErrorType="page" />
  }
  if (loading) return <LinearProgress color="secondary" />
  const response = data && data.Skills && data.Skills.skills

  return response ? (
    <div key="profile-form-skills">
      {errors.skills && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>{errors.skills}</ErrorMsg>
      )}
      <FieldArray name="skills">
        {({ push, remove }) => {
          return (
            <CardRoot>
              <Typography variant="body2" color="textSecondary">
                Choose any of these pre-populated options to help market your business and specialties!
              </Typography>
              <InputsTwoColumns>
                {data.Skills.skills.map(item => {
                  return (
                    <CheckboxItemWrapper key={item.skillId}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`skill-checkbox-${item.skillId}`}
                            className="checkbox"
                            checked={isChecked(item.skillId, checkedSkills)}
                            onChange={() =>
                              toggleSkillChecked(
                                item.skillId,
                                checkedSkills,
                                push,
                                remove,
                              )
                            }
                            value={item.skillId}
                            color="secondary"
                            disabled={
                              !isChecked(item.skillId, checkedSkills) &&
                              checkedSkills.length >= MAX_SKILLS
                            }
                          />
                        }
                        label={item.skillName}
                        htmlFor={`skill-checkbox-${item.skillId}`}
                      />
                    </CheckboxItemWrapper>
                  )
                })}
              </InputsTwoColumns>
            </CardRoot>
          )
        }}
      </FieldArray>
    </div>
  ) : null
}

Skills.propTypes = {
  values: formFields,
  errors: PropTypes.object,
}

Skills.defaultProps = {}

export default Skills
