import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { currency } from 'lib/utils/string'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { defineLessonPacksWithDiscount } from '../utils'
import { useStyles } from './styles'
import AlertWithDivider from './AlertWithDivider'

const SelectLessonPack = ({
  lessonPacks,
  selectedLessonPackId,
  setSelectedLessonPackId,
  lessonPackQuantity,
  setLessonPackQuantity,
}) => {
  const classes = useStyles()
  let selectedLessonPack

  if (selectedLessonPackId) {
    ;[selectedLessonPack] = lessonPacks.filter(
      lp => lp.id === selectedLessonPackId,
    )
  }

  const [lessonPacksSubtotal, setLessonPacksSubtotal] = useState(
    selectedLessonPack ? parseInt(selectedLessonPack.priceInCents) : 0,
  )
  const lessonPacksWithDiscount = defineLessonPacksWithDiscount(
    lessonPacks.sort((a, b) => a.quantity - b.quantity),
  )

  const handleChange = event => {
    setSelectedLessonPackId(event.target.value)
    const [selectedLessonPack] = lessonPacks.filter(
      lp => lp.id === event.target.value,
    )
    setLessonPacksSubtotal(parseInt(selectedLessonPack.priceInCents))
    if (setLessonPackQuantity) {
      setLessonPackQuantity(selectedLessonPack.quantity - 1)
    }
  }

  return (
    <>
      <Typography variant="h4">Select lesson pack</Typography>
      <FormControl component="fieldset" style={{ marginTop: '24px' }}>
        <RadioGroup
          name="lessonPacksSubtotal"
          value={selectedLessonPackId}
          onChange={handleChange}
        >
          {lessonPacksWithDiscount.map((lessonPack, id) => (
            <FormControlLabel
              key={id}
              data-testid={`lesson-pack-${id}`}
              value={lessonPack.id}
              control={<Radio color="primary" />}
              label={
                <Typography variant="body1">
                  {lessonPack.quantity} lesson pack{' '}
                  <span style={{ color: '#737373' }}>
                    {currency(lessonPack.priceInCents / 100)} total (
                    {currency(
                      lessonPack.priceInCents / lessonPack.quantity / 100,
                    )}{' '}
                    per lesson{lessonPack.discount ? '' : ')'}
                  </span>
                  {lessonPack.discount && (
                    <span style={{ color: '#737373', fontWeight: '700' }}>
                      {`, save ${currency(lessonPack.discount / 100)})`}
                    </span>
                  )}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
      {lessonPackQuantity !== undefined && (
        <AlertWithDivider
          text={`Once the purchase is complete, we will apply one of the lessons from your pack.
          You will have ${lessonPackQuantity} lessons left.`}
        />
      )}
      <Divider className={classes.divider} />
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="body1" className={classes.lighterText}>
          Subtotal
        </Typography>
        <Typography variant="body1" className={classes.lighterText}>
          {currency(lessonPacksSubtotal / 100)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" className={`${classes.fontBolded}`}>
          Due today
        </Typography>
        <Typography variant="body1" className={`${classes.fontBolded}`}>
          {currency(lessonPacksSubtotal / 100)}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
    </>
  )
}

export default SelectLessonPack
