import ContentSection from 'components/content-section'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { useStyles } from './styles'
import {
  AccessibleForward,
  AssignmentIndOutlined,
  CalendarTodayOutlined,
  FlagOutlined,
} from '@material-ui/icons'

function formatYearsOfService(start, end) {
  if (start === null || typeof start === 'undefined') return 'N/A'
  return `${start} - ${end || 'Present'}`
}

export default function ServiceBackground({ student = {} }) {
  const classes = useStyles()

  const { pgaHopeProfile: profile } = student

  const yearsOfService = formatYearsOfService(
    profile?.serviceYearStart,
    profile?.serviceYearEnd,
  )

  return (
    <ContentSection title="Service Background">
      <List className={classes.container}>
        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <FlagOutlined className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Branch of Service
                      </Typography>
                    }
                  />
                  <ListItemText secondary={profile?.serviceBranch || 'N/A'} />
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <AccessibleForward className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText primary="Accommodations" />
                  <ListItemText secondary={profile?.accommodations || 'N/A'} />
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>

        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <AssignmentIndOutlined className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>Status</Typography>
                    }
                  />
                  <ListItemText secondary={profile?.militaryStatus || 'N/A'} />
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <CalendarTodayOutlined className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Years of Service
                      </Typography>
                    }
                  />
                  <ListItemText secondary={yearsOfService} />
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>
      </List>
    </ContentSection>
  )
}
