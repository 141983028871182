import React, { lazy, Suspense } from 'react'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import { Helmet } from 'react-helmet'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SnackbarProvider } from 'notistack'
import Shell from 'components/shell/shell'
import themes, { extendTheme } from 'themes'
import { ReactComponent as HomeIcon } from 'images/icons/home.svg'
import { ReactComponent as GolfIcon } from 'images/icons/sports-golf.svg'
import logo from 'images/jr-league-logo.svg'
import { BRANDS } from 'components/use-brand'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import withJuniorLeagueAccess from 'lib/junior-league-utils/with-junior-league-access'
import { JRL_RESOURCE_CENTER_URL } from 'env'
import { ProgramsSubMenu } from 'pages/junior-league/dashboard/programs-sub-menu'
import Redirect from 'components/redirect'

const JRLDashboard = lazy(() => import('pages/junior-league/dashboard'))
const JRLLocations = lazy(() => import('pages/junior-league/locations'))
const JRLLocationsNew = lazy(() => import('pages/junior-league/locations-new'))
const JRLLocationEdit = lazy(() => import('pages/junior-league/locations-edit'))
const JRLLocationDetails = lazy(() =>
  import('pages/junior-league/location-details'),
)
const JRLProgramNew = lazy(() => import('pages/junior-league/program-new'))
const JRLChampProgramNew = lazy(() =>
  import('pages/junior-league/champ-program-new'),
)
const JRLRegionalChampProgramNew = lazy(() =>
  import('pages/junior-league/regional-champ-program/new'),
)
const JRLRegionalChampProgramEdit = lazy(() =>
  import('pages/junior-league/regional-champ-program/edit'),
)
const JRLProgram = lazy(() => import('pages/junior-league/program'))
const JRLChampProgram = lazy(() => import('pages/junior-league/champ-program'))
const JRLScheduleEdit = lazy(() =>
  import('pages/junior-league/program/schedule/schedule-edit'),
)
const JRLEventEdit = lazy(() =>
  import('components/program-schedule/event-edit'),
)
const JRLEventNew = lazy(() => import('components/program-schedule/event-new'))
const JRLStripeSignup = lazy(() =>
  import('components/payment-settings/stripe-signup'),
)
const JRLEditTeams = lazy(() =>
  import('pages/junior-league/program/teams/edit-teams'),
)
const JRLEditScores = lazy(() =>
  import('pages/junior-league/program/scoring/edit-scores'),
)
const JRLNewPlayDay = lazy(() =>
  import('pages/junior-league/hosted-play-day-new'),
)
const JRLEditPlayDay = lazy(() => import('pages/junior-league/hosted-play-day'))
const JRLReset = lazy(() => import('components/reset'))
const JRLPlayDaySearch = lazy(() =>
  import('pages/junior-league/champ-program/play-day-search'),
)
const JRLPlayDayDetails = lazy(() =>
  import('pages/junior-league/champ-program/play-day-details'),
)
const JRLChampEditTeams = lazy(() =>
  import('pages/junior-league/champ-program/teams/edit-teams'),
)

const JRLChampCreateTeam = lazy(() =>
  import('pages/junior-league/champ-program/teams/create-team'),
)

const JRLKitOrder = lazy(() =>
  import('pages/junior-league/program/fulfillment-orders/kit-order-form'),
)

const LocationsPageRedirect = <Redirect to="/junior-league/locations" />

const JRLNewSectionEvent = withProtectedRoute(
  lazy(() => import('pages/junior-league/hosted-play-day-new')),
  [roles.SECTION_STAFF, roles.PEC, roles.ADMIN],
  LocationsPageRedirect,
)

const JRLEditSectionEvent = withProtectedRoute(
  lazy(() => import('pages/junior-league/hosted-play-day')),
  [roles.SECTION_STAFF, roles.PEC, roles.ADMIN],
  LocationsPageRedirect,
)

const CreateRegionalChampionship = withProtectedRoute(
  lazy(() => import('pages/junior-league/regional-championship-new')),
  [roles.ADMIN, roles.PEC],
  LocationsPageRedirect,
)

const RegionalChampionship = withProtectedRoute(
  lazy(() => import('pages/junior-league/regional-championship')),
  [roles.ADMIN, roles.PEC],
  LocationsPageRedirect,
)

const RegionalChampionshipEditTeam = withProtectedRoute(
  lazy(() => import('pages/junior-league/regional-championship/edit-team')),
  [roles.ADMIN, roles.PEC],
  LocationsPageRedirect,
)

const JuniorLeagueRoutes = () => {
  const { path } = useRouteMatch()
  const { user } = useAuth()

  const menuItems = []

  if (user.jrlSignedUp) {
    menuItems.push(
      {
        icon: <HomeIcon />,
        label: 'Dashboard',
        path: `${path}`,
        exact: true,
      },
      {
        icon: <GolfIcon />,
        label: 'Locations',
        path: `${path}/locations`,
      },
      {
        icon: <PlaylistAddCheckIcon />,
        label: 'Resource Center',
        path: JRL_RESOURCE_CENTER_URL,
        external: true,
      },
      {
        component: ProgramsSubMenu,
        label: 'My Current Programs',
      },
    )
  }

  if (user.programAdmin || user.hasRole(roles.SECTION_STAFF)) {
    menuItems.push({
      icon: <BuildOutlinedIcon />,
      label: 'Admin',
      path: '/admin',
    })
  }

  return (
    <ThemeProvider theme={extendTheme(themes.juniorLeague)}>
      <Helmet>
        <title>PGA Jr. League Management</title>
        <meta name="description" content="PGA Jr. League Management" />
        <link rel="icon" href="/jrl-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Shell
          logo={{ src: logo, alt: 'PGA Jr. League Logo' }}
          menuItems={menuItems}
        >
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route exact path={path} component={JRLDashboard} />
              <Route exact path={`${path}/reset`}>
                <JRLReset isJRL />
              </Route>
              <Route
                exact
                path={`${path}/locations`}
                component={JRLLocations}
              />
              <Route
                exact
                path={`${path}/locations/new`}
                component={JRLLocationsNew}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/edit`}
                component={JRLLocationEdit}
              />
              <Route
                exact
                path={`${path}/locations/:locationId`}
                component={JRLLocationDetails}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/(programs|payment-settings|hosted-play-days|managers|regional-championships|national-championships)`}
                component={JRLLocationDetails}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/new`}
                component={JRLProgramNew}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/new/championship-season`}
                component={JRLChampProgramNew}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/new/regional-championship-program`}
                component={JRLRegionalChampProgramNew}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championship-program/:programId`}
                component={JRLRegionalChampProgramEdit}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championship-program/:programId/(edit|schedule|teams|players|discount-codes)`}
                component={JRLRegionalChampProgramEdit}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/new/national-championship-program`}
              >
                <JRLRegionalChampProgramNew promoted={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championship-program/:programId`}
              >
                <JRLRegionalChampProgramEdit promoted={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championship-program/:programId/(edit|schedule|teams|players|discount-codes)`}
              >
                <JRLRegionalChampProgramEdit promoted={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId`}
                component={JRLProgram}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/(program-details|schedule|teams|scoring|standings|discount-codes|fulfillment-orders|players)`}
                component={JRLProgram}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/fulfillment-orders/new`}
                component={JRLKitOrder}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/fulfillment-orders/:kitOrderId`}
                component={JRLKitOrder}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/schedule/edit`}
              >
                <JRLScheduleEdit />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/schedule/edit/:eventId`}
              >
                <JRLEventEdit />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/teams/edit`}
              >
                <JRLEditTeams />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/scoring/edit/:matchId`}
              >
                <JRLEditScores />
              </Route>
              <Route exact path={`${path}/stripe-signup`}>
                <JRLStripeSignup brand={BRANDS.JUNIOR_LEAGUE} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/hosted-play-days/new`}
                component={JRLNewPlayDay}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/hosted-play-days/:playDayId`}
              >
                <JRLEditPlayDay tab="playday" />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/hosted-play-days/:playDayId/scoring`}
              >
                <JRLEditPlayDay tab="scoring" />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId`}
                component={JRLChampProgram}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/(edit|schedule|teams|players|discount-codes)`}
                component={JRLChampProgram}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/teams/edit/:division(13u|17u)`}
              >
                <JRLChampEditTeams />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/teams/create/:division(13u|17u)`}
              >
                <JRLChampCreateTeam />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/schedule/events/new`}
              >
                <JRLEventNew isChamp={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/schedule/events/:eventId`}
              >
                <JRLEventEdit />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/schedule/play-days/search`}
              >
                <JRLPlayDaySearch />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/championship-season/:programId/schedule/play-days/:playDayId`}
              >
                <JRLPlayDayDetails />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/hosted-play-days/section-event/new`}
              >
                <JRLNewSectionEvent isSectionEvent />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/hosted-play-days/section-event/:playDayId`}
              >
                <JRLEditSectionEvent isSectionEvent />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championships/create`}
              >
                <CreateRegionalChampionship />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championships/:regionalChampionshipId/(regional-details|players|scoring)`}
              >
                <RegionalChampionship />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championships/create`}
              >
                <CreateRegionalChampionship promoted={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championships/:nationalChampionshipId/(national-details|players)`}
              >
                <RegionalChampionship promoted={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championships/:regionalChampionshipId/teams/:teamId`}
              >
                <RegionalChampionshipEditTeam />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championships/:nationalChampionshipId/teams/:teamId`}
              >
                <RegionalChampionshipEditTeam promoted={true} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championship-program/:programId/schedule/events/new`}
              >
                <JRLEventNew isChamp={false} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/regional-championship-program/:programId/schedule/events/:eventId`}
              >
                <JRLEventEdit />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championship-program/:programId/schedule/events/new`}
              >
                <JRLEventNew isChamp={false} />
              </Route>
              <Route
                exact
                path={`${path}/locations/:locationId/national-championship-program/:programId/schedule/events/:eventId`}
              >
                <JRLEventEdit />
              </Route>
            </Switch>
          </Suspense>
        </Shell>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(
  withJuniorLeagueAccess(JuniorLeagueRoutes),
  roles.COACH,
)
