import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { useTheme } from '@material-ui/core/styles'

const datePickerSlotProps = (
  error = false,
  helperText,
  theme,
  fullWidth = false,
  placeholder,
  name,
  id,
  dataTestId,
) => {
  const styleProps = {
    '& button.MuiPickersYear-yearButton.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
    '& button.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }

  return {
    textField: {
      id: id,
      error: error,
      helperText: helperText,
      fullWidth: fullWidth,
      placeholder: placeholder,
      name: name,
      'data-testid': dataTestId,
      sx: {
        '& .MuiFormLabel-root.Mui-focused': {
          color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiFormHelperText-root.Mui-error, & .MuiFormLabel-root.Mui-error': {
          color: theme.palette.error.main,
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
    },
    popper: {
      sx: styleProps,
    },
    dialog: {
      sx: styleProps,
    },
  }
}

const PgaKeyboardDatePicker = props => {
  const {
    id,
    label,
    value,
    onChange,
    format,
    error,
    helperText,
    disableFuture,
    fullWidth,
    placeholder,
    name,
    ...rest
  } = props
  const theme = useTheme()
  const dataTestId = props['data-testid']
  return (
    <DatePicker
      label={label}
      value={value}
      format={format}
      onChange={onChange}
      disableFuture={disableFuture}
      slotProps={datePickerSlotProps(
        error,
        helperText,
        theme,
        fullWidth,
        placeholder,
        name,
        id,
        dataTestId,
      )}
      dayOfWeekFormatter={day => `${day}`}
      {...rest}
    />
  )
}

export default PgaKeyboardDatePicker
