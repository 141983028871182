import React, { useState } from 'react'
import {
  Controller,
  useForm,
  FormProvider,
  useFormContext,
} from 'react-hook-form'
import { Helmet } from 'react-helmet'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import FacilitiesAlgolia from 'components/coaches-profile/profile-components/FacilitiesAlgolia'
import usStates from 'utils/usStates'
import timezones, { popularTimezones } from 'utils/timezones'
import { FormActionBar, CtrlTextField } from 'components/form-util'
import ContentSection from 'components/content-section'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import DeleteFacilityDialog from 'pages/pga-coach/facilities/delete-facility-dialog'
import { useHistory } from 'react-router-dom'
import Disclaimer from 'components/disclaimer'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useIsMobile } from 'lib/hooks'
import { useAuth } from 'lib/auth'

const validationSchema = yup.object().shape({
  facility: yup.object().shape(
    {
      name: yup
        .string()
        .label('Facility Name')
        .required(),
      address1: yup
        .string()
        .nullable()
        .label('Address')
        .when(['city', 'state', 'zip'], {
          is: (city, state, zip) => city || state || zip,
          then: yup.string().required(),
        }),
      city: yup
        .string()
        .nullable()
        .label('City')
        .when(['address1', 'state', 'zip'], {
          is: (address1, state, zip) => address1 || state || zip,
          then: yup.string().required(),
        }),
      state: yup
        .string()
        .nullable()
        .label('State')
        .when(['address1', 'city', 'zip'], {
          is: (address1, city, zip) => address1 || city || zip,
          then: yup.string().required(),
        }),
      zip: yup
        .string()
        .nullable()
        .label('Zip Code')
        .when(['address1', 'city', 'state'], {
          is: (address1, city, state) => address1 || city || state,
          then: yup.string().required(),
        }),
      timezone: yup
        .string()
        .nullable()
        .label('Time Zone'),
      phone: yup
        .string()
        .nullable()
        .label('Phone Number'),
      url: yup
        .string()
        .url()
        .nullable()
        .label('Website'),
    },
    [
      ['address1', 'city'],
      ['address1', 'state'],
      ['address1', 'zip'],
      ['city', 'state'],
      ['city', 'zip'],
      ['state', 'zip'],
    ],
  ),
})

const popularTimezonesLength = popularTimezones.length

const useStyles = makeStyles(theme => ({
  facilityInput: {
    marginBottom: `${theme.spacing(2)}px`,
  },
  listbox: {
    [`& li:nth-child(${popularTimezonesLength})`]: {
      borderBottom: '2px solid black',
    },
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  radio: {
    marginRight: theme.spacing(2),
  },
  deleteButton: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  disabledRadio: {
    pointerEvents: 'none',
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'underline',
  },
}))

const FormSections = ({
  facility,
  control,
  register,
  setFacility,
  errors,
  preventExternalFacilityEdit,
  facilityPersisted = false,
  clearSelectedFacility,
  setValue,
  isJRL,
}) => {
  const styles = useStyles()
  const isMobile = useIsMobile()
  const { user } = useAuth()
  const hasCoachProfile = user?.coach?.coachProfile
  const { watch } = useFormContext()
  const [isVisible, setIsVisible] = useState(
    facility ? watch('facility.visible') : true,
  )
  const isPrivate = watch('facility.private')
  const isPrimary = facility
    ? facility?.coachProfilesFacilities[0]?.facilityRank === 1
    : false

  return (
    <Box>
      {hasCoachProfile && !isJRL && (
        <ContentSection title="Coach Profile Visibility">
          {isPrimary && (
            <Disclaimer
              icon={<InfoOutlinedIcon style={{ fontSize: '32px' }} />}
              size="small"
              description="It is required for the primary facility to be visible. To edit the visibility, you must first set another facility to be primary."
            />
          )}
          <input
            name="facility.visible"
            type="hidden"
            ref={register}
            control={control}
          />
          <FormControl>
            <RadioGroup aria-labelledby="Coach Profile Visibility">
              <FormControlLabel
                checked={isVisible}
                disabled={isPrimary}
                classes={{ disabled: styles.disabledRadio }}
                control={<Radio color="primary" className={styles.radio} />}
                label={
                  <Typography variant="subtitle1">Show Facility</Typography>
                }
                onClick={() => {
                  setIsVisible(!isVisible)
                  setValue('facility.visible', true, {
                    shouldDirty: true,
                  })
                }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                style={{ marginLeft: '48px' }}
              >
                Choose to display this facility on your coach profile and set as
                your primary facility (optional).
              </Typography>
              <Box ml={isMobile ? 0 : 5} display={isVisible ? 'block' : 'none'}>
                <Controller
                  name="facility.primary"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <FormControlLabel
                      label={
                        <Typography variant="subtitle1">
                          Set as Primary (Optional)
                        </Typography>
                      }
                      disabled={isPrimary}
                      control={
                        <Checkbox
                          color="primary"
                          className={styles.radio}
                          name={name}
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  gutterBottom
                  style={{ marginLeft: '48px' }}
                >
                  Your primary facility is used for location-based searches on
                  pga.com. For optimal search results, your service location
                  should match the primary facility.
                </Typography>
              </Box>
              <FormControlLabel
                checked={!isVisible}
                disabled={isPrimary}
                classes={{ disabled: styles.disabledRadio }}
                control={<Radio color="primary" className={styles.radio} />}
                label={
                  <Typography variant="subtitle1">Hide Facility</Typography>
                }
                onClick={() => {
                  setIsVisible(!isVisible)
                  setValue('facility.visible', false, {
                    shouldDirty: true,
                  })
                  setValue('facility.primary', false)
                }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                style={{ marginLeft: '48px' }}
              >
                Choose to hide this facility on your coach profile. Any booking
                types associated with this facility will also be hidden on your
                booking page.
              </Typography>
            </RadioGroup>
          </FormControl>
        </ContentSection>
      )}
      <ContentSection title="Facility Information">
        {preventExternalFacilityEdit && (
          <Box mr={2} mb={3}>
            <Disclaimer
              icon={<InfoOutlinedIcon style={{ fontSize: '32px' }} />}
              size="small"
              description={
                <>
                  Editing this pre-existing facility is disabled. If you feel
                  this information is incorrect, please email the team to update
                  the information at{' '}
                  <Link
                    href="mailto:membership@pgahq.com"
                    className={styles.link}
                  >
                    membership@pgahq.com
                  </Link>
                  .
                </>
              }
            />
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Controller
              name="facility.name"
              control={control}
              render={({ name, value, ref, onChange, onBlur }) =>
                facilityPersisted ? (
                  <TextField
                    required
                    name={name}
                    data-cy="facility.name"
                    label="Facility Name"
                    variant="outlined"
                    className={styles.facilityInput}
                    error={!!errors.facility?.name}
                    helperText={errors.facility?.name?.message}
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    autoComplete="off"
                    disabled={preventExternalFacilityEdit && facility}
                  />
                ) : (
                  <FacilitiesAlgolia
                    name={name}
                    onSelect={setFacility}
                    value={value}
                    onBlur={onBlur}
                    error={!!errors.facility?.name}
                    helperText={errors.facility?.name?.message}
                    inputVariant="outlined"
                    onChange={onChange}
                  />
                )
              }
            />
            <input
              name="facility.externalFacilityId"
              type="hidden"
              ref={register}
            />
            {preventExternalFacilityEdit && !facility && (
              <Button
                variant="outlined"
                size="large"
                color="primary"
                style={{ margin: '20px 0px' }}
                onClick={clearSelectedFacility}
              >
                Clear Selection
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Controller
              name="facility.address1"
              control={control}
              render={({ name, value, ref, onChange }) => (
                <TextField
                  required
                  name={name}
                  data-cy="facility.address1"
                  label="Address"
                  variant="outlined"
                  className={styles.facilityInput}
                  error={!!errors.facility?.address1}
                  helperText={errors.facility?.address1?.message}
                  inputRef={ref}
                  disabled={preventExternalFacilityEdit}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Controller
              name="facility.address2"
              control={control}
              render={({ name, value, ref, onChange }) => (
                <TextField
                  name={name}
                  data-cy="facility.address2"
                  label="Address 2"
                  variant="outlined"
                  className={styles.facilityInput}
                  error={!!errors.facility?.address2}
                  helperText={errors.facility?.address2?.message}
                  inputRef={ref}
                  disabled={preventExternalFacilityEdit}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={2} sm={12} xs={12}>
            <Controller
              name="facility.city"
              control={control}
              render={({ name, value, ref, onChange }) => (
                <TextField
                  required
                  name={name}
                  data-cy="facility.city"
                  label="City"
                  variant="outlined"
                  className={styles.facilityInput}
                  error={!!errors.facility?.city}
                  helperText={errors.facility?.city?.message}
                  inputRef={ref}
                  disabled={preventExternalFacilityEdit}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Controller
              name="facility.state"
              control={control}
              render={({ name, value, ref, onChange }) => (
                <Autocomplete
                  name={name}
                  data-cy="facility.state"
                  disableClearable
                  required
                  value={value}
                  options={usStates}
                  onChange={(e, value) => onChange(value)}
                  inputRef={ref}
                  disabled={preventExternalFacilityEdit}
                  className={styles.facilityInput}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      error={!!errors.facility?.state}
                      helperText={errors.facility?.state?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <CtrlTextField
              name="facility.zip"
              data-cy="facility.zip"
              control={control}
              errors={errors}
              disabled={preventExternalFacilityEdit}
              label="Zip"
              required
              variant="outlined"
              className={styles.facilityInput}
            />
          </Grid>
        </Grid>
        {!preventExternalFacilityEdit && (
          <Grid container spacing={2}>
            <Grid item md={4} sm={12} xs={12}>
              <Controller
                name="facility.timezone"
                control={control}
                render={({ name, value, ref, onChange }) => (
                  <Autocomplete
                    name={name}
                    data-cy="facility.timezone"
                    disableClearable
                    required
                    value={value}
                    options={timezones}
                    onChange={(e, value) => onChange(value)}
                    inputRef={ref}
                    disabled={preventExternalFacilityEdit}
                    className={styles.facilityInput}
                    classes={{
                      listbox: styles.listbox,
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Time Zone"
                        variant="outlined"
                        error={!!errors.facility?.timezone}
                        helperText={errors.facility?.timezone?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="facility.phone"
              control={control}
              render={({ name, value, ref, onChange }) => (
                <TextField
                  name={name}
                  data-cy="facility.phone"
                  label="Phone (optional)"
                  variant="outlined"
                  className={styles.facilityInput}
                  error={!!errors.facility?.phone}
                  helperText={errors.facility?.phone?.message}
                  inputRef={ref}
                  disabled={preventExternalFacilityEdit}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="facility.url"
              control={control}
              render={({ name, value, ref, onChange }) => (
                <TextField
                  name={name}
                  data-cy="facility.url"
                  label="Website (optional)"
                  variant="outlined"
                  className={styles.facilityInput}
                  error={!!errors.facility?.url}
                  helperText={
                    errors.facility?.url?.message ||
                    'Enter the full address (https://examplegolfcourse.com)'
                  }
                  inputRef={ref}
                  disabled={preventExternalFacilityEdit}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="facility.private"
              control={control}
              render={({ onChange, value, name }) => (
                <FormControlLabel
                  label="This is a private facility"
                  control={
                    <Checkbox
                      color="primary"
                      name={name}
                      checked={value}
                      disabled={preventExternalFacilityEdit}
                      onChange={e => onChange(e.target.checked)}
                    />
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        {hasCoachProfile && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="facility.offersCoachingAtPrivateClub"
                control={control}
                render={({ onChange, value, name }) =>
                  isPrivate ? (
                    <FormControlLabel
                      label="I will offer coaching to the public at this facility"
                      control={
                        <Checkbox
                          color="primary"
                          name={name}
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  ) : null
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentSection>
    </Box>
  )
}

const FacilitiesForm = ({
  facility,
  facilityPersisted,
  preventExternalFacilityEdit,
  externalFacilityId,
  onSubmit,
  loading,
  isFirstFacility,
  isJRL,
}) => {
  const defaultValues = facility
    ? {
      facility: {
        name: facility.name,
        address1: facility.address1,
        address2: facility.address2,
        city: facility.city,
        state: facility.state,
        zip: facility.zip,
        phone: facility.phone,
        url: facility.url,
        private: facility.private,
        timezone: facility.timezone,
        externalFacilityId: facility.externalFacilityId,
        visible: facility?.coachProfilesFacilities[0]?.visible || false,
        primary: facility?.coachProfilesFacilities[0]?.facilityRank === 1,
        offersCoachingAtPrivateClub:
          facility?.coachProfilesFacilities[0]?.offersCoachingAtPrivateClub ||
          false,
      },
    }
    : {
      facility: {
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        url: '',
        private: false,
        visible: true,
        primary: isFirstFacility,
        offersCoachingAtPrivateClub: false,
      },
    }

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
  const styles = useStyles()
  const history = useHistory()
  const isPrimary = facility?.coachProfilesFacilities[0]?.facilityRank === 1
  const [facilitySelected, setFacilitySelected] = useState(externalFacilityId)

  const {
    register,
    errors,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState,
  } = form

  const formValues = getValues()

  const setFacility = hit => {
    const {
      name,
      address1,
      city,
      state,
      zip,
      phone,
      website: url,
      operations_type_label: typeLabel,
      facility_id: externalFacilityId,
    } = hit

    setValue(
      'facility',
      {
        ...formValues,
        name,
        address1,
        city,
        state,
        zip,
        phone,
        externalFacilityId,
        url: url && !/^https?:\/\//i.test(url) ? `http://${url}` : url,
        private:
          typeLabel === 'Private Equity' || typeLabel === 'Private Non-Equity',
        customData: {
          ...formValues.customData,
        },
      },
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    )

    setFacilitySelected(externalFacilityId)
  }

  const clearSelectedFacility = () => {
    setValue('facility', {
      ...formValues,
      externalFacilityId: null,
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      url: '',
      private: false,
      visible: false,
      primary: false,
      offersCoachingAtPrivateClub: false,
      customData: {
        ...formValues.customData,
      },
    })

    setFacilitySelected(null)
  }

  return (
    <>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <FormActionBar
        instructions={errors.length > 0 && 'Form has one or more errors'}
        error={errors.length > 0}
        stickToBottom={isJRL}
      >
        <div className={styles.buttonWrapper}>
          <Button
            onClick={() =>
              isJRL
                ? history.push('/admin/facilities')
                : facility
                  ? history.push(
                    `/pga-coach/settings/facilities/${facility.id}`,
                  )
                  : history.push('/pga-coach/facilities')
            }
            variant="text"
            style={{ marginRight: '16px' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isDirty}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              className={styles.buttonProgress}
              color="secondary"
            />
          )}
        </div>
      </FormActionBar>
      <Container>
        <FormProvider {...form}>
          <FormSections
            facility={facility}
            control={control}
            setFacility={setFacility}
            register={register}
            errors={errors}
            preventExternalFacilityEdit={
              !!facilitySelected && preventExternalFacilityEdit
            }
            facilityPersisted={!!facilityPersisted}
            clearSelectedFacility={clearSelectedFacility}
            setValue={setValue}
            isJRL={isJRL}
          />
        </FormProvider>
        {facility && (
          <DeleteFacilityDialog
            facilityId={facility.id}
            onSuccess={() => history.push('/pga-coach/facilities')}
            trigger={
              <Button
                variant="text"
                classes={{
                  root: styles.deleteButton,
                  disabled: styles.disabled,
                }}
                disabled={isPrimary}
              >
                DELETE FACILITY
              </Button>
            }
          />
        )}
      </Container>
    </>
  )
}

export default FacilitiesForm
