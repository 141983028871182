import { styled } from '@material-ui/core/styles'

const LegalDoc = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  font: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  '& h5': {
    fontSize: 16,
  },
  '& a': {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
  },
}))

export default LegalDoc
