import { useAuth } from 'lib/auth'
import NotFound from 'components/not-found.js'

// Areas of improvement:
// --> Callback for authorization logic.
// --> Fallback display page.
export default function SensitiveInfoWrapper({ children, enableFallback }) {
  const { user } = useAuth()

  if (user == null || user.hideSensitiveInformation) {
    return enableFallback ? (
      <NotFound
        subtext="You don't have permission to do this!"
        buttonLabel="Go home"
      />
    ) : null
  }

  return <>{children}</>
}
