import useTheme from '@material-ui/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useIsTablet = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  })

  return isTablet
}

export default useIsTablet
