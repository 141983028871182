import React, { useState } from 'react'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'
import NotFound from 'components/not-found.js'
import { PageHeader } from 'components/page-header'
import { LinearProgress, Container, Tabs, Tab } from '@material-ui/core'
import { useGetPracticeSessionTypes } from './query'
import propTypes from 'prop-types'

import { RefetchContext } from 'lib/utils/refetchContext'
import ReservationsList from './reservations-list'

const ReservationsShowPage = () => {
  const [selectedTab, setSelectedTab] = useState('upcoming')

  const { loading, error, data, refetch } = useGetPracticeSessionTypes({
    slug: FRISCO_COACHING_CENTER_SLUG,
  })
  const { academy } = data || {}

  if (loading) return <LinearProgress />
  if (error) {
    return <NotFound returnUrl={`${FRISCO_COACHING_CENTER_SLUG}`} />
  }

  return (
    <RefetchContext.Provider value={refetch}>
      <PageHeader
        title="Bay Reservation"
        tabs={
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
          >
            <Tab label="Upcoming" value="upcoming" />
            <Tab label="Past" value="past" />
          </Tabs>
        }
      />
      <Container>
        <ReservationsList academy={academy} selectedTab={selectedTab} />
      </Container>
    </RefetchContext.Provider>
  )
}
export default ReservationsShowPage

ReservationsShowPage.propTypes = {
  academy: propTypes.shape({
    id: propTypes.string,
    name: propTypes.string,
    facilities: propTypes.arrayOf(
      propTypes.shape({
        timezone: propTypes.string,
      }),
    ),
    practiceSessionTypes: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string,
        title: propTypes.string,
        description: propTypes.string,
        priceInCents: propTypes.number,
        spaces: propTypes.arrayOf(
          propTypes.shape({
            id: propTypes.string,
            name: propTypes.string,
            reservations: propTypes.arrayOf(
              propTypes.shape({
                id: propTypes.string,
                startDateTime: propTypes.string,
                endDateTime: propTypes.string,
                reason: propTypes.string,
                spaceName: propTypes.string,
                student: propTypes.shape({
                  id: propTypes.string,
                  firstName: propTypes.string,
                  lastName: propTypes.string,
                }),
              }),
            ),
          }),
        ),
      }),
    ),
  }),
}
