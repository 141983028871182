import React, { lazy, Suspense } from 'react'
import { withProtectedRoute, roles } from 'lib/auth'
import { Helmet } from 'react-helmet'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SnackbarProvider } from 'notistack'
import Shell from 'components/shell/shell'
import themes, { extendTheme } from 'themes'
import { ReactComponent as HomeIcon } from 'images/icons/home.svg'
import logo from 'images/jr-league-logo.svg'
import withJuniorLeagueAccess from 'lib/junior-league-utils/with-junior-league-access'

const JRLManagerDashboard = lazy(() =>
  import('pages/junior-league-manager/dashboard'),
)
const RequirementsPage = lazy(() => import('components/requirements'))

const JuniorLeagueManagerRoutes = () => {
  const { path } = useRouteMatch()

  const menuItems = [
    {
      icon: <HomeIcon />,
      label: 'Dashboard',
      path: `${path}`,
      exact: true,
    },
  ]

  return (
    <ThemeProvider theme={extendTheme(themes.juniorLeague)}>
      <Helmet>
        <title>PGA Jr. League Management</title>
        <meta name="description" content="PGA Jr. League Management" />
        <link rel="icon" href="/jrl-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Shell
          logo={{ src: logo, alt: 'PGA Jr. League Logo' }}
          menuItems={menuItems}
        >
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route exact path={path} component={JRLManagerDashboard} />
              <Route
                exact
                path={`${path}/requirements`}
                component={RequirementsPage}
              />
            </Switch>
          </Suspense>
        </Shell>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(
  withJuniorLeagueAccess(JuniorLeagueManagerRoutes),
  roles.CONSUMER,
)
