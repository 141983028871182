import React, { useEffect } from 'react'
import { AUTH_ROOT } from 'env'
import LinearProgress from '@material-ui/core/LinearProgress'

const redirectToLogin = (role, returnTo = window.location.href) => {
  const urlParams = new URLSearchParams({
    return_to: returnTo,
  })
  if (role === 'consumer') {
    // redirect to the consumer login page
    window.location = `${AUTH_ROOT}/login?${urlParams}`
  } else if (role === 'coach') {
    // redirect to the coach login page
    window.location = `${AUTH_ROOT}/v2/login?${urlParams}`
  }
}

const LoginRedirect = ({ role, returnTo }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    redirectToLogin(role, returnTo)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <LinearProgress color="secondary" />
}

export default LoginRedirect
export { redirectToLogin }
