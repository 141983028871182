import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  contentRoot: {
    padding: '8px 24px 24px 24px',
    marginBottom: '8px',
  },
  eventRow: {
    margin: '20px 0',
  },
  headerTitleFont: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    fontWeight: 900,
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  headerImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    objectPosition: 'bottom',
  },
  eventRowIcon: {
    marginRight: '10px',
  },
  bodyFontCaption: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  copyTextButtonStyled: {
    textTransform: 'unset',
    fontSize: 'unset',
    padding: 'unset',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
  },
  actionButtonContainer: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
  },
  actionAvatar: {
    backgroundColor: 'white',
    cursor: 'pointer',
    margin: '0 5px',
  },
  qrCode: {
    border: '1px solid #AB9157',
    borderRadius: '4px',
    padding: '10px',
  },
}))
