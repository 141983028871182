import ContentSection from 'components/content-section'
import { Typography } from '@material-ui/core'
import { useStyles } from '../styles'

export default function AdditionalInformation({ student }) {
  const classes = useStyles()
  const { pgaHopeProfile } = student

  return (
    <ContentSection title="Additional Information">
      {pgaHopeProfile?.additionalInfo ? (
        <Typography>{pgaHopeProfile.additionalInfo}</Typography>
      ) : (
        <Typography className={classes.secondaryText}>
          No information provided.
        </Typography>
      )}
    </ContentSection>
  )
}
