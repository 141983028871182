import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { UPDATE_FACILITY, GET_FACILITY } from 'components/facilities/api-hooks'
import FacilitiesForm from 'components/facilities/form'
import LinearProgress from '@material-ui/core/LinearProgress'

const FacilityEdit = () => {
  const { id: facilityId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [updateFacility, { loading: updatingFacility }] = useMutation(
    UPDATE_FACILITY,
  )
  const { loading, data } = useQuery(GET_FACILITY, {
    variables: { facilityId },
  })

  const history = useHistory()

  const { path } = useRouteMatch()

  const facilityPath = path
    .split('/')
    .slice(0, 4)
    .join('/')
  const crumbs = [
    {
      label: 'Facilities',
      to: facilityPath,
    },
  ]
  crumbs.splice(1, 0, {
    label: 'Settings',
    to: path
      .split('/')
      .slice(0, 3)
      .join('/'),
  })

  if (loading) return <LinearProgress color="secondary" />
  const { facility } = data

  const onSubmit = async ({ facility }) => {
    try {
      await updateFacility({
        variables: {
          facilityId: facilityId,
          facility: {
            name: facility.name,
            address1: facility.address1 || null,
            address2: facility.address2 || null,
            city: facility.city || null,
            state: facility.state || null,
            zip: facility.zip || null,
            timezone: !facility.externalFacilityId
              ? facility.timezone || null
              : null,
            phone: facility.phone || null,
            url: facility.url || null,
            externalFacilityId: facility.externalFacilityId || null,
            private: facility.private,
          },
          primary: facility.primary,
          visible:
            facility.visible === undefined ? null : facility.visible === 'true',
          offersCoachingAtPrivateClub: facility.offersCoachingAtPrivateClub,
        },
      })
      enqueueSnackbar('Facility Updated', { variant: 'success' })
      history.push('/pga-coach/facilities')
    } catch (e) {
      enqueueSnackbar('There was an error when creating this facility', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <PageHeader title="Edit Facility" fixedMobileActions />
      <FacilitiesForm
        facility={facility}
        onSubmit={onSubmit}
        preventExternalFacilityEdit={true}
        loading={updatingFacility}
        externalFacilityId={facility.externalFacilityId}
        facilityPersisted={!!facility.id}
      />
    </>
  )
}

export default FacilityEdit
