import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import BookingTypeFormWithLessonPacks from 'components/booking-type-form'
import Error from 'components/error'
import useBookingTypeFormData from '../useBookingTypeFormData'

const NewBookingTypePage = () => {
  const { data: formData, loading, error } = useBookingTypeFormData()

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} displayErrorType="page" />
  return <BookingTypeFormWithLessonPacks {...formData} />
}

export default NewBookingTypePage
