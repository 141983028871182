import { flatten, flow, groupBy, map, sortBy } from 'lodash/fp'
import capitalize from 'lodash/capitalize'

export const LINE_ITEM_GROUPS = {
  PGA_FEES: {
    value: 'PGA_FEES',
    label: 'PGA Fees',
    order: 1,
  },
  RENTAL_FEES: {
    value: 'RENTAL_FEES',
    label: 'Rental Fees',
    order: 2,
  },
  COACHING_FEES: {
    value: 'COACHING_FEES',
    label: 'Coaching Fees',
    order: 3,
  },
  DISCOUNT: {
    value: 'DISCOUNT',
    label: 'Discounts',
    order: 4,
  },
  OTHER: {
    value: 'OTHER',
    label: 'Taxes and Other Fees',
    order: 5,
  },
  DONATION: {
    value: 'DONATION',
    label: 'Donations',
    order: 6,
  },
}

export const LINE_ITEM_TYPES = {
  COACHING_FEE: {
    label: 'Coaching Fee',
    group: LINE_ITEM_GROUPS.COACHING_FEES.value,
    value: 'COACHING_FEE',
    refundable: true,
  },
  DISCOUNT: {
    label: 'Discount Code',
    group: LINE_ITEM_GROUPS.DISCOUNT.value,
    value: 'DISCOUNT',
    refundable: false,
  },
  OFFLINE_FEE: {
    label: 'Coaching Fee (Offline)',
    group: LINE_ITEM_GROUPS.COACHING_FEES.value,
    value: 'OFFLINE_FEE',
    refundable: false,
  },
  PGA_FEE: {
    label: 'PGA Membership Fee',
    group: LINE_ITEM_GROUPS.PGA_FEES.value,
    value: 'PGA_FEE',
    refundable: true,
  },
  PROCESSING_FEE: {
    label: 'Fee',
    group: LINE_ITEM_GROUPS.OTHER.value,
    value: 'PROCESSING_FEE',
    refundable: true,
  },
  TAXES: {
    label: 'Taxes',
    group: LINE_ITEM_GROUPS.OTHER.value,
    value: 'TAXES',
    refundable: true,
  },
  DONATION: {
    label: 'Donation',
    group: LINE_ITEM_GROUPS.DONATION.value,
    value: 'DONATION',
    refundable: true,
  },
  RENTAL_FEE: {
    label: 'Rental Fee',
    group: LINE_ITEM_GROUPS.RENTAL_FEES.value,
    value: 'RENTAL_FEE',
    refundable: true,
  },
}

const enrichLineItems = ({ lineItems, createdAt, refund }) =>
  lineItems.map(line => ({
    createdAt,
    refund,
    ...line,
    ...LINE_ITEM_TYPES[line.lineType],
  }))

export const sumField = field => (acc, item) => acc + item[field]
export const sumAmountInCents = sumField('amountInCents')

const lineItemGroups = lineItems => {
  const [{ group }] = lineItems
  const subtotal = lineItems.reduce(sumAmountInCents, 0)
  const refundableSubtotal = lineItems
    .filter(lineItem => lineItem.refundable)
    .reduce(sumAmountInCents, 0)
  return {
    group,
    lineItems,
    order: LINE_ITEM_GROUPS[group].order,
    refundableSubtotal,
    subtotal,
    title: LINE_ITEM_GROUPS[group].label,
  }
}

export const groupLineItems = flow(
  map(enrichLineItems),
  flatten,
  groupBy('group'),
  map(lineItemGroups),
  sortBy('order'),
)

const transactionTypes = {
  Enrollment: 'Enrollment',
  LessonPack: 'Lesson Pack',
  FamilyCupProgram: 'Family Cup Program',
  JRLKitOrder: 'Kit Order',
  JRLProgramEnrollment: 'PGA Jr. League Program Enrollment',
  FamilyCupProgramEnrollment: 'Family Cup Program Enrollment',
  FamilyGolfProgram: 'Family Golf Program',
  FamilyGolfProgramEnrollment: 'Family Golf Program Enrollment',
}

export const getTransactionType = ({ __typename } = {}) => {
  const transactionType = transactionTypes[__typename]
  return transactionType || ''
}

export const getProductName = product => {
  switch (product.__typename) {
    case 'Enrollment': {
      const { lesson } = product
      return lesson.title
    }
    case 'LessonPack': {
      const { lessonType, lessonPackOffering } = product
      return `${lessonType.title} | Pack of ${lessonPackOffering?.quantity ??
        'Unknown quantity'}`
    }
    case 'JRLProgramEnrollment': {
      const { player, program } = product
      return `${program.name} for ${player.fullName}`
    }
    case 'JRLKitOrder': {
      const { category, program } = product
      return `${capitalize(category)} Kit Order for ${program.name}`
    }
    case 'FamilyCupProgramEnrollment': {
      const { program, captain } = product
      return `${program?.name || 'Family Cup Program'} for ${
        captain.firstName
      } ${captain.lastName}`
    }
    case 'FamilyGolfProgramEnrollment': {
      const { program, captain } = product
      return `${program?.name || 'Family Golf Program'} for ${
        captain.firstName
      } ${captain.lastName}`
    }
    default: {
      const { name } = product
      return name
    }
  }
}
