import gql from 'graphql-tag'

export const inviteToPlatformMutation = gql`
  mutation SendConnectionInvitation($studentIds: [ID!]!) {
    SendConnectionInvitation(studentIds: $studentIds) {
      message
      success
    }
  }
`

export const deleteCoachConnectionMutation = gql`
  mutation DeleteCoachConnection($studentId: ID!) {
    deleteCoachConnection(studentId: $studentId) {
      message
      success
    }
  }
`

export const coachingLeadViewedMutation = gql`
  mutation CoachingLeadViewed($id: ID!) {
    CoachingLeadViewed(inquiryId: $id) {
      message
      success
      leadId
    }
  }
`
