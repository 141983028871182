import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'
import themes, { extendTheme } from 'themes'
import CoachAppBar from 'components/coach-app-bar'
import { withAuthenticatedRoute } from 'lib/auth'

const PaymentRequestShell = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <Helmet>
        <title>MyPGA | Club</title>
        <meta name="description" content="MyPGA" />
        <script async src="https://js.stripe.com/v3/"></script>
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <CoachAppBar position="relative" hideContactButton />
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  )
}
export default withAuthenticatedRoute(PaymentRequestShell)
