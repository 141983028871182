import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '150',
    overflow: 'visible',
  },
  coachAvatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '110px',
    height: '110px',
    marginTop: '0.25rem',
    fontSize: '2.5rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
    [theme.breakpoints.down('xs')]: {
      marginRight: `${theme.spacing(0)} !important`,
      marginBottom: theme.spacing(2),
    },
  },
  subtext: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '25px',
  },
  disableSpacing: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  coachInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '10rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  link: {
    color: '#3268A7',
    textDecoration: 'underline',
  },
  proceedingText: {
    marginTop: '2rem',
  },
}))
