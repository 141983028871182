import { Divider, Grid, ThemeProvider } from '@material-ui/core'
import VerifyAccessCode from 'components/verify-access-code'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useStyles } from './styles'
import TimeSlotSelect from './time-slot-select'
import CoachLessonHeader from '../components/CoachLessonHeader'
import themes, { extendTheme } from 'themes'
import { aggregateByDate } from './utils'
import BackButton from 'components/back-button'
import { useIsMobile } from 'lib/hooks'
import PropTypes from 'prop-types'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import StepDescriptionOfferings from '../components/step-description-offerings'
import { ContainerRoot } from '../components/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import OverwriteAvailabilityDialog from './overwrite-availability-dialog'
import Button from '@material-ui/core/Button'
import { useAuth } from 'lib/auth'

const SelectTimeLayout = ({
  lessonTypes,
  currentUrl,
  previousUrl,
  refetch,
  setShowRequestTime,
  lessonCreditBalanceQuantity,
}) => {
  const isMobile = useIsMobile()
  const { user } = useAuth()
  const { idOrSlug } = useParams()

  const timezone = get(lessonTypes, ['0', 'facilities', '0', 'timezone'])
  const timeSlots = get(lessonTypes, ['0', 'availableTimes']).map(
    t => t.startDateTime,
  )
  const datesWithAvailability = Object.keys(
    aggregateByDate(timeSlots, timezone),
  ).sort()
  const firstAvailableDate = DateTime.fromISO(
    datesWithAvailability[0] || DateTime.now(),
    {
      zone: timezone,
    },
  )
  const [selectedDate, setSelectedDate] = useState(firstAvailableDate)
  const coach = lessonTypes[0].coach
  const requiresAccessCode = get(lessonTypes, '0.requiresAccessCode')
  const location = useLocation()
  const [verifiedAccessCode, setVerifiedAccessCode] = useState(
    !requiresAccessCode || location?.state?.verifiedAccessCode,
  )
  const classes = useStyles()
  const description =
    'Find a day and time that works best for your schedule below. The available times are affiliated with the time zone of the facility.'
  const allowedToOverwriteSchedule =
    user?.coach?.externalId === idOrSlug ||
    user?.coach?.coachProfile?.slug === idOrSlug
  const durationInMinutes = get(lessonTypes, ['0', 'durationInMinutes'])

  const eventObject = {
    event: 'select-booking-time',
    title: get(lessonTypes, [0, 'title']),
    category: get(lessonTypes, [0, 'category']),
    facility: get(lessonTypes, ['0', 'facilities', '0', 'name']),
    location: `${get(lessonTypes, ['0', 'facilities', '0', 'city'])}, ${get(
      lessonTypes,
      ['0', 'facilities', '0', 'state'],
    )}`,
    price: `${get(lessonTypes, ['0', 'priceInCents']) / 100}`,
    event_date: null,
    event_time: null,
    duration: get(lessonTypes, [0, 'durationInMinutes']),
    coach_id: get(lessonTypes, ['0', 'coach', 'id']),
    coach_name: get(lessonTypes, ['0', 'coach', 'name']),
    access_code_required: get(lessonTypes, [0, 'requiresAccessCode'])
      ? 'yes'
      : 'no',
  }

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <ContainerRoot style={{ marginBottom: '4rem' }}>
        <ProgressIndicator value={25} />
        <BackButton previousUrl={previousUrl} />
        <CoachLessonHeader
          lessonType={lessonTypes.length && lessonTypes[0]}
          coach={coach}
        />
        <Divider variant="fullWidth" />
        <Grid container direction={isMobile ? 'row' : 'row-reverse'}>
          <Grid item xs={12} style={{ marginTop: '2rem' }}>
            <StepDescriptionOfferings
              stepText="Step 1 of 3"
              title="Choose a Day and Time"
              description={description}
            />
            <Grid container item spacing={3} className={classes.timeSlotStyles}>
              <TimeSlotSelect
                data={lessonTypes}
                coach={lessonTypes[0].coach}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                currentUrl={currentUrl}
                refetch={refetch}
                setShowRequestTime={setShowRequestTime}
                eventObject={eventObject}
              />
              {allowedToOverwriteSchedule && (
                <Grid item xs={12} md={5}>
                  <Box>
                    <Typography variant="body1">
                      Don't see the time you're looking for? Override your
                      availability by adding a custom time.
                    </Typography>
                    <OverwriteAvailabilityDialog
                      trigger={
                        <Button style={{ marginLeft: '-24px' }} color="primary">
                          Add Custom Time
                        </Button>
                      }
                      currentUrl={currentUrl}
                      durationInMinutes={durationInMinutes}
                      timezone={timezone}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <VerifyAccessCode
          lessonType={lessonTypes[0]}
          isOpen={!verifiedAccessCode && !lessonCreditBalanceQuantity}
          setIsOpen={setVerifiedAccessCode}
          coachName={coach.name}
          setVerifiedAccessCode={setVerifiedAccessCode}
        />
      </ContainerRoot>
    </ThemeProvider>
  )
}

SelectTimeLayout.propTypes = {
  lessonTypes: PropTypes.array,
  currentUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  refetch: PropTypes.func,
  setShowRequestTime: PropTypes.func,
  lessonCreditBalanceQuantity: PropTypes.string,
}
export default SelectTimeLayout
