import React from 'react'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import EditAvailabilityForm from './edit-availability-form'
import { useIsMobile } from 'lib/hooks'

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: 'none',
  },
}))

const EditAvailabilityFormDialog = ({
  trigger,
  initialTimeSlots,
  initialSelectedDay,
  coachFacilityId,
  facilityTimezone,
  onSuccess,
}) => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  return (
    <Dialog
      trigger={trigger}
      maxWidth="xs"
      fullWidth
      showCloseButton
      fullScreen={isMobile}
    >
      {({ closeDialog }) => (
        <>
          <DialogTitle className={classes.header}>
            Edit availability
          </DialogTitle>
          <DialogContent dividers={false}>
            <Box py={2}>
              <EditAvailabilityForm
                initialTimeSlots={initialTimeSlots}
                initialSelectedDay={initialSelectedDay}
                coachFacilityId={coachFacilityId}
                facilityTimezone={facilityTimezone}
                onSuccess={() => {
                  onSuccess && onSuccess()
                  closeDialog()
                }}
                onCancel={closeDialog}
              />
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default EditAvailabilityFormDialog
