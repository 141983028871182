export const dashboard = () => '/family-golf'

export const locations = () => '/family-golf/locations'

export const signup = () => '/family-golf-signup'

export const signupLpga = () => `${signup()}/lpga`

export const signupLpgaRequest = () => `${signup()}/lpga-request`

export const newLocation = () => '/family-golf/locations/new'

export const location = locationId => `/family-golf/locations/${locationId}`

export const locationPath = (locationId, path) =>
  `${location(locationId)}/${path}`

export const program = (locationId, programId, anchor = '') =>
  `${location(locationId)}/programs/${programId}${anchor}`

export const programs = locationId =>
  `/family-golf/locations/${locationId}/programs`

export const newProgram = locationId =>
  `/family-golf/locations/${locationId}/programs/new`

export const programTeams = (locationId, programId) =>
  `${program(locationId, programId)}/teams`

export const programScoring = (locationId, programId) =>
  `${program(locationId, programId)}/scoring`

export const playerTransactions = contactId =>
  `/family-golf/admin/players/${contactId}/transactions`

export const playerTransaction = (contactId, transactionId) =>
  `${playerTransactions(contactId)}/${transactionId}`

export const requirements = () =>
  `/account/requirements?return_to=${encodeURIComponent(
    window.location.pathname,
  )}`
