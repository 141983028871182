import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { redirectToLogin, redirectToSignup, useAuth } from 'lib/auth'

const SignInNudge = () => {
  const { isLoggedIn } = useAuth()

  if (isLoggedIn) {
    return null
  }

  return (
    <Box color="text.secondary" mb={1}>
      <Typography variant="caption">
        <Box display="inline" fontWeight={700}>
          <Link
            component="span"
            onClick={() => redirectToLogin('consumer')}
            style={{ cursor: 'pointer' }}
          >
            Login
          </Link>
        </Box>
        &nbsp;or&nbsp;
        <Box display="inline" fontWeight={700}>
          <Link
            component="span"
            onClick={() => redirectToSignup('consumer')}
            style={{ cursor: 'pointer' }}
          >
            Create
          </Link>
        </Box>
        &nbsp;a MyPGA account for a faster registration experience
      </Typography>
    </Box>
  )
}

export default SignInNudge
