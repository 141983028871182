import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import Error from 'components/error'
import LinearProgress from '@material-ui/core/LinearProgress'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import ConfirmSendModal from './ConfirmSendModal'
import SendResultModal from './SendResultModal'
import { CoachInquiriesQuery } from './query'
import SelectContactsModal from './SelectContactsModal'

const useSetContacts = () => {
  const [contacts, setContact] = useState([])
  const addContact = id => {
    if (contacts.includes(id)) {
      setContact(contacts.filter(el => el !== id))
    } else {
      setContact([...contacts, id])
    }
  }
  return [contacts, addContact]
}

export const ReviewsModal = ({
  open,
  setOpen,
  inquiries,
  students,
  refetch,
  step,
  setStep,
}) => {
  const [contacts, addContact] = useSetContacts()

  const [error, setError] = useState(null)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  useEffect(() => {
    const getLatestList = async () => {
      await refetch()
    }
    if (step === 0) {
      getLatestList()
    }
  }, [open, refetch, step])
  return (
    <Dialog
      fullScreen={isMobile}
      onClose={() => setOpen(!open)}
      open={open}
      fullWidth
      scroll="paper"
    >
      {step === 0 && (
        <SelectContactsModal
          {...{
            open,
            setOpen,
            setStep,
            contacts,
            addContact,
            inquiries,
            students,
          }}
        />
      )}
      {step === 1 && (
        <ConfirmSendModal
          {...{
            open,
            setOpen,
            setStep,
            contactsArr: contacts,
            setError,
            refetch,
          }}
        />
      )}
      {step === 2 && (
        <SendResultModal
          {...{
            open,
            setOpen,
            setStep,
            error,
          }}
        />
      )}
    </Dialog>
  )
}

ReviewsModal.propTypes = {
  coachProfileId: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  inquiries: PropTypes.array,
  refetch: PropTypes.func,
  step: PropTypes.number,
  setStep: PropTypes.func,
}

const getMatchingReview = (reviews, inquiry) => {
  const review = reviews.find(rvw => rvw.contact.id === inquiry.contact.id)
  return review || {}
}

const flattenStudents = students => {
  const studentsArr = []
  students.forEach(student => {
    student.contact.forEach(contact => {
      studentsArr.push({
        ...student,
        contact: contact,
      })
    })
  })
  return studentsArr
}

const mergeWithReviews = (collection, coachReviews) =>
  collection.map(item => {
    const review = getMatchingReview(coachReviews, item)
    return {
      ...item,
      status: review.status,
      reviewUpdatedAt: review.updatedAt,
    }
  })

const sortInquiries = inquiries =>
  inquiries.sort((a, b) => {
    if (b.reviewUpdatedAt > a.reviewUpdatedAt) return 1
    if (a.reviewUpdatedAt > b.reviewUpdatedAt || !a.reviewUpdatedAt) return -1
    return 0
  })

const GetCoachLeads = ({ coachReviews, refetch, open, setOpen }) => {
  const { data, loading, error } = useQuery(CoachInquiriesQuery)
  const [step, setStep] = useState(0)

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />

  const inquiries = get(data, ['coachingInquiries'], []) || []
  const students = flattenStudents(get(data, ['coachStudents'], [])) || []

  const mergedInquiriesAndReviews = mergeWithReviews(inquiries, coachReviews)
  const mergedStudentsAndReviews = mergeWithReviews(students, coachReviews)

  return (
    <ReviewsModal
      {...{
        open,
        setOpen,
        inquiries: sortInquiries(mergedInquiriesAndReviews),
        students: sortInquiries(mergedStudentsAndReviews),
        refetch,
        step,
        setStep,
      }}
    />
  )
}

GetCoachLeads.propTypes = {
  coachReviews: PropTypes.array,
  refetch: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default GetCoachLeads
