import React from 'react'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

export default ({
  students,
  selectedStudent,
  setSelectedStudent,
  marginBottom,
}) => {
  return (
    <FormControl
      style={{ minWidth: '200px', marginBottom: marginBottom ? '20px' : '0' }}
      variant="outlined"
    >
      <Select
        value={selectedStudent}
        onChange={event => setSelectedStudent(event.target.value)}
      >
        {students.map(({ id, firstName, lastName }) => (
          <MenuItem key={id} value={id}>{`${firstName} ${lastName}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
