import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { MobileDatePicker } from '@mui/x-date-pickers'
import EventIcon from '@mui/icons-material/Event'
import { useTheme } from '@material-ui/core/styles'

const mobileDatePickerSlotProps = (
  validationErrors = false,
  helperText = '',
  theme,
) => {
  return {
    textField: {
      error: validationErrors,
      helperText: helperText,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <EventIcon />
          </InputAdornment>
        ),
      },
    },
    dialog: {
      sx: {
        '& .MuiButton-root': {
          color: theme.palette.primary.main,
        },
        '& button.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          '&:focus': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '& .MuiPickersLayout-toolbar': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '& .MuiTypography-overline': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        },
      },
      disableEnforceFocus: true,
    },
  }
}

const PgaMobileDatePicker = ({
  label,
  value,
  onAccept,
  format,
  validationErrors,
  ...rest
}) => {
  const { helperText } = rest
  const theme = useTheme()
  return (
    <MobileDatePicker
      label={label}
      value={value}
      format={format}
      onAccept={onAccept}
      slotProps={mobileDatePickerSlotProps(validationErrors, helperText, theme)}
      dayOfWeekFormatter={day => `${day}`}
      {...rest}
    />
  )
}

export default PgaMobileDatePicker
