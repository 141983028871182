import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import StatusModal from 'components/status-modal'
import { useStyles } from './styles'

const useToggleCoachStatus = (status, coachProfileId, toggleCoachStatus) => {
  const [active, toggleStatusFn] = useState(status === 'ACTIVE')
  const toggleStatus = async (setOpen, setLoading, setError) => {
    try {
      setLoading(true)
      const { error } = await toggleCoachStatus({
        variables: { coachProfileId },
      })
      setLoading(false)
      if (error) setError(error)
      toggleStatusFn(!active)
      setOpen(false)
    } catch (e) {
      setError(e)
    }
  }
  return [active, toggleStatus]
}

const enableProfileTxt = {
  title: 'Enable Profile?',
  body1: 'Are you sure you want to enable your PGA.com coach profile?',
  body2:
    'Selecting "yes" below will make your Coach Profile visible on PGA.com',
}

const disableProfileTxt = {
  title: 'Disable Profile?',
  body1: 'Are you sure you want to disable your PGA.com coach profile?',
  body2:
    'Selecting "yes" below will remove your Coach Profile from public view on PGA.com',
}

const ToggleVisiblity = ({ status, coachProfileId, toggleCoachStatus }) => {
  const [open, setOpen] = useState(false)
  const [active, toggleStatus] = useToggleCoachStatus(
    status,
    coachProfileId,
    toggleCoachStatus,
  )
  const classes = useStyles()
  return (
    <div className={classes.toggleWrapped}>
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={() => setOpen(!open)}
            name="visible"
          />
        }
        label={<Typography variant="subtitle1">Profile Visibility</Typography>}
        className={classes.switchStyles}
      />
      <StatusModal
        {...{
          text: active ? disableProfileTxt : enableProfileTxt,
          open,
          setOpen,
          toggleStatus,
        }}
      />
    </div>
  )
}

ToggleVisiblity.propTypes = {
  status: PropTypes.string,
  coachProfileId: PropTypes.string,
  toggleCoachStatus: PropTypes.func,
}

export default ToggleVisiblity
