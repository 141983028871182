import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useAuth } from 'lib/auth'
import { PageHeader } from 'components/page-header'
import BookingTypesView from './booking-types-view'
import useStyles from './styles'
import RouterLinkButton from 'components/router-link-button'
import SocialShareDialog from 'components/social-share-dialog'

const BookingTypesPage = () => {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const { user, setUserProperties } = useAuth()

  const crumbs = [
    {
      label: 'Offerings',
      to: [...path.split('/').slice(0, 3), 'offerings'].join('/'),
    },
  ]

  const actions = (
    <>
      <SocialShareDialog
        trigger={
          <Button variant="outlined" color="primary">
            Share Booking Link
          </Button>
        }
        dialogTitle="Share"
        shareUrl={user.coach.bookingUrl}
        shareTitle="My Offerings"
        bookingType=""
        showScheduleUrl={true}
        att="booking-link"
      />
      <RouterLinkButton
        to="/pga-coach/bookings/booking-types/new"
        variant="contained"
        color="primary"
        data-cy="createBookingType"
        onClick={() => setUserProperties({ onboarding: false })}
      >
        Create Booking Type
      </RouterLinkButton>
    </>
  )

  return (
    <>
      <PageHeader
        title="Booking Types"
        crumbs={crumbs}
        actions={actions}
        fixedMobileActions
      />
      <Box className={classes.mainWrapper}>
        <Grid container>
          <Grid item xs={12}>
            <BookingTypesView />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BookingTypesPage
