import { gql } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'

export const GET_STUDENT_QUERY = gql`
  ${GolferProfileFragment}
  query Student($id: ID!) {
    student(id: $id) {
      id
      isMinor
      firstName
      lastName
      medicalProfile {
        info
      }
      contact {
        firstName
        lastName
        email
        phone
      }
      golferProfile {
        ...GolferProfileAttributes
      }
    }
  }
`
