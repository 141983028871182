import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

const AgeGroupSelect = ({ style }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl
      error={!!errors.ageGroup}
      variant="outlined"
      fullWidth
      style={style}
    >
      <InputLabel id="age-group">Age Group*</InputLabel>
      <Controller
        name="ageGroup"
        control={control}
        render={({ onChange, value }, _) => (
          <Select
            labelId="age-group"
            label="Age Group*"
            onChange={onChange}
            value={value}
            data-cy="age-group"
          >
            <MenuItem value="all-ages" data-cy="age-group-all-ages">
              All Ages
            </MenuItem>
            <MenuItem value="adults-only" data-cy="age-group-adults-only">
              Adults Only
            </MenuItem>
            <MenuItem value="juniors-only" data-cy="age-group-juniors-only">
              Juniors Only
            </MenuItem>
          </Select>
        )}
      />
      {errors.ageGroup ? (
        <Typography variant="caption" color="error">
          Please select an age group
        </Typography>
      ) : null}
    </FormControl>
  )
}

export default AgeGroupSelect
