import { Util } from 'cloudinary-core'

const uploadImage = options =>
  new Promise((resolve, reject) => {
    const scOptions = Util.withSnakeCaseKeys(options)
    window.cloudinary.openUploadWidget(scOptions, (error, res) => {
      if (!error) {
        if (res.event === 'success') {
          resolve(res.info.secure_url)
        }
      } else {
        reject(error)
      }
    })
  })

export default uploadImage

// TODO: this functionality is specific to the coach profile form
//   - src/components/coaches-profile/profile-components
//   - src/pages/pga-coach/profile/create-profile/form-components
export const handleImageUpload = async (
  uploadOptions,
  setFieldValue,
  setUploadError,
  fieldName,
  photos,
  rank,
) => {
  try {
    setUploadError(null)
    const res = await uploadImage(uploadOptions)
    if (photos) {
      if (rank) {
        photos.push({ reference: res, rank })
      } else {
        photos.push(res)
      }
    }
    setFieldValue(fieldName, photos || res)
  } catch (e) {
    setUploadError(e.statusText)
  }
}
