import React, { useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider, useSnackbar } from 'notistack'
import themes, { extendTheme } from 'themes'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import LinearProgress from '@material-ui/core/LinearProgress'
import CoachAppbar from 'components/coach-app-bar'
import WizardForm from 'components/wizard-form'

import ProfileNameStep from './steps/profile-name'
import ProfileStep from './steps/profile'
import FacilitiesStep from './steps/facilities'
import ExperienceStep from './steps/experience'
import ServicesSkillsToolsStep from './steps/sst'
import SuccessStep from './steps/success'
import { makeFormFieldObj } from './v3Utils'
import { createValidationSchema } from 'components/coaches-profile/constants'
import { gtmEvent } from 'lib/gtm'
import { submissionHandler } from 'components/coaches-profile/utils'
import Error from 'components/error'
import { roles, withProtectedRoute } from 'lib/auth'

const CREATE_COACH_PROFILE = gql`
  mutation CoachProfileCreate($params: CoachProfileCreateInput!) {
    CoachProfileCreate(params: $params) {
      message
      success
    }
  }
`
const GET_FACILITIES = gql`
  query CoachFacilities {
    currentUser {
      attributes {
        ... on Coach {
          facilities {
            id
          }
        }
      }
    }
  }
`
export const CreateCoachProfileFormWizardWrapper = ({ memberProfile }) => {
  const { data, loading, error } = useQuery(GET_FACILITIES)
  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />
  return (
    <CreateCoachProfileFormWizard
      memberProfile={memberProfile}
      facilities={data?.currentUser?.attributes?.facilities || []}
    />
  )
}

const CreateCoachProfileFormWizard = ({
  memberProfile,
  facilities,
  dialog,
  isOnboarding,
}) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [createdCoachProfile, setCreatedCoachProfile] = useState(null)
  const [coachSlug, setCoachSlug] = useState(null)
  const persistKey = 'create-profile-form'

  const [createCoachProfile, { loading }] = useMutation(CREATE_COACH_PROFILE, {
    onCompleted: data => {
      if (data.CoachProfileCreate.success) {
        setCreatedCoachProfile(true)
        localStorage.removeItem(persistKey)
        gtmEvent({
          event: 'formSubmit',
          formCategory: 'profile',
          formAction: 'create-profile',
        })
      } else {
        enqueueSnackbar(data.CoachProfileCreate.message, {
          variant: 'error',
          autoHideDuration: 5000,
        })
      }
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['create-coach-profile'],
        })
      enqueueSnackbar(error?.message || 'Unable to create profile.', {
        variant: 'error',
        autoHideDuration: 5000,
      })
    },
  })

  if (createdCoachProfile) {
    return <SuccessStep slug={coachSlug} dialog={dialog} />
  }

  return (
    <>
      {loading ? <LinearProgress color="primary" /> : null}
      <WizardForm
        formProps={{
          defaultValues: makeFormFieldObj(memberProfile),
          resolver: yupResolver(createValidationSchema),
        }}
        formName="coach-profile"
        persistKey={persistKey}
        steps={[
          {
            component: <ProfileStep memberProfile={memberProfile} />,
            fieldsToValidate: [
              'firstName',
              'lastName',
              'displayName',
              'gender',
              'title',
              'email',
              'phoneNumber',
              'phoneExt',
              'mobileNumber',
              'city',
              'state',
              'zipCode',
              'prices',
              'bio',
              'numberOfStudents',
              'numberOfFollowers',
              'socialJsonData',
              'testimonialsJsonData',
            ],
            nextButtonLabel: 'Next: Web Address',
            stepName: 'profile',
          },
          {
            component: <ProfileNameStep />,
            fieldsToValidate: ['customSlug'],
            nextButtonLabel:
              facilities?.length === 0
                ? 'Next: Facilities'
                : 'Next: Experience',
            stepName: 'profile-name',
          },
          ...(facilities?.length === 0
            ? [
              {
                component: <FacilitiesStep />,
                fieldsToValidate: ['facilities'],
                nextButtonLabel: 'Next: Experience',
                stepName: 'facilities',
              },
            ]
            : []),
          {
            component: <ExperienceStep />,
            fieldsToValidate: ['experiences'],
            nextButtonLabel: 'Next: Additional Information',
            stepName: 'experience',
          },
          {
            component: <ServicesSkillsToolsStep />,
            fieldsToValidate: ['services', 'skills', 'toolJsonData'],
            nextButtonLabel: 'Create Profile',
            nextButtonLabelMobile: 'Create Profile',
            stepName: 'services-skills',
          },
        ]}
        onSubmit={async formValues => {
          setCoachSlug(formValues.customSlug)
          submissionHandler(
            formValues,
            true,
            createCoachProfile,
            undefined,
            () => {},
            () => {},
          )
        }}
        onCancel={() =>
          history.push(isOnboarding ? '/pga-coach' : '/pga-coach/profile')
        }
      />
    </>
  )
}

const GET_MEMBER_PROFILE = gql`
  query MemberProifleWithFacilities {
    GetMemberProfile {
      message
      success
      memberProfile {
        id
        type
        firstName
        lastName
        displayName
        photo
        phoneNumber
        publicPhone
        primaryMobile
        publicMobile
        publicEmail
        social {
          instagram
          facebook
          twitter
          youtube
        }
        primaryFacility {
          facilityId
          operationsTypeLabel
          address {
            address1
            address2
            city
            state
            zip
          }
          name
          phoneNumber
          website
        }
        facilities {
          address {
            address1
            address2
            city
            state
            zip
          }
          name
          phoneNumber
          website
        }
      }
    }
  }
`

/*
This is used as work-around related to Intercom Product Tours
More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
*/
const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

export const CreateProfile = () => {
  const { data, loading, error } = useQuery(GET_MEMBER_PROFILE)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} displayErrorType="page" />

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach</title>
        <meta name="description" content="PGA Coach | Create Event" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <CoachAppbar position="relative" />
        <CreateCoachProfileFormWizardWrapper
          memberProfile={data?.GetMemberProfile?.memberProfile}
        />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(CreateProfile, roles.COACH)
