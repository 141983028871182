import { ContentSection } from '../../student-show-page/details-components/content-box'
import { Button, Box, Typography } from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import React, { useState } from 'react'

import { useStyles } from '../styles'
import { useIsMobile } from 'lib/hooks'
import DeleteLeadDialog from './delete-lead-dialog'

export default function DeleteStudent({ lead }) {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [isDeleteLeadDialogOpen, setIsDeleteLeadDialogOpen] = useState(false)

  const openDeleteLeadDialog = () => {
    setIsDeleteLeadDialogOpen(true)
  }

  if (isMobile) {
    return (
      <Box className={classes.mobileDelete}>
        <Button
          onClick={openDeleteLeadDialog}
          startIcon={<DeleteOutlineOutlined />}
          variant="outlined"
          className={classes.deleteButton}
          data-testid="delete-lead-button"
        >
          Delete Lead
        </Button>
        <DeleteLeadDialog
          lead={lead}
          open={isDeleteLeadDialogOpen}
          setOpen={setIsDeleteLeadDialogOpen}
        />
        <Typography className={classes.deleteText}>
          Delete student, including all data such messages and contact
          information.
        </Typography>
      </Box>
    )
  }

  return (
    <ContentSection title="Delete Lead">
      <Box className={classes.deleteLead}>
        <Typography className={classes.deleteText}>
          Delete lead, including all data such as messages and contact
          information.
        </Typography>
        <DeleteLeadDialog
          lead={lead}
          open={isDeleteLeadDialogOpen}
          setOpen={setIsDeleteLeadDialogOpen}
        />
        <Button
          onClick={openDeleteLeadDialog}
          startIcon={<DeleteOutlineOutlined />}
          variant="outlined"
          data-testid="delete-lead-button"
        >
          Delete Lead
        </Button>
      </Box>
    </ContentSection>
  )
}
