import React from 'react'
import Button from '@material-ui/core/Button'

const RegisterButton = ({
  groupEvent,
  paymentRequired,
  type,
  component,
  to,
}) => {
  const { isCanceled, registrationClosed, registrationFull } = groupEvent

  const registrationDisabled =
    isCanceled || registrationClosed || registrationFull

  const label = (() => {
    if (isCanceled) return 'Event Canceled'
    if (registrationClosed) return 'Registration Closed'
    if (registrationFull) return 'Registration Full'
    if (paymentRequired) return 'Confirm and Pay'
    return 'Register'
  })()

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      disabled={registrationDisabled}
      {...{ type, component, to }}
    >
      {label}
    </Button>
  )
}

export default RegisterButton
