import React from 'react'
import ReactDOM from 'react-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-condensed/700.css'
import '@fontsource/source-serif-pro/700.css'
import '@fontsource/nunito-sans'
import './index.css'
import * as serviceWorker from './serviceWorker'
import Routes from './routes'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { withAuthProvider, ImpersonationOverlay } from 'lib/auth'
import { withFeatureFlagProvider } from 'lib/feature-flags'
import { withApolloClient } from 'lib/graphql'
import { RaygunActivate } from 'lib/raygun'
import { DatadogActivate } from 'lib/datadog'
import GoogleTagManager from 'lib/gtm'
import themes from './themes'
import Amplitude from 'lib/amplitude'

const App = withAuthProvider(
  withFeatureFlagProvider(
    withApolloClient(() => (
      <ThemeProvider theme={createTheme(themes.common)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatadogActivate />
          <GoogleTagManager />
          <Amplitude />
          <Routes />
          <RaygunActivate />
          <ImpersonationOverlay />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    )),
  ),
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()
