import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { gtmEvent } from 'lib/gtm'
import { yupResolver } from '@hookform/resolvers'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useAuth } from 'lib/auth'
import { withStripeElements } from 'lib/stripe'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import RegistrationSubmitting from './registration-submitting'
import RegistrationComplete from './registration-complete'
import { REGISTER_GROUP_EVENT } from '../queries'
import VerifyAccessCode from 'components/verify-access-code'
import { CoachingCenterWaiver } from 'components/waivers'
import FormLayout from './form-layout'
import RegisterBackButton from './register-back-button'
import { useCreditCardErrors } from 'lib/hooks'
import { COACHING_CENTER_SLUGS } from 'utils/constants'
import { useFeatureFlags, flags } from 'lib/feature-flags'
import { DateTime } from 'luxon'

const optionalPhoneNumber = yup.string().matches(/^\d{3}-?\d{3}-?\d{4}$/, {
  message: 'Please enter a valid phone number',
  excludeEmptyString: true,
})

const formatPhoneNumber = phoneNumber => {
  const formatted = phoneNumber.replace(/\D/g, '')
  return formatted.length > 0 ? formatted : null
}

const validationSchema = (paymentRequired, shouldShowWaiver = false) =>
  yup.object().shape({
    useFirstParticipantContactInformation: yup.boolean().required(),
    contact: yup.object().when('useFirstParticipantContactInformation', {
      is: false,
      then: yup
        .object()
        .shape({
          firstName: yup.string().required('This field is required'),
          lastName: yup.string().required('This field is required'),
          email: yup
            .string()
            .email('Please enter a valid email address')
            .required('This field is required'),
          phoneNumber: optionalPhoneNumber,
        })
        .required(),
    }),
    participants: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup.string().required('This field is required'),
          lastName: yup.string().required('This field is required'),
          email: yup.string().email('Please enter a valid email address'),
          phoneNumber: optionalPhoneNumber,
          type: yup
            .string()
            .oneOf(['Adult', 'Junior'])
            .required(),
        }),
      )
      .required('Please register at least one participant')
      .min(1, 'Please register at least one participant')
      .when('useFirstParticipantContactInformation', {
        is: true,
        then: yup
          .array()
          .test(
            'first-participant-email-required',
            'This field is required',
            function (val) {
              return ((val && val.length > 0 && val[0].email) || '').length ===
                0
                ? this.createError({ path: 'participants[0].email' })
                : true
            },
          ),
      }),
    ...(paymentRequired && {
      payment: yup.object().shape({
        name: yup.string().required('This field is required'),
      }),
    }),
    ...(shouldShowWaiver && {
      agreeWaiver: yup
        .boolean()
        .oneOf([true], 'You must agree to the waiver to continue'),
    }),
  })

const GroupEventRegistrationForm = ({ groupEvent, isMobile }) => {
  const { user, isConsumer } = useAuth()
  const isAuthenticatedConsumer = user && isConsumer
  const coachRegisterOnBehalfOfStudent =
    user?.externalId === groupEvent?.coach?.externalId

  const canSkipPayment = user && user?.id === groupEvent?.coach?.id
  // TODO - paymentRequired can be folded into form state for easier handling of validation
  const [paymentRequired, setPaymentRequired] = useState(
    !canSkipPayment && groupEvent.acceptsOnlinePayments,
  )
  const [completedRegistration, setCompletedRegistration] = useState(null)
  const [isAccessCodeVerified, setIsAccessCodeVerified] = useState(false)
  const requiresAccessCode = groupEvent.requiresAccessCode
  const isCoachingCenterGE = COACHING_CENTER_SLUGS.includes(
    groupEvent?.academy?.slug,
  )
  const shouldShowWaiver = isCoachingCenterGE && !coachRegisterOnBehalfOfStudent
  const [showWaiver, setShowWaiver] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showWaiver])

  const [marketingOptInChecked, setMarketingOptInChecked] = useState(false)
  const handleMarketingOptInChange = isChecked => {
    setMarketingOptInChecked(isChecked)
  }

  const [marketingOptIn] = useFeatureFlags([
    flags.FLAG_FEAT_MARKETING_OPT_IN_GROUP_EVENTS,
  ])

  const { enqueueSnackbar } = useSnackbar()
  const form = useForm({
    defaultValues: {
      contact: isAuthenticatedConsumer
        ? {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
        }
        : {},
      participants: isAuthenticatedConsumer
        ? [
          {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            type: 'Adult',
          },
        ]
        : [],
      useFirstParticipantContactInformation: false,
      ...(shouldShowWaiver && {
        agreeWaiver: false,
      }),
    },
    resolver: yupResolver(validationSchema(paymentRequired, shouldShowWaiver)),
  })
  const [registerGroupEvent] = useMutation(REGISTER_GROUP_EVENT)
  const stripe = useStripe()
  const elements = useElements()
  const creditCardErrorMessage = useCreditCardErrors(
    elements,
    paymentRequired,
    !shouldShowWaiver,
  )

  const onSubmit = async values => {
    const { error: stripeError, token: stripeToken } = await (paymentRequired
      ? stripe.createToken(elements.getElement(CardNumberElement), {
        name: values.payment.name,
        address_country: 'US',
      })
      : Promise.resolve({ error: null, token: null }))

    const marketingOptInAt = marketingOptInChecked
      ? new Date().toISOString()
      : null

    if (stripeError) {
      setShowWaiver(false)
      enqueueSnackbar(stripeError.message, {
        variant: 'error',
      })
      return Promise.resolve()
    }
    return registerGroupEvent({
      variables: {
        id: groupEvent.id,
        participants: values.participants.map(p => ({
          firstName: p.firstName,
          lastName: p.lastName,
          email: (p.email || '').length > 0 ? p.email : null,
          phoneNumber: formatPhoneNumber(p.phoneNumber || ''),
          isMinor: p.type === 'Junior',
        })),
        contact: values.useFirstParticipantContactInformation
          ? {
            firstName: values.participants[0].firstName,
            lastName: values.participants[0].lastName,
            email: values.participants[0].email,
            phone: formatPhoneNumber(values.participants[0].phoneNumber || ''),
            marketingOptInAt: marketingOptInAt,
          }
          : {
            firstName: values.contact.firstName,
            lastName: values.contact.lastName,
            email: values.contact.email,
            phone: formatPhoneNumber(values.contact.phoneNumber || ''),
            marketingOptInAt: marketingOptInAt,
          },
        stripeToken: stripeToken?.id,
      },
    })
      .then(({ data }) => {
        if (data.registerGroupEvent.success) {
          setCompletedRegistration({
            contact: values.useFirstParticipantContactInformation
              ? values.participants[0]
              : values.contact,
          })
          gtmEvent({
            event: 'complete-event-registration',
            title: groupEvent.title,
            category: groupEvent.tags,
            event_date: DateTime.fromISO(groupEvent.startDateTime, {
              zone: groupEvent.coachFacility.timezone,
            }).toFormat('yyyy-LL-dd'),
            event_time: DateTime.fromISO(groupEvent.startDateTime, {
              zone: groupEvent.coachFacility.timezone,
            }).toFormat('hh:mm a'),
            facility: groupEvent.coachFacility.name,
            price: groupEvent.priceInCents / 100,
            coach_id: groupEvent.coach.id,
            coach_name: groupEvent.coach.name,
          })
          // Remove the below event in the future.
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'register-group-event',
            formAction: 'completed-registration',
            formStatus: 'group-event',
          })
          if (marketingOptInChecked) {
            gtmEvent({
              event: 'marketing-opt-in',
              source: 'event_registration',
            })
          }
        } else {
          setShowWaiver(false)
          enqueueSnackbar(data.registerGroupEvent.message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        setShowWaiver(false)
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['group-events', 'registration'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
  }

  if (completedRegistration) {
    return (
      <RegistrationComplete
        groupEvent={groupEvent}
        completedRegistration={completedRegistration}
      />
    )
  }

  return (
    <>
      {form.formState.isSubmitting && !form.formState.isValidating && (
        <RegistrationSubmitting />
      )}
      <Box
        display={
          !form.formState.isSubmitting || form.formState.isValidating
            ? 'block'
            : 'none'
        }
      >
        <Grid container>
          {showWaiver && (
            <Grid md={shouldShowWaiver ? 1 : false} item>
              <RegisterBackButton
                showWaiver={showWaiver}
                setShowWaiver={setShowWaiver}
                shouldShowWaiver={shouldShowWaiver}
              />
            </Grid>
          )}
          <Grid md={shouldShowWaiver ? 11 : 12} item>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <CoachingCenterWaiver
                  form={form}
                  showWaiver={showWaiver}
                  onSubmit={onSubmit}
                />
                <FormLayout
                  groupEvent={groupEvent}
                  paymentRequired={paymentRequired}
                  setPaymentRequired={setPaymentRequired}
                  isMobile={isMobile}
                  showWaiver={showWaiver}
                  setShowWaiver={setShowWaiver}
                  form={form}
                  shouldShowWaiver={shouldShowWaiver}
                  paymentErrorMessage={creditCardErrorMessage}
                  marketingOptIn={marketingOptIn}
                  marketingOptInChecked={marketingOptInChecked}
                  handleMarketingOptInChange={handleMarketingOptInChange}
                />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <VerifyAccessCode
        groupEvent={groupEvent}
        isOpen={requiresAccessCode && !isAccessCodeVerified}
        setIsOpen={setIsAccessCodeVerified}
        coachName={groupEvent?.coach?.name}
        setVerifiedAccessCode={setIsAccessCodeVerified}
        canRequestAccessCode={!isCoachingCenterGE}
      />
    </>
  )
}

export default withStripeElements(GroupEventRegistrationForm)
export { validationSchema }

GroupEventRegistrationForm.propTypes = {
  groupEvent: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
}
