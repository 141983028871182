import { makeStyles, styled } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

export const useStyles = makeStyles(theme => ({
  fontBolded: {
    fontWeight: 600,
  },
  divider: {
    margin: '20px 0',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  warningBox: {
    padding: '12px',
    background: '#F5F7FA',
    borderRadius: '4px',
    margin: '20px 0',
    '& svg': {
      fill: theme.palette.primary.light,
    },
    color: theme.palette.text.primary,
    fontWeight: '600',
    '& :first-child': {
      alignItems: 'center',
    },
    '& p': {
      fontWeight: 600,
      lineHeight: '16px',
      marginLeft: '12px',
    },
  },
  verticalDivider: {
    position: 'absolute',
    height: '42px',
    background: theme.palette.grey.main,
  },
  studentCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    border: '1px solid #EEE',
    height: '80px',
    padding: '16px',
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '16px',
  },
}))

const ContainerRoot = styled(Container)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

const WaiverContainer = styled('div')(({ theme, showWaiver }) => ({
  display: showWaiver ? 'flex' : 'none',
  background: '#fafafa',
  [theme.breakpoints.down('sm')]: {
    background: '#fff',
  },
}))

export { ContainerRoot, WaiverContainer }
