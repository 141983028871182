import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { PGA_COM_HOST } from 'env'
import ExternalLinkButton from 'components/external-link-button'
import useStyles, { DialogBox, FlexRow } from '../profile.styles'

const DialogAPIMessage = (props) => {
  const {
    message,
    success,
    open,
    handleClose,
    isCreate,
    customSlug,
  } = props
  const styles = useStyles()
  const isClosable = !isCreate || (isCreate && !success)

  return (
    <Dialog open={open} onClose={isClosable && handleClose}>
      <DialogBox>
        <Typography variant="h4" style={{ marginBottom: '1rem', textAlign: 'center' }}>
          {success ? 'Success' : 'Error'}
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '1rem', textAlign: 'center' }}>
          {message}
        </Typography>
        {
          success && (
            <FlexRow>
              <ExternalLinkButton
                href={`${PGA_COM_HOST}/coach/${customSlug}`}
                size="large"
              >
                View Profile
              </ExternalLinkButton>
              <Button
                onClick={handleClose}
                size="large"
              >
                Edit Profile
              </Button>
            </FlexRow>
          )
        }
        {
          isClosable && (
            <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )
        }
      </DialogBox>
    </Dialog>
  )
}

DialogAPIMessage.propTypes = {
  message: PropTypes.string,
  success: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isCreate: PropTypes.bool,
  customSlug: PropTypes.string,
}

DialogAPIMessage.defaultProps = {}

export default DialogAPIMessage
