import React, { useEffect } from 'react'
import { AUTH_ROOT } from 'env'
import auth0 from '../auth0/client'
import LinearProgress from '@material-ui/core/LinearProgress'

const redirectToLogin = async (
  role,
  returnTo = window.location.href,
  forceReauthenticate = false,
) => {
  if (role === 'consumer') {
    await auth0.loginWithRedirect({
      authorizationParams: {
        scope: `openid profile email offline_access api:${role}`,
        ...(forceReauthenticate ? { prompt: 'login' } : {}),
      },
      appState: { returnTo },
    })
  } else if (role === 'coach') {
    // clear the consumer session, if it exists
    await auth0.logout({ openUrl: false })
    // redirect to the coach login page
    const urlParams = new URLSearchParams({
      return_to: returnTo,
    })
    window.location = `${AUTH_ROOT}/v2/login?${urlParams}`
  }
}

const LoginRedirect = ({ role, returnTo }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    redirectToLogin(role, returnTo)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <LinearProgress color="secondary" />
}

export default LoginRedirect
export { redirectToLogin }
