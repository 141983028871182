import { gql } from '@apollo/client'

export const GET_PAYMENT_REQUEST_ENROLLMENT = gql`
  query GetEnrollmentForPayment($paymentRequestToken: ID!) {
    paymentRequest(paymentRequestToken: $paymentRequestToken) {
      id
      paymentRequestedDate
      paymentRequestToken
      canceledEnrollment: isCanceled
      isPaid
      confirmationCode
      student {
        id
        firstName
        lastName
        contact {
          firstName
          lastName
          email
        }
      }
      lesson {
        coach {
          id
          name
          coachProfile {
            id
            slug
            profilePhoto
            title
          }
        }
        coachFacility {
          id
          name
          timezone
        }
        id
        title
        description
        canceledLesson: isCanceled
        startDateTime
        endDateTime
        timezone
        ... on Session {
          lessonType {
            title
            description
            id
            category
            priceInCents
            acceptsOnlinePayments
            durationInMinutes
            facilities {
              id
              name
              city
              state
              timezone
            }
          }
        }
        ... on GroupEvent {
          slug
          tags
          featuredImageUrl
          priceInCents
          totalPriceInCents
          acceptsOnlinePayments
          paymentRecurrenceSchedule
          numberOfPayments
          lessonCount
          registrationCloseDateTime
          externalRegistrationUrl
          registrationCount
          sessions {
            id
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`
