import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

export const useStyles = makeStyles(theme => ({
  heading: {
    lineHeight: '28px',
    fontWeight: 800,
  },
  base: {
    background: 'rgb(255, 245, 229)',
    borderRadius: '5px',
  },
  ul: {
    padding: '6px 6px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardDivider: {
    margin: '0 8px',
    background: 'rgba(0, 35, 75, 0.24)',
  },
  logo: {
    color: '#ff9800',
    width: '50px',
    minWidth: '50px',
    alignSelf: 'center',
  },
  body: {
    padding: '8px 0px 8px',
    justifyContent: 'start',
    margin: '0.1rem 0px',
    fontWeight: 600,
  },
  subheading: {
    display: 'block',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  periodHeading: {
    fontWeight: '800',
    marginBottom: '10px',
    display: 'inline-block',
  },
  availableTimesWrapped: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
  },
  datePickerStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '5px 0 20px',
    boxShadow: '0 .5rem .8rem rgba(0, 0, 0, 0.2)',
  },
  timeSlotStyles: {
    marginTop: '24px',
    maxwidth: '100%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  timeSlotSelect: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  overrideDescription: {
    [theme.breakpoints.up('md')]: {
      order: 3,
    },
    '& button': {
      paddingLeft: 0,
    },
  },
  warnWrapper: {
    background: theme.palette.primary.selected,
    padding: '24px 16px 24px 16px',
    [theme.breakpoints.only('xs')]: {
      padding: '16px 0px 16px 16px',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: '-4px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dialogActions: {
    margin: '32px 8px 24px 0px',
    [theme.breakpoints.only('xs')]: {
      margin: '8px 8px 8px 0px',
    },
    justifyContent: 'flex-end',
  },
  calendarPng: {
    width: '100px',
    height: '100px',
  },
  adornedEnd: {
    '& button': {
      padding: 0,
    },
  },
  backButton: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'none',
    marginTop: '30px',
    marginBottom: '1rem',
    paddingLeft: '0',
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
    '&:active': {
      color: theme.palette.primary.lightest,
    },
  },
  colorNavy: {
    color: theme.palette.primary.main,
  },
  noTimesAvailable: {
    color: theme.palette.primary.light,
    fontWeight: 700,
  },
  noTimesAvatar: {
    backgroundColor: theme.palette.blue.medium,
    color: theme.palette.blue.main,
    width: '84px',
    height: '84px',
  },
  boldedText: {
    fontWeight: 700,
  },
}))

export const TimeSlotButton = withStyles(theme => ({
  root: {
    height: 'initial',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '6px 16px',
    borderRadius: '4px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    margin: theme.spacing(0.5),
    flexShrink: '0',
    backgroundColor: '#ffffff',
    color: theme.palette.primary.light,
    fontFamily: 'Roboto',
  },
  contained: {
    boxShadow: theme.shadows[2],
    border: '1px solid transparent',
  },
}))(Button)
