export const toBoolean = value => {
  if (value == null) return false
  const booleanMap = {
    true: true,
    false: false,
    1: true,
    0: false,
    '': false,
  }
  return booleanMap[value.toString().toLowerCase()]
}
