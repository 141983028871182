import React from 'react'
import orderBy from 'lodash/orderBy'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useIconAccessor from 'components/lesson-type-category/icons'
import BookingTypeCard from './booking-type-card'

const filterLessonsByLocation = (bookingTypes, selectedFacility) => {
  return bookingTypes.filter(bt => bt.locations[0].id === selectedFacility)
}

const sortBtByCategory = bookingTypes => orderBy(bookingTypes, 'category')

export default ({ title, bookingTypes, selectedFacility }) => {
  const filteredBtByLocation = filterLessonsByLocation(
    bookingTypes,
    selectedFacility,
  )

  const accessor = useIconAccessor()
  const filteredBookingTypes =
    selectedFacility !== 'all'
      ? sortBtByCategory(filteredBtByLocation)
      : sortBtByCategory(bookingTypes)

  if (bookingTypes.length === 0) return null
  return (
    <>
      <Box py={2}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Grid direction="row" container spacing={2}>
        {filteredBookingTypes.map((bt, i, arr) => (
          <>
            {i === 0 && (
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: '500' }}>
                  {accessor[bt.category].text}
                </Typography>
              </Grid>
            )}
            {i > 0 && bt.category !== arr[i - 1].category && (
              <Grid item xs={12}>
                <Typography variant="h5">
                  {accessor[bt.category].text}
                </Typography>
              </Grid>
            )}
            <Grid xs={12} md={6} lg={4} item>
              <BookingTypeCard bookingType={bt} />
            </Grid>
          </>
        ))}
      </Grid>
    </>
  )
}
