import { gql } from '@apollo/client'

export const GetMyLessons = gql`
  query getMyLessons(
    $startDateTime: DateTimeRangeInput
    $limit: Int
    $orderBy: [LessonsOrderByInput!]
    $studentId: ID
  ) {
    currentUser {
      lessons(
        startDateTime: $startDateTime
        limit: $limit
        orderBy: $orderBy
        studentId: $studentId
      ) {
        id
        startDateTime
        endDateTime
        isCanceled
        title
        timezone
        canStudentCancel
        coach {
          id
          name
          externalId
        }
        coachFacility {
          id
          name
          city
          state
          zipCode
        }
        enrollments {
          id
          confirmationCode
          isCanceled
          student {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
