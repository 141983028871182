import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import DisplayContact from './details-components/display-contact'
import EmergencyInformation from './details-components/emergency-information'
import AdditionalInformation from './details-components/additional-information'
import DeleteStudent from './details-components/delete-student'
import StudentStats from './details-components/stats'
import { useAuth } from 'lib/auth'
import GolferInformation from './details-components/golfer-information'
import GolferProfileCta from './golfer-profile-cta'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { gtmEvent } from 'lib/gtm'

const StudentDetails = ({ student, stats }) => {
  const location = useLocation()
  const { event, source, campaign } = qs.parse(location.search)
  const fireGtmEvent = () => {
    if (event && source && campaign) {
      gtmEvent({
        event: event,
        formCategory: source,
        formAction: campaign,
      })
    }
  }
  useEffect(() => {
    fireGtmEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    contact,
    isMinor,
    medicalProfile,
    emergencyContact,
    golferProfile,
  } = student
  const { data: { studentStatistics } = {}, loading } = stats
  const demographics = {
    studentGender: golferProfile?.gender || '',
    studentGenderSelfDescription: golferProfile?.genderSelfDescription || '',
    studentHeightInches: golferProfile?.heightInInches || '',
    studentPhysicalLimitations: golferProfile?.physicalLimitations || '',
    studentProunounPreference: golferProfile?.pronouns || '',
  }

  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const { user } = useAuth()
  const { hideSensitiveInformation } = user

  return (
    <Container
      style={{ marginTop: golferProfile ? isTablet && '-20px' : 'inherit' }}
    >
      <GolferProfileCta student={student} />
      <StudentStats
        totalRevenueInCents={studentStatistics?.totalRevenueInCents}
        totalBookingCount={studentStatistics?.totalBookingCount}
        privateBookingCount={studentStatistics?.privateBookingCount}
        groupSessionCount={studentStatistics?.groupSessionCount}
        loading={loading}
      />

      <DisplayContact isMinor={isMinor} contact={contact} />

      <GolferInformation golferProfile={golferProfile} />

      <AdditionalInformation demographics={demographics} />

      <EmergencyInformation
        medicalProfile={medicalProfile}
        emergencyContact={emergencyContact}
      />

      {!isMobile && !hideSensitiveInformation && (
        <DeleteStudent student={student} />
      )}
    </Container>
  )
}

export default StudentDetails
