import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
} from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import { useStyles } from '../styles'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import DeleteStudentDialog from '../../delete-student-dialog'

export default function DeleteStudent({ student }) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <Box className={classes.mobileDelete}>
        <Button
          startIcon={<DeleteOutlineOutlined />}
          variant="outlined"
          className={classes.deleteButton}
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          Delete
        </Button>
        <Typography className={classes.deleteText}>
          Delete student, including all data such as transaction history,
          session history, messages, and contact information.
        </Typography>
        <DeleteStudentDialog
          student={student}
          onSuccess={() => history.push('/pga-coach/students/')}
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
        />
      </Box>
    )
  }

  return (
    <Card className={classes.statsSection} variant="outlined">
      <Typography className={classes.statsTitle}>Delete Student</Typography>
      {!isTablet && <Divider />}
      <CardContent className={classes.deleteStudent}>
        <Typography className={classes.deleteText}>
          Delete student, including all data such as transaction history,
          session history, messages, and contact information.
        </Typography>
        <Button
          startIcon={<DeleteOutlineOutlined />}
          variant="outlined"
          className={classes.deleteButton}
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          Delete
        </Button>
        <DeleteStudentDialog
          student={student}
          onSuccess={() => history.push('/pga-coach/students/')}
          open={isDeleteDialogOpen}
          setOpen={setIsDeleteDialogOpen}
        />
      </CardContent>
    </Card>
  )
}
