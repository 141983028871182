import React, { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'

const Invitation = lazy(() => import('pages/family-cup-invitation'))

export default function FCInvitationRoutes() {
  const { path } = useRouteMatch()

  return (
    <ThemeProvider theme={extendTheme(themes.familyCup)}>
      <Helmet>
        <title>PGA Family Golf Management</title>
        <meta name="description" content="PGA Family Golf Management" />
        <link rel="icon" href="/family-golf-favicon.ico" />
      </Helmet>
      <CssBaseline />
      <Suspense fallback={<LinearProgress color="secondary" />}>
        <Switch>
          <Route exact path={`${path}/:registrationId`}>
            <Invitation />
          </Route>
        </Switch>
      </Suspense>
    </ThemeProvider>
  )
}
