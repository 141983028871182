import { makeStyles } from '@material-ui/styles'
import { styled, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.selected,
    borderRadius: 40,
    minWidth: 40,
    minHeight: 40,
  },
  mainWrapper: {
    textDecoration: 'none',
  },
  action: {
    marginLeft: 'auto',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0,
    },
    color: 'rgba(0, 0, 0, 0.54)',
  },
  adornedEnd: {
    paddingRight: '8px',
    '& button': {
      padding: 0,
    },
  },
  settingsCaption: {
    marginLeft: theme.spacing(2),
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  settingsWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 8px 24px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    minHeight: theme.spacing(9),
    [theme.breakpoints.only('xs')]: {
      minHeight: theme.spacing(12),
    },
  },
  settingsDescription: {
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  settingsText: {
    marginLeft: theme.spacing(7),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(7),
    width: '80%',
  },
}))

export const SettingsBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  padding: '24px 8px 24px 24px',
  color: 'rgba(0, 0, 0, 0.87)',
  minHeight: theme.spacing(9),
}))

export const SettingsFormControlLabel = withStyles(theme => ({
  label: { marginLeft: theme.spacing(1) },
}))(FormControlLabel)

export default useStyles
