import { PGA_COM_HOST } from 'env'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
// import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useLoadGroupEventFormValues } from 'components/group-events/create-group-event-form-wizard/hooks'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  menuRow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px',
    margin: '10px',
  },
  menuFont: {
    fontSize: '14px',
    fontWeight: '500',
  },
})

const EventsActionsMenu = ({
  event,
  isOpen,
  handleClose,
  anchorEl,
  setIsDetailsOpen,
}) => {
  const classes = useStyles()
  const loadGroupEventFormValues = useLoadGroupEventFormValues()

  const menuItems = [
    {
      Icon: LaunchOutlinedIcon,
      text: 'Go to event page',
      onClick: () =>
        window.open(`${PGA_COM_HOST}/things-to-do/events/${event.slug}`),
    },
    !event.externalRegistrationUrl
      ? {
        Icon: PeopleOutlineOutlinedIcon,
        text: 'View attendees',
        onClick: () => setIsDetailsOpen('participants'),
      }
      : null,
    {
      Icon: InsertChartOutlinedOutlinedIcon,
      text: 'View stats',
      onClick: () => setIsDetailsOpen('stats'),
    },
    {
      Icon: FileCopyOutlinedIcon,
      text: 'Duplicate',
      onClick: () => loadGroupEventFormValues(event),
    },
    // {
    //   Icon: DeleteOutlinedIcon,
    //   text: 'Delete',
    // },
  ]

  return (
    <Menu open={isOpen} onClose={handleClose} anchorEl={anchorEl}>
      {menuItems
        .filter(item => item)
        .map(({ Icon, text, onClick }) => (
          <MenuItem
            key={text}
            className={classes.menuRow}
            onClick={() => {
              handleClose()
              onClick()
            }}
          >
            <Icon style={{ marginRight: '5px' }} />
            <Typography className={classes.menuFont}>{text}</Typography>
          </MenuItem>
        ))}
    </Menu>
  )
}

export default EventsActionsMenu
