import React from 'react'
import { gql, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'

const BlockTimeDestroy = ({ blockTime, onCancel, onSuccess }) => {
  const DESTROY_BLOCK_TIME = gql`
    mutation DestroyBlockTime($id: ID!) {
      destroyCoachAvailabilityBlockTime(id: $id) {
        success
        message
      }
    }
  `
  const { enqueueSnackbar } = useSnackbar()

  const [destroyBlockTime] = useMutation(DESTROY_BLOCK_TIME, {
    onCompleted: data => {
      if (data.destroyCoachAvailabilityBlockTime.success) {
        const msg = 'Block time deleted'
        enqueueSnackbar(msg, { variant: 'success' })
        onSuccess && onSuccess()
      } else {
        const msg = data.destroyCoachAvailabilityBlockTime.message
        enqueueSnackbar(msg, { variant: 'error' })
      }
    },
  })

  const onSubmit = () => {
    destroyBlockTime({
      variables: {
        id: blockTime.id,
      },
    })
  }

  return (
    <>
      <Box textAlign="center" mb={2} fontWeight="fontWeightBold">
        {blockTime.startDate}, {blockTime.startTime} - {blockTime.endDate},{' '}
        {blockTime.endTime}
      </Box>

      <Box display="flex" mb={2}>
        <Typography>
          Deleting this block time will allow students to schedule appointments
          with you, during this time.
        </Typography>
      </Box>

      <Box display="flex" mb={2}>
        <Typography>Are you sure you want to delete it?</Typography>
      </Box>

      <Box display="flex" mt={4}>
        <Box mr={2}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </Box>
        <Button onClick={onCancel} size="large">
          Cancel
        </Button>
      </Box>
    </>
  )
}

export default BlockTimeDestroy
