import React from 'react'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import pluralize from 'lib/pluralize'
import { currency } from 'lib/utils/string'
import useStyles from './group-event-registration-form.styles'
import Divider from '@material-ui/core/Divider'
import {
  isWeeklyRecurringPayment,
  isMonthlyRecurringPayment,
  resolveLastSessionStartDateTime,
} from 'utils/groupEventUtils'

const participantPriceLabel = ({
  groupEvent,
  priceInDollars,
  participantsCount,
}) => {
  let label = currency(priceInDollars)

  if (isWeeklyRecurringPayment(groupEvent)) {
    label += ' / week'
  } else if (isMonthlyRecurringPayment(groupEvent)) {
    label += ' / month'
  }

  label += ` x ${participantsCount} `
  label += pluralize(participantsCount, 'participant')

  return label
}

const recurringPaymentDisclaimer = ({ groupEvent, totalFeesInDollars }) => {
  let firstLine = ''
  let secondLine = ''

  // Add the remaining number of payments
  const remainingPayments = groupEvent.numberOfPayments - 1
  if (remainingPayments > 1) {
    firstLine += `${remainingPayments} additional payments of `
  } else {
    firstLine += `${remainingPayments} additional payment of `
  }

  // Add the total fee that will be charged
  firstLine += `${currency(totalFeesInDollars)} each will be charged`

  // Add the frequency of the payments and the end date
  const endDate = resolveLastSessionStartDateTime(groupEvent)
    .setZone(groupEvent.timezone)
    .toFormat('DD')
  if (isWeeklyRecurringPayment(groupEvent)) {
    secondLine += `weekly through ${endDate}.`
  } else if (isMonthlyRecurringPayment(groupEvent)) {
    secondLine += `monthly through ${endDate}.`
  }

  return (
    <>
      {firstLine}
      <br />
      {secondLine}
    </>
  )
}

const PriceDetails = ({ groupEvent, paymentRequired }) => {
  const classes = useStyles()
  const { watch } = useFormContext()
  const participants = watch('participants')

  const registrationFeeAmountInCents =
    groupEvent.priceInCents * participants.length

  const priceInDollars = groupEvent.priceInCents / 100
  const registrationFeeAmountInDollars = registrationFeeAmountInCents / 100
  const totalFeesInDollars = registrationFeeAmountInDollars

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1" className={classes.subTitle}>
          Price details
        </Typography>
      </Box>
      <Box
        color="text.secondary"
        display="flex"
        justifyContent="space-between"
        mb={0.5}
      >
        <Typography variant="body1">
          {participantPriceLabel({
            groupEvent,
            priceInDollars,
            participantsCount: participants.length,
          })}
        </Typography>
        <Typography variant="body1">
          {currency(registrationFeeAmountInDollars)}
        </Typography>
      </Box>
      {paymentRequired ? (
        <>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Typography variant="subtitle1">Due today</Typography>
            <Typography variant="subtitle1">
              {currency(registrationFeeAmountInDollars)}
            </Typography>
          </Box>
          {groupEvent.numberOfPayments > 1 && (
            <>
              <Box my={2}>
                <Divider />
              </Box>
              <Box
                color="text.secondary"
                textAlign={'right'}
                fontStyle={'italic'}
              >
                <Typography variant="body2">
                  {recurringPaymentDisclaimer({
                    groupEvent,
                    totalFeesInDollars,
                  })}
                </Typography>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box
            color="text.secondary"
            display="flex"
            justifyContent="space-between"
            mb={0.5}
          >
            <Typography variant="body1">Tax</Typography>
            <Typography variant="body1">–</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="body1">Total</Typography>
            <Typography variant="body1">
              {currency(registrationFeeAmountInDollars)}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={0.5}>
            <Box>
              <Typography variant="subtitle1">Due today</Typography>
              <Box
                color="text.secondary"
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="caption">
                  Pay at location, state and local taxes may apply
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1">$0.00</Typography>
          </Box>
        </>
      )}
    </>
  )
}

export default PriceDetails
