import React from 'react'
import { Link } from 'react-router-dom'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import { Box, Button, Typography } from '@material-ui/core'
import { useStyles, ContainerRoot } from './styles'
import themes from 'themes'
import PropTypes from 'prop-types'

const PrepaidSuccess = ({ coachProfileUrl }) => {
  const classes = useStyles(themes.offeringsRefresh)

  return (
    <ContainerRoot>
      <Box className={classes.successIcon}>
        <CelebrationOutlinedIcon />
      </Box>
      <Typography variant="h5" align="center">
        Payment Complete
      </Typography>
      <Typography
        variant="body1"
        align="center"
        className={classes.textWrapper}
      >
        This session has already been paid for. Get ready to book another
        session with your coach.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={coachProfileUrl}
        className={classes.ctaButton}
      >
        Book Another Session
      </Button>
    </ContainerRoot>
  )
}
export default PrepaidSuccess

PrepaidSuccess.propTypes = {
  coachProfileUrl: PropTypes.string.isRequired,
}
