import { useFieldArray, useForm } from 'react-hook-form'
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import InitialsAvatar from 'components/avatars/initials-avatar'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import YesNoToggle from './yes-no-toggle'
import CheckIcon from '@material-ui/icons/Check'
import { StyledButton } from './styles'
import { UPDATE_RSVP } from './query'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: '#EBF0F2',
    color: '#172024',
    width: '40px',
    height: '40px',
  },
}))

const Participants = ({
  participants,
  setResponded,
  groupEventId,
  refetch,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const form = useForm({
    defaultValues: {
      participants,
    },
  })
  const { handleSubmit, control, register } = form
  const { fields } = useFieldArray({
    control,
    name: 'participants',
  })
  const [updateRsvp] = useMutation(UPDATE_RSVP)

  const handleSave = formValues => {
    setResponded(true)
    Promise.all(
      formValues.participants.map(participant =>
        updateRsvp({
          variables: {
            status: participant.rsvpOnEvent,
            groupEventId,
            studentId: participant.id,
          },
        }),
      ),
    )
      .then(() => {
        refetch()
      })
      .catch(e => {
        enqueueSnackbar('Error saving RSVP', { variant: 'error' })
        setResponded(false)
      })
  }

  return (
    <>
      <Box mt={5} width="100%">
        <List>
          {fields.map(({ id, firstName, lastName, rsvpOnEvent }, index) => {
            return (
              <ListItem key={id}>
                <ListItemAvatar>
                  <InitialsAvatar
                    name={`${firstName} ${lastName}`}
                    classes={classes}
                  />
                </ListItemAvatar>
                <ListItemText primary={firstName + ' ' + lastName} />
                <input
                  type="hidden"
                  value={id}
                  name={`participants.${index}.id`}
                  ref={register()}
                />
                <YesNoToggle
                  rsvp={rsvpOnEvent}
                  index={index}
                  register={register}
                />
              </ListItem>
            )
          })}
        </List>
      </Box>
      <StyledButton
        variant="contained"
        size="small"
        startIcon={<CheckIcon />}
        onClick={handleSubmit(handleSave)}
      >
        Save
      </StyledButton>
    </>
  )
}

export default Participants
