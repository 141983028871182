import {
  ContentSection,
  ListText,
} from '../../student-show-page/details-components/content-box'
import { formatNameForDisplay } from 'lib/text-display'
import { List, ListItem, ListItemAvatar } from '@material-ui/core'
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Alert from '@material-ui/lab/Alert'
import { useSnackbar } from 'notistack'
import { useSnackbarSuccess } from 'lib/snackbar'
import { useStyles } from '../styles'

export default function DisplayContact({
  contact = { firstName: '', lastName: '', phoneNumber: '', email: '' },
}) {
  const { firstName, lastName, phoneNumber, email } = contact
  const contactName = formatNameForDisplay(`${firstName} ${lastName}`)

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)

  const copyEmailToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(email)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  const copyPhoneToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(phoneNumber)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  const classes = useStyles()
  return (
    <ContentSection
      style={{ margin: 0, height: '100%', boxSizing: 'border-box' }}
      title="Contact Information"
    >
      <List className={classes.container}>
        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar className={classes.iconWrapper}>
                <PersonOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              {firstName ? (
                <>
                  <ListText primary={contactName} />
                </>
              ) : (
                <ListText primary="N/A" />
              )}
            </ListItem>
            <ListItem>
              <ListItemAvatar className={classes.iconWrapper}>
                <LocalPhoneOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              {phoneNumber ? (
                <>
                  <a
                    href={`tel:${phoneNumber}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListText primary={phoneNumber} />
                  </a>
                  <ListItemAvatar className={classes.iconWrapper}>
                    <FileCopyOutlinedIcon
                      className={classes.copyIcon}
                      onClick={copyPhoneToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListText primary="N/A" />
              )}
            </ListItem>

            <ListItem>
              <ListItemAvatar className={classes.iconWrapper}>
                <EmailOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              {email ? (
                <>
                  <a
                    href={`mailto:${email}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListText primary={email} />
                  </a>
                  <ListItemAvatar>
                    <FileCopyOutlinedIcon
                      className={classes.copyIcon}
                      onClick={copyEmailToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListText primary="N/A" />
              )}
            </ListItem>
            <ListItem>
              <Alert style={{ width: '100%' }} severity="info">
                Consider keeping all of your conversations within the PGA Coach
                platform to protect your personal contact information.
              </Alert>
            </ListItem>
          </List>
        </ListItem>
      </List>
    </ContentSection>
  )
}
