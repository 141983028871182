import React from 'react'
import get from 'lodash/get'
import { TextField } from '@material-ui/core'

/**
 * Display any errors associated with field via helperText.
 * Expects `errors` from react-hook-form of this shape:
 *   { [fieldName: string]: { message: string, type: string } }
 */
export default function TextFieldWithError({
  errors,
  name,
  component = TextField,
  ...other
}) {
  const error = get(errors, name)

  return React.createElement(component, {
    ...other,
    name,
    error: Boolean(error),
    helperText: get(error, 'message'),
  })
}
