import React, { lazy, Suspense } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'
import {
  withProtectedRoute,
  roles,
  withAuthenticatedRoute,
  LoginRedirect,
  SignupRedirect,
} from 'lib/auth'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { SnackbarProvider } from 'notistack'

const lpgaCoachSignupRoute = '/junior-league-signup/lpga/signup'
const managerCoachSignupRoute = '/junior-league-signup/manager/coach/signup'

const Landing = lazy(() => import('pages/jrl-signup'))
const Signup = withProtectedRoute(
  lazy(() => import('pages/jrl-signup/pga')),
  roles.COACH,
)
const AccountSignupLpga = withProtectedRoute(
  () => <Redirect to={lpgaCoachSignupRoute} />,
  roles.COACH,
  <SignupRedirect
    role="coach"
    returnTo={`${window.location.origin}${lpgaCoachSignupRoute}`}
    signupParams={{ lpga: true }}
  />,
)
const SignupLpga = withProtectedRoute(
  lazy(() => import('pages/jrl-signup/lpga')),
  roles.COACH,
)
const SignupManagerCoach = withProtectedRoute(
  lazy(() => import('pages/jrl-signup/manager-coach')),
  roles.COACH,
)
const AccountSignupManagerCoach = tokenId => {
  const route = managerCoachSignupRoute + `?tokenId=${tokenId}`
  return withProtectedRoute(
    () => <Redirect to={route} />,
    roles.COACH,
    <SignupRedirect
      role="coach"
      returnTo={`${window.location.origin}${route}`}
      signupParams={{ pgacom: true }}
    />,
  )
}
const SignupManagerConsumer = withProtectedRoute(
  lazy(() => import('pages/jrl-signup/manager-consumer')),
  roles.CONSUMER,
)
const SignupGuardian = withProtectedRoute(
  lazy(() => import('pages/jrl-signup/guardian')),
  roles.CONSUMER,
)
const AccountSignupManagerConsumer = tokenId => {
  const route = '/junior-league-signup/manager/consumer/signup'
  const returnToPath = route + `?tokenId=${tokenId}`
  return withProtectedRoute(
    () => <Redirect to={returnToPath} />,
    roles.CONSUMER,
    <LoginRedirect
      role="consumer"
      returnTo={`${window.location.origin}${returnToPath}`}
    />,
  )
}

const LpgaRequestMembershipForm = lazy(() =>
  import('components/lpga-request-membership'),
)

const LPGASuccessMessage = lazy(() =>
  import('components/lpga-request-membership/lpga-success-message'),
)

const JuniorLeagueSignupRoutes = () => {
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const tokenId = new URLSearchParams(search).get('tokenId')

  return (
    <ThemeProvider theme={extendTheme(themes.juniorLeague)}>
      <Helmet>
        <title>PGA Jr. League Signup</title>
        <meta name="description" content="PGA Jr. League Signup" />
        <link rel="icon" href="/jrl-favicon.ico" />
        <style>{'body { background-color: #f9f9f9; }'}</style>
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Suspense fallback={<LinearProgress color="secondary" />}>
          <Switch>
            <Route
              exact
              path={`${path}/lpga-request`}
              component={LpgaRequestMembershipForm}
            />
            <Route
              exact
              path={`${path}/lpga-request/sent`}
              component={LPGASuccessMessage}
            />
            <Route path={`${path}/lpga/signup`} component={SignupLpga} />
            <Route path={`${path}/lpga`} component={AccountSignupLpga} />
            <Route path={`${path}/pga`} component={Signup} />
            <Route
              path={`${path}/manager/coach/signup`}
              component={SignupManagerCoach}
            />
            <Route
              path={`${path}/manager/coach`}
              component={AccountSignupManagerCoach(tokenId)}
            />
            <Route
              path={`${path}/manager/consumer/signup`}
              component={SignupManagerConsumer}
            />
            <Route
              path={`${path}/manager/consumer`}
              component={AccountSignupManagerConsumer(tokenId)}
            />
            <Route path={`${path}/guardian`} component={SignupGuardian} />
            <Route exact path={path} component={Landing} />
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withAuthenticatedRoute(JuniorLeagueSignupRoutes)
