import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import validateUuid from 'uuid-validate'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import ContentUnavailable from 'components/content-unavailable'
import Error from 'components/error'
import { useHideIntercom } from 'lib/support'
import CondensedFooter from 'components/condensed-footer'
import LeadForm from './lead-form'
import { withAuthenticatedRoute } from 'lib/auth'
import { SnackbarProvider } from 'notistack'
import CoachAppbar from 'components/coach-app-bar'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 120px)',
    overflowX: 'clip',
  },
}))

const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

const GET_COACH = gql`
  query coachesForLeadPage($externalId: ID, $slug: String) {
    coaches(externalId: $externalId, slug: $slug) {
      id
      name
      pgaCoachEligible
      facilities {
        id
        name
      }
      coachProfile {
        firstName
        lastName
        city
        state
        profilePhoto
        slug
      }
    }
  }
`

const LeadFormContent = () => {
  const { idOrSlug } = useParams()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const source = queryParams.get('source')?.toUpperCase()

  const { data, loading, error } = useQuery(GET_COACH, {
    variables: {
      ...(validateUuid(idOrSlug)
        ? { externalId: idOrSlug }
        : { slug: idOrSlug }),
    },
  })

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  const coach = data?.coaches?.[0]

  if (!coach) return <Error statusCode={404} />
  if (!coach.pgaCoachEligible) return <ContentUnavailable />

  return <LeadForm coach={coach} source={source} />
}

const CoachLeads = () => {
  useHideIntercom()
  const classes = useStyles()

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.offeringsRefresh,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach | Contact</title>
        <meta name="description" content="PGA Coach | Contact" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <CoachAppbar position="relative" hideContactButton />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box className={classes.root}>
          <LeadFormContent />
        </Box>
      </SnackbarProvider>
      <CondensedFooter />
    </ThemeProvider>
  )
}

export default withAuthenticatedRoute(CoachLeads)
