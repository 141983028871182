import React from 'react'
import { PGA_COM_HOST } from 'env'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import IconFlag from 'components/icons/icon-flag'
import IconMarker from 'components/icons/icon-marker'
import { formatNameForDisplay } from 'lib/text-display'
import ToggleVisibility from './ToggleVisibility'
import { secureLink, downloadQR } from 'lib/utils'
import { useStyles } from './styles'
import { useIsMobile } from 'lib/hooks'
import QRCode from 'qrcode.react'
import { IconDownload } from 'components/atoms/icons'

const getFacilityName = facilities => get(facilities, ['0', 'facilityName'], '')

const getFacilityAddress = facilities => {
  const city = get(facilities, ['0', 'city'])
  const state = get(facilities, ['0', 'state'])
  if (city && state) {
    return `${city}, ${state}`
  }
  return ''
}

const CoachProfileCard = ({ coachProfile, toggleCoachStatus }) => {
  const {
    coachProfileId,
    firstName,
    lastName,
    facilities,
    profilePhoto,
    title,
    status,
    customSlug,
    city,
    state,
    coach,
  } = coachProfile

  const classes = useStyles()
  const facilityAddress = getFacilityAddress(facilities)
  const facilityName = getFacilityName(facilities)
  const coachName = `${firstName} ${lastName}`
  const isMobile = useIsMobile()
  const hasActiveCoachProfile = coachProfile?.status === 'ACTIVE'
  const coachProfileUrl = `${PGA_COM_HOST}/coach/${customSlug}?from-qr-code=true`

  return (
    <Card variant="outlined" className={classes.cardRoot}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'flex-start'}
            >
              <Box className={classes.avatarWrapper}>
                <Avatar
                  aria-label="coach"
                  src={secureLink(profilePhoto)}
                  className={classes.avatarStyles}
                >
                  {!profilePhoto &&
                    `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`}
                </Avatar>
              </Box>
              <Box
                px={isMobile ? 0 : 4}
                style={{ textAlign: isMobile ? 'center' : 'left' }}
              >
                <Typography variant="h5" style={{ fontWeight: '500' }}>
                  {formatNameForDisplay(coachName)}
                </Typography>
                <Typography variant="body2" className={classes.titleStyles}>
                  {title}
                </Typography>
                {!isEmpty(city) && !isEmpty(state) && (
                  <div className={classes.iconWrapper}>
                    <span className={classes.cardDetailIcon}>
                      <IconMarker />
                    </span>
                    <span className={classes.cardDetail}>
                      {city}, {state}
                    </span>
                  </div>
                )}
                {!isEmpty(facilityName) && !isEmpty(facilityAddress) && (
                  <div className={classes.iconWrapper}>
                    <span className={classes.cardDetailIcon}>
                      <IconFlag />
                    </span>
                    <span className={classes.cardDetail}>
                      {facilityName}, {facilityAddress}
                    </span>
                  </div>
                )}
              </Box>
            </Box>
          </Grid>
          {hasActiveCoachProfile && (
            <Grid
              container
              item
              sm={4}
              justifyContent={isMobile ? 'center' : 'flex-end'}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.qrCodeWrapper}
              >
                <QRCode
                  value={coachProfileUrl}
                  id="coach-profile-qr-code"
                  data-testid="coach-profile-qr-code"
                  size={100}
                  level="H" // error correction level (H = high) for more squareness and less likely to be misread
                  className={classes.qrCode}
                />
                <Button onClick={downloadQR} startIcon={<IconDownload />}>
                  Download
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>

      {coach.canPromoteContent && (
        <CardActions className={classes.cardActions}>
          <Box px={0.5}>
            <ToggleVisibility
              coachProfileId={coachProfileId}
              status={status}
              toggleCoachStatus={toggleCoachStatus}
            />
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.cardActionText}
              >
                Control the visibility of your coach profile on PGA.com. To be
                promoted and to begin receiving leads from prospective
                customers, your visibility must be turned on.
              </Typography>
            </Box>
          </Box>
        </CardActions>
      )}
    </Card>
  )
}

CoachProfileCard.propTypes = {
  coachprofile: PropTypes.object,
  adminPage: PropTypes.bool,
  toggleCoachStatus: PropTypes.func,
}

export default CoachProfileCard
