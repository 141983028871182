import React, { useState } from 'react'
import { Grid, Typography, Button, Divider } from '@material-ui/core'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { styled } from '@material-ui/styles'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import { useSnackbar, useSnackbarSuccess, useSnackbarError } from 'lib/snackbar'
import { useMutation } from '@apollo/client'
import { NUDGE_GOLFER_INTAKE } from './api-hooks'
import { get } from 'lodash'
import hasNonNullProperty from 'lib/utils/has-non-null-property'

const useStyles = makeStyles(theme => ({
  responsiveButton: {
    maxHeight: '4rem',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.3px',
    fontFamily: 'Roboto',
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '.75rem',
    },
  },
}))
const StyledGrid = styled(Grid)(({ isMobile, isTablet }) => ({
  display: 'flex',
  flexDirection: isMobile || isTablet ? 'column' : 'row',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '24px 0 0',
  maxWidth: '952px',
  width: '100%',
  minHeight: '86px',
  padding: '8px 1rem',
  alignSelf: 'center',
  borderRadius: '9px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #2196F3',
}))
const StyledText = styled(Typography)(() => ({
  color: '#00234B',
  lineHeight: '24px',
  fontWeight: '400',
  fontSize: '16px',
}))
const StyledSubGrid = styled(Grid)(({ isTablet }) => ({
  padding: isTablet ? '.5rem 1rem' : '0 1rem',
}))

const GolferProfileCta = ({ student }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const [mutationSent, setMutationSent] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const snackbarError = useSnackbarError(enqueueSnackbar)
  const [requestProfileFulfillment] = useMutation(NUDGE_GOLFER_INTAKE)

  const hasCompletedProfile = hasNonNullProperty(student?.golferProfile)
  const { hasBeenIntakeNudged } = student
  if (hasBeenIntakeNudged || hasCompletedProfile) return null

  const handleProfileNudge = async () => {
    try {
      const { data } = await requestProfileFulfillment({
        variables: { studentIds: [student.id] },
      })
      const success = get(data, ['SendGolferIntakeNudge', 'success'])
      const message = get(data, ['SendGolferIntakeNudge', 'message'])
      if (!success) {
        return snackbarError(
          message ||
            "This golfer has already been nudged. You're unable to send a new profile nudge",
        )
      }
      snackbarSuccess(
        `${student.firstName +
          ' ' +
          student.lastName} has been sent a request to complete their profile`,
      )
      setTimeout(() => {
        setMutationSent(true)
      }, 1000)
    } catch (e) {
      snackbarError('Unable to send request to complete profile')
    }
  }
  if (mutationSent) return null

  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledGrid item xs={12} isMobile={isMobile} isTablet={isTablet}>
        {!isMobile && !isTablet && (
          <>
            <PersonOutlineOutlinedIcon color="primary" />
            <Divider
              orientation="vertical"
              flexItem
              style={{ margin: '0 1rem' }}
            />
          </>
        )}
        <StyledSubGrid item xs={12} md={9}>
          <StyledText variant="body1">
            Request that your student complete their golfer profile. This form
            will help cater your instruction and learn more about their past
            golf experience.
          </StyledText>
        </StyledSubGrid>
        <StyledSubGrid item xs={12} md={4}>
          <Button
            variant="outlined"
            className={classes.responsiveButton}
            onClick={handleProfileNudge}
          >
            Request to complete profile
          </Button>
        </StyledSubGrid>
      </StyledGrid>
    </Grid>
  )
}
export default GolferProfileCta
