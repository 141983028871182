import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { DateTime } from 'luxon'
import { buildDateTime, isInvalidDate } from 'lib/CustomLuxonUtils'
import { draftToMarkdown } from 'markdown-draft-js'
import { useFeatureFlags, flags } from 'lib/feature-flags'
import { dollarsToCents } from 'lib/utils/number'
import WizardForm from 'components/wizard-form'
import GetStartedStep from './steps/get-started'
import MultiSessionEventDayTimeStep from './steps/multi-session-event-day-time'
import ExternalRegistrationUrlStep, {
  registrationCloseDates,
} from './steps/external-registration-url'
import PaymentAccountStep from './steps/payment-account'
import FeaturedImageStep from './steps/featured-image'
import EventDetailsStep from './steps/event-details'
import SuccessStep from './steps/success'
import { gtmEvent } from 'lib/gtm'
import UnlistedStep from './steps/unlisted'
import AttendanceRestrictionsStep from './steps/attendance-restrictions'
import { CREATE_GROUP_EVENT } from './queries'
import { inputPriceRegExp } from 'utils/regExp'
import {
  isOnlinePayment,
  PAYMENT_SCHEDULES,
  NON_RECURRING_PAYMENT_SCHEDULES,
  MANUAL_PAYMENT,
  composeMaxAttendance,
} from 'utils/groupEventUtils'
import { validateSessionOverlap } from 'pages/pga-coach/bookings/events/utils'

const defaultValues = {
  startDate: null,
  startTime: null,
  title: '',
  endTime: null,
  tags: [],
  featuredImageUrl: '',
  isExternalRegistration: false,
  externalRegistrationUrl: null,
  paymentRecurrenceSchedule: undefined,
  unlisted: false,
  locationId: '',
  academyId: '',
  isAcademyEvent: null,
  academyRequired: false,
  assistantCoachIds: [],
  hoursBeforeRegistrationCloses: registrationCloseDates[0].hoursBefore,
  sessions: (() => {
    const startDateTime = DateTime.local().plus({ weeks: 1 })
    return [
      {
        startDate: startDateTime.startOf('day'),
        startTime: startDateTime.set({ hour: 8, minute: 0, second: 0 }),
        endTime: startDateTime.set({ hour: 9, minute: 0, second: 0 }),
      },
    ]
  })(),
  waitlistEnabled: false,
  ageGroup: 'all-ages',
  collectAttendance: false,
}

const validationSchema = (
  coachFacilities,
  paymentAccounts,
  coachAcademies,
  coachFacilityLocations,
  isRecurringPaymentScheduleEnabled,
  user,
) => {
  return yup.object().shape({
    title: yup
      .string()
      .required('Please enter a name for your event')
      .min(1),
    maxAttendance: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return String(originalValue).trim() === '' ? null : value
      })
      .min(0),
    price: isRecurringPaymentScheduleEnabled
      ? yup
        .string()
        .when(
          'paymentRecurrenceSchedule',
          (paymentRecurrenceSchedule, schema) => {
            const priceValidation = schema
              .matches(inputPriceRegExp, 'Please enter a valid price')
              .required('Please specify a price per person')

            if (isOnlinePayment({ paymentRecurrenceSchedule })) {
              return priceValidation.test(
                'isPositiveNumber',
                'Price must be a number greater than $0.00. For free offerings, please choose "Manually Collect Payment" from the "Payment Collection" dropdown.',
                value => {
                  const numberValue = parseFloat(value)
                  return !isNaN(numberValue) && numberValue > 0
                },
              )
            }

            return priceValidation
          },
        )
      : yup
        .string()
        .matches(inputPriceRegExp, 'Please enter a valid price')
        .required('Please specify a price per person'),
    unlisted: yup.boolean().required(),
    paymentRecurrenceSchedule: yup.string().when('isExternalRegistration', {
      is: true,
      then: yup
        .string()
        .oneOf(
          [MANUAL_PAYMENT],
          'External events must collect payments maually',
        )
        .required('Please select a payment schedule'),
      otherwise: yup
        .string()
        .oneOf(
          isRecurringPaymentScheduleEnabled
            ? PAYMENT_SCHEDULES
            : NON_RECURRING_PAYMENT_SCHEDULES,
          'Please select a payment schedule',
        )
        .required('Please select a payment schedule'),
    }),
    paymentAccountId: yup
      .string()
      .oneOf(['', ...paymentAccounts.map(pa => pa.id)])
      .when('paymentRecurrenceSchedule', paymentRecurrenceSchedule => {
        if (isOnlinePayment({ paymentRecurrenceSchedule })) {
          return yup.string().required('Please select a payment account')
        } else {
          return yup.string().nullable()
        }
      }),
    locationId: yup
      .string()
      .required('Please select a location for your event'),
    tags: yup
      .array()
      .required()
      .min(1, 'Please select at least one tag for your event')
      .test(
        'max-tags',
        'Please select a maximum of 5 tags for your event',
        function (value) {
          const ageGroupTags = ['Adults Only', 'Juniors Only']
          const nonAgeGroupTags = value.filter(
            tag => !ageGroupTags.includes(tag),
          )
          return nonAgeGroupTags.length <= 5
        },
      ),
    academyRequired: yup.boolean(),
    // if academyRequired is true then we want this field to be required other wise it should be nullable
    academyId: yup.string().when('academyRequired', {
      is: true,
      then: yup.string().required('Please select a Section'),
      otherwise: yup.string().nullable(),
    }),
    featuredImageUrl: yup
      .string()
      .required('Please upload a featured image for your event'),
    sessions: yup
      .array()
      .required()
      .min(1, 'Group events must have at least one date/time occurrence')
      .test('validate', 'Sessions cannot overlap', value =>
        validateSessionOverlap(value),
      )
      .when('locationId', (locationId, schema) => {
        return schema.of(
          yup
            .object()
            .shape({
              startDate: yup.date().required(),
              startTime: yup
                .date()
                .typeError('Start time must be a valid time')
                .label('Start Time')
                .required(),
              endTime: yup
                .date()
                .typeError('End time must be a valid time')
                .label('End Time')
                .required()
                .test(
                  'isAfterStartTime',
                  'End time must be after start time',
                  function (value) {
                    const { startTime } = this.parent
                    return value > startTime
                  },
                ),
            })
            .test(
              'sessionIsAvailable',
              'Session is not available',
              async function (session) {
                try {
                  // Don't validate unless we have available information
                  if (!locationId) return true
                  if (isInvalidDate(session.startTime)) return true
                  if (isInvalidDate(session.endTime)) return true
                  if (session.startTime >= session.endTime) return true

                  return true
                } catch (error) {
                  console.error(error)
                  return this.createError({
                    message: 'session validation error',
                  })
                }
              },
            ),
        )
      }),
    isExternalRegistration: yup.boolean(),
    externalRegistrationUrl: yup
      .string()
      .url('Please enter a valid URL (e.g., https://www.example.com)')
      .when('isExternalRegistration', {
        is: true,
        then: yup
          .string()
          .required('Please enter a valid URL (e.g., https://www.example.com)'),
      }),
    hoursBeforeRegistrationCloses: yup.number().when('isExternalRegistration', {
      is: false,
      then: yup.number().required(),
    }),
    accessCode: yup.string().matches(/^[a-z0-9]+$/i, {
      message: 'Access codes must consist of numbers and letters only',
      excludeEmptyString: true.valueOf,
    }),
    waitlistEnabled: yup.boolean(),
    collectAttendance: yup.boolean(),
  })
}

const CreateGroupEventFormWizard = ({
  coachFacilities,
  paymentAccounts,
  coachAcademies = [],
  coachFacilityLocations,
  showOnboardingModal,
  backUrl,
}) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [createdGroupEvent, setCreatedGroupEvent] = useState(null)
  const [isRecurringPaymentScheduleEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_GROUP_EVENT_RECURRING_PAYMENTS_ENABLED,
  ])
  const { user } = useAuth()
  const [createGroupEvent] = useMutation(CREATE_GROUP_EVENT, {
    onError: error => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    },
    onCompleted: data => {
      if (data.createGroupEvent.success) {
        if (user.onboarding) {
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'group-event',
            formAction: 'completed-onboarding-create-group-event',
          })
        } else {
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'group-event',
            formAction: 'completed-create-group-event',
          })
        }
        setCreatedGroupEvent(data.createGroupEvent.groupEvent)
      } else {
        enqueueSnackbar(data.createGroupEvent.message, {
          variant: 'error',
        })
      }
    },
  })
  if (createdGroupEvent) {
    return (
      <SuccessStep
        slug={createdGroupEvent.slug}
        onCreateAnother={() => setCreatedGroupEvent(null)}
        showOnboardingModal={showOnboardingModal}
      />
    )
  }

  const buildEventCreationSteps = formValues => [
    {
      component: <GetStartedStep />,
      fieldsToValidate: [],
      nextButtonLabel: 'Start from scratch',
      stepName: 'get-started',
    },
    {
      component: <EventDetailsStep />,
      fieldsToValidate: [
        'title',
        'locationId',
        'tags',
        'description',
        'academyId',
      ],
      nextButtonLabel: 'Next: Featured Image',
      stepName: 'event-details',
    },
    {
      component: <FeaturedImageStep />,
      fieldsToValidate: ['featuredImageUrl'],
      nextButtonLabel: 'Next: Recurrence',
      stepName: 'featured-image',
    },
    {
      component: <MultiSessionEventDayTimeStep facilities={coachFacilities} />,
      fieldsToValidate: ['sessions'],
      nextButtonLabel: 'Next: Registration',
      stepName: 'event-day-time',
    },
    {
      component: <ExternalRegistrationUrlStep />,
      fieldsToValidate: formValues.isExternalRegistration
        ? ['externalRegistrationUrl', 'collectAttendance']
        : ['hoursBeforeRegistrationCloses', 'collectAttendance'],
      nextButtonLabel: formValues.isExternalRegistration
        ? 'Next: Price'
        : 'Next: Restrictions',
      stepName: 'external-registration-url',
    },
    ...(!formValues.isExternalRegistration
      ? [
        {
          component: <AttendanceRestrictionsStep />,
          fieldsToValidate: ['maxAttendance', 'accessCode'],
          nextButtonLabel: 'Next: Price',
          stepName: 'attendance-restrictions',
        },
      ]
      : []),
    {
      component: <PaymentAccountStep />,
      fieldsToValidate: [
        'paymentRecurrenceSchedule',
        'price',
        'paymentAccountId',
      ],
      nextButtonLabel: !formValues.isExternalRegistration
        ? 'Next: Visibility'
        : 'Create Event',
      nextButtonLabelMobile: !formValues.isExternalRegistration
        ? 'Next'
        : 'Create Event',
      stepName: 'event-cost',
    },
    ...(!formValues.isExternalRegistration
      ? [
        {
          component: <UnlistedStep />,
          fieldsToValidate: ['unlisted'],
          nextButtonLabel: 'Create Event',
          nextButtonLabelMobile: 'Create Event',
          stepName: 'unlisted',
        },
      ]
      : []),
  ]

  return (
    <WizardForm
      formProps={{
        defaultValues: {
          ...defaultValues,
        },
        resolver: yupResolver(
          validationSchema(
            coachFacilities,
            paymentAccounts,
            coachAcademies,
            coachFacilityLocations,
            isRecurringPaymentScheduleEnabled,
            user,
          ),
        ),
      }}
      formName="group-event"
      steps={buildEventCreationSteps}
      extraData={{
        coachFacilities,
        paymentAccounts,
        coachAcademies,
      }}
      onSubmit={async formValues => {
        const ageGroupMap = new Map([
          ['adults-only', 'Adults Only'],
          ['juniors-only', 'Juniors Only'],
        ])
        const filteredTags = formValues.tags.filter(
          tag => !['Juniors Only', 'Adults Only'].includes(tag),
        )
        const ageGroupValue = ageGroupMap.get(formValues.ageGroup)
        if (ageGroupValue) {
          filteredTags.push(ageGroupValue)
        }
        const selectedFacility = coachFacilityLocations?.find(
          cf => cf.id === formValues.locationId,
        )
        const startDate = formValues.sessions[0].startDate
        const startTime = formValues.sessions[0].startTime
        const startDateTime = buildDateTime(
          startDate,
          startTime,
          selectedFacility.timezone,
        )
        return createGroupEvent({
          variables: {
            groupEvent: {
              title: formValues.title,
              academyId: formValues.academyId,
              academyRequired:
                formValues.academyRequired || !!formValues.academyId,
              assistantCoachIds: formValues.assistantCoachIds,
              description: formValues.description
                ? draftToMarkdown(JSON.parse(formValues.description))
                : null,
              registrationCloseDateTime: !formValues.isExternalRegistration
                ? startDateTime.minus({
                  hours: formValues.hoursBeforeRegistrationCloses,
                })
                : null,
              tags: filteredTags,
              maxAttendance: composeMaxAttendance(
                formValues.maxAttendance,
                formValues.waitlistEnabled,
              ),
              unlisted: formValues.unlisted,
              priceInCents: dollarsToCents(formValues.price),
              externalRegistrationUrl: formValues.isExternalRegistration
                ? formValues.externalRegistrationUrl
                : null,
              featuredImageUrl: formValues.featuredImageUrl,
              paymentRecurrenceSchedule: formValues.paymentRecurrenceSchedule,
              paymentAccountId:
                isOnlinePayment(formValues) &&
                (formValues.paymentAccountId || '').length > 0
                  ? formValues.paymentAccountId
                  : null,
              accessCode:
                (formValues.accessCode || '').length > 0
                  ? formValues.accessCode
                  : null,
              sessions: formValues.sessions.map(session => ({
                facilityId: selectedFacility.id,
                startDateTime: buildDateTime(
                  session.startDate,
                  session.startTime,
                  selectedFacility.timezone,
                ),
                endDateTime: buildDateTime(
                  session.startDate,
                  session.endTime,
                  selectedFacility.timezone,
                ),
              })),
              waitlistEnabled: formValues.waitlistEnabled,
              collectAttendance: formValues.collectAttendance,
            },
          },
        })
      }}
      onCancel={() => history.push(backUrl || '/pga-coach/bookings/events')}
    />
  )
}

export default CreateGroupEventFormWizard
