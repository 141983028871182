import React from 'react'
import { Box, Typography } from '@material-ui/core'
import useStyles from './styles'
import CurrencyField from 'components/form-util/currency'

const BookingTypePrice = ({ register, errors }, ref) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography variant="subtitle1" gutterBottom>
        Price
      </Typography>
      <Typography variant="body2" gutterBottom>
        Enter the price you would like to charge each student that signs up for
        this booking type.
      </Typography>
      <CurrencyField
        className={classes.formFieldCurrencyWithNotice}
        name="price"
        label="Price for student"
        inputRef={register}
        error={!!errors.price}
        helperText={errors.price && errors.price.message}
      />
    </Box>
  )
}

export default React.forwardRef(BookingTypePrice)
