import React from 'react'
import { get } from 'lodash'
import { useAuth } from 'lib/auth'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import validateUuid from 'uuid-validate'
import { useQuery } from '@apollo/client'
import PickLessonTypeLayoutWithLeadForm from './pick-lesson-type-layout-with-lead-form'
import { LessonTypeQuery, GET_LESSON_CREDIT_BALANCE } from './query'
import Error from 'components/error'
import { parseData } from './utils'
import SelectTypeSkeleton from './select-type-skeleton'
import RequestTimeForm from '../RequestTimeForm'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from '../../../themes'
import LeadForm from '../../coach-leads/lead-form'
import { useFeatureFlags, flags } from 'lib/feature-flags'

const SelectLessonType = ({ idOrSlug }) => {
  const [leadConsolidationEnabled] = useFeatureFlags([
    flags.FLAG_LEAD_CONSOLIDATION,
  ])
  const location = useLocation()
  const isExternalId = validateUuid(idOrSlug)
  const { user } = useAuth()
  const { returnUrl } = qs.parse(location.search)
  const coachIsViewingOwnSchedule =
    user?.coach?.coachProfile?.slug === idOrSlug ||
    user?.externalId === idOrSlug
  const {
    data: lessonCreditBalancesData,
    loading: lessonCreditBalancesLoading,
    error: lessonCreditBalancesError,
  } = useQuery(GET_LESSON_CREDIT_BALANCE)

  const lessonCreditBalances = lessonCreditBalancesData?.currentUser?.attributes

  const { data, loading, error } = useQuery(LessonTypeQuery, {
    variables: {
      ...(isExternalId ? { externalId: idOrSlug } : { slug: idOrSlug }),
    },
  })

  if (loading || lessonCreditBalancesLoading) return <SelectTypeSkeleton />
  if (error || lessonCreditBalancesError) {
    return <Error error={error || lessonCreditBalancesError} />
  }

  const coach = data?.coaches[0]

  if (coach.coachProfile?.bookable || coachIsViewingOwnSchedule) {
    return (
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        <PickLessonTypeLayoutWithLeadForm
          data={parseData({ ...data, coachIsViewingOwnSchedule })}
          coach={coach}
          coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
          lessonCreditBalances={get(
            lessonCreditBalances,
            'lessonCreditBalances',
          )}
          returnUrl={returnUrl}
        />
      </ThemeProvider>
    )
  }

  if (coach.coachProfile) {
    return (
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        {leadConsolidationEnabled ? (
          <LeadForm coach={coach} source="MYPGA_SCHEDULE_NO_OFFERINGS" />
        ) : (
          <RequestTimeForm coach={coach} source="MYPGA_SCHEDULE_NO_OFFERINGS" />
        )}
      </ThemeProvider>
    )
  }

  return null
}

export default SelectLessonType
