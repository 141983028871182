import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import { formFields } from '../constants'
import { useIsMobile } from 'lib/hooks'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

const MAX_TOOLS = 8
const toolsMsg = `Add up to ${MAX_TOOLS} tools that apply to your coaching practice.`

const Tools = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props
  const isMobile = useIsMobile()

  const handleAddTool = () => {
    const newTools = values.toolJsonData
    newTools.push('')
    setFieldValue('toolJsonData', newTools)
  }

  const handleDeleteTool = index => {
    const newTools = values.toolJsonData
    newTools.splice(index, 1)
    setFieldValue('toolJsonData', newTools)
  }

  return (
    <div key="profile-form-tools">
      <Typography variant="body2" color="textSecondary">
        {toolsMsg}
      </Typography>
      <Box style={{ paddingTop: '1rem' }}>
        {values.toolJsonData &&
          values.toolJsonData.map((item, i) => {
            return (
              <Box
                key={i}
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="flex-start"
                justifyContent="flex-start"
                pb={3}
              >
                <TextField
                  name={`toolJsonData[${i}]`}
                  placeholder="Tool"
                  type="text"
                  variant="standard"
                  value={item}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`multiline ${errors.toolJsonData &&
                    errors.toolJsonData[i] &&
                    touched.toolJsonData &&
                    touched.toolJsonData[i] &&
                    ' error'}`}
                  error={
                    errors.toolJsonData &&
                    errors.toolJsonData[i] &&
                    touched.toolJsonData &&
                    touched.toolJsonData[i]
                  }
                  helperText={
                    touched.toolJsonData &&
                    touched.toolJsonData[i] &&
                    errors.toolJsonData &&
                    errors.toolJsonData[i]
                  }
                  InputProps={{
                    endAdornment: !isMobile && (
                      <InputAdornment position="end">
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleDeleteTool(i)}
                          startIcon={<DeleteOutlinedIcon />}
                        >
                          Delete
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                {isMobile && (
                  <Box pt={2}>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<DeleteOutlinedIcon />}
                      onClick={() => handleDeleteTool(i)}
                    >
                      Delete
                    </Button>
                  </Box>
                )}
              </Box>
            )
          })}
      </Box>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        onClick={() => handleAddTool()}
        disabled={values.toolJsonData.length >= MAX_TOOLS}
      >
        Add Tool
      </Button>
    </div>
  )
}

Tools.propTypes = {
  values: formFields,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
}

Tools.defaultProps = {}

export default Tools
