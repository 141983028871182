import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { PageHeader } from 'components/page-header'
import AvailabilityManager from './availability-manager'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7),
    [theme.breakpoints.only('xs')]: {
      margin: '16px 16px 96px 16px',
    },
  },
}))

const AvailabilityPage = () => {
  const classes = useStyles()
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'Settings',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]
  return (
    <>
      <PageHeader title="Availability" crumbs={crumbs} />
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <AvailabilityManager />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AvailabilityPage
