import React from 'react'
import { Link } from 'react-router-dom'

const AccountRouterLink = ({ to, ...props }) => {
  return (
    <Link
      to={`${to}?return_to=${encodeURIComponent(window.location.pathname)}`}
      {...props}
    />
  )
}

export default AccountRouterLink
