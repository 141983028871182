import React, { useCallback } from 'react'
import { PGA_COM_HOST } from 'env'
import PropTypes from 'prop-types'
import { gtmEvent } from 'lib/gtm'
import { useAuth } from 'lib/auth'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CoachLessonHeader from '../components/CoachLessonHeader'
import TransactionSummary from './transaction-summary'
import { useStyles } from './styles'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import { useIsMobile } from 'lib/hooks'
import InitialsAvatar from 'components/avatars/initials-avatar'
import { ContainerRoot } from '../components/styles'
import { useHistory } from 'react-router-dom'
import SummaryBookingButtons from '../components/SummaryBookingButtons'
import FCCSummaryBookingButtons from '../components/FCCSummaryBookingButtons'

const SummaryLayout = ({
  idOrSlug,
  lessonType,
  lessonTypeId,
  locationId,
  student,
  cardBrand,
  lastDigits,
  isComplete,
  coachIsViewingOwnSchedule,
}) => {
  const { title, priceInCents, coach, acceptsOnlinePayments } = lessonType
  const { isCoach } = useAuth()
  const classes = useStyles()
  const history = useHistory()

  const isCoachingCenterBooking = history.location.pathname.includes(
    'pga-coaching-center-at-pga-frisco',
  )
  const paymentRequired = !isCoach && acceptsOnlinePayments

  const studentName = `${student.firstName} ${student.lastName}`

  const bookNewSessionUrl = isCoachingCenterBooking
    ? coach?.bookingUrl
    : `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}`

  const onUpdateGolferProfile = useCallback(() => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-update-golfer-profile-button',
    })
    window.location.href = `/account/students/${student?.id}/profile/edit`
  }, [student])

  const onBookAnotherSession = useCallback(() => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-another-session-button',
    })
    window.open(bookNewSessionUrl, '_blank')
  }, [bookNewSessionUrl])

  const onViewCoachOfferings = useCallback(() => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-coachofferings-button',
    })
    window.location.href = window.location.pathname
  }, [])

  const onCoachingCenterBooking = useCallback(() => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-coachingcenterofferings-button',
    })
    window.location.href = `${PGA_COM_HOST}/coaching-centers/${lessonType.academy.slug}`
  }, [lessonType])

  const isMobile = useIsMobile()

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <ContainerRoot className={classes.root}>
        <Grid container justifyContent="center">
          <Grid item md={7} xs={12}>
            <Typography variant="h3" color="primary">
              Thank you!
            </Typography>
            <Typography variant="body1">
              Your booking is complete. You will receive a confirmation email
              shortly.
            </Typography>
            <Divider className={classes.divider} />
            {!coachIsViewingOwnSchedule ||
              (paymentRequired && (
                <TransactionSummary
                  title={title}
                  priceInCents={priceInCents}
                  cardBrand={cardBrand}
                  lastDigits={lastDigits}
                />
              ))}
            <Grid item xs={12} style={{ marginTop: '24px' }}>
              <CoachLessonHeader lessonType={lessonType} coach={coach} />
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              item
              xs={12}
              sm={6}
              spacing={2}
              className={classes.rowWrapped}
            >
              <InitialsAvatar name={`${studentName}`} classes={classes} />
              <div className={classes.infoWrapped}>
                <Typography
                  variant="caption"
                  className={`${classes.fontBolded} ${classes.captionText}`}
                >
                  {studentName}
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.captionText} ${classes.lighterText}`}
                >
                  Student
                </Typography>
              </div>
            </Grid>
            <Grid
              container
              spacing={1}
              direction={isMobile ? 'column' : 'row'}
              justifyContent={isMobile ? 'center' : 'space-around'}
              style={{ marginTop: '1.5rem' }}
            >
              {isCoachingCenterBooking ? (
                <FCCSummaryBookingButtons
                  isMobile={isMobile}
                  golferProfile={student?.golferProfile}
                  onBookAnotherSession={onBookAnotherSession}
                  onCoachingCenterBooking={onCoachingCenterBooking}
                  onUpdateGolferProfile={onUpdateGolferProfile}
                  classes={classes}
                />
              ) : (
                <SummaryBookingButtons
                  isMobile={isMobile}
                  coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
                  onBookAnotherSession={onBookAnotherSession}
                  onViewCoachOfferings={onViewCoachOfferings}
                  classes={classes}
                />
              )}
            </Grid>
            <DownloadMyPGAsection />
          </Grid>
        </Grid>
      </ContainerRoot>
    </ThemeProvider>
  )
}

SummaryLayout.propTypes = {
  idOrSlug: PropTypes.string.isRequired,
  lessonType: PropTypes.shape({
    title: PropTypes.string,
    priceInCents: PropTypes.number,
    coach: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
      bookingUrl: PropTypes.string, // new
    }).isRequired,
    acceptsOnlinePayments: PropTypes.bool,
    academy: PropTypes.shape({
      // new
      slug: PropTypes.string,
    }),
  }).isRequired,
  lessonTypeId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired, // updated
    lastName: PropTypes.string.isRequired, // updated
    id: PropTypes.string,
    golferProfile: PropTypes.object, // new
  }).isRequired,
  cardBrand: PropTypes.string,
  lastDigits: PropTypes.string,
  isComplete: PropTypes.bool,
  coachIsViewingOwnSchedule: PropTypes.bool,
}
export default SummaryLayout
