import { makeStyles, styled } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    minWidth: '500px',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      minWidth: 'unset',
    },
  },
  emptyIcon: {
    color: 'rgba(0, 35, 75, 1) !important',
    backgroundColor: 'rgba(244, 246, 248, 1) !important',
    height: '83px',
    width: '83px',
  },
  emptyText: {
    fontWeight: 500,
    fontSize: '20px',
    lineHight: '32px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
  },
  emptySubtext: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  icon: {
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem !important',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: '2rem',
    },
  },
  copyIcon: {
    marginLeft: '1.5rem',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0.75rem',
    },
  },
  linkText: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  pgaIcon: {
    height: '40px',
    width: '40px',
  },
  gridContainer: {
    paddingTop: '1.5em',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  gridItem: {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 0px',
    gap: '0.75em',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minWidth: '215px !important',
    marginBottom: '1.5em',
    marginTop: '1.5em',
  },
  personIcon: {
    position: 'absolute',
    fontSize: '42px',
  },
  infoText: {
    fontWeight: '500',
    fontSize: '30px',
  },
  captionText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '400',
    fontSize: '16px',
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
  },
  informationColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    paddingRight: '0',
    minWidth: '20%',
    wordBreak: 'break-word',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    paddingRight: '0',
    minWidth: '20%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  chip: {
    flexDirection: 'row',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),

    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 0,
      right: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
  },
  emergencyListItem: {
    paddingLeft: '0px',
  },
  deleteLead: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  deleteButton: {
    maxHeight: '40px',
    [theme.breakpoints.only('xs')]: {
      boxSizing: 'border-box !important',
      display: 'block',
      minWidth: '95px',
      maxWidth: '95px',
      minHeight: '95px',
      maxHeight: '95px',
      fontSize: '12px',
      paddingRight: 0,
      paddingLeft: 0,
      marginRight: '24px',
      borderColor: '#E0E0E0',
      '& > * > *': {
        marginRight: 0,
      },
    },
  },
  mobileDelete: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5em',
  },
  deleteText: {
    maxWidth: '60%',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: 0,
    },
  },
  fields: {
    border: '0 !important',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '28px',
    paddingBottom: 0,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  section: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sessionHeader: {
    paddingBottom: '3px',
    paddingTop: '2rem',
    fontWeight: '400',
  },
  sessionIcon: {
    color: 'rgba(0, 35, 75, 1) !important',
    backgroundColor: 'rgba(244, 246, 248, 1) !important',
    marginRight: '15px',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 6px',
  },
  tableContainer: {
    paddingBottom: '25px',
    minHeight: '75%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  sessionStart: {
    borderTop: '1px solid #EEEEEE !important',
    borderLeft: '1px solid #EEEEEE !important',
    borderBottom: '1px solid #EEEEEE !important',
    borderRadius: '8px 0 0 8px!important',
  },
  sessionMiddle: {
    borderTop: '1px solid #EEEEEE !important',
    borderBottom: '1px solid #EEEEEE !important',
  },
  sessionEnd: {
    borderTop: '1px solid #EEEEEE !important',
    borderRight: '1px solid #EEEEEE !important',
    borderBottom: '1px solid #EEEEEE !important',
    borderRadius: '0 8px 8px 0 !important',
  },
  subtext: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
  },
  toolbar: {
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  tabs: {
    paddingRight: '4rem',
    minWidth: '300px !important',
    maxWidth: '375px !important',
    height: '40px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  controlsContainer: {
    display: 'flex',
    width: '80%',
    marginLeft: 0,
    marginRight: 'auto',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  yearFilter: {
    marginTop: '1rem',
  },
}))

export const TileWrapper = styled('div')(({ theme, bgColor }) => ({
  backgroundColor: bgColor || theme.primary,
  borderRadius: '8px',
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
}))

export const TileIcon = ({ Icon, bgColor, iconColor }) => {
  const classes = useStyles()
  return (
    <TileWrapper bgColor={bgColor}>
      <Icon style={{ color: iconColor }} className={classes.personIcon} />
    </TileWrapper>
  )
}
