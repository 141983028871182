import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from 'components/dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { yupResolver } from '@hookform/resolvers'
import { useAuth } from 'lib/auth'
import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { styled } from '@material-ui/core'

const REQUEST_BGS = gql`
  mutation NcsiBgsRequest(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    ncsiBgsRequest(firstName: $firstName, lastName: $lastName, email: $email) {
      success
      message
    }
  }
`

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(1)
    .required(),
  lastName: yup
    .string()
    .min(1)
    .required(),
  email: yup
    .string()
    .email()
    .required(),
})

const formatErrorMessage = (name, message) => {
  if (!message) return null
  const error = message
    .split(' ')
    .slice(1)
    .join(' ')
  return `${name} ${error}`
}

const SuccessState = () => {
  const SuccessIcon = styled(AssignmentIndOutlined)(({ theme }) => ({
    borderRadius: '50%',
    background: 'rgba(0,25,75,.08)',
    padding: '1rem',
    color: theme.palette.primary.main,
    width: '83px',
    height: '83px',
    marginBottom: '.5rem',
  }))

  return (
    <DialogContent>
      <Box
        sx={{
          height: '290px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <SuccessIcon />
        <Typography align="center" variant="h6">
          Form Submitted!
        </Typography>
        <Typography align="center">
          You'll receive an email from NCSI with a link for your background
          screening.
        </Typography>
      </Box>
    </DialogContent>
  )
}

export default ({ isOpen, onClose }) => {
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [showSuccess, setShowSuccess] = useState(false)

  const [requestBackgroundScreen] = useMutation(REQUEST_BGS)

  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: user.coach?.email || '',
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async formValues => {
    try {
      await requestBackgroundScreen({
        variables: { ...formValues },
      }).then(response => {
        if (response.data?.ncsiBgsRequest.success) {
          setShowSuccess(true)
        } else {
          enqueueSnackbar(response.data?.ncsiBgsRequest.message, {
            variant: 'error',
          })
        }
      })
    } catch (e) {
      enqueueSnackbar('Sorry, the request could not be sent', {
        variant: 'error',
      })
    }
  }

  const { handleSubmit, register, errors } = form

  return (
    <Dialog isOpen={isOpen} onClose={onClose} maxWidth="sm" showCloseButton>
      {({ closeDialog }) => (
        <Box p={3}>
          <DialogTitle>
            <Typography variant="h5">
              <Box fontWeight={500}>Background Screening Request</Box>
            </Typography>
          </DialogTitle>

          {showSuccess ? (
            <SuccessState />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <Box>
                  <Typography variant="body1">
                    Complete this form to send a background screen request to
                    NCSI. Once submitted, you will receive an email from NCSI
                    with a link to complete your background screening.
                  </Typography>
                  <br />
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    Please enter your legal name as it should appear on your
                    background screening and the best email to contact you.
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstName"
                      margin="normal"
                      inputRef={register}
                      fullWidth
                      variant="outlined"
                      label="First name *"
                      errors={!!errors.firstName}
                      helperText={formatErrorMessage(
                        'First name',
                        errors.firstName?.message,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="lastName"
                      margin="normal"
                      inputRef={register}
                      fullWidth
                      variant="outlined"
                      label="Last name *"
                      errors={!!errors.lastName}
                      helperText={formatErrorMessage(
                        'Last name',
                        errors.lastName?.message,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      margin="normal"
                      inputRef={register}
                      fullWidth
                      variant="outlined"
                      label="Email *"
                      errors={!!errors.email}
                      helperText={formatErrorMessage(
                        'Last name',
                        errors.email?.message,
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button
                  aria-label="cancel"
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button
                  aria-label="send-request"
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Send
                </Button>
              </DialogActions>
            </form>
          )}
        </Box>
      )}
    </Dialog>
  )
}
