import ContentSection from 'components/content-section'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { useStyles } from './styles'
import { PersonAdd, SportsGolfOutlined } from '@material-ui/icons'
import {
  HandednessIcon,
  ClubsBagIcon,
  CrossedClubsIcon,
  ShirtSizeIcon,
} from './pga-hope-icons'

export default function GolfInformation({ student = {} }) {
  const classes = useStyles()
  const { pgaHopeProfile } = student

  return (
    <ContentSection title="Golf Information">
      <List className={classes.container}>
        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <SportsGolfOutlined className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Golf History
                      </Typography>
                    }
                  />
                  <ListItemText
                    secondary={pgaHopeProfile?.golfExperience || 'N/A'}
                  />
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <CrossedClubsIcon className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Has Own Clubs
                      </Typography>
                    }
                  />
                  <ListItemText
                    secondary={pgaHopeProfile?.hasOwnClubs ? 'Yes' : 'No'}
                  />
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <PersonAdd className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Referral Source
                      </Typography>
                    }
                  />
                  <ListItemText
                    secondary={pgaHopeProfile?.referralSource || 'N/A'}
                  />
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>

        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <HandednessIcon className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Handedness
                      </Typography>
                    }
                  />
                  <ListItemText
                    secondary={pgaHopeProfile?.handedness || 'N/A'}
                  />
                </ListItem>
              </List>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <ShirtSizeIcon className={classes.icon} />
              </ListItemAvatar>
              <List>
                <ListItem className={classes.textColumn}>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.header}>
                        Shirt Size
                      </Typography>
                    }
                  />
                  <ListItemText
                    secondary={pgaHopeProfile?.shirtSize || 'N/A'}
                  />
                </ListItem>
              </List>
            </ListItem>

            {pgaHopeProfile?.golfCareerInterest && (
              <ListItem>
                <ListItemAvatar>
                  <ClubsBagIcon className={classes.icon} />
                </ListItemAvatar>
                <List>
                  <ListItem className={classes.textColumn}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.header}>
                          Interest in Golf Industry
                        </Typography>
                      }
                    />
                    <ListItemText
                      secondary={
                        pgaHopeProfile?.golfCareerInterest ? 'Yes' : 'No'
                      }
                    />
                  </ListItem>
                </List>
              </ListItem>
            )}
          </List>
        </ListItem>
      </List>
    </ContentSection>
  )
}
