import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import BookingsTabs from './bookings-tabs'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 40px',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 10px',
    },
  },
}))

const SchedulePage = ({ selectedTab, onboardingSchedule }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <BookingsTabs
            selectedTab={selectedTab}
            onboardingSchedule={onboardingSchedule}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SchedulePage
