import React from 'react'
import { useFormContext } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { FlexInputWrapper } from 'components/coaches-profile/profile-stepper.styles'

const contactSectionConfig = [
  {
    name: 'email',
    label: 'Email',
    required: true,
    type: 'text',
  },
  {
    name: 'phoneNumber',
    label: 'Phone',
    required: false,
    type: 'text',
  },
  {
    name: 'phoneExt',
    label: 'Ext.',
    required: false,
    type: 'text',
  },
  {
    name: 'mobileNumber',
    label: 'Mobile',
    required: false,
    type: 'text',
  },
]

export const Contact = () => {
  const { register, errors } = useFormContext()

  return (
    <FlexInputWrapper>
      {contactSectionConfig.map(({ name, label, required, type }) => (
        <TextField
          key={name}
          name={name}
          label={label}
          type={type}
          inputRef={register()}
          variant="standard"
          className={`text-input ${errors[name] && ' error'}`}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
        />
      ))}
    </FlexInputWrapper>
  )
}

export default Contact
