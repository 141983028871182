import React, { useMemo, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import { currency } from 'lib/utils/string'
import { centsToDollars } from 'lib/utils/number'
import Dialog from 'components/dialog'
import RecordBookingPaymentDialog from 'components/record-booking-payment-dialog'
import {
  toFullTextDate,
  getTimePeriodWithTimezone,
  toFullTextDayDateWithTimezone,
} from 'utils/dateUtils'
import useIconAccessor from 'components/lesson-type-category/icons'
import EnrollmentItem from './enrollment-item'
import { useStyles } from './styles'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import PaymentActions from './payment-actions'
import SessionActions from './session-actions'
import CancelBookingDialog from '../cancel-booking-dialog'
import RequestPaymentDialog from '../request-payment-dialog'
import RemindOrCancelRequestPaymentDialog from '../remind-or-cancel-request-payment-dialog'
import useIsMobile from 'lib/hooks/use-is-mobile'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'env'

const SessionDetailsDialogContent = ({
  booking,
  onReschedule,
  onPayment,
  onCancellation,
  closeDialog,
}) => {
  const {
    title,
    startDateTime,
    endDateTime,
    coachFacility,
    enrollments,
    isCanceled,
    lessonType,
    timezone,
    lessonType: { paymentAccount },
  } = booking
  const [isCancelling, setIsCancelling] = useState(false)
  const isMobile = useIsMobile()

  const isPaid = enrollments.some(e => e.transactions.some(t => !t.refund))
  const isLessonCredit = enrollments.some(e => e.lessonCredit)
  const hasPaymentAccount = !!paymentAccount

  const facilityAddress = [
    coachFacility?.city && `${coachFacility.city},`,
    coachFacility?.state,
    coachFacility?.zipCode,
  ]
    .filter(s => !!s)
    .join(' ')

  const enrollment = enrollments[0]
  const { confirmationCode } = enrollment

  const paymentAmount = useMemo(() => {
    if (isLessonCredit) {
      return enrollment?.lessonCredit?.lessonPack?.lessonPackOffering
        ?.pricePerLessonInCents
    } else {
      return (isPaid && enrollment?.transactions[0]?.amountInCents) || 0
    }
  }, [isLessonCredit, isPaid, enrollment])

  const paymentDate = useMemo(() => {
    if (isLessonCredit) {
      return enrollment?.createdAt
    } else {
      return (isPaid && enrollment?.transactions[0]?.createdAt) || ''
    }
  }, [isLessonCredit, isPaid, enrollment])

  const registrationPaymentId =
    isPaid && enrollment?.transactions[0]?.registrationPayment.id
  const student = enrollment?.student
  const studentName = `${student?.firstName} ${student.lastName}`
  const isPaymentRequestVisible =
    !isPaid &&
    !isCanceled &&
    !isLessonCredit &&
    hasPaymentAccount &&
    lessonType.priceInCents > 0

  const canSendPaymentRequestReminder =
    isPaymentRequestVisible && !enrollment?.paymentRequestedDate
  const stripePromise = loadStripe(STRIPE_KEY)

  const classes = useStyles()
  const accessor = useIconAccessor()

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <Box position="relative">
          <Avatar className={classes.categoryIcon}>
            {accessor[lessonType.category].icon}
          </Avatar>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box mt={4} p={1.5}>
          <Box mb={2}>
            <Typography style={{ fontSize: '30px', fontWeight: 900 }}>
              {title}
            </Typography>
          </Box>
          {coachFacility ? (
            <Box display="flex" alignItems="center" mb={3}>
              <Box mr={4} color="text.secondary">
                <RoomOutlinedIcon />
              </Box>
              <Box>
                <Typography variant="body1">{coachFacility.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {facilityAddress}
                </Typography>
              </Box>
            </Box>
          ) : null}
          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={4} color="text.secondary">
              <EventNoteOutlinedIcon />
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box minWidth="140px">
                <Typography variant="body1">
                  {toFullTextDayDateWithTimezone(startDateTime, timezone)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {getTimePeriodWithTimezone(
                    startDateTime,
                    endDateTime,
                    timezone,
                  )}
                </Typography>
              </Box>
              <SessionActions booking={booking} onReschedule={onReschedule} />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={4} color="textSecondary">
              <MonetizationOnOutlinedIcon />
            </Box>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              {isLessonCredit ? (
                <Box>
                  <Typography variant="body1">Credit Redeemed</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Redeemed on {toFullTextDate(paymentDate)}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography variant="body1">
                    {isPaid
                      ? `${currency(
                        centsToDollars(paymentAmount),
                      )} on ${toFullTextDate(paymentDate)}`
                      : 'Pending Payment'}
                  </Typography>
                  <SensitiveInfoWrapper>
                    {isPaid ? (
                      <PaymentActions
                        registrationPaymentId={registrationPaymentId}
                        enrollment={enrollment}
                        eventTitle={title}
                        eventDate={startDateTime}
                        participantName={studentName}
                        lessonType={lessonType}
                        onPaymentSuccess={onPayment}
                      />
                    ) : !isCanceled ? (
                      <RecordBookingPaymentDialog
                        trigger={
                          <Button color="primary" data-testid="mark-as-paid">
                            Mark As Paid
                          </Button>
                        }
                        enrollment={enrollment}
                        eventTitle={title}
                        eventDate={startDateTime}
                        participantName={studentName}
                        defaultAmountInCents={lessonType.priceInCents}
                        acceptOnlinePayments={lessonType.acceptsOnlinePayments}
                        onPaymentSuccess={onPayment}
                      />
                    ) : null}
                  </SensitiveInfoWrapper>
                </>
              )}
            </Box>
          </Box>
          {isPaymentRequestVisible && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{
                borderRadius: '8px',
                backgroundColor: '#EFF5FB',
                padding: '1rem',
              }}
              mb={3}
            >
              {canSendPaymentRequestReminder ? (
                <>
                  <Box display="flex" alignItems="center" width="60%">
                    <Typography
                      variant="body2"
                      gutterBottom
                      color="textPrimary"
                      data-testid="payment-request-reminder-text"
                    >
                      Ready to collect? Click here to collect in person or email
                      your student a link for easy, online payment.
                    </Typography>
                  </Box>
                  <Box color="textSecondary">
                    <Elements stripe={stripePromise}>
                      <RequestPaymentDialog
                        trigger={
                          <Button
                            color="primary"
                            variant="outlined"
                            disabled={isCancelling}
                            data-testid="collect-payment"
                          >
                            Collect Payment
                          </Button>
                        }
                        onPaymentSuccess={() => {
                          closeDialog()
                          onPayment()
                        }}
                        confirmationCode={confirmationCode}
                        enrollment={{
                          category: lessonType.category,
                          priceInCents: lessonType.priceInCents,
                          startDateTime,
                          endDateTime,
                          timezone,
                          title,
                          ...enrollment,
                        }}
                      />
                    </Elements>
                  </Box>
                </>
              ) : (
                <>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="textPrimary">
                        Still waiting on a payment? Remind your student to
                        submit their payment online
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} color="textSecondary">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent={isMobile ? 'center' : 'flex-end'}
                        className={classes.cancelPaymentRequestButtons}
                      >
                        <Grid item>
                          <RemindOrCancelRequestPaymentDialog
                            trigger={<Button color="primary">Cancel</Button>}
                            onSuccess={onCancellation}
                            confirmationCode={confirmationCode}
                            enrollmentId={enrollment.id}
                            isCancelReminder={true}
                          />
                        </Grid>
                        <Grid item>
                          <RemindOrCancelRequestPaymentDialog
                            trigger={
                              <Button color="primary" variant="outlined">
                                Remind
                              </Button>
                            }
                            onSuccess={onCancellation}
                            confirmationCode={confirmationCode}
                            enrollmentId={enrollment.id}
                            isCancelReminder={false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          )}
          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={4} color="text.secondary">
              <PeopleOutlinedIcon />
            </Box>
            <Typography variant="body1">Attendees</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            {enrollment && <EnrollmentItem enrollment={enrollment} />}
          </Box>
          {!isCanceled && (
            <CancelBookingDialog
              trigger={
                <Button color="primary" variant="outlined">
                  Cancel Session
                </Button>
              }
              onConfirm={() => setIsCancelling(true)}
              onSuccess={onCancellation}
              confirmationCode={confirmationCode}
            />
          )}
        </Box>
      </DialogContent>
    </>
  )
}

const SessionDetailsDialog = ({
  isOpen,
  setIsOpen,
  booking,
  onReschedule,
  onPayment,
  onCancellation,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  return (
    <Dialog
      maxWidth="sm"
      fullScreen={isMobile}
      fullWidth={true}
      isOpen={isOpen}
      showCloseButton
      onClose={() => setIsOpen(!isOpen)}
    >
      {() => (
        <SessionDetailsDialogContent
          booking={booking}
          onReschedule={onReschedule}
          onPayment={onPayment}
          onCancellation={onCancellation}
          closeDialog={() => setIsOpen(!isOpen)}
        />
      )}
    </Dialog>
  )
}

export default SessionDetailsDialog
