import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  journeyContainer: {
    flexDirection: 'row',
    height: '13.75rem',
    margin: '4rem 0',
  },
  title: {
    color: theme.palette.primary.light,
    fontWeight: '700',
    lineHeight: '1',
    marginBottom: '.5rem',
  },
  fullHeroText: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexwrap: 'wrap',
    '& h4': {
      paddingLeft: '4rem',
      color: '#FFF',
    },
  },
  caption: {
    color: theme.palette.text.primary,
    fontWeight: '700',
    lineHeight: '1',
  },
  coachContactsSection: {
    backgroundColor: theme.palette.offwhite.main,
    color: theme.palette.primary.main,
    height: '13.75rem',
    padding: '0 4rem',
  },
  contactCoachButton: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    borderRadius: '3rem',
    lineHeight: '28px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  alignment: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  buttonBox: {
    marginTop: '1.25rem',
  },

  socialIcon: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    height: '59px',
    width: '59px',
    boxSizing: 'border-box',
    '& svg > path': {
      fill: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      '& svg > path': {
        fill: theme.palette.primary.contrastText,
      },
    },
  },
}))
