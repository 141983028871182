import React, { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles'
import { withProtectedRoute, roles } from 'lib/auth'
import themes, { extendTheme } from 'themes'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Shell from 'components/shell/shell'
import logo from 'images/jr-league-logo.svg'

const Register = lazy(() => import('pages/junior-league-player/register'))
const ChampionshipSeasonLanding = lazy(() =>
  import('pages/junior-league-player/championship-season/landing'),
)
const ChampionshipSeasonRegister = lazy(() =>
  import('pages/junior-league-player/championship-season/register'),
)
const RegionalLanding = lazy(() =>
  import('pages/junior-league-player/regional/landing'),
)
const RegionalRegister = lazy(() =>
  import('pages/junior-league-player/regional/register'),
)

const JuniorLeaguePlayerRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <ThemeProvider theme={extendTheme(themes.juniorLeague)}>
      <Helmet>
        <title>PGA Jr. League Registration</title>
        <meta name="description" content="PGA Jr. League" />
        <link rel="icon" href="/jrl-favicon.ico" />
      </Helmet>

      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Suspense fallback={<LinearProgress color="secondary" />}>
          <Switch>
            <Route exact path={`${path}/:programId`}>
              <Register />
            </Route>
            <Route
              exact
              path={`${path}/championship-season/:programId/landing`}
            >
              <Shell logo={{ src: logo, alt: 'PGA Jr. League Logo' }}>
                <ChampionshipSeasonLanding />
              </Shell>
            </Route>
            <Route exact path={`${path}/championship-season/:programId`}>
              <ChampionshipSeasonRegister />
            </Route>
            <Route exact path={`${path}/regional/:programId/landing`}>
              <Shell logo={{ src: logo, alt: 'PGA Jr. League Logo' }}>
                <RegionalLanding />
              </Shell>
            </Route>
            <Route exact path={`${path}/regional/:programId`}>
              <RegionalRegister />
            </Route>
            <Route exact path={`${path}/national/:programId/landing`}>
              <Shell logo={{ src: logo, alt: 'PGA Jr. League Logo' }}>
                <RegionalLanding promoted={true} />
              </Shell>
            </Route>
            <Route exact path={`${path}/national/:programId`}>
              <RegionalRegister promoted={true} />
            </Route>
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(JuniorLeaguePlayerRoutes, roles.CONSUMER)
