import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import { PageHeader } from 'components/page-header'
import TransactionHistory from './transaction-history'

const TransactionsPage = () => {
  const { path } = useRouteMatch()
  const { isCoach } = useAuth()
  const accountPath = isCoach
    ? path
      .split('/')
      .slice(0, 2)
      .join('/')
    : ''

  const crumbs = [
    {
      label: 'Account',
      to: accountPath,
    },
    {
      label: 'Payments',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]

  return (
    <>
      <PageHeader title="Transactions" crumbs={crumbs} />
      <TransactionHistory />
    </>
  )
}

export default withProtectedRoute(TransactionsPage, [
  roles.COACH,
  roles.CONSUMER,
])
