import { gql, useQuery } from '@apollo/client'

const STUDENT_FRAGMENT = gql`
  fragment StudentFragment on Student {
    isMinor
    id
    firstName
    lastName
    hasAccount
    contact {
      id
      firstName
      lastName
      email
      phoneNumber
      externalId
    }
    enrollments {
      id
      isCanceled
      transactions {
        amountInCents
        createdAt
        sourceType
        refund
        registrationPayment {
          id
          leftToRefundInCents
        }
      }
      lesson {
        coach {
          id
          name
        }
        coachFacility {
          id
          name
          timezone
        }
        id
        title
        description
        isCanceled
        startDateTime
        endDateTime
        timezone
        ... on Session {
          lessonType {
            id
            category
            priceInCents
            acceptsOnlinePayments
            durationInMinutes
          }
        }
        ... on GroupEvent {
          slug
          tags
          featuredImageUrl
          priceInCents
          totalPriceInCents
          acceptsOnlinePayments
          paymentRecurrenceSchedule
          numberOfPayments
          lessonCount
          registrationCloseDateTime
          externalRegistrationUrl
          registrationCount
          sessions {
            id
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`
const COACHING_CENTER_STUDENTS_QUERY = gql`
  query GetCoachingCenterStudents($academySlug: String!) {
    academyStudents(academySlug: $academySlug) {
      id
      student {
        ...StudentFragment
      }
      facilities {
        id
        name
      }
      coaches {
        id
        name
      }
    }
  }
  ${STUDENT_FRAGMENT}
`

export function useGetCoachingCenterStudents({ academySlug }) {
  return useQuery(COACHING_CENTER_STUDENTS_QUERY, {
    variables: {
      academySlug,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })
}
