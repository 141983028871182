import React from 'react'
import MaskedInput from 'react-text-mask'
import { Controller } from 'react-hook-form'
import TextFieldWithError from './text-field-with-error'

const PhoneMaskedInput = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      type="tel"
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  )
}
export const CtrlPhone = ({
  control,
  name,
  errors,
  controllerProps,
  ...other
}) => {
  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <TextFieldWithError
          {...other}
          value={value}
          onChange={onChange}
          name={name}
          errors={errors}
          InputProps={{
            inputComponent: PhoneMaskedInput,
          }}
        />
      )}
    />
  )
}
