import React, { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import Dialog from 'components/dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'

import { useDebounce, useIsMobile } from 'lib/hooks'
import GroupEventTemplateCard from './group-event-template-card'

const GET_EVENT_BY_SLUG = gql`
  query GroupEventBySlug($slug: String!) {
    groupEvents(slug: $slug) {
      slug
      academyRequired
      title
      tags
      description
      startDateTime
      endDateTime
      coachFacility {
        name
      }
      featuredImageUrl
      priceInCents
    }
  }
`

const CopyFromLink = ({ onCreateClick }) => {
  const [link, setLink] = useState('')
  const debouncedLink = useDebounce(link, 500)
  const [slug, setSlug] = useState(null)

  useEffect(() => {
    const regex = /((https?:\/\/)?(((www|my)\.)?((dev|qa)\.)?pga\.com)\/things-to-do\/events\/)?(?<slug>(\w|-)+)(\/\w+)?/
    const parsedSlug = debouncedLink?.match(regex)?.groups?.slug
    setSlug(parsedSlug)
  }, [debouncedLink, setSlug])
  const { data, loading } = useQuery(GET_EVENT_BY_SLUG, { variables: { slug } })
  const matchedEvent = data?.groupEvents?.filter(
    event => !event?.slug?.includes('pga-hope-'),
  )?.[0]

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1">Copy from event link</Typography>
        <Typography variant="caption">
          Enter the link of the event you'd like to copy.
        </Typography>
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Event Link"
          value={link}
          onChange={e => setLink(e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">
          Event links should look like:
        </Typography>
        <Typography display="block" variant="caption" color="textSecondary">
          &bull; https://www.pga.com/things-to-do/events/chip-n-sip
        </Typography>
        <Typography display="block" variant="caption" color="textSecondary">
          &bull; https://my.pga.com/things-to-do/events/chip-n-sip/register
        </Typography>
        <Typography display="block" variant="caption" color="textSecondary">
          &bull; chip-n-sip
        </Typography>
      </Box>
      {loading && (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      )}
      {!loading && (
        <>
          {matchedEvent && (
            <GroupEventTemplateCard
              event={matchedEvent}
              callToAction="Copy event"
              onCreateClick={onCreateClick}
            />
          )}
          {slug && !matchedEvent && (
            <Typography variant="caption" color="error">
              Event not found. The link provided is invalid or does not match an
              event.
            </Typography>
          )}
        </>
      )}
    </>
  )
}

const RECENT_EVENTS = gql`
  query RecentGroupEvents {
    currentUser {
      groupEvents(orderBy: [{ field: CREATED_AT, direction: DESC }]) {
        academyRequired
        id
        title
        tags
        description
        startDateTime
        endDateTime
        slug
        coachFacility {
          id
          name
        }
        featuredImageUrl
        priceInCents
        collectAttendance
      }
    }
  }
`

const RecentEventSelection = ({ onCreateClick }) => {
  const { data, loading } = useQuery(RECENT_EVENTS)
  // Filter out PGA HOPE events from being copied at the moment
  const recentEvents =
    data?.currentUser?.groupEvents?.filter(
      event => !event.slug?.includes('pga-hope'),
    ) || []

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle1">Copy recent event</Typography>
        <Typography variant="caption">
          Choose an event you've recently created.
        </Typography>
      </Box>
      {loading && (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      )}
      {!loading && (
        <Grid container spacing={4}>
          {recentEvents.map(event => (
            <Grid key={event.id} item lg={6} sm={12}>
              <GroupEventTemplateCard
                event={event}
                callToAction="Copy event"
                onCreateClick={onCreateClick}
              />
            </Grid>
          ))}
          {recentEvents.length === 0 && (
            <Grid item>
              <Typography variant="caption">
                No events found. Events you create will appear here.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

const CopyExistingEventDialog = ({ trigger }) => {
  const isMobile = useIsMobile()
  return (
    <Dialog
      trigger={trigger}
      maxWidth="lg"
      fullWidth
      fullScreen={isMobile}
      showCloseButton
    >
      {({ closeDialog }) => {
        return (
          <>
            <DialogTitle>Copy Existing Event</DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={!isMobile ? 6 : 4}>
                <Grid item md={4} sm={12}>
                  <CopyFromLink onCreateClick={closeDialog} />
                </Grid>
                {isMobile && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
                <Grid item md={8} sm={12}>
                  <RecentEventSelection onCreateClick={closeDialog} />
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )
      }}
    </Dialog>
  )
}

export default CopyExistingEventDialog
