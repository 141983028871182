import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import useStyles from './group-event-registration-form.styles'
import { golfHistoryOptions } from './constants'
import { handednessOptions } from 'utils/constants'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const GolferDetails = () => {
  const { control, errors } = useFormContext()
  const classes = useStyles()

  return (
    <>
      <Typography variant="h5" className={classes.formSectionHeader}>
        Golf Information
      </Typography>
      <Box>
        <Typography gutterBottom>
          Where are you in your golf journey?
        </Typography>
        <FormControl
          error={!!errors?.pgaHopeProfile?.golfExperience}
          variant="outlined"
          className={classes.input}
          required
        >
          <InputLabel id="pga-hope-golf-history-label">Golf history</InputLabel>
          <Controller
            as={
              <Select
                labelId="pga-hope-golf-history-label"
                label="Golf history"
                value=""
              >
                {golfHistoryOptions.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name="pgaHopeProfile.golfExperience"
            control={control}
          />
        </FormControl>
        <FormControl
          error={!!errors?.pgaHopeProfile?.handedness}
          variant="outlined"
          className={classes.input}
          required
        >
          <InputLabel id="pga-hope-handedness-label">Handedness</InputLabel>
          <Controller
            as={
              <Select
                labelId="pga-hope-handedness-label"
                label="Handedness"
                value=""
              >
                {handednessOptions.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name="pgaHopeProfile.handedness"
            control={control}
          />
        </FormControl>
        <Controller
          defaultValue={false}
          name="pgaHopeProfile.golfCareerInterest"
          control={control}
          render={({ onChange, value, name }) => (
            <FormControlLabel
              label="I'm interested in a career in the golf industry."
              className={classes.input}
              control={
                <Checkbox
                  color="primary"
                  name={name}
                  checked={value}
                  onChange={e => onChange(e.target.checked)}
                />
              }
            />
          )}
        />
      </Box>
    </>
  )
}

export default GolferDetails
