export const map = (value, fn) => (value == null ? value : fn(value))

class Just {
  constructor(value) {
    this.value = value
  }

  map(fn) {
    return of(fn(this.value))
  }

  withDefault() {
    return this.value
  }
}

const Nothing = {
  map(fn) {
    return this
  },

  withDefault(defaultValue) {
    return defaultValue
  },
}

export function of(value) {
  return value == null ? Nothing : new Just(value)
}
