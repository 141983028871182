import { useState, useEffect } from 'react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'

export default function useCreditCardErrors(
  elements,
  paymentRequired,
  skip = true,
) {
  const [cardErrorMessage, setCardErrorMessage] = useState(
    paymentRequired ? 'Enter card number' : null,
  )
  const [expirationErrorMessage, setExpirationErrorMessage] = useState(
    paymentRequired ? 'Enter card Expiration' : null,
  )
  const [cvcErrorMessage, setCvcErrorMessage] = useState(
    paymentRequired ? 'Enter card CVC' : null,
  )
  useEffect(() => {
    if (paymentRequired && !skip) {
      elements?.getElement(CardNumberElement).on('change', function (event) {
        setCardErrorMessage(event.error ? event.error.message : null)
      })
      elements?.getElement(CardExpiryElement).on('change', function (event) {
        setExpirationErrorMessage(event.error ? event.error.message : null)
      })
      elements?.getElement(CardCvcElement).on('change', function (event) {
        setCvcErrorMessage(event.error ? event.error.message : null)
      })
    }
  }, [elements, paymentRequired, skip])
  return cardErrorMessage || expirationErrorMessage || cvcErrorMessage
}
