import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import Error from 'components/error'
import { withProtectedRoute, roles } from 'lib/auth'
import { PageHeader } from 'components/page-header'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import TipWell from 'components/tip-well'
import PaymentAccountsDisplay from './payment-accounts-display'
import AddPaymentAccountDialog from './add-payment-account-dialog'

const GET_PAYMENT_ACCOUNTS = gql`
  query CoachPaymentAccounts {
    currentUser {
      attributes {
        ... on Coach {
          paymentAccounts {
            id
            name
            last4
            stripeActive
            manageUrl
          }
        }
      }
    }
  }
`

const PaymentAccountsPage = () => {
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'Account',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
    {
      label: 'Payments',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]

  const { data, loading, error, refetch } = useQuery(GET_PAYMENT_ACCOUNTS)

  const pageContent = (() => {
    if (error) return <Error />
    if (loading) return <LinearProgress color="primary" />
    const paymentAccounts = data?.currentUser?.attributes?.paymentAccounts

    if (paymentAccounts.length === 0) {
      return (
        <Container maxWidth="sm">
          <Box textAlign="center" mt={4}>
            <Box mb={2} display="inline-block">
              <CircularHighlightedIcon icon={CreditCardOutlinedIcon} />
            </Box>
            <Box mb={4}>
              <Typography variant="h5" style={{ fontWeight: 500 }} gutterBottom>
                Set up your online fees processing account
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Stripe is the online fee processor used for PGA of America
                online transactions. You will need to create a Stripe account to
                accept online payments. Please click the button below to get
                started.
              </Typography>
            </Box>
            <AddPaymentAccountDialog
              trigger={
                <Button variant="contained" color="primary">
                  Add Payment Account
                </Button>
              }
            />
          </Box>
        </Container>
      )
    }

    return (
      <>
        <Container maxWidth="md">
          <Box px={8} my={4}>
            <TipWell
              caption={`These payment accounts are connected to Stripe, which handles all your online payments.
                Changes made to a payment account will affect all connections associated with that account.`}
            />
          </Box>
        </Container>
        <Container maxWidth="md">
          <PaymentAccountsDisplay
            paymentAccounts={paymentAccounts}
            refetch={refetch}
          />
        </Container>
      </>
    )
  })()

  return (
    <>
      <PageHeader
        title="Online Payment Accounts"
        crumbs={crumbs}
        actions={
          <AddPaymentAccountDialog
            trigger={
              <Button variant="contained" color="primary">
                Add Payment Account
              </Button>
            }
          />
        }
      />
      <Box mb={6}>{pageContent}</Box>
    </>
  )
}

export default withProtectedRoute(PaymentAccountsPage, roles.COACH)
