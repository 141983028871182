import React from 'react'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

export default () => (
  <Box display="flex" flexDirection="column">
    <Skeleton variant="text" width={200} height={30} />
    <Skeleton variant="text" width={200} height={50} />
    <Skeleton variant="text" width={200} height={30} />
  </Box>
)
