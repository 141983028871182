import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function useStepper({ stepCount, preserveSteps = false }) {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (!preserveSteps) {
      history.replace({ ...history.location, state: null })
    }
  }, [history, preserveSteps])

  useEffect(() => {
    if (history.location.state) {
      setActiveStep(history.location.state.currentStep)
      window.scrollTo(0, 0)
    }
  }, [history.location.state, setActiveStep])

  useEffect(() => {
    const unlisten = history.listen(({ pathname, search }, action) => {
      if (!preserveSteps && action === 'POP') {
        const firstStep = 0
        const previousStep = activeStep - 1
        history.push(pathname + search, {
          currentStep: activeStep > firstStep ? previousStep : firstStep,
        })
      }
    })
    return unlisten
  }, [activeStep, history, preserveSteps])

  const path = history.location.pathname + history.location.search

  const nextStep = () => {
    if (activeStep < stepCount - 1) {
      history.push(path, { currentStep: activeStep + 1 })
    }
  }

  const previousStep = () => {
    if (activeStep !== 0) {
      history.push(path, { currentStep: activeStep - 1 })
    }
  }

  const setStep = step => {
    if (step < stepCount - 1 && step >= 0) {
      history.push(path, { currentStep: step })
    }
  }

  return [activeStep, { nextStep, previousStep, setStep }]
}
