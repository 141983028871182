import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import GroupEventInfo from './group-event-info'
import ParticipantInformation from './participant-information'
import EmergencyContact from './emergency-contact'
import MilitaryService from './military-service'
import GolferDetails from './golfer-details'
import Divider from '@material-ui/core/Divider'
import useStyles from './group-event-registration-form.styles'
import EventPreparation from './event-preparation'
import Container from '@material-ui/core/Container'
import { SignupStyledStepper } from 'components/signup-util'
import StepIcon from './stepIcon'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { Steps, StepLabels } from './steps'
import StepActions from './step-actions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Disclaimer from 'components/disclaimer'
import logo from 'images/pga-hope-logo.svg'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import themes, { extendTheme } from 'themes'
import { ThemeProvider } from '@material-ui/core/styles'

const Layout = ({
  groupEvent,
  activeStep,
  setActiveStep,
  isMobile,
  onSubmit,
}) => {
  const classes = useStyles()
  const uniqueSteps = [...new Set(Object.values(StepLabels))]
  const currentStep = Steps[activeStep]
  const { isCanceled, registrationClosed, registrationFull } = groupEvent
  const registrationDisabled =
    isCanceled || registrationClosed || registrationFull
  const labelStep = uniqueSteps.indexOf(currentStep.name)
  const backLinkUrl = window.location.pathname
    .split('/')
    .slice(0, -1)
    .join('/')

  return (
    <>
      <ThemeProvider theme={extendTheme(themes.pgaHope)}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item md={2}>
              <Box display="flex" flexGrow={1}>
                <Box
                  display={
                    currentStep.name === 'Participant Information'
                      ? 'block'
                      : 'none'
                  }
                >
                  <Button
                    className={classes.backButton}
                    href={backLinkUrl}
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Back
                  </Button>
                </Box>
                <Box
                  display={
                    currentStep.name === 'Additional Information' && isMobile
                      ? 'block'
                      : 'none'
                  }
                >
                  <Button
                    className={classes.backButton}
                    startIcon={<KeyboardBackspaceIcon />}
                    onClick={() => setActiveStep(0)}
                  >
                    Back
                  </Button>
                </Box>
              </Box>
            </Grid>
            {!isMobile && (
              <Grid item md={8}>
                <Container maxWidth="sm">
                  <SignupStyledStepper alternativeLabel activeStep={labelStep}>
                    {uniqueSteps.map(step => {
                      return (
                        <Step key={step}>
                          <StepLabel StepIconComponent={StepIcon}>
                            {step}
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </SignupStyledStepper>
                </Container>
              </Grid>
            )}
          </Grid>
        </Container>

        <Container maxWidth="sm">
          <Box
            display={
              currentStep.name === 'Participant Information' ? 'block' : 'none'
            }
          >
            <Box className={classes.eventWrapper}>
              <GroupEventInfo groupEvent={groupEvent} />
            </Box>
            <Box className={classes.baseWrapper}>
              <Disclaimer
                logo={logo}
                alt="pga-hope"
                description="To participate in PGA HOPE, you must be a Veteran, Reservist, National
                        Guard or Active Duty Military. A service connected disability is not
                        required to participate."
              />
              <ParticipantInformation />
              <Divider className={classes.divider} />
              <EmergencyContact />
            </Box>
          </Box>
          <Box
            display={
              currentStep.name === 'Additional Information' ? 'block' : 'none'
            }
          >
            <Box className={classes.eventWrapper}>
              <MilitaryService />
              <Divider className={classes.divider} />
              <GolferDetails />
              <Divider className={classes.divider} />
              <EventPreparation />
            </Box>
          </Box>
        </Container>
        <Box display={currentStep.name !== 'Confirmation' ? 'block' : 'none'}>
          <Divider className={classes.bottomDivider} />
          <Container maxWidth="sm">
            <Box>
              <StepActions
                stepName={currentStep.name}
                setActiveStep={setActiveStep}
                registrationDisabled={registrationDisabled}
                onSubmit={onSubmit}
              />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default Layout

Layout.propTypes = {
  groupEvent: PropTypes.object.isRequired,
  completedRegistration: PropTypes.object,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
}
