import React from 'react'
import get from 'lodash/get'
import { API_ROOT, AUTH_ROOT, APP_VERSION } from 'env'
import AuthContext from '../AuthContext'
import { fetchToken, setToken, clearToken } from './token'
import {
  getAssumedUser,
  clearAssumedUser,
  getAssumingUserRestrictions,
} from '../impersonation'
import { roles } from '../constants'
import intersection from 'lodash/intersection'
import castArray from 'lodash/castArray'

const currentUserQuery = `
{
  currentUser {
    externalId,
    programAdmin,
    familyCupSignedUp,
    jrlSignedUp,
    roles
    attributes {
      __typename
      ... on Coach {
        id
        name
        email
        intercomEmail
        bookingUrl
        profileUrl
        pgaCoachEligible
        coachProfile {
          slug
          profilePhoto
          unviewedLeadCount
        }
        employments {
          role
        }
      }
      ... on Contact {
        id
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
}
`

function withAuthProvider(App) {
  class WithAuthProvider extends React.Component {
    static displayName = `withAuthProvider(${App.displayName ||
      App.name ||
      'App'})`

    constructor(props) {
      super(props)

      this.state = {
        user: null,
      }

      this.login = this.login.bind(this)
      this.refreshToken = this.refreshToken.bind(this)
      this.logout = this.logout.bind(this)
      this.setUserProperties = this.setUserProperties.bind(this)
    }

    async login(callback) {
      // decode information from the jwt token and persist it to the AuthContext
      // also, query GraphQL to pull user attributes (name, avatar, permissions, etc.)
      const authToken = await fetchToken()
      if (authToken != null) {
        setToken(authToken)

        let currentUser
        let userProperties = {
          roles: [],
          hasRole(roles) {
            return intersection(this.roles, castArray(roles)).length > 0
          },
        }

        try {
          const assumedUser = getAssumedUser()
          const hideSensitiveInformation = getAssumingUserRestrictions()
          const res = await fetch(`${API_ROOT}/graphql`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
              ...(assumedUser && { 'X-PGA-Impersonation': assumedUser }),
              'X-Client-Version': APP_VERSION,
              'X-Client-Name': 'my-pga-com',
            },
            body: JSON.stringify({
              query: currentUserQuery,
            }),
          })
          const json = await res.json()
          currentUser = get(json, 'data.currentUser')

          if (currentUser) {
            userProperties = {
              ...userProperties,
              externalId: currentUser.externalId,
              programAdmin: currentUser.programAdmin,
              familyCupSignedUp: currentUser.familyCupSignedUp,
              jrlSignedUp: currentUser.jrlSignedUp,
              onboarding: false,
              roles: currentUser.roles,
              type: currentUser.attributes.__typename,
              id: currentUser.attributes.id,
              ...(currentUser.attributes.__typename === 'Coach' && {
                name: currentUser.attributes.name,
                firstName: currentUser.attributes.name
                  .split(' ')
                  .slice(0, -1)
                  .join(' '),
                lastName: currentUser.attributes.name
                  .split(' ')
                  .slice(-1)
                  .join(' '),
                email: currentUser.attributes.email,
                intercomEmail: currentUser.attributes.intercomEmail,
                pgaCoachEligible: currentUser.attributes.pgaCoachEligible,
                coach: currentUser.attributes,
                isAcademyOwner: currentUser.attributes.employments.some(
                  e => e.role === 'OWNER',
                ),
                isAcademyStaff: currentUser.attributes.employments.some(
                  e => e.role === 'STAFF',
                ),
              }),
              ...(currentUser.attributes.__typename === 'Contact' && {
                name: `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`,
                firstName: currentUser.attributes.firstName,
                lastName: currentUser.attributes.lastName,
                email: currentUser.attributes.email,
                phoneNumber: currentUser.attributes.phoneNumber,
                intercomEmail: currentUser.attributes.email,
              }),
              isAssumed: assumedUser != null,
              hideSensitiveInformation: hideSensitiveInformation != null,
            }
          }
        } catch (e) {
          window.rg4js &&
            window.rg4js('send', {
              error: e,
              tags: ['auth'],
            })
        }

        this.setState(
          {
            user: userProperties,
          },
          () => callback && callback(),
        )
      } else {
        callback && callback()
      }
    }

    logout() {
      clearToken()
      clearAssumedUser(false)

      let logoutUrl = `${AUTH_ROOT}/v2/logout`

      if (this.state.user?.hasRole(roles.CONSUMER)) {
        logoutUrl = `${AUTH_ROOT}/consumer/v1/logout?return_to=${AUTH_ROOT}`
      }

      this.setState({
        user: null,
      })

      window.location = logoutUrl
    }

    async refreshToken() {
      const authToken = await fetchToken()
      setToken(authToken)
    }

    setUserProperties(userProperties) {
      this.setState({ user: { ...this.state.user, ...userProperties } })
    }

    render() {
      const { user } = this.state
      return (
        <AuthContext.Provider
          value={{
            isLoggedIn: user != null,
            user,
            login: this.login,
            logout: this.logout,
            refreshToken: this.refreshToken,
            setUserProperties: this.setUserProperties,
          }}
        >
          <App {...this.props} />
        </AuthContext.Provider>
      )
    }
  }
  return WithAuthProvider
}

export default withAuthProvider
