import React from 'react'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import { useStyles } from './intake-form.styles'
import Stepper from '@material-ui/core/Stepper'
import StepIcon from './stepIcon'

const StepperComponent = ({ labelStep, uniqueSteps }) => {
  const classes = useStyles()
  return (
    <Stepper
      connector={<StepConnector />}
      alternativeLabel
      activeStep={labelStep}
      className={classes.stepper}
    >
      {uniqueSteps.map(step => {
        return (
          <Step key={step}>
            <StepLabel
              classes={{
                completed: classes.completedOvveride,
              }}
              StepIconComponent={StepIcon}
            >
              {step}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default StepperComponent
