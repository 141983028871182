import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Icon as MDIcon } from '@mdi/react'

const useStyles = ({ size, iconSize, iconColor, highlightColor }) =>
  makeStyles(theme => ({
    highlight: {
      alignItems: 'center',
      backgroundColor: highlightColor || theme.palette.primary.selected,
      borderRadius: size,
      color: iconColor || theme.palette.primary.main,
      display: 'flex',
      fontSize: iconSize,
      height: size,
      justifyContent: 'center',
      width: size,
    },
  }))

const CircularHighlightedIcon = ({
  icon: Icon,
  size = 83,
  iconSize = 36,
  iconColor,
  highlightColor,
  mdi = false,
}) => {
  const classes = useStyles({ size, iconSize, iconColor, highlightColor })()
  return (
    <Box className={classes.highlight}>
      {mdi ? (
        <MDIcon path={Icon} size={1.5} />
      ) : (
        <Icon color="inherit" fontSize="inherit" />
      )}
    </Box>
  )
}

export default CircularHighlightedIcon
