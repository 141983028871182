import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  FormHelperText,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import useStyles from './styles'
import { Controller, useFormContext } from 'react-hook-form'
import MUIRichTextEditor from 'mui-rte'
import { useParams } from 'react-router-dom'
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js'
import { convertToRaw } from 'draft-js'
import StartTimeIncrementPreviewDialog from 'components/start-time-increment-preview-dialog'
import { useAuth } from 'lib/auth'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'
import Disclaimer from 'components/disclaimer'
import ButtonCta from 'components/atoms/button-cta'

const BookingTypeDetails = ({
  coachFacilities,
  coachAvailabilities,
  coachingCenters = [],
  durationInMinutesOptions,
  categories,
  handleCategoryChange,
  startTimeIncrementOptions,
}) => {
  const { errors, control, register, watch, setValue } = useFormContext()
  const classes = useStyles()
  const coachingCenterId = watch('coachingCenter') || ''
  const { user } = useAuth()
  const { bookingTypeId } = useParams()
  const [showAvailabilityWarning, setShowAvailabilityWarning] = useState(false)
  const friscoCoachingCenter = coachingCenters?.find(
    cc => cc.slug === FRISCO_COACHING_CENTER_SLUG,
  )
  const showCoachingCenterDropdown =
    (user.isAcademyStaff || user.isAcademyOwner) && friscoCoachingCenter

  useEffect(() => {
    if (showCoachingCenterDropdown && coachingCenterId) {
      setValue('location', filteredCoachFacilities[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCoachingCenterDropdown, coachingCenterId, setValue])

  const coachingCentersWithNone = [
    { id: '', name: 'None', facilities: [] },
    { ...friscoCoachingCenter },
  ] // selecting none clears the facility filter

  const filteredCoachFacilities =
    showCoachingCenterDropdown && coachingCenterId
      ? friscoCoachingCenter.facilities
      : coachFacilities

  const facilitySelectWithCoachingCenters = () => {
    return (
      <>
        <Typography variant="body2" gutterBottom>
          Select the Coaching Center where this lesson type will be associated.
        </Typography>
        <FormControl
          variant="outlined"
          className={classes.formField}
          disabled={bookingTypeId}
          title={
            bookingTypeId
              ? 'You cannot change the coaching center for an existing booking type'
              : ''
          }
        >
          <InputLabel id="category">Select Coaching Center</InputLabel>
          <Controller
            as={
              <Select
                labelId="coachingCenter"
                label="Select Coaching Center"
                value={''}
                data-cy="coachingCenter"
              >
                {coachingCentersWithNone?.map((opt, idx) => (
                  <MenuItem
                    key={opt.id}
                    value={opt.id}
                    data-cy={`coachingCenter-${idx}`}
                  >
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            }
            name="coachingCenter"
            control={control}
          />
        </FormControl>
        <Typography variant="body2" gutterBottom>
          Select the facility where this booking type will be associated.
        </Typography>
        <FormControl
          variant="outlined"
          className={classes.formField}
          error={!!errors.location || !filteredCoachFacilities.length}
        >
          <InputLabel id="category">Select location</InputLabel>
          <Controller
            as={
              <Select
                labelId="location"
                label="Select location"
                value=""
                data-cy="location"
              >
                {filteredCoachFacilities?.map(opt => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            }
            name="location"
            control={control}
            error={!!errors.location}
          />
          {errors.location ? (
            <FormHelperText>
              A location is required to create a booking type
            </FormHelperText>
          ) : null}
        </FormControl>
      </>
    )
  }
  const facilitySelect = () => {
    const handleLocationChange = e => {
      const selectedFacility = coachAvailabilities.find(
        item => item.coachFacility.id === e.target.value,
      )
      setShowAvailabilityWarning(!selectedFacility?.timeSlots?.length)
    }
    return (
      <>
        <Typography variant="body2" gutterBottom>
          Select the location where this booking type will be associated.
        </Typography>
        <FormControl
          error={!!errors.location || !coachFacilities.length}
          variant="outlined"
          className={classes.formField}
        >
          <InputLabel id="category">Select location</InputLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                labelId="location"
                label="Select location"
                value={value || ''}
                data-cy="location"
                onChange={e => {
                  onChange(e)
                  handleLocationChange(e)
                }}
              >
                {coachFacilities?.map(opt => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            name="location"
            control={control}
          />
          {errors.location ? (
            <FormHelperText>
              {errors.location?.message || 'A location is required'}
            </FormHelperText>
          ) : null}
        </FormControl>
      </>
    )
  }

  return (
    <Box>
      <Box mt={3}>
        <Typography variant="subtitle1" gutterBottom>
          Category
        </Typography>
        <Typography variant="body2" gutterBottom>
          Select from the categories below to indicate the type of booking.
        </Typography>
        <FormControl error={!!errors.category} className={classes.formField}>
          <InputLabel id="category">Select category</InputLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                labelId="category"
                data-cy="category"
                label="Select category"
                onChange={e => {
                  onChange(e)
                  handleCategoryChange(e)
                }}
                value={value || ''}
                classes={{
                  root: classes.menuItem,
                }}
              >
                {categories.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <ListItemIcon className={classes.icon}>
                      {opt.icon}
                    </ListItemIcon>
                    <ListItemText>{opt.label}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            )}
            name="category"
            control={control}
          />
          {errors.category ? (
            <FormHelperText>
              A Category is required to create a booking type
            </FormHelperText>
          ) : null}
        </FormControl>
        <Typography variant="subtitle1" gutterBottom>
          Name
        </Typography>
        <Typography variant="body2" gutterBottom>
          Enter a name for this booking type. Feel free to edit the suggested
          name based on the category you've selected.
        </Typography>
        <TextField
          className={classes.formField}
          name="title"
          data-cy="title"
          inputRef={register}
          error={!!errors.title}
          helperText={errors.title && 'A title is required'}
        />
        <Typography variant="subtitle1" gutterBottom>
          Location
        </Typography>
        {showCoachingCenterDropdown
          ? facilitySelectWithCoachingCenters()
          : facilitySelect()}

        {showAvailabilityWarning && (
          <Disclaimer
            icon={<InfoIcon />}
            description={
              <>
                <Typography variant="body2">
                  To make this booking type available for your students, make
                  sure to set the facility's availability.
                </Typography>
                <ButtonCta
                  variant="text"
                  arrow
                  href={'/pga-coach/settings/availability'}
                  className={classes.ctaButton}
                >
                  Add Availability
                </ButtonCta>
              </>
            }
          />
        )}

        <Typography variant="subtitle1" gutterBottom>
          Description
        </Typography>
        <Typography variant="body2" gutterBottom>
          Provide a description for this booking type. Include information that
          would be helpful for your students to know when they book a session.
        </Typography>
        <Box className={classes.editorRoot}>
          <Controller
            render={({ onChange, value }, _) => (
              <>
                <MUIRichTextEditor
                  label="Add a description"
                  controls={['title', 'bold', 'italic', 'bulletList']}
                  defaultValue={JSON.stringify(
                    markdownToDraft(
                      control.defaultValuesRef.current.description,
                    ),
                  )}
                  onChange={e => {
                    onChange(e)
                    const content = convertToRaw(e.getCurrentContent())
                    setValue('description', draftToMarkdown(content))
                  }}
                />
              </>
            )}
            name="description"
            control={control}
            defaultValue={null}
          />
        </Box>
        <Typography variant="subtitle1" gutterBottom>
          Duration
        </Typography>
        <Typography variant="body2" gutterBottom>
          Select a time duration to indicate how long each session under this
          booking type will last.
        </Typography>
        <FormControl
          error={!!errors.durationInMinutes}
          className={classes.formField}
        >
          <InputLabel id="durationInMinutes">Select time duration</InputLabel>
          <Controller
            as={
              <Select
                labelId="durationInMinutes"
                label="Select time duration"
                data-cy="duration"
              >
                {durationInMinutesOptions.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name="durationInMinutes"
            control={control}
          />
          {errors.durationInMinutes ? (
            <FormHelperText>
              A duration is required to create a booking type
            </FormHelperText>
          ) : null}
        </FormControl>
        <Typography variant="subtitle1" gutterBottom>
          Start Time Increments
        </Typography>
        <Typography variant="body2" gutterBottom>
          Set the frequency of available start times displayed in the calendar
          for this booking type. The start times are based on your availability
          at the selected location.
        </Typography>
        <FormControl
          error={!!errors.startTimeIncrementInMinutes}
          className={classes.formField}
        >
          <InputLabel id="startTimeIncrementInMinutes">
            Select start time increment
          </InputLabel>
          <Controller
            as={
              <Select
                labelId="startTimeIncrementInMinutes"
                label="Select start time interval"
                data-cy="startTimeInterval"
                defaultValue={
                  startTimeIncrementOptions.filter(o => o.default)[0].value
                }
              >
                {startTimeIncrementOptions.map(opt => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name="startTimeIncrementInMinutes"
            control={control}
          />
          <StartTimeIncrementPreviewDialog
            startTimeIncrementOptions={startTimeIncrementOptions}
          />
        </FormControl>
      </Box>
    </Box>
  )
}

export default BookingTypeDetails
