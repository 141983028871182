import React, { useState } from 'react'

import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import RouterLinkButton from 'components/router-link-button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Switch from '@material-ui/core/Switch'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import EventsPageData from './event-components/events-show-page'

const useStyles = makeStyles(theme => ({
  tabWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  showCancellations: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  containerStyles: {
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      marginBottom: '60px',
    },
  },
  base: {
    background: '#e9f5fe',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: '0 16px',
    background: 'e9f5fe',
    color: 'e9f5fe',
  },
  logo: {
    width: '50px',
    minWidth: '50px',
    alignSelf: 'center',
  },
  body: {
    padding: '16px 16px',
  },
  button: {
    minWidth: '150px',
  },
}))

const EventsPage = () => {
  const [selectedTab, setSelectedTab] = useState('upcoming')
  const [showCanceledEvents, setShowCanceledEvents] = useState(false)
  const classes = useStyles()
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'Offerings',
      to: [...path.split('/').slice(0, 3), 'offerings'].join('/'),
    },
  ]
  return (
    <>
      <PageHeader
        title="Events"
        crumbs={crumbs}
        actions={
          <RouterLinkButton
            variant="contained"
            color="primary"
            to="/pga-coach/bookings/events/new"
          >
            Create Event
          </RouterLinkButton>
        }
        tabs={
          <Container className={classes.containerStyles}>
            <Box className={classes.tabWrapper}>
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
              >
                <Tab label="Upcoming" value="upcoming" />
                <Tab label="Past" value="past" />
              </Tabs>
              <Box>
                <FormControlLabel
                  className={classes.showCancellations}
                  size="size"
                  control={
                    <Switch
                      checked={showCanceledEvents}
                      onChange={e => setShowCanceledEvents(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="button">Show Cancellations</Typography>
                  }
                />
              </Box>
            </Box>
          </Container>
        }
        fixedMobileActions
      />
      <EventsPageData
        selectedTab={selectedTab}
        showCanceledEvents={showCanceledEvents}
      />
    </>
  )
}

export default EventsPage
