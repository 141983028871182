import React, { useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { useSnackbar, useSnackbarError } from 'lib/snackbar'
import { gtmEvent } from 'lib/gtm'

const GET_CURRENT_USER_GOOGLE_AVAILABILITY_SYNC_INTEGRATION = gql`
  query CurrentUserGoogleAvailabilitySyncIntegration {
    currentUser {
      attributes {
        ... on Coach {
          availableGoogleCalendars {
            id
            name
            description
            color
          }
          preferences {
            syncedCalendarIds
          }
        }
      }
    }
  }
`

const UPDATE_COACH_SYNCED_CALENDAR_IDS = gql`
  mutation UpdateCoachSyncedCalendarIds($syncedCalendarIds: [String!]!) {
    updateCoachSyncedCalendarIds(syncedCalendarIds: $syncedCalendarIds) {
      success
      coach {
        id
        preferences {
          syncedCalendarIds
        }
      }
    }
  }
`

const ColoredCheckbox = withStyles({
  root: props => ({
    color: props.color,
    '&$checked': {
      color: props.color,
    },
  }),
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const SyncedCalendarPicker = ({
  availableGoogleCalendars,
  syncedCalendarIds,
  onChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const [selectedCalendarIds, setSelectedCalendarIds] = useState(
    syncedCalendarIds,
  )

  const [updateCoachSyncedCalendarIds] = useMutation(
    UPDATE_COACH_SYNCED_CALENDAR_IDS,
    {
      onCompleted: data => {
        if (data.updateCoachSyncedCalendarIds.success) {
          setSelectedCalendarIds(
            data.updateCoachSyncedCalendarIds.coach.preferences
              .syncedCalendarIds,
          )
          onChange()
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'block-time-google-calendar-integration',
            formAction: 'updated-synced-calendar-ids',
          })
        } else {
          snackbarError('An unexpected error occurred. Please try again.')
        }
      },
      onError: error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['set-synced-calendar-ids'],
          })
        snackbarError('An unexpected error occurred. Please try again.')
      },
    },
  )

  const onCalendarOptionClick = calendarId => {
    const newSyncedCalendarIds = selectedCalendarIds.includes(calendarId)
      ? selectedCalendarIds.filter(id => id !== calendarId)
      : [...selectedCalendarIds, calendarId]

    updateCoachSyncedCalendarIds({
      variables: { syncedCalendarIds: newSyncedCalendarIds },
    })
  }

  return (
    <Box mt={2}>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="subtitle1">Select Calendars to Sync</Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Updates from imported (non-Google) calendars may take 12-24 hours to
        appear on your schedule.
      </Typography>
      <Box ml={2}>
        {availableGoogleCalendars.map(calendar => (
          <SyncedCalendarOption
            key={calendar.id}
            calendar={calendar}
            isSelected={selectedCalendarIds.includes(calendar.id)}
            onClick={() => onCalendarOptionClick(calendar.id)}
          />
        ))}
      </Box>
    </Box>
  )
}

const SyncedCalendarOption = ({ calendar, isSelected, onClick }) => (
  <FormControlLabel
    style={{ display: 'block' }}
    control={
      <ColoredCheckbox
        checked={isSelected}
        onClick={onClick}
        color={calendar.color}
      />
    }
    label={`${calendar.name}
    ${
      calendar.id.includes('@import.calendar.google.com') ? ' (Imported)' : ''
    }`}
  />
)

const withIntegrationSettings = Component => props => {
  const { data, loading } = useQuery(
    GET_CURRENT_USER_GOOGLE_AVAILABILITY_SYNC_INTEGRATION,
  )

  if (loading) {
    return (
      <Box mt={2}>
        <LinearProgress />
      </Box>
    )
  }

  const availableGoogleCalendars =
    data.currentUser.attributes.availableGoogleCalendars
  const syncedCalendarIds =
    data.currentUser.attributes.preferences.syncedCalendarIds

  return (
    <Component
      availableGoogleCalendars={availableGoogleCalendars}
      syncedCalendarIds={syncedCalendarIds}
      {...props}
    />
  )
}

export default withIntegrationSettings(SyncedCalendarPicker)
