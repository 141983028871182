import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import {
  ErrorMsg,
  FlexColumn,
  HelperMsg,
  ProfileCircle,
  ProfilePhoto,
} from 'components/coaches-profile/profile.styles'
import secureLink from 'utils/secureLink'
import Button from '@material-ui/core/Button'
import { handleImageUpload } from 'lib/cloudinary/uploadImage'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET } from 'env'

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET,
  cropping: true,
  sources: ['local', 'camera'],
  multiple: false,
  croppingAspectRatio: 1,
  resourceType: 'image',
  showSkipCropButton: false,
  folder: 'dot-com-images',
  showPoweredBy: false,
}

const profileImgMsg = 'Recommended dimensions of 400x400'

const ProfilePhotoStep = ({ memberProfile }) => {
  const { control, setValue } = useFormContext()
  const [uploadError, setUploadError] = useState(null)
  return (
    <Controller
      name="profilePhoto"
      control={control}
      defaultValue={memberProfile?.photo}
      render={({ onChange, value }, _) => (
        <ProfilePhoto>
          <ProfileCircle>
            {value ? (
              <img src={secureLink(value)} alt="Coach Portrait" />
            ) : (
              memberProfile.firstName &&
              memberProfile.lastName && (
                <Typography variant="h1">{`${memberProfile.firstName.slice(
                  0,
                  1,
                )}${memberProfile.lastName.slice(0, 1)}`}</Typography>
              )
            )}
          </ProfileCircle>
          <FlexColumn style={{ justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              type="button"
              onClick={() =>
                handleImageUpload(
                  uploadOptions,
                  setValue,
                  setUploadError,
                  'profilePhoto',
                )
              }
            >
              Upload{value && ' New'} Picture
            </Button>
            {uploadError ? (
              <ErrorMsg style={{ marginBottom: 0 }}>{uploadError}</ErrorMsg>
            ) : (
              <HelperMsg style={{ marginBottom: 0 }}>{profileImgMsg}</HelperMsg>
            )}
          </FlexColumn>
          {value && (
            <Button
              variant="text"
              size="large"
              type="button"
              className="delete"
              onClick={() => {
                setValue('profilePhoto', null)
              }}
            >
              Delete
            </Button>
          )}
        </ProfilePhoto>
      )}
    />
  )
}

export default ProfilePhotoStep
