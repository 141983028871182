import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CopyTextButton from '../copy-text-button'
import IconButton from '@material-ui/core/IconButton'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '14px',
    color: theme.palette.text.disabled,
  },
  textWrapper: {
    padding: theme.spacing(1),
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
  },
}))

const CopyTextLineEllipsis = ({ text }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={10}>
        <Box className={classes.textWrapper}>
          <Typography className={classes.text} noWrap={true}>
            {text}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <CopyTextButton component={IconButton} value={text}>
          <FileCopyOutlinedIcon color="primary" />
        </CopyTextButton>
      </Grid>
    </Grid>
  )
}

export default CopyTextLineEllipsis
