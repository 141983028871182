import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SITE_PRIMARY = '#836F50'

export default ({ fill }) => (
  <SvgIcon>
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.297 9.867V6.214l3.627 1.827-3.627 1.826zm-.57 8.619c-1.33 0-2.274-.177-2.767-.345.493-.167 1.437-.344 2.767-.344 1.33 0 2.274.177 2.768.344-.494.168-1.437.345-2.768.345zm5.726-10.959l-5.471-2.754a.568.568 0 0 0-.825.513V16.66c-1.305.049-3.647.3-3.647 1.48 0 1.385 3.228 1.492 4.217 1.492.99 0 4.218-.107 4.218-1.492 0-1.18-2.343-1.431-3.648-1.48V11.15l5.156-2.596a.574.574 0 0 0 0-1.027z"
          fill={fill || SITE_PRIMARY}
        />
      </g>
    </svg>
  </SvgIcon>
)
