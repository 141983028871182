import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import CheckIcon from '@mui/icons-material/Check'
import { styled } from '@material-ui/core/styles'

const baseButtonStyles = ({ theme }) => ({
  minWidth: '70px',
  height: '44px',
  textTransform: 'none !important',
  color: '#111111 !important',
  border: '1px solid #70787C !important',
  '&.Mui-selected': {
    backgroundColor: '#EBF0F2 !important',
  },
})

const RoundedLeftButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '24px 0px 0px 24px !important',
  ...baseButtonStyles({ theme }),
}))

const RoundedRightButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '0px 24px 24px 0px !important',
  ...baseButtonStyles({ theme }),
}))

const YesNoToggle = ({ rsvp, index, register }) => {
  const [response, setResponse] = useState(
    rsvp === 'YES' || rsvp === 'MAYBE' ? 'YES' : 'NO',
  )

  const handleResponse = (event, newResponse) => {
    if (newResponse !== null) {
      setResponse(newResponse)
    }
  }

  return (
    <ToggleButtonGroup
      value={response}
      exclusive
      onChange={handleResponse}
      aria-label="response"
    >
      <input
        type="hidden"
        value={response}
        name={`participants.${index}.rsvpOnEvent`}
        ref={register()}
      />
      <RoundedLeftButton value="YES" aria-label="Yes">
        {response === 'YES' && <CheckIcon fontSize="small" />}
        &nbsp;Yes
      </RoundedLeftButton>
      <RoundedRightButton value="NO" aria-label="No">
        {response === 'NO' && <CheckIcon fontSize="small" />}
        &nbsp;No
      </RoundedRightButton>
    </ToggleButtonGroup>
  )
}

export default YesNoToggle
