import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'lib/auth'

export default function withJuniorLeagueAccess(Component) {
  return function WithJuniorLeagueAccess(props) {
    const { user } = useAuth()

    if (!user?.jrlSignedUp && !user?.programAdmin) {
      return <Redirect to="/junior-league-signup?signupRequired=true" />
    }

    return <Component {...props} />
  }
}
