import React from 'react'
import { useHistory } from 'react-router-dom'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import RecordBookingPaymentDialog from '../record-booking-payment-dialog'
import { ActionsWrapper } from './styles'

const PaymentActions = ({
  registrationPaymentId,
  enrollment,
  eventTitle,
  eventDate,
  participantName,
  lessonType,
  onPaymentSuccess,
}) => {
  const history = useHistory()
  const allowedToEditSources = ['member', 'room', 'club']
  const { transactions } = enrollment
  const sourceType = transactions[0]?.sourceType
  const isAllowedToEdit = allowedToEditSources.includes(sourceType)
  const handleViewReceiptClick = () => {
    history.push(`/account/payments/transactions/${registrationPaymentId}`)
  }

  const RecordBookingTrigger = (
    <Tooltip title="Modify Payment">
      <IconButton>
        <EditOutlinedIcon color="primary" />
      </IconButton>
    </Tooltip>
  )

  return (
    <ActionsWrapper>
      <Tooltip title="View Receipt">
        <IconButton onClick={handleViewReceiptClick}>
          <ReceiptOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
      {isAllowedToEdit && (
        <RecordBookingPaymentDialog
          trigger={RecordBookingTrigger}
          enrollment={enrollment}
          eventTitle={eventTitle}
          eventDate={eventDate}
          participantName={participantName}
          defaultAmountInCents={lessonType.priceInCents}
          acceptOnlinePayments={lessonType.acceptsOnlinePayments}
          onPaymentSuccess={onPaymentSuccess}
        />
      )}
    </ActionsWrapper>
  )
}

export default PaymentActions
