import React from 'react'
import { Button, Grid } from '@material-ui/core'

const ButtonGrid = ({
  isMobile,
  variant,
  classes,
  onClick,
  children,
  additionalStyles,
  additionalClasses,
}) => (
  <Grid
    item
    xs={12}
    sm={5}
    container
    justifyContent={isMobile ? 'center' : 'flex-end'}
  >
    <Button
      variant={variant}
      className={`${classes.buttonScale} ${additionalClasses}`}
      onClick={onClick}
      children={children}
      style={additionalStyles}
    />
  </Grid>
)

const FCCSummaryBookingButtons = ({
  isMobile,
  golferProfile,
  onBookAnotherSession,
  onCoachingCenterBooking,
  onUpdateGolferProfile,
  classes,
}) => {
  const shouldHideIntakeForm = !!golferProfile?.id

  return (
    <>
      {shouldHideIntakeForm ? (
        <>
          <ButtonGrid
            isMobile={isMobile}
            variant="outlined"
            classes={classes}
            onClick={onCoachingCenterBooking}
            children="View Offerings"
          />
          <ButtonGrid
            isMobile={isMobile}
            variant="contained"
            classes={classes}
            onClick={onBookAnotherSession}
            children="Book Another Session"
            additionalClasses={classes.buttonOverrideBook}
          />
        </>
      ) : (
        <>
          <ButtonGrid
            isMobile={isMobile}
            variant="outlined"
            classes={classes}
            onClick={onBookAnotherSession}
            children="Book Another Session"
          />
          <ButtonGrid
            isMobile={isMobile}
            variant="contained"
            classes={classes}
            onClick={onUpdateGolferProfile}
            children="Complete Student Profile"
            additionalStyles={{ whiteSpace: 'nowrap' }}
            additionalClasses={classes.buttonOverrideBook}
          />
        </>
      )}
    </>
  )
}

export default FCCSummaryBookingButtons
