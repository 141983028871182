import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { daysUntil } from 'utils/dateUtils'
import { buildDateTime } from 'lib/CustomLuxonUtils'
import { draftToMarkdown } from 'markdown-draft-js'
import { useSnackbar } from 'notistack'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { dollarsToCents } from 'lib/utils/number'
import { validationSchema } from './validation'
import makeDefaultValues from './makeDefaultValues'
import { useStyles } from './styles'
import FormSectionContainer from './form-section-container'
import EventNameAndTags from './form-sections/event-name-and-tags'
import EventLocation from './form-sections/event-location'
import MultiSessionInput from 'components/multi-session-input'
import CancelGroupEventDialog from 'components/cancel-group-event-dialog'
import RegistrationCloseDay from './form-sections/registration-close-day'
import MaxAttendance from './form-sections/max-attendance'
import AccessCode from './form-sections/access-code'
import EventDescription from './form-sections/event-description'
import FeaturedImage from './form-sections/featured-image'
import Fees from './form-sections/fees'
import ExternalRegistrationUrl from './form-sections/event-external-registration-url'
import SessionList from './form-sections/session-list'
import Unlisted from './form-sections/unlisted'
import {
  composeMaxAttendance,
  isRecurringPayment,
  MANUAL_PAYMENT,
  ONE_TIME_PAYMENT,
} from 'utils/groupEventUtils'
import AssistantCoaches from './form-sections/assistant-coaches'
import { useFeatureFlags, flags } from 'lib/feature-flags'
import { FormActionBar } from 'components/form-util'
import AgeGroupSelect from 'components/age-group-select'

const UPDATE_GROUP_EVENT = gql`
  mutation UpdateGroupEvent(
    $id: ID!
    $values: GroupEventInput!
    $reason: String
  ) {
    updateGroupEvent(id: $id, values: $values, reason: $reason) {
      success
      message
    }
  }
`

const EditEventLayout = ({ event, coachFacilities, paymentAccounts }) => {
  const isAcademyEvent = event?.academy?.id
  const eligibleFacilities = isAcademyEvent
    ? event.academy?.facilities
    : coachFacilities

  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const [isUpdateReasonModalOpen, setIsUpdateReasonModalOpen] = useState(false)
  const [updateReason, setUpdateReason] = useState('')
  const [isAgeSpecificGroupEventsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_AGE_SPECIFIC_GROUP_EVENTS,
  ])
  const showCancelEventBtn = daysUntil(event.startDateTime) >= 0

  const [updateGroupEvent, { loading }] = useMutation(UPDATE_GROUP_EVENT, {
    onCompleted: data => {
      if (data.updateGroupEvent.success) {
        enqueueSnackbar('Event updated successfully', {
          variant: 'success',
        })
        setTimeout(() => {
          history.push('/pga-coach/bookings/events')
        }, 1000)
      } else {
        enqueueSnackbar(data.updateGroupEvent.message, {
          variant: 'error',
        })
      }
    },
  })

  const form = useForm({
    defaultValues: makeDefaultValues(event),
    resolver: yupResolver(
      validationSchema(eligibleFacilities, paymentAccounts, event),
    ),
  })

  const {
    formState: { dirtyFields },
    watch,
    errors,
  } = form

  const hasErrors = Object.keys(errors).length > 0

  !!errors &&
    Object.keys(errors).forEach(
      key =>
        errors[key].message &&
        enqueueSnackbar(errors[key].message, {
          variant: 'error',
          preventDuplicate: true,
        }),
    )

  const selectedFacilityId = watch('locationId')
  const selectedFacility = coachFacilities?.find(
    facility => facility.id === selectedFacilityId,
  )

  const handleSaveClick = async e => {
    e.preventDefault()

    // Manually trigger validation for 'locationId' and 'sessions' fields
    const isValidLocationId = await form.trigger('locationId')
    const isValidSessions = await form.trigger('sessions')

    // Check if both fields are valid
    if (isValidLocationId && isValidSessions) {
      if (dirtyFields.locationId || dirtyFields.sessions) {
        setIsUpdateReasonModalOpen(true)
      } else {
        form.handleSubmit(onSubmit)(e)
      }
    }
  }

  const onUpdateReasonChange = event => {
    setUpdateReason(event.target.value)
  }

  const handleUpdateDialogClose = () => {
    setIsUpdateReasonModalOpen(false)
    setUpdateReason('')
  }

  const onSubmit = async formValues => {
    const ageGroupMap = new Map([
      ['adults-only', 'Adults Only'],
      ['juniors-only', 'Juniors Only'],
    ])
    const filteredTags = formValues.tags.filter(
      tag => !['Juniors Only', 'Adults Only'].includes(tag),
    )
    const ageGroupValue = ageGroupMap.get(formValues.ageGroup)
    if (ageGroupValue) {
      filteredTags.push(ageGroupValue)
    }
    const selectedFacility = eligibleFacilities.find(
      cf => cf.id === formValues.locationId,
    )

    const hasPaymentAccountId = (formValues.paymentAccountId || '').length > 0

    return updateGroupEvent({
      variables: {
        id: event.id,
        reason: updateReason,
        values: {
          title: formValues.title,
          academyId: event.academy?.id || null,
          assistantCoachIds: formValues.assistantCoachIds,
          description: formValues.description
            ? draftToMarkdown(JSON.parse(formValues.description))
            : null,
          registrationCloseDateTime: buildDateTime(
            formValues.registrationCloseDate,
            formValues.registrationCloseTime,
            selectedFacility.timezone,
          ),
          tags: filteredTags,
          externalRegistrationUrl: formValues.externalRegistrationUrl,
          maxAttendance: composeMaxAttendance(
            formValues.maxAttendance,
            event.hasWaitlist,
          ),
          priceInCents: dollarsToCents(formValues.price),
          featuredImageUrl: formValues.featuredImageUrl,
          paymentAccountId: hasPaymentAccountId
            ? formValues.paymentAccountId
            : null,
          paymentRecurrenceSchedule: isRecurringPayment(event)
            ? event.paymentRecurrenceSchedule
            : hasPaymentAccountId
              ? ONE_TIME_PAYMENT
              : MANUAL_PAYMENT,
          sessions: isRecurringPayment(event)
            ? event.sessions.map(session => ({
              facilityId: selectedFacility.id,
              startDateTime: session.startDateTime,
              endDateTime: session.endDateTime,
            }))
            : formValues.sessions.map(session => ({
              facilityId: selectedFacility.id,
              startDateTime: buildDateTime(
                session.startDate,
                session.startTime,
                selectedFacility.timezone,
              ),
              endDateTime: buildDateTime(
                session.startDate,
                session.endTime,
                selectedFacility.timezone,
              ),
            })),
          unlisted: formValues.unlisted,
          accessCode:
            (formValues.accessCode || '').length > 0
              ? formValues.accessCode
              : null,
        },
      },
    })
  }
  return (
    <Box>
      <FormProvider {...form}>
        <form onSubmit={handleSaveClick}>
          <FormActionBar
            includeCancel
            instructions={hasErrors && 'Form has one or more errors'}
            error={hasErrors}
          >
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </FormActionBar>

          {loading ? (
            <LinearProgress color="primary" className={classes.loadingStyles} />
          ) : null}
          <Container>
            <Box py={3}>
              <Typography variant="h4" gutterBottom>
                Edit Event
              </Typography>
              <FormSectionContainer title="Customize your event">
                <EventNameAndTags />
                {isAgeSpecificGroupEventsEnabled && (
                  <Box style={{ paddingBottom: '8px' }}>
                    <Typography variant="subtitle1">
                      Specify Age Group
                    </Typography>
                    <AgeGroupSelect
                      style={{ marginTop: '10px', maxWidth: '450px' }}
                    />
                  </Box>
                )}
              </FormSectionContainer>

              <FormSectionContainer title="Location">
                <EventLocation
                  event={event}
                  coachFacilities={eligibleFacilities}
                />
              </FormSectionContainer>
              <FormSectionContainer title="Event details">
                <AssistantCoaches event={event} />
                {event.externalRegistrationUrl ? (
                  <ExternalRegistrationUrl />
                ) : null}
                <Typography variant="subtitle1" gutterBottom>
                  Session Dates and Times
                </Typography>
                <br />
                <Grid container direction="column" xs={10}>
                  {isRecurringPayment(event) ? (
                    <>
                      <Typography variant="body2" gutterBottom>
                        Subscription session dates/times cannot be edited. To
                        make changes, this offering will need to be duplicated
                        and cancelled.
                      </Typography>
                      <SessionList
                        event={event}
                        timezone={selectedFacility?.timezone}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="body2" gutterBottom>
                        Select the dates and times of the sessions in this
                        event.
                      </Typography>
                      <MultiSessionInput
                        name="sessions"
                        timezone={selectedFacility?.timezone}
                      />
                    </>
                  )}
                </Grid>
                <RegistrationCloseDay />
                <MaxAttendance />
                <AccessCode />
                <EventDescription />
              </FormSectionContainer>
              <FormSectionContainer title="Media & Visibility">
                <FeaturedImage />
                <Unlisted />
              </FormSectionContainer>
              <FormSectionContainer title="Fees">
                <Fees event={event} paymentAccounts={paymentAccounts} />
              </FormSectionContainer>
              {showCancelEventBtn && (
                <CancelGroupEventDialog event={event} style={useStyles} />
              )}
            </Box>
          </Container>
        </form>
      </FormProvider>
      <Dialog maxWidth="sm" fullWidth open={isUpdateReasonModalOpen}>
        <DialogTitle>This update will notify event attendees</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Updating the <strong>Location</strong> or the{' '}
            <strong>Schedule</strong> of an event will automatically inform the
            attendees of the changes. Use the form below if you would like to
            add a custom message.
          </Typography>
          <br />
          <Typography gutterBottom>
            <strong>Provide a Reason</strong> (optional)
          </Typography>
          <TextField
            multiline
            rows={4}
            name="updateReason"
            label="Reason for Update"
            onChange={onUpdateReasonChange}
            gutterBottom
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
          >
            Update Event
          </Button>
          <Button color="primary" onClick={handleUpdateDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EditEventLayout
