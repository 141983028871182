import React from 'react'
import { useFormContext } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { styled, makeStyles } from '@material-ui/styles'
import { SectionHeader, HelperText, FormFields } from './intake-form.styles'
import propTypes from 'prop-types'

const PhysicalWrapper = styled(Grid)({
  direction: 'column',
})

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: '.75rem 1.5rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '.5rem 1.5rem',
  },
}))

const useStyles = makeStyles(theme => ({
  helperText: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '.75rem',
    },
  },
}))

const PhysicalInformation = () => {
  const { register, errors } = useFormContext()
  const classes = useStyles()
  return (
    <PhysicalWrapper container>
      <SectionHeader variant="h6">Physical Information</SectionHeader>
      <FormFields container>
        <HelperText variant="subtitle2">
          The following questions are intended to help your coaches develop
          appropriate instruction and avoid creating or aggravating player
          injuries.
        </HelperText>
        <StyledGrid xs={12} sm={6} item>
          <TextField
            name="physicalInformation.heightFeet"
            inputRef={register}
            type="number"
            error={!!errors?.heightFeet}
            helperText={errors?.heightFeet?.message}
            label="Height (ft)"
            fullWidth
            id="heightFeet"
            onInput={e => {
              e.target.value = Math.max(3, parseInt(e.target.value))
                .toString()
                .slice(0, 1)
              e.target.value = Math.min(8, parseInt(e.target.value))
                .toString()
                .slice(0, 1)
            }}
          />
        </StyledGrid>
        <StyledGrid xs={12} sm={6} item>
          <TextField
            type="number"
            name="physicalInformation.heightInches"
            inputRef={register}
            error={!!errors?.heightInches}
            helperText={errors?.heightInches?.message}
            label="Height (in)"
            fullWidth
            id="heightInches"
            onInput={e => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 2)
              e.target.value = Math.min(11, parseInt(e.target.value))
                .toString()
                .slice(0, 2)
            }}
          />
        </StyledGrid>
        <HelperText variant="subtitle2" className={classes.helperText}>
          Please list any physical limitations or concerns
        </HelperText>
        <StyledGrid item xs={12}>
          <TextField
            name="physicalInformation.limitations"
            inputRef={register}
            multiline
            rows={3}
            id="limitations"
            defaultValue={null}
            error={!!errors?.limitations}
            helperText={errors?.limitations?.message}
            placeholder="i.e., past injuries, lingering injuries, joint replacements, range of motion deficiencies (optional)"
            fullWidth
          />
        </StyledGrid>
      </FormFields>
    </PhysicalWrapper>
  )
}

export default PhysicalInformation

PhysicalInformation.propTypes = {
  heightFeet: propTypes.number,
  heightInches: propTypes.number,
  limitations: propTypes.string,
}
