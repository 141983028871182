import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  dialogTitle: {
    background: theme.palette.primary.selected,
    minHeight: '185px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '85px',
    },
  },
  actionWrapper: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
  },
  categoryIcon: {
    position: 'absolute',
    top: '145px',
    left: '50px',
    height: '80px',
    width: '80px',
    background: theme.palette.primary.light,
    '& svg': {
      width: '2.5rem !important',
      height: '2.5rem !important',
      '& path': {
        fill: '#FFF !important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      top: '50px',
      left: '30px',
      height: '60px',
      width: '60px',
      '& svg': {
        width: '1.75rem !important',
        height: '1.75rem !important',
      },
    },
  },
}))
