import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),

    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },

  titleContainer: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
    '& :last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column-reverse',

      '& > *': {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },

      '& :first-child': {
        marginBottom: 0,
      },
    },
  },
  mobileActionWrapper: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'row',
      zIndex: '10',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#fff',
      padding: '12px',
      justifyContent: 'space-around',
      boxSizing: 'border-box',
    },
  },
}))

export default function StandaloneHeader({
  title,
  crumbs,
  actions,
  tabs,
  fixedMobileActions,
  ...props
}) {
  const classes = useStyles()

  return (
    <Box {...props}>
      <Box className={classes.headerContainer}>
        <Box className={classes.titleContainer}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.title}
            data-testid="page-header-title"
          >
            {title}
          </Typography>
        </Box>
        <Box
          className={`${classes.actionWrapper} ${
            fixedMobileActions ? classes.mobileActionWrapper : ''
          }`}
        >
          {actions}
        </Box>
      </Box>
      {tabs}
    </Box>
  )
}
