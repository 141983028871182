import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { IconInfoActive } from 'components/atoms/icons'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import AccountsBenefitModal from 'components/account-benefits-modal'
import StepDescriptionOfferings from '../step-description-offerings'
import { useIsMobile } from 'lib/hooks'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import { redirectToLogin, redirectToSignup } from 'lib/auth'

export const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '5rem',
  },
  icon: {
    margin: '.5rem .75rem .5rem .25rem',
  },
  loginPromptText: {
    marginLeft: '.75rem',
  },

  learnMoreText: {
    color: '#00234B',
    fontWeight: '600',
    cursor: 'pointer',
  },
}))

const CreateAccountForBookingLayout = ({ showDescription = true }) => {
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.root}>
      {showDescription && (
        <>
          <ProgressIndicator value={50} />
          <Divider style={{ margin: '24px 0 32px' }} />
          <Grid container direction="column">
            <StepDescriptionOfferings
              stepText="Step 2 of 3"
              title="Select a Student"
              description="Who will be attending the session?"
            />
          </Grid>
        </>
      )}
      <Grid container direction="column">
        <Grid
          container
          alignItems="center"
          direction="row"
          xs={12}
          sm={8}
          wrap="nowrap"
          style={{
            borderRadius: '.5rem',
            backgroundColor: '#F4F6F8',
            padding: '.5rem',
          }}
        >
          <IconInfoActive
            item
            size={16}
            className={classes.icon}
            fill="#00234B"
          />
          <Divider flexItem orientation="vertical" />
          <Grid item container className={classes.loginPromptText}>
            <Typography variant="body2">
              Log into your MyPGA account to complete booking this
              session.&nbsp;
            </Typography>
            <Typography
              variant="body2"
              className={classes.learnMoreText}
              onClick={() => setIsOpen(true)}
            >
              Learn more
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={isMobile ? 'row-reverse' : 'row'}
        justifyContent={isMobile ? 'flex-end' : 'flex-start'}
        spacing={2}
        style={{ margin: '1rem 0' }}
      >
        <Grid item>
          <Button
            onClick={() => redirectToLogin('consumer')}
            variant="contained"
            color="primary"
          >
            <Typography color="contrastText" variant="subtitle1">
              Log In
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => redirectToSignup('consumer')}
            variant="outlined"
            color="primary"
          >
            <Typography variant="subtitle1">Create Account</Typography>
          </Button>
        </Grid>
      </Grid>
      <AccountsBenefitModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Grid>
  )
}

export default CreateAccountForBookingLayout
