import { makeStyles } from '@material-ui/core/styles'
import themes from 'themes'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
  },
  dividerStyles: {
    margin: '20px 0',
  },
  paddedDivider: {
    margin: '8px 0px 40px',
  },
  loadingStyles: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowWrapped: {
    display: 'flex',
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  bookingType: {
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  fontBolded: {
    fontWeight: 600,
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  warningBox: {
    padding: '20px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
    borderRadius: '4px',
    margin: '20px 0',
    '& svg': {
      fill: theme.palette.primary.light,
    },
  },
  learnMoreText: {
    color: '#00234B',
    fontWeight: '600',
    cursor: 'pointer',
  },
  chip: {
    color: '#fff',
    borderRadius: '4px',
    height: '22px',
    fontSize: '10px',
  },
  chipInfo: {
    background: '#0B79D0',
  },
  chipSuccess: {
    background: '#3B873E',
    marginRight: theme.spacing(1),
  },
  iconStyled: {
    backgroundColor: 'rgba(0, 35, 75, 0.08)',
    height: '40px',
    width: '40px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '16px',
  },
  studentCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    border: '1px solid #EEE',
    height: '80px',
    padding: '16px',
  },
  buttonStyles: {
    borderRadius: '50px',
    height: '40px',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.lightest,
  },
  buttonOverrideBook: {
    backgroundColor: themes.offeringsRefresh.palette.primary.lightest,
    color: themes.offeringsRefresh.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
    },
    borderRadius: '50px',
    padding: '0.5rem 1rem',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '22px',
  },
  buttonOverrideOfferings: {
    borderRadius: '50px',
    textTransform: 'none',
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    fontWeight: '700',
    color: themes.offeringsRefresh.palette.primary.light,
    lineHeight: '22px',
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
      color: themes.offeringsRefresh.palette.primary.contrastText,
    },
  },
  buttonScale: {
    maxWidth: '213px',
    maxHeight: '40px',
    width: '100%',
    height: '100%',
    borderRadius: '50px',
    textTransform: 'none',
    padding: '0.5rem 1rem',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '22px',
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
      color: themes.offeringsRefresh.palette.primary.contrastText,
    },
  },
}))
