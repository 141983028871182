import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

// Not really sure where to put this, common title with specific style not based on
// typography styles

const useStyles = makeStyles(theme => ({
  adminTitle: {
    fontSize: '30px',
    fontWeight: 500,
    letterSpacing: '0.25px',
  },
}))

export default function AdminTitle({ align, children }) {
  const classes = useStyles()
  return (
    <Typography variant="h4" align={align} className={classes.adminTitle}>
      {children}
    </Typography>
  )
}
