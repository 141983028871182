import React from 'react'
import { useSnackbar } from 'notistack'

import {
  Button,
  CircularProgress,
  Container,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import {
  useCancelPaymentRequest,
  useSendPaymentRequest,
} from 'lib/graphql/mutations/payment-request-mutation.js'
import propTypes from 'prop-types'
import { gtmEvent } from 'lib/gtm'

const useStyles = makeStyles(theme => ({
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
  },
}))

const getDialogContent = isCancelReminder =>
  isCancelReminder
    ? {
      label: 'Cancel Request',
      description:
        'By canceling your request, your student will no longer be able to submit payment via their requested payment link.',
    }
    : {
      label: 'Payment Reminder',
      description:
        'Waiting on a requested payment? Remind your student to submit their payment online.',
    }

const RemindOrCancelRequestPaymentDialog = ({
  trigger,
  enrollmentId,
  onSuccess,
  isCancelReminder = false,
}) => {
  const [cancelPaymentRequest, { loading }] = useCancelPaymentRequest()
  const [sendPaymentRequest, { loading: loadingSend }] = useSendPaymentRequest()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const dialogContent = getDialogContent(isCancelReminder)

  const handleCancelPaymentRequest = async () => {
    const { data } = await cancelPaymentRequest({ variables: { enrollmentId } })
    if (data?.cancelPaymentRequest?.success) {
      enqueueSnackbar('Requested payment successfully canceled', {
        variant: 'success',
      })
      gtmEvent({
        event: 'request-payment',
        requestAction: 'cancel',
        reqestEnrollment: enrollmentId,
      })
      onSuccess && onSuccess()
    } else {
      enqueueSnackbar(data?.cancelPaymentRequest?.message, { variant: 'error' })
    }
  }

  const handlePaymentRequestConfirm = async () => {
    const { data } = await sendPaymentRequest({ variables: { enrollmentId } })
    if (data?.sendPaymentRequest?.success) {
      enqueueSnackbar('Payment request successfully sent', {
        variant: 'success',
      })
      gtmEvent({
        event: 'request-payment',
        requestAction: 'remind',
        reqestEnrollment: enrollmentId,
      })
      onSuccess && onSuccess()
    } else {
      enqueueSnackbar(data?.sendPaymentRequest?.message, { variant: 'error' })
    }
  }

  return (
    <Dialog trigger={trigger} maxWidth="sm">
      {({ closeDialog }) => (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogTitle>{dialogContent.label}</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {dialogContent.description}
            </Typography>
            {(loading || loadingSend) && <CircularProgress color="primary" />}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={closeDialog}>
              close
            </Button>
            {isCancelReminder ? (
              <Button
                variant="contained"
                startIcon={<DeleteOutlineOutlinedIcon />}
                onClick={handleCancelPaymentRequest}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                startIcon={<NotificationsActiveOutlinedIcon />}
                onClick={handlePaymentRequestConfirm}
              >
                Remind
              </Button>
            )}
          </DialogActions>
        </Container>
      )}
    </Dialog>
  )
}

export default RemindOrCancelRequestPaymentDialog

RemindOrCancelRequestPaymentDialog.propTypes = {
  trigger: propTypes.node.isRequired,
  enrollmentId: propTypes.string.isRequired,
  onSuccess: propTypes.func,
  isCancelReminder: propTypes.bool,
}
