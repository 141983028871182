import React from 'react'
import { useMutation } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ConnectionResponseMutation } from './query'
import { useStyles } from './connectionResponse.styles'

const getInitials = name => {
  return name
    .split(' ')
    .map(s => s[0])
    .join('')
    .substring(0, 2)
}

export default ({ history, connectionInfo, connectionRequestToken }) => {
  const { coachProfile, coach, student } = connectionInfo
  const { enqueueSnackbar } = useSnackbar()
  const [acceptConnection, { loading }] = useMutation(
    ConnectionResponseMutation,
  )

  const classes = useStyles()

  const handleClick = async () => {
    try {
      await acceptConnection({
        variables: { params: { connectionRequestToken, response: 'accepted' } },
      })
      enqueueSnackbar('You are now connected!', {
        variant: 'success',
        preventDuplicate: true,
      })
    } catch (e) {
      enqueueSnackbar('Sorry, something went wrong', {
        variant: 'error',
        preventDuplicate: true,
      })
    }
    return history.push('/club')
  }

  return (
    <Container className={classes.root}>
      <Paper elevation={0} className={classes.paperWrapper}>
        {loading && <LinearProgress color="primary" />}
        <Typography className={classes.titleStyles} variant="h4">
          Connect
        </Typography>
        <div className={classes.avatarsWrapped}>
          <Avatar
            src={coachProfile?.profilePhoto}
            className={classes.avatarStyles}
          >
            {getInitials(coach.name)}
          </Avatar>
          <SyncAltIcon
            style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            fontSize="large"
          />
          <Avatar className={classes.avatarStyles}>
            {getInitials(`${student.firstName} ${student.lastName}`)}
          </Avatar>
        </div>
        <Typography
          className={classes.textStyles}
          align="center"
          variant="body"
        >
          Do you want to connect your MyPGA account with{' '}
          {coachProfile
            ? `${coachProfile.firstName} ${coachProfile.lastName}`
            : coach.name}
          ?
        </Typography>
        <Button onClick={handleClick} variant="contained" color="primary">
          CONNECT MY ACCOUNT
        </Button>
        <Button href="/club" style={{ marginTop: '10px' }}>
          GO TO MY DASHBOARD <ArrowRightAltIcon />
        </Button>
        <Typography
          className={classes.textStyles}
          align="center"
          variant="body"
        >
          Connecting your account with your PGA Coach will allow for streamlined
          messaging, booking lessons, and reviews, with much more coming soon.
        </Typography>
      </Paper>
    </Container>
  )
}
