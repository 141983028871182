import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import { LinearProgress } from '@material-ui/core'
import { getValidationSchema } from './validationSchema'
import Error from 'components/error'
import WizardForm from 'components/wizard-form'
import { gtmEvent } from 'lib/gtm'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  LFO_COACH_ATTRIBUTES,
  LFO_CREATE_COACH_PROFILE,
  LFO_CREATE_GROUP_EVENT,
  UPDATE_COACH_PROFILE,
  buildNewProfileParams,
  buildUpdatedProfileParams,
  buildCreateLightningEventParams,
} from '../queries'
import EventDetailsStep from './steps/event-details'
import FacilityStep from './steps/facility'
import ProfileNameStep from './steps/profile-name'
import RegistrationDetailsStep from './steps/registration-details'
import PreviewStep from './steps/preview'
import SuccessStep from './steps/success'
import { TemplateContextProvider, useFindTemplate } from '../use-find-template'
import useQueryString from 'lib/hooks/use-query-string'

const CreateLightningEventFormWizard = () => {
  const history = useHistory()
  const { template } = useQueryString()
  const { enqueueSnackbar } = useSnackbar()
  const [createdGroupEvent, setCreatedGroupEvent] = useState(null)
  const templateValues = useFindTemplate()
  const RETURN_URL = template
    ? `/pga-coach/fast-onboarding?template=${template}`
    : '/pga-coach/fast-onboarding'

  // query for existing coach attributes
  const { loading, error, data } = useQuery(LFO_COACH_ATTRIBUTES)

  const [createCoachProfile, { loading: creatingProfile }] = useMutation(
    LFO_CREATE_COACH_PROFILE,
    {
      onCompleted: data => {
        if (data?.CoachProfileCreate?.success) {
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'lightning-event',
            formAction: 'created-coach-profile',
          })
          return data.CoachProfileCreate.coachProfile
        } else {
          enqueueSnackbar(data.CoachProfileCreate.message, {
            variant: 'error',
          })
        }
        return data.CoachProfileCreate.coachProfile
      },
      onError: error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['lfo-coach-profile-create-error'],
          })
        enqueueSnackbar(
          error?.message || 'An error has occurred. Please try again later.',
          { variant: 'error' },
        )
      },
    },
  )

  const [updateCoachProfile, { loading: updatingProfile }] = useMutation(
    UPDATE_COACH_PROFILE,
    {
      onCompleted: data => {
        if (data?.CoachProfileUpdate?.success) {
          return data.CoachProfileUpdate.coachProfile
        } else {
          enqueueSnackbar(data.CoachProfileUpdate.message, {
            variant: 'error',
          })
        }
        return data.CoachProfileUpdate.coachProfile
      },
      onError: error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['lfo-coach-profile-update-error'],
          })
        enqueueSnackbar(
          error?.message || 'An error has occurred. Please try again later.',
          { variant: 'error' },
        )
      },
    },
  )

  const [createLightningEvent, { loading: creatingGE }] = useMutation(
    LFO_CREATE_GROUP_EVENT,
    {
      onCompleted: data => {
        if (data.createGroupEvent.success) {
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'lightning-event',
            formAction: 'created-group-event',
          })
          if (window?.Intercom?.booted) {
            template
              ? window?.Intercom('trackEvent', `${template}-lfo-completed`)
              : window?.Intercom('trackEvent', 'lfo-completed')
          }
          setCreatedGroupEvent(data.createGroupEvent.groupEvent)
        } else {
          data.createGroupEvent.message.includes('time overlaps another event')
            ? enqueueSnackbar(
              'You have another session at this time. Try changing the date and/or time.',
              {
                variant: 'error',
              },
            )
            : enqueueSnackbar(data.createGroupEvent.message, {
              variant: 'error',
            })
        }
      },
      onError: error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['lfo-group-event-create-error'],
          })
        enqueueSnackbar(
          error?.message || 'An error has occurred. Please try again later.',
          { variant: 'error' },
        )
      },
    },
  )

  if (createdGroupEvent) {
    return <SuccessStep slug={createdGroupEvent.slug} />
  }

  // TODO: add tests for these states
  if (loading || creatingProfile || updatingProfile || creatingGE) {
    return <LinearProgress color="primary" />
  }
  if (error) return <Error error={error} />

  const {
    name,
    email,
    eligibleForLightningFastOnboarding,
    facilities,
    coachProfile,
  } = get(data, 'currentUser.attributes')

  const [firstName, lastName] = name.split(' ')
  const hasFacilitiesOnProfile = facilities.length > 0
  // let's make the intent of this variable name more clear
  const hasProfile = !eligibleForLightningFastOnboarding

  // creates the correct validation schema to for the scenario
  const schema = getValidationSchema(
    eligibleForLightningFastOnboarding,
    facilities,
  )

  const stepsWhenCoachHasProfile = [
    {
      component: <FacilityStep coachFacilities={facilities} />,
      fieldsToValidate: hasFacilitiesOnProfile ? ['facilityId'] : ['facility'],
      nextButtonLabel: 'Next: Details',
      stepName: 'facility',
    },
    {
      component: <EventDetailsStep coachFacilities={facilities} />,
      fieldsToValidate: ['title', 'description', 'sessions'],
      nextButtonLabel: 'Next: Registration Details',
      stepName: 'event-details',
    },
    {
      component: <RegistrationDetailsStep defaultValues={templateValues} />,
      fieldsToValidate: [
        'maxAttendance',
        'hoursBeforeRegistrationCloses',
        'price',
        'accessCode',
      ],
      nextButtonLabel: 'Next',
      stepName: 'registration-details',
    },
    {
      component: <PreviewStep coachFacilities={facilities} />,
      fieldsToValidate: [''],
      nextButtonLabel: 'Confirm',
      nextButtonLabelMobile: 'Confirm',
      stepName: 'preview',
    },
  ]

  // add profile name step if coach does not have a profile
  const fullSteps = [
    {
      component: <ProfileNameStep name={name} />,
      fieldsToValidate: ['customSlug'],
      nextButtonLabel: 'Next',
      stepName: 'profile-name',
    },
  ].concat(stepsWhenCoachHasProfile)

  const getFacility = (formValues, coachFacilities, hasFacilities) => {
    if (hasFacilities) {
      const existingFacility = coachFacilities.find(
        fac => fac.id === formValues.facilityId,
      )
      return {
        facilityId: existingFacility.id,
        facilityName: existingFacility.name,
        city: existingFacility.city,
        state: existingFacility.state,
        zipCode: existingFacility.zip,
        customData: {
          facilityRank: 1, // need to make sure we're not accidentally overwriting existing facility ranks
          offerings: [],
        },
      }
    } else {
      return {
        pgaFacilityId: formValues.facility.pgaFacilityId,
        facilityName: formValues.facility.facilityName,
        street: formValues.facility.address1,
        city: formValues.facility.city,
        state: formValues.facility.state,
        zipCode: formValues.facility.zipCode,
        customData: {
          facilityRank: 1,
          offerings: [],
        },
      }
    }
  }

  const createNewProfile = async (formValues, facility) => {
    const response = await createCoachProfile({
      variables: buildNewProfileParams({
        firstName: firstName,
        lastName: lastName,
        email: email,
        formValues: formValues,
        facility: facility,
      }),
    })
    return response.data?.CoachProfileCreate?.coachProfile
  }

  return (
    <TemplateContextProvider>
      <WizardForm
        formProps={{
          defaultValues: {
            ...templateValues,
          },
          resolver: yupResolver(schema),
        }}
        formName="lightning-event"
        steps={hasProfile ? stepsWhenCoachHasProfile : fullSteps}
        onSubmit={async formValues => {
          const facility = getFacility(
            formValues,
            facilities,
            hasFacilitiesOnProfile,
          )
          if (!hasProfile && !hasFacilitiesOnProfile) {
            const newProfile = await createNewProfile(formValues, facility)
            const facilityTimezone = newProfile.coach.facilities[0].timezone

            return createLightningEvent({
              variables: buildCreateLightningEventParams({
                formValues: formValues,
                defaultValues: templateValues,
                profile: newProfile,
                timezone: facilityTimezone,
              }),
            })
          } else if (!hasProfile && hasFacilitiesOnProfile) {
            const newProfile = await createNewProfile(formValues, facility)
            const facilityTimezone = newProfile.coach.facilities.find(
              fac => fac.id === facility.facilityId,
            ).timezone

            return createLightningEvent({
              variables: buildCreateLightningEventParams({
                formValues: formValues,
                defaultValues: templateValues,
                profile: newProfile,
                timezone: facilityTimezone,
              }),
            })
          } else if (hasProfile && !hasFacilitiesOnProfile) {
            const response = await updateCoachProfile({
              variables: buildUpdatedProfileParams({
                coachProfile: coachProfile,
                formValues: formValues,
              }),
            })
            const updatedProfile =
              response.data?.CoachProfileUpdate?.coachProfile
            const facilityTimezone = updatedProfile.coach.facilities[0].timezone
            return createLightningEvent({
              variables: buildCreateLightningEventParams({
                formValues: formValues,
                defaultValues: templateValues,
                profile: updatedProfile,
                timezone: facilityTimezone,
              }),
            })
          } else if (hasProfile && hasFacilitiesOnProfile) {
            const facilityTimezone = facilities.find(
              fac => fac.id === formValues.facilityId,
            ).timezone
            return createLightningEvent({
              variables: buildCreateLightningEventParams({
                formValues: formValues,
                defaultValues: templateValues,
                timezone: facilityTimezone,
              }),
            })
          }
        }}
        onCancel={() => history.push(RETURN_URL)}
      />
    </TemplateContextProvider>
  )
}

export default CreateLightningEventFormWizard
