import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useIsTablet } from 'lib/hooks'

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
  },
  sectionTitle: {
    fontWeight: '500',
    fontSize: '20px',
    margin: '24px 32px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
      margin: '16px 28px 8px',
      fontSize: '12px',
      lineHeight: '31px',
      textTransform: 'uppercase',
    },
  },
  sectionContent: {
    margin: '0px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
      margin: 0,
      padding: '0 16px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  listText: {
    wordBreak: 'break-word',
  },
}))

export const ContentSection = ({ children, title = '', ...props }) => {
  const styles = useStyles()
  const isTablet = useIsTablet()

  return (
    <Card className={styles.section} variant="outlined" {...props}>
      <Typography className={styles.sectionTitle}>{title}</Typography>
      {!isTablet && <Divider />}
      <CardContent className={styles.sectionContent}>{children}</CardContent>
    </Card>
  )
}

export const ListText = ({ text, ...props }) => {
  const classes = useStyles()

  return (
    <ListItemText
      disableTypography
      className={classes.listText}
      primary={<Typography style={{ all: 'inherit' }}>{text}</Typography>}
      {...props}
    />
  )
}
