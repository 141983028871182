import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import { useAuth } from 'lib/auth'
import { gtmEvent } from 'lib/gtm'
import TileButtons from './tile-buttons'
import { useStyles } from './styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import InputLabel from '@material-ui/core/InputLabel'

export default ({
  students,
  history,
  currentUrl,
  openAddStudent,
  setOpenAddStudent,
  showAddStudent = true,
  eventObject = {},
}) => {
  const { user } = useAuth()
  const classes = useStyles()
  const selfStudentId = students?.find(student =>
    student?.representatives.some(
      r =>
        r?.relationshipType === 'SELF' &&
        r?.contact?.externalId === user.externalId,
    ),
  )?.id
  const studentsWithoutSelf = selfStudentId
    ? students.filter(student => student.id !== selfStudentId)
    : students
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [isSelf, setIsSelf] = useState(false)
  const [isSomeoneElse, setIsSomeoneElse] = useState(false)

  const handleSelectStudentFromDropdown = studentId => {
    setSelectedStudent(studentId)
    setIsSelf(false)
    let urlForUpdate = currentUrl
    if (currentUrl.includes('studentId')) {
      urlForUpdate = urlForUpdate.replace(/&studentId=\d+/, '')
    }
    history.push(`${urlForUpdate}&studentId=${studentId}`)
    gtmEvent({
      ...eventObject,
      event: 'select-student-to-schedule',
    })
    // Remove the below event in the future.
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'selected-student',
    })
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <TileButtons
        {...{
          isSelf,
          setIsSelf,
          isSomeoneElse,
          setIsSomeoneElse,
          setSelectedStudent,
          selfStudentId,
          history,
          currentUrl,
          eventObject,
        }}
      />
      {isSomeoneElse && (
        <>
          {studentsWithoutSelf.length > 0 && (
            <FormControl
              variant="outlined"
              className={classes.withShadow}
              fullwidth
            >
              <InputLabel id="select-student" className={classes.inputLabel}>
                Select a Student
              </InputLabel>
              <Select
                id="select-student"
                value={selectedStudent}
                fullwidth
                variant="outlined"
                IconComponent={KeyboardArrowDownIcon}
                onChange={e => handleSelectStudentFromDropdown(e.target.value)}
              >
                {studentsWithoutSelf.map(({ id, firstName, lastName }) => (
                  <MenuItem key={id} value={id}>
                    {`${firstName} ${lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {showAddStudent && (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{ marginBottom: '64px', marginTop: '48px' }}
              onClick={() => setOpenAddStudent(!openAddStudent)}
            >
              Add a New Student
            </Button>
          )}
        </>
      )}
    </Grid>
  )
}
