import React from 'react'
import { gql, useMutation } from '@apollo/client'

import { useRouteMatch, Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Box, Tooltip, Typography } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import { useSnackbar } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import ActionsMenu from 'components/actions-menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { useIsMobile } from 'lib/hooks'
import DeleteFacilityDialog from 'pages/pga-coach/facilities/delete-facility-dialog'

const TOGGLE_COACH_PROFILE_FACILITY_VISIBILITY = gql`
  mutation toggleCoachProfileFacilityVisibility(
    $params: ToggleCoachProfileFacilityVisibilityInput!
  ) {
    toggleCoachProfileFacilityVisibility(params: $params) {
      message
      success
    }
  }
`

const SET_PRIMARY_COACH_PROFILE_FACILITY = gql`
  mutation setPrimaryCoachProfileFacility($facilityId: ID!) {
    setPrimaryCoachProfileFacility(facilityId: $facilityId) {
      message
      success
    }
  }
`

const useStyles = makeStyles(theme => ({
  facilityWrapper: {
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(
        2,
      )}px  ${theme.spacing(2)}px`,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  facilityTitle: {
    fontWeight: '500',
    fontSize: '16px',
  },
  facilityTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 'auto',
  },
  facilityIcons: {
    paddingLeft: theme.spacing(3),
  },
  facilityIcon: {
    marginRight: theme.spacing(2),
    display: 'inline',
  },
  togglePointer: {
    cursor: 'pointer',
  },
  facilityArrow: {
    float: 'right',
  },
  facilityIconButton: {
    backgroundColor: '#F4F6F8',
    marginRight: theme.spacing(2),
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  chip: {
    backgroundColor: '#51657F',
    '& svg': {
      backgroundColor: 'inherit !important',
    },
  },
}))

const FacilityListItem = ({ facility, index, hasCoachProfile, refetch }) => {
  const styles = useStyles()
  const { path } = useRouteMatch()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useIsMobile()
  const history = useHistory()

  const isPrimary = facility.coachProfilesFacilities[0]?.facilityRank === 1
  const isVisible = facility.coachProfilesFacilities[0]?.visible

  const [updateVisibility] = useMutation(
    TOGGLE_COACH_PROFILE_FACILITY_VISIBILITY,
  )
  const [updatePrimary] = useMutation(SET_PRIMARY_COACH_PROFILE_FACILITY)

  const handleVisibilityClick = isVisible => {
    updateVisibility({
      variables: {
        params: {
          facilityId: facility.id,
          visible: isVisible,
        },
      },
    })
      .then(data => {
        if (!data?.data?.toggleCoachProfileFacilityVisibility?.success) {
          enqueueSnackbar(
            data?.toggleCoachProfileFacilityVisibility?.message ||
              'Something went wrong',
            {
              variant: 'error',
            },
          )
        } else {
          refetch()
          enqueueSnackbar('Successfully updated visibility', {
            variant: 'success',
            preventDuplicate: true,
          })
        }
      })
      .catch(err => {
        enqueueSnackbar(err?.message || 'Something went wrong', {
          variant: 'error',
        })
      })
  }

  const handleSetPrimaryClick = () => {
    updatePrimary({
      variables: {
        facilityId: facility.id,
      },
    })
      .then(data => {
        if (!data?.data?.setPrimaryCoachProfileFacility?.success) {
          enqueueSnackbar(
            data?.setPrimaryCoachProfileFacility?.message ||
              'Something went wrong',
            {
              variant: 'error',
            },
          )
        } else {
          if (!isVisible) {
            handleVisibilityClick(true)
          }
          refetch()
          enqueueSnackbar('Successfully updated primary facility', {
            variant: 'success',
          })
        }
      })
      .catch(err => {
        enqueueSnackbar(err?.message || 'Something went wrong', {
          variant: 'error',
        })
      })
  }

  const visibilityToggles = () => {
    return hasCoachProfile ? (
      isVisible ? (
        <Tooltip title="Visible on Coach Profile" placement="bottom">
          <IconButton
            classes={{ root: styles.facilityIconButton }}
            color="primary"
          >
            <VisibilityOutlinedIcon
              className={styles.togglePointer}
              onClick={e => {
                e.stopPropagation()
                !isPrimary && handleVisibilityClick(false)
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Hidden on Coach Profile" placement="bottom">
          <IconButton
            classes={{ root: styles.facilityIconButton }}
            color="default"
          >
            <VisibilityOffOutlinedIcon
              className={styles.togglePointer}
              onClick={e => {
                e.stopPropagation()
                !isPrimary && handleVisibilityClick(true)
              }}
            />
          </IconButton>
        </Tooltip>
      )
    ) : null
  }

  return (
    <Box
      className={styles.facilityWrapper}
      key={index}
      onClick={() => history.push(`${path}/${facility.id}`)}
      data-cy="facilityShowLink"
    >
      <Box className={styles.facilityTitleWrapper}>
        <Box display="flex" alignItems="center">
          <Box className={`${styles.facilityIcon} ${styles.togglePointer}`}>
            {visibilityToggles}
          </Box>
          <Box>
            <Typography className={styles.facilityTitle}>
              {facility.name}
            </Typography>
            {isPrimary && isMobile && (
              <Tooltip
                title="The primary facility cannot be hidden or deleted."
                placement="bottom"
              >
                <Chip
                  color="primary"
                  size="small"
                  className={styles.chip}
                  avatar={<StarBorderIcon />}
                  label="Primary"
                  style={{ marginTop: '4px' }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
        {isPrimary && !isMobile && (
          <Tooltip
            title="The primary facility cannot be hidden or deleted."
            placement="bottom"
          >
            <Chip
              color="primary"
              className={styles.chip}
              avatar={<StarBorderIcon />}
              label="Primary"
            />
          </Tooltip>
        )}
      </Box>
      <Box>
        <ActionsMenu ariaId={`facility-${index}`}>
          <MenuItem
            component={Link}
            className={
              facility.externalFacilityId && (!hasCoachProfile || isPrimary)
                ? styles.disabled
                : null
            }
            to={{
              pathname: `${path}/${facility.id}/edit`,
              state: { facility: facility },
            }}
          >
            <ListItemIcon>
              <EditOutlinedIcon className={styles.icon} />
            </ListItemIcon>
            <ListItemText primary="Edit Details" />
          </MenuItem>
          {hasCoachProfile && (
            <MenuItem
              className={isPrimary ? styles.disabled : null}
              onClick={() => handleVisibilityClick(!isVisible)}
            >
              <ListItemIcon>
                {isVisible ? (
                  <VisibilityOffOutlinedIcon className={styles.icon} />
                ) : (
                  <VisibilityOutlinedIcon className={styles.icon} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={isVisible ? 'Hide on Profile' : 'Show on Profile'}
              />
            </MenuItem>
          )}
          {!isPrimary && hasCoachProfile ? (
            <MenuItem
              onClick={() => {
                handleSetPrimaryClick()
              }}
            >
              <ListItemIcon>
                <StarBorderOutlinedIcon className={styles.icon} />
              </ListItemIcon>
              <ListItemText primary="Set as Primary" />
            </MenuItem>
          ) : null}
          <MenuItem className={isPrimary ? styles.disabled : null}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon className={styles.icon} />
            </ListItemIcon>

            <DeleteFacilityDialog
              facilityId={facility.id}
              onSuccess={() => history.push('/pga-coach/facilities')}
              trigger={<ListItemText primary="Delete Facility" />}
            />
          </MenuItem>
        </ActionsMenu>
      </Box>
    </Box>
  )
}

export default FacilityListItem
