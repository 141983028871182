export const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Non-binary', value: 'N' },
  { label: 'Self describe', value: 'SD' },
]
export const pronounOptions = [
  { label: 'He/Him/His', value: 'HE' },
  { label: 'She/Her/Hers', value: 'SHE' },
  { label: 'They/Them/Theirs', value: 'THEY' },
]
