import React from 'react'

import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import SportsGolfIcon from '@material-ui/icons/SportsGolf'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '16px',
    margin: '20px 0',
    cursor: 'pointer',
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  sessionIcon: {
    marginRight: '20px',
  },
  sessionInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default ({ lesson, setOpenLesson }) => {
  const classes = useStyles()
  const { lessonDate, lessonTimePeriod, lessonCaption } = lesson

  return (
    <Card
      onClick={() => setOpenLesson(lesson)}
      className={classes.root}
      variant="outlined"
    >
      <div className={classes.cardWrapper}>
        <Avatar className={classes.sessionIcon}>
          <SportsGolfIcon fontSize="medium" />
        </Avatar>
        <div className={classes.sessionInfo}>
          <Typography variant="h6">{lessonDate}</Typography>
          <Typography variant="caption">{lessonTimePeriod}</Typography>
          <Typography variant="caption">{lessonCaption}</Typography>
        </div>
      </div>
      <ArrowForwardIosIcon />
    </Card>
  )
}
