import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { topLeftMenu, topRightMenu, urls } from './menus'
import MenuArrowSvg from 'images/icons/fc-marketing/menu-arrow.svg'
import PadlockSvg from 'images/icons/fc-marketing/padlock.svg'
import Popover from '@material-ui/core/Popover'
import MenuIcon from '@material-ui/icons/Menu'
import useIsMobile from 'components/program-marketing/useIsMobile'
import FGlogo from 'images/family-golf-logo.svg'

const useStyles = makeStyles({
  menuPopover: {
    padding: '12px',
    borderRadius: '10px',
  },
  logo: {
    maxWidth: 130,
    padding: '14px 0px 6px',
  },
  flex: {
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
  },
  menuItem: {
    marginLeft: '12px',
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
  },
  menuLink: {
    color: '#222222',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuSubLink: {
    fontSize: '16px',
    fontWeight: 700,
    padding: '8px 12px',
    color: '#222222',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#f9f5ec',
      color: '#ff7500',
    },
  },
  popover: {
    width: '100%',
  },
  textButton: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
})

const MenuDropDown = ({ anchorEl, open, handleClose, items }) => (
  <Menu
    anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={handleClose}
    PaperProps={{
      style: { padding: '12px', borderRadius: '10px', minWidth: '220px' },
    }}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
  >
    {items.map(sublink => (
      <div key={sublink.text}>
        <MenuSubLink
          text={sublink.text}
          href={sublink.url}
          handleClose={handleClose}
        />
      </div>
    ))}
  </Menu>
)

const MenuLink = ({
  anchorEl,
  open,
  href,
  text,
  subMenu,
  onClick,
  handleClose,
}) => {
  const classes = useStyles()
  return (
    <>
      <Link href={href} className={classes.menuLink}>
        <Typography className={classes.menuItem} onClick={onClick || undefined}>
          {text}
          {onClick && (
            <img
              alt="menu-arrow"
              src={MenuArrowSvg}
              style={{ fontSize: '16px', marginLeft: '6px' }}
            />
          )}
        </Typography>
      </Link>
      <MenuDropDown
        items={subMenu}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  )
}

const MenuSubLink = ({ handleClose, text, href }) => {
  const classes = useStyles()
  return (
    <MenuItem
      href={href}
      className={classes.menuSubLink}
      onClick={handleClose}
      component={Link}
    >
      {text}
    </MenuItem>
  )
}

const MobileMenu = ({ menu }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState('')
  const handleClick = text => setOpen(prev => (text === prev ? '' : text))
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {menu.map(({ text, url, subMenu }) => (
        <>
          <ListItem
            className={classes.menuLink}
            component={Link}
            button
            href={url}
            onClick={() => handleClick(text)}
          >
            <ListItemText
              primary={
                <span>
                  {text}{' '}
                  {subMenu && (
                    <img
                      alt="menu-arrow"
                      src={MenuArrowSvg}
                      style={{ fontSize: '16px', marginLeft: '6px' }}
                    />
                  )}
                </span>
              }
              className={classes.menuItem}
            />
          </ListItem>
          {subMenu &&
            subMenu.map(subLink => (
              <Collapse in={open === text} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    button
                    href={subLink.url}
                    className={classes.menuLink}
                  >
                    <ListItemText
                      className={classes.menuItem}
                      primary={subLink.text}
                    />
                  </ListItem>
                </List>
              </Collapse>
            ))}
        </>
      ))}
    </List>
  )
}

const Header = () => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const [menu, setMenu] = React.useState({})

  const handleClick = name => ({ currentTarget }) =>
    setMenu({ name, anchorEl: currentTarget })
  const handleClose = () => setMenu({})

  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null)

  const handleMobileMenuClick = event => {
    setMobileMenuAnchor(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null)
  }

  const open = Boolean(mobileMenuAnchor)
  const id = open ? 'simple-popover' : undefined

  return (
    <AppBar position="fixed" color="inherit" style={{ boxShadow: 'none' }}>
      <Toolbar>
        <Container maxWidth="lg">
          <Grid container direction="row" justify="center" width="md">
            <Grid
              item
              sm={12}
              md={12}
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Grid item className={classes.flex}>
                <Grid item>
                  <Box mr="36px">
                    <a href={urls.home}>
                      <img
                        src={FGlogo}
                        alt={'Family Golf Logo'}
                        className={classes.logo}
                      />
                    </a>
                  </Box>
                </Grid>

                {!isMobile &&
                  topLeftMenu.map(({ text, url, subMenu = [] }) => (
                    <Grid item key={text}>
                      <MenuLink
                        text={text}
                        href={url}
                        subMenu={subMenu}
                        open={menu.name === text}
                        anchorEl={menu.anchorEl}
                        onClick={subMenu.length > 0 && handleClick(text)}
                        handleClose={handleClose}
                      />
                    </Grid>
                  ))}
              </Grid>
              {!isMobile && (
                <Grid item className={classes.flex}>
                  <Grid item>
                    <Button
                      href={urls.search}
                      component={Link}
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      Find Events
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.textButton}
                      variant="text"
                      onClick={handleClick('Login')}
                    >
                      <img
                        src={PadlockSvg}
                        style={{ maxHeight: '20px', marginRight: '4px' }}
                        alt="login"
                      />
                      Login
                    </Button>
                    <MenuDropDown
                      items={topRightMenu}
                      open={menu.name === 'Login'}
                      anchorEl={menu.anchorEl}
                      handleClose={handleClose}
                    />
                  </Grid>
                </Grid>
              )}
              {isMobile && (
                <Grid item>
                  <MenuIcon onClick={handleMobileMenuClick} />
                  <Popover
                    className={classes.popover}
                    id={id}
                    open={open}
                    anchorEl={mobileMenuAnchor}
                    onClose={handleMobileMenuClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: { width: '100%' },
                    }}
                  >
                    <MobileMenu menu={topLeftMenu} />
                  </Popover>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <div id="top" />
    </AppBar>
  )
}

export default Header
