import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import { Grid, TextField } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useForm } from 'react-hook-form'
import CustomLuxonUtils from 'lib/CustomLuxonUtils'
import { useStyles } from './styles'
import TileButtons from './tile-buttons'
import { CREATE_STUDENT } from './mutation'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  studentFirstName: yup
    .string()
    .min(1)
    .required(),
  studentLastName: yup
    .string()
    .min(1)
    .required(),
})

const ConsumerAddStudentModal = ({
  open,
  setOpen,
  onAdd,
  dialogTitle = 'Create Student',
  btnTitle = 'Create Student',
  initialValues = {},
}) => {
  const [createStudent] = useMutation(CREATE_STUDENT)

  const [isAdult, setIsAdult] = useState(true)

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      studentFirstName: initialValues?.studentFirstName || '',
      studentLastName: initialValues?.studentLastName || '',
    },
    resolver: yupResolver(validationSchema),
  })

  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const classes = useStyles({ isAdult })

  const onSubmit = async formValues => {
    formValues.isMinor = !isAdult
    try {
      const response = await createStudent({
        variables: { params: formValues },
      })
      enqueueSnackbar('Student Created', { variant: 'success' })
      setOpen(false)
      onAdd && onAdd(response?.data)
    } catch (e) {
      enqueueSnackbar('Sorry, an error occurred', { variant: 'error' })
      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      scroll="paper"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
          <DialogContent dividers className={classes.root}>
            <TileButtons {...{ isAdult, setIsAdult }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="studentFirstName"
                  margin="normal"
                  inputRef={register}
                  style={{ marginRight: '10px' }}
                  fullWidth
                  variant="outlined"
                  label="First name *"
                  error={!!errors.studentFirstName}
                  helperText={
                    errors.studentFirstName && 'This field is required'
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="studentLastName"
                  margin="normal"
                  inputRef={register}
                  style={{ marginRight: '10px' }}
                  fullWidth
                  variant="outlined"
                  label="Last name *"
                  error={!!errors.studentLastName}
                  helperText={
                    errors.studentLastName && 'This field is required'
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {btnTitle}
            </Button>
          </DialogActions>
        </MuiPickersUtilsProvider>
      </form>
    </Dialog>
  )
}

ConsumerAddStudentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  dialogTitle: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    studentFirstName: PropTypes.string,
    studentLastName: PropTypes.string,
  }),
}

export default ConsumerAddStudentModal
