import { useHistory, useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { markdownToDraft } from 'markdown-draft-js'
import { useWizardFormContext } from 'components/wizard-form/context'

const STASHED_EVENT_FORM_VALUES = 'STASHED_EVENT_FORM_VALUES'

const stashEventFormValues = event => {
  window.sessionStorage.setItem(
    STASHED_EVENT_FORM_VALUES,
    JSON.stringify(event),
  )
}

const popEventFormValues = () => {
  const event = JSON.parse(
    window.sessionStorage.getItem(STASHED_EVENT_FORM_VALUES),
  )
  window.sessionStorage.removeItem(STASHED_EVENT_FORM_VALUES)
  return event
}

const useLoadGroupEventFormValues = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const hydrateEventFormValues = useHydrateEventFormValues()

  const isOnCreateEventPage = pathname === '/pga-coach/bookings/events/new'
  const isOnOnboardingCreateEventPage =
    pathname === '/pga-coach/onboarding/bookings/events/new'

  const loadGroupEventFormValues = event => {
    stashEventFormValues(event)
    if (isOnCreateEventPage || isOnOnboardingCreateEventPage) {
      hydrateEventFormValues()
    } else {
      history.push('/pga-coach/bookings/events/new?preload')
    }
  }

  return loadGroupEventFormValues
}

const useHydrateEventFormValues = () => {
  const form = useFormContext()
  const wizardForm = useWizardFormContext()

  const hydrateEventFormValues = () => {
    const event = popEventFormValues()
    if (event) {
      form.setValue('title', event.title)
      form.setValue(
        'description',
        JSON.stringify(markdownToDraft(event.description)),
      )
      form.setValue('academyRequired', event.academyRequired)
      form.setValue('tags', event.tags)
      form.setValue('locationId', event.coachFacility?.id)
      form.setValue('featuredImageUrl', event.featuredImageUrl)
      form.setValue('price', event.priceInCents / 100)
      form.setValue('collectAttendance', event.collectAttendance)
      wizardForm.advance()
    }
  }

  return hydrateEventFormValues
}

export { useLoadGroupEventFormValues, useHydrateEventFormValues }
