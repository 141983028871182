import React from 'react'
import { useAuth } from 'lib/auth'
import { DateTime } from 'luxon'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { gtmEvent } from 'lib/gtm'
import EditAvailabilityFormDialog from './edit-availability-form-dialog'
import { daysOfWeek } from './constants'
import useStyles from './edit-availability-display.styles'

const aggregateByDay = timeSlots => {
  const initialValue = daysOfWeek.reduce((acc, d) => {
    acc[d] = []
    return acc
  }, {})
  return (timeSlots || []).reduce((acc, ts) => {
    acc[ts.day].push(ts)
    return acc
  }, initialValue)
}

const EditAvailabilityDisplayLoading = () => {
  const classes = useStyles()
  return (
    <Card variant="outlined" className={classes.card}>
      <Box p={2}>
        <LinearProgress />
      </Box>
    </Card>
  )
}

const EditAvailabilityDisplay = ({
  availability,
  refetch,
  refetchCoachOnboardingStatus,
}) => {
  const classes = useStyles()
  const availabilityByDay = aggregateByDay(availability.timeSlots)
  const availabilityTimeZone =
    availability.timeZone || DateTime.local().zone.name
  const { user } = useAuth()

  const onAvailabilityEditSuccess = () => {
    if (user.onboarding) {
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'bookings',
        formAction: 'edit-onboarding-availability',
        formStatus: 'success',
      })
    } else {
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'bookings',
        formAction: 'edit-availability',
        formStatus: 'success',
      })
    }
    refetch()
    refetchCoachOnboardingStatus && refetchCoachOnboardingStatus()
  }

  return (
    <>
      <Card variant="outlined" className={classes.card}>
        <CardContent className={classes.content}>
          <Box display="flex" className={classes.daysContainer}>
            {daysOfWeek.map(day => {
              const timeSlots = availabilityByDay[day]
              return (
                <div key={day} className={classes.dayContainer}>
                  <>
                    <Box
                      p={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.dayHeader}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {day.toLowerCase()}
                      </Typography>
                      <EditAvailabilityFormDialog
                        trigger={
                          <Button data-cy={day} color="primary" size="small">
                            Edit
                          </Button>
                        }
                        initialSelectedDay={day}
                        initialTimeSlots={availability.timeSlots}
                        coachFacilityId={availability.coachFacility?.id}
                        facilityTimezone={availabilityTimeZone}
                        onSuccess={onAvailabilityEditSuccess}
                      />
                    </Box>
                    <Box p={2}>
                      {timeSlots.length > 0 &&
                        timeSlots.map(ts => {
                          const startTime = DateTime.fromISO(
                            ts.startTime,
                          ).toFormat('h:mma')
                          const endTime = DateTime.fromISO(ts.endTime).toFormat(
                            'h:mma',
                          )
                          const formattedTimeSlot = `${startTime} - ${endTime}`
                          return (
                            <Typography
                              key={formattedTimeSlot}
                              variant="body2"
                              className={classes.availabilityLabel}
                            >
                              {formattedTimeSlot}
                            </Typography>
                          )
                        })}
                      {timeSlots.length === 0 && (
                        <Typography
                          variant="body2"
                          className={classes.availabilityLabel}
                        >
                          Unavailable
                        </Typography>
                      )}
                    </Box>
                  </>
                </div>
              )
            })}
          </Box>
        </CardContent>
      </Card>
      <Grid item xs={12}>
        <Box textAlign="right">
          <Typography variant="caption">
            Times shown in{' '}
            {DateTime.local().setZone(availabilityTimeZone).offsetNameShort} (
            {availabilityTimeZone})
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

export default EditAvailabilityDisplay
export { EditAvailabilityDisplayLoading }
