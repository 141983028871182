import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { isRecurringPayment } from 'utils/groupEventUtils'

const EventLocation = ({ event, coachFacilities }) => {
  const { errors, control } = useFormContext()
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Location
      </Typography>
      <Typography variant="body2" gutterBottom>
        Select the location where this event will be held.
      </Typography>
      <FormControl
        error={!!errors.locationId}
        variant="outlined"
        style={{ marginTop: '10px', maxWidth: '450px' }}
      >
        <InputLabel id="category">Select location</InputLabel>
        <Controller
          as={
            <Select
              labelId="location"
              label="Select location"
              value=""
              data-cy="location"
              readOnly={isRecurringPayment(event)}
            >
              {coachFacilities.map(opt => (
                <MenuItem key={opt.id} value={opt.id}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          }
          name="locationId"
          control={control}
        />
      </FormControl>
      {errors.locationId ? (
        <Typography variant="caption" color="error">
          Please select a location
        </Typography>
      ) : null}
    </>
  )
}

export default EventLocation
