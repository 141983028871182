import React, { Fragment } from 'react'
import { makeStyles, Box, LinearProgress, Button } from '@material-ui/core'
import { ReactComponent as InfoIcon } from '../../images/icons/info.svg'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  barContainer: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.drawer + 1,

    display: 'flex',
    flexDirection: 'column',

    backgroundColor: lighten(theme.palette.info.light, 0.9),
  },
  barContainerBottom: {
    position: 'sticky',
    top: 'auto',
    bottom: 0,
    right: 0,
    zIndex: '1000',
    width: '100%',
  },
  error: {
    backgroundColor: lighten(theme.palette.error.light, 0.9),
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },

  informationContainer: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    color: theme.palette.info.main,
    marginRight: '12px',
  },
  actionContainer: {
    display: 'flex',
  },
}))

export default function ActionBar({
  children,
  instructions,
  loading,
  error,
  includeCancel,
  disableCancel,
  onCancel,
  stickToBottom,
  cancelLabel = 'Cancel',
}) {
  const history = useHistory()
  const classes = useStyles()
  const handleCancel = onCancel || (() => history.goBack())

  return (
    <>
      <Box
        className={clsx(classes.barContainer, {
          [classes.error]: error,
          [classes.barContainerBottom]: stickToBottom,
        })}
      >
        <Box className={classes.bar}>
          <Box className={classes.informationContainer}>
            {instructions && (
              <Fragment>
                <InfoIcon className={classes.infoIcon} />
                {instructions}
              </Fragment>
            )}
          </Box>
          <Box className={classes.actionContainer}>
            {includeCancel && (
              <Button
                color="primary"
                disabled={disableCancel}
                onClick={handleCancel}
              >
                {cancelLabel}
              </Button>
            )}

            {children && <Box ml={2}>{children}</Box>}
          </Box>
        </Box>

        {loading && (
          <Box width="100%">
            <LinearProgress color="secondary" />
          </Box>
        )}
      </Box>
    </>
  )
}
