import React from 'react'
import { styled } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { useAuth } from 'lib/auth'
import { CREATE_STUDENT } from 'components/add-student-modal/mutation'
import { gtmEvent } from 'lib/gtm'

const TileButton = styled('div')(({ theme, selected }) => ({
  width: '160px',
  height: '72px',
  border: '1px solid #00234B80',
  borderRadius: '4px',
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: selected ? 'rgba(0, 35, 75, 0.08)' : 'white',
  '& + div': {
    marginLeft: '16px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& span': {
    fontWeight: 700,
    fontFamily: 'Roboto Condensed',
    letterSpacing: '1.5px',
  },
}))

const TileButtonWrapper = styled('div')(() => ({
  display: 'flex',
  marginTop: '24px',
  marginBottom: '48px',
}))

const CheckIcon = styled(CheckCircleIcon)(() => ({
  position: 'absolute',
  bottom: '4px',
  left: '4px',
}))

export default ({
  isSelf,
  setIsSelf,
  isSomeoneElse,
  setIsSomeoneElse,
  setSelectedStudent,
  selfStudentId,
  history,
  currentUrl,
  eventObject,
}) => {
  const [createStudent] = useMutation(CREATE_STUDENT)
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()

  const createStudentForSelf = async () => {
    try {
      const { data } = await createStudent({
        variables: {
          params: {
            isMinor: false,
            studentFirstName: user?.firstName,
            studentLastName: user?.lastName,
          },
        },
      })
      return data
    } catch (e) {
      enqueueSnackbar('Sorry, an error occurred. Please try again', {
        variant: 'error',
      })
      return e
    }
  }
  const handleClick = isSelfTab => {
    setIsSelf(isSelfTab)
    setIsSomeoneElse(!isSelfTab)
    setSelectedStudent(isSelfTab ? selfStudentId : null)

    isSelfTab &&
      gtmEvent({
        ...eventObject,
        event: 'select-student-to-schedule',
      })
    // Remove the below event in the future.
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'selected-student',
    })

    let urlForUpdate = currentUrl
    if (currentUrl.includes('studentId')) {
      urlForUpdate = urlForUpdate.replace(/&studentId=\d+/, '')
    }

    if (isSelfTab && !selfStudentId) {
      createStudentForSelf().then(response => {
        if (response?.createStudent?.success) {
          const newStudentId = response?.createStudent?.student?.id
          history.push(`${urlForUpdate}&studentId=${newStudentId}`)
        }
      })
    } else if (isSelfTab) {
      history.push(`${urlForUpdate}&studentId=${selfStudentId}`)
    }
  }
  return (
    <TileButtonWrapper>
      <TileButton selected={isSelf} onClick={() => handleClick(true)}>
        <Typography variant="button" color="primary">
          Myself
        </Typography>
        <CheckIcon color={isSelf ? 'primary' : 'disabled'} />
      </TileButton>
      <TileButton selected={isSomeoneElse} onClick={() => handleClick(false)}>
        <Typography variant="button" color="primary">
          Someone Else
        </Typography>
        <CheckIcon color={!isSomeoneElse ? 'disabled' : 'primary'} />
      </TileButton>
    </TileButtonWrapper>
  )
}
