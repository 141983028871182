import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import { Box, Grid, TextField, Typography } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useForm } from 'react-hook-form'
import CustomLuxonUtils from 'lib/CustomLuxonUtils'
import { useStyles } from './styles'
import TileButtons from './tile-buttons'
import { CREATE_STUDENT } from './mutation'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useIsMobile } from 'lib/hooks'

const validationSchema = yup.object().shape({
  studentFirstName: yup
    .string()
    .min(1)
    .required(),
  studentLastName: yup
    .string()
    .min(1)
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  phoneNumber: yup.string(),
  guardianFirstName: yup.string().min(1),
  guardianLastName: yup.string().min(1),
})

const formatPhoneNumber = num => {
  if (num) {
    const cleanedNum = num.replace(/\D/g, '')
    return `${cleanedNum}`
  }
  return null
}

const CoachAddStudentModal = ({
  open,
  setOpen,
  onAdd,
  dialogTitle = 'Create Student',
  btnTitle = 'Create Student',
  initialValues = {},
  leadId,
}) => {
  const [createStudent] = useMutation(CREATE_STUDENT)
  const [isAdult, setIsAdult] = useState(true)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      studentFirstName: initialValues?.studentFirstName || '',
      studentLastName: initialValues?.studentLastName || '',
      email: initialValues?.email || '',
      phoneNumber: initialValues?.phoneNumber || '',
    },
    resolver: yupResolver(validationSchema),
  })

  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useIsMobile()

  const classes = useStyles({ isAdult })

  const onSubmit = async formValues => {
    formValues.isMinor = !isAdult
    formValues.phoneNumber = formatPhoneNumber(formValues.phoneNumber)
    formValues.coachingInquiryId = leadId
    try {
      const response = await createStudent({
        variables: { params: formValues },
      })
      enqueueSnackbar('Student Created', {
        variant: 'success',
      })
      setOpen(false)
      onAdd && onAdd(response?.data)
    } catch (e) {
      enqueueSnackbar('Sorry, an error occurred', {
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      scroll="paper"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
          <DialogContent dividers className={classes.root}>
            <TileButtons {...{ isAdult, setIsAdult }} />
            {!isAdult && (
              <Typography variant="button">Student Information</Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="studentFirstName"
                  margin="normal"
                  inputRef={register}
                  style={{ marginRight: '10px' }}
                  fullWidth
                  variant="outlined"
                  label="First name *"
                  error={!!errors.studentFirstName}
                  helperText={
                    errors.studentFirstName && 'This field is required'
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="studentLastName"
                  margin="normal"
                  inputRef={register}
                  style={{ marginRight: '10px' }}
                  fullWidth
                  variant="outlined"
                  label="Last name *"
                  error={!!errors.studentLastName}
                  helperText={
                    errors.studentLastName && 'This field is required'
                  }
                />
              </Grid>
            </Grid>
            {!isAdult && (
              <Box container>
                <Typography variant="button" style={{ marginTop: '20px' }}>
                  Guardian Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      name="guardianFirstName"
                      inputRef={register}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="First name *"
                      error={!!errors.guardianFirstName}
                      helperText={
                        errors.guardianFirstName && 'This field is required'
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="guardianLastName"
                      inputRef={register}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Last name *"
                      error={!!errors.guardianLastName}
                      helperText={
                        errors.guardianLastName && 'This field is required'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  inputRef={register}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Email *"
                  error={!!errors.email}
                  helperText={errors.email && 'This field is required'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phoneNumber"
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onBlur={e => {
                    e.target.value = formatPhoneNumber(e.target.value)
                  }}
                  label="Phone"
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber && 'This field is required'}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {btnTitle}
            </Button>
          </DialogActions>
        </MuiPickersUtilsProvider>
      </form>
    </Dialog>
  )
}

CoachAddStudentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  dialogTitle: PropTypes.string,
  btnTitle: PropTypes.string,
  initialValues: PropTypes.shape({
    studentFirstName: PropTypes.string,
    studentLastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  leadId: PropTypes.string,
}

export default CoachAddStudentModal
