import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import themes, { extendTheme } from 'themes'
import { withProtectedRoute, roles } from 'lib/auth'
import LinearProgress from '@material-ui/core/LinearProgress'
import CoachAppbar from 'components/coach-app-bar'
import CreateGroupEventFormWizard from 'components/group-events/create-group-event-form-wizard'
import { allFacilities } from 'components/group-events/create-group-event-form-wizard/utils'
import Error from 'components/error'

const GET_COACH_FACILITIES_AND_PAYMENT_ACCOUNTS = gql`
  query GetCoachFacilitiesAndPaymentAccounts {
    currentUser {
      attributes {
        ... on Coach {
          coachFacilities {
            id
            name
            timezone
          }
          academies {
            id
            name
            slug
            facilities {
              id
              name
              timezone
            }
            coaches {
              id
              name
            }
          }
          paymentAccounts {
            id
            name
            last4
          }
        }
      }
    }
  }
`

/*
This is used as work-around related to Intercom Product Tours
More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
*/
const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

export const CreateEvent = () => {
  const { data, error, loading } = useQuery(
    GET_COACH_FACILITIES_AND_PAYMENT_ACCOUNTS,
  )

  if (loading) return <LinearProgress color="primary" />

  if (error) return <Error error={error} />

  const {
    coachFacilities,
    paymentAccounts,
    academies,
  } = data?.currentUser.attributes

  const coachFacilityLocations = allFacilities({
    coachFacilities: coachFacilities,
    academies: academies,
  })
  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach</title>
        <meta name="description" content="PGA Coach | Create Event" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <CoachAppbar position="relative" />
        <CreateGroupEventFormWizard
          coachFacilities={coachFacilities}
          paymentAccounts={paymentAccounts}
          coachAcademies={academies}
          coachFacilityLocations={coachFacilityLocations}
          showOnboardingModal={true}
          backUrl="/pga-coach"
        />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(CreateEvent, roles.COACH)
