import { useRouteMatch, useHistory } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useAuth } from 'lib/auth'
import { gtmEvent } from 'lib/gtm'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PageHeader } from 'components/page-header'

import FacilitiesForm from 'components/facilities/form'

const CREATE_FACILITY = gql`
  mutation FacilityCreate(
    $facility: FacilityInputAttributes!
    $visible: Boolean
    $primary: Boolean
    $offersCoachingAtPrivateClub: Boolean
  ) {
    facilityCreate(
      facility: $facility
      visible: $visible
      primary: $primary
      offersCoachingAtPrivateClub: $offersCoachingAtPrivateClub
    ) {
      message
      success
    }
  }
`

const GET_FACILITIES = gql`
  query CoachFacilities {
    currentUser {
      attributes {
        ... on Coach {
          facilities {
            id
          }
        }
      }
    }
  }
`

const FacilitiesNew = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [createFacility, { loading }] = useMutation(CREATE_FACILITY)
  const { user } = useAuth()
  const hasCoachProfile = user?.coach?.coachProfile
  const { data, loading: facilitiesLoading } = useQuery(GET_FACILITIES)
  const { path } = useRouteMatch()
  const crumbs = [
    {
      label: 'Facilities',
      to: path
        .split('/')
        .slice(0, 4)
        .join('/'),
    },
  ]
  crumbs.splice(1, 0, {
    label: 'Settings',
    to: path
      .split('/')
      .slice(0, 3)
      .join('/'),
  })

  const onSubmit = async ({ facility }) => {
    try {
      await createFacility({
        variables: {
          facility: {
            name: facility.name,
            address1: facility.address1 || null,
            address2: facility.address2 || null,
            city: facility.city || null,
            state: facility.state || null,
            zip: facility.zip || null,
            timezone: !facility.externalFacilityId
              ? facility.timezone || null
              : null,
            phone: facility.phone || null,
            url: facility.url || null,
            externalFacilityId: facility.externalFacilityId || null,
            private: facility.private,
          },
          primary: facility.primary,
          visible:
            facility.visible === undefined ? null : facility.visible === 'true',
          offersCoachingAtPrivateClub: facility.offersCoachingAtPrivateClub,
        },
      })
      enqueueSnackbar('Facility Created', { variant: 'success' })

      if (user.onboarding) {
        gtmEvent({
          event: 'formSubmit',
          formCategory: 'facility',
          formAction: 'created-onboarding-facility',
        })
      }
      history.push(
        user.onboarding
          ? '/pga-coach/set-up-schedule'
          : '/pga-coach/facilities',
      )
    } catch (e) {
      enqueueSnackbar('There was an error when creating this facility', {
        variant: 'error',
      })
    }
  }

  if (facilitiesLoading) return <LinearProgress color="secondary" />

  const isFirstFacility =
    hasCoachProfile && data?.currentUser?.attributes?.facilities.length === 0

  return (
    <>
      <PageHeader title="Add Facility" />
      <FacilitiesForm
        preventExternalFacilityEdit={true}
        onSubmit={onSubmit}
        loading={loading}
        isFirstFacility={isFirstFacility}
      />
    </>
  )
}

export default FacilitiesNew
