// Dynamically sets the height according to the `innerHeight` of the document.
// This fix is for mobile web, where different browsers have different
// navbars of varying heights. This way, components at the bottom of the screen
// won't be hidden or overflow, etc.

const useMobileHeight = () => {
  const documentHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', documentHeight)

  return `${window.innerHeight}px`
}

export default useMobileHeight
