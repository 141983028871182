import { React, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TableRow from '@material-ui/core/TableRow'
import { Menu, MenuItem, Box, Avatar } from '@material-ui/core'
import DeleteLeadDialog from 'components/delete-lead-dialog'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { makeStyles } from '@material-ui/styles'
import TableCell from '@material-ui/core/TableCell'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CoachAddStudentModal from 'components/add-student-modal/coach-add-student-modal'
import { useIsTablet } from 'lib/hooks'
import { MoreHoriz } from '@material-ui/icons'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'

export const useStyles = makeStyles(theme => ({
  leadRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  leadDetailsLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  leadText: {
    color: theme.palette.text.primary,
  },
  unviewedLeadText: {
    fontWeight: 'normal',
  },
  leadNameCell: {
    borderTop: '1px solid #EEEEEE !important',
    borderLeft: '1px solid #EEEEEE !important',
    borderBottom: '1px solid #EEEEEE !important',
    borderRadius: '8px 0 0 8px!important',
    paddingLeft: '1.5rem',
  },
  row: {
    cursor: 'pointer',
  },
  info: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  studentAvatar: {
    height: '36px',
    width: '36px',
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  actions: {
    textAlign: 'right',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    color: theme.palette.grey[600],
    fontSize: '1.25rem',
  },
  badge: {
    left: '-0.75rem',
    top: '50%',
    backgroundColor: theme.palette.blue.badge,
  },
}))

export const LeadTableItem = ({ lead, refetch, studentRefetch }) => {
  const classes = useStyles()
  const isTablet = useIsTablet()
  const history = useHistory()

  const { contact } = lead

  const [anchorEl, setAnchorEl] = useState(null)

  const [
    isConvertLeadToStudentModalOpen,
    setIsConvertLeadToStudentModalOpen,
  ] = useState(false)

  const [isDeleteLeadDialogOpen, setIsDeleteLeadDialogOpen] = useState(false)

  const handleMoreVertClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const convertLeadToStudentButton = event => {
    event.stopPropagation()
    setIsConvertLeadToStudentModalOpen(true)
  }

  const openDeleteLeadDialog = event => {
    event.stopPropagation()
    setIsDeleteLeadDialogOpen(true)
  }

  const refetchLeadsAndStudents = () => {
    refetch()
    studentRefetch()
  }

  const styleLeads = () => {
    let str = classes.leadText
    if (!lead.viewedAt) {
      str += ` ${classes.unviewedLeadText}`
    }
    return str
  }

  return (
    <>
      <TableRow
        className={classes.row}
        onClick={() => history.push(`/pga-coach/leads/${lead.id}`)}
      >
        <TableCell className={classes.info}>
          <List disablePadding={true}>
            <Link
              to={`/pga-coach/leads/${lead.id}`}
              className={`${classes.leadDetailsLink} ${styleLeads()}`}
            >
              <ListItem>
                <Badge
                  variant="dot"
                  badgeContent={!lead.viewedAt ? ' ' : 0}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes={{ badge: classes.badge }}
                >
                  <ListItemIcon>
                    <Avatar
                      variant="circular"
                      className={classes.studentAvatar}
                    >
                      {contact.firstName[0]}
                      {contact.lastName[0]}
                    </Avatar>
                  </ListItemIcon>
                </Badge>
                <Box pl={2}>
                  <ListItemText>
                    <Link
                      to={`/pga-coach/leads/${lead.id}`}
                      className={`${classes.leadDetailsLink} ${styleLeads()}`}
                    >
                      {contact.firstName} {contact.lastName}
                    </Link>
                  </ListItemText>
                </Box>
              </ListItem>
            </Link>
          </List>
        </TableCell>
        {!isTablet && (
          <TableCell className={styleLeads()}>
            {lead.intent.replace(/\s,/g, '').replace(/^,\s/g, '')}
          </TableCell>
        )}
        {!isTablet && (
          <TableCell className={styleLeads()}>
            {DateTime.fromISO(lead.createdAt).toLocaleString(DateTime.DATE_MED)}
          </TableCell>
        )}
        <TableCell className={classes.actions}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMoreVertClick}
            data-testid="lead-details-actions"
          >
            <MoreHoriz fontSize="small" />
          </Button>
        </TableCell>
      </TableRow>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          to={`/pga-coach/leads/${lead.id}`}
          className={classes.leadDetailsLink}
        >
          <MenuItem>
            <ListItemIcon>
              <PersonOutlineIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="View Lead Details" />
          </MenuItem>
        </Link>
        <MenuItem onClick={convertLeadToStudentButton}>
          <ListItemIcon>
            <PersonAddOutlinedIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary="Convert to Student" />
        </MenuItem>
        <SensitiveInfoWrapper>
          <MenuItem onClick={openDeleteLeadDialog} data-testid="delete-lead">
            <ListItemIcon>
              <DeleteIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Delete Lead" />
          </MenuItem>
        </SensitiveInfoWrapper>
      </Menu>
      <CoachAddStudentModal
        open={isConvertLeadToStudentModalOpen}
        setOpen={setIsConvertLeadToStudentModalOpen}
        onAdd={refetchLeadsAndStudents}
        initialValues={{
          studentFirstName: lead.firstName,
          studentLastName: lead.lastName,
          email: lead.contact?.email,
          phoneNumber: lead.contact?.phone,
        }}
        leadId={lead.id}
      />
      <DeleteLeadDialog
        lead={lead}
        open={isDeleteLeadDialogOpen}
        setOpen={setIsDeleteLeadDialogOpen}
      />
    </>
  )
}
