import { gql } from '@apollo/client'

const SET_COACH_AVAILABILITY_PER_FACILITY = gql`
  mutation SetCoachAvailabilityPerFacility(
    $input: SetCoachAvailabilityInput!
    $coachFacilityId: ID!
  ) {
    setCoachAvailabilityPerFacility(
      input: $input
      coachFacilityId: $coachFacilityId
    ) {
      success
      message
    }
  }
`

export default SET_COACH_AVAILABILITY_PER_FACILITY
