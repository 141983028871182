import React from 'react'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    height: 100,
    width: 100,
    color: theme.palette.info.light,

    borderRadius: '50%',
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  large: {
    height: 112,
    width: 112,
    borderRadius: '50%',
  },
}))

export default function IconContainer({ children, large }) {
  const classes = useStyles()

  let appliedClasses = classes.iconContainer
  if (large) {
    appliedClasses = `${classes.iconContainer} ${classes.large}`
  }
  return <Box className={appliedClasses}>{children}</Box>
}
