import { useState } from 'react'
import {
  TableRow,
  Typography,
  TableCell,
  Box,
  Avatar,
  Chip,
  Button,
} from '@material-ui/core'
import { useStyles } from './item-styles'
import { useAuth } from 'lib/auth'
import useIconAccessor from 'components/lesson-type-category/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SessionMenu from './session-menu'
import { useSessionDates } from '../utils'
import LinesEllipsis from 'react-lines-ellipsis'
import SessionDetailsDialog from 'components/session-details-dialog'
import { sessionChipStatus } from 'lib/sessions/utils'

export default function SessionItem({
  session,
  isCanceled,
  isPaid,
  isLessonCredit,
  isTablet,
  refetch,
  onReschedule,
  onPayment,
  onCancellation,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAuth()
  const { hideSensitiveInformation } = user
  const status = sessionChipStatus({ isCanceled, isPaid, isLessonCredit })

  // ----> Handle details dialog.
  const handleOpenDialog = value => {
    handleClose()
    setIsOpen(value)
    setAnchorEl(null)
  }

  const DetailsDialog = (
    <SessionDetailsDialog
      isOpen={isOpen}
      setIsOpen={handleOpenDialog}
      booking={session?.lesson}
      onReschedule={onReschedule}
      onPayment={onPayment}
      onCancellation={onCancellation}
    />
  )

  // ----> Handle quick-action menu.
  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { coachFacility } = session?.lesson
  const { startDateTime, endDateTime, timezone, coach, title } = session.lesson
  const showActionMenu = !(hideSensitiveInformation && !isCanceled) // <-- Possibility of action menu being empty if items are being hidden because impersonation is happening.

  const { webDate, mobileDate, timePeriod } = useSessionDates(
    startDateTime,
    endDateTime,
    timezone,
  )
  const classes = useStyles({ isCanceled, isPaid: isPaid || isLessonCredit })

  const iconAccessor = useIconAccessor()
  const { icon, text: category } = iconAccessor[
    session.lesson.lessonType.category
  ]

  if (isTablet) {
    return (
      <>
        <TableRow onClick={() => setIsOpen(!isOpen)}>
          <TableCell>
            <Box className={classes.mobileContainer}>
              <Avatar variant="rounded" className={classes.sessionIcon}>
                {icon}
              </Avatar>
              <Box className={classes.mobileText}>
                <Box className={classes.mobileTop}>
                  <Typography>
                    {title}
                    {isCanceled && ' — Canceled'}
                  </Typography>
                  {showActionMenu && (
                    <Box>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={classes.button}
                      >
                        <MoreVertIcon fontSize="medium" />
                      </Button>
                    </Box>
                  )}
                </Box>
                <Box className={classes.mobileBottom}>
                  <LinesEllipsis
                    text={coachFacility?.name}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                    className={classes.subtext}
                  />
                  <Typography className={classes.subtext}>
                    {mobileDate} • {timePeriod}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
        <SessionMenu
          handleClose={handleClose}
          anchorEl={anchorEl}
          session={session}
          refetch={refetch}
        />
        {DetailsDialog}
      </>
    )
  } else {
    return (
      <>
        <TableRow
          onClick={() => setIsOpen(!isOpen)}
          className={classes.sessionRow}
        >
          <TableCell>
            <Box display="flex" alignItems="center">
              <Avatar variant="circular" className={classes.sessionIcon}>
                {icon}
              </Avatar>
              <Box display="flex" flexDirection="column">
                <Typography>{title}</Typography>
                <Typography className={classes.subtext}>
                  {category}
                  {isTablet && isCanceled && ' — (Cancelled)'}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Typography>{webDate}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{coach.name}</Typography>
          </TableCell>
          <TableCell>
            <Chip className={classes.chipStyles} size="small" label={status} />
          </TableCell>
          <TableCell>
            {showActionMenu && (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className={classes.button}
                >
                  <MoreVertIcon fontSize="small" />
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
        <SessionMenu
          handleClose={handleClose}
          anchorEl={anchorEl}
          session={session}
          refetch={refetch}
        />
        {DetailsDialog}
      </>
    )
  }
}
