import React from 'react'
import PropTypes from 'prop-types'
import { IconButtonBackArrow } from 'components/atoms/icons'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:focus': {
      color: theme.palette.secondary.dark,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
    '&:active': {
      color: theme.palette.primary.lightest,
    },
  },
}))

const RegisterBackButton = ({
  showWaiver,
  setShowWaiver,
  shouldShowWaiver,
}) => {
  const classes = useStyles()
  const handleBackButtonClick = () => {
    if (showWaiver) {
      setShowWaiver(false)
    } else {
      window.history.back()
    }
  }

  if (!shouldShowWaiver) return null
  return (
    <Button
      className={classes.backButton}
      startIcon={<IconButtonBackArrow />}
      color="primary"
      onClick={handleBackButtonClick}
    >
      Back
    </Button>
  )
}

RegisterBackButton.propTypes = {
  showWaiver: PropTypes.bool.isRequired,
  setShowWaiver: PropTypes.func.isRequired,
  shouldShowWaiver: PropTypes.bool.isRequired,
}

export default RegisterBackButton
