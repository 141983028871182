import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { formFields } from '../constants'

const VideoIntroduction = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = props

  return (
    <>
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: '1rem' }}>
        Share your story with potential students on your PGA Coach Profile on PGA.com! Record a brief, informal video introducing yourself, describing your coaching philosophy, experience, and what makes you unique as a PGA Coach. Once your video is ready, simply add the YouTube link below to highlight your expertise and attract new leads. <strong>We do not support YouTube Shorts at this time.</strong>
      </Typography>
      <TextField
        key="introductionVideoUrl"
        name="introductionVideoUrl"
        label="YouTube"
        type="text"
        variant="standard"
        placeholder="https://www.youtube.com/watch?v=AMozaT8GCFg&ab_channel=PGAofAmerica"
        value={values.introductionVideoUrl}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`text-input ${errors.introductionVideoUrl &&
          touched.introductionVideoUrl &&
          ' error'}`}
        error={errors.introductionVideoUrl && touched.introductionVideoUrl}
        helperText={touched.introductionVideoUrl && errors.introductionVideoUrl}
      />
    </>
  )
}

VideoIntroduction.propTypes = {
  values: formFields,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

VideoIntroduction.defaultProps = {}

export default VideoIntroduction
