/**
 * Insert a value returned from `separatorFn` in between items in the array.
 * The `separatorFn` argument is called with the current element index so
 * separator values can be based off the index if needed.
 *
 * Example:
 *
 *   intersperseWith(['foo', 'bar', 'baz'], index => index.toString())
 *   // ['foo', '0', 'bar', '1', 'baz']
 */
export const intersperseWith = (array, separatorFn) =>
  array.reduce(
    (acc, element, index) =>
      index === array.length - 1
        ? [...acc, element]
        : [...acc, element, separatorFn(index)],
    [],
  )

/**
 * Accumulate a value over an array while also mapping values in the array.
 * Return an tuple (2-item array) from the reducing function with the first
 * item containing a mapped value and the second item containing the new
 * accumulated value.
 *
 * Returns a tuple with the first item containing the mapped array and the
 * second item containing the accumulated value.
 *
 * Example:
 *
 *   mapReduce([1, 2, 3], (acc, n) => [n * 2, n + acc], 0)
 *   // [[2, 4, 6], 6]
 */
export const mapReduce = (array, mapReduceFn, initialAcc) =>
  array.reduce(
    ([mappedArray, acc], element) => {
      const [mappedElement, newAcc] = mapReduceFn(acc, element)

      return [[...mappedArray, mappedElement], newAcc]
    },
    [[], initialAcc],
  )

/**
 * Transforms values in an array via the `mapFn` argument and filters out falsy
 * values in one iteration.
 */
export const compactMap = (array, mapFn) =>
  (array || []).flatMap(value => {
    const mappedValue = mapFn(value)
    return mappedValue ? [mappedValue] : []
  })

/**
 * Returns true if the input string includes any of the strings in the `array`
 *  otherwise returns false.
 * argument.
 */
export const includesStringOrSubstring = (array = [], inputString) => {
  for (let i = 0; i < array.length; i++) {
    if (inputString.includes(array[i])) {
      return true
    }
  }
  return false
}
