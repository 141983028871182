import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { TestimonyFields } from '../profile.styles'
import { formFields } from '../constants'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'

const MAX_TESTIMONIALS_COUNT = 5

const Testimonials = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props

  const handleAddTestimonial = () => {
    const newTestimonials = values.testimonialsJsonData
    newTestimonials.push({
      testimonial: '',
      author: '',
      rank: values.testimonialsJsonData.length + 1,
    })
    setFieldValue('testimonialsJsonData', newTestimonials)
  }

  const handleDeleteTestimonial = index => {
    const newTestimonials = values.testimonialsJsonData
    newTestimonials.splice(index, 1)
    setFieldValue('testimonialsJsonData', newTestimonials)
  }

  const errorsHelper = (action, otherAction, value, i) => {
    return (
      action.testimonialsJsonData &&
      action.testimonialsJsonData.length &&
      action.testimonialsJsonData[i] &&
      action.testimonialsJsonData[i][value] &&
      otherAction.testimonialsJsonData &&
      otherAction.testimonialsJsonData.length &&
      otherAction.testimonialsJsonData[i] &&
      otherAction.testimonialsJsonData[i][value]
    )
  }

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        Have any kudos from past students you want to share? Add them here!
      </Typography>
      {values.testimonialsJsonData &&
        values.testimonialsJsonData.map((item, i) => (
          <Box key={i}>
            <Box mt={2} >
              <TestimonyFields>
                <TextField
                  multiline
                  name={`testimonialsJsonData[${i}].testimonial`}
                  label="Testimonial"
                  type="text"
                  variant="outlined"
                  value={values.testimonialsJsonData[i].testimonial}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text-input multiline"
                  error={errorsHelper(errors, touched, 'testimonial', i)}
                  helperText={errorsHelper(touched, errors, 'testimonial', i)}
                />
              </TestimonyFields>
              <Box style={{ maxWidth: '500px' }} display="flex">
                <TextField
                  name={`testimonialsJsonData[${i}].author`}
                  label="Attestant"
                  type="text"
                  value={values.testimonialsJsonData[i].author}
                  variant="standard"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text-input"
                  error={errorsHelper(errors, touched, 'author', i)}
                  helperText={errorsHelper(touched, errors, 'author', i)}
                />
                <Box p={2}>
                  <Button
                    variant="text"
                    onClick={() => {
                      handleDeleteTestimonial(i)
                    }}
                    startIcon={<DeleteOutlinedIcon />}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      <Box>
        <Button
          style={{ marginTop: '20px' }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleAddTestimonial}
          disabled={
            values.testimonialsJsonData.length >= MAX_TESTIMONIALS_COUNT
          }
        >
          Add Testimonial
        </Button>
      </Box>
    </>
  )
}

Testimonials.propTypes = {
  values: formFields,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
}

Testimonials.defaultProps = {}

export default Testimonials
