import CoachAppBar from 'components/coach-app-bar'
import React from 'react'
import Container from '@material-ui/core/Container'
import CondensedFooter from 'components/condensed-footer'
import Typography from '@material-ui/core/Typography'
import ProgramInfo from './program-info'
import { PGA_COM_HOST } from 'env'
import Button from '@material-ui/core/Button'
import RouterLinkButton from 'components/router-link-button'
import DownloadMyPGAsection from 'components/download-mypga-section/download-mypga-section'
import { ActionsWrapper, StyledContainer, StyledWideDivider } from './styles'

const SuccessPage = ({ program }) => {
  return (
    <>
      <CoachAppBar position="relative" hideContactButton />
      <StyledContainer maxWidth="md">
        <Container maxWidth="sm">
          <Typography variant="h3" gutterBottom style={{ marginTop: '60px' }}>
            Thank you!
          </Typography>
          <Typography variant="body1" gutterBottom>
            We have submitted your interest to the PGA Coaching Center and will
            be in touch soon to discuss the next steps. In the meantime, feel
            free to explore all of the programs available. We look forward to
            becoming part of your golfing journey!
          </Typography>
        </Container>
        <StyledWideDivider />
        <Container maxWidth="sm">
          <ProgramInfo program={program} successPage />
          <ActionsWrapper>
            <Button
              href={`${PGA_COM_HOST}/pga-coaching-center-at-pga-frisco`}
              variant="outlined"
              color="primary"
              size="small"
            >
              Coaching Center Website
            </Button>
            <RouterLinkButton
              size="small"
              color="primary"
              variant="contained"
              to="/pga-coaching-center-at-pga-frisco/specialty-programs"
            >
              View Specialty Programs
            </RouterLinkButton>
          </ActionsWrapper>
          <DownloadMyPGAsection />
        </Container>
      </StyledContainer>
      <CondensedFooter />
    </>
  )
}

export default SuccessPage
