import * as Yup from 'yup'
import {
  slugValidator,
  verifySlug,
  stateRegExp,
  zipRegEx,
  timeRegex,
} from 'components/coaches-profile/constants'
import { inputPriceRegExp } from 'utils/regExp'

const newFacilityValidator = Yup.object().shape({
  facilityName: Yup.string()
    .required()
    .label('Facility Name'),
  address1: Yup.string()
    .required()
    .label('Address 1'),
  address2: Yup.string()
    .nullable()
    .label('Address 2'),
  city: Yup.string()
    .nullable()
    .max(50)
    .label('City'),
  state: Yup.string()
    .matches(stateRegExp, 'Must be a valid US State')
    .nullable()
    .label('State'),
  zipCode: Yup.string()
    .matches(zipRegEx, 'Must use a valid zip code')
    .nullable()
    .max(10)
    .label('Zip Code'),
})

const existingFacilityValidator = facilities => {
  return Yup.string()
    .required()
    .oneOf(facilities.map(f => f.id))
}

const baseLFOValidationShape = {
  title: Yup.string().required('Event Name is a required field'),
  sessions: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        startDate: Yup.date().required(),
        startTime: Yup.string()
          .label('Start Time')
          .matches(timeRegex, 'Start time must be a valid time')
          .required(),
        endTime: Yup.string()
          .matches(timeRegex, 'End time must be a valid time')
          .label('End Time')
          .test(
            'end-time-after-start-time',
            'End time must be after start time',
            function (value) {
              return value > this.parent.startTime
            },
          )
          .required(),
      }),
    ),
  description: Yup.string().required(),
  hoursBeforeRegistrationCloses: Yup.number().required(),
  price: Yup.string()
    .matches(inputPriceRegExp, 'Please enter a valid price')
    .required(),
  maxAttendance: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return String(originalValue).trim() === '' ? null : value
    }),
  accessCode: Yup.string().matches(/^[A-Za-z0-9]+$/, {
    message: 'Access codes must consist of numbers and letters only',
    excludeEmptyString: true.valueOf,
  }),
}

export const getValidationSchema = (noCoachProfile, coachFacilities) => {
  if (noCoachProfile && coachFacilities.length > 0) {
    return Yup.object().shape({
      ...baseLFOValidationShape,
      ...{
        customSlug: slugValidator(verifySlug),
        facilityId: existingFacilityValidator(coachFacilities),
      },
    })
  } else if (noCoachProfile) {
    return Yup.object().shape({
      ...baseLFOValidationShape,
      ...{
        customSlug: slugValidator(verifySlug),
        facility: newFacilityValidator,
      },
    })
  } else if (!noCoachProfile && coachFacilities.length === 0) {
    return Yup.object().shape({
      ...baseLFOValidationShape,
      ...{
        facility: newFacilityValidator,
      },
    })
  } else if (!noCoachProfile && coachFacilities.length > 0) {
    return Yup.object().shape({
      ...baseLFOValidationShape,
      ...{
        facilityId: existingFacilityValidator(coachFacilities),
      },
    })
  }
}
