import React, { useState } from 'react'
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core'

const BookingTypeAccessCode = ({
  classes,
  register,
  errors,
  setValue,
  bookingType,
}) => {
  const accessCode = bookingType?.accessCode
  const [showAccessCodeInput, setShowAccessCodeInput] = useState(!!accessCode)

  return (
    <Box mt={3} mb={4}>
      <Typography variant="subtitle1" gutterBottom>
        Access Code
      </Typography>
      <FormControl>
        <RadioGroup aria-labelledby="Access Code">
          <FormControlLabel
            checked={!showAccessCodeInput}
            control={<Radio color="primary" />}
            label="Open Registration"
            onClick={() => {
              setValue('accessCode', null)
              setShowAccessCodeInput(false)
            }}
          />
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: '32px' }}
          >
            Anyone will be able to register for this offering.
          </Typography>
          <FormControlLabel
            checked={showAccessCodeInput}
            control={<Radio color="primary" />}
            label="Require Access Code"
            onClick={() => {
              setShowAccessCodeInput(true)
            }}
          />
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: '32px' }}
          >
            Requiring an access code is a way to password protect your offering.
            Create your own code and share with contacts to allow them to
            register.
            <TextField
              style={{ display: showAccessCodeInput ? 'block' : 'none' }}
              className={classes.formField}
              name="accessCode"
              inputRef={register}
              error={!!errors.accessCode}
              helperText={errors.accessCode && errors.accessCode.message}
              label="Create access code"
            />
          </Typography>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default BookingTypeAccessCode
