import React from 'react'
import { Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@material-ui/core/Button'
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'
import get from 'lodash/get'
import { useIsMobile } from 'lib/hooks'

const CoachingCenterAgreeSection = ({ form, handleClick }) => {
  const isMobile = useIsMobile()
  const { control, errors, watch } = form
  const fieldName = 'agreeWaiver'
  const error = get(errors, fieldName)
  const agreeWaiver = watch(fieldName)

  return (
    <>
      <Controller
        control={control}
        name={fieldName}
        render={({ onChange, value, name, ref }) => (
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => {
                  onChange(e.target.checked)
                }}
                checked={value}
                name={name}
                inputRef={ref}
                data-testid="waiver-checkbox"
              />
            }
            label={
              <Typography style={{ marginLeft: '16px' }}>
                I have read and agree to Assumption of Risk, Waiver and Release
                of Liability, and Indemnity Agreement *
              </Typography>
            }
          />
        )}
      />
      {error && (
        <FormHelperText style={{ color: '#f44336' }}>
          {get(error, 'message')}
        </FormHelperText>
      )}
      <Box display="flex" justifyContent={isMobile ? 'center' : 'start'} my={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          disabled={!agreeWaiver || form.formState.isSubmitting}
          data-testid="waiver-continue-button"
        >
          Continue
        </Button>
      </Box>
    </>
  )
}

export default CoachingCenterAgreeSection
