import React, { useMemo, Fragment } from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import ReservationItem from './reservation-item'
import EmptyPage from 'components/empty-page-state'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { DateTime } from 'luxon'
import { parseReservations, groupByDate, formatDate } from './utils'

const ReservationsList = ({ academy, selectedTab }) => {
  const allReservations = useMemo(() => parseReservations(academy), [academy])

  const pastReservations = useMemo(
    () => allReservations.filter(r => r.isPast && !r.canceled),
    [allReservations],
  )
  const futureReservations = useMemo(
    () => allReservations.filter(r => !r.isPast && !r.canceled),
    [allReservations],
  )
  const upcomingReservations = useMemo(
    () =>
      futureReservations.filter(
        r =>
          DateTime.fromISO(r.startDateTime) >= DateTime.local().startOf('day'),
      ),
    [futureReservations],
  )

  const reservationsByDate = groupByDate(upcomingReservations)
  const pastReservationsByDate = groupByDate(pastReservations)
  const dates = Object.keys(reservationsByDate).sort()
  const pastDates = Object.keys(pastReservationsByDate).sort()

  const selectedDates = selectedTab === 'upcoming' ? dates : pastDates
  const selectedReservationsByDate =
    selectedTab === 'upcoming' ? reservationsByDate : pastReservationsByDate

  if (!dates.length) {
    return (
      <EmptyPage
        IconComp={() => <ErrorOutlineIcon fontSize="large" />}
        title="No Reservations"
        caption="You don't have any reservations yet."
        removeButton
      />
    )
  }
  return (
    <Grid>
      {selectedDates?.map(date => (
        <Fragment key={date}>
          <Box my={1.5}>
            <Typography variant="h6">{formatDate(date)}</Typography>
          </Box>
          {selectedReservationsByDate[date].map(reservation => (
            <ReservationItem reservation={reservation} key={reservation.id} />
          ))}
        </Fragment>
      ))}
    </Grid>
  )
}
export default ReservationsList
