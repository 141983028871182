import { gql } from '@apollo/client'

export const LessonValidation = gql`
  query lessonValidation($timespans: [DateTimeRangeInput!]!) {
    lessonValidation(timespans: $timespans) {
      isAvailable
      warnings
    }
  }
`

export const GET_STUDENT_COACHES = gql`
  query GetStudentCoaches {
    currentUser {
      representatives {
        student {
          coaches {
            externalId
          }
        }
      }
    }
  }
`
