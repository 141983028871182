const hopeLink = slug => {
  return slug.split('pga-hope-')[1]
}

export const academyLink = academy => {
  const isHopeAcademy = academy.slug.startsWith('pga-hope')

  const linkPrefix = isHopeAcademy ? 'pga-hope' : 'academies'
  const slug = isHopeAcademy ? hopeLink(academy.slug) : academy.slug

  return `${linkPrefix}/${slug}`
}
