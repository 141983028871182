import { gql, useQuery } from '@apollo/client'

const GET_GROUP_EVENT_BY_ID = gql`
  query GroupEventById($id: ID) {
    groupEvents(id: $id) {
      __typename
      id
      title
      featuredImageUrl
      slug
      description
      coach {
        name
      }
    }
  }
`

const GET_LESSON_TYPE_BY_ID = gql`
  query GetLessonTypeById($id: ID) {
    lessonTypes(id: $id) {
      id
      title
      category
      description
      coach {
        name
      }
    }
  }
`

export function useGetGroupEvent({ id, skip }) {
  return useQuery(GET_GROUP_EVENT_BY_ID, {
    variables: { id },
    skip,
  })
}

export function useGetLessonType({ id, skip }) {
  return useQuery(GET_LESSON_TYPE_BY_ID, {
    variables: { id },
    skip,
  })
}
