import React from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import PGALogo from 'images/pga-logo-navy.png'
import BookingConfirmationCard from './booking-confirmation-card'

const BookingConfirmationPage = () => {
  const { confirmationCode } = useParams()

  return (
    <Box p={2}>
      <Grid item xs={12} container justify="center">
        <img src={PGALogo} alt="PGA logo" width="64px" />
      </Grid>
      <Grid item xs={12} container justify="center">
        <Grid item xs={12} md={4}>
          <BookingConfirmationCard confirmationCode={confirmationCode} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BookingConfirmationPage
