import React from 'react'
import { useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import useStyles from './group-event-registration-form.styles'
import Grid from '@material-ui/core/Grid'
import { PhoneField } from 'components/form-util'

const EmergencyContact = () => {
  const { register, errors } = useFormContext()
  const classes = useStyles()

  return (
    <>
      <Typography variant="h5" className={classes.formSectionHeader}>
        Emergency Contact
      </Typography>
      <Box>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <TextField
              name="emergencyContact.firstName"
              inputRef={register}
              error={!!errors?.emergencyContact?.firstName}
              helperText={errors?.emergencyContact?.firstName?.message}
              label="First Name"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              name="emergencyContact.lastName"
              inputRef={register}
              error={!!errors?.emergencyContact?.lastName}
              helperText={errors?.emergencyContact?.lastName?.message}
              label="Last Name"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="emergencyContact.relationship"
              inputRef={register}
              error={!!errors?.emergencyContact?.relationship}
              helperText={errors?.emergencyContact?.relationship?.message}
              label="Relationship"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneField
              name="emergencyContact.phone"
              inputRef={register}
              error={!!errors?.emergencyContact?.phone}
              helperText={errors?.emergencyContact?.phone?.message}
              label="Phone Number"
              fullWidth
              className={classes.input}
              required
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EmergencyContact
