import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  ctaTileWrapper: {
    backgroundColor: 'rgba(0, 35, 75, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 12px',
    margin: '56px 0 32px',
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
    '& > img': {
      borderRight: '1px solid rgba(0, 35, 75, 0.24)',
      paddingRight: '8px',
      marginRight: '8px',
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontWeight: 'bold',
  },
  baseText: {
    color: 'rgba(0, 0, 0, 0.64)',
    marginTop: '12px',
    maxWidth: '720px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: `${theme.spacing(2)}px`,
    },
  },
  videoResponsive: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    marginTop: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(3)}px`,
    '& > iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
  },
}))

export default useStyles
