import { styled, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

const ContainerRoot = styled(Container)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '16px 0 40px 0',
  [theme.breakpoints.down('sm')]: {
    margin: '8px 0 32px 0',
  },
}))

const StyledWideDivider = styled(Divider)(({ theme }) => ({
  margin: '40px 0',
  [theme.breakpoints.down('sm')]: {
    margin: '32px 0',
  },
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
  },
}))

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    '& a:first-child': {
      marginBottom: '1rem',
    },
  },
}))

const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Source Serif Pro',
    textTransform: 'none',
    marginTop: '30px',
    marginBottom: '1rem',
    paddingLeft: '0',
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:focus': {
      color: theme.palette.secondary.dark,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
    '&:active': {
      color: theme.palette.primary.lightest,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      marginBottom: '0px',
    },
  },
  icons: {
    color: theme.palette.secondary.dark,
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
    fontSize: '16px',
  },
  seeMoreToggle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  programsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
  },
  input: {
    '& label': {
      color: theme.palette.grey.main,
      fontWeight: 600,
    },
  },
  terms: {
    marginLeft: '8px',
  },
}))

export default useStyles
export {
  ContainerRoot,
  StyledDivider,
  StyledWideDivider,
  ActionsWrapper,
  StyledContainer,
}
