import React from 'react'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    alignItems: 'center',
    display: 'flex',
  },

  startIcon: {
    marginRight: theme.spacing(1),
  },

  endIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const addClass = (child, className) =>
  child &&
  React.cloneElement(child, {
    className: `${child.props.className || ''} ${className}`,
  })

export default function LinkWithIcon({
  className,
  endIcon,
  startIcon,
  children,
  ...props
}) {
  const classes = useStyles()

  return (
    <Link className={`${classes.link} ${className || ''}`} {...props}>
      {addClass(startIcon, classes.startIcon)}
      {children}
      {addClass(endIcon, classes.endIcon)}
    </Link>
  )
}
