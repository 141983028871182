export function checkSessionStatus({ isCanceled = false, enrollments = [] }) {
  return {
    isCanceled: isCanceled,
    isPaid: enrollments.some(e => e.transactions.some(t => !t.refund)),
    isLessonCredit: enrollments.some(e => e.lessonCredit),
  }
}

// Can be used if only one status chip is being displayed.
export function sessionChipStatus({ isCanceled, isPaid, isLessonCredit }) {
  if (isCanceled) return 'Canceled'
  if (isLessonCredit) return 'Credit Redeemed'
  return isPaid ? 'Paid' : 'Unpaid'
}
