import React from 'react'

import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import StepDescriptionSkeleton from 'components/skeleton-loaders/step-description-skeleton'

const StudentCard = () => (
  <Card variant="outlined" style={{ padding: '10px' }}>
    <Box display="flex" alignItems="center">
      <Skeleton
        variant="circle"
        width={50}
        height={50}
        style={{ marginRight: '10px' }}
      />
      <Skeleton variant="text" height={30} width={150} />
    </Box>
  </Card>
)

const CoachSelectStudentLayout = () => (
  <>
    <Grid container spacing={2} style={{ marginTop: '30px' }}>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rect" height={50} width={200} />
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '10px' }}>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rect" height={40} width={100} />
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '40px' }}>
      {new Array(18).fill().map((_, i) => (
        <Grid item md={4} xs={12}>
          <StudentCard key={i} />
        </Grid>
      ))}
    </Grid>
  </>
)

export default ({ isCoach }) => (
  <Container style={{ marginTop: '60px' }}>
    <Grid container>
      <Grid item md={4} xs={12}>
        <StepDescriptionSkeleton />
      </Grid>
    </Grid>
    {isCoach ? (
      <CoachSelectStudentLayout />
    ) : (
      <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item md={4} xs={12} style={{ display: 'flex' }}>
          <Skeleton
            variant="rect"
            height={75}
            width={150}
            style={{ marginRight: '10px' }}
          />
          <Skeleton variant="rect" height={75} width={150} />
        </Grid>
      </Grid>
    )}
  </Container>
)
