import React, { useState, useEffect } from 'react'
import Confetti from 'react-dom-confetti'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ReactComponent as CelebrationIcon } from 'images/icons/celebration-colored.svg'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import { PGA_COM_HOST } from 'env'

const confettiConfig = {
  angle: '219',
  spread: 360,
  startVelocity: '50',
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '1',
  width: '12px',
  height: '8px',
  perspective: '499px',
  colors: ['#00234B', '#AB9157', '#043362', '#BBA778'],
}

const SuccessStep = ({ slug, dialog }) => {
  const [isConfettiEnabled, setIsConfettiEnabled] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsConfettiEnabled(true)
    }, 500)
  })

  const coachFullUrl = `${PGA_COM_HOST}/coach/${slug}`

  return (
    <>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={8}
        >
          <Box mb={3}>
            <CircularHighlightedIcon
              icon={CelebrationIcon}
              highlightColor="#e9f5fe"
              size="160px"
            />
          </Box>
          <Confetti active={isConfettiEnabled} config={confettiConfig} />
          <Typography variant="h4" style={{ fontWeight: 700 }} gutterBottom>
            Success!
          </Typography>
          <Box textAlign="center" mb={5}>
            <Typography variant="body1">
              Nice work! Your PGA Coach Profile has been successfully created.
            </Typography>
          </Box>
          <Button
            color="primary"
            href={coachFullUrl}
            variant="contained"
            size="large"
          >
            View Coach Profile
          </Button>
          <Button
            color="primary"
            style={{ marginTop: '22px' }}
            onClick={() => {
              window.location.href = '/pga-coach/profile'
            }}
          >
            I'm Finished
          </Button>
        </Box>
      </Container>
      {dialog && dialog}
    </>
  )
}

export default SuccessStep
