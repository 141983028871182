import React from 'react'
import Dialog from 'components/dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'

const ManageStripeAccountConfirmationDialog = ({
  paymentAccount,
  isOpen,
  onClose,
}) => {
  return (
    <Dialog isOpen={isOpen} maxWidth="xs" onClose={onClose} showCloseButton>
      {({ closeDialog }) => {
        const launchStripe = () => {
          window.open(paymentAccount.manageUrl)
          closeDialog()
        }
        return (
          <>
            <DialogTitle>Manage Stripe Account?</DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" align="center">
                Making any changes will affect each of the connections you have
                to this Stripe account.
              </Typography>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Box mr={2}>
                  <Button color="primary" onClick={closeDialog}>
                    Cancel
                  </Button>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={launchStripe}
                >
                  Manage&nbsp;
                  <LaunchOutlinedIcon fontSize="small" />
                </Button>
              </Box>
            </DialogContent>
          </>
        )
      }}
    </Dialog>
  )
}

export default ManageStripeAccountConfirmationDialog
