import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import StepDescriptionSkeleton from 'components/skeleton-loaders/step-description-skeleton'
import LessonCardSkeleton from 'components/skeleton-loaders/lesson-type-card-skeleton'

export default () => (
  <Container
    style={{ marginTop: '60px' }}
    data-testid="select-lesson-type-skeleton"
  >
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <StepDescriptionSkeleton />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rect" width={'100%'} height={100} />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <Skeleton variant="rect" width={'100%'} height={40} />
      </Grid>
    </Grid>
    {new Array(2).fill().map((_, i) => (
      <div key={i} style={{ margin: '40px 0' }}>
        <Skeleton variant="text" width={100} height={50} />
        <Grid container spacing={2}>
          {new Array(3).fill().map((_, i) => (
            <Grid key={i} item md={4} sm={12} style={{ width: '100%' }}>
              <LessonCardSkeleton />
            </Grid>
          ))}
        </Grid>
      </div>
    ))}
  </Container>
)
