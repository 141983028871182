import { DateTime } from 'luxon'

const CURRENT_DAY = new Date()
const CURRENT_YEAR = CURRENT_DAY.getFullYear()

const DATE_FORMAT = 'MMMM d, yyyy'
const MOBILE_DATE_FORMAT = 'EEE, MMMM d'

function descendingDates(a, b) {
  if (b.lesson.startDateTime > a.lesson.startDateTime) return -1
  if (b.lesson.startDateTime < a.lesson.startDateTime) return 1
  return 0
}

function ascendingDates(a, b) {
  if (b.lesson.startDateTime > a.lesson.startDateTime) return 1
  if (b.lesson.startDateTime < a.lesson.startDateTime) return -1
  return 0
}

export function parsePastSessions(sessions) {
  return sessions
    .filter(session => new Date(session.lesson.startDateTime) < CURRENT_DAY)
    .sort(ascendingDates)
}

export function parseUpcomingSessions(sessions) {
  return sessions
    .filter(session => new Date(session.lesson.startDateTime) >= CURRENT_DAY)
    .sort(descendingDates)
}

// Pass in result of parsePastSessions which already filters the array.
export function yearsSelect(sessions) {
  const uniq = new Set(
    sessions.map(session =>
      new Date(session.lesson.startDateTime).getFullYear(),
    ),
  )
  const oldestYear = [...uniq].sort()[0]
  const select = []

  for (let i = CURRENT_YEAR; i >= oldestYear; i--) {
    select.push(i)
  }
  return select
}

export function filterByYear(sessions, year) {
  return sessions
    .filter(
      session => new Date(session.lesson.startDateTime).getFullYear() === year,
    )
    .sort(ascendingDates)
}

export function getTimePeriod(startDateTime, endDateTime, timezone) {
  const start = DateTime.fromISO(startDateTime).setZone(timezone)
  const end = DateTime.fromISO(endDateTime).setZone(timezone)

  return `${start.toFormat('h')}:${start.toFormat('mm')} - ${end.toFormat('t')}`
}

export function formatDate(date, isMobile) {
  if (DateTime.fromISO(date) === DateTime.local().startOf('day')) return 'Today'

  return isMobile
    ? DateTime.fromISO(date).toFormat(MOBILE_DATE_FORMAT)
    : DateTime.fromISO(date).toFormat(DATE_FORMAT)
}

export function useSessionDates(startDateTime, endDateTime, timezone) {
  const webDate = formatDate(startDateTime, false)
  const mobileDate = formatDate(startDateTime, true)
  const timePeriod = getTimePeriod(startDateTime, endDateTime, timezone)

  return { webDate, mobileDate, timePeriod }
}

export { CURRENT_DAY, CURRENT_YEAR }
