import { gql } from '@apollo/client'

export const LessonReviewQuery = gql`
  query LessonReviewQuery($id: ID!) {
    lessonTypes(id: $id) {
      id
      category
      title
      description
      durationInMinutes
      requiresAccessCode
      lessonPacks {
        quantity
        priceInCents
        id
      }
      facilities {
        id
        name
        city
        state
        timezone
      }
      coach {
        name
        bookingUrl
        externalId
        coachProfile {
          title
          profilePhoto
        }
      }
      academy {
        slug
      }
      acceptsOnlinePayments
    }
  }
`
export const GetStudentQuery = gql`
  query GetStudentByIdQuery($studentId: ID!) {
    student(id: $studentId) {
      id
      firstName
      lastName
      golferProfile {
        id
      }
    }
  }
`
export const PurchaseLessonPackQuery = gql`
  mutation PurchaseLessonPack(
    $lessonPackId: ID!
    $stripeToken: String!
    $marketingOptInAt: ISO8601DateTime
  ) {
    purchaseLessonPack(
      lessonPackId: $lessonPackId
      stripeToken: $stripeToken
      marketingOptInAt: $marketingOptInAt
    ) {
      success
      message
    }
  }
`
