import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Placeholder from '../images/placeholder.png'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import truncate from 'lib/utils/truncateString'
import { makeStyles } from '@material-ui/core/styles'
import { gtmEvent } from 'lib/gtm'

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.up('sm')]: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
}))

const StagesCard = ({ image, title, subtitle, url }) => {
  const classes = useStyles()
  const description = truncate(subtitle, 60, '...')

  const handleClick = name => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'plan-click',
      activitiesLabel: name,
    })
  }

  return (
    <Card>
      <CardMedia
        component="img"
        height="140"
        image={image || Placeholder}
        alt={title}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="large"
          color="primary"
          component={Link}
          to={url}
          onClick={() => handleClick(title)}
          endIcon={<ArrowRightAlt />}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  )
}

export default StagesCard
