import React from 'react'
import clsx from 'clsx'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  EmailIcon,
} from 'react-share'
import Box from '@material-ui/core/Box'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import CopyTextButton from '../copy-text-button'
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography'
import CopyTextLineEllipsis from '../copy-text-line-ellipsis'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import XShareButton from 'components/x-share-button'
import { gtmEvent } from 'lib/gtm'

const ShareBox = ({
  url,
  title,
  showBookingUrl,
  showProfileUrl,
  showScheduleUrl,
  bookingType,
  att,
  shareId,
}) => {
  const history = useHistory()
  const accessCode = bookingType?.accessCode
  let subtitle = showScheduleUrl
    ? 'Share your schedule page with your students.'
    : 'Share this booking type with your students.'
  subtitle = showBookingUrl
    ? 'Share your booking URL with your students'
    : subtitle
  subtitle = accessCode
    ? `${subtitle} Be sure to include the access code.`
    : subtitle
  subtitle = showProfileUrl ? 'Share Your Coach Profile' : subtitle

  if (bookingType?.lessonPacks?.length > 0) {
    const urlObj = new URL(url)
    urlObj.searchParams.append('isLessonPacks', 'true')
    url = urlObj.toString()
  }

  const sendTrackingEvent = social => {
    if (att === 'pga-group-event') {
      gtmEvent({
        event: 'share-event',
        platform: social,
      })
    }
  }

  const classes = useStyles()
  return (
    <Box>
      <Typography variant="body1" color="textSecondary">
        {subtitle}
      </Typography>
      <br />
      {showBookingUrl && <CopyTextLineEllipsis text={url} />}
      <Box display="flex" justifyContent="center" mb={4} mt={2}>
        <FacebookShareButton
          url={url}
          quote={title}
          className={classes.shareRow}
          onClick={() => sendTrackingEvent('facebook')}
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <XShareButton
          url={url}
          subject={title}
          size={40}
          wrapperClassname={classes.shareRow}
          onClick={() => sendTrackingEvent('x')}
        />
        <div onClick={() => sendTrackingEvent('email')}>
          <EmailShareButton
            url={url}
            subject={title}
            className={classes.shareRow}
          >
            <EmailIcon size={40} round />
          </EmailShareButton>
        </div>
        <Button
          className={clsx(classes.shareRow, classes.messageButton)}
          onClick={() => {
            sendTrackingEvent('message')
            history.push(
              `/pga-coach/messages?shared=${encodeURIComponent(
                url,
              )}&att=${att}${shareId && '&id=' + shareId}`,
            )
          }}
          disableRipple
        >
          <MessageOutlinedIcon />
        </Button>
        <CopyTextButton
          value={url}
          className={classes.shareRow}
          disableRipple={true}
          onClick={() => sendTrackingEvent('copy-url')}
        >
          <LinkOutlinedIcon className={classes.linkButton} />
        </CopyTextButton>
      </Box>
      {accessCode && (
        <Box textAlign="center" mb={2}>
          <Typography variant="body2" color="textSecondary">
            Required Access Code:
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <h3>{accessCode}</h3>
            <CopyTextButton
              value={accessCode}
              className={classes.shareRow}
              classes={{
                label: classes.copyButton,
              }}
              startIcon={<FileCopyOutlinedIcon />}
            ></CopyTextButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ShareBox
