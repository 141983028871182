import { gql, useQuery } from '@apollo/client'
import { mapResultData } from 'lib/graphql'

export const GET_CURRENT_PROGRAMS_FOR_SIDE_MENU_QUERY_NAME =
  'GetCurrentProgramsForSideMenu'

export const GET_CURRENT_PROGRAMS_FOR_SIDE_MENU = gql`
  query ${GET_CURRENT_PROGRAMS_FOR_SIDE_MENU_QUERY_NAME}($statuses: [JRLProgramStatus!]) {
    jrlPrograms(statuses: $statuses) {
      id
      name
      category
      programLocationId
      year
    }
  }
`

export const useGetCurrentPrograms = () => {
  const result = useQuery(GET_CURRENT_PROGRAMS_FOR_SIDE_MENU, {
    variables: {
      statuses: [
        'PUBLISHED',
        'PENDING_APPROVAL',
        'PENDING_REQUIREMENTS',
        'UNPUBLISHED',
      ],
    },
  })

  return mapResultData(result, ({ jrlPrograms }) => jrlPrograms)
}
