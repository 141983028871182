export const AUTH_TOKEN_COOKIE = 'pga_auth'
export const ASSUME_USER_COOKIE = 'pga_assume_user'
export const ASSUME_USER_RETURN_URL_COOKIE = 'pga_assume_user_return_url'
export const ASSUME_USER_ASSUMING_USER = 'pga_assuming_user_restrictions'

const roles = {
  COACH: 'COACH',
  CONSUMER: 'CONSUMER',
  ADMIN: 'ADMIN',
  PEC: 'PEC',
  SECTION_STAFF: 'SECTION_STAFF',
  SUPER_USER: 'SUPER_USER',
  JUNIOR_LEAGUE_COACH: 'JUNIOR_LEAGUE_COACH',
}

roles.ALL = Object.values(roles)
roles.NONE = []

export { roles }
