import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import CardActionArea from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { formattedGroupEventPrice } from 'utils/groupEventUtils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  media: {
    height: 140,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  canceledText: {
    fontWeight: 'bold',
    color: theme.palette.error.light,
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'relative',
    top: '14px',
  },
  chip: {
    border: '1px solid',
    borderColor: theme.palette.error.dark,
    borderRadius: '4px',
    height: '22px',
    fontSize: '10px',
    fontWeight: '500',
    color: theme.palette.error.dark,
  },
  actionItemMenu: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    rowGap: '10px',
  },
}))

const eventHasAssistants = event => {
  return event.assistantCoaches?.length > 0
}

const coachNames = event => {
  const currentCoach = event.coach.name
  const assistantCoachNames = event.assistantCoaches?.map(coach => coach.name)
  const allCoachNames = [currentCoach, ...assistantCoachNames]
  return allCoachNames.join(', ')
}

const EventCard = ({
  event,
  setIsDetailsOpen,
  cardActions,
  chip,
  actionMenu,
}) => {
  const [actionModalAnchor, setActionModalAnchor] = useState(null)

  const classes = useStyles()

  const handleOpenActionModal = e => {
    setActionModalAnchor(e.target)
  }

  const handleCloseActionModal = e => {
    setActionModalAnchor(null)
  }

  return (
    <Card className={classes.root}>
      <Box
        onClick={() => setIsDetailsOpen('detail')}
        position="relative"
        style={{ cursor: 'pointer' }}
      >
        <CardMedia
          className={classes.media}
          image={event.featuredImageUrl}
          title={event.title}
        />
        {chip}
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '6.5rem',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            mb={1}
          >
            <Typography variant="h6">{event.title}</Typography>
            {event.requiresAccessCode && (
              <Box ml={2}>
                <Chip
                  label="Access Code"
                  variant="outlined"
                  className={classes.chip}
                />
              </Box>
            )}
          </Box>
          <Typography variant="caption">{event.coachFacility.name}</Typography>
          {event?.academy?.name && (
            <Typography variant="caption">{event.academy.name}</Typography>
          )}
          <Typography variant="caption">
            {!event.isPast()
              ? event.closestEventDayAndTime
              : event.eventDayAndTime}
          </Typography>
          <Typography variant="caption">
            {event.sessions?.length}{' '}
            {event.sessions?.length > 1 ? 'sessions' : 'session'}
          </Typography>
          <Typography variant="caption">
            {formattedGroupEventPrice(event)}
            {event.isCanceled && (
              <span className={classes.canceledText}>&nbsp; - CANCELED</span>
            )}
          </Typography>
          {eventHasAssistants(event) && (
            <Typography variant="caption">{coachNames(event)}</Typography>
          )}
          {event.sessions.length !== event.futureSessions.length &&
            !event.isPast() && (
              <Typography variant="caption" className={classes.lighterText}>
                {event.futureSessions.length}/{event.sessions.length} sessions
                remaining
              </Typography>
            )}
        </CardContent>
      </Box>
      <CardActionArea className={classes.cardActions}>
        <Box>{cardActions}</Box>
        <Box className={classes.actionItemMenu} onClick={handleOpenActionModal}>
          {event.unlisted && (
            <Box ml={2}>
              <Tooltip title="This event is unlisted.">
                <VisibilityOffIcon />
              </Tooltip>
            </Box>
          )}
          <MoreVertIcon
            color="primary"
            style={{ cursor: 'pointer', marginRight: '5px' }}
          />
        </Box>
        {actionMenu ? (
          <actionMenu.type
            {...actionMenu.props}
            event={event}
            isOpen={Boolean(actionModalAnchor)}
            anchorEl={actionModalAnchor}
            handleClose={handleCloseActionModal}
          />
        ) : null}
      </CardActionArea>
    </Card>
  )
}

export default EventCard

EventCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    featuredImageUrl: PropTypes.string.isRequired,
    startDateTime: PropTypes.object.isRequired,
    endDateTime: PropTypes.object.isRequired,
    registrationCloseDateTime: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    timezone: PropTypes.string.isRequired,
    isCanceled: PropTypes.bool.isRequired,
    maxAttendance: PropTypes.number,
    requiresAccessCode: PropTypes.bool.isRequired,
    priceInCents: PropTypes.number.isRequired,
    totalPriceInCents: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    academyRequired: PropTypes.bool.isRequired,
    academy: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    assistantCoaches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    registrationCount: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    acceptsOnlinePayments: PropTypes.bool.isRequired,
    paymentRecurrenceSchedule: PropTypes.string.isRequired,
    numberOfPayments: PropTypes.number.isRequired,
    externalRegistrationUrl: PropTypes.string,
    seriesEndDateTime: PropTypes.string.isRequired,
    unlisted: PropTypes.bool.isRequired,
    lessonCount: PropTypes.number.isRequired,
    coachFacility: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    coach: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        startDateTime: PropTypes.string.isRequired,
        endDateTime: PropTypes.string.isRequired,
      }),
    ),
  }),
  cardActions: PropTypes.node.isRequired,
  chip: PropTypes.node,
  actionMenu: PropTypes.object,
  setIsDetailsOpen: PropTypes.func.isRequired,
}
