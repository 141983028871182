import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  LinearProgress,
  ListItemText,
  Typography,
  Button,
  Menu,
  ListItemIcon,
  MenuItem,
} from '@material-ui/core'
import Error from 'components/error'
import { PageHeader } from 'components/page-header'
import { roles, withProtectedRoute } from 'lib/auth'
import { GET_STUDENTS } from './queries'
import { MoreHoriz, PersonOutline } from '@material-ui/icons'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import { useStyles, StyledChip } from './styles'
import EditOutlined from '@material-ui/icons/EditOutlined'
import { formatPhoneNumber } from 'utils/phoneNumber'
import hasNonNullProperty from 'lib/utils/has-non-null-property'

const StudentItem = ({ student, relationshipType }) => {
  const history = useHistory()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const hasCompletedProfile = hasNonNullProperty(student?.golferProfile)

  const handleMoreClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleViewProfileClick = event => {
    handleClose(event)
    history.push(`/account/students/${student.id}/profile/edit`)
  }

  const handleEditDetailsClick = event => {
    handleClose(event)
    history.push(`/account/students/${student.id}/edit`)
  }

  return (
    <Grid
      container
      item
      spacing={1}
      className={classes.studentItem}
      onClick={() => history.push(`/account/students/${student.id}`)}
    >
      <Grid lg={3} xs={5} item>
        <AvatarWithNameAndAgeGroup
          student={student}
          relationshipType={relationshipType}
        />
      </Grid>
      <Grid lg={4} className={classes.hideOnMobile} item>
        <StudentContact student={student} field="email" />
      </Grid>
      <Grid lg={2} className={classes.hideOnMobile} item>
        <StudentContact student={student} field="phone" />
      </Grid>
      <Grid lg={2} xs={5} item className={classes.center}>
        <ProfileButton hasCompletedProfile={hasCompletedProfile} />
      </Grid>
      <Grid lg={1} xs={2} item>
        <Button
          aria-controls={`simple-menu-${student.id}`}
          aria-haspopup="true"
          data-testid="menu-button"
          onClick={handleMoreClick}
        >
          <MoreHoriz fontSize="small" />
        </Button>
        <Menu
          id={`simple-menu-${student.id}`}
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => history.push(`/account/students/${student.id}`)}
          >
            <ListItemIcon>
              <PersonOutline className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="View Account Details" />
          </MenuItem>
          <MenuItem onClick={handleEditDetailsClick}>
            <ListItemIcon>
              <EditOutlined className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Edit Account Details" />
          </MenuItem>
          <MenuItem
            onClick={handleViewProfileClick}
            data-testid="profile-button"
          >
            <ListItemIcon>
              <AssignmentIndOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primary={
                hasCompletedProfile
                  ? 'Edit Golfer Profile'
                  : 'Complete Golfer Profile'
              }
            />
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

const StudentsPage = () => {
  const { data, loading, error } = useQuery(GET_STUDENTS)
  const classes = useStyles()

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  const representatives = data?.currentUser?.representatives

  const crumbs = [
    {
      label: 'Home',
      to: '',
    },
  ]
  return (
    <>
      <PageHeader title="Accounts" crumbs={crumbs} />
      <Box my={6}>
        <Container maxWidth="lg">
          <Typography
            className={classes.overlineText}
            variant="overline"
            component="div"
          >
            Accounts ({representatives.length})
          </Typography>
          <Divider style={{ width: '100%', marginBottom: '1rem' }} />
          {representatives.map(rep => (
            <StudentItem
              key={rep.student.id}
              student={rep.student}
              relationshipType={rep.relationshipType}
            />
          ))}
        </Container>
      </Box>
    </>
  )
}

function AvatarWithNameAndAgeGroup({ student, relationshipType }) {
  const name = `${student?.firstName} ${student?.lastName}`
  let subtitle = student?.isMinor ? 'Junior' : 'Adult'
  if (relationshipType === 'SELF') {
    subtitle = 'Account Owner'
  }
  return (
    <Grid item>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <Avatar alt={name} />
        </Grid>
        <Grid item>
          <ListItemText primary={name} secondary={subtitle} />
        </Grid>
      </Grid>
    </Grid>
  )
}

function StudentContact({ student, field }) {
  let value = ''
  if (field === 'phone') {
    value = formatPhoneNumber(student?.contact[field])
  } else {
    value = student?.contact[field]
  }
  return <ListItemText primary={value} style={{ overflowWrap: 'anywhere' }} />
}

function ProfileButton({ hasCompletedProfile }) {
  return hasCompletedProfile ? (
    <StyledChip label="Completed Profile" />
  ) : (
    <StyledChip label="Incomplete Profile" bgColor="#9e9e9e" />
  )
}

export default withProtectedRoute(StudentsPage, [roles.CONSUMER])
