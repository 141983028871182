import React from 'react'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import ProfileForm from 'components/coaches-profile/profile-form'
import { getProfileSchema } from '../coach-profile-schema'
import { makeProfileObj } from './editUtils'

const GET_COACH_PROFILE_FOR_EDIT = gql`
  query GetCoachProfileForEdit {
    currentUser {
      attributes {
        ... on Coach {
          coachProfile {
            ${getProfileSchema}
          }
        }
      }
    }
  }
`

const EditProfilePage = () => {
  const { data, loading, error } = useQuery(GET_COACH_PROFILE_FOR_EDIT)

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} displayErrorType="page" />

  const coachProfile = data?.currentUser.attributes.coachProfile

  return coachProfile != null ? (
    <ProfileForm formFields={makeProfileObj(coachProfile)} />
  ) : (
    <Error statusCode={404} />
  )
}

export default EditProfilePage
