import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  withShadow: {
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    borderRadius: '.5rem',
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: '14px',
    '&[data-shrink="true"]': {
      display: 'none',
    },
  },
}))
