import { SupervisorAccountOutlined } from '@material-ui/icons'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import {
  ClubsIcon,
  IntroSessionIcon,
  OncourseIcon,
  PrivateSessionIcon,
  GolferIcon,
} from 'components/icons/refreshed-icons'

// Allows for resizing and recoloring of the Refreshed Icons.

export default function useIconAccessor(
  { size, color } = { size: 20, color: 'rgba(0, 35, 75, 1) !important' },
) {
  return {
    INTRO: {
      text: 'Intro',
      icon: <IntroSessionIcon color={color} size={size} />,
      suggestedName: 'Intro Session',
    },
    PRIVATE: {
      text: 'Private',
      icon: <PrivateSessionIcon color={color} size={size} />,
      suggestedName: 'Private Instruction',
    },
    ON_COURSE: {
      text: 'On Course',
      icon: <OncourseIcon color={color} size={size} />,
      suggestedName: 'On Course Playing Lesson',
    },
    SWING_EVALUATION: {
      text: 'Swing Evaluation',
      icon: <GolferIcon color={color} size={size} />,
      suggestedName: 'Swing Evaluation',
    },
    CLUB_FITTING: {
      text: 'Club Fitting',
      icon: <ClubsIcon color={color} size={size} />,
      suggestedName: 'Club Fitting',
    },
    OTHER: {
      text: 'Other',
      icon: (
        <AddCircleOutlineOutlinedIcon
          style={{ color: `${color}`, fontSize: `${size}px` }}
        />
      ),
      suggestedName: '',
    },
    GROUP: {
      text: 'Group Event',
      icon: (
        <SupervisorAccountOutlined
          style={{ color: `${color}`, fontSize: `${size}px` }}
        />
      ),
    },
  }
}
