import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isEqual from 'date-fns/isEqual'
import isDate from 'date-fns/isDate'
import { rangeFromDates } from 'lib/date-utils'
import endOfDay from 'date-fns/endOfDay'

// FORMAT CONSTANTS
// ----------------

export const longMonthDayFormat = 'MMMM d'
export const longMonthDayYearFormat = 'MMMM d, yyyy'
export const shortMonthDayFormat = 'MMM d'
export const shortMonthDayYearFormat = 'MMM d, yyyy'

// PUBLIC API
// ----------

export const parse = parseISO

export const safeParse = dateOrString =>
  isDate(dateOrString) ? dateOrString : parse(dateOrString)

export const safeFormat = (dateOrString, dateFormat) =>
  format(safeParse(dateOrString), dateFormat)

export const eventsRange = events =>
  rangeFromDates(events.map(({ date }) => safeParse(date)))

export const formatEventTime = (
  { startTime, endTime },
  timeFormat = 'h:mm a',
) => formatTimeRange(startTime, endTime, timeFormat)

export const formatEventDate = ({ date }, dateFormat) =>
  safeFormat(date, dateFormat)

export function getEventDatesLabel(
  events,
  {
    dateFormat = shortMonthDayYearFormat,
    sameYearDateFormat = shortMonthDayFormat,
  } = {},
) {
  const range = eventsRange(events) || {}
  const { startDate, endDate } = range

  return formatDateRange(startDate, endDate, { dateFormat, sameYearDateFormat })
}

export function getRegionalEventDatesLabel(events) {
  const dates = events.flatMap(({ startDateTime, endDateTime }) => [
    safeParse(startDateTime),
    safeParse(endDateTime),
  ])

  const range = rangeFromDates(dates)
  const { startDate, endDate } = range

  return formatDateRange(startDate, endDate, {
    dateFormat: shortMonthDayFormat,
  })
}

export function formatDateRange(
  startDate,
  endDate,
  {
    tbd = 'TBD',
    dateFormat = longMonthDayYearFormat,
    sameYearDateFormat = longMonthDayFormat,
  } = {},
) {
  if (!startDate && !endDate) return tbd
  if (!startDate) return safeFormat(endDate, dateFormat)
  if (!endDate) return safeFormat(startDate, dateFormat)

  const sd = safeParse(startDate)
  const ed = safeParse(endDate)

  if (isEqual(sd, ed)) {
    return format(sd, dateFormat)
  }

  if (sd.getFullYear() === ed.getFullYear()) {
    return `${format(sd, sameYearDateFormat)} - ${format(ed, dateFormat)}`
  }

  return `${format(sd, dateFormat)} - ${format(ed, dateFormat)}`
}

export function checkRegistrationWindow(registrationOpen, registrationClose) {
  const beforeRegistrationClose =
    endOfDay(parseISO(registrationClose)) > new Date()
  const afterRegistrationOpen = registrationOpen
    ? parseISO(registrationOpen) < new Date()
    : true
  return {
    inRegistrationWindow: afterRegistrationOpen && beforeRegistrationClose,
    afterRegistrationOpen,
    beforeRegistrationClose,
  }
}

export function useGetScheduleLabel(event) {
  if (!event || Object.keys(event).length === 0) {
    return ''
  }
  const normalizedEventDates = normalizeEventDates(event)
  const basedOnEvents = normalizedEventDates?.eventDates?.length > 0
  const eventDates = getEventDates(normalizedEventDates, basedOnEvents)
  return datesString(eventDates, basedOnEvents)
}

export const sortProgramsByEventDates = (a, b) => {
  const datesArr1 = a.events.map(event => new Date(event.date).getTime())
  const minDate1 = Math.min(...datesArr1)
  const datesArr2 = b.events.map(event => new Date(event.date).getTime())
  const minDate2 = Math.min(...datesArr2)
  return minDate1 - minDate2
}

// PRIVATE HELPERS
// ---------------

function formatTimeRange(startDateTime, endDateTime, timeFormat) {
  const start = safeFormat(startDateTime, timeFormat)
  const end = safeFormat(endDateTime, timeFormat)

  return `${start} - ${end}`
}

const algoliaFields = {
  START: 'anticipated_start',
  END: 'anticipated_end',
  EVENTS: 'event_dates',
}

const gqFields = {
  START: 'anticipatedStart',
  END: 'anticipatedEnd',
  EVENTS: 'events',
}

function normalizeEventDates(hit = {}) {
  const fieldNames = Object.keys(hit).includes('__typename')
    ? gqFields
    : algoliaFields

  return {
    anticipatedStart: hit[fieldNames.START],
    anticipatedEnd: hit[fieldNames.END],
    eventDates: hit[fieldNames.EVENTS],
  }
}

function getEventDates(dates, basedOnEvents) {
  if (basedOnEvents) {
    return dates.eventDates
  }
  if (
    dates.anticipatedStart !== 'TBD' &&
    dates.anticipatedEnd !== 'TBD' &&
    dates.anticipatedStart != null &&
    dates.anticipatedEnd != null
  ) {
    return [dates.anticipatedStart, dates.anticipatedEnd]
  }
  return []
}

const datesString = (dates, basedOnEvents) => {
  const dateFormat = basedOnEvents ? longMonthDayYearFormat : 'MMMM yyyy'
  const sameYearDateFormat = basedOnEvents
    ? longMonthDayYearFormat
    : 'MMMM yyyy'

  return getEventDatesLabel(
    dates.map(date => (typeof date === 'string' ? { date } : date)),
    {
      dateFormat,
      sameYearDateFormat,
    },
  )
}
