import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { gtmEvent } from 'lib/gtm'
import { useLoadGroupEventFormValues } from 'components/group-events/create-group-event-form-wizard/hooks'
import { fullDayDateAndTime } from 'utils/dateUtils'

const GroupEventTemplateCard = ({ event, callToAction, onCreateClick }) => {
  const loadGroupEventValues = useLoadGroupEventFormValues()
  const selectTemplate = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'group-event-templates',
      formAction: event.title
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-'),
    })
    loadGroupEventValues(event)
    onCreateClick && onCreateClick()
  }
  return (
    <Card
      variant="outlined"
      style={{
        height: '100%',
        maxHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}
      onClick={selectTemplate}
    >
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <CardMedia
          image={`${event.featuredImageUrl}&w=600`}
          title={event.title}
          style={{ height: '200px' }}
        >
          {event.isNew ? (
            <Box style={{ position: 'relative', left: '16px', top: '16px' }}>
              <Chip
                label={<Typography variant="caption">NEW</Typography>}
                color="secondary"
              />
            </Box>
          ) : null}
        </CardMedia>
        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
          <Box mb={2}>
            <Box mb={1}>
              <Typography variant="h6">{event.title}</Typography>
            </Box>
            {event.caption != null && (
              <Typography display="block" variant="body2" color="textSecondary">
                {event.caption}
              </Typography>
            )}
            {event.coachFacility && (
              <Typography display="block" variant="caption">
                {event.coachFacility.name}
              </Typography>
            )}
            {event.startDateTime && event.endDateTime && (
              <Typography variant="caption">
                {fullDayDateAndTime(event.startDateTime, event.endDateTime)}
              </Typography>
            )}
            {event.priceInCents != null && (
              <Typography display="block" variant="caption">
                ${(event.priceInCents / 100).toFixed(2)}
              </Typography>
            )}
          </Box>
          <Box mx={-0.5}>
            {event.tags.map(tag => (
              <Box key={tag} m={0.5} display="inline-block">
                <Chip size="small" label={tag} />
              </Box>
            ))}
          </Box>
        </CardContent>
      </Box>
      <CardActions style={{ justifyContent: 'center', padding: '0 0 20px' }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={selectTemplate}
          data-cy="use-template"
          style={{ padding: '8px 10px' }}
        >
          {callToAction}
        </Button>
      </CardActions>
    </Card>
  )
}

export default GroupEventTemplateCard
