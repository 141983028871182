import Avatar from '@material-ui/core/Avatar'
import React from 'react'
import { getInitials } from 'lib/text-display'
import PropTypes from 'prop-types'

const InitialsAvatar = ({ name, classes }) => {
  const initialsString = getInitials(name)

  return (
    <Avatar alt={name} className={classes.avatar}>
      {initialsString}
    </Avatar>
  )
}
InitialsAvatar.propTypes = {
  name: PropTypes.string.isRequired,
}

export default InitialsAvatar
