import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  ctaTileWrapper: {
    backgroundColor: 'rgba(0, 35, 75, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 12px',
    margin: '56px 0 32px',
    borderRadius: '8px',
    width: '100%',
    '& > img': {
      borderRight: '1px solid rgba(0, 35, 75, 0.24)',
      paddingRight: '8px',
      marginRight: '8px',
    },
  },
  heading: {
    fontWeight: 'bold',
  },
  baseText: {
    color: 'rgba(0, 0, 0, 0.64)',
    marginTop: '12px',
    maxWidth: '720px',
  },
  hiddenTabs: {
    display: 'none',
  },
}))

export default useStyles
