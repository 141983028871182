import React from 'react'
import { useParams } from 'react-router-dom'
import Error from 'components/error'
import ApolloDataView from 'components/apollo-data-view'
import StudentListView from './student-list-view'
import EmptyListView from 'pages/pga-coach/students/empty-list-view'
import LoadingView from 'pages/pga-coach/students/loading-view'
import { useGetCoachingCenterStudents } from '../api-hooks'
import CoachingCenterPageHeader from 'components/page-header/coaching-center-page-header'
import { processStudentsData } from '../utils'
import { useAuth } from 'lib/auth'

const CoachingCenterStudentsList = () => {
  const { slug } = useParams()
  const { loading, error, data, refetch } = useGetCoachingCenterStudents({
    academySlug: slug,
  })

  const enrolledStudents = data ? processStudentsData(data.academyStudents) : []
  const { user } = useAuth()

  return (
    <>
      {user.isAcademyOwner ? (
        <CoachingCenterPageHeader
          title="Students"
          children={
            <ApolloDataView
              error={error}
              loading={loading}
              data={enrolledStudents}
            >
              {{
                errorView: <Error />,
                emptyView: <EmptyListView removeButton={true} />,
                loadingView: <LoadingView />,
                dataView: students => (
                  <StudentListView
                    searchableItems={students}
                    students={students}
                    refetchStudents={refetch}
                    academySlug={slug}
                  />
                ),
              }}
            </ApolloDataView>
          }
        />
      ) : (
        <Error error={error} displayErrorType="page" />
      )}
    </>
  )
}

export default CoachingCenterStudentsList
