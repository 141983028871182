import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { showNewMessage } from 'lib/support'
import displayConfig from './displayConfig'
import { useStyles } from './FacilityDisplay.styles'

const TextWithOverline = ({ text, overlineText }) => (
  <>
    <Typography
      style={{
        fontSize: '12px',
        color: 'rgb(158, 158, 158)',
        fontWeight: '500',
      }}
    >
      {overlineText}
    </Typography>
    <Typography>{text}</Typography>
  </>
)

TextWithOverline.propTypes = {
  text: PropTypes.string,
  overlineText: PropTypes.string,
}

export default function FacilityDisplay({
  display,
  values,
  clearPGAFacilitySelection,
}) {
  const facilityValues = values || []
  const classes = useStyles()
  return (
    <Box mb={2} display={display} maxWidth="735px">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.infoWrapper}>
            <Box display="flex">
              <InfoIcon className={classes.infoIcon} />
            </Box>
            <Box display="flex" style={{ flexDirection: 'column' }}>
              <Typography className={classes.infoText}>
                You&apos;ve selected a pre-existing facility. Editing
                pre-existing facility information is disabled.
              </Typography>
              <Typography className={classes.intercomText}>
                If the data appears incorrect, please{' '}
                <button
                  type="button"
                  className={classes.contact_button}
                  onClick={() =>
                    showNewMessage(
                      `I found an issue with ${facilityValues.facilityName}'s data.  Specifically....`,
                    )
                  }
                >
                  contact us
                </button>{' '}
                to let us know.
              </Typography>
            </Box>
          </Box>
        </Grid>
        {displayConfig.map(({ attrName, overlineText, cols }) =>
          facilityValues[attrName] ? (
            <Grid key={attrName} item xs={12} sm={cols}>
              <TextWithOverline
                {...{ text: facilityValues[attrName], overlineText }}
              />
            </Grid>
          ) : null,
        )}
        {facilityValues.isPrivate ? (
          <Grid item xs={12}>
            <em>This is a private facility.</em>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={clearPGAFacilitySelection}
          >
            Clear Selection
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

FacilityDisplay.propTypes = {
  display: PropTypes.string,
  values: PropTypes.object,
  clearPGAFacilitySelection: PropTypes.func,
}
