import React from 'react'
import Dialog from 'components/dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { TextFieldWithError } from 'components/form-util'
import CurrencyField from 'components/form-util/currency'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { gql, useMutation } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'

const ISSUE_REFUND = gql`
  mutation issueRefund($input: PGACoachRefundInput!) {
    issueRefundPGACoach(input: $input) {
      success
      message
    }
  }
`

export const formatCurrency = amount => `$${(amount / 100).toFixed(2)}`

const schema = leftToRefund =>
  yup
    .object({
      amount: yup
        .number()
        .moreThan(0)
        .max(
          leftToRefund,
          `Amount is higher than what is left to refund (${formatCurrency(
            leftToRefund * 100,
          )})`,
        )
        .typeError('Amount must be a valid number')
        .label('Amount')
        .required(),
      reason: yup
        .string()
        .label('Reason')
        .required(),
    })
    .required()

const RefundDialog = ({
  registrationPaymentId,
  leftToRefund,
  trigger,
  onSuccess,
}) => {
  const [issueRefundMutation, { error, called, reset }] = useMutation(
    ISSUE_REFUND,
  )

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: leftToRefund,
      reason: '',
    },
    resolver: yupResolver(schema(leftToRefund)),
  })

  if (error) return <p>ERROR</p>

  const onSubmit = closeDialog => ({ amount, reason }) => {
    issueRefundMutation({
      variables: {
        input: {
          registrationPaymentId: registrationPaymentId,
          amountInCents: parseInt(amount * 100),
          reason,
        },
      },
    })
      .then(onSuccess)
      .then(closeDialog)
      .then(reset)
  }

  return (
    <Dialog trigger={trigger} showCloseButton={true} fullWidth maxWidth={'xs'}>
      {({ closeDialog }) => (
        <DialogContent>
          <Box mb={4}>
            <Typography variant="h4">Refund</Typography>
          </Box>
          {called ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={12}
            >
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit(closeDialog))}>
              <Box>
                <CurrencyField
                  name="amount"
                  label="Refund Amount"
                  inputRef={register}
                  error={!!errors.amount}
                  helperText={errors?.amount?.message}
                  required
                  includeThousandsSeparator={false}
                />
              </Box>
              <Box py={2}>
                <TextFieldWithError
                  label="Reason"
                  name="reason"
                  inputRef={register}
                  errors={errors}
                  multiline
                  rows="3"
                  required
                  inputProps={{ 'aria-label': 'reason' }}
                />
              </Box>

              <Box display="flex" justifyContent="center">
                <Box mx={2}>
                  <Button variant="contained" onClick={closeDialog}>
                    Close
                  </Button>
                </Box>
                <Box mx={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit(closeDialog))}
                    disabled={called}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </DialogContent>
      )}
    </Dialog>
  )
}

export default RefundDialog
