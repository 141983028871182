import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import themes from 'themes'
import { centsToDollarsFormatted } from 'lib/utils/number'
import { useStyles } from './lesson-types-coach-cards'
import {
  PGA_STAFF_OFFERING_TEXT,
  INTERNAL_STAFF_OFFERING_TITLES,
} from 'utils/constants'
import { includesStringOrSubstring } from 'lib/utils/array'

const PriceDetails = ({ lessonType }) => {
  const classes = useStyles()
  const {
    priceInCents,
    title,
    requiresAccessCode,
    lessonPackOfferings,
  } = lessonType
  const isLessonPack = lessonPackOfferings?.length > 0
  const lessonPackPrices = lessonPackOfferings?.map(offering =>
    centsToDollarsFormatted(offering.priceInCents),
  )
  const shouldShowText =
    requiresAccessCode &&
    includesStringOrSubstring(
      INTERNAL_STAFF_OFFERING_TITLES,
      title.toLowerCase(),
    )

  return (
    <>
      <SavingsIcon
        size="16"
        color={themes.offeringsRefresh.palette.secondary.main}
      />
      {shouldShowText ? (
        <Typography variant="caption" className={classes.property}>
          {PGA_STAFF_OFFERING_TEXT}
        </Typography>
      ) : null}
      {isLessonPack && !shouldShowText ? (
        <Typography variant="caption" className={classes.property}>
          Lesson Packs: {lessonPackPrices[0]}
        </Typography>
      ) : null}
      {!isLessonPack && !shouldShowText ? (
        <Typography variant="caption" className={classes.property}>
          {centsToDollarsFormatted(priceInCents)}
        </Typography>
      ) : null}
    </>
  )
}

PriceDetails.prototypes = {
  lessonType: PropTypes.object.isRequired,
}

export default PriceDetails
