import React from 'react'
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import Container from '@material-ui/core/Container'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import PersonIcon from '@material-ui/icons/Person'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { GET_FACILITY } from 'components/facilities/api-hooks'

import { PageHeader } from 'components/page-header'
import RouterLinkButton from 'components/router-link-button'
import Error from 'components/error'
import { FormattedOneLineAddress } from 'components/util/formatted_address'
import ContentSection from 'components/content-section'
import DeleteFacilityDialog from './delete-facility-dialog'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import { useIsMobile } from 'lib/hooks'
import Disclaimer from 'components/disclaimer'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import ButtonCta from 'components/atoms/button-cta'
import { useAuth } from 'lib/auth'
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  successCheck: {
    color: theme.palette.success.main,
  },
  deleteButton: {
    maxHeight: '40px',
    minWidth: '200px',
    [theme.breakpoints.only('xs')]: {
      boxSizing: 'border-box !important',
      display: 'block',
      minWidth: '80px',
      maxWidth: '80px',
      minHeight: '80px',
      maxHeight: '80px',
      fontSize: '12px',
      paddingRight: 0,
      paddingLeft: 0,
      marginRight: '16px',
      borderColor: '#E0E0E0',
      '& > * > *': {
        marginRight: 0,
      },
    },
  },
}))

const AvailabilityList = ({ availability }) => {
  const styles = useStyles()
  const daysOfWeek = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ]
  const aggregateByDay = timeSlots => {
    const initialValue = daysOfWeek.reduce((acc, d) => {
      acc[d] = []
      return acc
    }, {})
    return (timeSlots || []).reduce((acc, ts) => {
      acc[ts.day].push(ts)
      return acc
    }, initialValue)
  }

  const availabilityByDay = aggregateByDay(availability?.timeSlots)

  const renderTimeslots = timeSlots => {
    if (!isEmpty(timeSlots)) {
      const slots = timeSlots.map(ts => {
        const startTime = DateTime.fromISO(ts.startTime).toFormat('h:mma')
        const endTime = DateTime.fromISO(ts.endTime).toFormat('h:mma')
        const formattedTimeSlot = `${startTime} - ${endTime}`
        return formattedTimeSlot
      })

      return slots.join(', ')
    } else {
      return 'Not Available'
    }
  }

  return (
    <>
      {daysOfWeek.map(day => {
        const timeSlots = availabilityByDay[day]
        return (
          <ListItem key={day}>
            <ListItemAvatar>
              {isEmpty(timeSlots) ? (
                <RemoveCircleIcon />
              ) : (
                <CheckCircleIcon className={styles.successCheck} />
              )}
            </ListItemAvatar>

            <ListItemText
              primary={day.toLowerCase()}
              secondary={renderTimeslots(timeSlots)}
              style={{ textTransform: 'capitalize' }}
            />
          </ListItem>
        )
      })}
    </>
  )
}

const Facility = ({ facility }) => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const styles = useStyles()
  const theme = useTheme()
  const isMobile = useIsMobile()
  const { user } = useAuth()
  const hasCoachProfile = user?.coach?.coachProfile

  const {
    id,
    name,
    address1,
    address2,
    city,
    state,
    zip,
    timezone,
    url,
    availabilities,
    isPrivate,
    coachProfilesFacilities,
  } = facility
  const isVisible = coachProfilesFacilities[0]?.visible
  const isPrimary = coachProfilesFacilities[0]?.facilityRank === 1

  const facilityPath = path
    .split('/')
    .slice(0, 4)
    .join('/')

  const crumbs = [
    {
      label: 'Settings',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
    {
      label: 'Facilities',
      to: facilityPath,
    },
  ]

  const hasAvailabilities = !isEmpty(availabilities)
  return (
    <>
      <PageHeader
        title={name}
        crumbs={crumbs}
        actions={
          <>
            <RouterLinkButton
              variant="outlined"
              color="primary"
              startIcon={!isMobile && <EditOutlinedIcon />}
              to={{
                pathname: `${facilityPath}/${id}/edit`,
              }}
            >
              Edit
            </RouterLinkButton>
          </>
        }
        fixedMobileActions
      />
      {!hasCoachProfile && (
        <Container maxWidth="md">
          <Box mt={4}>
            <Disclaimer
              icon={<PersonOutlineOutlinedIcon />}
              description={
                <>
                  <Typography variant="subtitle1">
                    Create Your Coach Profile
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Once you create a profile, you will be able to manage how
                    this facility is displayed. You will also be able to set it
                    as your primary facility to be used for location-based
                    searches.
                  </Typography>
                </>
              }
              action={
                <ButtonCta
                  variant={isMobile ? 'text' : 'outlined'}
                  arrow={isMobile}
                  color="primary"
                  href={'/pga-coach/profile/new'}
                >
                  Create Profile
                </ButtonCta>
              }
            />
          </Box>
        </Container>
      )}
      <Container>
        {hasCoachProfile && (
          <ContentSection title="Coach Profile Visibility">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <ListItem>
                  <ListItemAvatar>
                    <VisibilityOutlinedIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Visibility"
                    secondary={`${isVisible ? 'Visible' : 'Hidden'} on Profile`}
                  />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem>
                  <ListItemAvatar>
                    <StarBorderOutlinedIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Primary Facility"
                    secondary={isPrimary ? 'Yes' : 'No'}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </ContentSection>
        )}
        <ContentSection title="Facility Details">
          <ListItem>
            <ListItemAvatar>
              <PersonIcon />
            </ListItemAvatar>
            <ListItemText
              primary="Coach Profile"
              secondary={hasAvailabilities ? 'Active' : 'Inactive'}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <RoomOutlinedIcon />
            </ListItemAvatar>
            <ListItemText
              primary="Address"
              secondary={
                address1 ? (
                  <FormattedOneLineAddress
                    address1={address1}
                    address2={address2}
                    city={city}
                    state={state}
                    zip={zip}
                  />
                ) : (
                  url
                )
              }
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <AccessTimeOutlinedIcon />
            </ListItemAvatar>
            <ListItemText primary="Time Zone" secondary={timezone} />
          </ListItem>

          {isPrivate && <ListItem>This is a private facility.</ListItem>}
        </ContentSection>
        <ContentSection
          title="Coaching Availability"
          actions={
            <RouterLinkButton
              variant="outlined"
              color="primary"
              startIcon={!isMobile && <SettingsOutlinedIcon />}
              to={'/pga-coach/settings/availability'}
            >
              MANAGE {isMobile ? '' : 'AVAILABILITY'}
            </RouterLinkButton>
          }
        >
          <AvailabilityList availability={availabilities[0]} />
        </ContentSection>
        {isMobile ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row-reverse"
            pb={3}
          >
            <Typography variant="body2" color="textSecondary">
              Delete facility and all booking types and availability associated
              to this facility. When deleted, this facility will also be removed
              from your coach profile.
            </Typography>
            <DeleteFacilityDialog
              facilityId={id}
              onSuccess={() => history.push('/pga-coach/facilities')}
              trigger={
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={isPrimary}
                  className={styles.deleteButton}
                  startIcon={<DeleteForeverOutlinedIcon />}
                >
                  Delete
                </Button>
              }
            />
          </Box>
        ) : (
          <ContentSection title="Delete Facility">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              py={3}
              pr={3}
            >
              <Typography variant="body1">
                Delete facility and all booking types and availability
                associated to this facility. When deleted, this facility will
                also be removed from your coach profile.
              </Typography>
              <DeleteFacilityDialog
                facilityId={id}
                onSuccess={() => history.push('/pga-coach/facilities')}
                trigger={
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={isPrimary}
                    className={styles.deleteButton}
                    startIcon={<DeleteForeverOutlinedIcon />}
                    style={{ marginLeft: theme.spacing(4) }}
                  >
                    Delete FACILITY
                  </Button>
                }
              />
            </Box>
          </ContentSection>
        )}
      </Container>
    </>
  )
}

const FacilitiesPageShow = () => {
  const { id: facilityId } = useParams()

  const { loading, data, error } = useQuery(GET_FACILITY, {
    variables: { facilityId },
  })

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />
  const { facility } = data

  return <Facility facility={facility} />
}

export default FacilitiesPageShow
