import ContentSection from 'components/content-section'
import { formatNameForDisplay } from 'lib/text-display'
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useSnackbarSuccess } from 'lib/snackbar'
import { useStyles } from './styles'
import {
  CakeOutlined,
  PersonOutline,
  LocalPhoneOutlined,
  FileCopyOutlined,
  EmailOutlined,
} from '@material-ui/icons'

export default function ParticipantInfo({ student = {} }) {
  const {
    firstName,
    lastName,
    dateOfBirth,
    contact: { email, phoneNumber },
    demographicProfile,
  } = student

  const contactName = formatNameForDisplay(`${firstName} ${lastName}`)

  const genderEnum = {
    M: 'Male',
    F: 'Female',
    O: 'Other',
  }

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)

  const copyEmailToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(email)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  const copyPhoneToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(phoneNumber)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  const classes = useStyles()

  return (
    <ContentSection title="Participant Information">
      <List className={classes.container}>
        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <PersonOutline className={classes.icon} />
              </ListItemAvatar>
              <ListItemText>{contactName}</ListItemText>
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <EmailOutlined className={classes.icon} />
              </ListItemAvatar>
              {email ? (
                <>
                  <a
                    href={`mailto:${email}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListItemText primary={email} />
                  </a>
                  <ListItemAvatar>
                    <FileCopyOutlined
                      className={classes.copyIcon}
                      onClick={copyEmailToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListItemText primary="N/A" />
              )}
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <PersonOutline className={classes.icon} />
              </ListItemAvatar>
              <ListItemText>
                {genderEnum[demographicProfile?.gender] || 'Gender unspecified'}
              </ListItemText>
            </ListItem>
          </List>
        </ListItem>

        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <LocalPhoneOutlined className={classes.icon} />
              </ListItemAvatar>
              {phoneNumber ? (
                <>
                  <a
                    href={`tel:${phoneNumber}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListItemText primary={phoneNumber} />
                  </a>
                  <ListItemAvatar>
                    <FileCopyOutlined
                      className={classes.copyIcon}
                      onClick={copyPhoneToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListItemText primary="N/A" />
              )}
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <CakeOutlined className={classes.icon} />
              </ListItemAvatar>
              <ListItemText>{dateOfBirth || 'N/A'}</ListItemText>
            </ListItem>
          </List>
        </ListItem>
      </List>
    </ContentSection>
  )
}
