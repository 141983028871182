import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { daysUntil, toFullDateShortMonth } from 'utils/dateUtils'
import { showNewMessage } from 'lib/support'
import { currency } from 'lib/utils/string'
import { centsToDollars } from 'lib/utils/number'
import { useStyles, TileIcon, TileWrapper } from './styles'
import pluralize from 'lib/pluralize'

const EventStats = ({ setIsOpen, event }) => {
  const {
    title,
    registrationCount,
    startDateTime,
    registrationCloseDateTime,
    revenueInCents,
  } = event
  // TODO - move this logic to the backend
  const numberOfParticipants = registrationCount
  const revenue = centsToDollars(revenueInCents)
  const classes = useStyles()
  return (
    <DialogContent className={classes.root}>
      <Box
        alignItems="center"
        display="flex"
        onClick={() => setIsOpen('detail')}
        className={classes.backButtonWrapper}
      >
        <ArrowBackIosIcon fontSize="small" />
        <Typography variant="caption" style={{ fontWeight: 900 }}>
          Back
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        className={classes.titleWrapper}
      >
        <Typography variant="h6" className={classes.titleFont}>
          {title}
        </Typography>
        <Typography variant="h4" className={classes.headerFont}>
          Stats
        </Typography>
      </Box>
      <Grid container spacing={4} className={classes.gridContainer}>
        <Grid item md={5} xs={12} className={classes.gridItem}>
          <TileWrapper bgColor="rgba(0, 35, 75, 0.08)">
            <SavingsIcon size="32" />
          </TileWrapper>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" className={classes.infoText}>
              {currency(revenue)}
            </Typography>
            <Typography variant="h6" className={classes.captionText}>
              Revenue
            </Typography>
          </Box>
        </Grid>
        {!event.externalRegistrationUrl ? (
          <Grid item md={5} xs={12} className={classes.gridItem}>
            <TileIcon
              Icon={PersonOutlinedIcon}
              iconColor="rgba(131, 111, 80, 1)"
              bgColor="rgba(171, 145, 87, 0.08)"
            />
            <Box display="flex" flexDirection="column">
              <Typography variant="h4" className={classes.infoText}>
                {numberOfParticipants}
              </Typography>
              <Typography variant="h6" className={classes.captionText}>
                {pluralize(numberOfParticipants, 'Attendee')}
              </Typography>
            </Box>
          </Grid>
        ) : null}
        <Grid item md={5} xs={12} className={classes.gridItem}>
          <TileIcon
            Icon={EventOutlinedIcon}
            iconColor="#FF7500"
            bgColor="rgba(255, 117, 0, 0.08)"
          />
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" className={classes.infoText}>
              {daysUntil(registrationCloseDateTime)} days
            </Typography>
            <Typography variant="h6" className={classes.captionText}>
              Reg. deadline
            </Typography>
          </Box>
        </Grid>
        <Grid item md={5} xs={12} className={classes.gridItem}>
          <TileWrapper bgColor="rgba(0, 117, 202, 0.08)">
            <CelebrationOutlinedIcon
              style={{ color: '#0075CA', fontSize: '32px' }}
            />
          </TileWrapper>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" className={classes.infoText}>
              {toFullDateShortMonth(startDateTime)}
            </Typography>
            <Typography variant="h6" className={classes.captionText}>
              Event date
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box m={5} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          Tell us what kind of stats you'd like to see
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            showNewMessage(
              "I'd like to suggest group events stats! Please contact me with more information.",
            )
          }
        >
          share feedback
        </Button>
      </Box>
    </DialogContent>
  )
}

export default EventStats
