import React from 'react'
import { PGA_COM_HOST } from 'env'
import CoachAppBar from 'components/coach-app-bar'
import { withAuthenticatedRoute } from 'lib/auth'
import useStyles, { ContainerRoot, StyledDivider } from './styles'
import { IconButtonBackArrow } from 'components/atoms/icons'
import Typography from '@material-ui/core/Typography'
import programs from './mocked-programs'
import GroupEventCard from 'components/group-event-card/group-event-card'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import CondensedFooter from 'components/condensed-footer'
import PGALogo from 'images/pga-logo-navy.png'
import { useIsMobile } from 'lib/hooks'
import Button from '@material-ui/core/Button'

const SpecialtyPrograms = () => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  return (
    <>
      <CoachAppBar position="relative" hideContactButton />
      <ContainerRoot>
        <Button
          href={`${PGA_COM_HOST}/pga-coaching-center-at-pga-frisco`}
          startIcon={<IconButtonBackArrow />}
          className={classes.backButton}
        >
          Coaching Center Website
        </Button>
        <Box
          display="flex"
          justifyContent={isMobile ? 'center' : 'start'}
          mt={2}
          mb={4}
        >
          <img src={PGALogo} alt="PGA logo" width="110px" />
        </Box>
        <Typography variant="h2" gutterBottom>
          Specialty Programs
        </Typography>
        <Typography variant="body1">
          Discover the diverse programming offered by the PGA Coaching Center,
          crafted to meet you at every stage of your golfing journey. View the
          specialty programs and contact our PGA Coaching Center staff to
          arrange and schedule.
        </Typography>
        <StyledDivider />
        <Grid container spacing={3} className={classes.programsContainer}>
          {programs
            .filter(program => program.showOnProgramsPage)
            .map((program, index) => (
              <Grid key={index} item md={4} lg={4} style={{ display: 'flex' }}>
                <GroupEventCard event={program} showEventDetails />
              </Grid>
            ))}
        </Grid>
      </ContainerRoot>
      <CondensedFooter />
    </>
  )
}

export default withAuthenticatedRoute(SpecialtyPrograms)
