import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PGAIconSVG from 'images/pga-seal-icon.svg'

const TipWell = ({ caption }) => {
  return (
    <Box
      px={2}
      py={1}
      display="flex"
      alignItems="center"
      borderRadius={4}
      style={{ backgroundColor: 'rgba(0, 35, 75, 0.08)' }}
    >
      <Box mr={1.5} width="24px">
        <Box height="24px" mb={0.25}>
          <img
            src={PGAIconSVG}
            width="24px"
            height="24px"
            alt="PGA of America seal"
          />
        </Box>
        <Typography
          align="center"
          color="primary"
          style={{
            fontSize: '12px',
            letterSpacing: '0.4px',
            fontWeight: 'bold',
          }}
        >
          TIP
        </Typography>
      </Box>
      <Box mr={1.5} bgcolor="primary.main" width="1px" height="40px" />
      <Typography variant="body2">{caption}</Typography>
    </Box>
  )
}

export default TipWell
