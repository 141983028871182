import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import { IconButtonBackArrow } from 'components/atoms/icons'
import useStyles from './styles'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import mockedPrograms from './mocked-programs'
import RouterLinkButton from 'components/router-link-button'
import CoachAppBar from 'components/coach-app-bar'
import CondensedFooter from 'components/condensed-footer'
import ProgramInterestForm from './program-interest-form'
import SuccessPage from './success-page'
import ProgramInfo from './program-info'

const ProgramInterest = () => {
  const classes = useStyles()
  const location = useLocation()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const { programId } = qs.parse(location.search)
  const program = mockedPrograms.find(program => program.id === programId)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isFormSubmitted])

  if (isFormSubmitted) {
    return <SuccessPage program={program} />
  }

  return (
    <>
      <CoachAppBar position="relative" hideContactButton />
      <Container maxWidth="md">
        <RouterLinkButton
          startIcon={<IconButtonBackArrow />}
          className={classes.backButton}
          to="/pga-coaching-center-at-pga-frisco/specialty-programs"
        >
          View All Programs
        </RouterLinkButton>
        <ProgramInfo program={program} />
        <ProgramInterestForm
          program={program}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      </Container>
      <CondensedFooter />
    </>
  )
}

export default ProgramInterest
