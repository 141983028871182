import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { HelperMsg } from 'components/coaches-profile/profile.styles'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CustomSlug from '../form-components/CustomSlug'
import {
  getSuggestions,
  ProfileNameTmpls,
} from 'pages/pga-coach/profile/new/steps/profile-name'
import { useStyles } from './styles'

const ProfileName = ({ name }) => {
  const { errors, getValues, control, setValue } = useFormContext()
  const { customSlug } = getValues()
  const classes = useStyles()
  const firstName = name
    .split(' ')
    .slice(0, -1)
    .join(' ')
  const lastName = name
    .split(' ')
    .slice(-1)
    .join(' ')

  const [suggestedIndex, setSuggestedIndex] = useState(0)

  const handleSuggestionClick = () => {
    setSuggestedIndex(prevState =>
      prevState >= ProfileNameTmpls.length - 1 ? 0 : prevState + 1,
    )
    setValue('customSlug', getSuggestions(firstName, lastName, suggestedIndex))
  }

  useEffect(() => {
    setSuggestedIndex(prevState =>
      prevState >= ProfileNameTmpls.length - 1 ? 0 : prevState + 1,
    )
    setValue('customSlug', getSuggestions(firstName, lastName, suggestedIndex))
    // eslint-disable-next-line
  }, [name])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" gutterBottom className={classes.title}>
        Set up your coach profile!
      </Typography>
      <Box className={classes.profileNameContainer}>
        <HelperMsg style={{ textAlign: 'center' }}>
          Pick a profile name.
        </HelperMsg>
        <HelperMsg style={{ marginBottom: '20px', textAlign: 'center' }}>
          This will show up in the URL of your dedicated Coach Profile page and
          can help with search results.
        </HelperMsg>
        <Controller
          name="customSlug"
          control={control}
          render={({ onChange, onBlur, value }, { isTouched }) => (
            <CustomSlug
              values={{ customSlug: value } || {}}
              handleChange={onChange}
              handleBlur={onBlur}
              touched={{ customSlug: isTouched } || {}}
              errors={errors}
              handleSuggestionClick={handleSuggestionClick}
            />
          )}
        />
        <Typography variant="caption" color="textSecondary">
          PREVIEW
        </Typography>
        <Typography variant="body1" style={{ overflowWrap: 'anywhere' }}>
          https://www.pga.com/coach/
          <span className={classes.highlight}>{customSlug}</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default ProfileName
