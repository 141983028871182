import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_STUDENT_QUERY } from '../queries'
import ApolloDataView from 'components/apollo-data-view'
import NotFound from 'components/not-found.js'
import LoadingView from '../../../pga-coach/students/loading-view'
import { roles, withProtectedRoute } from 'lib/auth'
import EditStudentForm from './edit-details-form'

const GolferAccountDetails = () => {
  const { studentId } = useParams()
  const { data, loading, error } = useQuery(GET_STUDENT_QUERY, {
    variables: { id: studentId },
  })

  return (
    <ApolloDataView error={error} loading={loading} data={data?.student}>
      {{
        errorView: (
          <NotFound
            subtext={'Golfer was not found.'}
            returnUrl={'/account/students'}
          />
        ),
        emptyView: (
          <NotFound
            subtext={'Golfer was not found.'}
            returnUrl={'/account/students'}
          />
        ),
        loadingView: <LoadingView />,
        dataView: student => <EditStudentForm student={student} />,
      }}
    </ApolloDataView>
  )
}

export default withProtectedRoute(GolferAccountDetails, [roles.CONSUMER])
