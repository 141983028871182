import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SITE_PRIMARY = '#836F50'

export default ({ fill }) => (
  <SvgIcon>
    <svg width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.173 4.712c1.484 0 2.907.573 3.957 1.592a5.354 5.354 0 0 1 1.638 3.843 5.532 5.532 0 0 1-.886 2.944c-1.121 1.802-4.14 6.131-4.269 6.314a.528.528 0 0 1-.437.228.529.529 0 0 1-.437-.228c-.128-.183-3.147-4.518-4.268-6.314a5.54 5.54 0 0 1-.894-2.946c0-1.44.59-2.822 1.639-3.842a5.683 5.683 0 0 1 3.957-1.59zm3.805 7.853a4.558 4.558 0 0 0 .741-2.418c0-1.577-.867-3.035-2.273-3.823a4.664 4.664 0 0 0-4.546 0c-1.407.788-2.274 2.246-2.274 3.823.013.856.27 1.693.742 2.418.857 1.374 2.906 4.348 3.805 5.643.899-1.295 2.948-4.267 3.805-5.643zM12.173 7.51a2.798 2.798 0 1 1-.002 5.597 2.798 2.798 0 0 1 .002-5.597zm0 4.546a1.749 1.749 0 1 0 0-3.497 1.749 1.749 0 0 0 0 3.497z"
          fillRule="nonzero"
          fill={fill || SITE_PRIMARY}
        />
      </g>
    </svg>
  </SvgIcon>
)
