import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import YesNoButtons from '../form-components/yes-no-buttons'

const AccessCode = () => {
  const [isYes, setIsYes] = useState(false)
  const { register, errors, watch, setValue } = useFormContext()

  const accessCode = watch('accessCode')
  const clearAccessCode = () => setValue('accessCode', null)

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="h6" align="center">
            Registration Access Code
          </Typography>
        </Box>
        <Typography variant="body" align="center">
          Would you like to require an access code to register for this
          offering?
        </Typography>
      </Box>
      <Box mb={4}>
        <YesNoButtons {...{ isYes, setIsYes }} dataCy="accessCodeToggle" />
      </Box>
      {isYes && (
        <Container maxWidth="xs">
          <TextField
            name="accessCode"
            type="text"
            inputRef={register}
            error={!!errors.accessCode}
            label="Access Code"
            disabled={!isYes}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={!accessCode} onClick={clearAccessCode}>
                    <ClearOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={errors?.accessCode?.message}
            fullWidth
          />
        </Container>
      )}
    </Box>
  )
}

export default AccessCode
