import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { get } from 'lodash'
import {
  HelperMsg,
  TestimonyFields,
  Testimony,
} from 'components/coaches-profile/profile-stepper.styles'
import Button from '@material-ui/core/Button'
import { IconTrashcan } from 'components/atoms/icons'

const useStyles = makeStyles(theme => ({
  testimonialsContainer: {
    display: 'revert',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .text-input:not(.last-input)': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

const MAX_TESTIMONIALS_COUNT = 5
const testimonialsMsg = `Add up to ${MAX_TESTIMONIALS_COUNT} testimonials.`

export const Testimonials = () => {
  const classes = useStyles()
  const { control, errors, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'testimonialsJsonData',
    control,
  })

  return (
    <Box className={classes.testimonialsContainer}>
      <HelperMsg style={{ marginTop: 0 }}>{testimonialsMsg}</HelperMsg>
      {fields.map((item, index) => {
        return (
          <Testimony key={item.id}>
            <TestimonyFields>
              <TextField
                multiline
                name={`testimonialsJsonData[${index}].testimonial`}
                defaultValue={item.testimonial}
                label="Testimonial"
                type="text"
                variant="outlined"
                inputRef={register()}
                className="text-input multiline"
                error={
                  !!get(errors, ['testimonialsJsonData', index, 'testimonial'])
                }
                helperText={get(errors, [
                  'testimonialsJsonData',
                  index,
                  'testimonial',
                  'message',
                ])}
              />
              <TextField
                name={`testimonialsJsonData[${index}].author`}
                defaultValue={item.author}
                label="Attestant"
                type="text"
                inputRef={register()}
                variant="standard"
                className="text-input"
                error={!!get(errors, ['testimonialsJsonData', index, 'author'])}
                helperText={get(errors, [
                  'testimonialsJsonData',
                  index,
                  'author',
                  'message',
                ])}
              />
              <input
                type="hidden"
                ref={register()}
                name={`testimonialsJsonData[${index}].rank`}
                value={index + 1}
              />
            </TestimonyFields>
            <Button
              style={{ padding: '8px 40px' }}
              variant="text"
              size="large"
              type="button"
              onClick={() => remove(index)}
            >
              <IconTrashcan />
              Delete
            </Button>
          </Testimony>
        )
      })}
      <Box>
        <Button
          style={{ marginTop: '20px' }}
          variant="outlined"
          color="primary"
          size="large"
          type="button"
          onClick={() => {
            append({ testimonial: '', author: '' })
          }}
          disabled={fields.length >= MAX_TESTIMONIALS_COUNT}
        >
          Add Testimonial
        </Button>
      </Box>
    </Box>
  )
}

export default Testimonials
