import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import useQueryString from 'lib/hooks/use-query-string'

const CREATE_PAYMENT_ACCOUNT = gql`
  mutation CreatePaymentAccount($input: CreatePaymentAccountInput!) {
    createPaymentAccount(input: $input) {
      success
      message
      paymentAccount {
        id
      }
    }
  }
`

const NewPaymentAccountCallbackPage = () => {
  const { code, state } = useQueryString()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [createPaymentAccount] = useMutation(CREATE_PAYMENT_ACCOUNT, {
    onCompleted: data => {
      // TODO -- navigate to specific payment account page
      console.log(data)
      history.push('/account/payments/payment-accounts')
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', { error, tags: ['payment-accounts'] })
      enqueueSnackbar(
        'An unexpected error occurred while finalizing your payment account. Please try again later.',
        { variant: 'error' },
      )
      history.push('/account/payments/payment-accounts')
    },
  })

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    createPaymentAccount({
      variables: { input: { name: state, authorizationCode: code } },
    })
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      <LinearProgress color="primary" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Box mb={2}>
          <CircularHighlightedIcon icon={CreditCardOutlinedIcon} />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Finalizing your payment account</Typography>
        </Box>
        <Box textAlign="center">
          <Typography variant="body1" color="textSecondary">
            Just a moment...
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default NewPaymentAccountCallbackPage
