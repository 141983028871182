import { makeStyles, Typography, Grid } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  rowWrapped: {
    margin: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: '10px',
  },
  iconStyled: {
    marginRight: '10px',
  },
  actionStyled: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  facilityWrapped: {
    display: 'flex',
    flexDirection: 'column',
  },
  enrollmentsWrapped: {
    marginLeft: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  responsiveButton: {
    maxHeight: '4rem',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.3px',
    fontFamily: 'Roboto',
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      margin: '.75rem',
    },
  },
}))

const StyledGrid = styled(Grid)(({ isMobile, isTablet }) => ({
  display: 'flex',
  flexDirection: isMobile || isTablet ? 'column' : 'row',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '24px 0 0',
  maxWidth: '952px',
  width: '100%',
  minHeight: '86px',
  padding: '8px 1rem',
  alignSelf: 'center',
  borderRadius: '9px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #2196F3',
}))

const StyledText = styled(Typography)(() => ({
  color: '#00234B',
  lineHeight: '24px',
  fontWeight: '400',
  fontSize: '16px',
}))

const StyledSubGrid = styled(Grid)(({ isTablet }) => ({
  padding: isTablet ? '.5rem 1rem' : '0 1rem 0 .5rem',
}))

export { useStyles, StyledGrid, StyledText, StyledSubGrid }
