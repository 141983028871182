import React from 'react'
import { DateTime } from 'luxon'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined'
import green from '@material-ui/core/colors/green'
import Dialog from 'components/dialog'
import RecordBookingPaymentForm from 'components/record-booking-payment-form'

const RecordBookingPaymentDialog = ({
  enrollment,
  eventTitle,
  eventDate,
  participantName,
  defaultAmountInCents,
  acceptOnlinePayments,
  onPaymentSuccess,
  trigger,
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" trigger={trigger} showCloseButton>
      {({ closeDialog }) => (
        <DialogContent>
          <Box p={2}>
            <Box mb={2.5}>
              <Typography variant="h6" gutterBottom>
                Event Transaction
              </Typography>
              <Typography variant="body2" gutterBottom>
                Record payment for this event
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={64}
                height={64}
                borderRadius={4}
                style={{ backgroundColor: green[50] }}
                mr={2}
              >
                <PaymentOutlinedIcon style={{ color: green[500] }} />
              </Box>
              <Box>
                <Typography variant="subtitle1">{participantName}</Typography>
                <Typography variant="body2">
                  {eventTitle} • {DateTime.fromISO(eventDate).toFormat('DD')}
                </Typography>
              </Box>
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <RecordBookingPaymentForm
              enrollment={enrollment}
              defaultAmountInCents={defaultAmountInCents}
              acceptOnlinePayments={acceptOnlinePayments}
              onPaymentSuccess={() => {
                closeDialog()
                onPaymentSuccess && onPaymentSuccess()
              }}
            />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default RecordBookingPaymentDialog
