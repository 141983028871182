import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import { PageHeader } from 'components/page-header'
import IconCard from 'components/icon-card'

const CoachMenuOptions = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Grid item md={6}>
        <IconCard
          title="Online Payment Accounts"
          actions={[
            {
              to: `${path}/payment-accounts`,
              text: 'MANAGE ONLINE PAYMENT ACCOUNTS',
            },
          ]}
          icon={ContactlessOutlinedIcon}
          variant="vertical"
        >
          Manage all your online payment accounts in one place.
        </IconCard>
      </Grid>
      <Grid item md={6}>
        <IconCard
          title="Transactions"
          actions={[
            { to: `${path}/transactions`, text: 'MANAGE TRANSACTIONS' },
          ]}
          icon={CreditCardOutlinedIcon}
          variant="vertical"
        >
          View all transactions associated with your coaching business.
        </IconCard>
      </Grid>
    </>
  )
}

const ConsumerMenuOptions = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Grid item md={6}>
        <IconCard
          title="Transactions"
          actions={[{ to: `${path}/transactions`, text: 'VIEW TRANSACTIONS' }]}
          icon={CreditCardOutlinedIcon}
          variant="vertical"
        >
          View all transactions associated with your purchases.
        </IconCard>
      </Grid>
    </>
  )
}

const PaymentsPage = () => {
  const { isCoach } = useAuth()
  const { path } = useRouteMatch()
  const accountPath = isCoach
    ? path
      .split('/')
      .slice(0, 2)
      .join('/')
    : ''
  const crumbs = [
    {
      label: 'Account',
      to: accountPath,
    },
  ]
  return (
    <>
      <PageHeader title="Payments" crumbs={crumbs} />
      <Box my={6}>
        <Container maxWidth="md">
          <Grid container direction="row" spacing={3}>
            {isCoach ? <CoachMenuOptions /> : <ConsumerMenuOptions />}
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default withProtectedRoute(PaymentsPage, [roles.COACH, roles.CONSUMER])
