import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress'
import Container from '@material-ui/core/Container'
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined'
import { CoachReviewsQuery } from './query'
import { PageHeader } from 'components/page-header'
import RouterLinkButton from 'components/router-link-button'
import CoachReviews from 'components/coach-reviews'
import AskForReviewsModal from 'components/coach-reviews-request'
import EmptyReviews from 'components/empty-page-state'
import Error from 'components/error'

export default () => {
  const { data, loading, error, refetch } = useQuery(CoachReviewsQuery)
  const [open, setOpen] = useState(false)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error />

  const coachReviews = get(data, 'GetCoachReviews.coachReviews', []) || []
  const coachProfile = get(coachReviews, '[0].coachProfile', []) || []

  const completedReviews = coachReviews
    .filter(review => review)
    .filter(({ status }) => status === 'COMPLETE')

  return (
    <>
      <PageHeader
        title="Coach Reviews"
        actions={
          completedReviews.length ? (
            <RouterLinkButton
              variant="contained"
              color="primary"
              onClick={() => setOpen(!open)}
            >
              Ask For Reviews
            </RouterLinkButton>
          ) : null
        }
        fixedMobileActions
      />
      <Container>
        {completedReviews.length ? (
          <CoachReviews
            coachProfile={coachProfile}
            coachReviews={data.GetCoachReviews}
          />
        ) : (
          <EmptyReviews
            actionFn={() => setOpen(true)}
            IconComp={() => <RateReviewOutlinedIcon fontSize="large" />}
            title="Reviews"
            caption="Feedback from your students will appear here."
            buttonText="Ask for Reviews"
          />
        )}
      </Container>
      <AskForReviewsModal {...{ coachReviews, refetch, open, setOpen }} />
    </>
  )
}
