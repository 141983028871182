import { gql } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'

export const GET_STUDENTS = gql`
  ${GolferProfileFragment}
  query GetStudents {
    currentUser {
      representatives {
        relationshipType
        student {
          id
          firstName
          lastName
          isMinor
          golferProfile {
            ...GolferProfileAttributes
          }
          contact {
            email
            phone
          }
        }
      }
    }
  }
`
