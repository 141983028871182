import { NODE_ENV } from 'env'
import {
  ASSUME_USER_COOKIE,
  ASSUME_USER_RETURN_URL_COOKIE,
  ASSUME_USER_ASSUMING_USER,
} from './constants'

const TEN_YEARS = 10 * 365 * 24 * 60 * 60

// Pass a "true" into hideSensitiveInformation through the assume-user dialog
// in order to restrict access to some functionality.
const assumeUser = ({ userType, id, hideSensitiveInformation }) => {
  setCookieValue(ASSUME_USER_COOKIE, `${userType}/${id}`)
  setCookieValue(
    ASSUME_USER_RETURN_URL_COOKIE,
    encodeURIComponent(window.location.href),
  )
  hideSensitiveInformation &&
    setCookieValue(ASSUME_USER_ASSUMING_USER, hideSensitiveInformation)
  window.location.href = userType === 'Coach' ? '/pga-coach' : '/club'
}

const clearAssumedUser = (redirect = true) => {
  const returnUrl = getCookieValue(ASSUME_USER_RETURN_URL_COOKIE)

  clearCookie(ASSUME_USER_COOKIE)
  clearCookie(ASSUME_USER_RETURN_URL_COOKIE)
  clearCookie(ASSUME_USER_ASSUMING_USER)

  if (redirect) {
    window.location.href = returnUrl
  }
}

const getAssumedUser = () => getCookieValue(ASSUME_USER_COOKIE)

const getAssumingUserRestrictions = () =>
  getCookieValue(ASSUME_USER_ASSUMING_USER)

const getCookieValue = cookieName => {
  const cookies = document.cookie.split(';')
  const cookiePairs = cookies.map(c => c.split('='))
  const cookieKeyValue = cookiePairs.find(cp => cp[0].trim() === cookieName)
  return cookieKeyValue ? decodeURIComponent(cookieKeyValue[1]) : null
}

const setCookieValue = (cookieName, value) => {
  document.cookie = `${cookieName}=${value}; path=/; max-age=${TEN_YEARS}; SameSite=Lax; ${
    NODE_ENV !== 'development' ? 'Secure;' : ''
  }`
}

const clearCookie = cookieName => {
  document.cookie = `${cookieName}=; path=/; max-age=0;`
}

export {
  assumeUser,
  clearAssumedUser,
  getAssumedUser,
  getAssumingUserRestrictions,
}
