import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LinearProgress from '@material-ui/core/LinearProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useAuth } from 'lib/auth'
import RouterLinkButton from 'components/router-link-button'
import ExternalLinkButton from 'components/external-link-button'
import SocialShareDialog from 'components/social-share-dialog'
import { PageHeader } from 'components/page-header'
import SchedulePage from './schedule'
import OfferingsPage from './offerings'
import { gtmEvent } from 'lib/gtm'
import GET_COACH_ACCOUNT from './query'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { ReactComponent as CalendarSyncIcon } from 'images/icons/calendar-sync.svg'
import IconButton from '@material-ui/core/IconButton'
import SubscribeDialog from './schedule/subscribe-dialog'

const useStyles = makeStyles(theme => ({
  button: {
    display: 'inherit',
    minWidth: 'max-content',
  },
  buttonsWrapper: {
    display: 'flex',
    marginRight: 0,
    width: '100%',
    paddingLeft: '0.5rem',
    justifyContent: 'space-around',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'end',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      justifyContent: 'center',
    },
  },
}))

const BookingsPage = () => {
  const classes = useStyles()
  const { user } = useAuth()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  let pageName = window.location.pathname.split('/').pop()
  const { data, loading } = useQuery(GET_COACH_ACCOUNT)
  const activeGroupEvents = data?.currentUser?.groupEvents.filter(
    e => !e.isCanceled,
  )
  pageName = pageName === 'offerings' ? 'My Programs' : pageName
  const isSchedulePage = pageName === 'schedule'
  const [selectedTab, setSelectedTab] = useState(0)

  const onboardingSchedule = {
    isFacilitiesCreated: data?.currentUser?.attributes?.facilities.length > 0,
    isGroupEventCreated: activeGroupEvents?.length > 0,
    isAvailable: data?.coachAvailabilities?.some(a => a.timeSlots.length > 0),
    isBookingTypeCreated: data?.lessonTypes.length > 0,
    hasCoachProfile: data?.currentUser?.attributes?.coachProfile !== null,
  }

  const isOnboardingComplete =
    onboardingSchedule.isGroupEventCreated ||
    onboardingSchedule.isBookingTypeCreated

  const ShareBookingDialog = () => (
    <SocialShareDialog
      trigger={
        <Button variant="outlined" color="primary">
          Share Booking Link
        </Button>
      }
      shareUrl={user.coach.bookingUrl}
      shareTitle="My Offerings"
      dialogTitle="Share this booking link with your students"
      showBookingUrl={true}
      att="booking-link"
    />
  )

  const handleBookStudentClick = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-session-button',
    })
  }

  const actionButton = isSchedulePage ? (
    <>
      {!isMobile ? (
        <>
          <SubscribeDialog
            trigger={
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                startIcon={<CalendarSyncIcon />}
                className={classes.button}
              >
                Subscribe
              </Button>
            }
          />
          <RouterLinkButton
            variant="outlined"
            color="primary"
            size="medium"
            to="/pga-coach/settings"
            startIcon={<SettingsOutlinedIcon />}
            className={classes.button}
          >
            Settings
          </RouterLinkButton>
        </>
      ) : null}
      {!isMobile && isOnboardingComplete ? (
        <>
          <ExternalLinkButton
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            href={user.coach.bookingUrl}
            target="_self"
          >
            Book a Student
          </ExternalLinkButton>
        </>
      ) : null}
      {isMobile && isOnboardingComplete ? (
        <>
          <ExternalLinkButton
            variant="contained"
            color="primary"
            href={user.coach.bookingUrl}
            onClick={handleBookStudentClick}
            target="_self"
            data-testid="book-student"
          >
            Book a Student
          </ExternalLinkButton>
        </>
      ) : null}
    </>
  ) : (
    <>
      {!isMobile && (
        <RouterLinkButton
          variant="outlined"
          color="primary"
          size="medium"
          to="/pga-coach/settings"
          startIcon={<SettingsOutlinedIcon />}
          className={classes.button}
        >
          Settings
        </RouterLinkButton>
      )}
      <ShareBookingDialog />
      {isOnboardingComplete && (
        <ExternalLinkButton
          variant="contained"
          color="primary"
          href={user.coach.bookingUrl}
          onClick={handleBookStudentClick}
          target="_self"
          data-testid="book-student"
        >
          Book a Student
        </ExternalLinkButton>
      )}
    </>
  )

  const actions = <Box className={classes.buttonsWrapper}>{actionButton}</Box>
  const isMobileActionEmpty = actionButton.props.children.every(e => e === null)

  useEffect(() => {
    if (isMobile) {
      const launcher = document.querySelector(
        '.intercom-lightweight-app-launcher',
      )

      if (launcher) {
        launcher.style.bottom = '60px'
      }

      return () => {
        const launcher = document.querySelector(
          '.intercom-lightweight-app-launcher',
        )

        if (launcher) {
          launcher.style.bottom = '30px'
        }
      }
    }
  }, [isMobile])

  if (loading) return <LinearProgress color="secondary" />

  return (
    <>
      <PageHeader
        title={pageName}
        tabs={
          isSchedulePage ? (
            <Tabs
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value)}
            >
              <Tab label="Upcoming" />
              <Tab label="Past" />
            </Tabs>
          ) : null
        }
        actions={actions}
        fixedMobileActions={isMobile && !isMobileActionEmpty}
        mobileCornerAction={
          <>
            <SubscribeDialog
              trigger={
                <Box mr={2}>
                  <IconButton style={{ padding: 0 }} color="primary">
                    <CalendarSyncIcon />
                  </IconButton>
                </Box>
              }
            />
            <IconButton
              style={{ padding: 0 }}
              color="primary"
              href="/pga-coach/settings"
            >
              <SettingsOutlinedIcon />
            </IconButton>
          </>
        }
      />
      {isSchedulePage ? (
        <SchedulePage
          selectedTab={selectedTab}
          onboardingSchedule={onboardingSchedule}
        />
      ) : (
        <OfferingsPage hasCoachProfile={onboardingSchedule.hasCoachProfile} />
      )}
    </>
  )
}

export default BookingsPage
