import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Pagination from 'components/pagination'
import CoachAverageRatingCard from 'components/coach-average-rating-card'
import CoachReviewCard from './coach-review-card'
import useStyles from './coach-reviews.styles'

const CoachReviews = props => {
  const { coachProfile, coachReviews } = props
  const styles = useStyles()
  const REVIEWS_ON_THE_PAGE = 4
  const reviews = coachReviews.coachReviews.filter(
    ({ status }) => status === 'COMPLETE',
  )
  const [offset, setOffset] = useState(0)
  const [currentReviews, setCurrentReviews] = useState(
    reviews.slice(0, REVIEWS_ON_THE_PAGE),
  )
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const handlePagination = newOffset => {
    setOffset(newOffset)
    setCurrentReviews(reviews.slice(newOffset, newOffset + REVIEWS_ON_THE_PAGE))
  }

  const loadMoreReviews = e => {
    e.preventDefault()
    setOffset(offset + REVIEWS_ON_THE_PAGE)
    setCurrentReviews(reviews.slice(0, offset + 2 * REVIEWS_ON_THE_PAGE))
  }

  return (
    <Grid container spacing={2} justify="center" className={styles.gridRoot}>
      <Grid item xs={12} md={2}>
        <CoachAverageRatingCard
          profile={coachProfile}
          rate={coachReviews.averageRating}
        />
      </Grid>
      <Grid
        item
        container
        spacing={isMobile ? 0 : 2}
        xs={12}
        md={10}
        alignItems="center"
        justify="center"
      >
        {currentReviews.map(review => {
          return (
            <Grid item md={6} sm={12} xs={12} key={review.id}>
              <CoachReviewCard isMobile={isMobile} review={review} />
            </Grid>
          )
        })}
      </Grid>
      {isMobile ? (
        <Button
          variant="contained"
          className={styles.loadAction}
          endIcon={<ArrowDownwardIcon className={styles.arrowDownwardIcon} />}
          onClick={loadMoreReviews}
          disabled={currentReviews.length >= reviews.length}
        >
          load more
        </Button>
      ) : (
        <Pagination
          limit={REVIEWS_ON_THE_PAGE}
          offset={offset}
          total={reviews.length}
          onClick={(e, newOffset) => handlePagination(newOffset)}
        />
      )}
    </Grid>
  )
}

CoachReviews.propTypes = {
  coachProfile: PropTypes.object,
  coachReviews: PropTypes.object,
}

export default CoachReviews
