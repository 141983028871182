import { PGA_COM_HOST } from 'env'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-dom-confetti'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PartyPopperIcon from 'components/icons/party-popper-icon'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import SocialSharePopover from 'components/social-share-popover'
import useStyles from './group-event-registration-form.styles'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import { SignupStyledStepper } from 'components/signup-util'
import StepIcon from './stepIcon'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { StepLabels } from './steps'

const confettiConfig = {
  angle: '219',
  spread: 360,
  startVelocity: '50',
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '1',
  width: '12px',
  height: '8px',
  perspective: '499px',
  colors: ['#00234B', '#AB9157', '#043362', '#BBA778'],
}

const RegistrationComplete = ({
  groupEvent,
  registrationEmail = '',
  isMobile = false,
}) => {
  const [isConfettiEnabled, setIsConfettiEnabled] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsConfettiEnabled(true)
    }, 500)
  })

  const classes = useStyles()
  const groupEventLink = `${PGA_COM_HOST}/things-to-do/events/${groupEvent.slug}`
  const uniqueSteps = [...new Set(Object.values(StepLabels))]
  return (
    <>
      {!isMobile && (
        <Container maxWidth="sm" disableGutters>
          <SignupStyledStepper alternativeLabel activeStep={3}>
            {uniqueSteps.map(step => {
              return (
                <Step key={step}>
                  <StepLabel StepIconComponent={StepIcon}>{step}</StepLabel>
                </Step>
              )
            })}
          </SignupStyledStepper>
        </Container>
      )}
      <Container
        className={classes.formStateContainer}
        style={{ marginTop: '1rem', minHeight: 'auto' }} // override class value
        maxWidth="sm"
        disableGutters
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={6}
          px={3.75}
        >
          <Box mb={3}>
            <CircularHighlightedIcon icon={PartyPopperIcon} />
          </Box>
          <Confetti active={isConfettiEnabled} config={confettiConfig} />
          <Box mb={2}>
            <Typography variant="h4" component="h1" gutterBottom>
              Success! You have registered for
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              {groupEvent.title}
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="body1" gutterBottom className={classes.body}>
              You'll receive an email confirmation shortly to:{' '}
              <Typography variant="body1" gutterBottom className={classes.body}>
                {registrationEmail}.
              </Typography>
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.body}>
              We look forward to being a part of your golf journey!
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            <Button href={groupEventLink} className={classes.detailsButton}>
              Event Details
            </Button>
            <SocialSharePopover
              trigger={<Button className={classes.actionButton}>Share</Button>}
              url={groupEventLink}
              text={`I just registered for ${groupEvent.title} at ${groupEvent.coachFacility.name}!`}
            />
          </Box>
        </Box>
      </Container>
      <Box>
        <DownloadMyPGAsection subtitle="Connect with your coach and stay up to date on your PGA HOPE program." />
      </Box>
    </>
  )
}

export default RegistrationComplete

RegistrationComplete.propTypes = {
  groupEvent: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    coachFacility: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  completedRegistration: PropTypes.object,
}
