import React from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { withProtectedRoute, roles } from 'lib/auth'
import Toolbar from '@material-ui/core/Toolbar'
import LinearProgress from '@material-ui/core/LinearProgress'
import AppBar from '@material-ui/core/AppBar'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import AccountDropdown from 'components/account-dropdown'
import PGALogo from 'images/pga-logo-navy.png'
import ConnectionResponse from './ConnectionResponse'
import Error from 'components/error'
import { GetConnectionQuery } from './query'
import { useStyles } from './styles'

export const AcceptInvitation = ({
  history,
  connectionInfo,
  connectionRequestToken,
}) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={extendTheme(themes.pgaCoach)}>
      <Helmet>
        <title>MyPGA | Club</title>
        <meta name="description" content="MyPGA" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <SnackbarProvider>
        <AppBar position="fixed" className={classes.appBar}>
          <div className={classes.appbarContent}>
            <Toolbar className={classes.toolBar}>
              <a href="/club">
                <img src={PGALogo} alt="PGA logo" className={classes.logo} />
              </a>
            </Toolbar>
            <AccountDropdown />
          </div>
        </AppBar>
        <ConnectionResponse
          connectionRequestToken={connectionRequestToken}
          history={history}
          connectionInfo={connectionInfo}
        />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export const GetConnection = ({ history }) => {
  const { connectionId } = useParams()

  const { data, loading, error } = useQuery(GetConnectionQuery, {
    variables: { connectionRequestToken: connectionId },
  })
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />
  if (data && data.GetConnectionRequest) {
    const connectionInfo = data.GetConnectionRequest
    return (
      <AcceptInvitation
        connectionInfo={connectionInfo}
        connectionRequestToken={connectionId}
        history={history}
      />
    )
  } else {
    return <Error />
  }
}

export default withProtectedRoute(GetConnection, roles.CONSUMER)
