import { PGA_COM_HOST } from 'env'
import React from 'react'
import Container from '@material-ui/core/Container'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import EmptyPageState from './empty-page-state'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#fff',
    margin: `${theme.spacing(4)}px auto`,
  },
}))

const RegistrationFull = () => {
  const classes = useStyles()

  return (
    <Container maxWidth="sm" className={classes.container}>
      <EmptyPageState
        IconComp={() => <ErrorOutlineIcon fontSize="large" />}
        title="Registration Full"
        caption="It looks like this event is full."
        buttonText="Back to Events"
        actionFn={() => window.open(`${PGA_COM_HOST}/things-to-do/events`)}
      />
    </Container>
  )
}

export default RegistrationFull
