import { makeStyles } from '@material-ui/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import { styled, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  action: {
    marginLeft: 'auto',
  },
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 8px 24px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    minHeight: theme.spacing(9),
    [theme.breakpoints.only('xs')]: {
      minHeight: theme.spacing(12),
    },
  },
  settingsDescription: {
    marginLeft: theme.spacing(0.5),
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  settingsText: {
    marginLeft: theme.spacing(7),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(7),
    width: '80%',
  },
  settingsCaption: {
    marginLeft: theme.spacing(3),
  },
  urlTextField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    width: '70%',
  },
  saveButton: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(1.5, 3),
  },
  labelText: {
    paddingBottom: theme.spacing(3),
  },
}))

export const SettingsBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  padding: '24px 8px 24px 24px',
  marginBottom: theme.spacing(2),
  color: 'rgba(0, 0, 0, 0.87)',
  minHeight: theme.spacing(9),
}))

export const SettingsFormControlLabel = withStyles(theme => ({
  label: { marginLeft: theme.spacing(1) },
}))(FormControlLabel)

export default useStyles
