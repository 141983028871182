import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '2rem',
    color: theme.palette.primary.main,
  },
  profileNameContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  highlight: {
    color: theme.palette.secondary.dark,
  },
  helperText: {
    margin: 0,
    position: 'absolute',
    right: 0,
    top: '-16px',
  },
  inputLabel: {
    lineHeight: '21px',
    marginBottom: '4px',
  },
  facilityInput: {
    marginBottom: theme.spacing(1),
  },
  cardMedia: {
    height: '150px',
    marginBottom: '24px',
    marginLeft: '-16px',
    marginTop: '-14px',
    width: 'calc(100%  + 32px)',
  },
  iconButton: {
    transform: 'rotate(180deg)',
  },
  expanded: {
    transform: 'rotate(0deg)',
  },
  textWrapper: ({ showFullDescription }) => ({
    height: showFullDescription ? 'auto' : '4rem',
    overflow: 'hidden',
  }),
}))
