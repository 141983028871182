import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useIsTablet } from 'lib/hooks'

const StepDescriptionOfferings = ({
  stepText,
  title = 'Choose a Day and Time',
  description = 'Find a day and time that works best for your schedule below.',
}) => {
  const isTablet = useIsTablet()
  return (
    <Grid item>
      <Box>
        <Typography
          variant="body1"
          style={{ margin: '0 auto 2rem', fontWeight: '700' }}
        >
          {stepText}
        </Typography>
      </Box>
      <Typography variant={isTablet ? 'h5' : 'h4'} style={{ maxWidth: 600 }}>
        {title}
      </Typography>
      <Typography variant="body1" style={{ margin: '1rem 0px' }}>
        {description}
      </Typography>
    </Grid>
  )
}
export default StepDescriptionOfferings
