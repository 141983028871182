import { useMutation } from '@apollo/client'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { PageHeader } from 'components/page-header'
import { useIsMobile } from 'lib/hooks'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import * as yup from 'yup'
import {
  UPDATE_STUDENT,
  UPSERT_MEDICAL_PROFILE,
} from 'pages/pga-coach/students/student-edit-page/api-hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormContent from 'pages/pga-coach/students/student-edit-page/form-container'
import Button from '@material-ui/core/Button'
import {
  ContactInfoFields,
  GuardianNameFields,
  StudentNameFields,
} from 'pages/pga-coach/students/student-edit-page/form-fields'
import { gtmEvent } from 'lib/gtm'
import { stripPhoneNumber } from 'utils/phoneNumber'
import MedicalProfile from './medical-profile'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    minWidth: '100%',
    margin: 0,
    paddingBottom: '16px',
  },
  appBar: {
    width: '100%',
    height: '70px',
    padding: '18px 12px',
    boxSizing: 'border-box',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    justifyContent: 'flex-end',

    [theme.breakpoints.only('xs')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#fff',
      justifyContent: 'space-around',
      boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.2)',
    },
  },
  formContainer: {
    padding: `${theme.spacing(5)}px 5%`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}))

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(1)
    .required(),
  lastName: yup
    .string()
    .min(1)
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  phone: yup.string().matches(/^\d{3}-?\d{3}-?\d{4}$/, {
    // "123-456-7890" or "1234567890".
    message: 'Phone number must be 10 digits',
    excludeEmptyString: true,
  }),
  guardianFirstName: yup.string().min(1),
  guardianLastName: yup.string().min(1),
  medicalProfile: yup.object().shape({
    info: yup.string(),
  }),
})

const cleanupPhoneNumber = num => {
  if (num) {
    const cleanedNum = num.replace(/\D/g, '')
    return `${cleanedNum}`
  }
  return null
}

export default function EditStudentForm({ student }) {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const studentName = `${student?.firstName} ${student?.lastName}`
  const isMinor = student.isMinor
  const { contact = {} } = student

  const [updateStudent, { loading }] = useMutation(UPDATE_STUDENT)
  const [
    upsertMedicalProfile,
    { loading: upsertingMedicalProfile },
  ] = useMutation(UPSERT_MEDICAL_PROFILE)

  const form = useForm({
    defaultValues: {
      firstName: student.firstName || '',
      lastName: student.lastName || '',
      email: contact?.email || '',
      phone: stripPhoneNumber(contact?.phone) || '',
      guardianFirstName: contact?.firstName,
      guardianLastName: contact?.lastName,
      medicalProfile: {
        info: student.medicalProfile?.info || '',
      },
    },
    resolver: yupResolver(validationSchema),
  })

  const { handleSubmit, formState } = form
  const { dirtyFields } = formState

  const handleResponses = (
    updateStudentResponse,
    upsertMedicalProfileResponse,
  ) => {
    const updateStudentSuccess =
      updateStudentResponse.data?.updateStudent.success
    const upsertMedicalProfileSuccess =
      upsertMedicalProfileResponse.data?.upsertMedicalProfile?.id

    if (updateStudentSuccess && upsertMedicalProfileSuccess) {
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'edit-student',
        formAction: 'student-edited-student-details',
        metaData: dirtyFields,
      })
      enqueueSnackbar('Account updated', {
        variant: 'success',
      })
      history.push(url)
    } else {
      const errorMessage = []
      if (!updateStudentSuccess) {
        errorMessage.push(updateStudentResponse.data?.updateStudent.message)
      }
      if (!upsertMedicalProfileSuccess) {
        errorMessage.push('Allergies and Medications could not be updated')
      }
      enqueueSnackbar(errorMessage.join('. '), { variant: 'error' })
    }
  }

  const onSubmit = async formValues => {
    formValues.phone = cleanupPhoneNumber(formValues.phone)
    const { medicalProfile, ...updateStudentValues } = formValues
    try {
      const updateStudentResponse = await updateStudent({
        variables: { studentId: student.id, inputs: updateStudentValues },
      })

      const upsertMedicalProfileResponse = await upsertMedicalProfile({
        variables: { studentId: student.id, inputs: formValues.medicalProfile },
      })

      handleResponses(updateStudentResponse, upsertMedicalProfileResponse)
    } catch (e) {
      enqueueSnackbar('Sorry, the account could not be updated', {
        variant: 'error',
      })
    }
  }

  const url = `${path
    .split('/')
    .slice(0, 3)
    .join('/')}/${student.id}`

  const crumbs = [
    {
      label: 'Accounts',
      to: `${path
        .split('/')
        .slice(0, 2)
        .join('/')}/students`,
    },
    {
      label: studentName,
      to: url,
    },
  ]

  if (loading || upsertingMedicalProfile) return <LinearProgress />

  return (
    <Box className={classes.root}>
      <PageHeader title="Edit Account" crumbs={crumbs} isMobile={isMobile} />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.appBar}>
            <Button component={Link} to={url}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
          <Grid container className={classes.formContainer}>
            <Grid item xs={12}>
              <FormContent title="Participant Information">
                <StudentNameFields />
                <Typography variant="subtitle1" style={{ marginTop: '2rem' }}>
                  {isMinor ? 'Guardian' : 'Contact'} Information
                </Typography>
                {isMinor ? <GuardianNameFields /> : null}
                <ContactInfoFields />
                <MedicalProfile />
              </FormContent>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  )
}
