import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'

import { GetMyLessons } from './query'
import SessionCard from './session-card'
import SessionDetailModal from './session-detail'
import { parseLessons } from './utils'
import { useStyles } from './styles'

import { DateTime } from 'luxon'

const ScheduleSection = ({ collection, sectionTitle, setOpenLesson }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrappedSection}>
      <Typography variant="h6">{sectionTitle}</Typography>
      {collection.map(lesson => (
        <SessionCard
          key={lesson.id}
          lesson={lesson}
          setOpenLesson={setOpenLesson}
        />
      ))}
    </div>
  )
}

const PastLessons = ({ lessons, refetchLessons }) => {
  const [openLesson, setOpenLesson] = useState({})

  const classes = useStyles()
  return (
    <>
      {lessons.allOthers.length ? (
        <ScheduleSection
          {...{
            collection: lessons.allOthers,
            sectionTitle: 'Past Sessions',
            openLesson,
            setOpenLesson,
          }}
        />
      ) : (
        <div className={classes.noSessionsWrapped}>
          <EventNoteOutlinedIcon
            fontSize="large"
            className={classes.calendarIconStyles}
          />
          <Typography variant="body">You have no past sessions</Typography>
        </div>
      )}
      <SessionDetailModal {...{ openLesson, setOpenLesson, refetchLessons }} />
    </>
  )
}

export default ({ studentId, classes }) => {
  const { data, loading, error, refetch } = useQuery(GetMyLessons, {
    variables: {
      studentId,
      orderBy: {
        field: 'START_DATE_TIME',
        direction: 'ASC',
      },
      startDateTime: { to: DateTime.local().toISODate() },
    },
  })
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Box>ERROR</Box>

  const lessons = get(data, 'currentUser.lessons')

  return (
    <>
      <div className={classes.text}>
        <Typography variant="caption" style={{ fontSize: '14px' }}>
          Select a session card to view more information.
        </Typography>
      </div>
      <PastLessons
        lessons={parseLessons(lessons, studentId)}
        refetchLessons={refetch}
      />
    </>
  )
}
