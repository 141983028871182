import React from 'react'
import MobileLayout from './mobile-layout'
import DesktopLayout from './desktop-layout'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'

const FormLayout = ({
  isMobile,
  groupEvent,
  paymentRequired,
  setPaymentRequired,
  showWaiver,
  form,
  shouldShowWaiver,
  setShowWaiver,
  paymentErrorMessage,
  marketingOptIn,
  marketingOptInChecked,
  handleMarketingOptInChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const handleRegisterClick = async () => {
    if (shouldShowWaiver) {
      const isValid = await form.trigger(['contact', 'participants', 'payment'])
      if (isValid && paymentErrorMessage) {
        enqueueSnackbar(paymentErrorMessage, {
          variant: 'error',
        })
      }
      if (isValid && !paymentErrorMessage) {
        setShowWaiver(true)
      }
    }
  }
  return (
    <Box display={showWaiver ? 'none' : 'block'}>
      {isMobile ? (
        <MobileLayout
          groupEvent={groupEvent}
          paymentRequired={paymentRequired}
          setPaymentRequired={setPaymentRequired}
          handleRegisterClick={handleRegisterClick}
          shouldShowWaiver={shouldShowWaiver}
          marketingOptIn={marketingOptIn}
          marketingOptInChecked={marketingOptInChecked}
          handleMarketingOptInChange={handleMarketingOptInChange}
        />
      ) : (
        <DesktopLayout
          groupEvent={groupEvent}
          paymentRequired={paymentRequired}
          setPaymentRequired={setPaymentRequired}
          handleRegisterClick={handleRegisterClick}
          shouldShowWaiver={shouldShowWaiver}
          marketingOptIn={marketingOptIn}
          marketingOptInChecked={marketingOptInChecked}
          handleMarketingOptInChange={handleMarketingOptInChange}
        />
      )}
    </Box>
  )
}

export default FormLayout
