import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 32px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: '16px 16px',
    },
  },
  grid: {
    alignItems: 'center',
  },
  chip: {
    background: '#B2EBF2',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '20px',
      marginRight: theme.spacing(1),
    },
  },
  heading: {
    borderRadius: theme.spacing(2),
    padding: '4px 14px',
    display: 'inline-block',
    marginRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      fontSize: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
}))

export default useStyles
