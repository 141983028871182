import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React from 'react'
import useStyles from './styles'

const EmptyPage = ({
  actionFn,
  IconComp,
  title,
  caption,
  captionSecondary,
  buttonText,
  removeButton,
  hideButtonMobile,
  buttonTextSecondary,
  actionFnSecondary,
}) => {
  const styles = useStyles()
  const mobileHidden = hideButtonMobile ? styles.mobileHiddenButton : ''
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton className={styles.messageIcon} onClick={actionFn}>
        <IconComp />
      </IconButton>
      <Typography variant="h5" className={styles.heading} gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        className={styles.baseText}
        gutterBottom
      >
        {caption}
        <br /> {captionSecondary}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        className={buttonTextSecondary ? styles.mobileBottomButtons : null}
      >
        {!removeButton ? (
          <Button
            variant="contained"
            color="primary"
            className={`${styles.actionButton} ${mobileHidden}`}
            onClick={actionFn}
          >
            {buttonText}
          </Button>
        ) : null}
        {buttonTextSecondary ? (
          <Button
            variant="outlined"
            color="primary"
            className={`${styles.actionButton}`}
            onClick={actionFnSecondary}
          >
            {buttonTextSecondary}
          </Button>
        ) : null}
      </Box>
    </Box>
  )
}

EmptyPage.propTypes = {
  setOpen: PropTypes.func,
}

export default EmptyPage
