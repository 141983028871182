export const COACHING_CENTER_SLUGS = ['pga-coaching-center-at-pga-frisco']
export const FRISCO_COACHING_CENTER_SLUG = 'pga-coaching-center-at-pga-frisco'
export const FRISCO_COACHING_CENTER_FACILITY_ID =
  '6bca689a-3a76-4e93-9725-f38961f67c31'
export const COACHING_CENTER_HOLIDAY_LESSON_TYPE_IDS = [
  '13d854a5-6b37-4128-8f8a-9515cb8d5b77',
  '24c59856-dc07-4b68-b6fa-53bf524858b1',
  '2bd263fa-57f7-4c8b-8a09-36160a3fecd',
  'f743bad6-6b5c-41e2-9ee3-0b85ba8e21d5',
  '3c5d0b59-fbee-41b8-a4a2-385491865e9e',
  '776d608f-dddf-408e-83b6-fef5e26505de',
]

export const BAY_RESERVATION_URL =
  '/pga-coaching-center-at-pga-frisco/reservations'

export const handednessOptions = [
  {
    value: 'Left',
    label: 'Left',
  },
  {
    value: 'Right',
    label: 'Right',
  },
]

export const INTERNAL_STAFF_OFFERING_TITLES = ['staff only']
export const PGA_STAFF_OFFERING_TEXT = 'Enter Access Code for Rate'
export const SPACE_BOOKING_LIMIT = 14 // days
export const LIGHTSPEED_VT_URL =
  'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/course/146750?parentCat=35272,31073&returnUrl=%2Fcategory%2F31073%3FparentCat%3D35272&search=&filter=all'
