import React from 'react'
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    borderTopColor: theme.palette.grey[300],
    borderTopStyle: 'solid',
    borderTopWidth: '1px',

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),

    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },

  borderBox: {
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    paddingBottom: '0.5rem',
  },

  titleContainer: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: theme.palette.grey[400],
    [theme.breakpoints.up('sm')]: {
      border: '0 none',
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  crumbTitle: {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  backButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      width:
        // 67 from the button + 16 from the padding
        'calc(67px + 16px)',
    },
  },
  backButton: {
    height: '35px',
    width: '35px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
    '& :last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column-reverse',

      '& > *': {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },

      '& :first-child': {
        marginBottom: 0,
      },
    },
  },
  mobileActionWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
      padding: '0 12px',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '12px',
    },
  },
}))

function PageCrumbs({ title, crumbs }) {
  const classes = useStyles()
  if (!crumbs) {
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map((value, index) => {
        return (
          <Link
            component={RouterLink}
            color="inherit"
            key={index}
            to={value.to}
          >
            {value.label}
          </Link>
        )
      })}
      <Typography color="textPrimary" className={classes.crumbTitle}>
        {title}
      </Typography>
    </Breadcrumbs>
  )
}

export default function PageHeader({
  title,
  crumbs,
  actions,
  tabs,
  isMobile,
  activeTab,
  ...props
}) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.borderBox} {...props}>
        <Box className={classes.headerContainer}>
          <Box className={classes.backButtonContainer}>
            {crumbs && crumbs.length > 0 ? (
              <Button component={RouterLink} to={crumbs[crumbs.length - 1].to}>
                <KeyboardArrowLeftIcon className={classes.backButton} />
              </Button>
            ) : (
              <Box className={classes.backButton} />
            )}
          </Box>
          <Box className={classes.titleContainer}>
            <Hidden xsDown>
              <PageCrumbs {...{ crumbs, title }} />
            </Hidden>
            {isMobile ? (
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.title}
                  data-testid="page-header-title"
                >
                  {title}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.title}
                data-testid="page-header-title"
              >
                {title}
              </Typography>
            )}
          </Box>
          <Hidden smDown>
            <Box className={`${classes.actionWrapper}`}>{actions}</Box>
          </Hidden>
        </Box>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Box
            className={`${classes.actionWrapper} ${classes.mobileActionWrapper}`}
          >
            {actions}
          </Box>
        </Hidden>
        <Hidden xsDown>{tabs || null}</Hidden>
      </Box>
      <Hidden smUp>
        <Box
          className={`${classes.actionWrapper} ${classes.mobileActionWrapper}`}
        >
          {actions}
        </Box>
      </Hidden>
    </>
  )
}
