import { makeStyles } from '@material-ui/core/styles'
import themes from 'themes'

export const useStyles = makeStyles(() => ({
  root: {
    margin: '2rem auto 3rem',
  },
  divider: {
    margin: '2rem 0px 1rem',
  },
  gridItem: {
    marginBottom: '3rem',
  },
  bookingButton: {
    backgroundColor: themes.offeringsRefresh.palette.primary.lightest,
    color: themes.offeringsRefresh.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: themes.offeringsRefresh.palette.primary.light,
    },
    padding: '0.5rem 1rem',
    marginTop: '1rem',
  },
}))
