import React from 'react'
import AppCard from 'components/app-card'
import FGlogo from 'images/family-golf-logo.svg'

export default function FamilyCup() {
  return (
    <AppCard
      title="Create a community at your facility by turning golf into a multi-generational team sport"
      to="/family-golf-signup"
      buttonText="launch"
      testid="family-cup-tile"
      logoStyle={{ height: '70px' }}
      logo={{
        src: FGlogo,
        alt: 'PGA Family Golf logo',
      }}
    />
  )
}
