import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '60px 0px',
  },
  select: {
    margin: '0 0 30px',
    maxWidth: '300px',
  },
}))

export default useStyles
