import React, { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles'
import { withProtectedRoute, roles } from 'lib/auth'
import themes, { extendTheme } from 'themes'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Shell from 'components/shell/shell'
import FGlogo from 'images/family-golf-logo.svg'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'

const CaptainLanding = lazy(() => import('pages/family-cup-captain/landing'))
const Events = lazy(() => import('pages/family-cup-captain/events'))
const Team = lazy(() => import('pages/family-cup-captain/team'))
const AddPlayer = lazy(() => import('pages/family-cup-captain/add-player'))
const Register = lazy(() => import('pages/family-cup-captain/register'))
const EditPlayer = lazy(() => import('pages/family-cup-captain/edit-player'))

const FCCaptainRoutes = () => {
  const { path } = useRouteMatch()

  const menuItems = [
    {
      icon: <InsertInvitationIcon />,
      label: 'Events',
      path: `${path}/events`,
      exact: true,
    },
  ]

  return (
    <ThemeProvider theme={extendTheme(themes.familyCup)}>
      <Helmet>
        <title>PGA Family Golf Management</title>
        <meta name="description" content="PGA Family Golf Management" />
        <link rel="icon" href="/family-golf-favicon.ico" />
      </Helmet>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Shell
          menuItems={menuItems}
          logo={{ src: FGlogo, alt: 'Family Golf Logo' }}
          showBrandSwitcher={false}
          showAccountDropdown={false}
        >
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route exact path={path}>
                <CaptainLanding />
              </Route>

              <Route exact path={`${path}/register/:programId`}>
                <Register />
              </Route>

              <Route exact path={`${path}/events`}>
                <Events />
              </Route>

              <Route exact path={`${path}/teams/:teamId`}>
                <Team />
              </Route>

              <Route exact path={`${path}/teams/:teamId/players/new`}>
                <AddPlayer />
              </Route>

              <Route
                exact
                path={`${path}/teams/:teamId/players/:playerId/edit`}
              >
                <EditPlayer />
              </Route>
            </Switch>
          </Suspense>
        </Shell>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(FCCaptainRoutes, roles.CONSUMER)
