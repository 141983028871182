import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

import { useStyles } from './styles'
import ShareEventPopper from '../../event-components/share-event-popper'

const ActionButtons = ({ setIsOpen, event, canEdit }) => {
  const [shareAnchor, setShareAnchor] = useState(null)
  const history = useHistory()
  const classes = useStyles()

  const handleShareOpen = e => {
    setShareAnchor(e.currentTarget)
  }

  return (
    <Box className={classes.actionButtonContainer}>
      <Avatar className={classes.actionAvatar} onClick={handleShareOpen}>
        <ShareOutlinedIcon color="primary" />
        <ShareEventPopper
          isOpen={Boolean(shareAnchor)}
          handleClose={() => setShareAnchor(null)}
          anchorEl={shareAnchor}
          url={event.eventUrl}
          title={event.title}
        />
      </Avatar>
      {canEdit ? (
        <Avatar
          className={classes.actionAvatar}
          onClick={() =>
            history.push(`/pga-coach/bookings/events/edit/${event.slug}`)
          }
        >
          <EditOutlinedIcon color="primary" />
        </Avatar>
      ) : null}
      <Avatar className={classes.actionAvatar} onClick={e => setIsOpen(false)}>
        <CloseOutlinedIcon color="primary" />
      </Avatar>
    </Box>
  )
}

export default ActionButtons
