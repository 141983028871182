import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { PageHeader } from 'components/page-header'
import { Link, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import ActivityPlansStageRoutes from 'routes/activity-plans-stage-routes'
import { urlToTitle } from '../lib/transformStage'
import useStyles from './styles'

const StagePage = () => {
  const { stage } = useParams()
  const styles = useStyles()
  const match = useRouteMatch()
  const path = match.url
  const location = useLocation()
  const isDetails =
    !location.pathname.endsWith('activities') &&
    !location.pathname.endsWith('plans')

  const crumbs = [
    {
      label: 'Activity Plans',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
    ...(isDetails
      ? [
        {
          label: urlToTitle(stage).title,
          to: location.pathname
            .split('/')
            .slice(0, 5)
            .join('/'),
        },
      ]
      : []),
  ]
  return (
    <>
      <PageHeader
        title={isDetails ? '' : urlToTitle(stage).title}
        crumbs={crumbs}
        tabs={
          <Tabs
            value={window.location.pathname}
            className={`${isDetails ? styles.hiddenTabs : ''}`}
          >
            <Tab
              label="Activities"
              value={`${path}/activities`}
              component={Link}
              to={`${path}/activities`}
            />
            <Tab
              label="Featured Plans"
              value={`${path}/plans`}
              component={Link}
              to={`${path}/plans`}
            />
          </Tabs>
        }
      />
      <ActivityPlansStageRoutes />
    </>
  )
}

export default StagePage
