import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Container from '@material-ui/core/Container'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined'
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import Grid from '@material-ui/core/Grid'
import Box from '@mui/material/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Switch from '@material-ui/core/Switch'
import TimerIcon from '@material-ui/icons/Timer'
import WebOutlinedIcon from '@material-ui/icons/WebOutlined'
import Typography from '@material-ui/core/Typography'
import { useAuth } from 'lib/auth'
import useStyles, {
  SettingsBlock,
  SettingsFormControlLabel,
} from './settings.styles'
import SettingsBox from 'components/coach-settings-box'
import Error from 'components/error'
import { PageHeader } from 'components/page-header'
import { usePortal } from 'lib/portal'
import { useSnackbar } from 'notistack'
import SocialShareDialog from 'components/social-share-dialog'
import { gtmEvent } from 'lib/gtm'

const COACH_PREFERENCES = gql`
  query CoachSettings {
    currentUser {
      attributes {
        ... on Coach {
          bookingUrl
          preferences {
            bookNowEnabled
          }
        }
      }
    }
  }
`

const TOGGLE_BOOKNOW_PREF = gql`
  mutation UpdateBookNowSetting($bookNow: Boolean!) {
    coachBookNowPreference(bookNowEnabled: $bookNow) {
      success
      message
      coach {
        preferences {
          bookNowEnabled
        }
      }
    }
  }
`

const scheduleBlocks = [
  {
    link: 'settings/availability',
    icon: <EventAvailableIcon />,
    title: 'Availability',
    subtitle:
      'Determine when you will be available to coach your students during the week at each location.',
  },
  {
    link: 'settings/blocked-times',
    icon: <EventBusyOutlinedIcon />,
    title: 'Blocked times',
    subtitle:
      'Override your availability by blocking busy times on your calendar.',
  },
  {
    link: 'settings/booking-limits',
    icon: <TimerIcon />,
    title: 'Booking & Cancellation Rules',
    subtitle:
      'Set your booking and cancellation limits to control when students can book sessions with you and if they can cancel them.',
  },
]

const programBlocks = [
  {
    link: 'bookings/offerings',
    icon: <AddToPhotosOutlinedIcon />,
    title: 'Manage Programs',
    subtitle: 'Continue to set up your PGA Schedule',
  },
]

const BookNowToggle = ({ enabled }) => {
  const [bookNowEnabled, setState] = useState(enabled)
  const [toggle] = useMutation(TOGGLE_BOOKNOW_PREF)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Switch
      checked={bookNowEnabled}
      onChange={event => {
        toggle({
          variables: { bookNow: event.target.checked },
        }).then(({ data: { coachBookNowPreference: op } }) => {
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'book-now',
            formAction: op.coach.preferences.bookNowEnabled
              ? 'book-now-enable'
              : 'book-now-disable',
          })
          enqueueSnackbar(op.message, {
            variant: op.success ? 'success' : 'error',
          })
          setState(op.coach.preferences.bookNowEnabled)
        })
      }}
    />
  )
}

const SettingsPage = () => {
  const { user } = useAuth()

  const [target] = usePortal()
  const styles = useStyles()

  const { data, loading, error } = useQuery(COACH_PREFERENCES)

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />

  return (
    <>
      <PageHeader title="Settings" actions={target} />
      <Container className={styles.root}>
        <Typography variant="h6" gutterBottom className={styles.heading}>
          Schedule
        </Typography>
        <Grid container spacing={2}>
          {scheduleBlocks.map(block => (
            <Grid item lg={7} md={8} xs={12} key={block.title}>
              <RouterLink className={styles.mainWrapper} to={block.link}>
                <SettingsBox
                  icon={<div className={styles.icon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={<ChevronRightIcon className={styles.action} />}
                />
              </RouterLink>
            </Grid>
          ))}
          <Grid item lg={7} md={8} xs={12}>
            <Typography variant="h6" className={styles.heading}>
              Coach Profile
            </Typography>
          </Grid>
          <Grid item lg={7} md={8} xs={12}>
            <RouterLink className={styles.mainWrapper} to="settings/facilities">
              <SettingsBox
                icon={
                  <div className={styles.icon}>
                    <RoomOutlinedIcon />
                  </div>
                }
                title="Manage Facilities"
                subtitle="Manage the facilities that are showcased on your profile. Your primary facility and its address is used for location-based searches on pga.com."
                action={<ChevronRightIcon className={styles.action} />}
              />
            </RouterLink>
          </Grid>
          <Grid item lg={7} md={8} xs={12}>
            <SettingsBlock className={styles.settingsDescription}>
              <SettingsFormControlLabel
                label={
                  <Typography variant="subtitle1">
                    Display the “Book Now” button on your profile
                  </Typography>
                }
                control={
                  <BookNowToggle
                    enabled={
                      data?.currentUser?.attributes?.preferences?.bookNowEnabled
                    }
                  />
                }
              />
              <Typography variant="body2" className={styles.settingsText}>
                When enabled, golfers will see a “Book Now” option on your Coach
                Profile whenever you have active offerings or events on PGA
                Coach. Additional booking behaviors may be configured in your
                Book Now Preferences.
              </Typography>
              <Typography variant="body2" className={styles.settingsText}>
                When disabled, golfers are able to message you from your
                Profile, but bookings remain private unless you share your
                Booking Link with them.
              </Typography>
            </SettingsBlock>
          </Grid>
          <Grid item lg={7} md={8} xs={12}>
            <RouterLink
              className={styles.mainWrapper}
              to="settings/booking-url"
            >
              <SettingsBox
                icon={
                  <div className={styles.icon}>
                    <WebOutlinedIcon />
                  </div>
                }
                title='"Book Now" Preferences'
                subtitle="Determine the system you would like to use to collect and manage registrations for your programs."
                action={<ChevronRightIcon className={styles.action} />}
              />
            </RouterLink>
          </Grid>
          <Grid item lg={7} md={8} xs={12}>
            <Typography variant="h6" className={styles.heading}>
              Your Programs
            </Typography>
          </Grid>
          {programBlocks.map(block => (
            <Grid item lg={7} md={8} xs={12} key={block.title}>
              <RouterLink className={styles.mainWrapper} to={block.link}>
                <SettingsBox
                  icon={<div className={styles.icon}>{block.icon}</div>}
                  title={block.title}
                  subtitle={block.subtitle}
                  action={<ChevronRightIcon className={styles.action} />}
                />
              </RouterLink>
            </Grid>
          ))}
          <Grid item lg={7} md={8} xs={12} className={styles.clickable}>
            <SocialShareDialog
              trigger={
                <Box className={styles.settingsWrapper}>
                  <div className={styles.icon}>
                    <LinkOutlinedIcon />
                  </div>
                  <Box className={styles.settingsCaption}>
                    <Typography variant="subtitle1">
                      Share Booking Link with Your Students
                    </Typography>
                    <Typography variant="body2">
                      Copy your Coach Offerings link and share it via text,
                      email, or social media.
                    </Typography>
                  </Box>
                </Box>
              }
              shareUrl={
                data?.currentUser?.attributes?.bookingUrl ||
                user?.coach?.bookingUrl
              }
              shareTitle="My Offerings"
              dialogTitle="Share this booking link with your students"
              showBookingUrl={true}
              att="booking-link"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default SettingsPage
