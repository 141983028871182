import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { gql, useQuery } from '@apollo/client'

const GET_PGA_MEMBERSHIP = gql`
  query CoachAccount {
    currentUser {
      attributes {
        ... on Coach {
          pgaMembership {
            memberNumber
            memberClass
            memberType
            membershipStatus
            suspended
            admCertified
            certificationLevel
            pgaCoachEligible
          }
        }
      }
    }
  }
`

const MembershipDetails = () => {
  const { data, loading, error } = useQuery(GET_PGA_MEMBERSHIP)
  const pgaMembership = data?.currentUser?.attributes?.pgaMembership

  if (loading || error) return null

  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">PGA Membership</Typography>
          <Typography variant="caption">
            Information about your PGA Membership is used to personalize your
            experience. If any information is outdated or incorrect, click the
            refresh icon.
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Member Number"
            value={pgaMembership?.memberNumber}
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Member Class"
            value={pgaMembership?.memberClass}
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Member Type"
            value={pgaMembership?.memberType}
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Membership Status"
            value={pgaMembership?.membershipStatus}
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Certification Level"
            value={pgaMembership?.certificationLevel}
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="ADM Certified"
            value={pgaMembership?.admCertified}
            disabled
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Suspended"
            value={pgaMembership?.suspended}
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MembershipDetails
