import React from 'react'
import { Stepper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckStepConnector from './check-step-connector'

const StepperWithBG = withStyles({
  root: {
    backgroundColor: 'inherit',
  },
})(Stepper)

export default function StyledStepper({ children, ...props }) {
  return (
    <StepperWithBG connector={<CheckStepConnector />} {...props}>
      {children}
    </StepperWithBG>
  )
}
