import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useSnackbar, useSnackbarSuccess, useSnackbarError } from 'lib/snackbar'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import MessageOutlined from '@material-ui/icons/MessageOutlined'
import DeleteStudentDialog from './delete-student-dialog'
import SessionDetailsDialog from 'components/session-details-dialog'
import { parseEvent } from 'utils/eventUtils'
import EventDetailsDialog from 'pages/pga-coach/bookings/events/event-details-dialog'
import { EditOutlined, EventNoteOutlined, MoreHoriz } from '@material-ui/icons'
import { useIsTablet } from 'lib/hooks'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import { inviteToPlatformMutation } from './mutations'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import { gtmEvent } from 'lib/gtm'
import { useAuth } from 'lib/auth'
import { isPgaLink } from 'lib/utils'

const useStyles = makeStyles(theme => ({
  row: {
    cursor: 'pointer',
  },
  info: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'min-content',
  },
  studentAvatar: {
    height: '36px',
    width: '36px',
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  actions: {
    textAlign: 'right',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    color: theme.palette.grey[600],
    fontSize: '1.25rem',
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  },
}))

const StudentItemView = ({
  student,
  refetchStudents,
  onReschedule,
  onPayment,
  onCancellation,
}) => {
  const { user } = useAuth()
  const { path } = useRouteMatch()
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const snackbarError = useSnackbarError(enqueueSnackbar)
  const isTablet = useIsTablet()

  const [inviteToPlatform, { loading }] = useMutation(inviteToPlatformMutation)

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  // ----> Handle details dialog.
  const hasSession = student?.upcomingSession.__typename !== null
  const noSessionDate = (
    <ListItemText
      style={{ cursor: 'default' }}
      disableTypography
      primary={
        <Typography className={classes.text}>
          No upcoming sessions
          <br />
          scheduled
        </Typography>
      }
    />
  )
  const type = student.upcomingSession.__typename

  const DetailsDialog = () => {
    if (!hasSession) return null

    return type === 'Session' ? (
      <SessionDetailsDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        booking={student?.upcomingSession}
        onReschedule={onReschedule}
        onPayment={onPayment}
        onCancellation={onCancellation}
      />
    ) : (
      <EventDetailsDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        event={parseEvent(student?.upcomingSession)}
        onPayment={onPayment}
      />
    )
  }

  // Only show the Book button for internal booking. Otherwise the button goes nowhere.
  const showBookButton =
    user.coach.bookingUrl !== null ? isPgaLink(user.coach.bookingUrl) : false

  const isEditButtonEnabled = !student.hasAccount

  const handleBookSessionClick = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-session-button',
    })
    const defaultPath = new URL(user.coach.bookingUrl).pathname
    window.open(
      `${defaultPath}?student=${student.id}`,
      '_blank',
      'noopener,noreferrer',
    )
  }

  const handleInvitation = async event => {
    setAnchorEl(null)
    try {
      const { data } = await inviteToPlatform({
        variables: { studentIds: [student.id] },
      })
      const success = get(data, ['SendConnectionInvitation', 'success'])
      if (!success) return snackbarError()
      snackbarSuccess(`${student.firstName} invited to platform`)
    } catch (e) {
      snackbarError()
    }
  }

  const studentURL = `${path
    .split('/')
    .slice(0, 2)
    .join('/')}/student`

  return (
    <>
      <TableRow
        className={classes.row}
        onClick={() => history.push(`${studentURL}/${student.id}`)}
      >
        <TableCell className={classes.info}>
          <List disablePadding={true}>
            <ListItem>
              <ListItemIcon>
                <Avatar className={classes.studentAvatar} />
              </ListItemIcon>
              <Box pl={2} className={classes.link}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.text}>
                      {student.firstName} {student.lastName}
                    </Typography>
                  }
                />
                <ListItemText>
                  <Typography className={classes.textSecondary}>
                    {student.isMinor ? 'Junior' : 'Adult'}
                  </Typography>
                </ListItemText>
              </Box>
            </ListItem>
          </List>
        </TableCell>
        {!isTablet && (
          <TableCell>
            {hasSession ? (
              <Box className={`${classes.info} ${classes.link}`}>
                <ListItemText
                  onClick={e => {
                    e.stopPropagation()
                    setIsOpen(type === 'Session' ? !isOpen : 'detail')
                  }}
                  disableTypography
                  primary={
                    <Typography className={`${classes.text} ${classes.link}`}>
                      {student.upcomingSession.humanizedStartDateTime}
                    </Typography>
                  }
                />
                <ListItemText
                  onClick={e => {
                    e.stopPropagation()
                    setIsOpen(type === 'Session' ? !isOpen : 'detail')
                  }}
                >
                  <Typography
                    className={`${classes.textSecondary} ${classes.link}`}
                  >
                    {student.upcomingSession.title}
                  </Typography>
                </ListItemText>
              </Box>
            ) : (
              noSessionDate
            )}
          </TableCell>
        )}
        <TableCell className={classes.actions}>
          {loading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreHoriz fontSize="small" />
            </Button>
          )}
        </TableCell>
      </TableRow>
      {/* Removing these items from the flow of the row prevents them from colliding with the overall navigation onClick event listener. */}
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SensitiveInfoWrapper>
          <StreamSendMessageButton
            userIds={[student.representatives[0]?.contact.externalId]}
            component={MenuItem}
          >
            <ListItemIcon>
              <MessageOutlined className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Message Student" />
          </StreamSendMessageButton>
        </SensitiveInfoWrapper>
        {showBookButton && (
          <MenuItem onClick={handleBookSessionClick}>
            <ListItemIcon>
              <EventNoteOutlined className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Book a Session" />
          </MenuItem>
        )}
        {isEditButtonEnabled && (
          <MenuItem
            onClick={() => history.push(`${studentURL}/${student.id}/edit`)}
          >
            <ListItemIcon>
              <EditOutlined className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Edit Student Details" />
          </MenuItem>
        )}
        {!student.hasAccount && (
          <MenuItem onClick={handleInvitation}>
            <ListItemIcon>
              <SyncAltIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Invite to Platform" />
          </MenuItem>
        )}
        <SensitiveInfoWrapper>
          <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Delete Student" />
          </MenuItem>
        </SensitiveInfoWrapper>
      </Menu>
      <DeleteStudentDialog
        student={student}
        onSuccess={() => refetchStudents()}
        setOpen={setIsDeleteDialogOpen}
        open={isDeleteDialogOpen}
      />
      <DetailsDialog />
    </>
  )
}

export default StudentItemView
