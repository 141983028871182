import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '60px',
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontSize: '48px',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  contentSection: {
    marginTop: '45px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  golferBanner: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    background: 'rgba(0, 35, 75, 0.04)',
    border: '1px solid #E0E0E0',
    borderRadius: '16px',
    margin: '10px',
    textDecoration: 'none',
  },
  pgaCoachBanner: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '45%',
    background: 'rgba(171, 145, 87, 0.04)',
    border: '1px solid #E0E0E0',
    borderRadius: '16px',
    margin: '10px',
    textDecoration: 'none',
  },
  headerSectionText: {
    fontSize: '60px',
    fontWeight: '900',
  },
  sectionCaptionText: {
    marginTop: '30px',
    minHeight: '50px',
  },
  golferContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    margin: '32px',
  },
  backgroundImage: {
    height: 'auto',
    width: '75%',
    marginTop: '30px',
  },
  btnStyles: {
    width: '250px',
    marginTop: '32px',
    [theme.breakpoints.down('xs')]: {
      width: '225px',
    },
  },
  bulletPointSection: {
    minHeight: '175px',
  },
}))
