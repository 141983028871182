import { DateTime } from 'luxon'
const DATE_FORMAT = 'EEEE, MMMM d, yyyy'

export const groupByDate = reservations => {
  return reservations.reduce((acc, r) => {
    const date = DateTime.fromISO(r.startDateTime, { zone: r.timezone })
      .startOf('day')
      .toISODate()
    if (!(date in acc)) {
      acc[date] = []
    }
    acc[date].push(r)
    return acc
  }, {})
}

export const parseReservations = academy => {
  if (!academy || !academy.practiceSessions) {
    return []
  }

  const now = DateTime.local().startOf('day')
  const timezone = academy.facilities[0]?.timezone
  const academyName = academy.name

  return academy.practiceSessions
    .map(({ student, practiceSessionType, reservation, transactions }) => {
      const reservationDateTime = DateTime.fromISO(reservation.startDateTime, {
        zone: timezone,
      })
      const isPast = reservationDateTime < now

      return {
        ...reservation,
        timezone,
        privateSessionTitle: practiceSessionType.title,
        isPast,
        academyName,
        student: student,
        transactions,
      }
    })
    .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
}

export const formatDate = date =>
  DateTime.fromISO(date) === DateTime.local().startOf('day')
    ? 'Today'
    : DateTime.fromISO(date).toFormat(DATE_FORMAT)
