import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'

export default ({ Icon, text, largeText }) => {
  const classes = useStyles()
  return (
    <div className={classes.rowWrapped}>
      <Icon className={classes.iconStyled} />
      <Typography variant={largeText ? 'h5' : 'body'}>{text}</Typography>
    </div>
  )
}
