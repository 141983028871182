import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import { TextField } from '@material-ui/core'
import Dialog from 'components/dialog'
import { toFullTextDayDateWithTimezone } from 'utils/dateUtils'
import useIconAccessor from 'components/lesson-type-category/icons'
import { useStyles } from './styles'
import { useTheme } from '@material-ui/core/styles'
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined'
import EventNoteOutlined from '@material-ui/icons/EventNoteOutlined'
import BookOutlinedIcon from '@material-ui/icons/BookOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import IconButton from '@material-ui/core/IconButton'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { useSnackbar } from 'notistack'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const UPDATE_LESSON_PACK = gql`
  mutation UpdateLessonPack(
    $lessonPackId: ID!
    $newCreditBalance: Int!
    $reason: String
  ) {
    updateLessonPack(
      lessonPackId: $lessonPackId
      newCreditBalance: $newCreditBalance
      reason: $reason
    ) {
      message
      success
    }
  }
`
const EditLessonPackDialog = ({ isOpen, setIsOpen, booking, refetch }) => {
  const [newCreditBalance, setNewCreditBalance] = useState(
    booking.remainingCredits,
  )
  const { enqueueSnackbar } = useSnackbar()
  const [updateLessonPack] = useMutation(UPDATE_LESSON_PACK)

  return (
    <Dialog
      maxWidth="xs"
      showCloseButton
      fullWidth={true}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      {({ closeDialog }) => {
        const handleClick = () => {
          updateLessonPack({
            variables: {
              lessonPackId: booking.lessonPackId,
              newCreditBalance: newCreditBalance,
              reason: 'Lesson Pack Edited',
            },
          }).then(({ data }) => {
            if (data.updateLessonPack.success) {
              enqueueSnackbar(data.updateLessonPack.message, {
                variant: 'success',
              })
              refetch()
              closeDialog()
            } else {
              enqueueSnackbar(data.updateLessonPack.message, {
                variant: 'error',
              })
            }
          })
        }

        return (
          <DialogContent>
            <Box py={3}>
              <Typography variant="h6" gutterBottom>
                Edit Lesson Pack
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Editing a lesson pack will add or remove lesson credits from
                their student account and they will receive an email
                confirmation.
              </Typography>
              <Box mb={4}>
                <TextField
                  type="number"
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  value={newCreditBalance}
                  onChange={e => {
                    setNewCreditBalance(parseInt(e.target.value))
                  }}
                />
              </Box>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save
              </Button>
            </Box>
          </DialogContent>
        )
      }}
    </Dialog>
  )
}
const LessonPacksDetailsDialogContent = ({ booking, setIsOpen, refetch }) => {
  const { lessonCredits, expiresAt, remainingCredits, category } = booking

  const [isEditLessonPackOpen, setIsEditLessonPackOpen] = useState(false)
  const classes = useStyles()
  const accessor = useIconAccessor()
  const lessonCreditsWithEnrollment = lessonCredits.filter(lc => lc.enrollment)
  const purchasedLessonCredits = lessonCreditsWithEnrollment.filter(
    e =>
      e.enrollment.transactions.length > 0 &&
      e.enrollment.transactions.some(t => !t.refund),
  )
  return (
    <>
      <Box className={classes.dialogTitle}>
        <Box position="relative">
          <Avatar className={classes.categoryIcon}>
            {accessor[category].icon}
          </Avatar>
          <Box className={classes.actionWrapper}>
            <IconButton
              className={classes.action}
              onClick={e => setIsEditLessonPackOpen(true)}
            >
              <EditOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              className={classes.actionAvatar}
              onClick={e => setIsOpen(false)}
            >
              <CloseOutlinedIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <DialogContent dividers>
        <Box mt={4} p={1.5}>
          <Typography variant="h6" gutterBottom>
            Lesson Pack Details
          </Typography>
          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={4} color="text.secondary">
              <CollectionsBookmarkOutlinedIcon />
            </Box>
            <Box>
              <Typography variant="body1">
                Number of Lessons Purchased
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {purchasedLessonCredits.length} Lessons
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={4} color="text.secondary">
              <BookOutlinedIcon />
            </Box>
            <Box>
              <Typography variant="body1">Sessions Remaining</Typography>
              <Typography variant="body2" color="textSecondary">
                {remainingCredits} Lessons
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={3}>
            <Box mr={4} color="text.secondary">
              <EventNoteOutlined />
            </Box>
            <Box>
              <Typography variant="body1">Expiration</Typography>
              <Typography variant="body2" color="textSecondary">
                {expiresAt
                  ? toFullTextDayDateWithTimezone(expiresAt)
                  : 'These packs do not expire'}
              </Typography>
            </Box>
          </Box>
          {lessonCreditsWithEnrollment.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                Lesson Pack History
              </Typography>
              {lessonCreditsWithEnrollment.map((lessonCredit, i) => (
                <Box mb={3} key={i}>
                  <Typography variant="body1">
                    {toFullTextDayDateWithTimezone(
                      lessonCredit.enrollment?.lesson.startDateTime,
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {lessonCredit.enrollment?.student.firstName}{' '}
                    {lessonCredit.enrollment?.student.lastName}
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
      </DialogContent>
      <EditLessonPackDialog
        isOpen={isEditLessonPackOpen}
        setIsOpen={setIsEditLessonPackOpen}
        booking={booking}
        refetch={refetch}
      />
    </>
  )
}

const LessonPackDetailsDialog = ({ isOpen, setIsOpen, booking, refetch }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={isMobile}
      fullWidth={true}
      isOpen={isOpen}
      onClose={() => setIsOpen(!isOpen)}
    >
      {() => (
        <LessonPacksDetailsDialogContent
          booking={booking}
          setIsOpen={setIsOpen}
          refetch={refetch}
        />
      )}
    </Dialog>
  )
}

export default LessonPackDetailsDialog
