import { gql, useQuery } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'

const GET_STUDENT = gql`
  ${GolferProfileFragment}
  query Student($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      dateOfBirth
      hasBeenIntakeNudged
      isMinor
      hasAccount
      contact {
        id
        firstName
        lastName
        email
        phoneNumber
        relationshipType
        externalId
        lessonPacks {
          id
          quantity
          lessonType {
            id
            title
            category
            durationInMinutes
            locations {
              name
              id
            }
            coach {
              externalId
              name
              id
            }
          }
          totalCredits
          expiresAt
          remainingCredits
          lessonCredits {
            createdAt
            updatedAt
            void
            enrollment {
              createdAt
              lesson {
                startDateTime
                title
              }
              student {
                firstName
                lastName
              }
              transactions {
                refund
              }
            }
          }
        }
      }
      medicalProfile {
        primaryDoctor
        primaryDoctorPhone
      }
      emergencyContact {
        firstName
        lastName
        phone
      }
      golferProfile {
        ...GolferProfileAttributes
      }
    }
  }
`

const GET_STUDENT_STATISTICS = gql`
  query StudentStatistics($studentId: ID!, $coachId: ID) {
    studentStatistics(studentId: $studentId, coachId: $coachId) {
      totalRevenueInCents
      privateBookingCount
      groupSessionCount
      totalBookingCount
    }
  }
`

const GET_STUDENT_SESSIONS = gql`
  query StudentEnrollments($studentId: ID!, $coachId: ID!) {
    studentEnrollments(studentId: $studentId, coachId: $coachId) {
      id
      createdAt
      isCanceled
      lessonCredit {
        id
      }
      paymentRequestToken
      paymentRequestedDate
      student {
        firstName
        lastName
      }
      transactions {
        amountInCents
        createdAt
        sourceType
        refund
        registrationPayment {
          id
          leftToRefundInCents
        }
      }
      lesson {
        id
        startDateTime
        endDateTime
        timezone
        isCanceled
        title
        description
        startDateTime
        coach {
          name
          id
        }
        coachFacility {
          name
          street
          city
          state
          zipCode
          name
          street
          city
          state
          zipCode
        }
        enrollments {
          id
          confirmationCode
          createdAt
          isCanceled
          paymentRequestToken
          paymentRequestedDate
          student {
            id
            firstName
            lastName
            representatives {
              contact {
                externalId
                firstName
                lastName
                email
                phone
              }
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              externalId
            }
          }
          transactions {
            amountInCents
            createdAt
            sourceType
            refund
            registrationPayment {
              id
              registrationPaymentCharge {
                id
                chargeType
                chargeNumber
                checkNumber
              }
            }
          }
        }
        ... on Session {
          lessonType {
            id
            category
            priceInCents
            acceptsOnlinePayments
            durationInMinutes
            paymentAccount {
              id
            }
          }
        }
        ... on GroupEvent {
          slug
          tags
          featuredImageUrl
          priceInCents
          totalPriceInCents
          acceptsOnlinePayments
          paymentRecurrenceSchedule
          numberOfPayments
          lessonCount
          registrationCloseDateTime
          externalRegistrationUrl
          registrationCount
          sessions {
            id
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`

export function useGetStudent(studentId) {
  return useQuery(GET_STUDENT, {
    variables: { id: studentId },
  })
}

export function useGetStatistics(studentId) {
  return useQuery(GET_STUDENT_STATISTICS, {
    variables: { studentId: studentId },
    fetchPolicy: 'no-cache',
  })
}

export function useGetSessions(studentId, coachId) {
  return useQuery(GET_STUDENT_SESSIONS, {
    variables: { studentId: studentId, coachId: coachId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
}

export const NUDGE_GOLFER_INTAKE = gql`
  mutation SendGolferIntakeNudge($studentIds: [ID!]!) {
    SendGolferIntakeNudge(studentIds: $studentIds) {
      message
      success
    }
  }
`
