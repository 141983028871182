import React, { useState, useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import useAuth from './useAuth'
import { roles } from 'lib/auth/constants'
import IneligibleUserPage from 'pages/ineligible-user'
import castArray from 'lodash/castArray'
import intersection from 'lodash/intersection'
import Unauthorized from 'components/unauthorized'
import { LoginRedirect } from 'lib/auth'

const TOKEN_REFRESH_INTERVAL_MS = 5 * 60 * 1000 // 5 minutes

const coachAuthRedirectRoles = [
  roles.COACH,
  roles.ADMIN,
  roles.PEC,
  roles.SECTION_STAFF,
  roles.JUNIOR_LEAGUE_COACH,
]

const consumerAuthRedirectRoles = [roles.CONSUMER]

const adminRoles = [
  roles.ADMIN,
  roles.SECTION_STAFF,
  roles.PEC,
  roles.SUPER_USER,
]

function withProtectedRoute(
  Page,
  authorizedRoles = roles.NONE,
  renderUnauthorizedFallback = null,
) {
  authorizedRoles = castArray(authorizedRoles)

  function WithProtectedRoute(props) {
    const auth = useAuth()
    const [authReady, setAuthReady] = useState(false)

    useEffect(() => {
      const login = async () => {
        await auth.login(() => setAuthReady(true))
      }
      if (!authReady && !auth.isLoggedIn) {
        login()
      }
      const intervalId = setInterval(
        auth.refreshToken,
        TOKEN_REFRESH_INTERVAL_MS,
      )
      return () => {
        clearInterval(intervalId)
      }
    }, [auth, authReady])

    // show a loading indicator until the auth state is ready
    if (!authReady && !auth.isLoggedIn) {
      return <LinearProgress color="secondary" />
    }

    // if provided, render the unauthorized fallback if the user is not logged in
    // or does not have the required role
    if (
      (!auth.isLoggedIn || !auth.user.hasRole(authorizedRoles)) &&
      renderUnauthorizedFallback != null
    ) {
      return renderUnauthorizedFallback
    }

    if (auth.isLoggedIn) {
      // block ineligible users from accessing coach pages
      if (
        authorizedRoles.includes(roles.COACH) &&
        auth.isCoach &&
        !auth.user.pgaCoachEligible &&
        // paths to exclude from the pgaCoachEligible check
        !window.location.pathname.startsWith('/junior-league-signup')
      ) {
        return <IneligibleUserPage user={auth.user} />
      }

      // block non-admins from accessing admin pages
      if (
        intersection(authorizedRoles, adminRoles).length > 0 &&
        !auth.isAdmin
      ) {
        return <Unauthorized />
      }

      // show the page if the user has any of the authorized roles
      if (auth.user.hasRole(authorizedRoles)) {
        return <Page {...props} />
      }
    }

    // redirect routes restricted for only consumers directly to consumer login flow
    if (intersection(authorizedRoles, consumerAuthRedirectRoles).length > 0) {
      return <LoginRedirect role="consumer" />
    }

    // redirect routes restricted for only coaches directly to coach login flow
    if (intersection(authorizedRoles, coachAuthRedirectRoles).length > 0) {
      return <LoginRedirect role="coach" />
    }

    // we should never get here, as long as the authorizedRoles are set correctly
    return null
  }

  return WithProtectedRoute
}

export default withProtectedRoute
