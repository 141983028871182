import {
  AttachMoney,
  EventOutlined,
  MoneyOffOutlined,
  ReceiptOutlined,
} from '@material-ui/icons'
import { Menu, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core'
import RecordBookingPaymentDialog from 'components/record-booking-payment-dialog'
import RefundDialog from 'pages/account/payments/transactions/transaction-detail/refund-dialog'
import AccountRouterLink from 'components/account-router-link'
import { forwardRef } from 'react'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import { useAuth } from 'lib/auth'
import { gtmEvent } from 'lib/gtm'

const Item = forwardRef(({ icon, text, ...props }, ref) => (
  <MenuItem {...props} ref={ref}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={text} />
  </MenuItem>
))

const BookingPaymentDialog = forwardRef(
  (
    {
      trigger,
      enrollment,
      eventTitle,
      eventDate,
      participantName,
      defaultAmountInCents,
      acceptOnlinePayments,
      onPaymentSuccess,
    },
    ref,
  ) => (
    <RecordBookingPaymentDialog
      trigger={trigger}
      enrollment={enrollment}
      eventTitle={eventTitle}
      eventDate={eventDate}
      participantName={participantName}
      defaultAmountInCents={defaultAmountInCents}
      acceptOnlinePayments={acceptOnlinePayments}
      onPaymentSuccess={onPaymentSuccess}
    />
  ),
)

export default function SessionMenu({
  handleClose,
  anchorEl,
  session,
  refetch,
}) {
  const { user } = useAuth()
  const { hideSensitiveInformation } = user

  const isPaid = session.transactions.some(t => !t.refund)
  const isRefund = session.transactions.some(t => t.refund)
  const { lesson, student } = session
  const { startDateTime, lessonType, title, isCanceled } = lesson
  const studentName = `${student.firstName} ${student.lastName}`
  const isGroupEvent = lesson.__typename === 'GroupEvent'

  const [isRefundEnabled] = useFeatureFlags([flags.FLAG_REFUND_QUICK_ACTION])

  const registrationPaymentId =
    isPaid && session?.transactions[0]?.registrationPayment.id
  const leftToRefund =
    isPaid &&
    session?.transactions[0]?.registrationPayment.leftToRefundInCents / 100.0
  const priceInCents = isGroupEvent
    ? lesson.priceInCents
    : lessonType.priceInCents
  const acceptsOnlinePayments = isGroupEvent
    ? lesson.acceptsOnlinePayments
    : lessonType.acceptsOnlinePayments

  const showMarkPaid = !isPaid && !isCanceled && !hideSensitiveInformation
  const showReceipt = isPaid && !hideSensitiveInformation
  const showBookNow = isCanceled

  const handleBookSessionClick = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-session-button',
    })
    window.open(
      new URL(user.coach.bookingUrl).pathname,
      '_blank',
      'noopener,noreferrer',
    )
    handleClose()
  }

  const handleMarkAsPaid = () => {
    handleClose()
    refetch()
  }

  return (
    <Menu
      id="simple-menu"
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {showReceipt && (
        <Item
          icon={<ReceiptOutlined htmlColor="rgba(0, 0, 0, 0.54)" />}
          text="View Reciept"
          color="primary"
          component={AccountRouterLink}
          to={`/account/payments/transactions/${registrationPaymentId}`}
        />
      )}
      {showMarkPaid && (
        <BookingPaymentDialog
          trigger={
            <Item
              icon={<AttachMoney htmlColor="rgba(0, 0, 0, 0.54)" />}
              text="Mark as Paid"
            />
          }
          enrollment={session}
          eventTitle={title}
          eventDate={startDateTime}
          participantName={studentName}
          defaultAmountInCents={priceInCents}
          acceptOnlinePayments={acceptsOnlinePayments}
          onPaymentSuccess={handleMarkAsPaid}
        />
      )}
      {showBookNow && (
        <Item
          icon={<EventOutlined htmlColor="rgba(0, 0, 0, 0.54)" />}
          text="Book a session"
          color="primary"
          onClick={e => {
            e.stopPropagation()
            handleBookSessionClick()
          }}
        />
      )}
      {isRefund ? (
        <Item
          icon={<ReceiptOutlined htmlColor="rgba(0, 0, 0, 0.54)" />}
          text="View Refund"
          props={{
            color: 'primary',
            component: AccountRouterLink,
            to: `/account/payments/transactions/${1}`,
          }}
        />
      ) : isRefundEnabled ? (
        isPaid && (
          <RefundDialog
            trigger={
              <Item
                onClick={e => e.stopPropagation()}
                icon={<MoneyOffOutlined htmlColor="rgba(0, 0, 0, 0.54)" />}
                text="Add Refund"
              />
            }
            registrationPaymentId={registrationPaymentId}
            leftToRefund={leftToRefund}
            onSuccess={handleMarkAsPaid}
          />
        )
      ) : null}
    </Menu>
  )
}
