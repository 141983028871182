import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '200px',
    marginTop: '8px',
    border: '1px solid #ccc',
    display: 'flex',
    flexDirection: 'column',
    /* overflow to prevent clipping of avatar images: */
    overflow: 'visible !important',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '120px',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
  },
  avatarWrapper: {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '33%',
    },
  },
  rateWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  avatarStyles: {
    height: '120px',
    width: '120px',
    position: 'absolute',
    margin: '-30px 0 50px 0',
    textTransform: 'uppercase',
    fontSize: '2rem',
    border: '3px solid white',
    boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
      height: '60px',
      width: '60px',
    },
  },
  rateStyles: {
    marginLeft: '10px',
  },
  starButton: {
    padding: '4px',
  },
}))

export default useStyles
