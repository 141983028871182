import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { DialogBox, FlexRow } from './profile.styles'

const PERSIST_INTERVAL_MS = 30 * 1000

const FormPersistence = ({
  persistKey,
  formValues,
  setFormValues,
  shouldPersist,
}) => {
  const [lastPersistDateTime, setLastPersistDateTime] = useState(
    new Date(Date.now()),
  )
  const [existingStateCheck, setExistingStateCheck] = useState(false)
  const [existingFormValues, setExistingFormValues] = useState(null)

  useEffect(() => {
    const now = new Date(Date.now())
    if (
      shouldPersist &&
      now.getTime() - lastPersistDateTime.getTime() > PERSIST_INTERVAL_MS
    ) {
      const persistValue = {
        date: now.toUTCString(),
        values: formValues,
      }
      localStorage.setItem(persistKey, JSON.stringify(persistValue))
      setLastPersistDateTime(now)
    } else if (!shouldPersist) {
      localStorage.removeItem(persistKey)
    }
  }, [shouldPersist, formValues, lastPersistDateTime, persistKey])

  useEffect(() => {
    if (!existingStateCheck) {
      setExistingStateCheck(true)

      const existingState = localStorage.getItem(persistKey)
      if (existingState) {
        try {
          const persistValue = JSON.parse(existingState)
          setExistingFormValues(persistValue.values)
        } catch {
          // Ignore error
        }
      }
    }
  }, [existingStateCheck, persistKey])

  const onDialogResponse = confirm => {
    if (confirm) {
      setFormValues(existingFormValues)
    }
    setExistingFormValues(null)
    localStorage.removeItem(persistKey)
  }

  const LoadPreviousStateDialog = (
    <Dialog open={existingFormValues != null}>
      <DialogBox>
        <Typography
          variant="h4"
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          Recover Unsaved Changes
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          We noticed you might have had unsaved changes on this form.
          <br />
          Continue where you left off?
        </Typography>
        <FlexRow>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => onDialogResponse(true)}
            style={{ marginRight: '20px' }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => onDialogResponse(false)}
          >
            No
          </Button>
        </FlexRow>
      </DialogBox>
    </Dialog>
  )

  return LoadPreviousStateDialog
}

FormPersistence.propTypes = {
  persistKey: PropTypes.string.isRequired,
  shouldPersist: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.func.isRequired,
}

export default FormPersistence
