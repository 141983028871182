/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import {
  InstantSearch,
  Configure,
  connectHits,
  connectRefinementList,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY, GROUP_EVENTS_INDEX_NAME } from 'env'
import 'instantsearch.css/themes/satellite.css'
import EventsSlider from './events-slider'
import Typography from '@material-ui/core/Typography'
import uniqBy from 'lodash/uniqBy'
import { getAlgoliaTimestamp } from 'lib/date-utils'
import ProgramCardAlgolia from 'components/program-card-algolia/program-card-algolia'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)
const index = searchClient.initIndex(GROUP_EVENTS_INDEX_NAME)

const CustomHits = connectHits(({ hits, setPrograms }) => {
  useEffect(() => {
    setPrograms(hits)
  }, [hits, setPrograms])
  return (
    hits.length > 0 && (
      <EventsSlider
        events={hits}
        renderEvent={event => (
          <ProgramCardAlgolia key={event.objectID} event={event} />
        )}
      />
    )
  )
})

const CustomRefinementList = connectRefinementList(
  ({ items, refine, selectedFacility }) => {
    useEffect(() => {
      if (selectedFacility !== 'all' && selectedFacility !== null) {
        refine(selectedFacility.split(' '))
      }
      if (selectedFacility === 'all') {
        refine([])
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedFacility])
    return null
  },
)

const getLocations = async query => {
  const { hits } = await index.search('', {
    attributesToRetrieve: ['coach_facility_id', 'coach_facility_name'],
    filters: query,
    hitsPerPage: 1000,
  })

  const locations = uniqBy(hits, 'coach_facility_id').map(
    ({ coach_facility_id, coach_facility_name }) => ({
      id: coach_facility_id,
      name: coach_facility_name,
    }),
  )
  return locations
}

const Programs = ({
  coach,
  classes,
  setProgramsLocations,
  selectedFacility,
}) => {
  const [programs, setPrograms] = useState([])
  const comparisonTimestamp = getAlgoliaTimestamp()
  const query = `coach_id:${coach.id} AND (tags:"PGA Jr. League" OR tags:"PGA Family Cup") AND registration_close_date_time_ts > ${comparisonTimestamp}`

  useEffect(() => {
    const getAllLocations = async query => {
      const allLocations = await getLocations(query)
      setProgramsLocations(allLocations)
    }
    getAllLocations(query)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])
  return (
    <>
      {programs.length > 0 && (
        <Typography variant="h4" className={classes.eventsTitle}>
          Programs
        </Typography>
      )}
      <InstantSearch
        indexName={GROUP_EVENTS_INDEX_NAME}
        searchClient={searchClient}
      >
        <CustomRefinementList
          attribute="coach_facility_id"
          selectedFacility={selectedFacility}
        />
        <CustomHits setPrograms={setPrograms} operator="or" />
        <Configure filters={query} hitsPerPage={1000} />
      </InstantSearch>
    </>
  )
}

export default Programs
