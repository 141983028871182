import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import { MY_PGA_COM_HOST } from 'env'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import CopyTextLineEllipsis from '../copy-text-line-ellipsis'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    fontWeight: '700',
  },
}))

const RequestAccessCode = ({
  coachExternalId,
  isOpen,
  setIsOpen,
  coachName,
  bookingUrl,
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const fullUrl = MY_PGA_COM_HOST + bookingUrl

  return (
    <Dialog
      maxWidth="xs"
      showCloseButton
      fullScreen={isMobile}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      hideBackdrop={true}
    >
      {() => (
        <Box m={3}>
          <DialogTitle>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography variant="h5" className={classes.title}>
                Request Access Code
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ textAlign: 'center' }}
            >
              {`Please send ${coachName} a message to request the access code. Be sure to include the link below.`}
            </Typography>
          </DialogContent>
          <DialogActions style={{ flexDirection: 'column' }}>
            <Box display="flex" width="100%" mb={5}>
              <CopyTextLineEllipsis text={fullUrl} />
            </Box>
            <StreamSendMessageButton
              userIds={[coachExternalId]}
              color="primary"
              variant="contained"
              startIcon={<MessageOutlinedIcon />}
            >
              Send Message
            </StreamSendMessageButton>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  )
}

export default RequestAccessCode
