import ConfirmationDialog from 'components/confirmation-dialog'
import React from 'react'

export default function UnsavedChangesDialog({
  open,
  onDiscard,
  onKeepEditing,
}) {
  // This looks a little strange, but we want the primary(confirm) button to continue editing
  return (
    <ConfirmationDialog
      heading="You have unsaved changes"
      cancelText="Discard edits"
      confirmText="Continue editing"
      message="Unsaved changes will be lost if you exit without saving"
      open={open}
      onConfirm={onKeepEditing}
      onCancel={onDiscard}
    />
  )
}
