import pipe from 'lodash/fp/pipe'
import flatMap from 'lodash/fp/flatMap'
import uniqBy from 'lodash/fp/uniqBy'

const groupByLessonCategory = (lessons, orderedObj) =>
  lessons.reduce((acc, cv) => {
    if (acc[cv.category]) {
      acc[cv.category].push(cv)
    } else {
      acc[cv.category] = [cv]
    }
    return acc
  }, orderedObj)

const duplicateLessonsWithMultipleLocations = lessonTypes =>
  lessonTypes.flatMap(lessonType =>
    lessonType.locations.map(loc => ({
      ...lessonType,
      locationPlace: `${loc?.city}, ${loc?.state}`,
      locationName: loc.name,
      locationId: loc.id,
    })),
  )

export const parseData = ({ coaches, __type, coachIsViewingOwnSchedule }) => {
  const coach = coaches[0]

  const orderedObj = __type.enumValues.reduce((acc, cv) => {
    acc[cv.name] = []
    return acc
  }, {})

  const coachLessonTypes = coachIsViewingOwnSchedule
    ? coach.lessonTypes
    : coach.lessonTypes.filter(lt => lt.status === 'ACTIVE')

  const lessonTypeLocations = pipe(
    flatMap(lt => lt.locations),
    uniqBy('id'),
  )(coachLessonTypes)

  const lessonTypes = duplicateLessonsWithMultipleLocations(coach.lessonTypes)
  return {
    coachName: coach.name,
    coachTitle: coach.coachProfile?.title,
    coachAvatar: coach.coachProfile?.profilePhoto,
    coachLocations: lessonTypeLocations,
    lessonTypes: groupByLessonCategory(lessonTypes, orderedObj),
  }
}

export const sortLessonTypes = ({
  filteredLessonTypes,
  lessonCreditBalances,
}) => {
  const lessonCreditSessionIdArr = []
  lessonCreditBalances &&
    lessonCreditBalances.forEach(lessonCredit => {
      lessonCreditSessionIdArr.push(lessonCredit.lessonType.id)
    })

  return Object.values(filteredLessonTypes)
    .flat()
    .map(lesson => {
      if (lessonCreditSessionIdArr.includes(lesson.id)) {
        lesson.lessonCreditBalance = lessonCreditBalances.find(
          lessonCredit => lessonCredit.lessonType.id === lesson.id,
        )
      }
      return lesson
    })
    .sort((a, b) => a.title.localeCompare(b.title))
    .sort((a, b) => {
      if (a.lessonCreditBalance && b.lessonCreditBalance) {
        return a.lessonCreditBalance.balance - b.lessonCreditBalance.balance
      }
      if (a.lessonCreditBalance) {
        return -1
      }
      if (b.lessonCreditBalance) {
        return 1
      }
      return 0
    })
}
