import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { PGA_COM_HOST } from 'env'
import { useStyles } from './styles'
import useIsMobile from 'lib/hooks/use-is-mobile'
import useIsTablet from 'lib/hooks/use-is-tablet'
import { getInitials } from 'lib/text-display'
import CoachLogo from 'components/coach-logo'
import { Link } from '@material-ui/core'

const CoachOfferingCard = ({ coach }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const { id, name, coachProfile, returnUrl } = coach
  const { memberType, certificationLevel, slug } = coachProfile || {}
  const initials = getInitials(coach.name)

  return (
    <Card key={id} className={classes.root}>
      <CardContent className={`${classes.coachInfo} ${classes.disableSpacing}`}>
        <Link
          href={returnUrl || `${PGA_COM_HOST}/coach/${slug}`}
          className={classes.link}
        >
          <Avatar
            className={classes.coachAvatar}
            alt={name}
            src={coachProfile?.profilePhoto}
            style={{ marginRight: isMobile ? '1rem' : '36px' }}
          >
            {initials}
          </Avatar>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box className={classes.infoWrapper}>
              <Typography variant="h4" className={classes.name}>
                {name}
              </Typography>
              {coachProfile?.title ? (
                <Typography variant="caption" className={classes.coachTitle}>
                  {coachProfile.title}
                </Typography>
              ) : null}
              <Typography
                variant="caption"
                className={classes.profileCaption}
                id="view-profile"
              >
                View Profile
              </Typography>
            </Box>
            {isTablet && (
              <Box className={classes.logoWrapper}>
                <CoachLogo {...{ memberType, certificationLevel }} />
              </Box>
            )}
          </Box>
        </Link>
        {!isTablet && (
          <Box className={classes.logoWrapper}>
            <CoachLogo {...{ memberType, certificationLevel }} />
          </Box>
        )}
        {/* member logo place holder */}
      </CardContent>
    </Card>
  )
}

export default CoachOfferingCard
