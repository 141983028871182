export const mapperObj = [
  {
    name: 'active-start',
    title: 'Active Start',
  },
  {
    name: 'fundamentals',
    title: 'FUNdamentals',
  },
  {
    name: 'learn-to-play',
    title: 'Learn to Play',
  },
  {
    name: 'play-to-improve',
    title: 'Play to Improve',
  },
  {
    name: 'play-to-compete',
    title: 'Play to Compete',
  },
  {
    name: 'play-to-win',
    title: 'Play to Win',
  },
  {
    name: 'play-golf-for-life',
    title: 'Play Golf for Life',
  },
  {
    name: 'fundamental-movement-skills',
    title: 'Fundamental Movement Skills',
  },
]

export const titleToURL = stage => mapperObj.find(o => o.title === stage)
export const urlToTitle = stage => mapperObj.find(o => o.name === stage)
