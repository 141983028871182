import { makeStyles, styled } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  overlineText: {
    fontWeight: 500,
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  studentItem: {
    border: '1px solid #E0E0E0',
    borderRadius: '0.25rem',
    padding: '1rem!important',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  studentItemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inline: {
    display: 'inline',
  },
  center: {
    textAlign: 'center',
  },
  hideOnMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.grey[600],
    fontSize: '1.25rem',
  },
}))

const StyledChip = styled(Chip)(({ theme, bgColor }) => ({
  backgroundColor: bgColor ?? theme.palette.success.light,
  color: theme.palette.primary.contrastText,
  fontWeight: 400,
  fontFamily: 'Roboto',
  letterSpacing: '0.4px',
}))

export { useStyles, StyledChip }
