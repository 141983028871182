import * as amplitude from '@amplitude/analytics-browser'
import { AMPLITUDE_API_KEY } from 'env'
import { useEffect, useState } from 'react'

/**
 * Send an Amplitude tracking event.
 *
 * @param {String} event - The name of the event.
 * @param {Object} attributes - An object of any relevant event properties (optional).
 */
export const amplitudeEvent = (event, attributes = {}) => {
  amplitude.track(event, attributes)
}

const Amplitude = () => {
  const [activated, setActivated] = useState(false)

  useEffect(() => {
    if (!activated) {
      amplitude.init(AMPLITUDE_API_KEY, {
        // This allows Amplitude to send events asynchronously, including after a domain redirect.
        transport: 'beacon',
        // To make sure the event will be scheduled right away.
        flushIntervalMillis: 0,
        flushQueueSize: 1,
        // Prevent unneeded tracking.
        defaultTracking: {
          attribution: false,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
        },
      })

      setActivated(true)
    }
  }, [activated])

  return null
}

export default Amplitude
