import React from 'react'
import Chip from '@material-ui/core/Chip'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  gray: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.primary.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.success.contrastText,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.success.contrastText,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.success.contrastText,
  },
  'green-stroke': {
    backgroundColor: 'white',
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: theme.palette.success.main,
  },
}))

export const createStatusChip = statusMap =>
  function StatusChip({ status }) {
    const classes = useStyles()
    const chipInfo = statusMap[status]

    return <Chip label={chipInfo.label} className={classes[chipInfo.color]} />
  }
