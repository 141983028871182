import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import parseISO from 'date-fns/parseISO'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from 'components/dialog'
import {
  FCCodeOfConduct,
  FCWaiver,
  JRLCoachCodeOfConduct,
  JRLManagerCodeOfConduct,
  JRLCoachWaiver,
} from 'components/waivers'
import * as maybe from 'lib/maybe'
import { PageHeader } from 'components/page-header'
import IconCard from 'components/icon-card'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import BackgroundCheck, {
  statuses as backgroundCheckStatuses,
} from './background-check'
import Aps, { statuses as apsStatuses } from './aps'

export const GET_REQUIREMENT_STATUSES = gql`
  query JRLRequirementStatuses {
    currentUser {
      jrlSignedUp
      familyCupSignedUp
      account {
        apsTraining {
          status
        }
        backgroundScreen {
          status
          passedAt
        }
        jrlUser {
          coachWaiver
          managerWaiver
        }
      }
    }
  }
`

const RequirementsDialog = ({ heading, open, children, onClose }) => {
  return (
    <Dialog showCloseButton isOpen={open} onClose={onClose}>
      {({ closeDialog }) => (
        <Box p={4}>
          <Typography variant="h6" align="center">
            {heading}
          </Typography>

          <Box mt={4} mb={4}>
            {children}
          </Box>

          <Button onClick={closeDialog} color="primary" variant="contained">
            Ok
          </Button>
        </Box>
      )}
    </Dialog>
  )
}

export const getStatuses = ({ data, loading, error }) => {
  if (loading) {
    return {
      backgroundScreen: {
        status: backgroundCheckStatuses.CHECKING,
        passedAt: null,
      },
      apsTraining: { status: apsStatuses.CHECKING },
    }
  }

  if (error) {
    return {
      backgroundScreen: {
        status: backgroundCheckStatuses.ERROR,
        passedAt: null,
      },
      apsTraining: { status: apsStatuses.ERROR },
    }
  }

  const { currentUser } = data

  return currentUser.account
}

const RequirementsPage = () => {
  const { path } = useRouteMatch()
  const crumbs = [
    {
      label: 'Account',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]

  const [activeDialog, setActiveDialog] = useState(null)

  const viewFCCodeOfConduct = () => setActiveDialog('fcCodeOfConduct')
  const viewFCWaiver = () => setActiveDialog('fcWaiver')
  const viewJRLCoachCodeOfConduct = () =>
    setActiveDialog('jrlCoachCodeOfConduct')
  const viewJRLManagerCodeOfConduct = () =>
    setActiveDialog('jrlManagerCodeOfConduct')
  const viewJRLWaiver = () => setActiveDialog('jrlWaiver')

  const closeActiveDialog = () => {
    setActiveDialog(null)
  }

  const { data, loading, error, refetch } = useQuery(GET_REQUIREMENT_STATUSES)

  const { backgroundScreen, apsTraining } = getStatuses({
    data,
    loading,
    error,
  })
  const { account, jrlSignedUp, familyCupSignedUp } = data?.currentUser || {}

  const waiverActions = []

  if (familyCupSignedUp) {
    waiverActions.push({
      onClick: viewFCCodeOfConduct,
      text: 'VIEW FAMILY CUP CODE OF CONDUCT',
    })
    waiverActions.push({
      onClick: viewFCWaiver,
      text: 'VIEW FAMILY CUP WAIVER',
    })
  }

  if (jrlSignedUp) {
    waiverActions.push({
      onClick: viewJRLWaiver,
      text: 'VIEW PGA JR. LEAGUE WAIVER',
    })
    if (account.jrlUser.coachWaiver) {
      waiverActions.push({
        onClick: viewJRLCoachCodeOfConduct,
        text: 'VIEW PGA JR. LEAGUE COACH CODE OF CONDUCT',
      })
    }
    if (account.jrlUser.managerWaiver) {
      waiverActions.push({
        onClick: viewJRLManagerCodeOfConduct,
        text: 'VIEW PGA JR. LEAGUE MANAGER CODE OF CONDUCT',
      })
    }
  }

  return (
    <>
      <PageHeader title="Requirements" crumbs={crumbs} />
      <Box my={6}>
        <Container maxWidth="md">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <BackgroundCheck
                status={backgroundScreen?.status || 'NOT_STARTED'}
                passedAt={maybe.map(backgroundScreen?.passedAt, parseISO)}
                refetchStatus={refetch}
              />
            </Grid>

            <Grid item>
              <Aps status={apsTraining?.status || 'NOT_STARTED'} />
            </Grid>

            <Grid item>
              <IconCard
                title="Code of Conduct and Waiver"
                actions={waiverActions}
                icon={AssignmentIndOutlinedIcon}
                testid="waiver-card"
              >
                Upon signup you will have agreed to the Code of Conduct and
                Waiver. To reference a copy of these agreements, please click
                below.
              </IconCard>
            </Grid>
          </Grid>

          <RequirementsDialog
            heading="Family Golf Code of Conduct"
            open={activeDialog === 'fcCodeOfConduct'}
            onClose={closeActiveDialog}
          >
            <FCCodeOfConduct />
          </RequirementsDialog>

          <RequirementsDialog
            heading="Family Golf Waiver"
            open={activeDialog === 'fcWaiver'}
            onClose={closeActiveDialog}
          >
            <FCWaiver />
          </RequirementsDialog>

          <RequirementsDialog
            heading="PGA Jr. League Waiver"
            open={activeDialog === 'jrlWaiver'}
            onClose={closeActiveDialog}
          >
            <JRLCoachWaiver />
          </RequirementsDialog>

          <RequirementsDialog
            heading="PGA Jr. League Coach Code of Conduct"
            open={activeDialog === 'jrlCoachCodeOfConduct'}
            onClose={closeActiveDialog}
          >
            <JRLCoachCodeOfConduct />
          </RequirementsDialog>

          <RequirementsDialog
            heading="PGA Jr. League Manager Code of Conduct"
            open={activeDialog === 'jrlManagerCodeOfConduct'}
            onClose={closeActiveDialog}
          >
            <JRLManagerCodeOfConduct />
          </RequirementsDialog>
        </Container>
      </Box>
    </>
  )
}

export default RequirementsPage
