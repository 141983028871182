import CoachAppBar from 'components/coach-app-bar'
import ReviewBooking from 'pages/schedule/ReviewBooking'
import { useQuery } from '@apollo/client'
import { GET_LESSON_PACK_OFFERINGS_BY_ID } from 'pages/academy/query'
import { LinearProgress } from '@material-ui/core'
import Error from 'components/error'
import BuyLessonPacksLayout from 'pages/schedule/BuyLessonPacksLayout/index'

const LessonPackSelection = ({
  idOrSlug,
  lessonTypeId,
  locationId,
  startDateTime,
  studentId,
  previousUrl,
  progressValue,
  stepText,
}) => {
  const { data, loading, error } = useQuery(GET_LESSON_PACK_OFFERINGS_BY_ID, {
    variables: {
      id: lessonTypeId,
    },
  })
  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />
  const lessonPackOfferings = data?.lessonTypes[0]?.lessonPackOfferings
  const isLessonPack = lessonPackOfferings?.length > 0
  // if there are lesson pack offerings, show the lesson pack purchase layout
  // else show the lesson purchase layout
  return (
    <>
      <CoachAppBar position="relative" hideContactButton />
      {isLessonPack ? (
        <BuyLessonPacksLayout
          previousUrl={previousUrl}
          lessonTypeId={lessonTypeId}
          idOrSlug={idOrSlug}
          locationId={locationId}
        />
      ) : (
        <ReviewBooking
          idOrSlug={idOrSlug}
          lessonTypeId={lessonTypeId}
          locationId={locationId}
          startDateTime={startDateTime}
          studentId={studentId}
          previousUrl={previousUrl}
          progressValue={progressValue}
          stepText={stepText}
        />
      )}
    </>
  )
}
export default LessonPackSelection
