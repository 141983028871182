import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams, useLocation, useRouteMatch } from 'react-router-dom'
import RequestPaymentForm from './RequestPaymentForm'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { GET_PAYMENT_REQUEST_ENROLLMENT } from './query'
import PrepaidSuccess from './components/prepaid-success'
import PaymentRequestShell from './components/payment-request-shell'
import PropTypes from 'prop-types'
import PaymentSuccess from './components/success-message'
import CanceledPaymentRequest from './components/canceled-payment-request'

export const PaymentMethodContext = React.createContext()

const RequestedPaymentPortal = ({ enrollment, showSucces = false }) => {
  const [paymentMethod, setPaymentMethod] = useState(null)
  const location = useLocation()
  if (!enrollment) {
    return <Error message="Enrollment not found." />
  }

  const {
    isPaid = false,
    lesson,
    paymentRequestedDate,
    canceledEnrollment = false,
  } = enrollment || {}
  const coach = lesson?.coach
  const coachProfileUrl = `/coach/${coach?.coachProfile?.slug}/schedule`
  const queryParams = new URLSearchParams(location.search)
  const urlPaymentRequestedDate = queryParams.get('paymentRequestedDate') || ''

  const hasPaymentRequestedDate =
    paymentRequestedDate || urlPaymentRequestedDate

  const isEnrollmentCanceledOrPaymentNotRequested =
    canceledEnrollment || !hasPaymentRequestedDate

  const renderCanceledPaymentRequest = () => <CanceledPaymentRequest />

  const renderPrepaidSuccess = coachProfileUrl => (
    <PrepaidSuccess coachProfileUrl={coachProfileUrl} />
  )

  const renderRequestPaymentForm = enrollment => (
    <RequestPaymentForm enrollment={enrollment} />
  )
  const renderSuccessMessage = () => <PaymentSuccess />

  const componentSelector = () => {
    if (showSucces) {
      return renderSuccessMessage()
    }
    if (isPaid) {
      return renderPrepaidSuccess(coachProfileUrl)
    }
    if (isEnrollmentCanceledOrPaymentNotRequested) {
      return renderCanceledPaymentRequest()
    }
    return renderRequestPaymentForm(enrollment)
  }

  return (
    <PaymentMethodContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      <PaymentRequestShell>{componentSelector()}</PaymentRequestShell>
    </PaymentMethodContext.Provider>
  )
}

const PaymentRequestPage = () => {
  const { paymentRequestToken } = useParams()
  const successPath = useRouteMatch('/payment/:paymentRequestToken/success')

  const { data, loading, error } = useQuery(GET_PAYMENT_REQUEST_ENROLLMENT, {
    variables: { paymentRequestToken: paymentRequestToken },
    skip: !paymentRequestToken,
  })
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />
  const enrollment = data?.paymentRequest

  return (
    <RequestedPaymentPortal enrollment={enrollment} showSucces={successPath} />
  )
}

export default PaymentRequestPage

RequestedPaymentPortal.propTypes = {
  enrollment: PropTypes.shape({
    isPaid: PropTypes.bool.isRequired,
    lesson: PropTypes.shape({
      coach: PropTypes.shape({
        coachProfile: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
    }),
  }),
}
