import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import Error from 'components/error'
import { useAuth } from 'lib/auth'
import { LessonReviewQuery, GetStudentQuery } from 'pages/schedule/queries'
import LessonReviewLayout from './lesson-review-layout'
import ReviewBookingSkeleton from 'components/skeletons/review-booking-skeleton'
import SummaryLayout from './summary-layout'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const ReviewBooking = ({
  idOrSlug,
  lessonTypeId,
  locationId,
  startDateTime,
  studentId,
  previousUrl,
  progressValue,
  stepText,
}) => {
  const fetchedLessonInfo = useQuery(LessonReviewQuery, {
    variables: {
      id: lessonTypeId,
    },
  })
  const fetchedStudent = useQuery(GetStudentQuery, {
    variables: {
      id: studentId,
    },
  })

  const { user } = useAuth()
  const coachIsViewingOwnSchedule =
    user?.coach?.coachProfile?.slug === idOrSlug ||
    user?.externalId === idOrSlug

  const [isComplete, setIsComplete] = useState(false)
  const [cardBrand, setCardBrand] = useState()
  const [lastDigits, setLastDigits] = useState()

  if (fetchedLessonInfo.error || fetchedStudent.error) {
    return <Error error={fetchedLessonInfo.error || fetchedStudent.error} />
  }
  if (fetchedLessonInfo.loading || fetchedStudent.loading) {
    return <ReviewBookingSkeleton />
  }
  const selectedStudent = fetchedStudent.data?.student

  if (!user) {
    return <Error error="Not logged in" message="Not logged in" />
  }

  const lessonType = get(fetchedLessonInfo.data, 'lessonTypes.0')
  const timezone = get(lessonType?.facilities, ['0', 'timezone'])

  const eventObject = {
    event: '',
    title: lessonType?.title,
    category: lessonType?.category,
    facility: get(lessonType?.facilities, ['0', 'name']),
    location: `${get(lessonType?.facilities, ['0', 'city'])}, ${get(
      lessonType?.facilities,
      ['0', 'state'],
    )}`,
    price: `${lessonType?.priceInCents / 100}`,
    event_date: DateTime.fromISO(startDateTime, {
      zone: timezone,
    }).toFormat('yyyy-LL-dd'),
    event_time: DateTime.fromISO(startDateTime, {
      zone: timezone,
    }).toFormat('hh:mm a'),
    duration: lessonType?.durationInMinutes,
    coach_id: lessonType?.coach?.id,
    coach_name: lessonType?.coach?.name,
    access_code_required: lessonType?.requiresAccessCode ? 'yes' : 'no',
  }

  return (
    <>
      {!isComplete ? (
        <LessonReviewLayout
          lessonType={get(fetchedLessonInfo.data, 'lessonTypes.0')}
          student={selectedStudent}
          locationId={locationId}
          startDateTime={startDateTime}
          previousUrl={previousUrl}
          isComplete={isComplete}
          setIsComplete={setIsComplete}
          setCardBrand={setCardBrand}
          setLastDigits={setLastDigits}
          coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
          progressValue={progressValue}
          stepText={stepText}
          eventObject={eventObject}
        />
      ) : (
        <SummaryLayout
          lessonType={get(fetchedLessonInfo.data, 'lessonTypes.0')}
          student={selectedStudent}
          idOrSlug={idOrSlug}
          lessonTypeId={lessonTypeId}
          locationId={locationId}
          startDateTime={startDateTime}
          previousUrl={previousUrl}
          cardBrand={cardBrand}
          lastDigits={lastDigits}
          isComplete={isComplete}
          coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
        />
      )}
    </>
  )
}

ReviewBooking.propTypes = {
  idOrSlug: PropTypes.string.isRequired,
  lessonTypeId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  previousUrl: PropTypes.string,
  progressValue: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
}

export default ReviewBooking
