import some from 'lodash/some'

const ApolloDataView = ({ children, error, loading, data }) => {
  const { errorView, loadingView, emptyView, dataView } = children
  const hasData = some(data)

  if (error) {
    return errorView
  }

  if (!error && loading) {
    return loadingView
  }

  if (!error && !loading && !hasData) {
    return emptyView
  }

  if (!error && !loading && hasData) {
    return dataView(data)
  }
  return null
}

export default ApolloDataView
