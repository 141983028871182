/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import SliderControl from 'components/atoms/slider-control'
import PropTypes from 'prop-types'
import useStyles from './slider-new.styles'
import { ResizePlugin } from './plugins'
import { baseConfig } from './utils'

const SliderNew = ({
  children, config, plugins, className, controlClassName, centerSlider, fade, fadeBg,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [disabledControls, setDisabledControls] = useState(false)
  const styles = useStyles({ disabledControls, centerSlider, fadeBg })
  const [slidesLength, setSlidesLength] = React.useState(0)

  const handleToggleControls = useCallback((disabled) => {
    setDisabledControls(disabled)
  }, [])

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      ...baseConfig,
      ...config,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel)
      },
    },
    [...plugins, ResizePlugin(handleToggleControls)],
  )

  const handleNext = () => {
    if (instanceRef.current) {
      instanceRef.current.next()
    }
  }
  const handlePrevious = () => {
    if (instanceRef.current) {
      instanceRef.current.prev()
    }
  }

  useEffect(() => {
    if (instanceRef.current) {
      if (instanceRef.current.update) {
        instanceRef.current.update()
      }
      if (instanceRef.current.track?.details?.slidesLength <= 1) {
        setDisabledControls(true)
      } else {
        setDisabledControls(false)
      }
      setSlidesLength(instanceRef.current.track?.details?.maxIdx)
    }
  }, [instanceRef, children])

  return (
    <div className={`${styles.sliderRoot}`} data-testid="slider">
      <div ref={sliderRef} className={`keen-slider ${styles.keenSlider} ${className}`}>
        {fade && <div className={styles.leftFadeBox} />}
        {children.map((child, index) => {
          const current = currentSlide === index
          return (
            <div
              className={`keen-slider__slide ${styles.slide}`}
              key={`${index + 1}`}
            >
              <div className={`${styles.slideScaler} ${current ? styles.slideScalerOpen : ''}`}>
                {React.cloneElement(child, { className: styles.card })}
              </div>
            </div>
          )
        })}
        {fade && <div className={styles.rightFadeBox} />}
      </div>
      {!disabledControls && (
        <SliderControl
          className={`${styles.sliderControl} ${controlClassName} ${currentSlide === 0 ? styles.disablePrev : ''} ${currentSlide === slidesLength ? styles.disableNext : ''}`}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      )}
    </div>
  )
}

SliderNew.propTypes = {
  children: PropTypes.array,
  config: PropTypes.object,
  plugins: PropTypes.array,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  centerSlider: PropTypes.bool,
  fade: PropTypes.bool,
  fadeBg: PropTypes.string,
}

SliderNew.defaultProps = {
  config: {},
  plugins: [],
  className: '',
}

export default SliderNew
