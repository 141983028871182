import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import { get } from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const PricesQuery = gql`
  query Prices {
    Prices {
      message
      success
      prices {
        priceId
        price
      }
    }
  }
`

const setPriceValue = (priceId, setFieldValue) => {
  return setFieldValue('prices', [{ priceId }])
}

const PricesComp = ({ prices, setFieldValue, values, touched, errors }) => {
  const isError = touched.prices && errors.prices
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">Price</Typography>
        <Typography variant="body2" color="textSecondary">
          How much do you charge per lesson?
        </Typography>
        <InputLabel error={isError} style={{ marginTop: '10px' }}>
          Price
        </InputLabel>
        <Select
          value={values.prices && values.prices[0] && values.prices[0].priceId}
          data-testid="price-input"
          onChange={e => setPriceValue(e.target.value, setFieldValue)}
          error={isError}
          style={{ width: '100%' }}
        >
          {prices.map(({ priceId, price }) => (
            <MenuItem key={priceId} id={priceId} value={priceId}>
              {price}
            </MenuItem>
          ))}
        </Select>
        {isError && (
          <FormHelperText style={{ color: '#f44336' }}>
            {errors.prices}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

PricesComp.propTypes = {
  prices: PropTypes.array,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
}

export default function Price({ setFieldValue, touched, values, errors }) {
  const { data, loading, error } = useQuery(PricesQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Box>ERROR</Box>

  return (
    <PricesComp
      prices={get(data, ['Prices', 'prices'], [])}
      setFieldValue={setFieldValue}
      values={values}
      touched={touched}
      errors={errors}
    />
  )
}

Price.propTypes = {
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
}
