import React from 'react'
import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  infoAlert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 16px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
    borderRadius: theme.spacing(1),
    '& svg': {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
  },
}))

export default function InfoAlert(props) {
  const classes = useStyles()
  const { children, maxWidth, ...rest } = props

  return (
    <Container maxWidth={maxWidth}>
      <Box {...rest}>
        <Alert severity="info" color="grey" className={classes.infoAlert}>
          {children}
        </Alert>
      </Box>
    </Container>
  )
}
