import React from 'react'
import PropTypes from 'prop-types'
import { Divider, ThemeProvider } from '@material-ui/core'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import StepDescriptionOfferings from 'pages/schedule/step-description-offerings'
import PrivateBookingHeader from '../components/private-booking-header'
import themes, { extendTheme } from 'themes'
import BackButton from 'components/back-button'
import { ContainerRoot } from 'pages/schedule/components/styles'
import { GET_COACHING_CENTER_LESSONS_BY_COACH } from 'pages/academy/query'
import { useQuery } from '@apollo/client'
import Error from 'components/error'
import { LessonTypesCoachCards } from 'pages/academy/components/lesson-types-coach-cards'

const LessonTypesByCoach = ({
  academy,
  lessonTypeCategory,
  coaches,
  currentUrl,
  previousUrl,
}) => {
  const coachIds = coaches?.map(coach => coach.id)
  const { data, loading, error } = useQuery(
    GET_COACHING_CENTER_LESSONS_BY_COACH,
    {
      variables: {
        slug: academy.slug,
        category: lessonTypeCategory.categoryParam,
        coachIds: coachIds,
      },
    },
  )
  if (loading) return <ProgressIndicator />
  if (error) return <Error error={error} />
  const coachesWithLessonTypes = data?.academy?.lessonTypesByCoach
  return (
    <ContainerRoot>
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        <ProgressIndicator value={50} />
        <BackButton previousUrl={previousUrl} />
        <PrivateBookingHeader academy={academy} category={lessonTypeCategory} />
        <Divider variant="fullWidth" style={{ marginBottom: '2.5rem' }} />
        <StepDescriptionOfferings
          stepText="Step 2 of 4"
          title="Select a Lesson Type"
          description={
            'Select what you would like to focus on during your lesson'
          }
        />
        <LessonTypesCoachCards
          coachesWithLessonTypes={coachesWithLessonTypes}
          currentUrl={currentUrl}
        />
      </ThemeProvider>
    </ContainerRoot>
  )
}

export default LessonTypesByCoach

LessonTypesByCoach.propTypes = {
  academy: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  lessonTypeCategory: PropTypes.shape({
    categoryParam: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    suggestedName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  coaches: PropTypes.array.isRequired,
  currentUrl: PropTypes.string.isRequired,
  previousUrl: PropTypes.string,
}
