import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    margin: '96px 56px 56px 56px',
    [theme.breakpoints.only('xs')]: {
      margin: 0,
    },
  },
  actionBar: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '64px',
    zIndex: '10',
    width: 'calc(100% - 250px)',
    display: 'flex',
    justifyContent: 'flex-end',
    background: '#e9f5fe',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.only('xs')]: {
      top: 'auto',
      bottom: 0,
      width: '100%',
      background: '#fff',
      padding: '12px 12px 24px',
      justifyContent: 'space-around',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    [theme.breakpoints.up('sm')]: {
      '& button': {
        marginRight: theme.spacing(2),
      },
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.only('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  },
  content: {
    padding: '0 !important',
    maxWidth: '960px',
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  formField: {
    marginTop: theme.spacing(2),
  },
  formFieldCurrencyWithNotice: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    minWidth: '40px',
  },
  editorRoot: {
    minHeight: '400px',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '0 16px',
    marginTop: '24px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  copyUrl: {
    background: '#D9D9D9',
    width: theme.spacing(5),
    height: theme.spacing(5),
    '&:hover': {
      background: '#D9D9D9',
    },
  },
  shareWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '16px 126px',
    [theme.breakpoints.only('xs')]: {
      padding: '8px',
    },
  },
  social: {
    display: 'flex',
    minWidth: '52px',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    textTransform: 'none',
  },
  mobileActionWrapper: {
    [theme.breakpoints.only('xs')]: {
      zIndex: '10',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#fff',
      padding: '18px 12px',
      height: '70px',
      justifyContent: 'space-around',
      boxSizing: 'border-box',
      boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
}))

export default useStyles
