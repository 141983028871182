import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import {
  HelperMsg,
  ProfileExamples,
} from 'components/coaches-profile/profile.styles'
import CustomSlug from '../form-components/CustomSlug'
import SuggestedSlug from 'components/coaches-profile/profile-components/SuggestedSlug'

const useStyles = makeStyles(theme => ({
  profileNameContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const firstLastTmpl = (a, b) => `${a}${b}`
const firstLastDashTmpl = (a, b) => `${a}-${b}`
const firstLastPGATmpl = (a, b) => `${a}${b}PGA`
const firstCharLastTmpl = (a, b) => `${a.charAt(0)}${b}`

export const ProfileNameTmpls = [
  firstLastTmpl,
  firstLastDashTmpl,
  firstLastPGATmpl,
  firstCharLastTmpl,
]

export const getSuggestions = (a = '', b = '', templateIndex) =>
  ProfileNameTmpls[templateIndex](a.toLowerCase(), b.toLowerCase()).replace(
    /[^a-zA-Z0-9-_]/gi,
    '',
  )

const ProfileName = () => {
  const { errors, getValues, control, setValue } = useFormContext()
  const { firstName, lastName } = getValues()
  const classes = useStyles()

  const [suggestedIndex, setSuggestedIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(true)

  const handleSuggestionClick = () => {
    setSuggestedIndex(prevState =>
      prevState >= ProfileNameTmpls.length - 1 ? 0 : prevState + 1,
    )
  }

  const handleUseClick = () => {
    setValue('customSlug', getSuggestions(firstName, lastName, suggestedIndex))
    setShowSuggestions(false)
  }

  const handleShowSuggestionClick = () => {
    setShowSuggestions(true)
  }

  const exampleProfiles = ['Suzywhaley', 'toddsones', 'trillium']

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" gutterBottom>
        Set Your Profile Name
      </Typography>
      <Box className={classes.profileNameContainer}>
        <HelperMsg style={{ marginBottom: 0 }}>
          Your profile will be shown on PGA.com. Where would you like your
          personal page to be located?
        </HelperMsg>
        <Controller
          name="customSlug"
          control={control}
          render={({ onChange, onBlur, value }, { isTouched }) => (
            <CustomSlug
              newProfile={true}
              values={{ customSlug: value } || {}}
              handleChange={onChange}
              handleBlur={onBlur}
              touched={{ customSlug: isTouched } || {}}
              errors={errors}
            />
          )}
        />
        <>
          {showSuggestions ? (
            <>
              <SuggestedSlug
                onSubmit={handleUseClick}
                onShuffle={handleSuggestionClick}
                templateHandlerResult={getSuggestions(
                  firstName,
                  lastName,
                  suggestedIndex,
                )}
              />
              <h4>Example Coach Profiles</h4>
              <ProfileExamples>
                {exampleProfiles.map(example => (
                  <li key={example}>
                    <a
                      href={`https://www.pga.com/coach/${example}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.pga.com/coach/
                      <span className="highlight">{example}</span>
                    </a>
                  </li>
                ))}
              </ProfileExamples>
            </>
          ) : (
            <Typography>
              <Button onClick={handleShowSuggestionClick}>
                Show Suggestions
              </Button>
            </Typography>
          )}
        </>
      </Box>
    </Box>
  )
}

export default ProfileName
