import { PGA_COM_HOST } from 'env'

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Confetti from 'react-dom-confetti'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import PartyPopperIcon from 'components/icons/party-popper-icon'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import CopyTextButton from 'components/copy-text-button'
import SocialSharePopover from 'components/social-share-popover'
import { useAuth } from 'lib/auth'
import Dialog from 'components/dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { gtmEvent } from 'lib/gtm'

const confettiConfig = {
  angle: '219',
  spread: 360,
  startVelocity: '50',
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '1',
  width: '12px',
  height: '8px',
  perspective: '499px',
  colors: ['#00234B', '#AB9157', '#043362', '#BBA778'],
}
const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
    },
    '& h4': {
      fontWeight: 700,
    },
    '& p': {
      color: theme.palette.text.secondary,
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      padding: '8px',
    },
  },
  textWrapper: {
    margin: `0 ${theme.spacing(8)}px`,
    [theme.breakpoints.only('xs')]: {
      margin: 0,
    },
  },
  dialogIcon: {
    borderRadius: '50%',
    background: 'rgba(0,25,75,.08)',
    padding: '1rem',
    color: theme.palette.primary.main,
    width: '83px',
    height: '83px',
    boxSizing: 'border-box',
    margin: '12px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '36px',
    },
  },
}))

export const OnboardingSuccessDialog = ({ isOpen, setIsOpen }) => {
  const classes = useStyles()

  useEffect(() => {
    if (isOpen) {
      gtmEvent({
        event: 'onboarding-step-completed',
      })
    }
  }, [isOpen])

  return (
    <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} maxWidth="sm">
      {() => (
        <Box className={classes.dialogContainer}>
          <Box className={classes.dialogIcon}>
            <CelebrationOutlinedIcon />
          </Box>
          <DialogTitle>
            <Typography variant="h5" align="center">
              Congratulations on Completing Onboarding!
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.textWrapper}>
              <Typography variant="body1" align="center">
                You're ready to start using PGA Coach! Head to your Dashboard
                for more tips on using PGA Coach!
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={'/pga-coach'}
            >
              Back to Dashboard
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  )
}
const SuccessStep = ({ slug, onCreateAnother, showOnboardingModal }) => {
  const [isConfettiEnabled, setIsConfettiEnabled] = useState(false)
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(showOnboardingModal)

  useEffect(() => {
    setTimeout(() => {
      setIsConfettiEnabled(true)
    }, 500)
  })
  const groupEventLink = `${PGA_COM_HOST}/things-to-do/events/${slug}`

  return (
    <>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={8}
        >
          <Box mb={1}>
            <CircularHighlightedIcon icon={PartyPopperIcon} />
          </Box>
          <Confetti active={isConfettiEnabled} config={confettiConfig} />
          <Typography variant="h4" style={{ fontWeight: 500 }} gutterBottom>
            Success!
          </Typography>
          <Box textAlign="center" mb={2}>
            <Typography variant="body1">
              Your event has been successfully created. You can find the event
              page below.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <Typography>{groupEventLink}</Typography>
            <CopyTextButton component={IconButton} value={groupEventLink}>
              <FileCopyOutlinedIcon />
            </CopyTextButton>
            <SocialSharePopover
              url={groupEventLink}
              trigger={
                <IconButton>
                  <ShareOutlinedIcon />
                </IconButton>
              }
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onCreateAnother}
            >
              Create Another Event
            </Button>
          </Box>
          <Button
            color="primary"
            component={Link}
            to={`${
              user.onboarding
                ? '/pga-coach/set-up-schedule'
                : '/pga-coach/bookings/events'
            }`}
          >
            I'm Finished
          </Button>
        </Box>
        <OnboardingSuccessDialog isOpen={isOpen} setIsOpen={setIsOpen} />
      </Container>
    </>
  )
}

export default SuccessStep
