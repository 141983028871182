import React from 'react'
import { DateTime } from 'luxon'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Typography from '@material-ui/core/Typography'

import useStyles from './styles'

const EmptyState = ({ type }) => {
  const styles = useStyles()
  return (
    <div className={styles.noLeadsWrapper}>
      <Typography style={{ marginBottom: '15px' }} variant="h6" align="center">
        No results found.
      </Typography>
      {type === 'leads' && (
        <Typography variant="h6" align="center">
          {' '}
          Try updating your coach profile to gain more interest from customers.
        </Typography>
      )}
    </div>
  )
}

const isEligibleForReview = dt => {
  if (!dt) return true
  const sixMonthsAgo = DateTime.local().minus({ months: 6 })
  const reviewDate = DateTime.fromSQL(dt)
  return reviewDate < sixMonthsAgo
}

export default ({ inquiries, contacts, addContact, type }) => {
  const styles = useStyles()
  return (
    <>
      {!inquiries.length ? (
        <EmptyState type={type} />
      ) : (
        inquiries.map(({ reviewUpdatedAt, contact, id }) => (
          <div className={styles.contactCard} key={id}>
            {isEligibleForReview(reviewUpdatedAt) ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contacts.includes(contact.id)}
                    onChange={() => addContact(contact.id)}
                    name={contact.lastName}
                    color="primary"
                  />
                }
                label={`${contact.firstName} ${contact.lastName}`}
              />
            ) : (
              <Typography>{`${contact.firstName} ${contact.lastName}`}</Typography>
            )}
            {reviewUpdatedAt && (
              <div style={{ display: 'flex' }}>
                <Typography>
                  Requested:{' '}
                  {DateTime.fromSQL(reviewUpdatedAt).toLocaleString(
                    DateTime.DATE_SHORT,
                  )}
                </Typography>
                <Tooltip
                  className={styles.dateTooltip}
                  style={{ marginLeft: '5px' }}
                  title="Reviews can be requested every 6 months"
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
            )}
          </div>
        ))
      )}
    </>
  )
}
