import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '60px',
  },
  container: {
    marginTop: '60px',
    marginBottom: '60px',
  },
  heading: {
    fontWeight: '500',
  },
  subheading: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
  },
  select: {
    margin: '60px 0 30px',
  },
}))

export default useStyles
