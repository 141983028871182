import React from 'react'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import EmptyPageState from 'components/empty-page-state'
import { PageHeader } from 'components/page-header'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined'
import ButtonCta from 'components/atoms/button-cta'
import { BOOKINGS_QUERY } from './query'
import Error from 'components/error'
import { DateTime, Interval } from 'luxon'
import SessionListItem from 'pages/pga-coach/bookings/schedule/session-list-item'
import GroupEventListItem from 'pages/pga-coach/bookings/schedule/group-event-list-item'
import { PGA_COM_HOST } from 'env'
import { PrivateSessionIcon } from 'components/icons/refreshed-icons'
import { useIsMobile } from 'lib/hooks'
import SocialShareDialog from 'components/social-share-dialog'
import Button from '@material-ui/core/Button'
import { onReschedule, onPayment, onCancellation } from 'lib/utils'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    margin: '0 60px 60px',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 20px',
    },
  },
  icon: {
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '12px',
    color: '#00234B',
    fontSize: '32px',
  },
  action: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  tile: {
    backgroundColor: '#F5F7FA',
    borderRadius: '12px',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    height: '100%',
    boxSizing: 'border-box',
  },
}))

const viewADMCertificateLink =
  'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/reports/certification/'
const goToTrainingLink =
  'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/'

const DefaultDashboard = ({
  hasGroupEventsOrLessonTypes,
  slug,
  isAdmCertified,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const setupItems = [
    {
      icon: <SchoolOutlinedIcon style={{ fontSize: '32px' }} />,
      title: 'Training',
      description:
        'PGA Coach offers a training center featuring diverse courses designed to elevate your coaching journey and empower you to become the best coach possible.',
      link:
        'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/',
    },
    {
      icon: <PrivateSessionIcon color="#00234B" size="24" />,
      title: 'ADM',
      description:
        'Developing fundamental skills for all sports by providing a "sport pathway" for coaches and athletes to get involved and become passionate about sports for their lifetime.',
      link: isAdmCertified ? viewADMCertificateLink : goToTrainingLink,
    },
    {
      icon: <GroupAddOutlinedIcon style={{ fontSize: '32px' }} />,
      title: 'Group Events',
      description:
        "Create an engaging group event for your local community. We'll create a marketing page for you on pga.com and handle all the registrations.",
      link: '/pga-coach/bookings/events',
    },
  ]

  const startDateTime = {
    from: DateTime.now()
      .startOf('day')
      .toISO(),
    to: null,
  }

  const isInvalidDateRange =
    startDateTime.from &&
    startDateTime.to &&
    !Interval.fromDateTimes(
      DateTime.fromISO(startDateTime.from),
      DateTime.fromISO(startDateTime.to),
    ).isValid

  const { data, loading, error, refetch: refreshBookings } = useQuery(
    BOOKINGS_QUERY,
    {
      skip: isInvalidDateRange,
      variables: {
        startDateTime: startDateTime,
        orderBy: [{ field: 'START_DATE_TIME', direction: 'ASC' }],
        limit: 2,
      },
    },
  )

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />

  const schedule = data?.currentUser.schedule || []

  return (
    <>
      <PageHeader title="Welcome to PGA Coach!" />
      <Box className={classes.container}>
        {hasGroupEventsOrLessonTypes && schedule.length > 0 ? (
          <>
            <Box>
              {schedule.map(booking => {
                if (booking.__typename === 'Session') {
                  return (
                    <SessionListItem
                      key={booking.id}
                      session={booking}
                      onReschedule={() => onReschedule(refreshBookings)}
                      onPayment={() => onPayment(refreshBookings)}
                      onCancellation={() => onCancellation(refreshBookings)}
                      withDate={true}
                    />
                  )
                }
                if (booking.__typename === 'GroupEvent') {
                  return (
                    <GroupEventListItem
                      key={booking.id}
                      groupEvent={booking}
                      onPayment={() => onPayment(refreshBookings)}
                      withDate={true}
                    />
                  )
                }
                return null
              })}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ButtonCta
                variant="text"
                color="primary"
                href={'/pga-coach/bookings/schedule'}
              >
                View Full Schedule
              </ButtonCta>
            </Box>
          </>
        ) : (
          <Box style={{ maxWidth: '530px', margin: 'auto' }}>
            <EmptyPageState
              IconComp={() => <CalendarTodayOutlinedIcon fontSize="large" />}
              title="Your Tee Sheet is Wide Open!"
              caption="Your upcoming lessons and events will display here. Share your booking link with students to start scheduling lessons using PGA Coach!"
              removeButton
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <SocialShareDialog
                trigger={
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '12px', marginBottom: '12px' }}
                  >
                    SHARE BOOKING LINK
                  </Button>
                }
                shareUrl={`${PGA_COM_HOST}/coach/${slug}`}
                shareTitle="Booking Link"
                dialogTitle="Share this booking link with your students"
                showBookingUrl={true}
                att="booking-link"
              />
            </Box>
          </Box>
        )}
        <Box mt={isMobile ? 4 : 7} mb={2}>
          <Typography variant="h6">Continue your Set Up</Typography>
        </Box>
        <Grid container spacing={2}>
          {setupItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box key={index} className={classes.tile}>
                <Box>
                  <Box className={classes.icon}>{item.icon}</Box>
                  <Typography variant="h6" color="primary">
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ marginBottom: '12px', marginTop: '8px' }}
                  >
                    {item.description}
                  </Typography>
                </Box>
                <ButtonCta
                  variant="outlined"
                  color="primary"
                  href={!item.link.includes('https') ? item.link : null}
                  externalLink={item.link.includes('https') ? item.link : null}
                >
                  Learn More
                </ButtonCta>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default DefaultDashboard
