import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Container from '@material-ui/core/Container'
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined'
import { isEmpty } from 'lodash'
import RouterLinkButton from 'components/router-link-button'
import EditAvailabilityDisplay, {
  EditAvailabilityDisplayLoading,
} from './edit-availability-display'
import Disclaimer from 'components/disclaimer'
import HowAvailabilityWorksDialog from './how-availability-works-dialog'

const GET_AVAILABILITY = gql`
  query CoachAvailability {
    coachAvailabilities {
      coachFacility {
        id
        name
      }
      startDate
      timeZone
      timeSlots {
        day
        startTime
        endTime
      }
    }
  }
`

const AvailabilityManagerWrapper = ({ children }) => {
  const description =
    "Define your working hours on any day of the week when you're at your facility and ready " +
    "to coach. Simply select the days and times you're available to provide coaching sessions."

  return (
    <>
      <Container maxWidth="md" style={{ marginBottom: '32px' }}>
        <Disclaimer
          icon={
            <EmojiObjectsOutlinedIcon
              fontSize="large"
              style={{ color: '#2196F3' }}
            />
          }
          description={
            <>
              <strong>Not sure where to start?</strong>
              <Typography variant="body1">
                View this sample calendar to better illustrate what general
                availability is.
              </Typography>
            </>
          }
          action={<HowAvailabilityWorksDialog />}
        />
      </Container>
      <Grid container item md={4} xs={12}>
        <Typography variant="h6">General Availability</Typography>
      </Grid>
      <Grid container item md={8} xs={12}>
        <Box mb={4}>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </Grid>
      {children}
    </>
  )
}

const AvailabilityManager = ({ refetchCoachOnboardingStatus }) => {
  const [selectedLocationId, setSelectedLocationId] = useState(null)
  const { data, loading, refetch } = useQuery(GET_AVAILABILITY)

  if (loading) {
    return (
      <AvailabilityManagerWrapper>
        <EditAvailabilityDisplayLoading />
      </AvailabilityManagerWrapper>
    )
  }

  if (isEmpty(data.coachAvailabilities)) {
    return (
      <AvailabilityManagerWrapper>
        <Typography variant="h6" color="textPrimary">
          You have not set up any facilities.
        </Typography>
        <Box mt={2}>
          <RouterLinkButton
            variant="contained"
            color="primary"
            to="/pga-coach/facilities/new"
          >
            Add Facility
          </RouterLinkButton>
        </Box>
      </AvailabilityManagerWrapper>
    )
  }

  const selectedAvailability = selectedLocationId
    ? data.coachAvailabilities.find(
      a => a.coachFacility.id === selectedLocationId,
    )
    : data.coachAvailabilities[0]

  const manageFacilityButton = (
    <RouterLinkButton
      color="primary"
      component="a"
      to="/pga-coach/facilities"
      style={{ paddingLeft: '0px', marginTop: '6px' }}
    >
      Manage Facilities
    </RouterLinkButton>
  )

  return (
    <AvailabilityManagerWrapper>
      <Grid item lg={4} xs={12}>
        <Box mb={2}>
          {data.coachAvailabilities.length === 1 ? (
            <FormControl variant="outlined">
              <TextField
                disabled
                value={selectedAvailability.coachFacility.name}
                variant="outlined"
              ></TextField>
            </FormControl>
          ) : (
            <FormControl variant="outlined">
              <TextField
                select
                value={selectedAvailability.coachFacility.id}
                variant="outlined"
                label="Select Facility"
                onChange={e => setSelectedLocationId(e.target.value)}
              >
                {data.coachAvailabilities.map(a => (
                  <MenuItem key={a.coachFacility.id} value={a.coachFacility.id}>
                    {a.coachFacility.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          {manageFacilityButton}
        </Box>
      </Grid>
      <EditAvailabilityDisplay
        availability={selectedAvailability}
        refetch={refetch}
        refetchCoachOnboardingStatus={refetchCoachOnboardingStatus}
      />
    </AvailabilityManagerWrapper>
  )
}

export default AvailabilityManager
