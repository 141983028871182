import gql from 'graphql-tag'

export const GetConnectionQuery = gql`
  query GetConnectionRequest($connectionRequestToken: String!) {
    GetConnectionRequest(connectionRequestToken: $connectionRequestToken) {
      status
      student {
        id
        firstName
        lastName
        contact {
          phoneNumber
          email
        }
      }
      coach {
        name
        phone
      }
      coachProfile {
        firstName
        lastName
        profilePhoto
      }
    }
  }
`

export const ConnectionResponseMutation = gql`
  mutation ConnectionRequestUpdate($params: ConnectionRequestUpdateInput!) {
    ConnectionRequestUpdate(params: $params) {
      message
      success
      status
    }
  }
`
