import React from 'react'
import Dialog from 'components/dialog'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import StarsIcon from '@material-ui/icons/Stars'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { redirectToLogin, redirectToSignup } from 'lib/auth'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '20px',
    padding: '20px',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  iconStyles: {
    marginRight: '10px',
  },
}))

const bulletPoints = [
  {
    Icon: StarsIcon,
    description:
      'Communicate in real time with your PGA Coach using our messaging feature',
  },
  {
    Icon: StarsIcon,
    description: 'Get access to the MyPGA mobile app',
  },
  {
    Icon: StarsIcon,
    description: 'Much more coming soon!',
  },
]

export default ({ isOpen, setIsOpen }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  return (
    <Dialog
      fullWidth={true}
      isOpen={isOpen}
      showCloseButton
      fullScreen={isMobile}
      onClose={() => setIsOpen(false)}
    >
      {({ closeDialog }) => (
        <>
          <DialogContent className={classes.root}>
            <Typography variant="h5" align="center">
              Create a FREE MyPGA account
            </Typography>
            <div style={{ margin: '40px 0' }}>
              {bulletPoints.map(({ Icon, description }) => (
                <div className={classes.flexBox} key={description}>
                  <Icon fontSize="large" className={classes.iconStyles} />
                  <Typography variant="body1">{description}</Typography>
                </div>
              ))}
            </div>
            <div className={classes.actionSection}>
              <Button
                onClick={() => redirectToSignup('consumer')}
                variant="contained"
                color="primary"
              >
                CREATE ACCOUNT
              </Button>
              <Typography
                variant="caption"
                color="primary"
                align="center"
                mt={3}
              >
                Already have an account?{' '}
                <Link
                  component="span"
                  style={{ cursor: 'pointer' }}
                  onClick={() => redirectToLogin('consumer')}
                >
                  Sign in
                </Link>
              </Typography>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
