import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  cardRoot: {
    marginTop: '2rem',
    marginBottom: '16px',
    border: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    /* overflow to prevent clipping of avatar images: */
    overflow: 'visible !important',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  qrCardRoot: {
    padding: '24px',
  },
  avatarStyles: {
    height: '100px',
    width: '100px',
    marginBottom: '1.25rem',
    textTransform: 'uppercase',
    fontSize: '2rem',
    border: '3px solid white',
    boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.15)',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    padding: '2rem',
  },
  cardDetail: {
    paddingLeft: '1.75em',
  },
  cardDetailIcon: {
    position: 'absolute',
    top: '-0.15em',
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #ccc',
    padding: '1rem',
  },
  cardDetailDivider: {
    position: 'absolute',
    top: '0',
  },
  cardActionsArrow: {
    padding: '0 10px',
    marginTop: '8px',
  },
  titleStyles: {
    color: '#AB9157',
    textTransform: 'uppercase',
    padding: '5px 0',
    margin: '0 0 1rem',
    letterSpacing: '0.4px',
    fontWeight: '500',
  },
  adminButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '85%',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '85%',
    height: '50px',
    borderTop: '1px solid darkGray',
    paddingTop: '10px',
  },
  moreResultsControlArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreSearchResultsControl: {
    margin: '2em',
    padding: '1.5em 5em',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.secondary.main}`,
    transition:
      'background .25s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s linear',
    '&:hover': {
      color: '#808080',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    lineHeight: '22px',
    marginBottom: theme.spacing(1),
    marginLeft: '-6px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'fit-content',
    },
  },
  switchStyles: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    margin: 0,
  },
  iconStyles: {
    marginTop: '7px',
  },
  qrCodeWrapper: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  qrCode: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '4px',
    padding: '10px',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.5rem',
    },
  },
  qrCodeText: {
    margin: '10px 0',
  },
  cardActionText: {
    margin: '0 24px 0 56px',
  },
}))
