import React from 'react'
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { API_ROOT, APP_VERSION } from 'env'
import { getToken } from 'lib/auth'
import { getAssumedUser } from 'lib/auth/impersonation'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const httpLink = createHttpLink({
  uri: operation => `${API_ROOT}/graphql?op=${operation.operationName}`,
})

const authLink = setContext((_, ctx) =>
  getToken().then(authToken => {
    const assumedUser = getAssumedUser()
    return {
      headers: {
        ...ctx.headers,
        ...(ctx.recaptchaToken && { 'X-PGA-RECAPTCHA': ctx.recaptchaToken }),
        ...(assumedUser && { 'X-PGA-Impersonation': assumedUser }),
        authorization: authToken ? `Bearer ${authToken}` : '',
        'X-Client-Version': APP_VERSION,
        'X-Client-Name': 'my-pga-com',
      },
    }
  }),
)

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  // TODO: Re-evaluate this...
  cache: new InMemoryCache({ resultCaching: false }),
  defaultOptions,
})

const withApolloClient = App => {
  const WithApolloClient = props => {
    return (
      <ApolloProvider client={apolloClient}>
        <App {...props} />
      </ApolloProvider>
    )
  }

  WithApolloClient.displayName = `withApolloClient(${App.displayName ||
    App.name ||
    'App'})`

  return WithApolloClient
}

export default withApolloClient
