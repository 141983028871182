import React from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import {
  useFindOrCreateChannelWithUserIds,
  useNavigateToChannel,
} from 'lib/stream-messaging/hooks'

const SendMessageButton = ({
  userIds,
  component = Button,
  onClick,
  ...props
}) => {
  const findOrCreateChannelWithUserIds = useFindOrCreateChannelWithUserIds()
  const navigateToChannel = useNavigateToChannel()
  const { enqueueSnackbar } = useSnackbar()

  const handleSendMessage = async () => {
    onClick && onClick()
    const channel = await findOrCreateChannelWithUserIds(userIds)
    if (channel != null) {
      navigateToChannel(channel)
    } else {
      enqueueSnackbar(
        'Unable to create or join conversation. Please try again later.',
        { variant: 'error' },
      )
    }
  }

  return React.createElement(component, {
    onClick: handleSendMessage,
    ...props,
  })
}

SendMessageButton.propTypes = {
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  component: PropTypes.element,
  onClick: PropTypes.func,
}

export default SendMessageButton
