import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import CoachAddStudentModal from 'components/add-student-modal/coach-add-student-modal'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  button: {
    margin: '0 12px',
    justifyContent: 'center',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    minWidth: '110px',
    boxSizing: 'border-box !important',
    [theme.breakpoints.down('sm')]: {
      borderColor: 'rgba(224, 224, 224, 1)',
    },
    [theme.breakpoints.only('sm')]: {
      maxHeight: '34px',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      minWidth: '95px', // <-- Make both values 75px on addition of 4th button.
      maxWidth: '95px',
      minHeight: '95px', // <-- Make both values 72px on addition of 4th button.
      maxHeight: '95px',
      fontSize: '12px',
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('375')]: {
      minWidth: '28%',
      width: '28%',
      minHeight: '54px',
      height: '25vw',
      fontSize: '3.25vw',
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  icon: {
    minWidth: '35px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '20px',
    },
    [theme.breakpoints.down(375)]: {
      minWidth: 'unset',
      width: '25%',
    },
  },
}))

export default function LeadActionButtons({
  contact = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    externalId: '',
  },
  leadId,
}) {
  const { firstName, lastName, phoneNumber, email, externalId } = contact
  const classes = useStyles()
  const history = useHistory()
  const allLeadsPath = '/pga-coach/students/leads'

  const handleClose = async () => {
    history.push(allLeadsPath)
  }

  const [
    isConvertLeadToStudentModalOpen,
    setIsConvertLeadToStudentModalOpen,
  ] = useState(false)

  const closeDialogAndRefetch = () => {
    handleClose()
  }

  const convertLeadToStudentButton = () => {
    setIsConvertLeadToStudentModalOpen(true)
  }

  return (
    <>
      <SensitiveInfoWrapper>
        <StreamSendMessageButton
          userIds={[externalId]}
          variant="outlined"
          color="primary"
          className={classes.button}
          startIcon={<MessageOutlinedIcon className={classes.icon} />}
        >
          Message
        </StreamSendMessageButton>
      </SensitiveInfoWrapper>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        startIcon={<PersonAddOutlinedIcon style={{ minWidth: '35px' }} />}
        onClick={convertLeadToStudentButton}
      >
        Convert
      </Button>
      <CoachAddStudentModal
        open={isConvertLeadToStudentModalOpen}
        setOpen={setIsConvertLeadToStudentModalOpen}
        onAdd={closeDialogAndRefetch}
        initialValues={{
          studentFirstName: firstName,
          studentLastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
        }}
        leadId={leadId}
      />
    </>
  )
}

LeadActionButtons.propTypes = {
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    externalId: PropTypes.string,
  }),
  leadId: PropTypes.string,
}
