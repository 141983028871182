import React from 'react'
import { Chat } from 'stream-chat-react'
import 'stream-chat-react/dist/css/index.css'
import './style-overrides.css'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useStreamClient } from './hooks'

const customStyles = {
  '--main-font':
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  '--second-font':
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  '--primary-color': '#00234B',
}

const withStreamClient = Component => props => {
  const streamClient = useStreamClient()

  if (!streamClient) return <LinearProgress color="primary" />

  return (
    <Chat client={streamClient} customStyles={customStyles}>
      <Component {...props} />
    </Chat>
  )
}

export default withStreamClient
