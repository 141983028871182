import React from 'react'
import { Link } from 'react-router-dom'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import PGAIcon from 'images/pga-logo-navy.png'
import { useAuth, redirectToLogin } from 'lib/auth'
import AccountDropdown from 'components/account-dropdown'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  logo: {
    display: 'block',
    width: '48px',

    [theme.breakpoints.down('sm')]: {
      width: '48px',
    },
  },
}))

const AppBar = () => {
  const classes = useStyles()
  const { isLoggedIn } = useAuth()
  const backLinkUrl = window.location.pathname
    .split('/')
    .slice(0, -1)
    .join('/')

  return (
    <MuiAppBar position="relative" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Button
          component={Link}
          to={backLinkUrl}
          startIcon={<NavigateBeforeOutlinedIcon />}
        >
          Back
        </Button>
        <img src={PGAIcon} alt="PGA logo" className={classes.logo} />
        {isLoggedIn ? (
          <AccountDropdown />
        ) : (
          <Button
            color="primary"
            size="small"
            onClick={() => redirectToLogin('consumer')}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar
