export const FormattedOneLineAddress = ({
  address1,
  address2,
  city,
  state,
  zip,
}) => {
  const formatted = []

  if (address1) formatted.push(address1.trim())
  if (address2) formatted.push(address2.trim())
  formatted.push(', ')

  if (city) formatted.push(`${city.trim()}, `)

  if (state) formatted.push(`${state.trim()} `)
  if (zip) formatted.push(zip.trim())

  return formatted.join('')
}
