import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import useIconAccessor from 'components/lesson-type-category/icons'

import {
  getTimePeriodWithTimezone,
  getDateTimePeriodWithTimezone,
} from 'utils/dateUtils'
import SessionDetailsDialog from 'components/session-details-dialog'
import Hidden from '@material-ui/core/Hidden'
import { Avatar } from '@material-ui/core'
import { checkSessionStatus } from 'lib/sessions/utils'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    marginBottom: '20px',
    padding: '28px 0 28px 0',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  chipStyles: ({ isPaid }) => ({
    backgroundColor: isPaid ? '#7BC67E' : '#E0E0E0',
    color: '#000000de',
    transition: 'none',
    cursor: 'pointer',
  }),
  iconWrapper: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    margin: '0 20px 0 24px',
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
  bodyText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
    color: '#0000008a',
  },
  timePeriodText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
    color: '#000000de',
  },
  nextIcon: {
    color: '#0000008a',
    paddingRight: '1rem',
  },
  paidChip: {
    backgroundColor: '#7BC67E',
    color: '#000000de',
  },
  unpaidChip: {
    backgroundColor: '#e0e0e0',
    colors: '#000000de',
  },
}))

const SessionListItem = ({
  session,
  onReschedule,
  onPayment,
  onCancellation,
  withDate = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    enrollments,
    startDateTime,
    endDateTime,
    title,
    coachFacility,
    timezone,
  } = session
  const { isPaid, isCanceled, isLessonCredit } = checkSessionStatus({
    isCanceled: session.isCanceled,
    enrollments: enrollments,
  })
  const timePeriod = withDate
    ? getDateTimePeriodWithTimezone(startDateTime, endDateTime, timezone)
    : getTimePeriodWithTimezone(startDateTime, endDateTime, timezone)

  const iconAccessor = useIconAccessor()
  const { icon } = iconAccessor[session.lessonType.category]
  const student = enrollments[0]?.student
  const fullName = student && `${student.firstName} ${student.lastName}`

  const paymentStatus = isPaid ? 'Paid' : 'Unpaid'
  const status = isLessonCredit ? 'Credit Redeemed' : paymentStatus

  const classes = useStyles({ isPaid: isPaid || isLessonCredit })

  return (
    <Card
      className={classes.root}
      variant="outlined"
      data-testid="session-list-item"
    >
      <Hidden mdUp>
        <Box display="flex" flexDirection="row-reverse" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            style={{ marginRight: '10px' }}
          >
            <Chip className={classes.chipStyles} size="small" label={status} />
            {isCanceled && (
              <Chip
                style={{ marginLeft: '12px' }}
                size="small"
                label="Canceled"
              />
            )}
          </Box>
        </Box>
      </Hidden>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box display="flex" alignItems="center">
          <Avatar variant="circular" className={classes.iconWrapper}>
            {icon}
          </Avatar>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h6" className={classes.timePeriodText}>
              {timePeriod}
            </Typography>
            <Typography variant="body1" className={classes.bodyText}>
              {coachFacility?.name}
            </Typography>
            <Typography
              variant="body1"
              className={classes.bodyText}
            >{`${title} with ${fullName}`}</Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Hidden smDown>
            <Box display="flex" flexDirection="row-reverse" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                style={{ marginRight: '10px' }}
              >
                <Chip className={classes.chipStyles} label={status} />
                {isCanceled && (
                  <Chip style={{ marginLeft: '12px' }} label="Canceled" />
                )}
              </Box>
            </Box>
          </Hidden>
          <NavigateNextIcon className={classes.nextIcon} fontSize="large" />
        </Box>
      </Box>
      <Hidden mdUp>
        <Box
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
          justifyContent="start"
          style={{ paddingLeft: '98px', paddingTop: '12px' }}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{ marginRight: '10px' }}
          >
            <Chip className={classes.chipStyles} size="small" label={status} />
            {isCanceled && (
              <Chip
                style={{ marginLeft: '12px' }}
                size="small"
                label="Canceled"
              />
            )}
          </Box>
        </Box>
      </Hidden>

      <SessionDetailsDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        booking={session}
        onReschedule={onReschedule}
        onPayment={onPayment}
        onCancellation={onCancellation}
      />
    </Card>
  )
}

export default SessionListItem
