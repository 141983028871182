import React, { useContext } from 'react'
import {
  Container,
  Grid,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { useStyles } from './styles'
import TransactionSummary from 'pages/schedule/ReviewBooking/transaction-summary'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import { useIsMobile } from 'lib/hooks'
import RouterLinkButton from 'components/router-link-button'
import { GET_PAYMENT_REQUEST_ENROLLMENT } from '../query'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Error from 'components/error'
import LinearProgress from '@material-ui/core/LinearProgress'
import PropTypes from 'prop-types'
import StudentCard from './student-card'
import BookingDateOrDuration from 'pages/schedule/components/BookingDateOrDuration'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import { PaymentMethodContext } from '../index'

const SuccessMessage = ({ enrollment }) => {
  const { lesson, student } = enrollment
  const { firstName, lastName } = student
  const studentName = `${firstName} ${lastName}`
  const { lessonType, startDateTime, coach, coachFacility } = lesson
  const {
    priceInCents,
    title: lessonTypeTitle,
    id: lessonTypeId,
    durationInMinutes,
    facilities,
  } = lessonType
  const { id: locationId } = coachFacility
  const { slug: coachSlug } = coach.coachProfile
  const classes = useStyles()
  const isMobile = useIsMobile()
  const viewCoachOfferingsUrl = `/coach/${coachSlug}/schedule`
  const bookAnotherSessionUrl = `${viewCoachOfferingsUrl}?lessonTypeId=${lessonTypeId}&locationId=${locationId}`
  const { paymentMethod } = useContext(PaymentMethodContext)
  const { display_brand: cardBrand, last4 } = paymentMethod || {}
  return (
    <Container className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item md={7} xs={12}>
          <Typography variant="h3" color="primary" gutterBottom>
            Thank you!
          </Typography>
          <Typography
            variant="body1"
            className={classes.fontBolded}
            gutterBottom
          >
            Your payment has been received. You will receive a confirmation
            email shortly.
          </Typography>
          <Divider className={classes.divider} />
          <TransactionSummary
            title={lessonTypeTitle}
            priceInCents={priceInCents}
            cardBrand={cardBrand}
            lastDigits={last4}
          />
          <Typography
            variant="h5"
            style={{ marginTop: '36px', marginBottom: '24px' }}
          >
            Details
          </Typography>
          <StudentCard studentName={studentName} />
          <List dense={true} className={classes.list}>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <GolfCourseIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.attributes }}
                primary={facilities[0].name}
              />
            </ListItem>
            {facilities[0].city && facilities[0].state && (
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <PlaceOutlinedIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.attributes }}
                  primary={`${facilities[0].city}, ${facilities[0].state}`}
                />
              </ListItem>
            )}
            <BookingDateOrDuration
              durationInMinutes={durationInMinutes}
              timezone={facilities[0].timezone}
              classes={classes}
              startTimestamp={startDateTime}
            />
          </List>
          <Divider className={classes.divider} />
          <Grid
            container
            spacing={1}
            direction={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'center' : 'space-around'}
            style={{ marginTop: '1.5rem' }}
          >
            <Grid
              item
              xs={12}
              sm={5}
              container
              justifyContent={isMobile ? 'center' : 'flex-end'}
            >
              <RouterLinkButton
                color="primary"
                variant="outlined"
                size="large"
                fullWidth
                to={viewCoachOfferingsUrl}
              >
                View Coach Offerings
              </RouterLinkButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              container
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <RouterLinkButton
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                to={bookAnotherSessionUrl}
              >
                Book Another Session
              </RouterLinkButton>
            </Grid>
          </Grid>

          <DownloadMyPGAsection />
        </Grid>
      </Grid>
    </Container>
  )
}

const PaymentSuccess = () => {
  const { paymentRequestToken } = useParams()
  const { data, loading, error } = useQuery(GET_PAYMENT_REQUEST_ENROLLMENT, {
    variables: { paymentRequestToken: paymentRequestToken },
  })
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />
  const enrollment = data?.paymentRequest

  return <SuccessMessage enrollment={enrollment} />
}

export default PaymentSuccess

PaymentSuccess.propTypes = {
  enrollment: PropTypes.shape({
    lesson: PropTypes.shape({
      lessonType: PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        priceInCents: PropTypes.number.isRequired,
      }),
      coach: PropTypes.shape({
        coachProfile: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
      coachFacility: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    student: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }),
}

SuccessMessage.propTypes = {
  enrollment: PropTypes.shape({
    lesson: PropTypes.shape({
      lessonType: PropTypes.shape({
        title: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        priceInCents: PropTypes.number.isRequired,
      }),
      coach: PropTypes.shape({
        coachProfile: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
      coachFacility: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    student: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }),
}
