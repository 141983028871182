import React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import useStyles from './group-event-registration-form.styles'

const RegistrationSubmitting = () => {
  const classes = useStyles()
  return (
    <Container
      className={classes.formStateContainer}
      maxWidth="sm"
      disableGutters
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={6}
        px={3.75}
      >
        <Box mb={4}>
          <Typography variant="h6">
            Processing registration information...
          </Typography>
        </Box>
        <Box position="relative">
          <CircularProgress size={100} thickness={1.8} />
          <LockOutlinedIcon
            color="primary"
            fontSize="large"
            style={{ position: 'absolute', top: '32.5px', left: '32.5px' }}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default RegistrationSubmitting
