import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import BookingTypeFormWithLessonPacks from 'components/booking-type-form'
import Error from 'components/error'
import useBookingTypeFormData from '../useBookingTypeFormData'
import { gql, useQuery } from '@apollo/client'
import { useCalcProgress } from 'lib/hooks'
import get from 'lodash/get'

export const COACH_ONBOARDING_STATUS = gql`
  query CoachOnboardingStatus {
    currentUser {
      attributes {
        ... on Coach {
          coachOnboardingProgress {
            coachProfileCreated
            schedulableCreated
            availabilityAdded
            facilityAdded
          }
        }
      }
    }
  }
`

const NewBookingType = ({ coachOnboardingProgress }) => {
  const { data: formData, loading, error } = useBookingTypeFormData()
  const progress = useCalcProgress(coachOnboardingProgress)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} displayErrorType="page" />
  return (
    <BookingTypeFormWithLessonPacks
      {...formData}
      progress={progress}
      showOnboardingModal={true}
      isOnboarding={true}
    />
  )
}

const NewBookingTypePage = () => {
  const { data, loading, error } = useQuery(COACH_ONBOARDING_STATUS)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} displayErrorType="page" />
  const { coachOnboardingProgress } = get(data, 'currentUser.attributes')
  return <NewBookingType coachOnboardingProgress={coachOnboardingProgress} />
}

export default NewBookingTypePage
