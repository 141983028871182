import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const GroupLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: '500',
}))

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: '16px',
  color: theme.palette.text.secondary,
}))

function FormGroup({ label, description, children }) {
  return (
    <Grid item>
      {label && <GroupLabel variant="h5">{label}</GroupLabel>}
      {description && <Description>{description}</Description>}
      {children}
    </Grid>
  )
}

export default FormGroup
