import React from 'react'
import Box from '@material-ui/core/Box'
import useStyles from './styles'

const YoutubeEmbed = ({ embedId }) => {
  const styles = useStyles()
  return (
    <>
      {embedId ? (
        <Box className={styles.videoResponsive}>
          <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Box>
      ) : null}
    </>
  )
}

export default YoutubeEmbed
