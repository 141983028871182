import React, { useState, useEffect } from 'react'

export const withSearch = Comp => props => {
  const { searchableItems } = props
  const [filteredItems, setFilteredItems] = useState(searchableItems)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const filtered = searchableItems.filter(item => {
      const { firstName, lastName } = item
      const term = searchTerm.toLowerCase().trim()

      if (term === '') setFilteredItems(searchableItems)
      if (
        firstName.toLowerCase().includes(term) ||
        lastName.toLowerCase().includes(term)
      ) {
        return item
      }
      return false
    })
    return setFilteredItems(filtered)
  }, [searchTerm, searchableItems])

  return <Comp {...{ filteredItems, searchTerm, setSearchTerm }} {...props} />
}

export default withSearch
