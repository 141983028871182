import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Typography } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import themes from 'themes'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useAuth } from 'lib/auth'

const tempTheme = createTheme(themes.pgaCoach)

const AssistantCoaches = ({ event }) => {
  const { user } = useAuth()
  const { control, errors } = useFormContext()

  const coaches = event?.academy?.coaches?.filter(coach => coach.id !== user.id)

  if (!coaches) return null

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Assistant Coaches
      </Typography>
      <Typography variant="body2" gutterBottom>
        Select the assistant coaches who will be helping you with this event.
      </Typography>
      <ThemeProvider theme={tempTheme}>
        <Box py={4}>
          <FormControl
            error={!!errors.assistantCoachIds}
            variant="outlined"
            fullWidth
          >
            <InputLabel id="category">Select Assistant Coaches</InputLabel>
            <Controller
              name="assistantCoachIds"
              control={control}
              render={({ onChange, value }, _) => (
                <Select
                  labelId="assistant-coaches"
                  multiple
                  value={value || []}
                  onChange={onChange}
                  renderValue={selected =>
                    selected
                      .map(id => coaches.find(coach => coach.id === id).name)
                      .join(', ')
                  }
                  input={<OutlinedInput label="Select Assistant Coaches" />}
                >
                  {coaches.map(coach => (
                    <MenuItem
                      key={coach.id}
                      value={coach.id}
                      data-cy="assistant-coach-menu-item"
                    >
                      <Checkbox checked={value.indexOf(coach.id) > -1} />
                      {coach.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </ThemeProvider>
    </>
  )
}

export default AssistantCoaches
