import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import AddToCalendarButton from 'components/add-to-calendar-button'
import ModifySessionDialog from 'components/modify-session-dialog'
import { makeCalendarEvent } from './utils'
import { ActionsWrapper } from './styles'

const SessionActions = ({ booking, onReschedule }) => {
  const { isCanceled } = booking

  return (
    <ActionsWrapper>
      {!isCanceled && (
        <Tooltip title="Add to calendar">
          <AddToCalendarButton
            icon={EventOutlinedIcon}
            event={makeCalendarEvent(booking)}
          />
        </Tooltip>
      )}

      <ModifySessionDialog
        trigger={
          <Tooltip title="Modify session">
            <IconButton>
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        }
        onSuccess={onReschedule}
        booking={booking}
      />
    </ActionsWrapper>
  )
}

export default SessionActions
