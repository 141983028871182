import React, { useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const Redirect = ({ to = '' }) => {
  useEffect(() => {
    window.location = to
  })
  return <LinearProgress color="primary" />
}

export default Redirect
