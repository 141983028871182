import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useGroupEventTagOptions } from 'components/group-events/form-option-hooks'
import Box from '@material-ui/core/Box'
import TagSelectionInput from 'components/tag-selection-input'
import Typography from '@material-ui/core/Typography'

const GroupEventTagSelectionInput = () => {
  const { errors, control } = useFormContext()
  const tagOptions = useGroupEventTagOptions()

  return (
    <Controller
      name="tags"
      control={control}
      defaultValue={[]}
      render={({ onChange, value }, _) => (
        <>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <TagSelectionInput
              tags={tagOptions}
              value={value}
              onChange={onChange}
              allowToSelectImmutableTags={false}
            />
          </Box>
          {errors.tags ? (
            <Typography variant="caption" color="error">
              {errors.tags.message}
            </Typography>
          ) : null}
        </>
      )}
    />
  )
}

export default GroupEventTagSelectionInput
