import React from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import themes from 'themes'
import PGALogo from 'images/pga-logo-navy.png'

const LoggedOutPage = () => {
  return (
    <ThemeProvider theme={createTheme(themes.pgaCoach)}>
      <Box bgcolor="rgb(245, 245, 245)">
        <Container maxWidth="sm">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            py={2}
            style={{ minHeight: '100vh' }}
          >
            <Paper elevation={4}>
              <Box
                p={4}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box mb={2}>
                  <img src={PGALogo} alt="PGA logo" style={{ width: '80px' }} />
                </Box>
                <Typography variant="h5" gutterBottom>
                  You have successfully signed out
                </Typography>
                <Typography variant="body1">
                  Close this window to end your session
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default LoggedOutPage
