import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import { makeStyles, useTheme } from '@material-ui/styles'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { useIsMobile } from 'lib/hooks'
import PropTypes from 'prop-types'
import BookingDateOrDuration from './BookingDateOrDuration'
import { formatCurrency } from '../utils'
import { getInitials } from 'lib/text-display'

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 0,
  },
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '1rem',
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
  },
  icons: {
    color: theme.palette.secondary.dark,
  },
  avatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '86px',
    height: '86px',
    fontSize: '2.5rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
  },
  mobileAvatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '3.3rem',
    height: '3.3rem',
    backgroundColor: '#d8d8d8',
  },
  coachCardSubtitle: {
    color: theme.palette.secondary.dark,
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  list: {
    '& li': {
      lineHeight: '.75rem',
    },
  },
  coachCardName: {
    color: theme.palette.primary.main,
    marginTop: '.75rem',
  },
}))
const MobileHeader = ({ lessonType, coach, classes }) => {
  const theme = useTheme()
  const [showSeeMore, setShowSeeMore] = useState(false)
  const {
    title,
    durationInMinutes,
    priceInCents,
    facilities,
    description,
    lessonPacks,
  } = lessonType
  const isLessonPackOffering = lessonPacks?.length > 0
  return (
    <Grid container>
      <Grid item xs>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{ height: '100%' }}
        >
          <Box textAlign="center">
            {coach.coachProfile?.profilePhoto != null ? (
              <Avatar
                className={classes.mobileAvatar}
                alt={coach.name}
                src={coach.coachProfile.profilePhoto}
              >
                {coach.name}
              </Avatar>
            ) : (
              <Avatar className={classes.avatar}>{coach.initials}</Avatar>
            )}
          </Box>
          <Box ml={2}>
            <Typography variant="h5" className={classes.coachCardName}>
              {coach.name}
            </Typography>
            {coach.coachProfile?.title != null ? (
              <Box>
                <Typography
                  variant="subtitle1"
                  className={classes.coachCardSubtitle}
                >
                  {coach.coachProfile.title}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={1}>
          <Typography variant={'h3'} className={classes.title}>
            {title}
          </Typography>
          <Grid item>
            {!showSeeMore && (
              <List dense={true}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600 }}
                  onClick={() => setShowSeeMore(true)}
                >
                  Read more
                </Typography>
              </List>
            )}
            {showSeeMore && (
              <>
                {description && (
                  <Typography variant="body1">{description}</Typography>
                )}
                <List dense={true}>
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <GolfCourseIcon className={classes.icons} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.attributes }}
                      primary={facilities[0].name}
                    />
                  </ListItem>
                  {facilities[0].city && facilities[0].state && (
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <PlaceOutlinedIcon className={classes.icons} />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.attributes }}
                        primary={`${facilities[0].city}, ${facilities[0].state}`}
                      />
                    </ListItem>
                  )}
                  <BookingDateOrDuration
                    durationInMinutes={durationInMinutes}
                    timezone={facilities[0].timezone}
                    classes={classes}
                  />
                  {!isLessonPackOffering && (
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <SavingsIcon
                          size={24}
                          color={theme.palette.secondary.dark}
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.attributes }}
                        primary={formatCurrency(priceInCents / 100)}
                      />
                    </ListItem>
                  )}
                </List>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 600 }}
                  onClick={() => setShowSeeMore(false)}
                >
                  Read less
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
const FullHeader = ({ lessonType, coach, classes }) => {
  const {
    title,
    durationInMinutes,
    priceInCents,
    facilities,
    description,
    lessonPacks,
  } = lessonType
  const isLessonPackOffering = lessonPacks?.length > 0

  const theme = useTheme()
  return (
    <>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={1}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          {description && (
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {description}
            </Typography>
          )}
          <List dense={true} className={classes.list}>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <GolfCourseIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.attributes }}
                primary={facilities[0].name}
              />
            </ListItem>
            {facilities[0].city && facilities[0].state && (
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <PlaceOutlinedIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.attributes }}
                  primary={`${facilities[0].city}, ${facilities[0].state}`}
                />
              </ListItem>
            )}
            <BookingDateOrDuration
              durationInMinutes={durationInMinutes}
              timezone={facilities[0].timezone}
              classes={classes}
            />
            {!isLessonPackOffering && (
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <SavingsIcon
                    size={24}
                    className={classes.icons}
                    color={theme.palette.secondary.dark}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.attributes }}
                  primary={formatCurrency(priceInCents / 100)}
                />
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{ height: '100%' }}
        >
          <Box textAlign="center">
            {coach.coachProfile?.profilePhoto != null ? (
              <Avatar
                className={classes.avatar}
                alt={coach.name}
                src={coach.coachProfile.profilePhoto}
              >
                {coach.name}
              </Avatar>
            ) : (
              <Avatar className={classes.avatar}>{coach.initials}</Avatar>
            )}
          </Box>
          <Box textAlign="center">
            <Typography variant="h5" className={classes.coachCardName}>
              {coach.name}
            </Typography>
            {coach.coachProfile?.title != null ? (
              <Box textAlign="center">
                <Typography
                  variant="subtitle1"
                  className={classes.coachCardSubtitle}
                >
                  {coach.coachProfile.title}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
const BookingSummaryCoachInfo = ({ lessonType, coach }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const initials = getInitials(coach.name)
  coach = { ...coach, initials }

  return (
    <Container className={classes.container}>
      <Grid
        container
        className={classes.root}
        spacing={2}
        alignItems="stretch"
        direction="row"
      >
        {isMobile ? (
          <MobileHeader
            lessonType={lessonType}
            coach={coach}
            classes={classes}
          />
        ) : (
          <FullHeader lessonType={lessonType} coach={coach} classes={classes} />
        )}
      </Grid>
    </Container>
  )
}

BookingSummaryCoachInfo.propTypes = {
  lessonType: PropTypes.object.isRequired,
  coach: PropTypes.shape({
    name: PropTypes.string.isRequired,
    coachProfile: PropTypes.shape({
      title: PropTypes.string,
      profilePhoto: PropTypes.string,
    }),
  }).isRequired,
}

export default BookingSummaryCoachInfo
