import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '45px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      marginBottom: '60px',
    },
  },
  tileButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  availabilityWarningBox: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    backgroundColor: theme.palette.primary.selected,
    padding: theme.spacing(2),
    maxWidth: '840px',
    marginBottom: theme.spacing(5),
  },
  availabilityWarningText: {
    fontSize: '16px',
    fontWeight: 500,
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },
  disabledActionTile: {
    backgroundColor: theme.palette.grey[200],
  },
}))
