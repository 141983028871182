import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import CreateAccountForBookingLayout from 'pages/schedule/SelectStudent/CreateAccountForBookingLayout.js'
import ReservationsHeaderWrapper from '../reservations-header-wrapper'
import ConfirmMemberNumberInput from './confirm-member-number-input'
import { useQuery } from '@apollo/client'
import { GET_STUDENTS } from '../queries'
import Error from 'components/error'

const SelectStudent = ({ academy }) => {
  const { isLoggedIn, user } = useAuth()
  const history = useHistory()
  const [memberNumber, setMemberNumber] = React.useState('')
  const [memberNumberError, setMemberNumberError] = React.useState('')
  const currentUrl = window.location.pathname + window.location.search

  const validateMemberNumber = () => {
    if (memberNumber.length !== 5) {
      setMemberNumberError('Please provide a valid member number.')
    } else if (memberNumber.length === 5) {
      setMemberNumberError('')
    }
  }

  const handleNext = selfStudentId => {
    validateMemberNumber()
    if (memberNumber.length === 5) {
      history.push(
        `${currentUrl}&studentId=${selfStudentId}&memberNumber=${memberNumber}`,
      )
    }
  }

  const { data, loading, error } = useQuery(GET_STUDENTS, {
    skip: !isLoggedIn,
  })

  const students = data?.currentUser?.students
  const selfStudentId = students?.find(student =>
    student?.representatives.some(
      r =>
        r?.relationshipType === 'SELF' &&
        r?.contact?.externalId === user.externalId,
    ),
  )?.id

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  return (
    <Grid container>
      <ReservationsHeaderWrapper
        academy={academy}
        currentStep={2}
        totalSteps={4}
        previousUrl={window.location.pathname}
      >
        <Grid item xs={12}>
          {isLoggedIn ? (
            <>
              <ConfirmMemberNumberInput
                memberNumber={memberNumber}
                setMemberNumber={setMemberNumber}
                memberNumberError={memberNumberError}
              />
              <Button
                disabled={!memberNumber}
                variant="contained"
                color="primary"
                onClick={() => handleNext(selfStudentId)}
              >
                Next
              </Button>
            </>
          ) : (
            <CreateAccountForBookingLayout showDescription={false} />
          )}
        </Grid>
      </ReservationsHeaderWrapper>
    </Grid>
  )
}

export default SelectStudent

SelectStudent.propTypes = {
  academy: PropTypes.object.isRequired,
}
