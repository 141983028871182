import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: '90%',
  },
  sectionTitle: {
    fontWeight: '500',
    fontSize: '20px',
    margin: '24px 32px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 16px',
    },
  },
  sectionInput: {
    marginBottom: `${theme.spacing(2)}px`,
  },
  sectionContent: {
    margin: '0px 16px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px',
    },
  },
}))

const ContentSection = ({ children, title = '', actions }) => {
  const styles = useStyles()

  return (
    <Card className={styles.section} variant="outlined">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pr={3}
      >
        <Typography className={styles.sectionTitle}>{title}</Typography>
        {actions}
      </Box>
      <Divider />
      <CardContent className={styles.sectionContent}>{children}</CardContent>
    </Card>
  )
}

export default ContentSection
