import React, { useEffect } from 'react'
import { AUTH_ROOT } from 'env'
import auth0 from '../auth0/client'
import LinearProgress from '@material-ui/core/LinearProgress'

const redirectToSignup = async (
  role,
  returnTo = window.location.href,
  signupParams = {},
) => {
  const urlParams = new URLSearchParams({
    return_to: returnTo,
    ...signupParams,
  })
  if (role === 'consumer') {
    // redirect to the consumer signup page
    await auth0.loginWithRedirect({
      authorizationParams: {
        scope: `openid profile email offline_access api:${role}`,
        screen_hint: 'signup',
      },
      appState: { returnTo },
    })
  } else if (role === 'coach') {
    // redirect to the coach signup page
    window.location = `${AUTH_ROOT}/v2/signup?${urlParams}`
  }
}

const SignupRedirect = ({ role, returnTo, signupParams }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    redirectToSignup(role, returnTo, signupParams)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <LinearProgress color="secondary" />
}

export default SignupRedirect
export { redirectToSignup }
