import React from 'react'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import PGAIcon from 'images/pga-logo-navy.png'
import { redirectToLogin, useAuth } from 'lib/auth'
import AccountDropdown from 'components/account-dropdown'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    borderBottom: '1px solid #E0E0E0',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  },
  toolbar: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  logo: {
    display: 'block',
    width: '48px',

    [theme.breakpoints.down('sm')]: {
      width: '48px',
    },
  },
  action: {
    textTransform: 'none',
    borderRadius: '50px',
    width: '90px',
  },
}))

const AppBar = () => {
  const classes = useStyles()
  const { isLoggedIn } = useAuth()

  return (
    <MuiAppBar position="relative" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img src={PGAIcon} alt="PGA logo" className={classes.logo} />
        {isLoggedIn ? (
          <AccountDropdown />
        ) : (
          <Button
            color="primary"
            size="small"
            onClick={() => redirectToLogin('consumer')}
            variant="outlined"
            className={classes.action}
          >
            Log In
          </Button>
        )}
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar
