import PhysicalInformation from './physical-information'
import GolfInformation from './golf-information'
import GolfFocusInformation from './golf-focus-information'
import {
  useStyles,
  SectionContainer,
  ComponentGrid,
  FormHeader,
  StyledTypography,
} from './intake-form.styles'
import Box from '@material-ui/core/Box'
import StepActions from './step-actions'
import { Steps, StepLabels } from './steps'
import RouterLinkButton from 'components/router-link-button'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useIsMobile } from 'lib/hooks'
import StepperComponent from './stepper'
import Success from './success'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

const Layout = ({ activeStep, setActiveStep, completedRegistration }) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useIsMobile()
  const uniqueSteps = [...new Set(Object.values(StepLabels))]
  const currentStep = Steps[activeStep]
  const labelStep = uniqueSteps.indexOf(currentStep.name)
  const backLinkUrl = window.location.pathname
    .split('/')
    .slice(0, -3)
    .join('/')

  const cancelPrevious = e => {
    e.preventDefault()
    if (activeStep === 1) {
      setActiveStep(0)
    } else {
      history.push(backLinkUrl)
    }
  }

  const currentLocationHeader = () => {
    if (currentStep.name === 'Physical Info') {
      return 'Physical Information'
    } else if (currentStep.name === 'Golfer info') {
      return 'Golfer information'
    }
  }

  return (
    <>
      <SectionContainer>
        <Container maxWidth="md">
          <Grid container justifyContent="center">
            <StepperComponent labelStep={labelStep} uniqueSteps={uniqueSteps} />
          </Grid>
        </Container>
        {!isMobile && (
          <FormHeader>
            <StyledTypography variant="h4">
              {currentLocationHeader()}
            </StyledTypography>
          </FormHeader>
        )}
        <Box display={currentStep.name === 'Physical Info' ? 'block' : 'none'}>
          <ComponentGrid>
            <PhysicalInformation />
          </ComponentGrid>
        </Box>
        <Box display={currentStep.name === 'Golfer info' ? 'block' : 'none'}>
          <ComponentGrid>
            <GolfInformation />
          </ComponentGrid>
          <ComponentGrid>
            <GolfFocusInformation />
          </ComponentGrid>
        </Box>
        <Box display={currentStep.name === 'Confirmation' ? 'block' : 'none'}>
          <Success />
        </Box>
      </SectionContainer>
      <Box position="relative" className={classes.container}>
        <Box className={classes.controlsWrapper}>
          <Box className={classes.controls}>
            <Box>
              <RouterLinkButton to="/club">CANCEL</RouterLinkButton>
            </Box>
            <Box className={classes.innerControls}>
              <Button
                variant="outlined"
                startIcon={<KeyboardBackspaceIcon />}
                onClick={e => cancelPrevious(e)}
                style={{ marginRight: '.75rem' }}
                id="previous-button"
              >
                PREVIOUS
              </Button>
              <StepActions
                stepName={currentStep.name}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isComplete={completedRegistration}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default Layout
