import React from 'react'
import Dialog from 'components/dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ShareBox from 'components/share-box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
}))

const ShareEventDialog = ({
  isOpen,
  setIsOpen,
  url,
  title,
  titleText,
  eventId,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
      showCloseButton
    >
      {() => (
        <>
          <DialogTitle
            disableTypography
            classes={{
              root: classes.title,
            }}
          >
            {titleText}
          </DialogTitle>
          <DialogContent>
            <ShareBox
              url={url}
              title={title}
              att="pga-group-event"
              shareId={eventId}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default ShareEventDialog
