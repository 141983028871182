import React from 'react'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import {
  toLuxonFromIso,
  getTimePeriodWithTimezone,
  toFullTextDateWithTimezone,
} from 'utils/dateUtils'

import Dialog from 'components/dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import GolfCourseOutlinedIcon from '@material-ui/icons/GolfCourseOutlined'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useIsMobile, useIsTablet } from 'lib/hooks'

import CancelBookingDialog from 'components/cancel-booking-dialog'
import RowItem from './row-item'
import AddToCalendarButton from 'components/add-to-calendar-button'
import { useStyles } from './styles'
import InitialsAvatar from 'components/avatars/initials-avatar'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import InformationActionBanner from 'components/banners/banner-info'
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined'
import PropTypes from 'prop-types'

const makeCalendarEvent = ({
  id,
  startDateTime,
  endDateTime,
  title,
  lessonCaption,
  coachFacility = {},
}) => ({
  uid: `coach-tools.lesson.${id}.pga.com`,
  startDateTime: toLuxonFromIso(startDateTime),
  endDateTime: toLuxonFromIso(endDateTime),
  title,
  description: lessonCaption,
  location: coachFacility.name,
  url: `${window.location.origin}/club`,
})

const SessionDetailModal = ({ openLesson, setOpenLesson, refetchLessons }) => {
  const {
    title,
    startDateTime,
    cancellationCode,
    endDateTime,
    timezone,
    enrollments = [],
    coachFacility = {},
    canStudentCancel,
    coach,
  } = openLesson
  const { city, state, zipCode } = coachFacility

  const classes = useStyles()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isUpcoming = DateTime.now() < toLuxonFromIso(endDateTime)

  const [isStudentCancellationEnabledFF] = useFeatureFlags([
    flags.FLAG_FEAT_STUDENT_CANCELLATION,
  ])

  const studentActionSelector = () => {
    if (isStudentCancellationEnabledFF) {
      return canStudentCancel ? (
        <CancelBookingDialog
          trigger={
            <Button color="primary" style={{ marginTop: '10px' }}>
              Cancel Session
            </Button>
          }
          confirmationCode={cancellationCode}
          onSuccess={() => {
            refetchLessons()
            setOpenLesson({})
          }}
        />
      ) : (
        <InformationActionBanner
          isMobile={isMobile}
          isTablet={isTablet}
          classes={classes}
          coach={coach}
          icon={<EmojiObjectsOutlinedIcon className={classes.iconColor} />}
          infoTextContent="Need to reschedule? To cancel this session, you will need to message your coach"
        />
      )
    }
    return (
      <CancelBookingDialog
        trigger={
          <Button color="primary" style={{ marginTop: '10px' }}>
            Cancel Session
          </Button>
        }
        confirmationCode={cancellationCode}
        onSuccess={() => {
          refetchLessons()
          setOpenLesson({})
        }}
      />
    )
  }

  const rowItemsList = [
    {
      icon: EventNoteOutlinedIcon,
      text: toFullTextDateWithTimezone(startDateTime, timezone),
    },
    {
      icon: ScheduleOutlinedIcon,
      text: getTimePeriodWithTimezone(startDateTime, endDateTime, timezone),
    },
    {
      icon: PeopleOutlinedIcon,
      text: `${enrollments.length} Attendee${
        enrollments.length > 1 ? 's' : ''
      }`,
    },
  ]

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      isOpen={!isEmpty(openLesson)}
      onClose={() => setOpenLesson({})}
      fullScreen={isMobile}
      showCloseButton
    >
      {({ closeDialog }) => (
        <>
          <DialogTitle onClose={() => setOpenLesson({})}></DialogTitle>
          <DialogContent>
            <RowItem Icon={GolfCourseOutlinedIcon} text={title} largeText />
            {coachFacility ? (
              <div className={classes.rowWrapped}>
                <RoomOutlinedIcon className={classes.iconStyled} />
                <div className={classes.facilityWrapped}>
                  <Typography variant="body">{coachFacility.name}</Typography>
                  {city && state && zipCode ? (
                    <Typography variant="caption">{`${coachFacility.city}, ${coachFacility.state} ${coachFacility.zipCode}`}</Typography>
                  ) : null}
                </div>
              </div>
            ) : null}
            {rowItemsList.map(rowItem => (
              <RowItem
                Icon={rowItem.icon}
                text={rowItem.text}
                key={rowItem.text}
              />
            ))}
            <div className={classes.rowWrapped}>
              {enrollments.map(({ student, isCanceled }) =>
                !isCanceled ? (
                  <div className={classes.enrollmentsWrapped}>
                    <InitialsAvatar
                      name={`${student.firstName} ${student.lastName}`}
                      classes={classes}
                    />
                    <Typography variant="body">
                      {student.firstName} {student.lastName}
                    </Typography>
                  </div>
                ) : null,
              )}
            </div>
          </DialogContent>
          {isUpcoming ? (
            <DialogActions className={classes.actionStyled}>
              <AddToCalendarButton
                event={makeCalendarEvent(openLesson)}
                color="primary"
                variant="contained"
              >
                Add To Calendar
              </AddToCalendarButton>
              {studentActionSelector()}
            </DialogActions>
          ) : null}
        </>
      )}
    </Dialog>
  )
}

export default SessionDetailModal

SessionDetailModal.propTypes = {
  openLesson: PropTypes.object,
  setOpenLesson: PropTypes.func,
  refetchLessons: PropTypes.func,
}
