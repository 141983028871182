import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import Typography from '@material-ui/core/Typography'
import { centsToDollarsFormatted } from 'lib/utils/number'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import RouterLinkButton from 'components/router-link-button'
import useStyles from './group-event-card.styles'

const EventDetailsDialog = ({ isOpen, setIsOpen, event }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const classes = useStyles()
  const {
    id,
    title,
    description,
    coachFacility,
    timeDuration,
    priceInCents,
    priceDetails,
  } = event

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      fullScreen={isMobile}
      maxWidth="sm"
      showCloseButton
    >
      {() => (
        <Box mx={2} mt={2} mb={3}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{description}</Typography>
            <Typography variant="body1" className={classes.subtitle}>
              Where
            </Typography>
            <Typography variant="body1">{coachFacility?.name}</Typography>
            {timeDuration && (
              <>
                <Typography variant="body1" className={classes.subtitle}>
                  Time Duration
                </Typography>
                <Typography variant="body1">{timeDuration}</Typography>
              </>
            )}
            <Typography variant="body1" className={classes.subtitle}>
              Price
            </Typography>
            <Typography variant="body1">
              {centsToDollarsFormatted(priceInCents)} per student
              {priceDetails || ''}
            </Typography>
          </DialogContent>
          <DialogActions>
            <RouterLinkButton
              size="small"
              to={`/pga-coaching-center-at-pga-frisco/specialty-programs/interest?programId=${id}`}
              variant="contained"
              color="primary"
            >
              Submit Interest
            </RouterLinkButton>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  )
}

export default EventDetailsDialog
