import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const FormSectionContainer = ({ title, children }) => {
  return (
    <Card variant="outlined" style={{ width: '100%', marginBottom: '40px' }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h6' }}
        style={{ borderBottom: '1px solid #E0E0E0' }}
      />
      <CardContent style={{ padding: '24px' }}>{children}</CardContent>
    </Card>
  )
}

export default FormSectionContainer
