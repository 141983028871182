import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useTheme } from '@material-ui/core/styles'
import {
  Button,
  Box,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined'
import { DateTime } from 'luxon'
import GoogleCalendarIntegration from './google-calendar-integration'
import BlockedTimesEmpty from './blocked-times-empty'
import BlockTimeFormDialog from './block-time-form-dialog'
import BlockTimeItem from './block-type-item'
import { gtmEvent } from 'lib/gtm'

const GET_BLOCK_TIME = gql`
  query coachAvailabilityBlockTimes($datetimeRange: DateTimeRangeInput) {
    coachAvailabilityBlockTimes(datetimeRange: $datetimeRange) {
      id
      startTime
      endTime
      allDay
      blockNote
    }
  }
`

const breakIntoDateTime = blockTimes => {
  return blockTimes.map(time => {
    return {
      id: time.id,
      startDate: DateTime.fromISO(time.startTime).toFormat('MMMM d'),
      startTime: time.allDay
        ? 'All day'
        : DateTime.fromISO(time.startTime).toFormat('t'),
      endDate: DateTime.fromISO(time.endTime).toFormat('MMMM d'),
      endTime: time.allDay
        ? 'All day'
        : DateTime.fromISO(time.endTime).toFormat('t'),
      blockNote: time.blockNote,
    }
  })
}

const oneMonthLater = isoTime => {
  const dt = DateTime.fromISO(isoTime)
  return dt.endOf('Month')
}

const formatFromIsoDate = isoTime => {
  const dt = DateTime.fromISO(isoTime)
  return dt.toFormat('MMMM yyyy')
}

const makeInitialDateArray = () => {
  const initialDateTime = DateTime.local().startOf('Month')
  const monthYears = [initialDateTime.toISO()]
  let dateTime = initialDateTime
  while (monthYears.length < 12) {
    dateTime = dateTime.plus({ months: 1 }).startOf('Month')
    monthYears.push(dateTime.toISO())
  }
  return monthYears
}

const BlockTimeManager = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedStart, setSelectedStart] = useState(
    DateTime.local().startOf('Month'),
  )

  const { loading, error, data, refetch } = useQuery(GET_BLOCK_TIME, {
    variables: {
      blockNote: '',
      datetimeRange: {
        from: selectedStart,
        to: oneMonthLater(selectedStart),
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const monthYears = makeInitialDateArray()

  const onDestroySuccess = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'bookings',
      formAction: 'delete-block-time',
      formStatus: 'success',
    })
    refetch()
  }

  const onCreateSuccess = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'bookings',
      formAction: 'create-block-time',
      formStatus: 'success',
    })
    refetch()
  }

  const blockTimes = breakIntoDateTime(data?.coachAvailabilityBlockTimes || [])

  return (
    <>
      <Box mb={2}>
        <GoogleCalendarIntegration onIntegrationChange={refetch} />
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined">
              <TextField
                select
                value={selectedStart}
                variant="outlined"
                label="Select a month"
                onChange={e => setSelectedStart(e.target.value)}
              >
                {monthYears.map(dT => (
                  <MenuItem key={dT} value={dT}>
                    {formatFromIsoDate(dT)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              display="flex"
              height="100%"
              justifyContent="flex-end"
              alignItems="center"
              mt={isMobile ? 2 : 0}
            >
              <BlockTimeFormDialog
                trigger={
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={isMobile}
                  >
                    Create Block
                  </Button>
                }
                initialSelectedDay="TUESDAY"
                initialTimeSlots={[]}
                onSuccess={onCreateSuccess}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading && <LinearProgress color="primary" />}
      {!loading ? (
        blockTimes.length > 0 ? (
          blockTimes.map(time => (
            <BlockTimeItem
              key={
                time.id === '0' ? `${time.startTime}${time.endTime}` : time.id
              }
              time={time}
              onDestroySuccess={onDestroySuccess}
            />
          ))
        ) : (
          <BlockedTimesEmpty
            IconComp={() => <EventBusyOutlinedIcon fontSize="large" />}
            title="No blocked times"
            caption="You currently have not blocked any times for this month."
          />
        )
      ) : null}
      {error && (
        <BlockedTimesEmpty
          IconComp={() => <EventBusyOutlinedIcon fontSize="large" />}
          title="Error getting blocked times"
          caption="An unexpected error occurred. Please try again."
        />
      )}
    </>
  )
}

export default BlockTimeManager
