import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { get } from 'lodash'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET } from 'env'
import { IconInfo } from 'components/atoms/icons'
import usStates from 'utils/usStates'
import secureLink from 'utils/secureLink'
import { gendersList, socialsList, formFields } from '../constants'
import useStyles, {
  CardRoot,
  ErrorMsg,
  FlexInputWrapper,
} from '../profile.styles'
import Price from './Price'
import { useDebounce } from 'lib/hooks'
import { handleImageUpload } from 'lib/cloudinary/uploadImage'
import TextFieldCounter from 'components/text-field-counter'
import { getInitials } from 'lib/text-display'
import useIsMobile from 'lib/hooks/use-is-mobile'

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET,
  cropping: true,
  sources: ['local', 'camera'],
  multiple: false,
  croppingAspectRatio: 1,
  resourceType: 'image',
  showSkipCropButton: false,
  folder: 'dot-com-images',
  showPoweredBy: false,
  clientAllowedFormats: ['png', 'jpeg', 'jpg', 'gif', 'webp'],
}

const profileImgMsg = 'Recommended dimensions of 400x400'

const idSectionConfig = [
  {
    name: 'firstName',
    label: 'First Name',
  },
  {
    name: 'lastName',
    label: 'Last Name',
  },
  {
    name: 'displayName',
    label: 'Display Name',
  },
  {
    name: 'gender',
    label: 'Gender',
    menuList: gendersList,
    menuId: 'value',
    menuLabel: 'label',
  },
  {
    name: 'title',
    label: 'Title',
  },
]

const contactSectionConfig = [
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'phoneNumber',
    label: 'Phone',
  },
  {
    name: 'phoneExt',
    label: 'Ext.',
  },
  {
    name: 'mobileNumber',
    label: 'Mobile',
  },
]

const Profile = props => {
  const [uploadError, setUploadError] = useState(null)
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props
  const debouncedValues = useDebounce(values, 750)
  const initials = getInitials(values.firstName, values.lastName)
  const classes = useStyles()
  const isMobile = useIsMobile()
  return (
    <div>
      <CardRoot>
        {/* About You Section */}
        <Typography variant="subtitle1" gutterBottom>About You</Typography>
        <Grid container spacing={2}>
          {idSectionConfig.map(
            ({ name, label, icon, menuList, menuId, menuLabel }) => (
              <Grid item xs={12} md={6}>
              <TextField
                key={name}
                select={!!menuList}
                name={name}
                label={label}
                type="text"
                value={values[name]}
                variant="standard"
                onChange={e => {
                  debouncedValues[name] = e.target.value
                  handleChange(e)
                }}
                onBlur={handleBlur}
                className={`text-input ${errors[name] &&
                  touched[name] &&
                  ' error'}`}
                error={errors[name] && touched[name]}
                helperText={touched[name] && errors[name]}
                InputProps={
                  icon && {
                    endAdornment: (
                      <InputAdornment position="end">{icon}</InputAdornment>
                    ),
                  }
                }
              >
                {menuList &&
                  menuList.map(option => {
                    return (
                      <MenuItem key={option[menuId]} value={option[menuId]}>
                        {option[menuLabel]}
                      </MenuItem>
                    )
                  })}
              </TextField>
              </Grid>
            ),
          )}
        </Grid>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" mb={6}>
          <Avatar
            className={classes.coachAvatar}
            alt={values.name}
            src={secureLink(values.profilePhoto)}
          >
            {initials}
          </Avatar>
          <Box display="flex" flexDirection="column" >
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() =>
                  handleImageUpload(
                    uploadOptions,
                    setFieldValue,
                    setUploadError,
                    'profilePhoto',
                  )
                }
              >
                Upload{!values.profilePhoto ? ' New' : ''} Picture
              </Button>
              {values.profilePhoto && (
                <Button
                  variant="text"
                  type="button"
                  onClick={() => {
                    setFieldValue('profilePhoto', null)
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Box mt={2}>
              {uploadError ? (
              <ErrorMsg style={{ marginBottom: 0 }}>{uploadError}</ErrorMsg>
              ) : (
              <Typography variant="body2" color="textSecondary">{profileImgMsg}</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardRoot>
      <Box mt={2}>
        {/* Contact Section */}
        <Typography variant="subtitle1" gutterBottom>Contact</Typography>
        <FlexInputWrapper>
          {contactSectionConfig.map(({ name, label }) => (
            <TextField
              key={name}
              name={name}
              label={label}
              type="text"
              value={values[name]}
              variant="standard"
              onChange={handleChange}
              onBlur={handleBlur}
              className={`text-input ${errors[name] &&
                touched[name] &&
                ' error'}`}
              error={errors[name] && touched[name]}
              helperText={touched[name] && errors[name]}
            />
          ))}
        </FlexInputWrapper>
      </Box>
      <CardRoot>
        {/* Location Section */}
        <Typography variant="subtitle1" >Service Area</Typography>
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            This describes where you offer services on PGA.com and helps people find you on Google.
          </Typography>
        </Box>
        <FlexInputWrapper className="only3">
          <TextField
            name="city"
            label="City"
            type="text"
            value={values.city}
            variant="standard"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`text-input ${errors.city && touched.city && ' error'}`}
            error={errors.city && touched.city}
            helperText={touched.city && errors.city}
          />
          <Autocomplete
            name="state"
            id="state"
            disableClearable
            value={values.state}
            options={usStates}
            onChange={(event, value) => {
              setFieldValue('state', value)
            }}
            onOpen={handleBlur}
            className={`text-input ${errors.state &&
              touched.state &&
              ' error'}`}
            renderInput={params => (
              <TextField
                {...params}
                label="State"
                variant="standard"
                helperText={touched.state && errors.state}
                error={errors.state && touched.state}
              />
            )}
          />
          <TextField
            name="zipCode"
            label="Zip"
            type="text"
            value={values.zipCode}
            variant="standard"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`text-input last-input-mobile ${errors.zipCode &&
              touched.zipCode &&
              ' error'}`}
            error={errors.zipCode && touched.zipCode}
            helperText={touched.zipCode && errors.zipCode}
          />
          <div />
        </FlexInputWrapper>
      </CardRoot>
      <Price
        {...{
          setFieldValue,
          values,
          touched,
          errors,
        }}
      />
      <Box mt={3}>
        {/* Personal Section */}
        <Typography variant="subtitle1">Personal</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Use your bio to introduce yourself, your golfing journey, and why students should choose you as their coach. Show your unique personality.
        </Typography>
        <Box py={2}>
          <TextFieldCounter
            characterLimit={5000}
            multiline
            rows={6}
            name="bio"
            label="Bio"
            type="text"
            variant="outlined"
            value={values.bio}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`multiline ${errors.bio && touched.bio && ' error'}`}
            error={errors.bio && touched.bio}
            helperText={touched.bio && errors.bio}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box py={2}>
              <TextField
                type="number"
                inputProps={{
                  step: '1',
                  pattern: '/d+',
                }}
                name="numberOfStudents"
                label="Number of Students"
                value={values.numberOfStudents}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`text-input ${errors.numberOfStudents &&
                  touched.numberOfStudents &&
                  ' error'}`}
                error={errors.numberOfStudents && touched.numberOfStudents}
                helperText={touched.numberOfStudents && errors.numberOfStudents}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box py={2}>
              <TextField
                type="number"
                inputProps={{
                  step: '1',
                  pattern: '/d+',
                }}
                name="numberOfFollowers"
                label="Number of social media followers"
                value={values.numberOfFollowers}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`text-input ${errors.numberOfFollowers &&
                  touched.numberOfFollowers &&
                  ' error'}`}
                error={errors.numberOfFollowers && touched.numberOfFollowers}
                helperText={touched.numberOfFollowers && errors.numberOfFollowers}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Highest social media following on one account"
                        aria-label="Highest social media following on one account"
                      >
                        <IconButton style={{ padding: 0 }}>
                          <IconInfo />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" gutterBottom>Social</Typography>
        <Box mt={2}>
        {socialsList.map(social => {
          const idx = values.socialJsonData.findIndex(
            sj => sj.type === social.value,
          )
          const correspondingValue =
            idx >= 0 ? values.socialJsonData[idx] : null
          const shortenURL =
            correspondingValue &&
            correspondingValue.reference &&
            correspondingValue.reference.replace(social.fullURL, '')
          return (
            <TextField
              key={social.value}
              name={social.value}
              label={social.label}
              type="text"
              value={shortenURL}
              variant="standard"
              onChange={e => {
                // if there isn't a corresponding value for this social link, append it to the socialJsonData list
                const idxToUpdate =
                  idx !== -1 ? idx : values.socialJsonData.length
                setFieldValue(`socialJsonData[${idxToUpdate}]`, {
                  reference:
                    e.target.value !== ''
                      ? `${social.fullURL}${e.target.value}`
                      : null,
                  type: social.value,
                  preferred: true,
                })
              }}
              onBlur={handleBlur}
              className={`text-input ${get(errors, [
                'socialJsonData',
                idx,
                'reference',
              ]) && ' error'}`}
              error={
                idx !== -1 && get(errors, ['socialJsonData', idx, 'reference'])
              }
              helperText={
                idx !== -1 && get(errors, ['socialJsonData', idx, 'reference'])
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className="input-adornment"
                    style={{ marginRight: 0 }}
                  >
                    {social.placeholder}
                  </InputAdornment>
                ),
              }}
            />
          )
        })}
        </Box>
      </Box>
    </div>
  )
}

Profile.propTypes = {
  values: formFields,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
}

Profile.defaultProps = {}

export default Profile
