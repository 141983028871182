import { gql, useQuery } from '@apollo/client'

export const GET_MEMBERSHIP = gql`
  query getMembership {
    me {
      name
      email
      familyCupSignedUp
      jrlSignedUp
      programAdmin
      contactPreference
      dateOfBirth
      phone
      gender
      state
      zip
      city
      pgaCoachEligible
    }
    pgaMembership {
      birthdate
      displayName
      firstName
      lastName
      publicEmail
      publicMobile
      type
      gender
      state
      zip
      city
    }
  }
`

export default function useEligibilityCheck() {
  const { data, error, loading } = useQuery(GET_MEMBERSHIP)

  const defaults = {
    displayName: data?.me?.name ?? data?.pgaMembership?.displayName,
    phoneNumber: data?.me?.phone ?? data?.pgaMembership?.publicMobile,
    email: data?.me?.email ?? data?.pgaMembership?.publicEmail,
    firstName: data?.pgaMembership?.firstName,
    lastName: data?.pgaMembership?.lastName,
    dateOfBirth: data?.me?.dateOfBirth ?? data?.pgaMembership?.birthdate,
    gender: data?.me?.gender ?? data?.pgaMembership?.gender,
    state: data?.me?.state ?? data?.pgaMembership?.state,
    zip: data?.me?.zip ?? data?.pgaMembership?.zip,
    city: data?.me?.city ?? data?.pgaMembership?.city,
    contactMethod: data?.me?.contactPreference,
  }

  return {
    loading,
    error,
    defaults,
    eligible: data?.me?.pgaCoachEligible ?? false,
    signedUp: data?.me?.familyCupSignedUp ?? false,
    jrlSignedUp: data?.me?.jrlSignedUp ?? false,
    admin: data?.me?.programAdmin ?? false,
    eligibleMemberType: ['MB', 'ST', 'AP'].includes(data?.pgaMembership?.type),
  }
}
