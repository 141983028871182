import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Link } from 'react-router-dom'
import ContactButton from './contact-button'
import PGALogo from 'images/pga-logo-navy.png'
import pgaCoachLogo from 'images/pga-coach-logo.png'
import AccountDropdown from 'components/account-dropdown'
import Button from '@material-ui/core/Button'
import { useAuth, redirectToLogin } from 'lib/auth'
import themes, { extendTheme } from 'themes'

const useStyles = makeStyles(theme => ({
  filler: {
    flexGrow: 1,
  },
  logo: {
    display: 'block',
    width: '48px',
  },
  pgaCoachLogo: {
    display: 'block',
    width: 'auto',
    height: '36px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    borderBottom: '1px solid #E0E0E0',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '1280px',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      boxSizing: 'border-box',
    },
  },
}))

const CoachAppBar = ({ position = 'fixed', hideContactButton = false }) => {
  const classes = useStyles()
  const { isConsumer, isCoach, isLoggedIn } = useAuth()

  const Logo = () => {
    if (isConsumer) {
      return (
        <Link to={'/club'}>
          <img src={PGALogo} alt="PGA logo" className={classes.logo} />
        </Link>
      )
    } else if (isCoach) {
      return (
        <Link to={'/pga-coach'}>
          <img
            src={pgaCoachLogo}
            alt="PGA Coach logo"
            className={classes.pgaCoachLogo}
          />
        </Link>
      )
    } else {
      return <img src={PGALogo} alt="PGA logo" className={classes.logo} />
    }
  }

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <AppBar position={position} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Logo />
          <div className={classes.filler}></div>
          {!hideContactButton && <ContactButton />}

          {isLoggedIn ? (
            <AccountDropdown />
          ) : (
            <Button
              color="primary"
              size="small"
              onClick={() => redirectToLogin('consumer')}
              variant="outlined"
              className={classes.action}
            >
              Log In
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default CoachAppBar
