import { ContentSection } from './content-box'
import { formatNameForDisplay } from 'lib/text-display'
import { ListItem, ListItemAvatar, ListItemText, Grid } from '@material-ui/core'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import HeightIcon from '@material-ui/icons/Height'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined'
import { useAuth } from 'lib/auth'
import { useStyles } from '../styles'
import { genderOptions, pronounOptions } from './constants'

export default function AdditionalInformation({ demographics }) {
  const {
    studentGender,
    studentGenderSelfDescription,
    studentHeightInches,
    studentPhysicalLimitations,
    studentProunounPreference,
  } = demographics
  const { user } = useAuth()
  const coachName = `${formatNameForDisplay(
    `${user.firstName} ${user.lastName}`,
  )}`

  return (
    <ContentSection title="Additional Information">
      <Grid container>
        <StudentHeight heightInches={studentHeightInches} />
        <CoachInfo coachName={coachName} />
        <StudentGender
          studentGender={studentGender}
          studentGenderSelfDescription={studentGenderSelfDescription}
        />
        <StudentPronouns
          studentProunounPreference={studentProunounPreference}
        />
        <StudentPhysicalLimitations
          studentPhysicalLimitations={studentPhysicalLimitations}
        />
      </Grid>
    </ContentSection>
  )
}
function CoachInfo({ coachName }) {
  const classes = useStyles()
  return (
    <AdditionalInfoItem
      icon={<PeopleOutlineIcon className={classes.icon} />}
      primaryText={'Coach'}
      secondaryText={coachName}
    />
  )
}
function StudentHeight({ heightInches }) {
  const classes = useStyles()
  const heightInFeet = Math.floor(heightInches / 12)
  const heightInInches = heightInches % 12
  const heightText = `${heightInFeet}' ${heightInInches}"`
  if (!heightInches) return null
  return (
    <AdditionalInfoItem
      icon={<HeightIcon className={classes.icon} />}
      primaryText="Student Height"
      secondaryText={heightText}
    />
  )
}
function StudentGender({ studentGender, studentGenderSelfDescription }) {
  const classes = useStyles()
  const genderValue = genderOptions.find(opt => opt.value === studentGender)
    ?.label

  const genderText = studentGenderSelfDescription
    ? `${genderValue}: (${studentGenderSelfDescription})`
    : genderValue
  return (
    <AdditionalInfoItem
      icon={<PersonOutlineIcon className={classes.icon} />}
      primaryText="Gender"
      secondaryText={genderText}
    />
  )
}
function StudentPhysicalLimitations({ studentPhysicalLimitations }) {
  const classes = useStyles()
  return (
    <AdditionalInfoItem
      icon={<LocalHospitalOutlinedIcon className={classes.icon} />}
      primaryText="Physical Limitations"
      secondaryText={studentPhysicalLimitations}
    />
  )
}
function AdditionalInfoItem({ icon, primaryText, secondaryText }) {
  return secondaryText ? (
    <Grid item xs={12} sm={6}>
      <ListItem>
        <ListItemAvatar>{icon}</ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText || ''} />
      </ListItem>
    </Grid>
  ) : null
}

function StudentPronouns({ studentProunounPreference }) {
  const classes = useStyles()
  const pronounValue = pronounOptions.find(
    opt => opt.value === studentProunounPreference,
  )?.label
  return (
    <AdditionalInfoItem
      icon={<PersonOutlineIcon className={classes.icon} />}
      primaryText="Pronouns"
      secondaryText={pronounValue}
    />
  )
}
