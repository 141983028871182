import React, { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { SnackbarProvider } from 'notistack'
import Shell from 'components/shell/shell'
import { ReactComponent as HomeIcon } from 'images/icons/home.svg'
import { ReactComponent as GolfIcon } from 'images/icons/sports-golf.svg'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import FGlogo from 'images/family-golf-logo.svg'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import { withFamilyCupAccess } from 'lib/family-cup-utils/eligibility'
import themes, { extendTheme } from 'themes'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BRANDS } from 'components/use-brand'
import { useFeatureFlags, flags } from 'lib/feature-flags'

const FCLocations = lazy(() => import('pages/family-cup/locations'))
const FCLocationsNew = lazy(() => import('pages/family-cup/locations-new'))
const FCLocationEdit = lazy(() => import('pages/family-cup/locations-edit'))
const FCLocationDetails = lazy(() =>
  import('pages/family-cup/location-details'),
)
const FCProgramNew = lazy(() =>
  import('pages/family-cup/program-new/new-program'),
)
const FCProgramNew1 = lazy(() =>
  import('pages/family-cup/program-new/new-program-new'),
)
const FCProgram = lazy(() => import('pages/family-cup/program'))
const FCDashboard = lazy(() => import('pages/family-cup/dashboard'))

// Components used for testing
const FCReset = lazy(() => import('components/reset'))
const FCStripeSignupNew = lazy(() =>
  import('components/payment-settings/stripe-signup'),
)

const FamilyCupRoutes = () => {
  const { path } = useRouteMatch()
  const { user } = useAuth()

  const [isFGEvent] = useFeatureFlags([flags.FLAG_FG_EVENT])
  const menuItems = []

  if (user.familyCupSignedUp) {
    menuItems.push(
      {
        icon: <HomeIcon />,
        label: 'Dashboard',
        path: `${path}`,
        exact: true,
      },
      {
        icon: <GolfIcon />,
        label: 'Locations',
        path: `${path}/locations`,
      },
    )
  }

  if (user.programAdmin) {
    menuItems.push({
      icon: <BuildOutlinedIcon />,
      label: 'Admin',
      path: '/admin',
    })
  }

  return (
    <ThemeProvider theme={extendTheme(themes.familyCup)}>
      <Helmet>
        <title>PGA Family Golf Management</title>
        <meta name="description" content="PGA Family Golf Management" />
        <link rel="icon" href="/family-golf-favicon.ico" />
      </Helmet>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Shell
          logo={{ src: FGlogo, alt: 'Family Golf Logo' }}
          menuItems={menuItems}
        >
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route exact path={path} component={FCDashboard} />
              <Route exact path={`${path}/locations`} component={FCLocations} />
              <Route
                exact
                path={`${path}/locations/new`}
                component={FCLocationsNew}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/edit`}
                component={FCLocationEdit}
              />

              <Route
                exact
                path={`${path}/locations/:locationId`}
                component={FCLocationDetails}
              />

              <Route
                exact
                path={`${path}/locations/:locationId/programs/new`}
                component={isFGEvent ? FCProgramNew1 : FCProgramNew}
              />
              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId`}
              >
                <FCProgram tab="edit" />
              </Route>

              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/teams`}
              >
                <FCProgram tab="teams" />
              </Route>

              <Route
                exact
                path={`${path}/locations/:locationId/programs/:programId/scoring`}
              >
                <FCProgram tab="scoring" />
              </Route>

              <Route
                exact
                path={`${path}/locations/:locationId/(programs|payment-settings)`}
                component={FCLocationDetails}
              />

              <Route exact path={`${path}/reset`} component={FCReset} />

              <Route exact path={`${path}/stripe-signup`}>
                <FCStripeSignupNew brand={BRANDS.FAMILY_GOLF} />
              </Route>

              <Route
                path={`${path}/*`}
                render={() => <div>Page Not Found</div>}
              />
            </Switch>
          </Suspense>
        </Shell>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(
  withFamilyCupAccess(FamilyCupRoutes),
  roles.COACH,
)
