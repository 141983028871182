import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

/*
  Pass in an array of fully customized JSX objects. This method allows full
  flexibility of functionality within each cell of a row. Make sure that cell-count
  reflects the number of columns.

  * @NOTE that in order to horizontally align with columns, row cells should have
  * left-right padding of `0.5rem`.

  const rows = some_data.map(data =>
    <CustomRowComponent data={data} />,
  )
*/

export const useStyles = makeStyles(theme => ({
  containerStyles: {
    boxSizing: 'border-box',
    padding: '0 36px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
      padding: '0 8px',
    },
  },
  root: ({ rowHeight }) => ({
    '& > tr': {
      border: 'none',
      '& > *': {
        boxSizing: 'border-box',
        height: rowHeight || '80px',
        padding: '0.5rem',
      },
      '& td': {
        borderTop: '1px solid #EEEEEE',
        borderBottom: '1px solid #EEEEEE !important',
      },
      '& td:first-of-type': {
        borderLeft: '1px solid #EEEEEE',
        borderRadius: '8px 0 0 8px',
      },
      '& td:last-of-type': {
        borderRight: '1px solid #EEEEEE',
        borderRadius: '0 8px 8px 0',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  }),
}))

export default function EnhancedTableBody({
  rows,
  columns,
  sortDirection,
  sortBy,
  page,
  numberOfRows,
  rowHeight,
}) {
  const classes = useStyles({ rowHeight })

  function getComparator(sortDirection, sortBy) {
    const sort = columns.find(col => col.id === sortBy)
    if (!sort) return

    const comparator = sort.sortComparator
    return (a, b) => comparator(a, b, sortDirection)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * numberOfRows - rows.length) : 0

  return (
    <TableBody className={classes.root}>
      {rows.length > 0 &&
        rows
          .sort(getComparator(sortDirection, sortBy))
          .slice(page * numberOfRows, page * numberOfRows + numberOfRows)}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
            visibility: 'hidden',
          }}
        >
          <TableCell colSpan={columns.length} />
        </TableRow>
      )}
    </TableBody>
  )
}

EnhancedTableBody.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  page: PropTypes.number,
  numberOfRows: PropTypes.number,
  rowHeight: PropTypes.string,
}
