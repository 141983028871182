import React, { useEffect, useState, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useWizardFormContext } from 'components/wizard-form/context'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const SelectLocation = ({ preSelectFacilityId = '' }) => {
  const { errors, control, watch, setValue } = useFormContext()
  const {
    extraData: { coachFacilities, coachAcademies },
  } = useWizardFormContext()
  const academyId = watch('academyId')

  const [facilities, setFacilities] = useState([])
  const findAcademyById = useCallback(
    id => coachAcademies.find(academy => academy.id === id),
    [coachAcademies],
  )

  const getFccFacility = () => {
    if (preSelectFacilityId) {
      const academy = findAcademyById(preSelectFacilityId)
      const academyFacilities = academy?.facilities ?? []
      return academyFacilities[0]?.id ?? ''
    }
    return ''
  }
  const fccFacilityId = getFccFacility()

  useEffect(() => {
    if (preSelectFacilityId) {
      setValue('locationId', preSelectFacilityId)
    }
  }, [preSelectFacilityId, setValue])

  const getFacilities = useCallback(() => {
    if (academyId) {
      const academy = findAcademyById(academyId)
      const academyFacilities = academy?.facilities ?? []
      setFacilities(academyFacilities)
    } else {
      setFacilities(coachFacilities)
    }
  }, [academyId, coachFacilities, findAcademyById])

  useEffect(() => {
    getFacilities()
  }, [getFacilities])

  return (
    <FormControl error={!!errors.locationId} variant="outlined" fullWidth>
      <InputLabel id="category">Select Location *</InputLabel>
      <Controller
        name="locationId"
        control={control}
        render={({ onChange, value }, _) => (
          <Select
            labelId="location"
            label="Select location"
            value={fccFacilityId || value || ''}
            onChange={onChange}
            data-cy={'location'}
            disabled={!!fccFacilityId}
          >
            {facilities
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((location, idx) => (
                <MenuItem
                  key={location.id}
                  value={location.id}
                  data-cy={'facility-menu-item'}
                >
                  {location.name}
                </MenuItem>
              ))}
          </Select>
        )}
      />
      {errors.locationId ? (
        <Typography variant="caption" color="error">
          Please select a location
        </Typography>
      ) : null}
    </FormControl>
  )
}

export default SelectLocation
PropTypes.SelectLocation = {
  preSelectFacilityId: PropTypes.string,
}
