import CoachAppBar from 'components/coach-app-bar'
import { ContainerRoot } from 'pages/schedule/components/styles'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import BackButton from 'components/back-button'
import { ThemeProvider, Divider, Grid } from '@material-ui/core'
import StepDescriptionOfferings from 'pages/schedule/step-description-offerings'
import PrivateBookingHeader from '../components/private-booking-header'
import themes, { extendTheme } from 'themes'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const reservationSteps = [
  {
    title: 'Choose a Day and Time',
    description: 'Find a day and time that works best for your schedule below.',
  },
  {
    title: 'Confirm Member Number',
    description: 'Please enter the attendee’s Club 1916 member number.',
  },
  {
    title: 'Review',
    description: 'Please review the information below.',
  },
  {
    title:
      'Assumption of Risk, Waiver, and Release of Liability, and Indemnity Agreement',
    description: '',
  },
]

const ReservationsHeaderWrapper = ({
  academy,
  currentStep = 1,
  totalSteps = 1,
  children,
  previousUrl = null,
}) => {
  const progress = (currentStep / totalSteps) * 100
  const { practiceSessionTypes = [] } = academy
  const category = {
    suggestedName: practiceSessionTypes[0]?.title || 'Bay Reservation',
    description: practiceSessionTypes[0]?.description || '',
  }
  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.offeringsRefresh,
      })}
    >
      <Helmet>
        <title>PGA Coach | Bay Reservations</title>
        <meta name="description" content="PGA Coach | Bay Reservations" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <CoachAppBar position="relative" hideContactButton />
      <ContainerRoot
        style={{
          marginBottom: '4rem',
          paddingTop: currentStep > 1 ? '0' : '2rem',
        }}
      >
        <ProgressIndicator value={progress} />
        {currentStep > 1 && <BackButton previousUrl={previousUrl} />}
        <PrivateBookingHeader academy={academy} category={category} />
        <Divider variant="fullWidth" style={{ marginBottom: '2.5rem' }} />
        {totalSteps > 1 && (
          <StepDescriptionOfferings
            stepText={`Step ${currentStep} of ${totalSteps}`}
            title={reservationSteps[currentStep - 1].title}
            description={reservationSteps[currentStep - 1].description}
          />
        )}
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </ContainerRoot>
    </ThemeProvider>
  )
}
export default ReservationsHeaderWrapper
ReservationsHeaderWrapper.propTypes = {
  academy: PropTypes.object.isRequired,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  children: PropTypes.node.isRequired,
}
