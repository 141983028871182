import { DateTime } from 'luxon'

const makeCalendarEvent = ({
  id,
  startDateTime,
  endDateTime,
  title,
  description,
  coachFacility,
}) => ({
  uid: `coach-tools.lesson.${id}.pga.com`,
  startDateTime: DateTime.fromISO(startDateTime),
  endDateTime: DateTime.fromISO(endDateTime),
  title,
  description: description,
  location: coachFacility?.name,
  url: `${window.location.origin}/pga-coach/bookings/schedule`,
})

export { makeCalendarEvent }
