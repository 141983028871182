import jwtDecode from 'jwt-decode'
import { NODE_ENV, AUTH_ROOT } from 'env'
import { AUTH_TOKEN_COOKIE } from '../constants'

const fetchToken = async () => {
  try {
    const resp = await fetch(`${AUTH_ROOT}/session`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    })
    const json = await resp.json()
    return json.token
  } catch (err) {
    // how do we handle errors typically?
    // eslint-disable-next-line no-console
    console.log('errinMIDDLE:', err)
  }
  return null
}

const getToken = () => {
  const cookies = document.cookie.split(';')
  const cookiePairs = cookies.map(c => c.split('='))
  const authTokenCookie = cookiePairs.find(
    cp => cp[0].trim() === AUTH_TOKEN_COOKIE,
  )
  return Promise.resolve(
    authTokenCookie ? decodeURIComponent(authTokenCookie[1]) : null,
  )
}

const setToken = authToken => {
  const decodedToken = _decodeToken(authToken)
  if (decodedToken) {
    document.cookie = `${AUTH_TOKEN_COOKIE}=${authToken}; path=/; max-age=${decodedToken.exp -
      decodedToken.iat}; SameSite=Lax; ${
      NODE_ENV !== 'development' ? 'Secure;' : ''
    }`
  }
}

/*
  example return value:
  {
    ...
    email: 'jsmith@pgahq.com',
    name: 'John Smith',
    updated_at: '2020-05-12T19:41:41Z',
    company: 'The PGA of America',
    sid: 'a898b980-44e0-4a35-b6dc-0a8ee89fe12b',
    aud: '2301c160-608c-0138-4844-0a1da233eeac96594',
    exp: 1589322712,
    iat: 1589315512,
    iss: 'https://openid-connect.onelogin.com/oidc'
  }
*/
const _decodeToken = authToken => {
  if (!authToken) return null
  try {
    return jwtDecode(authToken)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('jwt-decode error', e)
    return null
  }
}

const clearToken = () => {
  document.cookie = `${AUTH_TOKEN_COOKIE}=; path=/; max-age=0;`
}

export { fetchToken, getToken, setToken, clearToken }
