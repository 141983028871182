import { DateTime } from 'luxon'
import sortBy from 'lodash/fp/sortBy'
import { markdownToDraft } from 'markdown-draft-js'

const sortedSessions = sortBy(session => session.startDateTime)

const makeDefaultValues = ({
  title,
  startDateTime,
  endDateTime,
  coachFacility,
  maxAttendance,
  tags,
  featuredImageUrl,
  description,
  priceInCents,
  registrationCloseDateTime,
  paymentAccount,
  paymentRecurrenceSchedule,
  sessions,
  externalRegistrationUrl,
  unlisted,
  accessCode,
  timezone,
  assistantCoaches,
}) => ({
  title,
  maxAttendance,
  startDate: DateTime.fromISO(startDateTime)
    .setZone(timezone)
    .setZone('local', { keepLocalTime: true })
    .toJSDate(),
  startTime: DateTime.fromISO(startDateTime)
    .setZone(timezone)
    .setZone('local', { keepLocalTime: true })
    .toJSDate(),
  registrationCloseDate: DateTime.fromISO(registrationCloseDateTime)
    .setZone(timezone)
    .setZone('local', { keepLocalTime: true }),
  registrationCloseTime: DateTime.fromISO(registrationCloseDateTime)
    .setZone(timezone)
    .setZone('local', { keepLocalTime: true }),
  endTime: DateTime.fromISO(endDateTime)
    .setZone(timezone)
    .setZone('local', { keepLocalTime: true })
    .toJSDate(),
  locationId: coachFacility.id,
  tags,
  featuredImageUrl,
  externalRegistrationUrl,
  paymentRecurrenceSchedule,
  description: JSON.stringify(markdownToDraft(description)),
  price: (priceInCents / 100).toFixed(2),
  paymentAccountId: paymentAccount?.id,
  sessions: sortedSessions(sessions).map(s => ({
    id: s.id,
    startDate: DateTime.fromISO(s.startDateTime)
      .setZone(timezone)
      .setZone('local', { keepLocalTime: true }),
    startTime: DateTime.fromISO(s.startDateTime)
      .setZone(timezone)
      .setZone('local', { keepLocalTime: true }),
    endTime: DateTime.fromISO(s.endDateTime)
      .setZone(timezone)
      .setZone('local', { keepLocalTime: true }),
  })),
  unlisted,
  accessCode,
  assistantCoachIds: assistantCoaches.map(coach => coach.id),
  ageGroup: tags.includes('Adults Only')
    ? 'adults-only'
    : tags.includes('Juniors Only')
      ? 'juniors-only'
      : 'all-ages',
})

export default makeDefaultValues
