import React, { useState, useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import useAuth from './useAuth'

const TOKEN_REFRESH_INTERVAL_MS = 5 * 60 * 1000 // 5 minutes

function withAuthenticatedRoute(Page) {
  function WithAuthenticatedRoute(props) {
    const auth = useAuth()
    const [authReady, setAuthReady] = useState(false)

    useEffect(() => {
      const login = async () => {
        await auth.login(() => setAuthReady(true))
      }
      if (!authReady && !auth.isLoggedIn) {
        login()
      }
      const intervalId = setInterval(
        auth.refreshToken,
        TOKEN_REFRESH_INTERVAL_MS,
      )
      return () => {
        clearInterval(intervalId)
      }
    }, [auth, authReady])

    if (!authReady && !auth.isLoggedIn) {
      return <LinearProgress color="secondary" />
    }
    return <Page {...props} />
  }
  return WithAuthenticatedRoute
}

export default withAuthenticatedRoute
