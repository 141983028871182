import React from 'react'

import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

import StepDescriptionSkeleton from 'components/skeleton-loaders/step-description-skeleton'

const InfoRow = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    style={{ padding: '20px 0' }}
  >
    <Box display="flex" flexDirection="column">
      <Skeleton
        variant="text"
        height={40}
        width={200}
        style={{ marginBottom: '5px' }}
      />
      <Skeleton variant="text" height={30} width={200} />
    </Box>
    <Skeleton variant="circle" width={50} height={50} />
  </Box>
)

export default () => (
  <Container
    style={{ marginTop: '60px' }}
    data-testid="review-booking-skeleton"
  >
    <Grid container spacing={2} style={{ marginBottom: '30px' }}>
      <Grid item md={5} xs={12}>
        <StepDescriptionSkeleton />
      </Grid>
    </Grid>
    {new Array(3).fill().map((_, i) => (
      <Grid container spacing={2} key={i}>
        <Grid item md={5} xs={12}>
          <Divider />
          <InfoRow />
        </Grid>
      </Grid>
    ))}
  </Container>
)
