const GroupEventFragment = `
    slug
    tags
    featuredImageUrl
    priceInCents
    totalPriceInCents
    acceptsOnlinePayments
    paymentRecurrenceSchedule
    numberOfPayments
    lessonCount
    registrationCloseDateTime
    externalRegistrationUrl
    registrationCount
    sessions {
      id
      startDateTime
      endDateTime
    }
    assistantCoaches {
      id
      name
    }
    attendances {
      rsvp
      attendee {
        ... on Student {
          id
        }
      }
    }
    waitlist {
      entries {
        contact {
          id
          firstName
          lastName
          email
        }
      }
    }
`
export default GroupEventFragment
