import React, { useState } from 'react'
import { withStripeElements } from 'lib/stripe'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { FormProvider, useForm } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import InitialsAvatar from 'components/avatars/initials-avatar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { currency } from 'lib/utils/string'
import PaymentInformation from 'pages/schedule/ReviewBooking/payment-information'
import Button from '@material-ui/core/Button'
import { useStyles } from '../styles'
import ReservationsWaiver from './reservations-waiver'
import { yupResolver } from '@hookform/resolvers'

const validationSchema = showWaiver =>
  yup.object().shape({
    ...(!showWaiver && {
      payment: yup.object().shape({
        name: yup.string().required('This field is required'),
      }),
    }),
    ...(showWaiver && {
      agreeWaiver: yup
        .boolean()
        .oneOf([true], 'You must agree to the waiver to continue'),
    }),
  })

const ReviewReservationLayout = ({
  academy,
  student,
  setIsComplete,
  setCardBrand,
  setLastDigits,
  setSpaceName,
  showWaiver,
  setShowWaiver,
  salesTaxEstimation,
}) => {
  const studentName = `${student.firstName} ${student.lastName}`
  const priceInCents = academy?.practiceSessionTypes?.[0]?.priceInCents
  const priceInDollars = priceInCents ? priceInCents / 100 : null
  const salesTaxInCents = priceInDollars ? salesTaxEstimation : 0
  const totalInCents = priceInCents + salesTaxInCents
  const totalInDollars = totalInCents / 100

  const { enqueueSnackbar } = useSnackbar()

  const form = useForm({
    resolver: yupResolver(validationSchema(showWaiver)),
    defaultValues: {
      payment: {
        name: studentName,
      },
      agreeWaiver: false,
    },
  })
  const stripe = useStripe()
  const elements = useElements()
  const classes = useStyles()
  const [stripeToken, setStripeToken] = useState(null)

  const onSubmit = async formValues => {
    const { error: stripeError, token } = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        name: formValues.payment.name,
        address_country: 'US',
      },
    )
    if (stripeError) {
      enqueueSnackbar(stripeError.message, {
        variant: 'error',
      })
      return Promise.resolve()
    }
    setStripeToken(token)
    setShowWaiver(true)
  }

  const handleButtonClick = async () => {
    await form.handleSubmit(onSubmit)()
  }

  return showWaiver ? (
    <ReservationsWaiver
      form={form}
      academy={academy}
      stripeToken={stripeToken}
      setIsComplete={setIsComplete}
      setCardBrand={setCardBrand}
      setLastDigits={setLastDigits}
      setSpaceName={setSpaceName}
    />
  ) : (
    <Grid container>
      <Grid item md={7} xs={12} className={classes.gridItem}>
        <Grid container item xs={12} md={5} className={classes.studentCard}>
          <InitialsAvatar
            name={`${student.firstName} ${student.lastName}`}
            classes={classes}
          />
          <div className={classes.infoWrapped}>
            <Typography
              variant="caption"
              className={`${classes.fontBolded} ${classes.captionText}`}
              data-testid="student-name"
            >
              {studentName}
            </Typography>
            <Typography
              variant="caption"
              className={`${classes.captionText} ${classes.lighterText}`}
            >
              Student
            </Typography>
          </div>
        </Grid>
        <Divider style={{ margin: '2rem 0px 1rem' }} />
        {priceInDollars !== null && (
          <Box mb={2}>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body1" className={classes.lighterText}>
                Subtotal
              </Typography>
              <Typography variant="body1" className={classes.lighterText}>
                {currency(priceInDollars)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography variant="body1" className={classes.lighterText}>
                Sales Tax
              </Typography>
              <Typography variant="body1" className={classes.lighterText}>
                {currency(salesTaxInCents / 100)}
              </Typography>
            </Box>
            <Box
              display="flex"
              className={classes.infoRow}
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="body1" className={`${classes.fontBolded}`}>
                Due Today
              </Typography>
              <Typography variant="body1" className={`${classes.fontBolded}`}>
                {currency(totalInDollars)}
              </Typography>
            </Box>
            <Divider style={{ margin: '1rem 0px 1rem' }} />
          </Box>
        )}
        <FormProvider {...form}>
          <form>
            <Box mb={4}>
              <PaymentInformation
                lessonType={{ acceptsOnlinePayments: true }}
                paymentRequired={true}
                setPaymentRequired={() => {}}
              />
            </Box>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
                data-testid="continue-bay-reservation-button"
                disabled={form.formState.isSubmitting}
              >
                Continue
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  )
}

export default withStripeElements(ReviewReservationLayout)
