import React from 'react'
import { useParams } from 'react-router-dom'
import ApolloDataView from 'components/apollo-data-view'
import LoadingView from '../loading-view'
import NotFound from 'components/not-found.js'
import { useGetStudent } from './api-hooks'
import EditStudentForm from './edit-student-form'

/// Fetches up-to-date information on student prior to editing, and in case
/// user navigates here directly. A student with an account cannot be edited.
const EditStudentPage = () => {
  const { studentId } = useParams()

  const { loading, data, error } = useGetStudent(studentId)

  return (
    <ApolloDataView
      error={error || data?.student.hasAccount}
      loading={loading}
      data={data?.student}
    >
      {{
        errorView: (
          <NotFound
            subtext={
              'Student was not found or has an account and cannot be manually edited.'
            }
            returnUrl={'/pga-coach/students/list'}
          />
        ),
        emptyView: (
          <NotFound
            subtext={'Student was not found.'}
            returnUrl={'/pga-coach/students/list'}
          />
        ),
        loadingView: <LoadingView />,
        dataView: student => <EditStudentForm student={student} />,
      }}
    </ApolloDataView>
  )
}

export default EditStudentPage
