import { useLocalStorage } from '@rehooks/local-storage'
import get from 'lodash/get'
import set from 'lodash/fp/set'
import unset from 'lodash/fp/unset'

export const KEY = 'pgaStorage'

export default function useStorage(path, { defaultValue = undefined } = {}) {
  const [data, setData] = useLocalStorage(KEY)

  return {
    value: get(data, path, defaultValue),
    setValue: value => setData(set(path, value, data)),
    removeValue: () => setData(unset(path, data)),
  }
}
