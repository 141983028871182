import React from 'react'
import LegalDoc from './legal-doc'

export default function FCCodeOfConduct() {
  return (
    <LegalDoc>
      <p>
        In addition to complying with any federal or state laws that may be
        applicable to your hosting an event (s) in the PGA Family Golf program,
        you agree to comply with the following “Code of Conduct” which may, as
        determined by PGA, be updated from time to time. Any updates to this
        Code of Conduct will be communicated to all hosts of PGA Family Golf
        programs and will become effective thirty (30) days following such
        initial dissemination.
      </p>

      <h5>General Program Concepts</h5>
      <p>When participating as a host Professional, individuals shall:</p>
      <ul>
        <li>
          Be a positive role model to your players; display emotional maturity
          and be alert to the physical safety of players.
        </li>
        <li>
          Adjust to the personal needs and problems of players; be a good
          listener; never verbally or physically abuse a player, official, or
          volunteer.
        </li>
        <li>
          Maintain an open line of communication with your teams. Explain the
          goals and objectives of the PGA Family Golf program.
        </li>
      </ul>

      <h5>Host Professional Requirements</h5>
      <p>
        All PGA Family Golf Professionals agree to comply with the following
        program requirements, which apply to both regular season and postseason
        events:
      </p>

      <ul>
        <li>
          Every PGA Family Golf team and event must be registered and overseen
          by a host Professional.
        </li>
        <li>
          Host Professionals must be (1) a PGA Professional, (2) a PGA Associate
          or Student, (3) a LPGA Professional, or (4) a Member of a sanctioned
          PGA organization, as defined by PGA, IN GOOD STANDING with your
          respective Association.
        </li>
        <li>
          All host Professionals must also be registered 2020 PGA Jr. League
          Captains who have successfully completed the PGA defined criminal
          background screen process and Safe Sport Act training prior to being
          in contact with players and complete any additional registration
          requirements established by PGA. All PGA Family Golf related
          activities must be administered solely by host Professional.
        </li>
        <li>
          The “PGA Family Golf” is defined as an event or series of events where
          teams compete under the rules and policies defined in the PGA Family
          Golf Conditions of Play.
        </li>
        <li>
          The host Professional must confirm that every participant has
          completed the PGA defined waiver. This includes confirming that all
          adults have signed the waiver on behalf of themselves, and the
          guardians of all minors have signed the waiver on behalf of their
          children. This can be verified via the following process outlined
          below.
        </li>
        <li>
          Professionals are expected to have an in-depth knowledge and
          understanding of the PGA Family Golf format, policies and rules.
        </li>
        <li>
          Volunteers (i.e. non-host Professionals) may assist the host
          Professional and such individuals are not required to be a member of
          one of the golf organizations noted in the previous bullet.
        </li>
        <li>
          There shall be one authorized host Professional at each PGA Family
          Golf event.
        </li>
        <li>
          Transportation of PGA Family Golf players by host Professionals and/or
          Volunteers is forbidden unless the Professional/Volunteer is the
          parent, guardian or sibling of the applicable player.
        </li>
        <li>
          Host Professionals are required to inform spectators on the
          expectation of positive behavior and take appropriate action if
          violations occur.
        </li>
        <li>
          If an issue arises, the host Professional is expected to resolve it.
          In the event any issue remains unresolved, the applicable host
          Professional will bring such issue forward to the PGA Competitions
          Committee via the PGA Player Engagement Consultant for resolution,
          with the determination of the PGA Competitions Committee being final.
        </li>
        <li>
          Inappropriate touching between a PGA Family Golf player and a host
          Professional and/volunteer is prohibited.
        </li>
        <li>
          Use of audio or visual recording devices, including a cell phone
          camera, is not allowed in changing areas, rest rooms or locker rooms.
        </li>
        <li>
          It is every host Professional’s responsibility to promptly report any
          incident regarding sexual misconduct by any party involved in the PGA
          Family Golf program to a member of PGA’s staff or to the hotline
          established by PGA. Reporting must occur when an individual has
          firsthand knowledge of misconduct or where specific and credible
          information has been received from a victim or knowledgeable third
          party. Various state laws may also require reporting to law
          enforcement or to a designated child protection agency. Any other
          violation of the PGA Family Golf Code of Conduct shall be reported to
          the applicable PGA Player Engagement Consultant.
        </li>
        <li>
          Any additional rules and regulations established by The Professional
          Golfers’ Association of America, as such shall be communicated by The
          Professional Golfers’ Association of America to the host
          Professionals.
        </li>
      </ul>
      <p>
        The Additional Guidelines below describe strategies for creating an open
        and observable environment and establishing clear boundaries between
        adults and players. These guidelines must always be followed by host
        Professionals and Volunteers at all times.
      </p>
      <p>
        An open and observable environment shall be maintained for all
        interactions between adults and players. Private, or one-on-one
        situations, shall be avoided unless they are open and observable.
      </p>
      <ul>
        <li>
          Relationships of a peer-to-peer nature with any players shall be
          avoided.
        </li>
        <li>
          Host Professionals and Volunteers shall avoid horseplay and
          roughhousing with players.
        </li>
        <li>
          When a Professional touches a player as part of instruction, the
          Professional shall do so in direct view of others and inform the
          player of what he/she is doing prior to the initial contact. Touching
          players as part of their instruction shall be minimized outside the
          boundaries of what is considered normal instruction.
        </li>
        <li>
          All Professionals and Volunteers shall refrain from the use of
          inappropriate language and engaging in any activity deemed
          inappropriate by PGA (e.g., use of alcohol/tobacco during PGA Family
          Golf play, etc.)
        </li>
      </ul>

      <h5>Player Waiver</h5>

      <p>
        It is the host Professional’s responsibility to obtain signed waivers
        from every player participating in PGA Family Golf:
      </p>
      <ul>
        <li>
          Verify every player has completed the Waiver by confirming completion
          in the Registration Portal or
        </li>
        <li>
          Collect a signed Waiver Confirmation on-site at your PGA Family Golf
          Event (s) or
        </li>
        <li>
          Player to complete the Waiver on-site by visiting PGAFamilyGolf
          .com/waiver
        </li>
      </ul>
    </LegalDoc>
  )
}
