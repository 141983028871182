import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { useController, useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { useStyles } from './styles'
import { DateTime } from 'luxon'

function Input({ control, name, type, errors, placeholder }) {
  const inputType = type ?? 'text'
  const { field } = useController({
    name,
    control,
  })

  // These props apply an appropriate label for dates/times/etc,
  // where a text placeholder cannot be applied for an empty input.
  const labelProps = inputType !== 'text' && {
    InputLabelProps: {
      shrink: true,
    },
    label: placeholder,
  }

  const error = get(errors, name)

  return (
    <TextField
      variant="outlined"
      type={inputType}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      error={!!error}
      helperText={errors && error?.message}
      placeholder={placeholder}
      {...labelProps}
    />
  )
}

const EventDetailsStep = ({ coachFacilities }) => {
  const { register, errors, control, getValues } = useFormContext()
  const classes = useStyles()
  const selectedFacilityId = getValues('facilityId')
  const { timezone } =
    coachFacilities.find(facility => facility.id === selectedFacilityId) || {}
  const formattedTimezone =
    timezone &&
    DateTime.local()
      .setZone(timezone)
      .toFormat('ZZZZ')

  return (
    <Box display="flex" flexDirection={'column'} alignItems="center">
      <Box mb={3}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          A few more details...
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item style={{ paddingBottom: 0 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Event Name
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            control={control}
            name="title"
            errors={errors}
            placeholder="Event Name"
          />
        </Grid>
        <Grid item style={{ paddingBottom: 0, paddingTop: 16 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Date and Time ({formattedTimezone || 'Local to Facility'})
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Input
              control={control}
              name="sessions[0].startDate"
              type="date"
              placeholder="Date"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              control={control}
              name="sessions[0].startTime"
              type="time"
              placeholder="Start Time"
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              control={control}
              name="sessions[0].endTime"
              type="time"
              placeholder="End Time"
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid item style={{ paddingBottom: 0, paddingTop: 16 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Description
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={6}
            defaultValue="Lorem ipsum and so on"
            name="description"
            placeholder="Share more about the event with a description. This will be important for those planning to register."
            inputRef={register}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default EventDetailsStep
