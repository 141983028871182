import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useStyles } from './styles'
import Grid from '@material-ui/core/Grid'
import InitialsAvatar from 'components/avatars/initials-avatar'
import PropTypes from 'prop-types'

const StudentCard = ({ student }) => {
  const studentName = `${student.firstName} ${student.lastName}`
  const classes = useStyles()
  return (
    <Grid container className={classes.studentCard}>
      <InitialsAvatar
        name={`${student.firstName} ${student.lastName}`}
        classes={classes}
      />
      <div className={classes.infoWrapped}>
        <Typography
          variant="caption"
          className={`${classes.fontBolded} ${classes.captionText}`}
        >
          {studentName}
        </Typography>
        <Typography
          variant="caption"
          className={`${classes.captionText} ${classes.lighterText}`}
        >
          Student
        </Typography>
      </div>
    </Grid>
  )
}

StudentCard.propTypes = {
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
}

export default StudentCard
