import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  errorTxt: {
    color: 'red',
  },
  titleTxt: {
    padding: '20px',
  },
  bodyTxt: {
    marginBottom: '10px',
  },
}))
