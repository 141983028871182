import React from 'react'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import EmptyPageState from 'components/empty-page-state'

const ContentUnavailable = () => (
  <Container>
    <EmptyPageState
      IconComp={ReportProblemOutlinedIcon}
      title="Content Unavailable"
      caption={
        <>
          This is page is no longer available. Please contact your coach
          directly or email{' '}
          <Link href="mailto:support@pga.com">support@pga.com</Link>
        </>
      }
      removeButton
    />
  </Container>
)

export default ContentUnavailable
