import { gql } from '@apollo/client'

export const WAIVER_QUERY = gql`
  query WaiverQuery($id: ID!) {
    waiver(id: $id) {
      signed
    }
  }
`

export const SIGN_WAIVER_MUTATION = gql`
  mutation SignWaiver($waiverId: ID!) {
    signWaiver(waiverId: $waiverId) {
      success
      code
      message
    }
  }
`
