import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '5px',
    marginBottom: '25px',
    backgroundColor: '#FAFAFA',
  },
  coachAvatar: {
    margin: '5px 16px 5px 5px',
    width: '42px',
    height: '42px',
  },
  coachInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '8px',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 8px',
  },
}))
