import { gql, useQuery } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'

const GET_PRACTICE_SESSION_TYPES = gql`
  query practiceSessionTypes(
    $slug: String
    $startDateTime: ISO8601DateTime
    $endDateTime: ISO8601DateTime
  ) {
    academy(slug: $slug) {
      practiceSessionTypes {
        id
        description
        title
        durationInMinutes
        priceInCents
        spaces {
          id
          name
          description
          capacity
        }
        availableTimes(startDateTime: $startDateTime, endDateTime: $endDateTime)
      }
      id
      name
      facilities {
        id
        name
        timezone
        city
        state
        zip
      }
      coaches {
        id
        name
      }
    }
  }
`

export function useGetPracticeSessionTypes({
  slug,
  startDateTime,
  endDateTime,
}) {
  return useQuery(GET_PRACTICE_SESSION_TYPES, {
    variables: {
      slug,
      startDateTime,
      endDateTime,
    },
  })
}

const GET_PRACTICE_SESSION_TAX_ESTIMATION = gql`
  query practiceSessionTaxEstimation($id: ID!) {
    practiceSessionType(id: $id) {
      id
      salesTaxInCents
    }
  }
`

export function useGetPracticeSessionTaxEstimation(practiceSessionTypeId) {
  return useQuery(GET_PRACTICE_SESSION_TAX_ESTIMATION, {
    variables: { id: practiceSessionTypeId },
    skip: !practiceSessionTypeId,
  })
}

export const GetStudentQuery = gql`
  ${GolferProfileFragment}
  query GetStudentQuery($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      golferProfile {
        ...GolferProfileAttributes
      }
    }
  }
`

export const CREATE_PRACTICE_SESSION_FOR_STUDENT = gql`
  mutation CreatePracticeSessionForStudent(
    $input: CreatePracticeSessionForStudentInput!
  ) {
    createPracticeSessionForStudent(input: $input) {
      code
      success
      message
      practiceSession {
        id
        reservation {
          spaceName
        }
      }
    }
  }
`

export const GET_STUDENTS = gql`
  query GetStudents {
    currentUser {
      students {
        id
        representatives {
          relationshipType
          contact {
            externalId
          }
        }
      }
    }
  }
`
