import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  coachCardWrapper: {
    margintop: '25px',
  },
  sectionTitle: {
    marginBottom: '20px',
  },
  textWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
  },
  signInText: {
    marginTop: '10px',
    alignSelf: 'center',
  },
  learnMoreText: {
    color: '#00234B',
    fontWeight: '600',
    cursor: 'pointer',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowWrapped: {
    display: 'flex',
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  fontBolded: {
    fontWeight: 600,
  },
  dividerStyles: {
    margin: '20px 0',
  },
  paddedDivider: {
    margin: '1.5rem 0px',
    width: '100%',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  loadingStyles: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chip: {
    color: '#fff',
    borderRadius: '4px',
    height: '22px',
    fontSize: '10px',
  },
  chipInfo: {
    background: '#0B79D0',
  },
  chipSuccess: {
    background: '#3B873E',
    marginRight: theme.spacing(1),
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  bookingType: {
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  warningBox: {
    padding: '12px',
    background: '#F5F7FA',
    borderRadius: '4px',
    margin: '20px 0',
    '& svg': {
      fill: theme.palette.primary.light,
    },
    color: theme.palette.text.primary,
    fontWeight: '600',
  },
  rootRefresh: {
    margin: '0 auto',
  },
  divider: {
    margin: '2rem 0px 0px',
    width: '100%',
  },
  buttonStyles: {
    borderRadius: '50px',
    height: '40px',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.lightest,
  },
  altButtonStyle: {
    borderRadius: '50px',
    height: '40px',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'none',
  },
  details: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mobileAvatar: {
    border: '2px solid #DBD2C1',
    boxShadow: '0 0 0 7px #FFFFFF, 0px 4px 36px rgba(0, 10, 22, 0.13)',
    width: '3.3rem',
    height: '3.3rem',
  },
  coachCardName: {
    marginLeft: theme.spacing(2),
  },
  icons: {
    color: theme.palette.secondary.dark,
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
  },
  seeMoreToggle: {
    color: theme.palette.primary.light,
  },
}))
