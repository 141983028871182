import React from 'react'
import { IconButtonBackArrow } from 'components/atoms/icons'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'
import RouterLinkButton from 'components/router-link-button'

const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Source Serif Pro',
    textTransform: 'none',
    marginTop: '30px',
    marginBottom: '1rem',
    paddingLeft: '0',
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:focus': {
      color: theme.palette.secondary.dark,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
    '&:active': {
      color: theme.palette.primary.lightest,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      marginBottom: '0px',
    },
  },
}))

const BackButton = ({ previousUrl }) => {
  const classes = useStyles()
  const isInternalUrl =
    previousUrl?.startsWith('/') ||
    previousUrl?.startsWith(FRISCO_COACHING_CENTER_SLUG)

  return isInternalUrl ? (
    <RouterLinkButton
      to={previousUrl}
      startIcon={<IconButtonBackArrow />}
      className={classes.backButton}
    >
      Back
    </RouterLinkButton>
  ) : (
    <Button
      className={classes.backButton}
      href={previousUrl}
      startIcon={<IconButtonBackArrow />}
    >
      Back
    </Button>
  )
}

export default BackButton
