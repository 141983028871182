import React from 'react'
import PropTypes from 'prop-types'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { MenuItem, Select } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import CustomLuxonUtils from 'lib/CustomLuxonUtils'
import DateUtils, { datesForRelativeWindow } from 'utils/dateUtils'

const AnalyticsBox = ({ title, value, isLoading, caption }) => (
  <Grid item xs={6}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6">{title}</Typography>
      {isLoading ? (
        <Skeleton variant="rect" width={150} height={40} />
      ) : (
        <Typography style={{ fontSize: '36px', fontWeight: '600' }}>
          {Number.isNaN(value) ? '-' : value}
        </Typography>
      )}
      <Box width="75%" textAlign="center">
        <Typography variant="caption" color="textSecondary">
          {caption}
        </Typography>
      </Box>
    </Box>
  </Grid>
)

AnalyticsBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  isLoading: PropTypes.bool,
  info: PropTypes.string,
}

const CoachProfileAnalyticsDisplay = ({
  impressions,
  views,
  setStartDate,
  setEndDate,
  analyticsWindow,
  setAnalyticsWindow,
  loading,
  error,
}) => {
  return (
    <>
      {error && (
        <Typography variant="body1" color="error">
          Oops! Something went wrong
        </Typography>
      )}
      <Box mb={4}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
            <DateRangePicker
              analyticsWindow={analyticsWindow}
              setAnalyticsWindow={setAnalyticsWindow}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
      <Grid container>
        <AnalyticsBox
          title="Impressions"
          caption="Number of times your profile has appeared in search results on PGA.com"
          value={impressions}
          isLoading={loading}
        />
        <AnalyticsBox
          title="Views"
          caption="Number of profile views"
          value={views}
          isLoading={loading}
        />
      </Grid>
    </>
  )
}

const DateRangePicker = ({
  analyticsWindow,
  setAnalyticsWindow,
  setStartDate,
  setEndDate,
}) => {
  const handleAnalyticsWindowChange = e => {
    const { startDate, endDate } = datesForRelativeWindow(e.target.value)
    setStartDate(startDate)
    setEndDate(endDate)
    setAnalyticsWindow(e.target.value)
  }

  return (
    <Box flexGrow={0}>
      <Select
        id="analytics-window"
        value={analyticsWindow}
        variant="outlined"
        onChange={handleAnalyticsWindowChange}
      >
        <MenuItem value={DateUtils.THIS_WEEK}>This Week</MenuItem>
        <MenuItem value={DateUtils.LAST_WEEK}>Last Week</MenuItem>
        <MenuItem value={DateUtils.THIS_MONTH}>This Month</MenuItem>
        <MenuItem value={DateUtils.LAST_MONTH}>Last Month</MenuItem>
      </Select>
    </Box>
  )
}

CoachProfileAnalyticsDisplay.propTypes = {
  impressions: PropTypes.number,
  views: PropTypes.number,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  analyticsWindow: PropTypes.string,
  setAnalyticsWindow: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string,
}

export default CoachProfileAnalyticsDisplay
