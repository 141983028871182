import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '45px',
  },
  dividerStyles: {
    margin: '20px 0',
  },
  loadingStyles: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowWrapped: {
    display: 'flex',
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  fontBolded: {
    fontWeight: 600,
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  warningBox: {
    padding: '20px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
    borderRadius: '4px',
    margin: '20px 0',
    '& svg': {
      fill: theme.palette.primary.light,
    },
  },
  rootRefresh: {
    margin: '0 auto',
  },
  divider: {
    margin: '1.5rem 0px 2.5rem',
  },
  gridItem: {
    marginBottom: '3rem',
  },
  studentCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    border: '1px solid #EEE',
    height: '80px',
    padding: '16px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '16px',
  },
  buttonStyles: {
    borderRadius: '50px',
    height: '40px',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.lightest,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
