import React from 'react'
import { useParams } from 'react-router-dom'
import ApolloDataView from 'components/apollo-data-view'
import LoadingView from '../loading-view'
import { useGetStudent } from './api-hooks'
import NotFound from 'components/not-found.js'
import StudentShowPage from './student-show-page'

const StudentIndexPage = () => {
  const { studentId } = useParams()

  const { loading, data, error, refetch } = useGetStudent(studentId)

  return (
    <ApolloDataView error={error} loading={loading} data={data?.student}>
      {{
        errorView: (
          <NotFound
            subtext={'Student was not found.'}
            returnUrl={'/pga-coach/students/list'}
          />
        ),
        emptyView: (
          <NotFound
            subtext={'Student was not found.'}
            returnUrl={'/pga-coach/students/list'}
          />
        ),
        loadingView: <LoadingView />,
        dataView: student => (
          <StudentShowPage student={student} refetch={refetch} />
        ),
      }}
    </ApolloDataView>
  )
}

export default StudentIndexPage
