import { gql } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'
import GroupEventFragment from 'fragments/group-event'

const BOOKINGS_QUERY = gql`
  ${GolferProfileFragment}
  query GetCoachBookingsByStartDate(
    $startDateTime: DateTimeRangeInput!
    $orderBy: [ScheduleOrderByInput!]!
  ) {
    currentUser {
      schedule(startDateTime: $startDateTime, orderBy: $orderBy) {
        __typename
        id
        startDateTime
        endDateTime
        timezone
        isCanceled
        title
        description
        startDateTime
        coachFacility {
          id
          name
          street
          city
          state
          zipCode
        }
        coach {
          id
          name
        }
        enrollments {
          id
          confirmationCode
          createdAt
          isCanceled
          paymentRequestedDate
          paymentRequestToken
          lessonCredit {
            lessonPack {
              lessonPackOffering {
                pricePerLessonInCents
              }
            }
          }
          student {
            id
            firstName
            lastName
            representatives {
              relationshipType
              contact {
                externalId
                firstName
                lastName
                email
                phone
              }
            }
            pgaHopeEmergencyContact {
              id
              firstName
              lastName
              phone
              email
              relationship
            }
            pgaHopeProfile {
              id
              accommodations
              additionalInfo
              golfCareerInterest
              golfExperience
              handedness
              hasOwnClubs
              militaryStatus
              referralSource
              serviceBranch
              serviceYearStart
              serviceYearEnd
              shirtSize
            }
            demographicProfile {
              gender
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              externalId
            }
            golferProfile {
              ...GolferProfileAttributes
            }
          }
          transactions {
            amountInCents
            createdAt
            sourceType
            refund
            registrationPayment {
              id
              products {
                ... on Enrollment {
                  lesson {
                    title
                  }
                }
              }
              registrationPaymentCharge {
                id
                chargeType
                chargeNumber
                checkNumber
              }
            }
          }
        }
        ... on Session {
          lessonType {
            id
            category
            priceInCents
            acceptsOnlinePayments
            durationInMinutes
            paymentAccount {
              id
            }
          }
        }
        ... on GroupEvent {
        ${GroupEventFragment}
          revenueInCents
        }
      }
    }
  }
`

export default BOOKINGS_QUERY
