import React, { useState } from 'react'
import { formatPhoneNumber } from 'utils/phoneNumber'

import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PhoneOutlined from '@material-ui/icons/PhoneOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import { MoreHoriz, PersonOutline } from '@material-ui/icons'
import { useIsTablet } from 'lib/hooks'

const useStyles = makeStyles(theme => ({
  row: {
    cursor: 'pointer',
  },
  info: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'min-content',
  },
  studentAvatar: {
    height: '36px',
    width: '36px',
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  actions: {
    textAlign: 'right',
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    color: theme.palette.grey[600],
    fontSize: '1.25rem',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
  },
}))

const StudentItemView = ({ student, refetchStudents, ...props }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const isTablet = useIsTablet()

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  // ----> Handle details dialog.
  const hasSession = student?.upcomingSession?.__typename !== null
  const noSessionDate = (
    <ListItemText
      style={{ cursor: 'default' }}
      disableTypography
      primary={
        <Typography className={classes.text}>
          No upcoming sessions
          <br />
          scheduled
        </Typography>
      }
    />
  )
  const type = student.upcomingSession?.__typename !== null
  return (
    <>
      <TableRow
        className={classes.row}
        onClick={() => window.alert('You clicked on a student!')}
      >
        <TableCell className={classes.info}>
          <List disablePadding={true}>
            <ListItem>
              <ListItemIcon>
                <Avatar className={classes.studentAvatar} />
              </ListItemIcon>
              <Box pl={2} className={classes.link}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.text}>
                      {student.firstName} {student.lastName}
                    </Typography>
                  }
                />
                <ListItemText>
                  <Typography className={classes.secondaryText}>
                    {student.isMinor ? 'Junior' : 'Adult'}
                  </Typography>
                </ListItemText>
              </Box>
            </ListItem>
          </List>
        </TableCell>
        {!isTablet && (
          <TableCell>
            {hasSession ? (
              <Box className={`${classes.info} ${classes.link}`}>
                <ListItemText
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsOpen(type === 'Session' ? !isOpen : 'detail')
                  }}
                  disableTypography
                  primary={
                    <Typography className={`${classes.text} ${classes.link}`}>
                      {student.upcomingSession.humanizedStartDateTime}
                    </Typography>
                  }
                />
                <ListItemText
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsOpen(type === 'Session' ? !isOpen : 'detail')
                  }}
                >
                  <Typography
                    className={`${classes.secondaryText} ${classes.link}`}
                  >
                    {student.upcomingSession.title}
                  </Typography>
                </ListItemText>
              </Box>
            ) : (
              noSessionDate
            )}
          </TableCell>
        )}
        <TableCell>{student.coaches[0]?.name}</TableCell>
        <TableCell className={classes.actions}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreHoriz fontSize="small" />
          </Button>
        </TableCell>
      </TableRow>
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {!isTablet && (
          <MenuItem onClick={() => window.alert('You clicked on a student!')}>
            <ListItemIcon>
              <PersonOutline className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="View Student Details" />
          </MenuItem>
        )}
        {isTablet && student.representatives?.phoneNumber && (
          <MenuItem
            key="phone"
            component="a"
            href={`tel:${student.representatives.phoneNumber}`}
          >
            <ListItemIcon>
              <PhoneOutlined className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              link
              primary={formatPhoneNumber(student.representatives?.phoneNumber)}
            />
          </MenuItem>
        )}
        {isTablet && student.representives?.email && (
          <MenuItem
            key="Email"
            component="a"
            href={`mailto:${student.representatives.email}`}
          >
            <ListItemIcon>
              <EmailOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary={student.representatives.email} />
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default StudentItemView
