import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import RouterLinkButton from 'components/router-link-button'
import { gtmEvent } from 'lib/gtm'

const StudentProfileCTA = ({ golferProfile = {}, studentId }) => {
  const studentHasFilledOutProfile = !!golferProfile?.id

  const onUpdateGolferProfile = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-coachofferings-button',
    })
  }

  const sectionContent = studentHasFilledOutProfile
    ? {
      text:
        'Make sure your student information is up to date before your lesson.',
      buttonText: 'Update Student Profile',
    }
    : {
      text: 'Make sure you fill out your student info ahead of your lesson.',
      buttonText: 'Fill out Student Profile',
    }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {sectionContent.text}
        </Typography>
        <Box display="flex" my={2}>
          <RouterLinkButton
            to={`/account/students/${studentId}/profile/edit`}
            variant="contained"
            color="primary"
            onClick={onUpdateGolferProfile}
            children={sectionContent.buttonText}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default StudentProfileCTA

StudentProfileCTA.propTypes = {
  studentHasFilledOutProfile: PropTypes.bool,
  studentId: PropTypes.string,
}
