import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '60px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainContent: {
    flexBasis: '65%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
  sideColumn: {
    flexBasis: '35%',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginLeft: 0,
    },
  },
}))

export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Skeleton variant="rect" height={30} style={{ marginTop: '20px' }} />
      <Skeleton variant="rect" height={30} style={{ marginTop: '20px' }} />
      <Skeleton variant="rect" height={30} style={{ marginTop: '20px' }} />
      <Box style={{ margin: '60px' }} />
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainContent}>
          {new Array(4).fill().map((_, i) => (
            <Skeleton
              key={i}
              variant="rect"
              height={150}
              style={{ marginBottom: '10px' }}
            />
          ))}
        </Box>
        <Box className={classes.sideColumn}>
          <Skeleton variant="rect" height={350} />
        </Box>
      </Box>
    </Box>
  )
}
