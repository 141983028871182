import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import {
  withProtectedRoute,
  roles,
  withAuthenticatedRoute,
  SignupRedirect,
} from 'lib/auth'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'

const lpgaCoachSignupRoute = '/family-golf-signup/lpga'

const Landing = lazy(() => import('pages/fc-signup'))
const Signup = withProtectedRoute(
  lazy(() => import('pages/signup-pga')),
  roles.COACH,
)
const SignupLpga = withProtectedRoute(
  lazy(() => import('pages/signup-lpga')),
  roles.COACH,
)
const AccountSignupLpga = withProtectedRoute(
  () => <Redirect to={lpgaCoachSignupRoute} />,
  roles.COACH,
  <SignupRedirect
    role="coach"
    returnTo={`${window.location.origin}${lpgaCoachSignupRoute}`}
    signupParams={{ lpga: true }}
  />,
)

const LpgaRequestMembershipForm = lazy(() =>
  import('components/lpga-request-membership'),
)

const LPGASuccessMessage = lazy(() =>
  import('components/lpga-request-membership/lpga-success-message'),
)

const FCSignupRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <ThemeProvider theme={extendTheme(themes.familyCup)}>
      <Helmet>
        <title>PGA Family Golf Management</title>
        <meta name="description" content="PGA Family Golf Management" />
        <link rel="icon" href="/family-golf-favicon.ico" />
      </Helmet>
      <CssBaseline />
      <Suspense fallback={<LinearProgress color="secondary" />}>
        <Switch>
          <Route
            exact
            path={`${path}/lpga-request`}
            component={LpgaRequestMembershipForm}
          />
          <Route
            exact
            path={`${path}/lpga-request/sent`}
            component={LPGASuccessMessage}
          />
          <Route exact path={`${path}/lpga`} component={SignupLpga} />
          <Route path={`${path}/signup-lpga`} component={AccountSignupLpga} />
          <Route path={`${path}/signup`} component={Signup} />
          <Route exact path={path} component={Landing} />
        </Switch>
      </Suspense>
    </ThemeProvider>
  )
}

export default withAuthenticatedRoute(FCSignupRoutes)
