import React from 'react'
import { currency } from 'lib/utils/string'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useStyles } from './styles'
import pluralize from 'lib/pluralize'
import PropTypes from 'prop-types'

const TransactionSummary = ({
  priceInCents,
  cardBrand,
  lastDigits,
  selectedLessonPack,
}) => {
  const priceInDollars = priceInCents ? priceInCents / 100 : null
  const { quantity, priceInCents: priceInCentsLP } = selectedLessonPack
  const totalPrice = selectedLessonPack
    ? currency(priceInCentsLP / 100)
    : currency(priceInDollars)

  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography variant="h4" style={{ marginTop: '36px' }}>
        Transaction Summary
      </Typography>
      {selectedLessonPack && (
        <Box mb={1} mt={2} className={classes.infoRow}>
          <Typography variant="body1" className={classes.lighterText}>
            {`1 lesson pack (${quantity} ${pluralize(quantity, 'lesson')})`}
          </Typography>
          <Typography variant="body1" className={`${classes.lighterText}`}>
            {`${totalPrice} (${currency(
              priceInCentsLP / 100 / quantity,
            )} per lesson)`}
          </Typography>
        </Box>
      )}
      <Box mb={1} className={classes.infoRow}>
        <Typography variant="body1" className={classes.fontBolded}>
          Total
        </Typography>
        <Typography variant="body1" className={`${classes.fontBolded}`}>
          {totalPrice}
        </Typography>
      </Box>
      <Box mb={3} className={classes.infoRow}>
        <Typography variant="body1">Payment information</Typography>
        <Typography variant="body1">
          Paid with {cardBrand} card ************{lastDigits}
        </Typography>
      </Box>
      <Divider className={classes.dividerStyles} />
    </Box>
  )
}

TransactionSummary.propTypes = {
  priceInCents: PropTypes.number,
  cardBrand: PropTypes.string,
  lastDigits: PropTypes.string,
  selectedLessonPack: PropTypes.shape({
    quantity: PropTypes.number,
    priceInCents: PropTypes.number,
  }),
}

export default TransactionSummary
