import React from 'react'
import LegalDoc from './legal-doc'

export default function FCPlayerWaiver() {
  const currentYear = new Date().getFullYear()
  return (
    <LegalDoc>
      <p>
        In consideration of the right of Participant to participate in the{' '}
        {currentYear} PGA FAMILY GOLF, including in post-season and/or
        championship play, to be held from February 1 to December 31,{' '}
        {currentYear} (the “Program”), I hereby, on behalf of myself or on
        behalf of any minor third-party that I am registering as a participant
        in my capacity as such minor third-party’s authorized Parent or Legal
        Guardian (as the “Participant”), agree to the following:
      </p>

      <p>
        Medical Release. Participant acknowledges and agrees that participation
        in the Program subjects Participant to the possibility of physical
        illness or injury (minimal, serious, catastrophic and/or death) and
        Participant acknowledges that Participant is assuming the risk of such
        illness or injury by participating in the Program. In the event of such
        illness or injury, Participant authorizes The Professional Golfers’
        Association of America (or its authorized agents/affiliates) to obtain
        necessary medical treatment for Participant and hereby releases and
        holds harmless The Professional Golfers’ Association of America and all
        affiliated entities (including, but not limited to, Program host golf
        courses and Program vendors), all Program sponsors and each of their
        subsidiaries, trustees, officers, directors, staff, employees,
        volunteers and authorized representatives (“ Releasees”) in the
        exercises of this authority. Participant further acknowledges and
        understands that Participant will be responsible for any and all medical
        and related bills that may be related to or incurred on behalf of
        Participant for any illness or injury that Participant may sustain
        during the Program and/or while traveling to and from the site(s) for
        the Program whether or not the Program actually occurs.
      </p>

      <p>
        Participant is fully aware of the risks and hazards connected with
        Participant’s participation in this Program, and Participant hereby
        elects to voluntarily participate in said Program. PARTICIPANT
        VOLUNTARILY ASSUMES FULL RESPONSIBILITY FOR ANY RISKS OF LOSS, PROPERTY
        DAMAGE OR PERSONAL INJURY, INCLUDING DEATH, that may be sustained by
        Participant, or any loss or damage to Participant’s property, as a
        result of participating in this Program, or while in, on or upon the
        premises where the Program is being conducted or while in transit during
        and to and from said Program.
      </p>

      <p>
        In consideration of the right to participate in the Program, Participant
        hereby COVENANTS NOT TO SUE, and further HOLDS HARMLESS, RELEASES,
        WAIVES, and DISCHARGES the Releasees from any and all liability, claims,
        demands, losses, actions and causes of action whatsoever (including, but
        not limited to, the negligence of the Releasees) arising out of or
        related to (in whole or in part) any loss, damage, or injury (including,
        but not limited to, physical or mental injury, aggravation of any
        pre-existing illness or condition, permanent disability or death), that
        may be sustained by Participant, or to any property belonging to
        Participant, or otherwise, arising out of Participant’s participation in
        the Program and/or traveling to or from any Program related activity
        and/or event. Participant further AGREES TO INDEMNIFY AND HOLD HARMLESS
        the Releasees from any loss, liability, damages, or costs, including,
        but not limited to, court costs and attorney's fees, that may result
        from my being allowed to participate in the Program.
      </p>

      <p>Further, Participant hereby:</p>

      <ol>
        <li>
          agrees that prior to participating, Participant will inspect the
          facilities and equipment to be used, and if Participant believes
          anything is unsafe, Participant will immediately advise an official of
          such conditions(s) and refuse to participate until such conditions(s)
          is corrected;
        </li>
        <li>
          agrees that Participant’s acts, poses, words and performances at the
          Program will be in good taste and will not reflect negatively on the
          image, reputation or business of the Releasees and agrees to indemnify
          and save and hold harmless each of the Releasees from any loss,
          liability, damage or cost they may incur due to Participant’s
          participation in or otherwise in connection with the Program, whether
          caused by the negligence of any Releasees or otherwise;
        </li>
        <li>
          grants full permission to each of the Releasees, with no obligation to
          compensate Participant or to provide Participant any right to review
          or pre-approve, to use each of Participant’s name, voice, statements,
          image, likeness, and/or actions, in addition to any photographs,
          videotapes, motion pictures, recordings, and/or other record of
          Participant’s performances(s) in the Program in perpetuity worldwide
          in any manner (as determined by the Releasees) and in any and all
          media, whether now known or unknown or hereafter devised (the
          “Materials”), and represents that Participant has the right to grant
          such permission;
        </li>
        <li>
          expressly agrees that (a) this RELEASE AND WAIVER OF LIABILITY AND
          INDEMNITY AGREEMENT is intended to be as broad and inclusive as is
          permitted by Florida laws (which shall govern this RELEASE AND WAIVER
          OF LIABILITY AND INDEMNITY AGREEMENT) and that if any portion hereof
          is held invalid, it is agreed that the balance shall nonetheless
          continue in full legal force and effect and (b) for the purposes of
          any action or proceeding arising out of this RELEASE AND WAIVER OR
          LIABILITY AND INDEMNITY AGREEMENT, Participant expressly submits to
          the jurisdiction of all federal and state courts located in the State
          of Florida; and
        </li>
        <li>
          acknowledges that the Releasees are relying on the grant of rights
          contained herein.
        </li>
      </ol>

      <p>
        Participant hereby certifies that Participant has and will abide by the
        rules and regulations of the Program, as such shall be established by
        The Professional Golfers’ Association of America, and that the
        information stated on the form below is true and correct. Participant
        acknowledges that any violation of the previous statement shall result
        in immediate disqualification of Participant from any and/or all Program
        events.
      </p>

      <p>
        It is Participant’s express intent that this Release and Waiver shall
        bind Participant’s family members, heirs, assigns and personal
        representative(s). Participant hereby warrants that Participant has read
        this Release and Waiver Form in its entirety and fully understand its
        contents. Participant is aware that this Release and Waiver Form
        releases Releasees’, including, but not limited to, The Professional
        Golfers’ Association of America and its respective staff, liability and
        contains an acknowledgement of Participant’s voluntary and knowing
        assumption of the risk of injury or illness. Participant further
        acknowledges that nothing in this Release and Waiver Form constitutes a
        guarantee that the Program will occur.
      </p>

      <p>
        If this Release and Waiver Form is being executed by an authorized
        Parent and/or Legal Guardian for a minor, such Parent and/or Legal
        Guardian hereby accepts and agrees to all of the terms and conditions of
        this Release and Waiver Form on behalf of the minor in connection with
        the minor’s participation in the Program. If, despite this Release and
        Waiver Form, such authorized Parent and/or Legal Guardian, or anyone on
        the minor’s behalf, makes a claim for liability against any of the
        Releasees, such authorized Parent and/or Legal Guardian will indemnify,
        defend and hold harmless each of the Releasees from any liabilities
        which may be incurred as the result of such claim.
      </p>

      <p>
        By selecting the "I Accept" button, you are signing this Release and
        Waiver Form electronically. You agree your electronic signature is the
        legal equivalent of your manual signature on this Release and Waiver
        Form. By selecting "I Accept" you consent to be legally bound by this
        Release and Waiver Form 's terms and conditions and acknowledge that you
        have signed this document voluntarily and of your own free will
      </p>
    </LegalDoc>
  )
}
