import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/styles'

const PublishDate = ({ publishedAt, weekday }) => {
  // Fix Safari handling of date format
  const date = new Date(publishedAt.replace(/ /g, 'T'))
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  if (weekday) dateOptions.weekday = 'long'
  const dateFormatted = date.toLocaleDateString('en-US', dateOptions)

  return <time dateTime={publishedAt}>{dateFormatted}</time>
}

PublishDate.propTypes = {
  publishedAt: PropTypes.string,
  weekday: PropTypes.bool,
}

export const PublishDateCard = styled('span')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: 0.5,
  color: theme.palette.grey.dark,
}))

export const FillInDateCard = styled('div')(({ theme }) => ({
  fontSize: '12px',
  paddingLeft: '8px',
  lineHeight: '40px',
  letterSpacing: 1,
  textTransform: 'uppercase',
  color: theme.palette.grey.dark,
}))

export default PublishDate
