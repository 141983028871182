import { useState } from 'react'
import {
  TableRow,
  Typography,
  TableCell,
  Box,
  Avatar,
  Chip,
  Button,
} from '@material-ui/core'
import { useStyles } from './item-styles'
import { useAuth } from 'lib/auth'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SessionMenu from './session-menu'
import { useSessionDates } from '../utils'
import LinesEllipsis from 'react-lines-ellipsis'
import { parseEvent } from 'utils/eventUtils'
import EventDetailsDialog from 'pages/pga-coach/bookings/events/event-details-dialog'

export default function GroupEventItem({
  session,
  isCanceled,
  isPaid,
  isTablet,
  refetch,
  onPayment,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useAuth()
  const { hideSensitiveInformation } = user

  // ----> Handle quick-action menu.
  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { lesson } = session
  const showActionMenu = !(hideSensitiveInformation && !isCanceled) // <-- Possibility of action menu being empty if items are being hidden because impersonation is happening.

  const classes = useStyles({ isCanceled, isPaid })

  // ----> Handle details dialog.
  const handleOpenDialog = value => {
    handleClose()
    setIsOpen(value)
    setAnchorEl(null)
  }

  const DetailsDialog = (
    <EventDetailsDialog
      isOpen={isOpen}
      setIsOpen={handleOpenDialog}
      event={parseEvent(lesson)}
      onPayment={onPayment}
    />
  )

  const {
    startDateTime,
    endDateTime,
    timezone,
    coach,
    title,
    featuredImageUrl,
    coachFacility,
  } = lesson

  const { webDate, mobileDate, timePeriod } = useSessionDates(
    startDateTime,
    endDateTime,
    timezone,
  )

  if (isTablet) {
    return (
      <>
        <TableRow>
          <TableCell onClick={() => setIsOpen('detail')}>
            <Box className={classes.mobileContainer}>
              <Avatar
                variant="rounded"
                className={classes.sessionIcon}
                src={`${featuredImageUrl}&w-65`}
                alt="featured"
              />
              <Box className={classes.mobileText}>
                <Box className={classes.mobileTop}>
                  <Typography onClick={() => setIsOpen('detail')}>
                    {title}
                    {session.isCanceled && ' — Canceled'}
                  </Typography>
                  {showActionMenu && (
                    <Box>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        className={classes.button}
                      >
                        <MoreVertIcon fontSize="medium" />
                      </Button>
                    </Box>
                  )}
                </Box>
                <Box className={classes.mobileBottom}>
                  <Box
                    onClick={() =>
                      setIsOpen('detail')
                    } /* Box wrapper prevents onClick collision */
                  >
                    <LinesEllipsis
                      text={coachFacility?.name}
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                      className={classes.subtext}
                    />
                    <Typography className={classes.subtext}>
                      {mobileDate} • {timePeriod}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
        <SessionMenu
          handleClose={handleClose}
          anchorEl={anchorEl}
          session={session}
          refetch={refetch}
        />
        {DetailsDialog}
      </>
    )
  } else {
    return (
      <>
        <TableRow className={classes.sessionRow}>
          <TableCell onClick={() => setIsOpen('detail')}>
            <Box display="flex" alignItems="center">
              <Avatar
                variant="circular"
                className={classes.sessionIcon}
                src={`${featuredImageUrl}&w-65`}
                alt="featured"
              />
              <Box display="flex" flexDirection="column">
                <Typography>{title}</Typography>
                <Typography className={classes.subtext}>
                  Group Event
                  {isTablet && session.isCanceled && ' — (Cancelled)'}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell onClick={() => setIsOpen('detail')}>
            <Typography>{webDate}</Typography>
          </TableCell>
          <TableCell onClick={() => setIsOpen('detail')}>
            <Typography>{coach.name}</Typography>
          </TableCell>
          <TableCell onClick={() => setIsOpen('detail')}>
            <Chip
              className={classes.chipStyles}
              size="small"
              label={isCanceled ? 'Canceled' : isPaid ? 'Paid' : 'Unpaid'}
            />
          </TableCell>
          <TableCell>
            {showActionMenu && (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className={classes.button}
                >
                  <MoreVertIcon fontSize="small" />
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
        <SessionMenu
          handleClose={handleClose}
          anchorEl={anchorEl}
          session={session}
          refetch={refetch}
        />
        {DetailsDialog}
      </>
    )
  }
}
