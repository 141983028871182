import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useStyles from './styles'
import EmptyPageState from 'components/empty-page-state'
import PartyPopperIcon from 'components/icons/party-popper-icon'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'env'
import YoutubeEmbed from '../../YoutubeEmbed'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const Activities = ({ hits }) => {
  return (
    <>
      {hits.map(hit => (
        <Fragment key={hit.objectID}>
          <Typography variant="h6" gutterBottom>
            {hit.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {hit.details.summary}
          </Typography>
          <YoutubeEmbed embedId={hit.video.mediaId} />
        </Fragment>
      ))}
    </>
  )
}

const ActivitiesHits = connectHits(Activities)

const ActivityPlanList = activities => {
  const plans = activities.activities
  return (
    <>
      {plans.map(plan => (
        <InstantSearch
          indexName="coach_activities_dev"
          searchClient={searchClient}
          key={plan}
        >
          <ActivitiesHits />
          <Configure filters={`objectID:${plan}`} hitsPerPage={10} />
        </InstantSearch>
      ))}
    </>
  )
}

const Hits = ({ hits }) => {
  const hit = hits[0]
  const sections = get(hit, 'sections', [])
  const styles = useStyles()
  return (
    <Grid container spacing={2}>
      {hit ? (
        <>
          <Typography variant="h4" className={styles.heading}>
            {hit.title}
          </Typography>
          {sections.map(section => (
            <div key={section.name}>
              <Typography variant="h5" className={styles.heading}>
                {section.name}
              </Typography>
              <ActivityPlanList activities={section.activities} />
            </div>
          ))}
        </>
      ) : (
        <Grid item xs={12}>
          <EmptyPageState
            IconComp={() => <PartyPopperIcon />}
            title="No activity plans found."
            removeButton
          />
        </Grid>
      )}
    </Grid>
  )
}

const ActivityPlanCards = connectHits(Hits)

const SinglePlansPage = () => {
  const { id } = useParams()
  const classes = useStyles()
  const query = 'objectID:' + id
  return (
    <Box className={classes.container}>
      <Container maxWidth="md">
        <InstantSearch
          indexName="coach_activities_dev"
          searchClient={searchClient}
        >
          <ActivityPlanCards />
          <Configure filters={`${query}`} hitsPerPage={1} />
        </InstantSearch>
      </Container>
    </Box>
  )
}

export default SinglePlansPage
