import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useWizardFormContext } from 'components/wizard-form/context'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import themes from 'themes'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useAuth } from 'lib/auth'

const tempTheme = createTheme(themes.pgaCoach)

const SelectAssistantCoaches = () => {
  const { user } = useAuth()
  const { control, errors, watch } = useFormContext()
  const academyId = watch('academyId')
  const {
    extraData: { coachAcademies },
  } = useWizardFormContext()

  const [coaches, setCoaches] = useState([])

  const getCoaches = () => {
    if (academyId) {
      const academy = coachAcademies.find(academy => academy.id === academyId)
      const allCoaches = academy?.coaches ?? []
      const academyCoaches = allCoaches.filter(coach => coach.id !== user.id)
      setCoaches(academyCoaches)
    }
  }

  useEffect(() => {
    getCoaches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academyId])

  return (
    <ThemeProvider theme={tempTheme}>
      <Box pb={8}>
        <FormControl
          error={!!errors.assistantCoachIds}
          variant="outlined"
          fullWidth
        >
          <InputLabel id="category">Select Assistant Coaches</InputLabel>
          <Controller
            name="assistantCoachIds"
            control={control}
            render={({ onChange, value }, _) => (
              <Select
                labelId="assistant-coaches"
                multiple
                value={value || []}
                onChange={onChange}
                input={<OutlinedInput label="Select Assistant Coaches" />}
                renderValue={selected =>
                  selected
                    .map(id => coaches.find(coach => coach.id === id).name)
                    .join(', ')
                }
                data-cy={'assistant-coaches'}
              >
                {coaches
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(coach => (
                    <MenuItem
                      key={coach.id}
                      value={coach.id}
                      data-cy={'assistant-coach-menu-item'}
                    >
                      <Checkbox checked={value?.indexOf(coach.id) > -1} />
                      {coach.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
          {errors.assistantCoachIds ? (
            <Typography variant="body2" color="error">
              {errors.assistantCoachIds.message}
            </Typography>
          ) : null}
        </FormControl>
      </Box>
    </ThemeProvider>
  )
}

export default SelectAssistantCoaches
