import React, { Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'

export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]

const PopperMy = props => {
  return <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
}

function StateSelector({
  id,
  label,
  value,
  defaultValue,
  onChange,
  helperText,
  disabled,
  error = false,
  required = false,
}) {
  let selectedValue = value
  if (typeof value === 'string' && value.length > 0) {
    selectedValue = STATES.find(s => s.value === value) || ''
    onChange(selectedValue)
  }
  return (
    <Fragment>
      <Autocomplete
        disabled={disabled}
        value={selectedValue}
        defaultValue={defaultValue}
        required={required}
        onChange={(_, newValue) => {
          onChange && onChange(newValue)
        }}
        filterOptions={createFilterOptions({
          stringify: o => `${o.label} ${o.value}`,
        })}
        id={id}
        PopperComponent={PopperMy}
        getOptionSelected={(o, v) => o.value === v.value}
        disableClearable
        options={STATES}
        getOptionLabel={option => (option ? option.value : '')}
        renderOption={option => (
          <Typography data-testid="state-selector-option">
            {option.value} - {option.label}{' '}
          </Typography>
        )}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
            data-testid="state-selector"
          />
        )}
      />
    </Fragment>
  )
}

export default StateSelector
