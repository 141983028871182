import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { PageHeader } from 'components/page-header'
import LinearProgress from '@material-ui/core/LinearProgress'
import FacilitiesForm from 'components/facilities/form'
import { get } from 'lodash'
import { useCalcProgress } from 'lib/hooks'
import Dialog from 'components/onboarding-dialog'

const CREATE_FACILITY = gql`
  mutation FacilityCreate(
    $facility: FacilityInputAttributes!
    $visible: Boolean
    $primary: Boolean
    $offersCoachingAtPrivateClub: Boolean
  ) {
    facilityCreate(
      facility: $facility
      visible: $visible
      primary: $primary
      offersCoachingAtPrivateClub: $offersCoachingAtPrivateClub
    ) {
      message
      success
    }
  }
`

const COACH_ONBOARDING_STATUS = gql`
  query CoachOnboardingStatus {
    currentUser {
      attributes {
        ... on Coach {
          coachOnboardingProgress {
            coachProfileCreated
            schedulableCreated
            availabilityAdded
            facilityAdded
          }
        }
      }
    }
  }
`

const FacilityOnboarding = ({ coachOnboardingProgress, refetch }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [createFacility, { loading }] = useMutation(CREATE_FACILITY)
  const { path } = useRouteMatch()
  const progress = useCalcProgress(coachOnboardingProgress)
  const [dialogOpen, setDialogOpen] = useState(false)

  const crumbs = [
    {
      label: 'Onboarding Dashboard',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]

  const onSubmit = async ({ facility }) => {
    try {
      await createFacility({
        variables: {
          facility: {
            name: facility.name,
            address1: facility.address1 || null,
            address2: facility.address2 || null,
            city: facility.city || null,
            state: facility.state || null,
            zip: facility.zip || null,
            timezone: !facility.externalFacilityId
              ? facility.timezone || null
              : null,
            phone: facility.phone || null,
            url: facility.url || null,
            externalFacilityId: facility.externalFacilityId || null,
            private: facility.private,
          },
          primary: facility.primary,
          visible:
            facility.visible === undefined ? null : facility.visible === 'true',
          offersCoachingAtPrivateClub: facility.offersCoachingAtPrivateClub,
        },
      })
      setDialogOpen(true)
      refetch()
      // Specify onboarding GTM event
      // gtmEvent({
      // event: 'formSubmit',
      // formCategory: 'facility',
      // formAction: 'created-onboarding-facility',
      // })
    } catch (e) {
      enqueueSnackbar('There was an error when creating this facility', {
        variant: 'error',
      })
    }
  }

  const closeDialog = () => setDialogOpen(false)

  return (
    <>
      <PageHeader title="Add Facility" crumbs={crumbs} progress={progress} />
      <FacilitiesForm
        preventExternalFacilityEdit={true}
        onSubmit={onSubmit}
        loading={loading}
        isFirstFacility={coachOnboardingProgress.coachProfileCreated}
      />
      <Dialog
        heading="Coach Profile Complete!"
        open={dialogOpen}
        onClose={closeDialog}
        text="Coach profile creation complete! Head back to Onboarding to keep going!"
        action
      />
    </>
  )
}

const FacilityOnboardingPage = () => {
  const { data, loading, refetch } = useQuery(COACH_ONBOARDING_STATUS)

  if (loading) return <LinearProgress color="secondary" />

  const { coachOnboardingProgress } = get(data, 'currentUser.attributes')

  return (
    <FacilityOnboarding
      coachOnboardingProgress={coachOnboardingProgress}
      refetch={refetch}
    />
  )
}

export default FacilityOnboardingPage
