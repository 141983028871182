import { styled, makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  groupEvent: {
    boxShadow: '0px 4px 12px rgba(0, 10, 22, 0.13)',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '28px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '320px',
      minWidth: '360px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '0px',
    },
    '&:hover': {
      boxShadow: '0px 4px 12px rgba(0, 10, 22, 0.3)',
      cursor: 'pointer',
    },
  },
  media: {
    height: '110px',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '80px',
    },
  },
  chip: {
    position: 'absolute',
    top: '24px',
    left: '24px',
    borderRadius: '6px',
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
    fontWeight: 700,
  },
  caption: {
    fontWeight: '600',
    color: theme.palette.grey.dark,
    letterSpacing: '0',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 24px',
    flexGrow: '1',
    justifyContent: 'space-between',
    '&:last-child': {
      paddingBottom: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px 8px',
    },
  },
  cardSubtitle: {
    marginBottom: theme.spacing(1),
  },
  cardBody: {
    lineHeight: '22px',
    fontWeight: '600',
  },
  cardAction: {
    borderTop: `1px solid ${theme.palette.grey.light}`,
    height: '50px',
  },

  ctaLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.blue.main,
    fontWeight: '700',
    fontSize: '16px',
    letterSpacing: '0px',
    '&:hover': {
      color: theme.palette.blue.main,
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
  },
  ctaButton: {
    width: '100%',
    height: '50px',
    padding: '0',
    borderTop: `1px solid ${theme.palette.grey.light}`,
    '&:hover': {
      color: theme.palette.offwhite.main,
      backgroundColor: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  ctaArrow: {
    top: '0',
    backgroundColor: theme.palette.blue.main,
    '&:before, &:after': {
      backgroundColor: theme.palette.blue.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.offwhite.main,
    },
  },
  ctaLinkMobile: {
    borderTop: 'none',
    height: 'auto',
  },
}))

export const CardIcon = styled(Box)(({ theme }) => ({
  width: '80px',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.common.white,
  backgroundPosition: 'center',
  backgroundSize: '80%',
  backgroundRepeat: 'no-repeat',
  border: `2px solid ${theme.palette.secondary.light}`,
  position: 'absolute',
  top: '60px',
  right: '28px',
  [theme.breakpoints.down('sm')]: {
    width: '60px',
    height: '60px',
    top: '50px',
    right: '24px',
  },
}))

export default useStyles
