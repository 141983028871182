import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CtrlTextField } from '../../../form-util'
import TextField from '@material-ui/core/TextField'
import FacilitiesAlgolia from 'components/coaches-profile/profile-components/FacilitiesAlgolia'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import usStates from 'utils/usStates'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { HelperMsg } from 'components/coaches-profile/profile.styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import { useStyles } from './styles'

const FacilityStep = ({ coachFacilities }) => {
  const { register, errors, control, setValue } = useFormContext()
  const [facilitySelected, setFacilitySelected] = useState(false)
  const styles = useStyles()
  const hasExistingFacilities = coachFacilities.length > 0

  const setFacility = hit => {
    const {
      name,
      address1,
      address2,
      city,
      state,
      zip,
      facility_id: externalFacilityId,
    } = hit

    setValue(
      'facility',
      {
        facilityName: name,
        address1,
        address2,
        city,
        state,
        zipCode: zip,
        externalFacilityId,
      },
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    )

    setFacilitySelected(true)
  }

  const clearSelectedFacility = () => {
    setValue('facility', {
      externalFacilityId: null,
      facilityName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
    })

    setFacilitySelected(null)
  }

  return (
    <>
      <Box mb={2}>
        <Box display="flex" flexDirection="column" alignItems="center" my={2}>
          <Typography variant="h4" gutterBottom className={styles.title}>
            Set up your event!
          </Typography>
          <HelperMsg style={{ textAlign: 'center', lineHeight: '1.5' }}>
            We’ve done most of the work to set up your event, but we need a
            little more info from you.
          </HelperMsg>
        </Box>
        <Controller
          name={hasExistingFacilities ? 'facilityId' : 'facility.facilityName'}
          control={control}
          defaultValue={hasExistingFacilities ? coachFacilities[0].id : ''}
          render={({ name, value, onChange }) =>
            hasExistingFacilities ? (
              <TextField
                select
                label="Facility"
                variant="outlined"
                defaultValue={coachFacilities[0].id}
                onChange={onChange}
              >
                {coachFacilities.map(facility => (
                  <MenuItem key={facility.id} value={facility.id}>
                    {facility.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <FacilitiesAlgolia
                name={name}
                onSelect={setFacility}
                value={value}
                error={!!errors.facility?.facilityName}
                helperText={errors.facility?.facilityName?.message}
                inputVariant="outlined"
                onChange={onChange}
                disabled={facilitySelected}
                title="Event Location"
                label=""
                placeholder="Club or facility name"
                InputProps={
                  facilitySelected && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear facility"
                          onClick={clearSelectedFacility}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                }
              />
            )
          }
        />
        <input
          name="facility.externalFacilityId"
          type="hidden"
          ref={register}
        />
      </Box>
      {hasExistingFacilities ? null : (
        <Box>
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="subtitle2" color="textSecondary">
                Address
              </Typography>
              <Controller
                name="facility.address1"
                control={control}
                render={({ name, value, ref, onChange }) => (
                  <TextField
                    required
                    name={name}
                    placeholder="Address 1"
                    variant="outlined"
                    className={styles.facilityInput}
                    error={!!errors.facility?.address1}
                    helperText={errors.facility?.address1?.message}
                    inputRef={ref}
                    disabled={facilitySelected}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Controller
                name="facility.address2"
                control={control}
                render={({ name, value, ref, onChange }) => (
                  <TextField
                    name={name}
                    placeholder="Address 2"
                    variant="outlined"
                    className={styles.facilityInput}
                    error={!!errors.facility?.address2}
                    helperText={errors.facility?.address2?.message}
                    inputRef={ref}
                    disabled={facilitySelected}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Controller
                name="facility.city"
                control={control}
                render={({ name, value, ref, onChange }) => (
                  <TextField
                    required
                    name={name}
                    placeholder="City"
                    variant="outlined"
                    className={styles.facilityInput}
                    error={!!errors.facility?.city}
                    helperText={errors.facility?.city?.message}
                    inputRef={ref}
                    disabled={facilitySelected}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="facility.state"
                control={control}
                render={({ name, value, ref, onChange }) => (
                  <Autocomplete
                    name={name}
                    disableClearable
                    required
                    value={value}
                    options={usStates}
                    onChange={(e, value) => onChange(value)}
                    inputRef={ref}
                    disabled={facilitySelected}
                    className={styles.facilityInput}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={value || 'ST'}
                        variant="outlined"
                        error={!!errors.facility?.state}
                        helperText={errors.facility?.state?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <CtrlTextField
                name="facility.zipCode"
                control={control}
                errors={errors}
                disabled={facilitySelected}
                placeholder="Zip"
                required
                variant="outlined"
                className={styles.facilityInput}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default FacilityStep
