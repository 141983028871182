import { TwitterShareButton } from 'react-share'
import { XButton } from './x-button'

const XShareButton = ({
  url,
  title,
  wrapperClassname = {},
  buttonClassname = {},
  size,
  children,
  ...props
}) => (
  <TwitterShareButton
    url={url}
    subject={title}
    className={wrapperClassname}
    {...props}
  >
    {/* Icon component handles default size. */}
    <XButton size={size} className={buttonClassname} />
    {children}
  </TwitterShareButton>
)

export default XShareButton
