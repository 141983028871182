import { makeStyles } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'

const useStyles = makeStyles(theme => ({
  formField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  formFieldCurrencyWithNotice: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  editorRoot: {
    minHeight: '180px',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '0 16px',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fill: 'unset',
    minWidth: '40px',
  },
  mainIcon: {
    color: theme.palette.primary.main,
    background: '#ecedf1',
    padding: '24px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  warningWrapper: {
    background: '#feeceb',
    color: '#6f2b27',
    padding: '18px 16px',
    fontSize: '14px',
    borderRadius: '4px',
  },
  ctaButton: {
    textTransform: 'none',
    color: theme.palette.primary.light,
    paddingLeft: '0px',
    paddingBottom: '0px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  informationContainer: {
    paddingLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  infoWrapper: {
    backgroundColor: blue[50],
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5),
    borderRadius: '4px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '9px',
    },
  },
  infoIconWrapper: {
    padding: '0px 24px 0px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px 0px 8px',
    },
    '& svg': {
      fontSize: '1.5rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },
  },
}))

export default useStyles
