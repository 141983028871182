import React from 'react'
import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useStyles from './styles'

const DialogTitle = props => {
  const { children, onClose, ...other } = props

  const styles = useStyles()

  return (
    <MuiDialogTitle disableTypography className={styles.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  onClose: PropTypes.func,
}

export default DialogTitle
