import React from 'react'
import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import GET_LESSON_CREDIT_BALANCE from './query'
import LessonPacksDisplay from './lesson-packs-display'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'

const LessonPacksPageData = () => {
  const { data, loading, error } = useQuery(GET_LESSON_CREDIT_BALANCE)

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />

  const lessonCreditBalances = data?.currentUser?.attributes?.lessonPacks || []

  const activeLessonPacks = lessonCreditBalances.filter(
    lp =>
      lp.remainingCredits > 0 &&
      (lp.expiresAt === null || Date.parse(lp.expiresAt) > Date.now()),
  )

  const usedOrExpiredLessonPacks = lessonCreditBalances.filter(
    lp => lp.remainingCredits === 0 || Date.parse(lp.expiresAt) < Date.now(),
  )

  return (
    <LessonPacksDisplay
      activeLessonPacks={activeLessonPacks}
      usedOrExpiredLessonPacks={usedOrExpiredLessonPacks}
    />
  )
}

const LessonPacksPage = () => {
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'My Coach',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]
  return (
    <>
      <PageHeader title="Lesson Packs" crumbs={crumbs} />
      <LessonPacksPageData />
    </>
  )
}

export default LessonPacksPage
