import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import { makeStyles, styled } from '@material-ui/core/styles'

const StyledCard = styled(Card)(props => ({
  borderRadius: 4,
  boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  boxSizing: 'border-box',
}))

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  actionButton: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
    lineHeight: '1.3',
  },

  logoWrapper: {
    minHeight: theme.spacing(13),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: theme.spacing(9),
    },
  },

  logo: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },

  cardDescription: {
    minHeight: theme.spacing(6),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      minHeight: theme.spacing(12),
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    },
  },
}))

const AppCard = ({
  title,
  testid,
  buttonText,
  to,
  url,
  logo = { src: '', alt: '', className: '' },
  logoStyle = {},
}) => {
  const classes = useStyles()

  const buttonProps = {}

  if (to) {
    buttonProps.component = RouterLink
    buttonProps.to = to
  } else if (url) {
    buttonProps.component = 'a'
    buttonProps.href = url
    buttonProps.target = '_blank'
    buttonProps.rel = 'noopener noreferrer'
  } else {
    buttonProps.disabled = true
  }

  const renderLogo = () => {
    if (React.isValidElement(logo)) {
      return React.cloneElement(logo, {
        className: clsx(classes.logo, logo.props.className),
        style: { ...logoStyle, ...logo.props.style },
      })
    } else if (typeof logo === 'object' && logo.src) {
      return (
        <img
          src={logo.src}
          alt={logo.alt || ''}
          className={clsx(classes.logo, logo.className)}
          style={logoStyle}
        />
      )
    }
    return null
  }

  return (
    <StyledCard data-testid={testid}>
      <CardContent className={classes.content}>
        <div className={classes.logoWrapper}>{renderLogo()}</div>
        <Typography className={classes.cardDescription} variant="body1">
          {title}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.actionButton}
          fullWidth
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </CardContent>
    </StyledCard>
  )
}

AppCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      className: PropTypes.string,
    }),
    PropTypes.element,
  ]),
  logoStyle: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  url: PropTypes.string,
  testid: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
}

export default AppCard
