import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LocationDropdown from 'pages/schedule/SelectLessonType/location-dropdown'
import LessonPacksList from './lesson-packs-list'
import Divider from '@material-ui/core/Divider'
import EmptyPageState from 'components/empty-page-state'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

const getAllLocations = lessonPacks => {
  return lessonPacks.flatMap(lessonPack => lessonPack.lessonType.locations)
}

const filterLessonPacksByLocation = (lessonPacks, selectedFacility) => {
  return lessonPacks.filter(
    bt => bt.lessonType.locations[0].id === selectedFacility,
  )
}

const LessonPacks = ({ lessonPacks, refetch }) => {
  const [selectedFacility, setSelectedFacility] = useState({
    id: 'all',
    name: 'All locations',
  })
  const [allLocations, setAllLocation] = useState([])

  const activeLessonPacks = lessonPacks.filter(
    lp =>
      lp.remainingCredits > 0 &&
      (lp.expiresAt === null || Date.parse(lp.expiresAt) > Date.now()),
  )

  const usedOrExpiredLessonPacks = lessonPacks.filter(
    lp => lp.remainingCredits === 0 || Date.parse(lp.expiresAt) < Date.now(),
  )
  useEffect(() => {
    setAllLocation(getAllLocations(lessonPacks))
  }, [lessonPacks])

  if (lessonPacks.length === 0) {
    return (
      <EmptyPageState
        IconComp={() => <CalendarTodayIcon />}
        title="No Lesson Packs"
        caption="This student hasn't purchased any lesson packs."
        removeButton
      />
    )
  }
  return (
    <Box py={3} px={4}>
      <Box pb={3}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <LocationDropdown
              selectedFacility={selectedFacility}
              setSelectedFacility={setSelectedFacility}
              locations={allLocations}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <LessonPacksList
        title="Active"
        lessonPacks={
          selectedFacility !== 'all'
            ? filterLessonPacksByLocation(activeLessonPacks, selectedFacility)
            : activeLessonPacks
        }
        refetch={refetch}
      />
      <LessonPacksList
        title="Inactive / Expired"
        lessonPacks={
          selectedFacility !== 'all'
            ? filterLessonPacksByLocation(
              usedOrExpiredLessonPacks,
              selectedFacility,
            )
            : usedOrExpiredLessonPacks
        }
        refetch={refetch}
      />
    </Box>
  )
}
export default LessonPacks
