import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { centsToDollarsFormatted } from 'lib/utils/number'
import { useStyles } from './index'
import {
  PGA_STAFF_OFFERING_TEXT,
  INTERNAL_STAFF_OFFERING_TITLES,
} from 'utils/constants'
import { includesStringOrSubstring } from 'lib/utils/array'

const PriceDetails = ({
  lessonInfo,
  showLessonPackPrice,
  lessonPacksPrice,
}) => {
  const { priceInCents, title, requiresAccessCode } = lessonInfo
  const classes = useStyles()
  const theme = useTheme()
  const shouldShowText =
    requiresAccessCode &&
    includesStringOrSubstring(
      INTERNAL_STAFF_OFFERING_TITLES,
      title.toLowerCase(),
    )

  return (
    <>
      {shouldShowText ? (
        <ListItem disableGutters={true} className={classes.listItem}>
          <SavingsIcon color={theme.palette.secondary.dark} size={16} />
          <Typography className={classes.attributes} variant="body2">
            {PGA_STAFF_OFFERING_TEXT}
          </Typography>
        </ListItem>
      ) : null}
      {priceInCents && !lessonPacksPrice && !shouldShowText ? (
        <ListItem disableGutters={true} className={classes.listItem}>
          <SavingsIcon color={theme.palette.secondary.dark} size={16} />
          <Typography className={classes.attributes} variant="body2">
            {centsToDollarsFormatted(priceInCents)}
          </Typography>
        </ListItem>
      ) : null}
      {showLessonPackPrice && !shouldShowText ? (
        <ListItem disableGutters={true} className={classes.listItem}>
          <SavingsIcon color={theme.palette.secondary.dark} size={16} />
          <Typography className={classes.attributes} variant="body2">
            {`From ${centsToDollarsFormatted(lessonPacksPrice)} / Session`}
          </Typography>
        </ListItem>
      ) : null}
    </>
  )
}

PriceDetails.propTypes = {
  lessonInfo: PropTypes.object.isRequired,
  showLessonPackPrice: PropTypes.bool.isRequired,
  lessonPacksPrice: PropTypes.number,
}

export default PriceDetails
