import React from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withAuthenticatedRoute, useAuth } from 'lib/auth'

import { ThemeProvider, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import themes, { extendTheme } from 'themes'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import BulletPoints, { golferBullets, pgaCoachBullets } from './bullet-points'
import CoachLandingPage from 'pages/landing'
import { useStyles } from './styles'

const golferImg =
  'https://res.cloudinary.com/pgahq/image/upload/v1617391244/dot-com-images/golfer-banner-image.png'
const calendarImg =
  'https://res.cloudinary.com/pgahq/image/upload/v1617391263/dot-com-images/calendar-banner-image.png'

export const WelcomePage = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  return (
    <Container className={classes.root}>
      <div className={classes.headerSection}>
        <Typography variant="h3" color="primary" className={classes.headerText}>
          Welcome to MyPGA
        </Typography>
        <Typography variant="body1" color="primary">
          Select from the options below to log in.
        </Typography>
      </div>
      <div className={classes.contentSection}>
        <a href="/club" className={classes.golferBanner}>
          <div className={classes.golferContent}>
            <Typography
              variant="h4"
              color="primary"
              className={classes.headerSectionText}
            >
              GOLFER
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              className={classes.sectionCaptionText}
            >
              Ready to take the next step in your golf game?
            </Typography>
            <img
              src={golferImg}
              alt="golfer"
              className={classes.backgroundImage}
            />
            {!isMobile && (
              <div mt={2} className={classes.bulletPointSection}>
                <BulletPoints collection={golferBullets} color="primary" />
              </div>
            )}
            <Button
              href="/club"
              className={classes.btnStyles}
              variant="contained"
              color="primary"
            >
              GET STARTED
            </Button>
          </div>
        </a>
        <a href="/pga-coach" className={classes.pgaCoachBanner}>
          <div className={classes.golferContent}>
            <Typography
              variant="h4"
              color="secondary"
              className={classes.headerSectionText}
            >
              PGA COACH
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              className={classes.sectionCaptionText}
            >
              We take the administrative work off your plate so you can do more
              of what you love - coach golf.
            </Typography>
            <img
              src={calendarImg}
              alt="golfer"
              className={classes.backgroundImage}
            />
            {!isMobile && (
              <div mt={2} className={classes.bulletPointSection}>
                <BulletPoints collection={pgaCoachBullets} color="secondary" />
              </div>
            )}
            <Button
              href="/pga-coach"
              className={classes.btnStyles}
              variant="contained"
              color="secondary"
            >
              GET STARTED
            </Button>
          </div>
        </a>
      </div>
    </Container>
  )
}

/*
This is used as work-around related to Intercom Product Tours
More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
*/
const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

export const WelcomeShell = () => {
  const { isLoggedIn, isCoach, isConsumer } = useAuth()

  if (isLoggedIn && isConsumer) {
    return <Redirect to="/club" />
  }

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>MyPGA</title>
        <meta name="description" content="MyPGA | manage your journey" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      {isLoggedIn && isCoach ? <CoachLandingPage /> : <WelcomePage />}
    </ThemeProvider>
  )
}

export default withAuthenticatedRoute(WelcomeShell)
