import { gql, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

const VALIDATE_ADDRESS = gql`
  query ValidateAddress($input: ValidateAddressInput!) {
    validateAddress(input: $input) {
      success
      message
    }
  }
`

const errorMessage =
  'We cannot confirm the shipping address entered, please check if the address is correct'

export const useValidateAddress = () => {
  const { refetch } = useQuery(VALIDATE_ADDRESS, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const validateAddress = async ({ address1, city, state, zip }) => {
    setLoading(true)
    try {
      if (!(address1 && city && state && zip)) {
        throw new Error(errorMessage)
      }
      const { data } = await refetch({
        input: {
          address1,
          city,
          state,
          zip,
        },
      })
      if (!data.validateAddress.success) {
        throw new Error(errorMessage)
      }
      setLoading(false)
      return true
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 1e4,
      })
      setLoading(false)
      return false
    }
  }

  return [validateAddress, { loading }]
}
