import React from 'react'
import AppCard from 'components/app-card'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'

export default function CoachTraining() {
  return (
    <AppCard
      title="Access the coach training center for ADM, Modern PGA Coach and more"
      url="https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/"
      buttonText="launch"
      testid="coach-training-tile"
      logoStyle={{ height: '70px', width: '70px' }}
      logo={<SchoolOutlinedIcon style={{ color: '#00234B' }} />}
    />
  )
}
