import React from 'react'
import IconCard from 'components/icon-card'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import StatusChip, { statuses as requirementStatuses } from './status-chip'
import { gql, useQuery } from '@apollo/client'

export const statuses = {
  NOT_STARTED: 'NOT_STARTED',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  CHECKING: 'CHECKING',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  NEAR_EXPIRY: 'NEAR_EXPIRY',
}

const toChipStatus = apsStatus =>
  ({
    [statuses.NOT_STARTED]: requirementStatuses.NOT_STARTED,
    [statuses.PASSED]: requirementStatuses.PASSED,
    [statuses.FAILED]: requirementStatuses.FAILED,
    [statuses.CHECKING]: requirementStatuses.CHECKING,
    [statuses.ERROR]: requirementStatuses.ERROR,
    [statuses.EXPIRED]: requirementStatuses.EXPIRED,
    [statuses.NEAR_EXPIRY]: requirementStatuses.NEAR_EXPIRY,
  }[apsStatus])

export const APS_QUERY = gql`
  query aps {
    aps {
      trainingUrl
    }
  }
`

export default function Aps({ status }) {
  const { data, loading, error } = useQuery(APS_QUERY)
  let action

  if (loading) {
    action = { disabled: true, text: 'LOADING' }
  }
  if (error) {
    action = { disabled: true, text: 'ERROR LOADING URL' }
  }
  if (data?.aps?.trainingUrl) {
    action = { url: data.aps.trainingUrl, text: 'MANAGE' }
  }

  const statusChip = <StatusChip status={toChipStatus(status)} />
  return (
    <IconCard
      statusChip={statusChip}
      title="APS Training"
      actions={[action]}
      icon={AssignmentIndOutlinedIcon}
      testid="aps-card"
    >
      As a Coach or Manager you are required to attend an Abuse Prevention
      training before participating. Note, if you do not already have an Abuse
      Prevention Systems account, you will have to create one. Abuse Prevention
      Systems is a Third Party tool, so you will be directed away from the PGA
      system to do so. Abuse Prevention training is required every two years.
    </IconCard>
  )
}
