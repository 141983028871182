import React from 'react'
import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tag: {
    margin: '5px',
    width: 'fit-content',
  },
}))
const immutableTags = ['PGA HOPE', 'Adults Only', 'Juniors Only']

const TagSelectionInput = ({
  tags,
  value,
  onChange,
  allowToSelectImmutableTags,
}) => {
  const classes = useStyles()
  const onClick = tag => {
    if (value.includes(tag)) {
      onChange(value.filter(t => t !== tag))
    } else {
      onChange([...value, tag])
    }
  }
  // the tag is included in the immutableTags array
  // then we only want to set it on event creation
  const isImmutableTag = tag => immutableTags.includes(tag)

  return (
    <>
      {tags.map(tag => {
        const isSelected = value.includes(tag)
        return (
          <Chip
            key={tag}
            icon={isSelected ? <CheckIcon /> : <AddIcon />}
            color={isSelected ? 'primary' : 'default'}
            label={tag}
            onClick={() =>
              isImmutableTag(tag) && !allowToSelectImmutableTags
                ? null
                : onClick(tag)
            }
            className={classes.tag}
          />
        )
      })}
    </>
  )
}

export default TagSelectionInput
