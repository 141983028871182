import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Rating from 'material-ui-rating'
import LinesEllipsis from 'react-lines-ellipsis'
import PublishDate, { FillInDateCard } from 'components/publish-date'
import ReviewModal from 'components/review-modal'
import useStyles from './coach-review-card.styles'

const CoachReviewCard = props => {
  const { review, isMobile } = props

  const { rating, reviewText, updatedAt, contact } = review

  const { firstName, lastName } = contact

  const [maxLines, setMaxLines] = useState(3)
  const [isReviewClamped, setIsReviewClamped] = useState(false)
  const [showFullReview, setShowFullReview] = useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const MAX_LINES = 50
  const styles = useStyles({ showFullReview })

  const handleReflow = rleState => {
    const { clamped } = rleState
    setIsReviewClamped(clamped)
  }

  const handleFullReview = e => {
    e.preventDefault()
    if (isMobile) {
      setMaxLines(MAX_LINES)
      setShowFullReview(true)
    } else {
      setOpenDialog(true)
    }
  }

  const toggleDialog = isOpenDialog => {
    setOpenDialog(isOpenDialog)
  }

  return (
    <Card variant="outline" className={styles.cardRoot}>
      <div className={styles.ratingWrapper}>
        <Rating
          name="rate"
          value={rating}
          classes={{
            iconButton: styles.starButton,
          }}
          readOnly
        />
        <FillInDateCard>
          <PublishDate publishedAt={DateTime.fromSQL(updatedAt).toISO()} />
        </FillInDateCard>
      </div>
      <Box ml={1}>
        <Typography variant="h6" gutterBottom>
          {firstName} {lastName}
        </Typography>
        <LinesEllipsis
          maxLine={maxLines}
          text={reviewText || ''}
          className={styles.reviewWrapper}
          onReflow={handleReflow}
        />
      </Box>
      <Box display="flex">
        {isReviewClamped ? (
          <>
            <Button
              disableRipple
              className={styles.reviewAction}
              onClick={handleFullReview}
            >
              READ MORE
            </Button>
            <ReviewModal
              review={review}
              openDialog={openDialog}
              parentToggleDialog={toggleDialog}
            />
          </>
        ) : null}
      </Box>
    </Card>
  )
}

CoachReviewCard.propTypes = {
  review: PropTypes.object,
  isMobile: PropTypes.bool,
}

CoachReviewCard.defaultProps = {}

export default CoachReviewCard
