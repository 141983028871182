import React from 'react'
import {
  useFindOrCreateChannelWithUserIds,
  useStreamClient,
} from 'lib/stream-messaging/hooks'
import { useChatContext } from 'stream-chat-react'
import { useSnackbar } from 'notistack'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import LoadingSpinner from 'components/loading-spinner'

export default function ConversationLoader({ setLoading }) {
  const { externalId } = useParams()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const history = useHistory()
  const [error, setError] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const client = useStreamClient()
  const currentUserId = client?.user?.id
  const findOrCreateChannelWithUserIds = useFindOrCreateChannelWithUserIds()
  const { setActiveChannel } = useChatContext()

  const redirectURL = pathname
    .split('/')
    .slice(0, 3)
    .join('/')

  const startConversation = async () => {
    const channel = await findOrCreateChannelWithUserIds([externalId])
    if (channel) {
      setActiveChannel(channel)
      window.history.replaceState(null, null, `${redirectURL}/${channel.id}`)
    } else {
      setError(true)
      enqueueSnackbar(
        'Unable to create or join conversation. Please try again later.',
        {
          variant: 'error',
        },
      )
    }
  }

  // Once this is done we are manually "sleeping" for 1 second to avoid a
  // flash of an empty page.
  React.useEffect(() => {
    if (currentUserId && externalId) {
      if (pathname.includes('/new-conversation')) {
        startConversation()
      }
      setTimeout(() => setLoading(), 1000)
    } else if (path.includes(':channelId?')) {
      setTimeout(() => setLoading(), 1000)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentUserId, externalId])

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box mb={2} mt={6} display="flex" justifyContent="center">
          <Typography variant="body1">Unable to load conversation.</Typography>
        </Box>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ width: 'fit-content' }}
              href={pathname}
            >
              Reload
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ width: 'fit-content' }}
              onClick={() => history.push(redirectURL)}
            >
              All Messages
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      id="loading-container"
    >
      <LoadingSpinner />
    </Box>
  )
}
