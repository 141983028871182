import * as yup from 'yup'
import { get } from 'lodash'

export const defineLessonPacksWithDiscount = lpks => {
  const lpWithDiscount = JSON.parse(JSON.stringify(lpks))
  const highestPricePerLesson = Math.max(
    ...lpWithDiscount.map(item => item.priceInCents / item.quantity),
  )

  lpWithDiscount.map(lp => {
    if (lp.priceInCents / lp.quantity === highestPricePerLesson) {
      lp.discount = null
    } else {
      lp.discount = highestPricePerLesson * lp.quantity - lp.priceInCents
    }
    return lp
  })
  return lpWithDiscount
}

export const validationSchema = (paymentRequired, shouldShowWaiver = false) =>
  yup.object().shape({
    ...(paymentRequired && {
      payment: yup.object().shape({
        name: yup.string().required('This field is required'),
      }),
    }),
    ...(shouldShowWaiver && {
      agreeWaiver: yup
        .boolean()
        .oneOf([true], 'You must agree to the waiver to continue'),
    }),
  })

export const checkLessonPack = lessonType => {
  if (!lessonType) return false
  return get(lessonType, 'lessonTypes[0].lessonPacks')?.length > 0
}

export const checkLessonCredits = ({ lessonTypeId, lessonCreditBalances }) => {
  if (!lessonCreditBalances) return false
  return (
    lessonCreditBalances?.some(balances => {
      return balances.lessonType.id === lessonTypeId && balances.quantity > 0
    }) ?? false
  )
}

export const formatCurrency = amount => {
  amount = amount || 0
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}
