import { styled } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'

export const StyledPaper = styled(Paper)(() => ({
  cursor: 'pointer',
  margin: '5px',
  display: 'flex',
  flexDirection: 'column',
}))

export const ResultWrapper = styled('div')(() => ({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}))

export const FacilityResult = styled('span')(() => ({
  display: 'flex',
  cursor: 'pointer',
  minHeight: '36px',
  alignItems: 'center',
  padding: '6px 16px',
  boxSizing: 'border-box',
}))
