import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { IconInfo } from 'components/atoms/icons'
import { InputsTwoColumns } from 'components/coaches-profile/profile.styles'
import TextFieldCounter from 'components/text-field-counter'

const useStyles = makeStyles(theme => ({
  personalContainer: {
    display: 'revert',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .text-input:not(.last-input)': {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    // prevent error messages from disrupting the height of inputs
    '& .text-input:not(.multiline)': {
      '& p': {
        position: 'absolute',
        top: theme.spacing(7),
      },
      '& .input-adornment p': {
        position: 'unset',
      },
    },
  },
}))

export const Personal = () => {
  const classes = useStyles()
  const { control, errors, register } = useFormContext()

  return (
    <Box className={classes.personalContainer}>
      <Controller
        name="bio"
        control={control}
        render={({ onChange, onBlur, value }, _) => (
          <TextFieldCounter
            characterLimit={5000}
            multiline
            rows={6}
            name="bio"
            label="Bio"
            type="text"
            variant="outlined"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={`multiline ${errors?.bio && ' error'}`}
            error={errors?.bio}
            helperText={errors?.bio?.message}
          />
        )}
      />
      <InputsTwoColumns>
        <TextField
          name="numberOfStudents"
          label="Number of Students"
          type="number"
          variant="standard"
          inputRef={register()}
          className={`text-input ${errors?.numberOfStudents && ' error'}`}
          error={!!errors.numberOfStudents}
          helperText={!!errors?.numberOfStudents}
          inputProps={{
            pattern: '/d+',
          }}
        />
        <TextField
          name="numberOfFollowers"
          label="Number of social media followers"
          type="number"
          variant="standard"
          inputRef={register()}
          className={`text-input ${errors?.numberOfFollowers && ' error'}`}
          error={!!errors.numberOfFollowers}
          helperText={!!errors?.numberOfFollowers}
          inputProps={{
            pattern: '/d+',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="Highest social media following on one account"
                  aria-label="Highest social media following on one account"
                >
                  <IconButton style={{ padding: 0 }}>
                    <IconInfo />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </InputsTwoColumns>
    </Box>
  )
}

export default Personal
