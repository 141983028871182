import { gql } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'

export const REGISTER_PGA_HOPE_GROUP_EVENT = gql`
  mutation RegisterPgaHopeGroupEvent(
    $id: ID!
    $params: PGAHopeRegisterGroupEventInput!
  ) {
    registerPgaHopeGroupEvent(id: $id, params: $params) {
      success
      message
    }
  }
`
export const CURRENT_USER_INFO = gql`
  ${GolferProfileFragment}
  query currentUserDemographicInfo {
    currentUser {
      attributes {
        ... on Contact {
          representatives {
            relationshipType
            student {
              id
              firstName
              lastName
              dateOfBirth
              isMinor
              golferProfile {
                ...GolferProfileAttributes
              }
              pgaHopeProfile {
                id
                accommodations
                additionalInfo
                golfCareerInterest
                golfExperience
                handedness
                hasOwnClubs
                militaryStatus
                referralSource
                serviceBranch
                serviceYearStart
                serviceYearEnd
                shirtSize
                zipCode
              }
              contact {
                email
                phone
              }
            }
          }
        }
      }
    }
  }
`
