import React, { Fragment, useState } from 'react'
import { Box, Typography, Avatar, Card } from '@material-ui/core'
import { useStyles } from './styles'
import { getTimePeriodWithTimezone } from 'utils/dateUtils'
import useIconAccessor from 'components/lesson-type-category/icons'

import ReservationDetailsDialog from './reservation-dialog'

const ReservationItem = ({ reservation }) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    startDateTime,
    endDateTime,
    timezone,
    privateSessionTitle,
    spaceName,
    academyName,
    student,
  } = reservation

  const timePeriod = getTimePeriodWithTimezone(
    startDateTime,
    endDateTime,
    timezone,
  )
  const reservationName = `${student.firstName} ${student.lastName}`
  const classes = useStyles()
  const iconAccessor = useIconAccessor({
    size: 32,
    color: 'rgba(0, 35, 75, 1)',
  })
  const { icon } = iconAccessor.SWING_EVALUATION

  return (
    <>
      <Card
        className={classes.root}
        variant="outlined"
        data-testid="session-list-item"
        onClick={() => setIsOpen(true)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar variant="circular" className={classes.iconWrapper}>
              {icon}
            </Avatar>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h6" className={classes.timePeriodText}>
                {timePeriod}
              </Typography>
              <Typography variant="body1" className={classes.bodyText}>
                {academyName}, {spaceName}
              </Typography>
              <Typography
                variant="body1"
                className={classes.bodyText}
              >{`${privateSessionTitle} with ${reservationName}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      <ReservationDetailsDialog
        isOpen={isOpen}
        reservation={reservation}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
export default ReservationItem
