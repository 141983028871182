import { Container, Box, Paper, Grid, Button, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  landingBox: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  landingContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}))

export function LandingButtonExternal({ children, location }) {
  const handleClick = () => {
    window.location = location
  }
  return (
    <Grid item xs={12}>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        style={{ height: 60 }}
        size="large"
        onClick={handleClick}
      >
        {children}
      </Button>
    </Grid>
  )
}

export function LandingContainerDivider() {
  return (
    <Box pt={4} pb={3}>
      <Divider />
    </Box>
  )
}

export default function LandingContainer({ children }) {
  const classes = useStyles()

  return (
    <Container maxWidth="sm" className={classes.landingBox}>
      <Paper className={classes.landingContent}>{children}</Paper>
    </Container>
  )
}
