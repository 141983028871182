import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  navigation: {
    color: theme.palette.primary.lightest,
    paddingLeft: '0px',
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
    '&:focus': {
      outline: 'none',
    },
  },
  locationDropdown: {
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    borderRadius: '.5rem',
  },
  outerContainer: {
    marginTop: '2.5rem',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  title: {
    color: theme.palette.primary.main,
    margin: '40px 0 28px',
  },
  eventsTitle: {
    color: theme.palette.primary.main,
    margin: '40px 0px 0px',
  },
  slider: {
    '& .keen-slider__slide > div': {
      scale: '1',
    },
  },
  customControl: {
    '& button': {
      width: '56px',
      height: '49px',
      background: '#FFFFFF',
      boxShadow: '0px 4.8px 60px rgba(0, 0, 0, 0.1)',
    },
  },
  paginationNumber: {
    fontFamily: 'Source Serif Pro',
    fontSize: '18px',
    fontWeight: '700',
    letterSpacing: '0.02em',
    textAlign: 'center',
    textDecoration: 'none',
    color: theme.palette.grey.dark,
    padding: theme.spacing(1),
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  },
  eventsWrapper: {
    paddingBottom: '48px',
    '& .slick-slide': {
      padding: '30px 0px',
      '& > div': {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
    },
    '& .slick-list': {
      margin: '0 auto',
      width: 'calc( 100% + 28px)',
      marginLeft: '-14px',
    },
    '& .slick-dots': {
      bottom: '18px',
    },
    '& .slick-dots li': {
      width: '40px',
      height: '40px',
      margin: '0',
    },
    '& .slick-active > a': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '& .slick-next': {
      display: 'none!important',
    },
    '& .slick-prev': {
      display: 'none!important',
    },
  },
  eventsWrapperLeft: {
    '& .slick-slide': {
      maxWidth: `calc(410px + ${theme.spacing(3)}px)`,
    },
    '& .slick-slide > div': {
      maxWidth: '410px',
    },
  },
}))
