import React, { lazy, Suspense, useState } from 'react'
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Layout from 'pages/junior-league-marketing/layout'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { JRL_MARKETING_SITE_URL } from 'env'

const EventSearch = lazy(() =>
  import('pages/junior-league-marketing/event-search'),
)
const EventDetails = lazy(() =>
  import('pages/junior-league-marketing/event-details'),
)
const EventLeagueLeaderboard = lazy(() =>
  import('pages/junior-league-marketing/event-league-leaderboard'),
)

const ChampionshipSeasonRegions = lazy(() =>
  import('pages/junior-league-marketing/championship-season-regions'),
)

const RegionLeaderboard = lazy(() =>
  import('pages/junior-league-marketing/region-leaderboard'),
)

const RegionChampionshipLeaderboard = lazy(() =>
  import('pages/junior-league-marketing/region-championship-leaderboard'),
)

const RegionalChampionships = lazy(() =>
  import('pages/junior-league-marketing/regional-championships'),
)

const NationalChampionship = lazy(() =>
  import('pages/junior-league-marketing/national-championship'),
)
const Sectionals = lazy(() =>
  import('pages/junior-league-marketing/sectionals'),
)

export default function JRLMarketingRoutes() {
  const { path } = useRouteMatch()
  const location = useLocation()
  const [title, setTitle] = useState('')

  return (
    <ThemeProvider theme={extendTheme(themes.juniorLeagueMarketing)}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Helmet>
          <title>{title && `${title} | `} PGA Jr. League</title>
          <meta name="description" content="PGA Jr. League" />
          <link rel="icon" href="/jrl-favicon.ico" />
          <link
            rel="canonical"
            href={`${JRL_MARKETING_SITE_URL}/${location.pathname}`}
          ></link>
        </Helmet>
        <Layout>
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route exact path={`${path}/search`}>
                <EventSearch />
              </Route>
              <Route exact path={`${path}/leagues/:leagueId`}>
                <EventLeagueLeaderboard />
              </Route>
              <Route exact path={`${path}/regions`}>
                <ChampionshipSeasonRegions />
              </Route>
              <Route exact path={`${path}/regionals`}>
                <RegionalChampionships />
              </Route>
              <Route
                exact
                path={`${path}/national-car-rental-pga-jr-league-championship`}
              >
                <NationalChampionship />
              </Route>
              <Route exact path={`${path}/section-qualifiers-championships`}>
                <Sectionals />
              </Route>
              <Route exact path={`${path}/regions/:regionId`}>
                <RegionLeaderboard />
              </Route>
              <Route exact path={`${path}/regional-scoring`}>
                <RegionChampionshipLeaderboard />
              </Route>
              <Route exact path={`${path}/:programId`}>
                <EventDetails setTitle={setTitle} />
              </Route>
            </Switch>
          </Suspense>
        </Layout>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
