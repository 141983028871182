import React from 'react'
import { useHistory } from 'react-router-dom'
import Dialog from 'components/dialog'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-between',
  },
})

const SaveDialog = ({ trigger, handleSubmit }) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Dialog trigger={trigger} maxWidth="sm">
      {({ closeDialog }) => {
        return (
          <>
            <DialogTitle>Save Changes</DialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                Would you like to save the changes made to your profile?
              </Typography>
            </DialogContent>
            <DialogActions classes={{ root: classes.root }}>
              <Button color="primary" onClick={closeDialog}>
                Cancel
              </Button>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '16px' }}
                  onClick={() => {
                    history.push('/pga-coach/facilities')
                  }}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSubmit()
                    history.push('/pga-coach/facilities')
                  }}
                >
                  Save
                </Button>
              </div>
            </DialogActions>
          </>
        )
      }}
    </Dialog>
  )
}

export default SaveDialog
