import { gql } from '@apollo/client'

const GolferProfileFragment = gql`
  fragment GolferProfileAttributes on GolferProfile {
    heightInInches
    frequencyOfPlay
    homeGolfCourse
    golfJourney
    hasOwnClubs
    hasBeenFitted
    handedness
    handicap
    confidentArea
    improvementArea
    pronouns
    gender
    genderSelfDescription
    physicalLimitations
    associatedWithCourse
  }
`
export default GolferProfileFragment
