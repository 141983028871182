import React from 'react'
import { roles, withProtectedRoute } from 'lib/auth'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Error from 'components/error'
import AppBar from '../register/app-bar'
import LoadingPage from './loading-page'
import StreamGroupEventMessagesPage from './stream'
import ContentUnavailable from 'components/content-unavailable'

const GET_GROUP_EVENT = gql`
  query ThingsToDoGroupEvent($slug: String!) {
    groupEvents(slug: $slug) {
      title
      slug
      startDateTime
      endDateTime
      timezone
      coachFacility {
        name
      }
      coach {
        id
        externalId
        pgaCoachEligible
        name
        coachProfile {
          displayName
          profilePhoto
          customSlug
        }
      }
      featuredImageUrl
      isCanceled
      registrationClosed
      registrationFull
      registrationCount
      maxAttendance
    }
  }
`

const EventMessages = () => {
  const { slug } = useParams()

  const { data, loading, error } = useQuery(GET_GROUP_EVENT, {
    variables: { slug },
  })

  const groupEvent = data?.groupEvents?.[0]

  const getPageContent = () => {
    if (loading) return <LoadingPage />
    if (error) return <Error error={error} />
    if (!groupEvent) return <Error statusCode={404} />
    if (!groupEvent.coach.pgaCoachEligible) return <ContentUnavailable />

    return <StreamGroupEventMessagesPage event={groupEvent} />
  }

  return (
    <Box bgcolor="grey.100" style={{ minHeight: '100vh' }}>
      <AppBar />
      {getPageContent()}
    </Box>
  )
}

export default withProtectedRoute(EventMessages, roles.CONSUMER)
