import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './group-event-registration-form.styles'
import {
  referralSourcesOptions,
  shirtSizeOptions,
  ownClubOptions,
} from './constants'
import { TextFieldWithError } from 'components/form-util'
import Box from '@material-ui/core/Box'
import { FormControl, InputLabel, Select } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { useAuth } from 'lib/auth'

const EventPreparation = () => {
  const { control, errors, register } = useFormContext()
  const classes = useStyles()
  const { user, isConsumer } = useAuth()
  const isAuthenticatedConsumer = user && isConsumer

  return (
    <>
      <Typography variant="h5" className={classes.formSectionHeader}>
        Event Preparation
      </Typography>
      <FormControl
        variant="outlined"
        className={classes.input}
        error={errors?.pgaHopeProfile?.shirtSize}
        required
      >
        <InputLabel id="pga-hope-shirt-size-label">Shirt Size</InputLabel>
        <Controller
          as={
            <Select labelId="pga-hope-shirt-size-label" label="Shirt Size">
              {shirtSizeOptions.map(option => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          }
          control={control}
          name="pgaHopeProfile.shirtSize"
        />
      </FormControl>
      <Typography>Do you plan to bring your own clubs?</Typography>
      <Box my={2}>
        <FormControl
          variant="outlined"
          className={classes.input}
          error={errors?.pgaHopeProfile?.hasOwnClubs}
          required
        >
          <InputLabel id="pga-hope-has-own-clubs-label">Golf Clubs</InputLabel>
          <Controller
            as={
              <Select label="Golf Clubs" labelId="pga-hope-has-own-clubs-label">
                {ownClubOptions.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="pgaHopeProfile.hasOwnClubs"
          />
        </FormControl>
      </Box>
      <Typography variant="body1" style={{ marginBottom: '16px' }}>
        Do you need any accommodations?
      </Typography>
      <TextFieldWithError
        multiline
        minRows={3}
        variant="outlined"
        name="pgaHopeProfile.accommodations"
        errors={errors?.pgaHopeProfile?.accommodations}
        inputRef={register}
        className={classes.input}
        placeholder="i.e., Wheelchair use, amputations, spinal cord injuries, TBI, hard of hearing, etc."
        InputProps={{
          style: {
            padding: '8px 12px',
          },
        }}
      />
      <Typography variant="body1" className={classes.input}>
        Is there any additional information that our staff should know prior to
        your arrival?
      </Typography>
      <TextFieldWithError
        multiline
        minRows={3}
        variant="outlined"
        name="pgaHopeProfile.additionalInfo"
        errors={errors?.pgaHopeProfile?.additionalInfo}
        inputRef={register}
        className={classes.input}
        InputProps={{
          style: {
            padding: '8px 12px',
          },
        }}
      />
      <Typography variant="body1" className={classes.input}>
        How did you hear about PGA HOPE?
      </Typography>
      <FormControl
        variant="outlined"
        className={classes.input}
        error={errors?.pgaHopeProfile?.referralSource}
        required
      >
        <InputLabel>Referral Source</InputLabel>
        <Controller
          as={
            <Select label="Referral Source">
              {referralSourcesOptions.map(option => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          }
          control={control}
          name="pgaHopeProfile.referralSource"
        />
        {!isAuthenticatedConsumer && (
          <Typography variant="body2" className={classes.proceedingText}>
            By proceeding, you agree to PGA of America’s{' '}
            <Link
              className={classes.link}
              href="https://www.pga.com/pga-of-america/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              className={classes.link}
              href="https://www.pga.com/pga-of-america/terms-of-service"
              target="_blank"
            >
              Terms of Service
            </Link>
            .
          </Typography>
        )}
      </FormControl>
    </>
  )
}

export default EventPreparation
