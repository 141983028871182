import { useMutation } from '@apollo/client'
import { handleHookResult } from 'lib/graphql'

export function createMutationHook(
  mutation,
  resultName,
  variableName = 'input',
) {
  return ({ onCompleted, onError } = {}) => {
    const [save, result] = useMutation(mutation, {
      onCompleted: onCompleted && onCompleted,
      onError: onError && onError,
    })

    const mapResult = result =>
      handleHookResult(result, resultName, response => response)

    function runMutation(input) {
      return save({ variables: { [variableName]: input } }).then(mapResult)
    }

    return [runMutation, mapResult(result)]
  }
}
