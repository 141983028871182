import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  messageIcon: {
    color: theme.palette.primary.main,
    background: 'rgba(0, 117, 202, 0.08)',
    padding: '24px',
    marginBottom: '8px',
    marginTop: '96px',
  },
  actionButton: {
    margin: '10px',
    [theme.breakpoints.only('xs')]: {
      margin: '20px 10px',
    },
  },
  heading: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  baseText: {
    color: 'rgba(0, 0, 0, 0.64)',
    marginTop: '12px',
    maxWidth: '720px',
  },
  mobileHiddenButton: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  mobileBottomButtons: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
    },
  },
}))

export default useStyles
