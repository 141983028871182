import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'components/dialog'
import ShareBox from 'components/share-box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.25rem',
    fontWeight: '700',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

const SocialShareDialog = ({
  trigger,
  shareUrl,
  shareTitle,
  dialogTitle,
  bookingType,
  showBookingUrl,
  showScheduleUrl,
  showProfileUrl,
  att,
}) => {
  const classes = useStyles()
  return (
    <Dialog trigger={trigger} maxWidth="sm" fullWidth showCloseButton>
      {() => (
        <>
          <DialogTitle
            disableTypography
            classes={{
              root: classes.title,
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <ShareBox
              url={shareUrl}
              title={shareTitle}
              bookingType={bookingType}
              showBookingUrl={showBookingUrl}
              showScheduleUrl={showScheduleUrl}
              showProfileUrl={showProfileUrl}
              att={att}
              shareId={bookingType?.id || null}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default SocialShareDialog
