import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import pluralize from 'lib/pluralize'
import LessonPackCard from './lesson-pack-card'
import { MenuItem, Select } from '@material-ui/core'
import NoLessonPacksCard from './no-lesson-packs-card'
import { useStyles } from './styles'

const LessonPacksDisplay = ({
  activeLessonPacks,
  usedOrExpiredLessonPacks,
}) => {
  const classes = useStyles()
  const years = [
    ...new Set(
      usedOrExpiredLessonPacks.map(lp =>
        lp.expiresAt ? new Date(lp.expiresAt).getFullYear() : 'No expiration',
      ),
    ),
  ]
  const [selectedYear, setSelectedYear] = useState(years[0])
  const filteredUsedOrExpiredLessonPacks = usedOrExpiredLessonPacks.filter(lp =>
    selectedYear === 'No expiration'
      ? !lp.expiresAt
      : new Date(lp.expiresAt).getFullYear() === selectedYear,
  )

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Typography variant="h5" gutterBottom>
            Active
          </Typography>
          <Typography color="textSecondary">
            {activeLessonPacks.length} lesson{' '}
            {pluralize(activeLessonPacks.length, 'pack')} available
          </Typography>
          {activeLessonPacks.length > 0 ? (
            <Box mt={2}>
              {activeLessonPacks.map(lp => (
                <LessonPackCard {...lp} key={lp.id} />
              ))}
            </Box>
          ) : (
            <NoLessonPacksCard text="Lesson packs that have been purchased will display here." />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Box display="flex">
            <Box>
              <Typography variant="h5" gutterBottom>
                Past
              </Typography>
              <Typography color="textSecondary">
                {usedOrExpiredLessonPacks.length} lesson{' '}
                {pluralize(usedOrExpiredLessonPacks.length, 'pack')} used or
                expired
              </Typography>
            </Box>
            {years.length > 0 && (
              <Select
                value={selectedYear}
                variant="outlined"
                style={{ marginLeft: 'auto' }}
                onChange={e => setSelectedYear(e.target.value)}
              >
                {years.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          {filteredUsedOrExpiredLessonPacks.length > 0 ? (
            <Box mt={2}>
              {filteredUsedOrExpiredLessonPacks.map(lp => (
                <LessonPackCard {...lp} key={lp.id} isUsedOrExpired />
              ))}
            </Box>
          ) : (
            <NoLessonPacksCard text="Lesson packs that have been used or expired will display here." />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default LessonPacksDisplay
