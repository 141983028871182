import React, { useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { DateTime } from 'luxon'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { Box, Grid, InputAdornment, Container } from '@material-ui/core'
import EventIcon from '@mui/icons-material/Event'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import { useStyles } from './styles'
import { toThreeLetterTimezone } from 'utils/timezones'
import {
  DesktopTimePicker,
  MobileDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'themes'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'

const LESSON_VALIDATION_QUERY = gql`
  query lessonValidation($lessonId: ID!, $timespans: [DateTimeRangeInput!]!) {
    lessonValidation(lessonId: $lessonId, timespans: $timespans) {
      isAvailable
      warnings
    }
  }
`

const UPDATE_SESSION = gql`
  mutation UpdateSession($id: ID!, $startDateTime: ISO8601DateTime!) {
    updateSession(id: $id, startDateTime: $startDateTime) {
      message
      success
      session {
        id
        startDateTime
      }
    }
  }
`

const ModifySessionDialog = ({ trigger, booking, onSuccess }) => {
  const { startDateTime, timezone } = booking
  const formattedTimezone = toThreeLetterTimezone(timezone)
  const classes = useStyles()
  const currentDateTime = DateTime.local()
  const [startDate, setStartDate] = useState(
    DateTime.fromISO(startDateTime).setZone(timezone),
  )
  const [startTime, setStartTime] = useState(
    DateTime.fromISO(startDateTime).setZone(timezone),
  )
  const [warnings, setWarnings] = useState([])
  const [inputError, setInputError] = useState(null)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const { refetch } = useQuery(LESSON_VALIDATION_QUERY, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const { enqueueSnackbar } = useSnackbar()

  const [updateSession] = useMutation(UPDATE_SESSION)

  const coachTheme = createTheme(themes.pgaCoach)

  const handleTimeChange = val => {
    if (val.invalid) return
    setStartTime(val)
    validateSession({ time: val })
  }

  const handleDateChange = val => {
    if (val.invalid) return
    setStartDate(val)

    // Update startTime to reflect new date
    const date = new Date(val)
    const day = date.getDate()
    const month = date.getMonth()

    const newStartTime = new Date(startTime)
    newStartTime.setDate(day)
    newStartTime.setMonth(month)

    setStartTime(DateTime.fromJSDate(newStartTime))
    validateSession({ date: val })
  }

  const validateSession = async ({ date = null, time = null }) => {
    const newDate = date || startDate
    const newTime = time || startTime

    const { data } = await refetch({
      lessonId: booking.id,
      timespans: [
        {
          from: newDate.set({
            hour: newTime.hour,
            minute: newTime.minute,
          }),
          to: newDate
            .set({
              hour: newTime.hour,
              minute: newTime.minute,
            })
            .plus({ minutes: 60 }),
        },
      ],
    })

    const warningMessages = data?.lessonValidation.warnings.filter(
      message => message !== 'start_date_time must be before end_date_time',
    )
    setWarnings(warningMessages)
  }

  return (
    <Dialog
      trigger={trigger}
      maxWidth="xs"
      showCloseButton
      fullScreen={isMobile}
    >
      {({ closeDialog }) => {
        const onModifyConfirm = async () => {
          const { data } = await updateSession({
            variables: {
              id: booking.id,
              startDateTime: startTime.toISO(),
            },
          })

          if (data.updateSession.success) {
            onSuccess(data.updateSession.session)
            enqueueSnackbar(data.updateSession.message, { variant: 'success' })
            closeDialog()
          } else {
            enqueueSnackbar(data.updateSession.message, {
              variant: 'error',
            })
          }
        }

        return (
          <LocalizationProvider dateAdapter={CustomLuxonAdapter} locale="en-us">
            <ThemeProvider theme={coachTheme}>
              <DialogTitle>Edit Session</DialogTitle>
              <DialogContent>
                {warnings?.length > 0 && (
                  <Container style={{ padding: 0 }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      className={classes.base}
                      alignItems="center"
                    >
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                      >
                        <Typography className={classes.body}>
                          This time may conflict with the following event(s)
                          <ul className={classes.ul}>
                            {warnings.map((warningMessage, index) => (
                              <li key={index}>
                                <Typography gutterBottom>
                                  <strong>{warningMessage}</strong>
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Typography>
                      </Box>
                    </Box>
                  </Container>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.subtitle}
                      gutterBottom
                      style={{ margin: '16px 0px' }}
                    >
                      Select the date and time to reschedule this session.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <MobileDatePicker
                        id="start-date"
                        value={startDate}
                        format="MMM dd, yyyy"
                        minDate={currentDateTime}
                        onChange={handleDateChange}
                        label="Date *"
                        dayOfWeekFormatter={day => `${day}`}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EventIcon />
                                </InputAdornment>
                              ),
                            },
                          },
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <DesktopTimePicker
                        id="start-time"
                        label={
                          formattedTimezone
                            ? `Time (${formattedTimezone}) *`
                            : 'Time *'
                        }
                        minutesStep={5}
                        value={startTime}
                        onChange={handleTimeChange}
                        onError={error => setInputError(error)}
                        sx={{ width: '100%' }}
                        slotProps={{
                          actionBar: {
                            actions: ['cancel', 'accept'],
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button color="primary" onClick={closeDialog}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!(startTime && startDate) || !!inputError}
                  onClick={onModifyConfirm}
                >
                  Save
                </Button>
              </DialogActions>
            </ThemeProvider>
          </LocalizationProvider>
        )
      }}
    </Dialog>
  )
}

export default ModifySessionDialog
