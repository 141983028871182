import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  MobileTimePicker,
  renderMultiSectionDigitalClockTimeView,
} from '@mui/x-date-pickers'
import { useTheme } from '@material-ui/core/styles'

const mobileTimePickerSlotProps = (validationErrors = false, theme, rest) => {
  const dataCy = rest['data-cy']
  return {
    textField: {
      error: validationErrors,
      InputProps: {
        'data-cy': dataCy,
        endAdornment: (
          <InputAdornment position="end" style={{ marginLeft: '0px' }}>
            <AccessTimeIcon />
          </InputAdornment>
        ),
      },
    },
    dialog: {
      sx: {
        '& .MuiButton-root': {
          color: theme.palette.primary.main,
        },
        'li.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
        },
      },
      disableEnforceFocus: true,
    },
  }
}

const PgaMobileTimePicker = props => {
  const { id, label, value, onAccept, validationErrors, ...rest } = props
  const theme = useTheme()
  return (
    <MobileTimePicker
      id={id}
      label={label}
      value={value}
      onAccept={onAccept}
      minutesStep={5}
      viewRenderers={{
        hours: renderMultiSectionDigitalClockTimeView,
        minutes: renderMultiSectionDigitalClockTimeView,
      }}
      slotProps={mobileTimePickerSlotProps(validationErrors, theme, rest)}
      {...rest}
    />
  )
}

export default PgaMobileTimePicker
