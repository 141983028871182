import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/styles'
import {
  useStyles,
  SectionHeader,
  RadioFormFields,
  RadioHelperText,
} from './intake-form.styles'
import { FormRadioGroup } from 'components/form-util'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import {
  frequencyOptions,
  proficiencyOptions,
  handicapOptions,
} from './constants'
import { handednessOptions } from 'utils/constants'
import propTypes from 'prop-types'

const GolfWrapper = styled(Grid)({
  direction: 'column',
})

const GolfInformation = () => {
  const { control, errors, register, watch } = useFormContext()
  const courseAssociatedWatch = watch('golfInformation.associatedWithCourse')
  const classes = useStyles()

  React.useEffect(() => {
    if (errors?.homeGolfCourse) {
      document
        .getElementById('golf-information-club-name')
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [errors?.homeGolfCourse])

  return (
    <GolfWrapper container>
      <SectionHeader variant="h6">Golf Information</SectionHeader>
      <RadioFormFields container>
        <Controller
          as={
            <FormRadioGroup error={errors?.frequency}>
              <Grid container>
                <Grid xs={12} item className={classes.radioButtons}>
                  <RadioHelperText variant="subtitle2">
                    How often do you play, practice or work with a coach?
                  </RadioHelperText>
                  <Grid container>
                    {frequencyOptions.map(option => (
                      <Grid xs={12} item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio color="primary" />}
                          id="frequencyOption"
                          label={
                            <span className={classes.radioLabel}>
                              {option.label}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </FormRadioGroup>
          }
          control={control}
          name="golfInformation.frequency"
        />
        <Controller
          as={
            <FormRadioGroup error={errors?.associatedWithCourse}>
              <Grid container>
                <Grid xs={12} item className={classes.radioButtons}>
                  <RadioHelperText variant="subtitle2">
                    Are you associated with a specific club or course?
                  </RadioHelperText>
                  <Grid container>
                    {[
                      {
                        value: 'true',
                        label: 'Yes',
                      },
                      {
                        value: 'false',
                        label: 'No',
                      },
                    ].map(option => (
                      <Grid xs={12} item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio color="primary" />}
                          id="courseAssociationOption"
                          label={
                            <span className={classes.radioLabel}>
                              {option.label}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </FormRadioGroup>
          }
          control={control}
          name="golfInformation.associatedWithCourse"
        />
        <Grid
          xs={12}
          item
          className={classes.radioButtons}
          hidden={
            courseAssociatedWatch === 'false' || courseAssociatedWatch === null
          }
          id="golf-information-club-name"
        >
          <TextField
            name="golfInformation.homeGolfCourse"
            inputRef={register}
            error={!!errors?.homeGolfCourse}
            helperText={errors?.homeGolfCourse?.message}
            label="Club or course name"
            fullWidth
            className={classes.homeCourse}
          />
        </Grid>
        <Controller
          as={
            <FormRadioGroup error={errors?.proficiency}>
              <Grid container>
                <Grid xs={12} item className={classes.radioButtons}>
                  <RadioHelperText variant="subtitle2">
                    Where are you in your golfer journey?
                  </RadioHelperText>
                  <Grid container direction="column">
                    {proficiencyOptions.map(option => (
                      <Grid xs={12} item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio color="primary" />}
                          id="golfJourneyOption"
                          label={
                            <div className={classes.proficiencyLabel}>
                              <span>{option.label}</span>:
                              <span className={classes.proficiencySupportLabel}>
                                {option.supportText}
                              </span>
                            </div>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </FormRadioGroup>
          }
          control={control}
          name="golfInformation.proficiency"
        />
        <Controller
          as={
            <FormRadioGroup error={errors?.ownClubs}>
              <Grid container>
                <Grid xs={12} item className={classes.radioButtons}>
                  <RadioHelperText variant="subtitle2">
                    Do you plan to bring your own clubs?
                  </RadioHelperText>
                  <Grid container>
                    {[
                      {
                        value: 'true',
                        label: 'Yes',
                      },
                      {
                        value: 'false',
                        label: 'No',
                      },
                    ].map(option => (
                      <Grid xs={12} item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio color="primary" />}
                          id="bringOwnClubsOption"
                          label={
                            <span className={classes.radioLabel}>
                              {option.label}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </FormRadioGroup>
          }
          control={control}
          name="golfInformation.ownClubs"
        />
        <Controller
          as={
            <FormRadioGroup error={errors?.handedness}>
              <Grid container>
                <Grid xs={12} item className={classes.radioButtons}>
                  <RadioHelperText variant="subtitle2">
                    What is your handedness?
                  </RadioHelperText>
                  <Grid container>
                    {handednessOptions.map(option => (
                      <Grid xs={12} item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          id="handednessOption"
                          control={<Radio color="primary" />}
                          label={
                            <span className={classes.radioLabel}>
                              {option.label}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </FormRadioGroup>
          }
          control={control}
          name="golfInformation.handedness"
        />
        <Controller
          as={
            <FormRadioGroup error={errors?.handicapRating}>
              <Grid container>
                <Grid xs={12} item className={classes.radioButtons}>
                  <RadioHelperText variant="subtitle2">
                    What is your handicap?
                  </RadioHelperText>
                  <Grid container>
                    {handicapOptions.map(option => (
                      <Grid xs={12} item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio color="primary" />}
                          id="handicapOption"
                          label={
                            <span className={classes.radioLabel}>
                              {option.label}
                            </span>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </FormRadioGroup>
          }
          control={control}
          name="golfInformation.handicapRating"
        />
      </RadioFormFields>
    </GolfWrapper>
  )
}

export default GolfInformation

GolfInformation.propTypes = {
  frequency: propTypes.string,
  associatedWithCourse: propTypes.string,
  homeGolfCourse: propTypes.string,
  proficiency: propTypes.string,
  ownClubs: propTypes.string,
  handedness: propTypes.string,
  handicapRating: propTypes.string,
}
