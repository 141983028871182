import { gql, useQuery } from '@apollo/client'
import GolferProfileFragment from 'fragments/student/golferProfile'

const GET_STUDENT = gql`
  ${GolferProfileFragment}
  query Student($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      dateOfBirth
      isMinor
      hasAccount
      contact {
        id
        firstName
        lastName
        email
        phoneNumber
        relationshipType
        externalId
      }
      medicalProfile {
        primaryDoctor
        primaryDoctorPhone
      }
      emergencyContact {
        firstName
        lastName
        phone
      }
      golferProfile {
        ...GolferProfileAttributes
      }
    }
  }
`

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($studentId: ID!, $inputs: UpdateStudentInput!) {
    updateStudent(studentId: $studentId, inputs: $inputs) {
      message
      success
      student {
        id
        firstName
        lastName
        contacts {
          id
          firstName
          lastName
          phoneNumber
          email
        }
      }
    }
  }
`

export const UPSERT_MEDICAL_PROFILE = gql`
  mutation UpsertMedicalProfile($studentId: ID!, $inputs: MedicalAttributes!) {
    upsertMedicalProfile(studentId: $studentId, input: $inputs) {
      id
    }
  }
`

export function useGetStudent(studentId) {
  return useQuery(GET_STUDENT, {
    variables: { id: studentId },
  })
}
