import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'
import Error from 'components/error'
import { useAuth } from 'lib/auth'
import { Grid, Divider } from '@material-ui/core'
import { LessonReviewQuery, GetStudentsQuery } from './query'
import SelectStudentSkeleton from './select-student-skeleton'
import SelectStudentLayout from './SelectStudentLayout'
import CreateAccountForBookingLayout from './CreateAccountForBookingLayout.js'
import { ContainerRoot } from '../components/styles'
import CoachLessonHeader from '../components/CoachLessonHeader'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import BackButton from 'components/back-button'

export const GetStudents = ({
  currentUrl,
  previousUrl,
  isCoach,
  user,
  lessonType,
  lessonTypeId,
}) => {
  const { data, loading, error } = useQuery(GetStudentsQuery)

  if (loading) return <SelectStudentSkeleton isCoach={isCoach} />
  if (error) return <Error error={error} />

  return (
    <SelectStudentLayout
      students={get(data, 'currentUser.students')}
      currentUrl={currentUrl}
      previousUrl={previousUrl}
      isCoach={isCoach}
      user={user}
      lessonTypeId={lessonTypeId}
      lessonType={lessonType}
      progressValue={50}
      stepText="Step 2 of 3"
    />
  )
}

const SelectStudent = ({ currentUrl, previousUrl, lessonTypeId }) => {
  const { isLoggedIn, isCoach, user } = useAuth()
  const { data, loading, error } = useQuery(LessonReviewQuery, {
    variables: {
      id: lessonTypeId,
    },
  })

  if (loading) return <SelectStudentSkeleton isCoach={isCoach} />
  if (error) return <Error error={error} />

  const lessonType = get(data, 'lessonTypes.0', {})
  const coach = lessonType?.coach

  return (
    <ContainerRoot>
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        <Grid container>
          <BackButton previousUrl={previousUrl} />
          {coach && <CoachLessonHeader lessonType={lessonType} coach={coach} />}
          <Grid item xs={12}>
            {isLoggedIn ? (
              <>
                <Grid item xs={12}>
                  <Divider style={{ margin: '1rem 0 2.25rem' }} />
                  <GetStudents
                    {...{
                      currentUrl,
                      previousUrl,
                      isCoach,
                      user,
                      lessonType,
                      lessonTypeId,
                    }}
                  />
                </Grid>
              </>
            ) : (
              <CreateAccountForBookingLayout
                previousUrl={previousUrl}
                theme={extendTheme(themes.offeringsRefresh)}
              />
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </ContainerRoot>
  )
}

export default SelectStudent

SelectStudent.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  previousUrl: PropTypes.string.isRequired,
  lessonTypeId: PropTypes.string.isRequired,
}
