import React from 'react'

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import SportsGolfOutlinedIcon from '@material-ui/icons/SportsGolfOutlined'
import GolfCourseOutlinedIcon from '@material-ui/icons/GolfCourseOutlined'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import Icon from '@mdi/react'
import { mdiHandshakeOutline, mdiGolfCart } from '@mdi/js'

import {
  ClubsIcon,
  IntroSessionIcon,
  OncourseIcon,
  PrivateSessionIcon,
  GolferIcon,
} from 'components/icons/refreshed-icons'

export const mapCategories = {
  INTRO: {
    text: 'Intro',
    icon: <Icon path={mdiHandshakeOutline} color="#00234b" size={1.25} />,
    suggestedName: 'Intro Session',
  },
  PRIVATE: {
    text: 'Private',
    icon: <PersonOutlineOutlinedIcon color="primary" fontSize="large" />,
    suggestedName: 'Private Instruction',
  },
  ON_COURSE: {
    text: 'On Course',
    icon: <Icon path={mdiGolfCart} size={1.25} color="#00234b" />,
    suggestedName: 'On Course Playing Lesson',
  },
  SWING_EVALUATION: {
    text: 'Swing Evaluation',
    icon: <SportsGolfOutlinedIcon color="primary" fontSize="large" />,
    suggestedName: 'Swing Evaluation',
  },
  CLUB_FITTING: {
    text: 'Club Fitting',
    icon: <GolfCourseOutlinedIcon color="primary" fontSize="large" />,
    suggestedName: 'Club Fitting',
  },
  OTHER: {
    text: 'Other',
    icon: <AddCircleOutlineOutlinedIcon fontSize="large" color="primary" />,
    suggestedName: '',
  },
}

export const refreshedCategories = {
  INTRO: {
    text: 'Intro',
    icon: <IntroSessionIcon />,
    suggestedName: 'Intro Session',
    description:
      'Meet with a PGA Coach to see the facilities, assess your skill level and define your goals. Walk away with a program tailored to you.',
  },
  PRIVATE: {
    text: 'Private',
    icon: <PrivateSessionIcon />,
    suggestedName: 'Private Instruction',
    description:
      'Get 1:1 instruction with a PGA Coach to help in any facet of your game.',
  },
  ON_COURSE: {
    text: 'On Course',
    icon: <OncourseIcon />,
    suggestedName: 'On Course Playing Lesson',
    description:
      'Play some holes with a PGA Coach. Get help with on course strategy, club selection and real-time optomizations.',
  },
  SWING_EVALUATION: {
    text: 'Swing Evaluation',
    icon: <GolferIcon />,
    suggestedName: 'Swing Evaluation',
    description:
      "Can't shake a slice or a hook? Want to fine-tune your swing? An evaluation will help you make the right adjustments based on your goals.",
  },
  CLUB_FITTING: {
    text: 'Club Fitting',
    icon: <ClubsIcon />,
    suggestedName: 'Club Fitting',
    description:
      'Make your clubs work for you. Improve your game by getting fitted with a specialist.',
  },
  OTHER: {
    text: 'Other',
    icon: <AddCircleOutlineOutlinedIcon fontSize="large" color="secondary" />,
    suggestedName: '',
    description: 'This is a custom booking type.',
  },
}
