import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}))

export default useStyles
