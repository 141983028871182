import React from 'react'
import { currency } from 'lib/utils/string'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useStyles } from './styles'

const TransactionSummary = ({
  title,
  priceInCents,
  salesTaxInCents,
  cardBrand,
  lastDigits,
}) => {
  const priceInDollars = priceInCents ? priceInCents / 100 : null
  const salesTaxInDollars = salesTaxInCents ? salesTaxInCents / 100 : null
  const totalInCents = salesTaxInCents
    ? priceInCents + salesTaxInCents
    : priceInCents
  const total = totalInCents / 100
  const classes = useStyles()

  return (
    <>
      <Typography variant="h5" style={{ marginTop: '36px' }}>
        Transaction summary
      </Typography>
      <Box mb={1} mt={2} className={classes.infoRow}>
        <Typography variant="body1" className={classes.lighterText}>
          {title}
        </Typography>
        <Typography variant="body1" className={`${classes.lighterText}`}>
          {currency(priceInDollars)}
        </Typography>
      </Box>
      {salesTaxInDollars && (
        <Box my={1} className={classes.infoRow}>
          <Typography variant="body1" className={classes.lighterText}>
            Sales Tax
          </Typography>
          <Typography variant="body1" className={`${classes.lighterText}`}>
            {currency(salesTaxInDollars)}
          </Typography>
        </Box>
      )}
      <Box mb={1} className={classes.infoRow}>
        <Typography variant="body1" className={classes.fontBolded}>
          Total
        </Typography>
        <Typography variant="body1" className={`${classes.fontBolded}`}>
          {currency(total)}
        </Typography>
      </Box>
      <Box mb={3} className={classes.infoRow}>
        <Typography variant="body1">Payment information</Typography>
        <Typography variant="body1">
          Paid with {cardBrand} card ************{lastDigits}
        </Typography>
      </Box>
      <Divider className={classes.dividerStyles} />
    </>
  )
}

export default TransactionSummary
