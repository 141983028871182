const facilityName = 'PGA Coaching Center at PGA Frisco'
const programs = [
  {
    id: '1',
    title: 'Try Golf',
    description:
      'A Saturday morning class designed to introduce people to the game of golf. If you’ve never played the game before, here’s your opportunity to ‘Try Golf.’ No clubs are necessary. Come and learn a game for a lifetime. Adults only.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    timeDuration: '60 Minutes',
    priceInCents: 3500,
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/pUPZ4fJXANr0fOBKbf0A2/46688f40580c9b1f93b7dfeb8d0bd5b8/Try_golf.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    showOnProgramsPage: true,
  },
  {
    id: '2',
    title: 'Your Golf Journey Begins',
    description:
      'A 5-week program designed to take a beginning golfer from the start to playing the game of golf. You will learn and develop the skills necessary to swing, chip, putt and play the game. Along the way the ‘in’s and out’s of golf etiquette and basic rules of the game will be discussed. Ultimately you’ll graduate from the class playing the game of golf.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    timeDuration: '90 Minutes (5 sessions)',
    priceInCents: 29000,
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/SMvbdJiC9BMcjEEjvm6Fm/f9e4226c7bd48ba04bdfe16193f57f35/Your_golf_journey_begins.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
      {
        id: '2',
      },
      {
        id: '3',
      },
      {
        id: '4',
      },
      {
        id: '5',
      },
    ],
    sessionsTitle: '5-Week Program',
    showOnProgramsPage: true,
  },
  {
    id: '3',
    title: '2 Hour Playing Lesson',
    description:
      'Take the opportunity to maximize your golf skills and learn to play the game of golf better with an on-course playing session. Coaches will guide you through the process of a correct pre-shot routine and train you on how to create a strategy for every shot.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/1L56hSppOyPw2ntnhyVJu6/815b49ee875171d8b37746bdf2ed18f8/9_hole_playing_lesson.JPG?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    priceInCents: 45000,
    showOnProgramsPage: true,
  },
  {
    id: '4',
    title: 'Mid-Day Mom’s Clinic Series',
    description:
      "Whether you're a Mom or not, come join the Coaching Staff to improve your game. This four session program is designed to train your fullswing and all facets of your short game. Our climate controlled, state of the art Coaching Center is the perfect venue to create a plan for your improvement.",
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/266ZOnbZEiJ2b0QPIDkClm/b00b43928573bbe2f7bd4bfb64adeb8b/Morning_Moms_clinic.JPG?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
      {
        id: '2',
      },
      {
        id: '3',
      },
      {
        id: '4',
      },
    ],
    timeDuration: 'Four 90-minute weekly classes',
    priceInCents: 225_00,
    showOnProgramsPage: true,
  },
  {
    id: '5',
    title: 'Women’s Boot Camp',
    description:
      'A day of practice, play and social interaction. Join the Coaching Center’s Lead Coaches for a fun day on the practice grounds and Fields Ranch West Course. Practice in the morning, lunch in Ryder Cup Grill and then 9 holes of fun on the West Course of Fields Ranch.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/7mWqSJlwzxzDdtCSk6qa4h/bc1da02dfb6448d63f59dcd844bf450f/Womens_boot_camp.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '2 Hours',
    priceInCents: 37500,
    showOnProgramsPage: true,
  },
  {
    id: '6',
    title: 'Shape Your Shot',
    description:
      'A class for 10 handicap golfers or less who want to improve their ability to shape their shots on demand.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/OHr9FPmlRPamTDvI0Hjm0/80742bc0d21edf958a6b4d1470530932/Shape_up_your_shot.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '90 Minutes',
    priceInCents: 9000,
    showOnProgramsPage: true,
  },
  {
    id: '7',
    title: 'Play in the Wind',
    description:
      'This clinic is a big favorite with the wind we experience at PGA Frisco. Understand and put to use the important factors of playing in the wind. Learn the characteristics needed to flight the ball on windy days.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/6w6aaxajxQzuxlkqYrYUBL/fad2b1430c8e9bfda4447aa129a64554/Play_in_the_wind.JPG?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '90 Minutes',
    priceInCents: 9000,
    showOnProgramsPage: true,
  },
  {
    id: '8',
    title: 'Short Game Assessment',
    description:
      'Take the Coaching Center’s Short Game Assessment to identify your strengths and weaknesses inside 60 yards of the green. An 18 hole score equivalent to a round of golf is created along with a Lead Coaches assessment to improve your weaknesses.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/7aa4gjvKFvZDj1rubQyMwy/cebe21c598197b76232efe311496c115/short_game_assessment.JPG?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '90 Minutes',
    priceInCents: 9000,
    showOnProgramsPage: true,
  },
  {
    id: '9',
    title: 'Full Swing Training Program',
    description:
      'Enjoy 2 hours of golf training at the PGA Coaching Center with one of our Lead Coaches. Come away with a recipe for improvement with your fullswing.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/3emJqc6pWviK9jNV4VeBNs/591b6ba2606db4321a40eedc70471b58/Full_swing_training_program.JPG?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '120 Minutes',
    priceInCents: 15000,
    showOnProgramsPage: true,
  },
  {
    id: '10',
    title: 'Green Reading Clinic',
    description:
      'Learn to unlock your senses and improve your skill to read your putts more accurately on the course. This could be your missing link to lower scores.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/5D2VuLYJ9JB1k0Zq35Ie47/5f529cd8b82671b928ac9698f82cbd66/Green_Reading_clinic.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '90 Minutes',
    priceInCents: 9000,
    showOnProgramsPage: true,
  },
  {
    id: '11',
    title: 'Warm Up with a Professional',
    description:
      'A unique opportunity to have a PGA Professional alongside you as you warm up for a round on the Fields Ranch East or West Golf Course. One of our PGA Coaching Center Lead Coaches will offer light instruction or guidance necessary for you to be well prepared for your round of golf.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/4RSejaKyBoHDMokLFVkHNO/fbfd6c5cfbba7e3489c5bd9f75f98f30/Warm_up_with_the_professional.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '90 Minutes',
    priceInCents: 6000,
    priceDetails: ', minimum of 8 students',
    showOnProgramsPage: true,
  },
  {
    id: '12',
    title: 'The Swing Mini Pro-Am',
    description:
      'Treat your group to a competitive or casual atmosphere playing alongside our PGA Coaching Center Professional Staff. A member of our team will join each group in a Mini Pro-Am style event on The Swing, our 10 lighted Short Course.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/6mMAtHotgGJ8Joo4hkrchl/027ccc0e641e0f7956922d2200207fd9/swing_mini_pro_am.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: '90 Minutes',
    priceInCents: 9000,
    showOnProgramsPage: true,
  },
  {
    id: '13',
    title: 'One Day Golf Retreat',
    description:
      'Spend a day improving your golf game with one of our PGA Coaching Center Lead Coaches. Go through evaluations with your fullswing and short game along with a 9 hole playing lesson.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/39iVdUImIUjPMGFQ7vfkDZ/2303a4c0d4b75b9e73649ba1673d38c3/one_day_golf_retreat.jpg?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: 'One-Day Clinic',
    priceInCents: 97500,
    showOnProgramsPage: true,
  },
  {
    id: '14',
    title: 'Two Day Golf Retreat',
    description:
      'Enjoy two full days of improvement with your entire golf game. Day One consists of evaluations and implementation of your improvement program. Day Two allows for practice with your Lead Coach and then re-evaluate to measure your improvement.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/2M7df8ItoHA4T4UUlqxgij/fc389d9273320a58d9216c4bcf0546a3/two_day_golf_retreat.JPG?fl=progressive&q=80&h=300',
    sessions: [
      {
        id: '1',
      },
    ],
    timeDuration: 'Two-Day Clinic',
    priceInCents: 195000,
    showOnProgramsPage: true,
  },
  {
    id: '15',
    category: 'PGA Jr. League',
    title: 'PGA Jr League',
    description:
      'Embark on an 8-week journey that includes weekly practices and two tournaments. Our program forms multiple teams based on age/skill. Each team is coached by PGA Professionals at the cutting-edge PGA Coaching Center. Elevate your game, refine fundamentals, and gain invaluable tournament experience.',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/41T7xKDZdl8Be69I0kEKz4/0c2245db6997372de52c456b3942eb4b/jrL-team.jpg?fl=progressive&q=80&h=300',
    timeDuration: '8-week program with weekly practices, 2 tournaments',
    priceInCents: 74900,
    sessionsTitle: '8-Week Program',
    cardLogo: '/images/jr-league-logo.svg',
    showOnProgramsPage: false,
  },
  {
    id: '16',
    category: 'PGA Jr. League',
    title: 'Weekly Jr Clinic',
    description:
      'Join us for an exciting lesson in building the fundamentals of a successful golf game. Designed for ages 14-17, this class offers hands-on learning with up to 8 students. Dive into one hour of skill-building drills and set your young golfer on the path to success!',
    coachFacility: {
      name: facilityName,
      city: 'Frisco',
      state: 'TX',
    },
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/41T7xKDZdl8Be69I0kEKz4/0c2245db6997372de52c456b3942eb4b/jrL-team.jpg?fl=progressive&q=80&h=300',
    timeDuration: '1 Hour',
    priceInCents: 3500,
    sessions: [
      {
        id: '1',
      },
    ],
    cardLogo: '/images/jr-league-logo.svg',
    showOnProgramsPage: false,
  },
]

export default programs
