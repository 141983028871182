import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import uniqueId from 'lodash/uniqueId'
import MaskedInputContainer from './masked-input-container'

function PhoneMaskedField({ inputRef, ...props }) {
  return (
    <MaskedInput
      {...props}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      type="tel"
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  )
}

export default function Phone({
  id,
  value,
  onChange,
  label,
  name,
  inputRef,
  required,
  error,
  helperText,
  autoComplete,
  className,
  disabled = false,
  defaultValue,
}) {
  const [uid] = useState(() => uniqueId('phone-'))
  const actualId = id || uid
  return (
    <MaskedInputContainer
      {...{
        id: actualId,
        inputComponent: PhoneMaskedField,
        defaultValue,
        value,
        onChange,
        label,
        name,
        inputRef,
        required,
        error,
        helperText,
        autoComplete,
        className,
        disabled,
      }}
    />
  )
}
