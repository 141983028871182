import { ContentSection, ListText } from './content-box'
import { formatNameForDisplay } from 'lib/text-display'
import { List, ListItem, ListItemAvatar, Box } from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { useSnackbar } from 'notistack'
import { useSnackbarSuccess } from 'lib/snackbar'
import { useStyles } from '../styles'

export default function DisplayContact({ isMinor, contact }) {
  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const classes = useStyles()

  if (!contact) {
    return (
      <ContentSection title="Contact Information">
        <List className={classes.informationContainer}>
          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <LocalPhoneOutlinedIcon className={classes.icon} />
            </ListItemAvatar>
            <ListText text="N/A" />
          </ListItem>

          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <EmailOutlinedIcon className={classes.icon} />
            </ListItemAvatar>
            <ListText text="N/A" />
          </ListItem>
        </List>
      </ContentSection>
    )
  }

  const { firstName, lastName, phoneNumber, email, relationshipType } = contact

  const contactName = formatNameForDisplay(`${firstName} ${lastName}`)

  const copyEmailToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(email)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  const copyPhoneToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(phoneNumber)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  if (isMinor) {
    return (
      <ContentSection title="Guardian Information">
        <List className={classes.informationContainer}>
          <Box className={classes.informationColumn}>
            <ListItem className={classes.informationItem}>
              <ListItemAvatar className={classes.iconWrapper}>
                <PersonOutlineIcon className={classes.icon} />
              </ListItemAvatar>
              <ListText text={contactName} />
            </ListItem>

            <ListItem className={classes.informationItem}>
              <ListItemAvatar className={classes.iconWrapper}>
                <EmailOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              {email ? (
                <>
                  <a
                    href={`mailto:${email}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListText text={email} />
                  </a>
                  <ListItemAvatar className={classes.iconWrapper}>
                    <FileCopyOutlinedIcon
                      className={classes.copyIcon}
                      onClick={copyEmailToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListText text="N/A" />
              )}
            </ListItem>
          </Box>

          <Box className={classes.informationColumn}>
            <ListItem className={classes.informationItem}>
              <ListItemAvatar className={classes.iconWrapper}>
                <LocalPhoneOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              {phoneNumber ? (
                <>
                  <a
                    href={`tel:${phoneNumber}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListText text={phoneNumber} />
                  </a>
                  <ListItemAvatar className={classes.iconWrapper}>
                    <FileCopyOutlinedIcon
                      className={classes.copyIcon}
                      onClick={copyPhoneToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListText text="N/A" />
              )}
            </ListItem>

            <ListItem className={classes.informationItem}>
              <ListItemAvatar className={classes.iconWrapper}>
                <SupervisorAccountOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              <ListText
                text={formatNameForDisplay(relationshipType) || 'N/A'}
              />
            </ListItem>
          </Box>
        </List>
      </ContentSection>
    )
  } else {
    return (
      <ContentSection title="Contact Information">
        <List className={classes.informationContainer}>
          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <LocalPhoneOutlinedIcon className={classes.icon} />
            </ListItemAvatar>
            {phoneNumber ? (
              <>
                <a
                  href={`tel:${phoneNumber}`}
                  target="_top"
                  className={classes.linkText}
                >
                  <ListText text={phoneNumber} />
                </a>
                <ListItemAvatar className={classes.iconWrapper}>
                  <FileCopyOutlinedIcon
                    className={classes.copyIcon}
                    onClick={copyPhoneToClipboard}
                  />
                </ListItemAvatar>
              </>
            ) : (
              <ListText text="N/A" />
            )}
          </ListItem>

          <ListItem className={classes.informationItem}>
            <ListItemAvatar className={classes.iconWrapper}>
              <EmailOutlinedIcon className={classes.icon} />
            </ListItemAvatar>
            {email ? (
              <>
                <a
                  href={`mailto:${email}`}
                  target="_top"
                  className={classes.linkText}
                >
                  <ListText text={email} />
                </a>
                <ListItemAvatar className={classes.iconWrapper}>
                  <FileCopyOutlinedIcon
                    className={classes.copyIcon}
                    onClick={copyEmailToClipboard}
                  />
                </ListItemAvatar>
              </>
            ) : (
              <ListText text="N/A" />
            )}
          </ListItem>
        </List>
      </ContentSection>
    )
  }
}
