import PropTypes from 'prop-types'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import {
  getLessonDurationTextWithTimezone,
  toFullTextDayDateWithTimezone,
} from 'utils/dateUtils'
import { durationText } from 'utils/durationUtils'

const BookingDateOrDuration = ({
  durationInMinutes,
  timezone,
  classes,
  startTimestamp,
}) => {
  const location = useLocation()
  const { startDateTime } = qs.parse(location.search)
  const startDateTimeFromTimestamp = startDateTime || startTimestamp

  const formatLessonTime = () =>
    `${toFullTextDayDateWithTimezone(
      startDateTimeFromTimestamp,
      timezone,
    )} ${getLessonDurationTextWithTimezone(
      startDateTimeFromTimestamp,
      durationInMinutes,
      timezone,
    )}`

  return startDateTimeFromTimestamp ? (
    <ListItem disableGutters={true}>
      <ListItemIcon>
        <CalendarTodayOutlinedIcon className={classes.icons} />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.attributes }}
        primary={formatLessonTime()}
      />
    </ListItem>
  ) : (
    <ListItem disableGutters={true}>
      <ListItemIcon>
        <TimerIcon className={classes.icons} />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.attributes }}
        primary={durationText(durationInMinutes)}
      />
    </ListItem>
  )
}
BookingDateOrDuration.propTypes = {
  durationInMinutes: PropTypes.number.isRequired,
  timezone: PropTypes.string,
  classes: PropTypes.object.isRequired,
}
export default BookingDateOrDuration
