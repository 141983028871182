import React, { useContext } from 'react'

import FeatureFlagContext from './FeatureFlagContext'

// React hook for pulling feature flags into a function component
const useFeatureFlags = (flagKeys = []) => {
  const featureFlags = useContext(FeatureFlagContext)

  return flagKeys.reduce((agg, key) => {
    if (featureFlags[key] === undefined) {
      logError(key)
    }
    return [...agg, featureFlags[key]]
  }, [])
}

const ShowFeatureWithFlags = props => {
  const [canUse] = useFeatureFlags([props.flag])
  const children = canUse ? props.children : null

  return <>{children}</>
}

const logError = key => {
  // Send Error to Raygun
  window.rg4js &&
    window.rg4js('send', {
      error: `No such flag exists for key: ${key}`,
      tags: ['useFeatureFlags'],
    })
}

export { useFeatureFlags, ShowFeatureWithFlags }
