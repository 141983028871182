import { gql } from '@apollo/client'

export const GetStudentsQuery = gql`
  query GetStudentsQuery {
    currentUser {
      attributes {
        ... on Contact {
          lessonPacks {
            id
            totalCredits
            remainingCredits
            expiresAt
            lessonType {
              id
              title
              locations {
                id
              }
              coach {
                id
                bookingUrl
              }
            }
          }
        }
      }
      students {
        id
        firstName
        lastName
        isMinor
        hasAccount
        representatives {
          relationshipType
          contact {
            id
            externalId
          }
        }
      }
    }
  }
`

export const LessonReviewQuery = gql`
  query LessonReviewQuery($id: ID!) {
    lessonTypes(id: $id) {
      id
      category
      title
      durationInMinutes
      requiresAccessCode
      description
      priceInCents
      facilities {
        id
        name
        city
        state
        timezone
      }
      coach {
        id
        name
        bookingUrl
        coachProfile {
          title
          profilePhoto
        }
      }
      acceptsOnlinePayments
      lessonPacks {
        quantity
        priceInCents
      }
    }
  }
`
