import React, { useEffect, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { useIsMobile } from 'lib/hooks'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { useAuth } from 'lib/auth'
import { useUnhideChannels } from 'lib/stream-messaging/hooks'
import Dialog, { DialogActions } from 'components/dialog'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useChatContext } from 'stream-chat-react'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    maxHeight: '400px',
  },
  actionButton: {
    paddingLeft: '1.75rem',
    paddingRight: '1.75rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
}))

const HiddenMessages = ({ trigger, hiddenChannels }) => {
  const fullScreen = useIsMobile()
  const classes = useStyles()
  const unhideChannels = useUnhideChannels()
  const { setActiveChannel } = useChatContext()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedChannelIds, setSelectedChannelIds] = useState([])
  const { user } = useAuth()
  const { externalId } = user

  useEffect(() => {
    setSelectedChannelIds([])
  }, [hiddenChannels])

  const toggleChannelSelection = channel => {
    const currentIndex = selectedChannelIds.indexOf(channel.id)
    const newSelection = [...selectedChannelIds]

    if (currentIndex === -1) {
      newSelection.push(channel.id)
    } else {
      newSelection.splice(currentIndex, 1)
    }

    setSelectedChannelIds(newSelection)
  }

  const isChannelSelected = channel => selectedChannelIds.includes(channel.id)

  const allChannelsSelected =
    selectedChannelIds.length === hiddenChannels.length

  const toggleSelectAll = () => {
    if (!allChannelsSelected) {
      setSelectedChannelIds(hiddenChannels.map(channel => channel.id))
    } else {
      setSelectedChannelIds([])
    }
  }

  const getMembersInfo = members => {
    const memberNames = Object.values(members)
      .filter(member => member.user.id !== externalId)
      .map(member => member.user.name)

    return memberNames.join(', ')
  }

  const getLastMessage = messages => {
    if (!messages || messages.length === 0) return 'Nothing yet...'
    const lastMessage = messages[messages.length - 1]
    if (lastMessage.attachments.length > 0) return 'Attachment...'
    return lastMessage?.text || ''
  }

  return (
    <Dialog trigger={trigger} fullScreen={fullScreen} fullWidth>
      {({ closeDialog }) => {
        const unhideChannel = async () => {
          const channel = await unhideChannels(selectedChannelIds)
          if (channel) {
            setActiveChannel(channel)
            closeDialog && closeDialog()
          } else {
            enqueueSnackbar(
              'Unable to unhide conversation. Please try again later.',
              {
                variant: 'error',
              },
            )
          }
        }
        return (
          <>
            <DialogTitle disableTypography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Hidden Messages</Typography>
                <IconButton onClick={closeDialog}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              <ListItem>
                <Checkbox
                  edge="start"
                  color="primary"
                  onChange={toggleSelectAll}
                  checked={allChannelsSelected}
                  inputProps={{ 'aria-label': 'Select All' }}
                />
                <Box pl={2}>
                  <ListItemText
                    primary={
                      selectedChannelIds.length > 0
                        ? `${selectedChannelIds.length} selected`
                        : 'Contacts'
                    }
                  />
                </Box>
              </ListItem>
              <Divider />
              <Box className={classes.dialogContent}>
                <List>
                  {hiddenChannels.map(channel => {
                    const key = channel.id
                    return (
                      <ListItem
                        key={key}
                        button
                        onClick={() => toggleChannelSelection(channel)}
                      >
                        <Checkbox
                          edge="start"
                          color="primary"
                          onChange={() => toggleChannelSelection(channel)}
                          checked={isChannelSelected(channel)}
                          inputProps={{ 'aria-labelledby': key }}
                        />
                        <Box pl={2}>
                          <ListItemText
                            id={key}
                            primary={getMembersInfo(channel.state.members)}
                            secondary={getLastMessage(channel.state.messages)}
                          />
                        </Box>
                      </ListItem>
                    )
                  })}
                </List>
              </Box>
            </DialogContent>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <DialogActions>
                <Button
                  autoFocus={hiddenChannels.length === 0}
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.actionButton}
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={unhideChannel}
                  disabled={selectedChannelIds.length === 0}
                >
                  Unhide
                </Button>
              </DialogActions>
            </Box>
          </>
        )
      }}
    </Dialog>
  )
}

export default HiddenMessages
