const sortByFullName = (a, b, dir) => {
  a = a.props.student.fullName.toLowerCase().replace(/\s/g, '')
  b = b.props.student.fullName.toLowerCase().replace(/\s/g, '')

  if (a > b) return dir === 'desc' ? 1 : -1
  if (a < b) return dir === 'desc' ? -1 : 1
  return 0
}

const sortByCoach = (a, b, dir) => {
  const coach1 = a.props.student.coaches[0]?.name || ''
  const coach2 = b.props.student.coaches[0]?.name || ''
  a = coach1.toLowerCase().replace(/\s/g, '')
  b = coach2.toLowerCase().replace(/\s/g, '')

  if (a > b) return dir === 'desc' ? 1 : -1
  if (a < b) return dir === 'desc' ? -1 : 1
  return 0
}
const sortByUpcoming = (a, b, dir) => {
  a = a.props.student.upcomingSession.startDateTime
  b = b.props.student.upcomingSession.startDateTime

  if (a === null) return 0
  if (b === null) return -1
  if (a < b) return dir === 'desc' ? -1 : 1
  if (a === b) return 0
  if (a > b) return dir === 'desc' ? 1 : -1
}

export { sortByUpcoming, sortByFullName, sortByCoach }
