import { gql, useQuery } from '@apollo/client'

import React from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Disclaimer from 'components/disclaimer'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import ButtonCta from 'components/atoms/button-cta'
import { PageHeader } from 'components/page-header'
import RouterLinkButton from 'components/router-link-button'
import Error from 'components/error'
import EmptyPageState from 'components/empty-page-state'
import Link from '@material-ui/core/Link'

import FacilityListItem from './facility-list-item'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import { useIsMobile } from 'lib/hooks'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'underline',
  },
}))

const Facilities = ({ facilities, hasCoachProfile, refetch }) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useIsMobile()
  return !isEmpty(facilities) ? (
    <>
      {!hasCoachProfile && (
        <Container maxWidth="md">
          <Box mt={4}>
            <Disclaimer
              icon={<PersonOutlineOutlinedIcon />}
              description={
                <>
                  <Typography variant="subtitle1">
                    Create Your Coach Profile
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Once you create a profile, you will be able to manage how
                    this facility is displayed. You will also be able to set it
                    as your primary facility to be used for location-based
                    searches.
                  </Typography>
                </>
              }
              action={
                <ButtonCta
                  variant={isMobile ? 'text' : 'outlined'}
                  arrow={isMobile}
                  color="primary"
                  href={'/pga-coach/profile/new'}
                >
                  Create Profile
                </ButtonCta>
              }
            />
          </Box>
        </Container>
      )}
      <Box mb={3}>
        <Typography variant="h6">Facilities</Typography>
        {hasCoachProfile && (
          <Typography variant="body2" color="textSecondary" gutterBottom>
            View and manage the facilities that are showcased on your profile.
            Your primary facility and its address is used for location-based
            searches on pga.com.{' '}
            <Link
              href="https://support.pga.com/en/articles/5575003-manage-your-facilities"
              target="_blank"
              className={classes.link}
            >
              Learn more
            </Link>{' '}
            about managing your facilities.
          </Typography>
        )}
      </Box>
      {facilities.map((facility, index) => {
        const hasAvailabilities = !isEmpty(facility.availabilities)

        return (
          <FacilityListItem
            key={facility?.id}
            facility={facility}
            index={index}
            hasAvailabilities={hasAvailabilities}
            hasCoachProfile={hasCoachProfile}
            refetch={refetch}
          />
        )
      })}
    </>
  ) : (
    <EmptyPageState
      IconComp={() => <RoomOutlinedIcon fontSize="large" />}
      title="No Facilities"
      caption="Get started by adding a facility that you currently coach from."
      buttonText="Add facility"
      actionFn={() => history.push('/pga-coach/settings/facilities/new')}
      removeButton={isMobile}
    />
  )
}

const FacilitiesPage = () => {
  const GET_FACILITIES = gql`
    query CoachFacilities {
      currentUser {
        attributes {
          ... on Coach {
            coachProfile {
              id
            }
            facilities {
              id
              name
              address1
              address2
              city
              state
              zip
              timezone
              url
              phone
              externalFacilityId
              isPrivate
              availabilities {
                id
                timeSlots {
                  day
                  startTime
                  endTime
                }
              }
              coachProfilesFacilities {
                visible
                facilityRank
              }
            }
          }
        }
      }
    }
  `

  const styles = useStyles()
  const { path } = useRouteMatch()
  const crumbs = [
    {
      label: 'Settings',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]
  const { data, loading, error, refetch } = useQuery(GET_FACILITIES)

  if (error) return <Error error={error} />

  return (
    <>
      <PageHeader
        title="Facilities"
        crumbs={crumbs}
        actions={
          data?.currentUser?.attributes.facilities?.length > 0 && (
            <RouterLinkButton
              variant="contained"
              color="primary"
              to={`${path}/new`}
            >
              Add Facility
            </RouterLinkButton>
          )
        }
        fixedMobileActions
      />

      <Container className={styles.container}>
        {loading ? (
          <Skeleton />
        ) : (
          <Facilities
            facilities={get(data, 'currentUser.attributes.facilities')}
            hasCoachProfile={data.currentUser.attributes.coachProfile !== null}
            refetch={refetch}
          />
        )}
      </Container>
    </>
  )
}

export default FacilitiesPage
