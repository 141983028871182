import { StyledGrid, StyledText, StyledSubGrid } from './styles'
import { Divider } from '@material-ui/core'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import PropTypes from 'prop-types'

const InformationActionBanner = ({
  isMobile,
  isTablet,
  classes,
  coach,
  infoTextContent = '',
  icon = null,
}) => {
  if (!icon || !infoTextContent) return null

  return (
    <StyledGrid item xs={12} isMobile={isMobile} isTablet={isTablet}>
      {!isMobile && !isTablet && (
        <>
          {icon}
          <Divider
            orientation="vertical"
            flexItem
            style={{ margin: '0 1rem' }}
          />
        </>
      )}
      <StyledSubGrid item xs={12} md={8}>
        <StyledText variant="body1">{infoTextContent}</StyledText>
      </StyledSubGrid>
      <StyledSubGrid item xs={12} md={4}>
        <StreamSendMessageButton
          variant="outlined"
          userIds={[coach.externalId]}
          className={classes.responsiveButton}
        >
          Send Message
        </StreamSendMessageButton>
      </StyledSubGrid>
    </StyledGrid>
  )
}

export default InformationActionBanner

InformationActionBanner.propTypes = {
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  classes: PropTypes.object,
  coach: PropTypes.shape({
    externalId: PropTypes.string.isRequired,
  }),
  infoTextContent: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
}
