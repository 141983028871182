import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined'
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined'
import blue from '@material-ui/core/colors/blue'
import { withStripeElements, calculateStripeFee } from 'lib/stripe'
import { currency } from 'lib/utils/string'
import { dollarsToCents, centsToDollars } from 'lib/utils/number'
import CurrencyField from 'components/form-util/currency'
import StripeInput from 'components/payments/stripe-input'
import { inputPriceRegExp } from 'utils/regExp'
import { RECORD_BOOKING_PAYMENT, UPDATE_REGISTRATION_PAYMENT } from './queries'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}

const paymentMethodOptions = [
  {
    label: 'Credit Card',
    value: 'credit_card',
  },
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Member Charge',
    value: 'member',
  },
  {
    label: 'Room Charge',
    value: 'room',
  },
  {
    label: 'Venmo',
    value: 'venmo',
  },
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

const validationSchema = (defaultAmountInCents, shouldCharge, isPaid) => {
  let amountValidation = yup
    .string()
    .matches(inputPriceRegExp, 'Please enter a valid price')
    .required('Please specify an amount')

  if (shouldCharge) {
    amountValidation = amountValidation.max(
      centsToDollars(defaultAmountInCents),
      ({ value }) =>
        `Price (${currency(
          value,
        )}) must not exceed the value of the event (${currency(
          centsToDollars(defaultAmountInCents),
        )})`,
    )
  }

  return yup.object().shape({
    ...(!isPaid && {
      amount: amountValidation,
    }),
    sourceType: yup
      .string()
      .oneOf(paymentMethodOptions.map(pm => pm.value))
      .required(),
    ...(shouldCharge && {
      payment: yup.object().shape({
        name: yup.string().required('This field is required'),
      }),
    }),
  })
}

const RecordBookingPaymentForm = ({
  enrollment,
  defaultAmountInCents,
  acceptOnlinePayments,
  onPaymentSuccess,
}) => {
  const [shouldCharge, setShouldCharge] = useState(false)
  const { id: enrollmentId, transactions } = enrollment
  const chargeType =
    transactions[0]?.registrationPayment?.registrationPaymentCharge
      ?.chargeType || transactions[0]?.sourceType
  const chargeNumber =
    transactions[0]?.registrationPayment?.registrationPaymentCharge
      ?.chargeNumber
  const checkNumber =
    transactions[0]?.registrationPayment?.registrationPaymentCharge?.checkNumber
  const isPaid = transactions.some(t => !t.refund)

  const { register, handleSubmit, control, errors, watch } = useForm({
    defaultValues: {
      amount: defaultAmountInCents / 100,
      sourceType: chargeType || paymentMethodOptions[0].value,
      chargeNumber: chargeNumber || '',
      checkNumber: checkNumber || '',
    },
    resolver: yupResolver(
      validationSchema(defaultAmountInCents, shouldCharge, isPaid),
    ),
  })

  const [recordBookingPayment, { loading }] = useMutation(
    RECORD_BOOKING_PAYMENT,
    {
      onCompleted: data => {
        if (data.recordBookingPayment.success) {
          enqueueSnackbar('Payment recorded successfully', {
            variant: 'success',
          })
          onPaymentSuccess && onPaymentSuccess()
        } else {
          enqueueSnackbar(data.recordBookingPayment.message, {
            variant: 'error',
          })
        }
      },
      onError: error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['bookings', 'record-booking-payment'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      },
    },
  )

  const [
    updateRegistrationPayment,
    { loading: updateRegistrationLoading },
  ] = useMutation(UPDATE_REGISTRATION_PAYMENT, {
    onCompleted: data => {
      if (data.updateRegistrationPaymentCharge.success) {
        enqueueSnackbar('Payment updated successfully', {
          variant: 'success',
        })
        onPaymentSuccess && onPaymentSuccess()
      } else {
        enqueueSnackbar(data.updateRegistrationPaymentCharge.message, {
          variant: 'error',
        })
      }
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['bookings', 'update-registration-payment-charge'],
        })
      enqueueSnackbar('An error has occurred. Please try again later.', {
        variant: 'error',
      })
    },
  })
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = async values => {
    const { error: stripeError, token: stripeToken } = await (shouldCharge
      ? stripe.createToken(elements.getElement(CardNumberElement), {
        name: values.payment.name,
        address_country: 'US',
      })
      : Promise.resolve({ error: null, token: null }))

    if (stripeError) {
      enqueueSnackbar(stripeError.message, {
        variant: 'error',
      })
      return Promise.resolve()
    }

    if (isPaid) {
      const updateRegistrationInput = {
        id: transactions[0].registrationPayment?.registrationPaymentCharge?.id,
        chargeType: values.sourceType,
        chargeNumber: values.chargeNumber,
        checkNumber: values.checkNumber,
      }
      await updateRegistrationPayment({
        variables: {
          input: updateRegistrationInput,
        },
      })
    } else {
      const recordBookingInput = {
        enrollmentId: enrollmentId,
        payment: {
          sourceType: stripeToken ? 'stripe' : values.sourceType, // charge type
          amountInCents: dollarsToCents(values.amount),
          stripeToken: stripeToken?.id,
          chargeNumber: values.chargeNumber,
          checkNumber: values.checkNumber,
        },
      }
      await recordBookingPayment({
        variables: recordBookingInput,
      })
    }
  }

  const amountInCents = dollarsToCents(watch('amount') || 0)
  const [netAmountInCents, feeInCents] = calculateStripeFee(amountInCents)
  const sourceType = watch('sourceType')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display={loading || updateRegistrationLoading ? 'block' : 'none'}
        align="center"
        py={4}
      >
        <CircularProgress color="primary" />
      </Box>
      <Box display={loading || updateRegistrationLoading ? 'none' : 'block'}>
        <Box display={shouldCharge ? 'none' : 'block'}>
          <Box mb={2}>
            <CurrencyField
              name="amount"
              label="Price"
              inputRef={register}
              error={!!errors.amount}
              helperText={errors?.amount?.message}
              disabled={isPaid}
            />
          </Box>
          <Box mb={2}>
            <FormControl>
              <InputLabel>Payment type</InputLabel>
              <Controller
                as={
                  <Select
                    variant="outlined"
                    ref={register}
                    label="Payment type"
                    error={!!errors?.sourceType}
                  >
                    {paymentMethodOptions.map(({ value, label }) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="sourceType"
                control={control}
              />
              {errors.sourceType ? (
                <Typography color="error" variant="caption">
                  Please select a payment type
                </Typography>
              ) : null}
            </FormControl>
          </Box>
          {['room', 'member'].includes(sourceType) && (
            <>
              <Box mb={2}>
                <TextField
                  name="chargeNumber"
                  inputRef={register}
                  label={`${
                    sourceType === 'member' ? 'Member' : 'Room'
                  } Number`}
                  placeholder={`${
                    sourceType === 'member' ? 'Member' : 'Room'
                  } Number`}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  name="checkNumber"
                  inputRef={register}
                  label="Check Number"
                  placeholder="Check Number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </>
          )}
          {acceptOnlinePayments && (
            <Box mb={3}>
              <Typography variant="subtitle2">
                Need to collect payment now?
              </Typography>
              <Button
                size="large"
                variant="contained"
                startIcon={<PaymentOutlinedIcon />}
                onClick={() => setShouldCharge(true)}
              >
                Enter Credit Card
              </Button>
            </Box>
          )}
          <Button
            size="large"
            color="primary"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
        <Box display={shouldCharge ? 'block' : 'none'}>
          <Box
            p={1}
            mb={2}
            borderRadius={4}
            style={{ backgroundColor: blue[50] }}
          >
            <Typography variant="body2">
              Please note that a 2.9% + $0.30 fee will be applied to the
              transaction total.
            </Typography>
          </Box>
          <Box mb={3}>
            <Box px={1}>
              <Typography
                variant="body2"
                style={{ fontWeight: 600 }}
                gutterBottom
              >
                Transaction Details
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Coaching Fee
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {currency(amountInCents / 100)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" color="textSecondary">
                  Fee
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  -{currency(feeInCents / 100)}
                </Typography>
              </Box>
            </Box>
            <Box my={1}>
              <Divider style={{ height: '3px' }} />
            </Box>
            <Box px={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  You will Receive
                </Typography>
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  {currency(netAmountInCents / 100)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mb={2}>
            <TextField
              name="payment.name"
              inputRef={register}
              error={!!errors?.payment?.name}
              helperText={errors?.payment?.name?.message}
              label="Cardholder name"
              placeholder="Cardholder name"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  autocomplete: 'cc-name',
                },
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Credit card number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  options: ELEMENT_OPTIONS,
                  autocomplete: 'cc-number',
                },
              }}
            />
          </Box>
          <Box display="flex" mb={4}>
            <Box style={{ width: '160px' }} mr={3}>
              <TextField
                label="Expiration"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    options: ELEMENT_OPTIONS,
                    autocomplete: 'cc-exp',
                  },
                }}
              />
            </Box>
            <Box style={{ width: '140px' }}>
              <TextField
                label="CVC"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    options: ELEMENT_OPTIONS,
                    autocomplete: 'cc-csc',
                  },
                }}
              />
            </Box>
            {/* if you're looking for the zip code field, it was removed in this PR: https://github.com/pgahq/coaching-programs-frontend/pull/776 */}
          </Box>
          {errors.amount ? (
            <Box mb={2}>
              <Typography color="error" variant="caption">
                {errors?.amount?.message}
              </Typography>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="space-between">
            <Button
              color="primary"
              size="small"
              onClick={() => setShouldCharge(false)}
              startIcon={<NavigateBeforeOutlinedIcon />}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              Record Payment
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default withStripeElements(RecordBookingPaymentForm)
