import React, { useState, useContext } from 'react'
import {
  Box,
  Typography,
  Avatar,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { useStyles } from './styles'

import { useIsMobile } from 'lib/hooks'
import { centsToDollarsFormatted } from 'lib/utils/number'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useCancelPracticeSessionForStudent } from './query'
import { RefetchContext } from 'lib/utils/refetchContext'
import Dialog from 'components/dialog'
import { DateTime } from 'luxon'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import { getTimePeriodWithTimezone } from 'utils/dateUtils'
import useIconAccessor from 'components/lesson-type-category/icons'

const ReservationDetailsDialog = ({ isOpen, setIsOpen, reservation }) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const [
    cancelReservation,
    { loading: cancelLoading },
  ] = useCancelPracticeSessionForStudent()
  const refetch = useContext(RefetchContext)

  const isMobile = useIsMobile()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const {
    academyName,
    student,
    startDateTime,
    endDateTime,
    timezone,
    spaceName,
    capacity,
    isPast,
    transactions,
  } = reservation
  const reservationPayment = transactions[0]
  const registrationPaymentId = reservationPayment?.registrationPayment?.id
  const reservationName = `${student.firstName} ${student.lastName}`

  const iconAccessor = useIconAccessor({
    size: 32,
    color: '#043362',
  })
  const { icon } = iconAccessor.SWING_EVALUATION
  const formattedTimePeriod = getTimePeriodWithTimezone(
    startDateTime,
    endDateTime,
    timezone,
  )
  const formattedReservationDate = DateTime.fromISO(startDateTime).toFormat(
    'EEEE, LLLL dd,yyyy',
  )

  const formatPayment = payment => {
    const { amountInCents, createdAt = DateTime.now().toISO() } = payment
    return `${centsToDollarsFormatted(amountInCents)} on ${DateTime.fromISO(
      createdAt,
    )
      .setZone(timezone)
      .toFormat('dd LLLL, yyyy')}`
  }
  const handleCancel = async () => {
    try {
      const { data } = await cancelReservation({
        variables: { reservationId: reservation.id },
      })

      if (data.cancelPracticeSessionForStudent.success) {
        setIsOpen(false)
        enqueueSnackbar('Reservation cancelled successfully', {
          variant: 'success',
        })
        refetch() // refresh the list of reservations
      } else {
        enqueueSnackbar(data.cancelPracticeSessionForStudent.message, {
          variant: 'error',
        })
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      })
    }
  }

  const handleViewTransactionClick = () => {
    history.push(
      `/account/payments/transactions/${registrationPaymentId}?return_to=${encodeURIComponent(
        window.location.pathname,
      )}`,
    )
  }

  return (
    <>
      <Dialog
        showCloseButton
        fullScreen={isMobile}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {() => (
          <>
            <DialogTitle style={{ padding: 0 }}>
              {!isMobile && <Box className={classes.dialogHeader} />}
              {!isMobile && (
                <Box position="relative">
                  <Avatar className={classes.categoryIcon}>{icon}</Avatar>
                </Box>
              )}
            </DialogTitle>
            <DialogContent>
              <Typography variant="h6" className={classes.title}>
                Bay Reservation
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                className={classes.eventRow}
              >
                <PlaceOutlinedIcon className={classes.eventRowIcon} />
                <Typography variant="body1">
                  {academyName} - {spaceName}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.eventRow}
              >
                <EventNoteOutlinedIcon className={classes.eventRowIcon} />
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.bodyFont}>
                    {formattedReservationDate}
                  </Typography>
                  <Typography variant="body1">{formattedTimePeriod}</Typography>
                </Box>
              </Box>
              {reservationPayment && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.eventRow}
                >
                  <Box display="flex">
                    <MonetizationOnOutlinedIcon
                      className={classes.eventRowIcon}
                    />
                    <Typography variant="body1">
                      {formatPayment(reservationPayment)}
                    </Typography>
                  </Box>
                  <Tooltip title="View Transaction">
                    <IconButton onClick={handleViewTransactionClick}>
                      <ReceiptOutlinedIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {capacity && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.eventRow}
                >
                  <PeopleOutlinedIcon className={classes.eventRowIcon} />
                  <Typography variant="body1">{capacity}</Typography>
                </Box>
              )}
              <Box
                display="flex"
                alignItems="center"
                className={classes.eventRow}
              >
                <Avatar variant="circular" className={classes.avatarRowIcon}>
                  {reservationName.slice(0, 2).toUpperCase()}
                </Avatar>
                <Typography variant="body1">{reservationName}</Typography>
              </Box>
            </DialogContent>
            {!isPast && (
              <DialogActions>
                <Box display="flex" width="100%" mb={2.5} ml={2}>
                  <Button
                    onClick={() => setIsConfirming(true)}
                    variant="outlined"
                    disabled={cancelLoading}
                  >
                    Cancel Reservation
                  </Button>
                </Box>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
      <Dialog
        showCloseButton
        fullScreen={isMobile}
        isOpen={isConfirming}
        onClose={() => setIsConfirming(false)}
      >
        {() => (
          <>
            <DialogTitle DialogTitle>Cancel this Reservation?</DialogTitle>

            <DialogContent className={classes.confirmationDialogContent}>
              <Typography gutterBottom>
                Are you sure you want to cancel this reservation?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsConfirming(false)}
                disabled={cancelLoading}
              >
                Back
              </Button>
              <Button
                onClick={handleCancel}
                variant="contained"
                disabled={cancelLoading}
                color="primary"
              >
                Cancel Reservation
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
export default ReservationDetailsDialog
