import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import useStyles from './styles'
import {
  InstantSearch,
  Configure,
  Pagination,
  connectRefinementList,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  COACH_ACTIVITIES_INDEX_NAME,
} from 'env'
import ActivityPlanCards from './../activity-plans/activity-plan-cards'
import 'instantsearch.css/themes/satellite.css'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const RefinementList = ({
  items,
  currentRefinement,
  refine,
  searchForItems,
}) => {
  const classes = useStyles()
  const labelItems = []
  items.map(item => labelItems.push(item[Object.keys(item)[0]]))

  return (
    <Grid container spacing={2}>
      <Autocomplete
        id="authorName"
        fullWidth
        className={classes.select}
        options={labelItems}
        onChange={(event, newValue) => {
          event.preventDefault()
          if (newValue) {
            refine(newValue)
          } else {
            refine(labelItems)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Author Name"
            variant="outlined"
            onChange={event => searchForItems(event.currentTarget.value)}
          />
        )}
      />
    </Grid>
  )
}

const CustomRefinementList = connectRefinementList(RefinementList)

const CustomPlansPage = () => {
  const classes = useStyles()
  const query = 'object_type:lesson_plan'
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <InstantSearch
          indexName={COACH_ACTIVITIES_INDEX_NAME}
          searchClient={searchClient}
        >
          <CustomRefinementList
            attribute="authorName"
            searchable
            limit={1000}
          />
          <ActivityPlanCards />
          <Configure filters={`${query}`} hitsPerPage={12} />
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination showLast />
          </Box>
        </InstantSearch>
      </Container>
    </Box>
  )
}

export default CustomPlansPage
