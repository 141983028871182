import { gql } from '@apollo/client'

export const LessonTypeQuery = gql`
  query CoachWithLessonsAndFacilities($externalId: ID, $slug: String) {
    __type(name: "LessonTypeCategory") {
      name
      enumValues {
        name
      }
    }
    coaches(externalId: $externalId, slug: $slug) {
      name
      externalId
      id
      profileUrl
      facilities {
        id
        name
      }
      coachProfile {
        title
        firstName
        lastName
        city
        state
        profilePhoto
        slug
        status
        bookable
        certificationLevel
        memberType
        socialJsonData {
          type
          reference
          preferred
        }
      }
      lessonTypes {
        id
        category
        title
        durationInMinutes
        priceInCents
        description
        locations {
          id
          name
          city
          state
        }
        status
        lessonPacks {
          id
          quantity
          priceInCents
          expirationDuration
        }
        requiresAccessCode
        academy {
          slug
        }
        coach {
          id
          externalId
        }
      }
    }
  }
`

export const GET_LESSON_CREDIT_BALANCE = gql`
  query lessonCreditBalances {
    currentUser {
      attributes {
        ... on Contact {
          lessonCreditBalances {
            lessonType {
              id
              title
            }
            quantity
            nextExpirationDate
          }
        }
      }
    }
  }
`

export const GET_COACH_GROUP_EVENTS = gql`
  query getCoachGroupEvents(
    $coachId: ID
    $startDateTime: DateTimeRangeInput
    $includeCanceled: Boolean = false
  ) {
    groupEvents(
      coachId: $coachId
      startDateTime: $startDateTime
      includeCanceled: $includeCanceled
    ) {
      featuredImageUrl
      priceInCents
      slug
      title
      startDateTime
      endDateTime
      maxAttendance
      registrationCount
      unlisted
      registrationClosed
      tags
      coachFacility {
        name
        id
        timezone
      }
      sessions {
        id
      }
      hasWaitlist
      tags
    }
  }
`
