import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { withAuthenticatedRoute } from 'lib/auth'
import { flags, useFeatureFlags } from '../../lib/feature-flags'
import validateUuid from 'uuid-validate'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import themes, { extendTheme } from 'themes'
import CoachToolsBannerAd from 'components/smart-banner-ads/coach-tools-banner-ad'
import CoachAppbar from 'components/coach-app-bar'
import ContentUnavailable from 'components/content-unavailable'
import Error from 'components/error'
import ScrollToTopOnMount from 'lib/utils/scroll-to-top-on-mount'
import { useHideIntercom } from 'lib/support'
import SchedulerStepController from './scheduler-step-controller'
import CondensedFooter from 'components/condensed-footer'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 120px)',
    overflowX: 'clip',
  },
}))

const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

const PGA_COACH_ELIGIBLE_CHECK = gql`
  query CheckCoachEligibilityForSchedulePage($externalId: ID, $slug: String) {
    coaches(externalId: $externalId, slug: $slug) {
      name
      pgaCoachEligible
      coachProfile {
        firstName
        lastName
        profilePhoto
      }
    }
  }
`

const SchedulePageContent = () => {
  const { idOrSlug } = useParams()

  const { data, loading, error } = useQuery(PGA_COACH_ELIGIBLE_CHECK, {
    variables: {
      ...(validateUuid(idOrSlug)
        ? { externalId: idOrSlug }
        : { slug: idOrSlug }),
    },
  })

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  const coach = data?.coaches?.[0]
  if (!coach) return <Error statusCode={404} />
  if (!coach.pgaCoachEligible) return <ContentUnavailable />

  return (
    <>
      <SchedulerStepController />
      <ScrollToTopOnMount query={window.location.search} />
    </>
  )
}

const SchedulePage = () => {
  useHideIntercom()

  const [isAppInstallBannerAdEnabled] = useFeatureFlags([
    flags.FLAG_APP_INSTALL_BANNER_AD,
  ])
  const classes = useStyles()

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach | Schedule Session</title>
        <meta name="description" content="PGA Coach | Schedule Session" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <CoachAppbar position="relative" hideContactButton />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {isAppInstallBannerAdEnabled && <CoachToolsBannerAd />}
        <Box className={classes.root}>
          <SchedulePageContent />
        </Box>
      </SnackbarProvider>
      <CondensedFooter />
    </ThemeProvider>
  )
}

export default withAuthenticatedRoute(SchedulePage)
