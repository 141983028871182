import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import Cookie from 'js-cookie'
import getDeviceType from 'lib/utils/getDeviceType'
import CoachToolsAppIcon from 'images/icons/coach-tools-app-icon.png'
import SmartBannerAd from './smart-banner'
import { handleCtaClick, handleDismiss } from './utils'

const coachToolsiOSUrl = 'https://apps.apple.com/us/app/id1448721089'
const coachToolsAndroidUrl =
  'https://play.google.com/store/apps/details?id=com.pga.pgacoach'

const CoachToolsBannerAd = () => {
  const displayBanner = Cookie.get('appInstalledOrDismissed')
  const [isDisplaying, setIsDisplaying] = useState(
    !(displayBanner === 'nodisplay'),
  )
  const { user } = useAuth()
  const history = useHistory()

  const iosProps = {
    appIcon: CoachToolsAppIcon,
    appStoreUrl: coachToolsiOSUrl,
    titleText: 'PGA Coach',
    subtitleText: 'GET — On the App Store',
    ctaText: 'Install',
    handleCtaClick: handleCtaClick(
      setIsDisplaying,
      coachToolsiOSUrl,
      'coach-tools',
    ),
    handleDismiss: handleDismiss(setIsDisplaying, 'coach-tools'),
  }

  const androidProps = {
    appIcon: CoachToolsAppIcon,
    appStoreUrl: coachToolsAndroidUrl,
    titleText: 'PGA Coach',
    subtitleText: 'GET — Google Play',
    ctaText: 'Install',
    handleCtaClick: handleCtaClick(
      setIsDisplaying,
      coachToolsAndroidUrl,
      'coach-tools',
    ),
    handleDismiss: handleDismiss(setIsDisplaying, 'coach-tools'),
  }

  const device = getDeviceType()
  const deviceSpecificProps = device === 'android' ? androidProps : iosProps

  const isMessages = history.location.pathname.includes('messages')
  if (!user?.coach?.coachProfile || isMessages || !isDisplaying) {
    return null
  }

  const isSchedule =
    history.location.pathname.includes('schedule') &&
    !history.location.pathname.includes('pga-coach')
  return (
    <SmartBannerAd {...deviceSpecificProps} top={isSchedule ? '0px' : null} />
  )
}

export default CoachToolsBannerAd
