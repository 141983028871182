import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { PhoneField } from 'components/form-util'
import useStyles from './group-event-registration-form.styles'
import { stripPhoneNumber } from 'utils/phoneNumber'
import { useFeatureFlags, flags } from 'lib/feature-flags'

const ParticipantInformation = ({ tags }) => {
  const { register, errors, control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'participants',
    control,
  })

  const [isAgeSpecificGroupEventsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_AGE_SPECIFIC_GROUP_EVENTS,
  ])

  const appendParticipantWithType = type => {
    append({ type }, false)
  }
  const removeParticipantWithType = type => {
    const participantTypes = fields.map(p => p.type)
    const lastParticipantOfTypeIndex = participantTypes.lastIndexOf(type)
    if (lastParticipantOfTypeIndex >= 0) {
      remove(lastParticipantOfTypeIndex)
    }
  }

  const isAdultsOnly = tags?.includes('Adults Only')
  const isJuniorsOnly = tags?.includes('Juniors Only')
  const isAgeSpecificGroupEvent =
    isAgeSpecificGroupEventsEnabled && (isAdultsOnly || isJuniorsOnly)

  const adultParticipantCount = fields.filter(p => p.type === 'Adult').length
  const juniorParticipantCount = fields.filter(p => p.type === 'Junior').length

  const classes = useStyles()
  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.formSectionHeader}
      >
        Participant details
      </Typography>
      {!isAgeSpecificGroupEvent || isAdultsOnly ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={3.5}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" className={classes.details}>
              Adults
            </Typography>
            <Box color="text.secondary">
              <Typography variant="body2" className={classes.details}>
                Ages 18 or above
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.formButton}
              onClick={() => removeParticipantWithType('Adult')}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Typography variant="subtitle1">{adultParticipantCount}</Typography>
            <IconButton
              className={classes.formButton}
              onClick={() => appendParticipantWithType('Adult')}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </Box>
      ) : null}

      {!isAgeSpecificGroupEvent || isJuniorsOnly ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={3.5}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" className={classes.details}>
              Juniors
            </Typography>
            <Box color="text.secondary">
              <Typography variant="body2" className={classes.details}>
                Ages 17 or under
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.formButton}
              onClick={() => removeParticipantWithType('Junior')}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <Typography variant="subtitle1">
              {juniorParticipantCount}
            </Typography>
            <IconButton
              className={classes.formButton}
              onClick={() => appendParticipantWithType('Junior')}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </Box>
      ) : null}

      {fields.map((participant, index) => (
        <Box key={participant.id} mb={2}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.formSectionHeader}
          >
            Participant #{index + 1} ({participant.type})
          </Typography>
          <TextField
            name={`participants[${index}].firstName`}
            inputRef={register()}
            error={!!errors?.participants?.[index]?.firstName}
            helperText={errors?.participants?.[index]?.firstName?.message}
            defaultValue={participant.firstName}
            label="First name"
            fullWidth
            className={classes.input}
          />
          <TextField
            name={`participants[${index}].lastName`}
            inputRef={register()}
            error={!!errors?.participants?.[index]?.lastName}
            helperText={errors?.participants?.[index]?.lastName?.message}
            defaultValue={participant.lastName}
            label="Last name"
            fullWidth
            className={classes.input}
          />
          {participant.type === 'Adult' && (
            <>
              <TextField
                name={`participants[${index}].email`}
                inputRef={register()}
                error={!!errors?.participants?.[index]?.email}
                helperText={errors?.participants?.[index]?.email?.message}
                defaultValue={participant.email}
                label="Email"
                fullWidth
                className={classes.input}
              />
              <PhoneField
                name={`participants[${index}].phoneNumber`}
                inputRef={register()}
                error={!!errors?.participants?.[index]?.phoneNumber}
                helperText={errors?.participants?.[index]?.phoneNumber?.message}
                defaultValue={stripPhoneNumber(participant.phoneNumber)}
                label="Phone number"
                fullWidth
                className={classes.input}
              />
            </>
          )}
          <Input
            type="hidden"
            name={`participants[${index}].type`}
            inputRef={register()}
            defaultValue={participant.type}
          />
        </Box>
      ))}
      {fields.length === 0 && (
        <Box mb={2}>
          <FormHelperText error={true}>
            Please register at least one participant
          </FormHelperText>
        </Box>
      )}
    </>
  )
}

export default ParticipantInformation
