import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  cardRoot: ({ showFullReview }) => ({
    height: showFullReview ? 'auto' : '250px',
    marginTop: '8px',
    marginLeft: '8px',
    padding: '24px 16px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      height: showFullReview ? 'auto' : '280px',
    },
  }),
  ratingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  reviewWrapper: {
    fontSize: '16px',
  },
  reviewAction: {
    color: theme.palette.secondary.main,
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      fontSize: '12px',
    },
  },
  starButton: {
    padding: '5px',
  },
}))

export default useStyles
