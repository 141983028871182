/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import useStyles from '../profile.styles'

const Navigation = () => {
  const classes = useStyles()
  return (
    <div className={classes.navAside}>
      <div id="coachNavWrapper">
        <a href="#web-address">Web Address</a>
        <a href="#profile">Profile</a>
        <a href="#facilities">Facilities</a>
        <a href="#experience">Experience</a>
        <a href="#services">Services</a>
        <a href="#skills">Skills</a>
        <a href="#tools">Tools</a>
      </div>
    </div>
  )
}

export default Navigation
