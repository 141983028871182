import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/styles'
import { useInView } from 'react-intersection-observer'
import { SectionHeader, HelperText, FormFields } from './intake-form.styles'
import propTypes from 'prop-types'

const ImprovementWrapper = styled(Grid)({
  direction: 'column',
})

const FormField = ({ name, label }) => {
  const { register, errors } = useFormContext()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      // this exists to rerender the form as the multiline textfield
      // is collapsed when it is first rendered due to it not being in view
      // until the page navigation occurs.
    }
  }, [inView])

  return (
    <Grid xs={12} item ref={ref}>
      <TextField
        name={name}
        inputRef={register}
        multiline={true}
        minRows={4}
        error={!!errors?.[name]}
        helperText={errors?.[name]?.message}
        label={label}
        fullWidth
        id={name.split('.').join('-')}
      />
    </Grid>
  )
}

const GolfFocusInformation = () => {
  return (
    <ImprovementWrapper container>
      <SectionHeader variant="h6">Game Improvement</SectionHeader>
      <FormFields container spacing={3}>
        <HelperText variant="subtitle2">
          What part of your game are you most confident in?
        </HelperText>
        <FormField
          name="golfFocusInformation.confidentArea"
          label="i.e., your swing, your putting"
          defaultValue={null}
        />
        <HelperText variant="subtitle2">
          What part of your game would you like to see improve?
        </HelperText>
        <FormField
          name="golfFocusInformation.improvementArea"
          label="i.e., your swing, your putting"
          defaultValue={null}
        />
      </FormFields>
    </ImprovementWrapper>
  )
}

export default GolfFocusInformation

FormField.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string,
}
