import { buildDateTime } from 'lib/CustomLuxonUtils'

export const validateSessionOverlap = value => {
  const conflicts = value.some((current, idx, arr) => {
    const { startDate, startTime, endTime } = current
    const startDateTime = buildDateTime(startDate, startTime)
    const endDateTime = buildDateTime(startDate, endTime)
    return arr.slice(idx + 1).some(test => {
      const testStartDateTime = buildDateTime(test.startDate, test.startTime)
      const testEndDateTime = buildDateTime(test.startDate, test.endTime)
      return (
        (startDateTime >= testStartDateTime &&
          endDateTime <= testEndDateTime) ||
        (startDateTime < testEndDateTime && endDateTime >= testEndDateTime) ||
        (endDateTime > testStartDateTime && endDateTime <= testEndDateTime)
      )
    })
  })
  return !conflicts
}
