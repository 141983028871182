import LuxonUtils from '@date-io/luxon'
import { DateTime } from 'luxon'

// extension of the LuxonUtils class that uses Sunday as the first day of the week instead of Monday
class CustomLuxonUtils extends LuxonUtils {
  getWeekdays() {
    return ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
  }

  getWeekArray(date) {
    const weekLength = this.getWeekdays().length

    const firstDayOfMonth = date.startOf('month')
    const lastDayOfMonth = date.endOf('month').startOf('day')

    const startOfFirstWeekOfMonth = firstDayOfMonth.minus({
      days: firstDayOfMonth.weekday % weekLength,
    })

    const weeks = []
    let currentWeek = startOfFirstWeekOfMonth

    while (currentWeek <= lastDayOfMonth) {
      const startOfWeek = currentWeek
      const newWeek = Array.from({ length: weekLength }, (_, idx) =>
        startOfWeek.plus({ days: idx }),
      )
      weeks.push(newWeek)
      currentWeek = currentWeek.plus({ days: weekLength })
    }

    return weeks
  }
}

const buildDateTime = (date, time, timezone = null) => {
  let datetime = new CustomLuxonUtils().mergeDateAndTime(
    DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date),
    DateTime.isDateTime(time) ? time : DateTime.fromJSDate(time),
  )
  if (timezone) datetime = datetime.setZone(timezone, { keepLocalTime: true })
  return datetime
}

const buildDateTimeForNative = (date, time, timezone = null) => {
  // check if date is already a luxon DateTime
  const formattedDate = DateTime.isDateTime(date)
    ? date
    : DateTime.fromJSDate(date)
  // create time from ISO string
  const formattedTime = DateTime.fromISO(time)
  let datetime = new CustomLuxonUtils().mergeDateAndTime(
    formattedDate,
    formattedTime,
  )
  if (timezone) datetime = datetime.setZone(timezone, { keepLocalTime: true })
  return datetime
}

// Checks to see if a given date is invalid or not
const isInvalidDate = date => {
  return isNaN(new Date(date).getTime())
}

export default CustomLuxonUtils
export { buildDateTime, buildDateTimeForNative, isInvalidDate }
