import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import {
  LocalizationProvider,
  TimePicker,
  renderMultiSectionDigitalClockTimeView,
  MobileDatePicker,
} from '@mui/x-date-pickers'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import { roundDateTimeMinutes } from 'utils/dateUtils'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'themes'
import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@mui/icons-material/Event'

const useStyles = makeStyles(theme => ({
  pickerContainer: {
    marginTop: '16px',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      marginTop: '16px',
      width: '100%',
    },
  },
  adornedEnd: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const RegistrationClose = () => {
  const { control, register, errors } = useFormContext()
  const classes = useStyles()

  const handleTimeChange = (event, onChange) => {
    const roundedMinutesTime = roundDateTimeMinutes(event)
    if (roundedMinutesTime) {
      onChange(roundedMinutesTime)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      style={{ marginTop: '40px' }}
    >
      <Typography variant="subtitle1" gutterBottom>
        Registration close date
      </Typography>
      <Typography variant="body2" gutterBottom>
        Select the date and time this event registration will close.
      </Typography>
      <Box style={{ maxWidth: '450px' }}>
        <LocalizationProvider
          dateAdapter={CustomLuxonAdapter}
          dateFormats={{ normalDateWithWeekday: 'LLL d, yyyy' }} // Jul 1, 2021
        >
          <ThemeProvider theme={createTheme(themes.offeringsRefresh)}>
            <Grid container spacing={4} className={classes.pickerContainer}>
              <Grid item xs={5}>
                <Box>
                  <Controller
                    control={control}
                    name="registrationCloseDate"
                    render={props => (
                      <MobileDatePicker
                        label="Close date"
                        id="registration-close-date"
                        value={props.value}
                        format="MMM dd, yyyy"
                        disablePast
                        dayOfWeekFormatter={day => `${day}`}
                        slotProps={{
                          textField: {
                            error: !!errors.registrationCloseDate,
                            helperText: errors.registrationCloseDate
                              ? errors.registrationCloseDate.message
                              : null,
                            InputProps: {
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.adornedEnd}
                                >
                                  <EventIcon />
                                </InputAdornment>
                              ),
                            },
                          },
                        }}
                        onChange={e => props.onChange(e)}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Controller
                    control={control}
                    name="registrationCloseTime"
                    render={props => (
                      <TimePicker
                        id="registration-close-time"
                        label="Close time"
                        value={props.value}
                        name="registrationCloseTime"
                        onChange={e => handleTimeChange(e, props.onChange)}
                        minutesStep={5}
                        innerRef={register}
                        viewRenderers={{
                          hours: renderMultiSectionDigitalClockTimeView,
                          minutes: renderMultiSectionDigitalClockTimeView,
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors.registrationCloseTime,
                            helperText: errors.registrationCloseTime
                              ? errors.registrationCloseTime.message
                              : null,
                          },
                          dialog: {
                            disableEnforceFocus: true,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </LocalizationProvider>
      </Box>
    </Box>
  )
}

export default RegistrationClose
