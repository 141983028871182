import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

export default ({
  searchTerm,
  setSearchTerm,
  placeholder,
  style = null,
  ...props
}) => (
  <TextField
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: searchTerm.length ? (
        <InputAdornment
          onClick={() => setSearchTerm('')}
          position="end"
          style={{ cursor: 'pointer' }}
        >
          <ClearIcon />
        </InputAdornment>
      ) : null,
    }}
    value={searchTerm}
    onChange={e => setSearchTerm(e.target.value)}
    style={style || { margin: '20px', width: '65%' }}
    placeholder={placeholder}
    variant="outlined"
    {...props}
  />
)
