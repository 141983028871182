export const daysOfWeek = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
]

export const INVALID_INTERVAL_ERROR_MESSAGE =
  'End time cannot occur before start time'
export const OVERLAPPING_INTERVALS_ERROR_MESSAGE =
  'The current availability has overlapping time intervals'
export const ERROR_TOOLTIP_MESSAGE =
  'Please resolve any errors before you proceed'
