import Tooltip from '@material-ui/core/Tooltip'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import useStyles from '../profile.styles'
import IconButton from '@material-ui/core/IconButton'

const FacilityVisibilityToggle = ({ visible }) => {
  const classes = useStyles()
  return visible ? (
    <Tooltip title="Visible on Coach Profile" placement="bottom">
      <IconButton
        classes={{ root: classes.facilityIconButton }}
        color="primary"
      >
        <VisibilityOutlinedIcon
          className={classes.facilityIcon}
        />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title="Hidden on Coach Profile" placement="bottom">
      <IconButton classes={{ root: classes.facilityIconButton }}>
        <VisibilityOffOutlinedIcon
          className={classes.facilityIcon}
        />
      </IconButton>
    </Tooltip>
  )
}

export default FacilityVisibilityToggle
