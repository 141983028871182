import React from 'react'
import clsx from 'clsx'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { CardMedia, Box, Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { useGetLessonType } from '../api-hooks'
import useIconAccessor from 'components/lesson-type-category/icons'

// Code is forked from here: https://github.com/GetStream/stream-chat-react/blob/master/src/components/Attachment/Card.tsx

const useStyles = makeStyles(theme => ({
  cardHeader: {
    height: '75px',
    padding: '0 44px 0 20px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    fontSize: '0.75rem',
  },
  cardIcon: {
    position: 'absolute',
    color: theme.palette.secondary.dark,
    backgroundColor: '#FFFFFF',
    top: '29px',
    right: '20px',
    height: '75px',
    width: '75px',
    border: `2px solid ${theme.palette.secondary.light}`,
    '& svg': {
      fontSize: '2rem',
    },
  },
  title: {
    fontFamily: 'Source Serif Pro,serif !important',
    color: theme.palette.primary.main,
    lineHeight: '1.5rem',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 1.25rem',
    backgroundColor: '#FFFFFF',
    height: 'inherit',
  },
  listItem: {
    padding: '0',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  listIcon: {
    fontSize: '1rem',
    color: theme.palette.secondary.dark,
  },
  text: {
    textAlign: 'left',
    height: 'unset !important',
  },
  cardFooter: {
    textAlign: 'center',
    justifyContent: 'center',
    margin: 0,
  },
}))

const CustomImage = (props) => {
  const { dimensions = {}, fallback, category, title, coach, imageURL } = props
  const classes = useStyles()
  const accessor = useIconAccessor()
  const icon = accessor[category]?.icon
  const safeIcon = accessor.OTHER.icon

  return (
    <CardMedia
      alt={fallback}
      className='str-chat__message-attachment--img'
      data-testid='image-test'
      {...dimensions}
    >
      <Box className={classes.cardHeader}>
        <Typography variant="body2" style={{ paddingRight: '65px' }}>{coach ? `Coach ${coach}` : null}</Typography>
        <Avatar
          className={classes.cardIcon}
          src={`${imageURL}`}
          children={icon || safeIcon}
          alt="Coach Booking's Icon"
        />
      </Box>
      <Box className={classes.cardBody}>
        <Typography
          variant="h6"
          color="primary"
          className={classes.title}
        >
          {title}
        </Typography>
      </Box>
    </CardMedia>
  )
}

const UnMemoizedSafeAnchor = (props) => {
  const { children, className, download, href, rel, target } = props
  if (!href) return null
  const sanitized = sanitizeUrl(href)
  return (
    <a
      aria-label='Attachment'
      className={className}
      download={download}
      href={sanitized}
      rel={rel}
      target={target}
    >
      {children}
    </a>
  )
}

const SafeAnchor = React.memo(UnMemoizedSafeAnchor)

const UnableToRenderCard = ({ type }) => {
  return (
    <div
      className={clsx('str-chat__message-attachment-card', {
        [`str-chat__message-attachment-card--${type}`]: type,
      })}
    >
      <div className='str-chat__message-attachment-card--content'>
        <div className='str-chat__message-attachment-card--text'>
          {'this content could not be displayed'}
        </div>
      </div>
    </div>
  )
}

const Card = (props) => {
  const {
    type,
    lesson_type_id: id,
    pga_booking_url: bookingURL,
  } = props

  const classes = useStyles()
  const { data, loading, errors } = useGetLessonType({ id: id })

  if (loading) {
    return null
  }
  if (!bookingURL || errors) {
    return <UnableToRenderCard type={type} />
  }

  const lessonType = {
    text: data?.lessonTypes[0]?.description,
    category: data?.lessonTypes[0]?.category,
    title: data?.lessonTypes[0]?.title,
    ogScrapeURL: bookingURL,
    titleLink: bookingURL,
    type: type,
    coach: data?.lessonTypes[0]?.coach.name,
  }

  return (
    <div className={clsx(`str-chat__message-attachment-card str-chat__message-attachment-card--${type}`, classes.text)}>
      <CardHeader {...props} {...lessonType} />
      <div className={clsx(classes.cardFooter, 'str-chat__message-attachment-card--content')}>
        <div className='str-chat__message-attachment-card--flex'>
          {(lessonType.titleLink || lessonType.ogScrapeURL) && (
            <SafeAnchor
              className='str-chat__message-attachment-card--title str-chat__message-attachment-card--url'
              href={lessonType.titleLink || lessonType.ogScrapeURL}
              rel='noopener noreferrer'
              target='_blank'
            >
              Click here for more details and to register!
            </SafeAnchor>
          )}
        </div>
      </div>
    </div>
  )
}

const CardHeader = (props) => {
  const { dimensions, text, title, category, coach } = props
  return (
    <div
      className='str-chat__custom-attachment-card--header str-chat__message-attachment-card-react--header'
      data-testid={'card-header'}
    >
      <CustomImage
        dimensions={dimensions}
        fallback={title || text}
        category={category}
        title={title}
        coach={coach}
      />
    </div>
  )
}

const UnMemoizedCard = (props) => {
  return <Card {...props} />
}

/**
 * Simple Card Layout for displaying links
 */
export default React.memo(UnMemoizedCard)
