// forked from https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx

import React, { useRef } from 'react'
import { useChatContext } from 'stream-chat-react'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'

const UnMemoizedChannelPreviewMessenger = props => {
  const {
    active,
    channel,
    latestMessage,
    setActiveChannel,
    unread,
    watchers,
  } = props
  const { client } = useChatContext()

  const currentUserId = client.user?.id
  const { image, name } = channel?.data || {}
  const allMembers = Object.values(channel?.state.members || {})
  const otherMembers = allMembers
    .filter(m => m.user_id !== currentUserId)
    .map(m => m.user)

  const title = (() => {
    if (name) return name
    if (otherMembers.length > 3) {
      return `${otherMembers
        .slice(0, 3)
        .map(u => u.name)
        .join(', ')}, and ${otherMembers.length - 3} others`
    }
    return otherMembers.map(u => u.name).join(', ')
  })()

  const avatar = (() => {
    if (otherMembers.length > 1) {
      return (
        <CircularHighlightedIcon
          icon={GroupOutlinedIcon}
          size={40}
          iconSize={28}
          iconColor="#FFFFFF"
          highlightColor="#00234B"
        />
      )
    }
    return (
      <Avatar
        color="primary"
        src={image}
        size={40}
        style={{ backgroundColor: '#00234B' }}
      >
        {title?.[0]?.toUpperCase()}
      </Avatar>
    )
  })()

  const channelPreviewButton = useRef(null)

  const activeClass = active
    ? 'str-chat__channel-preview-messenger--active'
    : ''
  const unreadClass =
    unread && unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : ''

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers)
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur()
    }
  }

  const message = (() => {
    let messageTextToRender = latestMessage.props?.source || latestMessage || ''
    allMembers.forEach(member => {
      const { id, name } = member.user
      messageTextToRender = messageTextToRender.replaceAll(`@${id}`, `@${name}`)
    })
    return messageTextToRender
  })()

  return (
    <button
      className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
    >
      <div className="str-chat__channel-preview-messenger--left">
        <Box mr={1}>{avatar}</Box>
      </div>
      <div className="str-chat__channel-preview-messenger--right">
        <div className="str-chat__channel-preview-messenger--name">
          <span>{title}</span>
        </div>
        <div className="str-chat__channel-preview-messenger--last-message">
          {message}
        </div>
      </div>
    </button>
  )
}

const ChannelPreviewMessenger = React.memo(UnMemoizedChannelPreviewMessenger)
export default ChannelPreviewMessenger
