import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { PageHeader } from 'components/page-header'
import AvailabilityManager from './availability-manager'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from 'components/onboarding-dialog'
import { gql, useQuery } from '@apollo/client'
import { get } from 'lodash'
import { useCalcProgress } from 'lib/hooks'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'

export const COACH_ONBOARDING_STATUS = gql`
  query CoachOnboardingStatus {
    currentUser {
      attributes {
        ... on Coach {
          coachOnboardingProgress {
            coachProfileCreated
            schedulableCreated
            availabilityAdded
            facilityAdded
          }
        }
      }
    }
  }
`

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7),
    [theme.breakpoints.only('xs')]: {
      margin: '16px 16px 96px 16px',
    },
  },
}))

const Availability = ({ coachOnboardingProgress, refetch }) => {
  const classes = useStyles()
  const { path } = useRouteMatch()
  const [dialogOpen, setDialogOpen] = useState(false)

  const closeDialog = () => setDialogOpen(false)

  const progress = useCalcProgress(coachOnboardingProgress)

  const crumbs = [
    {
      label: 'Onboarding Dashboard',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]
  const handleClick = () => {
    setDialogOpen(true)
    //  gtmEvent({
    //  event: 'formSubmit',
    //  formCategory: 'facility',
    //  formAction: 'created-onboarding-facility',
    //  })
  }
  return (
    <>
      <PageHeader title="Availability" crumbs={crumbs} progress={progress} />
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <AvailabilityManager refetchCoachOnboardingStatus={refetch} />
            {coachOnboardingProgress?.facilityAdded && (
              <>
                <Box align="right" mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleClick}
                    disabled={!coachOnboardingProgress.availabilityAdded}
                  >
                    I'm Done
                  </Button>
                </Box>
                <Dialog
                  heading="Set your Working Hours Complete!"
                  open={dialogOpen}
                  onClose={closeDialog}
                  text="Set your working hours complete! Head back to Onboarding to keep going!"
                  action
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const AvailabilityPage = () => {
  const { data, loading, error, refetch } = useQuery(COACH_ONBOARDING_STATUS)

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error />

  const { coachOnboardingProgress } = get(data, 'currentUser.attributes')

  return (
    <Availability
      coachOnboardingProgress={coachOnboardingProgress}
      refetch={refetch}
    />
  )
}

export default AvailabilityPage
