import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import SliderNew from 'components/slider-new'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useStyles } from './styles'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const EventsSlider = ({ events, rows, renderEvent }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: Math.min(events.length, 3),
    slidesToScroll: 3,
    initialSlide: 0,
    rows: rows || 1,
    customPaging: function (i) {
      return (
        <a className={classes.paginationNumber} href="/#">
          {i < 9 && 0}
          {i + 1}
        </a>
      )
    },
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: Math.min(events.length, 2),
          slidesToScroll: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  }

  const config = {
    initial: 0,
    loop: false,
    mode: 'snap',
    slides: { origin: 'center', perView: 1.1, spacing: 10 },
    breakpoints: {
      '(min-width: 600px)': {
        loop: false,
        slides: { perView: 'auto', spacing: 28 },
      },
    },
  }

  return (
    <>
      {isMobile ? (
        <SliderNew
          className={classes.slider}
          controlClassName={classes.customControl}
          config={config}
        >
          {events.map(event => renderEvent(event))}
        </SliderNew>
      ) : (
        <>
          {events.length < 6 && rows ? (
            <Grid
              container
              spacing={4}
              justifyContent="flex-start"
              style={{ marginTop: '1rem', justifyContent: 'center' }}
            >
              {events?.map(event => (
                <Grid
                  key={event.id}
                  item
                  md={4}
                  lg={4}
                  style={{ display: 'flex' }}
                >
                  {renderEvent(event)}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Slider
              key={'events-slider-client'}
              {...settings}
              className={`${classes.eventsWrapper} ${events.length <= 2 &&
                classes.eventsWrapperLeft} `}
            >
              {events.map(event => renderEvent(event))}
            </Slider>
          )}
        </>
      )}
    </>
  )
}

EventsSlider.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  logoImageUrl: PropTypes.string,
  rows: PropTypes.number,
}

export default EventsSlider
