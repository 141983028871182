import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useFormContext } from 'react-hook-form'
import YesNoButtons from '../form-components/yes-no-buttons'

const Waitlist = ({ showWaitlist }) => {
  const [isYes, setIsYes] = useState(false)
  const { register } = useFormContext()
  if (!showWaitlist) return null

  return (
    <>
      <Typography variant="h6" align="center" gutterBottom>
        Waitlist
      </Typography>
      <Box maxWidth="520px" mb={4} textAlign="center">
        <Typography variant="body" style={{ lineHeight: 1.5 }}>
          Would you like to collect a waitlist for this event? We will contact
          students if a spot becomes available.
        </Typography>
      </Box>
      <Box mb={6}>
        <YesNoButtons {...{ isYes, setIsYes }} dataCy="waitlistToggle" />
        <input
          name="waitlistEnabled"
          type="hidden"
          ref={register}
          value={isYes}
        />
      </Box>
    </>
  )
}

export default Waitlist
