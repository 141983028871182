import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { gtmEvent } from 'lib/gtm'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Container from '@material-ui/core/Container'
import LinkIcon from '@material-ui/icons/Link'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useAuth } from 'lib/auth'
import useStyles from './schedule.styles'
import SettingsBox from 'components/coach-settings-box'
import Disclaimer from 'components/disclaimer'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Error from 'components/error'
import { useSnackbar } from 'notistack'
import ButtonCta from 'components/atoms/button-cta'
import ExternalLinkButton from 'components/external-link-button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import SocialShareDialog from 'components/social-share-dialog'
import CheckIcon from '@material-ui/icons/Check'

const COACH_PREFERENCES = gql`
  query CoachSettings($id: ID!) {
    coach(id: $id) {
      preferences {
        bookNowEnabled
      }
    }
  }
`

const TOGGLE_BOOKNOW_PREF = gql`
  mutation UpdateBookNow($bookNow: Boolean!) {
    coachBookNowPreference(bookNowEnabled: $bookNow) {
      success
      message
      coach {
        preferences {
          bookNowEnabled
        }
      }
    }
  }
`

const BookNowToggle = ({ enabled }) => {
  const [bookNowEnabled, setState] = useState(enabled)
  const [toggle] = useMutation(TOGGLE_BOOKNOW_PREF)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Switch
      checked={bookNowEnabled}
      onChange={event => {
        toggle({
          variables: { bookNow: event.target.checked },
        }).then(({ data: { coachBookNowPreference: op } }) => {
          enqueueSnackbar(op.message, {
            variant: op.success ? 'success' : 'error',
          })
          setState(op.coach.preferences.bookNowEnabled)
        })
      }}
    />
  )
}

const ScheduleOnboarding = ({ onboardingSchedule }) => {
  const styles = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const settingsBlocks = [
    {
      title: 'Set your facility:',
      blocks: [
        {
          link: '/pga-coach/facilities/new',
          disabled: false,
          isCompleted: onboardingSchedule?.isFacilitiesCreated,
          title: 'Where are you hosting your offerings?',
          subtitle:
            'Associate a facility to your account. This facility’s address will also help golfers find your Coach Profile on PGA.com.',
          gtmEvent: {
            event: 'onboarding-click',
            onboardingCategory: 'onboarding-schedule',
            onboardingLabel: 'create-facility',
            onboardingAction: 'click-create-facility',
          },
        },
      ],
    },
    {
      title: 'Set up a group offering:',
      blocks: [
        {
          link: '/pga-coach/bookings/events/new',
          disabled: !onboardingSchedule?.isFacilitiesCreated,
          isCompleted: onboardingSchedule?.isGroupEventCreated,
          title: 'Publish your first group event',
          subtitle:
            'Start with a template or create your own. Some examples are Whiskey & Wedges, 9-Holes with a PGA Coach and Night Glow Golf. If you want to add another one in the future, use the My Offerings link.',
          gtmEvent: {
            event: 'onboarding-click',
            onboardingCategory: 'onboarding-schedule',
            onboardingLabel: 'create-group-event',
            onboardingAction: 'click-create-group-event',
          },
        },
      ],
    },
    {
      title: 'Create a 1:1 private offering:',
      blocks: [
        {
          link: '/pga-coach/settings/availability',
          disabled: !onboardingSchedule?.isFacilitiesCreated,
          isCompleted: onboardingSchedule?.isAvailable,
          title: 'Set your working hours',
          subtitle:
            'Private sessions will only be offered within your available working hours at your facility. Set your availability before you create your booking type.',
          gtmEvent: {
            event: 'onboarding-click',
            onboardingCategory: 'onboarding-schedule',
            onboardingLabel: 'enable-availability',
            onboardingAction: 'click-enable-availability',
          },
        },
        {
          link: '/pga-coach/bookings/booking-types/new',
          disabled:
            !onboardingSchedule?.isAvailable ||
            !onboardingSchedule.hasCoachProfile,
          isCompleted: onboardingSchedule?.isBookingTypeCreated,
          title: 'Publish your first booking type',
          subtitle: onboardingSchedule.hasCoachProfile ? (
            'Choose from predefined categories like Intro, Private and Swing Evaluation, or create your own. If you want to manage your booking types or add more in the future, use the My Offerings link.'
          ) : (
            <>
              {
                'Before publishing your booking type, please make sure you have first '
              }
              <strong>{'created your Coach Profile.'}</strong>
            </>
          ),
          gtmEvent: {
            event: 'onboarding-click',
            onboardingCategory: 'onboarding-schedule',
            onboardingLabel: 'create-booking-type',
            onboardingAction: 'click-create-booking-type',
          },
          cta: !onboardingSchedule.hasCoachProfile && (
            <ButtonCta
              variant="text"
              arrow
              href={'/pga-coach/profile/'}
              className={styles.ctaButton}
            >
              Coach Profile
            </ButtonCta>
          ),
        },
      ],
    },
  ]

  const {
    user: {
      coach: { id: coachId },
    },
    user,
    setUserProperties,
  } = useAuth()

  const { data, loading, error } = useQuery(COACH_PREFERENCES, {
    variables: { id: coachId },
  })

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />
  return (
    <>
      <Container className={styles.root}>
        <Disclaimer
          icon={<HelpOutlineIcon fontSize="large" />}
          description="If you need assistance setting up your PGA Schedule, dive into our support articles for expert guidance."
          action={
            <ExternalLinkButton
              variant={isMobile ? 'text' : 'outlined'}
              color="primary"
              href="https://support.pga.com/en/articles/5610289-getting-started-with-pga-coach"
            >
              Learn More
            </ExternalLinkButton>
          }
        />
        <Grid container spacing={2}>
          {settingsBlocks.map(block => (
            <>
              <Typography variant="h6" gutterBottom className={styles.heading}>
                {block.title}
              </Typography>
              <Grid container spacing={2}>
                {block.blocks.map(item => {
                  const settingsBox = (
                    <SettingsBox
                      icon={
                        <div className={styles.icon}>
                          <CheckIcon />
                        </div>
                      }
                      title={item.title}
                      subtitle={item.subtitle}
                      action={<ChevronRightIcon className={styles.action} />}
                      isCompleted={item.isCompleted}
                      cta={item.cta}
                    />
                  )
                  return (
                    <Grid item xs key={item.title}>
                      {!item.disabled ? (
                        <RouterLink
                          className={`${styles.mainWrapper}`}
                          to={item.link}
                          onClick={() => {
                            gtmEvent(item.gtmEvent)
                            setUserProperties({ onboarding: true })
                          }}
                        >
                          {settingsBox}
                        </RouterLink>
                      ) : (
                        <Box className={`${styles.mainWrapper}`}>
                          {settingsBox}
                        </Box>
                      )}
                    </Grid>
                  )
                })}
              </Grid>
            </>
          ))}

          <Grid container spacing={2} style={{ marginTop: '24px' }}>
            <Typography variant="h6" gutterBottom className={styles.heading}>
              Ready to share your offerings?
            </Typography>
            <Grid item xs={12} className={styles.clickable}>
              <SocialShareDialog
                trigger={
                  <Box className={styles.settingsWrapper}>
                    <div className={styles.icon}>
                      <LinkIcon />
                    </div>
                    <Box className={styles.settingsCaption}>
                      <Typography variant="subtitle1">
                        Share Booking Link with Your Students
                      </Typography>
                      <Typography variant="body2">
                        Copy your Coach Offerings link and share it via text,
                        email, or social media.
                      </Typography>
                    </Box>
                  </Box>
                }
                shareUrl={
                  data?.currentUser?.attributes?.bookingUrl ||
                  user?.coach?.bookingUrl
                }
                shareTitle="My Offerings"
                dialogTitle="Share this booking link with your students"
                showBookingUrl={true}
                att="booking-link"
              />
            </Grid>
            <Grid item xs={12}>
              <SettingsBox
                icon={
                  <BookNowToggle
                    enabled={data.coach.preferences.bookNowEnabled}
                  />
                }
                title="Display the “Book Now” button on your profile"
                subtitle="Your offerings are shareable already, but they’re not available to the public via your Coach Profile until you enable this button."
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ScheduleOnboarding
