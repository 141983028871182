import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { PGA_COM_HOST } from 'env'
import { formFields } from '../constants'
import useStyles, {
  CardRoot,
  HelperMsg,
  WebAddressLink,
  ProfileExamples,
} from '../profile.styles'
import CustomSlug from './CustomSlug'
import SuggestedSlug from './SuggestedSlug'
import ExternalLinkButton from 'components/external-link-button'
import { useIsMobile } from 'lib/hooks'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

const firstLastTmpl = (a, b) => `${a}${b}`
const firstLastDashTmpl = (a, b) => `${a}-${b}`
const firstLastPGATmpl = (a, b) => `${a}${b}PGA`
const firstCharLastTmpl = (a, b) => `${a.charAt(0)}${b}`

const ProfileNameTmpls = [
  firstLastTmpl,
  firstLastDashTmpl,
  firstLastPGATmpl,
  firstCharLastTmpl,
]

const getSuggestions = (a = '', b = '', templateIndex) =>
  ProfileNameTmpls[templateIndex](a.toLowerCase(), b.toLowerCase())

const WebAddress = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    slugError,
    setFieldValue,
    setSlugError,
    newProfile,
  } = props

  const { customSlug, firstName, lastName } = values
  const classes = useStyles()
  const [copySuccessMessage, setCopySuccessMessage] = useState('')
  const [suggestedIndex, setSuggestedIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(
    !customSlug && customSlug === '',
  )
  const clipboardInputRef = useRef(null)
  const isMobile = useIsMobile()

  const copyToClipboard = e => {
    // select and copy text
    clipboardInputRef.current.type = 'text'
    clipboardInputRef.current.select()
    document.execCommand('copy')
    clipboardInputRef.current.type = 'hidden'

    // Don't show the the whole text area selected.
    e.target.focus()
    setCopySuccessMessage('Copied!')
  }

  const handleSuggestionClick = () => {
    setSuggestedIndex(prevState =>
      prevState >= ProfileNameTmpls.length - 1 ? 0 : prevState + 1,
    )
  }

  const handleUseClick = () => {
    setFieldValue(
      'customSlug',
      getSuggestions(firstName, lastName, suggestedIndex),
    )
    setShowSuggestions(false)
  }

  const handleShowSuggestionClick = () => {
    setShowSuggestions(true)
  }

  const exampleProfiles = ['Suzywhaley', 'toddsones', 'trillium']

  return (
    <CardRoot style={{ alignItems: 'flex-start' }}>
      <Typography variant="subtitle1">
        {newProfile ? 'Set Your Profile Name' : 'Your Profile Name'}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ textAlign: isMobile ? 'left' : 'center' }}
      >
        {newProfile
          ? 'Your profile will be shown on PGA.com. Where would you like your personal page to be located?'
          : 'Your coach profile is live on PGA.com. Click below to copy your unique link or to view your profile.'}
      </Typography>
      {newProfile ? (
        <CustomSlug
          {...{
            newProfile,
            values,
            slugError,
            setSlugError,
            handleChange,
            handleBlur,
            touched,
            errors,
          }}
        />
      ) : (
        <>
          <form>
            <input
              ref={clipboardInputRef}
              type="hidden"
              value={`${PGA_COM_HOST}/coach/${values.customSlug}`}
            />
          </form>
          <Box my={3} style={{ width: '100%' }}>
            <WebAddressLink onClick={copyToClipboard} variant="h6">
              <span>{`${PGA_COM_HOST}/coach/${values.customSlug}`}</span>
              <FileCopyOutlinedIcon
                aria-label="copy"
                fontSize="small"
                className={classes.icon}
              />
            </WebAddressLink>
            {copySuccessMessage && (
              <HelperMsg
                style={{
                  textAlign: 'center',
                  lineHeight: 0,
                  margin: '0.5rem 0 0 0',
                }}
              >
                {copySuccessMessage}
              </HelperMsg>
            )}
          </Box>
        </>
      )}
      {!newProfile && (
        <ExternalLinkButton
          variant="outlined"
          color="primary"
          href={`${PGA_COM_HOST}/coach/${values.customSlug}`}
        >
          View Profile
        </ExternalLinkButton>
      )}
      {newProfile && (
        <>
          {showSuggestions ? (
            <>
              <SuggestedSlug
                onSubmit={handleUseClick}
                onShuffle={handleSuggestionClick}
                templateHandlerResult={getSuggestions(
                  firstName,
                  lastName,
                  suggestedIndex,
                )}
              />
              <h4>Example Coach Profiles</h4>
              <ProfileExamples>
                {exampleProfiles.map(example => (
                  <li key={example}>
                    <a
                      href={`https://www.pga.com/coach/${example}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.pga.com/coach/
                      <span className="highlight">{example}</span>
                    </a>
                  </li>
                ))}
              </ProfileExamples>
            </>
          ) : (
            <Typography>
              <Button onClick={handleShowSuggestionClick}>
                Show Suggestions
              </Button>
            </Typography>
          )}
        </>
      )}
    </CardRoot>
  )
}

WebAddress.propTypes = {
  values: formFields,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  slugError: PropTypes.string,
  setFieldValue: PropTypes.func,
  setSlugError: PropTypes.func,
  newProfile: PropTypes.bool,
}

WebAddress.defaultProps = {}

export default WebAddress
