import React, { useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useAuth } from 'lib/auth'
import { ExperimentFlags } from 'lib/feature-flags'

const LogoutPage = () => {
  const auth = useAuth()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    ExperimentFlags.clear()
    auth.logout()
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <LinearProgress color="primary" />
}

export default LogoutPage
