import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import InitialsAvatar from 'components/avatars/initials-avatar/index'
import { useStyles } from './styles'
import PropTypes from 'prop-types'

const StudentCard = ({ studentName }) => {
  const classes = useStyles()
  return (
    <Grid container item xs={12} sm={6} className={classes.studentCard}>
      <InitialsAvatar name={studentName} classes={classes} />
      <div className={classes.infoWrapped}>
        <Typography
          variant="caption"
          className={`${classes.fontBolded} ${classes.captionText}`}
          data-testid="student-name"
        >
          {studentName}
        </Typography>
        <Typography
          variant="caption"
          className={classes.captionText}
          color="textSecondary"
        >
          Student
        </Typography>
      </div>
    </Grid>
  )
}

export default StudentCard

StudentCard.propTypes = {
  studentName: PropTypes.string,
}
