import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  titleStyles: {
    padding: '40px',
    height: '25px',
  },
  paperWrapper: {
    width: '625px',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarsWrapped: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
  },
  avatarStyles: {
    height: '75px',
    width: '75px',
    margin: '0 20px',
  },
  textStyles: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '60%',
    margin: '30px 0',
    lineHeight: '1.25',
  },
}))
