import React from 'react'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

const AlertWithDivider = ({ text }) => {
  const classes = useStyles()

  return (
    <Alert severity="info" className={classes.warningBox}>
      <Box display="flex" height="100%">
        <Divider orientation="vertical" className={classes.verticalDivider} />
        <Typography variant="body2">{text}</Typography>
      </Box>
    </Alert>
  )
}

export default AlertWithDivider
