import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  Popper,
  Grow,
  ClickAwayListener,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

const FilterDropdown = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
}) => {
  const [pendingSelectedFilter, setPendingSelectedFilter] = useState(
    selectedFilter,
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)

  const handleClose = () => {
    setAnchorEl(null)
    setFilterMenuOpen(false)
  }

  useEffect(() => {
    setPendingSelectedFilter(selectedFilter)
  }, [selectedFilter])

  const toggleFilterSelection = filterOptionKey => {
    setPendingSelectedFilter(filterOptionKey)
  }

  const filterBoxes = (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
    >
      <Grid item xs={12} sm={6} style={{ minWidth: '100%' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Filters</FormLabel>
          <Divider style={{ margin: '8px 8px 0 0' }} />
          <RadioGroup
            aria-label="filter"
            name="filter"
            value={pendingSelectedFilter}
            onChange={event => toggleFilterSelection(event.target.value)}
          >
            {filterOptions.map(opt => {
              const key = opt.name
              return (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={opt.description}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )

  return (
    <Box style={{ position: 'relative', display: 'inline-block' }}>
      <Button
        id="contact-filters"
        aria-controls={filterMenuOpen ? 'contact-filters-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={filterMenuOpen ? 'true' : undefined}
        variant="outlined"
        color="default"
        size="medium"
        disableElevation
        onClick={e => {
          setFilterMenuOpen(!filterMenuOpen)
          setAnchorEl(e.currentTarget)
        }}
        endIcon={<ArrowDropDown />}
        disableRipple
        style={{
          textTransform: 'none',
          fontWeight: 400,
          height: '56px',
          width: '115px',
          padding: 0,
          justifyContent: 'space-around',
        }}
      >
        <Typography variant="body1">Filters</Typography>
      </Button>
      <Popper
        id="contact-filters-menu"
        open={filterMenuOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 50000 }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Card
                variant="outlined"
                aria-labelledby="demo-customized-button"
                style={{
                  padding: '8px',
                  minWidth: '200px',
                  opacity: '100%',
                }}
                open={filterMenuOpen}
              >
                <CardContent style={{ padding: '0 !important' }}>
                  {filterBoxes}
                </CardContent>
                <CardActions
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedFilter(pendingSelectedFilter)
                      setFilterMenuOpen(false)
                    }}
                  >
                    Apply
                  </Button>
                </CardActions>
              </Card>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default FilterDropdown
