export const golfHistoryOptions = [
  {
    value: 'Beginner',
    label: 'Beginner',
  },
  {
    value: 'Somewhat familiar',
    label: 'Somewhat familiar',
  },
  {
    value: 'Frequent player',
    label: 'Frequent player',
  },
  {
    value: 'Returning to the game',
    label: 'Returning to the game after 5 years or more',
  },
]

export const shirtSizeOptions = [
  {
    label: 'Small',
    value: 'Small',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Large',
    value: 'Large',
  },
  {
    label: 'XL',
    value: 'XL',
  },
  {
    label: 'XXL',
    value: 'XXL',
  },
  {
    label: '3XL',
    value: '3XL',
  },
]

export const branchOfServiceOptions = [
  { label: 'Army', value: 'Army' },
  { label: 'Navy', value: 'Navy' },
  { label: 'Air Force', value: 'Air Force' },
  { label: 'Marine Corps', value: 'Marine Corps' },
  { label: 'Coast Guard', value: 'Coast Guard' },
  { label: 'Space Force', value: 'Space Force' },
]

export const militaryStatusOptions = [
  { label: 'Active Duty', value: 'Active Duty' },
  { label: 'Reserve', value: 'Reserve' },
  { label: 'National Guard', value: 'National Guard' },
  { label: 'Veteran', value: 'Veteran' },
]

export const referralSourcesOptions = [
  {
    label: 'Department of Veterans Affairs',
    value: 'Department of Veterans Affairs',
  },
  { label: 'Friend/Family', value: 'Friend/Family' },
  { label: 'TV', value: 'TV' },
  { label: 'Social Media', value: 'Social Media' },
  { label: 'News', value: 'News' },
  { label: 'Google Search', value: 'Google Search' },
  { label: 'PGA HOPE Ambassador', value: 'PGA HOPE Ambassador' },
]

export const ownClubOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
