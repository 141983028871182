import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  useTheme,
} from '@material-ui/core'

const Unlisted = props => {
  const theme = useTheme()
  const { control, watch } = useFormContext()
  const currentSetting = watch('unlisted')

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      style={{ marginTop: '40px' }}
    >
      <Typography variant="subtitle1" gutterBottom>
        Visibility on PGA.com
      </Typography>
      <Controller
        name="unlisted"
        control={control}
        render={({ onChange }) => (
          <RadioGroup
            name="unlisted"
            defaultValue={currentSetting.toString()}
            onChange={onChange}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Listed"
            />
            <Typography
              variant="body2"
              gutterBottom
              style={{
                marginLeft: '32px',
                color: theme.palette.text.secondary,
              }}
            >
              Listed events will be discoverable through the event search at{' '}
              <Link
                href="https://pga.com/things-to-do"
                target="_blank"
                rel="noopener noreferrer"
                color="info"
              >
                pga.com/things-to-do
              </Link>
              .
            </Typography>
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Unlisted"
            />
            <Typography
              variant="body2"
              gutterBottom
              style={{
                marginLeft: '32px',
                color: theme.palette.text.secondary,
              }}
            >
              Unlisted events will not show up in searches. You will need to
              provide a link for attendees to register.
            </Typography>
          </RadioGroup>
        )}
      ></Controller>
    </Box>
  )
}

export default Unlisted
