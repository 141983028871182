import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'
import YesNoButtons from '../form-components/yes-no-buttons'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { flags, useFeatureFlags } from 'lib/feature-flags'

export const registrationCloseDates = [
  {
    hoursBefore: 0,
    label: 'When the event starts',
  },
  {
    hoursBefore: 1,
    label: '1 hour before event',
  },
  {
    hoursBefore: 6,
    label: '6 hours before event',
  },
  {
    hoursBefore: 24,
    label: '1 day before event',
  },
  {
    hoursBefore: 168,
    label: '1 week before event',
  },
]

const ExternalRegistrationUrl = () => {
  const { register, errors, control, watch, setValue } = useFormContext()
  const [isRsvpEnabled] = useFeatureFlags([flags.FLAG_FEAT_GROUP_EVENT_RSVP])
  const isExternalRegistration = watch('isExternalRegistration')

  if (isExternalRegistration) {
    setValue('unlisted', 'false')
  }

  return (
    <>
      <Box mb={6}>
        <Typography variant="h4" align="center">
          Do you want to use PGA Coach to collect registrations?
        </Typography>
      </Box>
      <Controller
        name="isExternalRegistration"
        control={control}
        render={({ onChange, value }) => (
          <>
            <Box
              mb={8}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <YesNoButtons
                isYes={!value}
                setIsYes={newValue => onChange(!newValue)}
              />
            </Box>
            {value ? (
              <>
                <Container maxWidth="sm">
                  <Box mb={1}>
                    <Typography variant="h5" align="center">
                      Enter your registration URL
                    </Typography>
                  </Box>
                  <Box mb={6}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="center"
                    >
                      Your offering will still be marketed on
                      PGA.com/things-to-do however, registrants will be
                      redirected to the URL you provide below.
                    </Typography>
                  </Box>
                  <FormControl
                    error={!!errors.externalRegistrationUrl}
                    variant="outlined"
                  >
                    <TextField
                      label="Enter registration URL"
                      placeholder="https://www.example.com"
                      name="externalRegistrationUrl"
                      inputRef={register}
                      error={!!errors?.externalRegistrationUrl}
                      helperText={errors?.externalRegistrationUrl?.message}
                    />
                  </FormControl>
                </Container>
              </>
            ) : (
              <Container maxWidth="xs">
                <Box mb={4}>
                  <Typography variant="h5" align="center">
                    When should registration close?
                  </Typography>
                </Box>
                <FormControl
                  error={!!errors.hoursBeforeRegistrationCloses}
                  variant="outlined"
                  fullWidth
                  required
                >
                  <InputLabel>Registration Close</InputLabel>
                  <Controller
                    name="hoursBeforeRegistrationCloses"
                    control={control}
                    as={
                      <Select
                        labelId="hoursBeforeRegistrationCloses"
                        label="Registration Close *"
                        data-cy={'hoursBeforeRegistrationCloses'}
                      >
                        {registrationCloseDates.map(
                          ({ hoursBefore, label }) => (
                            <MenuItem key={label} value={hoursBefore}>
                              {label}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    }
                  />
                  {errors.hoursBeforeRegistrationCloses ? (
                    <Typography variant="caption" color="error">
                      Please select an option
                    </Typography>
                  ) : null}
                </FormControl>
              </Container>
            )}
          </>
        )}
      />
      {isRsvpEnabled && (
        <Controller
          name="collectAttendance"
          control={control}
          render={({ onChange, value }) => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={8}
              mb={4}
            >
              <Typography
                variant="h5"
                align="center"
                style={{ marginBottom: '32px' }}
              >
                Do you want to collect RSVPs?
              </Typography>
              <YesNoButtons
                isYes={value}
                setIsYes={newValue => onChange(newValue)}
              />
            </Box>
          )}
        />
      )}
      <input name="unlisted" type="hidden" ref={register()} />
    </>
  )
}

export default ExternalRegistrationUrl
