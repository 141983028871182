import React from 'react'
import useStyles from './styles'
import { useFormContext } from 'react-hook-form'
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import ButtonCta from 'components/atoms/button-cta'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const SingleOrLessonPack = ({
  isLessonPacks,
  setIsLessonPacks,
  paymentAccounts,
}) => {
  const classes = useStyles()
  const { register } = useFormContext()
  return (
    <Box mb={3}>
      <Typography variant="body2" gutterBottom color="textSecondary">
        Select whether you want to create a single booking type or pack of
        multiple lessons.
      </Typography>
      <FormControl>
        <RadioGroup aria-labelledby="Lesson Type">
          <FormControlLabel
            checked={!isLessonPacks}
            control={<Radio color="primary" />}
            label={
              <Box ml={2}>
                <Typography variant="subtitle1">Booking Type</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Online or Offline Payments
                  <br />
                  Best for One-Time Lesson
                  <br />
                  Students Book at Time of Purchase
                </Typography>
              </Box>
            }
            data-cy="single"
            onClick={() => setIsLessonPacks(false)}
          />
          <FormControlLabel
            checked={isLessonPacks}
            control={<Radio color="primary" />}
            label={
              <Box ml={2}>
                <Typography variant="subtitle1">Lesson Pack</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Online Payments Required
                  <br />
                  Best for Multi-Session Coaching
                  <br />
                  Offer Different Quantity Packages
                  <br />
                  Students can Purchase Now, Book Later
                </Typography>
              </Box>
            }
            data-cy="lessonPack"
            onClick={() => setIsLessonPacks(true)}
          />
        </RadioGroup>
      </FormControl>
      <input
        name="isLessonPacks"
        type="hidden"
        ref={register()}
        value={isLessonPacks}
      />
      {isLessonPacks && (
        <Box className={classes.infoWrapper}>
          <Typography variant="body2">
            The ability to sell Lesson Packs through PGA Coach is offered to
            coaches as a convenience. It is the individual coach's
            responsibility to establish the terms and conditions of such sales
            and ensure that the sale of Lesson Packs is compliant with
            applicable law.
          </Typography>
        </Box>
      )}
      {isLessonPacks && paymentAccounts.length === 0 && (
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          className={classes.infoWrapper}
        >
          <Box className={classes.infoIconWrapper}>
            <InfoIcon />
          </Box>
          <Box className={classes.informationContainer}>
            <Typography variant="body2">
              Heads up! Offering Lesson Packs requires you have an online
              payment account set up. Get set up with online payments now.
            </Typography>
            <ButtonCta
              variant="text"
              arrow
              href={'/account/payments/payment-accounts'}
              className={classes.ctaButton}
            >
              Add a Payment Account
            </ButtonCta>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default SingleOrLessonPack
