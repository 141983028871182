import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  warnWrapper: {
    background: theme.palette.primary.selected,
    padding: '24px 16px 24px 16px',
    [theme.breakpoints.only('xs')]: {
      padding: '16px 0px 16px 16px',
    },
  },
  dialogActions: {
    margin: '32px 8px 24px 0px',
    [theme.breakpoints.only('xs')]: {
      margin: '8px 8px 8px 0px',
    },
    justifyContent: 'flex-end',
  },
  adornedEnd: {
    '& button': {
      padding: 0,
    },
  },
  base: {
    background: 'rgb(255, 245, 229)',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ul: {
    marginTop: '4px',
    marginBottom: '0',
  },
  body: {
    padding: '8px 4px',
    justifyContent: 'start',
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
  },
}))
