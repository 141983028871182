import React from 'react'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(17),
    background: '#f7f7f7',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0),
    },
  },
  content: {
    backgroundColor: 'white',
    maxWidth: '600px',
    height: '346px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.primary.light,
    backgroundColor: '#f7f7f7',
    borderRadius: '50%',
    padding: '20px',
    marginBottom: theme.spacing(4),
  },
  title: {
    padding: '0 3.5rem 1rem',
    color: '#000',
    fontWeight: '700',

    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem 1rem',
    },
  },
  description: {
    padding: '0 3.5rem 0',
    fontFamily: 'Nunito sans',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem 1rem',
    },
  },
}))

const CanceledPaymentRequest = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box mb={8} className={classes.content}>
        <MoneyOffIcon className={classes.icon} />
        <Typography
          align="center"
          variant="h4"
          className={classes.title}
          data-testid="canceled-payment-request-title"
        >
          Payment Request Canceled
        </Typography>
        <Typography
          align="center"
          variant="body1"
          className={classes.description}
        >
          Your coach has canceled this payment request for this session. For
          further details, please reach out to them directly.
        </Typography>
      </Box>
    </div>
  )
}
export default CanceledPaymentRequest
