import React, { lazy, Suspense, useState } from 'react'
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import { LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Layout from 'pages/family-cup-marketing/layout'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { FG_MARKETING_SITE_URL } from 'env'

const EventSearch = lazy(() =>
  import('pages/family-cup-marketing/event-search'),
)
const EventDetails = lazy(() =>
  import('pages/family-cup-marketing/event-details'),
)

const FCMarketingRoutes = () => {
  const { path } = useRouteMatch()
  const location = useLocation()
  const [title, setTitle] = useState('')

  return (
    <ThemeProvider theme={extendTheme(themes.familyCupMarketing)}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Helmet>
          <title> {title && `${title} | `} PGA Family Golf Management</title>
          <meta name="description" content="PGA Family Golf Management" />
          <link rel="icon" href="/family-golf-favicon.ico" />
          <link
            rel="canonical"
            href={`${FG_MARKETING_SITE_URL}/${location.pathname}`}
          ></link>
        </Helmet>
        <Layout>
          <Suspense fallback={<LinearProgress color="secondary" />}>
            <Switch>
              <Route exact path={`${path}/search`}>
                <EventSearch />
              </Route>
              <Route exact path={`${path}/:id`}>
                <EventDetails setTitle={setTitle} />
              </Route>
            </Switch>
          </Suspense>
        </Layout>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default FCMarketingRoutes
