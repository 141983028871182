import React from 'react'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import WarningIcon from '@material-ui/icons/Warning'

const formTipStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: alpha('#FF9800', 0.2),
    alignItems: 'center',
  },
  tipContainer: {
    borderRightColor: alpha(lighten(theme.palette.primary.dark, 0.1), 0.24),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    marginRight: theme.spacing(2),
    padding: '4px',
  },
  children: {
    flexGrow: '1',
    fontSize: '14px',
  },
}))

export default function WarningFormTip({ children }) {
  const styles = formTipStyles()
  return (
    <div className={styles.container}>
      <div className={styles.tipContainer}>
        <WarningIcon style={{ width: 50, height: 60, color: '#FF9800' }} />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}
