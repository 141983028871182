import { formatNameForDisplay } from 'lib/text-display'
import { DateTime } from 'luxon'

export const parseStudents = (students, sessions) => {
  return students.map(student => {
    const nextSession = findUpcomingSession(student.id, sessions)
    return {
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      fullName: formatNameForDisplay(
        `${student.firstName} ${student.lastName}`,
      ),
      hasAccount: student.hasAccount,
      isMinor: student.isMinor,
      upcomingSession: nextSession,
      representatives: student.representatives,
    }
  })
}

export const findUpcomingSession = (studentId, sessions) => {
  const upcoming = sessions.currentUser.schedule.find(
    session =>
      session.enrollments[0]?.student.id === studentId &&
      session.isCanceled === false,
  )

  if (upcoming) {
    return {
      humanizedStartDateTime: formatDate(
        upcoming.startDateTime,
        upcoming.timezone,
      ),
      ...upcoming,
    }
  } else {
    return {
      __typename: null,
      humanizedStartDateTime: null,
      title: null,
      startDateTime: null,
    }
  }
}

const formatDate = (startDateTime, timezone) => {
  return DateTime.fromISO(startDateTime)
    .setZone(timezone)
    .toFormat('MMM d, yyyy, h:mma')
}
