import { styled, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

export const ContainerRoot = styled(Container)(({ theme }) => ({
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(21),
  padding: `${theme.spacing(2)}px`,
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
  },
  '& h4': {
    fontWeight: 700,
  },
  '& p': {
    color: theme.palette.text.secondary,
  },
}))

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '2rem',
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
    fontSize: '1rem',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '16px',
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  ctaButton: {
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      padding: '8px',
    },
  },
  divider: {
    margin: '2rem 0px 1rem',
  },
  fontBolded: {
    fontWeight: 600,
  },
  icons: {
    color: theme.palette.secondary.dark,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  list: {
    '& li': {
      lineHeight: '.75rem',
    },
  },
  textWrapper: {
    padding: 0,
    margin: '1rem',
  },
  studentCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    border: '1px solid #EEE',
    height: '80px',
    padding: '16px',
  },
  successIcon: {
    borderRadius: '50%',
    background: 'rgba(0,25,75,.08)',
    padding: '1rem',
    color: theme.palette.primary.main,
    width: '83px',
    height: '83px',
    boxSizing: 'border-box',
    margin: '12px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '36px',
    },
  },
}))
