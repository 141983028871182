import React, { useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import {
  HelperMsg,
  PhotoSelectorPhoto,
  PhotoSelector,
  ErrorMsg,
} from 'components/coaches-profile/profile.styles'
import Button from '@material-ui/core/Button'
import { IconTrashcan } from 'components/atoms/icons'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import uploadImage from 'lib/cloudinary/uploadImage'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET_LARGE } from 'env'

const useStyles = makeStyles(theme => ({
  photoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
}))

const MAX_PHOTOS_COUNT = 8
const photosMsg = `Add up to ${MAX_PHOTOS_COUNT} photos.`

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET_LARGE,
  cropping: true,
  multiple: false,
  croppingAspectRatio: 1.7,
  showSkipCropButton: false,
  sources: ['local', 'camera'],
  resourceType: 'image',
  folder: 'dot-com-images',
  showPoweredBy: false,
}

export const Photos = () => {
  const classes = useStyles()
  const { control, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'photosJsonData',
    control,
  })
  const [uploadError, setUploadError] = useState(null)
  const [checkedPhotos, setCheckedPhotos] = useState([])

  const handleImageUpload = async () => {
    try {
      const ref = await uploadImage(uploadOptions)
      append({ rank: fields.length + 1, reference: ref })
      setUploadError(null)
    } catch (e) {
      setUploadError(e.statusText)
    }
  }

  const handleCheckboxToggle = (isChecking, rank) => {
    if (isChecking) {
      setCheckedPhotos(photos => [rank, ...photos])
    } else {
      const rankIndex = checkedPhotos.findIndex(photo => photo === rank)
      const checked = [...checkedPhotos]
      if (rankIndex !== -1) {
        checked.splice(rankIndex, 1)
        setCheckedPhotos(checked)
      }
    }
  }

  const handlePhotosDelete = deleteIndex => {
    if (deleteIndex >= 0) {
      remove(deleteIndex)
    } else {
      // else delete all checked photos
      checkedPhotos.forEach(rank => {
        remove(rank - 1)
      })
      setCheckedPhotos([])
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      {uploadError ? (
        <ErrorMsg>{uploadError}</ErrorMsg>
      ) : (
        <HelperMsg style={{ marginTop: 0 }}>{photosMsg}</HelperMsg>
      )}
      <Box className={classes.photoWrapper}>
        {fields.map((photo, i) => {
          return (
            <PhotoSelector
              key={photo.id}
              isSelected={checkedPhotos.find(pic => pic === photo.rank)}
              onClick={() =>
                handleCheckboxToggle(
                  !checkedPhotos.find(pic => pic === photo.rank),
                  photo.rank,
                )
              }
            >
              <PhotoSelectorPhoto src={photo.reference} alt="Photos Preview" />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  marginLeft: '-2px',
                }}
              >
                <Checkbox
                  className="checkbox"
                  checked={!!checkedPhotos.find(pic => pic === photo.rank)}
                  color="secondary"
                />
                <IconButton
                  style={{ padding: 0, margin: '8px -6px 8px 8px' }}
                  onClick={() => handlePhotosDelete(i)}
                >
                  <IconTrashcan />
                </IconButton>
              </Box>
              <input
                type="hidden"
                ref={register()}
                name={`photosJsonData[${i}].rank`}
                value={i + 1}
              />
              <input
                type="hidden"
                ref={register()}
                name={`photosJsonData[${i}].reference`}
                value={photo.reference}
              />
            </PhotoSelector>
          )
        })}
      </Box>
      <Box>
        <Button
          style={{ marginTop: '16px' }}
          variant="outlined"
          color="primary"
          size="large"
          type="button"
          onClick={() => handleImageUpload()}
          disabled={fields.length >= MAX_PHOTOS_COUNT}
        >
          Upload Photo
        </Button>
        {checkedPhotos.length > 0 && (
          <Button
            style={{ marginTop: '16px' }}
            variant="text"
            color="primary"
            size="large"
            type="button"
            onClick={handlePhotosDelete}
          >
            Delete
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Photos
