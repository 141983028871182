import React from 'react'
import { PGA_COM_HOST } from 'env'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import PlaceIcon from '@material-ui/icons/Place'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import TodayIcon from '@material-ui/icons/Today'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LinkIcon from '@material-ui/icons/Link'
import ReactMarkdown from 'react-markdown'
import CopyTextButton from 'components/copy-text-button'
import LaunchIcon from '@material-ui/icons/Launch'
import { useStyles } from './styles'
import SessionsAccordion from 'components/group-events/sessions-accordion'
import QRCode from 'qrcode.react'
import Grid from '@material-ui/core/Grid'
import { formattedGroupEventPrice } from 'utils/groupEventUtils'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import logo from 'images/pga-hope-logo.svg'
import Disclaimer from 'components/disclaimer'
import Button from '@material-ui/core/Button'

const EventDetails = ({
  event,
  actionButtons: ActionButtons,
  participants,
  stats,
  isCoachView = false,
}) => {
  const groupEventUrl = event?.slug
  const classes = useStyles()
  const [isHopeGraduateEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_HOPE_GRADUATE,
  ])
  const lastSession = event?.sessions[event?.sessions.length - 1]
  const isPgaHopeEvent = event?.tags.includes('PGA HOPE')
  const showHopeGraduateTip =
    isPgaHopeEvent &&
    isHopeGraduateEnabled &&
    isCoachView &&
    new Date(lastSession?.endDateTime) < new Date()

  const coachGroupEventUrl = `${PGA_COM_HOST}/things-to-do/events/${groupEventUrl}?from-qr-code=true`

  return (
    <>
      <DialogTitle style={{ padding: 0 }}>
        <Box style={{ position: 'relative' }}>
          <img
            className={classes.headerImage}
            alt="event-header"
            src={event.featuredImageUrl}
          />
          {ActionButtons ? <ActionButtons /> : null}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <Grid container alignItems="center">
          {showHopeGraduateTip && (
            <Grid item xs={12}>
              <Disclaimer
                logo={logo}
                alt="pga-hope"
                description={
                  <>
                    <strong>{'Update Graduate Status'}</strong>
                    <Typography variant="body2" color="textSecondary">
                      {
                        'Designate or update the golfers as graduates using this link. '
                      }
                    </Typography>
                  </>
                }
                action={
                  <Button variant="outlined" color="primary">
                    {'Update Status'}
                  </Button>
                }
              />
            </Grid>
          )}
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              {event.title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            id="qr-code"
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Box variant="outlined" style={{ borderColor: '#ccc' }}>
              <QRCode
                data-testid="group-event-qr-code"
                value={coachGroupEventUrl}
                size={75}
                level="H"
                className={classes.qrCode}
              />
            </Box>
            <Typography variant="caption">Group Event Signup</Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" className={classes.bodyFontCaption}>
          <ReactMarkdown className={classes.bodyFontCaption}>
            {event.description}
          </ReactMarkdown>
        </Typography>
        <Box m={-0.5} pt={1}>
          {event.tags.map(tag => (
            <Box key={tag} m={0.5} display="inline-block">
              <Chip label={tag} size="small" />
            </Box>
          ))}
        </Box>
        <h3>Event Details</h3>
        <Box display="flex" alignItems="center" className={classes.eventRow}>
          <DateRangeIcon className={classes.eventRowIcon} />
          <Typography variant="body1">
            Starts on {event.eventDayAndTime}
          </Typography>
        </Box>
        {event.sessions.length > 1 && (
          <Box style={{ marginLeft: 'calc(1em + 18px)', marginTop: '-15px' }}>
            <SessionsAccordion
              sessions={event.sessions}
              timezone={event.timezone}
            />
          </Box>
        )}
        <Box display="flex" alignItems="center" className={classes.eventRow}>
          <PlaceIcon className={classes.eventRowIcon} />
          <Typography variant="body1">{event.facilityName}</Typography>
        </Box>
        {event.academy?.name ? (
          <Box display="flex" alignItems="center" className={classes.eventRow}>
            <LocationCityIcon className={classes.eventRowIcon} />
            <Typography variant="body1">{event.academy.name}</Typography>
          </Box>
        ) : null}
        <Box display="flex" alignItems="center" className={classes.eventRow}>
          <CreditCardIcon className={classes.eventRowIcon} />
          <Typography variant="body1">
            {formattedGroupEventPrice(event)}
          </Typography>
        </Box>
        {event.registrationCloseDateTime ? (
          <Box display="flex" alignItems="center" className={classes.eventRow}>
            <TodayIcon className={classes.eventRowIcon} />
            <Typography variant="body1">
              Registration closes on {event.registrationCloseText}
            </Typography>
          </Box>
        ) : null}
        <CopyTextButton
          value={event.eventUrl}
          className={classes.copyTextButtonStyled}
        >
          <Box display="flex" alignItems="center">
            <LinkIcon className={classes.eventRowIcon} />
            <Typography variant="body1" align="left">
              {event.eventUrl}
            </Typography>
          </Box>
        </CopyTextButton>
        {event.externalRegistrationUrl ? (
          <CopyTextButton
            value={event.eventUrl}
            className={classes.copyTextButtonStyled}
          >
            <Box
              display="flex"
              alignItems="center"
              style={{ margin: '10px 0' }}
            >
              <LaunchIcon className={classes.eventRowIcon} />
              <Typography variant="body1" align="left">
                {event.externalRegistrationUrl}
              </Typography>
            </Box>
          </CopyTextButton>
        ) : null}
        {!event.externalRegistrationUrl ? participants : null}
        {stats}
      </DialogContent>
    </>
  )
}

export default EventDetails
