import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAuth } from 'lib/auth'
import Typography from '@material-ui/core/Typography'
import LessonTypeCardRefresh from 'components/lesson-type-card-refresh'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import qs from 'query-string'
import { sortLessonTypes } from './utils'

const filterLessonsByLocation = (lessonTypes, selectedFacility) =>
  Object.keys(lessonTypes).reduce((acc, cv) => {
    const filteredlessons = lessonTypes[cv].filter(lessonInfo =>
      lessonInfo.locations.some(location =>
        location.id.includes(selectedFacility),
      ),
    )
    acc[cv] = filteredlessons
    return acc
  }, {})

const filterLessonsByActive = (lessonTypes, status) =>
  Object.keys(lessonTypes).reduce((acc, cv) => {
    const filteredlessons = lessonTypes[cv].filter(
      lessonInfo => lessonInfo.status === status,
    )
    acc[cv] = filteredlessons
    return acc
  }, {})

const UngroupedLessonTypes = ({
  lessonTypes,
  title,
  classes,
  selectedFacility,
  coachName,
  coachExternalId,
  lessonCreditBalances,
  isSingleSession,
  coachIsViewingOwnSchedule,
}) => {
  const { idOrSlug } = useParams()
  const { user } = useAuth()
  // get student id from query paramters
  const location = useLocation()
  const { student } = qs.parse(location.search)
  const studentQuery = student ? `&student=${student}` : ''

  const allowedToOverrideInactive =
    user?.coach?.externalId === idOrSlug ||
    user?.coach?.coachProfile?.slug === idOrSlug
  lessonTypes = allowedToOverrideInactive
    ? lessonTypes
    : filterLessonsByActive(lessonTypes, 'ACTIVE')

  const filteredLessonTypes =
    selectedFacility !== 'all'
      ? filterLessonsByLocation(lessonTypes, selectedFacility)
      : lessonTypes

  const allFilteredLessons = Object.values(filteredLessonTypes).flatMap(
    lesson => lesson,
  )

  const sortedLessons = sortLessonTypes({
    filteredLessonTypes,
    lessonCreditBalances,
  })

  return allFilteredLessons.length > 0 ? (
    <Box style={{ marginBottom: '4rem' }} data-testid="ungrouped-lesson-types">
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={4}>
        {sortedLessons.map(lessonType => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={lessonType.id}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <LessonTypeCardRefresh
              lessonInfo={lessonType}
              bookingUrl={`/coach/${idOrSlug}/schedule?lessonTypeId=${lessonType.id}&locationId=${lessonType.locationId}${studentQuery}`}
              shouldBeClickable
              coachName={coachName}
              coachExternalId={coachExternalId}
              isSingleSession={isSingleSession}
              lessonCreditBalance={lessonCreditBalances?.filter(
                lcb => lcb.lessonType.id === lessonType.id,
              )}
              coachIsViewingOwnSchedule={coachIsViewingOwnSchedule}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null
}

UngroupedLessonTypes.propTypes = {
  lessonTypes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  selectedFacility: PropTypes.string,
  coachName: PropTypes.string.isRequired,
  coachExternalId: PropTypes.string.isRequired,
  lessonCreditBalances: PropTypes.array,
  isSingleSession: PropTypes.bool,
  coachIsViewingOwnSchedule: PropTypes.bool,
}

export default UngroupedLessonTypes
