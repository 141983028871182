import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'

const EnrollmentItem = ({ enrollment }) => {
  const { student } = enrollment
  const { firstName, lastName } = student
  const { contact } = student
  const studentFullName = `${firstName} ${lastName}`
  const contactFullName = `${contact.firstName} ${contact.lastName}`
  const studentInitials = `${firstName.substring(0, 1)}${lastName.substring(
    0,
    1,
  )}`

  return (
    <Box display="flex" ml={5} alignItems="center" width="100%">
      <Box mr="30px">
        <Avatar>{studentInitials}</Avatar>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box>
          <Typography variant="body1">
            {firstName} {lastName}
          </Typography>
          {studentFullName !== contactFullName && (
            <Typography variant="body2" color="textSecondary">
              Point of Contact: {contactFullName}
            </Typography>
          )}
        </Box>
        <SensitiveInfoWrapper>
          <StreamSendMessageButton
            userIds={[contact.externalId]}
            color="primary"
            style={{ paddingRight: '0px' }}
            startIcon={<MessageOutlinedIcon style={{ color: '#043362' }} />}
          />
        </SensitiveInfoWrapper>
      </Box>
    </Box>
  )
}

EnrollmentItem.propTypes = {
  student: PropTypes.object,
}

export default EnrollmentItem
