import { formatPhoneNumber as format } from 'react-phone-number-input'

export const formatPhoneNumber = number => {
  return format(number) || number
}

// removes +1 from phone number if it exists
export const stripPhoneNumber = phoneNumber => {
  if (phoneNumber?.startsWith('+1')) {
    phoneNumber = phoneNumber.substring(2)
  }
  return phoneNumber
}
