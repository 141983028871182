import React from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  base: {
    background: '#e9f5fe',
    borderRadius: '10px',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginRight: theme.spacing(2),
  },
  logoWrapper: {
    minWidth: props => (props.size === 'small' ? 'auto' : '80px'),
    width: props => (props.size === 'small' ? 'auto' : '80px'),
    padding: props => (props.size === 'small' ? '0 16px' : '0'),
    textAlign: 'center',
  },
  logo: {
    width: '50px',
    minWidth: '50px',
  },
  body: {
    padding: props => (props.size === 'small' ? '0' : '16px 0'),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },
}))

const Disclaimer = ({
  logo,
  alt = 'Logo',
  description,
  icon,
  action,
  size,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const classes = useStyles({ size })
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={classes.base}
    >
      <Box className={classes.logoWrapper}>
        {logo && <img src={logo} alt={alt} className={classes.logo} />}
        {icon && icon}
      </Box>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <Box
        display="flex"
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        style={{ flex: '1 1 auto' }}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Typography className={classes.body}>{description}</Typography>
        {action && (
          <Box
            style={{
              minWidth: '200px',
              width: '200px',
              textAlign: isMobile ? 'left' : 'center',
            }}
          >
            {action}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Disclaimer
