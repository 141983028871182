import React, { useState, useRef } from 'react'
import { gql, useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import { withProtectedRoute, roles, ImpersonateUserDialog } from 'lib/auth'
import { useDebounce } from 'lib/hooks'

const SEARCH_USERS = gql`
  query SearchUsers($query: String!) {
    coaches(query: $query, limit: 10) {
      id
      name
      email
      coachProfile {
        profilePhoto
      }
    }
    contacts(query: $query, limit: 10) {
      id
      firstName
      lastName
      email
    }
  }
`

const UserCard = ({ user }) => {
  const fullName =
    user.__typename === 'Coach'
      ? user.name
      : `${user.firstName} ${user.lastName}`
  const { email } = user
  const avatar =
    user.__typename === 'Coach' ? user.coachProfile?.profilePhoto : null

  return (
    <Box mt={2}>
      <Card>
        <ImpersonateUserDialog
          trigger={
            <CardActionArea>
              <CardHeader
                avatar={
                  <Avatar src={avatar}>
                    {fullName
                      .split(' ')
                      .map(s => s[0])
                      .join('')
                      .substring(0, 2)}
                  </Avatar>
                }
                title={fullName}
                titleTypographyProps={{ variant: 'body1' }}
                subheader={email}
                subheaderTypographyProps={{ variant: 'body2' }}
              />
            </CardActionArea>
          }
          user={{ userType: user.__typename, id: user.id, name: fullName }}
        />
      </Card>
    </Box>
  )
}

const UserSearch = () => {
  const searchDropdownAnchorRef = useRef()

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 300).trim()
  const { data } = useQuery(SEARCH_USERS, {
    variables: { query: debouncedQuery },
    skip: debouncedQuery.length < 3,
  })
  const { coaches = [], contacts = [] } = data || {}

  return (
    <>
      <TextField
        value={query}
        onChange={e => setQuery(e.target.value)}
        ref={searchDropdownAnchorRef}
        placeholder="Search by name or email address"
      />
      <Popper
        open={data != null}
        anchorEl={searchDropdownAnchorRef.current}
        placement="bottom"
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left bottom' }}>
            <Paper
              style={{ width: searchDropdownAnchorRef.current.offsetWidth }}
            >
              <Box p={2}>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="h4">Coaches</Typography>
                    {coaches.length > 0 ? (
                      coaches.map(c => <UserCard key={c.id} user={c} />)
                    ) : (
                      <Typography variant="body1">No coaches</Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4">Consumers</Typography>
                    {contacts.length > 0 ? (
                      contacts.map(c => <UserCard key={c.id} user={c} />)
                    ) : (
                      <Typography variant="body1">No consumers</Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const AssumeUserPage = () => {
  return (
    <Container>
      <Box m={2}>
        <Box mb={2}>
          <Typography variant="h3">Assume User</Typography>
        </Box>
        <UserSearch />
      </Box>
    </Container>
  )
}

export default withProtectedRoute(AssumeUserPage, roles.SUPER_USER)
