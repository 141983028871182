import React from 'react'
import { Box } from '@material-ui/core'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => (
  <Box
    id="header"
    display="flex"
    minHeight="100vh"
    flexDirection="column"
    justifyContent="space-between"
    position="relative"
  >
    <Header />
    {children}
    <Footer />
  </Box>
)

export default Layout
