import React, { useEffect, useState } from 'react'
import { currency } from 'lib/utils/string'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useForm, FormProvider } from 'react-hook-form'
import { validationSchema } from '../utils'
import { yupResolver } from '@hookform/resolvers'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { useAuth } from 'lib/auth'
import { withStripeElements } from 'lib/stripe'
import { CREATE_LESSON_FOR_STUDENT_QUERY } from 'pages/schedule/mutations'
import PaymentInformation from './payment-information'
import { useStyles } from './styles'
import { gtmEvent } from 'lib/gtm'
import BackButton from 'components/back-button'
import themes, { extendTheme } from 'themes'
import { ThemeProvider } from '@material-ui/core'
import InitialsAvatar from 'components/avatars/initials-avatar'
import PropTypes from 'prop-types'
import BookingConfirmationSkeleton from 'components/skeletons/booking-confirmation-skeleton'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import StepDescriptionOfferings from '../components/step-description-offerings'
import CoachLessonHeader from '../components/CoachLessonHeader'
import { ContainerRoot, WaiverContainer } from '../components/styles'
import { COACHING_CENTER_SLUGS } from 'utils/constants'
import { CoachingCenterWaiver } from 'components/waivers'
import { useCreditCardErrors } from 'lib/hooks'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import MarketingOptIn from 'components/marketing-opt-in/marketing-opt-in'

const LessonReviewLayout = ({
  lessonType,
  locationId,
  startDateTime,
  student,
  previousUrl,
  isComplete,
  setIsComplete,
  setLastDigits,
  setCardBrand,
  progressValue,
  stepText,
  eventObject = {},
}) => {
  const { priceInCents, coach, facilities, acceptsOnlinePayments } = lessonType
  const facility = facilities.find(({ id }) => id === locationId)

  const studentName = `${student.firstName} ${student.lastName}`
  const priceInDollars = priceInCents ? priceInCents / 100 : null

  const { user } = useAuth()
  const coachBooksOnBehalfOfStudent =
    user?.externalId === lessonType?.coach.externalId
  const shouldShowWaiver =
    COACHING_CENTER_SLUGS.includes(lessonType?.academy?.slug) &&
    !coachBooksOnBehalfOfStudent
  const [showWaiver, setShowWaiver] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showWaiver])

  const canSkipPayment = user && user?.id === lessonType?.coach?.id
  const [paymentRequired, setPaymentRequired] = useState(
    !canSkipPayment && acceptsOnlinePayments,
  )
  const userIsNotOptedIn = !user?.marketingOptInAt
  const [useMarketingOptIn] = useFeatureFlags([
    flags.FLAG_FEAT_MARKETING_OPT_IN_PRIVATE_BOOKINGS,
  ])
  const [marketingOptInChecked, setMarketingOptInChecked] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const [createLesson, { loading }] = useMutation(
    CREATE_LESSON_FOR_STUDENT_QUERY,
  )

  const form = useForm({
    resolver: yupResolver(validationSchema(paymentRequired, shouldShowWaiver)),
    ...(shouldShowWaiver && {
      defaultValues: {
        agreeWaiver: false,
      },
    }),
  })

  const stripe = useStripe()
  const elements = useElements()
  const creditCardErrorMessage = useCreditCardErrors(
    elements,
    paymentRequired,
    !shouldShowWaiver,
  )
  const classes = useStyles()

  const onSubmit = async formValues => {
    const { error: stripeError, token: stripeToken } = await (paymentRequired
      ? stripe.createToken(elements.getElement(CardNumberElement), {
        name: formValues.payment.name,
        address_country: 'US',
      })
      : Promise.resolve({ error: null, token: null }))
    if (stripeError) {
      setShowWaiver(false)
      enqueueSnackbar(stripeError.message, {
        variant: 'error',
      })
      return Promise.resolve()
    }

    const marketingOptInAt = marketingOptInChecked
      ? new Date().toISOString()
      : null

    return createLesson({
      variables: {
        input: {
          studentId: student.id,
          lessonTypeId: lessonType.id,
          startDateTime,
          locationId: facility.id,
          stripeToken: stripeToken?.id,
          marketingOptInAt,
        },
      },
    })
      .then(({ data }) => {
        const { success, message } = data.createLessonForStudent

        if (success) {
          enqueueSnackbar('Your session has been booked.', {
            variant: 'success',
          })
          gtmEvent({
            ...eventObject,
            event: 'complete-private-session-booking',
          })
          // Remove the below event in the future.
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'book-session',
            formAction: 'clicked-book-session-only-button',
          })
          if (marketingOptInAt) {
            gtmEvent({
              event: 'marketing-opt-in',
              source: 'private_booking',
            })
          }

          setIsComplete(!isComplete)
          setLastDigits(stripeToken?.card.last4)
          setCardBrand(stripeToken?.card.brand)
        } else {
          setShowWaiver(false)
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        setShowWaiver(false)
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['bookings', 'form-submit'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
  }

  const handleButtonClick = async () => {
    if (shouldShowWaiver) {
      const isValid = await form.trigger(['payment'])
      if (isValid && creditCardErrorMessage) {
        enqueueSnackbar(creditCardErrorMessage, {
          variant: 'error',
        })
      }
      if (isValid && !creditCardErrorMessage) {
        setShowWaiver(true)
      }
    } else {
      await form.handleSubmit(onSubmit)()
    }
  }

  if (loading) {
    return (
      <ContainerRoot className={classes.root}>
        <div className={classes.loadingStyles}>
          <BookingConfirmationSkeleton />
        </div>
      </ContainerRoot>
    )
  }

  return (
    <>
      <WaiverContainer showWaiver={showWaiver}>
        <CoachingCenterWaiver
          form={form}
          showWaiver={showWaiver}
          setShowWaiver={setShowWaiver}
          onSubmit={onSubmit}
          withBackButton={true}
        />
      </WaiverContainer>
      <ContainerRoot>
        <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
          <Grid container style={{ display: showWaiver ? 'none' : 'block' }}>
            <ProgressIndicator value={progressValue} />
            <BackButton previousUrl={previousUrl} />
            <CoachLessonHeader lessonType={lessonType} coach={coach} />
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid container>
              <Grid item md={7} xs={12} className={classes.gridItem}>
                <StepDescriptionOfferings
                  stepText={stepText}
                  title="Review"
                  description="Please review the information below."
                />
                <Grid
                  container
                  item
                  xs={12}
                  md={5}
                  className={classes.studentCard}
                >
                  <InitialsAvatar
                    name={`${student.firstName} ${student.lastName}`}
                    classes={classes}
                  />
                  <div className={classes.infoWrapped}>
                    <Typography
                      variant="caption"
                      className={`${classes.fontBolded} ${classes.captionText}`}
                      data-testid="student-name"
                    >
                      {studentName}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={`${classes.captionText} ${classes.lighterText}`}
                    >
                      Student
                    </Typography>
                  </div>
                </Grid>
                <Divider style={{ margin: '2rem 0px 1rem' }} />
                {priceInDollars !== null && (
                  <Box mb={2}>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography
                        variant="body1"
                        className={classes.lighterText}
                      >
                        Subtotal
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.lighterText}
                      >
                        {currency(priceInDollars)}
                      </Typography>
                    </Box>
                    {paymentRequired ? (
                      <Box
                        display="flex"
                        className={classes.infoRow}
                        justifyContent="space-between"
                        mb={1}
                      >
                        <Typography
                          variant="body1"
                          className={`${classes.fontBolded}`}
                        >
                          Due Today
                        </Typography>
                        <Typography
                          variant="body1"
                          className={`${classes.fontBolded}`}
                        >
                          {currency(priceInDollars)}
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          className={classes.infoRow}
                          justifyContent="space-between"
                        >
                          <Typography
                            variant="body1"
                            className={`${classes.fontBolded}`}
                          >
                            Due Today
                          </Typography>
                          <Typography
                            variant="body1"
                            className={`${classes.fontBolded}`}
                          >
                            {currency(0)}
                          </Typography>
                        </Box>
                        <div className={classes.infoRow}>
                          <Typography
                            variant="body2"
                            className={`${classes.captionText} ${classes.lighterText}`}
                          >
                            Pay at location
                          </Typography>
                        </div>
                      </>
                    )}
                    <Divider style={{ margin: '1rem 0px 1rem' }} />
                  </Box>
                )}
                <FormProvider {...form}>
                  <form>
                    <Box mb={4}>
                      <PaymentInformation
                        lessonType={lessonType}
                        paymentRequired={paymentRequired}
                        setPaymentRequired={setPaymentRequired}
                      />
                    </Box>
                    {useMarketingOptIn && userIsNotOptedIn && (
                      <MarketingOptIn
                        checked={marketingOptInChecked}
                        onChange={setMarketingOptInChecked}
                        useMarketingOptIn={'callout'}
                      />
                    )}
                    <Grid item>
                      <Button
                        variant="outlined"
                        className={classes.bookingButton}
                        onClick={handleButtonClick}
                        data-testid="complete-booking-button"
                        disabled={form.formState.isSubmitting}
                      >
                        {shouldShowWaiver ? 'Continue' : 'Complete Purchase'}
                      </Button>
                    </Grid>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </ContainerRoot>
    </>
  )
}

LessonReviewLayout.propTypes = {
  lessonType: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired,
  student: PropTypes.object.isRequired,
  previousUrl: PropTypes.string,
  isComplete: PropTypes.bool,
  setIsComplete: PropTypes.func,
  setLastDigits: PropTypes.func,
  setCardBrand: PropTypes.func,
  progressValue: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
}

LessonReviewLayout.defaultProps = {
  isComplete: false,
}

export default withStripeElements(LessonReviewLayout)
