import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 8px 24px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    minHeight: theme.spacing(9),
    [theme.breakpoints.only('xs')]: {
      minHeight: theme.spacing(12),
    },
  },
  settingsDescription: {
    marginLeft: theme.spacing(2),
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  disabled: {
    background: 'rgba(0, 0, 0, 0.12)',
    cursor: 'default',
    pointerEvents: 'none',
  },
  action: {
    marginLeft: 'auto',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0,
    },
    color: 'rgba(0, 0, 0, 0.54)',
  },
  icon: {
    display: 'flex',
    minWidth: '40px',
    minHeight: '40px',
    alignItems: 'center',
    borderRadius: '40px',
    justifyContent: 'center',
    color: theme.palette.success.main,
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50',
  },
}))

export default useStyles
