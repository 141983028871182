import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { Box, Grid, Container, InputAdornment } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { LessonValidation } from './query'
import Dialog from 'components/dialog'
import RouterLinkButton from 'components/router-link-button'
import { useStyles } from './styles'
import { buildDateTime } from 'lib/CustomLuxonUtils'
import { toThreeLetterTimezone } from 'utils/timezones'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import {
  LocalizationProvider,
  DatePicker,
  MobileTimePicker,
  renderMultiSectionDigitalClockTimeView,
} from '@mui/x-date-pickers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'themes'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const OverwriteAvailabilityDialog = ({
  trigger,
  currentUrl,
  durationInMinutes,
  timezone,
}) => {
  const classes = useStyles()
  const [startDate, setStartDate] = useState(null)
  const [startTime, setStartTime] = useState(null)

  const formattedTimezone = toThreeLetterTimezone(timezone)
  const showTimezone = formattedTimezone !== null

  const startDateTime =
    startDate &&
    startTime &&
    buildDateTime(startDate, startTime, timezone).toUTC()

  const { data } = useQuery(LessonValidation, {
    skip: !startDateTime,
    variables: {
      timespans: [
        {
          from: startDateTime,
          to: startDateTime?.plus({ minutes: durationInMinutes }),
        },
      ],
    },
  })

  const changeStartTime = value => {
    if (!value) return
    setStartTime(value)
  }

  const warningMessages = data?.lessonValidation?.warnings
  const isAvailableTime = !!data?.lessonValidation?.isAvailable

  return (
    <Dialog trigger={trigger} maxWidth="sm" fullWidth showCloseButton>
      {({ closeDialog }) => {
        return (
          <>
            <DialogTitle>Add Session Details</DialogTitle>
            <DialogContent>
              {warningMessages?.length > 0 && (
                <Container maxWidth="md" className={classes.base}>
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography className={classes.body}>
                      This time may conflict with the following event(s)
                      <ul className={classes.ul}>
                        {warningMessages.map((warningMessage, index) => (
                          <li key={index}>
                            <Typography gutterBottom>
                              <strong>{warningMessage}</strong>
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </Typography>
                  </Box>
                </Container>
              )}
              <Grid container>
                <LocalizationProvider
                  dateAdapter={CustomLuxonAdapter}
                  dateFormats={{ normalDateWithWeekday: 'LLL d, yyyy' }} // Jul 1, 2021
                >
                  <ThemeProvider theme={createTheme(themes.offeringsRefresh)}>
                    <Grid item xs={12} sm={7}>
                      <Box mb={2} mt={2}>
                        <Typography
                          variant="body1"
                          className={classes.body}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Select a custom date and time to book this session.
                        </Typography>
                        <DatePicker
                          label="Date"
                          id="start-date"
                          value={startDate}
                          format="MMM dd, yyyy"
                          dayOfWeekFormatter={day => `${day}`}
                          onChange={setStartDate}
                          sx={{ width: '100%' }}
                          slotProps={{
                            dialog: {
                              disableEnforceFocus: true,
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Box mb={2}>
                        <MobileTimePicker
                          id="start-time"
                          label={`Time ${
                            showTimezone ? `(${formattedTimezone})` : ''
                          }`}
                          value={startTime}
                          onChange={val => changeStartTime(val)}
                          sx={{ width: '100%' }}
                          minutesStep={5}
                          viewRenderers={{
                            hours: renderMultiSectionDigitalClockTimeView,
                            minutes: renderMultiSectionDigitalClockTimeView,
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <AccessTimeIcon className={classes.icon} />
                                  </InputAdornment>
                                ),
                              },
                            },
                            dialog: {
                              disableEnforceFocus: true,
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </ThemeProvider>
                </LocalizationProvider>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button color="primary" variant="text" onClick={closeDialog}>
                Cancel
              </Button>
              <RouterLinkButton
                color="primary"
                variant="contained"
                disabled={!startDateTime || !isAvailableTime}
                to={`${currentUrl}&startDateTime=${startDateTime}`}
              >
                Add
              </RouterLinkButton>
            </DialogActions>
          </>
        )
      }}
    </Dialog>
  )
}

export default OverwriteAvailabilityDialog
