import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SlugWrapper } from 'components/coaches-profile/profile-stepper.styles'

export default function CustomSlug({
  newProfile,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) {
  const inputRef = useRef()

  return newProfile ? (
    <SlugWrapper onClick={() => inputRef && inputRef.current.focus()}>
      <TextField
        name="customSlug"
        type="text"
        variant="outlined"
        label="Profile Name"
        value={values.customSlug}
        onChange={e => {
          handleChange(e)
        }}
        onBlur={handleBlur}
        style={{ width: '100%' }}
        className={`text-input last-input ${errors.customSlug && ' error'}`}
        error={errors.customSlug}
        helperText={errors?.customSlug?.message}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="input-adornment">
              {(values.customSlug && 'www.pga.com/coach/') || ''}
            </InputAdornment>
          ),
        }}
      />
    </SlugWrapper>
  ) : (
    <SlugWrapper>
      <TextField
        name="customSlug"
        label="Profile Name"
        type="text"
        value={values.customSlug}
        disabled
        style={{ width: '100%' }}
        className={`text-input last-input ${errors.customSlug && ' error'}`}
        error={touched.customSlug && errors.customSlug}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="input-adornment">
              www.pga.com/coach/
            </InputAdornment>
          ),
        }}
      />
    </SlugWrapper>
  )
}

CustomSlug.propTypes = {
  newProfile: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}
