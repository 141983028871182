import React from 'react'
import useQueryString from 'lib/hooks/use-query-string'
import { DateTime } from 'luxon'

export const useFindTemplate = () => {
  const { template } = useQueryString()
  // If no query string was provided, offer the default.
  if (!template) return TEMPLATES.default

  // Match query string against available templates. If no match, return a blank template.
  // Possible future improvement: match string to object key 1-to-1 to remove need for switch.
  switch (template) {
    case 'adm-training':
      return TEMPLATES.blank // <-- This may become it's own key/value later, if fields change. Suggestion: TEMPLATES.adm
    case 'dcp':
      return TEMPLATES.dcp
    case 'on-course':
      return TEMPLATES.onCourse
    case 'jrl-info':
      return TEMPLATES.jrlInfo
    default:
      return TEMPLATES.blank
  }
}

export const TemplateContext = React.createContext()
export const useTemplateContext = () => React.useContext(TemplateContext)
export const TemplateContextProvider = ({ children }) => {
  const templateValues = useFindTemplate()
  return (
    <TemplateContext.Provider value={templateValues}>
      {children}
    </TemplateContext.Provider>
  )
}

const startDateTime = DateTime.local()

// Below are all the available templates. We will likely move these server-side, soon, because they're growing in number.
const TEMPLATES = {
  blank: {
    customSlug: '',
    title: '',
    featuredImageUrl:
      'https://images.unsplash.com/photo-1560674498-ec05053cbf14?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyMzQ&ixlib=rb-1.2.1&q=80&w=400',
    maxAttendance: null,
    hoursBeforeRegistrationCloses: 24,
    price: 0,
    sessions: [
      {
        startDate: startDateTime.startOf('day').toISODate(),
        startTime: startDateTime.toFormat('HH:mm'),
        endTime: startDateTime.plus({ hours: 1 }).toFormat('HH:mm'),
      },
    ],
    description: '',
    tags: ['Instructional'],
  },
  dcp: {
    customSlug: '',
    title: 'Drive, Chip and Putt Training Program',
    featuredImageUrl:
      'https://res.cloudinary.com/pgahq/image/upload/v1672852869/dot-com-images/GettyImages-1347070945_duvv04.jpg',
    maxAttendance: null,
    hoursBeforeRegistrationCloses: 24,
    price: 0,
    sessions: [
      {
        startDate: startDateTime.startOf('day').toISODate(),
        startTime: startDateTime.toFormat('HH:mm'),
        endTime: startDateTime.plus({ hours: 1 }).toFormat('HH:mm'),
      },
    ],
    description:
      "**Note: Participation in A Training Program is NOT required for participation in free Drive, Chip and Putt Qualifier Events.**\n\nWhether they're new to the game and entering Drive, Chip and Putt for the first time, or experienced players, Drive, Chip and Putt is a great way for junior golfers to get a bite-size taste of golf while measuring skills in three areas of golf’s main skills.\n\nDrive, Chip and Putt is an excellent program for juniors of all levels as participants have an opportunity to advance through local, subregional and regional qualifying to earn a place in the national finals that are conducted at Augusta National Golf Club on the Sunday before The Masters Tournament.\n\nOur Training Program will be a great opportunity for juniors to test their skills and experience the Drive, Chip and Putt format before attempting to qualify at the local level.\n\nOur program will be run just like the local qualifier with scorecards, scoreboards, tee times, etc. so juniors get comfortable with the experience.  In addition, coaching and instructions will be provided in the designated warm-up areas to assist juniors before or after the competition.\n\nAttire:\n\nDress for the weather, but shorts, a golf shirt or nice T-shirt, cap and tennis shoes are appropriate for our clinic. And don’t forget sunscreen!\n\nEquipment needed:\n\nIf you have golf clubs for your participant, please bring them. Otherwise, please contact us as a limited supply of loaner clubs are available.\n\nWhat’s included?\n - All instruction\n - Range balls\n - Refreshments: water, sports drinks, snacks\n - Actual Drive, Chip and Putt experience with scoring!\n\nTo register for Drive, Chip and Putt, visit drivechipandputt.com to create an account, then sign-up for the local qualifier of your choice to reserve your spot.",
    tags: [
      'Beginner Friendly',
      'Kid Friendly',
      'Family Friendly',
      'Instructional',
      'Just for Fun',
    ],
  },
  onCourse: {
    customSlug: '',
    title: 'On-Course Game Assessment',
    featuredImageUrl:
      'https://images.unsplash.com/photo-1602991174407-a015b35a7b00?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyODc&ixlib=rb-1.2.1&q=80&w=400',
    maxAttendance: null,
    hoursBeforeRegistrationCloses: 24,
    price: 100,
    sessions: [
      {
        startDate: startDateTime.startOf('day').toISODate(),
        startTime: startDateTime.toFormat('HH:mm'),
        endTime: startDateTime.plus({ hours: 1 }).toFormat('HH:mm'),
      },
    ],
    description:
      "The best way to accomplish your golfing goals is to have your PGA Professional see your whole game in real-life situations. In this on-course assessment, your PGA Professional will be able to see:\n- How you handle pressure\n- How you manage your game\n- Areas of weakness and where these aspects lead to dropped strokes.\n\nNothing can replace the vital learning and discovery of being on the course with your PGA pro. Getting out to play with them in a foursome enables your pro to simulate what you feel in your normal round of golf. Your professional will have these key insights on how to help you by:\n- Creating a written plan for your goals\n- Prioritizing areas to work on to speed up your improvement\n- Ensuring what you work on in private lessons will transfer and work when you're out on the course.\n\nGet signed up for your on-course game assessment and take the first step in achieving your golfing goals this year.",
    tags: ['9-holes', 'On course'],
  },
  jrlInfo: {
    customSlug: '',
    title: 'PGA Jr. League Parents Information Session',
    featuredImageUrl:
      'https://images.ctfassets.net/56u5qdsjym8c/1GU8DzhyXQMzWHjEGw2LYt/1007f40b488e96b47b5754b99fe82cbb/JrL.11.18.17.T.453.jpg',
    maxAttendance: null,
    hoursBeforeRegistrationCloses: 24,
    price: 0,
    sessions: [
      {
        startDate: startDateTime.startOf('day').toISODate(),
        startTime: startDateTime.toFormat('HH:mm'),
        endTime: startDateTime.plus({ hours: 1 }).toFormat('HH:mm'),
      },
    ],
    description:
      'Join our pre-season parents meeting to learn more about our upcoming PGA Jr. League season and  meet our coaches! This will be a great opportunity to learn about our season and ask questions with the coaching staff. We will cover:\n\n\n- What our PGA Jr. League season will look like from start to finish\n- Benefits of being a team member at our facility\n- Season schedule (pre-season kickoff event, practices and matches, All-Star Play Days, the Championship Season and end-of-season PGA Family Golf event for everyone)\n  - **Practices**: Time, format and what your junior golfer will experience\n  - **Matches**: Time, location, who we’ll be play and what your junior will experience on the course\n  - **Pre-season kickoff event**: We’re kicking off the season with an event that allows players and parents to meet each other, some fun games with full swing, putting, chipping and pitching, and awards.\n  - **All-Star Play Days**: Team members will have the opportunity to participate in All-Star Play Days throughout the season at our course and other facilities.\n  - **Championship Season**: Our team will participate in the Championship Season at the end of the year with the goal of advancing towards the PGA Jr. League National Championship.\n  - **End-of-Season PGA Family Golf event**: We will finish the season with a fun PGA Family golf event that’s similar to a traditional PGA Jr. League game. This event allows the parents, grandparents, siblings and other family members to stop spectating and get on the golf course and play!\n\n\nPlease contact us with any questions. ',
    tags: ['Instructional', 'Family Friendly'],
  },
  default: {
    customSlug: '',
    title: 'On-Course Game Assessment',
    featuredImageUrl:
      'https://images.unsplash.com/photo-1560674498-ec05053cbf14?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyMzQ&ixlib=rb-1.2.1&q=80&w=400',
    maxAttendance: 3,
    hoursBeforeRegistrationCloses: 24,
    price: 100,
    sessions: [
      {
        startDate: '2023-03-31',
        startTime: '14:30',
        endTime: '17:00',
      },
    ],
    description:
      "The best way to accomplish your golfing goals is to have your PGA Professional see your whole game in real-life situations. In this on-course assessment, your PGA Professional will be able to see:\n- How you handle pressure\n- How you manage your game\n- Areas of weakness and where these aspects lead to dropped strokes.\n\nNothing can replace the vital learning and discovery of being on the course with your PGA pro. Getting out to play with them in a foursome enables your pro to simulate what you feel in your normal round of golf. Your professional will have these key insights on how to help you by:\n- Creating a written plan for your goals\n- Prioritizing areas to work on to speed up your improvement\n- Ensuring what you work on in private lessons will transfer and work when you're out on the course.\n\nGet signed up for your on-course game assessment and take the first step in achieving your golfing goals this year.",
    tags: ['9-holes', 'On course'],
  },
}
