import jwtDecode from 'jwt-decode'
import auth0 from '../auth0/client'
import { NODE_ENV, AUTH_ROOT } from 'env'
import { AUTH_TOKEN_COOKIE } from '../constants'

const fetchToken = async () => {
  const [auth0Token, legacyToken] = await Promise.all([
    _fetchTokenFromAuth0(),
    _fetchTokenFromLegacy(),
  ])
  return auth0Token || legacyToken
}

const _fetchTokenFromAuth0 = async () => {
  try {
    const accessToken = await auth0.getTokenSilently()
    return accessToken
  } catch (error) {
    const skippedErrors = [
      'login_required',
      'mfa_required',
      'missing_refresh_token',
      'invalid_grant',
    ]
    if (!skippedErrors.includes(error.error)) {
      console.error(error)
      window.rg4js &&
        window.rg4js('send', {
          error,
          tags: ['auth'],
        })
      throw error
    }
    return null
  }
}

const _fetchTokenFromLegacy = async () => {
  try {
    const resp = await fetch(`${AUTH_ROOT}/session`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    })
    const json = await resp.json()
    return json.token
  } catch (err) {
    // how do we handle errors typically?
    // eslint-disable-next-line no-console
    console.log('errinMIDDLE:', err)
  }
  return null
}

const getToken = () => {
  const cookies = document.cookie.split(';')
  const cookiePairs = cookies.map(c => c.split('='))
  const authTokenCookie = cookiePairs.find(
    cp => cp[0].trim() === AUTH_TOKEN_COOKIE,
  )
  return Promise.resolve(
    authTokenCookie ? decodeURIComponent(authTokenCookie[1]) : null,
  )
}

const setToken = authToken => {
  const decodedToken = _decodeToken(authToken)
  if (decodedToken) {
    document.cookie = `${AUTH_TOKEN_COOKIE}=${authToken}; path=/; max-age=${decodedToken.exp -
      decodedToken.iat}; SameSite=Lax; ${
      NODE_ENV !== 'development' ? 'Secure;' : ''
    }`
  }
}

const _decodeToken = authToken => {
  if (!authToken) return null
  try {
    return jwtDecode(authToken)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('jwt-decode error', e)
    return null
  }
}

const clearToken = () => {
  document.cookie = `${AUTH_TOKEN_COOKIE}=; path=/; max-age=0;`
}

export { fetchToken, getToken, setToken, clearToken }
