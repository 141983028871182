import { gql } from '@apollo/client'

export const CoachReviewsQuery = gql`
  query GetCoachReviews {
    GetCoachReviews {
      message
      success
      averageRating
      coachReviews {
        id
        rating
        reviewText
        status
        contact {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        coachProfile {
          firstName
          lastName
          profilePhoto
        }
        updatedAt
        createdAt
      }
    }
  }
`
