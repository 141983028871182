import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { gtmEvent } from 'lib/gtm'

const Dialog = ({
  heading,
  text,
  children,
  open,
  onClose,
  action = false,
  scheduleDialog = false,
}) => {
  useEffect(() => {
    if (open && !scheduleDialog) {
      gtmEvent({
        event: 'onboarding-step-completed',
      })
    }
  }, [open, scheduleDialog])

  return (
    <MuiDialog maxWidth="sm" open={open} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <DialogTitle>{heading}</DialogTitle>
        <CloseIcon
          onClick={onClose}
          style={{ fontSize: '32px', cursor: 'pointer' }}
        />
      </Box>
      <DialogContent dividers>
        <Box mb={2}>
          {text ? (
            <Typography variant="body1" color="textSecondary">
              {text}
            </Typography>
          ) : (
            children
          )}
          {children}
        </Box>
        {action && (
          <Box align="right" mt={5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                window.location.href = '/pga-coach'
              }}
            >
              Back to onboarding
            </Button>
          </Box>
        )}
      </DialogContent>
    </MuiDialog>
  )
}

export default Dialog
