import React from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from '@material-ui/core'

export default function FormRadioGroup({
  legend,
  name,
  value,
  error,
  required = false,
  onChange,
  children,
  defaultValue,
  disabled,
}) {
  return (
    <FormControl
      component="fieldset"
      error={error}
      required={required}
      disabled={disabled}
    >
      <FormLabel component="legend">{legend}</FormLabel>
      <RadioGroup
        defaultValue={defaultValue}
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {children}
      </RadioGroup>

      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
