import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const MedicalProfile = () => {
  const { register, errors } = useFormContext()
  return (
    <>
      <Typography variant="subtitle1" style={{ marginTop: '2rem' }}>
        Allergies and Medications
      </Typography>
      <Grid container item xs={12}>
        <TextField
          name="medicalProfile.info"
          margin="normal"
          inputRef={register}
          fullWidth
          variant="outlined"
          label="Enter any allergies or medications"
          multiline
          minRows={3}
          errors={!!errors.medicalProfile?.info}
        />
      </Grid>
    </>
  )
}

export default MedicalProfile
