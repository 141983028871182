import React from 'react'
import Messenger from 'components/stream-messaging/messenger'
import { useHideIntercom } from 'lib/support'

const MessagingPage = () => {
  useHideIntercom()
  return <Messenger />
}

export default MessagingPage
