import Cookie from 'js-cookie'
import { NODE_ENV } from 'env'
import {
  FEATURE_FLAG_EXPERIMENT_COOKIE_NAME,
  FEATURE_FLAG_INTERNAL_COOKIE_NAME,
} from './constants'

const ONE_YEAR = 365
const cookieAttributes = {
  expires: ONE_YEAR,
  secure: NODE_ENV !== 'development',
  sameSite: 'Lax',
  path: '/',
}

const FlagStore = cookieName => ({
  get: () => {
    return deserializeFlagValues(Cookie.get(cookieName))
  },
  set: flagValues => {
    Cookie.set(cookieName, serializeFlagValues(flagValues), cookieAttributes)
  },
  clear: () => {
    Cookie.remove(cookieName, cookieAttributes)
  },
})

export const ExperimentFlags = FlagStore(FEATURE_FLAG_EXPERIMENT_COOKIE_NAME)
export const InternalFlags = FlagStore(FEATURE_FLAG_INTERNAL_COOKIE_NAME)

// converts raw header/cookie value into an object w/ key-value pairs
// e.g.
//   - input: flag-a=myvariant,flag-b=anothervariant
//   - output: { 'flag-a': 'myvariant', 'flag-b': 'anothervariant' }
const deserializeFlagValues = rawFlagValues => {
  const flagValues = (rawFlagValues != null && rawFlagValues.length > 0
    ? rawFlagValues.split(',').map(c => c.split('='))
    : []
  ).reduce((acc, val) => {
    const [k, v] = val
    acc[k] = v
    return acc
  }, {})
  return flagValues
}

// converts flag values object into serialized form
// (comma-separated key=value string, sorted alphabetically)
// e.g.
//   - input: { 'flag-a': 'myvariant', 'flag-b': 'anothervariant' }
//   - output: flag-a=myvariant,flag-b=anothervariant
const serializeFlagValues = flagValues => {
  const flagKeys = Object.keys(flagValues)
  flagKeys.sort()
  const serializedFlagValues = flagKeys
    .map(k => `${k}=${flagValues[k]}`)
    .join(',')
  return serializedFlagValues
}
