import * as yup from 'yup'
import { STATES } from 'components/util/state-selector'
import { differenceInYears } from 'date-fns'

export const getValue = ({ value }) => value

export const validateLeagueAgeUnder18 = dateOfBirth => {
  const currentYear = new Date().getFullYear()
  return dateOfBirth.test(
    'league-age-under18',
    "Player's league age is over 18",
    value => {
      return differenceInYears(new Date(currentYear, 6, 31), value) < 18
    },
  )
}

export const evaluateWithinPlayDayPeriod = date => {
  const currentYear = new Date().getFullYear()
  return (
    date >= new Date(currentYear, 4, 1, 0, 0, 0, 0) &&
    date <= new Date(currentYear, 7, 21, 23, 59, 59, 99)
  )
}

export const stateOptions = STATES

export const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Other', value: 'O' },
]

export const ethnicityOptions = [
  { label: 'African American or Black', value: 'AFRICAN_AMERICAN_BLACK' },
  { label: 'Asian or Pacific Islander', value: 'ASIAN_PACIFIC_ISLANDER' },
  { label: 'Caucasian or White', value: 'CAUCASIAN_WHITE' },
  { label: 'Hispanic or Latino', value: 'HISPANIC_LATINO' },
  {
    label: 'Middle Eastern or North African',
    value: 'MIDDLE_EASTERN_NORTH_AFRICAN',
  },
  {
    label: 'Native American or Alaska Native',
    value: 'NATIVE_AMERICAN_ALASKA_NATIVE',
  },
  { label: 'Multi-Racial/Multi-Ethnic', value: 'MULTI_RACIAL_MULTI_ETHNIC' },
  { label: 'I Prefer Not to Answer', value: 'NO_ANSWER' },
  { label: 'Other', value: 'OTHER' },
]

export const golfExperienceOptions = [
  { label: 'Beginner', value: 'BEGINNER' },
  { label: 'Recreational', value: 'RECREATIONAL' },
  { label: 'Competitive', value: 'COMPETITIVE' },
]

export const playerProgramAddressOptions = [
  { label: 'School', value: 'SCHOOL' },
  { label: 'Home', value: 'GUARDIAN_ADDRESS' },
]

export const stateField = label =>
  yup
    .object()
    .typeError(`${label} is required`)
    .label(label)
    .shape({
      value: yup.string().required(),
    })
    // Using test instead of .oneOf to not have to deal with object identity equality
    .test(
      'is-state',
      `${label} must be one of the available options`,
      ({ value }) => Boolean(stateOptions.find(state => state.value === value)),
    )

export const zipField = label =>
  yup
    .string()
    .label(label)
    .matches(/^\d{5}$/, `${label} must be a valid zip code`)

export const phoneField = label =>
  yup
    .string()
    .label(label)
    .matches(/^\d{3}-?\d{3}-?\d{4}$/, {
      message: `${label} must be a valid phone number`,
      excludeEmptyString: true,
    })

export const getOptionLabel = (options, value) => {
  const option = options.find(option => option.value === value)
  return option?.label
}
