import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Popover from '@material-ui/core/Popover'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  EmailIcon,
} from 'react-share'
import XShareButton from './x-share-button'

const SocialSharePopover = ({ trigger, text, url }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const openPopover = e => setAnchorEl(e.currentTarget)
  const closePopover = () => setAnchorEl(null)

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'social-share-popover' : undefined

  return (
    <>
      {trigger && <trigger.type {...trigger.props} onClick={openPopover} />}
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box p={1} display="flex">
          <Box mr={1}>
            <FacebookShareButton url={url} quote={text}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </Box>
          <Box mr={1}>
            <XShareButton url={url} title={text} buttonSize={40} />
          </Box>
          <EmailShareButton url={url} subject={text}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </Box>
      </Popover>
    </>
  )
}

export default SocialSharePopover
