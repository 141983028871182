import { styled } from '@material-ui/core/styles'

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: '525px',
  borderRadius: theme.spacing(2),
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: '66px 36px',
  [theme.breakpoints.down('xs')]: {
    width: 'auto',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
