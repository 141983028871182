import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  journeyContainer: {
    flexDirection: 'row',
    paddingBottom: '3rem',
    height: 'auto',
    backgroundColor: theme.palette.primary.main,
    marginTop: '2rem',
  },
  banner: {
    color: theme.palette.primary.contrastText,
    padding: '2rem  2rem',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.light,
    fontWeight: '700',
    lineHeight: '1',
    margin: '.5rem 0',
    marginTop: theme.spacing(5),
  },
  fullHeroText: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexwrap: 'wrap',
  },
  caption: {
    color: theme.palette.text.primary,
    fontWeight: '700',
    lineHeight: '1',
  },
  coachContactsSection: ({ hasSocials }) => ({
    backgroundColor: theme.palette.offwhite.main,
    color: theme.palette.primary.main,
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.1)',
    borderRadius: '.5rem',
    height: hasSocials ? '400px' : '200px',
  }),
  contactCoachButton: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.blue.dark,
    },
    padding: '10px 25px',
    margin: '2rem 0',
  },
  workWithSection: ({ hasSocials }) => ({
    borderBottom: hasSocials && `1px solid ${theme.palette.grey.light}`,
    padding: '0 1rem 1rem ',
  }),
  socialMediaSection: {
    marginTop: '1rem',
  },
  socialIcon: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    height: '59px',
    width: '59px',
    boxSizing: 'border-box',
    '& svg > path': {
      fill: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      '& svg > path': {
        fill: theme.palette.primary.contrastText,
      },
    },
  },
}))
