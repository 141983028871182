import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import { Typography } from '@material-ui/core'
import Rating from 'material-ui-rating'
import useStyles from './coach-average-rating-card.styles'
import { secureLink } from 'lib/utils'

const CoachAverageRatingCard = (props) => {
  const {
    profile,
    rate,
  } = props
  const styles = useStyles()
  return (
    <Card variant="outline" className={styles.cardRoot}>
      <div className={styles.avatarWrapper}>
        <Avatar
          aria-label="coach"
          src={secureLink(profile.profilePhoto)}
          className={styles.avatarStyles}
        >
          {!profile.profilePhoto && `${profile.firstName.substring(0, 1)}${profile.lastName.substring(0, 1)}`}
        </Avatar>
      </div>
      <div className={styles.rateWrapper}>
        <Typography variant="h4" className={styles.rateStyles}>
          {rate.toFixed(2)}
        </Typography>
        <Rating
          name="rate"
          value={rate}
          classes={{
            iconButton: styles.starButton,
          }}
          readOnly
        />
      </div>
    </Card>
  )
}

CoachAverageRatingCard.propTypes = {
  profile: PropTypes.object,
  rate: PropTypes.number,
}

CoachAverageRatingCard.defaultProps = {}

export default CoachAverageRatingCard
