import React from 'react'
import {
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import MuiListItem from '@material-ui/core/ListItem'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'

const listItemStyles = theme => ({
  root: {
    '&$selected': {
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.secondary.main,
      paddingLeft: '12px',
      borderLeftWidth: '4px',
    },
    '&$selected:hover': {
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.secondary.main,
      borderLeftWidth: '4px',
    },
    '&:hover': {},
  },
  selected: {},
})
const ListItem = withStyles(listItemStyles)(MuiListItem)

const navMenuItemStyles = makeStyles(theme => ({
  listItemIcon: {
    minWidth: 24 + theme.spacing(1),
  },
  listIcon: {
    height: '24px',
    width: '24px',
  },
}))

export default function SidebarMenuItem({
  icon,
  onClick,
  label,
  path,
  location,
  exact,
  annotation,
  disabled = false,
  external = false,
  hide = false,
  isTabletNavOpen,
}) {
  const classes = navMenuItemStyles()

  const iconComponent = React.cloneElement(icon, {
    className: classes.listIcon,
  })

  const isStudentOrLeadPage =
    path.includes('student') &&
    (location.pathname.includes('student') ||
      location.pathname.includes('leads'))
  const isBookingsPage =
    path.includes('bookings') &&
    location.pathname.includes('bookings') &&
    !location.pathname.includes('schedule') &&
    !path.includes('schedule') &&
    !location.pathname.includes('events')
  const isEventPage =
    path.includes('schedule') &&
    location.pathname.includes('events') &&
    !location.pathname.includes('offerings') &&
    !path.includes('offerings')

  const selected = exact
    ? `${path}/` === location.pathname ||
    path === location.pathname ||
    isBookingsPage ||
    isEventPage
    : location.pathname.startsWith(path) || isStudentOrLeadPage
  const linkProps = !external
    ? { component: NavLink, to: path }
    : { component: 'a', href: path, target: external.target || '_blank' }

  if (hide) return null

  return (
    <ListItem
      button
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      {...linkProps}
    >
      <ListItemIcon className={classes.listItemIcon}>
        {iconComponent}
      </ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{ style: { fontWeight: 500 } }}
      />
      {annotation && isTabletNavOpen && (
        <ListItemSecondaryAction>{annotation}</ListItemSecondaryAction>
      )}
    </ListItem>
  )
}
