import { gql } from '@apollo/client'

export const ProfilesQuery = gql`
  query CoachesAccountPage {
    CoachProfiles(me: true) {
      message
      success
      profiles {
        profilePhoto
        displayName
      }
    }
  }
`
