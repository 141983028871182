import React from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import CoachProfileCard from 'components/coach-profile-card'
import EmptyPage from 'components/empty-page-state'
import { PageHeader } from 'components/page-header'
import Error from 'components/error'
import { MY_COACH_PROFILE, ToggleCoachStatus } from './query'
import { gtmEvent } from 'lib/gtm'
import CoachProfileAnalytics from 'pages/pga-coach/profile/index/coach-profile-analytics/index'
import { useStyles } from './styles'
import PromotedProfileCaption from 'components/promoted-profile-caption'
import RouterLinkButton from 'components/router-link-button'
import ExternalLinkButton from 'components/external-link-button'
import { PGA_COM_HOST } from 'env'
import SocialShareDialog from 'components/social-share-dialog'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import { IconShare } from 'components/atoms/icons'
import IconButton from '@material-ui/core/IconButton'

const Actions = ({ customSlug }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  if (isTablet || isMobile) {
    return (
      <Box display="flex" style={{ marginRight: '-8px' }}>
        <IconButton
          color="primary"
          href={`${PGA_COM_HOST}/coach/${customSlug}`}
        >
          <VisibilityOutlinedIcon />
        </IconButton>

        <SocialShareDialog
          trigger={
            <IconButton color="primary">
              <IconShare />
            </IconButton>
          }
          shareUrl={`${PGA_COM_HOST}/coach/${customSlug}`}
          shareTitle="Share Your Coach Profile"
          dialogTitle="Share Your Coach Profile"
          att="coach-profile-link"
        />

        <IconButton
          color="primary"
          href="profile/edit"
          data-cy="edit-profile-link"
        >
          <EditOutlinedIcon />
        </IconButton>
      </Box>
    )
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ width: '100%', margin: 0 }}
      >
        <ExternalLinkButton
          variant="outlined"
          color="primary"
          startIcon={<VisibilityOutlinedIcon />}
          href={`${PGA_COM_HOST}/coach/${customSlug}`}
        >
          View
        </ExternalLinkButton>
        <Box>
          <SocialShareDialog
            trigger={
              <Button
                variant="outlined"
                color="primary"
                startIcon={<IconShare />}
                style={{ marginLeft: '8px' }}
              >
                Share
              </Button>
            }
            shareUrl={`${PGA_COM_HOST}/coach/${customSlug}`}
            shareTitle="Share Your Coach Profile"
            dialogTitle="Share Your Coach Profile"
            att="coach-profile-link"
            showProfileUrl
          />
          <Button
            variant="outlined"
            color="primary"
            to="profile/edit"
            startIcon={<EditOutlinedIcon />}
            component={RouterLinkButton}
            style={{ marginLeft: '8px' }}
            data-cy="edit-profile-link"
          >
            Edit
          </Button>
        </Box>
      </Box>
    </>
  )
}

const CoachProfileIndexPage = () => {
  const history = useHistory()
  const classes = useStyles()

  const redirectToCreateProfile = () => {
    history.push('/pga-coach/profile/new')
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'coach-profile',
      formAction: 'clicked-create-coach-profile-button',
    })
  }

  const { data, loading, error, refetch } = useQuery(MY_COACH_PROFILE)
  const [toggleCoachStatus] = useMutation(ToggleCoachStatus, {
    onCompleted: refetch,
  })

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />

  const coachProfile = get(data, 'currentUser.attributes.coachProfile')
  const facilities = get(data, 'currentUser.attributes.facilities')

  const customSlug = coachProfile?.customSlug // customSlug is only available for coaches who can promote their profile

  return (
    <>
      <PageHeader
        title="Coach Profile"
        actions={coachProfile && <Actions customSlug={customSlug} />}
      />
      {coachProfile != null ? (
        <Grid container spacing={1}>
          <Grid container className={classes.coachProfileCardsContainer}>
            <Grid item xs={12} lg={12} xl={12}>
              <Box>
                <PromotedProfileCaption
                  coachProfile={coachProfile}
                  facilities={facilities}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={12} xl={12}>
              <Box>
                <CoachProfileCard
                  coachProfile={coachProfile}
                  toggleCoachStatus={toggleCoachStatus}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              xl={12}
              className={classes.analyticsCardContainer}
            >
              <Grid item xs={12} container justifyContent="center">
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <CoachProfileAnalytics />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <EmptyPage
          actionFn={redirectToCreateProfile}
          IconComp={() => <PersonOutlineOutlinedIcon fontSize="large" />}
          title="Coach Profile"
          caption="Your PGA Coach Profile will appear here once completed."
          buttonText="Create Coach Profile"
        />
      )}
    </>
  )
}

export default CoachProfileIndexPage
