import React, { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

/* usePortal -- hook that allows child components to render components
 *              outside the direct hierarchy of their parent component
 */
const usePortal = (el = 'div') => {
  const [isMounted, setIsMounted] = useState(false)
  const ref = useRef()

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
  }, [isMounted])

  const p = React.forwardRef((props, ref) => {
    if (!ref || !ref.current) {
      return null
    }

    return ReactDOM.createPortal(<>{props.children}</>, ref.current)
  })

  const target = React.createElement(el, { ref })

  const portal = ({ children }) => React.createElement(p, { ref, children })

  return [portal, target]
}

export { usePortal }
