import React from 'react'
import Box from '@material-ui/core/Box'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  CardRoot,
  CheckboxItemWrapper,
  StyledH5,
  StyledH4,
  HelperMsg,
  InputsTwoColumns,
  ErrorMsg,
} from 'components/coaches-profile/profile-stepper.styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { findIndex, some } from 'lodash'

const ServicesQuery = gql`
  query Services {
    Services {
      message
      success
      serviceTypes {
        serviceTypeId
        typeName
        description
        services {
          serviceId
          serviceName
          serviceType
        }
      }
    }
  }
`

export const Services = ({ serviceTypes }) => {
  const { register, errors, control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: 'services',
    control,
  })

  const isChecked = (serviceId, checkedServices) => {
    return some(checkedServices, { serviceId })
  }

  const toggleServiceChecked = (serviceId, checkedServices) => {
    isChecked(serviceId, checkedServices)
      ? remove(findIndex(checkedServices, { serviceId }))
      : append({ serviceId })
  }

  return (
    <Box display="flex" flexDirection="column">
      <StyledH4>Services</StyledH4>
      {errors.services && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>
          {errors.services?.message}
        </ErrorMsg>
      )}
      {serviceTypes.map(serviceType => {
        return (
          <CardRoot key={serviceType.typeName} className="auto-width">
            <StyledH5>{serviceType.typeName}</StyledH5>
            <HelperMsg>{serviceType.description}</HelperMsg>
            <InputsTwoColumns>
              {serviceType.services.map((service, i) => {
                return (
                  <CheckboxItemWrapper key={service.serviceId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            toggleServiceChecked(service.serviceId, fields)
                          }
                          checked={isChecked(service.serviceId, fields)}
                          color="secondary"
                        />
                      }
                      value={service.serviceId}
                      name={`services[${i}].serviceId`}
                      label={service.serviceName}
                      color="secondary"
                    />
                  </CheckboxItemWrapper>
                )
              })}
            </InputsTwoColumns>
          </CardRoot>
        )
      })}
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <input
              type="hidden"
              ref={register()}
              name={`services[${index}].serviceId`}
              value={item.serviceId}
            />
          </div>
        )
      })}
    </Box>
  )
}

export default function Service() {
  const { data, loading, error } = useQuery(ServicesQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />
  return <Services serviceTypes={data?.Services?.serviceTypes} />
}
