import { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import identity from 'lodash/identity'
import * as maybe from 'lib/maybe'

/**
 * Process result from useQuery/useMutation to handle our custom GraphQL responses.
 * Bubbles up error `message` if `success` is false.
 *
 * Custom GraphQL response format:
 *   {
 *     code: integer,
 *     success: boolean,
 *     message: string,
 *     [string]: any
 *   }
 */
export function handleHookResult(result, field, onSuccess = identity) {
  return maybe
    .of(result.data)
    .map(data => data[field])
    .map(fieldData =>
      fieldData.success
        ? { ...result, data: onSuccess(fieldData) }
        : {
          ...result,
          data: null,
          error: new ApolloError({ errorMessage: fieldData.message }),
        },
    )
    .withDefault(result)
}

/**
 * If `data.success` is `false`, then throw `data.message` as an `ApolloError`.
 */
export const throwIfUnsuccessful = (mutation, field) => async (...args) => {
  const result = await mutation(...args)
  const fieldData = result.data[field]

  if (!fieldData.success) {
    throw new ApolloError({ errorMessage: fieldData.message })
  }

  return result
}

/**
 * Map result data into a different data type if non-null.
 */
export function mapResultData({ data, ...rest }, mapper) {
  return {
    ...rest,
    data: maybe
      .of(data)
      .map(mapper)
      .withDefault(data),
  }
}

export function useMapResultData(result, mapper) {
  return useMemo(() => {
    const { data, ...rest } = result

    return {
      ...rest,
      data: maybe
        .of(data)
        .map(mapper)
        .withDefault(data),
    }
  }, [result, mapper])
}
