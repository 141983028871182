import { gql, useMutation } from '@apollo/client'

const PREPARE_RECORD_BOOKING_PAYMENT = gql`
  mutation PrepareRecordBookingPayment($enrollmentId: ID!) {
    prepareRecordBookingPayment(enrollmentId: $enrollmentId) {
      paymentIntent {
        clientSecret
        amount
        metadata {
          operation
          args
          lineItems {
            label
            amountInCents
          }
        }
      }
      success
      message
      code
    }
  }
`
export function usePrepareRecordBookingPayment() {
  const [prepareRecordBookingPayment, result] = useMutation(
    PREPARE_RECORD_BOOKING_PAYMENT,
  )
  return [prepareRecordBookingPayment, result]
}
