import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import TagSelectionInput from 'components/tag-selection-input'
import { useGroupEventTagOptions } from 'components/group-events/form-option-hooks'

const EventNameAndTags = () => {
  const { register, control, errors } = useFormContext()
  const tagOptions = useGroupEventTagOptions()
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="subtitle1" align="center">
          Event name
        </Typography>
        <TextField
          name="title"
          inputRef={register}
          error={!!errors.title}
          helperText={errors.title && 'This field is required'}
          placeholder="Event name"
          style={{ margin: '10px 0', maxWidth: '450px' }}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-start" my={3}>
        <Typography variant="subtitle1" gutterBottom>
          Event tags
        </Typography>
        <Box>
          <Controller
            name="tags"
            control={control}
            defaultValue={[]}
            render={({ onChange, value }, _) => (
              <>
                <TagSelectionInput
                  tags={tagOptions}
                  value={value}
                  onChange={onChange}
                  allowToSelectImmutableTags={true}
                />
                {errors.tags ? (
                  <Typography variant="caption" color="error">
                    {errors.tags.message}
                  </Typography>
                ) : null}
              </>
            )}
          />
        </Box>
      </Box>
    </>
  )
}

export default EventNameAndTags
