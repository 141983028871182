import { gql } from '@apollo/client'

const GET_BOOKING_LIMIT_OPTIONS = gql`
  query GetBookingLimitOptions($id: ID!) {
    coach(id: $id) {
      bookingLimit
      bookingCutoff
      canStudentCancel
    }
    bookingLimitOptions {
      label
      value
    }
    bookingCutoffOptions {
      label
      value
    }
  }
`

const SET_BOOKING_LIMIT = gql`
  mutation updateCoachPreferences($input: CoachPreferencesInput!) {
    updateCoachPreferences(input: $input) {
      success
      message
      coach {
        bookingLimit
        bookingCutoff
        canStudentCancel
      }
    }
  }
`
export { GET_BOOKING_LIMIT_OPTIONS, SET_BOOKING_LIMIT }
