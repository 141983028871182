import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import MenuArrowSvg from 'images/icons/fc-marketing/menu-arrow.svg'
import Popover from '@material-ui/core/Popover'
import MenuIcon from '@material-ui/icons/Menu'
import logo from 'images/jr-league-logo.svg'
import useIsMobile from 'components/program-marketing/useIsMobile'
import { topLeftMenu, urls } from './menus'

const useStyles = makeStyles(theme => ({
  logo: {
    height: 70,
  },
  menuItem: {
    marginLeft: '12px',
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
  },
  menuLink: {
    color: '#222222',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuSubLink: {
    fontSize: '16px',
    fontWeight: 700,
    padding: '8px 12px',
    color: '#222222',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#f9f5ec',
      color: '#ff7500',
    },
  },
  popover: {
    width: '100%',
  },
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
    },
  },
}))

const MenuDropDown = ({ anchorEl, open, handleClose, items }) => (
  <Menu
    anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={handleClose}
    PaperProps={{
      style: { padding: '12px', borderRadius: '10px', minWidth: '220px' },
    }}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
  >
    {items.map(sublink => (
      <div key={sublink.text} style={sublink.style}>
        <MenuSubLink
          text={sublink.text}
          href={sublink.url}
          handleClose={handleClose}
        />
      </div>
    ))}
  </Menu>
)

const MenuLink = ({
  anchorEl,
  open,
  href,
  text,
  subMenu,
  onClick,
  handleClose,
}) => {
  const classes = useStyles()
  return (
    <>
      <Link href={href} className={classes.menuLink}>
        <Typography className={classes.menuItem} onClick={onClick || undefined}>
          {text}
          {onClick && (
            <img
              alt="menu-arrow"
              src={MenuArrowSvg}
              style={{ fontSize: '16px', marginLeft: '6px' }}
            />
          )}
        </Typography>
      </Link>
      <MenuDropDown
        items={subMenu}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  )
}

const MenuSubLink = ({ handleClose, text, href }) => {
  const classes = useStyles()
  return (
    <MenuItem
      href={href}
      className={classes.menuSubLink}
      onClick={handleClose}
      component={Link}
    >
      {text}
    </MenuItem>
  )
}

const MobileMenu = ({ menu }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState('')
  const handleClick = text => setOpen(prev => (text === prev ? '' : text))
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {menu.map(({ text, url, subMenu }) => (
        <>
          <ListItem
            className={classes.menuLink}
            component={Link}
            button
            href={url}
            onClick={() => handleClick(text)}
          >
            <ListItemText
              primary={
                <span>
                  {text}{' '}
                  {subMenu && (
                    <img
                      alt="menu-arrow"
                      src={MenuArrowSvg}
                      style={{ fontSize: '16px', marginLeft: '6px' }}
                    />
                  )}
                </span>
              }
              className={classes.menuItem}
            />
          </ListItem>
          {subMenu &&
            subMenu.map(subLink => (
              <Collapse in={open === text} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    component={Link}
                    button
                    href={subLink.url}
                    className={classes.menuLink}
                  >
                    <ListItemText
                      style={subLink.style}
                      className={classes.menuItem}
                      primary={subLink.text}
                    />
                  </ListItem>
                </List>
              </Collapse>
            ))}
        </>
      ))}
    </List>
  )
}

const Header = () => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const [menu, setMenu] = React.useState({})

  const handleClick = name => ({ currentTarget }) =>
    setMenu({ name, anchorEl: currentTarget })
  const handleClose = () => setMenu({})

  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState(null)

  const handleMobileMenuClick = event => {
    setMobileMenuAnchor(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null)
  }

  const open = Boolean(mobileMenuAnchor)
  const id = open ? 'simple-popover' : undefined

  return (
    <AppBar position="fixed" color="inherit" style={{ boxShadow: 'none' }}>
      <Box className={classes.headerWrapper}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={2}>
            <a href={urls.home}>
              <img src={logo} alt="logo" className={classes.logo} />
            </a>
          </Box>

          {!isMobile &&
            topLeftMenu.map(({ text, url, subMenu = [] }) => (
              <MenuLink
                key={text}
                text={text}
                href={url}
                subMenu={subMenu}
                open={menu.name === text}
                anchorEl={menu.anchorEl}
                onClick={subMenu.length > 0 && handleClick(text)}
                handleClose={handleClose}
              />
            ))}
        </Box>

        {!isMobile && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              href={urls.search}
              component={Link}
              color="secondary"
              variant="contained"
            >
              Find Programs
            </Button>
          </Box>
        )}

        {isMobile && (
          <Box>
            <MenuIcon onClick={handleMobileMenuClick} />
            <Popover
              className={classes.popover}
              id={id}
              open={open}
              anchorEl={mobileMenuAnchor}
              onClose={handleMobileMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: { width: '100%' },
              }}
            >
              <MobileMenu menu={topLeftMenu} />
            </Popover>
          </Box>
        )}
      </Box>
    </AppBar>
  )
}

export default Header
