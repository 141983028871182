import React from 'react'
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { gtmEvent } from 'lib/gtm'

const BookingTypeVisibility = ({ bookingType, status, setStatus }) => {
  const handleStatus = (event, newStatus) => {
    setStatus(newStatus)
  }

  const setVisibility = visibility => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'booking-type',
      formAction: `set-booking-type-${visibility}`,
    })
  }

  return (
    <Box mt={3} mb={4}>
      <Typography variant="subtitle1" gutterBottom>
        Visibility
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="Booking Type Status"
          defaultValue={status}
          onChange={handleStatus}
        >
          <FormControlLabel
            value="ACTIVE"
            control={<Radio color="primary" />}
            label="Public"
            onClick={() => {
              setVisibility('active')
            }}
          />
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: '32px' }}
          >
            The booking type will be visible and bookable by you and your
            students.
          </Typography>
          <FormControlLabel
            value="INACTIVE"
            control={<Radio color="primary" />}
            label="Private"
            onClick={() => {
              setVisibility('inactive')
            }}
          />
          <Typography
            variant="body2"
            gutterBottom
            style={{ marginLeft: '32px' }}
          >
            The booking type will only be visible and bookable by you.
          </Typography>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default BookingTypeVisibility
