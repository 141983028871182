import React, { useState } from 'react'
import getDeviceType from 'lib/utils/getDeviceType'
import MyPgaAppIcon from 'images/icons/my-pga-app-icon.png'
import SmartBannerAd from './smart-banner'
import Cookie from 'js-cookie'
import { handleCtaClick, handleDismiss } from './utils'

const myPgaAndroidUrl =
  'https://play.google.com/store/apps/details?id=com.pga.MyPGA.production'
const myPgaiOSUrl = 'https://apps.apple.com/us/app/mypga/id1554350218'

const MyPgaBannerAd = ({ top }) => {
  const displayBanner = Cookie.get('appInstalledOrDismissed')
  const [isDisplaying, setIsDisplaying] = useState(
    !(displayBanner === 'nodisplay'),
  )

  const iosProps = {
    appIcon: MyPgaAppIcon,
    appStoreUrl: myPgaiOSUrl,
    titleText: 'MyPGA',
    subtitleText: 'GET — On the App Store',
    ctaText: 'Install',
    handleCtaClick: handleCtaClick(setIsDisplaying, myPgaiOSUrl, 'my-pga'),
    handleDismiss: handleDismiss(setIsDisplaying, 'my-pga'),
    top: top,
  }

  const androidProps = {
    appIcon: MyPgaAppIcon,
    appStoreUrl: myPgaAndroidUrl,
    titleText: 'MyPGA',
    subtitleText: 'GET — Google Play',
    ctaText: 'Install',
    handleCtaClick: handleCtaClick(setIsDisplaying, myPgaAndroidUrl, 'my-pga'),
    handleDismiss: handleDismiss(setIsDisplaying, 'my-pga'),
    top: top,
  }

  const device = getDeviceType()
  const deviceSpecificProps = device === 'android' ? androidProps : iosProps

  if (!isDisplaying) return null
  return <SmartBannerAd {...deviceSpecificProps} />
}

export default MyPgaBannerAd
