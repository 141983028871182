import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

// extension of the AdapterLuxon class that uses Sunday as the first day of the week instead of Monday
// based on src/lib/CustomLuxonUtils.js
class CustomLuxonAdapter extends AdapterLuxon {
  getWeekdays = () => {
    return ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
  }

  getWeekArray = date => {
    const weekLength = this.getWeekdays().length

    const firstDayOfMonth = date.startOf('month')
    const lastDayOfMonth = date.endOf('month').startOf('day')

    const startOfFirstWeekOfMonth = firstDayOfMonth.minus({
      days: firstDayOfMonth.weekday % weekLength,
    })

    const weeks = []
    let currentWeek = startOfFirstWeekOfMonth

    while (currentWeek <= lastDayOfMonth) {
      const startOfWeek = currentWeek
      const newWeek = Array.from({ length: weekLength }, (_, idx) =>
        startOfWeek.plus({ days: idx }),
      )
      weeks.push(newWeek)
      currentWeek = currentWeek.plus({ days: weekLength })
    }
    return weeks
  }
}

export default CustomLuxonAdapter
