import { isEmpty, get } from 'lodash'

export const getSocialMap = {
  instagram: {
    type: 'IG',
    url: 'instagram.com/',
    basePath: 'https://www.instagram.com/',
  },
  twitter: {
    type: 'TWT',
    url: 'twitter.com/',
    basePath: 'https://www.twitter.com/',
  },
  facebook: {
    type: 'FB',
    url: 'facebook.com/',
    basePath: 'https://www.facebook.com/',
  },
  youtube: {
    type: 'YT',
    url: 'youtube.com/',
    basePath: 'https://www.youtube.com/',
  },
}

export const removeBaseUrl = (val, social) => {
  if (!val || !social) return val
  const splitUrl = val.split(social.url)
  if (splitUrl.length > 1) {
    return `${social.basePath}${splitUrl[splitUrl.length - 1]}`
  }
  return null
}

export const makeSocialData = social => {
  if (!social || isEmpty(social)) return

  Object.keys(social)
    .map(type => ({
      type: getSocialMap[type] && getSocialMap[type].type,
      reference: removeBaseUrl(social[type], getSocialMap[type]),
      preferred: true,
    }))
    .filter(({ type }) => type)
}

const privateLabels = ['Private Equity', 'Private Non-Equity']

const makeFacilityData = facility => {
  return [
    {
      pgaFacilityId: facility.facilityId,
      facilityName: facility.name,
      street: get(facility, ['address', 'address1'], ''),
      city: get(facility, ['address', 'city'], ''),
      state: get(facility, ['address', 'state'], ''),
      zipCode: get(facility, ['address', 'zip'], ''),
      isPrivate: privateLabels.includes(facility.operationsTypeLabel),
      phoneNumber: facility.phoneNumber,
      website: facility.website,
      customData: {
        facilityRank: 1,
      },
    },
  ]
}

export const makeFormFieldObj = data => {
  if (data && !isEmpty(data)) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      memberId: data.id || null,
      memberType: data.type || null,
      profilePhoto: data.photo,
      phoneNumber: data.publicPhone,
      mobileNumber: data.publicMobile,
      email: data.publicEmail,
      socialJsonData: makeSocialData(data.social),
      facilities: makeFacilityData(data.primaryFacility || []),
      toolJsonData: [],
    }
  }
  return null
}
