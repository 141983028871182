import React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ConsumerEventCard from './consumer-event-card'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '45px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
      marginBottom: '70px',
    },
  },
}))

const EventsDisplay = ({ events }) => {
  const { eventSlug } = useParams()

  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        {events.map(event => (
          <Grid
            key={event.id}
            item
            lg={4}
            md={6}
            sm={12}
            style={{ width: '100%' }}
          >
            <ConsumerEventCard
              {...{ event, openEvent: eventSlug === event.slug }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default EventsDisplay
