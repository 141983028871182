import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'
import Dialog from 'components/dialog'

const useStyles = makeStyles(theme => ({
  editorRoot: {
    minHeight: '400px',
    width: '75%',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '0 16px',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  descriptionRoot: {
    marginTop: '42px',
  },
}))

const EventDescription = () => {
  const { register, setValue, control } = useFormContext()
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    register('description')
  }, [register])

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="flex-start"
      className={classes.descriptionRoot}
    >
      <Typography variant="subtitle1" gutterBottom>
        Description
      </Typography>
      <Typography variant="body2" gutterBottom>
        Provide a description for this event.
      </Typography>
      <div className={classes.editorRoot}>
        <MUIRichTextEditor
          style={{ minHeight: '500px' }}
          label="Enter your event description..."
          controls={['title', 'bold', 'italic', 'bulletList']}
          defaultValue={control.defaultValuesRef.current.description}
          onChange={value => {
            const content = JSON.stringify(
              convertToRaw(value.getCurrentContent()),
            )
            setValue('description', content)
          }}
        />
      </div>
      <Button onClick={() => setIsOpen(!isOpen)}>
        RECOMMENDED EVENT DETAILS
      </Button>
      <RecommendedDetailsDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  )
}

const recommendationsConfig = [
  {
    id: 1,
    sectionTitle: 'Identify who the event is for',
    bullets: [
      'Is this event going to befor beginners or advanced golfers',
      'Is the event child and/or family friendly?',
      'Are there age restrictions? Will alcohol be served?',
    ],
  },
  {
    id: 2,
    sectionTitle: 'Do attendees need equipment',
    bullets: ['Include in your description if golf equipment is required'],
  },
  {
    id: 3,
    sectionTitle: 'What are the expectations?',
    bullets: [
      'Will attendees need to be ready to go onto the golf course?',
      'How many participants wil be attending (5 is a lot different than 50),',
      'How much 1-on-1 time will I have with the coach?',
    ],
  },
  {
    id: 4,
    sectionTitle: 'Are friends allowed to join?',
    bullets: [
      'Can attendees bring friends or family?',
      'Is the event for members of the club only?',
    ],
  },
  {
    id: 5,
    sectionTitle: "What's included?",
    bullets: [
      'Will attendees receive range balls, shop discount, food and/or alcohol?',
    ],
  },
  {
    id: 6,
    sectionTitle: 'Frequently asked questions',
    bullets: [
      'Include a list of commonly asked questions',
      'Provide contact information',
    ],
  },
]

const RecommendedDetailsDialog = ({ isOpen, setIsOpen }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  return (
    <Dialog
      fullScreen={isMobile}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      showCloseButton
    >
      {({ closeDialog }) => (
        <>
          <DialogTitle>Recommended Event Details</DialogTitle>
          <DialogContent style={{ padding: '0 20px 20px 20px' }}>
            {recommendationsConfig.map(({ id, sectionTitle, bullets }) => (
              <div key={id} style={{ margin: '16px' }}>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {sectionTitle}
                </Typography>
                <ul style={{ margin: 0 }}>
                  {bullets.map((listItem, i) => (
                    <li key={i}>
                      <Typography
                        variant="caption"
                        style={{ fontSize: '14px' }}
                      >
                        {listItem}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default EventDescription
