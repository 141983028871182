import React from 'react'
import { useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

const ExternalRegistrationUrl = () => {
  const { register, errors } = useFormContext()

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="subtitle1" align="center">
        External Registration URL
      </Typography>
      <TextField
        label="Enter registration URL"
        placeholder="https://www.example.com"
        name="externalRegistrationUrl"
        inputRef={register}
        error={!!errors?.externalRegistrationUrl}
        helperText={errors?.externalRegistrationUrl?.message}
        style={{ margin: '10px 0', maxWidth: '450px' }}
      />
    </Box>
  )
}

export default ExternalRegistrationUrl
