import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useAuth } from 'lib/auth'
import { DD_CLIENT_TOKEN, DD_APP_ID, APP_VERSION, APP_ENVIRONMENT } from 'env'

const DatadogActivate = () => {
  const [isActivated, setIsActivated] = useState(false)
  const { user } = useAuth()

  useEffect(() => {
    // currently RUM only activates in production
    if (APP_ENVIRONMENT !== 'production') return null

    if (!isActivated) {
      datadogRum.init({
        applicationId: DD_APP_ID,
        clientToken: DD_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'coach-tools',
        env: APP_ENVIRONMENT,
        version: APP_VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [/https:\/\/coach-tools\..*pga\.com/],
        enableExperimentalFeatures: ['feature_flags'],
      })
      datadogRum.startSessionReplayRecording()

      setIsActivated(true)
    }

    if (user != null && datadogRum.getUser().id !== user.externalId) {
      datadogRum.setUser({
        id: user.externalId,
        type: user.type,
        gid: `${user.type}/${user.id}`,
      })
    }
  }, [isActivated, user])

  return null
}

export default DatadogActivate
