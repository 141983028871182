import { useEffect } from 'react'

// header approx 70px, but 140px looks better with labels
const HEADER_OFFSET = 140

const scrollToElement = (elementPosition, headerOffset) => {
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset
  window.scrollTo({
    top: offsetPosition,
  })
}

const byTopBound = (a, b) => a.topBound - b.topBound

const getDOMElement = name => {
  const [element] = document.getElementsByName(name)
  return (
    element && {
      element,
      topBound: element.getBoundingClientRect().top,
    }
  )
}

export const scrollToError = (errors, headerOffset = HEADER_OFFSET) => {
  const elementNames = Object.keys(errors)
  if (elementNames.length) {
    const [firstError] = elementNames
      .map(getDOMElement)
      .filter(Boolean)
      .sort(byTopBound)

    if (firstError) {
      scrollToElement(firstError.topBound, headerOffset)
      firstError.element.focus()
    }
  }
}

export const useScrollToError = (errors, headerOffset) => {
  useEffect(() => {
    scrollToError(errors, headerOffset)
  }, [errors, headerOffset])
}
