import { gql, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useStyles } from './styles'
import { MY_PGA_COM_HOST, PGA_COM_HOST, RECAPTCHA_V2_KEY } from 'env'
import { shuffle } from 'lodash'
import { useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useIsMobile } from 'lib/hooks'
import { getInitials } from 'lib/text-display'
import ButtonCta from 'components/atoms/button-cta'
import DownloadMyPGAsection from 'components/download-mypga-section/download-mypga-section'
import { useSnackbar } from 'notistack'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from '@material-ui/core/Link'
import { gtmEvent } from 'lib/gtm'
import MarketingOptIn from 'components/marketing-opt-in/marketing-opt-in'
import { useFeatureFlags, flags } from 'lib/feature-flags'
import { useAuth } from 'lib/auth'

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(50)
    .required('First name is required')
    .label('First name'),
  lastName: Yup.string()
    .trim()
    .max(100)
    .required('Last name is required')
    .label('Last name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .max(100)
    .required('Email is required')
    .label('Email'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter a valid phone number')
    .label('Phone number'),
  interest: Yup.string().required('You must select an interest'),
})

const intentOptions = shuffle([
  'Help me shoot lower scores',
  'Fix a specific problem in my golf game',
  'Help my junior golfer improve',
  'Connect me with other golfers',
])

const introductionOptions = [
  'Introduce me to golf',
  'Introduce my child to golf',
  'Introduce my family to golf',
]

const insertIndex = Math.floor(Math.random() * intentOptions.length)

intentOptions.splice(insertIndex, 0, introductionOptions)

const LeadForm = ({ coach, source }) => {
  const classes = useStyles()
  const [submitted, setSubmitted] = useState(false)
  const isMobile = useIsMobile()
  const initials = getInitials(coach.name)
  const { enqueueSnackbar } = useSnackbar()
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const { user } = useAuth()
  const [marketingOptInChecked, setMarketingOptInChecked] = useState(false)
  const userIsNotOptedIn = !user?.marketingOptInAt
  const [marketingOptIn] = useFeatureFlags([
    flags.FLAG_FEAT_MARKETING_OPT_IN_EXP,
  ])

  const coachProfileUrl =
    source === 'MYPGA_SCHEDULE_CONTACT_BUTTON'
      ? `${MY_PGA_COM_HOST}/coach/${coach.coachProfile.slug}/schedule`
      : `${PGA_COM_HOST}/coach/${coach.coachProfile.slug}`

  const moreCoachesUrl = `${PGA_COM_HOST}/coaches/search`

  const COACHING_LEAD_CREATE = gql`
    mutation coachingLeadCreate($params: CoachingLeadCreateRequest!) {
      CoachingLeadCreate(params: $params) {
        success
        message
      }
    }
  `

  const [coachingLeadCreate, { error: submitError }] = useMutation(
    COACHING_LEAD_CREATE,
    {
      context: { recaptchaToken },
      onError: () => {},
      onCompleted: (data, error) => {
        const leadEmail = getValues('email')
        const leadIntent = getValues('interest')

        if (data.CoachingLeadCreate.success) {
          setSubmitted(true)
          gtmEvent({
            event: 'complete-lead-submission',
            coach_name: coach?.name,
            coach_id: coach?.id,
            facility:
              coach?.facilities.length > 0 ? coach.facilities[0].name : null,
            city: coach?.coachProfile?.city,
            state: coach?.coachProfile?.state,
            lead_email: leadEmail,
            lead_intent: leadIntent,
            source: source,
          })
          gtmEvent({
            event: 'formSubmit',
            formSource: source,
            formCategory: 'lead-submitted',
            formAction: 'lead-form-submit',
          })
          if (marketingOptInChecked) {
            gtmEvent({
              event: 'marketing-opt-in',
              source: 'mypga_lead_form',
              variant: marketingOptIn,
            })
          }
          window.scrollTo(0, 0)
        } else {
          enqueueSnackbar(
            'We had trouble processing your request. Please try again.',
            { variant: 'error' },
          )
        }
      },
    },
  )

  const {
    register,
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [termsAndPrivacyAccepted, setTermsAndPrivacyAccepted] = useState(false)

  useEffect(() => {
    if (marketingOptIn) {
      setTermsAndPrivacyAccepted(true)
    }
  }, [marketingOptIn])

  const handleMarketingOptInChange = isChecked => {
    setMarketingOptInChecked(isChecked)
  }

  const onSubmit = async data => {
    const marketingOptInAt = marketingOptInChecked
      ? new Date().toISOString()
      : null

    coachingLeadCreate({
      variables: {
        params: {
          coachSlug: coach.coachProfile.slug,
          email: data.email,
          phoneNumber: `+1${data.phoneNumber.replace(/\D/g, '')}`,
          firstName: data.firstName,
          lastName: data.lastName,
          intent: data.interest,
          source: source,
          marketingOptInAt: marketingOptInAt,
        },
      },
    })
  }

  if (submitted) {
    return (
      <Container
        style={{ marginTop: '60px', paddingBottom: '32px' }}
        data-testid="lead-form"
      >
        <Grid container justifyContent="center">
          <Grid item md={6}>
            <Box pb={1}>
              <Typography variant="h3">Thanks for your interest!</Typography>
            </Box>
            <Box pb={6}>
              <Typography>
                Your information has been shared with Coach{' '}
                {coach.coachProfile.firstName}. You will also receive an email
                confirmation.
              </Typography>
            </Box>
            <Box pb={3} className={classes.buttonWrapper}>
              <Box pb={3}>
                <ButtonCta
                  variant="outlined"
                  color="primary"
                  href={coachProfileUrl}
                >
                  View {coach.coachProfile.firstName}'s Profile
                </ButtonCta>
              </Box>
              <Box pb={3}>
                <ButtonCta
                  variant="contained"
                  color="primary"
                  href={moreCoachesUrl}
                >
                  Explore More Coaches
                </ButtonCta>
              </Box>
            </Box>
            <hr />
            <DownloadMyPGAsection />
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <Container
      style={{ marginTop: '60px', paddingBottom: '32px' }}
      data-testid="lead-form"
    >
      <Grid container>
        <Grid item md={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card className={classes.root}>
              <CardContent
                className={`${classes.coachInfo} ${classes.disableSpacing}`}
              >
                <Avatar
                  className={classes.coachAvatar}
                  alt={coach.name}
                  src={coach.coachProfile.profilePhoto}
                  style={{ marginRight: isMobile ? '1rem' : '36px' }}
                >
                  {initials}
                </Avatar>
                <Box>
                  <Typography variant="h4">
                    Contact Coach {coach.coachProfile.firstName}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            <Box>
              <Typography className={classes.subtext}>
                Please complete this form to be connected with Coach{' '}
                {coach.coachProfile.firstName}.
              </Typography>
            </Box>

            <Box mt={6}>
              <Typography variant="subtitle1">Your Information</Typography>
              <FormControl margin="normal">
                <TextField
                  name="firstName"
                  label="First name"
                  required
                  error={errors.firstName}
                  helperText={errors.firstName?.message}
                  inputRef={register}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  name="lastName"
                  label="Last name"
                  required
                  error={errors.lastName}
                  helperText={errors.lastName?.message}
                  inputRef={register}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  name="phoneNumber"
                  label="Phone number"
                  required
                  type="tel"
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  inputRef={register}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  name="email"
                  label="Email"
                  required
                  error={errors.email}
                  helperText={errors.email?.message}
                  inputRef={register}
                />
              </FormControl>
            </Box>

            <Box mt={6} mb={5}>
              <Typography variant="subtitle1">
                What can {coach.coachProfile.firstName} help you with?
              </Typography>
              <FormControl margin="normal">
                <InputLabel htmlFor="select-interest">
                  Select a goal *
                </InputLabel>
                <Controller
                  defaultValue=""
                  name="interest"
                  control={control}
                  as={
                    <Select
                      name="interest"
                      label="Select a goal * "
                      required
                      id="select-interest"
                      value=""
                      defaultValue=""
                      error={errors.interest}
                    >
                      {intentOptions.flat().map(opt => (
                        <MenuItem key={opt} value={opt}>
                          {opt}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>

              {marketingOptIn && userIsNotOptedIn && (
                <MarketingOptIn
                  checked={marketingOptInChecked}
                  onChange={handleMarketingOptInChange}
                  useMarketingOptIn={marketingOptIn}
                />
              )}

              {!marketingOptIn ? (
                <FormControl required margin="normal">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="termsAndPrivacy"
                        required
                        onChange={e =>
                          setTermsAndPrivacyAccepted(e.target.checked)
                        }
                        error={errors.termsAndPrivacy}
                      />
                    }
                    label={
                      <Typography>
                        I have read and agree to PGA of America’s
                        <Link
                          href="https://www.pga.com/pga-of-america/privacy-policy"
                          target="_blank"
                        >
                          {' '}
                          Privacy Policy{' '}
                        </Link>
                        and
                        <Link
                          href="https://www.pga.com/pga-of-america/terms-of-service"
                          target="_blank"
                        >
                          {' '}
                          Terms of Service
                        </Link>
                        .
                      </Typography>
                    }
                  />
                  {errors.termsAndPrivacy && (
                    <FormHelperText error>
                      {errors.termsAndPrivacy.message}
                    </FormHelperText>
                  )}
                </FormControl>
              ) : null}

              <FormControl margin="normal">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_V2_KEY}
                  onChange={token => setRecaptchaToken(token)}
                />
                {submitError && (
                  <FormHelperText error>
                    An error has occurred. Please try again later.
                  </FormHelperText>
                )}
              </FormControl>

              {marketingOptIn ? (
                <Typography variant="body1" className={classes.proceedingText}>
                  By proceeding, you agree to PGA of America’s{' '}
                  <Link
                    className={classes.link}
                    href="https://www.pga.com/pga-of-america/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    className={classes.link}
                    href="https://www.pga.com/pga-of-america/terms-of-service"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                  .
                </Typography>
              ) : null}
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={
                isSubmitting || !recaptchaToken || !termsAndPrivacyAccepted
              }
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LeadForm
