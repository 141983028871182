import React from 'react'
import ReactMarkdown from 'react-markdown'
import Box from '@material-ui/core/Box'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import { humanizeMinutes } from 'utils/dateUtils'
import { useStyles } from './styles'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import IconButton from '@material-ui/core/IconButton'
import LessonPackDetails from './lesson-packs-details'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import pluralize from 'lib/pluralize'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { redirectToLogin, useAuth } from 'lib/auth'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { IconButtonCtaArrow } from './../atoms/icons'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'

const BookingDetails = ({
  lessonInfo,
  isOpen,
  setIsOpen,
  bookingUrl,
  coachName,
  coachExternalId,
  nextExpirationDate,
  lessonCreditBalanceQuantity,
  isSingleSession,
  setOpenRequestAccessCode,
  setOpenVerifyAccessCode,
  coachIsViewingOwnSchedule,
}) => {
  const {
    id,
    title,
    description,
    locationName,
    durationInMinutes,
    lessonPacks = [],
    requiresAccessCode,
  } = lessonInfo
  const classes = useStyles()
  const { isLoggedIn } = useAuth()
  const history = useHistory()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  const formattedExpirationDate = expDate => {
    return format(parseISO(expDate), 'MMMM d, yyyy')
  }

  const handleRequestCodeClick = () => {
    setIsOpen(false)
    if (!isLoggedIn) {
      const returnTo = `${window.location.href}?lessonTypeId=${id}`
      redirectToLogin('consumer', returnTo)
      return
    }
    setOpenRequestAccessCode(true)
  }

  let redirectUrl =
    isSingleSession || coachIsViewingOwnSchedule
      ? bookingUrl
      : `${bookingUrl}&isLessonPacks=true`
  redirectUrl = lessonCreditBalanceQuantity
    ? `${redirectUrl}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`
    : redirectUrl

  const handleBookClick = () => {
    if (requiresAccessCode) {
      setIsOpen(false)
      setOpenVerifyAccessCode(true)
      return
    }
    history.push(redirectUrl)
  }

  const session = pluralize(lessonCreditBalanceQuantity, 'session')

  return (
    <Dialog
      open={!!isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box className={classes.actionButtonContainer}>
          <IconButton
            onClick={() => setIsOpen(false)}
            className={classes.alignRight}
          >
            <CloseOutlinedIcon color="primary" />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          className={classes.title}
          style={{ paddingTop: '1rem' }}
        >
          {title}
        </Typography>
        {description ? (
          <Typography variant="body1" className={classes.description}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Typography>
        ) : null}
      </DialogTitle>

      <DialogContent className={classes.contentRootV2}>
        <Box display="flex" className={classes.detailsRow}>
          <Typography variant="subtitle1">Where</Typography>
          <Typography variant="body1">{locationName}</Typography>
        </Box>

        <Box display="flex" className={classes.detailsRow}>
          <Typography variant="subtitle1">Time Duration</Typography>
          <Typography variant="body1">
            {humanizeMinutes(durationInMinutes)}
          </Typography>
        </Box>

        {requiresAccessCode && !lessonCreditBalanceQuantity && (
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Access Code Required
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  This offering requires an access code provided by the coach.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {lessonPacks.length > 0 && !coachIsViewingOwnSchedule && (
          <>
            {!isSingleSession ||
            (isSingleSession && !lessonCreditBalanceQuantity) ? (
              <Box width="100%" className={classes.detailsRow}>
                <Typography variant="subtitle1">Lesson packs</Typography>

                {lessonPacks.map(lessonPack => (
                  <LessonPackDetails
                    {...lessonPack}
                    isMobile={isMobile}
                    bookingUrl={bookingUrl}
                    key={lessonPack.id}
                  />
                ))}
              </Box>
            ) : (
              <Box width="100%" className={classes.detailsRow}>
                <Typography variant="subtitle1">Lesson packs</Typography>
                <Typography variant="body1">
                  You have {lessonCreditBalanceQuantity} {session} left.
                  {nextExpirationDate
                    ? `They expire on ${formattedExpirationDate(
                      nextExpirationDate,
                    )}`
                    : ''}
                </Typography>
              </Box>
            )}
          </>
        )}
        <Box display="flex" className={classes.detailsRow}>
          <Typography variant="subtitle1">Questions?</Typography>
          <Box>
            <Typography variant="body1">
              If you have any questions, please send {coachName.split(' ')[0]} a
              message.
            </Typography>
            <SensitiveInfoWrapper>
              <StreamSendMessageButton
                userIds={[coachExternalId]}
                color="primary"
                className={classes.textButton}
                endIcon={<IconButtonCtaArrow />}
              >
                Send Message
              </StreamSendMessageButton>
            </SensitiveInfoWrapper>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          style={{ paddingBottom: '2rem' }}
        >
          {requiresAccessCode && !lessonCreditBalanceQuantity && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleRequestCodeClick}
              >
                Request Code
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBookClick}
            >
              {/* Will show Book session if the user has >=1 lesson credit */}
              {lessonPacks.length > 0 &&
              !coachIsViewingOwnSchedule &&
              !lessonCreditBalanceQuantity
                ? 'Purchase Pack'
                : 'Book Session'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default BookingDetails
