import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFeatureFlags, flags } from 'lib/feature-flags'

export const useEventDetails = () => {
  const [isEnforceFccEnabled] = useFeatureFlags([flags.FLAG_FEAT_ENFORCE_FCC])
  const { getValues, register, watch, setValue } = useFormContext()
  const academyRequired = watch('academyRequired')
  const academyId = getValues('academyId') || ''

  const [isAcademyEvent, setIsAcademyEvent] = useState(academyRequired)

  useEffect(() => {
    register('academyRequired')
  }, [register])

  useEffect(() => {
    setIsAcademyEvent(academyRequired)
  }, [academyRequired])

  return {
    isEnforceFccEnabled,
    getValues,
    register,
    watch,
    setValue,
    academyRequired,
    academyId,
    isAcademyEvent,
    setIsAcademyEvent,
  }
}

export default useEventDetails
