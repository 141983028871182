import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import { formatCurrency } from 'pages/schedule/utils'

const PaymentSummary = ({ priceInDollars }) => {
  const classes = useStyles()
  return (
    <Box mb={2}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="body1" color="textSecondary">
          Subtotal
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {formatCurrency(priceInDollars)}
        </Typography>
      </Box>
      <Box
        display="flex"
        className={classes.infoRow}
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="body1" className={`${classes.fontBolded}`}>
          Due Today
        </Typography>
        <Typography variant="body1" className={`${classes.fontBolded}`}>
          {formatCurrency(priceInDollars)}
        </Typography>
      </Box>
      <Divider style={{ margin: '1rem 0px 1rem' }} />
    </Box>
  )
}
export default PaymentSummary
PaymentSummary.propTypes = {
  priceInDollars: PropTypes.number,
}
