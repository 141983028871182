import { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'

const GET_PAYMENT_ACCOUNT_SETUP_REDIRECT_URL = gql`
  query paymentAccountSetup(
    $accountName: String
    $redirect: PaymentAccountRedirectType
  ) {
    paymentAccountSetup(accountName: $accountName, redirect: $redirect) {
      oauthLink
    }
  }
`

const StripeAccountSetupRedirect = ({ accountName }) => {
  const { data } = useQuery(GET_PAYMENT_ACCOUNT_SETUP_REDIRECT_URL, {
    variables: { accountName, redirect: 'MY_PGA' },
  })
  useEffect(() => {
    const redirectUrl = data?.paymentAccountSetup?.oauthLink
    if (redirectUrl) {
      window.location = redirectUrl
    }
  }, [data])
  return null
}

export default StripeAccountSetupRedirect
