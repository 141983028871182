import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { InternalFlags, fetchActiveFlags } from 'lib/feature-flags'

const useFlagValues = () => {
  const [counter, setCounter] = useState(1)
  const rerender = () => setCounter(counter + 1)

  const flagValues = InternalFlags.get()
  const updateFlagValue = (key, value) => {
    const newFlagValues = {
      ...flagValues,
      [key]: value,
    }
    InternalFlags.set(newFlagValues)
    rerender()
  }

  return [flagValues, updateFlagValue]
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const ABTestPage = () => {
  const [featureFlags, setFeatureFlags] = useState([])
  const [flagValues, updateFlagValue] = useFlagValues()
  const classes = useStyles()

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const activeFlags = await fetchActiveFlags()
        setFeatureFlags(activeFlags)
      } catch (err) {
        setFeatureFlags([])
      }
    }

    fetchFeatureFlags()
  }, [])

  return (
    <Container>
      <Box m={2}>
        <Typography variant="h3">Feature Flags</Typography>
      </Box>
      <div className={classes.root}>
        <Grid container spacing={4}>
          {featureFlags.map(flag => (
            <Grid key={flag.id} item xs={3}>
              <Card className={classes.paper}>
                <div>{flag.key}</div>
                <RadioGroup
                  value={flagValues[flag.key] || ''}
                  onChange={e => updateFlagValue(flag.key, e.target.value)}
                >
                  {flag.variants.map(variant => (
                    <FormControlLabel
                      key={variant.id}
                      value={variant.key}
                      control={<Radio />}
                      label={variant.key}
                    />
                  ))}
                </RadioGroup>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
  )
}

export default ABTestPage
