import React from 'react'
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    borderTopColor: theme.palette.grey[300],
    borderTopStyle: 'solid',
    borderTopWidth: '1px',

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),

    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },

  borderBox: {
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },

  titleContainer: {
    display: 'flex',
    minWidth: 'max-content',
    flexGrow: '1',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: theme.palette.grey[400],
    [theme.breakpoints.up('sm')]: {
      border: '0 none',
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  crumbTitle: {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  backButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      width:
        // 67 from the button + 16 from the padding
        'calc(67px + 16px)',
    },
  },
  backButton: {
    height: '35px',
    width: '35px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: 0,
    paddingLeft: '0.5rem',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      justifyContent: 'center',
    },
  },
}))

function PageCrumbs({ title, crumbs }) {
  const classes = useStyles()
  if (!crumbs) {
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map((value, index) => {
        if (index === crumbs.length - 1) {
          return (
            <Typography
              color="textPrimary"
              className={classes.crumbTitle}
              key={`${index}+${value}`}
            >
              {title}
            </Typography>
          )
        } else {
          return (
            <Link
              component={RouterLink}
              color="inherit"
              key={`${index}+${value}`}
              to={value.to}
            >
              {value.label}
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  )
}

export default function PageHeader({
  title,
  crumbs,
  actions,
  tabs,
  isMobile,
  activeTab,
  ...props
}) {
  const classes = useStyles()
  return (
    <>
      <Box
        className={classes.borderBox}
        data-testid="student-header"
        {...props}
      >
        <Box className={classes.headerContainer}>
          {crumbs && crumbs.length > 0 ? (
            <Box className={classes.backButtonContainer}>
              <Button
                component={RouterLink}
                to={
                  activeTab && isMobile
                    ? crumbs[crumbs.length - 1].to
                    : crumbs[0].to
                }
              >
                <KeyboardArrowLeftIcon className={classes.backButton} />
              </Button>
            </Box>
          ) : null}
          <Box className={classes.titleContainer}>
            <Hidden xsDown>
              <PageCrumbs {...{ crumbs, title }} />
            </Hidden>
            {isMobile ? (
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.title}
                data-testid="page-header-title"
              >
                {activeTab ? `${title} ${activeTab}` : title}
              </Typography>
            ) : (
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.title}
                data-testid="page-header-title"
              >
                {title}
              </Typography>
            )}
          </Box>
          <Hidden xsDown>
            <Box className={classes.actionWrapper}>{actions}</Box>
          </Hidden>
        </Box>
        <Hidden xsDown>{tabs || null}</Hidden>
      </Box>
    </>
  )
}
