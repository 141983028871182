import React from 'react'
import { gtmEvent } from 'lib/gtm'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import StepDescriptionOfferings from '../step-description-offerings'
import { useStyles } from './styles'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import StudentCard from '../components/student-card'
import BookingSummaryCoachInfo from '../components/BookingSummaryCoachInfo'
import { ContainerRoot } from '../components/styles'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core'
import themes, { extendTheme } from 'themes'
import { useIsMobile } from 'lib/hooks'
import { useHistory, useParams } from 'react-router-dom'
import { PGA_COM_HOST } from 'env'
import StudentProfileCTA from '../components/StudentProfileCTA'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'

const SummaryLayout = ({
  idOrSlug,
  lessonType,
  lessonTypeId,
  locationId,
  student,
  lessonCreditBalanceQuantity,
}) => {
  const { coach } = lessonType
  const { slug: academySlug } = useParams()
  const classes = useStyles()
  const isMobile = useIsMobile()
  const history = useHistory()

  const isCoachingCenterBooking = history.location.pathname.includes(
    'coaching-center',
  )
  const isFCCBooking = history.location.pathname.includes(
    FRISCO_COACHING_CENTER_SLUG,
  )
  const bookNewSessionUrl = isCoachingCenterBooking
    ? coach?.bookingUrl
    : `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&lessonCreditBalanceQuantity=${lessonCreditBalanceQuantity}`

  const onCoachingCenterBooking = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-offerings-button',
    })
    if (isFCCBooking) {
      // window.location.href = `${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`
      window.open(`${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`, '_blank')
    } else {
      window.location.href = `${PGA_COM_HOST}/coaching-centers/${academySlug}`
    }
  }
  const onBookAnotherSession = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-another-session-button',
    })
    if (isFCCBooking) {
      // window.location.href = `${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`
      window.open(`${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`, '_blank')
    } else if (isCoachingCenterBooking) {
      window.location.href = `${PGA_COM_HOST}/coaching-centers/${academySlug}`
    } else {
      window.location.href = bookNewSessionUrl
    }
  }
  const onViewCoachOfferings = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-coachofferings-button',
    })
    window.location.href = window.location.pathname
  }

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <ContainerRoot className={classes.root}>
        <Grid container justifyContent="center">
          <Grid item md={7} xs={12}>
            <StepDescriptionOfferings
              stepText=""
              title="Thank you!"
              description="Your booking is complete. You will receive a confirmation email shortly."
            />
            <Alert
              severity="info"
              className={classes.warningBox}
              icon={
                <ConfirmationNumberOutlinedIcon
                  color="primary"
                  fontSize="small"
                />
              }
            >
              <strong>
                We have applied one of the lessons from your pack.{' '}
              </strong>
              You have {lessonCreditBalanceQuantity} lessons left.
              <br />
            </Alert>

            <Divider className={classes.paddedDivider} />
            <Grid container spacing={2}>
              <Typography variant="h4" gutterBottom>
                Details
              </Typography>
            </Grid>
            <Grid container style={{ marginBottom: '2rem' }}>
              <BookingSummaryCoachInfo lessonType={lessonType} coach={coach} />
            </Grid>
            <StudentCard student={student} />
            {isCoachingCenterBooking && (
              <>
                <Divider className={classes.dividerStyles} />
                <StudentProfileCTA
                  golferProfile={student?.golferProfile}
                  studentId={student?.id}
                />
              </>
            )}
            <Divider className={classes.dividerStyles} />
            <Grid
              container
              spacing={1}
              direction={isMobile ? 'column' : 'row'}
              justifyContent={isMobile ? 'center' : 'space-around'}
              style={{ marginTop: '1.5rem' }}
            >
              <Grid
                item
                xs={12}
                sm={5}
                container
                justifyContent={isMobile ? 'center' : 'flex-end'}
              >
                {isCoachingCenterBooking ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.buttonScale}
                    onClick={onCoachingCenterBooking}
                    children={'View Offerings'}
                  />
                ) : (
                  <Button
                    variant="outlined"
                    className={classes.buttonScale}
                    onClick={onViewCoachOfferings}
                    children={'View Coach Offerings'}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                container
                justifyContent={isMobile ? 'center' : 'flex-start'}
              >
                <Button
                  variant="contained"
                  className={`${classes.buttonOverrideBook} ${classes.buttonScale}`}
                  onClick={onBookAnotherSession}
                  children="Book Another Session"
                />
              </Grid>
            </Grid>
            <DownloadMyPGAsection />
          </Grid>
        </Grid>
      </ContainerRoot>
    </ThemeProvider>
  )
}

export default SummaryLayout

SummaryLayout.propTypes = {
  idOrSlug: PropTypes.string.isRequired,
  lessonType: PropTypes.object.isRequired,
  lessonTypeId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  student: PropTypes.object.isRequired,
  lessonCreditBalanceQuantity: PropTypes.number.isRequired,
}
