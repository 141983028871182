import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { centsToDollars, centsToDollarsFormatted } from 'lib/utils/number'
import pluralize from 'lib/pluralize'
import { useStyles } from './styles'
import { useIsMobile } from 'lib/hooks'

const LessonPackDetails = ({ quantity, priceInCents }) => {
  const session = pluralize(quantity, 'session')
  const classes = useStyles()
  const isMobile = useIsMobile()
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      <Typography variant="body1" className={classes.lessonPackDetails}>
        {`${quantity} ${session} $${centsToDollars(priceInCents)} total`} &nbsp;
      </Typography>
      {!isMobile ? (
        <Typography variant="body1" className={classes.lessonPackDetails}>
          {` (${centsToDollarsFormatted(priceInCents / quantity)} / session)`}
        </Typography>
      ) : null}
    </Box>
  )
}

export default LessonPackDetails
