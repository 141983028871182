import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  coachProfileCardsContainer: {
    margin: '2rem auto 6rem',
    maxWidth: '832px',
    padding: '0 1rem',
  },
  launchIcon: {
    fontSize: '0.8rem',
    margin: '10px 0 10px 5px',
  },
  analyticsCardContainer: {
    borderRadius: '4px',
    padding: '2rem',
    border: '1px solid #ccc',
  },
}))
