import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Dialog from 'components/dialog'

const CANCEL_GROUP_EVENT = gql`
  mutation CancelGroupEvent($groupEventId: ID!, $reason: String) {
    cancelGroupEvent(groupEventId: $groupEventId, reason: $reason) {
      success
      message
    }
  }
`

const CancelGroupEventDialog = ({ event }) => {
  const history = useHistory()

  const [cancelGroupEvent, { loading }] = useMutation(CANCEL_GROUP_EVENT, {
    onCompleted: data => {
      if (data.cancelGroupEvent.success) {
        enqueueSnackbar('Event cancelled successfully', {
          variant: 'success',
        })
        setTimeout(() => {
          history.push('/pga-coach/bookings/events')
        }, 1000)
      } else {
        enqueueSnackbar(data.cancelGroupEvent.message, {
          variant: 'error',
        })
      }
    },
  })

  const onCancelEvent = () => {
    cancelGroupEvent({
      variables: {
        groupEventId: event.id,
        reason: cancellationReason,
      },
    })
  }

  const onChange = event => {
    setCancellationReason(event.target.value)
  }
  const [cancellationReason, setCancellationReason] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  return (
    <Box mb={4}>
      <Dialog
        trigger={
          <Button type="button" color="primary">
            <Typography color="error"> Cancel This Event </Typography>
          </Button>
        }
        maxWidth="sm"
      >
        {({ closeDialog }) => {
          const onCancelEventConfirm = () => {
            onCancelEvent()
          }
          return (
            <>
              <DialogTitle>Cancel this Event?</DialogTitle>
              <DialogContent>
                <Typography gutterBottom>
                  We'll notify any registrants that the event has been canceled.
                  You are responsible for refunding any payments collected as
                  part of registering for this event.
                </Typography>
                <br />
                {loading ? <CircularProgress color="primary" /> : null}
                <Typography gutterBottom>
                  <strong>Provide a Cancellation Reason</strong> (optional)
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  name="cancellationReason"
                  label="Reason for cancellation"
                  onChange={onChange}
                />
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={closeDialog}>
                  Back to Edit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onCancelEventConfirm}
                >
                  Cancel Event
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Dialog>
    </Box>
  )
}

export default CancelGroupEventDialog
