import React from 'react'
import { Box, Button } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import LoadingButton from 'components/loading-button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  buttonGap: {
    gap: `${theme.spacing(2)}px`,
  },
  button: {
    textTransform: 'uppercase',
  },
}))

export default function StepControls({
  onNextStep,
  onGoBack,
  nextStepText,
  nextDisabled = false,
  loading,
  onSecondaryAction,
  secondaryActionText,
  ...props
}) {
  const classes = useStyles()
  return (
    <Box display="flex" justifyContent="space-between" mt={6} {...props}>
      {onGoBack ? (
        <Button
          onClick={onGoBack}
          size="large"
          color="secondary"
          startIcon={<ChevronLeftIcon />}
          className={classes.button}
        >
          Go Back
        </Button>
      ) : (
        <div />
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        className={classes.buttonGap}
      >
        {onSecondaryAction && (
          <Button
            onClick={onSecondaryAction}
            size="large"
            color="secondary"
            className={classes.button}
          >
            {secondaryActionText}
          </Button>
        )}
        {onNextStep && (
          <LoadingButton
            onClick={onNextStep}
            size="large"
            variant="contained"
            color="secondary"
            disabled={nextDisabled}
            loading={loading}
            className={classes.button}
          >
            {nextStepText || 'Continue'}
          </LoadingButton>
        )}
      </Box>
    </Box>
  )
}
