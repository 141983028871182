import { gql } from '@apollo/client'

const GET_COACH_ACCOUNT = gql`
  query GetCoachFacilitiesLessonTypesAccountQuery {
    currentUser {
      attributes {
        ... on Coach {
          coachProfile {
            id
          }
          facilities {
            id
          }
        }
      }
      groupEvents {
        id
        isCanceled
      }
    }
    lessonTypes {
      id
    }
    coachAvailabilities {
      id
      coachFacility {
        id
        name
      }
      timeSlots {
        day
        startTime
        endTime
      }
    }
  }
`

export default GET_COACH_ACCOUNT
