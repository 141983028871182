import React, { Fragment } from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'

function ContactRadio({ contactMethod, setContactMethod }) {
  return (
    <Fragment>
      <Typography variant="body1">Preferred Contact Method</Typography>

      <RadioGroup
        row
        aria-label="contact-method"
        name="contact-method"
        value={contactMethod}
        onChange={e => setContactMethod(e.target.value)}
      >
        <FormControlLabel
          value="EMAIL"
          control={<Radio color="primary" />}
          label=" Email"
        />
        <FormControlLabel
          value="TEXT"
          control={<Radio color="primary" />}
          label=" Phone/Text"
        />
      </RadioGroup>
    </Fragment>
  )
}

export default ContactRadio
