import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Dialog, MenuItem } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import gql from 'graphql-tag'
import useStyles from '../../styles'

const DeleteLeadDialog = ({ lead, open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // TODO: consider changing to 50
  const MAX_CUSTOM_REASON_LENGTH = 255

  const [deletionReason, setDeletionReason] = useState('')
  const [selectError, setSelectError] = useState(false)
  const handleSelectChange = event => {
    if (selectError && event.target.value !== '') {
      setSelectError(false)
    }
    setDeletionReason(event.target.value)
  }

  const [customReason, setCustomReason] = useState('')
  const [customReasonError, setCustomReasonError] = useState(false)
  const handleCustomChange = event => {
    setCustomReason(event.target.value)
    if (event.target.value.length > MAX_CUSTOM_REASON_LENGTH) {
      setCustomReasonError(true)
    } else {
      setCustomReasonError(false)
    }
  }

  const DELETION_REASONS = [
    'No response',
    'Not accepting new students',
    'Not a fit for my coaching',
    'Cost',
    'Continued conversation outside app',
    'Already my student',
    'Referred to another coach',
    'Golfer is not in my area',
    'Other',
  ]

  const deleteCoachingLead = gql`
    mutation DeleteCoachingLead($id: ID!, $deletionReason: String) {
      deleteCoachingLead(id: $id, deletionReason: $deletionReason) {
        message
        success
      }
    }
  `

  const [deleteLead] = useMutation(deleteCoachingLead, {
    onCompleted: () => {
      const allLeadsPath = '/pga-coach/students/leads'
      enqueueSnackbar('Lead deleted successfully', {
        variant: 'success',
      })
      history.push(allLeadsPath) // TODO test redirect with cypress
    },

    onError: () => {
      enqueueSnackbar('Error deleting lead', {
        variant: 'error',
      })
    },
  })

  const onDeleteLead = leadToDelete => {
    if (deletionReason === '') {
      setSelectError(true)
      return
    }
    if (customReasonError) {
      return
    }
    deleteLead({
      variables: {
        id: leadToDelete.id,
        deletionReason:
          deletionReason === 'Other' ? customReason : deletionReason,
      },
    })
  }

  const isDeleteButtonDisabled = () => {
    if (deletionReason === '') {
      return true
    } else if (
      deletionReason === 'Other' &&
      (customReason == null || customReason === '')
    ) {
      return true
    } else {
      return false
    }
  }

  const classes = useStyles()

  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      <DialogTitle>Delete Lead</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deleting a lead is an action that cannot be undone. Please provide a
          reason to help us improve both the coach and golfer experience.
        </DialogContentText>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2}
        >
          <FormControl required variant="outlined" error={selectError}>
            <InputLabel>Select Reasoning</InputLabel>
            <Select
              value={deletionReason}
              onChange={handleSelectChange}
              label="Select Reasoning *"
              inputProps={{ 'data-testid': 'deletion-reason-select' }}
            >
              {DELETION_REASONS.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectError ? (
            <FormHelperText error>Please select a reason.</FormHelperText>
          ) : null}
          {deletionReason === 'Other' ? (
            <Box pt={2}>
              <TextField
                label="Please Explain Below *"
                multiline
                maxRows={4}
                value={customReason}
                onChange={handleCustomChange}
                error={customReasonError}
                helperText={
                  customReasonError
                    ? `Reason must be less than ${MAX_CUSTOM_REASON_LENGTH} characters.`
                    : null
                }
                inputProps={{ 'data-testid': 'custom-reason-input' }}
              />
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          className={classes.deleteButton}
          onClick={() => onDeleteLead(lead)}
          disabled={
            selectError || customReasonError || isDeleteButtonDisabled()
          }
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteLeadDialog
