import React from 'react'
import { shuffle } from 'lodash'
import { useFormContext, Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ImageSelectionInput from 'components/image-selection-input'
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined'
import { CloudinaryImageUpload, largeUploadPreset } from 'lib/cloudinary'

const featuredImageUrls = shuffle([
  'https://images.unsplash.com/photo-1584304474743-a04b149f4210?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjc4MTc&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1560674498-ec05053cbf14?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyMzQ&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1465195079111-667efe5ba139?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyNTE&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1602646395218-8739d82c619a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyNzI&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1602991174407-a015b35a7b00?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyODc&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1526166997988-ce48e2f004af?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgyOTg&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/1VkBGy4i3UUohOdXjrQR75/520e3726df72c06b4dfa4ae19e38ef79/9-Holes_with_a_PGA_Coach.jpg?fl=progressive&q=80&h=300',
  'https://images.unsplash.com/photo-1584837140804-599306fb37f9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzMjE&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1520389013787-910f801d75f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzMzE&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1562204320-c7f5f2a04156?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzNDI&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.unsplash.com/photo-1595827432953-7161e19e303e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyMjgyMDl8MHwxfGFsbHx8fHx8fHx8fDE2MjAwNjgzNTM&ixlib=rb-1.2.1&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/3wYU4gtx5tVWC01pSE5KaX/367878430c660e6599f529974899a76c/Get_Golf_Ready.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/5S9MaySGq84GTgIkAv4Gsd/a30934c493a9987fb88531c330b66e28/Supervised_Practice.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/5pwW9QEmceJSnxcLBQCVra/a6007f49a0e07992f42f449614f700ff/Short_Game_Boot_Camp__resized_.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/5OcEKlF4sPIdqBztv2WV2F/737639d5f6f6ca281816e6ff5aef1ee0/Scoring_Boot_Camp_-_Putting.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/4eHIvtryQofzyPek7OPLzo/cefb7cdec4b7bd332297162decbf13a1/Whiskey___Wedges__resized.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/5bk7I9TcRGRMn8PRCDOmcB/74e621ceaa74c1809ef7931bdb38358a/Ladies_101_Beginner__resized.jpeg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/55EjiRECsYQypvOGkgbq2G/c51bebf18315a2969714804e078ee08f/Ladies_201__Intermediate_.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/edeFlzU1Oe1eYn3v845Ze/033cee660090d7f883943c5fc515158a/Ladies_301__Advanced_.jpg?fl=progressive&q=80&h=300',
  'https://images.ctfassets.net/56u5qdsjym8c/MvsB6wacE5x9bSFqn3Ku3/1cc0fbe743594b3d8343afaf16fbbdb7/On_Course_Game_Assessment__resized_.jpg?fl=progressive&q=80&h=300',
])

const FeaturedImageStep = () => {
  const { errors, control } = useFormContext()
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={5}>
        <Typography variant="h4" align="center">
          Select an image to feature on your offering page.
        </Typography>
      </Box>
      <Controller
        name="featuredImageUrl"
        control={control}
        defaultValue={null}
        render={({ onChange, value }, _) => (
          <>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <CloudinaryImageUpload
                render={({ openUploadDialog, uploadError }) => (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="button"
                      onClick={() => openUploadDialog()}
                    >
                      <CloudUploadOutlined />
                      &nbsp; Upload Image
                    </Button>
                    {uploadError != null ? (
                      <Box my={1}>
                        <Typography
                          variant="body1"
                          color="error"
                          align="center"
                        >
                          {uploadError}
                        </Typography>
                      </Box>
                    ) : null}
                  </>
                )}
                onUpload={uploadedImageUrl => {
                  featuredImageUrls.unshift(uploadedImageUrl)
                  onChange(uploadedImageUrl)
                }}
                options={{
                  upload_preset: largeUploadPreset,
                  cropping_aspect_ratio: 4 / 3,
                }}
              />
            </Box>
            <Box mb={3} mt={1}>
              <Typography variant="body-1" align="center">
                (Choose images at least 1980 px wide for best results)
              </Typography>
            </Box>
            <Box mb={5}>
              <Typography variant="body-1" align="center">
                - OR -
              </Typography>
            </Box>
            {errors.featuredImageUrl ? (
              <Typography variant="caption" color="error">
                Please select an image to continue
              </Typography>
            ) : null}
            <ImageSelectionInput
              imageUrls={
                value && !featuredImageUrls.some(e => e.includes(value))
                  ? [value, ...featuredImageUrls]
                  : featuredImageUrls
              }
              value={value}
              onChange={onChange}
            />
          </>
        )}
      />
    </Box>
  )
}

export default FeaturedImageStep
