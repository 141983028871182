import { gql } from '@apollo/client'

export const MY_COACH_PROFILE = gql`
  query CoachOwnProfile {
    currentUser {
      attributes {
        ... on Coach {
          facilities {
            id
            name
            isPrivate
            coachProfilesFacilities {
              visible
              offersCoachingAtPrivateClub
            }
          }
          coachProfile {
            coachProfileId
            lastName
            firstName
            displayName
            customSlug
            profilePhoto
            title
            status
            city
            state
            facilities {
              facilityId
              facilityName
              street
              city
              state
              zipCode
              phoneNumber
            }
            coach {
              canPromoteContent
            }
          }
        }
      }
    }
  }
`

export const ToggleCoachStatus = gql`
  mutation ToggleCoachInactive($coachProfileId: ID!) {
    ToggleCoachInactive(coachProfileId: $coachProfileId) {
      message
      success
      coachProfileId
      status
    }
  }
`
