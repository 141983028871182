import React from 'react'
import Box from '@material-ui/core/Box'
import EmptyPageState from 'components/empty-page-state'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import NewConversationDialog from './new-conversation-dialog'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'

const EmptyChannel = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <EmptyPageState
        IconComp={() => <MessageOutlinedIcon fontSize="large" />}
        title="No conversations yet"
        caption="Messages will appear here once you start a conversation"
        buttonText={
          <NewConversationDialog
            trigger={
              <Button color="primary" variant="contained">
                <Typography variant="body1">Start a conversation</Typography>
              </Button>
            }
          />
        }
      />
    </Box>
  )
}

export default EmptyChannel
