import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core'
import { withProtectedRoute, roles } from 'lib/auth'
import themes, { extendTheme } from 'themes'
import CoachAppBar from 'components/coach-app-bar'
import { useHideIntercom } from 'lib/support'
import { useMobileHeight } from 'lib/hooks'

const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

const ProductBoardIframe = () => {
  const height = useMobileHeight()

  return (
    <iframe
      title="productboard"
      src="https://portal.productboard.com/qjdccwfypyvbkvewpcvkfl2b?hide_header=1"
      frameborder="0"
      width="100%"
      maxWidth="100%"
      style={{ height: `calc(${height} - 60px)` }}
    />
  )
}

export const IdeasPage = () => {
  useHideIntercom()

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach | Ideas</title>
        <meta name="description" content="PGA Coach | Ideas" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <>
        <CoachAppBar position="relative" hideContactButton />
        <ProductBoardIframe />
      </>
    </ThemeProvider>
  )
}

export default withProtectedRoute(IdeasPage, roles.COACH)
