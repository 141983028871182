import React from 'react'
import { useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { useAuth } from 'lib/auth'
import StripeInput from 'components/payments/stripe-input'
import AlertWithDivider from 'pages/schedule/components/AlertWithDivider'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}

const PaymentInformation = ({
  lessonType,
  paymentRequired,
  setPaymentRequired = () => {},
  allowPaymentOverride = false,
}) => {
  const { user } = useAuth()
  const { register, errors } = useFormContext()

  const canSkipPayment = user && user?.id === lessonType?.coach?.id

  // provides an override for a deferred payment scenario
  // this is used for the "Send Payment Request" feature
  if (!lessonType.acceptsOnlinePayments && !allowPaymentOverride) {
    return null
  }

  return (
    <>
      <Box mb={1} mt={2} pt={2}>
        <Typography variant="h4">
          <Box display="flex" justifyContent="space-between">
            Enter payment details
            {canSkipPayment && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!paymentRequired}
                    onChange={() => setPaymentRequired(!paymentRequired)}
                  />
                }
                label="Skip payment"
              />
            )}
          </Box>
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        style={{ display: paymentRequired ? 'flex' : 'none' }}
      >
        <Grid item mb={1}>
          <AlertWithDivider text="Your credit card information is not being stored in our system." />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mb={2}>
            <TextField
              name="payment.name"
              inputRef={register}
              error={!!errors?.payment?.name}
              helperText={errors?.payment?.name?.message}
              label="Cardholder name"
              placeholder="Cardholder name"
              data-testid="cardholder-name"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  autoComplete: 'cc-name',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mb={2}>
            <TextField
              label="Card Number"
              InputLabelProps={{ shrink: true }}
              data-testid="card-number"
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  options: ELEMENT_OPTIONS,
                  autoComplete: 'cc-number',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            <TextField
              label="Expiration"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  options: ELEMENT_OPTIONS,
                  autoComplete: 'cc-exp',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            <TextField
              label="CVC"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  options: ELEMENT_OPTIONS,
                  autoComplete: 'cc-csc',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentInformation
