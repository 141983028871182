import { gql, useQuery, useMutation } from '@apollo/client'

const GET_PRACTICE_SESSION_TYPES = gql`
  query practiceSessionTypes($slug: String!) {
    academy(slug: $slug) {
      id
      name
      practiceSessions {
        id
        student {
          id
          firstName
          lastName
        }
        practiceSessionType {
          title
        }
        reservation {
          id
          canceled
          startDateTime
          endDateTime
          reason
          spaceName
        }
        transactions {
          amountInCents
          createdAt
          registrationPayment {
            id
          }
        }
      }
      facilities {
        timezone
      }
    }
  }
`
export function useGetPracticeSessionTypes({ slug }) {
  return useQuery(GET_PRACTICE_SESSION_TYPES, {
    variables: {
      slug,
    },
  })
}

const CANCEL_PRACTICE_SESSION_FOR_STUDENT = gql`
  mutation CancelPracticeSessionForStudent($reservationId: ID!) {
    cancelPracticeSessionForStudent(reservationId: $reservationId) {
      success
      message
    }
  }
`
export function useCancelPracticeSessionForStudent() {
  return useMutation(CANCEL_PRACTICE_SESSION_FOR_STUDENT)
}
