import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { MenuItem } from '@material-ui/core'
import { useWizardFormContext } from 'components/wizard-form/context'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

const AcademySelect = ({ fccAcademyId = null }) => {
  const { errors, control, watch } = useFormContext()
  const {
    extraData: { coachAcademies },
  } = useWizardFormContext()
  const isAcademyRequired = watch('academyRequired')

  return (
    <FormControl error={!!errors.academyId} variant="outlined" fullWidth>
      <InputLabel id="category">Location*</InputLabel>
      <Controller
        name="academyId"
        control={control}
        render={({ onChange, value }, _) => (
          <Select
            labelId="academy"
            label="Select academy"
            value={fccAcademyId || value || ''}
            onChange={onChange}
            data-cy={'academy'}
            disabled={!!fccAcademyId}
          >
            {coachAcademies.map(academy => (
              <MenuItem
                key={academy.id}
                value={academy.id}
                data-cy={'academy-menu-item'}
              >
                {academy.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {errors.academyId ? (
        <Typography variant="caption" color="error">
          {/* this is a split for PGA HOPE events. `isAcademyEvent` is more a validation for whether the Academy/Coaching center belongs to a PGA HOPE Section
          or not. If it does, then we want to display `Section` instead of `Coaching Center`.
          Coaching center is the word that we are using for now.
           */}
          Please Select {isAcademyRequired ? ' a Section' : 'a Coaching Center'}
        </Typography>
      ) : null}
    </FormControl>
  )
}

export default AcademySelect
