import Avatar from '@material-ui/core/Avatar'
import RouterLinkButton from 'components/router-link-button'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'

const BookingTypeCard = ({
  lessonType,
  remainingCredits,
  expiresAt,
  bookingUrl,
  coachName,
}) => {
  const classes = useStyles()
  const locationId = lessonType.locations[0].id
  const bookSessionUrl =
    '/coach' +
    bookingUrl.split('/coach')[1] +
    `?lessonTypeId=${lessonType.id}&locationId=${locationId}` +
    `&lessonCreditBalanceQuantity=${remainingCredits}`

  const formattedExpirationDate = expDate => {
    if (!expDate) return 'None'
    return format(parseISO(expDate), 'EEEE d MMMM yyyy')
  }
  return (
    <div className={classes.bookingType}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Avatar className={classes.iconStyled}>
          <ConfirmationNumberOutlinedIcon color="primary" />
        </Avatar>
        <Box my={1}>
          <Chip
            className={clsx(classes.chip, classes.chipSuccess)}
            label="Purchased"
          />
          <Chip
            className={clsx(classes.chip, classes.chipInfo)}
            label={`${remainingCredits} lessons left`}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="body1">{lessonType.title}</Typography>
        <Box mb={2} mt={1}>
          <Typography
            variant="caption"
            className={`${classes.captionText} ${classes.lighterText}`}
          >
            {coachName}
          </Typography>
          <br />
          {expiresAt && (
            <Typography
              variant="caption"
              className={`${classes.captionText} ${classes.lighterText}`}
            >
              Expiration: {formattedExpirationDate(expiresAt)}
            </Typography>
          )}
        </Box>
        <RouterLinkButton
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          startIcon={<CalendarTodayOutlinedIcon />}
          to={bookSessionUrl}
        >
          BOOK A SESSION
        </RouterLinkButton>
      </Box>
    </div>
  )
}

export default BookingTypeCard
