import { Divider, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PGALogo from 'images/pga-logo-white.png'

const useStyles = makeStyles(theme => ({
  footerLogoSection: {
    backgroundColor: '#000000',
    display: 'flex',
  },
  footerLogo: {
    padding: '1rem 0rem',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '3rem',
      marginRight: 'auto',
    },
  },
  footerLinks: {
    backgroundColor: '#001123',
    color: '#FFFFFF',
    display: 'flex',
    padding: '1.5rem 3rem',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  links: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
      marginLeft: '3rem',
      alignSelf: 'flex-end',
    },
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      padding: '6px 0px',
    },
  },
  copyright: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '18px',
    },
  },
}))

const CondensedFooter = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.footerLogoSection}>
        <div className={classes.footerLogo}>
          <a href="https://www.pga.com">
            <img src={PGALogo} alt="PGA Logo" style={{ width: '60px' }} />
          </a>
        </div>
      </div>
      <div className={classes.footerLinks}>
        <div className={classes.copyright}>
          <Typography variant="caption">
            {'\u00A9'} Copyright PGA of America 2023.
          </Typography>
        </div>
        <div className={classes.links}>
          <Typography variant="caption" className={classes.link}>
            <Link
              href="https://www.pga.com/pga-of-america/privacy-policy"
              color="inherit"
            >
              Privacy Policy
            </Link>
          </Typography>
          <Divider orientation="vertical" flexItem variant="middle" />
          <Typography variant="caption" className={classes.link}>
            <Link
              href="https://www.pga.com/pga-of-america/terms-of-service"
              color="inherit"
            >
              Terms of Service
            </Link>
          </Typography>
          <Divider orientation="vertical" flexItem variant="middle" />
          <Typography variant="caption" className={classes.link}>
            <Link
              href="https://privacyportal.onetrust.com/webform/d026f30a-622a-40b3-b261-d78a6b839240/e468c3de-717f-44a2-8576-29799026c21a?utm_campaign=redirect&utm_medium=301&utm_source=https%3A%2F%2Fwww.pga.com%2Fcontact"
              color="inherit"
            >
              Do Not Sell My Information
            </Link>
          </Typography>
        </div>
      </div>
    </>
  )
}

export default CondensedFooter
