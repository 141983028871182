import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  errorText: {
    color: 'red',
  },
  contactsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactCard: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    margin: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  actionsStyles: {
    margin: '10px 0',
  },
  actionsWrapper: {
    margin: '10px 0',
  },
  noLeadsWrapper: {
    height: '450px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default useStyles
