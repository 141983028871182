const STEPS = {
  STEP_BASE_INFO: 'STEP_BASE_INFO',
  STEP_ADDITIONAL_INFO: 'STEP_ADDITIONAL_INFO',
  STEP_CONFIRMATION: 'STEP_CONFIRMATION',
}

export const StepLabels = {
  [STEPS.STEP_BASE_INFO]: 'Golfer info',
  [STEPS.STEP_ADDITIONAL_INFO]: 'Physical Info',
}

export const Steps = [
  {
    name: StepLabels.STEP_BASE_INFO,
  },
  {
    name: StepLabels.STEP_ADDITIONAL_INFO,
  },
  {
    name: StepLabels.STEP_CONFIRMATION,
  },
]
