const isValidInterval = i => {
  if (i.start === null || i.end === null) {
    return true
  }
  return i.end > i.start
}

const isWithinInterval = (time, interval) =>
  interval.start <= time && time <= interval.end

const validateIntervals = intervals => {
  const validIntervals = intervals.filter(isValidInterval)

  return intervals.reduce((acc, i) => {
    const isInvalid = !isValidInterval(i)
    const isOverlap =
      !isInvalid &&
      validIntervals
        .filter(iv => i.id !== iv.id)
        .some(
          iv => isWithinInterval(i.start, iv) || isWithinInterval(i.end, iv),
        )
    if (isInvalid || isOverlap) {
      acc[i.id] = { isInvalid, isOverlap }
    }
    return acc
  }, {})
}

export default validateIntervals
