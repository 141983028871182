import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'components/dialog'
import AddPaymentAccountForm from './add-payment-account-form'

const AddPaymentAccountDialog = ({ trigger }) => {
  return (
    <Dialog trigger={trigger} maxWidth="sm" fullWidth showCloseButton>
      {() => {
        return (
          <>
            <DialogTitle>Add payment account</DialogTitle>
            <DialogContent dividers>
              <AddPaymentAccountForm />
            </DialogContent>
          </>
        )
      }}
    </Dialog>
  )
}

export default AddPaymentAccountDialog
