import UnsavedChangesDialog from 'components/unsaved-changes-dialog'
import React, { Fragment, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

export default function UnsavedChangesPrompt({ hasChanges }) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [destination, setDestination] = useState('')
  const history = useHistory()

  const handleBlockedNavigation = nextLocation => {
    if (!showConfirmation && hasChanges) {
      setDestination(nextLocation)
      setShowConfirmation(true)
      return false
    }
    return true
  }

  const handleDiscardEdits = () => {
    setShowConfirmation(false)
    history.push(destination)
  }

  return (
    <Fragment>
      <Prompt when={hasChanges} message={handleBlockedNavigation} />

      <UnsavedChangesDialog
        open={showConfirmation}
        onDiscard={handleDiscardEdits}
        onKeepEditing={() => setShowConfirmation(false)}
      />
    </Fragment>
  )
}
