import React from 'react'
import AppCard from 'components/app-card'
import logo from 'images/jr-league-logo.svg'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { signup } from 'lib/junior-league-utils/routes'

const useStyles = makeStyles(theme => ({
  jrlLogo: {
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default function JrLeague() {
  const classes = useStyles()
  return (
    <AppCard
      title="Create the next generation of golfers through a fun team format"
      to={signup}
      buttonText="Launch"
      testid="jr-league-tile"
      logo={{
        src: logo,
        alt: 'PGA Jr. League logo',
        className: classes.jrlLogo,
      }}
    />
  )
}
