import { gql } from '@apollo/client'

const GET_GROUP_EVENTS = gql`
  query ConsumerGroupEvents {
    currentUser {
      groupEvents {
        id
        startDateTime
        endDateTime
        seriesEndDateTime
        coach {
          id
          name
        }
        assistantCoaches {
          name
        }
        tags
        timezone
        isCanceled
        title
        priceInCents
        featuredImageUrl
        slug
        coachFacility {
          id
          name
        }
        description
        sessions {
          id
          startDateTime
          endDateTime
        }
      }
    }
  }
`

export default GET_GROUP_EVENTS
