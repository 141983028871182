import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '45px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
      marginBottom: '70px',
    },
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chip: {
    color: '#fff',
    borderRadius: '4px',
    height: '22px',
    fontSize: '10px',
  },
  chipInfo: {
    background: '#0B79D0',
  },
  chipWarning: {
    background: '#C77700',
    marginRight: theme.spacing(1),
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  bookingType: {
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  iconStyled: {
    backgroundColor: 'rgba(0, 35, 75, 0.08)',
    height: '48px',
    width: '48px',
  },
  actionButton: {
    padding: '12px',
    width: '100%',
  },
  actionContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  mainIcon: {
    color: theme.palette.primary.main,
    background: 'rgba(0, 117, 202, 0.08)',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))
