import { gql } from '@apollo/client'

export const SEND_SPECIALTY_PROGRAM_INTEREST = gql`
  mutation sendSpecialtyProgramInterest(
    $input: SpecialtyProgramInterestInput!
  ) {
    sendSpecialtyProgramInterest(input: $input) {
      success
      message
    }
  }
`
