export const frequencyOptions = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'new',
    label: 'New to the Game',
  },
]

export const proficiencyOptions = [
  {
    value: 'new',
    label: 'New',
    supportText: 'Just starting out, learning the basics of golf.',
  },
  {
    value: 'familiar',
    label: 'Familiar',
    supportText: 'Comfortable with the game, working on improving skills',
  },
  {
    value: 'intermediate',
    label: 'Intermediate',
    supportText: 'Honing skills, aiming for consistency and lower scores.',
  },
  {
    value: 'advanced',
    label: 'Advanced',
    supportText:
      'Highly experienced, with a deep understanding of golf and active involvement in the golf community.',
  },
]

export const handicapOptions = [
  {
    value: '0',
    label: '<0',
  },
  {
    value: '1-5',
    label: '1-5',
  },
  {
    value: '6-10',
    label: '6-10',
  },
  {
    value: '11-15',
    label: '11-15',
  },
  {
    value: '16-20',
    label: '16-20',
  },
  {
    value: '21+',
    label: '21+',
  },
  {
    value: 'Not sure',
    label: 'Not sure',
  },
]
export const genderOptions = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Non-binary', value: 'N' },
  { label: 'Prefer to self-describe', value: 'SD' },
]
export const pronounOptions = [
  { label: 'He/Him/His', value: 'HE' },
  { label: 'She/Her/Hers', value: 'SHE' },
  { label: 'They/Them/Theirs', value: 'THEY' },
]
