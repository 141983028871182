import React from 'react'
import { Channel, Window, MessageList, MessageInput } from 'stream-chat-react'
import { withStreamClient } from 'lib/stream-messaging'

const ChannelEmbed = withStreamClient(({ channel }) => {
  return (
    <Channel channel={channel}>
      <Window>
        <MessageList />
        <MessageInput />
      </Window>
    </Channel>
  )
})

const WrappedChannelEmbed = ({ channel }) => (
  <div className="str-chat-embed">
    <ChannelEmbed channel={channel} />
  </div>
)

export default WrappedChannelEmbed
