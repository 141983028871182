import { PGA_COM_HOST } from 'env'
import React, { useState, useEffect } from 'react'
import Confetti from 'react-dom-confetti'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { PartyPopperOutlinedIcon } from 'components/icons/refreshed-icons'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import SocialSharePopover from 'components/social-share-popover'
import useStyles from './group-event-registration-form.styles'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import themes from 'themes'
import DownloadMyPGAsection from 'components/download-mypga-section/download-mypga-section'

const confettiConfig = {
  angle: '219',
  spread: 360,
  startVelocity: '50',
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '1',
  width: '12px',
  height: '8px',
  perspective: '499px',
  colors: ['#00234B', '#AB9157', '#043362', '#BBA778'],
}

const RegistrationComplete = ({ groupEvent, completedRegistration }) => {
  const [isConfettiEnabled, setIsConfettiEnabled] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsConfettiEnabled(true)
    }, 500)
  })

  const classes = useStyles()
  const groupEventLink = `${PGA_COM_HOST}/things-to-do/events/${groupEvent.slug}`
  return (
    <ThemeProvider theme={createTheme(themes.offeringsRefresh)}>
      <Container
        className={classes.formStateContainer}
        maxWidth="sm"
        disableGutters
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={6}
          px={3.75}
        >
          <Box mb={3}>
            <CircularHighlightedIcon
              icon={PartyPopperOutlinedIcon}
              highlightColor="#e9ebf0"
            />
          </Box>
          <Confetti active={isConfettiEnabled} config={confettiConfig} />
          <Typography variant="h4" className={classes.webMobileTextCentering}>
            Success! You have registered for
          </Typography>
          <Typography
            variant="h5"
            style={{ margin: '1.5rem auto', textAlign: 'center' }}
          >
            {groupEvent.title}
          </Typography>

          <Box textAlign="center" mb={3}>
            <Typography variant="body1" className={classes.successText}>
              You'll receive an email confirmation shortly to:
              <br />
              {completedRegistration.contact.email}.
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography
              variant="body1"
              className={`${classes.successText} ${classes.webMobileTextCentering}`}
            >
              We look forward to being a part of your golf journey!
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Button
              variant="outlined"
              color="primary"
              href={groupEventLink}
              className={classes.button}
            >
              Event Details
            </Button>
            <SocialSharePopover
              trigger={
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.buttonText}
                  >
                    Share
                  </Typography>
                </Button>
              }
              url={groupEventLink}
              text={`I just registered for ${groupEvent.title} at ${groupEvent.coachFacility.name}!`}
            />
          </Box>
        </Box>
      </Container>
      <DownloadMyPGAsection subtitle="Connect with your coach and stay up to date on your PGA HOPE program." />
    </ThemeProvider>
  )
}

export default RegistrationComplete
