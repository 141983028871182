const hasNonNullProperty = obj => {
  if (!obj) return false
  for (const key in obj) {
    if ((obj[key] || obj[key] === false) && key !== '__typename') {
      return true
    }
  }
  return false
}

export default hasNonNullProperty
