import gql from 'graphql-tag'

export const CoachInquiriesQuery = gql`
  query GetCoachInquiries {
    coachStudents {
      intent
      contact: contacts {
        id
        firstName
        lastName
        email
        phoneNumber
      }
    }

    coachingInquiries {
      intent
      createdAt
      contact {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      coachProfile {
        id
        firstName
      }
    }
  }
`

export const RequestCoachReviews = gql`
  mutation CoachReviewRequest($params: ReviewRequestInput!) {
    CoachReviewRequest(params: $params) {
      message
      success
    }
  }
`
