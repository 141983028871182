import React from 'react'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useIsMobile } from 'lib/hooks'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const ReviewBookingSkeleton = () => {
  const isMobile = useIsMobile()

  const useStyles = makeStyles(theme => ({
    rounded: {
      borderRadius: '1rem',
    },
    roundeOffset: {
      borderRadius: '1.25rem',
      marginBottom: '.75rem',
    },
  }))

  const classes = useStyles()

  return (
    <Container style={{ marginTop: '1.25rem' }}>
      {/* Booking details section */}
      <Box>
        {isMobile ? (
          <Box>
            <Grid container item direction="row" alignItems="center">
              <Skeleton
                variant="rect"
                width={45}
                height={12}
                style={{ marginLeft: '.75rem' }}
                className={classes.roundeOffset}
              />
            </Grid>
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              style={{ marginTop: '1.5rem' }}
            >
              <Skeleton variant="circle" width={64} height={64} />
              <Skeleton
                variant="rect"
                width={127}
                height={21}
                style={{ marginLeft: '1.5rem' }}
                className={classes.rounded}
              />
            </Grid>
            <Grid
              container
              direction="column"
              item
              style={{ marginTop: '1.5rem' }}
            >
              <Grid item xs={10}>
                <Skeleton
                  variant="rect"
                  height={28}
                  className={classes.rounded}
                />
              </Grid>
              <Skeleton
                variant="rect"
                height={12}
                style={{ marginTop: '2.25rem' }}
                className={classes.rounded}
              />
            </Grid>
          </Box>
        ) : (
          <Grid container>
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                style={{ marginLeft: '.75rem', marginBottom: '.75rem' }}
              >
                <Skeleton variant="text" width={32} height={35} />
              </Grid>
            </Grid>
            <Grid container item xs={9}>
              <Grid item xs={4}>
                <Skeleton
                  variant="rect"
                  height={35}
                  className={classes.rounded}
                />
              </Grid>
              <Grid item xs={11} style={{ marginTop: '1.25rem' }}>
                <Skeleton
                  variant="rect"
                  height={14}
                  className={classes.rounded}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '2.5rem' }}>
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                  >
                    <Skeleton variant="rect" height={24} width={24} />
                    <Grid item xs={7}>
                      <Skeleton
                        variant="rect"
                        height={14}
                        className={classes.rounded}
                        style={{ marginLeft: '.75rem' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                  >
                    <Skeleton variant="rect" height={24} width={24} />
                    <Grid item xs={6}>
                      <Skeleton
                        variant="rect"
                        height={14}
                        className={classes.rounded}
                        style={{ marginLeft: '.75rem' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* coach avatar and name section */}
            <Grid item xs={3} style={{ padding: '.5rem' }}>
              <Grid container item direction="row" alignItems="center">
                <Grid item xs={12}>
                  <Grid
                    item
                    container
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Skeleton variant="circle" width={100} height={100} />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton
                      variant="rect"
                      height={28}
                      className={classes.rounded}
                      style={{ marginTop: '1.5rem' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <Divider style={{ marginTop: '2.5rem' }} />
      <Grid container direction="column" style={{ marginTop: '2rem' }}>
        <Grid item xs={12} sm={6} lg={4}>
          <Grid container direction="column">
            <Skeleton
              variant="rect"
              width={isMobile ? 81 : null}
              height={14}
              className={classes.rounded}
            />

            <Skeleton
              variant="rect"
              height={12}
              className={classes.rounded}
              style={{ marginTop: '1.25rem' }}
            />
          </Grid>
          <Skeleton
            height={80}
            variant="rect"
            style={{ marginTop: '2rem', borderRadius: '.5rem' }}
          />
          {/* Divider below large block of text */}
          <Divider style={{ marginTop: '2rem' }} />
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: '1.25rem' }}
          >
            <Grid item xs={7}>
              <Skeleton
                variant="rect"
                height={28}
                className={classes.rounded}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={3}>
              <Skeleton
                variant="rect"
                height={28}
                className={classes.rounded}
              />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: '2.5rem' }} />
          <Grid
            container
            direction="column"
            style={{
              marginTop: '2rem',
            }}
          >
            <Grid item xs={8}>
              <Skeleton
                variant="rect"
                height={21}
                className={classes.rounded}
              />
            </Grid>

            {/* Double section fillers */}

            <Grid container direction="column" style={{ marginTop: '2em' }}>
              {/* first block of two */}
              <Grid item xs={4}>
                <Skeleton
                  variant="rect"
                  height={12}
                  className={classes.rounded}
                />
              </Grid>
              <Grid item xs={5} style={{ marginTop: '1rem' }}>
                <Skeleton
                  variant="rect"
                  height={10}
                  className={classes.rounded}
                />
              </Grid>
              {/* second block of two */}
              <Grid item xs={3} style={{ marginTop: '2rem' }}>
                <Skeleton
                  variant="rect"
                  height={10}
                  className={classes.rounded}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '1rem' }}>
                <Skeleton
                  variant="rect"
                  height={14}
                  className={classes.rounded}
                />
              </Grid>
            </Grid>
            <Grid container direction="column" style={{ margin: '2em auto' }}>
              <Grid item xs={4}>
                <Skeleton
                  variant="rect"
                  height={14}
                  className={classes.rounded}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ReviewBookingSkeleton
