import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
  TextField,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { DateTime } from 'luxon'
import CustomLuxonUtils from 'lib/CustomLuxonUtils'
import { LocalizationProvider } from '@mui/x-date-pickers'
import PgaMobileTimePicker from 'components/pga-time-picker/pga-mobile-time-picker'
import PgaMobileDatePicker from 'components/pga-time-picker/pga-mobile-date-picker'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Disclaimer from 'components/disclaimer'

const CREATE_BLOCK_TIME = gql`
  mutation CreateCoachAvailabilityBlockTime(
    $input: CreateCoachAvailabilityBlockTimeInput!
  ) {
    createCoachAvailabilityBlockTime(input: $input) {
      success
      message
    }
  }
`

const BlockTimeForm = ({ onCancel, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [allDay, setAllDay] = useState(false)
  const [startDate, setStartDate] = useState(DateTime.local())
  const [startTime, setStartTime] = useState(DateTime.local().startOf('hour'))
  const [endDate, setEndDate] = useState(DateTime.local())
  const [endTime, setEndTime] = useState(
    DateTime.local()
      .startOf('hour')
      .plus({ hours: 1 }),
  )
  const [blockNote, setBlockNote] = useState('')

  const toggleAllDay = event => {
    if (event.target.checked) {
      setStartTime(DateTime.local().startOf('day'))
      setEndTime(DateTime.local().endOf('day'))
    }
    setAllDay(event.target.checked)
  }

  const setStartAndEndDate = value => {
    setStartDate(value)
    setEndDate(value)
  }

  const setStartAndEndTime = value => {
    setStartTime(value)
    setEndTime(value)
  }

  const changeEndTime = value => {
    setEndTime(value)
  }

  const [createBlockTime] = useMutation(CREATE_BLOCK_TIME, {
    onCompleted: (data, error) => {
      if (data.createCoachAvailabilityBlockTime.success) {
        enqueueSnackbar(data.createCoachAvailabilityBlockTime.message, {
          variant: 'success',
        })
        onSuccess && onSuccess()
      } else {
        enqueueSnackbar(data.createCoachAvailabilityBlockTime.message, {
          variant: 'error',
        })
      }
    },
  })

  const onSubmit = () => {
    const startDateTime = new CustomLuxonUtils().mergeDateAndTime(
      startDate,
      startTime,
    )

    const endDateTime = new CustomLuxonUtils().mergeDateAndTime(
      endDate,
      endTime,
    )

    if (startDateTime < endDateTime) {
      createBlockTime({
        variables: {
          input: {
            startTime: startDateTime,
            endTime: endDateTime,
            allDay: allDay,
            blockNote: blockNote,
          },
        },
      })
    } else {
      enqueueSnackbar('Blocked start date/time must be before end date/time', {
        variant: 'error',
      })
    }
  }

  const AllDayForm = () => (
    <>
      <Box display="flex" mb={4}>
        <Box mr={4}>
          <PgaMobileDatePicker
            label="Start date"
            id="start-date"
            value={startDate}
            format="MMM dd, yyyy"
            onAccept={val => setStartAndEndDate(val)}
          />
        </Box>
        <Box>
          <PgaMobileDatePicker
            label="End date"
            id="end-date"
            value={endDate}
            format="MMM dd, yyyy"
            onAccept={val => setEndDate(val)}
          />
        </Box>
      </Box>
    </>
  )

  const NotAllDayForm = () => (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box m={1} ml={0}>
            <PgaMobileDatePicker
              label="Start date"
              id="start-date"
              value={startDate}
              format="MMM dd, yyyy"
              onAccept={val => setStartAndEndDate(val)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={1} mr={0}>
            <PgaMobileTimePicker
              id="start-time"
              label="Start time"
              value={startTime}
              onAccept={val => setStartAndEndTime(val)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={2} style={{ marginTop: '2%', marginBottom: '2%' }}>
        <Grid item xs={6}>
          <Box m={1} ml={0}>
            <PgaMobileDatePicker
              label="End date"
              id="end-date"
              value={endDate}
              format="MMM dd, yyyy"
              onAccept={val => setEndDate(val)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box m={1} mr={0}>
            <PgaMobileTimePicker
              id="end-time"
              label="End time"
              value={endTime}
              onAccept={val => changeEndTime(val)}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )

  return (
    <LocalizationProvider
      dateAdapter={CustomLuxonAdapter}
      dateFormats={{ normalDateWithWeekday: 'LLL d, yyyy' }} // Jul 1, 2021
    >
      <>
        <Box mb={4}>
          <Typography>
            Select a range of dates and times you would like to block on your
            calendar.
          </Typography>
          <Box mt={2} style={{ maxWidth: '600px' }}>
            <Disclaimer
              icon={
                <InfoOutlinedIcon
                  fontSize="large"
                  style={{ color: '#2196F3' }}
                />
              }
              description="Note: Blocked times are not daily recurring"
              size="small"
            />
          </Box>
        </Box>

        {allDay && <AllDayForm />}
        {!allDay && <NotAllDayForm />}

        <Box display="flex" mb={4}>
          <FormGroup>
            <FormControlLabel
              label="All Day"
              control={
                <Switch
                  checked={allDay}
                  onChange={toggleAllDay}
                  name="allDay"
                />
              }
            />
          </FormGroup>
        </Box>
        <Box display="flex" mb={4}>
          <TextField
            id="outlined-basic"
            name="blockNote"
            label="Optional: Add a note"
            variant="outlined"
            fullWidth
            onChange={e => setBlockNote(e.target.value)}
            inputProps={{ maxLength: 25 }}
          />
        </Box>

        <Grid container style={{ paddingBottom: '2%' }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              onClick={onSubmit}
            >
              Create Block
            </Button>
          </Grid>
        </Grid>
      </>
    </LocalizationProvider>
  )
}

export default BlockTimeForm
