import { gql, useMutation } from '@apollo/client'

const CANCEL_PAYMENT_REQUEST = gql`
  mutation CancelPaymentRequest($enrollmentId: ID!) {
    cancelPaymentRequest(enrollmentId: $enrollmentId) {
      message
      success
    }
  }
`

const SEND_PAYMENT_REQUEST = gql`
  mutation SendPaymentRequest($enrollmentId: ID!) {
    sendPaymentRequest(enrollmentId: $enrollmentId) {
      message
      success
      paymentRequestToken
    }
  }
`
export function useCancelPaymentRequest() {
  const [cancelPaymentRequest, result] = useMutation(CANCEL_PAYMENT_REQUEST)
  return [cancelPaymentRequest, result]
}

export function useSendPaymentRequest() {
  const [sendPaymentRequest, result] = useMutation(SEND_PAYMENT_REQUEST)
  return [sendPaymentRequest, result]
}
