import React from 'react'
import AppCard from 'components/app-card'
import logo from 'images/pga-coach-logo.png'

export default function PgaCoach() {
  return (
    <AppCard
      title="Create a coach profile, manage your calendar, message your students, and much more"
      to="/pga-coach"
      buttonText="launch"
      testid="pga-coach-tile"
      logo={{
        src: logo,
        alt: 'PGA logo',
      }}
    />
  )
}
