import React from 'react'
import { getAssumedUser, clearAssumedUser } from './impersonation'
import useAuth from './useAuth'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const ImpersonationOverlay = () => {
  const assumedUser = getAssumedUser()
  const { user } = useAuth()
  if (!assumedUser) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 1300,
        width: '100%',
        backgroundColor: 'rgba(255, 91, 51, 0.8)',
        textAlign: 'center',
        padding: '8px 0',
      }}
    >
      <Box display="inline" mr={2}>
        <Typography display="inline" variant="subtitle1">
          You are currently impersonating {user?.name}
        </Typography>
      </Box>
      <Button onClick={clearAssumedUser} variant="contained" size="small">
        I'm done
      </Button>
    </div>
  )
}

export default ImpersonationOverlay
