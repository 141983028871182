import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'

import { useStyles } from './styles'

const ActionTile = ({
  icon,
  headlineText,
  captionText,
  chipText,
  callToAction,
  className,
}) => {
  const classes = useStyles()
  return (
    <Box className={`${classes.actionTileWrapper} ${className}`}>
      {chipText ? (
        <Chip className={classes.chip} color="secondary" label={chipText} />
      ) : null}
      <CircularHighlightedIcon icon={icon} />
      <Typography variant="h6">{headlineText}</Typography>
      <Typography variant="body1" className={classes.captionText}>
        {captionText}
      </Typography>
      {callToAction}
    </Box>
  )
}

export default ActionTile
