import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import { assumeUser } from './impersonation'
import useAuth from './useAuth'

const useStyles = makeStyles(() => ({
  dialogActions: {
    justifyContent: 'flex-end',
  },
}))

const ImpersonateUserDialog = ({ trigger, user, hideSensitiveInformation }) => {
  const classes = useStyles()
  const { user: currentUser } = useAuth()
  const { userType, id, name } = user

  const isSameUser =
    `${currentUser.type}/${currentUser.id}` === `${userType}/${id}`

  // disable the ability to impersonate yourself, or to impersonate again while impersonating someone else
  if (isSameUser || currentUser.isAssumed) return null

  return (
    <Dialog trigger={trigger} maxWidth="xs">
      {({ closeDialog }) => (
        <>
          <DialogTitle>Impersonate User</DialogTitle>
          <DialogContent>
            <Typography variant="body1" color="textSecondary">
              Are you sure you want to impersonate {name}? Any updates you make
              will also be reflected in their account.
            </Typography>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <Button color="primary" onClick={closeDialog}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                assumeUser({ userType, id, hideSensitiveInformation })
              }
            >
              Impersonate User
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ImpersonateUserDialog
