import React, { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'

const DashboardReturnLink = () => {
  const location = useLocation()
  const [returnLink, setReturnLink] = React.useState(
    new URLSearchParams(location.search).get('return_to') || '/',
  )
  useEffect(() => {
    setReturnLink(new URLSearchParams(location.search).get('return_to') || '/')
  }, [location.search])

  return (
    <Link
      underline="none"
      style={{ display: 'flex', alignItems: 'center' }}
      color="textPrimary"
      component={RouterLink}
      to={returnLink}
    >
      <Box mr={2}>
        <ArrowBackOutlinedIcon color="inherit" />
      </Box>
      <Typography variant="subtitle1" component="span">
        {returnLink !== '/' ? 'Back' : 'Return to Home'}
      </Typography>
    </Link>
  )
}

export default DashboardReturnLink
