import React, { useState } from 'react'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'
import { gql, useQuery } from '@apollo/client'
import CustomLuxonUtils, { buildDateTime } from 'lib/CustomLuxonUtils'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import SessionInput from './session-input'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  base: {
    background: 'rgb(255, 245, 229)',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ul: {
    marginTop: '4px',
    marginBottom: '0',
  },
  cardDivider: {
    margin: '0 8px',
    background: 'rgba(0, 35, 75, 0.24)',
  },
  logo: {
    color: '#ff9800',
    width: '50px',
    minWidth: '50px',
    alignSelf: 'center',
  },
  body: {
    padding: '8px 4px',
    justifyContent: 'start',
  },
  dateTimeRow: {
    marginBottom: '30px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'end',
    },
  },
  dateTimeRowError: {
    marginBottom: '30px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'baseline',
    },
  },
  dateInput: {
    order: 1,
  },
  startTimeInput: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      order: 3,
    },
  },
  endTimeInput: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      order: 4,
    },
  },
  deleteSessionBtn: {
    order: 4,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  divider: {
    margin: '30px 0px',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '0',
  },
  additionalSessionInfo: {
    textAlign: 'center',
    paddingTop: '5px',
  },
  addSessionBtn: {
    marginTop: '15px',
  },
  multiSessionInfoText: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  infoHeader: {
    paddingBottom: '5px',
  },
}))

const LESSON_VALIDATION_QUERY = gql`
  query lessonValidation($lessonId: ID!, $timespans: [DateTimeRangeInput!]!) {
    lessonValidation(lessonId: $lessonId, timespans: $timespans) {
      isAvailable
      warnings
    }
  }
`

const MultiSessionInput = ({ name, keyName = 'id', timezone }) => {
  const classes = useStyles()
  const { control, errors, getValues } = useFormContext()
  const { append, fields, remove } = useFieldArray({ control, name, keyName })

  const eventSessions = useWatch({ control, name })

  const [warnings, setWarnings] = useState([])

  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  const addSession = () => {
    const lastSession = eventSessions.slice(-1)[0]
    const nextDayStart = lastSession.startDate.plus({ days: 1 })
    const nextStartTime = buildDateTime(nextDayStart, lastSession.startTime)
    const nextEndTime = buildDateTime(nextDayStart, lastSession.endTime)

    const session = {
      startDate: nextDayStart,
      startTime: nextStartTime,
      endTime: nextEndTime,
    }

    append(session)
  }

  const { refetch } = useQuery(LESSON_VALIDATION_QUERY, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const handleVerifySessions = async () => {
    const timespans = fields.map((_, index) => {
      const startDate = getValues(`${name}[${index}].startDate`)
      const startTime = getValues(`${name}[${index}].startTime`)
      const endTime = getValues(`${name}[${index}].endTime`)

      const startDateTime = buildDateTime(
        startDate,
        startTime,
        timezone,
      ).toUTC()
      const endDateTime = buildDateTime(startDate, endTime, timezone).toUTC()

      return {
        from: startDateTime,
        to: endDateTime,
      }
    })

    const { data } = await refetch({
      lessonId: fields[0].id,
      timespans: timespans,
    })

    const warningMessages = data?.lessonValidation.warnings.filter(
      message => message !== 'start_date_time must be before end_date_time',
    )
    setWarnings(warningMessages)
  }

  return (
    <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
      <Box mb={2} mt={2}>
        <Box
          style={
            errors[name] ? { border: 'red solid 1px', padding: '4px 6px' } : {}
          }
        >
          {!!errors[name] && (
            <Typography style={{ marginBottom: '1rem', color: 'red' }}>
              {errors[name]?.message}
            </Typography>
          )}
          {fields.map((session, index) => (
            <SessionInput
              session={session}
              timezone={timezone}
              handleVerifySessions={handleVerifySessions}
              onDelete={remove}
              key={session.id}
              index={index}
              name={name}
              fields={fields}
            />
          ))}
        </Box>
        {warnings?.length > 0 && (
          <Container style={{ padding: 0 }}>
            <Box
              mt={3}
              display="flex"
              flexDirection="row"
              className={classes.base}
              alignItems="center"
            >
              {!isMobile && (
                <>
                  <ReportProblemOutlinedIcon
                    fontSize="medium"
                    className={classes.logo}
                  />
                  <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.cardDivider}
                  />
                </>
              )}
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                alignItems="start"
              >
                <Typography className={classes.body}>
                  This time may conflict with the following event(s)
                  <ul className={classes.ul}>
                    {warnings.map((warningMessage, index) => (
                      <li key={index}>
                        <Typography gutterBottom>
                          <strong>{warningMessage}</strong>
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Typography>
              </Box>
            </Box>
          </Container>
        )}
        <hr className={classes.divider} />
        <Box className={classes.additionalSessionInfo}>
          {eventSessions.length <= 1 && (
            <>
              <Typography
                variant="h6"
                color="primary"
                className={classes.infoHeader}
              >
                Multiple Sessions
              </Typography>
              <Typography
                variant="body1"
                className={classes.multiSessionInfoText}
              >
                Adding a session will create a single offering with multiple
                sessions.
              </Typography>
              <Typography
                variant="body1"
                className={classes.multiSessionInfoText}
              >
                Participants enrolling in this offering will be registered for{' '}
                <strong>all sessions</strong>.
              </Typography>
            </>
          )}
          <Button
            aria-controls="add-session-time"
            aria-haspopup="true"
            color="primary"
            variant="outlined"
            size="large"
            onClick={addSession}
            alignItems="center"
            className={classes.addSessionBtn}
          >
            {'+ Add Session'}
          </Button>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default MultiSessionInput
