import { makeStyles, Typography } from '@material-ui/core/index'
import PropTypes from 'prop-types'
import useHover from 'lib/hooks/use-hover'
import { useMemo } from 'react'
import { IconButtonCtaArrow, IconButtonCtaArrowExternal } from './icons'

const useStyles = makeStyles(() => ({
  root: {},
  arrow: {
    transition: '0.1s ease-in-out',
    transform: ({ hover }) => (hover ? 'translateX(8px)' : ''),
  },
}))

export function CtaText(props) {
  const { as: Tag, children, className, hovered, external, ...rest } = props
  const [hoverRef, isHovered] = useHover()
  const styles = useStyles({ hover: isHovered || hovered })
  const [firstHalf, lastWord] = useMemo(() => {
    const splitString = children.split(' ')
    const lastWordString = splitString.pop()

    return [splitString.join(' '), lastWordString]
  }, [children])

  if (!children) return <></>
  if (typeof children !== 'string') {
    return (
      <Tag
        ref={hoverRef}
        className={`${styles.root} ${className}`}
        {...rest}
        data-testid="cta-text-new"
      >
        {children}
      </Tag>
    )
  }

  return (
    <Tag
      ref={hoverRef}
      className={`${styles.root} ${className}`}
      {...rest}
      data-testid="cta-text-new"
    >
      <>
        {firstHalf && `${firstHalf} `}
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          {lastWord}
          <CtaArrow external={external} hovered={isHovered || hovered} />
        </span>
      </>
    </Tag>
  )
}

CtaText.propTypes = {
  as: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'a',
    'span',
    Typography,
  ]),
  className: PropTypes.string,
  children: PropTypes.string,
  hovered: PropTypes.bool,
  variant: PropTypes.string,
  external: PropTypes.bool,
}

CtaText.defaultProps = {
  as: Typography,
  variant: 'body1',
  className: '',
  external: false,
}

export default function CtaArrow(props) {
  const { className, hovered, external, ...rest } = props

  const styles = useStyles({ hover: hovered })

  const IconComponent = external
    ? IconButtonCtaArrowExternal
    : IconButtonCtaArrow

  return (
    <IconComponent
      className={`${styles.arrow} ${className}`}
      style={{ marginLeft: '.5rem' }}
      {...rest}
      data-testid="cta-arrow-new"
    />
  )
}

CtaArrow.propTypes = {
  className: PropTypes.string,
  hovered: PropTypes.bool,
  external: PropTypes.bool,
}

CtaArrow.defaultProps = {
  className: '',
}
