import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import WizardFormContext from './context'
import ProgressControls from './progress-controls'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash'
import { useSnackbarError } from 'lib/snackbar'
import FormPersistence from './FormPersistence'
import { gtmEvent } from 'lib/gtm'

const WizardForm = ({
  formProps,
  steps,
  onCancel,
  onSubmit,
  persistKey,
  formName,
  onAdditionalAction,
  extraData = {},
}) => {
  const form = useForm(formProps)
  const formValues = form.getValues()
  const _steps = typeof steps === 'function' ? steps(formValues) : steps
  const [activeStepNumber, setActiveStepNumber] = useState(0)
  const activeStep = _steps[activeStepNumber]

  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const reset = () => {
    form.reset()
    setActiveStepNumber(0)
    onCancel && onCancel()
  }

  const back = () => {
    document.activeElement.blur()
    activeStepNumber > 0 && setActiveStepNumber(activeStepNumber - 1)
  }

  const advance = async () => {
    const isValid = await form.trigger(activeStep.fieldsToValidate)
    const formValues = form.getValues()

    document.activeElement.blur()

    if (!isEmpty(form.errors)) {
      Object.values(form.errors).forEach(error => {
        snackbarError(error.message)
      })
    }

    if (isValid) {
      gtmEvent({
        event: 'formSubmit',
        formCategory: formName,
        formAction: `completed-${activeStep.stepName}`,
      })
      if (activeStep.additionalAction) {
        onAdditionalAction(formValues)
      }
      if (activeStepNumber === _steps.length - 1) {
        form.handleSubmit(onSubmit)()
      } else {
        setActiveStepNumber(activeStepNumber + 1)
        if (persistKey) {
          const persistValue = {
            activeStep: activeStepNumber + 1,
            values: form.getValues(),
          }
          localStorage.setItem(persistKey, JSON.stringify(persistValue))
        }
      }
    }
  }

  const wizardFormState = {
    steps: _steps,
    activeStep,
    activeStepNumber,
    setActiveStepNumber,
    reset,
    back,
    advance,
    formValues,
    extraData,
  }

  return (
    <FormProvider {...form}>
      <WizardFormContext.Provider value={wizardFormState}>
        <ProgressControls>
          <Container>
            {_steps.map(({ component }, idx) => (
              <Box
                key={idx}
                display={activeStepNumber === idx ? 'block' : 'none'}
              >
                {component}
              </Box>
            ))}
          </Container>
          {persistKey ? (
            <FormPersistence
              persistKey={persistKey}
              setFormValues={form.reset}
              setActiveStepNumber={setActiveStepNumber}
            />
          ) : null}
        </ProgressControls>
      </WizardFormContext.Provider>
    </FormProvider>
  )
}

export default WizardForm
