import React from 'react'
import { Grid, TextField, Box } from '@material-ui/core'
import CurrencyField from 'components/form-util/currency'
import { centsToDollars } from 'lib/utils/number'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { get, round } from 'lodash'

const LessonPackCalculation = ({
  packNumber,
  deletePack,
  errors,
  register,
  setValue,
  getValues,
  defaultValues,
}) => {
  const defaultPrice = defaultValues.priceInCents
    ? centsToDollars(defaultValues.priceInCents)
    : ''
  const defaultPricePerLesson = defaultValues.quantity
    ? defaultPrice / defaultValues.quantity
    : ''

  const onChange = e => {
    const { name } = e.target
    const isQuantity = name.indexOf('quantity') > -1
    const isPriceInCents = name.indexOf('priceInCents') > -1
    const isPricePerLesson = name.indexOf('pricePerLesson') > -1

    if (isPricePerLesson || isQuantity) {
      calcPrice()
    }
    if (isPriceInCents) {
      calcPricePerLesson()
    }
  }

  const calcPrice = () => {
    const quantity = getValues(`lessonPacks[${packNumber}].quantity`) || 1
    const pricePerLesson =
      getValues(`lessonPacks[${packNumber}].pricePerLesson`) || 0
    if (pricePerLesson) {
      setValue(
        `lessonPacks[${packNumber}].priceInCents`,
        round(quantity * pricePerLesson, 2),
      )
    }
  }

  const calcPricePerLesson = () => {
    const quantity = getValues(`lessonPacks[${packNumber}].quantity`) || 1
    const priceInCents =
      getValues(`lessonPacks[${packNumber}].priceInCents`) || 0
    let priceInCentsFormatted
    if (typeof priceInCents === 'string') {
      priceInCentsFormatted = priceInCents.replace(',', '')
    } else {
      priceInCentsFormatted = priceInCents
    }
    setValue(
      `lessonPacks[${packNumber}].pricePerLesson`,
      round(priceInCentsFormatted / quantity, 2),
    )
  }

  return (
    <Box mt={2} mb={2}>
      <Grid container spacing={2}>
        <Grid item lg={3}>
          <TextField
            defaultValue={defaultValues.quantity}
            name={`lessonPacks[${packNumber}].quantity`}
            label="Qty"
            data-cy="qty"
            onChange={onChange}
            type="number"
            inputRef={register()}
            error={!!get(errors, ['lessonPacks', packNumber, 'quantity'])}
            helperText={get(errors, [
              'lessonPacks',
              packNumber,
              'quantity',
              'message',
            ])}
          />
        </Grid>
        <Grid container item lg={8} spacing={2}>
          <Grid item lg={6}>
            <CurrencyField
              defaultValue={defaultPrice}
              label="Price"
              variant="outlined"
              name={`lessonPacks[${packNumber}].priceInCents`}
              inputRef={register}
              error={!!get(errors, ['lessonPacks', packNumber, 'priceInCents'])}
              helperText={get(errors, [
                'lessonPacks',
                packNumber,
                'priceInCents',
                'message',
              ])}
              onChange={onChange}
            />
          </Grid>
          <Grid item lg={6}>
            <CurrencyField
              defaultValue={defaultPricePerLesson}
              label="$/lesson"
              variant="outlined"
              name={`lessonPacks[${packNumber}].pricePerLesson`}
              inputRef={register}
              error={
                !!get(errors, ['lessonPacks', packNumber, 'pricePerLesson'])
              }
              helperText={get(errors, [
                'lessonPacks',
                packNumber,
                'pricePerLesson',
                'message',
              ])}
              onChange={onChange}
            />
          </Grid>
        </Grid>
        <Grid item lg={1}>
          {deletePack ? (
            <IconButton aria-label="delete" onClick={deletePack}>
              <DeleteOutlinedIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}

export default LessonPackCalculation
