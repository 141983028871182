import * as React from 'react'
import errorImage from 'images/not-found.png'
import { Box, Link, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(247,247,247,255)',
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    width: '232px',
    marginBottom: '1.5rem',
    objectPosition: '10% 15%',
  },
  button: {
    minWidth: '97px',
  },
  heading: {
    fontSize: '20px',
    fontWight: 500,
    lineHeight: '32px',
    zIndex: 5,
  },
  subheading: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.54)',
    zIndex: 5,
  },
}))

export default function NotFound({
  subtext = "What you were looking for isn't here.",
  returnUrl = '/',
  buttonLabel = 'Go Back',
}) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography variant="h1" className={classes.heading}>
        Oops, something went wrong!
      </Typography>
      <Typography variant="h2" className={classes.subheading}>
        {subtext}
      </Typography>
      <img className={classes.image} src={errorImage} alt={'error'} />
      <Button
        component={Link}
        color="primary"
        variant="contained"
        href={returnUrl}
        className={classes.button}
      >
        {buttonLabel}
      </Button>
    </Box>
  )
}

NotFound.propTypes = {
  subtext: PropTypes.string,
  returnUrl: PropTypes.string,
  buttonText: PropTypes.string,
}
