import { FLAGR_API_ROOT } from 'env'

const fetchActiveFlagKeys = async () => {
  const res = await fetch(`${FLAGR_API_ROOT}/flags?enabled=true`)
  const data = await res.json()
  return new Set(
    data
      .map(flag => flag.key)
      .filter(
        key =>
          key.startsWith('my-pga-com') ||
          key.startsWith('mobile-and-my-pga-com'),
      ),
  )
}

const fetchActiveFlags = async () => {
  const res = await fetch(`${FLAGR_API_ROOT}/flags?enabled=true&preload=true`)
  const data = await res.json()
  return data.filter(
    flag =>
      flag.key.startsWith('my-pga-com') ||
      flag.key.startsWith('mobile-and-my-pga-com'),
  )
}

const evaluateFlags = async (flagKeys, user) => {
  const res = await fetch(`${FLAGR_API_ROOT}/evaluation/batch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      enableDebug: false,
      flagKeys,
      entities: [
        {
          entityID: (user && user.externalId) || '',
          entityType: 'user',
          entityContext:
            user != null
              ? {
                user_id: user.externalId,
              }
              : {},
        },
      ],
    }),
  })
  const data = await res.json()
  const flagEvaluations = data.evaluationResults.reduce((acc, val) => {
    const { flagKey, variantKey } = val
    acc[flagKey] = variantKey
    return acc
  }, {})
  return flagEvaluations
}

export { fetchActiveFlagKeys, fetchActiveFlags, evaluateFlags }
