import { makeStyles, styled } from '@material-ui/core/styles'
import Select from '@mui/material/Select'

export const useStyles = makeStyles(theme => ({
  backButtonWrapper: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    margin: '15px 0',
  },
  titleFont: {
    fontSize: '16px',
    color: theme.palette.text.secondary,
    fontWeight: 900,
  },
  headerFont: {
    fontSize: '30px',
    fontWeight: 900,
  },
  avatarWrapper: {
    marginRight: '15px',
  },
  studentContent: {
    margin: '15px 0',
  },
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: '278px',
  [theme.breakpoints.down('xs')]: {
    width: '240px',
  },
  '& .MuiSelect-select': {
    padding: '10px 14px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
  },
  '& .MuiSelect-icon': {
    color: theme.palette.primary.light,
  },
}))
