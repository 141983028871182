import React from 'react'
import { DateTime } from 'luxon'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import capitalize from 'lodash/capitalize'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import { useAuth } from 'lib/auth'
import { safeFormat } from 'lib/family-cup-utils/event-dates'
import {
  getProductName,
  getTransactionType,
  groupLineItems,
  sumField,
} from './transaction-utils'
import useStyles from './styles'

const monthDayYearFormat = 'MM/dd/yyyy'
const formatCurrency = amountInCents =>
  `${amountInCents < 0 ? '-$' : '$'}${(Math.abs(amountInCents) / 100).toFixed(
    2,
  )}`

const byCreatedAt = (a, b) => {
  return DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 1 : -1
}

const byCreatedAtThenLineType = (a, b) => {
  if (a.createdAt === b.createdAt) {
    return a.lineType > b.lineType ? 1 : -1
  }
  return byCreatedAt(a, b)
}

const lineItemLabel = ({
  createdAt,
  discountCode,
  label,
  lineType,
  refund,
}) => {
  if (refund) {
    return `Refund (${safeFormat(createdAt, monthDayYearFormat)})`
  }
  if (lineType === 'DISCOUNT') {
    return `${label} (${discountCode})`
  }
  return label
}

const Card = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Box className={classes.card} {...props}>
      <Box px={4} style={{ width: '100%' }}>
        {children}
      </Box>
    </Box>
  )
}

const Title = ({ children, variant = 'h6', ...props }) => {
  const classes = useStyles()
  return (
    <Typography
      className={classes[variant]}
      variant={variant}
      color="textPrimary"
      {...props}
    >
      {children}
    </Typography>
  )
}

const Amount = ({ amountInCents }) => (
  <Typography style={{ color: amountInCents < 0 ? 'red' : 'initial' }}>
    {formatCurrency(amountInCents)}
  </Typography>
)

const TransactionLineItemsTable = ({ transaction }) => {
  const { lineItems, subtotal, title } = transaction
  const classes = useStyles()

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell
              className={classes.noBorderBottom}
              style={{ paddingBottom: 0 }}
              width="90%"
            >
              <Title>{title}</Title>
            </TableCell>
            <TableCell
              className={classes.noBorderBottom}
              style={{ paddingBottom: 0 }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.sort(byCreatedAtThenLineType).map((lineItem, idx) => {
            const { amountInCents, refund } = lineItem
            const displayLabel = lineItemLabel(lineItem)
            return (
              <TableRow
                key={idx}
                className={classes.tableRow}
                style={{
                  borderBottom: '1px solid',
                  borderBottomColor: 'gray',
                }}
              >
                <TableCell>{displayLabel}</TableCell>
                <TableCell align="right">
                  <Amount amountInCents={amountInCents} refund={refund} />
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow className={classes.tableRow}>
            <TableCell align="right">
              <Title>Subtotal</Title>
            </TableCell>
            <TableCell align="right">
              <Title>{formatCurrency(subtotal)}</Title>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

const OrderTotal = ({ total, lineItems }) => {
  const classes = useStyles()

  const coachingFees = lineItems.find(li => li.group === 'COACHING_FEES')
  const offlineCoachingFee =
    coachingFees?.lineItems.find(li => li.label === 'Coaching Fee (Offline)')
      ?.amountInCents * -1 || 0

  return (
    <Box py={2} className={classes.orderTotal}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell align="right">
              <Title>Order Total</Title>
            </TableCell>
            <TableCell align="right" className={classes.noBorderBottom}>
              <Title>{formatCurrency(total)}</Title>
            </TableCell>
          </TableRow>

          <TableRow className={classes.tableRow}>
            <TableCell align="right">
              <Title>Offline Fees</Title>
            </TableCell>
            <TableCell align="right" className={classes.noBorderBottom}>
              <Title>{formatCurrency(offlineCoachingFee)}</Title>
            </TableCell>
          </TableRow>

          <TableRow className={classes.tableRow}>
            <TableCell
              width="90%"
              align="right"
              className={classes.noBorderBottom}
            >
              <Title variant="h5">Consumer Total</Title>
            </TableCell>
            <TableCell align="right" className={classes.noBorderBottom}>
              <Title variant="h5">
                {formatCurrency(total + offlineCoachingFee)}
              </Title>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Box>
  )
}

const TransactionRecord = ({ transaction }) => {
  const { amountInCents, createdAt, refund, refundReason } = transaction
  const date = safeFormat(createdAt, monthDayYearFormat)
  const type = refund ? 'Refund' : 'Original Purchase'

  return (
    <Box mb={1} p={2} bgcolor="grey.100">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="top"
      >
        <Box>
          <Title style={{ lineHeight: '22px' }}>{date}</Title>
        </Box>
        <Box px={2} flexGrow={1}>
          <Amount amountInCents={amountInCents} />
          {refund && (
            <>
              <Typography>{refundReason}</Typography>
              {/*
                TODO: Add Refunded By to Transaction graphql type
                <Typography>Approved By: {refundedById}</Typography>
              */}
            </>
          )}
        </Box>
        <Box>{type}</Box>
      </Box>
    </Box>
  )
}

const TransactionSummary = ({ registrationPayment, paymentSourceType }) => {
  const { isCoach } = useAuth()

  const {
    payor,
    transactions,
    products,
    registrationPaymentCharge,
    ...payment
  } = registrationPayment

  const paymentSourceTypes = paymentSourceType.enumValues

  const payorName = payor.name || `${payor.firstName} ${payor.lastName}`
  const groupedLineItems = groupLineItems(transactions)

  const orderTotal = groupedLineItems.reduce(sumField('subtotal'), 0)

  const lastUpdated = safeFormat(payment.updatedAt, monthDayYearFormat)

  const initialTransaction = [...transactions].sort(byCreatedAt)[0]

  const product = getProductName(products[0])

  const transactionType = getTransactionType(products[0])

  const paymentMethod = (() => {
    if (payment.cardBrand && payment.last4) {
      return `${payment.cardBrand.toUpperCase()} ending in ${payment.last4}`
    }
    const source = paymentSourceTypes.find(
      ps => ps.name === initialTransaction.sourceType,
    )?.description
    return `${source} (collected offline)`
  })()

  return (
    <Box>
      <Card>
        <Box mb={3}>
          <Title variant="h5">Transaction Summary</Title>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Title>Transaction Number</Title>
            <Typography gutterBottom>{payment.id}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Title>Billing Information</Title>
            <Typography>{payorName}</Typography>
            <Typography gutterBottom>{payor.email}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Title>Date of Purchase</Title>
            <Typography>
              {safeFormat(payment.createdAt, monthDayYearFormat)}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Title>Payment Method</Title>
            <Typography>{paymentMethod}</Typography>
            {registrationPaymentCharge && (
              <>
                <Box display="flex" alignItems="center">
                  <Typography>
                    {`${capitalize(
                      registrationPaymentCharge.chargeType,
                    )} Number:`}{' '}
                    &nbsp;
                  </Typography>
                  <Typography>
                    {registrationPaymentCharge.chargeNumber}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography>Check Number: &nbsp;</Typography>
                  <Typography>
                    {registrationPaymentCharge.checkNumber}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Title>Product</Title>
            <Typography>{product}</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Title>Transaction Type</Title>
            <Typography>{transactionType}</Typography>
          </Grid>
        </Grid>
      </Card>
      {isCoach ? (
        <Card>
          <Box my={3}>
            <Typography color="textSecondary">
              Last Updated: {lastUpdated}
            </Typography>
          </Box>
          {groupedLineItems.map((item, idx) => (
            <React.Fragment key={idx}>
              <TransactionLineItemsTable transaction={item} />
            </React.Fragment>
          ))}
          <OrderTotal total={orderTotal} lineItems={groupedLineItems} />
        </Card>
      ) : null}
      <Card>
        <Box mb={3} display="flex" justifyContent="space-between">
          <Title variant="h5">Transaction Record</Title>
          {initialTransaction?.stripeReceiptUrl && (
            <Link
              variant="subtitle2"
              href={initialTransaction.stripeReceiptUrl}
              target="_blank"
              rel="noopener"
            >
              <Box display="flex">
                View Stripe Receipt
                <LaunchOutlinedIcon fontSize="small" />
              </Box>
            </Link>
          )}
        </Box>
        {Array.from(transactions)
          .sort(byCreatedAt)
          .map((transaction, idx) => (
            <TransactionRecord key={idx} transaction={transaction} />
          ))}
      </Card>
    </Box>
  )
}

export default TransactionSummary
