import { PGA_COM_HOST } from 'env'
import React from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import Redirect from 'components/redirect'
import EventRegistrationPage from 'pages/things-to-do/events/register'
import EventMessages from 'pages/things-to-do/events/messages'

const RootPathRedirect = () => <Redirect to={`${PGA_COM_HOST}/things-to-do`} />

const EventDetailRedirect = () => {
  const { slug } = useParams()
  return <Redirect to={`${PGA_COM_HOST}/things-to-do/events/${slug}`} />
}

const ThingsToDoRoutes = () => {
  const { path } = useRouteMatch()

  /*
  This is used as work-around related to Intercom Product Tours
  More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
  */
  const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Golf Events</title>
        <meta name="description" content="PGA Golf Events" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Switch>
          <Route exact path={path} component={RootPathRedirect} />
          <Route exact path={`${path}/events`} component={RootPathRedirect} />
          <Route
            exact
            path={`${path}/events/:slug`}
            component={EventDetailRedirect}
          />
          <Route
            exact
            path={`${path}/events/:slug/register`}
            component={EventRegistrationPage}
          />
          <Route
            exact
            path={`${path}/events/:slug/messages`}
            component={EventMessages}
          />
          <Route path={`${path}/*`} render={() => <div>Page Not Found</div>} />
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default ThingsToDoRoutes
