import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Step,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  Container,
} from '@material-ui/core'
import CheckStepConnector from './check-step-connector'
import CheckStepIcon from './check-step-icon'
import StepLabel from './step-label'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import useStepper from 'components/signup-util/use-stepper'
import StyledStepper from 'components/signup-util/styled-stepper'

const useStyles = makeStyles(theme => ({
  stepHeading: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    fontSize: 30,
    fontWeight: 'bold',
  },
}))

function StepControls({
  classes,
  onGoBack,
  onNextStep,
  activeStep,
  stepCount,
  nextDisabled,
}) {
  return (
    <Grid container justify="space-between" className={classes.buttonContainer}>
      {activeStep !== 0 ? (
        <Button onClick={onGoBack} size="large" color="primary">
          <ChevronLeftIcon /> Go Back
        </Button>
      ) : (
        <div />
      )}
      {activeStep !== stepCount - 1 && (
        <Button
          onClick={onNextStep}
          color="primary"
          variant="contained"
          disabled={nextDisabled}
          size="large"
        >
          Continue
        </Button>
      )}
    </Grid>
  )
}

/**
 * Form for user login
 * @param {object} props Component props
 * @param {string[]} props.steps Steps of the process
 * @param {function} props.getStepContent Function taking in step and step content props
 * @param {?object} props.stepContentProps Properties passed in to the getStepContent functino
 */
function SignupStepper({
  steps,
  getStepContent,
  stepContentProps,
  canStepContinue,
}) {
  const classes = useStyles()
  const [activeStep, stepHandlers] = useStepper({ stepCount: steps.length })

  const lastStep = activeStep === steps.length - 1

  const nextDisabled = useCallback(
    step => {
      if (canStepContinue) {
        return !canStepContinue(step)
      }
      return false
    },
    [canStepContinue],
  )

  const contentWidth = steps[activeStep].width || 'sm'
  return (
    <div>
      <Container maxWidth="sm">
        <StyledStepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CheckStepConnector />}
        >
          {steps.map(step => {
            return (
              <Step key={step.label}>
                <StepLabel StepIconComponent={CheckStepIcon}>
                  {step.label}
                </StepLabel>
              </Step>
            )
          })}
        </StyledStepper>
      </Container>
      <Container maxWidth={contentWidth}>
        <Paper>
          <Box p={7} mb={2}>
            <Typography
              variant="h5"
              component="h2"
              className={classes.stepHeading}
            >
              {steps[activeStep].heading}
            </Typography>
            {getStepContent(activeStep, stepContentProps)}

            {!lastStep && (
              <Box pt={8}>
                <StepControls
                  {...{
                    onGoBack: stepHandlers.previousStep,
                    onNextStep: stepHandlers.nextStep,
                    stepCount: steps.length,
                    nextDisabled: nextDisabled(activeStep),
                    classes,
                    activeStep,
                  }}
                />
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </div>
  )
}

export default SignupStepper
