import React from 'react'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import EmptyStudents from 'components/empty-page-state'

const EmptyListView = ({
  setOpen,
  title,
  caption,
  buttonText,
  removeButton = false,
  hideButtonMobile,
}) => (
  <EmptyStudents
    actionFn={() => setOpen(true)}
    IconComp={() => <PermContactCalendarOutlinedIcon fontSize="large" />}
    title="Students"
    caption="Your Students will appear here."
    buttonText="Create Student"
    hideButtonMobile
    removeButton={removeButton}
  />
)

export default EmptyListView
