import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import themes, { extendTheme } from 'themes'
import LinearProgress from '@material-ui/core/LinearProgress'
import CoachAppbar from 'components/coach-app-bar'
import Error from 'components/error'
import { roles, withProtectedRoute } from 'lib/auth'
import Dialog from 'components/onboarding-dialog'
import { CreateCoachProfileFormWizardWrapper } from './index'

const GET_MEMBER_PROFILE = gql`
  query MemberProifleWithFacilities {
    GetMemberProfile {
      message
      success
      memberProfile {
        id
        type
        firstName
        lastName
        displayName
        photo
        phoneNumber
        publicPhone
        primaryMobile
        publicMobile
        publicEmail
        social {
          instagram
          facebook
          twitter
          youtube
        }
        primaryFacility {
          facilityId
          operationsTypeLabel
          address {
            address1
            address2
            city
            state
            zip
          }
          name
          phoneNumber
          website
        }
        facilities {
          address {
            address1
            address2
            city
            state
            zip
          }
          name
          phoneNumber
          website
        }
      }
    }
  }
`

/*
This is used as work-around related to Intercom Product Tours
More Details: https://www.intercom.com/help/en/articles/3049411-why-can-t-i-build-a-product-tour#h_2b0ef1dcd9
*/
const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

export const CreateProfile = () => {
  const { data, loading, error } = useQuery(GET_MEMBER_PROFILE)
  const [dialogOpen, setDialogOpen] = useState(true)
  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} displayErrorType="page" />

  const closeDialog = () => setDialogOpen(false)

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA Coach</title>
        <meta name="description" content="PGA Coach | Create Event" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <CoachAppbar position="relative" />
        <CreateCoachProfileFormWizardWrapper
          memberProfile={data?.GetMemberProfile?.memberProfile}
          dialog={
            <Dialog
              open={dialogOpen}
              onClose={closeDialog}
              heading="Coach Profile Complete!"
              text="Coach profile creation complete! Head back to Onboarding to keep going!"
              action
            />
          }
          isOnboarding={true}
        />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(CreateProfile, roles.COACH)
