import React from 'react'
import { useHistory } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from 'components/dialog'

const CANCEL_GROUP_EVENT_REGISTRATION = gql`
  mutation CancelGroupEventRegistration($groupEventId: ID!, $studentId: ID!) {
    cancelGroupEventRegistration(
      groupEventId: $groupEventId
      studentId: $studentId
    ) {
      success
      message
    }
  }
`

const CancelGroupEventRegistration = ({
  trigger,
  studentId,
  eventId,
  acceptsOnlinePayments,
}) => {
  const [cancelGroupEventRegistration, { loading }] = useMutation(
    CANCEL_GROUP_EVENT_REGISTRATION,
  )
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const onSuccess = () => {
    history.push('/pga-coach/bookings/events')
  }

  return (
    <Dialog trigger={trigger} maxWidth="sm">
      {({ closeDialog }) => {
        const onCancelGroupEventRegistrationConfirm = () => {
          cancelGroupEventRegistration({
            variables: {
              groupEventId: eventId,
              studentId: studentId,
            },
          }).then(({ data }) => {
            if (data.cancelGroupEventRegistration.success) {
              enqueueSnackbar('Registration canceled', { variant: 'success' })
              closeDialog()
              onSuccess()
            } else {
              enqueueSnackbar(data.cancelGroupEventRegistration.message, {
                variant: 'error',
              })
            }
          })
        }
        return (
          <>
            <DialogTitle>Cancel Registration</DialogTitle>
            <DialogContent>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to cancel the registration for this
                attendee? This cannot be undone. We'll notify the attendee of
                this cancelation.
              </Typography>
              {acceptsOnlinePayments && (
                <Box mt={2}>
                  <Typography variant="body1" color="textSecondary">
                    If you would like to add a refund, please visit your
                    transaction page, open the associated transaction, and
                    select "Add Refund."
                  </Typography>
                </Box>
              )}
              {loading ? <CircularProgress color="primary" /> : null}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={closeDialog}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onCancelGroupEventRegistrationConfirm}
              >
                Cancel Registration
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Dialog>
  )
}

export default CancelGroupEventRegistration
