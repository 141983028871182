import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import plusJakarta from 'fonts/plusJakarta'
import '@fontsource/source-serif-pro/700.css'
import '@fontsource/nunito-sans/600.css'
import '@fontsource/nunito-sans/700.css'

const breakpoints = createBreakpoints({})

const fonts = {
  headline: 'Source Serif Pro',
  eyebrow: 'Roboto Condensed',
  text: 'Nunito Sans',
  form: 'Roboto',
}

const headlineFont = [fonts.headline, 'serif'].join(',')
const defaultFont = [fonts.text, 'sans-serif'].join(',')
const eyebrowFont = [fonts.eyebrow, 'sans-serif'].join(',')
const formFont = [fonts.form, 'sans-serif'].join(',')

export { headlineFont, defaultFont, eyebrowFont, formFont }

const headlineWeight = 700
const utilityWeight = 700
const bodyWeight = 300

const commonTheme = {
  palette: {
    primary: {
      main: '#0075CA',
      dark: '#004A99',
      light: '#5BA3FE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF7500',
      light: '#ffa641',
      dark: '#c44500',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.64)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      hoverOpacity: 0.04,
      selectedOpacity: 0.08,
    },
    divider: '#E0E0E0',
    blue: {
      main: '#3268A7',
      light: '#EFF5FB',
      light25: '#F5F7FA',
      light100: '#EBEFF5',
      badge: '#2380FF',
      dark: '#001529',
      medium: '#EBEFF5',
    },
  },
  typography: {
    h4: {
      fontSize: '34px',
      letterSpacing: '0.25px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true,
    },
    MuiFormControl: {
      variant: 'outlined',
      fullWidth: true,
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#EEEEEE',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      },
      head: {
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: 14,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
        justifyContent: 'flex-end',
      },
    },
  },
}

// theme overrides for family cup UI
const familyCupTheme = {
  palette: {
    primary: {
      main: '#49C5B1',
      dark: '#38A48E',
      light: '#5BA3FE',
      contrastText: '#FFFFFF',
      selected: 'rgba(0, 117, 202, 0.08)',
    },
    secondary: {
      main: '#FF7500',
      light: '#ffa641',
      dark: '#c44500',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'PlusJakarta Text',
      'PlusJakarta Display',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    button: {
      fontFamily: 'PlusJakarta Text',
      textTransform: 'capitalize',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': plusJakarta,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#49C5B1',
        color: '#FFFFFF',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
        justifyContent: 'flex-end',
      },
    },
  },
}

// Junior League theme
const juniorLeagueTheme = {
  palette: {
    primary: {
      main: '#0075CA',
      dark: '#004A99',
      light: '#5BA3FE',
      contrastText: '#FFFFFF',
      selected: 'rgba(0, 117, 202, 0.08)',
    },
    secondary: {
      main: '#FF7500',
      light: '#ffa641',
      dark: '#c44500',
      contrastText: '#FFFFFF',
    },
    card: {
      border: '#EEE',
      borderHover: '#FF7500',
      divider: '#DBDCDD',
      distance: {
        background: '#EEE',
      },
      status: {
        border: '#49C5B1',
        text: '#2C2D36',
        background: {
          main: '#49C5B1',
          dark: '#2C2D36',
        },
      },
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#0075CA',
        color: '#FFFFFF',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
        justifyContent: 'flex-end',
      },
    },
    MuiInputLabel: {
      outlined: {
        width: 'calc(100% - 28px)',
      },
    },
  },
}

// theme overrides for family cup marketing UI
const familyCupMarketingTheme = {
  palette: {
    primary: {
      main: '#FF7500',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#49C5B1',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#2C2D36',
    },
    background: {
      main: '#FAFAFA',
      light: '#FFFFFF',
      dark: '#49C5B1',
    },
    card: {
      border: '#EEE',
      borderHover: '#FF7500',
      divider: '#DBDCDD',
      distance: {
        background: '#EEE',
      },
      status: {
        border: '#49C5B1',
        text: '#2C2D36',
        background: {
          main: '#49C5B1',
          dark: '#2C2D36',
        },
      },
    },
  },
  shape: {
    borderRadius: '10px',
  },
  typography: {
    fontFamily: [
      'PlusJakarta Text',
      'PlusJakarta Display',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '21px',
    },
    h1: {
      fontFamily: 'PlusJakarta Display',
      fontSize: '54px',
      lineHeight: '70px',
      letterSpacing: 0,
    },
    h2: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '36px',
    },
    h3: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '26px',
    },
    h4: {
      fontFamily: 'PlusJakarta Display',
      fontSize: '20px',
      lineHeight: '34px',
    },
    h5: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '23px',
    },
    h6: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '21px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: 0,
      textAlign: 'left',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '26px',
      letterSpacing: 0,
      textAlign: 'left',
    },
    caption: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': plusJakarta,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '10px',
      },
    },
    MuiDivider: {
      vertical: {
        background: '#FFFFFF',
        opacity: 0.4,
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiTableRow: {
      root: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.03)',
        borderRadius: '10px',
        margin: '4px',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '5px',
        padding: '16px 24px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedSecondary: {
        padding: '19px 50px',
      },
      text: {
        color: '#FF7500',
        padding: '16px 24px',
      },
      sizeLarge: {
        padding: '18px 26px',
        fontSize: '1rem',
        lineHeight: '1.5',
      },
    },
    MuiButtonGroup: {
      root: {
        background: '#ffffff',
        borderRadius: '10px',
      },
      groupedTextPrimary: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        '&:hover': {
          background: '#ffffff',
          opacity: 0.65,
          borderRadius: '10px',
        },
        '&:not(:last-child)': {
          borderColor: 'rgba(0, 0, 0, 0.08)',
        },
        '& span:last-child': {
          flex: 'auto',
          justifyContent: 'flex-end',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: '6px',
      },
    },
  },
}

// theme overrides for junior league marketing UI
const juniorLeagueMarketingTheme = {
  palette: {
    primary: {
      main: '#0075CA',
      dark: '#004A99',
      light: '#5BA3FE',
      contrastText: '#FFFFFF',
      selected: 'rgba(0, 117, 202, 0.08)',
    },
    secondary: {
      main: '#FF7500',
      light: '#ffa641',
      dark: '#c44500',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#2C2D36',
    },
    background: {
      main: '#FAFAFA',
      light: '#FFFFFF',
      dark: '#0275CA',
    },
    chip: {
      background: {
        main: '#bddaff',
        disabled: '#f2f2f2',
      },
      text: {
        disabled: '#d9d9d9',
      },
    },
    card: {
      border: '#EEE',
      borderHover: '#FF7500',
      divider: '#DBDCDD',
      distance: {
        background: '#EEE',
      },
      status: {
        border: '#0275CA',
        text: '#2C2D36',
        background: {
          main: '#0275CA',
          dark: '#2C2D36',
        },
      },
    },
  },
  shape: {
    borderRadius: '10px',
  },
  typography: {
    fontFamily: [
      'PlusJakarta Text',
      'PlusJakarta Display',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '21px',
    },
    h1: {
      fontFamily: 'PlusJakarta Display',
      fontSize: '54px',
      lineHeight: '70px',
      letterSpacing: 0,
    },
    h2: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '36px',
    },
    h3: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '26px',
    },
    h4: {
      fontFamily: 'PlusJakarta Display',
      fontSize: '20px',
      lineHeight: '34px',
    },
    h5: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '23px',
    },
    h6: {
      fontFamily: 'PlusJakarta Display',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '21px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: 0,
      textAlign: 'left',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '26px',
      letterSpacing: 0,
      textAlign: 'left',
    },
    caption: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#0075CA',
        color: '#FFFFFF',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': plusJakarta,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '10px',
      },
    },
    MuiDivider: {
      vertical: {
        background: '#FFFFFF',
        opacity: 0.4,
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiTableRow: {
      root: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.03)',
        borderRadius: '10px',
        margin: '4px',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '5px',
        padding: '16px 24px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedSecondary: {
        padding: '19px 50px',
      },
      text: {
        color: '#FF7500',
        padding: '16px 24px',
      },
    },
    MuiButtonGroup: {
      root: {
        background: '#ffffff',
        borderRadius: '10px',
      },
      groupedTextPrimary: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        '&:hover': {
          background: '#ffffff',
          opacity: 0.65,
          borderRadius: '10px',
        },
        '&:not(:last-child)': {
          borderColor: 'rgba(0, 0, 0, 0.08)',
        },
        '& span:last-child': {
          flex: 'auto',
          justifyContent: 'flex-end',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: '6px',
      },
    },
  },
}

// theme overrides for PGA coach UI
const pgaCoachTheme = {
  palette: {
    primary: {
      main: '#00234B',
      light: '#043362',
      dark: '#001834',
      contrastText: '#FFFFFF',
      active: 'rgba(0, 35, 75, 0.24)',
      selected: 'rgba(0, 35, 75, 0.08)',
      border: 'rgba(0, 35, 75, 0.5)',
    },
    secondary: {
      main: '#AB9157',
      light: '#BBA778',
      dark: '#77653C',
      contrastText: '#FFFFFF',
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    blue: {
      badge: '#2380FF',
      main: '#3268A7',
    },
  },
  typography: {
    h4: {
      [breakpoints.down('sm')]: {
        fontSize: '22px',
      },
    },
  },
  props: {
    MuiTabs: {
      indicatorColor: 'primary',
      textColor: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
        minWidth: '2.25rem', // 36px at 16px font size
      },
    },
    MUIRichTextEditor: {
      toolbar: {
        borderBottom: '1px solid #E0E0E0',
        marginBottom: '10px',
      },
      editorContainer: {
        height: '100%',
      },
    },
    MuiToggleButtonGroup: {
      root: {
        width: '100%',
      },
    },
    MuiButton: {
      root: {
        [breakpoints.down('sm')]: {
          fontSize: '13px',
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },
      sizeLarge: {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      startIcon: {
        marginRight: '8px',
        marginLeft: '0',
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiToggleButton: {
      root: {
        border: '1px solid #043362',
        borderColor: '#043362',
        color: '#043362',
        width: '50%',
        '&$selected': {
          backgroundColor: '#00234B',
          color: 'white',
          '&:hover': {
            backgroundColor: '#00234B',
          },
        },
      },
    },
  },
}

const offeringsRefreshPalette = {
  primary: {
    lightest: '#3268A7',
    light: '#043362',
    main: '#00234B',
    dark: '#001123',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#DBD2C1',
    main: '#AB9157',
    dark: '#836F50',
    neutral: '#FAF6EF',
    contrastText: '#FFFFFF',
  },
  ryderCup: {
    usa: '#C81414',
    intl: '#003C82',
    neutral: '#828282',
  },
  accent: {
    main: '#FFBE00',
    red: '#D32F2F',
  },
  grey: {
    darkest: '#090909',
    darker: '#555555',
    dark: '#6A6A6A',
    light: '#D8D8D8',
    main: '#9E9E9E',
  },
  blue: {
    main: '#3268A7',
    light: '#EFF5FB',
    light25: '#F5F7FA',
    light100: '#EBEFF5',
    dark: '#001529',
    medium: '#EBEFF5',
  },
  green: {
    light: '#EDF4EA',
    dark: '#002C21',
  },
  gold: {
    main: '#E6BC5A',
  },
  opaque: {
    white: '#FFFFFFF2',
    blue: '#00234BE6',
  },
  offwhite: {
    main: '#F7F7F7',
    secondary: '#EBEBEB',
  },
  background: {
    default: '#F7F7F7',
    gradient: `
      linear-gradient(180deg,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.2) 10%,
        rgba(0, 0, 0, 0.15) 30%,
        rgba(0, 0, 0, 0.5) 50%,
        rgba(0, 0, 0, 0.6) 100%
      )`,
  },
  text: {
    primary: '#000000',
  },
  shadow: {
    cardBoxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  },
}
const offeringsRefreshTheme = {
  palette: offeringsRefreshPalette,
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: defaultFont,
    fontSize: 14,
    h1: {
      fontSize: '60px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1,
      color: offeringsRefreshPalette.primary.main,
    },
    h2: {
      fontSize: '50px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.2,
      color: offeringsRefreshPalette.primary.main,
      [breakpoints.only('xs')]: {
        fontSize: '40px',
        lineHeight: 1.12,
      },
    },
    h3: {
      fontSize: '40px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.3,
      color: offeringsRefreshPalette.primary.main,
      [breakpoints.only('xs')]: {
        fontSize: '32px',
      },
    },
    h4: {
      fontSize: '32px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.25,
      color: offeringsRefreshPalette.primary.main,
      [breakpoints.only('xs')]: {
        fontSize: '24px',
      },
    },
    h5: {
      fontSize: '28px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.16,
      color: offeringsRefreshPalette.primary.main,
    },
    h6: {
      fontSize: '20px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.3,
      color: offeringsRefreshPalette.primary.main,
    },
    body1: {
      lineHeight: 1.75,
      fontSize: '16px',
      fontWeight: bodyWeight,
    },
    body2: {
      lineHeight: 1.75,
      fontSize: '14px',
      fontWeight: bodyWeight,
    },
    subtitle1: {
      // Subtitle Large
      fontSize: '16px',
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: utilityWeight,
    },
    subtitle2: {
      // Subtitle Small
      fontSize: '14px',
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: utilityWeight,
    },
    subtitleXl: {
      fontSize: '18px',
      letterSpacing: 0.1,
      lineHeight: '20px',
      fontWeight: utilityWeight,
    },
    button: {
      fontSize: '14px',
      letterSpacing: 0.1,
    },
    caption: {
      fontSize: '12px',
      letterSpacing: 0.4,
      lineHeight: '16px',
      fontWeight: '600',
    },
    overline: {
      fontSize: '14px',
      letterSpacing: 1.5,
      fontWeight: 'bold',
      fontFamily: eyebrowFont,
      display: 'block',
    },
    cta: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    formLabels: {
      fontSize: '14px',
      lineHeight: '25px',
      fontWeight: utilityWeight,
    },
    formInputs: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: utilityWeight,
    },
    formTags: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: utilityWeight,
    },
    cardFilters: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    cardItemCategory: {
      fontFamily: eyebrowFont,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    navigation: {
      fontSize: '16px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      lineHeight: 1.253125,
    },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
        height: 48,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.625rem 1.5625rem',
        fontSize: '1rem',
        lineHeight: 1.75,
        fontFamily: 'Nunito Sans',
        textTransform: 'none',
        fontWeight: 700,
        borderRadius: '50px',
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
        textDecorationColor: 'transparent',
        transition:
          'text-decoration-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        padding: '0 72px',
      },
      fullWidth: {
        padding: '0 1rem',
      },
      startIcon: {
        marginRight: '5px',
        marginLeft: '0',
      },
      endIcon: {
        marginLeft: '5px',
        marginRight: '0',
      },
      containedPrimary: {
        color: offeringsRefreshPalette.primary.contrastText,
        backgroundColor: offeringsRefreshPalette.primary.lightest,
        '&:hover': {
          backgroundColor: offeringsRefreshPalette.primary.main,
          color: offeringsRefreshPalette.primary.contrastText,
          textDecorationColor: offeringsRefreshPalette.primary.contrastText,
        },
      },
      containedSecondary: {
        color: offeringsRefreshPalette.text.primary,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: offeringsRefreshPalette.gold.main,
          textDecorationColor: offeringsRefreshPalette.text.primary,
        },
      },
      sizeSmall: {
        height: 40,
        fontSize: '.875rem',
        padding: '.24rem 1.5625rem',
      },
      containedSizeSmall: {
        padding: '.24rem 1.5625rem',
      },
      textSizeSmall: {
        padding: '.24rem 1.5625rem',
      },
      text: {
        color: offeringsRefreshPalette.blue.main,
        padding: '.625rem 1.5625rem',
        borderRadius: 0,
        '&:hover': {
          color: offeringsRefreshPalette.primary.dark,
          backgroundColor: 'transparent',
        },
      },
      textPrimary: {
        padding: '.625rem 1.5625rem',
        color: offeringsRefreshPalette.blue.main,
        fontFamily: 'Nunito Sans',
        '&:hover': {
          color: offeringsRefreshPalette.primary.main,
          backgroundColor: 'transparent',
        },
      },
      outlinedPrimary: {
        padding: '.625rem 1.5625rem',
        color: offeringsRefreshPalette.primary.light,
        borderColor: offeringsRefreshPalette.primary.light,
        '&:hover': {
          backgroundColor: offeringsRefreshPalette.offwhite.secondary,
          color: offeringsRefreshPalette.primary.dark,
          borderColor: offeringsRefreshPalette.primary.light,
          textDecorationColor: 'currentColor',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: '#9e9e9e',
      },
    },
    MuiAvatar: {
      root: {
        height: 110,
        width: 110,
        fontFamily: headlineFont,
      },
    },
    MuiPickersDay: {
      current: {
        color: '#000000',
        '& .MuiIconButton-label p': {
          color: '#000000',
          fontWeight: '600',
        },
        '&.MuiPickersDay-dayDisabled .MuiIconButton-label p': {
          color: 'inherit',
        },
        '&.MuiPickersDay-daySelected .MuiIconButton-label p': {
          color: '#FFFFFF',
        },
      },
      daySelected: {
        backgroundColor: offeringsRefreshPalette.primary.light,
        '&:hover': {
          backgroundColor: offeringsRefreshPalette.primary.light,
        },
      },
    },
    MuiPaginationItem: {
      root: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        fontFamily: headlineFont,
        fontSize: '18px',
        margin: '0px',
        color: offeringsRefreshPalette.grey.dark,
      },
      page: {
        '&.Mui-selected': {
          backgroundColor: offeringsRefreshPalette.primary.main,
          color: offeringsRefreshPalette.primary.contrastText,
        },
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: pgaCoachTheme.palette.primary.selected,
      },
    },
  },
}

const pgaHopeRefreshTheme = {
  palette: pgaCoachTheme,
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: defaultFont,
    fontSize: 14,
    h4: {
      fontSize: '32px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: -1,
      lineHeight: 1.25,
      [breakpoints.down('sm')]: {
        fontSize: '22px',
      },
    },
    h5: {
      fontSize: '24px',
      fontFamily: headlineFont,
      fontWeight: headlineWeight,
      letterSpacing: 0,
      lineHeight: 1.16,
      [breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    body1: {
      lineHeight: 1.75,
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: defaultFont,
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    formLabels: {
      fontSize: '14px',
      lineHeight: '25px',
      fontWeight: utilityWeight,
      fontFamily: 'Roboto',
    },
    formInputs: {
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: utilityWeight,
      fontFamily: 'Roboto',
    },
    formTags: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: utilityWeight,
      fontFamily: formFont,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: '16px',
        fontFamily: formFont,
        fontWeight: '400',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: formFont,
        fontWeight: '400',
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: formFont,
        fontWeight: '400',
      },
    },
  },
}

// theme overrides for Intercom Dialog Work-Arounds
const intercomOverrideTheme = {
  props: {
    MuiDialog: {
      disableAutoFocus: true,
      disableEnforceFocus: true,
    },
  },
}

const extendTheme = extensions => theme => {
  return createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      ...(extensions.palette || {}),
    },
    typography: {
      ...theme.typography,
      ...(extensions.typography || {}),
    },
    props: {
      ...theme.props,
      ...(extensions.props || {}),
    },
    overrides: {
      ...theme.overrides,
      ...(extensions.overrides || {}),
    },
  })
}

const themes = {
  common: commonTheme,
  familyCup: familyCupTheme,
  familyCupMarketing: familyCupMarketingTheme,
  pgaCoach: pgaCoachTheme,
  offeringsRefresh: offeringsRefreshTheme,
  pgaHope: pgaHopeRefreshTheme,
  intercom: intercomOverrideTheme,
  juniorLeague: juniorLeagueTheme,
  juniorLeagueMarketing: juniorLeagueMarketingTheme,
}

export default themes
export { extendTheme }
