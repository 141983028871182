import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { PageHeader } from 'components/page-header'
import TransactionSummary from 'components/transaction-summary'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RefundDialog from './refund-dialog'
import useGetRegistrationPayment from 'components/transaction-summary/use-get-registration-payment'
import LinearProgress from '@material-ui/core/LinearProgress'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'

const TransactionDetailPage = () => {
  const { isCoach } = useAuth()
  const { transactionId } = useParams()
  const { path } = useRouteMatch()
  const { data, loading, error, refetch } = useGetRegistrationPayment(
    transactionId,
  )

  if (error) return <p>ERROR</p>
  if (loading) return <LinearProgress />

  const { paymentSourceType, registrationPayment } = data
  const { products } = registrationPayment
  const leftToRefund = registrationPayment.leftToRefundInCents / 100.0
  const canRefund =
    isCoach &&
    ['Enrollment', 'LessonPack', 'PracticeSession'].includes(
      products[0].__typename,
    ) &&
    leftToRefund > 0

  const crumbs = [
    {
      label: 'Account',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
    {
      label: 'Payments',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
    {
      label: 'Transactions',
      to: path
        .split('/')
        .slice(0, 4)
        .join('/'),
    },
  ]

  return (
    <>
      <PageHeader title={`Transaction #${transactionId}`} crumbs={crumbs} />
      <SensitiveInfoWrapper enableFallback>
        <Container>
          <Box my={4}>
            <TransactionSummary
              paymentSourceType={paymentSourceType}
              registrationPayment={registrationPayment}
            />
          </Box>
          {canRefund && (
            <>
              <Grid container pt={2} pb={4} spacing={2}>
                <Grid item>
                  <RefundDialog
                    trigger={
                      <Button variant="contained" color="primary">
                        Add Refund
                      </Button>
                    }
                    registrationPaymentId={registrationPayment.id}
                    leftToRefund={leftToRefund}
                    onSuccess={refetch}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </SensitiveInfoWrapper>
    </>
  )
}

export default withProtectedRoute(TransactionDetailPage, [
  roles.COACH,
  roles.CONSUMER,
])
