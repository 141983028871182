import React from 'react'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'

export default function AgreeCheckbox({ value, name, onChange }) {
  return (
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            color="primary"
            onChange={e => onChange(e.target.checked)}
            name={name}
          />
        }
        label="I Agree"
      />
    </Grid>
  )
}
