import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, Link } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import Layout from './layout'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { styled } from '@material-ui/styles'
import { GET_STUDENT_QUERY, UPSERT_GOLFER_PROFILE } from './queries'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import Success from './success'
import { combinedSchema } from './validationSchema'
import AccountDropdown from 'components/account-dropdown'
import Shell from 'components/shell/shell'
import PGALogo from 'images/pga-logo-navy.png'
import { gtmEvent } from 'lib/gtm'

const StyledContainer = styled(Container)(({ theme }) => ({
  margin: '1rem auto',
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}))

const FormBodyContainer = styled(Container)(({ theme }) => ({
  marginBottom: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '0px .5rem',
    marginBottom: '4rem',
  },
}))

const FormBody = ({ student }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isComplete, setIsComplete] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep, isComplete])

  const { golferProfile } = student || {}

  const height = golferProfile?.heightInInches

  const heightFeet = Math.trunc(golferProfile?.heightInInches / 12) || null

  const heightInches =
    (height > 0 && height % 12) === 0 ? 0 : height % 12 || null

  const form = useForm({
    defaultValues: {
      golfInformation: {
        frequency: golferProfile?.frequencyOfPlay || null,
        associatedWithCourse:
          golferProfile?.associatedWithCourse?.toString() || null,
        homeGolfCourse: golferProfile?.homeGolfCourse || null,
        proficiency: golferProfile?.golfJourney || null,
        ownClubs: golferProfile?.hasOwnClubs?.toString() || null,
        handedness: golferProfile?.handedness || null,
        handicapRating: golferProfile?.handicap || null,
      },
      physicalInformation: {
        heightFeet: heightFeet || null,
        heightInches: heightInches === 0 ? 0 : heightInches || null,
        limitations: golferProfile?.physicalLimitations || null,
      },
      golfFocusInformation: {
        confidentArea: golferProfile?.confidentArea || null,
        improvementArea: golferProfile?.improvementArea || null,
      },
    },
    resolver: yupResolver(yup.object(combinedSchema)),
  })

  const heightInInches = ({ feet, inches }) => {
    return parseInt(feet) * 12 + parseInt(inches)
  }

  const hasOwnClubs = hasClubs => {
    if (hasClubs === null) return null

    return hasClubs.toLowerCase() === 'true'
  }

  const [upsertGolferProfile] = useMutation(UPSERT_GOLFER_PROFILE)
  const onSubmit = async values => {
    return upsertGolferProfile({
      variables: {
        studentId: student.id,
        input: {
          frequencyOfPlay: values.golfInformation.frequency,
          homeGolfCourse:
            values.golfInformation.associatedWithCourse?.toLowerCase() ===
            'true'
              ? values.golfInformation.homeGolfCourse
              : null,
          associatedWithCourse:
            values.golfInformation.associatedWithCourse !== null &&
            values.golfInformation.associatedWithCourse !== undefined
              ? JSON.parse(values.golfInformation.associatedWithCourse)
              : null,
          golfJourney: values.golfInformation.proficiency,
          hasOwnClubs: hasOwnClubs(values.golfInformation.ownClubs),
          handedness: values.golfInformation.handedness,
          handicap: values.golfInformation.handicapRating,
          confidentArea: values.golfFocusInformation.confidentArea || null,
          improvementArea: values.golfFocusInformation.improvementArea || null,
          heightInInches:
            heightInInches({
              feet: values.physicalInformation.heightFeet,
              inches: values.physicalInformation.heightInches,
            }) || null,
          physicalLimitations: values.physicalInformation.limitations || null,
        },
      },
    }).then(({ data }) => {
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'golfer-intake-form',
        formAction: golferProfile ? 'update-profile' : 'create-profile',
        formStatus: 'success',
      })
      if (data.upsertGolferProfile) {
        enqueueSnackbar('Profile updated', { variant: 'success' })
        setIsComplete(true)
      } else {
        enqueueSnackbar(data.upsertGolferProfile, {
          variant: 'error',
        })
      }
    })
  }
  return (
    <StyledContainer
      display={
        !form.formState.isSubmitting || form.formState.isValidating
          ? 'block'
          : 'none'
      }
    >
      {isComplete ? (
        <Success />
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Layout
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isComplete={isComplete}
            />
          </form>
        </FormProvider>
      )}
    </StyledContainer>
  )
}

const GolferIntakeForm = () => {
  const { studentId } = useParams() // need to update clubroute to account for this
  const { data, loading, error } = useQuery(GET_STUDENT_QUERY, {
    variables: { id: studentId },
  })

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />
  const { student } = data || {}
  return (
    <Shell
      toolbarContent={
        <>
          <Link to={'/club'}>
            <img
              src={PGALogo}
              alt="PGA logo"
              style={{
                display: 'block',
                width: '48px',
              }}
            />
          </Link>
          <AccountDropdown />
        </>
      }
    >
      <FormBodyContainer>
        <FormBody student={student} />
      </FormBodyContainer>
    </Shell>
  )
}

export default GolferIntakeForm

FormBody.propTypes = {
  student: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    demographicProfile: PropTypes.shape({
      gender: PropTypes.string,
      genderSelfDescription: PropTypes.string,
    }),
    golferProfile: PropTypes.shape({
      frequencyOfPlay: PropTypes.string,
      homeGolfCourse: PropTypes.string,
      golfJourney: PropTypes.string,
      hasOwnClubs: PropTypes.bool,
      handedness: PropTypes.string,
      handicap: PropTypes.string,
      confidentArea: PropTypes.string,
      improvementArea: PropTypes.string,
      heightInInches: PropTypes.number,
      physicalLimitations: PropTypes.string,
    }),
  }),
}
