import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    marginBottom: '20px',
    padding: '4px 0 38px 0',
  },
  canceled: {
    color: theme.palette.text.disabled,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  },
  chipStyles: ({ isCanceled, isPaid }) => ({
    backgroundColor: isCanceled ? '#9E9E9E' : isPaid ? '#7BC67E' : '#836F50',
    color: 'white',
    transition: 'none',
    cursor: 'pointer',
  }),
  sessionRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& td': {
      borderTop: '1px solid #EEEEEE',
      borderBottom: '1px solid #EEEEEE !important',
    },
    '& td:first-of-type': {
      borderLeft: '1px solid #EEEEEE',
      borderRadius: '8px 0 0 8px',
    },
    '& td:last-of-type': {
      borderRight: '1px solid #EEEEEE',
      borderRadius: '0 8px 8px 0',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
  },
  sessionIcon: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '64px',
      height: '64px',
      marginRight: '1rem',
    },
  },
  subtext: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  mobileText: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  mobileBottom: {
    width: '100%',
    paddingRight: '15px',
  },
  mobileContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}))
