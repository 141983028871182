import React from 'react'
import Link from '@material-ui/core/Link'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'

const SIGN_IN_URL = encodeURIComponent(
  'https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com',
)

export const authenticatedRedirectLink = (path = '') => {
  const destinationLink = encodeURIComponent(
    `https://vt.lightspeedvt.com${path}`,
  )
  return `https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=${SIGN_IN_URL}&dest=${destinationLink}`
}

export const ADMTrainingExternalLink = ({ icon = true }) => (
  <Link
    style={{ fontWeight: 600 }}
    target="_blank"
    href={authenticatedRedirectLink('/trainingCenter/category/16873')}
  >
    ADM Training
    {icon && <LaunchOutlinedIcon fontSize="inherit" />}
  </Link>
)
export const GROUP_EVENT_PAGE_LIMIT = 12
