import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { Channel, ChannelList, useChatContext, Thread } from 'stream-chat-react'
import { withStreamClient } from 'lib/stream-messaging'
import { useDebounce, useIsMobile, useMobileHeight } from 'lib/hooks'
import ChannelPreview from './channel-preview'
import { ChannelInner, CustomAttachment, CustomMessage } from './channel-inner'
import NewConversationDialog from './new-conversation-dialog'
import EmptyChannel from './empty-channel'
import { AttachmentContextProvider } from './use-attachment-context'
import ConversationLoader from './components/conversation-loader'
import { useFeatureFlags, flags } from 'lib/feature-flags'
import HiddenMessages from './hidden-messages'
import { PageHeader } from '../page-header'

const ChannelListHeader = React.memo(({ searchQuery, setSearchQuery }) => {
  return (
    <Box display="flex" position="relative" p={2} alignItems="center">
      <Box display="flex" flexGrow={1} mr={2} bgcolor="white" borderRadius={4}>
        <TextField
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          color="primary"
          variant="outlined"
          margin="none"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <NewConversationDialog
        trigger={
          <IconButton color="primary">
            <AddOutlinedIcon />
          </IconButton>
        }
      />
    </Box>
  )
})

const Messenger = withStreamClient(() => {
  const { channelId: initialChannelId } = useParams()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(true)
  const [displayChannelList, setDisplayChannelList] = useState(false)
  const debouncedSearchQuery = useDebounce(searchQuery, 300)?.trim()
  const { channel, client, setActiveChannel } = useChatContext()
  const [hiddenChannels, setHiddenChannels] = useState([])
  const [threadsEnabled] = useFeatureFlags([flags.FLAG_FEAT_STREAM_THREADS])
  const isMobile = useIsMobile()
  useMobileHeight()

  const MemoizedChannelListHeader = useCallback(
    () => (
      <ChannelListHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    ),
    [searchQuery, setSearchQuery],
  )

  const newConversationDialogTrigger = isMobile ? (
    <IconButton color="primary">
      <AddOutlinedIcon />
    </IconButton>
  ) : (
    <Button variant="contained" color="primary">
      New Message
    </Button>
  )

  useEffect(() => {
    if (!displayChannelList) {
      const hasChannels = async () => {
        const channels = await client.queryChannels(filters, sort, { limit: 1 })
        setDisplayChannelList(channels.length > 0)
      }

      hasChannels()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [channel])

  // if channelId parameter is provided, select the corresponding channel on load
  useEffect(() => {
    if (initialChannelId != null) {
      const updateActiveChannel = async () => {
        const filter = { id: initialChannelId }
        const sort = []
        const [ch] = await client.queryChannels(filter, sort, { limit: 1 })
        if (ch != null) {
          setActiveChannel(ch)
        }
      }

      updateActiveChannel()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  // get hidden channels
  useEffect(() => {
    const getHiddenChannels = async () => {
      const filters = {
        members: { $in: [client.user?.id] },
        frozen: false,
        hidden: true,
      }
      const sort = { last_message_at: -1 }
      const hiddenChannels = await client.queryChannels(filters, sort, {
        limit: 20,
      })
      setHiddenChannels(hiddenChannels)
    }

    getHiddenChannels()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [channel])

  // update browser url to match selected channel whenever it changes
  useEffect(() => {
    if (channel != null && path.includes(':channelId?')) {
      window.history.replaceState(
        null,
        null,
        path.replace(':channelId?', channel.id),
      )
    }
  }, [channel, path])

  const filters = {
    members: { $in: [client.user?.id] },
    frozen: false,
    hidden: false,
    ...(debouncedSearchQuery && {
      'member.user.name': { $autocomplete: debouncedSearchQuery },
    }),
  }
  const sort = { last_message_at: -1 }
  const options = { limit: 20 }

  // The conversation loader checks if we are creating a conversation and makes/finds
  // that channel, and otherwise returns the loading spinner.
  if (loading) {
    return <ConversationLoader setLoading={() => setLoading(false)} />
  }

  return displayChannelList ? (
    <>
      <PageHeader
        title="Messaging"
        actions={
          <>
            {hiddenChannels.length > 0 && (
              <HiddenMessages
                trigger={
                  <Button
                    variant={isMobile ? 'text' : 'outlined'}
                    style={{ marginRight: '12px' }}
                  >
                    Hidden Messages
                  </Button>
                }
                hiddenChannels={hiddenChannels}
              />
            )}
            <NewConversationDialog trigger={newConversationDialogTrigger} />
          </>
        }
      />
      <ChannelList
        filters={filters}
        sort={sort}
        options={options}
        showChannelSearch
        Preview={ChannelPreview}
        ChannelSearch={MemoizedChannelListHeader}
        setActiveChannelOnMount={
          !initialChannelId && !pathname.includes('/new-conversation')
        }
      />
      {channel && (
        <Channel
          channel={channel}
          Message={CustomMessage}
          Attachment={CustomAttachment}
        >
          <AttachmentContextProvider>
            <ChannelInner />
            {threadsEnabled && <Thread />}
          </AttachmentContextProvider>
        </Channel>
      )}
    </>
  ) : (
    <EmptyChannel />
  )
})

export default Messenger
