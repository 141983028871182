import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import EventDetailsDialog from '../../pga-coach/bookings/events/event-details-dialog'
import ShareEventDialog from 'components/group-events/share-event-dialog'
import ActionMenu from './action-menu'
import EventCard from 'components/group-events/event-card'

const ConsumerEventCard = ({ event, openEvent }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(
    openEvent ? 'detail' : null,
  )
  const [isShareOpen, setIsShareOpen] = useState(false)

  return (
    <EventCard
      event={event}
      setIsDetailsOpen={setIsDetailsOpen}
      cardActions={
        <>
          <Box>
            <Button
              size="small"
              color="primary"
              onClick={() => setIsDetailsOpen('detail')}
            >
              VIEW DETAILS
            </Button>
          </Box>
          <EventDetailsDialog
            isOpen={isDetailsOpen}
            event={event}
            setIsOpen={setIsDetailsOpen}
          />
          <ShareEventDialog
            isOpen={isShareOpen}
            url={event.eventUrl}
            title={event.title}
            setIsOpen={setIsShareOpen}
            titleText="Share this event"
          />
        </>
      }
      actionMenu={<ActionMenu setIsShareOpen={setIsShareOpen} />}
    />
  )
}

export default ConsumerEventCard
