import React from 'react'
import { gtmEvent } from 'lib/gtm'
import { PGA_COM_HOST } from 'env'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TransactionSummary from '../components/transaction-summary'
import { useStyles } from './styles'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import SummaryDetails from './summary-details'
import { useHistory, useParams } from 'react-router-dom'
import StudentProfileCTA from '../components/StudentProfileCTA'
import useQueryString from 'lib/hooks/use-query-string'
import { useQuery } from '@apollo/client'
import { GetStudentQuery } from './query'
import { LinearProgress } from '@material-ui/core'
import Error from 'components/error'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'

const SummaryLayout = ({
  idOrSlug,
  lessonType,
  lessonTypeId,
  locationId,
  cardBrand,
  lastDigits,
  selectedLessonPackId,
}) => {
  const { studentId } = useQueryString()
  const { slug: academySlug } = useParams()

  const history = useHistory()
  const classes = useStyles()

  const {
    data: fetchedStudent,
    error: studentError,
    loading: studentLoading,
  } = useQuery(GetStudentQuery, {
    variables: {
      studentId: studentId,
    },
  })

  if (studentLoading) return <LinearProgress />
  if (studentError) return <Error error={studentError} />
  const { golferProfile } = fetchedStudent?.student || {}

  const { coach, lessonPacks = [] } = lessonType

  const [selectedLessonPack] = lessonPacks.filter(
    lp => lp.id === selectedLessonPackId,
  )
  const { priceInCents, quantity } = selectedLessonPack

  const isCoachingCenterBooking = history.location.pathname.includes(
    'coaching-center',
  )
  const isFCCBooking = history.location.pathname.includes(
    FRISCO_COACHING_CENTER_SLUG,
  )
  const bookNewSessionUrl = isCoachingCenterBooking
    ? coach?.bookingUrl
    : `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&lessonCreditBalanceQuantity=${quantity}`

  const onCoachingCenterBooking = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-another-session-button',
    })
    if (isFCCBooking) {
      window.location.href = `${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`
    } else {
      window.location.href = `${PGA_COM_HOST}/coaching-centers/${academySlug}`
    }
  }
  const onBookNow = () => {
    if (isFCCBooking) {
      window.location.href = `${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`
    } else if (isCoachingCenterBooking) {
      window.location.href = `${PGA_COM_HOST}/coaching-centers/${academySlug}`
    } else {
      window.location.href = bookNewSessionUrl
    }
  }

  const onViewCoachOfferings = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-coachofferings-button',
    })
    if (isFCCBooking) {
      window.location.href = `${PGA_COM_HOST}/${FRISCO_COACHING_CENTER_SLUG}`
    } else if (isCoachingCenterBooking) {
      window.location.href = `${PGA_COM_HOST}/coaching-centers/${academySlug}`
    } else {
      window.location.href = window.location.pathname
    }
  }

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <Box display="flex" flexDirection="column" width="100%" mt={7} px={1}>
        <Container className={classes.root} maxWidth="md">
          <Box maxWidth="600px">
            <Typography variant="h4">Thank you!</Typography>
            <Typography variant="body1" className={classes.fontBolded}>
              Your purchase is complete. You will receive a confirmation email
              shortly.
            </Typography>
          </Box>
          <Divider className={classes.divider} />
        </Container>
        <Container className={classes.root} maxWidth="sm">
          <TransactionSummary
            priceInCents={priceInCents}
            cardBrand={cardBrand}
            lastDigits={lastDigits}
            selectedLessonPack={selectedLessonPack}
          />
          <SummaryDetails
            lessonType={lessonType}
            coach={coach}
            selectedLessonPack={selectedLessonPack}
            classes={classes}
          />
          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            width="100%"
          >
            {isCoachingCenterBooking && (
              <>
                <Divider className={classes.paddedDivider} />
                <StudentProfileCTA
                  golferProfile={golferProfile}
                  studentId={studentId}
                />
                <Divider className={classes.paddedDivider} />
              </>
            )}
            {isCoachingCenterBooking ? (
              <Button
                color="primary"
                variant="outlined"
                className={`${classes.details} ${classes.altButtonStyle}`}
                onClick={onCoachingCenterBooking}
                children={'View Offerings'}
              />
            ) : (
              <Button
                color="primary"
                variant="outlined"
                className={`${classes.details} ${classes.altButtonStyle}`}
                onClick={onViewCoachOfferings}
                children={'View Coach Offerings'}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              className={`${classes.details} ${classes.buttonStyles}`}
              onClick={onBookNow}
            >
              Book Now
            </Button>
          </Box>
          <DownloadMyPGAsection />
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default SummaryLayout
