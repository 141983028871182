import React, { useImperativeHandle, forwardRef, useContext } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import LoadingSpinner from 'components/loading-spinner'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { gtmEvent } from 'lib/gtm'
import PropTypes from 'prop-types'
import { Backdrop } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { MY_PGA_COM_HOST } from 'env'
import { PaymentMethodContext } from '../index'

const PaymentForm = forwardRef(
  (
    { enrollment, redirectUrl, isSubmitting, setIsSubmitting, clientSecret },
    ref,
  ) => {
    const { handleSubmit: handleFormSubmit } = useForm()
    const { setPaymentMethod } = useContext(PaymentMethodContext) || {}
    const history = useHistory()
    const stripe = useStripe()
    const elements = useElements()

    const { enqueueSnackbar } = useSnackbar()

    const contact = enrollment.student.contact

    const billingName = `${contact.firstName} ${contact.lastName}`
    const billingDetails = {
      name: billingName,
      email: contact.email,
    }

    const onSubmit = async e => {
      if (!stripe || !elements) {
        return
      }

      setIsSubmitting(true)

      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          expand: ['payment_method'],
          return_url: `${MY_PGA_COM_HOST}/payment/${enrollment.paymentRequestToken}/success`,
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
      })
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' })
        setIsSubmitting(false)
        return
      }
      const paymentMethod = paymentIntent?.payment_method?.card
      if (setPaymentMethod) {
        setPaymentMethod(paymentMethod)
      }
      // eslint-disable-next-line default-case
      switch (paymentIntent?.status) {
        case 'succeeded':
          enqueueSnackbar('Payment succeeded!', { variant: 'success' })
          gtmEvent({
            event: 'request-payment',
            requestAction: 'payment',
            reqestEnrollment: enrollment.id,
          })
          if (setPaymentMethod) {
            history.push(
              `${redirectUrl}?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${paymentIntent.client_secret}&redirect_status=succeeded`,
            )
          } else {
            setIsSubmitting(false)
          }
          break
        case 'processing':
          enqueueSnackbar('Your payment is processing.', {
            variant: 'info',
          })
          break
        case 'requires_payment_method':
          enqueueSnackbar('Payment failed', { variant: 'error' })
          break
      }

      // eslint-disable-next-line default-case
      switch (error?.type) {
        case 'card_error':
          enqueueSnackbar(error.message, { variant: 'error' })
          break
        case 'validation_error':
          enqueueSnackbar(error.message, { variant: 'error' })
          break
      }
      setIsSubmitting(false)
    }
    const handleSubmit = handleFormSubmit(onSubmit)
    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit,
    }))

    const paymentElementOptions = {
      layout: 'tabs',
    }

    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        {isSubmitting && (
          <Backdrop
            open={isSubmitting}
            id="payment-loading"
            style={{ zIndex: 9999, color: '#fff' }}
          >
            <LoadingSpinner />
          </Backdrop>
        )}
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </form>
    )
  },
)

export default PaymentForm

PaymentForm.propTypes = {
  enrollment: PropTypes.shape({
    student: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      representatives: PropTypes.arrayOf(
        PropTypes.shape({
          relationshipType: PropTypes.string.isRequired,
          contact: PropTypes.shape({
            email: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  clientSecret: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setIsSubmitting: PropTypes.func.isRequired,
}
