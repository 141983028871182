import isValid from 'date-fns/isValid'
import set from 'date-fns/set'
import { useWatch } from 'react-hook-form'
import castArray from 'lodash/castArray'

const getDate = date => ({
  year: date.getFullYear(),
  month: date.getMonth(),
  date: date.getDate(),
})
const getTime = date => ({ hours: date.getHours(), minutes: date.getMinutes() })

export const useSyncDateTimeInputs = ({
  control,
  setValue,
  dateFieldName,
  timeFieldNames = [],
  shouldDirty = true,
}) => {
  const timeFields = useWatch({ control, name: castArray(timeFieldNames) })
  const date = useWatch({ control, name: dateFieldName })

  const onDateInputChange = dateInput => {
    setValue(dateFieldName, dateInput, { shouldDirty })
    if (isValid(dateInput)) {
      const newDate = getDate(dateInput)
      Object.entries(timeFields).map(([key, value]) =>
        setValue(key, set(value, newDate), { shouldDirty }),
      )
    }
  }

  const onTimeInputChange = fieldName => timeInput => {
    setValue(
      fieldName,
      isValid(timeInput) ? set(date, getTime(timeInput)) : timeInput,
      { shouldDirty },
    )
  }

  return {
    onDateInputChange,
    onTimeInputChange,
  }
}
