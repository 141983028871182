import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import { useAuth } from 'lib/auth'
import { usePortal } from 'lib/portal'
import { useSnackbarSuccess, useSnackbarError } from 'lib/snackbar'
import { useSnackbar } from 'notistack'
import Disclaimer from 'components/disclaimer'
import { PageHeader } from 'components/page-header'
import PGAIcon from 'images/pga-seal-icon.svg'
import useStyles, { SettingsBlock } from './styles'
import { isPgaLink } from 'lib/utils'

const GET_BOOKING_URL = gql`
  query CoachBookingUrl {
    currentUser {
      attributes {
        ... on Coach {
          bookingUrl
        }
      }
    }
  }
`

const UPDATE_COACH_EXTERNAL_BOOKING_URL = gql`
  mutation UpdateCoachBookingUrl($externalBookingUrl: URL) {
    updateCoachExternalBookingUrl(externalBookingUrl: $externalBookingUrl) {
      success
      message
      coach {
        bookingUrl
        id
      }
    }
  }
`

const validationSchema = yup.object().shape({
  externalBookingUrl: yup
    .string()
    .required('Please enter a URL.')
    .url(
      'Please enter a valid URL. URL must start with "http://" or "https://"',
    )
    .matches(
      /^(?!.*.pga.com)/i,
      'Please enter a URL that is not associated with PGA.',
    ),
})

const BookingUrlPage = () => {
  const { path } = useRouteMatch()
  const { user } = useAuth()
  const [target] = usePortal()
  const styles = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const { data, loading, refetch } = useQuery(GET_BOOKING_URL)

  const bookingUrl =
    data?.currentUser?.attributes?.bookingUrl || user?.coach?.bookingUrl

  const isPgaUrl = isPgaLink(bookingUrl)

  const [externalBookingUrl, setExternalBookingUrl] = useState(null)
  const [checked, setChecked] = useState(!isPgaUrl)
  const [displayButton, setDisplayButton] = useState(false)
  const [enableButton, setEnableButton] = useState(false)

  const {
    register,
    handleSubmit,
    trigger,
    errors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      externalBookingUrl: !isPgaUrl ? bookingUrl : '',
    },
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (!loading) {
      setChecked(!isPgaUrl)
    }
    if (!isPgaUrl && !loading) {
      setValue('externalBookingUrl', bookingUrl)
    }
  }, [loading, isPgaUrl, bookingUrl, setValue])

  const [setBookingUrl] = useMutation(UPDATE_COACH_EXTERNAL_BOOKING_URL, {
    onCompleted: data => {
      const urlChanged = data?.updateCoachExternalBookingUrl?.success
      if (!urlChanged) {
        snackbarError('"Book Now" preferences were not set.')
      } else {
        snackbarSuccess(data.updateCoachExternalBookingUrl?.message)
      }
      refetch()
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['set-booking-url'],
        })
      snackbarError('"Book Now" preferences were not set.')
    },
  })

  const crumbs = [
    {
      label: 'Settings',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]

  const handleUrlClear = e => {
    setBookingUrl({
      variables: { externalBookingUrl: null },
    })
  }

  const onSubmit = async formValues => {
    setDisplayButton(false)
    !checked
      ? handleUrlClear()
      : setBookingUrl({
        variables: {
          externalBookingUrl: formValues.externalBookingUrl,
        },
      })
  }

  if (loading) return <LinearProgress color="secondary" />

  return (
    <>
      <PageHeader
        title='"Book Now" Preferences'
        crumbs={crumbs}
        actions={target}
      />
      <Container className={styles.root}>
        <Grid container item lg={7} md={8} xs={12}>
          <Disclaimer
            logo={PGAIcon}
            description='To allow students to book with you and register for your events, make sure your "Book Now" toggle is on.'
          />
        </Grid>
        <Typography variant="h6" gutterBottom>
          "Book Now" Preferences
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={7} md={8} xs={12}>
            <Box>
              <Typography variant="body2" color="textSecondary">
                Determine how you would like to collect and manage registrations
                for your programs.
              </Typography>
            </Box>
          </Grid>
          <FormControl>
            <Grid item lg={7} md={8} xs={12}>
              <SettingsBlock className={styles.settingsDescription}>
                <FormControlLabel
                  checked={!checked}
                  value="PGA_URL"
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      variant="subtitle1"
                      className={styles.settingsCaption}
                    >
                      PGA Coach (Default)
                    </Typography>
                  }
                  onChange={() => {
                    setChecked(false)
                    setDisplayButton(!isPgaUrl)
                    setEnableButton(!isPgaUrl)
                  }}
                />
                <Typography
                  variant="body2"
                  display="block"
                  className={styles.settingsText}
                >
                  Use the PGA Coach platform to promote, collect and manage your
                  bookings and event registrations. Access PGA Coach from a
                  browser or via the iOS and Android applications.
                </Typography>
                <Typography
                  display="block"
                  variant="body2"
                  className={styles.settingsText}
                >
                  Your students will be able to search for your programs, book
                  individual sessions and register for group events using the
                  MyPGA website or by downloading the iOS or Android
                  applications.
                </Typography>
              </SettingsBlock>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item lg={7} md={8} xs={12}>
                <SettingsBlock className={styles.settingsDescription}>
                  <FormControlLabel
                    checked={checked}
                    value="EXTERNAL_URL"
                    control={<Radio color="primary" />}
                    label={
                      <Typography
                        variant="subtitle1"
                        className={styles.settingsCaption}
                      >
                        External Tool
                      </Typography>
                    }
                    onChange={() => {
                      setChecked(true)
                      setDisplayButton(
                        !!(
                          isPgaUrl ||
                          getValues('externalBookingUrl') !== bookingUrl
                        ),
                      )
                      setEnableButton(
                        !!(
                          getValues('externalBookingUrl') &&
                          getValues('externalBookingUrl') !== bookingUrl
                        ),
                      )
                    }}
                  />
                  <Typography variant="body2" className={styles.settingsText}>
                    You can direct your students to another site where you
                    collect and manage your offerings. This means that PGA Coach
                    cannot promote your programs and your students will not be
                    able to directly sign up on PGA Coach.
                  </Typography>
                  <FormControl margin="normal">
                    <TextField
                      name="externalBookingUrl"
                      inputRef={register}
                      variant="standard"
                      error={!!errors.externalBookingUrl}
                      helperText={errors.externalBookingUrl?.message}
                      style={{ display: checked ? 'block' : 'none' }}
                      className={styles.urlTextField}
                      label={
                        <Typography
                          variant="body2"
                          className={styles.labelText}
                        >
                          Enter URL (ex. https://www.bookinglink.com)
                        </Typography>
                      }
                      onChange={e => {
                        setDisplayButton(e.target.value !== bookingUrl)
                        setEnableButton(true)
                        setExternalBookingUrl(e.target.value)
                        trigger('externalBookingUrl')
                      }}
                    />
                  </FormControl>
                </SettingsBlock>
                <Button
                  type="submit"
                  disabled={
                    !!errors.externalBookingUrl ||
                    (!externalBookingUrl && !enableButton)
                  }
                  style={{ display: displayButton ? 'block' : 'none' }}
                  className={styles.saveButton}
                  variant="contained"
                  color="primary"
                >
                  Save updates
                </Button>
              </Grid>
            </form>
          </FormControl>
        </Grid>
      </Container>
    </>
  )
}

export default BookingUrlPage
