import CloudinaryImageUpload from './cloudinary-image-upload'
import uploadImage from './uploadImage'
import { CLOUDINARY_UPLOAD_PRESET, CLOUDINARY_UPLOAD_PRESET_LARGE } from 'env'

const defaultUploadPreset = CLOUDINARY_UPLOAD_PRESET
const largeUploadPreset = CLOUDINARY_UPLOAD_PRESET_LARGE

export {
  CloudinaryImageUpload,
  uploadImage,
  defaultUploadPreset,
  largeUploadPreset,
}
