import React from 'react'
// import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { toThreeLetterTimezone } from 'utils/timezones'

const SessionListRow = ({ session, timezone }) => {
  const startDateTime = DateTime.fromISO(session.startDateTime).setZone(
    timezone,
  )
  const endDateTime = DateTime.fromISO(session.endDateTime).setZone(timezone)

  return (
    <TableRow>
      <TableCell>
        {/* Oct 21, 2023 */}
        {startDateTime.toLocaleString(DateTime.DATE_MED)}
      </TableCell>
      <TableCell>
        {/* Monday, Tuesday, etc */}
        {startDateTime.toLocaleString({ weekday: 'long' })}
      </TableCell>
      <TableCell>
        {/* Start time - End time: 9:00 AM - 10:00 AM */}
        {startDateTime.toLocaleString(DateTime.TIME_SIMPLE)} -{' '}
        {endDateTime.toLocaleString(DateTime.TIME_SIMPLE)}
      </TableCell>
    </TableRow>
  )
}

const SessionList = ({ event, timezone }) => {
  const timezoneAbbreviation = toThreeLetterTimezone(timezone, event.startDate)

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sessions</TableCell>
            <TableCell>Day</TableCell>
            <TableCell>
              Time {timezoneAbbreviation ? `(${timezoneAbbreviation})` : ''}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {event.sessions.map((session, index) => (
            <SessionListRow session={session} timezone={timezone} key={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SessionList
