import React, { useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMutation } from '@apollo/client'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { RequestCoachReviews } from './query'
import useStyles from './styles'
import { gtmEvent } from 'lib/gtm'
import pluralize from 'lib/pluralize'

const ConfirmSendModal = ({
  contactsArr,
  open,
  setOpen,
  setStep,
  setError,
}) => {
  const [loading, setLoading] = useState(false)
  const [requestCoachReviews] = useMutation(RequestCoachReviews)
  const styles = useStyles()

  // GTM Tracking Code
  const setGTMEvent = obj => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'reviews',
      formAction: 'send-review-request',
      ...obj,
    })
  }

  const handleReviewRequest = async () => {
    try {
      setLoading(true)
      await requestCoachReviews({ variables: { params: { contactsArr } } })

      setGTMEvent({ formStatus: 'success' })

      setLoading(false)
      setStep(2)
    } catch (e) {
      setGTMEvent({
        formStatus: 'error',
        errorDescription: e.message,
      })
      setLoading(false)
      setError(e)
      setStep(2)
    }
  }

  return (
    <>
      <DialogTitle>
        <Typography variant="h6">
          {`Send review request to ${contactsArr.length} ${pluralize(
            contactsArr,
            'contact',
          )}?`}
        </Typography>
      </DialogTitle>
      {loading && (
        <CircularProgress style={{ alignSelf: 'center' }} color="secondary" />
      )}
      <DialogContent>
        <Typography variant="body" style={{ fontSize: '16px' }}>
          Are you sure you want to send an email to {contactsArr.length}{' '}
          {pluralize(contactsArr, 'contact')} requesting a review of your
          coaching program?
        </Typography>
      </DialogContent>
      <DialogActions className={styles.actionsWrapper}>
        <Button
          onClick={() => {
            setStep(0)
            setOpen(!open)
          }}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={() => handleReviewRequest()}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Yes
        </Button>
      </DialogActions>
    </>
  )
}

ConfirmSendModal.propTypes = {
  contactsArr: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setStep: PropTypes.func,
  setError: PropTypes.func,
}

export default ConfirmSendModal
