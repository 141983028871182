import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import EventDetailsDialog from '../event-details-dialog'
import EventsActionsMenu from './events-action-menu'
import ShareEventDialog from 'components/group-events/share-event-dialog'
import EventCard from 'components/group-events/event-card'
import pluralize from 'lib/pluralize'
import { useAuth } from 'lib/auth'

const CoachEventCard = ({ event, onPayment }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [isShareOpen, setIsShareOpen] = useState(false)
  const { user } = useAuth()
  const waitlistCount = event?.waitlist?.entries.length
  const showWaitlistLabel = waitlistCount > 0
  const userIsAssistantCoach = event.assistantCoaches?.some(
    coach => coach.id === user.id,
  )

  const coachCanEdit =
    !event.isCanceled && !event.isPast() && !userIsAssistantCoach

  const chipLabel = event.maxAttendance
    ? `${event.totalEnrolled}/${event.maxAttendance} ${pluralize(
      event.maxAttendance,
      'attendee',
    )}`
    : `${event.totalEnrolled} ${pluralize(event.totalEnrolled, 'attendee')}`

  return (
    <EventCard
      event={event}
      setIsDetailsOpen={setIsDetailsOpen}
      cardActions={
        <>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {coachCanEdit && (
                <Button
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/pga-coach/bookings/events/edit/${event.slug}`}
                >
                  Edit
                </Button>
              )}
              <Button
                size="small"
                color="primary"
                onClick={() => setIsShareOpen(!isShareOpen)}
              >
                Share
              </Button>
            </Box>
          </Box>
          <ShareEventDialog
            isOpen={isShareOpen}
            url={event.eventUrl}
            title={event.title}
            setIsOpen={setIsShareOpen}
            titleText="Share this event with your students"
            eventId={event.id}
          />
          <EventDetailsDialog
            isOpen={isDetailsOpen}
            event={event}
            setIsOpen={setIsDetailsOpen}
            onPayment={onPayment}
          />
        </>
      }
      actionMenu={<EventsActionsMenu setIsDetailsOpen={setIsDetailsOpen} />}
      chip={
        <>
          {!event.externalRegistrationUrl ? (
            <Chip
              style={{ position: 'absolute', top: '5px', right: '5px' }}
              label={chipLabel}
            />
          ) : null}
          {showWaitlistLabel && (
            <Chip
              style={{ position: 'absolute', top: '5px', right: '125px' }}
              label={`${waitlistCount} Waitlist`}
            />
          )}
        </>
      }
    />
  )
}

export default CoachEventCard
