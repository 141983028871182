import React from 'react'
import { useQuery, gql } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Box } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import useStyles from './styles'
import Avatar from '@material-ui/core/Avatar'
const COACHING_CENTER_AFFILIATION_QUERY = gql`
  query GetCoachingCenterAffiliations {
    currentUser {
      attributes {
        ... on Coach {
          employments {
            role
            academy {
              id
              name
              logoImageUrl
              slug
            }
          }
        }
      }
    }
  }
`

const CoachingCenterListDropDown = ({ showHelperText = true }) => {
  const { data, loading, error } = useQuery(COACHING_CENTER_AFFILIATION_QUERY)
  const history = useHistory()
  const classes = useStyles()
  const { slug } = useParams()

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />
  const userEmployments = data.currentUser.attributes.employments

  const ownedCoachingCenters = userEmployments
    .filter(e => e.role === 'OWNER')
    .map(e => e.academy)

  const currentCoachingCenter =
    ownedCoachingCenters.find(academy => academy.slug === slug) || false
  // lets create a new list from ownedAcademies that removes the current academy
  const otherCoachingCenters = ownedCoachingCenters.filter(
    academy => academy.slug !== slug,
  )

  const selectDropdown = ccSlug => {
    history.push(`/account/coaching-center/${ccSlug}`)
  }

  return (
    <Box className={classes.dropdown}>
      <FormControl>
        {!currentCoachingCenter && (
          <InputLabel
            id="coaching-center-select-list"
            className={classes.label}
          >
            Coaching Center
          </InputLabel>
        )}
        <Select
          labelId="coaching-center-select-list"
          id="coaching-center-select"
          defaultValue={currentCoachingCenter?.slug}
          onChange={event => selectDropdown(event.target.value)}
        >
          {currentCoachingCenter && (
            <MenuItem
              key={currentCoachingCenter.id}
              value={currentCoachingCenter?.slug}
              selected={currentCoachingCenter.slug === slug}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box style={{ minWidth: '150px' }}>
                  {currentCoachingCenter?.name}
                </Box>
                <Avatar src={currentCoachingCenter.logoImageUrl} />
              </Box>
            </MenuItem>
          )}
          {otherCoachingCenters.map(academy => (
            <MenuItem
              key={academy.id}
              value={academy.slug}
              selected={currentCoachingCenter.slug === slug}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box style={{ minWidth: '150px' }}>{academy?.name}</Box>
                <Avatar src={academy.logoImageUrl} />
              </Box>
            </MenuItem>
          ))}
        </Select>
        {showHelperText && (
          <FormHelperText>
            Select a Coaching Center to see all students
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default CoachingCenterListDropDown
