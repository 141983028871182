import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const LoadingPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      my={8}
    >
      <Box mb={2}>
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          Creating a secure conversation with the event host.
        </Typography>
      </Box>
      <Box position="relative">
        <CircularProgress size={100} thickness={1.8} />
        <MessageOutlinedIcon
          fontSize="large"
          color="primary"
          style={{ position: 'absolute', top: '32.5px', left: '32.5px' }}
        />
      </Box>
    </Box>
  )
}

export default LoadingPage
