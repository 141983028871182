import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import BlockTimeForm from './block-time-form'

const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 0,
    paddingTop: '50px',
  },
  content: {
    paddingTop: '10px',
  },
}))

const BlockTimeFormDialog = ({
  trigger,
  initialTimeSlots,
  initialSelectedDay,
  onSuccess,
}) => {
  const classes = useStyles()
  return (
    <Dialog trigger={trigger} maxWidth="sm" fullWidth showCloseButton>
      {({ closeDialog }) => (
        <>
          <DialogTitle className={classes.header}>
            Create blocked time
          </DialogTitle>
          <DialogContent className={classes.content}>
            <BlockTimeForm
              onSuccess={() => {
                onSuccess && onSuccess()
                closeDialog()
              }}
              onCancel={closeDialog}
            />
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default BlockTimeFormDialog
