import * as React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { useIsTablet } from 'lib/hooks'
import { visuallyHidden } from '@mui/utils'
import { UnfoldMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import NorthIcon from '@mui/icons-material/North'
import PropTypes from 'prop-types'

/*
  const columns = [
    {
      id: string,
      label: string,
      align: string (left, right, center),
      width: string/number,
      mobile: bool, <-- TRUE if visible on tablet/mobile.
      sortable: bool, <-- TRUE if sortable by this column.
      sortComparator: function, <-- Function used to sort.
    },
    {
      ...
    },
  ]

  Access row values in the sortComparator using the 'props' attribute on the arguments.
  Pass in the direction to change sorting behavior.
    (a, b, dir) => {
      a = a.props.attribute_name_with_data_to_sort
      b = b.props.attribute_name_with_data_to_sort

      if (a > b) return dir === 'desc' ? 1 : -1
      if (a < b) return dir === 'desc' ? -1 : 1
      return 0
    }
*/

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0.25rem 0.5rem !important',
    fontSize: '16px !important',
    lineHeight: '28px !important!',
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottomStyle: 'none !important',
    '& .MuiTableSortLabel-root': {
      '& .MuiTableSortLabel-icon': {
        marginLeft: '0.5rem',
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.38)',
      },
    },
    '& .Mui-active': {
      '& .MuiTableSortLabel-icon': {
        color: 'rgba(0, 0, 0, 0.84) !important',
      },
    },
  },
}))

export default function EnhancedTableHead({
  columns,
  sortDirection,
  sortBy,
  onRequestSort,
  ...props
}) {
  const classes = useStyles()
  const isTablet = useIsTablet()
  const responsiveColumns = columns.filter(col => (isTablet ? col.mobile : col))

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead {...props}>
      <TableRow>
        {responsiveColumns.map(column => {
          return column.sortable ? (
            <TableCell
              key={column.id}
              align={column.align}
              sortDirection={sortBy === column.id ? sortDirection : false}
              width={column.width}
              className={classes.root}
            >
              <TableSortLabel
                active={sortBy === column.id}
                direction={sortBy === column.id ? sortDirection : 'asc'}
                onClick={createSortHandler(column.id)}
                icon={{
                  color:
                    sortBy === column.id
                      ? 'rgba(0, 0, 0, 0.87)'
                      : 'rgba(0, 0, 0, 0.38)',
                }}
                IconComponent={sortBy === column.id ? NorthIcon : UnfoldMore}
              >
                {column.label}
                {sortBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortDirection === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={column.id}
              align={column.align}
              width={column.width}
              className={classes.root}
            >
              {column.label}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
  onRequestSort: PropTypes.func.isRequired,
}
