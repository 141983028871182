import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    borderBottom: '1px solid #E0E0E0',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  formStateContainer: {
    backgroundColor: 'white',
    minHeight: 'inherit',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    borderRadius: '4px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '1px',
      minHeight: 'calc(100vh - 57px)',
      borderRadius: '0px',
    },
  },
  formSectionHeader: {
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  formSectionSubheader: {
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: 700,
    },
  },
  successText: {
    fontWeight: '600',
  },
  button: {
    minWidth: '147px',
    margin: '0 .5rem',
  },
}))

export default useStyles
