import React, { useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useRouteMatch, useParams } from 'react-router-dom'
import PageHeader from './header-components/page-header'
import { formatNameForDisplay } from 'lib/text-display'
import { makeStyles } from '@material-ui/styles'
import DisplayContact from './details-components/display-contact'
import LeadDetails from './details-components/lead-details'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import { Container, Grid } from '@material-ui/core'
import { useGetLead } from '../api-hooks'
import NotchedOutlinedBox from './notched-outline-box'
import { ReactComponent as PGAIcon } from 'images/pga-seal-icon.svg'
import DeleteLead from './details-components/delete-lead'
import LeadActionButtons from './header-components/action-buttons'
import { coachingLeadViewedMutation } from '../mutations'
import ApolloDataView from 'components/apollo-data-view'
import LoadingView from '../loading-view'
import NotFound from 'components/not-found.js'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import Alert from '@material-ui/lab/Alert'

export const useStyles = makeStyles(theme => ({
  mobilePaper: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: '20px 20px 0 0',
    },
  },
  mobileTitleBar: {
    padding: '5px',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  pgaIcon: {
    height: '70px',
    width: '70px',
  },
  gridItem: {
    alignItems: 'stretch',
  },
}))

const LeadShowPage = ({ coachingInquiry, leadId }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const classes = useStyles()

  const { path } = useRouteMatch()

  const recommendationArray = [
    'When you respond to your leads ask questions to gain a deeper understanding of their needs.',
    'Make contact with your leads no later than 24 hours if possible to ensure the lead is in the same mindset.',
    'Not available or not a good fit? Let this lead know, and if possible offer a referral to a fellow member who might be able to help.',
    'Did you know one (1) lead can refer five (5) additional students by referring friends and family? Focus on building relationships.',
  ]

  const leadContact = coachingInquiry?.contact
  const leadName = `${leadContact?.firstName} ${leadContact?.lastName}`
  const leadTime = coachingInquiry?.createdAt
  const leadIntent = coachingInquiry?.intent

  const crumbs = [
    {
      label: 'Leads',
      to: `${path
        .split('/')
        .slice(0, 2)
        .join('/')}/students/leads`,
    },
  ]

  return (
    <>
      <PageHeader
        title={formatNameForDisplay(leadName)}
        crumbs={crumbs}
        isMobile={isMobile}
        actions={<LeadActionButtons contact={leadContact} leadId={leadId} />}
      />
      <Container style={{ paddingTop: '1.5em' }}>
        <Grid
          container
          spacing={isTablet ? 1 : 2}
          alignItems="stretch"
          direction={isTablet ? 'column' : 'row'}
          display="flex"
        >
          {leadContact.banned && (
            <Grid item className={classes.gridItem} xs={12}>
              <Alert severity="warning">
                This lead has been flagged as <i>potential</i> spam.
              </Alert>
            </Grid>
          )}
          <Grid item className={classes.gridItem} xs={12} md={6}>
            <DisplayContact contact={leadContact} />
          </Grid>
          <Grid item className={classes.gridItem} xs={12} md={6}>
            <LeadDetails timeCreatedAt={leadTime} intent={leadIntent} />
          </Grid>
        </Grid>
        <SensitiveInfoWrapper>
          <DeleteLead lead={coachingInquiry} />
        </SensitiveInfoWrapper>
        <NotchedOutlinedBox
          icon={<PGAIcon className={classes.pgaIcon} />}
          label="Tips from PGA"
          content={
            recommendationArray[
              Math.floor(Math.random() * recommendationArray.length)
            ]
          }
          bottomLink={{
            text: 'LEARN MORE',
            url:
              'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://pgacoach.lightspeedvt.com&dest=https://vt.lightspeedvt.com/trainingCenter/course/117834',
          }}
        />
      </Container>
    </>
  )
}

const LeadIndexPage = () => {
  const { leadId } = useParams()

  const { loading, data, error } = useGetLead(leadId)

  const [coachingLeadViewed] = useMutation(coachingLeadViewedMutation)
  const leadViewedMutation = useCallback(async () => {
    try {
      await coachingLeadViewed({
        variables: { id: leadId },
      })
    } catch (e) {
      console.error(e.message)
    }
  }, [leadId, coachingLeadViewed])

  useEffect(() => {
    leadViewedMutation()
    // eslint-disable-next-line
  }, [])

  return (
    <ApolloDataView
      error={error}
      loading={loading}
      data={data?.coachingInquiry}
    >
      {{
        errorView: (
          <NotFound
            subtext={'Lead was not found.'}
            returnUrl={'/pga-coach/students/leads'}
          />
        ),
        emptyView: (
          <NotFound
            subtext={'Lead was not found.'}
            returnUrl={'/pga-coach/students/leads'}
          />
        ),
        loadingView: <LoadingView />,
        dataView: coachingInquiry => (
          <LeadShowPage coachingInquiry={coachingInquiry} leadId={leadId} />
        ),
      }}
    </ApolloDataView>
  )
}

export default LeadIndexPage
