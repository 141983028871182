import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_COACH_GROUP_EVENTS } from './query'
import { DateTime } from 'luxon'
import Error from 'components/error'
import Typography from '@material-ui/core/Typography'
import EventsSlider from './events-slider'
import LinearProgress from '@material-ui/core/LinearProgress'
import pipe from 'lodash/fp/pipe'
import flatMap from 'lodash/fp/flatMap'
import uniqBy from 'lodash/fp/uniqBy'
import filter from 'lodash/fp/filter'
import GroupEventCard from 'components/group-event-card/group-event-card'

const filterGroupEventsByLocation = (groupEvents, selectedFacility) => {
  let filteredGE = []
  if (!groupEvents) return filteredGE
  const listedGE = groupEvents.filter(
    ge => !ge.unlisted && !ge.registrationClosed,
  )
  if (selectedFacility === 'all') return listedGE
  filteredGE = listedGE.filter(ge => ge.coachFacility.id === selectedFacility)
  return filteredGE
}

const CoachGroupEvents = ({
  coach,
  selectedFacility,
  setGroupEventsLocations,
  classes,
}) => {
  const { data, loading, error } = useQuery(GET_COACH_GROUP_EVENTS, {
    variables: {
      startDateTime: { from: DateTime.local().toISODate() },
      coachId: coach.id,
      includeCanceled: false,
    },
  })

  const groupEvents = data?.groupEvents
  const sortedAndFilteredGroupEvents = filterGroupEventsByLocation(
    groupEvents,
    selectedFacility,
  ).sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))

  useEffect(() => {
    const groupEventsLocations = pipe(
      filter(ge => !ge.unlisted),
      flatMap(ge => ge.coachFacility),
      uniqBy('id'),
    )(groupEvents)
    setGroupEventsLocations(groupEventsLocations)
  }, [groupEvents, setGroupEventsLocations])

  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />

  return sortedAndFilteredGroupEvents.length > 0 ? (
    <>
      <Typography variant="h4" className={classes.eventsTitle}>
        Upcoming Events
      </Typography>
      <EventsSlider
        events={sortedAndFilteredGroupEvents}
        renderEvent={event => (
          <GroupEventCard key={event.slug} event={event} coach={coach} />
        )}
      />
    </>
  ) : null
}

export default CoachGroupEvents
