import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, gql } from '@apollo/client'
import CoachProfileAnalyticsDisplay from './coach-profile-analytics-display'

const COACH_PROFILE_ANALYTICS = gql`
  query CoachProfileAnalytics(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    currentUser {
      coachProfileAnalytics(startDate: $startDate, endDate: $endDate) {
        impressions
        views
      }
    }
  }
`

const CoachProfileAnalytics = () => {
  const aMonthAgo = DateTime.local().minus({ months: 1 })
  const today = DateTime.local()

  const [startDate, setStartDate] = useState(aMonthAgo)
  const [endDate, setEndDate] = useState(today)

  const [analyticsWindow, setAnalyticsWindow] = React.useState('thisMonth')

  const { loading, error, data } = useQuery(COACH_PROFILE_ANALYTICS, {
    variables: {
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
    },
  })

  const analytics = !loading
    ? data?.currentUser?.coachProfileAnalytics || {}
    : {}
  return (
    <CoachProfileAnalyticsDisplay
      {...analytics}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      analyticsWindow={analyticsWindow}
      setAnalyticsWindow={setAnalyticsWindow}
      loading={loading}
      error={error}
    />
  )
}

CoachProfileAnalytics.propTypes = {}

export default CoachProfileAnalytics
