import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  timeContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    flexWrap: 'nowrap',
  },
  timeHeader: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      flexWrap: 'wrap',
    },
  },
  cardHeader: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'left',
      flexDirection: 'column',
    },
  },
  action: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '10px 0px 0px 10px',
    fontSize: '32px',
  },
  switcher: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  createBlockBtn: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  headerExplainerText: {
    paddingBottom: '16px',
  },
}))

export default useStyles
