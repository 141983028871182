import { gql } from '@apollo/client'

export const CREATE_GROUP_EVENT = gql`
  mutation CreateGroupEvent($groupEvent: GroupEventInput!) {
    createGroupEvent(groupEvent: $groupEvent) {
      success
      message
      groupEvent {
        id
        slug
      }
    }
  }
`

export const COACH_SCHEDULE_AVAILABILITY = gql`
  query coachScheduleAvailability($id: ID!, $timespan: DateTimeRangeInput!) {
    coach(id: $id) {
      scheduleAvailability(timespan: $timespan) {
        isAvailable
        warnings
      }
    }
  }
`

export const GROUP_EVENT_PAYMENT_OPTIONS = gql`
  query groupEventPaymentOptions($sessions: [SessionInput!]!) {
    groupEventPaymentOptions(sessions: $sessions) {
      name
      value
    }
  }
`

export const GROUP_EVENT_COST = gql`
  query groupEventCost(
    $priceInCents: Int!
    $paymentRecurrenceSchedule: PaymentRecurrenceSchedule!
    $sessions: [SessionInput!]!
  ) {
    groupEventCost(
      priceInCents: $priceInCents
      paymentRecurrenceSchedule: $paymentRecurrenceSchedule
      sessions: $sessions
    ) {
      __typename
      numberOfPayments
      totalPriceInCents
    }
  }
`
