import Typography from '@material-ui/core/Typography'
import React from 'react'
import useStyles from './styles'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Button from '@material-ui/core/Button'
import AccountRouterLink from 'components/account-router-link'

const NoPaymentAccounts = ({ errors }) => {
  const styles = useStyles()
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton className={styles.mainIcon}>
        <AttachMoneyIcon fontSize="large" />
      </IconButton>
      <Typography variant="subtitle1" gutterBottom>
        No Payment Account
      </Typography>
      <Typography
        variant="body2"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        You currently do not have a payment account set up.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        to="/account/payments/payment-accounts"
        component={AccountRouterLink}
        style={{ marginTop: '12px', marginBottom: '24px' }}
      >
        Add Payment Account
      </Button>
      {errors?.message && (
        <Box className={styles.warningWrapper}>
          It is required to have a payment account in order to offer lesson
          packs.
        </Box>
      )}
    </Box>
  )
}

export default NoPaymentAccounts
