import React from 'react'
import { Grid, Hidden } from '@material-ui/core'

export default function HalfMdGridSpacer() {
  return (
    <Hidden smDown>
      <Grid item md={6} />
    </Hidden>
  )
}
