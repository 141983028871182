import * as routes from 'lib/junior-league-utils/routes'

export const JRL_PROGRAM_CATEGORIES = {
  CHAMPIONSHIP_SEASON: 'CHAMPIONSHIP_SEASON',
  LOCAL_LEAGUE: 'LOCAL_LEAGUE',
  REGIONAL: 'REGIONAL',
  NATIONAL: 'NATIONAL',
}

export const JRL_PROGRAM_CATEGORY_LABELS = {
  [JRL_PROGRAM_CATEGORIES.CHAMPIONSHIP_SEASON]: 'Championship Season',
  [JRL_PROGRAM_CATEGORIES.LOCAL_LEAGUE]: 'Local League',
  [JRL_PROGRAM_CATEGORIES.REGIONAL]: 'Regional',
  [JRL_PROGRAM_CATEGORIES.NATIONAL]: 'National',
}

export const PROGRAM_CATEGORIES = {
  ...JRL_PROGRAM_CATEGORIES,
  FAMILY_CUP: 'FAMILY_CUP',
}

export const PROGRAM_CATEGORY_LABELS = {
  ...JRL_PROGRAM_CATEGORY_LABELS,
  [PROGRAM_CATEGORIES.FAMILY_CUP]: 'Family Golf',
}

export const PROGRAM_ROUTES = {
  [JRL_PROGRAM_CATEGORIES.LOCAL_LEAGUE]: {
    ...routes,
    program: routes.program,
    schedule: routes.programSchedule,
    editSchedule: routes.editSchedule,
    players: routes.programPlayers,
  },
  [JRL_PROGRAM_CATEGORIES.CHAMPIONSHIP_SEASON]: {
    ...routes,
    program: routes.champProgram,
    schedule: routes.champProgramSchedule,
    editSchedule: routes.champProgramSchedule,
    players: routes.champProgramPlayers,
  },
  [JRL_PROGRAM_CATEGORIES.REGIONAL]: {
    ...routes,
    program: routes.regionalChampionshipProgram,
    schedule: routes.regionalProgramSchedule,
    editSchedule: routes.regionalProgramSchedule,
    players: routes.programPlayers,
  },
  [JRL_PROGRAM_CATEGORIES.NATIONAL]: {
    ...routes,
    program: routes.nationalChampionshipProgram,
    schedule: routes.nationalProgramSchedule,
    editSchedule: routes.nationalProgramSchedule,
    players: routes.programPlayers,
  },
}
