import React, { useState, useMemo, useEffect, useRef } from 'react'
import { MY_PGA_COM_HOST, STRIPE_KEY } from 'env'
import { useSnackbar } from 'notistack'
import QRCode from 'qrcode.react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import useIconAccessor from 'components/lesson-type-category/icons'
import { makeStyles } from '@material-ui/core/styles'
import { getTimePeriodWithTimezone } from 'utils/dateUtils'
import Dialog from 'components/dialog'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import { centsToDollars } from 'lib/utils/number'
import propTypes from 'prop-types'
import { gtmEvent } from 'lib/gtm'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { formatCurrency } from 'pages/schedule/utils'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from 'pages/payment-request/components/payment-form'

import { useSendPaymentRequest } from 'lib/graphql/mutations/payment-request-mutation.js'
import { usePrepareRecordBookingPayment } from 'lib/graphql/mutations/prepare-record-booking-payment-mutation.js'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '485px',
    padding: 0,
  },
  timePeriodText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '16px',
    },
    color: '#000000de',
  },
  bodyText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
    color: '#0000008a',
  },
  iconWrapper: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    margin: '0 12px 0',
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
  componentSpacing: {
    marginTop: '1.25rem',
  },
  actions: {
    padding: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    marginTop: '.75rem',
    marginRight: '.75rem',

    [theme.breakpoints.down('xs')]: {
      top: '-4px',
      right: '-4px',
    },

    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',

      [theme.breakpoints.down('xs')]: {
        width: '20px',
        height: '20px',
      },
    },
  },
}))
const stripePromise = loadStripe(STRIPE_KEY)

const PaymentLink = ({
  timePeriod,
  title,
  student,
  price,
  classes,
  category,
}) => {
  const accessor = useIconAccessor()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      className={classes.componentSpacing}
    >
      <Avatar variant="circular" className={classes.iconWrapper}>
        {accessor[category].icon}
      </Avatar>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="h6" className={classes.timePeriodText}>
          {timePeriod}
        </Typography>
        <Typography
          variant="body1"
          className={classes.bodyText}
        >{`${title} with ${student?.firstName ||
          'Student'} ${student?.lastName || ''}`}</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          {formatCurrency(price)}
        </Typography>
      </Box>
    </Box>
  )
}
const QRCodeComponent = ({ classes, enrollmentUUID }) => {
  const timeStamp = new Date().toISOString().slice(0, 19)
  return (
    <Box
      display="flex"
      justifyContent="center"
      className={classes.componentSpacing}
      data-testid="payment-request-qr-code"
    >
      <QRCode
        value={`${MY_PGA_COM_HOST}/payment/${enrollmentUUID}?paymentRequestedDate=${timeStamp}`}
        size={178}
        level="H" // error correction level (H = high) for more squareness and less likely to be misread
      />
    </Box>
  )
}
const DialogCloseButton = ({ styles, closeDialog }) => (
  <IconButton
    aria-label="close"
    className={styles.closeButton}
    onClick={closeDialog}
  >
    <CloseIcon />
  </IconButton>
)

const RequestPaymentDialog = ({ trigger, enrollment, onPaymentSuccess }) => {
  const paymentFormRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sendPaymentRequest, { loading }] = useSendPaymentRequest()
  const [
    prepareRecordBookingPayment,
    { data, loading: pendingPaymentLoading },
  ] = usePrepareRecordBookingPayment()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedTab, setSelectedTab] = useState('link')
  const [clientSecret, setClientSecret] = useState('')
  useEffect(() => {
    if (enrollment) {
      prepareRecordBookingPayment({
        variables: {
          enrollmentId: enrollment.id,
        },
      })
    }
  }, [prepareRecordBookingPayment, enrollment])
  useEffect(() => {
    if (data?.prepareRecordBookingPayment?.success) {
      setClientSecret(
        data?.prepareRecordBookingPayment?.paymentIntent?.clientSecret,
      )
    }
  }, [data])

  const {
    category,
    id: enrollmentId,
    startDateTime,
    endDateTime,
    priceInCents = 0,
    student,
    timezone,
    title,
  } = enrollment
  const classes = useStyles()
  const timePeriod = getTimePeriodWithTimezone(
    startDateTime,
    endDateTime,
    timezone,
  )
  const price = centsToDollars(priceInCents)
  const paymentOptions = useMemo(() => {
    const appearance = {
      theme: 'stripe',
    }
    const options = {
      clientSecret,
      appearance,
    }

    return [
      {
        label: 'Payment Link',
        value: 'link',
        description:
          'Send a payment request to your student for this session. Once paid, both of you will receive a notification.',
        component: (
          <PaymentLink
            timePeriod={timePeriod}
            title={title}
            student={student}
            price={price}
            classes={classes}
            category={category}
          />
        ),
      },
      {
        label: 'QR Code',
        value: 'qrCode',
        description:
          "Have your student scan this QR code and enter their payment information. It's a quick and easy way to collect payments on the spot!",
        component: (
          <QRCodeComponent
            classes={classes}
            enrollmentUUID={enrollment.paymentRequestToken}
          />
        ),
      },
      {
        label: 'Credit Card',
        value: 'creditCard',
        description: 'Manually enter credit card details to collect payment.',
        component: (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              ref={paymentFormRef}
              enrollment={enrollment}
              clientSecret={clientSecret}
              redirectUrl={`${MY_PGA_COM_HOST}/payment/${enrollment.paymentRequestToken}/success`}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Elements>
        ),
      },
    ]
  }, [
    timePeriod,
    title,
    student,
    price,
    classes,
    category,
    enrollment,
    clientSecret,
    isSubmitting,
  ])

  const paymentTabs = () => {
    return (
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        style={{ borderBottom: '1px solid #e0e0e0' }}
      >
        {paymentOptions.map((option, index) => (
          <Tab
            label={option.label}
            value={option.value}
            key={index}
            data-testid={`payment-request-tab-${option.value}`}
          />
        ))}
      </Tabs>
    )
  }

  const renderPaymentSwitch = (tab = 'link') => {
    const option = paymentOptions.find(option => option.value === tab)
    return (
      <>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.componentSpacing}
        >
          {option.description}
        </Typography>
        {option.component}
      </>
    )
  }

  if (loading || pendingPaymentLoading) {
    return <CircularProgress color="primary" />
  }
  return (
    <Dialog trigger={trigger} maxWidth="sm" fullWidth>
      {({ closeDialog }) => {
        const onPaymentRequestConfirm = () => {
          sendPaymentRequest({ variables: { enrollmentId } }).then(
            ({ data }) => {
              if (data.sendPaymentRequest.success) {
                enqueueSnackbar('Payment request successfully sent', {
                  variant: 'success',
                })
                gtmEvent({
                  event: 'request-payment',
                  requestAction: 'request',
                  reqestEnrollment: enrollmentId,
                })
                closeDialog()
                onPaymentSuccess()
              } else {
                enqueueSnackbar(data.sendPaymentRequest.message, {
                  variant: 'error',
                })
              }
            },
          )
        }
        const handleSubmitStripe = async () => {
          await paymentFormRef.current.submitForm()
          gtmEvent({
            event: 'request-payment',
            requestAction: 'payment',
            reqestEnrollment: enrollmentId,
          })
          if (!isSubmitting) {
            closeDialog()
            // this needs to wait like .5 seconds to allow the Stripe webhook to fire
            setTimeout(() => {
              onPaymentSuccess()
            }, 500)
          }
        }

        return (
          <Container className={classes.dialogContent}>
            <DialogTitle>
              <Typography variant="h5">Collect Payment</Typography>
              <DialogCloseButton styles={classes} closeDialog={closeDialog} />
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              {paymentTabs()}
              <Container>{renderPaymentSwitch(selectedTab)}</Container>
              {loading ? <CircularProgress color="primary" /> : null}
            </DialogContent>
            {selectedTab === 'link' && (
              <DialogActions className={classes.actions}>
                <Button color="primary" onClick={closeDialog}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SendOutlinedIcon />}
                  onClick={onPaymentRequestConfirm}
                  data-testid="send-payment-request"
                >
                  Request
                </Button>
              </DialogActions>
            )}
            {selectedTab === 'creditCard' && clientSecret && (
              <DialogActions className={classes.actions}>
                <Button color="primary" onClick={closeDialog}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmitStripe}
                  disabled={isSubmitting}
                >
                  Process Payment
                </Button>
              </DialogActions>
            )}
          </Container>
        )
      }}
    </Dialog>
  )
}

export default RequestPaymentDialog

RequestPaymentDialog.propTypes = {
  trigger: propTypes.any,
  enrollment: propTypes.object,
  onPaymentSuccess: propTypes.func,
}
PaymentLink.propTypes = {
  timePeriod: propTypes.string,
  title: propTypes.string,
  student: propTypes.object,
  price: propTypes.number,
  classes: propTypes.object,
  category: propTypes.string,
}
QRCodeComponent.propTypes = {
  classes: propTypes.object,
  enrollmentUUID: propTypes.string,
}
