import { gql, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import GoBack from 'components/go-back'
import { MY_PGA_COM_HOST, PGA_COM_HOST, RECAPTCHA_V2_KEY } from 'env'
import { shuffle } from 'lodash'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import PgaMobileDatePicker from 'components/pga-time-picker/pga-mobile-date-picker'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
// @TODO: Remove once consolidate-lead-forms flag is permanently enabled being replaced by new lead form
const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .max(50)
    .required('First name is required')
    .label('First name'),
  lastName: Yup.string()
    .trim()
    .max(100)
    .required('Last name is required')
    .label('Last name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .max(100)
    .required('Email is required')
    .label('Email'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter a valid phone number')
    .label('Phone number'),
  date: Yup.date()
    .typeError('Please select a date')
    .required('Date is required')
    .label('Date'),
  timeOfDay: Yup.string().required('You must select a time of day'),
  interest: Yup.string().required('You must select an interest'),
})

const intentOptions = shuffle([
  'Help me shoot lower scores',
  'Fix a specific problem in my golf game',
  'Help my junior golfer improve',
  'Connect me with other golfers',
])

const introductionOptions = [
  'Introduce me to golf',
  'Introduce my child to golf',
  'Introduce my family to golf',
]

const insertIndex = Math.floor(Math.random() * intentOptions.length)

intentOptions.splice(insertIndex, 0, introductionOptions)

const RequestTimeForm = ({
  coach,
  showGoBack = true,
  goBackLabel = 'Coach Profile',
  goBackHandler,
  source,
}) => {
  const coachProfileUrl =
    source === 'MYPGA_SCHEDULE_CONTACT_BUTTON'
      ? `${MY_PGA_COM_HOST}/coach/${coach.coachProfile.slug}/schedule`
      : `${PGA_COM_HOST}/coach/${coach.coachProfile.slug}`

  const COACHING_LEAD_CREATE = gql`
    mutation coachingLeadCreate($params: CoachingLeadCreateRequest!) {
      CoachingLeadCreate(params: $params) {
        success
        message
      }
    }
  `

  const { enqueueSnackbar } = useSnackbar()
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const [coachingLeadCreate, { error: submitError }] = useMutation(
    COACHING_LEAD_CREATE,
    {
      context: { recaptchaToken },
      onError: () => {},
      onCompleted: (data, error) => {
        if (data.CoachingLeadCreate.success) {
          enqueueSnackbar(`${coach.name} received your request`, {
            variant: 'success',
          })
          window.location.href = coachProfileUrl
        } else {
          enqueueSnackbar(
            'We had trouble processing your request. Please try again.',
            { variant: 'error' },
          )
        }
      },
    },
  )

  const {
    register,
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async data => {
    const formattedDate = DateTime.fromJSDate(data.date).toISODate() // 2024-03-25
    coachingLeadCreate({
      variables: {
        params: {
          coachSlug: coach.coachProfile.slug,
          email: data.email,
          phoneNumber: `+1${data.phoneNumber.replace(/\D/g, '')}`,
          firstName: data.firstName,
          lastName: data.lastName,
          intent: `${formattedDate}/${data.timeOfDay}/${data.interest}`,
          source: source,
        },
      },
    })
  }

  return (
    <Container
      style={{ marginTop: '60px', paddingBottom: '32px' }}
      data-testid="request-time-form"
    >
      {showGoBack ? (
        <Grid container>
          <GoBack
            externalUrl={coachProfileUrl}
            label={goBackLabel}
            clickHandler={goBackHandler}
          />
        </Grid>
      ) : null}
      <Grid container justifyContent="center">
        <Grid item md={5}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={4}>
              <Typography variant="h4" align="center">
                Request Coaching
              </Typography>
            </Box>
            <Box>
              <Typography align="center">
                Complete the form below to share your interest in booking time
                with this PGA Coach.
              </Typography>
            </Box>

            <Box mt={6}>
              <Typography variant="subtitle1">Contact Information</Typography>
              <FormControl margin="normal">
                <TextField
                  name="firstName"
                  label="First Name"
                  error={errors.firstName}
                  helperText={errors.firstName?.message}
                  inputRef={register}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  name="lastName"
                  label="Last Name"
                  error={errors.lastName}
                  helperText={errors.lastName?.message}
                  inputRef={register}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  error={errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  inputRef={register}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  name="email"
                  label="Email"
                  error={errors.email}
                  helperText={errors.email?.message}
                  inputRef={register}
                />
              </FormControl>
            </Box>

            <Box mt={6}>
              <LocalizationProvider
                dateAdapter={CustomLuxonAdapter}
                dateFormats={{ normalDateWithWeekday: 'LLL d, yyyy' }} // Jul 1, 2021
              >
                <Typography variant="subtitle1">Availability</Typography>
                <FormControl margin="normal">
                  <Controller
                    control={control}
                    name="date"
                    defaultValue={null}
                    render={({ onChange, value }) => (
                      <PgaMobileDatePicker
                        label="Date"
                        id="start-date"
                        name="date"
                        innerRef={register}
                        value={value}
                        validationErrors={!!errors.date}
                        helperText={errors.date?.message}
                        format="MMM dd, yyyy"
                        onChange={onChange}
                        disablePast
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
              <FormControl margin="normal">
                <InputLabel htmlFor="select-time-of-day">
                  Time of Day
                </InputLabel>
                <Controller
                  defaultValue=""
                  name="timeOfDay"
                  control={control}
                  as={
                    <Select
                      name="timeOfDay"
                      label="Time of Day"
                      id="select-time-of-day"
                      value=""
                      defaultValue=""
                      error={errors.timeOfDay}
                    >
                      <MenuItem value="early morning">Early Morning</MenuItem>
                      <MenuItem value="late morning">Late Morning</MenuItem>
                      <MenuItem value="early afternoon">
                        Early Afternoon
                      </MenuItem>
                      <MenuItem value="late afternoon">Late Afternoon</MenuItem>
                    </Select>
                  }
                />
              </FormControl>
            </Box>

            <Box mt={6} mb={5}>
              <Typography variant="subtitle1">Interest</Typography>
              <FormControl margin="normal">
                <InputLabel htmlFor="select-interest">
                  Select Interest
                </InputLabel>
                <Controller
                  defaultValue=""
                  name="interest"
                  control={control}
                  as={
                    <Select
                      name="interest"
                      label="Select Interest"
                      id="select-interest"
                      value=""
                      defaultValue=""
                      error={errors.interest}
                    >
                      {intentOptions.flat().map(opt => (
                        <MenuItem key={opt} value={opt}>
                          {opt}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </FormControl>
              <FormControl margin="normal">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_V2_KEY}
                  onChange={token => setRecaptchaToken(token)}
                />
                {submitError && (
                  <FormHelperText error>
                    An error has occurred. Please try again later.
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting || !recaptchaToken}
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RequestTimeForm
