import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const fieldMapper = {
  firstName: 'first name',
  lastName: 'last name',
  bio: 'bio',
  prices: 'price',
  email: 'email',
  city: 'city',
  state: 'state',
  zipCode: 'zip code',
  facilities: 'facilities',
  experiences: 'experiences',
  services: 'services',
  skills: 'skills',
  customSlug: 'coach profile url',
}

const ErrorDisplay = ({ errors, touched }) => {
  const realErrorKeys = Object.keys(errors).filter(e => touched[e])
  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <div>
        {realErrorKeys.length > 0 && (
          <Typography variant="p" style={{ color: 'red' }}>
            Please make sure the following fields have data
          </Typography>
        )}
        {realErrorKeys.map(errorKey => (
          <li style={{ color: 'red' }}>{fieldMapper[errorKey]}</li>
        ))}
      </div>
    </div>
  )
}

ErrorDisplay.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
}

export default ErrorDisplay
