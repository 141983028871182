import React from 'react'
import { TextField } from '@material-ui/core'

function SignupTextField({ id, label, value, onChange, fullWidth, password }) {
  const type = password ? 'password' : 'text'
  return (
    <div>
      <TextField
        fullWidth={fullWidth}
        id={id}
        label={label}
        name={id}
        type={type}
        variant="outlined"
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default SignupTextField
