import React from 'react'
import LegalDoc from './legal-doc'

export default function JRLCoachWaiver() {
  return (
    <LegalDoc>
      I acknowledge and agree:
      <ol>
        <li>
          that participation in PGA Jr. League subjects me to the possibility of
          physical illness or injury (minimal, serious, catastrophic and/or
          death) including, without limitation, the risk of contracting a
          communicable disease or illness;
        </li>
        <li>
          that, by participating in PGA Jr. League and ancillary events, I am
          VOLUNTARILY ASSUMING FULL RESPONSIBILITY FOR ANY RISKS OF LOSS,
          PROPERTY DAMAGE OR PERSONAL INJURY, INCLUDING DEATH AND HEREBY
          COVENANT NOT TO SUE, and further HOLD HARMLESS, RELEASE, WAIVE, and
          DISCHARGE The Professional Golfers’ Association of America and all
          affiliated entities (including, but not limited to, host golf courses,
          and all PGA Jr. League sponsors) and each of their subsidiaries,
          trustees, officers, directors, staff, employees, volunteers and
          authorized representatives (“Releasees”) from any and all liability,
          claims, demands, losses, actions and causes of action whatsoever
          (including, but not limited to, the negligence of the Releasees)
          arising out of or related to (in whole or in part) any loss, damage,
          or injury (including, but not limited to, physical or mental injury,
          aggravation of any pre-existing illness or condition, permanent
          disability or death), that may be sustained by me, or to any property
          belonging to me, or otherwise, arising out of my participation in a
          PGA Jr. League or ancillary event and/or traveling to or from any a
          PGA Jr. League tournament or ancillary event;
        </li>
        <li>
          TO INDEMNIFY AND HOLD HARMLESS the Releasees from any loss, liability,
          damages, or costs, including, but not limited to, court costs and
          attorney's fees, that may result from my being allowed to participate
          in PGA Jr. League or ancillary events, including, but not limited to,
          any damage caused to hosting facilities or the property of such
          facilities; and
        </li>
        <li>that my participation in PGA Jr. League is voluntary.</li>
      </ol>
      Further, I hereby:
      <ol>
        <li>
          agree that prior to participating or allowing any member of my team to
          participate, I will inspect the facilities and equipment to be used,
          and if I believe anything is unsafe, I will immediately advise an
          official of such conditions(s) or, to the extent I am the official,
          prohibit participation until the unsafe facility or equipment is
          corrected;
        </li>
        <li>
          grant full permission to each of the Releasees, with no obligation to
          compensate me, to use my name, voice, statements, image, likeness,
          and/or actions, in addition to any photographs, videotapes, motion
          pictures, recordings, and/or other record of performance(s) in PGA
          Jr.League in perpetuity worldwide in any manner (as determined by the
          Releasees) and in any and all media, whether now known or unknown or
          hereafter devised, and represent that I have the right to grant such
          permission;
        </li>
        <li>
          acknowledge that the Released Entities are relying on the grant of
          rights contained herein; and
        </li>
        <li>
          agree that I will abide by the rules and regulations of PGA Jr.
          League, as such are established by The Professional Golfers’
          Association of America.
        </li>
      </ol>
      It is my express intent that this Release and Waiver shall bind the
      members of my family, heirs, assigns and personal representative(s). I
      further agree that this Release and Waiver shall be construed in
      accordance with the laws of the State of Florida. I hereby warrant that I
      have read this Release and Waiver in its entirety and fully understand its
      contents. I am aware that this Release and Waiver releases Releasees from
      liability and contains an acknowledgement of my voluntary and knowing
      assumption of the risk of injury or illness. I have signed this document
      voluntarily and of my own free will.
    </LegalDoc>
  )
}
