import { useContext } from 'react'
import { roles } from './constants'
import AuthContext from 'lib/auth/AuthContext'

const adminRoles = [
  roles.ADMIN,
  roles.SECTION_STAFF,
  roles.PEC,
  roles.SUPER_USER,
]

const useAuth = () => {
  const auth = useContext(AuthContext)
  if (!auth) return {}

  return {
    isLoggedIn: auth.isLoggedIn,
    user: auth.user,
    isConsumer: auth.user?.hasRole(roles.CONSUMER) || false,
    isCoach: auth.user?.hasRole(roles.COACH) || false,
    isAdmin: auth.user?.hasRole(adminRoles) || false,
    login: auth.login,
    logout: auth.logout,
    refreshToken: auth.refreshToken,
    setUserProperties: auth.setUserProperties,
  }
}

export default useAuth
