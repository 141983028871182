import { makeStyles } from '@material-ui/core/styles'

export const useIosStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: ({ top }) => top || 60,
    width: '100%',
    background: '#f2f2f2',
    zIndex: 10000,
  },
  appIcon: {
    width: 60,
    height: 60,
    borderRadius: '5px',
    margin: '10px 5px 10px 16px',
  },
  titleText: {
    color: '#4d4d4d',
    fontWeight: 500,
    fontSize: '14px',
  },
  subtitleText: {
    color: '#6a6a6a',
    fontSize: '12px',
    fontWeight: 300,
  },
  ctaButton: {
    color: '#0c71fd',
    marginRight: '16px',
  },
  dismissIcon: {
    cursor: 'pointer',
    marginLeft: '16px',
  },
}))

export const useAndroidStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: ({ top }) => top || 60,
    width: '100%',
    background:
      '#3d3d3d url("data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7")',
    boxShadow: 'inset 0 4px 0 #88b131',
    zIndex: 10000,
  },
  appIcon: {
    width: 60,
    height: 60,
    borderRadius: '5px',
    margin: '10px 5px 10px 16px',
  },
  titleText: {
    color: 'white',
    fontWeight: 700,
    fontSize: '14px',
  },
  subtitleText: {
    color: '#ccc',
    fontSize: '12px',
    fontWeight: 700,
    textShadow: '0 1px 2px #000',
  },
  ctaButton: {
    background: 'linear-gradient(180deg,#42b6c9,#39a9bb)',
    borderRadius: '0',
    boxShadow: '0 0 1px #333, 0 0 0 2px ',
    color: '#d1d1d1',
    textTransform: 'none',
    marginRight: '16px',
  },
  dismissIcon: {
    cursor: 'pointer',
    color: 'white',
    marginRight: '16px',
  },
}))
