import React from 'react'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

export default function RouterLinkButton({ children, ...props }) {
  return (
    <Button {...props} component={RouterLink}>
      {children}
    </Button>
  )
}
