import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Typography from '@material-ui/core/Typography'
import CachedIcon from '@material-ui/icons/Cached'
import { SuggestedSlugContainer } from '../profile.styles'

const SuggestedSlug = ({ onShuffle, onSubmit, templateHandlerResult }) => {
  return (
    <SuggestedSlugContainer>
      <Typography variant="body2">
        Quick Suggestion{' '}
        <span style={{ fontSize: '.75em' }}>(or enter your own above)</span>
      </Typography>
      <Box className="innerContainer">
        <Box display="flex" className="suggestedItem">
          <Typography style={{ fontWeight: 'bold' }}>
            {templateHandlerResult}
          </Typography>
        </Box>
        <ButtonGroup
          variant="outlined"
          color="secondary"
          aria-label="split button"
        >
          <Button
            onClick={onSubmit}
            size="small"
            style={{ margin: 0, height: '30px', padding: '0 20px' }}
          >
            Use
          </Button>
          <Button
            color="secondary"
            onClick={onShuffle}
            size="small"
            style={{ margin: 0, height: '30px', padding: '0 20px' }}
          >
            <CachedIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </SuggestedSlugContainer>
  )
}

SuggestedSlug.propTypes = {
  onShuffle: PropTypes.func,
  onSubmit: PropTypes.func,
  templateHandlerResult: PropTypes.string,
}

export default SuggestedSlug
