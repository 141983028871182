import { Box, Card, Typography, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 12px',
    marginTop: '1rem',
    minHeight: '96px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  infoBox: {
    display: 'flex',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    marginLeft: '16px',
  },
  icon: {
    height: '64px',
    width: '64px',
    backgroundColor: 'rgba(224, 224, 224, 0.35)',
  },
  primary: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
  },
  secondary: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chevron: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))

export default function IndexCard({ title, value, icon, onClick }) {
  const classes = useStyles()

  return (
    <Card
      variant="outlined"
      className={classes.container}
      onClick={() => onClick(value)}
    >
      <Box className={classes.infoBox}>
        <Avatar variant="rounded" className={classes.icon}>
          {icon}
        </Avatar>
        <Box className={classes.textBox}>
          <Typography className={classes.primary}>{title}</Typography>
          <Typography className={classes.secondary}>View {title}</Typography>
        </Box>
      </Box>
      <KeyboardArrowRight className={classes.chevron} />
    </Card>
  )
}
