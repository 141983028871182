import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

const Waitlist = ({ contacts }) => {
  const classes = useStyles()

  return (
    <Box className={classes.studentContent} width="100%">
      {contacts.map(c => {
        const { id, firstName, lastName, email } = c.contact
        const contactInitials = `${firstName.substring(
          0,
          1,
        )}${lastName.substring(0, 1)}`
        return (
          <Box display="flex" key={id}>
            <Avatar className={classes.avatarWrapper}>{contactInitials}</Avatar>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mb={2}
            >
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {firstName} {lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {email}
              </Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

Waitlist.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contact: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    }),
  ),
}

export default Waitlist
