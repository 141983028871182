import ContentSection from 'components/content-section'
import { formatNameForDisplay } from 'lib/text-display'
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import {
  PersonOutline,
  LocalPhoneOutlined,
  FileCopyOutlined,
  SupervisorAccountOutlined,
} from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { useSnackbarSuccess } from 'lib/snackbar'
import { useStyles } from './styles'

export default function EmergencyInformation({ student = {} }) {
  const { pgaHopeEmergencyContact } = student
  const emergencyContactName = pgaHopeEmergencyContact
    ? formatNameForDisplay(
      `${pgaHopeEmergencyContact.firstName} ${pgaHopeEmergencyContact.lastName}`,
    )
    : 'N/A'

  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)

  const copyPhoneToClipboard = async event => {
    event.stopPropagation()
    navigator.clipboard.writeText(pgaHopeEmergencyContact?.phone)
    snackbarSuccess('Successfully copied to clipboard.')
  }

  return (
    <ContentSection title="Emergency Information">
      <List className={classes.container}>
        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <PersonOutline className={classes.icon} />
              </ListItemAvatar>
              <ListItemText primary={emergencyContactName} />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <LocalPhoneOutlined className={classes.icon} />
              </ListItemAvatar>
              {pgaHopeEmergencyContact?.phone ? (
                <>
                  <a
                    href={`tel:${pgaHopeEmergencyContact?.phone}`}
                    target="_top"
                    className={classes.linkText}
                  >
                    <ListItemText primary={pgaHopeEmergencyContact?.phone} />
                  </a>
                  <ListItemAvatar>
                    <FileCopyOutlined
                      className={classes.copyIcon}
                      onClick={copyPhoneToClipboard}
                    />
                  </ListItemAvatar>
                </>
              ) : (
                <ListItemText primary="N/A" />
              )}
            </ListItem>
          </List>
        </ListItem>

        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar>
                <SupervisorAccountOutlined className={classes.icon} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  formatNameForDisplay(pgaHopeEmergencyContact?.relationship) ||
                  'N/A'
                }
              />
            </ListItem>
          </List>
        </ListItem>
      </List>
    </ContentSection>
  )
}
