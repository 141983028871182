import PlusJakartaDisplayBold from './PlusJakartaDisplay-Bold.ttf'
import PlusJakartaTextBold from './PlusJakartaText-Bold.ttf'
import PlusJakartaTextBoldItalic from './PlusJakartaText-BoldItalic.ttf'
import PlusJakartaTextItalic from './PlusJakartaText-Italic.ttf'
import PlusJakartaTextRegular from './PlusJakartaText-Regular.ttf'

const jakartaDisplayBold = {
  fontFamily: 'PlusJakarta Display',
  src: `url(${PlusJakartaDisplayBold}) format('truetype')`,
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'auto',
}

const jakartaTextBold = {
  fontFamily: 'PlusJakarta Text',
  src: `url(${PlusJakartaTextBold}) format('truetype')`,
  fontWeight: 700,
  fontStyle: 'normal',
  fontDisplay: 'auto',
}
const jakartaTextBoldItalic = {
  fontFamily: 'PlusJakarta Text',
  src: `url(${PlusJakartaTextBoldItalic}) format('truetype')`,
  fontWeight: 700,
  fontStyle: 'italic',
  fontDisplay: 'auto',
}
const jakartaTextItalic = {
  fontFamily: 'PlusJakarta Text',
  src: `url(${PlusJakartaTextItalic}) format('truetype')`,
  fontWeight: 400,
  fontStyle: 'italic',
  fontDisplay: 'auto',
}

const jakartaTextRegular = {
  fontFamily: 'PlusJakarta Text',
  src: `url(${PlusJakartaTextRegular}) format('truetype')`,
  fontWeight: 400,
  fontStyle: 'normal',
  fontDisplay: 'auto',
}

export default [
  jakartaDisplayBold,
  jakartaTextBold,
  jakartaTextBoldItalic,
  jakartaTextItalic,
  jakartaTextRegular,
]
