import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { PageHeader } from 'components/page-header'
import BlockTimeManager from './block-time-manager'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(7),
    [theme.breakpoints.only('xs')]: {
      margin: '16px 16px 96px 16px',
    },
  },
}))

const BlockedTimesPage = () => {
  const classes = useStyles()
  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'Settings',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
  ]
  return (
    <>
      <PageHeader title="Blocked Times" crumbs={crumbs} />
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5">
              <Box fontWeight={500}>Blocked times</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Override your availability by blocking busy times on your
                calendar.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <BlockTimeManager />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BlockedTimesPage
