import { useEffect } from 'react'
import { withAuthenticatedRoute } from 'lib/auth'
import { CCLogo } from './styles'
import CoachingCenterLogo from 'images/pga-cc-logo.svg'
import CoachAppBar from 'components/coach-app-bar'
import Container from '@mui/material/Container'
import { styled } from '@material-ui/core/styles'
import { RulesAndRegulations } from './constants'

const RulesWrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  padding: '40px 40px 40px 24px',
  [theme.breakpoints.down('xs')]: {
    padding: '0px 24px 0px 8px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  '& li': {
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Roboto',
    letterSpacing: '0.15px',
  },
}))

const OuterWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fafafa',
  [theme.breakpoints.down('xs')]: {
    backgroundColor: 'white',
  },
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.only('xs')]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const StyledHeader = styled('p')(({ theme }) => ({
  fontWeight: 700,
  fontFamily: 'Source Serif Pro',
  fontSize: '24px',
  margin: '32px 0px 16px',
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px',
    margin: '16px 0px 8px',
  },
}))
const ReservationRules = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <CoachAppBar position="relative" hideContactButton />
      <OuterWrapper>
        <StyledContainer
          disableGutters
          maxWidth="sm"
          sx={{
            '&.MuiContainer-maxWidthSm': {
              maxWidth: '610px',
            },
          }}
        >
          <RulesWrapper>
            <CCLogo src={CoachingCenterLogo} alt="pga-coaching-center-logo" />
            <StyledHeader variant="h5">
              Bay Rentals Rules & Regulations
            </StyledHeader>
            <ol>
              {RulesAndRegulations.map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ol>
          </RulesWrapper>
        </StyledContainer>
      </OuterWrapper>
    </>
  )
}

export default withAuthenticatedRoute(ReservationRules)
