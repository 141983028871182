import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers'
import LoadingButton from 'components/loading-button'
import { useSnackbar } from 'notistack'
import { redirectToLogin, useAuth } from 'lib/auth'
import { PGA_COM_HOST } from 'env'
import RequestAccessCode from '../request-access-code'
import { gtmEvent } from 'lib/gtm'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    fontWeight: '700',
    color: theme.palette.primary.main,
  },
}))

const validationSchema = yup.object().shape({
  accessCode: yup
    .string()
    .required('Access Code is a required field')
    .matches(/^[a-z0-9]+$/i, {
      message: 'Codes must consist only of numbers and letters with no spaces',
    }),
})

const VERIFY_ACCESS_CODE = gql`
  mutation verifyAccessCode(
    $lessonTypeId: ID
    $groupEventId: ID
    $accessCode: String!
  ) {
    verifyAccessCode(
      lessonTypeId: $lessonTypeId
      groupEventId: $groupEventId
      accessCode: $accessCode
    ) {
      success
    }
  }
`

const VerifyAccessCode = ({
  lessonType,
  groupEvent,
  isOpen,
  setIsOpen,
  coachName,
  setVerifiedAccessCode,
  canRequestAccessCode = true,
}) => {
  const classes = useStyles()
  const { idOrSlug } = useParams()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { isLoggedIn } = useAuth()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  })
  const [isRequestAccessCodeOpen, setIsRequestAccessCodeOpen] = useState(false)

  const dialogText = canRequestAccessCode
    ? `This offering requires an access code provided by ${coachName}. If you don't have the code, select the button to request it below.`
    : `This offering requires an access code provided by ${coachName}.`

  const [verifyAccessCode, { loading }] = useMutation(VERIFY_ACCESS_CODE, {
    onCompleted: data => {
      if (data.verifyAccessCode.success) {
        if (groupEvent) {
          gtmEvent({
            event: 'apply-access-code',
            status: 'pass',
            title: groupEvent?.title,
          })
        }
        setIsOpen(false)
        setVerifiedAccessCode(true)
      } else {
        gtmEvent({
          event: 'apply-access-code',
          status: 'fail',
          title: groupEvent?.title,
        })
        enqueueSnackbar('Incorrect Access Code', { variant: 'error' })
      }
    },
    onError: error => {
      window.rg4js &&
        window.rg4js('send', {
          error: error,
          tags: ['verify-access-code'],
        })
      enqueueSnackbar('An error has occurred. Please try again later.', {
        variant: 'error',
      })
    },
  })

  const onSubmit = val => {
    verifyAccessCode({
      variables: {
        lessonTypeId: lessonType?.id,
        groupEventId: groupEvent?.id,
        accessCode: val.accessCode,
      },
    })
  }

  const handleRequestCodeClick = async () => {
    if (groupEvent) {
      gtmEvent({ event: 'request-access-code', title: groupEvent?.title })
    }
    if (!isLoggedIn) {
      const returnTo = groupEvent
        ? window.location.href
        : window.location.href + `?lessonTypeId=${lessonType?.id}`
      redirectToLogin('consumer', returnTo)
      return
    }
    setIsRequestAccessCodeOpen(true)
  }

  const closeAccessCodeDialog = () => {
    if (window.location.pathname.includes('coaching-center')) {
      setIsOpen(false)
    } else {
      if (lessonType) {
        window.location.search && history.replace(`/coach/${idOrSlug}/schedule`)
        setIsOpen(false)
      } else if (groupEvent) {
        const thingsToDoUrl =
          PGA_COM_HOST + window.location.pathname.replace('/register', '')
        window.location.href = thingsToDoUrl
      }
    }
  }

  const verifyAccessCodeContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m={3}>
          <DialogTitle>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography variant="h5" className={classes.title}>
                Access Code Required
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ textAlign: 'center' }}
            >
              {dialogText}
            </Typography>
          </DialogContent>
          <DialogActions style={{ flexDirection: 'column' }}>
            <Box display="flex" width="100%" mb={5} alignItems="center">
              <TextField
                label="Access Code"
                variant="outlined"
                style={{ paddingRight: '8px' }}
                name="accessCode"
                id="accessCode"
                error={!!errors.accessCode}
                helperText={errors?.accessCode?.message}
                inputRef={register}
              />
              <LoadingButton
                color="primary"
                variant="contained"
                buttonSize="large"
                loading={loading}
                type="submit"
              >
                Apply
              </LoadingButton>
            </Box>
            {canRequestAccessCode && (
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<LockOutlinedIcon />}
                  onClick={handleRequestCodeClick}
                >
                  Request Code
                </Button>
              </Box>
            )}
          </DialogActions>
        </Box>
      </form>
    )
  }

  return (
    <>
      <Dialog
        maxWidth="xs"
        showCloseButton
        fullScreen={isMobile}
        isOpen={isOpen}
        onClose={closeAccessCodeDialog}
      >
        {() => verifyAccessCodeContent()}
      </Dialog>
      <RequestAccessCode
        isOpen={isRequestAccessCodeOpen}
        setIsOpen={setIsRequestAccessCodeOpen}
        coachName={coachName}
        coachExternalId={
          groupEvent
            ? groupEvent?.coach?.externalId
            : lessonType?.coach?.externalId
        }
        bookingUrl={window.location.pathname + window.location.search}
      />
    </>
  )
}

export default VerifyAccessCode
