import React from 'react'
import Grid from '@material-ui/core/Grid'
import StagesCard from 'components/stages-card'
import { connectHits } from 'react-instantsearch-dom'
import { titleToURL } from '../../lib/transformStage'
import { useRouteMatch } from 'react-router-dom'

const cardPath = (hit, path) => {
  const prefix = path.startsWith('/pga-coach')
    ? '/pga-coach/activity-plans/'
    : '/activity-plans/'

  return `${prefix}${titleToURL(hit.stages[0]).name}/activities/${hit.objectID}`
}

const Hits = ({ hits }) => {
  const { path } = useRouteMatch()
  return (
    <Grid container spacing={2}>
      {hits.map(hit => (
        <Grid item lg={3} md={6} xs={12} key={hit.objectID}>
          <StagesCard
            image={hit.details.imageUrl}
            title={hit.details.shortTitle}
            subtitle={hit.details.summary}
            url={cardPath(hit, path)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const ActivityCards = connectHits(Hits)

export default ActivityCards
