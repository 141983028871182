import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'

function MaskedInputContainer({
  id,
  className,
  value,
  onChange,
  label,
  name,
  inputComponent,
  inputRef,
  helperText,
  autoComplete,
  startAdornment,
  defaultValue,
  disabled = false,
  readOnly = false,
  required = false,
  error = false,
}) {
  return (
    <FormControl className={className}>
      <InputLabel
        variant="outlined"
        htmlFor={id}
        required={required}
        error={error}
        disabled={disabled}
        readOnly={readOnly}
      >
        {label}
      </InputLabel>

      <OutlinedInput
        value={value}
        onChange={onChange}
        variant="contained"
        name={name}
        data-cy={name}
        label={label}
        id={id}
        inputComponent={inputComponent}
        inputRef={inputRef}
        required={required}
        error={error}
        inputProps={{ autoComplete }}
        startAdornment={startAdornment}
        defaultValue={defaultValue}
        disabled={disabled}
        readOnly={readOnly}
      />

      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default MaskedInputContainer
