import { useState } from 'react'
import uploadImage from './uploadImage'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET } from 'env'

const defaultOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET,
  cropping: true,
  sources: ['local'],
  multiple: false,
  croppingAspectRatio: 1,
  resourceType: 'image',
  showSkipCropButton: false,
  folder: 'dot-com-images',
  showPoweredBy: false,
}

const CloudinaryImageUpload = ({ render, options = {}, onUpload }) => {
  const [uploadError, setUploadError] = useState(null)

  const openUploadDialog = async () => {
    try {
      setUploadError(null)
      const newImageUrl = await uploadImage({
        ...defaultOptions,
        ...options,
      })
      if (newImageUrl) {
        onUpload(newImageUrl)
      }
    } catch (e) {
      setUploadError(e.statusText)
    }
  }

  return render({ openUploadDialog, uploadError })
}

export default CloudinaryImageUpload
