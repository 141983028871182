import {
  ContentSection,
  ListText,
} from '../../student-show-page/details-components/content-box'
import {
  List,
  ListItem,
  Grid,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import { useStyles } from '../styles'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Chip from '@material-ui/core/Chip'

export default function LeadDetails({ timeCreatedAt = 0, intent = '' }) {
  const classes = useStyles()
  const intentArray = intent.split(',')

  return (
    <ContentSection
      style={{ margin: 0, height: '100%', boxSizing: 'border-box' }}
      title="Lead Details"
    >
      <List className={classes.container}>
        <ListItem className={classes.column}>
          <List className={classes.column}>
            <ListItem>
              <ListItemAvatar className={classes.iconWrapper}>
                <EventOutlinedIcon className={classes.icon} />
              </ListItemAvatar>
              <ListText
                text={`Lead created ${formatDistanceToNow(
                  Date.parse(timeCreatedAt),
                )} ago`}
              />
            </ListItem>
            {intent && (
              <ListItem>
                <ListItemAvatar className={classes.iconWrapper}>
                  <InfoOutlinedIcon className={classes.icon} />
                </ListItemAvatar>
                <ListItemText>
                  <Grid container direction="row" spacing={1}>
                    {intentArray?.map(intent => (
                      <Grid item xs={8}>
                        <Chip className={classes.chip} label={intent} />
                      </Grid>
                    ))}
                  </Grid>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </ListItem>
      </List>
    </ContentSection>
  )
}
