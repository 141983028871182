import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),

    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },

  titleContainer: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      border: '0 none',
      paddingLeft: theme.spacing(3),
    },
  },
  title: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
    '& :last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      '& > *': {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },

      '& :first-child': {
        marginBottom: 0,
      },
    },
  },
  dropdown: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))
