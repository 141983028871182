import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withAuthenticatedRoute } from 'lib/auth'
import ContentUnavailable from 'components/content-unavailable'
import Error from 'components/error'
import AppBarRefreshed from './components/app-bar'
import PgaHopeRegistrationForm from './pga-hope-group-event/pga-hope-registration-form'
import { GET_GROUP_EVENT } from './queries'
import RegistrationFull from 'components/group-event-card/registration-full/registration-full'
import { ThemeProvider } from '@material-ui/core'
import themes, { extendTheme } from 'themes'
import ExternalRedirect from 'components/external-redirect'
import GroupEventRegistrationForm from './components/group-event-registration-form'
import { Helmet } from 'react-helmet'

const renderPageContent = (
  loading,
  error,
  groupEvent,
  isMobile,
  isPgaHopeEvent,
) => {
  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />
  if (!groupEvent) return <Error statusCode={404} />
  if (!groupEvent.coach.pgaCoachEligible) return <ContentUnavailable />
  if (groupEvent.registrationFull) {
    return (
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        <RegistrationFull />
      </ThemeProvider>
    )
  }
  const { title } = groupEvent
  const pageTitle = `PGA Coach | Schedule Session${title ? ` | ${title}` : ''}`
  const { description = 'PGA Golf Event Registration' } = groupEvent
  return (
    <Container disableGutters>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      {isPgaHopeEvent ? (
        <PgaHopeRegistrationForm groupEvent={groupEvent} isMobile={isMobile} />
      ) : (
        <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
          <GroupEventRegistrationForm
            groupEvent={groupEvent}
            isMobile={isMobile}
          />
        </ThemeProvider>
      )}
    </Container>
  )
}

const GroupEventRegistrationPage = () => {
  const { slug } = useParams()
  const { data, loading, error } = useQuery(GET_GROUP_EVENT, {
    variables: { slug },
  })
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const groupEvent = data?.groupEvents?.[0]
  const isPgaHopeEvent = groupEvent?.tags.includes('PGA HOPE')
  const hasExternalRegistration = groupEvent?.externalRegistrationUrl

  if (!loading && hasExternalRegistration) {
    return <ExternalRedirect externalUrl={hasExternalRegistration} />
  }

  return (
    <Box bgcolor={!isMobile && `${'grey.100'}`} style={{ minHeight: '100vh' }}>
      <AppBarRefreshed />
      {renderPageContent(loading, error, groupEvent, isMobile, isPgaHopeEvent)}
    </Box>
  )
}

export default withAuthenticatedRoute(GroupEventRegistrationPage)
