import { makeStyles, styled } from '@material-ui/core/styles'
import { Container, Grid, Typography, Box } from '@material-ui/core'

export const SectionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1.25rem',
  fontFamily: 'Roboto',
  lineHeight: '2rem',
  letterSpacing: '0.15px',
  borderRadius: '4px 4px 0px 0px',
  width: '100%',
  borderBottom: '1px solid #E0E0E0',
  alignItems: 'center',
  padding: '1.5rem 2rem',
  [theme.breakpoints.down('sm')]: {
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.25rem',
    letterSpacing: '.1px',
    padding: ' 1rem 1.75rem .5rem',
    borderBottom: 'none',
  },
}))
export const SectionContainer = styled(Container)(({ theme }) => ({
  maxWidth: '1280px',
  width: '100%',
  paddingBottom: '3rem',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0',
    paddingRight: '0',
  },
}))
export const ComponentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #E0E0E0',
  borderRadius: '4px',
  marginBottom: '3rem',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '1.5rem',
  },
  [theme.breakpoints.down('xs')]: {
    marginBottom: '.75rem',
  },
}))
export const HelperText = styled(Typography)(({ theme }) => ({
  margin: '.5rem 1rem .75rem 2.25rem',
  color: 'rgba(0, 0, 0, 0.54)',
  lineHeight: '21px',
  letterSpacing: '0.1px',
  [theme.breakpoints.down('sm')]: {
    margin: '0 1.5rem .25rem',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
}))

export const RadioHelperText = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  lineHeight: '21px',
  letterSpacing: '0.1px',
}))

export const FormFields = styled(Grid)(({ theme }) => ({
  margin: '1.25rem 1.5rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    margin: '0',
  },
}))
export const RadioFormFields = styled(Grid)(({ theme }) => ({
  margin: '1.25rem 2rem',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    margin: '.75rem 1rem 0',
  },
}))

export const FormHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem 0 2.5rem',
})

export const StyledTypography = styled(Typography)({
  fontWeight: '500',
  fontFamily: 'Roboto',
  fontSize: '2.125rem',
  lineHeight: '42px',
  color: '#000000',
})

export const useStyles = makeStyles(theme => ({
  actionButton: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    letterSpacing: '0.3px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    width: '186px',
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.9,
    },
  },
  controls: {
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 4,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#e9f5fe',
    padding: `${theme.spacing(2.5)}px ${theme.spacing(6)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2.5)}px 1rem`,
    },
  },
  controlsWrapper: {
    position: 'sticky',
    width: '100%',
    top: '0px',
    zIndex: 4,
  },
  completedOvveride: {
    color: 'grey !important',
    fontWeight: '400 !important',
    fontFamily: 'Roboto !important',
  },
  container: {
    zIndex: 4,
    height: 'fit-content',
    borderTop: `.25rem solid ${theme.palette.primary.main}`,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
  },
  homeCourse: {
    marginBottom: '.75rem',
  },
  innerControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#e9f5fe',
    boxSizing: 'border-box',
  },
  navigationContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '80%',
    margin: '0 auto',
  },
  radioButtons: {
    marginBottom: '.5rem',
    marginLeft: '.25rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1.5rem',
    },
  },
  proficiencyLabel: {
    marginLeft: '.5rem',
    marginTop: '.25rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
    },
  },
  proficiencySupportLabel: {
    marginLeft: '.5rem',
    color: theme.palette.text.secondary,
  },
  radioLabel: {
    marginLeft: '.5rem',
  },
  selfDescription: {
    marginBottom: '.5rem',
  },
  stepper: {
    width: '100%',
    paddingTop: '.5rem',
  },
  successSeparation: {
    marginTop: '1.5rem',
  },
}))
