import React from 'react'
import Box from '@material-ui/core/Box'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Fab from '@material-ui/core/Fab'
import FacebookIcon from '@material-ui/icons/Facebook'
import Grid from '@material-ui/core/Grid'
import InstagramIcon from '@material-ui/icons/Instagram'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
// import YouTubeIcon from '@material-ui/icons/YouTube'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { HashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom'
import useIsMobile from 'components/program-marketing/useIsMobile'
import { footerMenus, urls } from './menus'
import { XIcon } from 'components/x-share-button/x-icon'
import PGALogo from 'images/pga-logo-white.png'

const useStyles = makeStyles(theme => ({
  loginButtonArrow: {
    width: '24px',
    height: '24px',
    color: '#222222',
  },
  link: {
    opacity: 0.8,
    color: theme.palette.primary.contrastText,
    display: 'block',
    fontWeight: 700,
    marginBottom: '12px',
    marginRight: '48px',
  },
  linkWhite: {
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    display: 'block',
    fontWeight: 700,
    marginRight: '24px',
  },
  socialIcon: {
    opacity: 0.5,
    color: theme.palette.primary.contrastText,
    marginLeft: '9px',
    marginRight: '9px',
  },
  footerSectionBg: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.primary.contrastText,
    paddingTop: '86px',
  },
  grid: {
    alignItems: 'start',
    gridColumnGap: '48px',
    gridRowGap: '0px',
    gridTemplateRows: 'auto',
  },
  gridItem: {
    gridColumnGap: '0px',
    gridRowGap: '6px',
    gridTemplateColumns: '1fr',
  },
  fab: {
    height: '40px',
    width: '40px',
    background: '#ff7500',
    boxShadow: 'none',
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'relative',
    float: 'right',
  },
}))

const MenuLink = ({ href, children }) => {
  const classes = useStyles()
  return (
    <Link href={href} className={classes.link}>
      {children}
    </Link>
  )
}

const Footer = () => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const { search } = useLocation()

  return (
    <Box px={isMobile ? 0 : 4} py={1} className={classes.footerSectionBg}>
      <Box mx={isMobile ? 4 : 5}>
        <Grid container justify="center">
          <Grid item sm={12}>
            <Grid
              container
              spacing={6}
              justify={isMobile ? 'center' : 'space-between'}
              direction={isMobile ? 'column' : 'row'}
            >
              <Grid item sm={12} md={4}>
                <Box mb={2}>
                  <a href="https://www.pga.com">
                    <img
                      src={PGALogo}
                      alt="PGA Logo"
                      style={{ width: '60px' }}
                    />
                  </a>
                </Box>
                <Typography variant="body2">
                  PGA Jr. League brings friends and families together through
                  fun, team golf experiences.
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item container spacing={2}>
                  <Grid item>
                    {footerMenus.leftTop.map(({ text, url }) => (
                      <MenuLink key={text} href={url}>
                        {text}
                      </MenuLink>
                    ))}
                  </Grid>
                  <Grid item>
                    {footerMenus.leftBottom.map(({ text, url }) => (
                      <MenuLink key={text} href={url}>
                        {text}
                      </MenuLink>
                    ))}
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    {footerMenus.rightTop.map(({ text, url }) => (
                      <MenuLink key={text} href={url}>
                        {text}
                      </MenuLink>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={4}>
                <Grid item sm={6}>
                  {footerMenus.rightBottom.map(({ text, url }) => (
                    <MenuLink key={text} href={url}>
                      {text}
                    </MenuLink>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              justify={isMobile ? 'center' : 'space-between'}
              style={{ marginTop: isMobile ? '20px' : '120px' }}
            >
              <Grid
                item
                sm={12}
                md={10}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isMobile ? '10px' : 'initial',
                }}
              >
                {footerMenus.bottom.map(({ text, url }) => (
                  <Link key={text} href={url} className={classes.linkWhite}>
                    {text}
                  </Link>
                ))}
                <Typography style={{ fontSize: '14px' }}>
                  &copy; Copyright {new Date().getFullYear()} | PGA Junior
                  League
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                md={2}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Link href={urls.twitter}>
                  <XIcon
                    className={classes.socialIcon}
                    style={{
                      height: '16px',
                      width: '16px',
                      marginBottom: '1px',
                    }}
                  />
                </Link>
                <Link href={urls.facebook}>
                  <FacebookIcon className={classes.socialIcon} />
                </Link>
                <Link href={urls.instagram}>
                  <InstagramIcon className={classes.socialIcon} />
                </Link>
                {/* <Link href={urls.youtube}>
                  <YouTubeIcon className={classes.socialIcon} />
                </Link> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Fab
        to={`${search}#header`}
        smooth
        component={HashLink}
        color="primary"
        aria-label="add"
        className={classes.fab}
      >
        <ExpandLessIcon />
      </Fab>
    </Box>
  )
}

export default Footer
