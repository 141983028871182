import React from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import IconContainer from './icon-container'
import AdminTitle from 'components/admin-title'

export default function NoItemsPlaceholder({
  addButton,
  icon,
  title,
  children,
  small,
}) {
  const bodyVariant = small ? 'body2' : 'body1'

  return (
    <Container maxWidth="sm">
      <Box pt={10} pb={4}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <IconContainer large={!small}>{icon}</IconContainer>

          {small ? (
            <Typography variant="h6">{title}</Typography>
          ) : (
            <AdminTitle>{title}</AdminTitle>
          )}
        </Box>

        <Box mt={2}>
          <Typography
            variant={bodyVariant}
            align="center"
            color="textSecondary"
            component="div"
          >
            {children}
          </Typography>
        </Box>

        {addButton && (
          <Box mt={2} display="flex" justifyContent="center">
            {addButton}
          </Box>
        )}
      </Box>
    </Container>
  )
}
