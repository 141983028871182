import React, { useEffect } from 'react'
import auth0 from './client'
import LinearProgress from '@material-ui/core/LinearProgress'

const redirectToLogin = async (
  role,
  returnTo = window.location.href,
  forceReauthenticate = false,
) => {
  await auth0.loginWithRedirect({
    authorizationParams: {
      scope: `openid profile email offline_access api:${role}`,
      ...(forceReauthenticate ? { prompt: 'login' } : {}),
    },
    appState: { returnTo },
  })
}

const LoginRedirect = ({ role, returnTo = window.location.href }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    redirectToLogin(role, returnTo)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <LinearProgress color="secondary" />
}

export default LoginRedirect
export { redirectToLogin }
