import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MaxAttendance from './max-attendance'
import AccessCode from './access-code'

const AttendanceRestrictions = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Typography variant="h4" align="center">
          Would you like to add restrictions to this offering?
        </Typography>
        <MaxAttendance />
        <AccessCode />
      </Box>
    </Box>
  )
}

export default AttendanceRestrictions
