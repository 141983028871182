import React from 'react'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    cursor: 'pointer',
    '&:hover': {
      shadow: '5px',
    },
  },
}))

export default ({ prevUrl, label = 'Back', externalUrl, clickHandler }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = () => {
    if (clickHandler) {
      return clickHandler()
    }

    if (externalUrl) {
      window.location.href = externalUrl
      return
    }

    if (prevUrl) return history.push(prevUrl)
    return history.goBack()
  }

  return (
    <div onClick={handleClick} className={classes.root}>
      <ArrowBackIosIcon fontSize="small" />
      <Typography variant="caption">{label}</Typography>
    </div>
  )
}
