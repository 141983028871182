import React, { useLayoutEffect } from 'react'
import NotFound from 'components/not-found.js'
import { LinearProgress } from '@material-ui/core'
import { useAuth, withAuthenticatedRoute } from 'lib/auth'
import { useGetPracticeSessionTypes } from './queries'
import {
  FRISCO_COACHING_CENTER_SLUG,
  SPACE_BOOKING_LIMIT,
} from 'utils/constants'
import { DateTime } from 'luxon'
import qs from 'query-string'
import SelectTimeSlotLayout from './select-time-slot-layout'
import { useLocation } from 'react-router-dom'
import ConfirmMemberNumber from './confirm-member-number'
import ReviewReservation from './review-reservation'
import Error from 'components/error'
import scrollToTopOnMount from 'lib/utils/scroll-to-top-on-mount'

const Reservations = () => {
  const { isLoggedIn, isCoach } = useAuth()
  const location = useLocation()

  const {
    startDateTime,
    studentId,
    memberNumber,
    practiceSessionTypeId,
  } = qs.parse(location.search)
  scrollToTopOnMount({ query: `${startDateTime}${memberNumber}` })

  const { loading, error, data, refetch } = useGetPracticeSessionTypes({
    slug: FRISCO_COACHING_CENTER_SLUG,
    startDateTime: DateTime.local()
      .startOf('hour')
      .plus({ hour: 1 })
      .toISO(),
    endDateTime: DateTime.local()
      .endOf('day')
      .plus({ days: SPACE_BOOKING_LIMIT })
      .toISO(),
  })

  useLayoutEffect(() => {
    if (startDateTime == null) {
      refetch()
    }
  }, [startDateTime, refetch])

  if (isLoggedIn && isCoach) {
    return (
      <Error
        statusCode={404}
        message="Currently, only student accounts are eligible to book bay reservations."
      />
    )
  }

  if (loading) return <LinearProgress />

  if (error) {
    return <NotFound returnUrl={`${FRISCO_COACHING_CENTER_SLUG}`} />
  }

  if (studentId && memberNumber && practiceSessionTypeId) {
    return (
      <ReviewReservation
        academy={data.academy}
        studentId={studentId}
        startDateTime={startDateTime}
        practiceSessionTypeId={practiceSessionTypeId}
      />
    )
  }

  if (startDateTime) {
    return <ConfirmMemberNumber academy={data.academy} />
  }

  return <SelectTimeSlotLayout academy={data.academy} refetch={refetch} />
}

export default withAuthenticatedRoute(Reservations)
