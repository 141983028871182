import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { CtrlTextField } from 'components/form-util'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import useStyles from './styles'
import { SEND_SPECIALTY_PROGRAM_INTEREST } from './queries'
import { useMutation } from '@apollo/client'
import { gtmEvent } from 'lib/gtm'
import { useSnackbar } from 'notistack'
import LinearProgress from '@material-ui/core/LinearProgress'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'themes'
import InputAdornment from '@material-ui/core/InputAdornment'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import ReCAPTCHA from 'react-google-recaptcha'
import { RECAPTCHA_V2_KEY } from 'env'
import { DateTime } from 'luxon'

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
const formatPhoneNumber = phoneNumber => {
  const formatted = phoneNumber.replace(/\D/g, '')
  return formatted.length > 0 ? formatted : null
}

const formatDate = date => {
  return date?.slice(0, 10) // save only the date portion of the ISO string
}

const validationSchema = yup.object().shape({
  eventName: yup.string().required('Event name is required'),
  studentName: yup
    .string()
    .label('Name')
    .required()
    .max(50),
  studentEmail: yup
    .string()
    .email()
    .label('Email Address')
    .required(),
  studentPhone: yup
    .string()
    .matches(phoneRegExp, 'Your phone number must be 10 digits')
    .label('Phone Number')
    .required(),
  preferredDate: yup.string(),
  numberOfAttendees: yup
    .number()
    .transform(value => (Number.isInteger(value) ? value : null))
    .nullable(),
})

const ProgramInterestForm = ({ program, setIsFormSubmitted }) => {
  const minSubmissionDate = DateTime.now().plus({ hours: 48 })
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    errors,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      eventName: program.title,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    shouldFocusError: true,
  })
  const offeringsTheme = createTheme(themes.offeringsRefresh)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isChecked, setIsChecked] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [pickerError, setPickerError] = useState('')
  const [sendSpecialtyProgramInterest] = useMutation(
    SEND_SPECIALTY_PROGRAM_INTEREST,
    {
      context: { recaptchaToken },
    },
  )
  const canSubmit = isValid && isChecked && recaptchaToken && !pickerError

  const handlePickerOpen = () => {
    const { preferredDate } = getValues()
    // need this to handle odd datepicker behavior, that appears after adding minDate
    if (!preferredDate || pickerError) {
      setValue('preferredDate', minSubmissionDate)
    }
  }

  const handlePickerError = error => {
    if (!error) {
      setPickerError('')
    } else {
      setPickerError('Preferred Availability must be after 48 hours from now')
    }
  }

  const onSubmit = async values => {
    return sendSpecialtyProgramInterest({
      variables: {
        input: {
          eventName: values.eventName,
          studentName: values.studentName,
          studentEmail: values.studentEmail,
          studentPhone: formatPhoneNumber(values.studentPhone),
          preferredDate: formatDate(values.preferredDate),
          numberOfAttendees: values.numberOfAttendees,
        },
      },
    })
      .then(({ data }) => {
        if (data.sendSpecialtyProgramInterest.success) {
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'specialty-program-interest',
            formAction: 'completed-interest',
          })
          setIsFormSubmitted(true)
        } else {
          enqueueSnackbar(data?.sendSpecialtyProgramInterest?.message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['specialty-program-interest-form'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
  }

  return (
    <Box maxWidth="400px" mb={8}>
      <Typography variant="h5" gutterBottom>
        Submit Interest
      </Typography>
      <Typography variant="body1" className={classes.attributes} gutterBottom>
        This form lets you express interest in PGA Coaching Center events before
        registration.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={3} mb={2}>
          <CtrlTextField
            name="studentName"
            label="Name"
            placeholder="Name"
            fullWidth
            required
            errors={errors}
            control={control}
            className={classes.input}
          />
        </Box>
        <Box mb={2}>
          <CtrlTextField
            name="studentEmail"
            label="Email Address"
            placeholder="Email Address"
            fullWidth
            required
            errors={errors}
            control={control}
            className={classes.input}
          />
        </Box>
        <Box mb={2}>
          <CtrlTextField
            name="studentPhone"
            label="Phone Number"
            placeholder="Phone Number"
            fullWidth
            required
            errors={errors}
            control={control}
            className={classes.input}
          />
        </Box>
        <Box mb={2}>
          <FormControl>
            <LocalizationProvider dateAdapter={CustomLuxonAdapter}>
              <ThemeProvider theme={offeringsTheme}>
                <Controller
                  as={
                    <DatePicker
                      name="preferredDate"
                      label="Preferred Availability"
                      format="MM/dd/yyyy"
                      timezone="UTC"
                      minDate={minSubmissionDate}
                      onOpen={handlePickerOpen}
                      onError={handlePickerError}
                      dayOfWeekFormatter={day => `${day}`}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayOutlinedIcon
                                  style={{ color: '#6A6A6A' }}
                                />
                              </InputAdornment>
                            ),
                          },
                          error: !!pickerError,
                          helperText: pickerError,
                        },
                      }}
                      sx={{ width: '100%' }}
                      className={classes.input}
                    />
                  }
                  control={control}
                  name="preferredDate"
                />
              </ThemeProvider>
            </LocalizationProvider>
          </FormControl>
        </Box>
        <Box mb={4}>
          <CtrlTextField
            name="numberOfAttendees"
            label="Number of Attendees"
            placeholder="Number of Attendees"
            fullWidth
            type={'number'}
            errors={errors}
            control={control}
            className={classes.input}
          />
        </Box>
        <input
          type="hidden"
          name="eventName"
          value={program.title}
          ref={register}
        />
        <Box mb={4}>
          <Typography
            variant="body1"
            className={classes.attributes}
            gutterBottom
          >
            <input
              type="checkbox"
              name="terms"
              value="terms"
              ref={register}
              onChange={() => setIsChecked(!isChecked)}
            />
            <Typography
              variant="caption"
              className={`${classes.attributes} ${classes.terms}`}
              gutterBottom
            >
              By checking this box, you are agreeing to PGA of America's&nbsp;
              <a
                target="_blank"
                href="https://www.pga.com/pga-of-america/privacy-policy"
                rel="noreferrer"
              >
                Privacy Policy&nbsp;
              </a>
              and
              <a
                target="_blank"
                href="https://www.pga.com/pga-of-america/terms-of-service"
                rel="noreferrer"
              >
                &nbsp;Terms of Service.
              </a>
            </Typography>
          </Typography>
        </Box>
        <Box item xs={12} mb={4}>
          <ReCAPTCHA
            sitekey={RECAPTCHA_V2_KEY}
            onChange={token => setRecaptchaToken(token)}
          />
        </Box>
        {isSubmitting && <LinearProgress style={{ marginBottom: '24px' }} />}
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={!canSubmit}
        >
          Submit
        </Button>
      </form>
    </Box>
  )
}

export default ProgramInterestForm
