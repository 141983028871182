import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import InfoAlert from 'components/info-alert'
import { makeStyles } from '@material-ui/core/styles'
import CurrencyField from 'components/form-util/currency'
import AccountRouterLink from 'components/account-router-link'
import {
  isRecurringPayment,
  isWeeklyRecurringPayment,
  isMonthlyRecurringPayment,
} from 'utils/groupEventUtils'
import { centsToDollarsFormatted, dollarsToCents } from 'lib/utils/number'

const useStyles = makeStyles(theme => ({
  currencyStyled: {
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  warningBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 16px',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;',
    borderRadius: theme.spacing(1),
    '& svg': {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
  },
}))

/**
 * Returns the label for the price field based on the event payment type
 *
 * @param {Object} event - The event object
 * @returns {String} - The label for the price field
 */
const priceLabel = event => {
  if (isWeeklyRecurringPayment(event)) {
    return 'Price (per Week)'
  } else if (isMonthlyRecurringPayment(event)) {
    return 'Price (per Month)'
  } else {
    return 'Price'
  }
}

/**
 * Returns the label for the Stripe fee based on the event payment type
 *
 * @param {String} paymentRecurrenceSchedule - The event payment recurrence schedule
 * @returns {JSX.Element} - The label for the Stripe fee
 */
const feeLabel = paymentRecurrenceSchedule => (
  <Typography variant="body2">
    Please note that a 2.9% + $0.30 fee will be applied to{' '}
    {isRecurringPayment({ paymentRecurrenceSchedule }) ? (
      <>
        <strong>each</strong> billing cycle transaction.
      </>
    ) : (
      <>the transaction total.</>
    )}
  </Typography>
)

const Fees = ({ event, paymentAccounts }) => {
  const { errors, register, control, watch } = useFormContext()
  const classes = useStyles()

  const price = watch('price')
  const totalPriceInCents = dollarsToCents(price) * event.numberOfPayments

  const allowsPaymentAccount =
    !isRecurringPayment(event) && !event.externalRegistrationUrl

  const priceDisabled = isRecurringPayment(event) && event.registrationCount > 0

  return (
    <>
      <Grid container md={8} lg={6} direction="column">
        <Box mb={2}>
          <Typography variant="subtitle1">{priceLabel(event)}</Typography>
          {priceDisabled && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              The price of this subscription cannot be changed because one or
              more attendees have already registered.
            </Typography>
          )}
        </Box>
        <Box mb={4}>
          <div className={classes.currencyStyled}>
            <CurrencyField
              name="price"
              label="Price per person"
              inputRef={register}
              error={!!errors.price}
              helperText={errors?.price?.message}
              readOnly={priceDisabled}
            />
          </div>
          {isRecurringPayment(event) && (
            <Box mt={2}>
              <Typography variant="subtitle2">
                Total Cost: {centsToDollarsFormatted(totalPriceInCents)}
              </Typography>
            </Box>
          )}
        </Box>
        {allowsPaymentAccount && (
          <>
            <Box mb={2}>
              <Typography variant="subtitle1" gutterBottom>
                Payment account
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Select a payment account to collect registration fees online for
                this event. If no payment account is selected, upfront payments
                will not be required.
              </Typography>
            </Box>
            <FormControl
              error={!!errors.paymentAccountId}
              variant="outlined"
              style={{ maxWidth: '450px' }}
            >
              <InputLabel id="category">Select payment account</InputLabel>
              <Controller
                defaultValue=""
                as={
                  <Select
                    labelId="paymentAccount"
                    label="Select paymentAccount"
                    data-cy="paymentAccount"
                    value=""
                    defaultValue=""
                    disabled={isRecurringPayment(event)}
                  >
                    {paymentAccounts.map(opt => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name} – x{opt.last4}
                      </MenuItem>
                    ))}
                    <MenuItem key="none" value="">
                      <em>None</em>
                    </MenuItem>
                  </Select>
                }
                name="paymentAccountId"
                control={control}
              />
            </FormControl>
            <Box mt={1}>
              <Button
                color="primary"
                size="small"
                to="/account/payments/payment-accounts"
                component={AccountRouterLink}
              >
                Manage Payment Accounts
              </Button>
            </Box>
            <InfoAlert mt={2}>
              {feeLabel(event.paymentRecurrenceSchedule)}
            </InfoAlert>
          </>
        )}
      </Grid>
    </>
  )
}

export default Fees
