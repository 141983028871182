import { gql } from '@apollo/client'

const RECORD_BOOKING_PAYMENT = gql`
  mutation RecordBookingPayment($enrollmentId: ID!, $payment: PaymentInput!) {
    recordBookingPayment(enrollmentId: $enrollmentId, payment: $payment) {
      message
      success
    }
  }
`

const UPDATE_REGISTRATION_PAYMENT = gql`
  mutation UpdateRegistrationPaymentCharge(
    $input: RegistrationPaymentChargeInput!
  ) {
    updateRegistrationPaymentCharge(input: $input) {
      message
      success
    }
  }
`

export { RECORD_BOOKING_PAYMENT, UPDATE_REGISTRATION_PAYMENT }
