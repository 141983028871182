import React from 'react'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  containerGrid: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

export default function CardGrid({ children, className, wideCards = false }) {
  const classes = useStyles()
  const hidden = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const SpacerBlock =
    hidden || wideCards ? null : <Grid item md={1} lg={1} xl={2} />

  const widthProps = wideCards
    ? { xs: 12 }
    : {
      sm: 12,
      md: 10,
      lg: 10,
      xl: 8,
    }

  return (
    <Box pr={2} pl={2}>
      <Grid container className={clsx(classes.containerGrid, className)}>
        {SpacerBlock}
        <Grid item container direction="column" spacing={4} {...widthProps}>
          {children}
        </Grid>
        {SpacerBlock}
      </Grid>
    </Box>
  )
}
