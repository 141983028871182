import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  wrappedSection: {
    marginBottom: '10px',
  },
  noSessionsWrapped: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '50px',
  },
  calendarIconStyles: {
    marginRight: '10px',
  },
}))
