import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import Grid from '@material-ui/core/Grid'

import CoachView from './coach-view'
import ConsumerView from './consumer-view'

import CoachAddStudentModal from 'components/add-student-modal/coach-add-student-modal'
import ConsumerAddStudentModal from 'components/add-student-modal/consumer-add-student-modal'
import StepDescriptionOfferings from '../components/step-description-offerings'
import { gtmEvent } from 'lib/gtm'
import qs from 'query-string'
import { DateTime } from 'luxon'
import { get } from 'lodash'

const SelectStudentLayout = ({
  students,
  currentUrl,
  previousUrl,
  isCoach,
  user,
  progressValue,
  stepText,
  lessonType,
}) => {
  const [openAddStudent, setOpenAddStudent] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const { startDateTime: ts } = qs.parse(location.search)
  const timezone = get(lessonType?.facilities, ['0', 'timezone'])

  const eventObject = {
    event: 'select-student-to-schedule',
    title: lessonType?.title,
    category: lessonType?.category,
    facility: get(lessonType?.facilities, ['0', 'name']),
    location: `${get(lessonType?.facilities, ['0', 'city'])}, ${get(
      lessonType?.facilities,
      ['0', 'state'],
    )}`,
    price: `${lessonType?.priceInCents / 100}`,
    event_date: DateTime.fromISO(ts, {
      zone: timezone,
    }).toFormat('yyyy-LL-dd'),
    event_time: DateTime.fromISO(ts, {
      zone: timezone,
    }).toFormat('hh:mm a'),
    duration: lessonType?.durationInMinutes,
    coach_id: lessonType?.coach?.id,
    coach_name: lessonType?.coach?.name,
    access_code_required: lessonType?.requiresAccessCode ? 'yes' : 'no',
  }

  return (
    <>
      <Grid container spacing={2}>
        <ProgressIndicator value={progressValue} />
        <StepDescriptionOfferings
          stepText={stepText}
          title="Select a Student"
          description={
            isCoach
              ? 'Select a student to attend this session.'
              : 'Who will be attending this session?'
          }
        />
      </Grid>
      {isCoach ? (
        <>
          <CoachView
            searchableItems={students}
            history={history}
            currentUrl={currentUrl}
            openAddStudent={openAddStudent}
            setOpenAddStudent={setOpenAddStudent}
            eventObject={eventObject}
          />
          <CoachAddStudentModal
            open={openAddStudent}
            setOpen={setOpenAddStudent}
            onAdd={data => {
              const newStudentId = data?.createStudent?.student?.id
              history.push(`${currentUrl}&studentId=${newStudentId}`)
              gtmEvent({
                ...eventObject,
                event: 'add-add-student-to-schedule',
              })
            }}
            dialogTitle="Add a New Student"
            btnTitle="Add Student"
          />
        </>
      ) : (
        <>
          <ConsumerView
            students={students}
            history={history}
            currentUrl={currentUrl}
            openAddStudent={openAddStudent}
            setOpenAddStudent={setOpenAddStudent}
            user={user}
            eventObject={eventObject}
          />
          <ConsumerAddStudentModal
            open={openAddStudent}
            setOpen={setOpenAddStudent}
            onAdd={data => {
              const newStudentId = data?.createStudent?.student?.id
              history.push(`${currentUrl}&studentId=${newStudentId}`)
              gtmEvent({
                ...eventObject,
                event: 'add-add-student-to-schedule',
              })
            }}
            dialogTitle="Add a New Student"
            btnTitle="Add Student"
          />
        </>
      )}
    </>
  )
}

export default SelectStudentLayout

SelectStudentLayout.propTypes = {
  progressValue: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
}
