import React from 'react'
import { DateTime } from 'luxon'
import get from 'lodash/get'
import { gql, useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddToCalendarButton from 'components/add-to-calendar-button'
import CancelBookingDialog from 'components/cancel-booking-dialog'

export const GET_BOOKING_CONFIRMATION = gql`
  query GetBookingConfirmation($confirmationCode: String) {
    enrollments(confirmationCode: $confirmationCode) {
      id
      isCanceled
      confirmationCode
      student {
        id
        firstName
        lastName
        contact {
          id
          firstName
          lastName
          email
          phone
        }
      }
      lesson {
        id
        title
        description
        locationType
        location
        startDateTime
        endDateTime
        coach {
          id
          name
        }
      }
    }
  }
`

const formatLocation = (locationType, location) => {
  let formattedLocation = null
  switch (locationType) {
    case 'IN_PERSON':
      formattedLocation = (
        <a href={`https://maps.google.com/?q=${encodeURIComponent(location)}`}>
          {location}
        </a>
      )
      break
    case 'PHONE':
      formattedLocation = (
        <>
          <a href={`tel:${location}`}>{location}</a> (Phone Call)
        </>
      )
      break
    case 'VIDEO':
      formattedLocation = (
        <>
          <a href={location}>{location}</a> (Video Call)
        </>
      )
      break
    default:
      formattedLocation = location
      break
  }
  return formattedLocation
}

const BookingConfirmationCard = ({ confirmationCode }) => {
  const { data, loading, refetch } = useQuery(GET_BOOKING_CONFIRMATION, {
    variables: { confirmationCode },
  })

  if (loading) return <CircularProgress color="primary" />

  const enrollment = get(data, 'enrollments.0')

  if (!enrollment) {
    return (
      <Box
        border={1}
        borderColor="grey.300"
        borderRadius={4}
        bgcolor="background.default"
        my={2}
        p={4}
      >
        <Box mb={2}>
          <Typography variant="body1">Booking not found.</Typography>
        </Box>
        <Typography variant="body1">
          If you believe this is an error, please contact your coach.
        </Typography>
      </Box>
    )
  }

  const { lesson, student, isCanceled } = enrollment
  const { coach } = lesson
  const startDateTime = DateTime.fromISO(lesson.startDateTime)
  const endDateTime = DateTime.fromISO(lesson.endDateTime)
  const dateLabel = `${startDateTime.toLocaleString(DateTime.DATE_HUGE)}`
  const timeLabel = `${startDateTime.toLocaleString(
    DateTime.TIME_SIMPLE,
  )} - ${endDateTime.toLocaleString(DateTime.TIME_SIMPLE)} (${
    startDateTime.offsetNameShort
  })`
  const locationLabel = formatLocation(lesson.locationType, lesson.location)
  const calendarEvent = {
    uid: `coach-tools.enrollment.${confirmationCode}.pga.com`,
    startDateTime: startDateTime,
    endDateTime: endDateTime,
    title: `${lesson.title} with ${coach.name}`,
    description: lesson.description,
    location: lesson.location,
    url: `${window.location.origin}/booking-confirmation/${confirmationCode}`,
  }
  return (
    <Box
      border={1}
      borderColor="grey.300"
      borderRadius={4}
      bgcolor="background.default"
      my={2}
      p={4}
    >
      <Typography variant="h4" gutterBottom>
        Booking Confirmation
      </Typography>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="overline">Session</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {lesson.title}
          </Typography>
          <Typography variant="caption">{lesson.description}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Coach</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {coach.name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Student</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {student.firstName} {student.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Date</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {dateLabel}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Time</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {timeLabel}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">Location</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {locationLabel}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Status</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {isCanceled ? 'CANCELED' : 'SCHEDULED'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline">Confirmation Code</Typography>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            {confirmationCode}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={4} display="flex">
        <Box mr={2}>
          <AddToCalendarButton
            event={calendarEvent}
            color="primary"
            variant="contained"
          >
            Add To Calendar
          </AddToCalendarButton>
        </Box>
        {!isCanceled && startDateTime > DateTime.local() && (
          <CancelBookingDialog
            trigger={
              <Button color="primary" variant="outlined">
                Cancel Session
              </Button>
            }
            confirmationCode={confirmationCode}
            onSuccess={() => {
              refetch()
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default BookingConfirmationCard
