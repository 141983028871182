import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  heading: {
    marginTop: theme.spacing(4),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[300],
    borderRadius: 40,
    minWidth: 40,
    minHeight: 40,
  },
  mainWrapper: {
    textDecoration: 'none',
    display: 'grid',
    height: '100%',
  },
  action: {
    marginLeft: 'auto',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0,
    },
    color: 'rgba(0, 0, 0, 0.54)',
  },
  adornedEnd: {
    paddingRight: '8px',
    '& button': {
      padding: 0,
    },
  },
  ctaButton: {
    pointerEvents: 'all',
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(1),
  },
  settingsCaption: {
    marginLeft: theme.spacing(2),
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  settingsWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 8px 24px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    minHeight: theme.spacing(9),
    [theme.breakpoints.only('xs')]: {
      minHeight: theme.spacing(12),
    },
  },
  settingsDescription: {
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  settingsText: {
    marginLeft: theme.spacing(7),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(7),
    width: '80%',
  },
}))

export default useStyles
