import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { Avatar } from '@material-ui/core'
import { EventBusyOutlined } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useAuth } from 'lib/auth'
import { DateTime } from 'luxon'
import { toThreeLetterTimezone } from 'utils/timezones'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import { useQuery } from '@apollo/client'
import { GET_STUDENT_COACHES } from './query'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'

const getUniqueExternalIds = representatives => {
  if (!representatives) return []
  const externalIds = new Set()

  representatives.forEach(representative => {
    representative.student.coaches.forEach(coach => {
      externalIds.add(coach.externalId)
    })
  })

  return Array.from(externalIds)
}

const NoTimesAvailable = ({
  classes,
  coach,
  nextSlot,
  setShowRequestTime,
  timezone,
  showTimezone,
  isBayReservation,
  setSelectedDate,
}) => {
  const { isLoggedIn, isCoach } = useAuth()
  const { data, loading, error } = useQuery(GET_STUDENT_COACHES, {
    skip: !isLoggedIn || isCoach || isBayReservation,
  })
  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error error={error} />

  const coachesIds = getUniqueExternalIds(data?.currentUser?.representatives)
  const hasCoachConnection = coachesIds.includes(coach?.externalId)
  const showRequestCoachingForm = !hasCoachConnection || !isLoggedIn

  const formattedDateTime = (dateTime, tz) => {
    const dt = DateTime.fromISO(dateTime, { zone: tz })
    return dt.toFormat("LLLL d', ' t")
  }

  const timezoneInParentheses = timezone
    ? ` (${toThreeLetterTimezone(timezone)})`
    : ''

  const RequestCoaching = () => {
    return (
      <Box>
        <Box mb={3}>
          Select another day or submit a request for coaching below.
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowRequestTime(true)
          }}
        >
          Request Coaching
        </Button>
      </Box>
    )
  }

  const SendMessage = ({ coach, nextSlot, timezone, classes }) => {
    return (
      <Box>
        <Typography
          className={classes.boldedText}
          style={{ marginBottom: '24px' }}
        >
          {nextSlot
            ? `The next day with availability is ${formattedDateTime(
              nextSlot,
              timezone || null,
            )}${timezoneInParentheses}.`
            : null}
        </Typography>
        <StreamSendMessageButton
          userIds={[coach.externalId]}
          variant="contained"
          color="primary"
        >
          Send Message
        </StreamSendMessageButton>
      </Box>
    )
  }

  return (
    <Box align="center">
      <Box mb={3}>
        <Avatar className={classes.noTimesAvatar}>
          <EventBusyOutlined fontSize="large" />
        </Avatar>
      </Box>
      <Typography variant="h5" className={classes.noTimesAvailable}>
        No Times Available
      </Typography>
      {isBayReservation && (
        <Box mt={3}>
          <Typography className={classes.boldedText}>
            The PGA Coaching Center does not have any available times on this
            day.{' '}
            {nextSlot
              ? `The next day with availability is ${formattedDateTime(
                nextSlot,
                timezone || null,
              )}${timezoneInParentheses}.`
              : null}
          </Typography>
          {nextSlot && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '24px' }}
              onClick={() => setSelectedDate(DateTime.fromISO(nextSlot))}
            >
              View Available Times
            </Button>
          )}
        </Box>
      )}
      {coach && !isBayReservation && (
        <Box mt={3}>
          <Typography className={classes.boldedText}>
            {coach.name} doesn't have any available times on this day.
          </Typography>
          {!nextSlot && !showRequestCoachingForm && (
            <Typography className={classes.boldedText}>
              Send {coach.name} a message for more info.
            </Typography>
          )}
          {showRequestCoachingForm && setShowRequestTime && <RequestCoaching />}
          {!showRequestCoachingForm && (
            <SendMessage
              coach={coach}
              nextSlot={nextSlot}
              timezone={showTimezone && timezone}
              classes={classes}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

NoTimesAvailable.propTypes = {
  classes: PropTypes.object,
  coach: PropTypes.object,
  nextSlot: PropTypes.string,
  setShowRequestTime: PropTypes.func,
  timezone: PropTypes.string,
  showTimezone: PropTypes.bool,
  isBayReservation: PropTypes.bool,
  setSelectedDate: PropTypes.func,
}
export default NoTimesAvailable
