import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '60px',
  },
  sectionTitle: {
    marginBottom: '20px',
  },
  textWrapped: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    color: '#fff',
    borderRadius: '4px',
    height: '22px',
    fontSize: '10px',
  },
  chipInfo: {
    background: '#0B79D0',
  },
  chipSuccess: {
    background: '#3B873E',
    marginRight: theme.spacing(1),
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  bookingType: {
    backgroundColor: '#ffffff',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
  },
  iconStyled: {
    background: 'rgba(0, 35, 75, 0.08)',
  },
}))
