import React, { useEffect } from 'react'
import { useStyles } from '../styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TransactionSummary from 'pages/schedule/ReviewBooking/transaction-summary'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import PrivateBookingHeader from 'pages/academy/components/private-booking-header'
import StudentCard from 'pages/schedule/components/student-card'
import SummaryActionButtons from './summary-action-buttons'

const SummaryLayout = ({
  academy,
  student,
  cardBrand,
  lastDigits,
  spaceName = '',
  salesTaxEstimation,
}) => {
  const classes = useStyles()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const priceInCents = academy?.practiceSessionTypes?.[0]?.priceInCents

  return (
    <Container className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item md={7} xs={12}>
          <Typography
            variant="h3"
            color="primary"
            gutterBottom
            data-testId="success-reservation-title"
          >
            Thank you!
          </Typography>
          <Typography
            variant="body1"
            className={classes.fontBolded}
            gutterBottom
          >
            Your booking is complete. You will receive a confirmation email
            shortly.
          </Typography>
          <Divider className={classes.divider} />
          <TransactionSummary
            title="Bay Reservation"
            priceInCents={priceInCents}
            salesTaxInCents={salesTaxEstimation}
            cardBrand={cardBrand}
            lastDigits={lastDigits}
          />
          <Typography
            variant="h5"
            style={{ marginTop: '36px', marginBottom: '24px' }}
          >
            Details
          </Typography>
          <PrivateBookingHeader academy={academy} spaceName={spaceName} />
          <Divider className={classes.divider} />
          <Grid item xs={12} sm={6}>
            <StudentCard student={student} />
          </Grid>
          <SummaryActionButtons student={student} />
          <DownloadMyPGAsection />
        </Grid>
      </Grid>
    </Container>
  )
}

export default SummaryLayout
