import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { gtmEvent } from 'lib/gtm'
import { yupResolver } from '@hookform/resolvers'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import { genderOptions } from 'lib/form-schemas/common'
import Layout from './layout'
import RegistrationSubmitting from '../registration-submitting'
import { REGISTER_PGA_HOPE_GROUP_EVENT, CURRENT_USER_INFO } from './queries'
import VerifyAccessCode from 'components/verify-access-code'
import * as Common from 'lib/form-schemas/common'
import { branchOfServiceOptions } from './constants'
import { handednessOptions } from 'utils/constants'
import { formatISO } from 'date-fns'
import RegistrationComplete from './registration-complete'
import { useAuth } from 'lib/auth'
import { stripPhoneNumber } from 'utils/phoneNumber'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DateTime } from 'luxon'

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/
const zipCodeRegExp = /^\d{5}$/

const formatPhoneNumber = phoneNumber => {
  const formatted = phoneNumber.replace(/\D/g, '')
  return formatted.length > 0 ? formatted : null
}

const formatDob = dob => {
  return formatISO(dob).slice(0, 10) // save only the date portion of the ISO string
}

const emergencyContactSchema = {
  firstName: yup
    .string()
    .label('First Name')
    .required(),
  lastName: yup
    .string()
    .label('Last Name')
    .required(),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Your phone number must be 10 digits')
    .label('Phone Number')
    .required(),
  relationship: yup
    .string()
    .label('Relationship')
    .required(),
}

const studentSchema = {
  firstName: yup
    .string()
    .label('First Name')
    .required(),
  lastName: yup
    .string()
    .label('Last Name')
    .required(),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Your phone number must be 10 digits')
    .label('Phone Number')
    .required(),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('This field is required'),
  dateOfBirth: yup
    .date()
    .typeError('Date of Birth must be a valid date')
    .required()
    .label('Date of Birth'),
  gender: yup
    .string()
    .label('Gender')
    .required()
    .oneOf(
      genderOptions.map(Common.getValue),
      'Gender must be one of the available options',
    ),
  zipCode: yup
    .string()
    .matches(zipCodeRegExp, 'Must be a valid zip code')
    .required(),
}

const pgaHopeProfileSchema = {
  accommodations: yup.string(),
  additionalInfo: yup.string(),
  golfCareerInterest: yup.boolean().required(),
  golfExperience: yup.string().required(),
  handedness: yup
    .string()
    .oneOf(
      handednessOptions.map(Common.getValue),
      'Handedness must be one of the available options',
    )
    .required(),
  hasOwnClubs: yup.boolean().required(),
  serviceBranch: yup
    .string()
    .oneOf(
      branchOfServiceOptions.map(Common.getValue),
      'Branch of service must be one of the available options',
    )
    .required(),
  referralSource: yup.string().required(),
  militaryStatus: yup
    .string()
    .required()
    .label('current status'),
  serviceYearStart: yup
    .number()
    .transform((_, val) => (val ? Number(val) : null))
    .label('From')
    .required(),
  serviceYearEnd: yup
    .number()
    .nullable()
    .transform((_, val) => {
      if (val === 'Present') {
        return null
      }
      return val ? Number(val) : null
    })
    .label('To'),
  shirtSize: yup.string().required(),
}

const validationSchema = () =>
  yup.object().shape({
    student: yup.object().shape(studentSchema),
    emergencyContact: yup.object().shape(emergencyContactSchema),
    pgaHopeProfile: yup.object().shape(pgaHopeProfileSchema),
  })

const PgaHopeRegistrationForm = ({ groupEvent, isMobile }) => {
  const [registrationEmail, setRegistrationEmail] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [isAccessCodeVerified, setIsAccessCodeVerified] = useState(false)
  const requiresAccessCode = groupEvent.requiresAccessCode
  const [formIsLoading, setFormIsLoading] = useState(false)

  const { user, isConsumer } = useAuth()
  const isAuthenticatedConsumer = user && isConsumer

  const { data, loading } = useQuery(CURRENT_USER_INFO, {
    skip: !isAuthenticatedConsumer,
    onCompleted: () => {
      prefillStudentValues()
    },
  })

  const loggedInUser = data?.currentUser?.attributes || {}
  const currentStudent =
    loggedInUser?.representatives?.filter(
      representative => representative.relationshipType === 'SELF',
    )[0]?.student || {}

  const prefillStudentValues = () => {
    if (currentStudent) {
      form.setValue('student', {
        zipCode: currentStudent?.pgaHopeProfile?.zipCode,
        gender: currentStudent?.golferProfile?.gender,
      })
      form.setValue('pgaHopeProfile', {
        golfCareerInterest: currentStudent?.pgaHopeProfile?.golfCareerInterest,
        golfExperience: currentStudent?.pgaHopeProfile?.golfExperience,
        handedness: currentStudent?.pgaHopeProfile?.handedness,
        hasOwnClubs: currentStudent?.pgaHopeProfile?.hasOwnClubs,
        militaryStatus: currentStudent?.pgaHopeProfile?.militaryStatus,
        referralSource: currentStudent?.pgaHopeProfile?.referralSource,
        serviceBranch: currentStudent?.pgaHopeProfile?.serviceBranch,
        serviceYearStart: currentStudent?.pgaHopeProfile?.serviceYearStart,
        serviceYearEnd: currentStudent?.pgaHopeProfile?.serviceYearEnd,
        accommodations: currentStudent?.pgaHopeProfile?.accommodations,
        additionalInfo: currentStudent?.pgaHopeProfile?.additionalInfo,
        shirtSize: currentStudent?.pgaHopeProfile?.shirtSize,
      })
    }
  }

  const { enqueueSnackbar } = useSnackbar()
  const form = useForm({
    defaultValues: {
      student: {
        firstName: isAuthenticatedConsumer ? user.firstName : '',
        lastName: isAuthenticatedConsumer ? user.lastName : '',
        email: isAuthenticatedConsumer ? user.email : '',
        phone: isAuthenticatedConsumer
          ? stripPhoneNumber(user.phoneNumber)
          : '',
        gender: currentStudent?.golferProfile?.gender || '',
      },
      pgaHopeProfile: {
        golfCareerInterest:
          currentStudent?.pgaHopeProfile?.golfCareerInterest || false,
        golfExperience: currentStudent?.pgaHopeProfile?.golfExperience || '',
        handedness: currentStudent?.pgaHopeProfile?.handedness || '',
        hasOwnClubs: currentStudent?.pgaHopeProfile?.hasOwnClubs || null,
        militaryStatus: currentStudent?.pgaHopeProfile?.militaryStatus || '',
        referralSource: currentStudent?.pgaHopeProfile?.referralSource || '',
        serviceBranch: currentStudent?.pgaHopeProfile?.serviceBranch || '',
        serviceYearStart:
          currentStudent?.pgaHopeProfile?.serviceYearStart || '',
        serviceYearEnd:
          currentStudent?.pgaHopeProfile?.serviceYearEnd || 'Present',
        accommodations: currentStudent?.pgaHopeProfile?.accommodations || '',
        additionalInfo: currentStudent?.pgaHopeProfile?.additionalInfo || '',
        shirtSize: currentStudent?.pgaHopeProfile?.shirtSize || '',
        zipCode: currentStudent?.pgaHopeProfile?.zipCode || '',
      },
    },
    resolver: yupResolver(validationSchema()),
  })
  const [registerPgaHopeGroupEvent] = useMutation(REGISTER_PGA_HOPE_GROUP_EVENT)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const onSubmit = async values => {
    setFormIsLoading(true)
    return registerPgaHopeGroupEvent({
      variables: {
        id: groupEvent.id,
        params: {
          student: {
            firstName: values.student.firstName,
            lastName: values.student.lastName,
            email: values.student.email,
            phone: formatPhoneNumber(values.student.phone),
            dateOfBirth: formatDob(values.student.dateOfBirth),
            gender: values.student.gender,
          },
          emergencyContact: {
            firstName: values.emergencyContact.firstName,
            lastName: values.emergencyContact.lastName,
            relationship: values.emergencyContact.relationship,
            phone: formatPhoneNumber(values.emergencyContact.phone),
          },
          pgaHopeProfile: {
            golfCareerInterest: values.pgaHopeProfile.golfCareerInterest,
            golfExperience: values.pgaHopeProfile.golfExperience,
            handedness: values.pgaHopeProfile.handedness,
            hasOwnClubs: values.pgaHopeProfile.hasOwnClubs,
            militaryStatus: values.pgaHopeProfile.militaryStatus,
            referralSource: values.pgaHopeProfile.referralSource,
            serviceBranch: values.pgaHopeProfile.serviceBranch,
            serviceYearStart: values.pgaHopeProfile.serviceYearStart,
            serviceYearEnd: values.pgaHopeProfile.serviceYearEnd,
            accommodations: values.pgaHopeProfile.accommodations,
            additionalInfo: values.pgaHopeProfile.additionalInfo,
            shirtSize: values.pgaHopeProfile.shirtSize,
            zipCode: values.student.zipCode,
          },
        },
      },
    })
      .then(({ data }) => {
        if (data.registerPgaHopeGroupEvent.success) {
          setRegistrationEmail(values?.student?.email)
          gtmEvent({
            event: 'complete-event-registration',
            title: groupEvent.title,
            category: groupEvent.tags,
            event_date: DateTime.fromISO(groupEvent.startDateTime, {
              zone: groupEvent.coachFacility.timezone,
            }).toFormat('yyyy-LL-dd'),
            event_time: DateTime.fromISO(groupEvent.startDateTime, {
              zone: groupEvent.coachFacility.timezone,
            }).toFormat('hh:mm a'),
            facility: groupEvent.coachFacility.name,
            price: groupEvent.priceInCents / 100,
            coach_id: groupEvent.coach.id,
            coach_name: groupEvent.coach.name,
          })
          // Remove the below event in the future.
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'register-group-event',
            formAction: 'completed-registration',
            formStatus: 'pga-hope-event',
          })
          setActiveStep(2)
        } else {
          enqueueSnackbar(data.registerPgaHopeGroupEvent.message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['pga-hope-group-event', 'registration'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
      .finally(() => {
        setFormIsLoading(false)
      })
  }
  if (loading || formIsLoading) return <LinearProgress color="secondary" />

  return (
    <>
      {form.formState.isSubmitting && !form.formState.isValidating && (
        <RegistrationSubmitting />
      )}
      <Box
        display={
          !form.formState.isSubmitting || form.formState.isValidating
            ? 'block'
            : 'none'
        }
      >
        {registrationEmail ? (
          <RegistrationComplete
            groupEvent={groupEvent}
            registrationEmail={registrationEmail}
            isMobile={isMobile}
          />
        ) : (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Layout
                groupEvent={groupEvent}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                isMobile={isMobile}
                onSubmit={onSubmit}
              />
            </form>
          </FormProvider>
        )}
      </Box>
      <VerifyAccessCode
        groupEvent={groupEvent}
        isOpen={requiresAccessCode && !isAccessCodeVerified}
        setIsOpen={setIsAccessCodeVerified}
        coachName={groupEvent?.coach?.name}
        setVerifiedAccessCode={setIsAccessCodeVerified}
      />
    </>
  )
}

export default PgaHopeRegistrationForm

PgaHopeRegistrationForm.propTypes = {
  groupEvent: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
}
