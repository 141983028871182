import { useEffect } from 'react'
import { useAuth, roles } from 'lib/auth'
import useQueryString from 'lib/hooks/use-query-string'

export const gtmEvent = obj => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

export const useImpressionEvent = obj => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    gtmEvent(obj)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */
}

export const useTrackAffiliate = () => {
  const { affiliate } = useQueryString()
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (affiliate) {
      // abusing the formSubmit event here because I don't
      // know how to set up new event triggers in GTM
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'affiliateLink', // category
        formAction: window.location.pathname, // action
        formStatus: affiliate, // label
      })
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */
}

export const useTrackTemplate = () => {
  const { template } = useQueryString()
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (template) {
      gtmEvent({
        event: 'formSubmit',
        formCategory: 'templateLink', // category
        formAction: window.location.pathname, // action
        formStatus: template, // label
      })
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */
}

const GoogleTagManager = () => {
  const auth = useAuth()

  useEffect(() => {
    if (auth.isLoggedIn) {
      gtmEvent({
        event: 'userLogin',
        email: auth.user?.email,
        isLoggedIn: auth.isLoggedIn,
        name: auth.user?.name ?? '',
        userId: auth.user?.externalId,
        userType: auth.isCoach ? roles.COACH : roles.CONSUMER,
        bookingUrl: auth.user?.coach?.bookingUrl,
        profileUrl: auth.user?.coach?.profileUrl,
        intercomEmail: auth.user?.intercomEmail,
      })
    } else {
      gtmEvent({
        event: 'userLogout',
        isLoggedIn: auth.isLoggedIn,
      })
    }
  }, [auth.isLoggedIn, auth.user, auth.isCoach])

  return null
}

export default GoogleTagManager
