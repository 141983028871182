import React, { useState } from 'react'
import { PGA_COM_HOST } from 'env'
import { get } from 'lodash'
import { useQuery } from '@apollo/client'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'

import { parseData, hasAtLeastOneCoach } from './utils'
import { GetCoachesQuery } from './query'
import { PageHeader } from 'components/page-header'
import EmptyCoachPage from 'components/empty-page-state'
import StudentDropdown from './student-dropdown'
import Layout from './Layout'

export const MyCoach = ({ students, lessonCreditBalances }) => {
  const [selectedStudent, setSelectedStudent] = useState(
    get(students, ['0', 'id']),
  )
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  return (
    <>
      <PageHeader
        title="My Coach"
        actions={
          !isMobile && students.length > 1 ? (
            <StudentDropdown
              students={students}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
            />
          ) : null
        }
      />
      <Container style={{ height: '100%' }}>
        {hasAtLeastOneCoach(students) ? (
          <Layout
            students={students}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            isMobile={isMobile}
            lessonCreditBalances={lessonCreditBalances}
          />
        ) : (
          <EmptyCoachPage
            actionFn={() => {
              window.location.href = `${PGA_COM_HOST}/coaches/search`
            }}
            IconComp={() => <GolfCourseIcon fontSize="large" />}
            title="My Coach"
            caption="Find your next coach today."
            buttonText="Search Coaches"
          />
        )}
      </Container>
    </>
  )
}

export default () => {
  const { data, loading, error } = useQuery(GetCoachesQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Box>ERROR</Box>

  const representatives = get(data, 'currentUser.representatives')
  const lessonCreditBalances = get(data, 'currentUser.attributes.lessonPacks')

  return (
    <MyCoach
      students={parseData(representatives)}
      lessonCreditBalances={lessonCreditBalances}
    />
  )
}
