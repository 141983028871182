import React from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
} from 'react-share'
import CopyTextButton from '../copy-text-button'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import useStyles from './styles'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import XShareButton from 'components/x-share-button'

const ShareBookingUrlAndAccessCodeSection = ({ url, accessCode }) => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box className={classes.shareWrapper}>
        <FacebookShareButton url={url} className={classes.social}>
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <XShareButton url={url} size={40} wrapperClassname={classes.social} />
        <EmailShareButton url={url} className={classes.social}>
          <EmailIcon size={40} round />
        </EmailShareButton>
        <CopyTextButton
          component={IconButton}
          value={url}
          className={classes.copyUrl}
        >
          <LinkOutlinedIcon color="primary" fontSize="small" />
        </CopyTextButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Typography variant="body2" color="textSecondary">
          Required Access Code:
        </Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h5">{accessCode}</Typography>
          <CopyTextButton component={IconButton} value={accessCode}>
            <FileCopyOutlinedIcon color="primary" />
          </CopyTextButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ShareBookingUrlAndAccessCodeSection
