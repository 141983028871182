import React from 'react'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import useStyles from './styles'
import { InstantSearch, Configure, Pagination } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  COACH_ACTIVITIES_INDEX_NAME,
} from 'env'
import ActivityPlanCards from './activity-plan-cards'
import 'instantsearch.css/themes/satellite.css'
import { urlToTitle } from '../../lib/transformStage'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const ActivityPlansPage = () => {
  const classes = useStyles()
  const { stage } = useParams()
  const query =
    'stage:"' +
    urlToTitle(stage).title +
    '" AND object_type:lesson_plan AND isSystem:true'
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <InstantSearch
          indexName={COACH_ACTIVITIES_INDEX_NAME}
          searchClient={searchClient}
        >
          <ActivityPlanCards />
          <Configure filters={`${query}`} hitsPerPage={12} />
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination showLast />
          </Box>
        </InstantSearch>
      </Container>
    </Box>
  )
}

export default ActivityPlansPage
