import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'lib/auth'

export default function withFamilyCupAccess(Component) {
  return function WithFamilyCupAccess(props) {
    const { user } = useAuth()

    if (!user?.familyCupSignedUp && !user?.programAdmin) {
      return <Redirect to={'/family-golf-signup?signupRequired=true'} />
    }

    return <Component {...props} />
  }
}
