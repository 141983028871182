import React from 'react'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MobileStepper from '@material-ui/core/MobileStepper'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Divider from '@material-ui/core/Divider'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useWizardFormContext } from './context'

const useStyles = makeStyles(theme => ({
  container: {
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 61px)',
    },
  },
  controls: {
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: '10',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    background: '#e9f5fe',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    '& button': {
      marginRight: theme.spacing(2),
    },
  },
  controlsWrapper: {
    position: 'sticky',
    width: '100%',
    top: '0px',
    zIndex: 10,
  },
  stepper: {
    padding: 0,
    display: ({ isSubmitting }) => (isSubmitting ? 'none' : 'flex'),
  },
  stepperProgress: {
    width: '100%',
  },
  loading: {
    display: ({ isSubmitting }) => (isSubmitting ? 'flex' : 'none'),
  },
  subheader: {
    boxSizing: 'border-box',
    height: 'fit-content',
    padding: `${theme.spacing(3)}px ${theme.spacing(5.5)}px`,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '68px',
      padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    },
    [theme.breakpoints.down('xs')]: {
      height: '38px',
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
      '& p': {
        fontSize: '0.875rem',
      },
    },
  },
  formSteps: {
    height: 'fit-content',
    padding: `${theme.spacing(0)}px 0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      // (full viewport - appbar - stepper - subheader - controls)
      height: 'calc(100vh - 61px - 4px - 68px - 69px)',
      overflowY: 'auto',
      boxSizing: 'border-box',
      padding: `${theme.spacing(3)}px 0`,
    },
    [theme.breakpoints.down('xs')]: {
      // (full viewport - appbar - stepper - subheader - controls)
      height: 'calc(100vh - 61px - 4px - 38px - 69px)',
    },
  },
  actionBtnContainer: {
    gap: '10px',
    rowGap: '10px',
    marginLeft: theme.spacing(2),
  },
  actionBtnWrapper: {
    position: 'fixed',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    backgroundColor: '#ffffff',
    zIndex: 10,
    [theme.breakpoints.up('sm')]: {
      '& button:first-of-type': {
        marginLeft: theme.spacing(8),
      },
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
}))

const ProgressControls = ({ children }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const {
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles({ isSubmitting })
  const {
    steps,
    activeStep,
    activeStepNumber,
    reset,
    advance,
    back,
  } = useWizardFormContext()

  const ProgressIndicator = () => (
    <>
      <MobileStepper
        variant="progress"
        steps={steps.length}
        position="static"
        activeStep={activeStepNumber}
        classes={{ progress: classes.stepperProgress }}
        className={classes.stepper}
      />
      <LinearProgress color="primary" className={classes.loading} />
    </>
  )

  const ActionButtons = () => (
    <>
      <Box display="flex" justifyContent="space-between" flexGrow="1">
        <Button color="primary" onClick={reset} className={classes.button}>
          Cancel
        </Button>
        <Box display="flex" className={classes.actionBtnContainer}>
          {activeStepNumber > 0 && (
            <Button
              onMouseDown={e => e.preventDefault()}
              variant="outlined"
              color="primary"
              onClick={back}
              className={classes.button}
              startIcon={!isMobile && <ArrowBackOutlinedIcon />}
            >
              Previous
            </Button>
          )}
          {activeStep.stepName !== 'get-started' && (
            <Button
              onMouseDown={e => e.preventDefault()}
              variant="contained"
              color="primary"
              onClick={advance}
              disabled={isSubmitting}
              className={classes.button}
            >
              {isMobile ? (
                <>
                  {activeStep.nextButtonLabelMobile
                    ? activeStep.nextButtonLabelMobile
                    : 'NEXT'}
                </>
              ) : (
                activeStep.nextButtonLabel
              )}
            </Button>
          )}
        </Box>
      </Box>
    </>
  )

  return (
    <Box position="relative" className={classes.container}>
      {!isMobile && (
        <Box className={classes.controlsWrapper}>
          <Box className={classes.controls}>
            <ActionButtons />
          </Box>
          <ProgressIndicator />
        </Box>
      )}
      <Box className={classes.subheader}>
        <Typography variant="body1">
          {activeStep.stepName === 'preview'
            ? 'Preview and Submit'
            : `Step ${activeStepNumber + 1} of ${steps.length}`}
        </Typography>
      </Box>
      {isMobile && <ProgressIndicator />}
      <Box className={classes.formSteps}>{children}</Box>
      {isMobile && (
        <Box className={classes.actionBtnWrapper}>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            style={{
              paddingRight: activeStep.nextButtonLabelMobile ? '6px' : '',
            }}
          >
            <ActionButtons />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ProgressControls
