import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useStyles } from './styles'
import Box from '@material-ui/core/Box'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

const NoLessonPacksCard = ({ text }) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={2}
      className={classes.bookingType}
    >
      <IconButton className={classes.mainIcon}>
        <ConfirmationNumberOutlinedIcon fontSize="large" />
      </IconButton>
      <Typography variant="subtitle1" gutterBottom>
        No Lesson Packs
      </Typography>
      <Typography
        variant="body2"
        align="center"
        className={classes.lighterText}
        gutterBottom
      >
        {text}
      </Typography>
    </Box>
  )
}

NoLessonPacksCard.propTypes = {
  text: PropTypes.string,
}

export default NoLessonPacksCard
