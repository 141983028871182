import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles, styled } from '@material-ui/core/styles'

const TileButton = styled('div')(({ selected }) => ({
  width: '155px',
  height: '70px',
  border: selected ? '1px solid rgba(0, 35, 75, 0.5)' : '1px solid #E0E0E0',
  borderRadius: '4px',
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: selected ? 'rgba(0, 35, 75, 0.08)' : 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 10px',
}))

export const useStyles = makeStyles(theme => ({
  checkIcon: {
    position: 'absolute',
    bottom: '5px',
    left: '5px',
  },
}))

export const YesNoButtons = ({ isYes, setIsYes, dataCy }) => {
  const classes = useStyles({ selected: isYes })
  return (
    <Box display="flex" alignItems="center" data-cy={dataCy}>
      <TileButton selected={isYes} onClick={() => setIsYes(true)} data-cy="yes">
        <Typography variant="body1">YES</Typography>
        {isYes && (
          <CheckCircleIcon color="primary" className={classes.checkIcon} />
        )}
      </TileButton>
      <TileButton
        selected={!isYes}
        onClick={() => setIsYes(false)}
        data-cy="no"
      >
        <Typography variant="body1">NO</Typography>
        {!isYes && (
          <CheckCircleIcon color="primary" className={classes.checkIcon} />
        )}
      </TileButton>
    </Box>
  )
}

export default YesNoButtons
