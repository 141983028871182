import React from 'react'
import { PaperContainer } from 'components/layout-util'
import { Link } from 'react-router-dom'

function Unauthorized() {
  return (
    <PaperContainer>
      <h2>Unauthorized to view page</h2>
      <Link to="/">Home</Link>
    </PaperContainer>
  )
}

export default Unauthorized
