import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

import StepDescriptionSkeleton from 'components/skeleton-loaders/step-description-skeleton'
import LessonCardSkeleton from 'components/skeleton-loaders/lesson-type-card-skeleton'

export default () => (
  <Container style={{ marginTop: '60px' }}>
    <Grid container spacing={2} style={{ marginBottom: '20px' }}>
      <Grid item md={4} xs={12}>
        <StepDescriptionSkeleton />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <LessonCardSkeleton />
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{ marginTop: '30px' }}>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rect" height={300} width={'100%'} />
      </Grid>
      <Grid item xs={12} md={7} style={{ marginLeft: '20px' }}>
        {[1, 2].map(item => (
          <Box key={item} mb={2}>
            <Skeleton variant="text" height={50} width={50} />
            <Box display="flex" flexWrap="wrap">
              {new Array(12).fill().map((_, i) => (
                <Skeleton
                  key={i}
                  variant="rect"
                  height={40}
                  width={100}
                  style={{ margin: '4px', borderRadius: '5px' }}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  </Container>
)
