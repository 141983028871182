import React, { useState } from 'react'
import uniqueId from 'lodash/uniqueId'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Tooltip from '@material-ui/core/Tooltip'
import {
  daysOfWeek,
  OVERLAPPING_INTERVALS_ERROR_MESSAGE,
  ERROR_TOOLTIP_MESSAGE,
  INVALID_INTERVAL_ERROR_MESSAGE,
} from './constants'
import { useAuth } from 'lib/auth'
import PgaMobileTimePicker from 'components/pga-time-picker/pga-mobile-time-picker'
import SET_COACH_AVAILABILITY_PER_FACILITY from './query'
import validateIntervals from './utils'

const EditAvailabilityForm = ({
  initialTimeSlots,
  initialSelectedDay,
  coachFacilityId,
  facilityTimezone,
  onSuccess,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()
  const history = useHistory()

  const inputWidth = '166px'

  const description = (
    <Typography
      variant="body1"
      color="textSecondary"
      style={{ marginBottom: '24px' }}
    >
      Define your regular, weekly working hours at each facility below. You can
      block specific dates and times in Settings.
    </Typography>
  )

  const [setCoachAvailability, { loading: isSubmitting }] = useMutation(
    SET_COACH_AVAILABILITY_PER_FACILITY,
    {
      onCompleted: data => {
        const result = data.setCoachAvailabilityPerFacility
        if (result.success) {
          enqueueSnackbar(result.message, {
            variant: 'success',
          })
          onSuccess && onSuccess()
        } else {
          enqueueSnackbar(result.message, {
            variant: 'error',
          })
        }
      },
    },
  )
  // convert time slots (start time + duration) to time intervals (start time + end time)
  const _intervals = (initialTimeSlots || []).map(ts => {
    return {
      id: uniqueId('interval_'),
      day: ts.day,
      start: DateTime.fromISO(ts.startTime),
      end: DateTime.fromISO(ts.endTime),
    }
  })

  const [intervals, setIntervals] = useState(_intervals)
  const [selectedDay, setSelectedDay] = useState(
    initialSelectedDay || daysOfWeek[0],
  )
  const [daysToReplicate, setDaysToReplicate] = useState(null)

  const intervalsToDisplay = intervals.filter(i => i.day === selectedDay)
  const hasEmptyIntervalToDisplay = intervalsToDisplay.some(
    i => i.start === null || i.end === null,
  )

  const validationErrors = validateIntervals(intervalsToDisplay)

  const containsOverlappingIntervals = Object.keys(validationErrors).some(
    id => validationErrors[id].isOverlap,
  )
  const isDayInvalid = Object.keys(validationErrors).length > 0
  const showApplyToOtherDaysButton = intervalsToDisplay?.length > 0

  const updateInterval = (id, start, end) => {
    if (!start?.isValid && !end?.isValid) return

    const idxToUpdate = intervals.findIndex(i => i.id === id)
    const updatedIntervals = [
      ...intervals.slice(0, idxToUpdate),
      {
        ...intervals[idxToUpdate],
        start,
        end,
      },
      ...intervals.slice(idxToUpdate + 1),
    ]
    setIntervals(updatedIntervals)
  }

  const insertIntervalEnhanced = () => {
    const newIntervalStartHours =
      intervalsToDisplay.length > 0
        ? intervalsToDisplay[intervalsToDisplay.length - 1].end?.hour + 1
        : null
    const startDate =
      intervalsToDisplay.length > 0
        ? DateTime.local()
          .startOf('day')
          .plus({ hours: newIntervalStartHours })
        : null
    const newInterval = {
      id: uniqueId('interval_'),
      day: selectedDay,
      start: startDate,
      end: null,
    }
    const updatedIntervals = [...intervals, newInterval]
    setIntervals(updatedIntervals)
  }

  const deleteInterval = id => {
    const idxToDelete = intervals.findIndex(i => i.id === id)
    const updatedIntervals = [
      ...intervals.slice(0, idxToDelete),
      ...intervals.slice(idxToDelete + 1),
    ]
    setIntervals(updatedIntervals)
  }

  const onApplyToOtherDaysClick = () => {
    setDaysToReplicate(
      Object.fromEntries(daysOfWeek.map(d => [d, d === selectedDay])),
    )
  }

  const onApplyToOtherDaysBackClick = () => setDaysToReplicate(null)

  const onChangeDaysToReplicate = event => {
    setDaysToReplicate({
      ...daysToReplicate,
      [event.target.name]: event.target.checked,
    })
  }

  const onSubmit = () => {
    let timeSlots = intervals.map(i => ({
      day: i.day,
      startTime: i.start.toFormat('HH:mm:00'),
      endTime: i.end.toFormat('HH:mm:00'),
    }))
    if (daysToReplicate != null) {
      const timeSlotsToReplicate = timeSlots.filter(
        ts => ts.day === selectedDay,
      )
      // filter out any existing intervals for days we're replicating to
      timeSlots = timeSlots.filter(ts => !daysToReplicate[ts.day])
      // replicate time slots to selected days
      Object.keys(daysToReplicate)
        .filter(d => daysToReplicate[d])
        .forEach(d => {
          const replicaTimeSlots = timeSlotsToReplicate.map(ts => {
            return {
              day: d,
              startTime: ts.startTime,
              endTime: ts.endTime,
            }
          })
          timeSlots = [...timeSlots, ...replicaTimeSlots]
        })
    }

    setCoachAvailability({
      variables: {
        input: {
          startDate: DateTime.local().toISODate(),
          timeZone: facilityTimezone,
          timeSlots,
        },
        coachFacilityId: coachFacilityId,
      },
    })

    if (user.onboarding) {
      history.push('/pga-coach/set-up-schedule')
    }
  }

  const EditDayView = () => (
    <>
      <Box mb={4}>
        <Tooltip
          title={isDayInvalid ? ERROR_TOOLTIP_MESSAGE : ''}
          arrow
          placement="bottom"
        >
          <FormControl variant="outlined" disabled={isDayInvalid}>
            <InputLabel id="selected-day-label">Day</InputLabel>
            <Select
              labelId="selected-day-label"
              id="selected-day"
              value={selectedDay}
              onChange={e => setSelectedDay(e.target.value)}
              label="Day"
            >
              {daysOfWeek.map(d => (
                <MenuItem key={d} value={d}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {d.toLowerCase() + 's'}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
      </Box>
      {description}
      {intervalsToDisplay.length > 0 &&
        intervalsToDisplay.map(i => (
          <Box key={i.id} mb={2}>
            <Box display="flex">
              <Box mr={2} flexBasis={inputWidth}>
                <PgaMobileTimePicker
                  id="from"
                  label="From"
                  data-cy="timeFrom"
                  value={i.start}
                  validationErrors={validationErrors[i.id]}
                  onAccept={val => updateInterval(i.id, val, i.end)}
                />
              </Box>
              <Box mr={1} flexBasis={inputWidth}>
                <PgaMobileTimePicker
                  id="to"
                  label="To"
                  data-cy="timeTo"
                  value={i.end}
                  validationErrors={validationErrors[i.id]}
                  onAccept={val => updateInterval(i.id, i.start, val)}
                />
              </Box>
              <IconButton
                aria-label="delete"
                data-cy="delete"
                color="primary"
                onClick={() => deleteInterval(i.id)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>
            {validationErrors[i.id] && validationErrors[i.id].isInvalid && (
              <FormHelperText error>
                {INVALID_INTERVAL_ERROR_MESSAGE}
              </FormHelperText>
            )}
          </Box>
        ))}
      {showApplyToOtherDaysButton && (
        <Box mb={4}>
          <Tooltip
            title={isDayInvalid ? ERROR_TOOLTIP_MESSAGE : ''}
            arrow
            placement="top"
          >
            <span>
              <Button
                color="primary"
                data-cy="applyToOtherDays"
                onClick={onApplyToOtherDaysClick}
                disabled={isDayInvalid}
                endIcon={<ArrowRightAltIcon />}
              >
                Apply to other days
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
      <Box mb={1}>
        <Button
          style={{
            minWidth: 'revert-layer',
            padding: '11px',
          }}
          aria-label="add"
          data-cy="addAvailabilityButton"
          variant="outlined"
          color="primary"
          onClick={insertIntervalEnhanced}
          disabled={hasEmptyIntervalToDisplay}
        >
          <AddOutlinedIcon />
          {intervalsToDisplay.length > 0 && <span>&nbsp;Add More Time</span>}
        </Button>
      </Box>
      {containsOverlappingIntervals && (
        <Box mb={1}>
          <FormHelperText error>
            {OVERLAPPING_INTERVALS_ERROR_MESSAGE}
          </FormHelperText>
        </Box>
      )}
    </>
  )

  const ReplicateDaysView = () => (
    <>
      <Box mb={2}>
        <Button
          onClick={onApplyToOtherDaysBackClick}
          startIcon={
            <ArrowRightAltIcon style={{ transform: 'rotate(180deg)' }} />
          }
        >
          Go back
        </Button>
      </Box>
      <Box mb={2}>
        <Typography variant="body1" style={{ fontWeight: '500' }}>
          Availability for{' '}
          {selectedDay.charAt(0).toUpperCase() +
            selectedDay.slice(1).toLowerCase()}
          :
        </Typography>
      </Box>
      {intervalsToDisplay.length > 0 &&
        intervalsToDisplay.map(i => (
          <Box key={i.id} mb={2}>
            <Box display="flex">
              <Box mr={4} flexBasis="110px">
                <DesktopTimePicker
                  label="From"
                  value={i.start}
                  disabled
                  disableOpenPicker
                />
              </Box>
              <Box mr={2} flexBasis="110px">
                <DesktopTimePicker
                  label="To"
                  value={i.end}
                  disabled
                  disableOpenPicker
                />
              </Box>
            </Box>
          </Box>
        ))}
      {intervalsToDisplay.length === 0 && (
        <Typography
          variant="body1"
          style={{ marginBottom: '32px', fontWeight: '500' }}
        >
          You are currently unavailable on this day.
        </Typography>
      )}
      <Box mb={1}>
        <Typography variant="body1">
          Select additional days to apply these times to:
        </Typography>
      </Box>
      <Box mx={1} mb={3}>
        <FormControl component="fieldset">
          <FormGroup>
            {daysOfWeek.map(d => (
              <FormControlLabel
                key={d}
                control={
                  <Checkbox
                    color="primary"
                    checked={daysToReplicate[d]}
                    onChange={onChangeDaysToReplicate}
                    name={d}
                  />
                }
                label={d.charAt(0).toUpperCase() + d.slice(1).toLowerCase()}
                disabled={selectedDay === d}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </>
  )

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale="en-us">
      <>
        {!daysToReplicate && <EditDayView />}
        {daysToReplicate && <ReplicateDaysView />}
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row-reverse"
        >
          <Tooltip
            title={isDayInvalid ? ERROR_TOOLTIP_MESSAGE : ''}
            arrow
            placement="top"
          >
            <Box mr={0}>
              <Button
                data-cy="save"
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  isDayInvalid || isSubmitting || hasEmptyIntervalToDisplay
                }
                onClick={onSubmit}
              >
                Save
              </Button>
            </Box>
          </Tooltip>
          {onCancel && (
            <Button
              color="primary"
              size="large"
              onClick={onCancel}
              style={{ marginRight: '16px' }}
            >
              Cancel
            </Button>
          )}
        </Box>
      </>
    </LocalizationProvider>
  )
}

export default EditAvailabilityForm
