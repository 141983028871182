import React from 'react'
import { useFormContext } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

const EventName = () => {
  const { register, errors } = useFormContext()

  return (
    <TextField
      name="title"
      required={true}
      inputRef={register}
      error={!!errors.title}
      helperText={errors.title && 'This field is required'}
      label="Offering name"
    />
  )
}

export default EventName
