import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Fab from '@material-ui/core/Fab'
import FacebookIcon from '@material-ui/icons/Facebook'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import InstagramIcon from '@material-ui/icons/Instagram'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
// import YouTubeIcon from '@material-ui/icons/YouTube'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { HashLink } from 'react-router-hash-link'
import CoachSvg from 'images/icons/fc-marketing/coach.svg'
import PlayerSvg from 'images/icons/fc-marketing/player.svg'
import { footerMenus, urls } from './menus'
import useIsMobile from 'components/program-marketing/useIsMobile'
import { XIcon } from 'components/x-share-button/x-icon'
import PGALogo from 'images/pga-logo-white.png'

const useStyles = makeStyles(theme => ({
  loginButtonArrow: {
    width: '24px',
    height: '18px',
    color: '#222222',
    margin: '0 4px',
  },
  link: {
    opacity: 0.8,
    color: theme.palette.primary.contrastText,
    display: 'block',
    fontWeight: 700,
    marginBottom: '4px',
    marginRight: '48px',
    marginLeft: '7px',
    fontSize: '1rem',
  },
  linkWhite: {
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    display: 'block',
    fontWeight: 700,
    marginRight: '24px',
  },
  socialIcon: {
    opacity: 0.5,
    color: theme.palette.primary.contrastText,
    marginLeft: '9px',
    marginRight: '9px',
    width: '0.9em',
    height: '0.9em',
  },
  footerSectionBg: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.primary.contrastText,
    paddingTop: '72px',
  },
  grid: {
    alignItems: 'start',
    gridColumnGap: '48px',
    gridRowGap: '0px',
    gridTemplateRows: 'auto',
  },
  gridItem: {
    gridColumnGap: '0px',
    gridRowGap: '6px',
    gridTemplateColumns: '1fr',
  },
  fab: {
    height: '40px',
    width: '40px',
    background: '#ff7500',
    boxShadow: 'none',
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'relative',
    float: 'right',
  },
  buttonLink: {
    padding: '18px 24px',
  },
  socialLink: {
    // width: '30px',
  },
  container: {
    '@media (min-width:1310px)': {
      maxWidth: '1310px',
    },
  },
}))

const MenuLink = ({ href, children }) => {
  const classes = useStyles()
  return (
    <Link href={href} className={classes.link}>
      {children}
    </Link>
  )
}

const Footer = () => {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <Box px={isMobile ? 0 : 4} className={classes.footerSectionBg}>
      <Container className={classes.container}>
        <Grid container justify="center">
          <Grid item sm={12} md={12}>
            <Grid
              container
              spacing={0}
              justify={isMobile ? 'center' : 'space-between'}
              direction={isMobile ? 'column' : 'row'}
            >
              <Grid item sm={12} md={3}>
                <Box>
                  <a href="https://www.pga.com">
                    <img
                      src={PGALogo}
                      alt="PGA Logo"
                      style={{ width: '60px' }}
                    />
                  </a>
                </Box>
                <Typography
                  variant="body1"
                  style={{ lineHeight: '1.5', marginTop: '14px' }}
                >
                  PGA Family Golf connects families through golf in a new, fun
                  and meaningful way.
                </Typography>
              </Grid>
              <Grid item sm={1} md={1}></Grid>
              <Grid item sm={12} md={4} style={{ display: 'flex' }}>
                <Grid item>
                  {footerMenus.left.map(({ text, url }) => (
                    <MenuLink key={text} href={url}>
                      {text}
                    </MenuLink>
                  ))}
                </Grid>
                <Grid item style={{ marginLeft: '10px' }}>
                  {footerMenus.right.map(({ text, url }) => (
                    <MenuLink key={text} href={url}>
                      {text}
                    </MenuLink>
                  ))}
                </Grid>
              </Grid>
              <Grid item sm={12} md={4} style={{ paddingLeft: '16px' }}>
                <ButtonGroup
                  orientation="vertical"
                  color="primary"
                  variant="text"
                  fullWidth
                >
                  <Button
                    component={Link}
                    href={urls.coachLogin}
                    className={classes.buttonLink}
                    startIcon={
                      <img
                        src={CoachSvg}
                        alt="coach"
                        style={{
                          width: '36px',
                          height: '36px',
                          marginLeft: '4px',
                          marginRight: '10px',
                        }}
                      />
                    }
                    endIcon={
                      <ArrowForwardIosIcon
                        className={classes.loginButtonArrow}
                      />
                    }
                  >
                    Coach Login
                  </Button>
                  <Button
                    component={Link}
                    href={urls.playerLogin}
                    className={classes.buttonLink}
                    startIcon={
                      <img
                        src={PlayerSvg}
                        alt="player"
                        style={{
                          width: '36px',
                          height: '36px',
                          marginLeft: '4px',
                          marginRight: '10px',
                        }}
                      />
                    }
                    endIcon={
                      <ArrowForwardIosIcon
                        className={classes.loginButtonArrow}
                      />
                    }
                  >
                    Player Login
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={isMobile ? '6' : '0'}
              justify={isMobile ? 'center' : 'space-between'}
              style={{ marginTop: isMobile ? '20px' : '120px' }}
            >
              <Grid
                item
                sm={12}
                md={10}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isMobile ? '10px' : 'initial',
                }}
              >
                {footerMenus.bottom.map(({ text, url }) => (
                  <Link key={text} href={url} className={classes.linkWhite}>
                    {text}
                  </Link>
                ))}
                <Typography style={{ fontSize: '14px' }}>
                  &copy; Copyright {new Date().getFullYear()} | PGA Family Golf
                </Typography>
              </Grid>
              <Grid
                item
                sm={12}
                md={2}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginRight: '-10px',
                }}
              >
                <Link href={urls.twitter}>
                  <XIcon
                    className={classes.socialIcon}
                    style={{
                      height: '14px',
                      width: '14px',
                      marginBottom: '2px',
                    }}
                  />
                </Link>
                <Link href={urls.facebook}>
                  <FacebookIcon className={classes.socialIcon} />
                </Link>
                <Link href={urls.instagram}>
                  <InstagramIcon className={classes.socialIcon} />
                </Link>
                {/* <Link href={urls.youtube}>
                  <YouTubeIcon className={classes.socialIcon} />
                </Link> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Fab
        to="#header"
        smooth
        component={HashLink}
        color="primary"
        aria-label="add"
        className={classes.fab}
      >
        <ExpandLessIcon />
      </Fab>
    </Box>
  )
}

export default Footer
