import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Error from 'components/error'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
  InputsTwoColumns,
  CheckboxItemWrapper,
  ErrorMsg,
} from '../profile.styles'
import { formFields } from '../constants'
import { findIndex, some } from 'lodash'

const ServicesQuery = gql`
  query Services {
    Services {
      message
      success
      serviceTypes {
        serviceTypeId
        typeName
        description
        services {
          serviceId
          serviceName
          serviceType
        }
      }
    }
  }
`

const isChecked = (serviceId, services) => some(services, { serviceId })

const toggleServiceChecked = (serviceId, services, push, remove) => {
  isChecked(serviceId, services)
    ? remove(findIndex(services, { serviceId }))
    : push({ serviceId })
}

const Services = props => {
  const { values, errors } = props
  const checkedServices = values.services || []

  const { data, loading, error } = useQuery(ServicesQuery)
  if (error) {
    return <Error error={error} displayErrorType="page" />
  }
  if (loading) return <LinearProgress color="secondary" />
  const response = data && data.Services && data.Services.serviceTypes

  return response ? (
    <div>
      <Typography variant="body2" color="textSecondary">
        Choose any of these pre-populated options to help market your business and specialities!
      </Typography>
      {errors.services && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>{errors.services}</ErrorMsg>
      )}
      <FieldArray name="services">
        {({ push, remove }) => {
          return data.Services.serviceTypes.map(serviceType => {
            return (
              <Box key={serviceType.typeName} pt={3}>
                <Typography variant="subtitle1">
                  {serviceType.typeName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {serviceType.description}
                </Typography>
                <InputsTwoColumns>
                  {serviceType.services.map(service => {
                    return (
                      <CheckboxItemWrapper key={service.serviceId}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={`serv-checkbox-${serviceType}-${service.serviceName}`}
                              className="checkbox"
                              checked={isChecked(
                                service.serviceId,
                                checkedServices,
                              )}
                              onChange={() =>
                                toggleServiceChecked(
                                  service.serviceId,
                                  checkedServices,
                                  push,
                                  remove,
                                )
                              }
                              value={service.serviceId}
                              color="secondary"
                            />
                          }
                          label={service.serviceName}
                          htmlFor={`serv-checkbox-${serviceType}-${service.serviceName}`}
                        />
                      </CheckboxItemWrapper>
                    )
                  })}
                </InputsTwoColumns>
              </Box>
            )
          })
        }}
      </FieldArray>
    </div>
  ) : (
    <Error />
  )
}

Services.propTypes = {
  values: formFields,
  errors: PropTypes.object,
}

Services.defaultProps = {}

export default Services
