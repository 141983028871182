import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #d6d6d6',
    borderRadius: '5px',
    padding: '5px',
  },
  infoIcon: {
    paddingRight: '5px',
    color: theme.palette.primary.main,
  },
  infoText: {
    color: '#757575',
  },
  intercomText: {
    color: '#757575',
  },
  contact_button: {
    letterSpacing: 'unset',
    fontSize: '16px',
    textTransform: 'unset',
    fontWeight: '500',
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    backgroundColor: 'white',
  },
}))
