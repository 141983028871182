import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@material-ui/core/Button'
import {
  styled,
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'
import CoachingCenterLogo from 'images/pga-cc-logo.svg'
import { IconButtonBackArrow } from '../atoms/icons'
import themes from 'themes'
import CoachingCenterAgreeSection from './cc-agree-section'

const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(6),
    '& svg': {
      transition: '0.1s ease-in-out',
    },
    '&:focus': {
      color: theme.palette.secondary.dark,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg': {
        transform: 'translateX(-4px)',
      },
    },
    '&:active': {
      color: theme.palette.primary.lightest,
    },
  },
}))

const CCLogo = styled('img')(({ theme }) => ({
  width: '290px',
  [theme.breakpoints.down('xs')]: {
    width: '240px',
  },
}))

const Heading = styled('p')(({ theme }) => ({
  fontFamily: 'Source Serif Pro',
  fontSize: '24px',
  fontWeight: '700',
  lineHeight: '30px',
  [theme.breakpoints.down('xs')]: {
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'center',
  },
}))

const WaiverWrapper = styled('div')(({ theme, withBackButton }) => ({
  backgroundColor: 'white',
  width: '525px',
  borderRadius: theme.spacing(2),
  marginTop: withBackButton ? 0 : theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: '66px 36px',
  [theme.breakpoints.down('xs')]: {
    width: 'auto',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const CoachingCenterWaiverContent = ({ showHeader = true }) => {
  return (
    <>
      {showHeader && (
        <>
          <Box display="flex" justifyContent="center">
            <CCLogo src={CoachingCenterLogo} alt="pga-coaching-center-logo" />
          </Box>
          <Heading>
            Assumption of Risk, Waiver and Release of Liability, and Indemnity
            Agreement
          </Heading>
        </>
      )}
      <Typography paragraph variant="body2">
        DECLARATIONS: This Assumption of Risk, Waiver and Release of Liability,
        and Indemnity Agreement (“Agreement”) is entered into by the undersigned
        (“Client”) for the express and exclusive benefit of PGA Golf Properties,
        Inc., a Florida corporation, its affiliates, its parents, and each of
        its and their officers, directors, managers, employees, and agents
        (collectively “PGA”). The provision of any and all services by PGA,
        including, but not limited to, by its employees and authorized agents,
        and Client’s use of any PGA premises or facilities (as defined below),
        online/remote fitness or golf programs, or equipment, are all subject to
        the terms of this Agreement.
      </Typography>
      <Typography paragraph variant="body2">
        ASSUMPTION OF RISK: Client agrees that if Client engages in any
        activity, enters or uses the premises or facilities at the PGA Coaching
        Center, PGA Headquarters, Fields Ranch, and/or PGA Frisco (collectively,
        the “PGA Premises”) for any purpose, or uses any equipment at the PGA
        Premises, Client does so at their own risk and assumes all risk of any
        and all injury and/or damage of any kind whatsoever that Client may
        suffer, whether before, during, or after such engagement, entry, or use,
        including, but not limited to, any injury and/or damage of any kind
        whatsoever arising out of the negligence of PGA or other users of the
        PGA Premises, and further, Client acknowledges and agrees that Client's
        assumption of risk includes, but is not limited to: Client’s use of any
        equipment (mechanical, golf training, exercise-related, or otherwise),
        golf clubs, or related equipment (whether Client’s, PGA’s, or a
        third-party’s), hitting or putting bays, simulators, studios, ranges,
        locker rooms, sidewalks, parking lots, stairs, lobby or other general
        areas of the PGA Premises, and Client's participation in any individual
        or group activity, class, program, instruction, or event, including but
        not limited to weightlifting, walking, jogging, running, aerobic and
        anaerobic activities, strength and conditioning activities, aquatic
        activities, yoga, pilates, massage, golf play, golf lessons, training
        and conditioning activities, golf club fitting, or any related
        activities or endeavors of any kind whatsoever conducted on or in the
        PGA Premises (collectively, the “Assumed Risk Activities”).
      </Typography>
      <Typography paragraph variant="body2">
        RELEASE AND INDEMNIFICATION: Client agrees on behalf of themself and all
        of Client’s personal representatives, heirs, executors, administrators,
        agents, and assigns to fully, absolutely, and unconditionally release,
        discharge, hold harmless, and indemnify PGA from any and all claims,
        demands, actions, suits, damages, judgments, and causes of action of any
        kind and of whatsoever nature (collectively “Claims”), whether known or
        unknown, fixed or contingent, suspected or unsuspected, or whether
        having arisen or hereafter to arise, which Client ever had, now has,
        claims to have, or may have in the future against PGA, for, upon, or by
        reasons of any act, omission, other conduct, matter, event, or cause
        whatsoever, in any way arising from, related to, or in connection with
        (a) the Assumed Risk Activities, (b) instruction or supervision by PGA,
        (c) negligent hiring or retention of employees, and/or (d)
        slipping/tripping/falling while on or in any portion of the PGA Premises
        or while traveling to or from the PGA Premises, including injuries
        resulting from PGA’s or anyone else’s negligent inspection or
        maintenance of PGA’s premises or facilities.
      </Typography>
      <Typography paragraph variant="body2">
        This Agreement is not and is not intended as a release or attempted
        release of claims of gross negligence or intentional acts.
      </Typography>
      <Typography paragraph variant="body2">
        ACKNOWLEDGMENTS: Client expressly agrees that the foregoing assumption
        of risk, release, and indemnity is intended to be as broad and inclusive
        as permitted by the law in the State of Texas and that if any portion
        thereof is held invalid, it is agreed that the balance shall,
        notwithstanding, continue in full legal force and effect. Client
        expressly agrees to: (a) be solely responsible for Client’s own safety
        and well-being, (b) comply with all rules imposed by PGA regarding the
        use of the PGA Premises and the engagement in any activity at, on, or in
        the PGA Premises or through PGA, (c) conduct themself in a controlled
        and reasonable manner at all times, and (d) refrain from using any
        portion of PGA's premises or facilities or any equipment of any kind in
        a manner inconsistent with its intended design and purpose. Client
        understands, acknowledges, and agrees that the use of any equipment of
        any kind at, on, or in PGA’s premises or facilities involves risk of
        serious injury, including permanent disability and death. Client further
        understands, acknowledges, and agrees that PGA is not responsible for
        property that is lost, stolen, or damaged while in, on, or about the PGA
        Premises.
      </Typography>
      <Typography paragraph variant="body2">
        PHOTOGRAPHS/VIDEO/AUDIO: Client gives permission for PGA to use, without
        limitation or obligation, photographs, film, video, and/or audio
        recordings that may include Client’s image, likeness, and/or voice for
        purposes of promoting PGA, the PGA Coaching Center, and/or the PGA
        Premises via print, digital, and online channels with or without
        Client’s direct or indirect knowledge or consent.
      </Typography>
      <Typography paragraph variant="body2">
        Client acknowledges and agrees that (a) Client has carefully read this
        Agreement, (b) Client fully understands its terms and their impact on
        Client, (c) Client fully understands that Client is giving up certain
        legal rights, (d) Client is voluntarily executing this Agreement
        intending to be fully bound by all its terms, and (e) that no oral
        representations, statements, or inducements apart from the foregoing
        written agreement have been made.
      </Typography>
      <Typography paragraph variant="body2">
        I hereby certify that (a) I am the parent or legal guardian of Client;
        (b) if Client is a minor under applicable law, I have full legal
        capacity to execute this Agreement on behalf of Client; (c) regardless
        of whether Client is a minor, I am executing this Agreement in
        confirmation of my understanding and agreement to the terms of this
        Agreement; and (d) I have read and fully and completely understand and
        unconditionally agree to the terms set forth in this Agreement.
      </Typography>
    </>
  )
}
export default function CCWaiver({
  form,
  showWaiver,
  onSubmit,
  withBackButton = false,
  setShowWaiver = () => {},
}) {
  const classes = useStyles()
  const { handleSubmit, setValue } = form
  const fieldName = 'agreeWaiver'

  const handleClick = () => {
    setValue(fieldName, !!form.getValues(fieldName), { shouldValidate: true })
    handleSubmit(onSubmit)()
  }
  return (
    <ThemeProvider theme={createTheme(themes.offeringsRefresh)}>
      <Container disableGutters>
        <Button
          startIcon={<IconButtonBackArrow />}
          color="primary"
          onClick={() => {
            setShowWaiver(false)
          }}
          className={classes.backButton}
          style={{ display: withBackButton && showWaiver ? 'flex' : 'none' }}
        >
          Back
        </Button>
        <Container
          maxWidth="sm"
          style={{ display: showWaiver ? 'block' : 'none' }}
        >
          <WaiverWrapper withBackButton={withBackButton}>
            <CoachingCenterWaiverContent />
            <CoachingCenterAgreeSection form={form} handleClick={handleClick} />
          </WaiverWrapper>
        </Container>
      </Container>
    </ThemeProvider>
  )
}
