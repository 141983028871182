import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'

const StatusModal = ({ text, open, setOpen, toggleStatus }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={() => setOpen(!open)}>
      {loading && <LinearProgress />}
      {error && (
        <Typography variant="body1" className={classes.errorTxt}>
          Oops! Something went wrong, please try again later.
        </Typography>
      )}
      <DialogTitle align="center">{text.title}</DialogTitle>
      <DialogContent align="center">
        <Typography variant="body1" className={classes.bodyTxt}>
          {text.body1}
        </Typography>
        <Typography variant="body1" className={classes.bodyTxt}>
          {text.body2}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(!open)}
          disabled={loading}
          className={classes.buttonStyle}
          size="medium"
        >
          CANCEL
        </Button>
        <Button
          onClick={() => toggleStatus(setOpen, setLoading, setError)}
          disabled={loading}
          className={classes.buttonStyle}
          variant="contained"
          color="primary"
          size="medium"
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatusModal.propTypes = {
  text: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  toggleStatus: PropTypes.func,
}

export default StatusModal
