import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '48px 0 48px',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  badge: {
    height: '54px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      height: '45px',
      marginBottom: theme.spacing(2),
    },
  },
  badgeContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
}))
