import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import Right from '@material-ui/icons/KeyboardArrowRight'
import Left from '@material-ui/icons/KeyboardArrowLeft'
import { useTheme } from '@material-ui/core/styles'
import { PaginationStyles } from './pagination.styles'

const Pagination = (props) => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Box pt={[4, 6, 8]}>
      <PaginationStyles
        {...props}
        reduced={small}
        size={small ? 'small' : 'medium'}
        otherPageColor="inherit"
        currentPageColor="secondary"
        previousPageLabel={<Left aria-label="previous page" />}
        nextPageLabel={<Right aria-label="next page" />}
      />
    </Box>
  )
}

Pagination.propTypes = {
  width: PropTypes.string,
}

Pagination.defaultProps = {}

export default Pagination
