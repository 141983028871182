import React from 'react'
import TextFieldWithError from 'components/form-util/text-field-with-error'
import { Controller } from 'react-hook-form'

export default function CtrlTextField({
  control,
  name,
  errors,
  controllerProps,
  ...other
}) {
  return (
    <Controller
      {...controllerProps}
      as={<TextFieldWithError {...other} errors={errors} />}
      control={control}
      name={name}
    />
  )
}
