import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import MaskedInputContainer from './masked-input-container'
import uniqueId from 'lodash/uniqueId'

function ZipMaskedField({ inputRef, ...props }) {
  return (
    <MaskedInput
      {...props}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      data-testid="zip-code"
    />
  )
}

export default function Zip({
  id,
  value,
  onChange,
  label,
  name,
  inputRef,
  required,
  error,
  helperText,
  defaultValue,
  disabled,
}) {
  const [uid] = useState(() => uniqueId('zip-'))

  const actualId = id || uid
  return (
    <MaskedInputContainer
      {...{
        id: actualId,
        inputComponent: ZipMaskedField,
        value,
        onChange,
        label,
        name,
        inputRef,
        required,
        error,
        helperText,
        defaultValue,
        disabled,
      }}
    />
  )
}
