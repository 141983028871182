import React from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core'
import useStyles from './styles'
import AccountRouterLink from 'components/account-router-link'
import { Controller } from 'react-hook-form'
import ExpirationDurationDropdown from './expiration-duration-dropdown'
import BookingTypePriceQuantity from './booking-type-price-quantity'
import BookingTypePrice from './booking-type-price'
import blue from '@material-ui/core/colors/blue'
import NoPaymentAccounts from './no-payment-accounts'

const BookingTypePaymentOptions = ({
  errors,
  control,
  register,
  isLessonPacks,
  paymentAccounts,
  singleLessonPrice,
}) => {
  const classes = useStyles()
  return (
    <>
      {!isLessonPacks ? (
        <BookingTypePrice register={register} errors={errors} />
      ) : (
        <>
          <BookingTypePriceQuantity singleLessonPrice={singleLessonPrice} />
          <ExpirationDurationDropdown />
        </>
      )}
      <Box className={classes.formField}>
        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>
            Payment Account {!isLessonPacks && '(Optional)'}
          </Typography>
          {!isLessonPacks ? (
            <Typography variant="body2" gutterBottom>
              Select a payment account to collect registration fees online for
              this event. If no payment account is selected, upfront payments
              will not be required.
            </Typography>
          ) : (
            <Typography variant="body2" gutterBottom>
              Select a payment account to collect online payments for this
              Lesson Pack. Upfront payments are required in order to offer a
              lesson pack.
            </Typography>
          )}
        </Box>
        {paymentAccounts.length > 0 || !isLessonPacks ? (
          <>
            <FormControl error={!!errors.paymentAccountId} variant="outlined">
              <InputLabel id="category">Select payment account</InputLabel>
              <Controller
                defaultValue=""
                as={
                  <Select
                    labelId="paymentAccount"
                    data-cy="paymentAccount"
                    label="Select paymentAccount"
                    value=""
                    defaultValue=""
                  >
                    {paymentAccounts.map(opt => (
                      <MenuItem key={opt.id} value={opt.id} data-cy={opt.last4}>
                        {opt.name} – x{opt.last4}
                      </MenuItem>
                    ))}
                    <MenuItem key="none" value="">
                      <em>None</em>
                    </MenuItem>
                  </Select>
                }
                name="paymentAccountId"
                control={control}
              />
            </FormControl>
            <Button
              color="primary"
              size="large"
              to="/account/payments/payment-accounts"
              component={AccountRouterLink}
              style={{ marginTop: '24px' }}
            >
              Manage Payment Accounts
            </Button>
          </>
        ) : (
          <NoPaymentAccounts errors={errors.paymentAccountId} />
        )}
      </Box>

      {paymentAccounts.length > 0 && (
        <Box
          p={1}
          mb={4}
          borderRadius={4}
          style={{ backgroundColor: blue[50] }}
        >
          <Typography variant="body2">
            Please note that a 2.9% + $0.30 fee will be applied to the
            transaction total.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default BookingTypePaymentOptions
