import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import Error from 'components/error'
import { withAuthenticatedRoute, useAuth, LoginRedirect } from 'lib/auth'
import { useHistory, useParams } from 'react-router-dom'
import { SIGN_WAIVER_MUTATION, WAIVER_QUERY } from './queries'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import { Helmet } from 'react-helmet'
import { useSnackbar } from 'notistack'
import { CoachingCenterWaiverContent } from 'components/waivers/cc-waiver'
import { Wrapper } from './styles'
import { useIsMobile } from 'lib/hooks'
import CoachAppBar from 'components/coach-app-bar'

const WaiverAgreement = ({ waiverId }) => {
  const [agreedToWaiver, setAgreedToWaiver] = useState(false)
  const isMobile = useIsMobile()
  const { data, loading, error } = useQuery(WAIVER_QUERY, {
    variables: { id: waiverId },
  })
  const [signWaiver] = useMutation(SIGN_WAIVER_MUTATION)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const redirectToClub = duration => {
    setTimeout(() => {
      history.push('/club')
    }, duration)
  }

  const handleSubmit = () => {
    signWaiver({
      variables: {
        waiverId: waiverId,
      },
    }).then(({ data }) => {
      if (data.signWaiver.success) {
        enqueueSnackbar('Waiver signed!', { variant: 'success' })
        redirectToClub(2000)
      }
    })
  }

  if (loading) return <LinearProgress />
  if (error) return <Error error={error} />

  const handleChange = event => {
    setAgreedToWaiver(event.target.checked)
  }

  const isWaiverSigned = data.waiver?.signed

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <Helmet>
        <title>PGA Coaching Center</title>
        <meta name="descirption" content="PGA Coaching Center" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <Box
        bgcolor={!isMobile && `${'grey.100'}`}
        style={{ minHeight: '100vh' }}
        display="flex"
        pt={2}
      >
        <CoachAppBar />
        <Container maxWidth="sm">
          <Box pt={4} flex={1}>
            <Wrapper>
              {isWaiverSigned ? (
                <>
                  <Typography>Waiver signed! Redirecting...</Typography>
                  {redirectToClub(3000)}
                </>
              ) : (
                <>
                  <CoachingCenterWaiverContent />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreedToWaiver}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography>
                        I have read and agree to Assumption of Risk, Waiver and
                        Release of Liability, and Indemnity Agreement
                      </Typography>
                    }
                  />
                  <Box display="flex" justifyContent="center" my={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!agreedToWaiver}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </>
              )}
            </Wrapper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

const WaiverPage = () => {
  const { waiverId } = useParams()
  const { isLoggedIn, isConsumer } = useAuth()

  if (isLoggedIn && isConsumer) {
    return <WaiverAgreement waiverId={waiverId} />
  }

  return <LoginRedirect role="consumer" />
}

export default withAuthenticatedRoute(WaiverPage)
