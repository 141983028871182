import React, { useEffect } from 'react'
import auth0 from './client'
import LinearProgress from '@material-ui/core/LinearProgress'

const redirectToSignup = async (
  role,
  returnTo = window.location.href,
  _signupParams = {},
) => {
  // redirect to the signup page
  await auth0.loginWithRedirect({
    authorizationParams: {
      scope: `openid profile email offline_access api:${role}`,
      screen_hint: 'signup',
    },
    appState: { returnTo },
  })
}
const SignupRedirect = ({
  role,
  returnTo,
  signupParams, // TBD if we need this
}) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    redirectToSignup(role, returnTo, signupParams)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return <LinearProgress color="secondary" />
}

export default SignupRedirect
export { redirectToSignup }
