import React, { useEffect } from 'react'
import {
  Window,
  MessageList,
  MessageInput,
  useMessageInputContext,
  MessageInputFlat,
  useMessageContext,
  MessageSimple,
  useChannelActionContext,
  Attachment,
  Card,
} from 'stream-chat-react'
import ChannelHeader from './channel-header'
import useQueryString from 'lib/hooks/use-query-string'
import {
  GroupEventAttachmentCard,
  LessonTypeAttachmentCard,
} from './components'
import { OUR_TYPES, prepareCustomAttachment, replaceMentions } from './utils'
import { useAttachmentContext } from './use-attachment-context'
import SwingsVideoPlaceholder from './components/swings-video-placeholder'

const UpdatedCard = props => {
  switch (props?.type) {
    case 'pga_group_event':
      return <GroupEventAttachmentCard {...props} />
    case 'pga_lesson_type':
      return <LessonTypeAttachmentCard {...props} />
    case 'pga_student_library_upload':
      // This is a stopgap effort. In the future, we may instead support displaying the video.
      return <SwingsVideoPlaceholder />
    default:
      return <Card {...props} />
  }
}

/* Note that Stream for web encapsulates all non-standard attachment types as a Card. */
export const CustomAttachment = props => {
  return <Attachment {...props} Card={UpdatedCard} />
}

export const CustomMessage = () => {
  let { message } = useMessageContext()
  const { attachments } = message
  let customAttachment = false
  let hasUrlAttachment = false

  // Listed Group Events may be scraped and generate a URL preview. This causes TWO preview cards to appear.
  // This checks for the presence of our custom attachment and an URL attachment and prioritizes ours.
  attachments.length > 0 &&
    attachments.forEach(att => {
      if (OUR_TYPES.includes(att.type)) customAttachment = att
      if (att.og_scrape_url) hasUrlAttachment = true
    })

  if (customAttachment && hasUrlAttachment) {
    message.attachments = [customAttachment]
  }

  if (message.mentioned_users.length > 0) {
    message = replaceMentions({ message: message })
  }

  return <MessageSimple message={message} />
}

const UserMessageInput = () => {
  const { setText } = useMessageInputContext()
  const { shared } = useQueryString()
  const prompt = 'Check out this link for booking info: '

  useEffect(() => {
    if (shared) {
      setText(prompt + shared)
    }
  }, [shared, setText])

  return <MessageInputFlat />
}

export const ChannelInner = () => {
  const { sendMessage } = useChannelActionContext()
  const { shared, attType, id } = useAttachmentContext()

  const overrideSubmitHandler = message => {
    // This handler sends the message as normal, but first checks if we are/aren't
    // sending one of our custom attachments.

    const customAttachment = prepareCustomAttachment({
      message: message,
      shared: shared,
      attType: attType,
      id: id,
    })
    if (customAttachment) {
      message.attachments.push(customAttachment)
    }

    const updatedMessage = {
      attachments: message.attachments,
      mentioned_users: message.mentioned_users,
      parent_id: message.parent?.id,
      parent: message.parent,
      text: message.text,
    }

    if (sendMessage) {
      sendMessage(updatedMessage)
    }
  }

  return (
    <Window>
      <ChannelHeader />
      <MessageList style={{ textAlign: 'left !important' }} />
      <MessageInput
        focus={false}
        Input={UserMessageInput}
        overrideSubmitHandler={overrideSubmitHandler}
      />
    </Window>
  )
}
