import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const LeaveGroupConversation = ({
  color = 'black',
  size = 24,
  ...props
}) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="none" />
      <g mask="url(#mask0_6214_5891)">
        <path
          d="M18 21L16.6 19.6L18.175 18H14V16H18.175L16.6 14.4L18 13L22 17L18 21ZM3 21V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H17C17.55 4 18.0208 4.19583 18.4125 4.5875C18.8042 4.97917 19 5.45 19 6V11.075C18.8333 11.0417 18.6667 11.0208 18.5 11.0125C18.3333 11.0042 18.1667 11 18 11C17.8333 11 17.6667 11.0042 17.5 11.0125C17.3333 11.0208 17.1667 11.0417 17 11.075V6H5V16H12.075C12.0417 16.1667 12.0208 16.3333 12.0125 16.5C12.0042 16.6667 12 16.8333 12 17C12 17.1667 12.0042 17.3333 12.0125 17.5C12.0208 17.6667 12.0417 17.8333 12.075 18H6L3 21ZM7 10H15V8H7V10ZM7 14H12V12H7V14Z"
          fill={color}
        />
      </g>
    </svg>
  </SvgIcon>
)
