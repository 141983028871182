import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useAuth } from 'lib/auth'
import initializeFeatures from './initializeFeatures'
import FeatureFlagContext from './FeatureFlagContext'

const withFeatureFlagProvider = App => props => {
  const { user } = useAuth()
  const [featureFlags, setFeatureFlags] = useState({})
  useEffect(() => {
    const getFeatures = async () => {
      const flags = await initializeFeatures(user)
      setFeatureFlags(flags)
    }
    getFeatures()
  }, [user])

  if (isEmpty(featureFlags)) {
    return <LinearProgress color="secondary" />
  }

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      <App {...props} />
    </FeatureFlagContext.Provider>
  )
}

export default withFeatureFlagProvider
