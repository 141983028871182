import { Box, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import React from 'react'
import CoachingCenterListDropDown from 'pages/academy/coaching-center-students/dropdown/coaching-center-list-dropdown'

const CoachingCenterPageHeader = ({ title = 'Coaching Center', children }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <Box className={classes.titleContainer}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.title}
            data-testid="page-header-title"
          >
            {title}
          </Typography>
        </Box>
        <Box className={classes.actionWrapper}>
          <CoachingCenterListDropDown showHelperText={false} />
        </Box>
      </Box>
      {children}
    </Box>
  )
}
export default CoachingCenterPageHeader
