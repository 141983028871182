/* eslint-disable camelcase */
import React from 'react'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { formattedTimeWithTimezone } from 'utils/dateUtils'
import ButtonCta from 'components/atoms/button-cta'
import { IconFlagFilled, IconPrice } from 'components/atoms/icons'
import useStyles, { CardIcon } from './program-card-algolia.styles'
import { centsToDollarsFormatted } from 'lib/utils/number'
import JRLLogoIcon from 'images/jr-league-logo.svg'
import FGLogoIcon from 'images/family-golf-logo.svg'
import { useFeatureFlags, flags } from 'lib/feature-flags'

const ProgramCardAlgolia = ({ event }) => {
  const [isFGEvent] = useFeatureFlags([flags.FLAG_FG_EVENT])

  const classes = useStyles()
  const {
    title,
    start_date_time,
    end_date_time,
    price_in_cents,
    coach_facility_timezone,
    coach_facility_name,
    tags,
    url,
    additional_fees,
    total_price_in_cents,
  } = event

  const timePeriod = formattedTimeWithTimezone(
    start_date_time,
    end_date_time,
    coach_facility_timezone,
  )

  const totalPriceInDollars = centsToDollarsFormatted(
    total_price_in_cents + additional_fees,
  )
  const isJRL = tags.includes('PGA Jr. League')
  const logoUrl = isJRL ? JRLLogoIcon : FGLogoIcon
  const programImage = isJRL
    ? 'https://images.ctfassets.net/56u5qdsjym8c/1yoePOUAugYt2Za7y7Zmca/394ca263cad1c11e1344efb13ed2e0e3/JRL_programs_bg.png?h=250'
    : 'https://images.ctfassets.net/56u5qdsjym8c/5SWGO8vvLshekEP1mGo826/0345e17afa5d282c0bafb50174b06bf5/FG_programs_bg.png?h=250'

  return (
    <Card
      className={`${classes.groupEvent}`}
      onClick={() => (window.location = url)}
    >
      <CardMedia
        className={`${classes.media}`}
        style={{ backgroundImage: `url(${programImage})` }}
      />

      <CardIcon style={{ backgroundImage: `url(${logoUrl})` }} />

      <Box style={{ flexGrow: '1', display: 'flex', flexDirection: 'column' }}>
        <CardContent className={classes.cardContent}>
          <Box>
            {start_date_time && end_date_time ? (
              <Typography variant="caption" className={classes.caption}>
                {timePeriod}
              </Typography>
            ) : (
              <Typography variant="caption" className={classes.caption}>
                {isFGEvent && !isJRL ? 'Event' : 'Program'} Dates TBD
              </Typography>
            )}
            <Typography
              variant="h6"
              color="primary"
              className={classes.cardSubtitle}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            {coach_facility_name != null && (
              <Box display="flex" mb={1}>
                <IconFlagFilled />
                <Typography
                  variant="caption"
                  className={classes.caption}
                  style={{ marginLeft: '12px' }}
                >
                  {coach_facility_name}
                </Typography>
              </Box>
            )}
            {price_in_cents != null && (
              <Box display="flex">
                <IconPrice />
                <Typography
                  variant="caption"
                  className={classes.caption}
                  style={{ marginLeft: '12px' }}
                >
                  {totalPriceInDollars} / {isJRL ? 'player' : 'team'} + 5%
                  processing fee*
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Box>
      <ButtonCta variant="text" arrow href={url} className={classes.ctaButton}>
        View {isFGEvent && !isJRL ? 'Event' : 'Program'}
      </ButtonCta>
    </Card>
  )
}

export default ProgramCardAlgolia
