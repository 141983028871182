import React from 'react'
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import { isUndefined } from 'lodash'

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    borderTopColor: theme.palette.grey[300],
    borderTopStyle: 'solid',
    borderTopWidth: '1px',

    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),

    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },

  borderBox: {
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },

  titleContainer: ({ hasCrumbs }) => ({
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    paddingLeft: hasCrumbs ? theme.spacing(2) : theme.spacing(4),
    borderLeftStyle: hasCrumbs && 'solid',
    borderLeftWidth: hasCrumbs && '1px',
    borderLeftColor: hasCrumbs && theme.palette.grey[400],
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
  title: {
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '42px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  crumbTitle: {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  backButton: {
    height: '35px',
    width: '35px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
    '& a:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column-reverse',

      '& > *': {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },

      '& :first-child': {
        marginBottom: 0,
      },
    },
  },
  mobileActionWrapper: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'row',
      zIndex: '10',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      background: '#fff',
      padding: '18px 12px',
      height: '70px',
      justifyContent: 'space-around',
      boxSizing: 'border-box',
      boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.2)',
      '& > *': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
  },
  action: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  progress: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: '#3268A7',
    marginBottom: '30px',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  progressBar: {
    width: 'calc(100% - 60px)',
    height: '12px',
    borderRadius: '4px',
    backgroundColor: 'rgba(158, 171, 186, 0.6)',
  },
  bar: {
    backgroundColor: '#3268A7',
  },
}))

function PageCrumbs({ title, crumbs }) {
  const classes = useStyles()
  if (!crumbs) {
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map((value, index) => {
        return value.to ? (
          <Link
            component={RouterLink}
            color="inherit"
            key={index}
            to={value.to}
          >
            {value.label}
          </Link>
        ) : (
          <Typography
            color="textPrimary"
            className={classes.crumbTitle}
            key={index}
          >
            {value.label}
          </Typography>
        )
      })}
      {crumbs.length < 2 ? (
        <Typography color="textPrimary" className={classes.crumbTitle}>
          {title}
        </Typography>
      ) : null}
    </Breadcrumbs>
  )
}

export default function PageHeader({
  title,
  crumbs = [],
  actions,
  tabs,
  fixedMobileActions,
  mobileCornerAction,
  progress,
  ...props
}) {
  const hasCrumbs = crumbs && crumbs.length > 0
  const classes = useStyles({ hasCrumbs })

  return (
    <Box className={classes.borderBox} {...props}>
      <Box className={classes.headerContainer}>
        <Box className={classes.backButtonContainer}>
          {hasCrumbs ? (
            <Button component={RouterLink} to={crumbs[crumbs.length - 1].to}>
              <KeyboardArrowLeftIcon className={classes.backButton} />
            </Button>
          ) : null}
        </Box>
        <Box className={classes.titleContainer}>
          {hasCrumbs ? (
            <Hidden smDown>
              <PageCrumbs {...{ crumbs, title }} />
            </Hidden>
          ) : null}
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.title}
            data-testid="page-header-title"
          >
            {title}
          </Typography>
        </Box>
        <Hidden smUp>{mobileCornerAction || null}</Hidden>
        {actions && (
          <Box
            className={`${classes.actionWrapper} ${
              fixedMobileActions ? classes.mobileActionWrapper : ''
            }`}
          >
            {actions}
          </Box>
        )}
      </Box>
      {!isUndefined(progress) && (
        <Box className={classes.progress}>
          <LinearProgress
            color="primary"
            variant="determinate"
            value={progress}
            classes={{
              root: classes.progressBar,
              barColorPrimary: classes.bar,
            }}
          />
          <Typography variant="h6">{progress} %</Typography>
        </Box>
      )}
      {tabs}
    </Box>
  )
}
