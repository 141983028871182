import React from 'react'
import { Button } from '@material-ui/core'

export default function ExternalLinkButton({ children, ...props }) {
  return (
    <Button {...props} target={props.target || '_blank'} component="a">
      {children}
    </Button>
  )
}
