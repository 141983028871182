import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const StepDescriptionOfferings = ({ stepText = '', title, description }) => {
  return (
    <Grid item data-testid={`step-description-offerings-${title}`}>
      {stepText && (
        <Box>
          <Typography
            variant="body1"
            style={{ margin: '0 auto 2rem', fontWeight: '700' }}
          >
            {stepText}
          </Typography>
        </Box>
      )}
      <Typography variant="h4">{title}</Typography>
      <Typography
        variant="body1"
        style={{ margin: '1rem 0px', fontWeight: 600 }}
      >
        {description}
      </Typography>
    </Grid>
  )
}
export default StepDescriptionOfferings
