import { makeStyles, styled } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 0,
  },
  emptyBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '50px',
  },
  emptyIcon: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    height: '83px',
    width: '83px',
    margin: '24px 0 16px',
  },
  emptyText: {
    fontWeight: 500,
    fontSize: '20px',
    lineHight: '32px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    marginBottom: '16px',
  },
  emptySubtext: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: '2rem',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  copyIcon: {
    marginLeft: '1.5rem',
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0.75rem',
    },
  },
  linkText: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  statsSection: {
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  statsTitle: {
    fontWeight: '500',
    fontSize: '20px',
    margin: '24px 32px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
      margin: '16px 28px 0',
      fontSize: '12px',
      lineHeight: '31px',
      textTransform: 'uppercase',
    },
  },
  statsContainer: {
    padding: '0 32px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 9px 0',
    },
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '1em 0 0',
    gap: '12px 32px',
    [theme.breakpoints.down('sm')]: {
      gap: '12px 12px',
    },
  },
  gridItem: {
    boxSizing: 'border-box',
    border: '1px solid #E0E0E0',
    padding: '24px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 0px',
    gap: '0.5em',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minWidth: '242px !important',
    minHeight: '224px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '163px !important',
      maxWidth: '163px',
      minHeight: '152px !important',
      maxHeight: '152px',
      gap: 0,
      padding: '16px',
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: '152px !important',
      maxWidth: '152px',
      padding: '12px',
    },
  },
  statIcon: {
    position: 'absolute',
    fontSize: '30px',
  },
  statIconWrapper: {
    marginBottom: '15px',
    width: '68px !important',
    height: '68px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: '48px !important',
      width: '48px !important',
      marginBottom: '14px',
    },
  },
  infoText: {
    fontWeight: '500',
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  captionText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '400',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
  },
  informationColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    justifySelf: 'start',
    alignItems: 'flex-start',
    paddingRight: '0',
    width: '50%',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      width: '100%',
    },
  },
  informationItem: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteStudent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  deleteText: {
    maxWidth: '60%',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '1rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: 0,
    },
  },
  deleteButton: {
    maxHeight: '40px',
    [theme.breakpoints.only('xs')]: {
      boxSizing: 'border-box !important',
      display: 'block',
      minWidth: '95px',
      maxWidth: '95px',
      minHeight: '95px',
      maxHeight: '95px',
      fontSize: '12px',
      paddingRight: 0,
      paddingLeft: 0,
      marginRight: '24px',
      borderColor: '#E0E0E0',
      '& > * > *': {
        marginRight: 0,
      },
    },
  },
  mobileDelete: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
  },
  sessionHeader: {
    paddingBottom: '3px',
    paddingTop: '1rem',
    fontWeight: '400',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 6px',
  },
  tableContainer: {
    paddingBottom: '25px',
    minHeight: '75%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 0,
    marginRight: 'auto',
    marginTop: '1rem',
    width: '95%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
    },
  },
  tabs: {
    paddingRight: '4rem',
    minWidth: '300px',
    maxWidth: '375px',
    height: '40px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '1rem',
      justifyContent: 'flex-start',
    },
  },
  yearFilter: {
    minWidth: '120px',
  },
}))

export const TileWrapper = styled('div')(({ theme, bgColor }) => ({
  backgroundColor: bgColor || theme.primary,
  borderRadius: '8px',
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
}))

export const TileIcon = ({ Icon, bgColor, iconColor }) => {
  const classes = useStyles()
  return (
    <TileWrapper bgColor={bgColor} className={classes.statIconWrapper}>
      <Icon style={{ color: iconColor }} className={classes.statIcon} />
    </TileWrapper>
  )
}
