import React from 'react'
import { Button, Grid } from '@material-ui/core'

const SummaryBookingButtons = ({
  isMobile,
  coachIsViewingOwnSchedule,
  onBookAnotherSession,
  onViewCoachOfferings,
  classes,
}) => {
  const renderButton = (variant, className, onClick, children) => (
    <Button
      variant={variant}
      className={className}
      onClick={onClick}
      children={children}
    />
  )

  return (
    <>
      <Grid
        item
        xs={12}
        sm={5}
        container
        justifyContent={isMobile ? 'center' : 'flex-end'}
      >
        {renderButton(
          'outlined',
          classes.buttonScale,
          onViewCoachOfferings,
          coachIsViewingOwnSchedule
            ? 'View My Schedule'
            : 'View Coach Offerings',
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        container
        justifyContent={isMobile ? 'center' : 'flex-end'}
      >
        {renderButton(
          'contained',
          `${classes.buttonOverrideBook} ${classes.buttonScale}`,
          onBookAnotherSession,
          'Book Another Session',
        )}
      </Grid>
    </>
  )
}

export default SummaryBookingButtons
