import { Tab, Tabs } from '@material-ui/core'
import { PageHeader } from 'components/page-header'
import { useHideIntercom } from 'lib/support'
import { urlToTitle } from 'pages/pga-coach/activity-plans/lib/transformStage'
import useStyles from 'pages/pga-coach/activity-plans/stage'
import { Link, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import ActivityPlansStageRoutes from 'routes/activity-plans-stage-routes'

const ActivityStage = () => {
  const { stage } = useParams()
  const match = useRouteMatch()
  const path = match.url
  const location = useLocation()
  const isDetails =
    !location.pathname.endsWith('activities') &&
    !location.pathname.endsWith('plans')
  const styles = useStyles()
  useHideIntercom()

  const crumbs = [
    {
      label: 'Activity',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]
  const crumbsDetails = [
    {
      label: 'Activity Plans',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
    {
      label: 'Plans',
      to: path
        .split('/')
        .slice(0, 3)
        .join('/'),
    },
    {
      label: urlToTitle(stage).title,
      to: location.pathname
        .split('/')
        .slice(0, 4)
        .join('/'),
    },
  ]
  return (
    <>
      <PageHeader
        title={urlToTitle(stage).title}
        crumbs={isDetails ? crumbsDetails : crumbs}
        tabs={
          <Tabs
            value={window.location.pathname}
            className={`${isDetails ? styles.hiddenTabs : ''}`}
          >
            <Tab
              label="Activities"
              value={`${path}/activities`}
              component={Link}
              to={`${path}/activities`}
            />
            <Tab
              label="Featured Plans"
              value={`${path}/plans`}
              component={Link}
              to={`${path}/plans`}
            />
          </Tabs>
        }
      />
      <ActivityPlansStageRoutes />
    </>
  )
}

export default ActivityStage
