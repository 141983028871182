import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import notFoundImage from 'images/not-found.png'
import { show } from 'lib/support'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(17),
    background: '#f7f7f7',
    height: '100vh',
    width: '100%',
  },
  link: {
    fontWeight: 700,
    cursor: 'pointer',
  },
  errorImg: {
    width: '100%',
  },
}))

export default ({ statusCode = 500, error, message }) => {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    if (error) {
      window.rg4js && window.rg4js('send', { error })
    }
  }, [error])

  let errorMessage
  switch (statusCode) {
    case 404:
      errorMessage = 'The page you are looking for cannot be found.'
      break
    case 500:
    default:
      errorMessage = "Sorry, something went wrong. We're looking into it."
      break
  }

  return (
    <div className={classes.root} data-testid="error-page">
      <Typography align="center" variant="h3" gutterBottom>
        Oops...out of bounds!
      </Typography>
      <Typography align="center" variant="body1" style={{ fontWeight: 600 }}>
        {message || errorMessage}
      </Typography>
      <Box mb={8} maxWidth="420px">
        <img alt="error-img" className={classes.errorImg} src={notFoundImage} />
      </Box>
      <Box align="center">
        <Button
          onClick={() => history.goBack()}
          className={classes.link}
          style={{ color: '#043362' }}
        >
          Go Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={show}
          className={classes.link}
        >
          Contact Us
        </Button>
      </Box>
    </div>
  )
}
