import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import PageHeader from 'pages/pga-coach/students/student-show-page/header-components/page-header'
import { formatNameForDisplay } from 'lib/text-display'
import { useIsMobile } from 'lib/hooks'
import RouterLinkButton from 'components/router-link-button'
import ParticipantInformation from './participant-information'
import hasNonNullProperty from 'lib/utils/has-non-null-property'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    margin: 0,
    paddingBottom: '16px',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
    '& :last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column-reverse',

      '& > *': {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },

      '& :first-child': {
        marginBottom: 0,
      },
    },
  },
  mobileActionWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
      padding: '34px 16px 0',
      justifyContent: 'space-evenly',
      boxSizing: 'border-box',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('375')]: {
      justifyContent: 'space-between',
    },
  },
  mobile: {
    '& > :first-child': {
      marginBottom: 0,
      marginTop: '1rem',
    },
  },
}))

const actions = (hasCompletedProfile, currentUrl) => {
  return (
    <>
      <RouterLinkButton
        to={`${currentUrl}/profile/edit`}
        variant="outlined"
        color="primary"
        startIcon={<AssignmentIndOutlinedIcon />}
      >
        {hasCompletedProfile
          ? 'Edit Golfer Profile'
          : 'Complete Golfer Profile'}
      </RouterLinkButton>
      <RouterLinkButton
        to={`${currentUrl}/edit`}
        variant="contained"
        color="primary"
      >
        Edit
      </RouterLinkButton>
    </>
  )
}

export default function GolferAccount({ student }) {
  const isMobile = useIsMobile()
  const classes = useStyles()

  const { path, url: currentUrl } = useRouteMatch()
  const studentName = formatNameForDisplay(
    `${student?.firstName} ${student?.lastName}`,
  )
  const hasCompletedProfile = hasNonNullProperty(student?.golferProfile)

  const URL = `${path
    .split('/')
    .slice(0, 3)
    .join('/')}`

  const crumbs = [
    {
      label: 'Accounts',
      to: `${path
        .split('/')
        .slice(0, 2)
        .join('/')}/students`,
    },
    {
      label: studentName,
      to: URL,
    },
  ]

  return (
    <Box className={classes.root}>
      <PageHeader
        title={studentName}
        crumbs={crumbs}
        actions={actions(hasCompletedProfile, currentUrl)}
        isMobile={isMobile}
      />
      {isMobile && (
        <Box
          className={`${classes.actionWrapper} ${classes.mobileActionWrapper}`}
        >
          {actions(hasCompletedProfile, currentUrl)}
        </Box>
      )}
      <ParticipantInformation participant={student} />
    </Box>
  )
}
