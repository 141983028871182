import React, { useState } from 'react'
import { DateTime, Interval } from 'luxon'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from 'components/dialog'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  toggleBtn: {
    borderRadius: '0px',
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      borderWidth: '2px',
    },
  },
  toggleBtnSelected: {
    borderRadius: '0px',
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  },
  startTime: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '4px',
    borderColor: theme.palette.primary.light,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  startTimeContainer: {
    paddingTop: '5px',
  },
  triggerBtn: {
    fontSize: '15px',
    padding: '8px 11px',
  },
}))

const StartTimeIncrementPreviewDialog = ({ startTimeIncrementOptions }) => {
  const classes = useStyles()
  const [selectedIncrementValue, setSelectedIncrementValue] = useState(30)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  const amStartTimes = Interval.fromDateTimes(
    DateTime.now()
      .startOf('day')
      .plus({ hours: 9 }),
    DateTime.now()
      .startOf('day')
      .plus({ hours: 11, minutes: 59 }),
  )
    .splitBy({ minutes: selectedIncrementValue })
    .map(d => d.start)

  const pmStartTimes = Interval.fromDateTimes(
    DateTime.now()
      .startOf('day')
      .plus({ hours: 12 }),
    DateTime.now()
      .startOf('day')
      .plus({ hours: 18 }),
  )
    .splitBy({ minutes: selectedIncrementValue })
    .map(d => d.start)

  return (
    <Box>
      <Dialog
        showCloseButton
        trigger={
          <Button color="primary" size="small" className={classes.triggerBtn}>
            View Sample Calendar
          </Button>
        }
        maxWidth="sm"
      >
        {({ closeDialog }) => {
          return (
            <>
              <DialogTitle>Sample Calendar</DialogTitle>
              <DialogContent>
                <Typography gutterBottom variant="body1">
                  Select a start time increment to preview the results. This
                  sample calendar is based on availability of 9 AM - 6 PM.
                </Typography>
                <Box mt={4}>
                  {isMobile ? (
                    <FormControl fullWidth>
                      <Select
                        onChange={e =>
                          setSelectedIncrementValue(e.target.value)
                        }
                        defaultValue={
                          startTimeIncrementOptions.filter(o => o.default)[0]
                            .value
                        }
                      >
                        {startTimeIncrementOptions.map(opt => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Box mt={4}>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                      >
                        {startTimeIncrementOptions.map(sio => (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={
                              sio.value === selectedIncrementValue
                                ? classes.toggleBtnSelected
                                : classes.toggleBtn
                            }
                            onClick={() => setSelectedIncrementValue(sio.value)}
                          >
                            {sio.label}
                          </Button>
                        ))}
                      </Grid>
                    </Box>
                  )}
                </Box>
                <Box mt={3} mb={2}>
                  <Typography variant="body1">AM</Typography>
                  <Grid
                    container
                    spacing={2}
                    className={classes.startTimeContainer}
                  >
                    {amStartTimes.map(st => (
                      <Grid item lg={2} sm={3} xs={4}>
                        <div className={classes.startTime}>
                          {st.toFormat('hh:mm')}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Typography variant="body1">PM</Typography>
                  <Grid
                    container
                    spacing={2}
                    className={classes.startTimeContainer}
                  >
                    {pmStartTimes.map(st => (
                      <Grid item lg={2} sm={3} xs={4}>
                        <div className={classes.startTime}>
                          {st.toFormat('hh:mm')}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </DialogContent>
            </>
          )
        }}
      </Dialog>
    </Box>
  )
}

export default StartTimeIncrementPreviewDialog
