import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

export const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: '1.5em',
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
  },
  label: {
    position: 'absolute',
    left: '25px',
    top: '-8px',
    backgroundColor: 'white',
    padding: '0px 8px',
    fontSize: '12px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  contentWrapper: {
    fontSize: '15px',
  },
  content: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    margin: '20px 0px',
    padding: '0px 15px',
    color: theme.palette.text.secondary,
  },
  iconBox: {
    padding: '0px 20px',
  },
  bottomLinkWrapper: {
    margin: '10px 0px -8px -8px',
  },
  bottomLink: {},
}))

const NotchedOutlinedBox = ({ label, content, icon, bottomLink }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="2rem"
      className={classes.wrapper}
    >
      <Box className={classes.label}>{label}</Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.contentWrapper}
      >
        {icon ? (
          <>
            <Box display="flex" alignItems="center" className={classes.iconBox}>
              {icon}
            </Box>
            <Box alignItems="center" className={classes.content}>
              <Box display="flex">{content}</Box>
              {bottomLink && (
                <Box display="flex" className={classes.bottomLinkWrapper}>
                  <Button
                    color="primary"
                    href={bottomLink.url}
                    className={classes.bottomLink}
                  >
                    {bottomLink.text}
                  </Button>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box>
            <Box display="flex" alignItems="center" className={classes.iconBox}>
              content
            </Box>
            <Box display="flex">
              {bottomLink && (
                <Button href={bottomLink.url}>{bottomLink.text}</Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default NotchedOutlinedBox
