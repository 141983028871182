import React from 'react'
import { createEvent } from 'ics'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

const AddToCalendarButton = React.forwardRef(function AddToCalendarButton(
  props,
  ref,
) {
  const { event, icon, children, ...buttonProps } = props
  const downloadIcsFile = () => {
    const icsArgs = {
      uid: event.uid,
      start: event.startDateTime
        .toFormat('y-M-d-H-m')
        .split('-')
        .map(n => parseInt(n)),
      end: event.endDateTime
        .toFormat('y-M-d-H-m')
        .split('-')
        .map(n => parseInt(n)),
      title: event.title,
      description: event.description,
      location: event.location,
      url: event.url,
      status: 'CONFIRMED',
      busyStatus: 'BUSY',
      organizer: event.organizer,
      attendees: event.attendees,
    }
    createEvent(icsArgs, (error, value) => {
      if (!error) {
        const reader = new FileReader()
        const out = new Blob([value], { type: 'text/calendar' })
        reader.onload = function () {
          window.location.href = reader.result
        }
        reader.readAsDataURL(out)
      }
    })
  }
  return icon ? (
    <IconButton ref={ref} {...buttonProps} onClick={downloadIcsFile}>
      <icon.type {...icon.props} />
    </IconButton>
  ) : (
    <Button ref={ref} {...buttonProps} onClick={downloadIcsFile}>
      {children}
    </Button>
  )
})

export default AddToCalendarButton
