import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { BAY_RESERVATION_URL } from 'utils/constants'

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      '& button:first-of-type': {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& button:first-of-type': {
        marginRight: theme.spacing(2),
      },
    },
  },
}))

const SummaryActionButtons = ({ student }) => {
  const history = useHistory()
  const classes = useStyles()

  const onReserveAnotherBay = () => {
    history.push(BAY_RESERVATION_URL)
  }

  const onViewRules = () => {
    history.push('/pga-coaching-center-at-pga-frisco/reservations/rules')
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      width="100%"
      className={classes.actionsContainer}
    >
      <Button variant="outlined" color="primary" onClick={onReserveAnotherBay}>
        Reserve Another Bay
      </Button>
      <Button variant="contained" color="primary" onClick={onViewRules}>
        View Rules and Regulations
      </Button>
    </Box>
  )
}

export default SummaryActionButtons
