import React from 'react'
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import get from 'lodash/get'

export default function CtrlCheckbox({
  name,
  label,
  control,
  errors,
  disabled,
  onChangeCallback,
}) {
  const error = get(errors, name)
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ onChange, onBlur, value, name, ref }) => (
          <FormControlLabel
            control={
              <Checkbox
                onBlur={onBlur}
                onChange={e => {
                  onChangeCallback && onChangeCallback(e.target.checked)
                  onChange(e.target.checked)
                }}
                checked={value}
                name={name}
                inputRef={ref}
                color="primary"
              />
            }
            disabled={disabled}
            label={label}
          />
        )}
      />
      {error && (
        <FormHelperText style={{ color: '#f44336' }}>
          {get(error, 'message')}
        </FormHelperText>
      )}
    </>
  )
}
