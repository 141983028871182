import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'
import Error from 'components/error'
import { useAuth } from 'lib/auth'
import { LessonReviewQuery, GetStudentQuery } from './query'
import LessonPackReviewLayout from './lesson-pack-review-layout'

import SummaryLayout from './summary-layout'
import ReviewBookingSkeleton from './review-booking-skeleton'

const ReviewBookingNew = ({
  idOrSlug,
  lessonTypeId,
  locationId,
  startDateTime,
  studentId,
  previousUrl,
}) => {
  const {
    data: fetchedLessonInfo,
    error: lessonError,
    loading: lessonLoading,
  } = useQuery(LessonReviewQuery, {
    variables: {
      id: lessonTypeId,
    },
  })

  const {
    data: fetchedStudent,
    error: studentError,
    loading: studentLoading,
  } = useQuery(GetStudentQuery, {
    variables: {
      id: studentId,
    },
  })

  const [isComplete, setIsComplete] = useState(false)
  const [cardBrand, setCardBrand] = useState()
  const [lastDigits, setLastDigits] = useState()
  const [selectedLessonPackId, setSelectedLessonPackId] = useState('')
  const [lessonPackQuantity, setLessonPackQuantity] = useState()

  if (lessonLoading || studentLoading) return <ReviewBookingSkeleton />
  if (lessonError || studentError) {
    return <Error error={lessonError || studentError} />
  }

  const lessonType = get(fetchedLessonInfo.data, 'lessonTypes.0')
  const selectedLessonPack = lessonType?.lessonPacks?.filter(
    lp => lp.id === selectedLessonPackId,
  )

  const eventObject = {
    event: 'complete-lesson-pack-purchase',
    title: lessonType?.title,
    category: lessonType?.category,
    facility: get(lessonType?.facilities, ['0', 'name']),
    location: `${get(lessonType?.facilities, ['0', 'city'])}, ${get(
      lessonType?.facilities,
      ['0', 'state'],
    )}`,
    price: `${get(selectedLessonPack, ['0', 'priceInCents']) / 100}`,
  }

  return (
    <>
      {!isComplete ? (
        <LessonPackReviewLayout
          previousUrl={previousUrl}
          lessonInfo={get(fetchedLessonInfo, 'lessonTypes.0')}
          student={get(fetchedStudent, 'currentUser.students.0')}
          locationId={locationId}
          startDateTime={startDateTime}
          isComplete={isComplete}
          setIsComplete={setIsComplete}
          setCardBrand={setCardBrand}
          setLastDigits={setLastDigits}
          selectedLessonPackId={selectedLessonPackId}
          setSelectedLessonPackId={setSelectedLessonPackId}
          lessonPackQuantity={lessonPackQuantity}
          setLessonPackQuantity={setLessonPackQuantity}
          eventObject={eventObject}
        />
      ) : (
        <SummaryLayout
          lessonType={lessonType}
          student={get(fetchedStudent, 'currentUser.students.0')}
          idOrSlug={idOrSlug}
          lessonTypeId={lessonTypeId}
          locationId={locationId}
          startDateTime={startDateTime}
          previousUrl={previousUrl}
          cardBrand={cardBrand}
          lastDigits={lastDigits}
          selectedLessonPackId={selectedLessonPackId}
          setSelectedLessonPackId={setSelectedLessonPackId}
          lessonPackQuantity={lessonPackQuantity}
          isComplete={isComplete}
        />
      )}
    </>
  )
}

export default props => {
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn) {
    return <Redirect to={props.previousUrl} />
  }
  return <ReviewBookingNew {...props} />
}
