import React from 'react'
import { useFormContext } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { useAuth } from 'lib/auth'
import StripeInput from 'components/payments/stripe-input'
import useStyles from './group-event-registration-form.styles'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}

const PaymentInformation = ({
  groupEvent,
  paymentRequired,
  setPaymentRequired,
}) => {
  const { user } = useAuth()
  const { register, errors } = useFormContext()
  const classes = useStyles()

  const canSkipPayment = user && user?.id === groupEvent?.coach?.id

  if (!groupEvent.acceptsOnlinePayments) {
    return null
  }

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography variant="h6" className={classes.formSectionHeader}>
          <Box display="flex" justifyContent="space-between">
            Payment details
            {canSkipPayment && (
              <FormControlLabel
                control={
                  <Switch
                    checked={!paymentRequired}
                    onChange={() => setPaymentRequired(!paymentRequired)}
                  />
                }
                label="Skip payment"
              />
            )}
          </Box>
        </Typography>
      </Box>
      <Box display={paymentRequired ? 'block' : 'none'}>
        <Box>
          <TextField
            name="payment.name"
            inputRef={register}
            error={!!errors?.payment?.name}
            helperText={errors?.payment?.name?.message}
            label="Cardholder name"
            placeholder="Cardholder name"
            fullWidth
            className={classes.input}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                autocomplete: 'cc-name',
              },
            }}
          />
        </Box>
        <Box>
          <TextField
            label="Credit card number"
            className={classes.input}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
                options: ELEMENT_OPTIONS,
                autocomplete: 'cc-number',
              },
            }}
          />
        </Box>
        <Box>
          <Box display="flex">
            <Box style={{ width: '160px' }} mr={3}>
              <TextField
                label="Expiration"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    options: ELEMENT_OPTIONS,
                    autocomplete: 'cc-exp',
                  },
                }}
              />
            </Box>
            <Box style={{ width: '140px' }}>
              <TextField
                label="CVC"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    options: ELEMENT_OPTIONS,
                    autocomplete: 'cc-csc',
                  },
                }}
              />
            </Box>
            {/* if you're looking for the zip code field, it was removed in this PR: https://github.com/pgahq/coaching-programs-frontend/pull/776 */}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentInformation
