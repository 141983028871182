import ContentSection from 'components/content-section'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ListItem, ListItemAvatar, ListItemText, Box } from '@material-ui/core'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import EmailOutlineIcon from '@material-ui/icons/EmailOutlined'
import PhoneOutlineIcon from '@material-ui/icons/PhoneOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { styled } from '@material-ui/core/styles'
import { formatPhoneNumber } from 'utils/phoneNumber'

const StyledHeadline = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  fontWeight: 400,
  color: theme.palette.text.secondary,
}))

const StyledContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    '& span': {
      fontSize: '0.875rem',
      overflowWrap: 'anywhere',
    },
  },
}))

const InfoItem = ({ icon, primaryText }) => {
  return (
    <ListItem>
      <ListItemAvatar style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
        {icon}
      </ListItemAvatar>
      <StyledListItemText primary={primaryText} />
    </ListItem>
  )
}

const ParticipantInformation = ({ participant }) => {
  const { firstName, lastName, isMinor, contact, medicalProfile } = participant
  const representative = isMinor ? 'Guardian' : 'Contact'

  return (
    <StyledContainer>
      <ContentSection title="Participant Information">
        <Grid container>
          <Grid xs={12} sm={6} item>
            <StyledHeadline>Student Name</StyledHeadline>
            <InfoItem
              icon={<PersonOutlineIcon />}
              primaryText={`${firstName} ${lastName}`}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <StyledHeadline>{representative} Name</StyledHeadline>
            <InfoItem
              icon={<PersonOutlineIcon />}
              primaryText={`${contact?.firstName} ${contact?.lastName}`}
            />
          </Grid>
          <Grid xs={12} item>
            <StyledHeadline>{representative} Information</StyledHeadline>
          </Grid>
          <Grid xs={12} sm={6} item>
            <InfoItem
              icon={<EmailOutlineIcon />}
              primaryText={contact?.email || 'N/A'}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <InfoItem
              icon={<PhoneOutlineIcon />}
              primaryText={formatPhoneNumber(contact?.phone) || 'N/A'}
            />
          </Grid>
          {medicalProfile?.info && (
            <>
              <Grid xs={12} item>
                <StyledHeadline>Allergies and Medications</StyledHeadline>
              </Grid>
              <Grid xs={12} item>
                <InfoItem
                  icon={<InfoOutlinedIcon />}
                  primaryText={medicalProfile.info}
                />
              </Grid>
            </>
          )}
        </Grid>
      </ContentSection>
    </StyledContainer>
  )
}

export default ParticipantInformation
