import * as yup from 'yup'
// from the ./constants file let's iterate over the options to ensure the validation
// schema is always up to date with the options
import {
  handicapOptions,
  frequencyOptions,
  proficiencyOptions,
} from './constants'

const handicapValues = handicapOptions.map(option => option.value)
const frequencyValues = frequencyOptions.map(option => option.value)
const proficiencyValues = proficiencyOptions.map(option => option.value)

export const combinedSchema = {
  heightFeet: yup
    .number()
    .nullable()
    .min(3, 'Must be a valid height')
    .max(8, 'Must be a valid height')
    .transform(value => (Number.isInteger(value) ? value : null)),
  heightInches: yup
    .number()
    .nullable()
    .min(0, 'Must be a valid height')
    .max(11, 'Must be a valid height')
    .transform(value => (Number.isInteger(value) ? value : null)),
  limitations: yup.string().nullable(),
  frequency: yup
    .string()
    .oneOf(frequencyValues)
    .nullable(),
  associatedWithCourse: yup.string(),
  homeGolfCourse: yup
    .string()
    .test('require-home-course', 'This field is required', function () {
      const associatedWithCourse = this.resolve(
        yup.ref('golfInformation.associatedWithCourse'),
      )
      if (associatedWithCourse === 'true') {
        const value = this.resolve(yup.ref('golfInformation.homeGolfCourse'))
        return value && value.trim() !== '' // Text input is required if not empty
      }
      return true
    }),
  handedness: yup.string().oneOf(['Left', 'Right']),
  handicapRating: yup.string().oneOf(handicapValues),
  confidentArea: yup.string(),
  improvementArea: yup.string(),
  proficiency: yup.string().oneOf(proficiencyValues),
}
