import { useState, useEffect } from 'react'
import { StreamChat } from 'stream-chat'
import { gql, useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { STREAM_API_KEY } from 'env'
import { useAuth } from 'lib/auth'

const GET_STREAM_ACCESS_TOKEN = gql`
  query StreamAccessToken {
    streamAccessToken
  }
`

const stream = StreamChat.getInstance(STREAM_API_KEY)

const useStreamClient = () => {
  const [streamClient, setStreamClient] = useState(null)
  const { user } = useAuth()
  const apolloClient = useApolloClient()

  const tokenProvider = async () => {
    const { data } = await apolloClient.query({
      query: GET_STREAM_ACCESS_TOKEN,
    })
    return data?.streamAccessToken
  }

  useEffect(() => {
    const connectUser = async () => {
      if (stream.user?.id !== user.externalId) {
        await stream.connectUser({ id: user.externalId }, tokenProvider)
      }
      setStreamClient(stream)
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    const disconnectUser = async () => {
      await stream.disconnectUser()
      setStreamClient(null)
    }

    if (user) {
      connectUser()
    } else {
      disconnectUser()
    }
  }, [user])
  /* eslint-enable react-hooks/exhaustive-deps */

  return streamClient
}

const useFindOrCreateChannelWithUserIds = () => {
  const client = useStreamClient()
  const currentUserId = client?.user?.id

  const findOrCreateChannelWithUserIds = async userIds => {
    if (!currentUserId || userIds.length === 0) return null
    if (userIds.includes(undefined)) return null
    try {
      const channel = client.channel('messaging', {
        members: Array.from(new Set([currentUserId, ...userIds])),
      })
      await channel.watch()
      return channel
    } catch (e) {
      window.rg4js &&
        window.rg4js('send', {
          error: e,
          tags: ['messaging', 'stream'],
        })
      return null
    }
  }
  return findOrCreateChannelWithUserIds
}

const useUnhideChannels = () => {
  const client = useStreamClient()
  const currentUserId = client?.user?.id

  const unhideChannels = async channelIds => {
    if (!currentUserId || channelIds.length === 0) return null
    try {
      for (const id of channelIds) {
        const channel = client.channel('messaging', id)
        await channel.show()
      }
      const lastUpdatedChannelId = channelIds[channelIds.length - 1]
      return client.channel('messaging', lastUpdatedChannelId)
    } catch (e) {
      window.rg4js &&
        window.rg4js('send', {
          error: e,
          tags: ['messaging', 'stream'],
        })
      return null
    }
  }
  return unhideChannels
}

const useNavigateToChannel = () => {
  const auth = useAuth()
  const history = useHistory()

  const navigateToChannel = channel => {
    if (auth.isCoach) {
      history.push(`/pga-coach/messages/${channel.id}`)
    } else if (auth.isConsumer) {
      history.push(`/club/messages/${channel.id}`)
    }
  }
  return navigateToChannel
}

export {
  useStreamClient,
  useFindOrCreateChannelWithUserIds,
  useNavigateToChannel,
  useUnhideChannels,
}
