import React from 'react'
import Card from '@material-ui/core/Card'
import Skeleton from '@material-ui/lab/Skeleton'

export default () => (
  <Card variant="outlined" style={{ padding: '10px' }}>
    <Skeleton variant="circle" height={40} width={40} />
    <Skeleton variant="text" width={200} height={50} />
    <Skeleton variant="text" width={200} height={30} />
    <Skeleton variant="text" width={200} height={30} />
  </Card>
)
