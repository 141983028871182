import { gql } from '@apollo/client'

export const CREATE_LESSON_FOR_STUDENT_QUERY = gql`
  mutation CreateLessonForStudent($input: CreateLessonForStudentInput!) {
    createLessonForStudent(input: $input) {
      code
      success
      message
      lesson {
        id
      }
    }
  }
`
