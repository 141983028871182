import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MessageOutlined from '@material-ui/icons/MessageOutlined'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { Tabs, Tab } from '@material-ui/core'
import { PGA_COM_HOST } from 'env'
import StreamSendMessageButton from 'components/stream-messaging/send-message-button'
import CoachCardWithLessonPacks from 'components/coach-card'
import StudentDropdown from './student-dropdown'
import UpcomingSessions from './sessions/upcoming'
import PastSessions from './sessions/past'
import { gtmEvent } from 'lib/gtm'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '30px',
  },
  columnContainer: {
    height: '100%',
  },
  text: {
    margin: '15px 0',
  },
  btnStyle: {
    width: '100%',
    margin: '25px 0',
  },
}))

const CardActions = ({ coachSlug, coachExternalId }) => {
  const history = useHistory()

  const handleBookSessionClick = () => {
    history.push({
      pathname: `coach/${coachSlug}/schedule`,
    })
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-book-session-button',
    })
  }

  return (
    <>
      <Button color="primary" onClick={handleBookSessionClick}>
        COACH OFFERINGS
      </Button>
      <StreamSendMessageButton
        userIds={[coachExternalId]}
        component={IconButton}
        color="primary"
      >
        <MessageOutlined />
      </StreamSendMessageButton>
    </>
  )
}

export default ({
  students,
  selectedStudent,
  setSelectedStudent,
  isMobile,
  lessonCreditBalances,
}) => {
  const student = students.find(({ id }) => id === selectedStudent)
  const classes = useStyles()
  const now = new Date()
  const [selectedTab, setSelectedTab] = useState(0)
  student.coaches.forEach(coach => {
    const filteredById = lessonCreditBalances.filter(({ lessonType }) => {
      return lessonType.coach.id === coach.id
    })
    coach.lessonCreditBalances = filteredById.filter(
      ({ remainingCredits, expiresAt }) => {
        return (
          remainingCredits > 0 && (expiresAt > now.toISOString() || !expiresAt)
        )
      },
    )
  })

  return (
    <Grid className={classes.root} container spacing={6}>
      <Grid className={classes.columnContainer} item md={6} xs={12}>
        {isMobile && students.length > 1 ? (
          <StudentDropdown
            marginBottom
            students={students}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
          />
        ) : null}
        <Typography variant="h5">Coach Information</Typography>
        <div className={classes.text}>
          <Typography variant="body1" style={{ marginBottom: '48px' }}>
            PGA Coaches that you have interacted with can be found here. You can
            book a new session with your coach or send them a message.
          </Typography>
        </div>
        {!student.coaches.length ? (
          <Button
            className={classes.btnStyle}
            variant="contained"
            color="primary"
            href={`${PGA_COM_HOST}/coaches/search`}
          >
            Find a Coach
          </Button>
        ) : null}
        {student.coaches.map(coach => (
          <CoachCardWithLessonPacks
            key={coach.id}
            coach={coach}
            actions={
              <CardActions
                coachSlug={coach.coachSlug || coach.externalId}
                coachExternalId={coach.externalId}
              />
            }
          />
        ))}
      </Grid>
      <Grid item md={6} xs={12}>
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
        >
          <Tab label="Upcoming" />
          <Tab label="Past" />
        </Tabs>
        {selectedTab === 0 && (
          <UpcomingSessions studentId={selectedStudent} classes={useStyles()} />
        )}
        {selectedTab === 1 && (
          <PastSessions studentId={selectedStudent} classes={useStyles()} />
        )}
      </Grid>
    </Grid>
  )
}
