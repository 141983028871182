import React, { useEffect } from 'react'
import { gtmEvent } from 'lib/gtm'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStyles from 'pages/pga-coach/activity-plans/styles'
import StagesBox from 'components/stages-box'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PGAIcon from '../../images/pga-seal-icon.svg'
import { useFeatureFlags, flags } from 'lib/feature-flags'

const ActivityPlans = () => {
  const styles = useStyles()
  const [isFundamentalMovementSkillsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_FUNDAMENTAL_MOVEMENT_SKILLS,
  ])

  useEffect(() => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'stages-loaded',
      activitiesLabel: 'stages-loaded',
    })
  }, [])

  const LIGHTSPEED_URL =
    'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/'

  const FundamentalMovementSkillsItem = {
    link: 'fundamental-movement-skills',
    bgColor: '#FFA07A',
    title: 'Fundamental Movement Skills',
    tags: ['All Ages'],
  }

  const stagesBlocks = [
    {
      link: 'active-start',
      bgColor: '#FFFF19',
      title: 'Active Start',
      tags: ['Girls 0 - 6', 'Boys 0 – 6'],
    },
    {
      link: 'fundamentals',
      bgColor: '#AAFFC3',
      title: 'FUNdamentals',
      tags: ['Girls 6 – 8', 'Boys 6 – 9'],
    },
    {
      link: 'learn-to-play',
      bgColor: '#808080',
      title: 'Learn to Play',
      tags: ['Girls 8 – 11', 'Boys 9 – 12'],
    },
    {
      link: 'play-to-improve',
      bgColor: '#3CB44B',
      title: 'Play to Improve',
      tags: ['Girls 11 – 15', 'Boys 12 – 16'],
    },
    {
      link: 'play-to-compete',
      bgColor: '#46F0F0',
      title: 'Play to Compete',
      tags: ['Girls 15 – 21', 'Boys 16 – 23'],
    },
    {
      link: 'play-to-win',
      bgColor: '#000080',
      color: '#ffffff',
      title: 'Play to Win',
      tags: ['Girls 18+', 'Boys 19+'],
    },
    {
      link: 'play-golf-for-life',
      bgColor: '#008080',
      color: '#ffffff',
      title: 'Play Golf for Life',
      tags: ['Adults'],
    },
    ...(isFundamentalMovementSkillsEnabled
      ? [FundamentalMovementSkillsItem]
      : []),
  ]

  return (
    <Container maxWidth="md">
      <Box className={styles.ctaTileWrapper}>
        <Grid container>
          <Grid item xs={12} md={9} className={styles.column}>
            <img
              src={PGAIcon}
              alt="tip"
              width={44}
              height={44}
              style={{ marginRight: '8px' }}
            />
            <Typography variant="body2">
              Access the ADM Training, Becoming a Modern Coach video series, and
              other education opportunities.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={styles.buttonWrapper}>
            <Button
              to={{ pathname: LIGHTSPEED_URL }}
              color="primary"
              variant="contained"
              endIcon={<OpenInNewIcon />}
              component={Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Training
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h5" className={styles.heading} gutterBottom>
        Featured Plans by ADM Stage
      </Typography>
      <Typography
        variant="body1"
        align="left"
        className={styles.baseText}
        gutterBottom
      >
        Select an ADM stage below to get started. In each stage you’ll be able
        to view individual activities and select from ADM aligned activity
        plans.
      </Typography>
      <Grid container style={{ margin: '32px 0' }}>
        {stagesBlocks.map(block => (
          <StagesBox
            key={block.title}
            title={block.title}
            bgColor={block.bgColor}
            color={block.color}
            tags={block.tags}
            link={block.link}
          />
        ))}
      </Grid>
    </Container>
  )
}

export default ActivityPlans
