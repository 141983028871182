import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import { gtmEvent } from 'lib/gtm'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import useStyles from './stages-box.styles'

const StagesBox = ({ title, bgColor, color, tags, link }) => {
  const styles = useStyles()
  const { path } = useRouteMatch()

  const handleClick = e => {
    gtmEvent({
      event: 'activities-click',
      activitiesCategory: 'coach-activities',
      activitiesAction: 'stages-click',
      activitiesLabel: link,
    })
  }

  return (
    <Box className={styles.mainWrapper}>
      <Grid container className={styles.grid}>
        <Grid container item xs={9} sm={10} md={11}>
          <Grid item xs={12} sm={7} md={5}>
            <Typography
              variant="h6"
              className={styles.heading}
              style={{ backgroundColor: bgColor, color }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={6} lg={6}>
            {tags.map(tag => (
              <Chip label={tag} className={styles.chip} key={tag} />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <Button
            className={styles.button}
            color="primary"
            variant="outlined"
            component={Link}
            onClick={handleClick}
            to={`${path}/${link}/activities`}
          >
            VIEW
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

StagesBox.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  tags: PropTypes.array,
  action: PropTypes.element,
}

export default StagesBox
