import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import startOfYear from 'date-fns/startOfYear'
import addYears from 'date-fns/addYears'
import addMonths from 'date-fns/addMonths'
import addDays from 'date-fns/addDays'
import { MY_PGA_COM_HOST } from 'env'

export const rangeFromDates = dates =>
  dates.reduce((acc, date) => {
    if (!acc) {
      return { startDate: date, endDate: date }
    }

    const { startDate, endDate } = acc

    if (!startDate || isBefore(date, startDate)) {
      return { endDate, startDate: date }
    }

    if (!endDate || isAfter(date, endDate)) {
      return { startDate, endDate: date }
    }

    return acc
  }, null)

export const getAlgoliaTimestamp = (date = new Date()) =>
  Math.floor(date / 1000)

const createOption = ({ formatLabel, formatValue }) => value => ({
  label: format(value, formatLabel),
  value: format(value, formatValue),
})

const dateSteps = {
  YEAR: addYears,
  MONTHS: addMonths,
  DAYS: addDays,
}

export const getDateOptions = (
  amount,
  {
    step = 'YEAR',
    formatLabel = 'yyyy',
    formatValue = 'yyyy',
    start = new Date(),
  } = {},
) => {
  const startDate = startOfYear(start)
  const years = Array.from({ length: amount }).map((_, i) =>
    dateSteps[step](startDate, i),
  )

  return years.map(createOption({ formatLabel, formatValue }))
}

export const canCreateCurrentYearProgram = () => {
  const isDev =
    MY_PGA_COM_HOST.includes('.dev.') || MY_PGA_COM_HOST.includes('.qa.')
  const isFallSeasonAvailable = new Date().getMonth() < 9
  return isDev || isFallSeasonAvailable
}

export const defaultYearFilter = () => {
  const currentYear = Number(new Date().getFullYear())
  const shouldShowNextYearPrograms = new Date() >= new Date(currentYear, 10, 1)
  return shouldShowNextYearPrograms ? currentYear + 1 : currentYear
}

export const defaultJRLOpenDate = year => {
  const yearSet = Number(year || new Date().getFullYear())
  return new Date(yearSet, 0, 9)
}

export const defaultJRLMinDeliveryDate = year => {
  const yearSet = Number(year || new Date().getFullYear())
  return new Date(yearSet, 0, 31)
}

export const winterSeasonCutoffDate = () => {
  // February 28th of current year
  const currentYear = new Date().getFullYear()
  return new Date(Date.UTC(currentYear, 1, 28, 23, 59, 59))
}

export const champSeasonCutoffDate = () => {
  // August 25th of current year
  const currentYear = new Date().getFullYear()
  return new Date(Date.UTC(currentYear, 7, 25, 23, 59, 59))
}
