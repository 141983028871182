import { makeStyles, styled } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    minWidth: '500px',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      minWidth: 'unset',
    },
  },
  backButtonWrapper: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    margin: '15px 0',
  },
  titleFont: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 900,
  },
  titleWrapper: {
    margin: '20px 0 32px 0',
  },
  headerFont: {
    fontSize: '30px',
    fontWeight: 900,
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridItem: {
    border: '1px solid #E0E0E0',
    margin: '10px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  personIcon: {
    position: 'absolute',
    fontSize: '42px',
  },
  infoText: {
    fontWeight: 900,
    fontSize: '24px',
  },
  captionText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 900,
    fontSize: '16px',
  },
}))

export const TileWrapper = styled('div')(({ theme, bgColor }) => ({
  backgroundColor: bgColor || theme.primary,
  borderRadius: '8px',
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
}))

export const TileIcon = ({ Icon, bgColor, iconColor }) => {
  const classes = useStyles()
  return (
    <TileWrapper bgColor={bgColor}>
      <Icon style={{ color: iconColor }} className={classes.personIcon} />
    </TileWrapper>
  )
}
