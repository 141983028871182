import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'sticky',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  loadingStyles: {
    zIndex: 1000,
    position: 'sticky',
    top: '69px',
  },
}))
