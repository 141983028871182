import React from 'react'
import Box from '@material-ui/core/Box'
import { blue, yellow, red } from '@material-ui/core/colors'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import { ADMTrainingExternalLink } from 'lib/pga-dot-coach'
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Divider from '@material-ui/core/Divider'

const PromotedProfileCaption = ({ coachProfile, facilities }) => {
  const { coach } = coachProfile

  const allFacilitiesPrivate = facilities?.every(facility => facility.isPrivate)
  const offersCoachingAtPrivateClub = facilities?.some(
    facility =>
      facility.coachProfilesFacilities[0]?.offersCoachingAtPrivateClub,
  )

  const [captionText, captionColor, captionIcon] = (() => {
    if (coach.canPromoteContent && coachProfile.status === 'ACTIVE') {
      return !(allFacilitiesPrivate && !offersCoachingAtPrivateClub)
        ? [
          'Your coach profile is currently being promoted on PGA.com and can receive leads from prospective customers.',
          blue[50],
          <InfoOutlinedIcon style={{ color: '#2196F3' }} fontSize="inherit" />,
        ]
        : [
          <>
            {
              'Your coach profile is currently not promoted on PGA.com. One or more of your facilities on your coach profile must allow coaching to the public to be promoted. '
            }
            <Link
              to="profile/edit"
              component={RouterLink}
              style={{ fontWeight: 600 }}
            >
              Click here
            </Link>
            {' to update'}
          </>,
          red[50],
          <ErrorOutlineIcon color="error" fontSize="inherit" />,
        ]
    } else if (coach.canPromoteContent && coachProfile.status !== 'ACTIVE') {
      return [
        'Your coach profile is currently not promoted on PGA.com. Turn on your profile visibility to begin receiving leads from prospective customers.',
        yellow[50],
        <ReportProblemOutlinedIcon
          style={{ color: '#FF9800' }}
          fontSize="inherit"
        />,
      ]
    } else if (!coach.canPromoteContent) {
      return [
        <>
          {
            'You are currently ineligible to promote your coach profile on PGA.com. Please complete '
          }
          <ADMTrainingExternalLink icon={false} />
          {' to become eligible.'}
        </>,
        red[50],
        <ErrorOutlineIcon color="error" fontSize="inherit" />,
      ]
    }
  })()
  return (
    <Box
      py={1}
      borderRadius={4}
      bgcolor={captionColor}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Box
        style={{
          minWidth: '50px',
          width: '50px',
          fontSize: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {captionIcon}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box px={2}>
        <Typography variant="body2" align="left">
          {captionText}
        </Typography>
      </Box>
    </Box>
  )
}

export default PromotedProfileCaption
