import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import YesNoButtons from '../form-components/yes-no-buttons'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from '@material-ui/core'
import Container from '@material-ui/core/Container'

const UnlistedStep = () => {
  const { control } = useFormContext()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4}>
        <Typography variant="h4" align="center">
          Would you like to list this offering on PGA.com?
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="body1" align="center">
          Listed offerings will be discoverable through the offering search at{' '}
          <Link
            href="https://pga.com/things-to-do"
            target="_blank"
            rel="noopener noreferrer"
            color="info"
          >
            pga.com/things-to-do
          </Link>
          .
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Box mb={8}>
          <Typography variant="body1" align="center">
            Unlisted offerings will not show up in searches and will require you
            to provide potential attendees with the link in order for them to
            register.
          </Typography>
        </Box>
      </Container>
      <Controller
        name="unlisted"
        control={control}
        render={({ onChange, value }) => (
          <>
            <Box mb={4}>
              <YesNoButtons
                isYes={!value}
                setIsYes={newValue => onChange(!newValue)}
              />
            </Box>
          </>
        )}
      ></Controller>
    </Box>
  )
}

export default UnlistedStep
