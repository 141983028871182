import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { MY_PGA_APPLE_APP_STORE, MY_PGA_GOOGLE_PLAY_STORE } from 'env'
import AppStoreBadge from 'images/store-badges/app-store-badge.svg'
import GooglePlayBadge from 'images/store-badges/google-play-badge.png'
import { useStyles } from './styles'

export default function DownloadMyPGAsection({ subtitle }) {
  const classes = useStyles()

  return (
    <Box textAlign="center" className={classes.root}>
      <Typography variant="h5" style={{ marginBottom: '16px' }}>
        Download MyPGA Today
      </Typography>
      <Typography variant="body1" className={classes.lighterText}>
        {subtitle ||
          'Start your golf journey today, and discover a PGA Coach near you.'}
      </Typography>
      <Box className={classes.badgeContainer}>
        <a href={MY_PGA_APPLE_APP_STORE} target="_blank" rel="noreferrer">
          <img
            src={AppStoreBadge}
            alt="MyPGA app on App Store"
            className={classes.badge}
          />
        </a>
        <a href={MY_PGA_GOOGLE_PLAY_STORE} target="_blank" rel="noreferrer">
          <img
            src={GooglePlayBadge}
            alt="MyPGA app on Google Play"
            className={classes.badge}
          />
        </a>
      </Box>
    </Box>
  )
}
