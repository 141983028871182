import React from 'react'
import Messenger from 'components/stream-messaging/messenger'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import { useHideIntercom } from 'lib/support'

const MessagesPage = () => {
  useHideIntercom()
  return (
    <SensitiveInfoWrapper enableFallback>
      <Messenger />
    </SensitiveInfoWrapper>
  )
}

export default MessagesPage
