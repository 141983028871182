import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import Table from '@mui/material/Table'
import TablePagination from '@mui/material/TablePagination'
import EnhancedTableHead from './enhanced-table-head'
import EnhancedTableBody from './enhanced-table-body'
import { ErrorOutline } from '@material-ui/icons'
import { Box, Typography, Avatar } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useIsTablet } from 'lib/hooks'
import PropTypes from 'prop-types'

/*
 * @label - Inserted into '#-# OF # LABEL'.
 * @rows - See EnhancedTableBody for shape of expected input.
 * @columns - See EnhancedTableHead for shape of expected input.
 * @numberOfRows - Rows to be displayed per page. Defaults to all.
 * @emptyState - Pass in a custom empty state function, otherwise the default will appear.
 * @showPaginator - Set to false to hide Pagination on top and bottom.
 * @rowHeight - Change height of rows.
 */

export const useStyles = makeStyles(theme => ({
  containerStyles: {
    boxSizing: 'border-box',
    padding: '0 36px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
      padding: '0 8px',
    },
  },
  root: {
    borderCollapse: 'separate !important',
    borderSpacing: '0 10px !important',
    tableLayout: 'auto',
  },
  emptyBox: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '100%',
    width: '100%',
    margin: '3em 0',
  },
  emptyIcon: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    height: '83px',
    width: '83px',
    margin: '24px 0 16px',
  },
  emptyText: {
    fontWeight: 500,
    fontSize: '20px',
    lineHight: '32px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    marginBottom: '16px',
  },
  emptySubtext: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  header: ({ showPaginator }) => ({
    width: 'calc(100% - 0.5rem)',
    boxSizing: 'border-box',
    margin: '0 4px',
    color: 'rgba(0,0,0,0.54) !important',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    justifySelf: 'center',
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '0 auto 4.25rem',
      padding: 0,
      '& > div:last-child': {
        display: !showPaginator && 'none',
        '& > button': {
          padding: 0,
        },
      },
    },
  }),
  paginator: {
    margin: '1rem 0 1rem',
    display: 'flex',
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& > ul > li > button': {
      [theme.breakpoints.down(360)]: {
        minWidth: '28px',
        margin: '0 2px',
        padding: '0 4px',
      },
    },
  },
}))

const initialState = {
  sortBy: null,
  sortDirection: null,
  pageTop: 0,
  pageBottom: 1,
}

function reducer(state, action) {
  switch (action.type) {
    case 'sort-by':
      return { ...state, sortBy: action.value }
    case 'sort-direction':
      return { ...state, sortDirection: action.value }
    case 'page-number':
      return {
        ...state,
        pageTop: action.pageTop,
        pageBottom: action.pageBottom,
      }
    case 'reset':
      return { ...initialState }
    default:
      return state
  }
}
export default function EnhancedTable({
  label,
  rows,
  columns,
  numberOfRows,
  emptyState,
  showPaginator,
  rowHeight,
}) {
  const classes = useStyles({ showPaginator })
  const isTablet = useIsTablet()
  const scrollRef = React.useRef(null)

  const [state, dispatch] = React.useReducer(reducer, initialState)

  // Requires an offset of 1 that throws off calculations when rendering rows.
  const pageTotal = Math.ceil(rows.length / numberOfRows) || 0

  // Check and/or stores dynamically-named table state in sessionStorage in case of refresh or tab-switching.
  React.useEffect(() => {
    const sessionSortDirection = window.sessionStorage.getItem(
      `${label}SortDirection`,
    )
    const sessionSortBy = sessionStorage.getItem(`${label}SortColumn`)
    if (sessionSortBy) {
      dispatch({ type: 'sort-by', value: sessionSortBy })
      sessionSortDirection &&
        dispatch({ type: 'sort-direction', value: sessionSortDirection })
    } else {
      sessionStorage.setItem(`${label}SortDirection`, state.sortDirection)
      sessionStorage.setItem(`${label}SortColumn`, state.sortBy)
    }

    // Prevents mismatch in page-number occuring when a search/filter changes the number of pages.
    let sessionPageNumber = Number(sessionStorage.getItem(`${label}PageNumber`))
    if (sessionPageNumber) {
      if (sessionPageNumber > pageTotal - 1) sessionPageNumber = pageTotal - 1
      dispatch({
        type: 'page-number',
        pageBottom: sessionPageNumber + 1,
        pageTop: sessionPageNumber,
      })
    } else {
      sessionStorage.setItem(`${label}PageNumber`, 0)
    }
  }, [pageTotal, label, state.sortBy, state.sortDirection])

  const handleRequestSort = (event, property) => {
    const isAsc = state.sortBy === property && state.sortDirection === 'asc'
    const direction = isAsc ? 'desc' : 'asc'

    dispatch({ type: 'sort-direction', value: direction })
    dispatch({ type: 'sort-by', value: property })

    sessionStorage.setItem(`${label}SortDirection`, direction)
    sessionStorage.setItem(`${label}SortColumn`, property)
  }

  const handleChangePageTop = (event, newPage) => {
    dispatch({ type: 'page-number', pageBottom: newPage + 1, pageTop: newPage })
    sessionStorage.setItem(`${label}PageNumber`, newPage)
  }

  const handleChangePageBottom = (event, newPage) => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    dispatch({ type: 'page-number', pageBottom: newPage, pageTop: newPage - 1 })
    sessionStorage.setItem(`${label}PageNumber`, newPage - 1)
  }

  function EmptyState() {
    return (
      <Box className={classes.emptyBox}>
        <Avatar variant="circular" className={classes.emptyIcon}>
          <ErrorOutline fontSize="large" />
        </Avatar>
        <Typography className={classes.emptyText}>
          Nothing to see, yet!
        </Typography>
        <Typography className={classes.emptySubtext}>
          Results will show up here.
        </Typography>
      </Box>
    )
  }

  return (
    <>
      {rows.length === 0 ? (
        emptyState() || <EmptyState />
      ) : (
        <div ref={scrollRef}>
          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            count={rows.length}
            page={state.pageTop}
            rowsPerPage={numberOfRows || rows.length}
            onPageChange={handleChangePageTop}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} of ${
                count !== -1 ? count : `more than ${to}`
              } ${label}`
            }
            className={classes.header}
          />
          <Table className={classes.root}>
            <EnhancedTableHead
              columns={columns}
              sortDirection={state.sortDirection}
              sortBy={state.sortBy}
              onRequestSort={handleRequestSort}
              ref={scrollRef}
            />
            <EnhancedTableBody
              rows={rows}
              columns={columns}
              sortDirection={state.sortDirection}
              sortBy={state.sortBy}
              page={state.pageTop}
              numberOfRows={numberOfRows || rows.length}
              rowHeight={rowHeight}
            />
          </Table>
          {showPaginator && pageTotal > 1 && (
            <Pagination
              count={pageTotal}
              page={state.pageBottom}
              shape="rounded"
              variant="outlined"
              size="medium"
              siblingCount={isTablet ? 0 : 1}
              showFirstButton={rows.length > 5}
              showLastButton={rows.length > 5}
              onChange={handleChangePageBottom}
              className={classes.paginator}
            />
          )}
        </div>
      )}
    </>
  )
}

EnhancedTable.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  numberOfRows: PropTypes.number,
  emptyState: PropTypes.func,
  showPaginator: PropTypes.bool,
  rowHeight: PropTypes.string,
  searchTerm: PropTypes.string,
}

EnhancedTable.defaultProps = {
  label: 'Items',
  showPaginator: true,
  rowHeight: '80px',
}
