import { Grid, Typography, Card, CardContent, Divider } from '@material-ui/core'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import { useStyles, TileIcon, TileWrapper } from '../styles'
import LoadingView from '../../loading-view'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { useMemo } from 'react'
import { useIsTablet } from 'lib/hooks'

export default function StudentStats({
  totalRevenueInCents,
  totalBookingCount,
  privateBookingCount,
  groupSessionCount,
  loading,
}) {
  const classes = useStyles()
  const isTablet = useIsTablet()

  const Pig = () => {
    return <SavingsIcon size={30} color="#00234B" />
  }

  const [isStudentRevenueEnabled] = useFeatureFlags([
    flags.FLAG_STUDENT_REVENUE_STAT,
  ])

  const calculatedRevenue = useMemo(() => totalRevenueInCents / 100, [
    totalRevenueInCents,
  ])

  return (
    <Card className={classes.statsSection} variant="outlined">
      <Typography className={classes.statsTitle}>Student Statistics</Typography>
      {!isTablet && <Divider />}
      {loading ? (
        <LoadingView />
      ) : (
        <CardContent className={classes.statsContainer}>
          <Grid container className={classes.gridContainer}>
            {isStudentRevenueEnabled ? (
              <Grid item className={classes.gridItem}>
                <TileIcon
                  Icon={Pig}
                  bgColor="rgba(0, 35, 75, 0.08)"
                  className={TileWrapper}
                />
                <Typography variant="h4" className={classes.infoText}>
                  ${calculatedRevenue}
                </Typography>
                <Typography variant="h6" className={classes.captionText}>
                  Revenue
                </Typography>
              </Grid>
            ) : null}
            <Grid item className={classes.gridItem}>
              <TileIcon
                Icon={EventOutlinedIcon}
                iconColor="rgba(131, 111, 80)"
                bgColor="rgba(131, 111, 80, 0.08)"
                className={TileWrapper}
              />
              <Typography variant="h4" className={classes.infoText}>
                {totalBookingCount}
              </Typography>
              <Typography variant="h6" className={classes.captionText}>
                {isTablet ? 'Booked Sessions' : 'Total Booked Sessions'}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TileIcon
                Icon={LocalOfferOutlinedIcon}
                iconColor="rgba(255, 152, 0)"
                bgColor="rgba(255, 152, 0, 0.08)"
                className={TileWrapper}
              />
              <Typography variant="h4" className={classes.infoText}>
                {privateBookingCount}
              </Typography>
              <Typography variant="h6" className={classes.captionText}>
                {isTablet ? 'Private Sessions' : 'Private Sessions Booked'}
              </Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
              <TileIcon
                Icon={PeopleOutlinedIcon}
                iconColor="rgba(76, 175, 80)"
                bgColor="rgba(76, 175, 80, 0.08)"
                className={TileWrapper}
              />
              <Typography variant="h4" className={classes.infoText}>
                {groupSessionCount}
              </Typography>
              <Typography variant="h6" className={classes.captionText}>
                {isTablet ? 'Group Events' : 'Group Events Booked'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}
