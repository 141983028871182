import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import ScheduleOnboarding from './schedule-onboarding'
import GET_COACH_ACCOUNT from '../query'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PageHeader } from 'components/page-header'
import { useRouteMatch } from 'react-router-dom'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 40px',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 10px',
    },
  },
}))

const ScheduleOnboardingPage = () => {
  const classes = useStyles()
  const { data, loading } = useQuery(GET_COACH_ACCOUNT)
  const activeGroupEvents = data?.currentUser?.groupEvents.filter(
    e => !e.isCanceled,
  )

  const { path } = useRouteMatch()

  const crumbs = [
    {
      label: 'Home',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]

  const onboardingSchedule = {
    isFacilitiesCreated: data?.currentUser?.attributes?.facilities.length > 0,
    isGroupEventCreated: activeGroupEvents?.length > 0,
    isAvailable: data?.coachAvailabilities?.some(a => a.timeSlots.length > 0),
    isBookingTypeCreated: data?.lessonTypes.length > 0,
    hasCoachProfile: data?.currentUser?.attributes?.coachProfile !== null,
  }

  return (
    <>
      {loading && <LinearProgress color="secondary" />}
      <PageHeader title="Set Up PGA Schedule" crumbs={crumbs} />
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <ScheduleOnboarding onboardingSchedule={onboardingSchedule} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ScheduleOnboardingPage
