import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import algoliasearch from 'algoliasearch'
import { isEmpty } from 'lodash'
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
} from 'react-instantsearch-dom'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'env'
import { FacilityResult, StyledPaper, ResultWrapper } from './styles'

export const FacilityInput = props => {
  const {
    currentRefinement,
    disabled,
    error,
    inputClassName,
    hits,
    name,
    onSelect,
    onChange,
    refine,
    value,
    inputVariant = 'outlined',
    label = 'Facility Name',
    InputProps,
    placeholder,
  } = props
  return (
    <Fragment>
      <TextField
        name={name}
        label={label}
        data-cy="facilityName"
        type="text"
        variant={inputVariant}
        className={inputClassName}
        style={{ marginTop: '1rem' }}
        value={value || currentRefinement}
        onChange={e => {
          onChange(e)
          refine(e.currentTarget.value)
        }}
        disabled={disabled}
        error={error}
        autoComplete="off"
        InputProps={InputProps}
        placeholder={placeholder}
      />
      <StyledPaper>
        {!disabled &&
          !isEmpty(currentRefinement) &&
          hits
            .filter(
              ({ object_type: objectType }) =>
                objectType.toLowerCase() === 'facility',
            )
            .map(hit => (
              <ResultWrapper
                key={hit.facility_id}
                onClick={() => {
                  onSelect(hit)
                  refine('')
                }}
              >
                <FacilityResult>
                  {hit.name} {hit.city && '-'} {hit.city}
                  {hit.state && ','} {hit.state}
                </FacilityResult>
              </ResultWrapper>
            ))}
      </StyledPaper>
    </Fragment>
  )
}

FacilityInput.propTypes = {
  currentRefinement: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputClassName: PropTypes.string,
  hits: PropTypes.array,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  refine: PropTypes.func,
  value: PropTypes.string,
  inputVariant: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
}

const connectedHits = connectHits(FacilityInput)
const SearchBox = connectSearchBox(connectedHits)

const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const FacilitiesAlgolia = props => {
  const { error, helperText } = props

  return (
    <InstantSearch
      searchClient={algoliaClient}
      indexName="MemberFacilityDirectory"
    >
      <SearchBox {...props} />
      {error ? (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      ) : null}
    </InstantSearch>
  )
}

FacilitiesAlgolia.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputClassName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  inputVariant: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
}

export default FacilitiesAlgolia
