import { gql, useQuery } from '@apollo/client'
import useIconAccessor from 'components/lesson-type-category/icons'

const GET_LESSON_TYPE_FORM_DATA = gql`
  query LessonTypeFormData {
    __type(name: "LessonTypeCategory") {
      name
      enumValues {
        name
      }
    }
    currentUser {
      attributes {
        ... on Coach {
          coachFacilities {
            id
            name
            timezone
          }
          paymentAccounts {
            id
            name
            last4
          }
          coachingCenters: academies {
            id
            name
            slug
            facilities {
              id
              name
              timezone
            }
          }
        }
      }
    }
    coachAvailabilities {
      coachFacility {
        id
      }
      timeSlots {
        day
      }
    }
    startTimeIncrementOptions {
      label
      value
      default
    }
  }
`

const useBookingTypeFormData = () => {
  const { data, error, loading } = useQuery(GET_LESSON_TYPE_FORM_DATA)
  const accessor = useIconAccessor()

  const categories = (data?.__type?.enumValues || []).map(ctgr => ({
    label: accessor[ctgr.name].text,
    icon: accessor[ctgr.name].icon,
    value: ctgr.name,
  }))

  const { coachFacilities = [], paymentAccounts = [], coachingCenters = [] } =
    data?.currentUser?.attributes || {}

  const startTimeIncrementOptions = data?.startTimeIncrementOptions || []
  const coachAvailabilities = data?.coachAvailabilities || []

  return {
    data: {
      categories,
      coachFacilities,
      coachingCenters,
      paymentAccounts,
      startTimeIncrementOptions,
      coachAvailabilities,
    },
    error,
    loading,
  }
}

export default useBookingTypeFormData
