const { DateTime, Duration, Interval } = require('luxon')

/**
 * Returns the full month and day of the month, i.e. `February 11`
 * @param {string} startDateTime ISO date string
 * @returns {string} month and date, i.e. `February 11`
 */
export const toFullDate = startDateTime => {
  const dt = DateTime.fromISO(startDateTime)

  if (!dt.isValid) return null

  return `${dt.toFormat('LLLL')} ${dt.toFormat('d')}`
}

/**
 * Returns the full month and day of the month, i.e. `February 11` when converted to a specific timezone
 * @param {string} startDateTime ISO date string
 * @param {string} timezone timezone string, i.e. `America/New_York`
 * @returns month and date, i.e. `February 11`
 */
export const toFullDateWithTimezone = (startDateTime, timezone) => {
  const dt = DateTime.fromISO(startDateTime).setZone(timezone)

  if (!dt.isValid) return null

  return `${dt.toFormat('LLLL')} ${dt.toFormat('d')}`
}

// returns month and date, i.e. `Feb 11`
export const toFullDateShortMonth = startDateTime => {
  const dt = DateTime.fromISO(startDateTime)

  if (!dt.isValid) return null

  return `${dt.toFormat('LLL')} ${dt.toFormat('d')}`
}

export const toFullDateAndTime = (startDateTime, timezone = null) => {
  const dt = timezone
    ? DateTime.fromISO(startDateTime).setZone(timezone)
    : DateTime.fromISO(startDateTime)
  if (!dt.isValid) return null

  return dt.toFormat('ff')
}

export const toLuxonFromIso = dateTime => {
  if (!dateTime) return null
  return DateTime.fromISO(dateTime)
}

export const isToday = date => {
  const today = DateTime.local()
  const dt = DateTime.fromISO(date)
  return today.hasSame(dt, 'day')
}

export const isTomorrow = date => {
  const tomorrow = DateTime.local().plus({ days: 1 })
  const dt = DateTime.fromISO(date)
  return tomorrow.hasSame(dt, 'day')
}

// returns time duration of a lesson, i.e. '10 - 11:30 PM'
export const getLessonDurationText = (startDateTime, timeInMinutes, tz) => {
  const dt = DateTime.fromISO(startDateTime, { zone: tz })

  if (!dt.isValid) return null

  const startTimeHour = `${dt.toFormat('h')}:${dt.toFormat('mm')}`
  const endTime = dt.plus({ minutes: timeInMinutes })

  return `${startTimeHour} - ${endTime.toLocaleString(DateTime.TIME_SIMPLE)}`
}

// e.g from 10:00 to 10:45 AM (CST)
export const getLessonDurationTextWithTimezone = (
  startDateTime,
  durationInMinutes,
  tz,
) => {
  const dt = DateTime.fromISO(startDateTime, { zone: tz })

  if (!dt.isValid) return null
  const startTimeHour = dt.toFormat('h:mm')
  const endTime = dt.plus({ minutes: durationInMinutes })

  return `from ${startTimeHour} to ${endTime.toLocaleString(
    DateTime.TIME_SIMPLE,
  )} (${endTime.toFormat('ZZZZ')})`
}

// returns full date, i.e. 'March 1, 2021'
export const toFullTextDate = startDateTime => {
  const dt = DateTime.fromISO(startDateTime)
  if (!dt.isValid) return null

  return dt.toFormat('DDD')
}

// e.g March 22, 2023
export const toFullTextDateWithTimezone = (startDateTime, timezone) => {
  const dt = DateTime.fromISO(startDateTime).setZone(timezone)
  if (!dt.isValid) return null

  return dt.toFormat('DDD')
}

// returns full date, i.e. 'Wednesday, March 1, 2021'
export const toFullTextDayDate = startDateTime => {
  const dt = DateTime.fromISO(startDateTime)
  if (!dt.isValid) return null

  return dt.toFormat('DDDD')
}

/**
 * Converts an ISO date string to a full date with day of the week, abbreviated
 * month, a two-digit date, and the year.
 * @param {string} startDateTime ISO date string
 * @param {string} timezone timezone string, i.e. `America/New_York`
 * @returns DateTime formatted as `EEEE MMM dd, y`, i.e. `Wednesday Aug 02, 2023`
 */
export const toFullTextDayDateWithTimezone = (startDateTime, timezone) => {
  const dt = DateTime.fromISO(startDateTime).setZone(timezone)
  if (!dt.isValid) return null

  return dt.toFormat('EEEE MMM dd, y')
}

/**
 * Returns a string with the full date and a start time with timezone.
 * Example: `Wednesday Aug 02, 2023 from 10:00 AM (CST)`
 * @param {string} startDateTime ISO date string
 * @param {string} timezone timezone string, i.e. `America/New_York`
 * @returns {string} full date and start time with timezone
 */
export const toFullTextDayDateFromVisibleTimezone = (
  startDateTime,
  timezone,
) => {
  const dt = DateTime.fromISO(startDateTime).setZone(timezone)
  if (!dt.isValid) return null
  const dateFormat = dt.toFormat('EEEE MMM dd, y')
  const timeOnly = dt.toFormat('t')
  const withTZ = dt.toFormat('ZZZZ')

  return `${dateFormat} from ${timeOnly} (${withTZ})`
}

// returns time period from start and end time, i.e. '10 - 11:30pm'
export const getTimePeriod = (startDateTime, endDateTime) => {
  const start = DateTime.fromISO(startDateTime)
  const end = DateTime.fromISO(endDateTime)

  return `${start.toFormat('h')}:${start.toFormat('mm')} - ${end.toFormat('t')}`
}

// 2:00 - 5:00 AM (MST)
export const getTimePeriodWithTimezone = (
  startDateTime,
  endDateTime,
  timezone,
) => {
  const start = DateTime.fromISO(startDateTime).setZone(timezone)
  const end = DateTime.fromISO(endDateTime).setZone(timezone)

  return `${start.toFormat('h')}:${start.toFormat('mm')} - ${end.toFormat(
    't (ZZZZ)',
  )}`
}

// September 1, 2:00 - 5:00 AM (MST)
export const getDateTimePeriodWithTimezone = (
  startDateTime,
  endDateTime,
  timezone,
) => {
  const start = DateTime.fromISO(startDateTime).setZone(timezone)
  const end = DateTime.fromISO(endDateTime).setZone(timezone)

  return `${start.toFormat('LLLL d')}, ${start.toFormat('h')}:${start.toFormat(
    'mm',
  )} - ${end.toFormat('t (ZZZZ)')}`
}

export const fullDayDateAndTime = (startDateTime, endDateTime) => {
  const start = DateTime.fromISO(startDateTime)
  return `${start.toFormat('cccc')} • ${start.toFormat(
    'DDD',
  )} • ${getTimePeriod(startDateTime, endDateTime)}`
}

// Sunday • September 1, 2024 • 2:00 - 5:00 AM (MST)
export const fullDayDateAndTimeWithTimezone = (
  startDateTime,
  endDateTime,
  timezone,
) => {
  const start = DateTime.fromISO(startDateTime).setZone(timezone)
  return `${start.toFormat('cccc')} • ${start.toFormat(
    'DDD',
  )} • ${getTimePeriodWithTimezone(startDateTime, endDateTime, timezone)}`
}

export const daysUntil = startDateTime => {
  const start = DateTime.local()
  const end = DateTime.fromISO(startDateTime)

  const diffInDays = end.diff(start, 'days')
  const { days } = diffInDays.toObject()
  return days > 0 ? Math.floor(days) : 0
}

export const humanizeMinutes = minutes => {
  if (isNaN(minutes) || minutes === null) return ''

  if (minutes <= 60) return `${minutes} minutes`

  if (Number.isInteger(minutes / 60)) {
    return Duration.fromObject({ minutes: minutes }).toFormat("h 'hours'")
  }

  if (minutes < 120) {
    return Duration.fromObject({ minutes: minutes }).toFormat(
      "h 'hour and' mm 'minutes'",
    )
  }

  return Duration.fromObject({ minutes: minutes }).toFormat(
    "h 'hours and' mm 'minutes'",
  )
}

export const roundDateTimeMinutes = val => {
  if (!val || !val.isValid) return

  const year = val.get('year')
  const month = val.get('month')
  const day = val.get('day')
  const hour = val.get('hour')
  const minute = val.get('minute')
  return DateTime.local(year, month, day, hour, Math.floor(minute / 5) * 5)
}

// Sun, Sep 01 • 2:00 - 5:00 AM (MST)
export const formattedTimeWithTimezone = (
  startDateTime,
  endDateTime,
  timezone,
) => {
  const period = Interval.fromISO(`${startDateTime}/${endDateTime}`)

  return (
    <time dateTime={period.toISO()}>
      {period.hasSame('day')
        ? `${period.start
          .setZone(timezone)
          .toFormat('EEE, MMM dd \u2022 h:mm')} - ${period.end
          .setZone(timezone)
          .toFormat('t (ZZZZ)')}`
        : `${period.toFormat('EEE, MMM dd')}`}
    </time>
  )
}

export const LAST_WEEK = 'lastWeek'
export const LAST_MONTH = 'lastMonth'
export const LAST_QUARTER = 'lastQuarter'
export const LAST_YEAR = 'lastYear'

export const THIS_MONTH = 'thisMonth'
export const THIS_WEEK = 'thisWeek'
export const THIS_QUARTER = 'thisQuarter'
export const THIS_YEAR = 'thisYear'

export const dateRangeWindows = {
  LAST_WEEK,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR,
  THIS_WEEK,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
}

export const datesForRelativeWindow = (relativeWindow, referenceDate) => {
  if (Object.values(dateRangeWindows).indexOf(relativeWindow) === -1) {
    return null
  }

  const today = referenceDate || DateTime.local().startOf('day')
  const lastWeek = today.minus({ weeks: 1 })
  const lastMonth = today.minus({ months: 1 })
  const lastQuarter = today.minus({ months: 3 })
  const lastYear = today.minus({ years: 1 })

  const result = { startDate: null, endDate: null }

  switch (relativeWindow) {
    case THIS_WEEK:
      result.startDate = today.startOf('week')
      result.endDate = today
      break
    case LAST_WEEK:
      result.startDate = lastWeek.startOf('week')
      result.endDate = lastWeek.endOf('week')
      break
    case THIS_MONTH:
      result.startDate = today.startOf('month')
      result.endDate = today
      break
    case LAST_MONTH:
      result.startDate = lastMonth.startOf('month')
      result.endDate = lastMonth.endOf('month')
      break
    case THIS_QUARTER:
      result.startDate = today
        .minus({ months: today.month - (today.quarter - 1) * 3 - 1 })
        .startOf('month')
      result.endDate = today
      break
    case LAST_QUARTER:
      // DateTime objects representing the quarter containing lastQuarter
      result.startDate = lastQuarter
        .minus({
          months: lastQuarter.month - (lastQuarter.quarter - 1) * 3 - 1,
        })
        .startOf('month')
      result.endDate = result.startDate.plus({ months: 2 }).endOf('month')
      break
    case THIS_YEAR:
      result.startDate = today.startOf('year')
      result.endDate = today
      break
    case LAST_YEAR:
      result.startDate = lastYear.startOf('year')
      result.endDate = lastYear.endOf('year')
      break
    default:
  }
  return result
}

export default {
  THIS_WEEK,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  LAST_WEEK,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR,
}
