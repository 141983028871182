import React from 'react'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'

import tipIcon from '../../images/tip.svg'
import FGTipIcon from '../../images/fg-tip.svg'

const formTipStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.hoverOpacity,
    ),
    alignItems: 'center',
  },
  tipContainer: {
    borderRightColor: alpha(lighten(theme.palette.primary.dark, 0.3), 0.24),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    marginRight: theme.spacing(2),
    padding: '4px',
  },
  children: {
    flexGrow: '1',
  },
}))

export default function FormTip({ children, isFG }) {
  const styles = formTipStyles()
  return (
    <div className={styles.container}>
      <div className={styles.tipContainer}>
        <img src={isFG ? FGTipIcon : tipIcon} alt="tip icon" />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}
