import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { socialsList } from 'components/coaches-profile/constants'
import InputAdornment from '@material-ui/core/InputAdornment'
import { get } from 'lodash'

const useStyles = makeStyles(theme => ({
  socialContainer: {
    display: 'revert',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .text-input:not(.last-input)': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

export const Social = () => {
  const classes = useStyles()
  const { control, errors, register, setValue } = useFormContext()

  const initialValues = socialsList.map(item => {
    return {
      reference: null,
      type: item.value,
      preferred: true,
    }
  })

  const [socialOptions, setSocialOptions] = useState(initialValues)

  const handleSocialChange = (e, social, i) => {
    const updatedSocial = socialOptions.map((item, iter) => {
      if (iter === i) {
        return {
          ...item,
          reference:
            e.target.value !== '' ? `${social.fullURL}${e.target.value}` : null,
        }
      }
      return item
    })
    setSocialOptions(updatedSocial)
    setValue('socialJsonData', updatedSocial, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  return (
    <Box className={classes.socialContainer}>
      <Controller
        name="socialJsonData"
        control={control}
        render={({ onChange, value }) =>
          socialsList.map((social, i) => {
            const correspondingValue = (value && value[i]) || ''
            const shortenURL =
              correspondingValue &&
              correspondingValue.reference &&
              correspondingValue.reference.replace(social.fullURL, '')
            return (
              <TextField
                name={`socialJsonData[${i}].name`}
                key={social.value}
                label={social.label}
                type="text"
                inputRef={register()}
                value={shortenURL}
                variant="standard"
                className={`text-input ${get(errors, [
                  'socialJsonData',
                  i,
                  'reference',
                ]) && ' error'}`}
                error={!!get(errors, ['socialJsonData', i, 'reference'])}
                helperText={get(errors, [
                  'socialJsonData',
                  i,
                  'reference',
                  'message',
                ])}
                onChange={e => {
                  onChange(e)
                  handleSocialChange(e, social, i)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className="input-adornment"
                      style={{ marginRight: 0 }}
                    >
                      {social.placeholder}
                    </InputAdornment>
                  ),
                }}
              />
            )
          })
        }
      />
    </Box>
  )
}

export default Social
