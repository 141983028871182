import React from 'react'
import Box from '@material-ui/core/Box'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
  CardRoot,
  CheckboxItemWrapper,
  StyledH4,
  HelperMsg,
  InputsTwoColumns,
  ErrorMsg,
} from 'components/coaches-profile/profile-stepper.styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { findIndex, some } from 'lodash'

const MAX_SKILLS = 8
const skillsMsg = `Select up to ${MAX_SKILLS} skills.`

const SkillsQuery = gql`
  query Skills {
    Skills {
      message
      success
      skills {
        skillId
        skillName
        description
      }
    }
  }
`

export const Skills = ({ skillTypes }) => {
  const { register, errors, control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: 'skills',
    control,
  })

  const isChecked = (skillId, checkedSkills) => {
    return some(checkedSkills, { skillId })
  }

  const toggleSkillChecked = (skillId, checkedSkills) => {
    isChecked(skillId, checkedSkills)
      ? remove(findIndex(checkedSkills, { skillId }))
      : append({ skillId })
  }

  return (
    <Box display="flex" flexDirection="column">
      <StyledH4>Skills</StyledH4>
      {errors.skills && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>
          {errors.skills?.message}
        </ErrorMsg>
      )}
      <CardRoot className="auto-width">
        <HelperMsg>{skillsMsg}</HelperMsg>
        <InputsTwoColumns>
          {skillTypes.map((skillType, i) => {
            return (
              <CheckboxItemWrapper key={skillType.skillId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        toggleSkillChecked(skillType.skillId, fields)
                      }
                      checked={isChecked(skillType.skillId, fields)}
                      color="secondary"
                    />
                  }
                  value={skillType.skillId}
                  name={`skills[${i}].skillId`}
                  label={skillType.skillName}
                  color="secondary"
                />
              </CheckboxItemWrapper>
            )
          })}
        </InputsTwoColumns>
      </CardRoot>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <input
              type="hidden"
              ref={register()}
              name={`skills[${index}].skillId`}
              value={item.skillId}
            />
          </div>
        )
      })}
    </Box>
  )
}

export default function Skill() {
  const { data, loading, error } = useQuery(SkillsQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />
  return <Skills skillTypes={data?.Skills?.skills} />
}
