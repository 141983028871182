import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'

const useStepIconStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  notActive: {
    color: theme.palette.text.disabled,
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.grey[500],
    zIndex: 1,
    fontSize: 18,
  },
}))

function StepIcon(props) {
  const classes = useStepIconStyles()
  const { active, completed } = props

  const activeClass = active ? classes.active : classes.notActive
  return (
    <div className={`${classes.root} ${activeClass}`}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

export default StepIcon
