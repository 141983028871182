import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { formatCurrency } from '../utils'
import { useTheme } from '@material-ui/core/styles'

const BookingPrice = ({ priceInCents, classes, isLessonPackOffering }) => {
  const theme = useTheme()
  if (isLessonPackOffering) return null
  return (
    <ListItem disableGutters={true}>
      <ListItemIcon>
        <SavingsIcon
          size={24}
          className={classes.icons}
          color={theme.palette.secondary.dark}
        />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.attributes }}
        primary={formatCurrency(priceInCents / 100)}
      />
    </ListItem>
  )
}

export default BookingPrice
