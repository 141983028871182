import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  tileButton: {
    width: '155px',
    height: '70px',
    border: '1px solid rgba(0, 35, 75, 0.5)',
    marginRight: '10px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  wrappedNameFields: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sectionHeader: {
    textTransform: 'uppercase',
    fontWeight: '500',
    fontSize: '12px',
    marginTop: '15px',
  },
}))
