import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    borderTopWidth: 0,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      borderTopWidth: '1px',
    },
  },
  h5: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  h6: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
  orderTotal: {
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey[300],
  },
  tableRow: {
    '& :first-child': {
      paddingLeft: 0,
    },
    '& :last-child': {
      paddingRight: 0,
    },
  },
}))

export default useStyles
