import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SlugWrapper } from 'components/coaches-profile/profile-stepper.styles'
import IconButton from '@material-ui/core/IconButton'
import CachedIcon from '@material-ui/icons/Cached'

export default function CustomSlug({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  handleSuggestionClick,
}) {
  const inputRef = useRef()
  return (
    <SlugWrapper onClick={() => inputRef && inputRef.current.focus()}>
      <TextField
        name="customSlug"
        type="text"
        variant="outlined"
        label="Profile Name"
        value={values.customSlug}
        onChange={e => {
          handleChange(e)
        }}
        onBlur={handleBlur}
        style={{ width: '100%' }}
        className={`text-input last-input ${errors.customSlug && ' error'}`}
        error={errors.customSlug}
        helperText={errors?.customSlug?.message}
        inputRef={inputRef}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className="input-adornment">
              <IconButton onClick={handleSuggestionClick} color="default">
                <CachedIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </SlugWrapper>
  )
}

CustomSlug.propTypes = {
  newProfile: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}
