import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  logo: {
    display: 'block',
    width: '48px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
  },
  filler: {
    flexGrow: 1,
  },
  landingBox: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  helper: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '20px',
    height: '20px',
  },
  coachName: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: theme.spacing(7),
    fontWeight: 300,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginBottom: theme.spacing(3),
    },
  },
  ctaText: {
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  avatarStyles: {
    height: '100px',
    width: '100px',
    textTransform: 'uppercase',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      height: '80px',
      width: '80px',
    },
  },
}))

export default useStyles
