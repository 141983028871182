import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CurrencyField from 'components/form-util/currency'
import YesNoButtons from '../form-components/yes-no-buttons'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useStyles } from './styles'
import Grid from '@material-ui/core/Grid'

export const registrationCloseDates = [
  {
    hoursBefore: 0,
    label: 'When the event starts',
  },
  {
    hoursBefore: 1,
    label: '1 hour before event',
  },
  {
    hoursBefore: 6,
    label: '6 hours before event',
  },
  {
    hoursBefore: 24,
    label: '1 day before event',
  },
  {
    hoursBefore: 168,
    label: '1 week before event',
  },
]

const RegistrationDetailsStep = () => {
  const { control, register, errors, setValue, watch } = useFormContext()
  const [isYes, setIsYes] = useState(true)
  const accessCode = watch('accessCode')
  const classes = useStyles()
  const clearAccessCode = () => setValue('accessCode', null)

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={3}>
        <Typography variant="h4" align="center" className={classes.title}>
          Let's set some registration rules.
        </Typography>
      </Box>
      {/* Maximum Sign-Ups Allowed */}
      <Grid container spacing={2}>
        <Grid item style={{ paddingBottom: 0 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Maximum Sign-Ups Allowed
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="maxAttendance"
            type="number"
            inputRef={register}
            placeholder="Maximum Sign-Ups"
            error={!!errors.maxAttendance}
            helperText={
              errors.maxAttendance
                ? 'This field must be a positive number'
                : null
            }
            InputLabelProps={{ shrink: true }}
            fullWidth
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
          />
        </Grid>
        {/* Registration Should Close */}
        <Grid item style={{ paddingBottom: 0, paddingTop: 16 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Registration Should Close
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="hoursBeforeRegistrationCloses"
            control={control}
            as={
              <TextField
                select
                variant="outlined"
                placeholder="Registration Close Date"
              >
                {registrationCloseDates.map(option => (
                  <MenuItem key={option.label} value={option.hoursBefore}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
        </Grid>
        {/* Price Per Person */}
        <Grid item style={{ paddingBottom: 0, paddingTop: 16 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Price Per Person
          </Typography>
          <Typography variant="body2" color="textSecondary">
            This is the published price. You can integrate Stripe later, if you
            want.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CurrencyField
            name="price"
            placeholder="Price Per Person"
            fullWidth
            inputRef={register}
            error={!!errors.price}
            helperText={errors?.price?.message}
          />
        </Grid>
        <Grid item style={{ paddingBottom: 0, paddingTop: 16 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.inputLabel}
          >
            Is this event open to the public?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <YesNoButtons {...{ isYes, setIsYes }} />
        </Grid>
        {!isYes ? (
          <Grid item xs={12}>
            <TextField
              name="accessCode"
              type="text"
              inputRef={register}
              error={!!errors.accessCode}
              placeholder="Set an Access Code"
              disabled={isYes}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!accessCode}
                      onClick={clearAccessCode}
                    >
                      <ClearOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={errors?.accessCode?.message}
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}

export default RegistrationDetailsStep
