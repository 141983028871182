import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
  content: {
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  daysContainer: {
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  dayContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      '&:last-child': {
        borderRight: 0,
      },
    },
    [theme.breakpoints.down('lg')]: {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  dayHeader: {
    [theme.breakpoints.up('md')]: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
    },
  },
  availabilityLabel: {
    marginBottom: theme.spacing(2),

    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export default useStyles
