import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '150',
    overflow: 'visible',
  },
  disableSpacing: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  coachAvatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '110px',
    height: '110px',
    marginTop: '0.25rem',
    fontSize: '2.5rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
    [theme.breakpoints.down('sm')]: {
      marginRight: `${theme.spacing(0)} !important`,
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&:hover #view-profile': {
      textDecoration: 'underline', // Underlines only 'view profile' on hover of whole clickable block.
    },
  },
  coachInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '10rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  coachTitle: {
    display: 'block',
    color: theme.palette.secondary.dark,
    fontWeight: 700,
    fontSize: '1rem',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
  },
  profileCaption: {
    display: 'block',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1rem',
    marginTop: theme.spacing(1.25),
    paddingLeft: '2px',
    paddingBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
      paddingLeft: 0,
    },
  },
  infoWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& h4': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
      },
    },
  },
  logoWrapper: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
    },
  },
}))
