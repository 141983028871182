import React from 'react'
import AppCard from 'components/app-card'
import Typography from '@mui/material/Typography'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'

export default function PGACoachBusinessInsights() {
  return (
    <AppCard
      title={
        <Typography variant="inherit">
          Access the PGA Coach Business Insights <strong>prototype</strong>
        </Typography>
      }
      url="https://sites.google.com/pgahq.com/pgacoachbusinessinsights/home"
      buttonText="launch"
      testid="pga-coach-business-insights-tile"
      logoStyle={{ height: '70px', width: '70px' }}
      logo={<BarChartOutlinedIcon style={{ color: '#00234B' }} />}
    />
  )
}
