import React, { useState, useCallback } from 'react'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import uniqueId from 'lodash/uniqueId'
import InputAdornment from '@material-ui/core/InputAdornment'
import MaskedInputContainer from './masked-input-container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  readOnlyInput: {
    '& input': {
      color: theme.palette.text.disabled,
    },
  },
}))

const createCurrencyMask = (opts = {}) => {
  const decimalsRegex = /\.([0-9]{1,2})/
  const numberMask = createNumberMask({
    allowDecimal: true,
    requireDecimal: true,
    allowLeadingZeroes: false,
    ...opts,
  })

  return rawValue => {
    const mask = numberMask(rawValue)
    const result = decimalsRegex.exec(rawValue)

    // In case there is only one decimal
    if (result && result[1].length < 2) {
      mask.push('0')
    } else if (!result) {
      mask.push('0')
      mask.push('0')
    }
    return mask
  }
}

function CurrencyMaskedField({ inputRef, ...props }) {
  const { includeThousandsSeparator } = props

  return (
    <MaskedInput
      {...props}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      type="tel"
      mask={createCurrencyMask({
        prefix: '',
        includeThousandsSeparator: includeThousandsSeparator,
      })}
      guide={false}
    />
  )
}

export default function Currency({
  id,
  className,
  defaultValue,
  value,
  onChange,
  label,
  name,
  inputRef,
  required,
  disabled,
  readOnly,
  error,
  helperText,
  autoComplete,
  includeThousandsSeparator = true,
}) {
  const [uid] = useState(() => uniqueId('currency-'))
  const classes = useStyles()

  const actualId = id || uid
  return (
    <MaskedInputContainer
      {...{
        id: actualId,
        className: [readOnly ? classes.readOnlyInput : '', className].join(' '),
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputComponent: useCallback(
          props => CurrencyMaskedField({ ...props, includeThousandsSeparator }),
          [includeThousandsSeparator],
        ),
        defaultValue,
        value,
        onChange,
        label,
        name,
        inputRef,
        required,
        error,
        helperText,
        autoComplete,
        disabled,
        readOnly,
      }}
    />
  )
}
