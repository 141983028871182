import { useLocation } from 'react-router-dom'
import * as familyGolfRoutes from 'lib/family-cup-utils/routes'
import * as jrlRoutes from 'lib/junior-league-utils/routes'
import { FGSignupLogo, JRLSignupLogo } from './signup-util'

export const BRANDS = {
  FAMILY_CUP: 'FAMILY_CUP',
  FAMILY_GOLF: 'FAMILY_GOLF',
  JUNIOR_LEAGUE: 'JUNIOR_LEAGUE',
}

export const BRAND_ROUTES = {
  '/junior-league': {
    brand: BRANDS.JUNIOR_LEAGUE,
    routes: jrlRoutes,
    title: 'PGA Jr. League',
    storageKey: 'juniorLeague',
    SignupLogo: JRLSignupLogo,
    isJuniorLeague: true,
    isFamilyCup: false,
    supportEmail: 'teamusergroup@pgajrleague.com',
  },
  '/family-golf': {
    brand: BRANDS.FAMILY_GOLF,
    routes: familyGolfRoutes,
    title: 'Family Golf',
    storageKey: 'familyGolf',
    SignupLogo: FGSignupLogo,
    isJuniorLeague: false,
    isFamilyCup: true,
    supportEmail: 'teamusergroup@pgafamilycup.com',
  },
  get '/family-golf-signup'() {
    return this['/family-golf']
  },
  get '/events'() {
    return this['/family-golf']
  },
}

export const getBrandData = brand =>
  BRAND_ROUTES[`/${brand?.replace('_', '-').toLowerCase()}`]

export const getBrandParam = (urlParams, brandParam) => {
  const brand = brandParam || urlParams.get('brand')
  return brand ? new URLSearchParams({ brand }) : ''
}

const getBrandByParam = (search, rebrand) => {
  const brand = new URLSearchParams(search).get('brand')
  const [, brandValue] =
    Object.entries(rebrand).find(([, value]) => value.brand === brand) || []
  return brandValue
}

const getBrandByRoute = (pathname, rebrand) => {
  const [[, brandValue] = []] = Object.entries(rebrand).filter(([key]) =>
    pathname.startsWith(key),
  )
  return brandValue
}

export default function () {
  const { pathname, search } = useLocation()
  const brandValue = getBrandByParam(search, BRAND_ROUTES)
  if (brandValue) {
    return brandValue
  }

  return getBrandByRoute(pathname, BRAND_ROUTES) || {}
}
