import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    minWidth: '500px',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      minWidth: 'unset',
    },
  },
  p: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  copyIcon: {
    marginLeft: '1.5rem',
    cursor: 'pointer',
  },
  linkText: {
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10%',
    justifyContent: 'start',
    alignItems: 'flex-start',
    paddingRight: '0',
    minWidth: '20%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10%',
    justifyContent: 'start',
    alignItems: 'flex-start',
    paddingRight: '0',
  },
  header: {
    [theme.breakpoints.up('sm')]: {
      fontWeight: '500 !important',
    },
  },
  secondaryText: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  // Allow word-wrapping of emails and other arbitrary values.
  guard: {
    wordBreak: 'break-word',
  },
}))
