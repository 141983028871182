import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useFormContext } from 'react-hook-form'
import useStyles from './group-event-registration-form.styles'

export default function StepActions({
  stepName,
  setActiveStep,
  registrationDisabled = false,
  onSubmit,
}) {
  const form = useFormContext()
  const classes = useStyles()
  if (stepName === 'Confirmation') return null

  const handleContinueClick = async () => {
    const isValid = await form.trigger(['student', 'emergencyContact'])
    return isValid ? setActiveStep(1) : null
  }
  const handleSubmit = async () => {
    const isValid = await form.trigger()
    if (isValid) {
      await form
        .handleSubmit(onSubmit)()
        .then(() => setActiveStep(2))
    }
  }

  if (stepName === 'Participant Information') {
    return (
      <Box
        display="flex"
        justifyContent="center"
        pb={5}
        className={classes.stepActions}
      >
        <Button
          variant="contained"
          className={`${classes.actionButton} ${classes.next}`}
          onClick={handleContinueClick}
          disabled={registrationDisabled}
        >
          {!registrationDisabled ? 'Continue' : 'Registration Closed'}
        </Button>
      </Box>
    )
  }
  if (stepName === 'Additional Information') {
    return (
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="flex-start"
        pb={5}
        className={classes.stepActions}
      >
        <Button
          startIcon={<KeyboardBackspaceIcon />}
          className={classes.previous}
          onClick={() => setActiveStep(0)}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          className={`${classes.actionButton} ${classes.next}`}
          onClick={() => handleSubmit()}
        >
          Complete Registration
        </Button>
      </Box>
    )
  }
}
