import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  useStreamClient,
  useFindOrCreateChannelWithUserIds,
} from 'lib/stream-messaging/hooks'
import ChannelEmbed from 'components/stream-messaging/channel-embed'
import LoadingPage from '../loading-page'
import GroupEventInfo from '../../register/group-event-info'
import RegisterButton from '../../register/register-button'
import CoachInfo from '../coach-info'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      flexDirection: 'column-reverse',
    },
  },
  messageSection: {
    width: '500px',
    height: 'calc(100vh - 230px)',
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'calc(100vh - 208px)',
      borderRadius: 0,
    },
  },
  registerSection: {
    margin: `0 ${theme.spacing(4)}px`,
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0`,
      borderRadius: 0,
    },
  },
}))

const StreamGroupEventMessagesPage = ({ event }) => {
  const classes = useStyles()
  const client = useStreamClient()
  const currentUserId = client?.user?.id
  const findOrCreateChannelWithUserIds = useFindOrCreateChannelWithUserIds()
  const [channel, setChannel] = useState()

  useEffect(() => {
    const fetchChannel = async () => {
      const ch = await findOrCreateChannelWithUserIds([event.coach.externalId])
      if (ch) {
        setChannel(ch)
      }
    }

    if (currentUserId) {
      fetchChannel()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentUserId])

  if (!channel) {
    return <LoadingPage />
  }

  return (
    <Box className={classes.container}>
      <Box bgcolor="white">
        <Box p={2}>
          <CoachInfo coach={event.coach} />
        </Box>
        <Divider />
        <Box className={classes.messageSection}>
          <ChannelEmbed channel={channel} />
        </Box>
      </Box>
      <Box
        p={3}
        bgcolor="white"
        height="fit-content"
        className={classes.registerSection}
      >
        <Box mb={4}>
          <GroupEventInfo groupEvent={event} hideMessageLink />
        </Box>
        <RegisterButton
          groupEvent={event}
          component={Link}
          to={`/things-to-do/events/${event.slug}/register`}
        />
      </Box>
    </Box>
  )
}

export default StreamGroupEventMessagesPage
