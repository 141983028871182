import { makeStyles, styled } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '3rem auto',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  infoWrapped: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  fontBolded: {
    fontWeight: 600,
  },
  captionText: {
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  gridItem: {
    marginBottom: '3rem',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '16px',
  },
  studentCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: '8px',
    border: '1px solid #EEE',
    height: '80px',
    padding: '16px',
  },
  divider: {
    margin: '1.5rem 0px 2.5rem',
  },
}))

export const WaiverWrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: '600px',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.down('xs')]: {
    width: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const CCLogo = styled('img')(({ theme }) => ({
  width: '290px',
  [theme.breakpoints.down('xs')]: {
    width: '240px',
  },
}))
