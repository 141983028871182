import React from 'react'
import { ThemeProvider, styled } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import themes, { extendTheme } from 'themes'
import { mdiAlertRhombusOutline } from '@mdi/js'

const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)
const supportArticleURL =
  'https://support.pga.com/en/articles/9519103-requirements-for-pga-coach-eligibility'

const StyledButton = styled(Button)(({ theme }) => ({
  margin: '1rem 1rem',
}))

const IneligibleUserPage = ({ user }) => {
  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Container maxWidth="sm">
        <Box my={4}>
          <Paper elevation={4}>
            <Box
              p={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box mb={2}>
                <CircularHighlightedIcon
                  icon={mdiAlertRhombusOutline}
                  mdi={true}
                />
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: 500 }}
                >
                  Current Account: {user?.email}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                  gutterBottom
                >
                  You are currently not eligible to use PGA Coach. Please see
                  the support article we have prepared for further assistance.
                </Typography>
              </Box>
              <Button
                color="primary"
                variant="contained"
                href={supportArticleURL}
              >
                Support Article
              </Button>
              <StyledButton href="/logout">Log Out</StyledButton>
            </Box>
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default IneligibleUserPage
