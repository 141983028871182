export default [
  {
    attrName: 'facilityName',
    overlineText: 'Facility Name',
    cols: 12,
  },
  {
    attrName: 'street',
    overlineText: 'Address',
    cols: 12,
  },
  {
    attrName: 'city',
    overlineText: 'City',
    cols: 5,
  },
  {
    attrName: 'state',
    overlineText: 'State',
    cols: 4,
  },
  {
    attrName: 'zipCode',
    overlineText: 'Zip',
    cols: 3,
  },
  {
    attrName: 'phoneNumber',
    overlineText: 'Phone Number',
    cols: 4,
  },
  {
    attrName: 'website',
    overlineText: 'Facility Website',
    cols: 8,
  },
]
