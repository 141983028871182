import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TileButtons from './tile-buttons'
import { useWizardFormContext } from 'components/wizard-form/context'
import { FRISCO_COACHING_CENTER_SLUG } from 'utils/constants'
import useEventDetails from './use-event-details'
import PropTypes from 'prop-types'
import { useFeatureFlags, flags } from 'lib/feature-flags'

// Child components
import EventName from './event-name'
import GroupEventTagSelectionInput from './group-event-tag-selection-input'
import SelectLocation from './select-location'
import EventDescription from './event-description'
import AcademySelect from './academy-select'
import SelectAssistantCoaches from './select-assistant-coaches'
import AgeGroupSelect from 'components/age-group-select'
const AcademyAndLocationSelection = ({
  preselectFriscoCoachingCenter,
  fccAcademyId,
  fccFacilityId,
  isAcademyEvent,
  academyId,
}) => {
  if (preselectFriscoCoachingCenter) {
    return (
      <>
        <Box mb={4}>
          <AcademySelect fccAcademyId={fccAcademyId} />
        </Box>
        <Box mb={4} style={{ display: 'none' }}>
          <SelectLocation preSelectFacilityId={fccFacilityId} />
        </Box>
      </>
    )
  } else {
    return (
      <>
        {isAcademyEvent && (
          <Box mb={4}>
            <AcademySelect />
          </Box>
        )}
        {((isAcademyEvent && academyId !== '') || !isAcademyEvent) && (
          <Box mb={4}>
            <SelectLocation />
          </Box>
        )}
      </>
    )
  }
}

const EventDetails = () => {
  const {
    isEnforceFccEnabled,
    register,
    setValue,
    academyRequired,
    academyId,
    isAcademyEvent,
    setIsAcademyEvent,
  } = useEventDetails()
  const {
    extraData: { coachAcademies },
  } = useWizardFormContext()

  const hasAcademies = coachAcademies.length >= 1

  useEffect(() => {
    register('academyRequired')
  }, [register])

  useEffect(() => {
    setIsAcademyEvent(academyRequired)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academyRequired])

  const fccAcademy = coachAcademies.find(
    academy => academy.slug === FRISCO_COACHING_CENTER_SLUG,
  )
  const fccAcademyId = fccAcademy?.id ?? ''
  const fccFacilityId = fccAcademy?.facilities[0]?.id ?? ''

  const shouldSetFriscoValues =
    isEnforceFccEnabled && isAcademyEvent && fccAcademyId !== ''

  useEffect(() => {
    if (shouldSetFriscoValues) {
      setValue('academyId', fccAcademyId)
      setValue('locationId', fccFacilityId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSetFriscoValues, setValue])
  const preselectFriscoCoachingCenter =
    isEnforceFccEnabled && isAcademyEvent && fccAcademyId !== ''

  const [isAgeSpecificGroupEventsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_AGE_SPECIFIC_GROUP_EVENTS,
  ])

  return (
    <Container maxWidth="md">
      <Box mb={6}>
        <Typography variant="h4" align="center">
          Enter details about your offering.
        </Typography>
      </Box>
      <Box mb={6}>
        <EventName />
      </Box>
      {hasAcademies && (
        <Box>
          <Box mb={3}>
            <Typography variant="h4" align="center">
              Who is hosting this event?
            </Typography>
          </Box>
          {!academyRequired && (
            <Box mb={6} display="flex" justifyContent="center">
              <TileButtons
                prompt=""
                isAcademyEvent={isAcademyEvent}
                setIsAcademyEvent={setIsAcademyEvent}
                controlName="academyRequired"
              />
            </Box>
          )}
        </Box>
      )}
      <AcademyAndLocationSelection
        preselectFriscoCoachingCenter={preselectFriscoCoachingCenter}
        fccAcademyId={fccAcademyId}
        fccFacilityId={fccFacilityId}
        isAcademyEvent={isAcademyEvent}
        academyId={academyId}
      />
      {isAcademyEvent && academyId !== '' && (
        <Box mb={4}>
          <SelectAssistantCoaches />
        </Box>
      )}
      <Box style={{ marginBottom: '1.5rem' }}>
        <Typography variant="h5" align="center">
          Choose up to five (5) tags for this offering:
        </Typography>
      </Box>
      <Box mb={6}>
        <GroupEventTagSelectionInput />
      </Box>
      {isAgeSpecificGroupEventsEnabled && (
        <Box>
          <Box align="center">
            <Box style={{ paddingBottom: '8px' }}>
              <Typography variant="h6">Specify Age Group:</Typography>
            </Box>
            <Box style={{ paddingBottom: '20px' }}>
              <Typography variant="body">
                Would you like to specify an age group for this event?
              </Typography>
            </Box>
          </Box>
          <Box mb={4}>
            <AgeGroupSelect />
          </Box>
        </Box>
      )}
      <Box mb={4}>
        <EventDescription />
      </Box>
    </Container>
  )
}

export default EventDetails

PropTypes.AcademyAndLocationSelection = {
  preselectFriscoCoachingCenter: PropTypes.bool,
  fccAcademyId: PropTypes.string,
  fccFacilityId: PropTypes.string,
  isAcademyEvent: PropTypes.bool,
  academyId: PropTypes.string,
}
