import React from 'react'
import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { useParams, Redirect, useRouteMatch } from 'react-router-dom'
import Error from 'components/error'
import EditEventForm from './edit-event-form'
import GET_GROUP_EVENT_BY_SLUG from './query'
import LinearProgress from '@material-ui/core/LinearProgress'
import { PageHeader } from 'components/page-header'

const EditEvent = () => {
  const { slug } = useParams()
  const { path } = useRouteMatch()
  const { data, loading, error } = useQuery(GET_GROUP_EVENT_BY_SLUG, {
    variables: {
      slug,
    },
  })

  if (loading) return <LinearProgress color="primary" />
  if (error) return <Error />

  const event = data.currentUser?.groupEvents[0]

  if (!event) {
    return <Redirect to="/404" />
  }

  const {
    coachFacilities,
    paymentAccounts,
    academies,
  } = data.currentUser.attributes

  const crumbs = [
    {
      label: 'Schedule',
      to: [...path.split('/').slice(0, 3), 'schedule'].join('/'),
    },
    {
      label: 'Events',
      to: [...path.split('/').slice(0, 3), 'events'].join('/'),
    },
  ]

  return (
    <>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <PageHeader title="Edit Event" crumbs={crumbs} />
      <EditEventForm
        event={event}
        coachFacilities={coachFacilities}
        paymentAccounts={paymentAccounts}
        coachAcademies={academies}
      />
    </>
  )
}

export default EditEvent
