import React from 'react'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    minWidth: '20px',
  },
}))

export default function LoadingButton({
  loading,
  disabled,
  size = 24,
  buttonSize = 'medium',
  children,
  ...props
}) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Button {...props} size={buttonSize} disabled={loading || disabled}>
        {children}
      </Button>
      {loading && (
        <CircularProgress size={size} className={classes.buttonProgress} />
      )}
    </div>
  )
}
