import React from 'react'
import BookingTypeCard from 'pages/pga-coach/bookings/booking-types/booking-type-card'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const LessonPacksList = ({ title, lessonPacks, refetch }) => {
  if (lessonPacks.length === 0) return null

  return (
    <>
      <Box py={2}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Grid direction="row" container spacing={2}>
        {lessonPacks?.map(lp => {
          const bookingType = {
            ...lp.lessonType,
            lessonPacks: [],
            remainingCredits: lp.remainingCredits,
            totalCredits: lp.totalCredits,
            expiresAt: lp.expiresAt,
            lessonCredits: lp.lessonCredits,
            isLessonPacks: true,
            lessonPackId: lp.id,
          }
          return (
            <Grid xs={12} md={6} lg={4} item>
              <BookingTypeCard
                bookingType={bookingType}
                isLessonPack={true}
                refetch={refetch}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
export default LessonPacksList
