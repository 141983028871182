import React, { useEffect, useRef, useState } from 'react'
import { ContentSection } from './content-box'
import { Grid, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import SportsGolfIcon from '@material-ui/icons/SportsGolf'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined'
import {
  ClubsIcon,
  FrontHandOutlinedIcon,
  PrivateSessionIcon,
} from 'components/icons/refreshed-icons'
import { useStyles } from '../styles'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { gtmEvent } from 'lib/gtm'
import { useIntersectionObserver } from 'lib/utils'

export default function GolferInformation({ golferProfile }) {
  const ref = useRef()
  const location = useLocation()
  const [hasFiredEvent, setHasFiredEvent] = useState(false)
  const { event } = qs.parse(location.search)
  const isIntersecting = useIntersectionObserver(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  })

  useEffect(() => {
    if (isIntersecting && !hasFiredEvent && !event) {
      gtmEvent({
        event: 'click-navigation',
        formCategory: 'golfer-intake-form',
        formAction: 'golfer-profile-completion-view',
      })
      setHasFiredEvent(true)
    }
  }, [isIntersecting, hasFiredEvent, event])

  if (!golferProfile) return null

  const {
    frequencyOfPlay,
    homeGolfCourse,
    golfJourney,
    hasOwnClubs,
    handedness,
    handicap,
    confidentArea,
    improvementArea,
  } = golferProfile

  return (
    <ContentSection title="Golfer Information">
      <Grid container ref={ref}>
        <FrequencyOfPlay frequency={frequencyOfPlay} />
        <GolferJourney text={golfJourney} />
        <AreaOfConfidence text={confidentArea} />
        <AreaOfImprovement text={improvementArea} />
        <HomeCourse text={homeGolfCourse} />
        <Handedness text={handedness} />
        <Handicap text={handicap} />
        <HasOwnClubs hasOwnClubs={hasOwnClubs} />
      </Grid>
    </ContentSection>
  )
}

function FrequencyOfPlay({ frequency }) {
  const classes = useStyles()
  return (
    <GolferInfoItem
      icon={<SportsGolfIcon className={classes.icon} />}
      primaryText="Frequency of Play"
      secondaryText={frequency}
    />
  )
}

function HasOwnClubs({ hasOwnClubs }) {
  const classes = useStyles()
  const text = hasOwnClubs ? 'Bringing own clubs' : 'Needs rental clubs'

  return (
    <GolferInfoItem
      icon={
        <PrivateSessionIcon
          className={classes.icon}
          color="rgba(0, 0, 0, 0.54)"
          size="24"
        />
      }
      primaryText="Clubs"
      secondaryText={text}
    />
  )
}

function AreaOfConfidence({ text }) {
  const classes = useStyles()
  return (
    <GolferInfoItem
      icon={<ThumbUpAltOutlinedIcon className={classes.icon} />}
      primaryText="Area of Confidence"
      secondaryText={text}
    />
  )
}

function AreaOfImprovement({ text }) {
  const classes = useStyles()
  return (
    <GolferInfoItem
      icon={<ThumbDownAltOutlinedIcon className={classes.icon} />}
      primaryText="Area of Improvement"
      secondaryText={text}
    />
  )
}

function HomeCourse({ text }) {
  const classes = useStyles()
  return (
    <GolferInfoItem
      icon={<GolfCourseIcon className={classes.icon} />}
      primaryText="Home Course"
      secondaryText={text}
    />
  )
}

function Handedness({ text }) {
  const classes = useStyles()
  return (
    <GolferInfoItem
      icon={
        <FrontHandOutlinedIcon
          className={classes.icon}
          color="rgba(0, 0, 0, 0.54)"
          size="24"
        />
      }
      primaryText="Handedness"
      secondaryText={text}
    />
  )
}

function Handicap({ text }) {
  const classes = useStyles()

  // if text is '' return 'Not sure' but allow null to exist to prevent render
  const handicapValue = text === '' ? 'Not sure' : text

  return (
    <GolferInfoItem
      icon={<WorkspacePremiumOutlinedIcon className={classes.icon} />}
      primaryText="Handicap"
      secondaryText={handicapValue}
    />
  )
}

function GolferJourney({ text }) {
  const classes = useStyles()
  return (
    <GolferInfoItem
      icon={
        <ClubsIcon
          className={classes.icon}
          color="rgba(0, 0, 0, 0.54)"
          size="24"
        />
      }
      primaryText="Golfer Journey"
      secondaryText={text}
    />
  )
}

function GolferInfoItem({ icon, primaryText, secondaryText }) {
  return secondaryText ? (
    <Grid item xs={12} sm={6}>
      <ListItem>
        <ListItemAvatar>{icon}</ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </ListItem>
    </Grid>
  ) : null
}
