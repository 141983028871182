import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    borderBottom: '1px solid #E0E0E0',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  formStateContainer: {
    backgroundColor: 'white',
    minHeight: 'inherit',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    borderRadius: '4px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '1px',
      minHeight: 'calc(100vh - 57px)',
      borderRadius: '0px',
    },
  },
  formSectionHeader: {
    color: '#000',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },
  formSectionSubheader: {
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: 700,
    },
  },
  details: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  successText: {
    fontWeight: '600',
  },
  button: {
    minWidth: '147px',
    margin: '0 .5rem',
    lineHeight: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '.25rem',
    },
  },
  subTitle: {
    fontFamily: 'Source Serif Pro',
    fontSize: '18px',
  },
  formButton: {
    '&:focus': {
      outline: 'none',
    },
  },
  webMobileTextCentering: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  clampedDescription: ({ maxLines }) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': maxLines,
    '-webkit-box-orient': 'vertical',
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600,
  }),
  horizontalDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: '#3268A7',
    textDecoration: 'underline',
  },
  proceedingText: {
    marginBottom: theme.spacing(3),
  },
}))

export default useStyles
