import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import useStyles from './text-field-counter.styles'

const TextFieldCounter = (props) => {
  const {
    characterLimit,
    value,
    ...otherProps
  } = props
  const styles = useStyles()

  const [isError, setError] = useState(false)

  useEffect(() => {
    if (value) setError(value.length > characterLimit)
  }, [value, characterLimit])

  return (
    <>
      <TextField
        helperText={value && `${value.length}/${characterLimit}`}
        error={isError || props.error}
        value={value || props.defaultValue || ''}
        {...otherProps}
        FormHelperTextProps={{
          classes: {
            root: styles.helperText,
          },
        }}
      />
      <FormHelperText error={isError || props.error}>{props.helperText}</FormHelperText>
    </>
  )
}

TextFieldCounter.propTypes = {
  characterLimit: PropTypes.number,
  value: PropTypes.string,
}

TextFieldCounter.defaultProps = {
  characterLimit: 200,
  value: '',
}

export default TextFieldCounter
