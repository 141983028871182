import { gql } from '@apollo/client'
import { buildDateTimeForNative } from 'lib/CustomLuxonUtils'
import { dollarsToCents } from 'lib/utils/number'

export const LFO_CREATE_COACH_PROFILE = gql`
  mutation LFOCreateCoachProfile($params: CoachProfileCreateInput!) {
    CoachProfileCreate(params: $params) {
      success
      message
      newProfileId
      coachProfile {
        id
        coach {
          id
          facilities {
            id
            timezone
          }
        }
      }
    }
  }
`

export const LFO_CREATE_GROUP_EVENT = gql`
  mutation LFOCreateGroupEvent($groupEvent: GroupEventInput!) {
    createGroupEvent(groupEvent: $groupEvent) {
      success
      message
      groupEvent {
        id
        slug
        title
        priceInCents
        startDateTime
        timezone
        coachFacility {
          id
          name
          city
          street
          state
          zipCode
        }
      }
    }
  }
`

export const LFO_COACH_ATTRIBUTES = gql`
  query CoachAttributes {
    currentUser {
      attributes {
        ... on Coach {
          name
          email
          eligibleForLightningFastOnboarding
          facilities {
            id
            name
            timezone
            city
            state
            zip
          }
          coachProfile {
            coachProfileId
            lastName
            firstName
            displayName
            customSlug
            profilePhoto
            title
            status
            city
            state
            zipCode
          }
        }
      }
    }
  }
`

export const UPDATE_COACH_PROFILE = gql`
  mutation UpdateCoachProfile(
    $coachProfileId: ID!
    $params: CoachProfileUpdateInput!
  ) {
    CoachProfileUpdate(coachProfileId: $coachProfileId, params: $params) {
      success
      message
      coachProfile {
        id
        coach {
          id
          facilities {
            id
            timezone
          }
        }
      }
    }
  }
`

export const buildNewProfileParams = ({
  formValues,
  firstName,
  lastName,
  email,
  facility,
}) => {
  return {
    params: {
      firstName: firstName,
      lastName: lastName,
      customSlug: formValues.customSlug,
      email: email,
      facilities: [facility],
      city: facility.city,
      state: facility.state,
      zipCode: facility.zipCode,
    },
  }
}

export const buildUpdatedProfileParams = ({ coachProfile, formValues }) => {
  // pull profile ID out since it has to be passed in as a separate param
  const profileId = coachProfile.coachProfileId
  // delete fields from coachProfile that update mutation will reject
  delete coachProfile.coachProfileId
  delete coachProfile.customSlug
  delete coachProfile.__typename
  return {
    coachProfileId: profileId,
    params: {
      ...coachProfile,
      ...{
        facilities: [
          {
            pgaFacilityId: formValues.facility?.externalFacilityId,
            facilityName: formValues.facility.facilityName,
            street: formValues.facility?.address1,
            city: formValues.facility?.city,
            state: formValues.facility?.state,
            zipCode: formValues.facility?.zipCode,
            customData: {
              facilityRank: 1,
              offerings: [],
            },
          },
        ],
      },
    },
  }
}

export const buildCreateLightningEventParams = ({
  formValues,
  defaultValues,
  profile,
  timezone,
}) => {
  const eventStartDateTime = buildDateTimeForNative(
    formValues.sessions[0].startDate,
    formValues.sessions[0].startTime,
    timezone,
  )
  const eventEndDateTime = buildDateTimeForNative(
    formValues.sessions[0].startDate,
    formValues.sessions[0].endTime,
    timezone,
  )
  const registrationCloseDateTime = eventStartDateTime.minus({
    hours: formValues.hoursBeforeRegistrationCloses,
  })

  const facilityId = formValues.facilityId ?? profile.coach.facilities[0].id

  return {
    groupEvent: {
      title: formValues.title,
      description: formValues.description,
      featuredImageUrl: defaultValues.featuredImageUrl,
      maxAttendance: formValues.maxAttendance
        ? parseInt(formValues.maxAttendance, 10)
        : null,
      registrationCloseDateTime: registrationCloseDateTime,
      priceInCents: dollarsToCents(formValues.price),
      ...(formValues.accessCode && {
        accessCode:
          (formValues.accessCode || '').length > 0
            ? formValues.accessCode
            : null,
      }),
      tags: defaultValues?.tags,
      unlisted: true,
      sessions: [
        {
          startDateTime: eventStartDateTime,
          endDateTime: eventEndDateTime,
          facilityId: facilityId,
        },
      ],
    },
  }
}
