import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'

import { GetMyLessons } from './query'
import SessionCard from './session-card'
import SessionDetailModal from './session-detail'
import { hasNoUpcomingLessons, parseLessons, isUpNext } from './utils'
import { useStyles } from './styles'

import { DateTime } from 'luxon'

const makeSectionConfig = lessons => {
  const { upNext, laterLessons } = isUpNext(lessons)
  return [
    {
      collection: lessons.today,
      sectionTitle: 'Today',
    },
    {
      collection: lessons.tomorrow,
      sectionTitle: 'Tomorrow',
    },
    {
      collection: upNext,
      sectionTitle: 'Up Next',
    },
    {
      collection: laterLessons,
      sectionTitle: 'Future Sessions',
    },
  ]
}

const ScheduleSection = ({ collection, sectionTitle, setOpenLesson }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrappedSection}>
      <Typography variant="h6">{sectionTitle}</Typography>
      {collection.map(lesson => (
        <SessionCard
          key={lesson.id}
          lesson={lesson}
          setOpenLesson={setOpenLesson}
        />
      ))}
    </div>
  )
}

const UpcomingLessons = ({ lessons, refetchLessons }) => {
  const [openLesson, setOpenLesson] = useState({})

  const classes = useStyles()
  return (
    <>
      {makeSectionConfig(lessons).map(({ collection, sectionTitle }) =>
        collection.length ? (
          <ScheduleSection
            {...{ collection, sectionTitle, openLesson, setOpenLesson }}
          />
        ) : null,
      )}

      {hasNoUpcomingLessons(lessons) ? (
        <div className={classes.noSessionsWrapped}>
          <EventNoteOutlinedIcon
            fontSize="large"
            className={classes.calendarIconStyles}
          />
          <Typography variant="body">You have no upcoming sessions</Typography>
        </div>
      ) : null}
      <SessionDetailModal {...{ openLesson, setOpenLesson, refetchLessons }} />
    </>
  )
}

export default ({ studentId, classes }) => {
  const { data, loading, error, refetch } = useQuery(GetMyLessons, {
    variables: {
      studentId,
      orderBy: {
        field: 'START_DATE_TIME',
        direction: 'ASC',
      },
      startDateTime: { from: DateTime.local().toISODate() },
    },
  })
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Box>ERROR</Box>

  const lessons = get(data, 'currentUser.lessons')

  return (
    <>
      <div className={classes.text}>
        <Typography variant="caption" style={{ fontSize: '14px' }}>
          Review your upcoming session schedule with your PGA Coach. Select a
          session card to view more information.
        </Typography>
      </div>
      <UpcomingLessons
        lessons={parseLessons(lessons, studentId)}
        refetchLessons={refetch}
      />
    </>
  )
}
