import React from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import useStyles from './styles'
import {
  InstantSearch,
  Configure,
  Pagination,
  connectMenu,
} from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  COACH_ACTIVITIES_INDEX_NAME,
} from 'env'
import ActivityCards from './activity-cards'
import 'instantsearch.css/themes/satellite.css'
import { urlToTitle } from '../../lib/transformStage'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const MenuSelect = ({ items, currentRefinement, refine }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item lg={3}>
        <FormControl fullWidth className={classes.select}>
          <InputLabel id="categories">Categories</InputLabel>
          <Select
            labelId="categories"
            id="categories-select"
            label="Categories"
            value={currentRefinement || ''}
            onChange={event =>
              refine(event.currentTarget.getAttribute('data-value'))
            }
          >
            {items.map(item => (
              <MenuItem
                key={item.value}
                value={item.isRefined ? currentRefinement : item.value}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Typography className={classes.heading} variant="h6" gutterBottom>
        {currentRefinement || ''}
      </Typography>
    </>
  )
}
const CustomMenuSelect = connectMenu(MenuSelect)

const ActivitiesPage = () => {
  const classes = useStyles()
  const { stage } = useParams()
  const query =
    'stages:"' + urlToTitle(stage).title + '" AND object_type:activity'
  return (
    <Container className={classes.root}>
      <InstantSearch
        indexName={COACH_ACTIVITIES_INDEX_NAME}
        searchClient={searchClient}
      >
        <CustomMenuSelect attribute="categories" />
        <ActivityCards />
        <Configure filters={`${query}`} hitsPerPage={12} />
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination showLast />
        </Box>
      </InstantSearch>
    </Container>
  )
}

export default ActivitiesPage
