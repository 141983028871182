import React from 'react'
import PropTypes from 'prop-types'
import Rating from 'material-ui-rating'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
// import { FillInDateCard, PublishDate } from '@components/atoms'
import DialogTitle from './dialog-title'
import useStyles from './styles'

const ReviewModal = props => {
  const { review, openDialog, parentToggleDialog } = props

  const styles = useStyles()

  const [open, setOpen] = React.useState(openDialog)

  React.useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const handleClose = () => {
    setOpen(false)
    parentToggleDialog(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Rating
          name="rate"
          value={review.rating}
          classes={{
            iconButton: styles.starButton,
          }}
          readOnly
        />
        {/* <FillInDateCard>
          <PublishDate publishedAt={new Date(Number(review.updatedAt)).toISOString()} />
        </FillInDateCard> */}
        <Typography variant="h6" gutterBottom className={styles.contactName}>
          {review.contact.firstName || ''} {review.contact.lastName || ''}
        </Typography>
      </DialogTitle>
      <MuiDialogContent dividers>
        <Typography gutterBottom>{review.reviewText || ''}</Typography>
      </MuiDialogContent>
    </Dialog>
  )
}

ReviewModal.propTypes = {
  review: PropTypes.object,
  openDialog: PropTypes.bool,
  parentToggleDialog: PropTypes.func,
}

export default ReviewModal
