import { gql } from '@apollo/client'

export const CREATE_STUDENT = gql`
  mutation CreateStudent($params: CreateStudentInput!) {
    createStudent(params: $params) {
      message
      success
      student {
        id
        firstName
        lastName
      }
    }
  }
`
