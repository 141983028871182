import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 32px 24px',
    color: 'rgba(0, 0, 0, 0.87)',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: '16px 16px',
    },
  },
  root: {
    margin: '60px 0px',
  },
  chip: {
    background: '#0075CA',
    color: '#ffffff',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '26px',
      fontSize: '11px',
      '& + div': {
        marginTop: theme.spacing(1),
      },
    },
  },
  heading: {
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  button: {
    float: 'right',
  },
  subtitle: {
    color: '#0B79D0',
  },
  container: {
    marginTop: '60px',
    marginBottom: '60px',
  },
}))

export default useStyles
