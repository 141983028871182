import React, { useEffect, useState } from 'react'
import MuiListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useIsTablet } from 'lib/hooks'
import { NavLink, useLocation } from 'react-router-dom'
import { useGetCurrentPrograms } from './api-hooks'
import { PROGRAM_ROUTES } from 'lib/junior-league-utils/program-categories'

const listItemStyles = theme => ({
  root: {
    '&$selected': {
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.secondary.main,
      paddingLeft: '12px',
      borderLeftWidth: '4px',
    },
    '&$selected:hover': {
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.secondary.main,
      borderLeftWidth: '4px',
    },
    '&:hover': {},
  },
  selected: {},
})
const ListItem = withStyles(listItemStyles)(MuiListItem)

const navMenuItemStyles = makeStyles(theme => ({
  listItemIcon: {
    minWidth: 24 + theme.spacing(1),
  },
  listIcon: {
    height: '24px',
    width: '24px',
  },
}))

export const ProgramsSubMenu = ({
  onClick,
  isTabletNavOpen,
  setIsTabletNavOpen,
}) => {
  const { pathname } = useLocation()
  const { data: programs, loading } = useGetCurrentPrograms()
  const filteredPrograms = programs?.filter(
    program =>
      program.year >= new Date().getFullYear() || program.year === null,
  )
  const classes = navMenuItemStyles()
  const isTablet = useIsTablet()

  const [open, setOpen] = useState(false)
  const handleExpand = () => setOpen(s => !s)

  const handleNavbarExpand = () => setIsTabletNavOpen(s => !s)

  useEffect(() => {
    if (!loading) {
      setOpen(true)
    }
  }, [loading, setOpen])

  return (
    <>
      <Divider light />

      <ListItem
        button
        onClick={isTablet ? handleNavbarExpand : handleExpand}
        disabled={loading || filteredPrograms?.length === 0}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <AddToPhotosOutlinedIcon className={classes.listIcon} />
        </ListItemIcon>
        <ListItemText primary="My Current Programs" />
        {open ? (
          <ExpandLess
            style={{ display: !isTabletNavOpen ? 'none' : 'block' }}
          />
        ) : (
          <ExpandMore />
        )}
      </ListItem>
      {isTabletNavOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {(filteredPrograms || []).map(program => (
              <ListItem
                key={program.id}
                button
                onClick={onClick}
                selected={pathname.includes(program.id)}
                component={NavLink}
                to={PROGRAM_ROUTES[program.category].program(
                  program.programLocationId,
                  program.id,
                )}
              >
                <ListItemText
                  primary={program.name}
                  primaryTypographyProps={{ style: { fontWeight: 500 } }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}
