import React from 'react'
import { Link } from 'react-router-dom'
import { useFormContext, Controller } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import { useAuth } from 'lib/auth'
import { PhoneField } from 'components/form-util'
import useStyles from './group-event-registration-form.styles'
import { FormControlLabel } from '@material-ui/core'

const LoggedInUserInformation = ({ user }) => (
  <Paper variant="outlined">
    <Box
      p={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography variant="body1">{user.name}</Typography>
        <Box color="text.secondary">
          <Typography variant="body2">{user.email}</Typography>
        </Box>
      </Box>
      <Button component={Link} to="/logout" color="primary" size="small">
        Not You?
      </Button>
    </Box>
  </Paper>
)

const ContactInformation = () => {
  const { register, errors, control, watch, setValue } = useFormContext()
  const firstParticipantType = watch('participants[0].type')
  const useFirstParticipantContactInformation = watch(
    'useFirstParticipantContactInformation',
  )
  const { user, isConsumer } = useAuth()
  const isAuthenticatedConsumer = user && isConsumer

  const classes = useStyles()

  // uncheck the "Same as Participant #1" checkbox whenever the first participant isn't an adult
  if (
    firstParticipantType !== 'Adult' &&
    useFirstParticipantContactInformation
  ) {
    setValue('useFirstParticipantContactInformation', false)
  }

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        className={classes.formSectionHeader}
      >
        Contact information
      </Typography>
      {isAuthenticatedConsumer && (
        <Box mb={2}>
          <LoggedInUserInformation user={user} />
        </Box>
      )}
      <FormControlLabel
        style={{
          display:
            (isAuthenticatedConsumer || firstParticipantType !== 'Adult') &&
            'none',
        }}
        control={
          <Controller
            name="useFirstParticipantContactInformation"
            control={control}
            render={props => (
              <Checkbox
                {...props}
                checked={props.value}
                onChange={e => props.onChange(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
          />
        }
        label="Same as Participant #1"
      />
      <Box
        display={
          (isAuthenticatedConsumer || useFirstParticipantContactInformation) &&
          'none'
        }
      >
        <TextField
          name="contact.firstName"
          inputRef={register}
          error={!!errors?.contact?.firstName}
          helperText={errors?.contact?.firstName?.message}
          label="First name"
          fullWidth
          className={classes.input}
        />
        <TextField
          name="contact.lastName"
          inputRef={register}
          error={!!errors?.contact?.lastName}
          helperText={errors?.contact?.lastName?.message}
          label="Last name"
          fullWidth
          className={classes.input}
        />
        <TextField
          name="contact.email"
          inputRef={register}
          error={!!errors?.contact?.email}
          helperText={errors?.contact?.email?.message}
          label="Email"
          fullWidth
          className={classes.input}
        />
        <PhoneField
          name="contact.phoneNumber"
          inputRef={register}
          error={!!errors?.contact?.phoneNumber}
          helperText={errors?.contact?.phoneNumber?.message}
          label="Phone number"
          fullWidth
          className={classes.input}
        />
      </Box>
    </>
  )
}

export default ContactInformation
