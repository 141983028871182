import { useEffect, useState } from 'react'
import ReservationsHeaderWrapper from '../reservations-header-wrapper'
import Grid from '@material-ui/core/Grid'
import SummaryLayout from './summary-layout'
import ReviewReservationLayout from './review-reservation-layout'
import { useQuery } from '@apollo/client'
import Error from 'components/error'
import { GetStudentQuery, useGetPracticeSessionTaxEstimation } from '../queries'
import ReviewBookingSkeleton from 'pages/schedule/ReviewBooking/review-booking-skeleton'
import { BAY_RESERVATION_URL } from 'utils/constants'

const ReviewReservation = ({
  academy,
  studentId,
  startDateTime,
  practiceSessionTypeId,
}) => {
  const {
    data: fetchedStudent,
    loading: studentLoading,
    error: studentError,
  } = useQuery(GetStudentQuery, {
    variables: {
      id: studentId,
    },
  })

  const {
    data: fetchedTax,
    loading: taxLoading,
    error: taxError,
  } = useGetPracticeSessionTaxEstimation(practiceSessionTypeId)

  const [isComplete, setIsComplete] = useState(false)
  const [cardBrand, setCardBrand] = useState()
  const [lastDigits, setLastDigits] = useState()
  const [spaceName, setSpaceName] = useState()
  const [showWaiver, setShowWaiver] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showWaiver])

  if (studentError || taxError) {
    return <Error error={studentError} />
  }

  if (studentLoading || taxLoading) {
    return <ReviewBookingSkeleton />
  }
  const selectedStudent = fetchedStudent?.student
  const salesTaxEstimation = fetchedTax?.practiceSessionType?.salesTaxInCents

  return !isComplete ? (
    <Grid container>
      <ReservationsHeaderWrapper
        academy={academy}
        currentStep={showWaiver ? 4 : 3}
        totalSteps={4}
        previousUrl={`${BAY_RESERVATION_URL}?startDateTime=${startDateTime}&practiceSessionTypeId={practiceSessionTypeId}`}
      >
        <ReviewReservationLayout
          academy={academy}
          student={selectedStudent}
          setIsComplete={setIsComplete}
          setCardBrand={setCardBrand}
          setLastDigits={setLastDigits}
          setSpaceName={setSpaceName}
          showWaiver={showWaiver}
          setShowWaiver={setShowWaiver}
          salesTaxEstimation={salesTaxEstimation}
        />
      </ReservationsHeaderWrapper>
    </Grid>
  ) : (
    <SummaryLayout
      academy={academy}
      student={selectedStudent}
      cardBrand={cardBrand}
      lastDigits={lastDigits}
      spaceName={spaceName}
      salesTaxEstimation={salesTaxEstimation}
    />
  )
}

export default ReviewReservation
