import { gql, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

export const GET_COACHING_CENTER_LESSONS_BY_COACH = gql`
  query CoachingCenterLessonsByCoach(
    $slug: String!
    $category: LessonTypeCategory!
    $coachIds: [ID!]!
  ) {
    academy(slug: $slug) {
      lessonTypesByCoach(coachIds: $coachIds, category: $category) {
        lessonTypes {
          id
          title
          priceInCents
          durationInMinutes
          accessCode
          requiresAccessCode
          locations {
            id
          }
          acceptsOnlinePayments
          lessonPackOfferings {
            id
            quantity
            priceInCents
            pricePerLessonInCents
            expirationDuration
          }
        }
        coach {
          id
          name
          coachProfile {
            title
            profilePhoto
          }
        }
      }
    }
  }
`
export const GET_AVAILABLE_TIMES_BY_DAY = gql`
  query GetAvailableTimesByDay(
    $slug: String
    $category: LessonTypeCategory!
    $startDateTime: ISO8601DateTime
  ) {
    academy(slug: $slug) {
      name
      slug
      facilities {
        id
        name
        city
        state
        timezone
      }
      availableTimes(category: $category, startDateTime: $startDateTime) {
        startDateTime
        coaches {
          id
        }
      }
    }
  }
`
export const GET_LESSON_PACK_OFFERINGS_BY_ID = gql`
  query GetLessonPackOfferingsById($id: ID!) {
    lessonTypes(id: $id) {
      lessonPackOfferings {
        id
        quantity
        priceInCents
        pricePerLessonInCents
        expirationDuration
      }
    }
  }
`
const tomorrow = DateTime.now()
  .plus({ hours: 24 })
  .toISO()

export function useGetAvailableTimesByDay(
  academySlug,
  lessonCategory,
  shouldSkip,
) {
  return useQuery(GET_AVAILABLE_TIMES_BY_DAY, {
    variables: {
      slug: academySlug,
      category: lessonCategory,
      startDateTime: tomorrow,
    },
    fetchPolicy: 'cache-first',
    skip: shouldSkip,
  })
}
