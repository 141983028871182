import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useRouteMatch, Link, useLocation, useHistory } from 'react-router-dom'
import { Container, Tab, Tabs, Box } from '@material-ui/core'
import PageHeader from './header-components/page-header'
import { formatNameForDisplay } from 'lib/text-display'
import StudentDetails from './student-details'
import StudentSessions from './student-sessions'
import StudentActionButtons from './header-components/action-buttons'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import { useGetStatistics, useGetSessions } from './api-hooks'
import { useAuth } from 'lib/auth'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import IndexCard from './mobile-index-card'
import { gtmEvent } from 'lib/gtm'
import DisplayContact from './details-components/display-contact'
import DeleteStudent from './details-components/delete-student'
import LessonPacks from './lesson-packs'
import GolferProfileCta from './golfer-profile-cta'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    margin: 0,
    paddingBottom: '16px',
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
    '& :last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'column-reverse',

      '& > *': {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },

      '& :first-child': {
        marginBottom: 0,
      },
    },
  },
  mobileActionWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      width: '100%',
      padding: '12px 16px 0',
      justifyContent: 'space-evenly',
      boxSizing: 'border-box',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('375')]: {
      justifyContent: 'space-between',
    },
  },
  mobile: {
    '& > :first-child': {
      marginBottom: 0,
      marginTop: '1rem',
    },
  },
}))

export default function StudentShowPage({ student, refetch }) {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const classes = useStyles()

  // ----> NAVIGATION LOGIC.
  const { path } = useRouteMatch()
  const { search } = useLocation()
  const { user } = useAuth()
  const { hideSensitiveInformation } = user
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(false)

  const statsPayload = useGetStatistics(student?.id)
  const studentSessions = useGetSessions(student?.id, user?.id)
  const studentName = formatNameForDisplay(
    `${student?.firstName} ${student?.lastName}`,
  )
  const lessonPacks = student?.contact.lessonPacks.filter(
    lessonPack => lessonPack.lessonType.coach.id === user.coach.id,
  )

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const tab = queryParams.get('tab')

    /*
      We'll append ?tab=details or ?tab=cool-things or whatever
      to track the open tab, rather than mucking with a bunch of
      new routes. This also prevents us from having an invalid tab.
    */

    switch (tab) {
      case 'details':
        setActiveTab('details')
        break
      case 'sessions':
        setActiveTab('sessions')
        break
      case 'lessonPacks':
        setActiveTab('lessonPacks')
        break
      default:
        !isMobile ? setActiveTab('details') : setActiveTab(false)
    }
  }, [search, isMobile])

  const URL = `${path
    .split('/')
    .slice(0, 3)
    .join('/')}/${student.id}`

  const crumbs = [
    {
      label: 'Students',
      to: `${path
        .split('/')
        .slice(0, 2)
        .join('/')}/students/list`,
    },
    {
      label: studentName,
      to: URL,
    },
  ]

  const onSessionsClick = () => {
    gtmEvent({
      event: 'student-page-tab-click',
      activitiesCategory: 'student-sessions',
      activitiesAction: 'sessions-click',
      activitiesLabel: 'sessions-click',
    })
  }

  const onLessonPacksClick = () => {
    gtmEvent({
      event: 'student-page-tab-click',
      activitiesCategory: 'student-lesson-packs',
      activitiesAction: 'lesson-packs-click',
      activitiesLabel: 'lesson-packs-click',
    })
  }

  const onDetailsClick = () => {
    gtmEvent({
      event: 'student-page-tab-click',
      activitiesCategory: 'student-details',
      activitiesAction: 'details-click',
      activitiesLabel: 'details-click',
    })
  }

  const handleMobileCardClick = value => {
    setActiveTab(value)
    if (value === 'sessions') {
      onSessionsClick()
    } else if (value === 'details') {
      onDetailsClick()
    }
    history.push(`${URL}?tab=${value}`)
  }

  const tabs = (
    <Tabs value={activeTab}>
      <Tab
        label="Details"
        value="details"
        component={Link}
        to={`${URL}?tab=details`}
        onClick={() => onDetailsClick()}
      />
      <Tab
        label="Sessions"
        value="sessions"
        component={Link}
        to={`${URL}?tab=sessions`}
        onClick={() => onSessionsClick()}
      />
      <Tab
        label="Lesson Packs"
        value="lessonPacks"
        component={Link}
        to={`${URL}?tab=lessonPacks`}
        onClick={() => onLessonPacksClick()}
      />
    </Tabs>
  )

  function DisplayedTab() {
    if (activeTab === 'details') {
      return <StudentDetails student={student} stats={statsPayload} />
    } else if (activeTab === 'sessions') {
      return <StudentSessions sessions={studentSessions} />
    } else if (activeTab === 'lessonPacks') {
      return <LessonPacks lessonPacks={lessonPacks} refetch={refetch} />
    } else {
      return isMobile ? (
        <MobileDisplay />
      ) : (
        <StudentDetails student={student} stats={statsPayload} />
      )
    }
  }

  function MobileDisplay() {
    return (
      <Container className={classes.mobile}>
        <GolferProfileCta student={student} />
        <DisplayContact isMinor={student?.isMinor} contact={student?.contact} />
        <IndexCard
          title="Student Details"
          value="details"
          icon={<PeopleOutlinedIcon htmlColor="rgba(0, 35, 75, 1)" />}
          onClick={handleMobileCardClick}
        />
        <IndexCard
          title="Student Sessions"
          value="sessions"
          icon={<EventOutlinedIcon htmlColor="rgba(131, 111, 80, 1)" />}
          onClick={handleMobileCardClick}
        />
        {isMobile && !hideSensitiveInformation && (
          <DeleteStudent student={student} />
        )}
      </Container>
    )
  }

  return (
    <Box className={classes.root}>
      <PageHeader
        title={studentName}
        crumbs={crumbs}
        tabs={tabs}
        actions={
          <StudentActionButtons
            student={student}
            useCornerActions={!student.hasAccount && isTablet}
          />
        }
        isMobile={isMobile}
        activeTab={activeTab && formatNameForDisplay(activeTab)}
      />
      {isMobile && !activeTab && (
        <Box
          className={`${classes.actionWrapper} ${classes.mobileActionWrapper}`}
        >
          <StudentActionButtons student={student} />
        </Box>
      )}
      {DisplayedTab()}
    </Box>
  )
}
