import React, { useState, useEffect } from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import TimerIcon from '@material-ui/icons/Timer'
import { useHistory } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import VerifyAccessCode from 'components/verify-access-code'
import { makeStyles } from '@material-ui/core/styles'
import PriceDetails from './price-details'
import { durationText } from 'utils/durationUtils'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: '3rem 0',
  },
  coachSection: {
    maxWidth: '17rem',
    [theme.breakpoints.only('xs')]: {
      marginBottom: '1rem',
    },
  },
  avatar: {
    width: '4.75rem',
    height: '4.75rem',
    marginRight: '1rem',
  },
  card: {
    borderRadius: '0.5rem',
    maxWidth: '30rem',
    boxShadow: '0rem 0.25rem 2.25rem 0rem rgba(0, 10, 22, 0.13)',
    padding: '1rem 2rem',
    marginBottom: '.75rem',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0rem 0.5rem 2.75rem 0rem rgba(0, 10, 22, 0.13)',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '1rem',
    },
  },
  chipSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginTop: '0.25rem',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
  },
  property: {
    marginLeft: '0.75rem',
    color: theme.palette.grey.dark,
  },
  coachName: {
    fontSize: '1.5rem',
  },
  coachTitle: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  arrow: {
    color: theme.palette.primary.lightest,
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  chip: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.contrastText,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '4px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    letterSpacing: 1.5,
    height: '22px',
    fontSize: '12px',
    marginBottom: '.25rem',
    textTransform: 'uppercase',
    '& span': {
      paddingLeft: '.25rem',
      paddingRight: '.25rem',
    },
  },
}))

const LessonTypeCard = ({ lessonType, currentUrl, coachName }) => {
  const history = useHistory()
  const {
    title,
    durationInMinutes,
    requiresAccessCode,
    lessonPackOfferings,
    locations,
  } = lessonType
  const isLessonPack = lessonPackOfferings?.length > 0
  const classes = useStyles()
  const navUrl = `${currentUrl}&lessonTypeId=${lessonType.id}&facilityId=${locations[0]?.id}`
  const [isOpen, setIsOpen] = useState(false)
  const [verifiedAccessCode, setVerifiedAccessCode] = useState(false)
  const hasChipsSection = isLessonPack || requiresAccessCode
  useEffect(() => {
    if (verifiedAccessCode) {
      history.push(navUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifiedAccessCode])

  const handleCardClick = () => {
    if (requiresAccessCode && !verifiedAccessCode) {
      setIsOpen(true)
    } else {
      history.push(navUrl)
    }
  }
  return (
    <>
      <Grid
        container
        key={lessonType.id}
        className={classes.card}
        alignItems="center"
        justifyContent="space-between"
        onClick={handleCardClick}
      >
        <Grid
          item
          container
          xs={hasChipsSection ? 8 : 10}
          sm={hasChipsSection ? 7 : 10}
          lg={hasChipsSection ? 6 : 10}
          direction="column"
          className={classes.lessonInfoSection}
        >
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.lineItem}>
            <PriceDetails lessonType={lessonType} />
          </Grid>
          <Grid item xs={12} className={classes.lineItem}>
            <TimerIcon fontSize="small" className={classes.icon} />
            <Typography variant="caption" className={classes.property}>
              {durationText(durationInMinutes)}
            </Typography>
          </Grid>
        </Grid>
        {hasChipsSection && (
          <Grid
            container
            item
            xs={4}
            lg={5}
            direction="column"
            className={classes.chipSection}
          >
            {requiresAccessCode && (
              <Grid item xs={12}>
                <Chip className={classes.chip} label="Access Code" />
              </Grid>
            )}
            {isLessonPack && (
              <Grid item xs={12}>
                <Chip className={classes.chip} label="Lesson Pack" />
              </Grid>
            )}
          </Grid>
        )}
        <ArrowRightAlt className={classes.arrow} />
      </Grid>
      <VerifyAccessCode
        lessonType={lessonType}
        isOpen={requiresAccessCode && !verifiedAccessCode && isOpen}
        setIsOpen={setIsOpen}
        coachName={coachName}
        setVerifiedAccessCode={setVerifiedAccessCode}
        canRequestAccessCode={false}
      />
    </>
  )
}

export const LessonTypesCoachCards = ({
  coachesWithLessonTypes,
  currentUrl,
}) => {
  const classes = useStyles()
  return coachesWithLessonTypes?.map((coachObj, idx) => {
    const coach = coachObj.coach
    const lessonTypes = coachObj.lessonTypes

    return (
      <div key={coach.id}>
        <Divider hidden={idx === 0} />
        <Grid
          container
          className={classes.container}
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={5} key={coach.id} className={classes.coach}>
            <Grid
              container
              alignItems="center"
              className={classes.coachSection}
            >
              <Grid item xs={4}>
                <Avatar
                  alt={coach.name}
                  src={coach.coachProfile.profilePhoto}
                  className={classes.avatar}
                />
              </Grid>
              <Grid item xs={6} sm={8}>
                <Typography variant="h5" className={classes.coachName}>
                  {coach.name}
                </Typography>
                <Typography variant="body1" className={classes.coachTitle}>
                  {coach.coachProfile.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7}>
            {lessonTypes
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((lessonType, idx) => {
                return (
                  <LessonTypeCard
                    lessonType={lessonType}
                    key={idx}
                    currentUrl={currentUrl}
                    coachName={coach.name}
                  />
                )
              })}
          </Grid>
        </Grid>
      </div>
    )
  })
}

LessonTypesCoachCards.propTypes = {
  coachesWithLessonTypes: PropTypes.arrayOf(
    PropTypes.shape({
      coach: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        coachProfile: PropTypes.shape({
          profilePhoto: PropTypes.string,
          title: PropTypes.string.isRequired,
        }),
      }),
      lessonTypes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          priceInCents: PropTypes.number,
          durationInMinutes: PropTypes.number.isRequired,
        }),
      ),
    }),
  ),
}

LessonTypeCard.propTypes = {
  lessonType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    priceInCents: PropTypes.number,
    durationInMinutes: PropTypes.number.isRequired,
  }),
}

export { useStyles }
export default LessonTypeCard
