import { Box, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IconTrashcan } from 'components/atoms/icons'
import {
  HelperMsg,
  SectionTitle,
  Testimony,
  TestimonyFields,
} from 'components/coaches-profile/profile-stepper.styles'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { v1 as uuid } from 'uuid'
import { get } from 'lodash'

const useStyles = makeStyles(theme => ({
  testimonialsContainer: {
    display: 'revert',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .text-input, .multiline': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

const MAX_OFFERINGS_COUNT = 4
const testimonialsMsg = `What about your facility enhances your coaching ability?
                          Add up to ${MAX_OFFERINGS_COUNT} Facility Offerings.`

export const FacilityOfferings = ({ fieldName }) => {
  const { control, errors, register } = useFormContext()

  const classes = useStyles()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  const handleAddOffering = () => {
    append({ name: '', description: '' })
  }

  const offeringError = (index, field) => {
    const fieldResult = get(errors, fieldName)
    const result = get(fieldResult, [index, field])
    return { error: !!result, message: result?.message }
  }

  return (
    <Box className={classes.testimonialsContainer}>
      <SectionTitle>Facility Offerings</SectionTitle>
      <HelperMsg style={{ marginTop: 0 }}>{testimonialsMsg}</HelperMsg>
      {fields.map((item, index) => {
        return (
          <Testimony key={item.id}>
            <TestimonyFields>
              <Controller
                control={control}
                name={`${fieldName}[${index}].name`}
                as={
                  <TextField
                    variant="standard"
                    label="Offering Name"
                    className="text-input multiline"
                    error={errors && offeringError(index, 'name').error}
                    helperText={errors && offeringError(index, 'name').message}
                  />
                }
              />
              <Controller
                control={control}
                name={`${fieldName}[${index}].description`}
                as={
                  <TextField
                    variant="outlined"
                    label="Offering Description"
                    className="multiline"
                    minRows={4}
                    error={errors && offeringError(index, 'description').error}
                    helperText={
                      errors && offeringError(index, 'description').message
                    }
                  />
                }
              />
              <input
                type="hidden"
                ref={register()}
                name={`${fieldName}[${index}].id`}
                value={uuid()}
              />
            </TestimonyFields>
            <Button
              style={{ padding: '8px 40px' }}
              variant="text"
              size="large"
              type="button"
              onClick={() => remove(index)}
            >
              <IconTrashcan />
              Delete
            </Button>
          </Testimony>
        )
      })}
      <Box>
        <Button
          style={{ marginTop: '20px' }}
          variant="outlined"
          color="primary"
          size="large"
          type="button"
          onClick={() => {
            handleAddOffering()
          }}
          disabled={fields.length >= MAX_OFFERINGS_COUNT}
        >
          Add Facility Offering
        </Button>
      </Box>
    </Box>
  )
}

export default FacilityOfferings
