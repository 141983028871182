import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'components/dialog'
import EditPaymentAccountNameForm from './edit-payment-account-name-form'

const EditPaymentAccountNameDialog = ({
  isOpen,
  onOpen,
  onClose,
  onSuccess,
  paymentAccount,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      showCloseButton
    >
      {() => {
        return (
          <>
            <DialogTitle>Edit name</DialogTitle>
            <DialogContent dividers>
              <EditPaymentAccountNameForm
                paymentAccount={paymentAccount}
                onSuccess={onSuccess}
              />
            </DialogContent>
          </>
        )
      }}
    </Dialog>
  )
}

export default EditPaymentAccountNameDialog
