import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Services from '../form-components/services'
import Skills from '../form-components/skills'
import Tools from '../form-components/tools'
import { useFormContext } from 'react-hook-form'

export const SstStep = () => {
  const { getValues } = useFormContext()
  const allValues = getValues()
  const tools = allValues.toolJsonData || []

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h4" gutterBottom>
        Additional Information
      </Typography>
      <Services />
      <Skills />
      <Tools tools={tools} />
    </Box>
  )
}

export default SstStep
