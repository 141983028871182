const RulesAndRegulations = [
  'Simulator bay users must reserve simulator time in advance and use the simulator only during their reserved time.',
  'All individuals entering the facility premises must present a valid form of identification upon arrival.',
  'Appointment cancellations must be made at least 24 hours in advance. Any cancellation made within the 24-hour window prior to the appointment time will result in the individual being held responsible for the appointment fees immediately upon registration.',
  'A maximum of four players are allowed in one simulator bay at one time.',
  'All children under the age of 18 must be accompanied by an adult inside the Coaching Center at all times.',
  'The simulator bay doors must remain closed at all times.',
  'Food and alcoholic beverages may not be brought into the Coaching Center.',
  'All balls should be hit from the middle of the hitting mat. All shots must be directed towards the hitting screen.',
  'Only one player shall be on the hitting mat at any given time. Everyone must pay attention and maintain a safe distance from the player on the hitting mat.',
  'Golf shots may only be done using conventional swings. No ‘Happy Gilmore’ swings.',
  'To register a shot on the screen you must have a green light from the ball placement.',
  'Simulator bay users must follow all instructions and directives from Coaching Center staff. Please consult a Coaching Center staff member with questions. Report all accidents to Coaching Center staff immediately.',
  'All guests must sign the Assumption of Risk, Waiver and Release of Liability, and Indemnity Agreement before using the simulator. Simulator bay users are financially responsible for any personal injury or property damage caused by the failure to follow these Rules and Regulations.',
]

export { RulesAndRegulations }
