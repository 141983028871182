import React, { useState } from 'react'
import { Divider, Grid, Box, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import TimeSlotSelect from 'pages/schedule/SelectLessonTime/time-slot-select'
import { aggregateByDate } from 'pages/schedule/SelectLessonTime/utils'
import ReservationsHeaderWrapper from './reservations-header-wrapper'
import { SPACE_BOOKING_LIMIT } from 'utils/constants'
import Club1916Logo from 'images/club_1916_logo.png'

const getCurrentUrl = () => {
  return window.location.pathname + window.location.search
}

const SelectTimeSlotLayout = ({ academy, refetch }) => {
  const { facilities, coaches, practiceSessionTypes } = academy
  const timeSlots = practiceSessionTypes[0].availableTimes
  const timezone = facilities[0].timezone
  const datesWithAvailability = Object.keys(
    aggregateByDate(timeSlots, timezone),
  ).sort()

  const firstAvailableDate = DateTime.fromISO(
    datesWithAvailability[0] || DateTime.now(),
    {
      zone: timezone,
    },
  )
  const [selectedDate, setSelectedDate] = useState(firstAvailableDate)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const currentUrl = getCurrentUrl()
  const handleMonthChange = async month => {
    const currentTime = DateTime.local()
    const currentMonth = currentTime.month
    const fetchMonth = month.month

    let startDateTime = currentTime.plus({ hour: 1 })
    let endDateTime = startDateTime
      .plus({ days: SPACE_BOOKING_LIMIT })
      .endOf('day')

    if (fetchMonth >= currentMonth + 1) {
      startDateTime = month.startOf('month')
      endDateTime = startDateTime.plus({ days: SPACE_BOOKING_LIMIT })
    }
    setIsDataLoading(true)
    await refetch({ startDateTime, endDateTime })
    setIsDataLoading(false)
    setSelectedDate(startDateTime)
  }

  return (
    <ReservationsHeaderWrapper academy={academy} currentStep={1} totalSteps={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            bgcolor="#F5F7FA"
            borderRadius="8px"
            p={1.5}
            my={2}
            width="fit-content"
          >
            <Box height={46} width={46}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <Grid item>
                  <img
                    src={Club1916Logo}
                    height={32}
                    width={32}
                    alt="Club 1916"
                    style={{ justifyItems: 'center' }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider
              flexItem
              orientation="vertical"
              style={{ marginLeft: '12px', marginRight: '12px' }}
            />
            <Box display="flex" alignItems="center">
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Bay reservations are only available to Club 1916 members and can
                be booked up to 2 weeks in advance.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <TimeSlotSelect
          data={[academy]}
          refetch={refetch}
          setShowRequestTime={false}
          coach={coaches[0]}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          currentUrl={currentUrl}
          onMonthChange={handleMonthChange}
          isDataLoading={isDataLoading}
        />
      </Grid>
    </ReservationsHeaderWrapper>
  )
}

export default SelectTimeSlotLayout
