import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import usStates from 'utils/usStates'
import { FlexInputWrapper } from 'components/coaches-profile/profile-stepper.styles'

export const Location = () => {
  const { register, errors, control } = useFormContext()

  return (
    <FlexInputWrapper>
      <TextField
        key="city"
        name="city"
        label="City"
        type="text"
        inputRef={register()}
        variant="standard"
        className={`text-input ${errors.city && ' error'}`}
        error={!!errors.city}
        helperText={errors.city?.message}
      />
      <FormControl
        error={!!errors.state}
        className={`text-input ${errors.state && ' error'}`}
      >
        <Controller
          name="state"
          control={control}
          render={({ value, onChange }) => (
            <Autocomplete
              name="state"
              disableClearable
              value={value || ''}
              options={usStates}
              onChange={(e, value) => onChange(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="State"
                  variant="standard"
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                />
              )}
            />
          )}
        />
      </FormControl>
      <TextField
        key="zipCode"
        name="zipCode"
        label="Zip"
        type="text"
        inputRef={register()}
        variant="standard"
        className={`text-input ${errors.zipCode && ' error'}`}
        error={!!errors.zipCode}
        helperText={errors.zipCode?.message}
      />
    </FlexInputWrapper>
  )
}

export default Location
