import React from 'react'
import { styled } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const TileButton = styled('div')(({ theme, selected }) => ({
  width: '155px',
  height: '70px',
  border: selected ? '1px solid rgba(0, 35, 75, 0.5)' : '1px solid #E0E0E0',
  marginRight: '10px',
  borderRadius: '4px',
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: selected ? 'rgba(0, 35, 75, 0.08)' : 'white',
}))

const TileButtonWrapper = styled('div')(() => ({
  display: 'flex',
  marginBottom: '20px',
}))

const ButtonText = styled(Typography)(() => ({
  position: 'absolute',
  height: 'auto',
  top: 'calc(50% - 20%)',
  left: 'calc(50% - 20%)',
}))

const CheckIcon = styled(CheckCircleIcon)(() => ({
  position: 'absolute',
  bottom: '5px',
  left: '5px',
}))

export default ({ isAdult, setIsAdult }) => (
  <div style={{ marginBottom: '10px 0' }}>
    <Typography style={{ marginBottom: '10px' }}>
      Is this student an adult or junior (18 years old or younger)?
    </Typography>
    <TileButtonWrapper>
      <TileButton selected={isAdult} onClick={() => setIsAdult(true)}>
        <ButtonText color="primary">ADULT</ButtonText>
        {isAdult && <CheckIcon color="primary" data-testid="adult-selected" />}
      </TileButton>
      <TileButton selected={!isAdult} onClick={() => setIsAdult(false)}>
        <ButtonText color="primary">JUNIOR</ButtonText>
        {!isAdult && (
          <CheckIcon color="primary" data-testid="junior-selected" />
        )}
      </TileButton>
    </TileButtonWrapper>
  </div>
)
