import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import { get } from 'lodash'
import { HelperMsg } from 'components/coaches-profile/profile.styles'
import Error from 'components/error'
import { TextField } from '@material-ui/core'

const PricesQuery = gql`
  query Prices {
    Prices {
      message
      success
      prices {
        priceId
        price
      }
    }
  }
`

const PricesComp = ({ prices }) => {
  const { control, errors, setValue } = useFormContext()

  const handlePriceChange = e => {
    setValue('prices', [{ priceId: e.target.value }], {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  return (
    <>
      <HelperMsg>How much do you charge per lesson?</HelperMsg>
      <FormControl error={!!errors.prices} style={{ maxWidth: '450px' }}>
        <Controller
          render={({ onChange, value }) => (
            <TextField
              select
              name="price"
              variant="standard"
              label="Price"
              error={!!errors.prices}
              helperText={errors?.prices?.message}
              onChange={e => {
                onChange(e)
                handlePriceChange(e)
              }}
              value={(value && value[0]?.priceId) || ''}
            >
              {prices.map(opt => (
                <MenuItem key={opt.priceId} value={opt.priceId}>
                  {opt.price}
                </MenuItem>
              ))}
            </TextField>
          )}
          name="prices"
          control={control}
        />
      </FormControl>
    </>
  )
}

export default function Price() {
  const { data, loading, error } = useQuery(PricesQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Error error={error} />

  return <PricesComp prices={get(data, ['Prices', 'prices'], [])} />
}
