import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from 'components/dialog'
import { useAuth } from 'lib/auth'

const DELETE_FACILITY = gql`
  mutation RemoveCoachFacility($coachId: ID!, $facilityId: ID!) {
    removeCoachFacility(coachId: $coachId, facilityId: $facilityId) {
      success
      message
    }
  }
`

const DeleteFacilityDialog = ({ trigger, facilityId, onSuccess }) => {
  const { user } = useAuth()
  const [deleteFacility, { loading }] = useMutation(DELETE_FACILITY)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Dialog trigger={trigger} maxWidth="xs">
      {({ closeDialog }) => {
        const onDeleteFacilityConfirm = () => {
          deleteFacility({ variables: { coachId: user.id, facilityId } }).then(
            ({ data }) => {
              if (data.removeCoachFacility.success) {
                enqueueSnackbar('Successfully deleted facility', {
                  variant: 'success',
                })
                closeDialog()
                onSuccess && onSuccess()
              } else {
                enqueueSnackbar('Unable to delete facility', {
                  variant: 'error',
                })
              }
            },
          )
        }
        return (
          <>
            <DialogTitle>Delete Facility</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                Are you sure you want to delete this facility? All booking types
                associated to this facility will be deleted, and this facility
                will be removed from your coach profile.
              </Typography>
              {loading ? <CircularProgress color="primary" /> : null}
            </DialogContent>
            <DialogActions>
              <Button color="text" onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onDeleteFacilityConfirm}
                style={{ backgroundColor: '#E31B0C' }}
              >
                Delete Facility
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Dialog>
  )
}

export default DeleteFacilityDialog
