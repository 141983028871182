import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DateTime } from 'luxon'
import { buildDateTime } from 'lib/CustomLuxonUtils'
import CustomLuxonAdapter from 'lib/CustomLuxonAdapter'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { toThreeLetterTimezone } from 'utils/timezones'
import PGATimePicker from '../pga-time-picker'
import isValid from 'date-fns/isValid'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'themes'
import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@mui/icons-material/Event'

const useStyles = makeStyles(theme => ({
  base: {
    background: 'rgb(255, 245, 229)',
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ul: {
    marginTop: '4px',
    marginBottom: '0',
  },
  cardDivider: {
    margin: '0 8px',
    background: 'rgba(0, 35, 75, 0.24)',
  },
  logo: {
    color: '#ff9800',
    width: '50px',
    minWidth: '50px',
    alignSelf: 'center',
  },
  body: {
    padding: '8px 4px',
    justifyContent: 'start',
  },
  dateTimeRow: {
    marginBottom: '30px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'end',
    },
  },
  dateTimeRowError: {
    marginBottom: '30px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'baseline',
    },
  },
  dateInput: {
    order: 1,
  },
  timeInputs: {
    order: 2,
  },
  startTimeInput: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  endTimeInput: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  deleteSessionBtn: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  divider: {
    margin: '30px 0px',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '0',
  },
  additionalSessionInfo: {
    textAlign: 'center',
    paddingTop: '5px',
  },
  addSessionBtn: {
    marginTop: '15px',
  },
  multiSessionInfoText: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  infoHeader: {
    paddingBottom: '5px',
  },
}))

const SessionInput = ({
  session,
  timezone,
  onDelete,
  handleVerifySessions,
  index,
  name,
  fields,
}) => {
  const classes = useStyles()
  const { control, register, errors, setValue } = useFormContext()

  const [startDate, setStartDate] = useState(session?.startDate)
  const [startTime, setStartTime] = useState(session?.startTime)
  const [endTime, setEndTime] = useState(session?.endTime)

  const defineTimezone = (session, timezone, field) => {
    const resultTimezone = toThreeLetterTimezone(
      timezone,
      buildDateTime(session.startDate, session[field]),
    )
    return resultTimezone ? `(${resultTimezone})` : ''
  }

  const startDateTime =
    startDate &&
    startTime &&
    buildDateTime(startDate, startTime, timezone).toUTC()

  const changeStartDate = value => {
    const date = new Date(value)

    if (isValid(date)) {
      setStartDate(date)
      handleVerifySessions()

      const day = date.getDate()
      const month = date.getMonth()

      const newStartTime = new Date(startTime)
      newStartTime.setDate(day)
      newStartTime.setMonth(month)
      setValue(`${name}[${index}].startTime`, DateTime.fromJSDate(newStartTime))

      const newEndTime = new Date(endTime)
      newEndTime.setDate(day)
      newEndTime.setMonth(month)
      setValue(`${name}[${index}].endTime`, DateTime.fromJSDate(newEndTime))
    }
  }

  const changeStartTime = value => {
    const date = new Date(value.ts)

    if (isValid(date)) {
      handleVerifySessions()

      const minutes = date.getMinutes()
      const roundedMinutes = Math.floor(minutes / 5) * 5
      date.setMinutes(roundedMinutes)
      setStartTime(date)
    }
  }

  const changeEndTime = value => {
    const date = new Date(value.ts)

    if (isValid(date)) {
      handleVerifySessions()

      const minutes = date.getMinutes()
      const roundedMinutes = Math.floor(minutes / 5) * 5
      date.setMinutes(roundedMinutes)

      setEndTime(date)
    }
  }
  return (
    <Grid
      className={
        errors?.sessions?.[index]
          ? classes.dateTimeRowError
          : classes.dateTimeRow
      }
      container
      spacing={1}
    >
      <Grid
        item
        xs={12}
        sm={fields.length > 1 ? 5 : 6}
        className={classes.dateInput}
      >
        <LocalizationProvider
          dateAdapter={CustomLuxonAdapter}
          dateFormats={{ normalDateWithWeekday: 'LLL d, yyyy' }} // Jul 1, 2021
        >
          <ThemeProvider theme={createTheme(themes.offeringsRefresh)}>
            <Controller
              control={control}
              name={`${name}[${index}].startDate`}
              defaultValue={session.startDate}
              render={({ onChange, value }) => (
                <MobileDatePicker
                  label="Date"
                  id="start-date"
                  name={`${name}[${index}].startDate`}
                  innerRef={register}
                  value={value}
                  onChange={newValue => {
                    onChange(newValue)
                    changeStartDate(newValue)
                  }}
                  disablePast
                  format="MMM dd, yyyy"
                  dayOfWeekFormatter={day => `${day}`}
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      error: !!errors[name]?.[index],
                      helperText:
                        errors[name]?.[index]?.startDate?.message ||
                        errors[name]?.[index]?.message,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                />
              )}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={12}
        sm={fields.length > 1 ? 7 : 6}
        className={classes.timeInputs}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={fields.length > 1 ? 5 : 6}
            className={classes.startTimeInput}
          >
            <PGATimePicker
              id="start-time"
              name={`${name}[${index}].startTime`}
              defaultValue={session.startTime}
              date={startDateTime}
              error={!!errors[name]?.[index]}
              helperText={errors[name]?.[index]?.startTime?.message}
              onChanged={newValue => {
                changeStartTime(newValue)
              }}
              label={`Start time ${defineTimezone(
                session,
                timezone,
                'startTime',
              )}`}
              minutesStep={5}
              format="h:mm a"
            />
          </Grid>
          <Grid
            item
            xs={fields.length > 1 ? 5 : 6}
            className={classes.endTimeInput}
          >
            <PGATimePicker
              id="end-time"
              name={`${name}[${index}].endTime`}
              defaultValue={session.endTime}
              date={startDateTime}
              error={!!errors[name]?.[index]}
              helperText={errors[name]?.[index]?.endTime?.message}
              onChanged={newValue => changeEndTime(newValue)}
              label={`End time ${defineTimezone(session, timezone, 'endTime')}`}
              horizontalAnchor="right"
              minutesStep={5}
              format="h:mm a"
            />
          </Grid>
          {fields.length > 1 && (
            <Grid item xs={2} className={classes.deleteSessionBtn}>
              <IconButton
                onClick={() => {
                  onDelete(index)
                }}
              >
                <DeleteOutlineIcon color="primary" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SessionInput
