import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

export default function LoadingSpinner() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}
