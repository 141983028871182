import { gql } from '@apollo/client'

const GET_LESSON_CREDIT_BALANCE = gql`
  query lessonCreditBalances {
    currentUser {
      attributes {
        ... on Contact {
          lessonPacks {
            id
            totalCredits
            remainingCredits
            expiresAt
            lessonCredits {
              updatedAt
            }
            lessonType {
              id
              title
              locations {
                id
              }
              coach {
                name
                bookingUrl
              }
            }
          }
        }
      }
    }
  }
`

export default GET_LESSON_CREDIT_BALANCE
