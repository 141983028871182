import { useAuth } from 'lib/auth'
import { useQuery } from '@apollo/client'
import { GetStudentsQuery } from 'pages/schedule/SelectStudent/query'
import { ContainerRoot } from 'pages/schedule/components/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import CreateAccountForBookingLayout from 'pages/schedule/SelectStudent/CreateAccountForBookingLayout'
import themes, { extendTheme } from 'themes'
import SelectStudentSkeleton from 'pages/schedule/SelectStudent/select-student-skeleton'
import SelectStudentLayout from 'pages/schedule/SelectStudent/SelectStudentLayout'
import Error from 'components/error'

const GetStudents = ({
  isCoach,
  user,
  lessonTypeId,
  currentUrl,
  previousUrl,
  progressValue,
  stepText,
}) => {
  const { data, loading, error } = useQuery(GetStudentsQuery)

  if (loading) return <SelectStudentSkeleton isCoach={isCoach} />
  if (error) return <Error error={error} />

  return (
    <SelectStudentLayout
      students={data?.currentUser?.students}
      isCoach={isCoach}
      user={user}
      lessonTypeId={lessonTypeId}
      currentUrl={currentUrl}
      previousUrl={previousUrl}
      progressValue={progressValue}
      stepText={stepText}
    />
  )
}

const SelectStudentPage = ({
  lessonTypeId,
  currentUrl,
  previousUrl,
  progressValue,
  stepText,
}) => {
  const { isLoggedIn, isCoach, user } = useAuth()
  return (
    <ContainerRoot>
      <Grid container>
        <Grid item xs={12}>
          {isLoggedIn ? (
            <Grid item xs={12}>
              <Divider style={{ margin: '1rem 0 2.25rem' }} />
              <GetStudents
                isCoach={isCoach}
                user={user}
                lessonTypeId={lessonTypeId}
                currentUrl={currentUrl}
                previousUrl={previousUrl}
                progressValue={progressValue}
                stepText={stepText}
              />
            </Grid>
          ) : (
            <CreateAccountForBookingLayout
              theme={extendTheme(themes.offeringsRefresh)}
            />
          )}
        </Grid>
      </Grid>
    </ContainerRoot>
  )
}

export default SelectStudentPage
