import { Box, Dialog, Button, Typography } from '@material-ui/core'
import React from 'react'

export default function ConfirmationDialog({
  onConfirm,
  onCancel,
  open,
  message,
  confirmText = 'Yes',
  cancelText = 'Cancel',
  heading = 'Are you sure?',
  confirmVariant = 'contained',
  disabled = false,
}) {
  return (
    <Dialog open={open}>
      <Box display="flex" flexDirection="column" p={4}>
        <Box textAlign="center">
          <Typography variant="h6">{heading}</Typography>
        </Box>
        <Box mt={4} mb={4}>
          <Typography variant="body2" color="textSecondary">
            {message}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Button onClick={onCancel} color="primary">
            {cancelText}
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            variant={confirmVariant}
            disabled={disabled}
          >
            {confirmText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
