import React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const UPDATE_PAYMENT_ACCOUNT = gql`
  mutation UpdatePaymentAccount($input: UpdatePaymentAccountInput!) {
    updatePaymentAccount(input: $input) {
      success
      message
    }
  }
`

const EditPaymentAccountNameForm = ({ paymentAccount, onSuccess }) => {
  const { register, errors, handleSubmit } = useForm({
    defaultValues: { name: paymentAccount.name },
  })

  const [updatePaymentAccount] = useMutation(UPDATE_PAYMENT_ACCOUNT, {
    onCompleted: onSuccess,
  })

  const onSubmit = ({ name }) => {
    return updatePaymentAccount({
      variables: {
        input: {
          id: paymentAccount.id,
          name,
        },
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <TextField
          autoFocus
          name="name"
          label="Name"
          inputRef={register({ required: true })}
          variant="outlined"
          fullWidth
          error={!!errors?.name}
          helperText={errors?.name?.message}
        />
      </Box>
      <Box textAlign="right">
        <Button type="submit" variant="contained" color="primary" size="large">
          Save
        </Button>
      </Box>
    </form>
  )
}

export default EditPaymentAccountNameForm
