import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import {
  Avatar,
  Box,
  Typography,
  MenuItem,
  Container,
  Select,
  Toolbar,
  FormControl,
  Tabs,
  Tab,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { CalendarTodayOutlined } from '@material-ui/icons'
import {
  parsePastSessions,
  parseUpcomingSessions,
  yearsSelect,
  filterByYear,
} from './utils'
import { useIsMobile, useIsTablet } from 'lib/hooks'
import LoadingView from '../loading-view'
import SessionItem from './sessions-components/session-item'
import GroupEventItem from './sessions-components/group-event-item'
import EnhancedTable from 'components/tables/enhanced-table'
import { onReschedule, onPayment, onCancellation } from 'lib/utils'
import { checkSessionStatus } from 'lib/sessions/utils'

const StudentSessions = ({ sessions = {} }) => {
  const classes = useStyles()
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()

  const { data: { studentEnrollments = [] } = {}, loading, refetch } = sessions

  const [sessionType, setSessionType] = useState('upcoming')
  const [year, setYear] = useState(null)
  const [selectYears, setSelectYears] = useState([])
  const [tabSessions, setTabSessions] = useState([])

  useEffect(() => {
    if (!loading) {
      if (sessionType === 'upcoming') {
        const initial = parseUpcomingSessions(studentEnrollments)
        setTabSessions(initial)
      }
    }
  }, [studentEnrollments, loading, sessionType])

  const handleToggleChange = (event, newType) => {
    // There's a chance newType is `null` -- in this case, do nothing
    // https://mui.com/material-ui/react-toggle-button/#enforce-value-set
    if (newType || newType === sessionType) {
      setSessionType(newType)
    }

    if (newType !== sessionType) {
      if (newType === 'upcoming') {
        setYear(null)
        const newSessions = parseUpcomingSessions(studentEnrollments)
        setTabSessions(newSessions)
      }
      if (newType === 'past') {
        if (year === null) {
          const newSessions = parsePastSessions(studentEnrollments)
          const pastYears = yearsSelect(newSessions)
          const start = pastYears[0]
          setYear(start)
          setSelectYears(pastYears)
          setTabSessions(filterByYear(newSessions, start))
        }
      }
    }
  }

  // The innate refetch function, while indeed triggering a refetch, did not cause any re-renders to reflect
  // the new info. This function updates state manually based on the new info, so mutation changes can be seen.
  const handleRefetch = () => {
    refetch().then(({ data }) => {
      if (sessionType === 'upcoming') {
        const newSessions = parseUpcomingSessions(data?.studentEnrollments)
        setTabSessions(newSessions)
      }
      if (sessionType === 'past') {
        const newSessions = parsePastSessions(data?.studentEnrollments)
        setTabSessions(filterByYear(newSessions, year))
      }
    })
  }

  const handleSelectChange = ({ target }) => {
    setYear(target.value)
    const newSessions = parsePastSessions(studentEnrollments)
    setTabSessions(filterByYear(newSessions, target.value))
  }

  function MobileTabs() {
    return (
      <Tabs value={sessionType} onChange={handleToggleChange}>
        <Tab label="Upcoming" value="upcoming" />
        <Tab label="Past" value="past" />
      </Tabs>
    )
  }

  function EmptyState() {
    return (
      <Box className={classes.emptyBox}>
        <Avatar variant="circular" className={classes.emptyIcon}>
          <CalendarTodayOutlined fontSize="large" />
        </Avatar>
        <Typography className={classes.emptyText}>No Sessions</Typography>
        <Typography className={classes.emptySubtext}>
          Sessions will show up here.
        </Typography>
      </Box>
    )
  }

  const columns = [
    {
      id: 'title',
      label: null,
      align: 'left',
      width: 'auto',
      mobile: true,
      sortable: false,
    },
    {
      id: 'date',
      label: null,
      align: 'left',
      width: '15%',
      mobile: false,
      sortable: false,
    },
    {
      id: 'coach',
      label: null,
      align: '20%',
      width: 'auto',
      mobile: false,
      sortable: false,
    },
    {
      id: 'status',
      label: null,
      align: 'left',
      width: '10%',
      mobile: false,
      sortable: false,
    },
    {
      id: 'actions',
      label: null,
      align: 'left',
      width: '10%',
      mobile: false,
      sortable: false,
    },
  ]

  const rows = tabSessions?.map(session => {
    const { isPaid, isCanceled, isLessonCredit } = checkSessionStatus({
      isCanceled: session.isCanceled,
      enrollments: [session],
    })

    if (session.lesson.__typename === 'Session') {
      return (
        <SessionItem
          session={session}
          isCanceled={isCanceled}
          isPaid={isPaid}
          isLessonCredit={isLessonCredit}
          isTablet={isTablet}
          refetch={handleRefetch}
          key={session.id}
          onReschedule={() => onReschedule(handleRefetch)}
          onPayment={() => onPayment(handleRefetch)}
          onCancellation={() => onCancellation(handleRefetch)}
        />
      )
    }
    if (session.lesson.__typename === 'GroupEvent') {
      return (
        <GroupEventItem
          session={session}
          isCanceled={session.isCanceled}
          isPaid={isPaid}
          isTablet={isTablet}
          refetch={handleRefetch}
          key={session.id}
          onPayment={() => onPayment(handleRefetch)}
        />
      )
    }
    return null
  })

  function TableToolbar() {
    return (
      <Toolbar className={classes.toolbar} disableGutters>
        {isMobile ? (
          <MobileTabs />
        ) : (
          <ToggleButtonGroup
            size="small"
            value={sessionType}
            color="primary"
            exclusive
            onChange={handleToggleChange}
            className={classes.tabs}
          >
            <ToggleButton value="upcoming">Upcoming</ToggleButton>
            <ToggleButton value="past">Past</ToggleButton>
          </ToggleButtonGroup>
        )}
        <Box className={classes.controlsContainer}>
          {sessionType === 'past' && selectYears.length ? (
            <Box className={classes.yearFilter}>
              <FormControl size="small" fullWidth>
                <Select
                  id="select-past-year"
                  value={year}
                  defaultValue={year}
                  onChange={handleSelectChange}
                >
                  {selectYears.map(y => (
                    <MenuItem value={y} key={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </Box>
      </Toolbar>
    )
  }

  if (loading) {
    return <LoadingView />
  } else if (studentEnrollments?.length === 0) {
    return <EmptyState />
  }

  return (
    <Container>
      <TableToolbar />
      <EnhancedTable
        rows={rows}
        columns={columns}
        label="Sessions"
        numberOfRows={8}
        emptyState={EmptyState}
        rowHeight={isTablet && '105px'}
      />
    </Container>
  )
}

export default StudentSessions
