import React from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const Error = ({ error }) => {
  let errorTitle = ''
  let errorDescription = ''

  switch (error.error) {
    case 'access_denied':
      errorTitle = 'Access Denied'
      break
    case 'unauthorized':
      errorTitle = 'Unauthorized'
      break
    default:
      errorTitle = 'Error'
      break
  }
  errorDescription = error.error_description

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        backgroundImage:
          'url(https://images.ctfassets.net/56u5qdsjym8c/3MODyhNak3B1FiX6QZ1k1m/60c76e7f493e787614aef34f0eea1639/auth0-background.jpeg)',
        backgroundPosition: '50%',
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="sm">
        <Paper>
          <Box p={4} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" gutterBottom>
              {errorTitle}
            </Typography>
            <Typography
              variant="body1"
              style={{ textAlign: 'center' }}
              gutterBottom
            >
              {errorDescription}
            </Typography>
            {error?.appState?.returnTo && (
              <Link href={error?.appState?.returnTo}>Retry</Link>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}

export default Error
