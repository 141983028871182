import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import Error from 'components/error'
import { LessonReviewQuery, GetStudentQuery } from './query'
import LessonReviewLayout from './lesson-review-layout'
import SummaryLayout from './summary-layout'
import ReviewBookingSkeleton from 'components/skeletons/review-booking-skeleton'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const RedeemLessonsCredit = ({
  idOrSlug,
  lessonTypeId,
  locationId,
  startDateTime,
  studentId,
  previousUrl,
  lessonCreditBalanceQuantity,
  progressValue = 100,
  stepText = 'Step 3 of 3',
}) => {
  const fetchedLessonInfo = useQuery(LessonReviewQuery, {
    variables: {
      id: lessonTypeId,
    },
  })
  const fetchedStudent = useQuery(GetStudentQuery, {
    variables: {
      id: studentId,
    },
  })

  const lessonType = get(fetchedLessonInfo.data, 'lessonTypes.0')
  const timezone = get(lessonType?.facilities, ['0', 'timezone'])

  const eventObject = {
    event: '',
    title: lessonType?.title,
    category: lessonType?.category,
    facility: get(lessonType?.facilities, ['0', 'name']),
    location: `${get(lessonType?.facilities, ['0', 'city'])}, ${get(
      lessonType?.facilities,
      ['0', 'state'],
    )}`,
    price: `${lessonType?.priceInCents / 100}`,
    event_date: DateTime.fromISO(startDateTime, {
      zone: timezone,
    }).toFormat('yyyy-LL-dd'),
    event_time: DateTime.fromISO(startDateTime, {
      zone: timezone,
    }).toFormat('hh:mm a'),
    duration: lessonType?.durationInMinutes,
    coach_id: lessonType?.coach?.id,
    coach_name: lessonType?.coach?.name,
    access_code_required: lessonType?.requiresAccessCode ? 'yes' : 'no',
  }

  const [isComplete, setIsComplete] = useState(false)
  if (fetchedLessonInfo.error || fetchedStudent.error) {
    return <Error error={fetchedLessonInfo.error || fetchedStudent.error} />
  }
  if (fetchedLessonInfo.loading || fetchedStudent.loading) {
    return <ReviewBookingSkeleton />
  }
  const selectedStudent = fetchedStudent.data?.student
  return (
    <>
      {!isComplete ? (
        <LessonReviewLayout
          lessonType={lessonType}
          student={selectedStudent}
          locationId={locationId}
          startDateTime={startDateTime}
          previousUrl={previousUrl}
          lessonCreditBalanceQuantity={lessonCreditBalanceQuantity}
          setIsComplete={setIsComplete}
          progressValue={progressValue}
          stepText={stepText}
          eventObject={eventObject}
        />
      ) : (
        <SummaryLayout
          lessonType={lessonType}
          student={selectedStudent}
          idOrSlug={idOrSlug}
          lessonTypeId={lessonTypeId}
          locationId={locationId}
          startDateTime={startDateTime}
          lessonCreditBalanceQuantity={lessonCreditBalanceQuantity - 1}
        />
      )}
    </>
  )
}

RedeemLessonsCredit.propTypes = {
  idOrSlug: PropTypes.string.isRequired,
  lessonTypeId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  previousUrl: PropTypes.string.isRequired,
  lessonCreditBalanceQuantity: PropTypes.string.isRequired,
}
export default RedeemLessonsCredit
