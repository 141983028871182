export function prepareCustomAttachment({ message, shared, attType, id }) {
  // For types with links, make sure that user didn't delete the URL if there was one.
  // If they did, they probably didn't want to send it so we shouldn't add an attachment.
  switch (attType) {
    case 'pga-group-event':
      if (message.text.includes(shared)) {
        return {
          type: 'pga_group_event',
          group_event_id: id,
          booking_url: shared,
        }
      } else return null
    case 'pga-lesson-type':
      if (message.text.includes(shared)) {
        return {
          type: 'pga_lesson_type',
          lesson_type_id: id,
          booking_url: shared,
        }
      } else return null
    default:
      return null
  }
}

export const OUR_TYPES = [
  'pga_group_event',
  'pga_lesson_type',
  'note',
  'pga_student_library_upload',
]

export function replaceMentions({ message }) {
  // It replaces the user mentions with the actual user names.

  let messageTextToRender = message.text
  message.mentioned_users.forEach(user => {
    const { id, name } = user
    // replace instances of @id with @name
    messageTextToRender = messageTextToRender.replaceAll(`@${id}`, `@${name}`)
  })

  message.text = messageTextToRender
  return message
}
