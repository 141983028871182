import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'
import { withAuthenticatedRoute } from 'lib/auth'
import { ThemeProvider } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useStyles from './styles'
import EmptyPageState from 'components/empty-page-state'
import PartyPopperIcon from 'components/icons/party-popper-icon'
import { InstantSearch, Configure, connectHits } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'env'
import YoutubeEmbed from '../../YoutubeEmbed'
import themes, { extendTheme } from 'themes'
import PGALogo from 'images/pga-logo-navy.png'

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

const Activities = ({ hits }) => {
  return (
    <>
      {hits.map(hit => (
        <Fragment key={hit.objectID}>
          <Typography variant="h6" gutterBottom>
            {hit.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {hit.details.summary}
          </Typography>
          <YoutubeEmbed embedId={hit.video.mediaId} />
        </Fragment>
      ))}
    </>
  )
}

const ActivitiesHits = connectHits(Activities)

const ActivityPlanList = activities => {
  const plans = get(activities, 'activities', [])
  return (
    <>
      {plans.map(plan => (
        <InstantSearch
          indexName="coach_activities_dev"
          searchClient={searchClient}
          key={plan}
        >
          <ActivitiesHits />
          <Configure filters={`objectID:${plan}`} hitsPerPage={10} />
        </InstantSearch>
      ))}
    </>
  )
}

const Hits = ({ hits }) => {
  const hit = hits[0]
  const sections = get(hit, 'sections', [])
  const styles = useStyles()
  return (
    <Grid container spacing={2}>
      {hit ? (
        <>
          <Typography variant="h4" className={styles.heading}>
            {hit.title}
          </Typography>
          {sections.map(section => (
            <div key={section.name}>
              <Typography variant="h5" className={styles.heading}>
                {section.name}
              </Typography>
              <ActivityPlanList activities={section.activities} />
            </div>
          ))}
        </>
      ) : (
        <Grid item xs={12}>
          <EmptyPageState
            IconComp={() => <PartyPopperIcon />}
            title="No activity plans found."
            removeButton
          />
        </Grid>
      )}
    </Grid>
  )
}

const ActivityPlanCards = connectHits(Hits)

const isIntercomOverride = !!window.location.search.match(/intercomOverride/i)

const SinglePlansPublicPage = () => {
  const { id } = useParams()
  const classes = useStyles()
  const query = 'objectID:' + id

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
        ...(isIntercomOverride ? themes.intercom : {}),
      })}
    >
      <Helmet>
        <title>PGA | Activity plans</title>
        <meta name="description" content="PGA | Activity plans" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <Container maxWidth="md">
        <Grid container spacing={2} style={{ margin: '16px 0' }}>
          <img src={PGALogo} alt="PGA logo" width={70} />
        </Grid>
      </Container>
      <Box className={classes.container}>
        <Container maxWidth="md">
          <InstantSearch
            indexName="coach_activities_dev"
            searchClient={searchClient}
          >
            <ActivityPlanCards />
            <Configure filters={`${query}`} hitsPerPage={1} />
          </InstantSearch>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default withAuthenticatedRoute(SinglePlansPublicPage)
