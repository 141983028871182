import React from 'react'

import { ReactComponent as MonitorMan } from 'images/icons/welcome-page-icons/monitor-man.svg'
import { ReactComponent as CalendarKeyIcon } from 'images/icons/welcome-page-icons/calendar-key-icon.svg'
import { ReactComponent as ChatIcon } from 'images/icons/welcome-page-icons/chat-icon.svg'

import { ReactComponent as CalendarIcon } from 'images/icons/welcome-page-icons/calendar-icon.svg'
import { ReactComponent as SpeakerIcon } from 'images/icons/welcome-page-icons/speaker-icon.svg'
import { ReactComponent as PeopleIcon } from 'images/icons/welcome-page-icons/people-icon.svg'

import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const golferBullets = [
  {
    key: 1,
    Icon: MonitorMan,
    text: 'Find and connect with a coach in your area',
  },
  {
    key: 2,
    Icon: CalendarKeyIcon,
    text: 'Get direct access to your coaches calendar with online bookings.',
  },
  {
    key: 3,
    Icon: ChatIcon,
    text: 'No more emails. Message your coach directly in the app.',
  },
]

export const pgaCoachBullets = [
  {
    key: 1,
    Icon: CalendarIcon,
    text: 'Ditch pen and paper calendars with online bookings.',
  },
  {
    key: 2,
    Icon: SpeakerIcon,
    text: 'Manage your coaching business in one app.',
  },
  {
    key: 3,
    Icon: PeopleIcon,
    text: 'Simplify your contact list with Student Management',
  },
]

const useStyles = makeStyles(theme => ({
  bulletPointRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  iconStyles: {
    margin: '0 10px',
  },
}))

export default ({ collection, color }) => {
  const classes = useStyles()
  return collection.map(({ key, Icon, text }) => (
    <div key={key} className={classes.bulletPointRow}>
      <Icon className={classes.iconStyles} />
      <Typography variant="body1" color={color}>
        {text}
      </Typography>
    </div>
  ))
}
