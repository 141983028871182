import React, { useState, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import InfoAlert from 'components/info-alert'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import AccountRouterLink from 'components/account-router-link'
import { useWizardFormContext } from 'components/wizard-form/context'
import CurrencyField from 'components/form-util/currency'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import { GROUP_EVENT_COST, GROUP_EVENT_PAYMENT_OPTIONS } from '../queries'
import { apolloClient } from 'lib/graphql'
import { buildDateTime } from 'lib/CustomLuxonUtils'
import { centsToDollarsFormatted } from 'lib/utils/number'
import pluralize from 'lib/pluralize'
import {
  isWeeklyRecurringPayment,
  isMonthlyRecurringPayment,
  isOnlinePayment,
  isRecurringPayment,
  isManualPayment,
  MANUAL_PAYMENT,
} from 'utils/groupEventUtils'
import uniq from 'lodash/uniq'

const useStyles = makeStyles(theme => ({
  divider: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: '0',
  },
}))

/**
 * Generates a list of mapped sessions for various group event GQL queries
 *
 * @param {Object} params - The input parameters.
 * @param {Array} params.sessions - The sessions of the group event.
 * @param {Object} params.selectedFacility - The facility where the group event will be held.
 * @returns {Array} The mapped sessions.
 */
function generateMappedSessions({ sessions, selectedFacility }) {
  // Sometimes the datetime in sessions, while editting can be invalid which causes the buildDateTime function to throw an error
  // This try/catch block is to prevent the entire page from crashing when this happens
  try {
    return sessions.map(session => ({
      facilityId: selectedFacility.id,
      startDateTime: buildDateTime(
        session.startDate,
        session.startTime,
        selectedFacility.timezone,
      ),
      endDateTime: buildDateTime(
        session.startDate,
        session.endTime,
        selectedFacility.timezone,
      ),
    }))
  } catch (error) {
    return []
  }
}

/**
 * Serialize the mapped sessions for a group event in order to identify changes to the underlying data presented in the list of sessions
 * This is particularly useful to ensure that the recurring payment options are updated as internal values of the sessions change
 *
 * @param {Object} params - The input parameters.
 * @param {Array} params.sessions - The sessions of the group event.
 * @param {Object} params.selectedFacility - The facility where the group event will be held.
 * @returns {string} The serialized sessions.
 */
function serializeSessions({ sessions, selectedFacility }) {
  if (sessions && selectedFacility) {
    return JSON.stringify(
      generateMappedSessions({ sessions, selectedFacility }),
    )
  }
}

/**
 * Performs a GraphQL query to calculate the cost of a group event.
 *
 * @param {Object} params - The input parameters.
 * @param {number} params.priceInCents - The price of the purchase, in cents.
 * @param {string} params.paymentRecurrenceSchedule - The schedule of the payments.
 * @param {Array} params.sessions - The sessions of the group event.
 * @param {Object} params.selectedFacility - The facility where the group event will be held.
 * @returns {Object} The cost of a group event.
 */
const fetchCalculateGroupEventCost = async ({
  priceInCents,
  paymentRecurrenceSchedule,
  sessions,
  selectedFacility,
}) => {
  const mappedSessions = generateMappedSessions({ sessions, selectedFacility })

  const { data } = await apolloClient.query({
    query: GROUP_EVENT_COST,
    variables: {
      priceInCents,
      paymentRecurrenceSchedule,
      sessions: mappedSessions,
    },
    fetchPolicy: 'cache-first',
  })

  return data.groupEventCost
}

/**
 * Performs a GraphQL query to fetch the payment options for a group event.
 *
 * @param {Object} params - The input parameters.
 * @param {Array} params.sessions - The sessions of the group event.
 * @param {Object} params.selectedFacility - The facility where the group event will be held.
 * @returns {Array} The payment options for a group event.
 */
const fetchGroupEventPaymentOptions = async ({
  sessions,
  selectedFacility,
}) => {
  const mappedSessions = generateMappedSessions({ sessions, selectedFacility })

  if (mappedSessions.length === 0) {
    return []
  }

  const { data } = await apolloClient.query({
    query: GROUP_EVENT_PAYMENT_OPTIONS,
    variables: {
      sessions: mappedSessions,
    },
    fetchPolicy: 'cache-first',
  })

  return data.groupEventPaymentOptions
}

/**
 * Returns a label describing the payment calculation for a purchase.
 *
 * @param {Object} params - The input parameters.
 * @param {number} params.price - The price of the purchase, in cents.
 * @param {number} params.calculatedNumberOfPayments - The total number of payments to be made.
 * @param {string} params.paymentRecurrenceSchedule - The schedule of the payments.
 * @returns {string} A label describing the payment calculation for a purchase.
 */
function paymentCalculationLabel({
  price,
  calculatedNumberOfPayments,
  paymentRecurrenceSchedule,
}) {
  const formattedPrice = centsToDollarsFormatted(price * 100)
  const isWeeklyRecurring = isWeeklyRecurringPayment({
    paymentRecurrenceSchedule,
  })
  const isMonthlyRecurring = isMonthlyRecurringPayment({
    paymentRecurrenceSchedule,
  })
  const paymentSuffix = pluralize(calculatedNumberOfPayments - 1, 'payment')
  const anniversarySuffix = isMonthlyRecurring
    ? ' on the anniversary of the first session'
    : ''
  const paymentType = isWeeklyRecurring ? 'weekly' : 'monthly'
  const additionalPayments = calculatedNumberOfPayments - 1

  return `${formattedPrice} due at registration, followed by ${additionalPayments} additional ${paymentType} ${paymentSuffix}${anniversarySuffix}.`
}

/**
 * Returns a label describing the payment calculation for a purchase.
 *
 * @param {Object} params - The input parameters.
 * @param {string} params.paymentRecurrenceSchedule - The schedule of the payments.
 * @returns {string} A label describing the payment calculation for a purchase.
 */
const pricingLabel = paymentRecurrenceSchedule => {
  let label = 'How much do you want to charge for this offering?'

  if (isWeeklyRecurringPayment({ paymentRecurrenceSchedule })) {
    label = `${label} (per week)`
  } else if (isMonthlyRecurringPayment({ paymentRecurrenceSchedule })) {
    label = `${label} (per month)`
  } else {
    label = `${label} (all sessions included)`
  }

  return label
}

const feeLabel = paymentRecurrenceSchedule => {
  if (isRecurringPayment({ paymentRecurrenceSchedule })) {
    return (
      <>
        Please note that a 2.9% + $0.30 fee will be applied to{' '}
        <strong>each</strong> billing cycle transaction.
      </>
    )
  } else {
    return (
      <>
        Please note that a 2.9% + $0.30 fee will be applied to the transaction
        total.
      </>
    )
  }
}

const PaymentAccountRedesign = () => {
  const classes = useStyles()
  const { errors, control, watch, register, setValue } = useFormContext()
  const {
    extraData: { paymentAccounts, coachFacilities, coachAcademies },
  } = useWizardFormContext()

  const academyFacilities = coachAcademies.reduce(
    (acc, academy) => [...acc, ...academy.facilities],
    [],
  )

  const allFacilities = uniq(coachFacilities.concat(academyFacilities))

  const isExternalRegistration = watch('isExternalRegistration')
  const paymentRecurrenceSchedule = watch('paymentRecurrenceSchedule')
  const sessions = watch('sessions')
  const price = watch('price')
  const locationId = watch('locationId')
  const selectedFacility = allFacilities.find(f => f.id === locationId)
  const serializedSessionsJSON = serializeSessions({
    sessions,
    selectedFacility,
  })

  const [
    calculatedTotalPriceInCents,
    setCalculatedTotalPriceInCents,
  ] = useState(null)

  const [calculatedNumberOfPayments, setCalculatedNumberOfPayments] = useState(
    null,
  )

  const [paymentOptions, setPaymentOptions] = React.useState([])

  // Fetch the recurring payment options
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    ;(async () => {
      if (sessions && sessions.length > 0 && selectedFacility) {
        const groupEventPaymentOptions = await fetchGroupEventPaymentOptions({
          sessions,
          selectedFacility,
        })

        setPaymentOptions(groupEventPaymentOptions)
      } else {
        setPaymentOptions([])
      }
    })()
  }, [selectedFacility, serializedSessionsJSON])

  // Fetch the cost of the group event.
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    ;(async () => {
      const priceInCents = parseInt(
        isNaN(parseFloat(price)) ? 0 : parseFloat(price) * 100,
      )

      if (
        selectedFacility &&
        priceInCents > 0 &&
        isRecurringPayment({ paymentRecurrenceSchedule })
      ) {
        try {
          const groupEventCost = await fetchCalculateGroupEventCost({
            priceInCents,
            paymentRecurrenceSchedule,
            sessions,
            selectedFacility,
          })

          setCalculatedTotalPriceInCents(groupEventCost.totalPriceInCents)
          setCalculatedNumberOfPayments(groupEventCost.numberOfPayments)
        } catch (error) {
          setCalculatedTotalPriceInCents(null)
          setCalculatedNumberOfPayments(null)
        }
      } else {
        setCalculatedTotalPriceInCents(null)
        setCalculatedNumberOfPayments(null)
      }
    })()
  }, [
    paymentRecurrenceSchedule,
    price,
    selectedFacility,
    serializedSessionsJSON,
  ])

  if (
    isExternalRegistration &&
    !isManualPayment({ paymentRecurrenceSchedule })
  ) {
    setValue('paymentRecurrenceSchedule', MANUAL_PAYMENT)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={6}>
        <Typography variant="h4" align="center">
          How do you want to collect payment for this offering?
        </Typography>
      </Box>
      <Container maxWidth="xs">
        <Box mb={6}>
          <FormControl
            error={!!errors.paymentRecurrenceSchedule}
            variant="outlined"
            required
          >
            <InputLabel id="payment-recurrence-schedule-label">
              Payment Collection
            </InputLabel>
            <Controller
              defaultValue=""
              as={
                <Select
                  labelId="paymentRecurrenceSchedule"
                  label="Payment Collection"
                  disabled={isExternalRegistration}
                  defaultValue=""
                  data-cy="payment-recurrence-schedule"
                >
                  {paymentOptions.map(paymentOption => (
                    <MenuItem
                      key={paymentOption.value}
                      value={paymentOption.value}
                    >
                      {paymentOption.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="paymentRecurrenceSchedule"
              control={control}
            />
            {/* TODO: Is there a way to show the error with the above components already? */}
            {errors?.paymentRecurrenceSchedule && (
              <FormHelperText error={true}>
                {errors.paymentRecurrenceSchedule.message}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Container>
      <Box mb={2}>
        <Typography variant="h6" align="center">
          {pricingLabel(paymentRecurrenceSchedule)}
        </Typography>
      </Box>
      <Container maxWidth="xs">
        <CurrencyField
          name="price"
          label="Price per Person"
          fullWidth
          inputRef={register}
          error={!!errors.price}
          helperText={errors?.price?.message}
        />
      </Container>
      {isRecurringPayment({ paymentRecurrenceSchedule }) && (
        <Box mb={2} mt={8}>
          <Typography variant="h5" align="center">
            Total Cost:{' '}
            {centsToDollarsFormatted(calculatedTotalPriceInCents || 0)}
          </Typography>
          {calculatedTotalPriceInCents && calculatedNumberOfPayments && (
            <Typography variant="body1" align="center">
              {paymentCalculationLabel({
                price,
                calculatedNumberOfPayments,
                paymentRecurrenceSchedule,
              })}
            </Typography>
          )}
        </Box>
      )}
      {isOnlinePayment({ paymentRecurrenceSchedule }) && (
        <>
          <Container maxWidth="md">
            <Box my={6}>
              <hr className={classes.divider} />
            </Box>
          </Container>
          <Container maxWidth="sm">
            <Box mb={1}>
              <Typography variant="h5" align="center">
                Select a payment account
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant="body1" align="center">
                Select a payment account to collect registration fees online for
                this offering.
              </Typography>
            </Box>
          </Container>
          <Container maxWidth="xs">
            <Box mb={1}>
              <FormControl
                error={!!errors.paymentAccountId}
                variant="outlined"
                required
              >
                <InputLabel id="category">Payment Account</InputLabel>
                <Controller
                  defaultValue=""
                  name="paymentAccountId"
                  control={control}
                  as={
                    <Select
                      labelId="paymentAccount"
                      label="Select paymentAccount"
                      value=""
                      defaultValue=""
                      data-cy="payment-account"
                    >
                      {paymentAccounts.map(opt => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name} – x{opt.last4}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
                {errors?.paymentAccountId && (
                  <FormHelperText error={true}>
                    {errors.paymentAccountId.message}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                color="primary"
                size="small"
                to="/account/payments/payment-accounts"
                component={AccountRouterLink}
              >
                Manage Payment Accounts
              </Button>
            </Box>
          </Container>
          <InfoAlert mt={2} maxWidth="sm">
            {feeLabel(paymentRecurrenceSchedule)}
          </InfoAlert>
        </>
      )}
    </Box>
  )
}

export default PaymentAccountRedesign
