import { get } from 'lodash'
import {
  toFullDateWithTimezone,
  isToday,
  isTomorrow,
  getTimePeriodWithTimezone,
} from 'utils/dateUtils'

export const hasNoUpcomingLessons = lessons => {
  return (
    !lessons.today.length &&
    !lessons.tomorrow.length &&
    !lessons.allOthers.length
  )
}

export const isUpNext = lessons => {
  if (lessons.today.length || lessons.tomorrow.length) {
    return {
      upNext: [],
      laterLessons: lessons.allOthers,
    }
  }
  return {
    upNext: lessons.allOthers?.length ? [lessons.allOthers[0]] : [],
    laterLessons: lessons.allOthers?.slice(1),
  }
}

const parseData = lesson => {
  return {
    coachName: get(lesson, 'coach.name'),
    lessonDate: toFullDateWithTimezone(lesson.startDateTime, lesson.timezone),
    lessonTimePeriod: getTimePeriodWithTimezone(
      lesson.startDateTime,
      lesson.endDateTime,
      lesson.timezone,
    ),
    lessonCaption: `${lesson.title} with ${lesson.coach.name}`,
  }
}

export const parseLessons = (lessons, studentId) => {
  return lessons.reduce(
    (acc, cv) => {
      const { isCanceled, confirmationCode } = cv.enrollments.find(
        ({ student }) => student.id === studentId,
      )
      cv.cancellationCode = confirmationCode

      if (isCanceled) return acc

      if (isToday(cv.startDateTime)) {
        acc.today.push({
          ...cv,
          ...parseData(cv),
        })
      } else if (isTomorrow(cv.startDateTime)) {
        acc.tomorrow.push({
          ...cv,
          ...parseData(cv),
        })
      } else {
        acc.allOthers.push({
          ...cv,
          ...parseData(cv),
        })
      }
      return acc
    },
    { today: [], tomorrow: [], allOthers: [] },
  )
}
