import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  List,
} from '@material-ui/core'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import EventIcon from '@material-ui/icons/Event'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import PaymentIcon from '@material-ui/icons/Payment'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { useWizardFormContext } from 'components/wizard-form/context'
import { registrationCloseDates } from './registration-details'
import pluralize from 'lib/pluralize'
import { DateTime } from 'luxon'
import CardMedia from '@material-ui/core/CardMedia'
import { useStyles } from './styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getTimePeriod } from 'utils/dateUtils'
import IconButton from '@material-ui/core/IconButton'
import ReactMarkdown from 'react-markdown'
import { useTemplateContext } from 'components/lightning-fast-onboarding/use-find-template'

const Preview = ({ coachFacilities }) => {
  const templateValues = useTemplateContext()
  const { formValues } = useWizardFormContext()
  const formOrDefaultValues = { ...templateValues, ...formValues }
  const {
    title,
    description,
    facility,
    maxAttendance,
    price,
    hoursBeforeRegistrationCloses,
    sessions,
    facilityId,
  } = formOrDefaultValues

  const existingFacility = coachFacilities.filter(
    obj => obj.id === facilityId,
  )[0]
  const [showFullDescription, setShowFullDescription] = useState(false)
  const classes = useStyles({ showFullDescription })
  const timePeriod = getTimePeriod(sessions[0].startTime, sessions[0].endTime)
  const hoursBeforeRegistrationClosesLabel = registrationCloseDates.filter(
    b => hoursBeforeRegistrationCloses === b.hoursBefore,
  )

  const FacilityListItem = () => {
    // Prefer an existing facility, but if not available, show a
    // newly-created facility. Display nothing if there's a mysterious third case.

    if (existingFacility) {
      return (
        <ListItem>
          <ListItemAvatar>
            <LocationOnOutlinedIcon />
          </ListItemAvatar>
          <ListItemText primary={existingFacility.name} />
        </ListItem>
      )
    }
    if (facility?.facilityName) {
      return (
        <ListItem>
          <ListItemAvatar>
            <LocationOnOutlinedIcon />
          </ListItemAvatar>
          <ListItemText primary={facility.facilityName} />
        </ListItem>
      )
    }
    return null
  }

  return (
    <Box>
      <CardMedia
        image={templateValues.featuredImageUrl}
        title=""
        className={classes.cardMedia}
      />
      {title && (
        <Box mb={2}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {title}
          </Typography>
        </Box>
      )}
      <Typography
        variant="caption"
        component="div"
        gutterBottom
        color="textSecondary"
      >
        Description
      </Typography>
      <Box
        display="flex"
        alignItems="start"
        justifyContent="space-between"
        pb={1}
      >
        <Typography
          variant="body1"
          gutterBottom
          className={classes.textWrapper}
        >
          <ReactMarkdown>{description}</ReactMarkdown>
        </Typography>

        <IconButton
          onClick={() => setShowFullDescription(!showFullDescription)}
        >
          <ExpandMoreIcon
            className={`${classes.iconButton} ${
              !showFullDescription ? classes.expanded : ''
            }`}
            color="textSecondary"
          />
        </IconButton>
      </Box>

      <Box>
        <List dense>
          <FacilityListItem />
          {sessions[0].startDate && (
            <ListItem>
              <ListItemAvatar>
                <CalendarTodayIcon />
              </ListItemAvatar>
              <ListItemText
                primary={DateTime.fromISO(sessions[0].startDate).toFormat(
                  'MMMM dd, yyyy',
                )}
              />
            </ListItem>
          )}
          <ListItem>
            <ListItemAvatar>
              <EventIcon />
            </ListItemAvatar>
            <ListItemText
              primary={`Registration closes ${hoursBeforeRegistrationClosesLabel[0]?.label}`}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <ScheduleIcon />
            </ListItemAvatar>
            <ListItemText primary={timePeriod} />
          </ListItem>
          {maxAttendance && (
            <ListItem>
              <ListItemAvatar>
                <GroupOutlinedIcon />
              </ListItemAvatar>
              <ListItemText
                primary={`${maxAttendance} ${pluralize(
                  maxAttendance,
                  'attendee',
                )}`}
              />
            </ListItem>
          )}
          {price && (
            <ListItem>
              <ListItemAvatar>
                <PaymentIcon />
              </ListItemAvatar>
              <ListItemText primary={`$${price} per attendee`} />
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  )
}

export default Preview
