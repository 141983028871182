import { useSnackbar } from 'notistack'

const useSnackbarSuccess = enqueueSnackbar => successMsg => {
  enqueueSnackbar(successMsg, {
    variant: 'success',
    autoHideDuration: 3000,
  })
}

const useSnackbarError = enqueueSnackbar => errorMsg => {
  enqueueSnackbar(errorMsg || 'Something went wrong', {
    variant: 'error',
    autoHideDuration: 3000,
  })
}

export { useSnackbar, useSnackbarSuccess, useSnackbarError }
