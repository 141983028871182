import { withStyles } from '@material-ui/core/styles'
import { StepConnector } from '@material-ui/core'

const CheckStepConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[400],
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))(StepConnector)

export default CheckStepConnector
