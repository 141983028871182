import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { get } from 'lodash'
import {
  CardRoot,
  StyledH4,
  HelperMsg,
  Testimony,
  TestimonyFields,
} from 'components/coaches-profile/profile-stepper.styles'
import Button from '@material-ui/core/Button'
import { IconTrashcan } from 'components/atoms/icons'
import InputAdornment from '@material-ui/core/InputAdornment'

const useStyles = makeStyles(theme => ({
  testimonialsContainer: {
    display: 'revert',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .text-input:not(.last-input)': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
}))

const MAX_TOOLS = 8
const toolsMsg = `Add up to ${MAX_TOOLS} tools that apply to your coaching practice.`

export const Tools = ({ tools }) => {
  const classes = useStyles()
  const { errors, register, setValue } = useFormContext()
  const [displayTools, setDisplayTools] = useState(tools)

  const handleAddTool = () => {
    setDisplayTools(displayTools => [...displayTools, ''])
  }

  const handleDeleteTool = index => {
    const newTools = [...tools]
    newTools.splice(index, 1)
    setValue('toolJsonData', newTools)
    setDisplayTools(newTools)
  }

  return (
    <Box className={classes.testimonialsContainer}>
      <StyledH4>Tools</StyledH4>
      <CardRoot className="auto-width">
        <HelperMsg style={{ marginTop: 0 }}>{toolsMsg}</HelperMsg>
        {displayTools.map((item, index) => {
          return (
            <Testimony key={index}>
              <TestimonyFields>
                <TextField
                  multiline
                  name={`toolJsonData[${index}]`}
                  defaultValue={item}
                  label="Tool"
                  type="text"
                  variant="standard"
                  inputRef={register()}
                  className="text-input multiline"
                  error={!!get(errors, ['toolJsonData', index])}
                  helperText={get(errors, ['toolJsonData', index, 'message'])}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="text"
                          color="primary"
                          size="medium"
                          type="button"
                          onClick={() => handleDeleteTool(index)}
                        >
                          <IconTrashcan />
                          Delete
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </TestimonyFields>
            </Testimony>
          )
        })}
        <Box>
          <Button
            style={{ marginTop: '20px' }}
            variant="outlined"
            color="primary"
            size="large"
            type="button"
            onClick={() => {
              handleAddTool()
            }}
            disabled={tools.length >= MAX_TOOLS}
          >
            Add Tool
          </Button>
        </Box>
      </CardRoot>
    </Box>
  )
}

export default Tools
