import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  shareRow: {
    display: 'flex',
    minWidth: '52px',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px !important',
    borderRadius: '4px',
    textTransform: 'none',
    '& p': {
      marginLeft: '34px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  linkButton: {
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0.18)',
    height: '32px',
    width: '32px',
    padding: '5px',
  },
  title: {
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  copyButton: {
    justifyContent: 'flex-start',
    padding: '4px 0 4px 10px',
  },
  messageButton: {
    '& span:first-of-type': {
      background: 'rgba(76, 175, 80, 1)',
      color: 'white',
      borderRadius: '50%',
      height: '30px',
      width: '30px',
      padding: '5px',
      '& svg': {
        width: '25px',
        height: '25px',
      },
    },
  },
}))
