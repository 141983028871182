import React, { useState } from 'react'
import { ThemeProvider, Divider, Grid } from '@material-ui/core'
import { DateTime } from 'luxon'
import { ContainerRoot } from 'pages/schedule/components/styles'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import themes, { extendTheme } from 'themes'
import BackButton from 'components/back-button'
import PrivateBookingHeader from '../components/private-booking-header'
import StepDescriptionOfferings from 'pages/schedule/step-description-offerings'
import TimeSlotSelect from 'pages/schedule/SelectLessonTime/time-slot-select'
import { aggregateByDate } from 'pages/schedule/SelectLessonTime/utils'
import { Helmet } from 'react-helmet'
import CoachAppbar from 'components/coach-app-bar'

const getCurrentUrl = () => {
  return window.location.pathname + window.location.search
}

const SelectTimeSlotLayout = ({
  academy,
  lessonTypeCategory,
  refetch,
  redirectUrl,
}) => {
  const { availableTimes, facilities } = academy
  const timeSlots = availableTimes.map(t => t.startDateTime)
  const timezone = facilities[0].timezone
  const datesWithAvailability = Object.keys(
    aggregateByDate(timeSlots, timezone),
  ).sort()

  const firstAvailableDate = DateTime.fromISO(
    datesWithAvailability[0] || DateTime.now(),
    {
      zone: timezone,
    },
  )
  const [selectedDate, setSelectedDate] = useState(firstAvailableDate)
  const data = [{ facilities, availableTimes }]
  const currentUrl = getCurrentUrl()

  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.offeringsRefresh,
      })}
    >
      <Helmet>
        <title>PGA Coach | Schedule Session</title>
        <meta name="description" content="PGA Coach | Schedule Session" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <CoachAppbar position="relative" hideContactButton />
      <ContainerRoot style={{ marginBottom: '4rem' }}>
        <ProgressIndicator value={25} />
        <BackButton previousUrl={redirectUrl} />
        <PrivateBookingHeader academy={academy} category={lessonTypeCategory} />
        <Divider variant="fullWidth" style={{ marginBottom: '2.5rem' }} />
        <StepDescriptionOfferings
          stepText="Step 1 of 4"
          title="Choose a Day and Time"
          description="Find a day and time that works best for your schedule below."
        />
        <Grid container>
          <TimeSlotSelect
            data={data}
            coach={null}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentUrl={currentUrl}
            refetch={refetch}
            setShowRequestTime={true}
          />
        </Grid>
      </ContainerRoot>
    </ThemeProvider>
  )
}

export default SelectTimeSlotLayout
