import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { gql, useQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SlugWrapper } from '../profile.styles'

const CustomSlugDBValidator = gql`
  query ValidateCustomSlug($customSlug: CustomSlug!) {
    ValidateCustomSlug(customSlug: $customSlug) {
      message
      success
    }
  }
`

export default function CustomSlug({
  newProfile,
  values,
  slugError,
  setSlugError,
  handleChange,
  handleBlur,
  touched,
  errors,
}) {
  const inputRef = useRef()

  const { data, refetch } = useQuery(CustomSlugDBValidator, {
    variables: { customSlug: values.customSlug },
  })

  if (get(data, ['ValidateCustomSlug', 'success']) === false) {
    setSlugError('Sorry, this slug is in use')
  } else {
    setSlugError(null)
  }

  return newProfile ? (
    <SlugWrapper onClick={() => inputRef && inputRef.current.focus()}>
      <TextField
        name="customSlug"
        type="text"
        variant="outlined"
        label="Profile Name"
        value={values.customSlug}
        onChange={e => {
          handleChange(e)
          return refetch
        }}
        onBlur={handleBlur}
        style={{ width: '100%' }}
        className={`text-input last-input ${(errors.customSlug || slugError) &&
          ' error'}`}
        error={touched.customSlug && (errors.customSlug || slugError)}
        helperText={touched.customSlug && errors.customSlug}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="input-adornment">
              {values.customSlug && 'www.pga.com/coach/'}
            </InputAdornment>
          ),
        }}
      />
    </SlugWrapper>
  ) : (
    <SlugWrapper>
      <TextField
        name="customSlug"
        label="Profile Name"
        type="text"
        value={values.customSlug}
        disabled
        style={{ width: '100%' }}
        className={`text-input last-input ${(errors.customSlug || slugError) &&
          ' error'}`}
        error={touched.customSlug && (errors.customSlug || slugError)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="input-adornment">
              www.pga.com/coach/
            </InputAdornment>
          ),
        }}
      />
    </SlugWrapper>
  )
}

CustomSlug.propTypes = {
  newProfile: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  slugError: PropTypes.string,
  setSlugError: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}
