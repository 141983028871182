import clone from 'lodash/clone'
import uniqBy from 'lodash/uniqBy'

/**
 * This function fixes a bug where all sessions in a multi-session group event
 * show in a coach's schedule as starting on the same day.
 *
 * uniqBy removes the "duplicated" GroupEvent records from bookings, and then
 * groupEventList creates a list of GroupEvents with the correct startDateTime
 * and endDateTime attributes by cloning the original GroupEvent and updating
 * the times with the values from each session.
 */
export const bookingsWithSessionStartTimes = (bookings, timeframe) => {
  const uniqueBookings = uniqBy(bookings, 'id')
  const resultingBookings = []

  uniqueBookings.forEach(booking =>
    isGroupEvent(booking)
      ? resultingBookings.push(groupEventList(booking, timeframe))
      : resultingBookings.push(booking),
  )

  const flattenedBookings = resultingBookings.flat()
  return flattenedBookings.filter(booking => isInTimeframe(booking, timeframe))
}

function isGroupEvent(booking) {
  return booking.__typename === 'GroupEvent'
}

function groupEventList(groupEvent) {
  const sessions = groupEvent.sessions
  const newEvents = []

  sessions.forEach(session => {
    newEvents.push(cloneGroupEventFromSession(groupEvent, session))
  })

  return newEvents
}

function cloneGroupEventFromSession(groupEvent, session) {
  const clonedEvent = clone(groupEvent)
  clonedEvent.startDateTime = session.startDateTime
  clonedEvent.endDateTime = session.endDateTime

  return clonedEvent
}

function isInTimeframe(booking, timeframe) {
  const isPast = new Date(booking.endDateTime).getTime() < Date.now()
  return timeframe === 'past' ? isPast : !isPast
}
