import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const ProgressIndicator = ({ value, color = 'primary' }) => {
  return (
    <LinearProgress
      value={value}
      color={color}
      variant="determinate"
      style={{
        height: '.625rem',
        position: 'absolute',
        width: '100vw',
        left: 0,
        top: '3.75rem',
        zIndex: 1300, // this is irrationally high, but the header has a z-index of 1200. This is intended to hide the single pixel bottom border
      }}
    />
  )
}

export default ProgressIndicator
