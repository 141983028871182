import React from 'react'
import { gtmEvent } from 'lib/gtm'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import StepDescriptionOfferings from '../step-description-offerings'
import { useStyles } from './styles'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import { DownloadMyPGAsection } from 'components/download-mypga-section'
import themes, { extendTheme } from 'themes'
import { ThemeProvider } from '@material-ui/core/styles'
import TransactionSummary from '../components/transaction-summary'
import BookingSummaryCoachInfo from '../components/BookingSummaryCoachInfo'
import StudentCard from '../components/student-card'

const SummaryLayout = ({
  idOrSlug,
  lessonType,
  lessonTypeId,
  locationId,
  cardBrand,
  lastDigits,
  selectedLessonPackId,
  lessonPackQuantity,
  student,
}) => {
  const { coach, lessonPacks = [] } = lessonType
  const [selectedLessonPack] = lessonPacks.filter(
    lp => lp.id === selectedLessonPackId,
  )
  const { priceInCents, id } = selectedLessonPack

  const classes = useStyles()
  const purchaseNewSessionUrl = `/coach/${idOrSlug}/schedule?lessonTypeId=${lessonTypeId}&locationId=${locationId}&isLessonPacks=true&lessonPackId=${id}`

  const onBuyAnotherLessonPack = () => {
    window.location.href = purchaseNewSessionUrl
  }

  const onViewCoachOfferings = () => {
    gtmEvent({
      event: 'formSubmit',
      formCategory: 'book-session',
      formAction: 'clicked-view-coachofferings-button',
    })
    window.location.href = window.location.pathname
  }

  return (
    <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
      <Container className={classes.root}>
        <Grid container>
          <Grid item md={7} xs={12}>
            <StepDescriptionOfferings
              stepText=""
              title="Thank you!"
              description="Your purchase is complete. You will receive a confirmation email shortly."
            />
            <Alert
              severity="info"
              className={classes.warningBox}
              icon={
                <ConfirmationNumberOutlinedIcon
                  color="primary"
                  fontSize="small"
                />
              }
            >
              <strong>
                We have applied one of the lessons from your pack.
              </strong>{' '}
              You have {lessonPackQuantity} lessons left.
            </Alert>
            <TransactionSummary
              priceInCents={priceInCents}
              cardBrand={cardBrand}
              lastDigits={lastDigits}
              selectedLessonPack={selectedLessonPack}
            />
            <Box mt={3}>
              <Typography variant="h4" gutterBottom>
                Details
              </Typography>
              <BookingSummaryCoachInfo lessonType={lessonType} coach={coach} />
            </Box>
            <StudentCard student={student} />
            <Divider className={classes.dividerStyles} />
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              width="100%"
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={onViewCoachOfferings}
                className={classes.buttonStyles}
              >
                View Coach Offerings
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onBuyAnotherLessonPack}
                className={classes.buttonStyles}
              >
                Buy Another Lesson Pack
              </Button>
            </Box>
            <DownloadMyPGAsection />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

export default SummaryLayout
