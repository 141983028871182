import Dashboard from './dashboard'
import { useQuery } from '@apollo/client'
import { COACH_ONBOARDING_STATUS } from './query'
import Error from 'components/error'
import LoadingSkeleton from './loadingSkeleton'

const DashboardPage = () => {
  const { data, loading, error } = useQuery(COACH_ONBOARDING_STATUS)

  if (loading) return <LoadingSkeleton />
  if (error) return <Error />

  return <Dashboard data={data} />
}

export default DashboardPage
