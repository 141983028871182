import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET_LARGE } from 'env'
import { formFields } from '../constants'
import { handleImageUpload } from 'lib/cloudinary/uploadImage'
import useStyles, {
  ErrorMsg,
  PhotoSelector,
  PhotoSelectorPhoto,
} from '../profile.styles'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useIsMobile } from 'lib/hooks'

const MAX_PHOTO_COUNT = 8

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET_LARGE,
  cropping: true,
  multiple: false,
  croppingAspectRatio: 1.7,
  showSkipCropButton: false,
  sources: ['local', 'camera'],
  resourceType: 'image',
  folder: 'dot-com-images',
  showPoweredBy: false,
}

const Photos = props => {
  const [uploadError, setUploadError] = useState(null)
  const [checkedPhotos, setCheckedPhotos] = useState([])
  const { values, setFieldValue } = props
  const classes = useStyles()
  const isMobile = useIsMobile()

  const handleCheckboxToggle = (isChecking, rank) => {
    if (isChecking) {
      // if checking
      setCheckedPhotos(photos => [rank, ...photos])
    } else {
      // if unchecking
      const rankIndex = checkedPhotos.findIndex(photo => photo === rank)
      const checked = [...checkedPhotos]
      if (rankIndex !== -1) {
        checked.splice(rankIndex, 1)
        setCheckedPhotos(checked)
      }
    }
  }

  const handlePhotosDelete = deleteIndex => {
    const newPhotos = [...values.photosJsonData]
    let newChecked = [...checkedPhotos]
    if (deleteIndex >= 0) {
      // if deleting a specific index
      newChecked.splice(
        newChecked.indexOf(values.photosJsonData[deleteIndex].rank),
        1,
      )
      newPhotos.splice(deleteIndex, 1)
    } else {
      // else delete all checked photos
      checkedPhotos.forEach(rank => {
        const rankIndex = newPhotos.findIndex(photo => photo.rank === rank)
        if (rankIndex !== -1) {
          newPhotos.splice(rankIndex, 1)
        }
        newChecked = []
      })
    }
    setCheckedPhotos(newChecked)
    setFieldValue('photosJsonData', newPhotos)
  }

  return (
    <>
      {uploadError ? (
        <ErrorMsg>{uploadError}</ErrorMsg>
      ) : (
        <Typography variant="body2" color="textSecondary" gutterBottom>Showcase your best moments helping students grow! Great profiles have between 4-6 photos.</Typography>
      )}
      <Box className={classes.flexRow}>
        <Box className={classes.photosImagesWrapper}>
          {values.photosJsonData.map((photo, i) => (
            <PhotoSelector
              key={photo.rank}
              isSelected={checkedPhotos.find(pic => pic === photo.rank)}
              onClick={() =>
                handleCheckboxToggle(
                  !checkedPhotos.find(pic => pic === photo.rank),
                  photo.rank,
                )
              }
            >
              <PhotoSelectorPhoto src={photo.reference} alt="Photos Preview" />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginLeft: '-2px',
                }}
              >
                <Checkbox
                  className="checkbox"
                  checked={!!checkedPhotos.find(pic => pic === photo.rank)}
                  color="secondary"
                />
                <IconButton
                  style={{ padding: 0, margin: '8px -6px 8px 8px' }}
                  onClick={() => handlePhotosDelete(i)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            </PhotoSelector>
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
      >
        <Button
          variant="outlined"
          color="primary"
          type="button"
          disabled={values.photosJsonData.length >= MAX_PHOTO_COUNT}
          onClick={() => {
            // increment one rank from the last photo in the array
            const rankToInsert =
              values.photosJsonData && values.photosJsonData.length > 0
                ? values.photosJsonData[values.photosJsonData.length - 1].rank +
                1
                : 1
            handleImageUpload(
              uploadOptions,
              setFieldValue,
              setUploadError,
              'photosJsonData',
              values.photosJsonData,
              rankToInsert,
            )
          }}
        >
          Upload Photo
        </Button>
      </Box>
    </>
  )
}

Photos.defaultProps = {
  values: formFields,
  setFieldValue: PropTypes.func,
}

export default Photos
