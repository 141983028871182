import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { useIsMobile } from 'lib/hooks'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { gql, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useChatContext } from 'stream-chat-react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import SearchBar from 'pages/pga-coach/students/search-bar'
import { useAuth } from 'lib/auth'
import { useFindOrCreateChannelWithUserIds } from 'lib/stream-messaging/hooks'
import Dialog, { DialogActions } from 'components/dialog'
import FilterDropdown from 'components/filter-dropdown'
import { Divider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const GET_MESSAGING_CONTACTS = gql`
  query MessagingContacts {
    messagingContacts {
      type
      id
      name
      image
      externalId
      description
      sources
    }
    messagingContactSource: __type(name: "MessagingContactSource") {
      enumValues {
        name
        description
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 'calc(100vh - 64px)',
  },
  dialogContent: {
    maxHeight: '400px',
  },
  createButton: {
    paddingLeft: '1.75rem',
    paddingRight: '1.75rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
}))

const NewConversationDialog = ({ trigger }) => {
  const fullScreen = useIsMobile()
  const classes = useStyles()
  const findOrCreateChannelWithUserIds = useFindOrCreateChannelWithUserIds()
  const { setActiveChannel } = useChatContext()
  const { enqueueSnackbar } = useSnackbar()
  const { data, loading, error, refetch } = useQuery(GET_MESSAGING_CONTACTS)
  const [selectedFilter, setSelectedFilter] = useState('ALL')
  const [searchFieldText, setSearchFieldText] = useState('')
  const [selectedContactExternalIds, setSelectedContactExternalIds] = useState(
    [],
  )
  const { isConsumer, isCoach } = useAuth()

  const toggleContactSelection = contact => {
    const currentIndex = selectedContactExternalIds.indexOf(contact.externalId)
    const newSelection = [...selectedContactExternalIds]

    if (currentIndex === -1) {
      newSelection.push(contact.externalId)
    } else {
      newSelection.splice(currentIndex, 1)
    }

    setSelectedContactExternalIds(newSelection)
  }

  const isContactSelected = contact =>
    selectedContactExternalIds.includes(contact.externalId)

  const handleFilterChange = newFilter => {
    setSelectedFilter(newFilter)
    setSelectedContactExternalIds([])
  }

  if (loading) return <CircularProgress />
  if (error) {
    return (
      <>
        <Box mb={2}>
          <Typography variant="body1">Unable to load contacts</Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={() => refetch()}>
          Reload
        </Button>
      </>
    )
  }

  const { messagingContacts, messagingContactSource } = data

  const filterOptions = Array.from(
    new Set(messagingContacts.flatMap(contact => contact.sources)),
  ).map(opt => messagingContactSource.enumValues.find(e => e.name === opt))
  filterOptions.unshift({
    name: 'ALL',
    description: 'All Contacts',
    __typename: '__EnumValue',
  })

  const selectedFilterValue = selectedFilter

  const filteredContacts = messagingContacts
    .filter(
      contact =>
        !searchFieldText ||
        contact.name.toLowerCase().includes(searchFieldText.toLowerCase()),
    )
    .filter(
      contact =>
        !selectedFilterValue.length ||
        selectedFilterValue.includes('ALL') ||
        contact.sources.some(s => selectedFilterValue.includes(s)),
    )

  const allContactsSelected =
    selectedContactExternalIds.length === filteredContacts.length

  const toggleSelectAll = () => {
    if (!allContactsSelected) {
      setSelectedContactExternalIds(
        filteredContacts.map(contact => contact.externalId),
      )
    } else {
      setSelectedContactExternalIds([])
    }
  }

  return (
    <Dialog trigger={trigger} fullScreen={fullScreen} fullWidth>
      {({ closeDialog }) => {
        const createConversation = async () => {
          const channel = await findOrCreateChannelWithUserIds(
            selectedContactExternalIds,
          )
          if (channel) {
            setActiveChannel(channel)
            closeDialog && closeDialog()
          } else {
            enqueueSnackbar(
              'Unable to create or join conversation. Please try again later.',
              {
                variant: 'error',
              },
            )
          }
        }
        return (
          <>
            <DialogTitle disableTypography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">New Conversation</Typography>
                <IconButton onClick={closeDialog}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={9}>
                  <SearchBar
                    searchTerm={searchFieldText}
                    setSearchTerm={setSearchFieldText}
                    style={{ margin: '20px', marginLeft: '0px' }}
                    placeholder="Search contacts"
                  />
                </Grid>
                {filterOptions.length > 2 && (
                  <Grid item xs={3}>
                    <FilterDropdown
                      filterOptions={filterOptions}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={handleFilterChange}
                    />
                  </Grid>
                )}
              </Grid>
              <ListItem>
                <Checkbox
                  edge="start"
                  color="primary"
                  onChange={toggleSelectAll}
                  checked={allContactsSelected}
                  inputProps={{ 'aria-label': 'Select All' }}
                />
                <Box pl={2}>
                  <ListItemText
                    primary={
                      selectedContactExternalIds.length > 0
                        ? `${selectedContactExternalIds.length} selected`
                        : 'Contacts'
                    }
                  />
                </Box>
              </ListItem>
              <Divider />
              {filteredContacts.length > 0 ? (
                <Box className={classes.dialogContent}>
                  <List>
                    {filteredContacts.map(contact => {
                      const key = `${contact.type}-${contact.id}`
                      return (
                        <ListItem
                          key={key}
                          button
                          onClick={() => toggleContactSelection(contact)}
                        >
                          <Checkbox
                            edge="start"
                            color="primary"
                            onChange={() => toggleContactSelection(contact)}
                            checked={isContactSelected(contact)}
                            inputProps={{ 'aria-labelledby': key }}
                          />
                          <Box pl={2}>
                            <ListItemText
                              id={key}
                              primary={contact.name}
                              secondary={contact.description}
                            />
                          </Box>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              ) : (
                <Box mt={2}>
                  <Typography variant="body1">No contacts found</Typography>
                  {isCoach && selectedFilterValue.length === 0 && (
                    <Typography variant="body2">
                      Your students and leads will appear here once they have
                      been added
                    </Typography>
                  )}
                  {isConsumer && selectedFilterValue.length === 0 && (
                    <Typography variant="body2">
                      It doesn't look like you have any contacts yet
                    </Typography>
                  )}
                  {selectedFilterValue.length !== 0 && (
                    <Typography variant="body2">
                      Try removing some of your filters and search again
                    </Typography>
                  )}
                </Box>
              )}
            </DialogContent>
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <DialogActions>
                <Button
                  autoFocus={filteredContacts.length === 0}
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.createButton}
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={createConversation}
                  disabled={selectedContactExternalIds.length === 0}
                >
                  Create
                </Button>
              </DialogActions>
            </Box>
          </>
        )
      }}
    </Dialog>
  )
}

export default NewConversationDialog
