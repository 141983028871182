import { styled } from '@material-ui/styles'
import Pagination from 'material-ui-flat-pagination'

export const PaginationStyles = styled(Pagination)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'normal',
  '& button': {
    height: 32,
    width: 32,
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '50%',
    textAlign: 'center',
    fontWeight: 'normal',
    '& span': {
      textAlign: 'center',
      height: 24,
      display: 'block',
      paddingLeft: 1,
    },
  },
  '& .MuiFlatPageButton-textSecondary': {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
}))
