import React from 'react'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from 'components/dialog'
import BlockTimeDestroy from './block-time-destroy'

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}))

const BlockTimeDestroyDialog = ({ blockTime, onSuccess, trigger }) => {
  const classes = useStyles()
  return (
    <Dialog trigger={trigger} maxWidth="sm" fullWidth showCloseButton>
      {({ closeDialog }) => (
        <>
          <DialogTitle className={classes.header}>
            Delete block time?
          </DialogTitle>
          <DialogContent dividers>
            <Box py={2}>
              <BlockTimeDestroy
                blockTime={blockTime}
                onSuccess={() => {
                  onSuccess && onSuccess()
                  closeDialog()
                }}
                onCancel={closeDialog}
              />
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default BlockTimeDestroyDialog
