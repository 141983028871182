import { PGA_COM_HOST } from 'env'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Confetti from 'react-dom-confetti'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import { ReactComponent as PartyPopperIcon } from 'images/icons/party-popper-outlined.svg'
import CircularHighlightedIcon from 'components/circular-highlighted-icon'
import CopyTextButton from 'components/copy-text-button'
import SocialSharePopover from 'components/social-share-popover'
import { makeStyles } from '@material-ui/core/styles'
import LinesEllipsis from 'react-lines-ellipsis'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: '30px',
    color: theme.palette.primary.main,
    margin: '12px 0',
  },
  text: {
    lineHeight: '1.6',
  },
}))

const confettiConfig = {
  angle: '219',
  spread: 360,
  startVelocity: '50',
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '1',
  width: '12px',
  height: '8px',
  perspective: '499px',
  colors: ['#00234B', '#AB9157', '#043362', '#BBA778'],
}

const SuccessStep = ({ slug }) => {
  const [isConfettiEnabled, setIsConfettiEnabled] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => {
      setIsConfettiEnabled(true)
    }, 500)
  })
  const groupEventLink = `${PGA_COM_HOST}/things-to-do/events/${slug}`
  const editgroupEventLink = `/pga-coach/bookings/events/edit/${slug}`
  return (
    <>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={8}
        >
          <Box mb={1}>
            <CircularHighlightedIcon icon={PartyPopperIcon} />
          </Box>
          <Confetti active={isConfettiEnabled} config={confettiConfig} />
          <Typography variant="h4" className={classes.title}>
            You’re all set!
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Your profile & event are published.
          </Typography>
          <Box mb={1.5} mx={1} textAlign="center">
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.text}
            >
              Your profile and event are published! In a few moments, you’ll
              receive an email with suggestions on how to continue to build out
              your PGA Coach profile and maximize your coaching success through
              our platforms.
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            View or share your event:
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            my={2}
            color="primary"
          >
            <LinesEllipsis
              text={groupEventLink}
              component="span"
              basedOn="letters"
            />
            <CopyTextButton component={IconButton} value={groupEventLink}>
              <FileCopyOutlinedIcon color="primary" />
            </CopyTextButton>
            <SocialSharePopover
              url={groupEventLink}
              trigger={
                <IconButton>
                  <ShareOutlinedIcon color="primary" />
                </IconButton>
              }
            />
          </Box>
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={editgroupEventLink}
            >
              Edit Additional Event Details
            </Button>
          </Box>
          <Button color="primary" href={groupEventLink}>
            View My Event
          </Button>
        </Box>
      </Container>
    </>
  )
}

export default SuccessStep
