import React from 'react'
import Box from '@material-ui/core/Box'
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined'
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined'
import Typography from '@material-ui/core/Typography'
import ActionButtons from './action-buttons'
import EventDetails from './index'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import { useStyles } from './styles'
import pluralize from 'lib/pluralize'
import { useAuth } from 'lib/auth'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import { DateTime } from 'luxon'

const CoachEventDetails = ({ event, setIsOpen }) => {
  const classes = useStyles()
  const { user } = useAuth()
  const [isRsvpEnabled] = useFeatureFlags([flags.FLAG_FEAT_GROUP_EVENT_RSVP])
  // get the event assistant if the user is an assistant coach
  const assistantCoaches = event?.assistantCoaches?.map(coach => coach.id) || []
  const isAssistantCoach = assistantCoaches?.includes(user?.id) || false
  const waitlistCount = event?.waitlist?.entries.length
  const showWaitlist = waitlistCount > 0

  const getRsvpStatusCount = (students, status) => {
    return students.filter(attendance => attendance.rsvp === status).length
  }

  const getRsvpString = () => {
    let result = ''
    if (!isRsvpEnabled || !event?.attendances) return result

    const students = event.attendances.filter(
      attendance => attendance.attendee.__typename === 'Student',
    )
    const rsvpYes = getRsvpStatusCount(students, 'YES')
    const rsvpNo = getRsvpStatusCount(students, 'NO')
    const rsvpMaybe = getRsvpStatusCount(students, 'MAYBE')

    if (event.startDateTime < DateTime.now() && (rsvpYes > 0 || rsvpNo > 0)) {
      result = `(${rsvpYes} Attended, ${rsvpNo} Did Not Attend)`
    } else {
      result = `(${rsvpYes} RSVP yes, ${rsvpNo} RSVP no, ${rsvpMaybe} RSVP maybe)`
    }
    return result
  }

  return (
    <EventDetails
      event={event}
      isCoachView={true}
      actionButtons={() => (
        <ActionButtons
          setIsOpen={setIsOpen}
          event={event}
          canEdit={!event.isCanceled && !event.isPast() && !isAssistantCoach}
        />
      )}
      participants={
        <Box
          className={classes.clickableRow}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setIsOpen('participants')}
        >
          <Box display="flex" alignItems="center" className={classes.eventRow}>
            <PeopleAltOutlinedIcon className={classes.eventRowIcon} />
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">
                {event.totalEnrolled}{' '}
                {pluralize(event.totalEnrolled, 'Attendee')} {getRsvpString()}
                {showWaitlist && ` (${waitlistCount} waitlist)`}
              </Typography>
              {event.maxAttendance ? (
                <Typography variant="body2" className={classes.bodyFontCaption}>
                  {`Max attendance: ${event.maxAttendance} attendees • ${
                    event.totalEnrolled > 0
                      ? Math.ceil(
                        (event.totalEnrolled / event.maxAttendance) * 100,
                      )
                      : 0
                  }% full`}
                </Typography>
              ) : null}
            </Box>
          </Box>
          <NavigateNextOutlinedIcon />
        </Box>
      }
      stats={
        <Box
          className={classes.clickableRow}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setIsOpen('stats')}
        >
          <Box display="flex" alignItems="center" className={classes.eventRow}>
            <InsertChartOutlinedIcon className={classes.eventRowIcon} />
            <Typography variant="body1">Stats</Typography>
          </Box>
          <NavigateNextOutlinedIcon />
        </Box>
      }
    />
  )
}

export default CoachEventDetails
