import React from 'react'
import Button from '@material-ui/core/Button'
import { useIsMobile } from 'lib/hooks'

export default function StepActions({
  stepName,
  activeStep,
  setActiveStep,
  isComplete = false,
}) {
  const isMobile = useIsMobile()
  if (stepName === 'Confirmation') return null

  const handleContinueClick = async event => {
    event.preventDefault()
    const isValid = !isComplete && activeStep === 0
    return isValid ? setActiveStep(1) : null
  }

  if (stepName === 'Physical Info') {
    return (
      <span display="flex" justifyContent="center" pb={5}>
        <Button
          variant="contained"
          type="submit"
          disabled={isComplete}
          color="primary"
          id="complete-button"
        >
          {isMobile ? 'NEXT' : 'NEXT: Complete'}
        </Button>
      </span>
    )
  }
  if (stepName === 'Golfer info') {
    return (
      <span
        display="flex"
        justifyContent="space-evenly"
        alignItems="flex-start"
        pb={5}
        id="complete-button"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinueClick}
          id="continue-button"
        >
          {isMobile ? 'NEXT' : 'NEXT: Physical Info'}
        </Button>
      </span>
    )
  }
}
