import { gql } from '@apollo/client'

export const LessonReviewQuery = gql`
  query LessonReviewQuery($id: ID!) {
    lessonTypes(id: $id) {
      id
      category
      title
      priceInCents
      durationInMinutes
      facilities {
        id
        name
        state
        city
      }
      coach {
        name
        bookingUrl
        coachProfile {
          title
          profilePhoto
        }
      }
      lessonPacks {
        priceInCents
        quantity
        expirationDuration
      }
      academy {
        slug
      }
    }
  }
`

export const RedeemLessonCreditQuery = gql`
  mutation RedeemLessonCredit(
    $lessonTypeId: ID!
    $locationId: ID!
    $startDateTime: ISO8601DateTime!
    $studentId: ID!
  ) {
    redeemLessonCredit(
      lessonTypeId: $lessonTypeId
      locationId: $locationId
      startDateTime: $startDateTime
      studentId: $studentId
    ) {
      success
      message
    }
  }
`

export const GetStudentQuery = gql`
  query GetStudentQuery($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      golferProfile {
        id
      }
    }
  }
`
