import React, { useState } from 'react'
import { sortBy } from 'lodash'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ContactlessOutlinedIcon from '@material-ui/icons/ContactlessOutlined'
import Typography from '@material-ui/core/Typography'
// import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ManageStripeAccountConfirmationDialog from './manage-stripe-account-confirmation-dialog'
import EditPaymentAccountNameDialog from './edit-payment-account-name-dialog'

const PaymentAccountActionMenu = ({ paymentAccount, trigger, refetch }) => {
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null)
  const [isManageStripeDialogOpen, setIsManageStripeDialogOpen] = useState(
    false,
  )
  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = useState(false)

  const openMenu = e => setActionMenuAnchor(e.target)
  const closeMenu = () => setActionMenuAnchor(null)

  return (
    <>
      <trigger.type {...trigger.props} onClick={openMenu} />
      <Menu
        open={Boolean(actionMenuAnchor)}
        onClose={closeMenu}
        anchorEl={actionMenuAnchor}
        disableAutoFocus={true}
        disableAutoFocusItem={true}
        disableRestoreFocus={true}
        disableEnforceFocus={true}
      >
        <MenuItem onClick={() => setIsManageStripeDialogOpen(true)}>
          <Box display="flex">
            <Typography variant="body1" style={{ marginRight: '4px' }}>
              Manage Stripe
            </Typography>
            <LaunchOutlinedIcon
              style={{ position: 'relative', top: '2px' }}
              fontSize="small"
            />
          </Box>
        </MenuItem>
        <MenuItem onClick={() => setIsEditNameDialogOpen(true)}>
          Edit name
        </MenuItem>
      </Menu>
      <ManageStripeAccountConfirmationDialog
        paymentAccount={paymentAccount}
        isOpen={isManageStripeDialogOpen}
        onClose={() => {
          closeMenu()
          setIsManageStripeDialogOpen(false)
        }}
      />
      <EditPaymentAccountNameDialog
        paymentAccount={paymentAccount}
        isOpen={isEditNameDialogOpen}
        onClose={() => {
          closeMenu()
          setIsEditNameDialogOpen(false)
        }}
        onSuccess={() => {
          closeMenu()
          setIsEditNameDialogOpen(false)
          refetch()
        }}
      />
    </>
  )
}

const PaymentAccountRow = ({ paymentAccount, refetch }) => {
  const { name, last4 } = paymentAccount
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={4}
    >
      <Box display="flex" flexGrow={2}>
        <Box mr={4.25}>
          <ContactlessOutlinedIcon size="small" />
        </Box>
        <Typography variant="body1">
          {name} – x{last4}
        </Typography>
      </Box>
      {/* <Box flexShrink={1} mr={16}>
        <Chip label={`2 connections`} />
      </Box> */}
      <Box flexShrink={1}>
        <PaymentAccountActionMenu
          paymentAccount={paymentAccount}
          trigger={
            <IconButton>
              <MoreHorizOutlinedIcon color="action" />
            </IconButton>
          }
          refetch={refetch}
        />
      </Box>
    </Box>
  )
}

const PaymentAccountsDisplay = ({ paymentAccounts, refetch }) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title="Payment Accounts"
        titleTypographyProps={{ variant: 'h6' }}
        style={{ borderBottom: '1px solid #E0E0E0' }}
      />
      <Box px={4}>
        {sortBy(paymentAccounts, ['name']).map((pa, idx) => (
          <Box key={pa.id}>
            {idx > 0 && <Divider />}
            <PaymentAccountRow paymentAccount={pa} refetch={refetch} />
          </Box>
        ))}
      </Box>
    </Card>
  )
}

export default PaymentAccountsDisplay
