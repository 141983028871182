import React, { Fragment } from 'react'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import logo from 'images/jr-league-logo.svg'

const useStyles = makeStyles(theme => ({
  logo: {
    width: 154,
    height: 70,
    position: 'absolute',
    left: theme.spacing(4),
    top: theme.spacing(2),
  },
  logoSmallScreen: {
    height: 150,
  },
  stepHeading: {
    fontSize: 30,
    fontWeight: 'bold',
  },
}))

export default function JRLSignupLogo() {
  const classes = useStyles()

  return (
    <Fragment>
      <Hidden smDown>
        <img src={logo} alt="PGA Jr. League Logo" className={classes.logo} />
      </Hidden>
      <Hidden mdUp>
        <Box display="flex" justifyContent="center">
          <img
            src={logo}
            alt="PGA Jr. League Logo"
            className={classes.logoSmallScreen}
          />
        </Box>
      </Hidden>
    </Fragment>
  )
}
