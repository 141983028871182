import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { InputLabel, Typography } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import useStyles from '../styles'

export const expirationDurationOptions = [
  { label: 'Never', value: 'never', default: true },
  { label: '1 Month', value: 'P1M' },
  { label: '3 Months', value: 'P3M' },
  { label: '6 Months', value: 'P6M' },
  { label: '1 Year', value: 'P1Y' },
  { label: '18 Months', value: 'P18M' },
  { label: '2 Years', value: 'P2Y' },
]

const ExpirationDurationDropdown = () => {
  const { errors, control } = useFormContext()
  const classes = useStyles()

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Expiration (Optional)
      </Typography>
      <Typography variant="body2" gutterBottom>
        Select a timeframe for the lesson pack to expire. Students will receive
        notification prior to the lesson packs expiring.
      </Typography>
      <FormControl
        error={!!errors.expirationDuration}
        className={classes.formField}
      >
        <InputLabel id="expirationDuration">Select an expiration</InputLabel>
        <Controller
          defaultValue={
            expirationDurationOptions.find(opt => opt.default).value
          }
          as={
            <Select labelId="expirationDuration" label="Select an expiration">
              {expirationDurationOptions.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          }
          name="expirationDuration"
          control={control}
        />
      </FormControl>
    </>
  )
}

export default ExpirationDurationDropdown
