import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import { SavingsIcon } from 'components/icons/refreshed-icons'
import { centsToDollarsFormatted } from 'lib/utils/number'
import useStyles, { StyledDivider, StyledWideDivider } from './styles'
import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useIsMobile } from 'lib/hooks'

const ProgramInfo = ({ program, successPage }) => {
  const {
    title,
    description,
    priceInCents,
    coachFacility,
    priceDetails,
  } = program
  const classes = useStyles()
  const theme = useTheme()
  const [showReadMore, setShowReadMore] = useState(successPage)
  const isMobile = useIsMobile()

  return (
    <>
      <Typography variant={successPage ? 'h4' : 'h3'} gutterBottom>
        {title}
      </Typography>
      {showReadMore && successPage && (
        <List dense={true}>
          <Typography
            variant="subtitle1"
            color="primary"
            onClick={() => setShowReadMore(false)}
            className={classes.seeMoreToggle}
          >
            Read more
          </Typography>
        </List>
      )}
      {(!showReadMore || !isMobile) && (
        <>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
          <List dense={true}>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <GolfCourseIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.attributes }}
                primary={coachFacility.name}
              />
            </ListItem>

            <ListItem disableGutters={true}>
              <ListItemIcon>
                <PlaceOutlinedIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.attributes }}
                primary={`${coachFacility.city}, ${coachFacility.state}`}
              />
            </ListItem>

            <ListItem disableGutters={true}>
              <ListItemIcon>
                <SavingsIcon size={24} color={theme.palette.secondary.dark} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.attributes }}
                primary={`${centsToDollarsFormatted(
                  priceInCents,
                )}${priceDetails || ''}`}
              />
            </ListItem>
          </List>
          {successPage && (
            <Typography
              variant="subtitle1"
              color="primary"
              onClick={() => setShowReadMore(true)}
              className={classes.seeMoreToggle}
            >
              Read less
            </Typography>
          )}
        </>
      )}
      {successPage && !isMobile ? <StyledWideDivider /> : <StyledDivider />}
    </>
  )
}

ProgramInfo.propTypes = {
  program: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    priceInCents: PropTypes.number.isRequired,
    coachFacility: PropTypes.shape({
      name: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
    priceDetails: PropTypes.string,
  }).isRequired,
  successPage: PropTypes.bool,
}
export default ProgramInfo
