import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import { buildDateTime, isInvalidDate } from 'lib/CustomLuxonUtils'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { InputAdornment } from '@material-ui/core'
import ScheduleIcon from '@mui/icons-material/Schedule'

const useStyles = makeStyles(theme => ({
  popover: {
    position: 'absolute',
    zIndex: 10000,
    top: '100%',
    left: '0px',
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    boxShadow: theme.shadows[4],
    border: '1px solid #E0E0E0',
    backgroundColor: '#ffffff',
    minWidth: '270px',
    marginTop: theme.spacing(1),

    '&.is-right-anchored': {
      left: 'auto',
      right: '0px',
    },
  },
  popoverColumn: {
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '300px',
    width: '33.333%',

    '& button.is-selected': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  popoverFooter: {
    padding: theme.spacing(1),
    borderTop: '1px solid #E0E0E0',
  },
}))

const PGATimePickerDropdown = ({
  defaultValue,
  minutesStep,
  horizontalAnchor,
  onChange,
  onCancel,
}) => {
  const [value, setValue] = React.useState(defaultValue)

  React.useEffect(() => {
    let resolvedDefaultValue = defaultValue

    if (isInvalidDate(resolvedDefaultValue)) {
      resolvedDefaultValue = DateTime.now().startOf('day')
    }

    setValue(resolvedDefaultValue)
  }, [defaultValue])

  const classes = useStyles()
  const currentHour = parseInt(value.toFormat('h')) - 1
  const currentMinute = parseInt(value.toFormat('m'))
  const currentAMPM = value.toFormat('a')
  const totalMinutesOptions = parseInt(60 / (minutesStep || 1))

  onCancel = onCancel || (() => {})
  onChange = onChange || (() => {})

  const setHour = hour => {
    if (hour === 12) {
      hour = 0
    }

    if (currentAMPM === 'PM') {
      hour += 12
    }

    const newValue = value.set({ hour })
    setValue(newValue)
  }

  const setMinute = minute => {
    const newValue = value.set({ minute })
    setValue(newValue)
  }

  const setAMPM = ampm => {
    if (currentAMPM === ampm) return

    let hour = value.get('hour')
    hour += ampm === 'AM' ? -12 : 12

    const newValue = value.set({ hour })
    setValue(newValue)
  }

  const popoverClasses = [
    classes.popover,
    horizontalAnchor === 'right' ? 'is-right-anchored' : '',
  ].join(' ')

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'stretch'}
      className={popoverClasses}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'stretch'}
        justifyContent={'flex-start'}
      >
        <Box className={classes.popoverColumn}>
          {Array.from(Array(12).keys()).map(hour => (
            <Button
              className={hour === currentHour ? 'is-selected' : ''}
              key={`hour-${hour + 1}`}
              onClick={() => setHour(hour + 1)}
            >
              {hour + 1}
            </Button>
          ))}
        </Box>
        <Box className={classes.popoverColumn}>
          {Array.from(Array(totalMinutesOptions).keys()).map(minuteIndex => {
            const minute = minuteIndex * minutesStep
            return (
              <Button
                className={minute === currentMinute ? 'is-selected' : ''}
                key={`minute-${minute}`}
                onClick={() => setMinute(minute)}
              >
                {minute.toString().padStart(2, '0')}
              </Button>
            )
          })}
        </Box>
        <Box className={classes.popoverColumn}>
          <Button
            className={currentAMPM === 'AM' ? 'is-selected' : ''}
            onClick={() => setAMPM('AM')}
          >
            AM
          </Button>
          <Button
            className={currentAMPM === 'PM' ? 'is-selected' : ''}
            onClick={() => setAMPM('PM')}
          >
            PM
          </Button>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        className={classes.popoverFooter}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onChange(value)}
        >
          OK
        </Button>
      </Box>
    </Box>
  )
}

const handleTimeChange = (event, onChange, date) => {
  if (!event) return

  if (isInvalidDate(event)) {
    return onChange(DateTime.invalid(event))
  }

  const eventDateTime = buildDateTime(date, event)
  const year = date?.get('year') || eventDateTime.get('year')
  const month = date?.get('month') || eventDateTime.get('month')
  const day = date?.get('day') || eventDateTime.get('day')
  const hour = eventDateTime.get('hour')
  const minute = eventDateTime.get('minute')
  const roundedMinutesTime = DateTime.local(
    year,
    month,
    day,
    hour,
    Math.floor(minute / 5) * 5,
  )

  onChange(roundedMinutesTime)
}

const PGATimePicker = ({
  id,
  name,
  error,
  defaultValue,
  date,
  helperText,
  minutesStep,
  horizontalAnchor,
  onChanged,
  ...rest
}) => {
  const { control, register } = useFormContext()
  const [isFocussed, setIsFocussed] = React.useState(false)
  const rootRef = React.useRef(null)

  minutesStep = minutesStep || 1
  minutesStep = Math.min(Math.max(minutesStep, 1), 60)

  React.useEffect(() => {
    const handleBodyClick = e => {
      setIsFocussed(rootRef.current && rootRef.current.contains(e.target))
    }

    document.body.addEventListener('mousedown', handleBodyClick)

    return () => {
      document.body.removeEventListener('mousedown', handleBodyClick)
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale="en-us">
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          return (
            <Box ref={rootRef} position="relative">
              <DesktopTimePicker
                {...rest}
                id={id}
                innerRef={register}
                slotProps={{
                  ...(rest.slotProps || {}),
                  textField: {
                    ...(rest.slotProps?.textField || {}),
                    InputProps: {
                      endAdornment: (
                        <InputAdornment>
                          <ScheduleIcon />
                        </InputAdornment>
                      ),
                    },
                    fullWidth: true,
                    name,
                    error,
                    helperText,
                  },
                }}
                value={value.toJSDate()}
                onChange={e => handleTimeChange(e, onChange, date)}
                open={false}
              />
              {isFocussed && (
                <PGATimePickerDropdown
                  defaultValue={value}
                  minutesStep={minutesStep}
                  horizontalAnchor={horizontalAnchor}
                  onCancel={() => setIsFocussed(false)}
                  onChange={newValue => {
                    onChanged(newValue)
                    handleTimeChange(newValue, onChange, date)
                    setIsFocussed(false)
                  }}
                />
              )}
            </Box>
          )
        }}
      />
    </LocalizationProvider>
  )
}

export default PGATimePicker
