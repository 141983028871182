import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import useStyles from './styles'
import ContactsTab from './contacts-tab' // TODO: move this to appropriate folder

const StudentLeadTabs = ({ tabValue, handleTabChange }) => {
  return (
    <Tabs value={tabValue} onChange={handleTabChange}>
      <Tab value="students" label="Students" />
      <Tab value="leads" label="Leads" />
    </Tabs>
  )
}

const SelectContactsModal = ({
  contacts,
  addContact,
  inquiries,
  students,
  open,
  setOpen,
  setStep,
}) => {
  const [error, setError] = useState(null)
  const [tabValue, setTabValue] = useState('students')
  const styles = useStyles()

  const handleNextStep = () => {
    if (contacts.length) {
      setStep(1)
    } else {
      setError('Please select at least 1 contact.')
    }
  }

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue)
  }

  return (
    <>
      <DialogTitle>
        <div className={styles.titleWrapper}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="p">Select Contacts</Typography>
          </div>
          {contacts.length > 0 && (
            <Typography variant="p" color="primary">
              {contacts.length} selected
            </Typography>
          )}
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent dividers>
        <StudentLeadTabs {...{ tabValue, handleTabChange }} />
        {error && !contacts.length ? (
          <Typography className={styles.errorText}>{error}</Typography>
        ) : null}
        <div className={styles.contactsWrapper}>
          <ContactsTab
            {...{
              inquiries: tabValue === 'students' ? students : inquiries,
              students,
              contacts,
              addContact,
              type: tabValue,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={styles.actionsStyles}>
        <Button onClick={() => setOpen(!open)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleNextStep} color="primary" variant="contained">
          Next
          <ArrowForwardIosIcon
            fontSize="small"
            style={{ paddingLeft: '10px' }}
          />
        </Button>
      </DialogActions>
    </>
  )
}

SelectContactsModal.propTypes = {
  contacts: PropTypes.array,
  addContact: PropTypes.func,
  inquiries: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setStep: PropTypes.func,
}

export default SelectContactsModal
