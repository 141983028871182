import { gql } from '@apollo/client'

export const GET_FACILITY = gql`
  query getFacility($facilityId: ID!) {
    facility(facilityId: $facilityId) {
      id
      name
      address1
      address2
      city
      state
      zip
      timezone
      url
      phone
      externalFacilityId
      private
      availabilities {
        id
        timeSlots {
          day
          startTime
          endTime
        }
      }
      coachProfilesFacilities {
        visible
        facilityRank
        offersCoachingAtPrivateClub
      }
    }
  }
`

export const UPDATE_FACILITY = gql`
  mutation FacilityUpdate(
    $facilityId: ID!
    $facility: FacilityInputAttributes!
    $visible: Boolean
    $primary: Boolean
    $offersCoachingAtPrivateClub: Boolean
  ) {
    facilityUpdate(
      facilityId: $facilityId
      facility: $facility
      visible: $visible
      primary: $primary
      offersCoachingAtPrivateClub: $offersCoachingAtPrivateClub
    ) {
      message
      success
    }
  }
`
