import { useState, useEffect } from 'react'

export default function useCalcProgress(data) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const progressObject = {
      facilityAdded: data.facilityAdded,
      coachProfileCreated: data.coachProfileCreated,
      schedulableCreated: data.schedulableCreated,
      availabilityAdded: data.availabilityAdded,
    }
    const completedFields = Object.keys(progressObject).filter(
      key => progressObject[key] !== null,
    )
    setProgress(
      Math.floor(
        (completedFields.length / Object.keys(progressObject).length) * 100,
      ),
    )
  }, [data])

  return progress
}
