import React, { useState } from 'react'
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
  Box,
} from '@material-ui/core'
import { useSnackbar, useSnackbarSuccess, useSnackbarError } from 'lib/snackbar'
import { gql, useMutation } from '@apollo/client'
import { get } from 'lodash'
import useStyles from './styles'

const deleteCoachConnectionMutation = gql`
  mutation DeleteCoachConnection($studentId: ID!, $deletionReason: String) {
    deleteCoachConnection(
      studentId: $studentId
      deletionReason: $deletionReason
    ) {
      message
      success
    }
  }
`

const DeleteStudentDialog = ({ student, open, onSuccess, setOpen }) => {
  const [deleteStudent] = useMutation(deleteCoachConnectionMutation)

  const { enqueueSnackbar } = useSnackbar()
  const snackbarSuccess = useSnackbarSuccess(enqueueSnackbar)
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const [customReason, setCustomReason] = useState('')
  const [customReasonError, setCustomReasonError] = useState(false)
  const [deletionReason, setDeletionReason] = useState('')
  const [selectError, setSelectError] = useState(false)

  const MAX_CUSTOM_REASON_LENGTH = 255

  const DELETION_REASONS = [
    'Multiple accounts for this student',
    'Student is no longer in program',
    'Student moved away',
    'Other',
  ]

  const handleSelectChange = event => {
    if (selectError && event.target.value !== '') {
      setSelectError(false)
    }
    setDeletionReason(event.target.value)
  }

  const handleCustomChange = event => {
    setCustomReason(event.target.value)
    if (event.target.value.length > MAX_CUSTOM_REASON_LENGTH) {
      setCustomReasonError(true)
    } else {
      setCustomReasonError(false)
    }
  }

  const handleDeleteStudent = async () => {
    if (deletionReason === '') {
      setSelectError(true)
      return
    }
    if (customReasonError) {
      return
    }
    try {
      const { data } = await deleteStudent({
        variables: {
          studentId: student.id,
          deletionReason:
            deletionReason === 'Other' ? customReason : deletionReason,
        },
      })
      const deleted = get(data, ['deleteCoachConnection', 'success'])

      if (!deleted) {
        return snackbarError()
      } else {
        setOpen(!open)
        onSuccess()
        snackbarSuccess(`${student.firstName} successfully removed`)
      }
    } catch (e) {
      snackbarError()
    }
  }

  const isDeleteButtonDisabled = () => {
    if (deletionReason === '') {
      return true
    } else {
      return (
        deletionReason === 'Other' &&
        (customReason == null || customReason === '')
      )
    }
  }

  const classes = useStyles()

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
      <DialogTitle>Delete Student</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this student?
          <br />
          This student will be removed from your coach profile. Please provide a
          reason to help us improve both the coach and golfer experience.
        </DialogContentText>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2}
        >
          <FormControl required variant="outlined" error={selectError}>
            <InputLabel>Select Reasoning</InputLabel>
            <Select
              value={deletionReason}
              onChange={handleSelectChange}
              label="Select Reasoning *"
              inputProps={{ 'data-testid': 'deletion-reason-select' }}
            >
              {DELETION_REASONS.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectError ? (
            <FormHelperText error>Please select a reason.</FormHelperText>
          ) : null}
          {deletionReason === 'Other' ? (
            <Box pt={2}>
              <TextField
                label="Please Explain Below *"
                multiline
                maxRows={4}
                value={customReason}
                onChange={handleCustomChange}
                error={customReasonError}
                helperText={
                  customReasonError
                    ? `Reason must be less than ${MAX_CUSTOM_REASON_LENGTH} characters.`
                    : null
                }
                inputProps={{ 'data-testid': 'custom-reason-input' }}
              />
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          className={classes.deleteButton}
          variant="contained"
          onClick={handleDeleteStudent}
          disabled={
            selectError || customReasonError || isDeleteButtonDisabled()
          }
        >
          Delete Student
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteStudentDialog
