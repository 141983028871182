import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/styles'

const DialogBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '4rem 0',

  [theme.breakpoints.up(700)]: {
    width: '600px',
  },

  '& a': {
    textAlign: 'center',

    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}))

const FlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.only('xs')]: {
    '& .Mui-error': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const FormPersistence = ({
  persistKey,
  setFormValues,
  setActiveStepNumber,
}) => {
  const [existingStateCheck, setExistingStateCheck] = useState(false)
  const [existingFormValues, setExistingFormValues] = useState(null)
  const [existingStep, setExistingStep] = useState(0)

  useEffect(() => {
    if (!existingStateCheck) {
      setExistingStateCheck(true)

      const existingState = localStorage.getItem(persistKey)
      if (existingState) {
        try {
          const persistValue = JSON.parse(existingState)
          setExistingFormValues(persistValue.values)
          setExistingStep(persistValue.activeStep)
        } catch {
          // Ignore error
        }
      }
    }
  }, [existingStateCheck, persistKey])

  const onDialogResponse = confirm => {
    if (confirm) {
      setFormValues(existingFormValues)
      setActiveStepNumber(existingStep)
    } else {
      localStorage.removeItem(persistKey)
    }
    setExistingFormValues(null)
  }

  const LoadPreviousStateDialog = (
    <Dialog open={existingFormValues != null}>
      <DialogBox>
        <Typography
          variant="h4"
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          Recover Unsaved Changes
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: '1rem', textAlign: 'center' }}
        >
          We noticed you might have had unsaved changes on this form.
          <br />
          Continue where you left off?
        </Typography>
        <FlexRow>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => onDialogResponse(true)}
            style={{ marginRight: '20px' }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => onDialogResponse(false)}
          >
            No
          </Button>
        </FlexRow>
      </DialogBox>
    </Dialog>
  )

  return LoadPreviousStateDialog
}

FormPersistence.propTypes = {
  persistKey: PropTypes.string.isRequired,
  setActiveStepNumber: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
}

export default FormPersistence
