import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import LoadingButton from 'components/loading-button'
import { useHistory } from 'react-router-dom'

const DEFAULT_TEXT = `
Thank you for taking the first step to a new level of engagement with
your golfing community. Next, let's review some simple steps to get
your PGA Jr. League up and running.`

export default function JRLWelcome({ text = DEFAULT_TEXT, onClick, isCoach }) {
  const history = useHistory()
  const [clicked, setClicked] = useState(false)
  const handleClick = () => {
    setClicked(true)
    onClick()
    isCoach
      ? history.push('/junior-league')
      : history.push('/junior-league-manager')
  }
  return (
    <Grid container direction="column" alignItems="center" spacing={6}>
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <LoadingButton
          loading={clicked}
          disabled={clicked}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleClick}
          endIcon={<ArrowForwardIcon />}
        >
          Get Started
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
