import React from 'react'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => (
  <div
    id="header"
    style={{
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
    }}
  >
    <Header />
    {children}
    <Footer />
  </div>
)

export default Layout
