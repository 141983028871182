import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import LaunchIcon from '@material-ui/icons/Launch'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import Typography from '@material-ui/core/Typography'
import { makeStyles, styled } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import LinkWithIcon from 'components/link-with-icon'

const StyledCard = styled(Card)(props => ({
  borderRadius: 12,
  boxShadow: 'none',
  boxSizing: 'border-box',
  border: `1px solid ${
    props.warning
      ? props.theme.palette.warning.main
      : props.theme.palette.divider
  }`,
  backgroundColor: props.warning ? '#fff5e5' : 'none',
}))

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },

  grid: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
    marginBottom: props => (props.isHorizontal ? 0 : theme.spacing(1)),
  },

  icon: {
    alignItems: 'center',
    backgroundColor: ({ warning }) =>
      warning ? theme.palette.warning.main : theme.palette.primary.selected,
    borderRadius: 88,
    color: ({ warning }) =>
      warning
        ? theme.palette.primary.contrastText
        : theme.palette.primary.light,
    display: 'flex',
    fontSize: 36,
    height: 88,
    justifyContent: 'center',
    width: 88,
  },

  actions: {
    alignItems: 'flex-start',
    borderTop: '1px solid #EEE',
    display: 'flex',
    flexDirection: props => (props.isColumn ? 'column' : 'row'),
    fontSize: 14,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },

  action: {
    fontWeight: 500,
    fontSize: 15,
    '&:not(:first-child)': {
      marginTop: props => (props.isColumn ? theme.spacing(2) : 0),
      marginLeft: props => (props.isColumn ? 0 : theme.spacing(2)),
    },
  },

  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
  caption: {
    minHeight: theme.spacing(12),
  },
}))

const Action = ({
  url,
  text,
  onClick,
  to,
  arrowIcon,
  isColumn,
  disabled,
  component,
}) => {
  const classes = useStyles({ isColumn })

  const iconProps = {
    color: 'inherit',
    fontSize: 'small',
  }

  const linkIcon =
    to || arrowIcon ? (
      <ArrowRightAlt {...iconProps} />
    ) : (
      <LaunchIcon {...iconProps} />
    )

  const props = {
    className: clsx(classes.action, disabled && classes.disabled),
    color: 'primary',
    endIcon: linkIcon,
    underline: 'none',
    disabled,
  }

  if (onClick) {
    props.component = 'button'
    props.onClick = onClick
  } else if (to) {
    props.to = to
    props.component = RouterLink
  } else {
    props.href = url
    props.target = '_blank'
    props.rel = 'noopener noreferrer'
  }

  props.component = component || props.component

  return <LinkWithIcon {...props}>{text}</LinkWithIcon>
}

const IconCard = ({
  statusChip,
  title,
  icon: Icon,
  actions,
  testid,
  children,
  variant,
  actionsVariant = 'column',
  justify = 'space-between',
  warning,
}) => {
  const isHorizontal = variant === 'horizontal'
  const isColumn = actionsVariant === 'column'
  const classes = useStyles({ isHorizontal, isColumn, warning })
  const alignItems = isHorizontal ? 'flex-start' : 'center'
  return (
    <StyledCard raised={false} data-testid={testid} warning={warning}>
      <CardContent className={classes.content}>
        <Grid
          container
          alignItems={alignItems}
          direction="row"
          spacing={3}
          justify={justify}
          className={classes.grid}
        >
          {Icon && (
            <Grid item>
              <CardMedia>
                <div className={classes.icon}>
                  <Icon color="inherit" fontSize="inherit" />
                </div>
              </CardMedia>
            </Grid>
          )}

          {isHorizontal && (
            <Grid item data-testid="icon-card-horizontal">
              <Typography variant="h5">{title}</Typography>

              <Typography>{children}</Typography>
            </Grid>
          )}

          {statusChip && <Grid item>{statusChip}</Grid>}
        </Grid>

        {!isHorizontal && (
          <div data-testid="icon-card-vertical">
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>

            <Typography className={classes.caption}>{children}</Typography>
          </div>
        )}
      </CardContent>

      {actions && actions.length > 0 && (
        <CardActions className={classes.actions}>
          {actions.map((action, i) => (
            <Action {...action} isColumn={isColumn} key={i} />
          ))}
        </CardActions>
      )}
    </StyledCard>
  )
}

IconCard.defaultProps = {
  variant: 'horizontal',
}

IconCard.propTypes = {
  statusChip: PropTypes.element,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  actions: PropTypes.arrayOf(PropTypes.object),
  testid: PropTypes.string,
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
}

export default IconCard
