import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import Redirect from 'components/redirect'

export default function ExternalRedirect({ externalUrl, destination }) {
  const [redirecting, setRedirecting] = useState(true)

  const beginRedirect = async () => {
    await new Promise(resolve => setTimeout(resolve, 3000))
    setRedirecting(false)
  }

  beginRedirect()

  if (redirecting) {
    return (
      <Box display="flex" flexDirection="column" mt="5rem" alignItems="center">
        <Typography style={{ marginBottom: '2rem' }}>
          Redirecting you to {destination || 'external site'}...
        </Typography>
        <CircularProgress />
      </Box>
    )
  }

  return <Redirect to={externalUrl} />
}

ExternalRedirect.propTypes = {
  externalUrl: PropTypes.string.isRequired,
  destination: PropTypes.string,
}
