import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  logo: {
    height: '60px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
  },
  appbarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '0 60px',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  toolBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))
