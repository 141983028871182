import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    marginBottom: '20px',
    padding: '28px 0 28px 0',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  bodyText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
    },
    color: '#0000008a',
  },
  dialogHeader: {
    [theme.breakpoints.up('xs')]: {
      minWidth: '600px',
      height: '200px',
      backgroundColor: '#EBEFF5',
    },
  },
  title: {
    marginTop: '4.5rem',
  },
  eventRow: {
    margin: '20px 0',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
  },
  eventRowIcon: {
    marginRight: '2rem',
    color: '#0000008a',
  },
  iconWrapper: {
    color: 'rgba(0, 35, 75, 1)',
    backgroundColor: 'rgba(244, 246, 248, 1)',
    margin: '0 20px 0 24px',
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
  avatarRowIcon: {
    marginRight: '16px',
  },
  bodyFont: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: '0.1px',
  },
  categoryIcon: {
    position: 'absolute',
    height: '84px',
    width: '84px',
    left: '50px',
    top: '-42px',
    background: theme.palette.primary.light,
    '& svg': {
      width: '2.5rem !important',
      height: '2.5rem !important',
      '& path': {
        fill: '#FFF !important',
      },
    },
  },
  confirmationDialogContent: {
    textAlign: 'center',
    padding: '0 2rem',
  },
  paymentLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    textTransform: 'none',
    paddingLeft: '0px',
  },
}))
