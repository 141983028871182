import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.selected,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    '&:hover': {
      background: theme.palette.primary.selected,
      cursor: 'initial',
    },
  },
  baseText: {
    color: theme.palette.text.secondary,
  },
}))

const BlockedTimesEmpty = ({ IconComp, title, caption }) => {
  const styles = useStyles()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <IconButton className={styles.icon}>
        <IconComp />
      </IconButton>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        className={styles.baseText}
        gutterBottom
      >
        {caption}
      </Typography>
    </Box>
  )
}

BlockedTimesEmpty.propTypes = {
  IconComp: PropTypes.func,
  title: PropTypes.string,
  caption: PropTypes.string,
}

export default BlockedTimesEmpty
