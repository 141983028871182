import { formatNameForDisplay } from 'lib/text-display'
import { DateTime } from 'luxon'

const formatUpcomingSession = session => {
  const sessionStartDateTime = session?.startDateTime

  if (sessionStartDateTime) {
    return {
      humanizedStartDateTime: formatDate(
        sessionStartDateTime,
        session.coachFacility.timezone,
      ),
      ...session,
    }
  } else {
    return {
      __typename: null,
      humanizedStartDateTime: null,
      title: null,
      startDateTime: null,
    }
  }
}
const processStudentsData = data => {
  const processedData = []

  data?.forEach(studentData => {
    processedData.push(studentData)
  })

  return processedData
}

const parseStudents = coachingCenterStudents => {
  return (
    coachingCenterStudents.length > 0 &&
    coachingCenterStudents?.map(({ student, facilities, coaches }) => {
      const facilityList = facilities.map(({ id }) => id)

      const futureLessons = student.enrollments.filter(
        ({ lesson }) =>
          !lesson.isCanceled &&
          DateTime.fromISO(lesson.startDateTime) > DateTime.now(),
      )

      const lessonsAtFacility = futureLessons.filter(({ lesson }) =>
        facilityList.includes(lesson?.coachFacility?.id),
      )
      const lessons = lessonsAtFacility.map(({ lesson }) => lesson)
      const firstSortedLessons = lessons.sort(
        (a, b) => a.startDateTime - b.startDateTime,
      )[0]
      const nextSession = formatUpcomingSession(firstSortedLessons)
      return {
        id: student.id,
        firstName: student.firstName,
        lastName: student.lastName,
        fullName: formatNameForDisplay(
          `${student.firstName} ${student.lastName}`,
        ),
        isMinor: student.isMinor,
        upcomingSession: nextSession,
        representatives: student.contact,
        coaches: coaches,
        facilities: facilities,
      }
    })
  )
}

const formatDate = (startDateTime, timezone) => {
  const newDate = DateTime.fromISO(startDateTime).setZone(timezone)

  return newDate.toFormat('MMM d, yyyy, h:mma')
}

export { parseStudents, processStudentsData, formatDate }
