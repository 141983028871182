import React from 'react'
import { Container, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  box: {
    marginTop: theme.spacing(8),
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

// This is horribly named come up with something better.
function PaperContainer({ children }) {
  const classes = useStyles()

  return (
    <Container maxWidth="sm" className={classes.box}>
      <Paper className={classes.content}>{children}</Paper>
    </Container>
  )
}

export default PaperContainer
