import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from 'components/dialog'
import { useAuth } from 'lib/auth'
import Link from '@material-ui/core/Link'
import { MY_PGA_COM_HOST } from 'env'

const CANCEL_ENROLLMENT = gql`
  mutation CancelEnrollment($input: CancelEnrollmentInput!) {
    cancelEnrollment(input: $input) {
      success
      message
    }
  }
`

const CancelBookingDialog = ({
  trigger,
  confirmationCode,
  onSuccess,
  onConfirm,
}) => {
  const [cancelEnrollment, { loading }] = useMutation(CANCEL_ENROLLMENT)
  const { enqueueSnackbar } = useSnackbar()
  const { isConsumer } = useAuth()

  return (
    <Dialog trigger={trigger} maxWidth="sm">
      {({ closeDialog }) => {
        const onCancelBookingConfirm = () => {
          onConfirm && onConfirm()
          cancelEnrollment({
            variables: { input: { confirmationCode } },
          }).then(({ data }) => {
            if (data.cancelEnrollment.success) {
              enqueueSnackbar('Session canceled', { variant: 'success' })
              closeDialog()
              onSuccess && onSuccess()
            } else {
              enqueueSnackbar(data.cancelEnrollment.message, {
                variant: 'error',
              })
            }
          })
        }
        return (
          <>
            <DialogTitle>Cancel Session</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Are you sure you want to cancel this session?
              </Typography>
              <Typography variant="body1" gutterBottom>
                Attendees will be notified.
              </Typography>
              <Typography variant="body1" gutterBottom>
                {isConsumer ? (
                  'For any applicable refund, please reach out to your Coach.'
                ) : (
                  <>
                    {
                      'If you want to provide your student with a refund, please visit your '
                    }
                    <Link
                      href={`${MY_PGA_COM_HOST}/account/payments/transactions`}
                      style={{ fontWeight: 600 }}
                      target="_blank"
                    >
                      Transactions page
                    </Link>
                    {', open the associated transaction and select Add Refund.'}
                  </>
                )}
              </Typography>
              {loading ? <CircularProgress color="primary" /> : null}
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={closeDialog}>
                Dismiss
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onCancelBookingConfirm}
              >
                Cancel Session
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Dialog>
  )
}

export default CancelBookingDialog
