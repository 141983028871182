import { AUTH_IMPL } from 'env'
import useAuth from './useAuth'
import withProtectedRoute from './withProtectedRoute'
import withAuthenticatedRoute from './withAuthenticatedRoute'
import { roles } from './constants'
import ImpersonationOverlay from './ImpersonationOverlay'
import ImpersonateUserDialog from './ImpersonateUserDialog'

let withAuthProvider,
  getToken,
  LoginRedirect,
  redirectToLogin,
  SignupRedirect,
  redirectToSignup

switch (AUTH_IMPL) {
  case 'auth0': // use Auth0 Universal Login for both coach and consumer
    withAuthProvider = require('./auth0/withAuthProvider').default
    getToken = require('./auth0/token').getToken
    LoginRedirect = require('./auth0/LoginRedirect').default
    redirectToLogin = require('./auth0/LoginRedirect').redirectToLogin
    SignupRedirect = require('./auth0/SignupRedirect').default
    redirectToSignup = require('./auth0/SignupRedirect').redirectToSignup
    break
  case 'hybrid': // use Auth0 Universal Login for consumer, and legacy for coach
    withAuthProvider = require('./hybrid/withAuthProvider').default
    getToken = require('./hybrid/token').getToken
    LoginRedirect = require('./hybrid/LoginRedirect').default
    redirectToLogin = require('./hybrid/LoginRedirect').redirectToLogin
    SignupRedirect = require('./hybrid/SignupRedirect').default
    redirectToSignup = require('./hybrid/SignupRedirect').redirectToSignup
    break
  case 'legacy': // use legacy login for both coach and consumer
    withAuthProvider = require('./legacy/withAuthProvider').default
    getToken = require('./legacy/token').getToken
    LoginRedirect = require('./legacy/LoginRedirect').default
    redirectToLogin = require('./legacy/LoginRedirect').redirectToLogin
    SignupRedirect = require('./legacy/SignupRedirect').default
    redirectToSignup = require('./legacy/SignupRedirect').redirectToSignup
    break
  default:
    throw new Error(`Unknown AUTH_IMPL: ${AUTH_IMPL}`)
}

export {
  useAuth,
  withProtectedRoute,
  withAuthProvider,
  withAuthenticatedRoute,
  getToken,
  roles,
  ImpersonationOverlay,
  ImpersonateUserDialog,
  LoginRedirect,
  redirectToLogin,
  SignupRedirect,
  redirectToSignup,
}
