import React, { useState } from 'react'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { fullDayDateAndTimeWithTimezone } from 'utils/dateUtils'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  lighterText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))

const SessionsAccordion = ({ sessions, timezone }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = useStyles()

  return (
    <>
      <Link disableRipple onClick={() => setIsExpanded(!isExpanded)}>
        <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          {sessions.length} sessions
        </span>
      </Link>
      <Box pt={1} pl={2} hidden={!isExpanded}>
        {sessions.map(s => (
          <Box
            key={s.id}
            pb={1}
            variant="caption1"
            className={
              DateTime.fromISO(s.startDateTime) <
                DateTime.local().startOf('day') && classes.lighterText
            }
          >
            {fullDayDateAndTimeWithTimezone(
              s.startDateTime,
              s.endDateTime,
              timezone,
            )}
          </Box>
        ))}
      </Box>
    </>
  )
}

export default SessionsAccordion
