import React, { useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useSnackbar, useSnackbarError } from 'lib/snackbar'
import { ReactComponent as GoogleCalendarIcon } from 'images/icons/google-calendar-colored.svg'
import { GOOGLE_CLIENT_ID } from 'env'
import { gtmEvent } from 'lib/gtm'
import SettingsBox from 'components/coach-settings-box'
import SyncedCalendarPicker from './synced-calendar-picker'

const GOOGLE_RESOURCE_NAME = 'google'

const GET_CURRENT_USER_CALENDAR_CONNECTED_STATUS = gql`
  query GetCurrentUserCalendarConnectedStatus {
    currentUser {
      attributes {
        ... on Coach {
          googleCalendarConnected
        }
      }
    }
  }
`

const GRANT_RESOURCE_ACCESS = gql`
  mutation GrantResourceAccess($input: GrantResourceAccessInput!) {
    grantResourceAccess(input: $input) {
      success
      message
    }
  }
`
const REVOKE_RESOURCE_ACCESS = gql`
  mutation RevokeResourceAccess($input: RevokeResourceAccessInput!) {
    revokeResourceAccess(input: $input) {
      success
      message
    }
  }
`

const GoogleCalendarIntegration = ({
  googleCalendarConnected,
  onIntegrationChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)

  const [isConnectedCalendar, setIsConnectedCalendar] = useState(
    googleCalendarConnected,
  )

  const [grantResourceAccess] = useMutation(GRANT_RESOURCE_ACCESS, {
    onCompleted: data => {
      if (data.grantResourceAccess.success) {
        setIsConnectedCalendar(true)
        onIntegrationChange()
        gtmEvent({
          event: 'formSubmit',
          formCategory: 'block-time-google-calendar-integration',
          formAction: 'connected-google-calendar',
        })
      } else {
        snackbarError(data.grantResourceAccess.message)
      }
    },
  })
  const [revokeResourceAccess] = useMutation(REVOKE_RESOURCE_ACCESS, {
    onCompleted: data => {
      if (data.revokeResourceAccess.success) {
        setIsConnectedCalendar(false)
        onIntegrationChange()
        gtmEvent({
          event: 'formSubmit',
          formCategory: 'block-time-google-calendar-integration',
          formAction: 'disconnected-google-calendar',
        })
      } else {
        snackbarError(data.revokeResourceAccess.message)
      }
    },
  })

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    overrideScope: true,
    onSuccess: response =>
      grantResourceAccess({
        variables: {
          input: { key: response.code, resourceName: GOOGLE_RESOURCE_NAME },
        },
      }),
    onFailure: () => snackbarError('Google Login Failure'),
  })

  const actionConnect = (
    <Button
      variant="contained"
      color="primary"
      style={{ marginLeft: 'auto', marginRight: '16px' }}
      onClick={googleLogin}
    >
      Connect
    </Button>
  )

  const actionDisconnect = (
    <Button
      variant="contained"
      color="primary"
      style={{ marginLeft: 'auto', marginRight: '16px' }}
      onClick={() =>
        revokeResourceAccess({
          variables: { input: { resourceName: GOOGLE_RESOURCE_NAME } },
        })
      }
    >
      Disconnect
    </Button>
  )

  return (
    <SettingsBox
      icon={<GoogleCalendarIcon />}
      title="Sync blocked times from Google Calendar"
      subtitle="Connect your Google Calendar to automatically block off times when you are busy."
      action={isConnectedCalendar ? actionDisconnect : actionConnect}
    >
      {isConnectedCalendar && (
        <SyncedCalendarPicker onChange={onIntegrationChange} />
      )}
    </SettingsBox>
  )
}

const withGoogleOAuthProvider = Component => props => (
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Component {...props} />
  </GoogleOAuthProvider>
)

const withCalendarConnectedStatus = Component => props => {
  const { data, loading } = useQuery(GET_CURRENT_USER_CALENDAR_CONNECTED_STATUS)
  if (loading) return <LinearProgress />
  return (
    <Component
      googleCalendarConnected={
        data?.currentUser?.attributes.googleCalendarConnected
      }
      {...props}
    />
  )
}

export default withGoogleOAuthProvider(
  withCalendarConnectedStatus(GoogleCalendarIntegration),
)
