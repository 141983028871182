import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  label: {
    position: 'absolute',
    top: '-8px',
    backgroundColor: 'white',
    padding: '0px 8px',
    fontSize: '12px',
  },
  dropdown: {
    margin: '1rem 0',
    minWidth: '200px',
    maxWidth: '300px',
    paddingLeft: '2rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}))

export default useStyles
