import { useEffect } from 'react'
import { useAuth } from 'lib/auth'

const RaygunActivate = () => {
  const auth = useAuth()

  useEffect(() => {
    if (auth.user) {
      window.rg4js &&
        window.rg4js('setUser', {
          identifier: auth.user.externalId,
          isAnonymous: false,
          email: auth.user.email,
          fullName: auth.user.name,
        })
    } else {
      window.rg4js &&
        window.rg4js('setUser', {
          isAnonymous: true,
        })
    }
  }, [auth.user])

  return null
}

export default RaygunActivate
