import { gql, useMutation, useQuery } from '@apollo/client'
import { handleHookResult, mapResultData } from 'lib/graphql'

export const GET_COACH_ACCOUNT = gql`
  query CoachAccount {
    currentUser {
      attributes {
        ... on Coach {
          phone
          name
          email
          id
          coachProfile {
            id
            profilePhoto
          }
        }
      }
    }
  }
`

export const UPDATE_COACH_ACCOUNT = gql`
  mutation UpdateCoachAccount($coachId: ID!, $input: UpdateCoachInput!) {
    updateCoach(coachId: $coachId, input: $input) {
      success
      message
      coach {
        phone
        name
        email
        id
      }
    }
  }
`

export const useGetCoachAccount = () => {
  const result = useQuery(GET_COACH_ACCOUNT)
  return mapResultData(result, ({ currentUser }) => currentUser.attributes)
}

export const useUpdateCoachAccount = () => {
  const [mutation, result] = useMutation(UPDATE_COACH_ACCOUNT)

  const mapResult = result =>
    handleHookResult(result, 'updateCoach', response => response)

  function runMutation({ coachId, input }) {
    return mutation({ variables: { coachId, input } }).then(mapResult)
  }

  return [runMutation, mapResult(result)]
}
