import { gql } from '@apollo/client'
import GroupEventFragment from 'fragments/group-event'

export const COACH_ONBOARDING_STATUS = gql`
  query CoachOnboardingStatus {
    currentUser {
      attributes {
        ... on Coach {
          coachProfile {
            id
            slug
          }
          pgaMembership {
            admCertified
          }
          coachOnboardingProgress {
            coachProfileCreated
            schedulableCreated
            availabilityAdded
            facilityAdded
          }
        }
      }
    }
  }
`

export const BOOKINGS_QUERY = gql`
  query GetCoachBookingsByStartDate(
    $startDateTime: DateTimeRangeInput!
    $orderBy: [ScheduleOrderByInput!]!
    $limit: Int
  ) {
    currentUser {
      schedule(
        startDateTime: $startDateTime
        orderBy: $orderBy
        limit: $limit
      ) {
        __typename
        id
        startDateTime
        endDateTime
        timezone
        isCanceled
        title
        description
        startDateTime
        coachFacility {
          id
          name
          street
          city
          state
          zipCode
        }
        coach {
          id
          name
        }
        enrollments {
          id
          confirmationCode
          createdAt
          isCanceled
          paymentRequestToken
          paymentRequestedDate
          lessonCredit {
            lessonPack {
              lessonPackOffering {
                pricePerLessonInCents
              }
            }
          }
          student {
            id
            firstName
            lastName
            representatives {
              contact {
                externalId
                firstName
                lastName
                email
                phone
              }
            }
            demographicProfile {
              gender
            }
            pgaHopeEmergencyContact {
              id
              firstName
              lastName
              phone
              email
              relationship
            }
            pgaHopeProfile {
              id
              accommodations
              additionalInfo
              golfCareerInterest
              golfExperience
              handedness
              hasOwnClubs
              militaryStatus
              referralSource
              serviceBranch
              serviceYearStart
              serviceYearEnd
              shirtSize
            }
            contact {
              id
              firstName
              lastName
              email
              phone
              externalId
            }
          }
          transactions {
            amountInCents
            createdAt
            sourceType
            refund
            registrationPayment {
              id
              products {
                ... on Enrollment {
                  lesson {
                    title
                  }
                }
              }
              registrationPaymentCharge {
                id
                chargeType
                chargeNumber
                checkNumber
              }
            }
          }
        }
        ... on Session {
          lessonType {
            id
            category
            priceInCents
            acceptsOnlinePayments
            durationInMinutes
          }
        }
        ... on GroupEvent {
          ${GroupEventFragment}
        }
      }
    }
  }
`
