import React, { lazy, Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { withProtectedRoute, roles } from 'lib/auth'
import themes, { extendTheme } from 'themes'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LinearProgress } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'

const Register = lazy(() => import('pages/family-cup-captain/register'))

const FCCaptainRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <ThemeProvider theme={extendTheme(themes.familyCup)}>
      <Helmet>
        <title>PGA Family Golf Management</title>
        <meta name="description" content="PGA Family Golf Management" />
        <link rel="icon" href="/family-golf-favicon.ico" />
      </Helmet>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Suspense fallback={<LinearProgress color="secondary" />}>
          <Switch>
            <Route exact path={`${path}/:programId`}>
              <Register />
            </Route>
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(FCCaptainRoutes, roles.CONSUMER)
