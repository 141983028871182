import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DateTime, Interval } from 'luxon'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import RouterLinkButton from 'components/router-link-button'
import SessionListItem from './session-list-item'
import GroupEventListItem from './group-event-list-item'
import LocationDropdown from '../../../schedule/SelectLessonType/location-dropdown'
import LinearProgress from '@material-ui/core/LinearProgress'
import BOOKINGS_QUERY from './bookings-query'
import { bookingsWithSessionStartTimes } from './bookings-list-helpers'
import Divider from '@material-ui/core/Divider'
import pipe from 'lodash/fp/pipe'
import flatMap from 'lodash/fp/flatMap'
import uniqBy from 'lodash/fp/uniqBy'
import { useIsMobile } from 'lib/hooks'
import { onReschedule, onPayment, onCancellation } from 'lib/utils'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    '& a': {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
  },
  title: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '1px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 35, 75, 0.08)',
    borderRadius: '45px',
    height: '83px',
    width: '83px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '2rem',
    marginBottom: '1.5rem',
    [theme.breakpoints.only('xs')]: {
      paddingTop: 0,
      justifyContent: 'start',
      flexDirection: 'row-reverse',
    },
  },
  locationWrapper: {
    width: '100%',
    maxWidth: '200px',
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(2),
    },
    '& h6': {
      fontSize: '1rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
}))

const groupByDate = (bookings, timeframe) => {
  bookings = bookingsWithSessionStartTimes(bookings, timeframe)
  return bookings.reduce((acc, b) => {
    const date = DateTime.fromISO(b.startDateTime, { zone: b.timezone })
      .startOf('day')
      .toISODate()
    if (!(date in acc)) {
      acc[date] = []
    }
    acc[date].push(b)
    return acc
  }, {})
}

const DATE_FORMAT = 'EEEE, MMMM d, yyyy'
const formatDate = date =>
  DateTime.fromISO(date) === DateTime.local().startOf('day')
    ? 'Today'
    : DateTime.fromISO(date).toFormat(DATE_FORMAT)

const BookingsList = ({
  startDateTime,
  reverse,
  emptyStateTitle,
  emptyStateSubtitle,
  timeframe,
  selectedTab,
}) => {
  const isInvalidDateRange =
    startDateTime.from &&
    startDateTime.to &&
    !Interval.fromDateTimes(
      DateTime.fromISO(startDateTime.from),
      DateTime.fromISO(startDateTime.to),
    ).isValid

  const { data, loading, refetch: refreshBookings } = useQuery(BOOKINGS_QUERY, {
    skip: isInvalidDateRange,
    variables: {
      startDateTime: startDateTime,
      orderBy: [
        { field: 'START_DATE_TIME', direction: reverse ? 'DESC' : 'ASC' },
      ],
    },
  })

  const [selectedFacility, setSelectedFacility] = useState({
    id: 'all',
    name: 'All locations',
  })
  const classes = useStyles()
  const isMobile = useIsMobile()
  const [showCanceledBookings, setShowCanceledBookings] = useState(false)
  const [coachFacilities, setCoachFacilities] = useState([])
  const [bookingsByDate, setBookingsByDate] = useState([])
  const [hasOneLocation, setHasOneLocation] = useState(false)
  const schedule = data?.currentUser.schedule || []
  const bookings = schedule.filter(l => showCanceledBookings || !l.isCanceled)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const filteredSchedule =
      selectedFacility === 'all' || selectedFacility.id === 'all'
        ? bookings
        : bookings.filter(el => el.coachFacility.id === selectedFacility)
    setBookingsByDate(groupByDate(filteredSchedule, timeframe))
  }, [JSON.stringify(bookings), selectedFacility])

  useEffect(() => {
    const groupEventsLocations = pipe(
      flatMap(ge => ge.coachFacility),
      uniqBy('id'),
    )(schedule)

    setCoachFacilities(groupEventsLocations)
  }, [data])

  const dates = reverse
    ? Object.keys(bookingsByDate)
      .sort()
      .reverse()
    : Object.keys(bookingsByDate).sort()

  if (loading) {
    return <LinearProgress color="primary" />
  }

  if (bookings.length === 0) {
    return (
      <Box p={4} display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.icon} mb={1.5} mt={3}>
          <CalendarTodayOutlinedIcon
            color="primary"
            style={{ fontSize: '40px' }}
          />
        </Box>
        <Box mb={1.5}>
          <Typography variant="h6">{emptyStateTitle}</Typography>
        </Box>
        <Box mb={2} style={{ maxWidth: '550px' }}>
          <Typography
            variant="body2"
            style={{ textAlign: 'center', marginBottom: '12px' }}
          >
            {emptyStateSubtitle}&nbsp;
          </Typography>
        </Box>
        <RouterLinkButton
          variant="contained"
          color="primary"
          to="/pga-coach/settings/availability"
        >
          Manage Availability
        </RouterLinkButton>
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.controls}>
        <FormControlLabel
          size="size"
          control={
            <Switch
              checked={showCanceledBookings}
              onChange={e => setShowCanceledBookings(e.target.checked)}
            />
          }
          label={
            <Typography variant="button">
              {!isMobile || (isMobile && hasOneLocation)
                ? 'Show Cancellations'
                : 'Canceled'}
            </Typography>
          }
        />
        {!hasOneLocation && (
          <Box className={classes.locationWrapper}>
            <LocationDropdown
              selectedFacility={selectedFacility}
              setSelectedFacility={setSelectedFacility}
              locations={coachFacilities}
              setHasOneLocation={setHasOneLocation}
            />
          </Box>
        )}
      </Box>
      {selectedTab === 1 && (
        <Typography variant="body2" className={classes.title}>
          PAST 30 DAYS
        </Typography>
      )}
      <Divider />
      {dates.map(date => (
        <Fragment key={date}>
          <Box my={1.5}>
            <Typography variant="h6">{formatDate(date)}</Typography>
          </Box>
          {bookingsByDate[date].map(booking => {
            if (booking.__typename === 'Session') {
              return (
                <SessionListItem
                  key={booking.id}
                  session={booking}
                  onReschedule={() => onReschedule(refreshBookings)}
                  onPayment={() => onPayment(refreshBookings)}
                  onCancellation={() => onCancellation(refreshBookings)}
                />
              )
            }
            if (booking.__typename === 'GroupEvent') {
              return (
                <GroupEventListItem
                  key={booking.id}
                  groupEvent={booking}
                  onPayment={() => onPayment(refreshBookings)}
                />
              )
            }
            return null
          })}
        </Fragment>
      ))}
    </>
  )
}

BookingsList.propTypes = {
  startDateTime: PropTypes.object.isRequired,
  emptyStateTitle: PropTypes.string.isRequired,
  emptyStateSubtitle: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  timeframe: PropTypes.string,
  selectedTab: PropTypes.number,
}

export default BookingsList
