import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { PropTypes } from 'prop-types'
import { IconButtonCtaArrow } from './icons'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    width: '3.0625rem',
    height: '3.0625rem',
    minWidth: 'initial',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.blue.dark,
      color: theme.palette.offwhite.main,
    },
  },
  left: {
    transform: 'rotate(180deg)',
    backgroundColor: theme.palette.offwhite.main,
  },
  arrow: {
    margin: '0',
  },
}))

export default function SliderControl(props) {
  const { onNext, onPrevious, className } = props
  const styles = useStyles()

  return (
    <div className={`${styles.container} ${className}`}>
      <Button
        type="button"
        onClick={() => onPrevious()}
        className={`${styles.button} ${styles.left}`}
        title="previous slide"
      >
        <IconButtonCtaArrow />
      </Button>
      <Button
        type="button"
        onClick={() => onNext()}
        className={styles.button}
        title="next slide"
      >
        <IconButtonCtaArrow className={styles.arrow} width={21} height={12} />
      </Button>
    </div>
  )
}

SliderControl.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  className: PropTypes.string,
}

SliderControl.defaultProps = {
  className: '',
}
