import React from 'react'
import { PGA_COM_HOST } from 'env'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const CoachInfo = ({ coach }) => {
  const { coachProfile } = coach
  const coachDisplayInitial = (
    coachProfile?.displayName ?? coach.name
  ).substring(0, 1)

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Avatar src={coachProfile?.profilePhoto || ''} alt="coach">
            {coachDisplayInitial}
          </Avatar>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1">
            {coachProfile?.displayName ?? coach.name}
          </Typography>
          <Typography variant="body2">Event Host</Typography>
        </Box>
      </Box>
      {coachProfile != null && (
        <Button
          href={`${PGA_COM_HOST}/coach/${coachProfile.customSlug}`}
          color="primary"
        >
          View Profile
        </Button>
      )}
    </Box>
  )
}

export default CoachInfo
