import React, { useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import pipe from 'lodash/fp/pipe'
import { get } from 'lodash'
import uniqBy from 'lodash/fp/uniqBy'
import sortBy from 'lodash/fp/sortBy'
import { Typography } from '@material-ui/core'

const LocationDropdown = ({
  selectedFacility,
  setSelectedFacility,
  locations,
  groupEventsLocations = [],
  programsLocations = [],
  setHasOneLocation = () => {},
}) => {
  let allLocations = locations.concat(groupEventsLocations)
  allLocations = allLocations.concat(programsLocations)
  // the pipe function is treated as a curried function, can pass in the
  // arguments one at a time and it will return a new function that takes the
  // next argument. Acts similar to the '|' operator in bash to pass the results into the next function
  const uniqueSortedLocations = pipe(uniqBy('id'), sortBy('name'))(allLocations)

  uniqueSortedLocations.unshift({
    id: 'all',
    name: `All Locations (${uniqueSortedLocations.length})`,
  })

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSelectedFacility(get(uniqueSortedLocations, '0.id', null))
    setHasOneLocation(uniqueSortedLocations.length === 2)
  }, [])

  return (
    <FormControl variant="outlined">
      <Select
        value={selectedFacility || ''}
        variant="outlined"
        IconComponent={KeyboardArrowDownIcon}
        onChange={e => setSelectedFacility(e.target.value)}
      >
        {uniqueSortedLocations.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Typography variant="subtitle2" color="textPrimary">
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default LocationDropdown
