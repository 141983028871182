import React from 'react'
import ActionButtons from './action-buttons'
import EventDetails from './index'

const CoachEventDetails = ({ event, setIsOpen }) => {
  return (
    <EventDetails
      event={event}
      actionButtons={() => (
        <ActionButtons setIsOpen={setIsOpen} event={event} />
      )}
    />
  )
}

export default CoachEventDetails
