import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import LessonPackCalculation from './lesson-pack-calculation'
import { useFieldArray, useFormContext } from 'react-hook-form'

const BookingTypePriceQuantity = ({ singleLessonPrice }) => {
  const { control, errors, register, setValue, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'lessonPacks',
    control,
  })

  typeof singleLessonPrice !== 'undefined' &&
    fields.length === 0 &&
    fields.push({
      quantity: '1',
      pricePerLesson: singleLessonPrice,
      priceInCents: singleLessonPrice,
    })
  const addPack = () => {
    append({ quantity: '', pricePerLesson: '', priceInCents: '' })
  }

  const deletePack = packNumber => {
    remove(packNumber)
  }

  return (
    <Box mt={3} mb={4}>
      <Typography variant="subtitle1" gutterBottom>
        Price &amp; Quantity
      </Typography>
      <Typography variant="body2" gutterBottom>
        Build lesson packs by entering the quantity and price you would like to
        offer. Payment for lesson packs will be collected up front with the
        option to book at a later time.
      </Typography>
      {fields.map((item, index) => {
        return (
          <LessonPackCalculation
            key={item.id}
            packNumber={index}
            deletePack={fields.length > 1 && (() => deletePack(index))}
            errors={errors}
            register={register}
            setValue={setValue}
            getValues={getValues}
            defaultValues={item}
          />
        )
      })}
      <Button
        aria-controls="add-lesson-pack"
        aria-haspopup="true"
        color="primary"
        variant="text"
        size="large"
        alignitems="center"
        onClick={addPack}
      >
        {'+ Add PACK'}
      </Button>
    </Box>
  )
}

export default BookingTypePriceQuantity
