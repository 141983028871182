// forked from https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelHeader/ChannelHeader.tsx

import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { useChannelStateContext, useChatContext } from 'stream-chat-react'
import ConversationActionsMenu from './components/conversation-actions'

const ChannelHeader = () => {
  const { channel } = useChannelStateContext()
  const { client, openMobileNav } = useChatContext()

  const currentUserId = client.user?.id
  const { name, description } = channel?.data || {}
  const otherMembers = Object.values(channel?.state.members || {})
    .filter(m => m.user_id !== currentUserId)
    .map(m => m.user)

  const title = (() => {
    if (name) return name
    if (otherMembers.length > 3) {
      return `${otherMembers
        .slice(0, 3)
        .map(u => u.name)
        .join(', ')}, and ${otherMembers.length - 3} other(s)`
    }
    return otherMembers.map(u => u.name).join(', ')
  })()

  return (
    <div className="str-chat__header-livestream">
      <div className="str-chat__header-hamburger" onClick={openMobileNav}>
        <ArrowBack />
      </div>
      <div className="str-chat__header-livestream-left">
        <p className="str-chat__header-livestream-left--title">{title} </p>
        {description && (
          <p className="str-chat__header-livestream-left--subtitle">
            {description}
          </p>
        )}
        <p className="str-chat__header-livestream-left--members">
          {!!otherMembers && otherMembers.length > 1 && (
            <>{`${otherMembers.length + 1} members`}</>
          )}
        </p>
      </div>
      <div className="str-chat__header-livestream-right">
        <ConversationActionsMenu channel={channel} />
      </div>
    </div>
  )
}

export default ChannelHeader
