import { MY_PGA_COM_HOST } from 'env'

export const urls = {
  home: '/',
  search: '/events/search',
  how: '/learn/how-it-works',
  faq: '/learn/faq',
  news: '/learn/news',
  history: '/learn/history',
  coachBecome: '/coach/become-a-coach',
  coachResources: '/coach/coaching-resources',
  privacy: 'https://www.pga.com/pga-of-america/privacy-policy',
  terms: '/terms',
  coachLogin: `${MY_PGA_COM_HOST}/family-golf`,
  playerLogin: `${MY_PGA_COM_HOST}/family-golf-captain`,
  twitter: 'https://twitter.com/pgafamilygolf',
  facebook: 'https://www.facebook.com/PGAFamilyCup/',
  instagram: 'https://www.instagram.com/pgafamilygolf/',
  pgaCom: 'https://www.pga.com',
  doNotSell:
    'https://privacyportal.onetrust.com/webform/d026f30a-622a-40b3-b261-d78a6b839240/e468c3de-717f-44a2-8576-29799026c21a',
}

export const topLeftMenu = [
  {
    text: 'Home',
    url: urls.home,
  },
  {
    text: 'Learn',
    subMenu: [
      {
        text: 'How It Works',
        url: urls.how,
      },
      {
        text: 'FAQ',
        url: urls.faq,
      },
      {
        text: 'News',
        url: urls.news,
      },
      {
        text: 'History',
        url: urls.history,
      },
    ],
  },
  {
    text: 'Play',
    url: urls.search,
  },
  {
    text: 'Coach',
    subMenu: [
      {
        text: 'Become a Coach',
        url: urls.coachBecome,
      },
      {
        text: 'Coaching Dashboard',
        url: urls.coachLogin,
      },
      {
        text: 'Resource Center',
        url: urls.coachResources,
      },
    ],
  },
]

export const topRightMenu = [
  {
    text: 'Coach Login',
    url: urls.coachLogin,
  },
  {
    text: 'Player Login',
    url: urls.playerLogin,
  },
]

export const footerMenus = {
  left: [
    {
      text: 'Home',
      url: urls.home,
    },
    {
      text: 'Find Events',
      url: urls.search,
    },
    {
      text: 'How it Works',
      url: urls.how,
    },
    {
      text: 'History',
      url: urls.history,
    },
  ],
  right: [
    {
      text: 'News',
      url: urls.news,
    },
    {
      text: 'FAQs',
      url: urls.faq,
    },
    {
      text: 'Coach',
      url: urls.coachBecome,
    },
  ],
  bottom: [
    {
      text: 'Terms of Service',
      url: urls.terms,
    },
    {
      text: 'Privacy Policy',
      url: urls.privacy,
    },
    {
      text: 'California Privacy Notice',
      url: 'https://www.pga.com/pga-of-america/california-privacy-notice',
    },
    {
      text: 'Do Not Sell or Share My Personal Information',
      url: urls.doNotSell,
    },
  ],
}
