import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import { useIsMobile } from 'lib/hooks'

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      flexDirection: 'column !important',
      border: 'none !important',
    },
  },
  sectionTitle: {
    fontWeight: '500',
    fontSize: '20px',
    margin: '24px 32px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column !important',
      fontSize: '16px',
      margin: '16px',
      lineHeight: '31px',
    },
  },
  sectionContent: {
    margin: '0px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column !important',
      margin: 0,
      padding: '0 16px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}))

export default ({ children, title = '', ...props }) => {
  const styles = useStyles()
  const isMobile = useIsMobile()

  return (
    <Card className={styles.section} variant="outlined" {...props}>
      <Typography className={styles.sectionTitle}>{title}</Typography>
      {!isMobile && <Divider />}
      <CardContent className={styles.sectionContent}>{children}</CardContent>
    </Card>
  )
}
